import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { DashboardService } from "src/app/services/dashboard.service";
import { Location } from "@angular/common";
import {
  Observable,
  Subject,
  concatMap,
  defer,
  filter,
  first,
  firstValueFrom,
  from,
  map,
  merge,
  of,
  switchMap,
  takeUntil,
  tap,
  toArray,
} from "rxjs";
import { ModalService } from "src/app/services/modal.service";
import { Group, PassService } from "src/app/sinigangnababoywithgabi";
import { GroupModalComponent } from "src/app/app-common-module/components/group-modal/group-modal.component";

@Component({
  selector: "app-general-settings-guest-login",
  templateUrl: "./general-settings-guest-login.component.html",
  styles: [],
})
export class GeneralSettingsGuestLoginComponent implements OnInit {
  form: UntypedFormGroup;
  groupListGuestObs: Observable<Group[]>;
  groupListClaimedObs: Observable<Group[]>;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private dashboardService: DashboardService,
    private _location: Location,
    private modalService: ModalService,
    private passService: PassService
  ) {}

  async ngOnInit() {
    let cast = await firstValueFrom(this.dashboardService.getCast());
    let application = await firstValueFrom(
      this.passService.getApplication(localStorage.getItem("application_id"))
    );
    let learners = await firstValueFrom(
      this.passService.listGroups("Learners", 5)
    );
    this.form = this.formBuilder.group({
      allowGuestLogin: [
        application.allowGuestLogin ||
          cast.data.attributes.allowGuestLogin ||
          false,
      ],
      defaultLoginAsGuest: [
        cast.data?.attributes?.defaultLoginAsGuest || false,
      ],
      additionalInstructions: [
        cast.data.attributes.claimGuestAccountMessage || "",
      ],
      defaultGuestGroupIds: [
        application.defaultGuestGroupIds || [],
        Validators.required,
      ],
      claimedGuestGroupIds: [
        application.claimedGuestGroupIds?.length > 0
          ? application.claimedGuestGroupIds
          : [learners.groups[0]?.id] || [],
        Validators.required,
      ],
    });

    this.generateGroupListForClaimedObs();
    this.generateGroupListForGuestObs();
  }
  private unsubscribe$: Subject<void> = new Subject<void>();
  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  get allowGuestLogin() {
    return this.form.get("allowGuestLogin");
  }
  async selectGroupForGuest() {
    let result = await this.modalService.openModal(GroupModalComponent, {
      panelClass: [
        "overflow-y-auto",
        "flex",
        "justify-center",
        "items-center",
        "shadow-lg",
        "w-3/4",
        "h-1/2",
      ],
      data: { idOnly: true },
    });
    if (result) {
      let distinctGroupIds: string[] = [
        ...new Set([...this.form.get("defaultGuestGroupIds").value, ...result]),
      ];
      this.form.get("defaultGuestGroupIds").setValue(distinctGroupIds);
    }
  }

  async selectGroupForClaimed() {
    let result = await this.modalService.openModal(GroupModalComponent, {
      panelClass: [
        "overflow-y-auto",
        "flex",
        "justify-center",
        "items-center",
        "shadow-lg",
        "w-3/4",
        "h-1/2",
      ],
      data: { idOnly: true },
    });
    if (result) {
      let distinctGroupIds: string[] = [
        ...new Set([...this.form.get("claimedGuestGroupIds").value, ...result]),
      ];
      this.form.get("claimedGuestGroupIds").setValue(distinctGroupIds);
    }
    this.form.get("claimedGuestGroupIds").value;
  }
  generateGroupListForGuestObs() {
    this.groupListGuestObs = defer(() =>
      merge(
        this.form.get("defaultGuestGroupIds").valueChanges,
        of(this.form.get("defaultGuestGroupIds").value)
      ).pipe(
        takeUntil(this.unsubscribe$),
        filter((r) => r),
        switchMap((groupIds: string[]) => {
          return from(groupIds).pipe(
            concatMap((id) =>
              this.passService.getGroup(id, ["roles", "users_count"])
            ),

            map((result) => {
              return {
                ...result,
                roles: result.roles.filter((role) => !role.name?.includes(":")),
              };
            }),
            toArray()
          );
        })
      )
    );
  }

  generateGroupListForClaimedObs() {
    this.groupListClaimedObs = defer(() =>
      merge(
        this.form.get("claimedGuestGroupIds").valueChanges,
        of(this.form.get("claimedGuestGroupIds").value)
      ).pipe(
        takeUntil(this.unsubscribe$),
        filter((r) => r),
        switchMap((groupIds: string[]) => {
          return from(groupIds).pipe(
            concatMap((id) =>
              this.passService.getGroup(id, ["roles", "users_count"])
            ),

            map((result) => {
              return {
                ...result,
                roles: result.roles.filter((role) => !role.name?.includes(":")),
              };
            }),
            toArray()
          );
        })
      )
    );
  }

  removeGroupGuest(index: number) {
    let newGroupIds = [...this.form.get("defaultGuestGroupIds").value];

    newGroupIds.splice(index, 1);

    this.form.get("defaultGuestGroupIds").setValue(newGroupIds);
  }

  removeGroupClaimed(index: number) {
    let newGroupIds = [...this.form.get("claimedGuestGroupIds").value];

    newGroupIds.splice(index, 1);

    this.form.get("claimedGuestGroupIds").setValue(newGroupIds);
  }

  async onSave() {
    let updatedCast = await firstValueFrom(
      this.dashboardService.saveCast({
        data: {
          attributes: {
            allowGuestLogin: this.form.get("allowGuestLogin").value,
            defaultLoginAsGuest: this.form.get("defaultLoginAsGuest").value,
            claimGuestAccountMessage: this.form.get("additionalInstructions")
              .value,
          },
        },
      })
    );
    let updatedApplication = await firstValueFrom(
      this.passService.updateApplication(
        localStorage.getItem("application_id"),
        {
          allowGuestLogin: this.form.get("allowGuestLogin").value,
          defaultGuestGroupIds: this.form.get("defaultGuestGroupIds").value,
          claimedGuestGroupIds: this.form.get("claimedGuestGroupIds").value,
        }
      )
    );
    this._location.back();
  }
}
