import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";

class IconValue {
  value: string;
  icon: string;
  disabled?: boolean;
}

// icons listed here: https://icomoon.io/#preview-free
export const AVAILABLE_ACTIVITY_TYPES: IconValue[] = [
  {
    value: "MC",
    icon: "radio_button_checked",
  },
  // {
  //   value: "IC",
  //   icon: "profile",
  // },
  {
    value: "LA",
    icon: "notes",
  },
  {
    value: "FW",
    icon: "forum",
  },
  {
    value: "CB",
    icon: "check_box",
  },
  {
    value: "TA",
    icon: "filter_1",
  },
  {
    value: "PO",
    icon: "format_list_numbered",
  },
  {
    value: "SA",
    icon: "format_color_text",
  },
  {
    value: "SU",
    icon: "star_half",
  },
];

@Component({
  selector: "app-select-activity-type-view",
  templateUrl: "./select-activity-type-view.component.html",
})
export class SelectActivityTypeViewComponent implements OnInit {
  @Output()
  select = new EventEmitter<string>();

  @Input()
  ignore: string[] = [];

  activityTypes;

  constructor() {}

  ngOnInit() {
    this.activityTypes = AVAILABLE_ACTIVITY_TYPES.filter(
      (type) => !this.ignore?.includes(type.value)
    );
  }

  onTypeSelect(activityType: IconValue) {
    this.select.emit(activityType.value);
  }
}
