import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  ResourcesService,
  Resource,
  V1ExamItem,
  V1ExamsService,
} from "src/app/sinigangnababoywithgabi";
import { firstValueFrom } from "rxjs";

@Component({
  selector: "app-buribooks-select-type",
  templateUrl: "./buribooks-new-question-select-type.component.html",
})
export class BuribooksNewQuestionSelectTypeComponent implements OnInit {
  resource: Resource;

  examItem: V1ExamItem;

  loading: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private v1ExamsService: V1ExamsService,
    private resourcesService: ResourcesService
  ) {}

  async ngOnInit() {
    let { resource_uuid: resourceUuid } = this.route.snapshot.queryParams;

    this.resource = await firstValueFrom(
      this.resourcesService.getResourceByUuid(resourceUuid)
    );
  }

  async onTypeSelect(activityType: string) {
    let examUuid = this.resource.content["examDetails"]?.["examUuid"];

    if (activityType === "TA") {
      this.loading = true;
      let tallyExamProfile;

      try {
        tallyExamProfile = await firstValueFrom(
          this.v1ExamsService.tallyExamProfileDetail(examUuid)
        );
      } catch (err) {
        tallyExamProfile = await firstValueFrom(
          this.v1ExamsService.tallyExamProfileDetailCreate(examUuid)
        );
      } finally {
        this.loading = false;
      }
    }

    this.router.navigate(["new"], {
      relativeTo: this.route.parent,
      queryParams: {
        item_type: activityType,
      },
      queryParamsHandling: "merge",
      replaceUrl: true,
    });
  }
}
