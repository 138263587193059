<div
  class="h-10 flex font-bold items-center w-full p-1 {{
    block.data.attributes.properties?.classes?.navbar
  }}"
  [style.backgroundColor]="
    block.data.attributes.properties.appearance?.base?.backgroundColor
      | castColor
  "
>
  <img class="h-8" [src]="cast?.data.attributes.castIcon?.web?.nav" alt="" />

  <div
    class="flex-1 mx-1 truncate"
    [style.fontFamily]="
      block.data.attributes.properties.appearance?.base?.fontFamily?.[0]
    "
    [style.color]="
      block.data.attributes.properties.appearance?.base?.foregroundColor
        | castColor
    "
  >
    {{ cast?.data.attributes.title }}
  </div>

  <!-- <button class="btn btn-icon bg-white mx-1">
    <app-icon icon="more_horiz"></app-icon>
  </button> -->

  <app-dropdown-layout toggleClass="mx-1" toggleBodyClass="z-50">
    <span dropdownToggle>
      <app-icon icon="more_horiz"></app-icon>
    </span>

    <div dropdownContent>
      <ul class="{{ block.data.attributes.properties?.classes?.navbar }}">
        <li
          class=" py-2 {{ block.data.attributes.properties?.classes?.links }}"
          *ngFor="let item of block.data.attributes.items"
        >
          <a
            routerLink="/_casteditor"
            [queryParams]="{ screen_id: item.screenId }"
            class="mx-1 block w-full"
          >
            <!-- <app-icon [icon]="item.icon?.material"></app-icon> -->

            {{ item.title }}
          </a>
        </li>
      </ul>
    </div>
  </app-dropdown-layout>

  <!-- <div class="flex items-center">
    <span
      class="mx-1"
      *ngFor="let item of block.data.attributes.items"
    >
      {{ item.title }}
    </span>
  </div> -->

  <div>
    <button
      class="btn btn-icon bg-gray-200 text-primary mx-1"
      [routerLink]="['navigation', 'edit']"
      queryParamsHandling="merge"
    >
      <app-icon icon="edit"></app-icon>
    </button>
  </div>
</div>
