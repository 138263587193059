<select
  class="form-control"
  [disabled]="disabled"
  [ngModel]="value"
  (ngModelChange)="writeValue($event)"
  [name]="name"
>
  <option value="inherit">-----</option>
  <option *ngFor="let font of fonts" [ngValue]="font.value">
    {{ font.name }}
  </option>
</select>
