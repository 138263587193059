<table class="table-auto border-collapse border">
  <tr class="border text-primary text-left">
    <th class="py-5 px-3" *ngFor="let column of columnsForDisplay">
      {{ column | titlecase }}
    </th>
  </tr>

  <tr class="border" *ngFor="let item of data">
    <td class="py-2 px-3" *ngFor="let column of columns">{{ item[column] }}</td>
  </tr>
</table>
