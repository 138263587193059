import { TextFieldModule } from "@angular/cdk/text-field";
import { CommonModule, DatePipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDialogModule } from "@angular/material/dialog";
import { RouterModule } from "@angular/router";
import { PieChartModule } from "@swimlane/ngx-charts";
import { ColorPickerModule } from "ngx-color-picker";
import { NgxFileDropModule } from "ngx-file-drop";
import { LottieCacheModule, LottieModule } from "ngx-lottie";
import { MarkdownModule } from "ngx-markdown";
import { MatTabsModule } from "@angular/material/tabs";

import { AlertDialogComponent } from "./components/alert-dialog/alert-dialog.component";
import { CheckboxComponent } from "./components/checkbox/checkbox.component";
import { ColorComponent } from "./components/color/color.component";
import { ConfirmDialogComponent } from "./components/confirm-dialog/confirm-dialog.component";
import { CsvUploadOrPasteComponent } from "./components/csv-upload-or-paste/csv-upload-or-paste.component";
import { DropzoneComponent } from "./components/dropzone/dropzone.component";
import { FilterComponent } from "./components/filter/filter.component";
import { FontSelectComponent } from "./components/font-select/font-select.component";
import { GroupSelectDialogComponent } from "./components/group-select-dialog/group-select-dialog.component";
import { IconSelectDialogComponent } from "./components/icon-select-dialog/icon-select-dialog.component";
import { IconComponent } from "./components/icon/icon.component";
import { ItemListComponent } from "./components/item-list/item-list.component";
import { ItemSelectWithHeaderComponent } from "./components/item-select-with-header/item-select-with-header.component";
import { ItemSelectComponent } from "./components/item-select/item-select.component";
import { ListTableHeaderComponent } from "./components/list-table-header/list-table-header.component";
import { MarkdownViewerAndEditorComponent } from "./components/markdown-viewer-and-editor/markdown-viewer-and-editor.component";
import { MarkdownViewerComponent } from "./components/markdown-viewer/markdown-viewer.component";
import { MultiSelectComponent } from "./components/multi-select/multi-select.component";
import { NotificationOverlayComponent } from "./components/notification-overlay/notification-overlay.component";
import { PaginationQueryComponent } from "./components/pagination-query/pagination-query.component";
import { PaginationComponent } from "./components/pagination/pagination.component";
import { PromptDialogComponent } from "./components/prompt-dialog/prompt-dialog.component";
import { RadioComponent } from "./components/radio/radio.component";
import { ResourceCardComponent } from "./components/resource-card/resource-card.component";
import { ResourceLibrarySelectorComponent } from "./components/resource-library-selector/resource-library-selector.component";
import { SearchBarQueryComponent } from "./components/search-bar-query/search-bar-query.component";
import { SearchBarComponent } from "./components/search-bar/search-bar.component";
import { SimplemdeEditorComponent } from "./components/simplemde-editor/simplemde-editor.component";
import { UserSelectComponent } from "./components/user-select/user-select.component";
import { DndDirective } from "./directives/dnd.directive";
import { GoBackDirective } from "./directives/go-back.directive";
import { BackTitleLayoutComponent } from "./layouts/back-title-layout/back-title-layout.component";
import { DropdownLayoutComponent } from "./layouts/dropdown-layout/dropdown-layout.component";
import { FormControlErrorLayoutComponent } from "./layouts/form-control-error-layout/form-control-error-layout.component";
import { IndexLinkListComponent } from "./layouts/index-link-list/index-link-list.component";
import { LoadingWithRetryComponent } from "./layouts/loading-with-retry/loading-with-retry.component";
import { LoadingComponent } from "./layouts/loading/loading.component";
import { MarginLayoutComponent } from "./layouts/margin-layout/margin-layout.component";
import { ModalComponent } from "./layouts/modal/modal.component";
import { NavDrawerLayoutComponent } from "./layouts/nav-drawer-layout/nav-drawer-layout.component";
import { ProfileLinkListComponent } from "./layouts/profile-link-list/profile-link-list.component";
import { UploadLoadingWrapperComponent } from "./layouts/upload-loading-wrapper/upload-loading-wrapper.component";
import { UploadWrapperComponent } from "./layouts/upload-wrapper/upload-wrapper.component";
import { ActivityTypePipe } from "./pipes/activity-type.pipe";
import { CastColorPipe } from "./pipes/cast-color.pipe";
import { FilterPipePipe } from "./pipes/filter-pipe.pipe";
import { RangePipe } from "./pipes/range.pipe";
import { ResourceTypeIconPipe } from "./pipes/resource-type-icon.pipe";
import { ResourceTypePipe } from "./pipes/resource-type.pipe";
import { SafeHtmlPipe } from "./pipes/safe-html.pipe";
import { SafeResourceUrlPipe } from "./pipes/safe-resource-url.pipe";
import { TimeDurationPipe } from "./pipes/time-duration.pipe";
import { WaitForObsAfterClickDirective } from "./directives/wait-for-obs-after-click.directive";
import { PillLabelComponent } from "./components/pill-label/pill-label.component";
import { FiltersComponent } from "./components/filters/filters.component";
import { TableHeaderComponent } from "./components/table-header/table-header.component";
import { FilterSectionComponent } from "./components/filter-section/filter-section.component";
import { GroupModalComponent } from "./components/group-modal/group-modal.component";
import { ColumnsComponent } from "./components/columns/columns.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { PolicyComponent } from "./components/policy/policy.component";
import { DateTimeComponent } from "./components/date-time/date-time.component";

export function playerFactory() {
  return import(
    /* webpackChunkName: 'lottie-web' */ "lottie-web/build/player/lottie_light"
  );
}

@NgModule({
  declarations: [
    GoBackDirective,
    BackTitleLayoutComponent,
    TimeDurationPipe,
    MarginLayoutComponent,
    ModalComponent,
    ResourceTypePipe,
    UploadWrapperComponent,
    DropzoneComponent,
    FilterPipePipe,
    ActivityTypePipe,
    RangePipe,
    DropdownLayoutComponent,
    FormControlErrorLayoutComponent,
    LoadingComponent,
    NotificationOverlayComponent,
    UploadLoadingWrapperComponent,
    DndDirective,
    IconComponent,
    ProfileLinkListComponent,
    SearchBarComponent,
    CheckboxComponent,
    RadioComponent,
    UserSelectComponent,
    ItemSelectComponent,
    LoadingWithRetryComponent,
    ItemListComponent,
    SearchBarQueryComponent,
    MarkdownViewerComponent,
    MarkdownViewerAndEditorComponent,
    SafeResourceUrlPipe,
    IndexLinkListComponent,
    AlertDialogComponent,
    ConfirmDialogComponent,
    PromptDialogComponent,
    IconSelectDialogComponent,
    SafeHtmlPipe,
    ResourceLibrarySelectorComponent,
    MultiSelectComponent,
    ColorComponent,
    FontSelectComponent,
    CastColorPipe,
    PaginationQueryComponent,
    PaginationComponent,
    NavDrawerLayoutComponent,
    ResourceCardComponent,
    CsvUploadOrPasteComponent,
    SimplemdeEditorComponent,
    GroupSelectDialogComponent,
    ListTableHeaderComponent,
    ItemSelectWithHeaderComponent,
    ResourceTypeIconPipe,
    FilterComponent,
    WaitForObsAfterClickDirective,
    PillLabelComponent,
    FiltersComponent,
    TableHeaderComponent,
    FilterSectionComponent,
    ColumnsComponent,
    GroupModalComponent,
    PolicyComponent,
    DateTimeComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TextFieldModule,
    NgxFileDropModule,
    PieChartModule,
    ColorPickerModule,
    MarkdownModule.forRoot(),
    MatDialogModule,
    DragDropModule,
    LottieModule.forRoot({ player: playerFactory }),
    LottieCacheModule.forRoot(),
    MatTabsModule,
  ],
  exports: [
    GoBackDirective,
    BackTitleLayoutComponent,
    TimeDurationPipe,
    MarginLayoutComponent,
    ModalComponent,
    ResourceTypePipe,
    UploadWrapperComponent,
    PieChartModule,
    FilterPipePipe,
    ActivityTypePipe,
    RangePipe,
    DropdownLayoutComponent,
    FormControlErrorLayoutComponent,
    LoadingComponent,
    NotificationOverlayComponent,
    UploadLoadingWrapperComponent,
    DndDirective,
    IconComponent,
    ProfileLinkListComponent,
    SearchBarComponent,
    CheckboxComponent,
    RadioComponent,
    UserSelectComponent,
    ItemSelectComponent,
    LoadingWithRetryComponent,
    ItemListComponent,
    SearchBarQueryComponent,
    MarkdownViewerComponent,
    MarkdownViewerAndEditorComponent,
    SafeResourceUrlPipe,
    IndexLinkListComponent,
    AlertDialogComponent,
    ConfirmDialogComponent,
    IconSelectDialogComponent,
    SafeHtmlPipe,
    ResourceLibrarySelectorComponent,
    MultiSelectComponent,
    ColorComponent,
    FontSelectComponent,
    CastColorPipe,
    PaginationQueryComponent,
    PaginationComponent,
    ResourceCardComponent,
    NavDrawerLayoutComponent,
    CsvUploadOrPasteComponent,
    SimplemdeEditorComponent,
    GroupSelectDialogComponent,
    ItemSelectWithHeaderComponent,
    ListTableHeaderComponent,
    ResourceTypeIconPipe,
    FilterComponent,
    WaitForObsAfterClickDirective,
    PillLabelComponent,
    FiltersComponent,
    TableHeaderComponent,
    FilterSectionComponent,
    ColumnsComponent,
    GroupModalComponent,
    PolicyComponent,
    DateTimeComponent,
    MatTabsModule,
  ],
  providers: [
    ResourceTypePipe,
    ResourceTypeIconPipe,
    CastColorPipe,
    DatePipe,
    FilterPipePipe,
    TimeDurationPipe,
  ],
})
export class AppCommonModule {}
