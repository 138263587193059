<app-back-title-layout>
  <div class="my-5">
    <app-item-list [source]="groupListObservable" [itemTemplate]="itemTemplate">
    </app-item-list>
  </div>
</app-back-title-layout>

<ng-template #itemTemplate let-group="item">
  <a
    class="flex items-center"
    [routerLink]="['/groups', 'profile']"
    [queryParams]="{
      group_uuid: group.id
    }"
  >
    <app-icon icon="group" class="text-gray-600 text-4xl mx-2"></app-icon>

    <div>
      <div class="text-gray-600 font-semibold leading-none">
        {{ group.name }}
      </div>
    </div>
  </a>
</ng-template>
