<div
  class="
    container
    w-full
    justify-center
    pb-3
    mx-auto
    bg-white
    h-full
    min-h-screen
  "
>
  <ng-content></ng-content>
</div>
