<app-back-title-layout>
  <div>
    <h1 class="font-bold text-xl">System Emails</h1>
    <div class="text-gray-400 text-sm">
      Emails listed below are fixed emails used for operational purposes of
      Cast.
    </div>
  </div>
  <div class="grid grid-cols-2 gap-4 mt-4">
    <ng-container *ngFor="let action of emailActions">
      <button
        data-tooltip="Contact your admin to gain access"
        [disabled]="true"
        [routerLink]="action.routerLink"
        class="
          flex
          w-full
          border border-gray-300
          rounded
          leading-tight
          focus:outline-none focus:bg-white focus:border-gray-500
        "
      >
        <div
          class="
            border-r border-gray-300
            py-4
            px-4
            text-4xl text-primary-600
            bg-primary-100
          "
        >
          <app-icon [icon]="action.icon"></app-icon>
        </div>
        <div class="ml-4 truncate py-4 px-2">
          <div class="truncate text-left font-bold mb-1">{{ action.name }}</div>
          <div class="truncate text-xs text-gray-400">
            {{ action.description }}
          </div>
        </div>
      </button>
    </ng-container>
  </div>

  <!-- waiting for system email to be accessible -->
  <!-- <div
    [routerLink]="'/mailer/system-email'"
    class="justify-end text-primary-600 flex my-2 uppercase cursor-pointer"
  >
    see all
    <app-icon [icon]="'chevron_right'"></app-icon>
  </div> -->
  <div class="relative mt-16">
    <router-outlet></router-outlet>
  </div>
</app-back-title-layout>
