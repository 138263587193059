<app-back-title-layout>
  <h2 class="text-center my-3 text-xl font-bold">Add Resource</h2>

  <div
    class="grid grid-cols-3 grid-row gap-3 text-gray-500 mb-10 max-w-xl mx-auto"
  >
    <div
      class="h-0 aspect-rect w-full relative col-span-3"
      *ngIf="!ignoreCourseCreate"
    >
      <div class="absolute inset-0">
        <button
          (click)="onTypeSelect({ type: 'course' })"
          class="transition-colors duration-100 hover:bg-gray-100 hover:text-gray-600 rounded-lg bg-gray-100 flex justify-center items-center h-full w-full"
        >
          <app-icon class="text-5xl" icon="school"> </app-icon>

          <span class="font-semibold mx-5">Create a course</span>
        </button>
      </div>
    </div>

    <div
      class="h-0 aspect-square w-full relative"
      *ngIf="!ignoreResourceSelect"
    >
      <div class="absolute inset-0">
        <button
          class="transition-colors hover:bg-gray-100 hover:text-gray-600 rounded-lg bg-gray-100 flex flex-col justify-center items-center h-full w-full p-3"
          [routerLink]="[
            '/library',
            isInsideCourse ? 'add-to-course' : 'add-to-collection'
          ]"
          [replaceUrl]="true"
          queryParamsHandling="merge"
        >
          <app-icon class="text-5xl" icon="file_copy"> </app-icon>

          <span class="font-semibold"> Use existing resource </span>
        </button>
      </div>
    </div>

    <ng-container *ngFor="let resourceType of resourceTypes">
      <div class="h-0 aspect-square w-full relative">
        <div class="absolute inset-0">
          <button
            [disabled]="resourceType.disabled"
            (click)="onTypeSelect(resourceType)"
            class="transition-colors hover:bg-gray-100 hover:text-gray-600 rounded-lg bg-gray-100 flex flex-col justify-center items-center h-full w-full p-3"
            [ngClass]="{
              'bg-gray-50 text-gray-100 cursor-not-allowed':
                resourceType.disabled,
              'hover:bg-gray-100 hover:text-gray-600': !resourceType.disabled
            }"
          >
            <app-icon class="text-5xl" [icon]="resourceType | resourceTypeIcon">
            </app-icon>

            <span class="font-semibold">
              {{ resourceType.text || (resourceType | resourceType) }}
            </span>
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</app-back-title-layout>
