import { Injectable, EventEmitter } from "@angular/core";

export interface SuccessMessage {
  title?: string;
  message?: string;
}

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  onError: EventEmitter<Error> = new EventEmitter();
  onSuccess: EventEmitter<SuccessMessage> = new EventEmitter();

  constructor() {}

  addError(error: Error) {
    this.onError.emit(error);
  }

  addSuccess(successMessage: SuccessMessage) {
    this.onSuccess.emit(successMessage);
  }
}
