import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { firstValueFrom } from "rxjs";
import { HumanizeService } from "src/app/services/humanize.service";
import {
  PassService,
  User,
  V3PayService,
} from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-purchase-product-user-manual-payment",
  templateUrl: "./purchase-product-user-manual-payment.component.html",
  styles: [],
})
export class PurchaseProductUserManualPaymentComponent implements OnInit {
  user: User;

  loading: boolean;

  subsChoices: {
    subscripitionPlanUuid: string;
    productId: string;
    price: number;
    planUnitDuration: string;
    freeTrialUnitDuration: string;
  }[];

  formGroup = new FormGroup({
    subscripitionPlanUuid: new FormControl<string>(null, Validators.required),
  });

  constructor(
    private route: ActivatedRoute,
    private passService: PassService,
    private v3PayService: V3PayService,
    private humanizeService: HumanizeService,
    private location: Location
  ) {}

  async ngOnInit() {
    const {
      product_id: productId,
      subscription_group_uuid: subscriptionGroupUuid,
      user_uuid: userUuid,
    } = this.route.snapshot.queryParams;

    this.user = await firstValueFrom(this.passService.getUser(userUuid));

    this.subsChoices = await firstValueFrom(
      this.v3PayService.listProducts(
        null,
        null,
        null,
        null,
        null,
        "stock_records,subscription_plan",
        null,
        null,
        subscriptionGroupUuid
      )
    ).then((result) =>
      result.map((product) => ({
        productId: product.id,
        subscripitionPlanUuid: product.subscriptionPlan.uuid,
        price: product.stockRecords[0]?.price,
        planUnitDuration: this.humanizeService.humanizePeriodUnit(
          product.subscriptionPlan.planPeriod,
          product.subscriptionPlan.planUnit
        ),
        freeTrialUnitDuration: this.humanizeService.humanizePeriodUnit(
          product.subscriptionPlan.trialPeriod,
          product.subscriptionPlan.trialUnit
        ),
      }))
    );
  }
  async onSubmit() {
    this.loading = true;

    try {
      await firstValueFrom(
        this.v3PayService.subscribeUsers({
          userSubscriptions: [
            {
              plan: this.formGroup.value.subscripitionPlanUuid,
              user: this.user.id,
              isActive: true,
            },
          ],
        })
      );
      this.location.back();
    } catch (err) {
      this.loading = false;

      throw err;
    }
  }
}
