import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { Resource } from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-resource-type-upload-text",
  templateUrl: "./resource-type-upload-text.component.html",
  styles: [],
})
export class ResourceTypeUploadTextComponent implements OnInit {
  @Input()
  formGroup: UntypedFormGroup;

  @Input()
  resource: Resource;
  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.formGroup.addControl(
      "content",
      this.formBuilder.group({
        text: [this.resource.content["text"], Validators.required],
      })
    );
  }
}
