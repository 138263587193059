<app-back-title-layout>
  <div class="w-full h-full">
    <form *ngIf="formGroup" [formGroup]="formGroup" (ngSubmit)="onSave()">
      <h2 class="font-bold text-xl">Colors</h2>
      <p class="mb-4">
        Your Cast already have its default overall color theme, changing the
        assigned colors below will override your overall Cast color theme
      </p>
      <button
        type="button"
        class="text-primary-700 mb-12"
        (click)="resetToDefault()"
      >
        Reset to default
      </button>
      <label class="form-label">Text Color</label>

      <div class="w-24">
        <app-color
          formControlName="baseForegroundColor"
          class="w-1/2"
        ></app-color>
      </div>

      <div class="my-5">
        <label class="form-label">Background Color</label>
        <div class="w-24">
          <app-color
            formControlName="baseBackgroundColor"
            class="w-1/2"
          ></app-color>
        </div>
      </div>
      <div class="flex w-full justify-end sticky bottom-0">
        <button class="btn btn-primary" type="submit">SAVE</button>
      </div>
    </form>
  </div>
</app-back-title-layout>
