import { Component, OnInit } from "@angular/core";
import { CoursesService } from "src/app/sinigangnababoywithgabi";
import { ActivatedRoute } from "@angular/router";
import { merge, Observable } from "rxjs";
import { map, flatMap } from "rxjs/operators";
import { FilterPipePipe } from "src/app/app-common-module/pipes/filter-pipe.pipe";
import { UntypedFormControl } from "@angular/forms";
import { of } from "rxjs";

// DEPRECATED
@Component({
  selector: "app-course-batches",
  templateUrl: "./course-batches.component.html",
  styleUrls: ["./course-batches.component.scss"],
})
export class CourseBatchesComponent implements OnInit {
  courseUuid: string;

  scheduleListObservable: Observable<any>;
  tabFormControl = new UntypedFormControl(1);

  constructor(
    private coursesService: CoursesService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.courseUuid = this.route.snapshot.queryParams["course_uuid"];

    let filterAll = new FilterPipePipe();

    this.scheduleListObservable = this.coursesService
      .courseScheduleList(this.courseUuid)
      .pipe(
        flatMap((items) => {
          return this.route.queryParams.pipe(
            map((params) => {
              return filterAll.transform(items, params["q"]);
            })
          );
        }),
        flatMap((items) => {
          return merge(
            of(this.tabFormControl.value),
            this.tabFormControl.valueChanges
          ).pipe(
            map((tab) => {
              let nowDate = new Date();

              if (tab === 1) {
                return items.filter((schedule) => {
                  let startDate = schedule.start
                    ? new Date(schedule.start)
                    : null;
                  let endDate = schedule.end ? new Date(schedule.end) : null;

                  return (
                    (startDate === null ||
                      startDate.valueOf() < nowDate.valueOf()) &&
                    (endDate == null || endDate.valueOf() > nowDate.valueOf())
                  );
                });
              } else if (tab == 2) {
                return items.filter((schedule) => {
                  let startDate = schedule.start
                    ? new Date(schedule.start)
                    : null;
                  let endDate = schedule.end ? new Date(schedule.end) : null;

                  return startDate && startDate.valueOf() > nowDate.valueOf();
                });
              } else if (tab == 3) {
                return items.filter((schedule) => {
                  let startDate = schedule.start
                    ? new Date(schedule.start)
                    : null;
                  let endDate = schedule.end ? new Date(schedule.end) : null;

                  return endDate && endDate.valueOf() < nowDate.valueOf();
                });
              }
            })
          );
        })
      );
  }

  selectTab(index: number) {
    this.tabFormControl.setValue(index);
  }
}
