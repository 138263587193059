import { Component, OnInit } from "@angular/core";
import { DashboardService } from "src/app/services/dashboard.service";

@Component({
  selector: "app-idis-options",
  templateUrl: "./idis-options.component.html",
  styles: [],
})
export class IdisOptionsComponent implements OnInit {
  idisActions = [];
  constructor(private dashboardService: DashboardService) {}
  ngOnInit(): void {
    this.idisActions = this.dashboardService.getIDISActions();
  }
}
