import { Location } from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  SimpleChanges,
  Output,
  EventEmitter,
} from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { firstValueFrom, zip } from "rxjs";
import { criteriaWeightSum } from "src/app/app-common-module/form-validators/criteria-weight-sum.validator";
import { minLengthArray } from "src/app/app-common-module/form-validators/min-length-array.validator";
import {
  V1ExamChoice,
  V1ExamChoiceSetFeedback,
  V1ExamItem,
  V1ExamItemCriteria,
  V1ExamsService,
} from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-activity-question-edit",
  templateUrl: "./activity-question-edit.component.html",
})
export class ActivityQuestionEditComponent implements OnInit {
  formGroup: UntypedFormGroup;

  loading: boolean = false;
  canUpdate: boolean = false;
  correctImg: string;
  incorrectImg: string;
  label: string;
  @Input() insideSlide: boolean = false;
  @Input() examItemUuid: string;
  @Output() choiceSetFeedbacks: EventEmitter<V1ExamChoiceSetFeedback[]> =
    new EventEmitter<V1ExamChoiceSetFeedback[]>();
  @Output() item: EventEmitter<V1ExamItem> = new EventEmitter<V1ExamItem>();
  @Output() choices: EventEmitter<V1ExamChoice[]> = new EventEmitter<
    V1ExamChoice[]
  >();
  @Output() isLoading: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() updated: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(
    private v1ExamsService: V1ExamsService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private formBuilder: UntypedFormBuilder,
    private cd: ChangeDetectorRef
  ) {}
  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.examItemUuid.currentValue !== changes.examItemUuid.previousValue
    ) {
      this.ngOnInit();
    }
  }
  async ngOnInit() {
    this.loading = true;
    this.isLoading.emit(this.loading);
    let {
      exam_uuid: examUuid,
      item_uuid: itemUuid,

      // Flag for saving then immediately creating another item
      // Used if the question is inside an assessment resource type
      additional,

      // Flag for disabling/enabling show results checkbox
      disable_show_results: disableShowResults,
    } = this.route.snapshot.queryParams;

    // Fetch Exam details
    let [exam, examItem, examChoices, examChoiceSetFeedbacks] =
      await firstValueFrom(
        zip([
          this.v1ExamsService.examsRead(examUuid, "allowed_actions"),
          this.v1ExamsService.examsSectionsItemsRead(
            itemUuid || this.route.snapshot.params.uuid || this.examItemUuid
          ),
          this.v1ExamsService.examsSectionsItemsChoicesList(
            itemUuid || this.route.snapshot.params.uuid || this.examItemUuid
          ),
          this.v1ExamsService.examsItemsChoicesetfeedbacksList(
            itemUuid || this.route.snapshot.params.uuid || this.examItemUuid
          ),
        ])
      );
    this.item.emit(examItem);
    this.choices.emit(examChoices);
    this.choiceSetFeedbacks.emit(examChoiceSetFeedbacks);
    // Can the user update the exam? If no use this to disable the form and the edit buttons
    if (exam.allowedActions?.includes("update")) {
      this.canUpdate = true;
    }

    this.formGroup = this.formBuilder.group({
      itemUuid: [
        itemUuid || this.route.snapshot.params.uuid || this.examItemUuid,
      ],
      text: [examItem.text || "", Validators.required],
      title: [examItem.title || ""],
      type: [examItem.type || ""],
      subtype: [examItem.subtype || ""],
      mediaUrl: [examItem.mediaUrl || ""],
      hint: [examItem.hint || ""],
      instructions: [examItem.instructions || ""],
      explanation: [examItem.explanation || ""],
      required: [examItem.required],
      showResults: [
        {
          value: examItem.showResults,
          disabled: disableShowResults,
        },
      ],
      shuffleChoices: [examItem.shuffleChoices],
      isGraded: [
        examItem.isGraded,
        // disabled:
        //   examItem.type === "ME" ||
        //   examItem.type === "FW" ||
        //   examItem.type === "TA",
      ],
      weight: [examItem.weight],
      neutralFeedbackMessage: [examItem.neutralFeedbackMessage || ""],
      neutralFeedbackImageUrl: [examItem.neutralFeedbackImageUrl || ""],
      correctFeedbackMessage: [examItem.correctFeedbackMessage || ""],
      correctFeedbackImageUrl: [examItem.correctFeedbackImageUrl || ""],
      incorrectFeedbackMessage: [examItem.incorrectFeedbackMessage || ""],
      incorrectFeedbackImageUrl: [examItem.incorrectFeedbackImageUrl || ""],
      additional: [additional],
      choices: this.constructExamChoicesFormArray(examChoices, examItem.type),
      choiceSetFeedbacks: [examChoiceSetFeedbacks || []],
    });

    if (
      examItem.type === "ME" ||
      examItem.type === "PO" ||
      examItem.type === "FW" ||
      examItem.type === "TA"
    ) {
      let criteriaList = await firstValueFrom(
        this.v1ExamsService.examsSectionsItemsCriteriaList(
          this.formGroup.value.itemUuid
        )
      );

      this.formGroup.addControl(
        "rubrics",
        this.constructExamRubricsFormArray(criteriaList)
      );
    }
    // Add choices in form control
    this.loading = false;
    this.isLoading.emit(this.loading);
    // this.cd.detectChanges();

  }

  constructExamChoicesFormArray(
    examChoices: V1ExamChoice[],
    examItemType: string
  ): UntypedFormArray {
    // If multiple choice
    if (
      examItemType == "MC" ||
      examItemType == "IC" ||
      examItemType == "CB" ||
      examItemType == "PO"
    ) {
      return this.formBuilder.array(
        examChoices?.map(
          (choice) => {
            return this.formBuilder.group({
              text: [choice.longInput || choice.shortInput],
              choiceUuid: [choice.uuid],
              imageUri: [choice.imageUrl],
              audioUri: [choice.audioUrl],
              isCorrect: [examItemType == "PO" ? true : choice.isCorrect],
              isDeleted: [false],
              feedbackImageUrl: [],
              feedbackText: [],
            });
          },
          // Add this if multiple choice and short answer
          minLengthArray(1)
        )
      );
    } else if (examItemType == "SA") {
      let controls: UntypedFormGroup[];
      if (examChoices.length > 0) {
        controls = examChoices?.map((choice) => {
          return this.formBuilder.group({
            text: [choice.longInput || choice.shortInput, Validators.required],
            choiceUuid: [choice.uuid],
            imageUri: [choice.imageUrl],
            audioUri: [choice.audioUrl],
            // Force correctness
            isCorrect: [true],
            isDeleted: [false],
          });
        });
      } else {
        controls = [
          this.formBuilder.group({
            text: [null, Validators.required],
            choiceUuid: [null],
            imageUri: [null],
            audioUri: [null],
            // Force correctness
            isCorrect: [true],
            isDeleted: [false],
          }),
        ];
      }

      return this.formBuilder.array(controls);
    } else {
      return this.formBuilder.array([]);
    }
  }

  constructExamRubricsFormArray(
    criteriaList: V1ExamItemCriteria[]
  ): UntypedFormArray {
    return this.formBuilder.array(
      criteriaList.map((criteria) => {
        return this.formBuilder.group({
          criteriaUuid: [criteria.uuid],
          name: [criteria.name],
          weight: [
            criteria.weight * 100,
            [Validators.min(0), Validators.max(100)],
          ],
          isDeleted: [false],
        });
      }),
      criteriaWeightSum()
    );
  }

  async updateItem() {
    return await firstValueFrom(
      this.v1ExamsService.examsSectionsItemsUpdate(
        this.formGroup.value.itemUuid,
        {
          ...this.formGroup.value,
        }
      )
    );
  }

  async createChoice(choice: V1ExamChoice) {
    await firstValueFrom(
      this.v1ExamsService.examsSectionsItemsChoicesCreate(
        this.formGroup.value.itemUuid,
        choice
      )
    );
  }

  async updateChoice(choice: V1ExamChoice) {
    await firstValueFrom(
      this.v1ExamsService.examsSectionsItemsChoicesUpdate(choice.uuid, choice)
    );
  }

  async deleteChoice(choice: V1ExamChoice) {
    await firstValueFrom(
      this.v1ExamsService.examsSectionsItemsChoicesDelete(choice.uuid)
    );
  }

  // Main mathod for updating the exam item
  async updateAll() {
    try {
      this.loading = true;
      this.isLoading.emit(this.loading);
      await this.updateChoices();
      await this.updateRubrics();
      await this.updateItem();
      this.loading = false;
    } catch (err) {
      throw err;
    } finally {
      let { item_uuid: itemUuid } = this.route.snapshot.queryParams;
      if (this.insideSlide) {
        let [examItem, examChoices] = await firstValueFrom(
          zip([
            this.v1ExamsService.examsSectionsItemsRead(
              itemUuid || this.examItemUuid
            ),
            this.v1ExamsService.examsSectionsItemsChoicesList(
              itemUuid || this.examItemUuid
            ),
          ])
        );
        this.item.emit(examItem);
        this.choices.emit(examChoices);
        this.isLoading.emit(this.loading);
        this.updated.emit(true);
      }
    }
  }

  async updateChoices() {
    // Get choices information from the activity-type-poll component
    let choicesArray = this.formGroup.get("choices") as UntypedFormArray;

    let updateAndCreateChoiceControls = choicesArray?.controls?.filter(
      (control) => !control.value.isDeleted
    );
    let deleteChoiceControls = choicesArray?.controls?.filter(
      (control) => control.value.isDeleted
    );

    // Handle choices
    if (updateAndCreateChoiceControls) {
      // For each choice form control
      for (let i = 0; i < updateAndCreateChoiceControls.length; i++) {
        let control = updateAndCreateChoiceControls[i];
        let index = i;
        let choiceValue = control.value;

        if (choiceValue.choiceUuid) {
          // If uuid exists, then the choice exists. Update the choice.
          await this.updateChoice({
            uuid: choiceValue.choiceUuid,
            longInput: choiceValue.text || "",
            shortInput: choiceValue.text || "",
            imageUrl: choiceValue.imageUri || "",
            audioUrl: choiceValue.audioUri || "",
            isCorrect: choiceValue.isCorrect,
            orderId: index,
          });
        } else {
          // If uuid dont exist, then the choice does not exist. Create the choice
          await this.createChoice({
            longInput: choiceValue.text || "",
            shortInput: choiceValue.text || "",
            imageUrl: choiceValue.imageUri || "",
            audioUrl: choiceValue.audioUri || "",
            isCorrect: choiceValue.isCorrect,
            orderId: index,
          });
        }
      }
    }

    if (deleteChoiceControls) {
      // Delete choices in the deleted form array
      await Promise.all(
        deleteChoiceControls.map(async (control) => {
          let choiceUuid = control.value.choiceUuid;

          await this.deleteChoice({ uuid: choiceUuid });
        })
      );
    }
  }

  async updateRubrics() {
    // Get choices information from the activity-type-poll component
    let rubricFormArray = this.formGroup.get("rubrics") as UntypedFormArray;

    let createAndUpdateRubicFormControls = rubricFormArray?.controls.filter(
      (control) => !control.value.isDeleted
    );
    let deleteRubicFormControls = rubricFormArray?.controls.filter(
      (control) => control.value.criteriaUuid && control.value.isDeleted
    );

    if (createAndUpdateRubicFormControls) {
      for (let i = 0; i < createAndUpdateRubicFormControls.length; i++) {
        let control = createAndUpdateRubicFormControls[i];
        let index = i;
        let rubricControl = control.value;

        if (rubricControl.criteriaUuid) {
          // If uuid exists, then the criteria exists. Update the criteria.

          firstValueFrom(
            this.v1ExamsService.examsSectionsItemsCriteriaUpdate(
              rubricControl.criteriaUuid,
              {
                name: rubricControl.name,
                orderId: index,
                weight: rubricControl.weight / 100,
              }
            )
          );
        } else {
          // If uuid dont exist, then the criteria does not exist. Create the criteria
          firstValueFrom(
            this.v1ExamsService.examsSectionsItemsCriteriaCreate(
              this.formGroup.value.itemUuid,
              {
                name: rubricControl.name,
                orderId: index,
                weight: rubricControl.weight / 100,
              }
            )
          );
        }
      }
    }

    if (deleteRubicFormControls) {
      // Delete rubric in the deleted form array
      await Promise.all(
        deleteRubicFormControls.map(async (control) =>
          firstValueFrom(
            this.v1ExamsService.examsSectionsItemsCriteriaDelete(
              control.value.criteriaUuid
            )
          )
        )
      );
    }
  }

  async onSave() {
    let {
      // Flag to determine if we publish the resource on next.
      // Used for freedom-wall and file-upload resource types
      publish_resource_on_next: publishResourceOnNext,
      resource_uuid: resourceUuid,
    } = this.route.snapshot.queryParams;

    await this.updateAll();

    if (publishResourceOnNext) {
      // this.router.navigate(["/resources", "publish"], {
      //   replaceUrl: true,
      //   queryParams: {
      //     resource_uuid: resourceUuid,
      //   },
      //   queryParamsHandling: "merge",
      // });
      this.cd.detectChanges();
    } else if (this.insideSlide) {
      this.cd.detectChanges();
    } else {
      this.location.back();
    }
  }

  async onSaveAndCreateAnother() {
    let { additional } = this.route.snapshot.queryParams;

    await this.updateAll();

    this.router.navigateByUrl(additional, {
      replaceUrl: true,
    });
  }
}
