<app-back-title-layout>
  <div class="my-5">
    <app-search-bar-query> </app-search-bar-query>
  </div>

  <button
    [routerLink]="['./select-new']"
    class="btn btn-primary w-full uppercase"
  >
    <app-icon icon="add"></app-icon>

    <span> Add monetized resource </span>
  </button>

  <div class="my-5">
    <app-item-list
      [itemTemplate]="itemTemplate"
      [source]="purchaseListObservable"
    ></app-item-list>
  </div>
</app-back-title-layout>

<ng-template #itemTemplate let-item="item">
  <a
    class="flex"
    [routerLink]="['/purchases', 'list', 'product-detail']"
    [queryParams]="{ product_id: item.productId }"
  >
    <app-icon
      icon="local_mall"
      class="text-gray-600 text-6xl mr-2 leading-none"
    ></app-icon>

    <div class="flex flex-col justify-between flex-grow">
      <div>
        <div class="truncate font-medium">
          {{ item.name }}
        </div>
      </div>

      <div class="text-primary font-bold text-sm">
        {{ item.description }} {{ item.price ? " - ₱" + item.price : "" }}
      </div>
    </div>
  </a>
</ng-template>
