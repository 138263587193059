import { Component, Input, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { HumanizeService } from "src/app/services/humanize.service";

export interface SubscriptionFormGroupFields {
  productId?: FormControl<string | null>;
  productTitle?: FormControl<string>;

  stockRecordId?: FormControl<string | null>;
  price?: FormControl<number | null>;

  subscriptionPlanUuid?: FormControl<string | null>;
  duration?: FormControl<string | null>;
  description?: FormControl<string | null>;
  isFreeTrialEnabled?: FormControl<boolean | null>;
  freeTrialDuration?: FormControl<string | null>;

  helixPayReferenceUuid?: FormControl<string | null>;
  helixPayReferenceReferenceId?: FormControl<string | null>;
}

@Component({
  selector: "app-purchases-subscription-forms",
  templateUrl: "./purchases-subscription-forms.component.html",
  styles: [],
})
export class PurchasesSubscriptionFormsComponent implements OnInit {
  durations = ["1 M", "3 M", "6 M", "1 Y"];

  @Input() subscriptionFormGroups: FormGroup<SubscriptionFormGroupFields>[];

  constructor(
    private formBuilder: FormBuilder,
    private humanizerService: HumanizeService
  ) {}

  ngOnInit(): void {}

  constructSubscriptionFormGroup() {
    return this.formBuilder.group<SubscriptionFormGroupFields>({
      productTitle: new FormControl("", Validators.required),

      price: new FormControl(null, Validators.required),

      duration: new FormControl(null, Validators.required),
      description: new FormControl(null),
      isFreeTrialEnabled: new FormControl(false),
      freeTrialDuration: new FormControl(null),

      helixPayReferenceUuid: new FormControl(null),
      helixPayReferenceReferenceId: new FormControl(null),
    });
  }

  addSubscription() {
    this.subscriptionFormGroups.push(this.constructSubscriptionFormGroup());
  }

  humanizer(str = "") {
    let raw = str.split(" ");

    return this.humanizerService.humanizePeriodUnit(raw[0], raw[1]);
  }
}
