import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";

@Component({
  selector: "app-policy",
  templateUrl: "./policy.component.html",
  styles: [],
})
export class PolicyComponent implements OnInit {
  @ViewChild("policy") policy: ElementRef;
  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    if (localStorage.getItem("policy") === "accepted") {
      console.log("accepted");
      this.policy.nativeElement.classList.toggle("hidden");
    }
  }
  acceptPolicy() {
    localStorage.setItem("policy", "accepted");
    this.policy.nativeElement.classList.toggle("translate-y-full");
  }
}
