import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { firstValueFrom } from "rxjs";
import { Group, PassService } from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-course-batch-permissions-add-group",
  templateUrl: "./course-batch-permissions-add-group.component.html",
})
export class CourseBatchPermissionsAddGroupComponent implements OnInit {
  groups: Group[];

  loading: boolean;

  constructor(
    private passService: PassService,
    private route: ActivatedRoute,
    private location: Location
  ) {}

  async ngOnInit() {
    let { schedule_uuid: scheduleUuid } = this.route.snapshot.queryParams;

    let result = await firstValueFrom(
      this.passService.listGroups(null, null, null, ["roles"])
    );
    this.groups = result.groups.filter(
      (group) =>
        !group.roles.some(
          (role) => role.name === `crud:schedules:${scheduleUuid}`
        )
    );
  }

  async selectGroup(group: Group) {
    let { schedule_uuid: scheduleUuid } = this.route.snapshot.queryParams;

    this.loading = true;

    try {
      await firstValueFrom(
        this.passService.createRole({
          role: {
            name: `crud:schedules:${scheduleUuid}`,
            groupIds: [group.id],
            permissionsAttributes: [
              {
                name: `read:schedules:${scheduleUuid}`,
                description: "Can read the batch",
              },
              {
                name: `update:schedules:${scheduleUuid}`,
                description: "Can update the batch",
              },
              {
                name: `delete:schedules:${scheduleUuid}`,
                description: "Can delete the batch",
              },
            ],
            applicationId: localStorage.getItem("application_id"),
          },
        })
      );
    } catch (err) {
      throw err;
    } finally {
      this.loading = false;
    }

    this.location.back();
  }
}
