<div
  class="w-full overflow-auto relative"
  [ngSwitch]="currentSlide?.spread?.spreadType"
>
  <ng-container *ngSwitchCase="'new'">
    <button
      type="button"
      (click)="openAddBlockModal()"
      class="btn w-full h-[627px] py-6 px-10 text-sm bg-gray-100 border border-gray-300"
    >
      <app-icon icon="add_circle" class="text-gray-300 text-9xl"></app-icon>
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="'slide'">
    <ng-container *ngIf="!currentSlide.spread.uri; else image">
      <ng-container *ngIf="previewMode">
        <div
          class="text-center w-full h-[627px] py-6 px-10 text-sm bg-primary-100 border border-gray-300 rounded"
        >
          <div class="mt-40">
            <app-icon
              [icon]="'image'"
              class="text-primary-300 text-9xl"
            ></app-icon>
            <div class="text-2xl text-primary-400 font-bold text-center">
              Nothing to see here
            </div>
            <div class="text-primary-400 text-center">
              Add an image through edit view
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="editMode">
        <button
          *ngIf="editMode"
          (click)="fileInput.click()"
          type="button"
          class="btn w-full h-[627px] py-6 px-10 text-sm bg-gray-100 border border-gray-300"
        >
          <app-icon [icon]="'image'" class="text-gray-300 text-9xl"></app-icon>
          <div class="text-2xl text-gray-400 font-bold">Upload Image</div>
        </button>
      </ng-container>
    </ng-container>
    <ng-template #image>
      <ng-container *ngIf="previewMode">
        <img
          [style.backgroundColor]="
            previewMode
              ? (currentSlide?.spread?.appearance?.base?.backgroundColor
                  | castColor) ||
                (generalAppearance?.base?.backgroundColor | castColor)
              : '#FFFFFF'
          "
          [style.color]="
            previewMode
              ? (currentSlide?.spread?.appearance?.base?.foregroundColor
                  | castColor) ||
                (generalAppearance?.base?.foregroundColor | castColor)
              : '#000000'
          "
          [src]="currentSlide.spread.uri"
          class="object-contain btn w-full h-[627px] py-6 px-10 text-sm bg-gray-100 border border-gray-300"
        />
      </ng-container>
      <ng-container *ngIf="editMode">
        <app-upload-loading-wrapper
          #editImage
          [file]="slideFile"
          accept="image/*"
          class=""
          [overlayTemplate]="overlayTemplate"
          text="Change Image"
          (upload)="onUpload($event)"
        >
          <img
            [src]="currentSlide.spread.uri"
            class="object-contain btn w-full h-[627px] py-6 px-10 text-sm bg-gray-100 border border-gray-300"
          />
        </app-upload-loading-wrapper>
        <div class="flex justify-end mt-4">
          <button
            class="btn bg-primary-600 text-white btn-primary"
            type="button"
            (click)="onSave()"
          >
            save
          </button>
        </div>
      </ng-container>
      <!-- <button
          *ngIf="editMode"
          (click)="editImage.click()"
          type="button"
          class="absolute top-0 right-0 rounded bg-gray-300 text-primary w-6 h-6 m-2"
        >
          <app-icon icon="edit"></app-icon>
        </button> -->
    </ng-template>
  </ng-container>
  <ng-container *ngSwitchCase="'link'">
    <ng-container *ngIf="!currentSlide.spread.uri; else link">
      <ng-container *ngIf="editMode">
        <app-burislides-block-link
          (link)="linkOut($event)"
        ></app-burislides-block-link>
      </ng-container>

      <ng-container *ngIf="previewMode">
        <div
          [style.backgroundColor]="
            previewMode
              ? (currentSlide?.spread?.appearance?.base?.backgroundColor
                  | castColor) ||
                (generalAppearance?.base?.backgroundColor | castColor)
              : '#FFFFFF'
          "
          [style.color]="
            previewMode
              ? (currentSlide?.spread?.appearance?.base?.foregroundColor
                  | castColor) ||
                (generalAppearance?.base?.foregroundColor | castColor)
              : '#000000'
          "
          class="text-center w-full h-[627px] py-6 px-10 text-sm bg-primary-100 border border-gray-300 rounded"
        >
          <div class="mt-40">
            <app-icon
              [icon]="'link'"
              class="text-primary-300 text-9xl"
            ></app-icon>
            <div class="text-2xl text-primary-400 font-bold text-center">
              Nothing to see here
            </div>
            <div class="text-primary-400 text-center">
              Add a link through edit view
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <ng-template #link>
      <!-- Edit Template of Link  -->
      <ng-container *ngIf="editMode">
        <app-burislides-block-link
          (link)="linkOut($event)"
          [inputLink]="currentSlide.spread.uri"
        ></app-burislides-block-link>
      </ng-container>
      <!-- Preview Template of Link  -->
      <ng-container *ngIf="previewMode">
        <div
          [style.backgroundColor]="
            previewMode
              ? (currentSlide?.spread?.appearance?.base?.backgroundColor
                  | castColor) ||
                (generalAppearance?.base?.backgroundColor | castColor)
              : '#FFFFFF'
          "
          [style.color]="
            previewMode
              ? (currentSlide?.spread?.appearance?.base?.foregroundColor
                  | castColor) ||
                (generalAppearance?.base?.foregroundColor | castColor)
              : '#000000'
          "
          class="w-full h-[627px] py-6 px-10 text-sm bg-gray-100 border border-gray-300 justify-center items-center flex flex-col"
        >
          <app-icon icon="link" class="text-gray-300 text-9xl"></app-icon>
          <a
            target="_blank"
            [href]="currentSlide.spread.uri"
            class="text-secondary-500 text-lg"
            ><app-icon icon="link"></app-icon> Go to external link</a
          >
        </div></ng-container
      >
    </ng-template>
  </ng-container>
  <ng-container *ngSwitchCase="'video'">
    <ng-container *ngIf="editMode; else videoPreviewMode">
      <!-- New Video Upload  -->
      <h1 class="text-center mb-10 font-bold text-2xl">Video</h1>
      <div class="mb-10 text-gray-400">Upload Video</div>

      <app-upload-loading-wrapper
        #editVideo
        [file]="slideFile"
        accept="video/*"
        class=""
        [overlayTemplate]="overlayTemplate"
        (upload)="onUpload($event)"
        [ngClass]="{ hidden: !currentSlide.spread.uri }"
      >
        <ng-container *ngIf="currentSlide.spread.uri">
          <video
            width="100%"
            height="288"
            controls
            [src]="currentSlide.spread.uri"
          >
            <!-- <source [src]="currentSlide.spread.uri" /> -->
            Your browser does not support the video tag.
          </video>

          <div class="flex text-primary-700">
            <app-icon icon="attachment"></app-icon>
            <div>{{ currentSlide.spread.uri }}</div>
          </div>
        </ng-container>
      </app-upload-loading-wrapper>

      <button
        *ngIf="!currentSlide.spread.uri"
        (click)="videoInput.click()"
        type="button"
        class="rounded flex uppercase p-2 text-sm border border-gray-300 text-gray-400 mb-10"
      >
        <app-icon
          [icon]="'play_arrow'"
          class="text-gray-300 text-sm"
        ></app-icon>
        <div class="text-sm text-gray-400">Add Video</div>
      </button>
      <button
        *ngIf="currentSlide.spread.uri"
        (click)="editVideo.click()"
        type="button"
        class="rounded flex uppercase p-2 text-sm border border-gray-300 text-gray-400 mb-10"
      >
        <app-icon
          [icon]="'play_arrow'"
          class="text-gray-300 text-sm"
        ></app-icon>
        <div class="text-sm text-gray-400">Add Video</div>
      </button>
      <div class="flex justify-end">
        <button
          class="btn bg-primary-600 text-white btn-primary"
          type="button"
          (click)="onSave()"
        >
          save
        </button>
      </div>

      <!-- Edit Video Upload  -->
    </ng-container>
    <ng-template #videoPreviewMode>
      <!-- New Video Upload Preview  -->
      <ng-container *ngIf="!currentSlide.spread.uri">
        <div
          class="text-center w-full h-[627px] py-6 px-10 text-sm bg-primary-100 border border-gray-300 rounded"
        >
          <div class="mt-40">
            <app-icon
              [icon]="newResource.icon"
              class="text-primary-300 text-9xl"
            ></app-icon>
            <div class="text-2xl text-primary-400 font-bold text-center">
              Nothing to see here
            </div>
            <div class="text-primary-400 text-center">
              Upload a Video through edit view
            </div>
          </div>
        </div>
      </ng-container>

      <!-- Edit Video Upload Preview  -->
      <ng-container *ngIf="currentSlide.spread.uri">
        <div
          [style.backgroundColor]="
            previewMode
              ? (currentSlide?.spread?.appearance?.base?.backgroundColor
                  | castColor) ||
                (generalAppearance?.base?.backgroundColor | castColor)
              : '#FFFFFF'
          "
          [style.color]="
            previewMode
              ? (currentSlide?.spread?.appearance?.base?.foregroundColor
                  | castColor) ||
                (generalAppearance?.base?.foregroundColor | castColor)
              : '#000000'
          "
        >
          <video width="100%" height="627" controls>
            <source [src]="currentSlide.spread.uri" />
            Your browser does not support the video tag.
          </video>
        </div>
      </ng-container>
    </ng-template>
  </ng-container>
  <ng-container *ngSwitchCase="'pdf'">
    <ng-template #pdfPreviewMode>
      <!-- New PDF Upload Preview  -->

      <ng-container *ngIf="!currentSlide.spread.uri">
        <div
          class="w-full text-center h-[627px] py-6 px-10 text-sm bg-primary-100 border border-gray-300 rounded"
        >
          <div class="mt-40">
            <app-icon
              [icon]="'picture_as_pdf'"
              class="text-primary-300 text-9xl text-center"
            ></app-icon>
            <div class="text-2xl text-primary-400 font-bold text-center">
              Nothing to see here
            </div>
            <div class="text-primary-400 text-center">
              Upload a PDF through edit view
            </div>
          </div>
        </div>
      </ng-container>

      <!-- Edit PDF Upload Preview  -->
      <ng-container *ngIf="currentSlide.spread.uri">
        <iframe
          [src]="currentSlide.spread.uri | safeResourceUrl"
          width="100%"
          height="627"
          type="application/pdf"
        ></iframe>
      </ng-container>
    </ng-template>
    <ng-container *ngIf="editMode; else pdfPreviewMode">
      <!-- New PDF Upload  -->
      <h1 class="text-center mb-10 font-bold text-2xl">PDF</h1>
      <div class="mb-10 text-gray-400">Upload PDF</div>
      <app-upload-loading-wrapper
        #editPdf
        [file]="slideFile"
        accept="application/pdf"
        class=""
        [overlayTemplate]="overlayTemplate"
        text="Change PDF"
        (upload)="onUpload($event)"
      >
        <ng-container *ngIf="currentSlide.spread.uri">
          <iframe
            [src]="currentSlide.spread.uri | safeResourceUrl"
            width="100%"
            height="288"
            type="application/pdf"
          ></iframe>
        </ng-container>
      </app-upload-loading-wrapper>
      <button
        *ngIf="!currentSlide.spread.uri"
        (click)="pdfInput.click()"
        type="button"
        class="mt-4 rounded flex uppercase p-2 text-sm border border-gray-300 text-gray-400 mb-10"
      >
        <app-icon
          [icon]="'picture_as_pdf'"
          class="text-gray-300 text-sm"
        ></app-icon>
        <div class="text-sm text-gray-400">Add PDF</div>
      </button>
      <button
        *ngIf="currentSlide.spread.uri"
        (click)="editPdf.click()"
        type="button"
        class="mt-4 rounded flex uppercase p-2 text-sm border border-gray-300 text-gray-400 mb-10"
      >
        <app-icon
          [icon]="'picture_as_pdf'"
          class="text-gray-300 text-sm"
        ></app-icon>
        <div class="text-sm text-gray-400">Add PDF</div>
      </button>
      <div class="flex justify-end">
        <button
          class="btn bg-primary-600 text-white btn-primary"
          type="button"
          (click)="onSave()"
        >
          save
        </button>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'download-link'">
    <!-- Preview Download Link  -->
    <ng-template #downloadPreviewMode>
      <!-- New Download Link Preview  -->
      <ng-container *ngIf="!currentSlide.spread.uri">
        <div
          class="w-full text-center h-[627px] py-6 px-10 text-sm bg-primary-100 border border-gray-300 rounded"
        >
          <div class="mt-40">
            <app-icon
              [icon]="newResource.icon"
              class="text-primary-300 text-9xl text-center"
            ></app-icon>
            <div class="text-2xl text-primary-400 font-bold text-center">
              Nothing to see here
            </div>
            <div class="text-primary-400 text-center">
              Add a Download link through edit view
            </div>
          </div>
        </div>
      </ng-container>

      <!-- Edit Download Link Preview  -->
      <ng-container *ngIf="currentSlide.spread.uri">
        <div
          [style.backgroundImage]="getMediaUrl()"
          [style.backgroundPosition]="'center'"
          [style.backgroundRepeat]="'no-repeat'"
          [style.backgroundSize]="'cover'"
          [style.backgroundColor]="
            previewMode
              ? (currentSlide?.spread?.appearance?.base?.backgroundColor
                  | castColor) ||
                (generalAppearance?.base?.backgroundColor | castColor)
              : '#FFFFFF'
          "
          [style.color]="
            previewMode
              ? (currentSlide?.spread?.appearance?.base?.foregroundColor
                  | castColor) ||
                (generalAppearance?.base?.foregroundColor | castColor)
              : '#000000'
          "
          class="w-full flex flex-col justify-center items-center text-center h-[627px] py-6 px-10 text-sm bg-primary-100 border border-gray-300 rounded"
        >
          <!-- <img
            [src]="currentSlide.spread.imageUri"
            alt="download link image"
            class="h-[208px] w-full mb-10 object-contain"
          /> -->
          <div class="mb-10">
            <div class="font-bold text-gray-700 text-2xl">
              {{ currentSlide.spread.title }}
            </div>

            <div class="text-gray-700">{{ currentSlide.spread.text }}</div>
          </div>
          <button class="btn uppercase text-white bg-primary-600 rounded-full">
            Download Item
          </button>
        </div>
      </ng-container>
    </ng-template>
    <!-- New/Edit Download Link  -->
    <ng-container *ngIf="editMode; else downloadPreviewMode">
      <h1 class="text-center mb-10 font-bold text-2xl">Download Link</h1>
      <div class="text-gray-400">Supporting Image</div>
      <div class="mb-10 text-gray-400 text-sm">
        Accepts .jpeg, .png, and .gif
      </div>

      <img
        [src]="currentSlide.spread.imageUri"
        class="h-[288px] object-contain"
        [ngClass]="{ hidden: !currentSlide.spread.imageUri }"
      />

      <button
        *ngIf="!currentSlide.spread.uri"
        (click)="editImageDL.click()"
        type="button"
        class="rounded flex uppercase p-2 text-sm border border-gray-300 text-gray-400 mb-10 mt-4"
      >
        <app-icon [icon]="'image'" class="text-gray-300 text-sm"></app-icon>
        <div class="text-sm text-gray-400">Add Image</div>
      </button>
      <button
        *ngIf="currentSlide.spread.uri"
        (click)="editImageDL.click()"
        type="button"
        class="rounded flex uppercase p-2 text-sm border border-gray-300 text-gray-400 mb-10"
      >
        <app-icon [icon]="'download'" class="text-gray-300 text-sm"></app-icon>
        <div class="text-sm text-gray-400">Add Image</div>
      </button>

      <!-- Input for title and description here  -->
      <label class="form-label">
        Download Link Title
        <input
          class="form-control mb-4"
          placeholder="Input download link title here"
          [value]="currentSlide.spread.title"
          [ngModel]="currentSlide.spread.title"
          (ngModelChange)="downloadTitleOut($event)"
        />
      </label>

      <label class="form-label">
        Additional Notes
        <input
          class="form-control mb-10"
          placeholder="Input download link description here"
          [value]="currentSlide.spread.text"
          [ngModel]="currentSlide.spread.text"
          (ngModelChange)="downloadTextOut($event)"
        />
      </label>

      <!-- Input for download link here  -->
      <label class="form-label">
        Paste Download Link*
        <input
          type="text"
          class="form-control"
          placeholder="Link"
          [value]="currentSlide.spread.uri"
          [ngModel]="currentSlide.spread.uri"
          (ngModelChange)="downloadLinkOut($event)"
        />
      </label>
      <div class="flex justify-end">
        <button
          class="btn bg-primary-600 text-white btn-primary"
          type="button"
          (click)="onSave()"
        >
          save
        </button>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'html'">
    <div class="w-full relative py-6 px-10">
      <app-loading position="absolute" padding="py-32" [loading]="loadingInput">
        <ng-container *ngIf="editMode">
          <app-burislides-block-html
            [initialValue]="currentSlide.spread.text"
            (html)="markdownOut($event)"
          >
          </app-burislides-block-html>
        </ng-container>
      </app-loading>
      <ng-container *ngIf="previewMode">
        <ng-container *ngIf="!currentSlide.spread.text">
          <div
            class="w-full text-center h-[627px] py-6 px-10 text-sm bg-primary-100 border border-gray-300 rounded"
          >
            <div class="mt-40">
              <app-icon
                [icon]="'text_fields'"
                class="text-primary-300 text-9xl text-center"
              ></app-icon>
              <div class="text-2xl text-primary-400 font-bold text-center">
                Nothing to see here
              </div>
              <div class="text-primary-400 text-center">
                Type content through edit view
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="currentSlide.spread.text">
          <div
            [style.backgroundColor]="
              previewMode
                ? (currentSlide?.spread?.appearance?.base?.backgroundColor
                    | castColor) ||
                  (generalAppearance?.base?.backgroundColor | castColor)
                : '#FFFFFF'
            "
            [style.color]="
              previewMode
                ? (currentSlide?.spread?.appearance?.base?.foregroundColor
                    | castColor) ||
                  (generalAppearance?.base?.foregroundColor | castColor)
                : '#000000'
            "
            [innerHTML]="currentSlide.spread.text | safeHtml"
            class="w-full h-[627px] py-6 px-10 border rounded overflow-auto"
          ></div>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'markdown'">
    <div class="w-full relative py-6 px-10">
      <app-loading position="absolute" padding="py-32" [loading]="loadingInput">
        <ng-container *ngIf="editMode">
          <app-burislides-block-markdown
            [initialValue]="currentSlide.spread.text"
            (text)="markdownOut($event)"
          ></app-burislides-block-markdown
        ></ng-container>
      </app-loading>
      <ng-container *ngIf="previewMode">
        <ng-container *ngIf="!currentSlide.spread.text">
          <div
            class="w-full text-center h-[627px] py-6 px-10 text-sm bg-primary-100 border border-gray-300 rounded"
          >
            <div class="mt-40">
              <app-icon
                [icon]="'text_fields'"
                class="text-primary-300 text-9xl text-center"
              ></app-icon>
              <div class="text-2xl text-primary-400 font-bold text-center">
                Nothing to see here
              </div>
              <div class="text-primary-400 text-center">
                Type content through edit view
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="currentSlide.spread.text">
          <app-markdown-viewer
            [value]="currentSlide.spread.text"
            [backgroundColor]="
              previewMode
                ? (currentSlide?.spread?.appearance?.base?.backgroundColor
                    | castColor) ||
                  (generalAppearance?.base?.backgroundColor | castColor)
                : '#FFFFFF'
            "
            [foregroundColor]="
              previewMode
                ? (currentSlide?.spread?.appearance?.base?.foregroundColor
                    | castColor) ||
                  (generalAppearance?.base?.foregroundColor | castColor)
                : '#000000'
            "
            styles="h-[627px] overflow-y-scroll border rounded p-2"
          >
          </app-markdown-viewer>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'carousel'">
    <ng-container *ngIf="editMode; else carousel">
      <div class="w-full h-[627px] relative">
        <app-loading position="absolute" padding="py-32" [loading]="loading">
          <h1 class="text-center mb-10 font-bold text-2xl">Carousel</h1>

          <div class="mb-2" *ngIf="currentSlide.spread.uris">
            <div class="text-bold mb-2">View Mode:</div>
            <div class="text-xs italic">*default mode is carousel mode</div>
            <mat-slide-toggle
              class="text-primary-500"
              (change)="changeViewMode($event)"
              [checked]="
                currentSlide.spread.viewMode === 'scroll' ? true : false
              "
            ></mat-slide-toggle>
            <span class="text-xs">Scroll Mode</span>
          </div>

          <button
            (click)="slideInput.click()"
            type="button"
            class="btn w-full flex bg-primary-100 mb-10 text-center items-center justify-center"
          >
            <app-icon
              [icon]="'add_circle_outline'"
              class="text-primary-700 text-base text-center"
            ></app-icon>
            <div
              class="text-base text-primary-700 font-bold text-center uppercase"
            >
              Add Slides
            </div>
          </button>

          <ng-container *ngIf="currentSlide.spread.uris">
            <!-- Show Images here  -->
            <div
              appDnd
              (fileDropped)="processSlideImages($event)"
              cdkDropList
              (cdkDropListDropped)="imageDrop($event)"
              class="relative mb-4"
            >
              <ng-container
                *ngFor="
                  let spreadUri of currentSlide.spread.uris;
                  let i = index
                "
              >
                <div cdkDrag>
                  <div class="flex justify-center mb-10">
                    <div>
                      <button class="p-3 text-gray-600" cdkDragHandle>
                        <app-icon icon="unfold_more"></app-icon>
                      </button>
                    </div>
                    <img
                      *ngIf="spreadUri"
                      [src]="spreadUri"
                      class="w-[771px] h-[434px] object-contain bg-gray-100 border border-gray-300 rounded"
                    />
                    <div class="m-2">
                      <app-dropdown-layout position="left" width="w-fit">
                        <div dropdownToggle>
                          <app-icon icon="more_vert"></app-icon>
                        </div>
                        <div dropdownContent>
                          <button
                            class="w-full py-1 px-2 text-left text-primary hover:bg-primary hover:text-white"
                            (click)="deleteSlideImage(i)"
                          >
                            Delete
                          </button>
                        </div>
                      </app-dropdown-layout>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>
          <div class="flex justify-end sticky bottom-0 right-0">
            <button
              class="btn btn-primary bg-primary-600 text-white"
              type="button"
              (click)="onSave()"
            >
              save
            </button>
          </div>
        </app-loading>
      </div>
    </ng-container>
    <ng-template #carousel>
      <div class="flex w-full overflow-auto flex-row">
        <ng-container
          *ngIf="
            !currentSlide.spread.uris || currentSlide.spread.uris.length === 0
          "
        >
          <div
            [style.backgroundColor]="
              previewMode
                ? (currentSlide?.spread?.appearance?.base?.backgroundColor
                    | castColor) ||
                  (generalAppearance?.base?.backgroundColor | castColor)
                : '#FFFFFF'
            "
            [style.color]="
              previewMode
                ? (currentSlide?.spread?.appearance?.base?.foregroundColor
                    | castColor) ||
                  (generalAppearance?.base?.foregroundColor | castColor)
                : '#000000'
            "
            class="w-full text-center h-[627px] bg-primary-100 border border-gray-300 rounded"
          >
            <div class="mt-40">
              <app-icon
                [icon]="'note'"
                class="text-primary-300 text-9xl text-center"
              ></app-icon>
              <div class="text-2xl text-primary-400 font-bold text-center">
                Nothing to see here
              </div>
              <div class="text-primary-400 text-center">
                Type content through edit view
              </div>
            </div>
          </div>
        </ng-container>

        <!-- Carousel Preview  -->
        <ng-container
          *ngIf="
            currentSlide.spread.uris && currentSlide.spread.uris.length > 0
          "
        >
          <!-- Scroll Mode  -->
          <ng-container
            *ngIf="currentSlide.spread.viewMode === 'scroll'; else carouselMode"
          >
            <div
              class="w-full h-full flex flex-col justify-center max-w-full relative mx-auto"
            >
              <ng-container *ngFor="let imageUri of currentSlide.spread.uris">
                <img
                  *ngIf="imageUri"
                  [src]="imageUri"
                  class="w-[771px] h-[434px] object-contain bg-gray-100 border border-gray-300 rounded mb-2"
                />
              </ng-container>
            </div>
          </ng-container>
          <!-- Carousel Mode -->
          <ng-template #carouselMode>
            <div
              class="w-full h-full flex flex-col justify-center max-w-full relative overflow-x-hidden mx-auto"
            >
              <div class="flex w-full relative">
                <button
                  (click)="setSlideIndex('back')"
                  class="text-2xl md:text-4xl lg:text-5xl transition-all absolute left-0 my-auto top-0 bottom-0 z-30 {{
                    slidesIndex <= 0
                      ? 'hidden'
                      : 'rounded-full bg-white h-8 w-8 md:h-10 md:w-10 lg:h-12 lg:w-12 mr-4 opacity-50 hover:opacity-100'
                  }}"
                >
                  <app-icon [icon]="'chevron_left'"></app-icon>
                </button>
                <div
                  class="flex transition-transform duration-700 ease-in-out h-[80vh] mx-auto"
                  [style.transform]="'translateX(' + -100 * slidesIndex + '%)'"
                >
                  <ng-container
                    *ngFor="
                      let imageUri of currentSlide.spread.uris;
                      let i = index
                    "
                  >
                    <div
                      class="bg-gray-100 h-[90%] min-w-[90%] max-w-[90%] mx-[5%] my-auto rounded flex relative border-2 border-black transition-all duration-500 drop-shadow-lg"
                    >
                      <img [src]="imageUri || ''" class="mx-auto" />
                    </div>
                  </ng-container>
                </div>
                <button
                  (click)="setSlideIndex('next')"
                  class="text-2xl md:text-4xl lg:text-5xl transition-all absolute right-0 my-auto top-0 bottom-0 z-30 {{
                    slidesIndex === (currentSlide.spread.uris.length || 1) - 1
                      ? 'hidden'
                      : 'rounded-full bg-white h-8 w-8 md:h-10 md:w-10 lg:h-12 lg:w-12 mr-4 opacity-50 hover:opacity-100'
                  }}"
                >
                  <app-icon [icon]="'chevron_right'"></app-icon>
                </button>
              </div>

              <div class="flex mt-2 px-2">
                <progress
                  class="w-full h-2"
                  [value]="slidesIndex + 1"
                  [max]="currentSlide.spread.uris.length || 1"
                ></progress>
              </div>
            </div>
          </ng-template>
        </ng-container>

        <!-- <ng-container *ngFor="let imageUri of currentSlide.spread.uris">
          <img
            *ngIf="imageUri"
            [src]="imageUri"
            class="object-contain w-full h-[627px] bg-gray-100 border border-gray-300 mr-4"
          />
        </ng-container> -->
      </div>
    </ng-template>
  </ng-container>
  <ng-container *ngSwitchCase="'activity'">
    <ng-container *ngIf="!currentSlide.spread.examItemUuid; else activity">
      <div
        [style.backgroundColor]="
          previewMode
            ? (currentSlide?.spread?.appearance?.base?.backgroundColor
                | castColor) ||
              (generalAppearance?.base?.backgroundColor | castColor)
            : '#FFFFFF'
        "
        [style.color]="
          previewMode
            ? (currentSlide?.spread?.appearance?.base?.foregroundColor
                | castColor) ||
              (generalAppearance?.base?.foregroundColor | castColor)
            : '#000000'
        "
        class="rounded"
      >
        <app-burislides-block-activity
          [sectionUuid]="sectionUuid"
          [newItem]="true"
          e
          (_itemUuid)="itemCreateOut($event)"
          [editMode]="editMode"
          [itemType]="currentSlide.itemType"
          [itemSubtype]="currentSlide.itemSubtype"
          [previewMode]="previewMode"
          (isLoading)="outputIsLoading($event)"
          [feedback]="feedback"
        ></app-burislides-block-activity>
      </div>
    </ng-container>
    <ng-template #activity>
      <div
        [style.backgroundColor]="
          previewMode
            ? (currentSlide?.spread?.appearance?.base?.backgroundColor
                | castColor) ||
              (generalAppearance?.base?.backgroundColor | castColor)
            : '#FFFFFF'
        "
        [style.color]="
          previewMode
            ? (currentSlide?.spread?.appearance?.base?.foregroundColor
                | castColor) ||
              (generalAppearance?.base?.foregroundColor | castColor)
            : '#000000'
        "
        class="rounded"
      >
        <app-burislides-block-activity
          [item]="examItem"
          [choices]="examChoices"
          [itemUuid]="currentSlide.spread.examItemUuid"
          [itemType]="currentSlide.itemType"
          [editMode]="editMode"
          [previewMode]="previewMode"
          (currentItem)="activityItem($event)"
          (isLoading)="outputIsLoading($event)"
          (updated)="onSave()"
          [feedback]="feedback"
          [navigated]="navigated"
        ></app-burislides-block-activity>
      </div>
    </ng-template>
  </ng-container>
  <ng-container *ngSwitchCase="'image-spread'">
    <ng-container *ngIf="editMode">
      <app-buribooks-image-spread-edit
        class="drag-outline flex-grow"
        (upload)="onSave()"
        [spread]="currentSlide.spread"
        [leftPageNumber]="currentSlide.leftPageNumber"
        [rightPageNumber]="currentSlide.rightPageNumber"
        [leftFile]="currentSlide.leftFile"
        [rightFile]="currentSlide.rightFile"
      ></app-buribooks-image-spread-edit>
    </ng-container>
    <ng-container *ngIf="previewMode">
      <div
        class="relative flex justify-center w-full h-[627px] bg-gray-200 rounded-lg"
      >
        <ng-container *ngIf="currentSlide.spread.leftUri">
          <img
            [src]="currentSlide.spread.leftUri"
            class="h-full w-fit max-w-[50%]"
        /></ng-container>
        <ng-container *ngIf="currentSlide.spread.rightUri">
          <img
            [src]="currentSlide.spread.rightUri"
            class="h-full w-fit max-w-[50%]"
          />
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'activity-group'">
    <div
      [style.backgroundColor]="
        previewMode
          ? (currentSlide?.spread?.appearance?.base?.backgroundColor
              | castColor) ||
            (generalAppearance?.base?.backgroundColor | castColor)
          : '#FFFFFF'
      "
      [style.color]="
        previewMode
          ? (currentSlide?.spread?.appearance?.base?.foregroundColor
              | castColor) ||
            (generalAppearance?.base?.foregroundColor | castColor)
          : '#000000'
      "
    >
      <ng-container *ngIf="!previewMode">
        <h1 class="text-center mb-10 font-bold text-2xl">Group of Questions</h1>
      </ng-container>

      <div class="h-100 overflow-y-scroll">
        <app-burislides-block-activity-group
          [examItemRequiredFlags]="currentSlide.spread.examItemRequiredFlags"
          [examChoiceTriggerFlags]="currentSlide.spread.examChoiceTriggerFlags"
          [isResultPageShown]="currentSlide.spread.isResultPageShown"
          [resultPageImageUri]="currentSlide.spread.resultPageImageUri"
          [resultPageText]="currentSlide.spread.resultPageText"
          [examSectionUuid]="currentSlide.spread.examSectionUuid"
          [editMode]="editMode"
          (updated)="activityGroupOut($event); onSave()"
          [previewMode]="previewMode"
          (isLoading)="outputIsLoading($event)"
        ></app-burislides-block-activity-group>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="previewMode">
    <div class="w-full flex justify-between mt-4">
      <button
        (click)="setFeedback()"
        class="btn rounded-full bg-primary-600 text-white"
      >
        <app-icon icon="arrow_back"></app-icon>
      </button>
      <button
        (click)="getFeedback()"
        class="btn rounded-full bg-primary-600 text-white"
        [disabled]="
          currentSlide?.spread?.spreadType === 'carousel' &&
          slidesIndex !== currentSlide?.spread?.uris?.length - 1
        "
      >
        continue
      </button>
    </div>
  </ng-container>
</div>
<!-- For Multiple Images -->
<input
  type="file"
  #fileInput
  multiple
  class="hidden"
  accept="image/*"
  (change)="processFiles($event.target.files)"
/>

<!-- For Slides  -->
<input
  type="file"
  #slideInput
  multiple
  class="hidden"
  accept="image/*"
  (change)="processSlideImages($event.target.files)"
/>
<!-- For PDF -->
<input
  type="file"
  #pdfInput
  class="hidden"
  accept="application/pdf"
  (change)="processFiles($event.target.files)"
/>
<!-- For Video -->
<input
  type="file"
  #videoInput
  class="hidden"
  accept="video/*"
  (change)="processFiles($event.target.files)"
/>
<!-- For Editing PDF-->
<!-- <app-upload-loading-wrapper
  #editPdf
  [file]="slideFile"
  accept="application/pdf"
  class="hidden"
  text="Change PDF"
  (upload)="onUpload($event)"
>
</app-upload-loading-wrapper> -->
<!-- For Editing Video-->
<!-- <app-upload-loading-wrapper
  #editVideo
  [file]="slideFile"
  accept="video/*"
  class="hidden"
  text="Change Video"
  (upload)="onUpload($event)"
>
</app-upload-loading-wrapper> -->
<!-- For Editing Image-->

<app-upload-loading-wrapper
  #editImageDL
  [file]="slideFile"
  accept="image/*"
  class="hidden"
  text="Change Image"
  (upload)="onUploadDL($event)"
>
</app-upload-loading-wrapper>
<ng-template #overlayTemplate><div></div></ng-template>
