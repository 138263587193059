import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Subject } from "rxjs";
import { fadeInOut } from "src/app/app-common-module/animations/fade";
import { DashboardService } from "src/app/services/dashboard.service";
import { Block, CastsService } from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-casteditor-block-carousel",
  templateUrl: "./casteditor-block-carousel.component.html",
  styleUrls: ["./casteditor-block-carousel.component.scss"],
  animations: [fadeInOut],
})
export class CasteditorBlockCarouselComponent implements OnInit, OnDestroy {
  constructor(
    private castService: CastsService,
    private dashboardService: DashboardService
  ) {}
  @ViewChild("uploader") fileInput: ElementRef;

  isInEditMode = false;

  clicked: boolean = false;
  @Input() block: Block;
  @Input() disableEdit: boolean;
  data: any;
  subscribing: boolean;
  sub: any;
  observeClick = new Subject();
  @Output() deleteBlock: EventEmitter<any> = new EventEmitter();

  castId: string;

  async ngOnInit() {
    let cast = await this.dashboardService.getCast().toPromise();

    this.castId = cast.data.id;
    this.block.properties.items = this.block.properties.items || [
      {
        //previous
        image: null,

        //v0.1
        src: null,

        // Nasa docs so sinama ko na
        url: null,
      },
    ];
  }

  onDeleteBlock() {
    this.deleteBlock.emit();
    this.observeClick.next(null);
  }

  onUpload(event) {
    this.block.properties.items = [
      {
        //previous
        image: event.uri,

        //v0.1
        src: event.uri,

        // Nasa docs so sinama ko na
        url: event.uri,
      },
    ];

    this.castService
      .blockUpdate(this.castId, this.block.screenId + "", this.block.id + "", {
        block: this.block,
      })
      .subscribe();
  }

  onEditToggle(value) {
    this.isInEditMode = value;
  }

  onSaveBlock() {
    this.observeClick.next(null);
  }
  ngOnDestroy(): void {
    this.observeClick.next(null);
    this.observeClick.complete();
  }
  //Rollback for canva
  /*
  setEvent(event: string) {
    if (event === "local") {
      this.clicked = !this.clicked;
    }
    if (event === "canva_img") {
      if (this.subscribing) {
        this.sub.unsubscribe();
        this.subscribing = false;
      }
      let type: string;
      const click = fromEvent(document.querySelector("#toggle"), "click");
      window.open(`https://canva.com`, `_blank`);
      const oneSecInterval = interval(1000);
      const getImg = oneSecInterval.pipe(
        mergeMap(() => {
          return this.downloadService.getData(environment.CANVA_API);
        }),
        filter((data) => {
          return Object.keys(data).length !== 0;
        }),
        mergeMap((data) => {
          this.data = data;
          type = this.data.links[0].type.toLowerCase();
          return this.downloadService.downloadImage(this.data.links[0].url);
        }),
        map((data) => {
          return new Blob([data], {
            type: type,
          });
        }),
        mergeMap((blob) => {
          return this.uploadsService.uploadFile(
            blob,
            undefined,
            "response",
            true
          );
        }),
        mergeMap((res) => {
          const url = res.body.uri;
          this.block.properties.items = [
            {
              image: url,

              src: url,

              url: url,
            },
          ];

          return this.castService.blockUpdate(
            this.castId,
            this.block.screenId,
            this.block.id,
            {
              block: this.block,
            }
          );
        }),
        takeUntil(this.observeClick)
      );
      this.sub = getImg.subscribe(() => {
        this.subscribing = true;
        this.sub.unsubscribe();
        this.data = null;
      });
    }
  }*/
}
