import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../app-common-module/guards/auth.guard";
import { CreateEmailComponent } from "./create-email/create-email.component";
import { EmailEditComponent } from "./email-edit/email-edit.component";
import { EmailReportComponent } from "./email-report/email-report.component";
import { MailerCreateTemplateComponent } from "./mailer-create-template/mailer-create-template.component";
import { MailerEditTemplateComponent } from "./mailer-edit-template/mailer-edit-template.component";
import { MailerTemplatesComponent } from "./mailer-templates/mailer-templates.component";
import { MailerComponent } from "./mailer.component";
import { MailerHistoryComponent } from "./main-screen/mailer-history/mailer-history.component";
import { MainScreenComponent } from "./main-screen/main-screen.component";

const routes: Routes = [
  {
    path: "_mailer",
    component: MailerComponent,
    canActivate: [AuthGuard],
    data: { layoutTitle: "Mailer", hideBackTitleDiagonalDesign: false },
    children: [
      {
        path: "",
        component: MainScreenComponent,
        children: [{ path: "", component: MailerHistoryComponent }],
      },
      // {
      //   path: "system-email",

      //   children: [
      //     {
      //       path: "",
      //       component: SystemEmailsComponent,
      //       data: { layoutTitle: "System Emails" },
      //     },
      //     {
      //       path: "edit",
      //       component: SystemEmailEditComponent,
      //       data: { layoutTitle: "System Email" },
      //     },
      //   ],
      // },
      {
        path: "templates",
        data: { layoutTitle: "Templates", hideBackTitleDiagonalDesign: true },
        children: [
          {
            path: "",
            component: MailerTemplatesComponent,
          },
          {
            path: "create",
            component: MailerCreateTemplateComponent,
          },
          {
            path: "edit",
            component: MailerEditTemplateComponent,
            data: {
              layoutTitle: "Edit Template",
              hideBackTitleDiagonalDesign: true,
              preserveQueryParamFields: ["template_uuid"],
            },
          },
        ],
      },
      {
        path: "create",
        component: CreateEmailComponent,
        data: {
          layoutTitle: "Create Email",
          hideBackTitleDiagonalDesign: true,
        },
      },
      {
        path: "email",
        children: [
          {
            path: "edit",
            component: EmailEditComponent,
            data: {
              layoutTitle: "Edit Email",
              hideBackTitleDiagonalDesign: true,
              preserveQueryParamFields: ["mail_uuid", "status"],
            },
          },
          {
            path: "report",
            component: EmailReportComponent,
            data: {
              layoutTitle: "Email Report",
              hideBackTitleDiagonalDesign: true,
              preserveQueryParamFields: ["mail_uuid", "status"],
            },
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MailerRoutingModule {}
