<app-back-title-layout layoutTitle="Edit Collection" [loading]="!formGroup">
  <ng-container *ngIf="formGroup">
    <form [formGroup]="formGroup" (submit)="updateCollection()">
      <div class="my-5">
        <label>
          <span class="form-label"> Collection Name </span>
          <input formControlName="name" class="form-control" />

          <app-form-control-error-layout
            [control]="formGroup.get('name')"
          ></app-form-control-error-layout>
        </label>
      </div>

      <div class="my-5 cursor-pointer">
        <label>
          <span class="form-label"> Collection Thumbnail </span>

          <div #thumbnailInput class="bg-gray-300 w-32 h-32">
            <app-upload-loading-wrapper
              accept="image/*"
              (upload)="onUpload($event)"
            >
              <img
                [src]="formGroup.value.coverUri"
                alt=""
                class="w-full h-full object-contain"
              />
            </app-upload-loading-wrapper>
          </div>

          <div>
            <button
              type="button"
              (click)="thumbnailInput.click()"
              class="w-32 bg-blue-500 text-white mt-2 rounded"
            >
              CHANGE
            </button>
            <!-- <span>Recomended size</span> -->
          </div>
        </label>
      </div>

      <!-- <label class="my-5">
        <span class='form-label'>
          Description

        </span>

        <textarea
          formControlName="description"
          class="form-control"
          cols="30"
          rows="10"
        ></textarea>
      </label> -->

      <div class="my-5">
        <button
          [disabled]="!formGroup.valid"
          type="submit"
          class="btn btn-primary w-full"
        >
          Update
        </button>
      </div>
    </form>

    <div class="my-10">
      <button (click)="deleteCollection()" class="btn btn-danger w-full">
        Delete
      </button>
    </div>
  </ng-container>
</app-back-title-layout>
