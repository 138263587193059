<app-back-title-layout [loading]="!formGroup || loading">
  <div class="form-width">
    <input
      type="file"
      #fileInput
      (change)="fileChange($event)"
      class="hidden"
      accept=".csv"
    />
    <ng-container *ngIf="resource && section && formGroup">
      <form [formGroup]="formGroup">
        <!-- <div class="my-5 flex justify-between items-center">
        <div class="flex flex-col">
          <div class="form-label leading-none">Attempt Limit</div>
          <div class="text-sm text-gray-600">Set number of retakes allowed</div>
        </div>

        <input
          type="number"
          min="0"
          formControlName="maxAllowedSubmissions"
          class="w-24 text-right border rounded p-2"
        />
      </div> -->

        <div class="my-5">
          <label
            class="flex"
            [attr.data-tooltip]="!canUpdate ? 'Not allowed to update' : ''"
          >
            <app-checkbox
              class="text-primary text-2xl leading-none mr-2"
              formControlName="shuffleItems"
            >
            </app-checkbox>

            <div class="mt-1">
              <div class="form-label leading-none">Shuffle Exam Order</div>
              <div class="text-sm text-gray-600">
                Options order will shuffle everytime question is opened
              </div>
            </div>
          </label>
        </div>

        <div class="my-5 flex justify-between items-center">
          <div class="flex flex-col">
            <div class="form-label leading-none">Item Limit</div>
            <div class="text-sm text-gray-600">
              Reveals
              {{
                formGroup.value.itemLimit
                  ? formGroup.value.itemLimit +
                    " out of " +
                    items?.length +
                    " questions."
                  : "all questions"
              }}
            </div>
          </div>

          <input
            type="number"
            class="w-24 text-right border rounded p-2"
            formControlName="itemLimit"
          />
        </div>

        <div class="my-5 flex justify-between items-center">
          <div class="flex flex-col">
            <div class="form-label leading-none">Time Limit</div>
            <div class="text-sm text-gray-600">
              Set a time limit for assessment (in seconds)
            </div>
          </div>

          <input
            type="number"
            min="0"
            formControlName="timeLimit"
            class="w-24 text-right border rounded p-2"
          />
        </div>
      </form>

      <hr class="my-10" />

      <button class="text-primary-600 uppercase" (click)="fileInput.click()">
        <app-icon icon="upload_file" class="mr-1"></app-icon>upload questions
        from csv
      </button>
      <button
        [ngClass]="{ invisible: !canUpdate }"
        class="bg-blue-200 text-blue-600 rounded font-bold w-full my-4"
        (click)="onAddActivityClick(0)"
      >
        <app-icon icon="add_circle"></app-icon>

        <span>ADD QUESTION</span>
      </button>

      <div
        cdkDropList
        (cdkDropListDropped)="drop($event)"
        [cdkDropListDisabled]="!canUpdate"
      >
        <ng-container *ngFor="let item of items; let i = index">
          <div class="flex items-start m-3" cdkDrag>
            <button
              class="p-3"
              cdkDragHandle
              [ngClass]="{ invisible: !canUpdate }"
            >
              <app-icon icon="unfold_more" class="text-gray-600"></app-icon>
            </button>

            <app-activity-spread-view
              [item]="item"
              [index]="i + 1"
              (pencil)="editActivitySpread(item)"
              class="drag-outline"
            >
            </app-activity-spread-view>

            <button
              class="btn btn-icon drag-hide m-2 bg-red-600 text-white rounded"
              (click)="deleteActivitySpread(i, item)"
              [ngClass]="{ invisible: !canUpdate }"
            >
              <app-icon icon="delete"></app-icon>
            </button>
          </div>

          <button
            [ngClass]="{ invisible: !canUpdate }"
            class="bg-blue-200 text-blue-600 rounded font-bold w-full my-4"
            (click)="onAddActivityClick(i + 1)"
          >
            <app-icon icon="add_circle"></app-icon>

            <span>ADD QUESTION</span>
          </button>
        </ng-container>
      </div>

      <!-- <button
          [ngClass]="{ invisible: !canUpdate }"
          class="bg-blue-200 text-blue-600 rounded font-bold w-full my-4"
          (click)="onAddActivityClick(items.length)"
        >
          <app-icon icon="add_circle"></app-icon>

          <span>ADD QUESTION</span>
        </button>  -->

      <div
        *ngIf="items?.length == 0"
        class="mx-auto text-xs text-red-700 bg-red-200 p-1 my-2 rounded"
      >
        *Activity Required
      </div>

      <div class="sticky bottom-0 z-20" *ngIf="publishResourceOnNext">
        <a
          class="block btn btn-primary w-full my-5"
          queryParamsHandling="merge"
          [routerLink]="['/resources', 'publish']"
          [queryParams]="{
            resource_uuid: resource?.uuid,
            done_history_length: 2
          }"
          >NEXT</a
        >
      </div>

      <div class="sticky bottom-0 z-20" *ngIf="isInsideCourse">
        <button (click)="goBack()" class="block btn btn-primary w-full my-5">
          DONE
        </button>
      </div>
    </ng-container>
  </div>
</app-back-title-layout>
