import { Component, OnInit } from "@angular/core";
import { DashboardService } from "src/app/services/dashboard.service";

@Component({
  selector: "app-main-screen",
  templateUrl: "./main-screen.component.html",
  styles: [],
})
export class MainScreenComponent implements OnInit {
  emailActions = [];
  constructor(private dashboardService: DashboardService) {}

  ngOnInit(): void {
    let actions = [];
    actions = this.dashboardService.getEmailActions();
    for (let i = 0; i < 2; i++) {
      this.emailActions.push(actions[i]);
    }
  }
}
