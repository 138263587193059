import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, Subscription, switchMap, tap } from "rxjs";
import { fadeInOut } from "src/app/app-common-module/animations/fade";
import { Header } from "src/app/app-common-module/components/list-table-header/list-table-header.component";
import { ResourcesService } from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-course-modal",
  templateUrl: "./course-modal.component.html",
  styles: [],
  animations: [fadeInOut],
})
export class CourseModalComponent implements OnInit {
  @Output() course = new EventEmitter<any>();
  @Input() currentIndex: number;
  loading: boolean = false;
  headers: Header[] = [{ name: "Course Name", value: "name", width: "w-96" }];
  defaultHeaders: string[] = ["Course Name"];
  totalCourseCount: number;
  limit: number = 4;
  totalCountSub: Subscription;
  listResourcesObservable: Observable<any[]>;
  resourceSub: Subscription;
  constructor(
    private resourcesService: ResourcesService,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.totalCountSub = this.route.queryParams
      .pipe(
        switchMap((params) => {
          let { course } = params;
          if (!course) course = null;
          else {
            course = `${course}:*`;
          }

          return this.resourcesService.listResourcesCount(
            "course",
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            true,
            course
          );
        })
      )
      .subscribe((result) => {
        this.totalCourseCount = result.count;
      });
    this.listResourcesObservable = this.route.queryParams.pipe(
      switchMap((params) => {
        this.loading = true;
        this.cd.detectChanges();
        let { page, course } = params;
        if (!course) course = null;
        else course = `${course}:*`;
        if (!page) page = 0;

        return this.resourcesService.listResources(
          "course",
          this.limit,
          page * this.limit,
          null,
          null,
          null,
          null,
          null,
          null,
          true,
          course
        );
      }),
      tap((result) => {
        this.loading = false;
        this.cd.detectChanges();
      })
    );
  }

  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }
  ngOnDestroy(): void {
    this.totalCountSub?.unsubscribe();
    this.resourceSub?.unsubscribe();
  }
  outputCourse(data) {
    this.course.emit(data);
    this.router.navigate([], {
      relativeTo: this.route,
      replaceUrl: false,
      queryParamsHandling: "merge",
      queryParams: { course: null, page: null },
    });
  }
}
