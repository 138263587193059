import { DragDropModule } from "@angular/cdk/drag-drop";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppCommonModule } from "../app-common-module/app-common-module.module";
import { GroupActivityLogsComponent } from "./groups/group-activity-logs/group-activity-logs.component";
import { GroupCommonRolePermissionsToggleComponent } from "./groups/group-common-role-permissions-toggle/group-common-role-permissions-toggle.component";
import { GroupCreateComponent } from "./groups/group-create/group-create.component";
import { GroupEditMembersEmailListComponent } from "./groups/group-edit-members-email-list/group-edit-members-email-list.component";
import { GroupEditMembersIndexComponent } from "./groups/group-edit-members-index/group-edit-members-index.component";
import { GroupEditMembersComponent } from "./groups/group-edit-members/group-edit-members.component";
import { GroupEditComponent } from "./groups/group-edit/group-edit.component";
import { GroupMemberListComponent } from "./groups/group-member-list/group-member-list.component";
import { GroupProfileComponent } from "./groups/group-profile/group-profile.component";
import { GroupRemoveMembersEmailListComponent } from "./groups/group-remove-members-email-list/group-remove-members-email-list.component";
import { GroupRolePermissionsEditComponent } from "./groups/group-role-permissions-edit/group-role-permissions-edit.component";
import { GroupListComponent } from "./tablist/group-list/group-list.component";
import { TablistComponent } from "./tablist/tablist.component";
import { UserListComponent } from "./tablist/user-list/user-list.component";
import { UserManagementRoutingModule } from "./user-management-routing.module";
import { UserManagementComponent } from "./user-management.component";
import { UserAccountDetailsComponent } from "./users/user-account-details/user-account-details.component";
import { UserActivityLogsComponent } from "./users/user-activity-logs/user-activity-logs.component";
import { UserCreateCsvComponent } from "./users/user-create-csv/user-create-csv.component";
import { UserCreateIndexComponent } from "./users/user-create-index/user-create-index.component";
import { UserCreateComponent } from "./users/user-create/user-create.component";
import { UserEditComponent } from "./users/user-edit/user-edit.component";
import { UserGroupsComponent } from "./users/user-groups/user-groups.component";
import { UserProfileComponent } from "./users/user-profile/user-profile.component";
import { UserStatsComponent } from "./users/user-stats/user-stats.component";
import { GroupRolePermissionsToggleComponent } from "./groups/group-role-permissions-toggle/group-role-permissions-toggle.component";
import { GroupRoleNewComponent } from "./groups/group-role-new/group-role-new.component";
import { GroupRolePermissionsToggleFormComponent } from "./groups/components/group-role-permissions-toggle-form/group-role-permissions-toggle-form.component";
import { RoleSelectorComponent } from "./groups/components/role-selector/role-selector.component";
import { UserCourseProgressComponent } from "./users/user-course-progress/user-course-progress.component";
import { MatDialogModule } from "@angular/material/dialog";
import { UserGroupEditComponent } from "./users/user-group-edit/user-group-edit.component";

@NgModule({
  declarations: [
    GroupProfileComponent,
    UserManagementComponent,
    GroupEditComponent,
    GroupEditMembersComponent,
    GroupCreateComponent,
    TablistComponent,
    GroupListComponent,
    UserListComponent,
    UserCreateComponent,
    UserProfileComponent,
    UserEditComponent,
    UserAccountDetailsComponent,
    UserGroupsComponent,
    UserStatsComponent,
    GroupEditMembersEmailListComponent,
    GroupEditMembersIndexComponent,
    GroupMemberListComponent,
    GroupRolePermissionsEditComponent,
    GroupCommonRolePermissionsToggleComponent,
    GroupRemoveMembersEmailListComponent,
    UserCreateIndexComponent,
    UserCreateCsvComponent,
    UserActivityLogsComponent,
    GroupActivityLogsComponent,
    GroupRolePermissionsToggleComponent,
    GroupRoleNewComponent,
    GroupRolePermissionsToggleFormComponent,
    RoleSelectorComponent,
    UserCourseProgressComponent,
    UserGroupEditComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UserManagementRoutingModule,
    DragDropModule,
    MatDialogModule,
    AppCommonModule,
  ],
  exports: [UserEditComponent],
})
export class UserManagementModule {}
