<label
  role="radio"
  #label
  (keydown.enter)="label.click()"
  (keydown.space)="label.click()"
  tabindex="0"
  class="cursor-pointer"
>
  <input
    type="radio"
    [checked]="checked"
    class="hidden"
    [name]="name"
    [value]="value"
    [disabled]="disabled"
    (change)="valueChange(value)"
  />

  <app-icon class="checked" [icon]="checkIcon"></app-icon>
  <app-icon class="unchecked" [icon]="uncheckIcon"></app-icon>
</label>
