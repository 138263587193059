<app-back-title-layout layoutTitle="Create Screen">
  <form (submit)="createScreen()" class="my-10" [formGroup]="formGroup">
    <label class="block my-5">
      <div class="form-label">Screen Title</div>

      <input
        type="text"
        name="title"
        class="form-control"
        formControlName="title"
      />
    </label>

    <label class="block my-5">
      <div class="form-label">Slug</div>

      <input
        type="text"
        name="title"
        class="form-control"
        formControlName="slug"
      />
    </label>

    <button
      type="submit"
      class="btn btn-primary w-full my-5"
      [disabled]="formGroup.invalid || formGroup.disabled"
    >
      SAVE
    </button>
  </form>
</app-back-title-layout>
