<app-back-title-layout layoutTitle="Create new batch">
  <form [formGroup]="newBatchForm" (submit)="createNewBatch()">
    <div class="my-5">
      <label>
        <span class="form-label"> Batch Name </span>
        <input formControlName="batchName" class="form-control" />

        <app-form-control-error-layout
          [control]="newBatchForm.get('batchName')"
        ></app-form-control-error-layout>
      </label>
    </div>

    <div class="my-5">
      <label class="form-label"> Batch Access Period </label>

      <div class="flex flex-col mb-10">
        <label class="flex mb-5">
          <!-- <input  /> -->

          <app-radio
            formControlName="batchAccess"
            type="radio"
            value="forever"
            class="text-primary"
          >
          </app-radio>

          <div class="ml-2">
            Forever

            <div class="text-xs text-gray-600">
              Users will immediately have unlimited access to this course
            </div>
          </div>
        </label>

        <label class="flex">
          <app-radio
            formControlName="batchAccess"
            type="radio"
            value="startend"
            class="text-primary"
          >
          </app-radio>

          <div class="ml-2">
            <div>Set start and end date</div>
            <div class="text-xs text-gray-600">
              Set a specific time period batches can access your course
            </div>

            <div
              class="border-gray-300 w-full p-3 flex flex-col"
              [ngClass]="{
                'pointer-events-none':
                  newBatchForm.controls.batchAccess.value !== 'startend',
                'opacity-25':
                  newBatchForm.controls.batchAccess.value !== 'startend'
              }"
            >
              <span class="flex flex-col mb-3">
                <span> Start Date and Time </span>

                <app-date-time formControlName="startDateTime"></app-date-time>
              </span>
              <span class="flex flex-col mb-3">
                <span> End Date and Time </span>

                <app-date-time formControlName="endDateTime"></app-date-time>
              </span>
            </div>
          </div>
        </label>
      </div>
    </div>

    <button
      type="submit"
      [disabled]="newBatchForm.invalid || newBatchForm.disabled"
      class="btn btn-primary w-full"
    >
      Done
    </button>
  </form>
</app-back-title-layout>
