<app-back-title-layout>
  <div class="my-10">
    <div class="my-2">
      <app-search-bar-query placeholder="Search"></app-search-bar-query>
    </div>

    <div class="my-2 flex justify-end">
      <app-pagination-query
        [pageSize]="pageSize"
        [listLength]="userCount"
      ></app-pagination-query>
    </div>

    <app-item-list
      [itemTemplate]="itemTemplate"
      [source]="userObservable"
    ></app-item-list>
  </div>

  <div class="sticky bottom-0 z-20">
    <!-- <button
            appGoBack
            type="button"
            class="w-1/3 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
          >
            Cancel
          </button> -->
    <button (click)="save()" type="button" class="btn btn-primary w-full my-2">
      Save
    </button>
  </div>
</app-back-title-layout>

<ng-template #itemTemplate let-item="item">
  <label class="flex items-center">
    <app-checkbox
      class="text-primary"
      name="{{ item.firstName }} {{ item.lastName }}"
      [(ngModel)]="selectedUserIdMap[item.id]"
    >
    </app-checkbox>

    <app-icon icon="person" class="text-gray-600 text-4xl mx-2"></app-icon>

    <div class>
      <div class="truncate font-semibold leading-tight">
        {{ item.firstName }} {{ item.lastName }}
      </div>

      <div class="text-sm text-gray-600">{{ item.email }}</div>
    </div>
  </label>
</ng-template>
