<app-back-title-layout
  layoutTitle="Update Course Section"
  [loading]="!formGroup"
>
  <ng-container *ngIf="formGroup">
    <form [formGroup]="formGroup" (ngSubmit)="updateCourseSection()">
      <label class="form-label my-5">
        Section Title
        <input formControlName="title" class="form-control" />

        <app-form-control-error-layout
          [control]="formGroup.get('title')"
        ></app-form-control-error-layout>
      </label>

      <input
        type="submit"
        class="btn btn-primary w-full my-5"
        value="Update Section"
        [disabled]="!formGroup.valid"
      />
    </form>

    <button
      (click)="deleteSection()"
      type="button"
      class="w-full btn btn-danger"
    >
      Delete
    </button>
  </ng-container>
</app-back-title-layout>
