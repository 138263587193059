import { Component, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import {
  Observable,
  Subject,
  firstValueFrom,
  map,
  of,
  switchMap,
  takeUntil,
  takeWhile,
  tap,
  timer,
} from "rxjs";
import { Report, ReportsService } from "src/app/sinigangnababoywithgabi";
import { DateTime } from "luxon";
import { DatePipe } from "@angular/common";

interface SnapshotListItem {
  snapshotId: string;
  name: string;
  fromDate: string;
  toDate: string;
  createdAt: string;
  status: string;
}

@Component({
  selector: "app-reporting-snapshots-list",
  templateUrl: "./reporting-snapshots-list.component.html",
  styles: [],
})
export class ReportingSnapshotsListComponent implements OnInit {
  snapshotItems: Observable<SnapshotListItem>[];
  createSnapshotObs: Observable<any>;

  loading: boolean = false;

  timerangeSelectOptions: {
    name: string;
    value: string;
  }[] = [
    {
      name: "Last week",
      value: "last_week",
    },
    {
      name: "Last 30 days",
      value: "last_30_days",
    },
    {
      name: "Last  two months",
      value: "last_two_months",
    },
    {
      name: "Last Year",
      value: "last_year",
    },
    {
      name: "Custom",
      value: "custom",
    },
  ];
  timerangeSelectFormControl = new FormControl();
  timerangeFromFormControl = new FormControl(null, [Validators.required]);
  timerangeToFormControl = new FormControl(null, [Validators.required]);

  constructor(
    private reportsService: ReportsService,
    private datePipe: DatePipe
  ) {}

  private unsubscribe$: Subject<void> = new Subject<void>();
  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  async ngOnInit() {
    this.loadSnapshots();
    this.loadSnapshotCreateObs();

    this.timerangeSelectFormControl.valueChanges
      .pipe(
        tap((value) => {
          if (value === "last_week") {
            this.timerangeFromFormControl.setValue(
              DateTime.now().minus({ weeks: 1 }).toISODate()
            );
            this.timerangeToFormControl.setValue(DateTime.now().toISODate());
          } else if (value === "last_30_days") {
            this.timerangeFromFormControl.setValue(
              DateTime.now().minus({ days: 30 }).toISODate()
            );
            this.timerangeToFormControl.setValue(DateTime.now().toISODate());
          } else if (value === "last_two_months") {
            this.timerangeFromFormControl.setValue(
              DateTime.now().minus({ months: 2 }).toISODate()
            );
            this.timerangeToFormControl.setValue(DateTime.now().toISODate());
          } else if (value === "last_year") {
            this.timerangeFromFormControl.setValue(
              DateTime.now().minus({ years: 1 }).toISODate()
            );
            this.timerangeToFormControl.setValue(DateTime.now().toISODate());
          }
        }),

        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  async loadSnapshots() {
    let reports = await firstValueFrom(this.reportsService.reportsList());

    if (reports.length == 0) {
      this.snapshotItems = [];

      return;
    }

    let snapshots = await firstValueFrom(
      this.reportsService.reportsSnapshotsList(reports[0].id)
    );

    this.snapshotItems = snapshots.map((snapshot) => {
      return timer(0, 5000).pipe(
        switchMap(() => {
          if (snapshot.status === "done") {
            return of({
              snapshotId: snapshot.id,
              name: snapshot.name,
              createdAt: snapshot.createdAt,
              status: snapshot.status,
              fromDate: snapshot.fromDate,
              toDate: snapshot.toDate,
            });
          }

          return this.reportsService.snapshotsGet(snapshot.id).pipe(
            map((snapshot) => ({
              snapshotId: snapshot.id,
              name: snapshot.name,
              createdAt: snapshot.createdAt,
              status: snapshot.status,
              fromDate: snapshot.fromDate,
              toDate: snapshot.toDate,
            }))
          );
        }),
        takeWhile((snapshot) => snapshot.name !== "done", true)
      );
    });
  }

  async loadSnapshotCreateObs() {
    this.createSnapshotObs = this.reportsService.reportsList().pipe(
      switchMap((reports) => {
        let report = reports[0];

        if (!report) {
          return this.reportsService.reportsCreate({
            name: "Report",
            autoGenerateFrequency: "monthly",
            autoGenerateIntervalUnit: "month",
            autoGenerateIntervalLength: 1,
          });
        } else {
          return of(report);
        }
      }),
      switchMap((report: Report) => {
        return this.reportsService.reportsSnapshotCreate(report.id, {
          name: `${
            this.timerangeSelectOptions.find(
              (i) => i.value === this.timerangeSelectFormControl.value
            )?.name
          }`,
          fromDate: this.timerangeFromFormControl.value,
          toDate: this.timerangeToFormControl.value,
        });
      }),
      tap((newSnapshot) => {
        this.snapshotItems = [
          timer(0, 5000).pipe(
            switchMap(() => {
              if (newSnapshot.status === "done") {
                return of({
                  snapshotId: newSnapshot.id,
                  name: newSnapshot.name,
                  createdAt: newSnapshot.createdAt,
                  status: newSnapshot.status,
                  fromDate: newSnapshot.fromDate,
                  toDate: newSnapshot.toDate,
                });
              }

              return this.reportsService.snapshotsGet(newSnapshot.id).pipe(
                map((snapshot) => ({
                  snapshotId: snapshot.id,
                  name: snapshot.name,
                  createdAt: snapshot.createdAt,
                  status: snapshot.status,
                  fromDate: snapshot.fromDate,
                  toDate: snapshot.toDate,
                }))
              );
            }),
            takeWhile((snapshot) => snapshot.name !== "done", true)
          ),
          ...this.snapshotItems,
        ];
      })
    );
  }
}
