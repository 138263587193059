import { Component, OnInit } from "@angular/core";
import {
  CourseBlock,
  CoursesService,
  ResourcesService,
} from "src/app/sinigangnababoywithgabi";
import { ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { ModalService } from "src/app/services/modal.service";

@Component({
  selector: "app-course-block-certification-details",
  templateUrl: "./course-block-certification-details.component.html",
  styleUrls: ["./course-block-certification-details.component.scss"],
})
export class CourseBlockCertificationDetailsComponent implements OnInit {
  courseUuid: string;
  block: CourseBlock;

  prerequisiteBlocks: CourseBlock[];

  constructor(
    private route: ActivatedRoute,
    private coursesService: CoursesService,
    private location: Location,
    private resourcesService: ResourcesService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    let blockUuid = this.route.snapshot.queryParams["block_uuid"];
    this.courseUuid = this.route.snapshot.queryParams["course_uuid"];

    this.coursesService
      .blockRead(blockUuid, "reference_info")
      .subscribe((result) => {
        this.block = result;
      });
  }

  isValidForCertificate() {
    let resourceType = this.block.content?.resourceDetails?.type;
    return (
      resourceType === "assessment" ||
      resourceType === "buribook-with-activity" ||
      resourceType === "burislides"
    );
  }

  isRequiredForCertificate() {
    return !!this.block.referenceInfo?.referenceId;
  }

  addCertificationRequirements() {
    this.resourcesService
      .getResourceByUuid(this.block.content?.resourceDetails?.uuid)
      .subscribe((result) => {
        let timeslotUuid = result.content.examDetails?.examTimeslotUuid;

        this.block.referenceInfo = this.block.referenceInfo || {};

        Object.assign(this.block.referenceInfo, {
          referenceId: timeslotUuid,
          customPassingMark: 50,
        });
      });
  }

  async save() {
    await this.updateBlock();

    this.location.back();
  }

  updateBlock() {
    return this.coursesService
      .blockUpdate(this.block.uuid, {
        referenceInfo: this.block.referenceInfo,
      })
      .toPromise();
  }

  async remove() {
    let result = await this.modalService.confirm("Are you sure?");

    if (result) {
      Object.assign(this.block.referenceInfo, {
        referenceId: null,
        customPassingMark: null,
      });

      await this.updateBlock();
    }
  }
}
