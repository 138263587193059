import { Component, OnInit } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import {
  Resource,
  CourseSection,
  CoursesService,
  ResourcesService,
} from "src/app/sinigangnababoywithgabi";
import { ModalService } from "src/app/services/modal.service";

@Component({
  selector: "app-course-section-edit",
  templateUrl: "./course-section-edit.component.html",
  styleUrls: ["./course-section-edit.component.scss"],
})
export class CourseSectionEditComponent implements OnInit {
  formGroup: UntypedFormGroup;

  section: CourseSection;

  constructor(
    private route: ActivatedRoute,
    private courseService: CoursesService,
    private formBuilder: UntypedFormBuilder,
    private location: Location,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      let sectionUuid = params["section_uuid"];

      this.courseService.sectionRead(sectionUuid).subscribe((result) => {
        this.section = result;

        this.formGroup = this.formBuilder.group({
          title: ["", Validators.required],
          coverUri: [""],
        });

        this.formGroup.setValue({
          title: this.section.title,
          coverUri: this.section.coverUri,
        });
      });
    });
  }

  updateCourseSection() {
    this.courseService
      .sectionUpdate(this.section.uuid, {
        ...this.formGroup.value,
      })
      .subscribe((result) => {
        this.location.back();
      });
  }

  async deleteSection() {
    let result = await this.modalService.confirm(
      "Are you sure you want to delete this section?"
    );

    if (result) {
      this.courseService
        .sectionDelete(this.section.uuid)
        .subscribe((result) => {
          this.location.back();
        });
    }
  }
}
