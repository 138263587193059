import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ExamsComponent } from "./exams.component";
import { AuthGuard } from "../app-common-module/guards/auth.guard";
import { ExamUserSubmissionsComponent } from "./exam-user-submissions/exam-user-submissions.component";
import { ExamSubmissionResponsesComponent } from "./exam-submission-responses/exam-submission-responses.component";
import { ExamResultsComponent } from "./exam-results/exam-results.component";

const routes: Routes = [
  {
    path: "_exams",
    component: ExamsComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "user-submissions",
        component: ExamUserSubmissionsComponent,
      },
      {
        path: "submission-responses",
        component: ExamSubmissionResponsesComponent,
      },
      {
        path: "results",
        component: ExamResultsComponent,
        data: { layoutTitle: "Exam Results" },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ExamsRoutingModule {}
