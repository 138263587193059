<app-back-title-layout
  layoutTitle="Edit User Details"
  [loading]="!formGroup || isLoading"
>
  <form
    [formGroup]="formGroup"
    (ngSubmit)="save()"
    class="mt-5"
    *ngIf="formGroup"
  >
    <div
      class="text-5xl rounded-full bg-gray-300 text-white h-20 w-20 flex items-center justify-center mb-5"
    >
      <app-icon icon="group"></app-icon>
    </div>

    <h2 class="form-heading">Account Details</h2>

    <label class="my-10">
      <span class="form-label"> Email </span>

      <input formControlName="email" class="form-control" />
      <div
        *ngIf="!formGroup.value.isUserConfirmed"
        class="text-sm text-error-700 py-2"
      >
        <app-icon icon="error" class="align-bottom"></app-icon>
        <span> email not yet confirmed </span>
      </div>

      <app-form-control-error-layout
        [control]="formGroup.get('email')"
      ></app-form-control-error-layout>

      <ng-container *ngIf="!formGroup.value.isUserConfirmed">
        <button
          type="button"
          class="btn bg-transparent text-primary-600 px-0 text-sm"
          (click)="resendConfirmationEmail()"
        >
          <app-icon icon="email"></app-icon>
          Resend confirmation email
        </button>
      </ng-container>

      <button
        type="button"
        class="btn bg-transparent text-primary-600 px-0 text-sm"
        (click)="sendPasswordChangeEmail()"
      >
        <app-icon icon="email"></app-icon>
        Send password change email
      </button>
    </label>

    <label class="py-2 flex justify-between items-center form-width">
      <span class="form-label"> Change password on login </span>

      <app-checkbox
        class="text-3xl"
        uncheckIcon="toggle_off"
        checkIcon="toggle_on"
        uncheckClass="text-gray-500"
        checkClass="text-primary-dark"
        formControlName="isPasswordExpired"
      ></app-checkbox>
    </label>

    <button
      *ngIf="formGroup.value.isActive"
      type="button"
      class="btn bg-transparent border border-red-500 text-red-500"
      (click)="deactivateUser()"
    >
      DEACTIVATE USER
    </button>

    <button
      *ngIf="!formGroup.value.isActive"
      type="button"
      class="btn btn-primary"
      (click)="activateUser()"
    >
      ACTIVATE USER
    </button>

    <hr class="form-divider" />

    <h2 class="form-heading">User Details</h2>

    <div class="flex my-5 form-width">
      <div class="w-1/2 mr-3">
        <label>
          <span class="form-label"> First Name </span>

          <input formControlName="firstName" class="form-control" />

          <app-form-control-error-layout
            [control]="formGroup.get('firstName')"
          ></app-form-control-error-layout>
        </label>
      </div>
      <div class="w-1/2">
        <label>
          <span class="form-label"> Last Name </span>
          <input formControlName="lastName" class="form-control" />

          <app-form-control-error-layout
            [control]="formGroup.get('lastName')"
          ></app-form-control-error-layout>
        </label>
      </div>
    </div>

    <div class="my-10">
      <details>
        <summary class="font-bold px-1 py-3 cursor-pointer">
          Show additional fields
        </summary>
        <!-- Traits -->
        <div
          class="bg-gray-100 p-2 rounded"
          *ngIf="_traits.controls.length > 0"
        >
          <div class="font-bold text-lg">Traits</div>
          <div
            class="mb-3"
            *ngFor="let trait of _traits.controls; let i = index"
            formArrayName="traits"
          >
            <label [formGroupName]="i">
              <span class="form-label">
                <!-- <span
                  class="text-red-600"
                  *ngIf="isRequiredField(trait.controls)"
                  >*</span -->
                {{ trait.value.name }}</span
              >
              <input
                *ngIf="!trait.value.dd"
                class="form-control"
                [type]="trait.value.type"
                formControlName="value"
              />
              <app-form-control-error-layout
                [control]="trait.controls.value"
              ></app-form-control-error-layout>
              <select
                *ngIf="trait.value.dd && trait.value.type !== 'boolean'"
                formControlName="enumValue"
                class="form-control"
              >
                <option [ngValue]="null" disabled selected>
                  Choose option
                </option>
                <option
                  *ngFor="let option of getDropdownFields(trait)"
                  [ngValue]="option.value.value"
                >
                  {{ option.value.name }}
                </option>
              </select>
              <app-form-control-error-layout
                [control]="trait.controls.enumValue"
              ></app-form-control-error-layout>
              <select
                *ngIf="trait.value.dd && trait.value.type === 'boolean'"
                formControlName="boolean"
                class="form-control"
              >
                <option [ngValue]="null" disabled selected>
                  True or False
                </option>
                <option
                  *ngFor="let option of booleanChoices"
                  [ngValue]="option.value"
                >
                  {{ option.name }}
                </option>
              </select>
              <app-form-control-error-layout
                [control]="trait.controls.boolean"
              ></app-form-control-error-layout>
            </label>
          </div>
          <hr class="form-divider" />
        </div>

        <!-- Public Metadata -->
        <div
          class="bg-gray-100 p-2 rounded"
          *ngIf="_publicMetadata.controls.length > 0"
        >
          <div class="font-bold text-lg">Public Metadata</div>
          <div
            class="mb-3"
            *ngFor="let public of _publicMetadata.controls; let i = index"
            formArrayName="publicMetadata"
          >
            <label [formGroupName]="i">
              <span class="form-label">
                <!-- <span
                  class="text-red-600"
                  *ngIf="isRequiredField(public.controls)"
                  >*</span -->
                {{ public.value.name }}</span
              >
              <input
                *ngIf="!public.value.dd"
                class="form-control"
                [type]="public.value.type"
                formControlName="value"
              />
              <app-form-control-error-layout
                [control]="public.controls.value"
              ></app-form-control-error-layout>
              <select
                *ngIf="public.value.dd && public.value.type !== 'boolean'"
                formControlName="enumValue"
                class="form-control"
              >
                <option [ngValue]="null" disabled selected>
                  Choose option
                </option>
                <option
                  *ngFor="let option of getDropdownFields(public)"
                  [ngValue]="option.value.value"
                >
                  {{ option.value.name }}
                </option>
              </select>
              <app-form-control-error-layout
                [control]="public.controls.enumValue"
              ></app-form-control-error-layout>
              <select
                *ngIf="public.value.dd && public.value.type === 'boolean'"
                formControlName="boolean"
                class="form-control"
              >
                <option [ngValue]="null" disabled selected>
                  True or False
                </option>
                <option
                  *ngFor="let option of booleanChoices"
                  [ngValue]="option.value"
                >
                  {{ option.name }}
                </option>
              </select>
              <app-form-control-error-layout
                [control]="public.controls.boolean"
              ></app-form-control-error-layout>
            </label>
          </div>

          <hr class="form-divider" />
        </div>
        <!-- Admin Metadata -->
        <div
          class="bg-gray-100 p-2 rounded"
          *ngIf="_adminMetadata.controls.length > 0"
        >
          <div class="font-bold text-lg">Admin Metadata</div>
          <div
            class="mb-3"
            *ngFor="let admin of _adminMetadata.controls; let i = index"
            formArrayName="adminMetadata"
          >
            <label [formGroupName]="i">
              <span class="form-label">
                <!-- <span
                  class="text-red-600"
                  *ngIf="isRequiredField(admin.controls)"
                  >*</span -->
                {{ admin.value.name }}</span
              >
              <input
                *ngIf="!admin.value.dd"
                class="form-control"
                [type]="admin.value.type"
                formControlName="value"
              />
              <app-form-control-error-layout
                [control]="admin.controls.value"
              ></app-form-control-error-layout>
              <select
                *ngIf="admin.value.dd && admin.value.type !== 'boolean'"
                formControlName="enumValue"
                class="form-control"
              >
                <option [ngValue]="null" disabled selected>
                  Choose option
                </option>
                <option
                  *ngFor="let option of getDropdownFields(admin)"
                  [ngValue]="option.value.value"
                >
                  {{ option.value.name }}
                </option>
              </select>
              <app-form-control-error-layout
                [control]="admin.controls.enumValue"
              ></app-form-control-error-layout>
              <select
                *ngIf="admin.value.dd && admin.value.type === 'boolean'"
                formControlName="boolean"
                class="form-control"
              >
                <option [ngValue]="null" disabled selected>
                  True or False
                </option>
                <option
                  *ngFor="let option of booleanChoices"
                  [ngValue]="option.value"
                >
                  {{ option.name }}
                </option>
              </select>
              <app-form-control-error-layout
                [control]="admin.controls.boolean"
              ></app-form-control-error-layout>
            </label>
          </div>
          <hr class="form-divider" />
        </div>
      </details>
    </div>

    <h2 class="form-heading">Groups</h2>

    <table class="w-full border-b-1 border-gray-200">
      <tr class="border-b-2 border-gray-200">
        <th class="my-1 font-normal text-left text-gray-500">Group Name</th>
        <th class="my-1 font-normal text-left text-gray-500">Members</th>
        <!-- <th class="my-1 font-normal text-left text-gray-500">Role</th> -->
        <!-- <th></th> -->
      </tr>

      <tr
        class="py-2 border-b"
        *ngFor="let group of user.groups; let i = index"
      >
        <td class="py-2">
          <app-icon class="text-gray-400" icon="group"></app-icon>
          {{ group.name }}
        </td>
        <td>
          <app-icon class="text-gray-400" icon="person"></app-icon>
          {{ group.usersCount }}
        </td>

        <td>
          <button
            type="button"
            (click)="removeGroup(i)"
            class="btn btn-icon bg-transparent text-gray-500 hover:text-error-600"
          >
            <app-icon icon="close"></app-icon>
          </button>
        </td>
        <!-- <td class="my-1">asdsad</td> -->
      </tr>
    </table>

    <a
      [routerLink]="['../group-edit']"
      queryParamsHandling="merge"
      class="block my-2 uppercase text-primary-700"
    >
      Modify groups
    </a>
    <!-- 
    <button type="button" class="btn bg-transparent text-primary-700">
      <app-icon icon="add"></app-icon>
      Add more group
    </button> -->

    <div class="my-5 flex flex-row-reverse">
      <button
        type="submit"
        class="btn btn-primary"
        [disabled]="formGroup.invalid"
      >
        SAVE
      </button>
    </div>
  </form>
</app-back-title-layout>
