<div class="input-group mb-3">
  <div class="input-group-prepend">
    <label class="input-group-text" for="inputGroupSelect01">{{
      resource.uuid ? 'Selected Video' : 'Select a Video'
    }}</label>
  </div>
  <select class="custom-select" id="inputGroupSelect01" [(ngModel)]="resource.content['sourceResourceUuid']"
    (change)="onSelect()" [disabled]="resource.uuid">
    <ng-container *ngFor="let resource of resources">
      <option [value]="resource.uuid">{{ resource.name }}</option>
    </ng-container>
  </select>
</div>

<button class="btn btn-primary" *ngIf="resource.uuid" [routerLink]="['/misc', 'video-activity-edit']"
  [queryParams]="{ resource_uuid: resource.uuid }">
  Edit Activity
</button>