<app-back-title-layout layoutTitle="Upload CSV" [loading]="!groups">
  <ng-container *ngIf="groups">
    <div class="my-2 text-gray-600 text-sm">
      Upload your CSV file below. Please make sure that the file format is in
      .CSV and all information listed in your file is correct.
    </div>

    <!-- <button class="my-2 text-primary text-sm">
      Learn how to prep your CSV files
    </button> -->

    <app-csv-upload-or-paste
      [camelCaseKeys]="false"
      (onChange)="onDataProcess($event)"
    >
    </app-csv-upload-or-paste>

    <ng-container *ngIf="items && response">
      <div *ngIf="response.deactivated?.length" class="text-green-500 my-2">
        <app-icon icon="check_circle"></app-icon>
        <span>
          {{ response.deactivated.length }} of {{ items.length }} successfully
          deactivated!
        </span>
      </div>
      <div *ngIf="response.modified.length" class="text-green-500 my-2">
        <app-icon icon="check_circle"></app-icon>
        <span>
          {{ response.modified.length }} of {{ items.length }} successfully
          modified!
        </span>
      </div>

      <div *ngIf="response.succeeded.length" class="text-green-500 my-2">
        <app-icon icon="check_circle"></app-icon>
        <span>
          {{ response.succeeded.length }} of {{ items.length }} successfully
          uploaded!
        </span>
      </div>

      <div
        *ngIf="response.failed.length"
        class="text-red-500 bg-red-200 rounded p-1 my-2"
      >
        <span>
          {{ response.failed.length }} of {{ items.length }} failed to upload
        </span>
      </div>
    </ng-container>

    <ng-container *ngIf="items">
      <div class="relative">
        <app-loading
          [loading]="loading"
          position="absolute"
          wrapperClass="bg-white opacity-50"
        ></app-loading>

        <app-item-select-with-header
          [headers]="[
            { name: 'Name', value: 'firstName', width: 'w-56' },
            { name: 'Email', value: 'email', width: 'w-56' },
            { name: 'Groups', value: '', width: 'w-56' },
            {
              name: 'Change password on login?',
              value: 'isPasswordExpired',
              width: 'w-56'
            }
          ]"
          [enableSort]="false"
          [source]="listSourceObs"
          [itemTemplate]="itemTemplate"
          [category]="'group'"
          [limit]="pageSize"
          [totalCount]="items?.length"
          [disableCheckbox]="true"
        >
        </app-item-select-with-header>

        <button class="btn btn-primary" (click)="startUpload()">
          Process data
        </button>

        <button
          class="btn bg-blue-500 text-white mx-2"
          *ngIf="response"
          (click)="downloadResult()"
        >
          Download Result
        </button>
      </div>
    </ng-container>
  </ng-container>
</app-back-title-layout>

<ng-template #itemTemplate let-item="item">
  <div class="flex">
    <div class="w-56">
      <div>
        <app-icon
          icon="group"
          class="text-white text-xl h-10 leading-none px-1 mr-1 bg-gray-200 rounded-full items-center justify-center"
        ></app-icon>

        {{ item.firstName }} {{ item.lastName }}
      </div>
    </div>

    <div class="w-56">{{ item.email }}</div>

    <div class="w-56">
      <ng-container *ngFor="let groupName of item.groupNames">
        <span
          class="bg-secondary-50 text-secondary-600 rounded-full inline-block px-2 py-0.5 mr-1"
        >
          <app-icon icon="group"></app-icon>
          {{ groupName }}
        </span>
      </ng-container>
    </div>

    <div class="w-56 flex justify-center">
      {{ item.isPasswordExpired ? "Yes" : "No" }}
    </div>
    <!-- <div class="w-56" >
            <div *ngFor="let role of groupItem.roles">{{ role.name }}</div>
          </div> -->
    <!-- <div class="w-56 ml-4">
      {{ item.username }}
    </div> -->
  </div>
</ng-template>
