<div class="my-5" [formGroup]="formGroup">
  <label class="form-label">Answer</label>

  <div formArrayName="choices">
    <ng-container
      *ngFor="let choiceControl of getChoiceControls(); let i = index"
    >
      <div class="flex justify-between items-center" [formGroupName]="i">
        <input
          [type]="formGroup.value.subtype === 'DT' ? 'date' : 'text'"
          placeholder="Type answer here..."
          class="form-control mr-2"
          formControlName="text"
        />
      </div>
    </ng-container>
  </div>
</div>
