<div class="form-width" [formGroup]="formGroup">
  <div>
    <ng-container *ngIf="formGroup.value.type == 'video'">
      <app-resource-type-upload-video
        [resource]="resource"
        [formGroup]="formGroup"
      ></app-resource-type-upload-video>
    </ng-container>

    <ng-container *ngIf="formGroup.value.type == 'pdf'">
      <app-resource-type-upload-pdf
        [resource]="resource"
        [formGroup]="formGroup"
      ></app-resource-type-upload-pdf>
    </ng-container>

    <ng-container *ngIf="formGroup.value.type == 'epub'">
      <app-resource-type-upload-epub
        [resource]="resource"
        [formGroup]="formGroup"
      ></app-resource-type-upload-epub>
    </ng-container>

    <ng-container *ngIf="formGroup.value.type == 'buribook'">
      <app-resource-type-upload-buribook
        [resource]="resource"
        [formGroup]="formGroup"
      ></app-resource-type-upload-buribook>
    </ng-container>

    <ng-container *ngIf="formGroup.value.type == 'buribook-with-activity'">
      <app-resource-type-upload-buribook-with-activity
        [resource]="resource"
        [formGroup]="formGroup"
      ></app-resource-type-upload-buribook-with-activity>
    </ng-container>

    <ng-container *ngIf="formGroup.value.type == 'burislides'">
      <app-resource-type-upload-burislides
        [resource]="resource"
        [formGroup]="formGroup"
      ></app-resource-type-upload-burislides>
    </ng-container>

    <ng-container *ngIf="formGroup.value.type == 'html'">
      <app-resource-type-upload-html
        [resource]="resource"
        [formGroup]="formGroup"
      ></app-resource-type-upload-html>
    </ng-container>

    <ng-container *ngIf="formGroup.value.type == 'xapi'">
      <app-resource-type-upload-xapi
        [resource]="resource"
        [formGroup]="formGroup"
      ></app-resource-type-upload-xapi>
    </ng-container>

    <ng-container *ngIf="formGroup.value.type == 'link'">
      <app-resource-type-upload-link
        [resource]="resource"
        [formGroup]="formGroup"
      ></app-resource-type-upload-link>
    </ng-container>

    <ng-container *ngIf="formGroup.value.type == 'audio'">
      <app-resource-type-upload-audio
        [resource]="resource"
        [formGroup]="formGroup"
      ></app-resource-type-upload-audio>
    </ng-container>

    <ng-container *ngIf="formGroup.value.type == 'video-with-activity'">
      <app-resource-type-upload-video-with-activity
        [resource]="resource"
        [formGroup]="formGroup"
      ></app-resource-type-upload-video-with-activity>
    </ng-container>

    <ng-container *ngIf="formGroup.value.type == 'course'">
      <app-resource-type-upload-course
        [resource]="resource"
        [formGroup]="formGroup"
      ></app-resource-type-upload-course>
    </ng-container>
    <ng-container *ngIf="formGroup.value.type == 'embed'">
      <app-resource-type-upload-embed
        [resource]="resource"
        [formGroup]="formGroup"
      ></app-resource-type-upload-embed>
    </ng-container>
    <ng-container *ngIf="formGroup.value.type == 'assessment'">
      <ng-container [ngSwitch]="formGroup.value.subtype">
        <ng-container *ngSwitchCase="'freedom-wall'">
          <app-resource-type-upload-assessment-freedom-wall
            [resource]="resource"
            [formGroup]="formGroup"
          ></app-resource-type-upload-assessment-freedom-wall>
        </ng-container>

        <ng-container *ngSwitchCase="'file-upload'">
          <app-resource-type-upload-assessment-file-upload
            [resource]="resource"
            [formGroup]="formGroup"
          ></app-resource-type-upload-assessment-file-upload>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <app-resource-type-upload-assessment
            [resource]="resource"
            [formGroup]="formGroup"
          ></app-resource-type-upload-assessment>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="formGroup.value.type == 'google-slide'">
      <app-resource-type-upload-google-slide
        [resource]="resource"
        [formGroup]="formGroup"
      ></app-resource-type-upload-google-slide>
    </ng-container>

    <ng-container *ngIf="formGroup.value.type == 'google-doc'">
      <app-resource-type-upload-google-doc
        [resource]="resource"
        [formGroup]="formGroup"
      ></app-resource-type-upload-google-doc>
    </ng-container>

    <ng-container *ngIf="formGroup.value.type == 'google-sheet'">
      <app-resource-type-upload-google-sheet
        [resource]="resource"
        [formGroup]="formGroup"
      ></app-resource-type-upload-google-sheet>
    </ng-container>

    <ng-container *ngIf="formGroup.value.type == 'google-form'">
      <app-resource-type-upload-google-form
        [resource]="resource"
        [formGroup]="formGroup"
      ></app-resource-type-upload-google-form>
    </ng-container>

    <ng-container *ngIf="formGroup.value.type == 'text'">
      <app-resource-type-upload-text
        [resource]="resource"
        [formGroup]="formGroup"
      ></app-resource-type-upload-text>
    </ng-container>

    <ng-container *ngIf="formGroup.value.type == 'casticulate'">
      <app-resource-type-upload-casticulate
        [resource]="resource"
        [formGroup]="formGroup"
      ></app-resource-type-upload-casticulate>
    </ng-container>
  </div>

  <div class="my-10">
    <label>
      <span class="form-label"> Name </span>
      <input formControlName="name" class="form-control" />

      <app-form-control-error-layout
        [control]="formGroup.get('name')"
      ></app-form-control-error-layout>
    </label>
  </div>

  <div class="my-10">
    <label class="form-label leading-none">
      <span>Thumbnail</span>
      <a
        class="ml-1 text-primary-light"
        href="https://support.castlms.com/help/what-is-a-thumbnail"
        target="_blank"
      >
        <app-icon icon="help_outline"></app-icon>
      </a>
    </label>
    <div class="text-sm text-gray-600 mb-1">
      Recommended size is 220x220px. Accepts jpeg, png and gif.
    </div>

    <div class="mt-2 h-32 w-32 bg-purple-200">
      <app-upload-loading-wrapper
        #thumbnailInput
        [disabled]="formGroup.get('coverUri').disabled"
        (upload)="onUpload($event)"
        accept="image/jpeg,image/jpg,image/png,image/gif"
      >
        <div class="w-full h-full">
          <div
            *ngIf="!formGroup.value.coverUri"
            class="h-full text-purple-500 w-full flex flex-col items-center justify-center"
          >
            <app-icon class="text-6xl" icon="image"></app-icon>
          </div>

          <img
            *ngIf="formGroup.value.coverUri"
            [src]="formGroup.value.coverUri"
            alt=""
            class="w-full object-contain h-full"
          />
        </div>
      </app-upload-loading-wrapper>
    </div>

    <div>
      <button
        type="button"
        [disabled]="formGroup.get('coverUri').disabled"
        (click)="thumbnailInput.click()"
        class="w-32 bg-purple-500 text-white mt-2 rounded"
      >
        {{ formGroup.value.coverUri ? "CHANGE" : "ADD IMAGE" }}
      </button>
    </div>
  </div>

  <div class="my-10">
    <label class="form-label leading-none"> Resource Description </label>
    <div class="text-sm text-gray-600 mb-1">
      This will appear to your resource info card, right before opening this
      resource
    </div>

    <app-markdown-viewer-and-editor
      [autoResize]="false"
      editorClass="h-40 overflow-y-scroll"
      viewerClass="h-40 overflow-y-scroll border rounded p-2"
      formControlName="description"
    ></app-markdown-viewer-and-editor>
  </div>

  <!-- <textarea
      formControlName="description"
      class="form-control"
      cols="30"
      rows="10"
    ></textarea> -->

  <!-- <label class="block mb-6">
    <span class="uppercase tracking-wide text-gray-700 text-xs font-bold ">
      Collections
    </span>
    <br />

    <span class="text-xs text-gray=200">
      Organize how your resources will be displayed
    </span>

    <button class="w-full bg-blue-500 text-white mt-2 rounded">
      SET COLLECTIONS
    </button>
  </label> -->

  <details>
    <summary class="py-2 my-10 cursor-pointer font-bold">
      Advanced Details
    </summary>

    <div class="my-10">
      <label>
        <span class="form-label">
          <span> Tags </span>

          <a
            class="ml-1 text-primary-light"
            href="https://support.castlms.com/help/what-are-tags"
            target="_blank"
          >
            <app-icon icon="help_outline"></app-icon>
          </a>
        </span>

        <div class="text-sm text-gray-600 my-1">
          To create another tag, press enter.
        </div>

        <div
          class="border rounded border-gray-400 mt-2 p-2 flex flex-wrap"
          [ngClass]="{
            'bg-gray-200 text-gray-600': formGroup.get('tags').disabled
          }"
        >
          <ng-container
            *ngFor="let tag of formGroup.get('tags').value; let i = index"
          >
            <span
              class="rounded-full text-xs bg-primary-light text-white px-2 mr-1 my-1"
            >
              <span>
                {{ tag }}
              </span>

              <button
                [disabled]="formGroup.get('tags').disabled"
                (click)="removeTag(i, $event)"
              >
                <app-icon icon="close"></app-icon>
              </button>
            </span>
          </ng-container>

          <input
            [disabled]="formGroup.get('tags').disabled"
            type="text"
            class="flex-grow shadow-none outline-none bg-transparent"
            placeholder="Type a tag here..."
            (keydown.enter)="addTag($event)"
            (keydown.backspace)="removeTag(-1, $event)"
          />
        </div>
      </label>
    </div>
  </details>
</div>
