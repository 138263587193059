import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { distinctUntilChanged, of, switchMap, tap } from "rxjs";
import { PassService, User } from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-idis-user-profile",
  templateUrl: "./idis-user-profile.component.html",
  styles: [],
})
export class IdisUserProfileComponent implements OnInit {
  formGroup: UntypedFormGroup;
  userDetails: User;
  enabledSubTabs: boolean;
  currentForm: any;
  sampleSubTabs = [
    {
      id: "tabUuid1",
      selected: true,
      displayName: "Account Details",
      fieldGroups: [
        {
          name: "Account Details",
          orderId: 0,
          fields: [
            {
              name: "Email",
              fieldType: "short_text",
              isRequired: true,
              orderId: 0,
              fieldEntry: {
                valueStr: "sampleemail@gmail.com",
                approvalStatus: "pending",
              },
            },
            {
              name: "Password",
              fieldType: "short_text",
              isRequired: true,
              orderId: 1,
              fieldEntry: {
                valueStr: "samplepassword123",
                approvalStatus: "pending",
              },
            },
          ],
        },
      ],
    },
    {
      id: "tabUuid2",
      selected: false,
      displayName: "Additional User Details",
      fieldGroups: [
        {
          name: "Demographic Information",
          orderId: 0,
          fields: [
            {
              name: "Administrative Region",
              fieldType: "dropdown",
              isRequired: true,
              orderId: 0,
              fieldChoices: [
                { name: "Region I (Ilocos Region)" },
                { name: "NCR (National Capital Region)" },
              ],
              fieldEntry: {
                valueStr: "Region I (Ilocos Region)",
                approvalStatus: "pending",
              },
            },
            {
              name: "Birthdate",
              fieldType: "date",
              isRequired: true,
              orderId: 1,
              fieldEntry: {
                valueStr: "04-10-2023",
                approvalStatus: "pending",
              },
            },
            {
              name: "Age Group",
              fieldType: "dropdown",
              isRequired: true,
              orderId: 2,
              fieldChoices: [
                { name: "18-29" },
                { name: "30-45" },
                { name: "46-60" },
                { name: "61-" },
              ],
              fieldEntry: {
                valueStr: "18-29",
                approvalStatus: "pending",
              },
            },
          ],
        },
      ],
    },
    {
      id: "tabUuid3",
      selected: false,
      displayName: "External Training Documents",
      fieldGroups: [
        {
          name: "Training Details",
          orderId: 0,
          fields: [
            {
              name: "Training Title",
              fieldType: "short_text",
              isRequired: true,
              orderId: 0,
              fieldEntry: {
                valueStr: "Sample Title",
                approvalStatus: "pending",
              },
            },
            {
              name: "Training Provider",
              fieldType: "short_text",
              isRequired: true,
              orderId: 1,
              fieldEntry: {
                valueStr: "Sample Training Provider",
                approvalStatus: "pending",
              },
            },
            {
              name: "Location",
              fieldType: "short_text",
              isRequired: true,
              orderId: 2,
              fieldEntry: {
                valueStr: "Sample Location",
                approvalStatus: "pending",
              },
            },
            {
              name: "Month",
              fieldType: "dropdown",
              isRequired: true,
              orderId: 3,
              fieldChoices: [{ name: "January" }, { name: "December" }],
              fieldEntry: {
                valueStr: "January",
                approvalStatus: "pending",
              },
            },
            {
              name: "Year",
              fieldType: "short_text",
              isRequired: true,
              orderId: 4,
              fieldEntry: {
                valueStr: "2024",
                approvalStatus: "pending",
              },
            },
            {
              name: "Certificate / Proof of Attendance",
              fieldType: "file_upload",
              isRequired: true,
              orderId: 5,
              fieldEntry: {
                valueFileUrl: "https://samplefileurl.com",
                approvalStatus: "pending",
              },
            },
          ],
        },
      ],
    },
  ];
  trainingNeedsForm = {
    id: "sampletabuuid123",
    displayName: "Additional User Details",
    fieldGroups: [
      {
        name: "Training Needs",
        orderId: 0,
        fields: [
          {
            name: "Domain",
            fieldType: "dropdown",
            isRequired: true,
            orderId: 0,
            fieldChoices: [{ name: "Other" }, { name: "Other 1" }],
            fieldEntry: {
              valueStr: "Other 1",
              approvalStatus: "pending",
            },
          },
          {
            name: "Training Requests",
            fieldType: "paragraph",
            isRequired: false,
            orderId: 1,
            fieldEntry: {
              valueStr: "My sample requests are here",
              approvalStatus: "pending",
            },
          },
        ],
      },
    ],
  };
  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private passService: PassService
  ) {}
  ngOnInit(): void {
    this.route.queryParams
      .pipe(
        distinctUntilChanged(),
        switchMap((params) => {
          let { parent_tab, user_uuid } = params;
          switch (parent_tab) {
            case "user_information":
              this.enabledSubTabs = true;
              this.currentForm = this.sampleSubTabs[0];
              break;
            case "assigned_trainings":
              this.enabledSubTabs = false;
              this.currentForm = this.trainingNeedsForm;
              break;
            case "training_needs":
              this.enabledSubTabs = false;
              this.currentForm = this.trainingNeedsForm;
              break;
          }
          return this.passService.getUser(user_uuid, ["groups"]);
        }),
        tap((result) => {
          this.userDetails = result;
          console.log(result);
        })
      )
      .subscribe();
  }

  selectSubTab(index) {
    this.sampleSubTabs = this.sampleSubTabs.map((subtab, i) => {
      let selected = index === i;
      return {
        ...subtab,
        selected,
      };
    });
    this.currentForm = this.sampleSubTabs[index];
    console.log(this.currentForm);
  }
}
