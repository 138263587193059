import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { throwError, of, from, Subscription } from "rxjs";
import { flatMap, tap, filter, map, toArray } from "rxjs/operators";
import { ModalService } from "src/app/services/modal.service";
import { TaskService, Task } from "src/app/services/task.service";
import { Group, PassService, User } from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-group-remove-members-email-list",
  templateUrl: "./group-remove-members-email-list.component.html",
  styleUrls: ["./group-remove-members-email-list.component.scss"],
})
export class GroupRemoveMembersEmailListComponent implements OnInit {
  group: Group;

  loading: boolean;
  subscription: Subscription;
  taskItems: { task: Task; email: string }[];

  constructor(
    private route: ActivatedRoute,
    private passService: PassService,
    private modalService: ModalService,
    private taskService: TaskService
  ) {}

  async ngOnInit() {
    let { group_uuid: groupUuid } = this.route.snapshot.queryParams;

    this.group = await this.passService.getGroup(groupUuid).toPromise();
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  startRemoveUsers(value: string) {
    this.subscription?.unsubscribe();
    let { group_uuid: groupUuid } = this.route.snapshot.queryParams;

    this.loading = true;
    this.taskItems = null;

    let emailRaw = value
      .trim()
      .split("\n")
      .filter((val) => val)
      .map((val) => val.trim().toLowerCase());

    let emails = [...new Set(emailRaw)];

    this.subscription = from(
      this.modalService.confirm(
        "This action will remove these users from the group. Continue?"
      )
    )
      .pipe(
        // Ask if you are usre to remove members of the group
        filter((result) => result),

        // Create a tasks for checking and adding if user are in the group
        map(() => {
          this.taskItems = emails.map((email) => {
            return {
              email,
              task: this.taskService.createTask({
                context: {
                  email,
                },
                steps: [
                  {
                    successMessage: "User is registered in system",
                    stepMessage: "Checking User",
                    fn: (context) => {
                      return this.passService.listUsers(email, 5).pipe(
                        map((result) => result.users),
                        flatMap((users) => {
                          let user = users.find((user) => user.email === email);

                          if (!user) {
                            return throwError(
                              new Error("User is not registered in the system")
                            );
                          } else {
                            context.user = user;
                            return of(null);
                          }
                        })
                      );
                    },
                  },
                  {
                    successMessage: "User is removed from the group",
                    stepMessage: "Remove user from group",
                    errorMessage: "User is not added in the group",
                    fn: (context) => {
                      return this.passService.deleteGroupUsers(groupUuid, {
                        userIds: [context.user.id],
                      });
                    },
                  },
                ],
              }),
            };
          });

          return this.taskItems;
        }),

        // Start and set concurrency to 5
        flatMap((items) => {
          return from(items).pipe(
            flatMap((item) => item.task.start(), 5),
            toArray()
          );
        })

        //Update group
        // flatMap((result) => {
        //   return this.passService.updateGroup(this.group.id, {
        //     group: {
        //       userIds: this.group.userIds,
        //     },
        //   });
        // })
      )
      .subscribe((result) => {
        this.loading = false;
      });
  }

  // async start(value: string) {
  //   let emails = value
  //     .split("\n")
  //     .filter((val) => val)
  //     .map((val) => val.trim());

  //   if (
  //     !(await this.modalService.confirm(
  //       "This action will remove these users from the group. Continue?"
  //     ))
  //   ) {
  //     return;
  //   }

  //   this.tasks = [];
  //   emails.forEach((email) => {
  //     this.tasks.push(
  //       new Task({ group: this.group, email }, [
  //         (task) => {
  //           return new Observable((observer: Observer<any>) => {
  //             let user = task.data.group.users.find(
  //               (user) => user.email === task.data.email
  //             );

  //             if (user) {
  //               task.data.group.userIds = task.data.group.userIds?.filter(
  //                 (id) => id !== user.id
  //               );
  //             }
  //             observer.next(true);
  //             observer.complete();
  //           });
  //         },
  //       ])
  //     );
  //   });

  //   zip(...this.tasks.map((task) => task.start())).subscribe((result) => {
  //     if (
  //       !this.tasks.map((task) => task.success).some((value) => value === false)
  //     ) {
  //       this.updateGroup();
  //     }
  //   });
  // }

  // updateGroup() {
  //   this.passService
  //     .updateGroup(this.group.id, {
  //       group: {
  //         userIds: this.group.userIds,
  //       },
  //     })
  //     .subscribe((result) => {
  //       this.tasks.forEach((task) => {
  //         task.success = true;
  //         task.message = "User removed from group";
  //       });
  //     });
  // }

  get pendingCount() {
    return this.taskItems?.filter((item) => item.task.isPending).length;
  }

  get successCount() {
    return this.taskItems?.filter((item) => item.task.isDone).length;
  }

  get errorCount() {
    return this.taskItems?.filter((item) => item.task.isError).length;
  }
}
