<div class="flex justify-between pb-4 pt-2">
  <div class="flex items-start font-bold">
    <div class="catalog-handle" cdkDragHandle>
      <button>
        <app-icon icon="unfold_more" class="text-gray-600"></app-icon>
      </button>
    </div>

    <div class="-1" *ngIf="!block.isInEditMode; else editName">
      {{ block.properties.heading }}
    </div>

    <ng-template #editName>
      <input
        type="text"
        id="collectionName"
        name="collectionName"
        [(ngModel)]="block.properties.heading"
        class="font-bold border-b-2 border-purple-300"
      />
    </ng-template>
  </div>

  <app-casteditor-edit-toggle
    [ngClass]="{
      invisible: disableEdit
    }"
    (deleteBlock)="onDeleteBlock()"
  >
  </app-casteditor-edit-toggle>
</div>

<div class="h-48 p-5">
  <div>
    <div class="w-1/4 h-4 bg-gray-200 rounded mb-2"></div>

    <div class="flex">
      <div class="w-10 h-10 bg-gray-400 mr-2"></div>
      <div class="flex-1">
        <div class="w-3/4 h-3 bg-gray-100 rounded mb-1"></div>
        <div class="w-1/4 h-3 bg-green-100 rounded"></div>
      </div>
    </div>
  </div>

  <hr class="my-5" />

  <div>
    <div class="w-1/4 h-4 bg-gray-200 rounded mb-2"></div>

    <div class="flex">
      <div class="w-10 h-10 bg-gray-400 mr-2"></div>
      <div class="flex-1">
        <div class="w-3/4 h-3 bg-gray-100 rounded mb-1"></div>
        <div class="w-1/4 h-3 bg-green-100 rounded"></div>
      </div>
    </div>
  </div>
</div>
