import { Apollo, gql } from "apollo-angular";
import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { flatMap, map, switchMap, tap } from "rxjs/operators";
import {
  CourseSchedule,
  CoursesService,
} from "src/app/sinigangnababoywithgabi";
import { firstValueFrom, Observable } from "rxjs";
import { ModalService } from "src/app/services/modal.service";
import { ItemListComponent } from "src/app/app-common-module/components/item-list/item-list.component";

interface UserEnrollmentItem {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  enrollmentUuid: string;
}

// Deprecated
@Component({
  selector: "app-course-batch-enrollments-list",
  templateUrl: "./course-batch-enrollments-list.component.html",
})
export class CourseBatchEnrollmentsListComponent implements OnInit {
  schedule: CourseSchedule;

  enrollmentCount: number;
  pageSize = 20;

  userListObservable: Observable<UserEnrollmentItem[]>;

  @ViewChild(ItemListComponent)
  itemListComponent: ItemListComponent;

  constructor(
    private route: ActivatedRoute,
    private apollo: Apollo,
    private modalService: ModalService,
    private coursesService: CoursesService
  ) {}

  ngOnInit(): void {
    this.userListObservable = this.setUpUserObservable();
  }

  setUpUserObservable() {
    return this.route.queryParams.pipe(
      switchMap((params) => {
        let scheduleUuid = params["schedule_uuid"];
        let limit = this.pageSize;
        let offset = params.page * this.pageSize || 0;

        return this.apollo.query({
          query: gql`
            query AdminStuff($schedule_uuid: ID!, $limit: Int, $offset: Int) {
              admin {
                schedule(uuid: $schedule_uuid) {
                  name
                  start
                  end

                  fullEnrollments: enrollments {
                    uuid
                  }

                  enrollments(limit: $limit, offset: $offset) {
                    uuid
                    user {
                      id
                      first_name
                      last_name
                      email
                    }
                  }
                }
              }
            }
          `,
          variables: {
            scheduleUuid,
            limit,
            offset,
          },
        });
      }),
      map((result: any) => result.data.admin),
      tap((result: any) => {
        this.schedule = result.schedule;
        this.enrollmentCount = result.schedule?.fullEnrollments?.length;
      }),
      map((result) => {
        return result.schedule?.enrollments
          .filter((enrollment) => enrollment.user)
          .map((enrollment) => {
            let user = enrollment.user;

            return {
              id: user.id,
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.email,
              enrollmentUuid: enrollment.uuid,
            };
          });
      })
    );
  }

  async removeEnrollment(userEnrollmentItem: UserEnrollmentItem) {
    let result = await this.modalService.confirm(
      `Are you sure you want to remove ${userEnrollmentItem.firstName} ${userEnrollmentItem.lastName} from the batch?`
    );

    if (result) {
      await firstValueFrom(
        this.coursesService.courseUnenrollBatch({
          unenrolls: [
            { uuid: userEnrollmentItem.enrollmentUuid, isActive: false },
          ],
        })
      );

      this.itemListComponent.refreshList();
    }
  }
}
