<div class="relative min-h-40">
  <app-loading [loading]="!roles" position="absolute">
    <div class="grid gap-2 xl:grid-cols-2">
      <ng-container *ngFor="let role of roles">
        <label class="flex my-5">
          <app-radio
            [disabled]="disabled"
            [value]="role.id"
            [ngClass]="disabled ? 'text-gray-400' : 'text-primary-700'"
            [ngModel]="selectedRoleId"
            (ngModelChange)="writeValue($event)"
          ></app-radio>

          <div class="mx-2">
            <div
              class="font-bold form-label"
              [ngClass]="isCustomRole(role) ? 'text-black' : 'text-primary-700'"
            >
              {{ role.name }}
            </div>

            <div class="text-sm text-gray-700">{{ role.description }}</div>

            <a
              [routerLink]="['permissions-toggle']"
              [queryParams]="{ role_uuid: role.id }"
              queryParamsHandling="merge"
              class="text-primary-700"
              >view permissions</a
            >
          </div>
        </label>
      </ng-container>
    </div>
  </app-loading>
</div>
<a
  class="px-2 py-1 uppercase bg-transparent rounded hover:bg-gray-100 text-primary-700"
  href="/users-and-groups/groups/profile/edit/new-role"
>
  <app-icon icon="add"></app-icon>

  <span>add new role</span>
</a>
