import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { RouteWithData } from "src/extensions/route-with-data";
import { PurchaseListComponent } from "./purchase-list/purchase-list.component";
import { PurchasePaymentOptionComponent } from "./purchase-payment-option/purchase-payment-option.component";
import { PurchaseProductSelectUserComponent } from "./purchase-product-select-user/purchase-product-select-user.component";
import { PurchaseProductDetailComponent } from "./purchase-product-detail/purchase-product-detail.component";
import { PurchaseSelectNewComponent } from "./purchase-select-new/purchase-select-new.component";
import { PurchaseSubscriptionEditComponent } from "./purchase-subscription-edit/purchase-subscription-edit.component";
import { PurchaseSubscriptionNewComponent } from "./purchase-subscription-new/purchase-subscription-new.component";
import { PurchasesComponent } from "./purchases.component";
import { PurchaseProductUserManualPaymentComponent } from "./purchase-product-user-manual-payment/purchase-product-user-manual-payment.component";

const routes: RouteWithData[] = [
  {
    path: "purchases",
    component: PurchasesComponent,
    children: [
      {
        path: "list",
        data: {
          layoutTitle: "Purchases",
        },
        children: [
          {
            path: "",
            component: PurchaseListComponent,
          },
          {
            path: "select-new",
            component: PurchaseSelectNewComponent,
            data: {
              layoutTitle: "New",
              hideBackTitleDiagonalDesign: true,
            },
          },
          {
            path: "payment-option",
            component: PurchasePaymentOptionComponent,
            data: {
              layoutTitle: "Purchases",
              hideBackTitleDiagonalDesign: true,
            },
          },
          {
            path: "subscription-new",
            component: PurchaseSubscriptionNewComponent,
            data: {
              layoutTitle: "Purchases",
              hideBackTitleDiagonalDesign: true,
            },
          },
          {
            path: "subscription-edit",
            component: PurchaseSubscriptionEditComponent,
            data: {
              layoutTitle: "Edit",
              hideBackTitleDiagonalDesign: true,
            },
          },
          {
            path: "product-detail",
            data: {
              layoutTitle: "Product Detail",
              hideBackTitleDiagonalDesign: true,
            },
            children: [
              {
                component: PurchaseProductDetailComponent,
                path: "",
              },
              {
                path: "add-user",
                data: {
                  layoutTitle: "Add Users",
                  hideBackTitleDiagonalDesign: true,
                },
                children: [
                  {
                    path: "",
                    component: PurchaseProductSelectUserComponent,
                  },
                  {
                    path: "manual-payment",
                    component: PurchaseProductUserManualPaymentComponent,
                    data: {
                      layoutTitle: "Input Manual Payment",
                      hideBackTitleDiagonalDesign: true,
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PurchasesRoutingModule {}
