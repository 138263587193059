import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { PassService, CoursesService } from "src/app/sinigangnababoywithgabi";
import { Location } from "@angular/common";
import { ModalService } from "src/app/services/modal.service";
import { DashboardService } from "src/app/services/dashboard.service";
import {
  ActivityLogService,
  VERBS,
} from "src/app/services/activity-log.service";
import { firstValueFrom } from "rxjs";

@Component({
  selector: "app-user-account-details",
  templateUrl: "./user-account-details.component.html",
})
export class UserAccountDetailsComponent implements OnInit {
  formGroup: UntypedFormGroup;
  isLoading: boolean;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private passService: PassService,
    private location: Location,
    private modalService: ModalService,
    private dashboardService: DashboardService,
    private coursesService: CoursesService,
    private activityLogService: ActivityLogService
  ) {}

  ngOnInit() {
    this.loadData();
  }

  async loadData() {
    let { user_uuid: userUuid } = this.route.snapshot.queryParams;

    let user = await firstValueFrom(this.passService.getUser(userUuid));

    let cast = await firstValueFrom(this.dashboardService.getCast());

    let isPasswordExpired;

    if (
      user.passwordExpiresAt &&
      new Date(user.passwordExpiresAt) < new Date()
    ) {
      isPasswordExpired = true;
    }

    this.formGroup = this.formBuilder.group({
      userUuid: [user.id],
      email: [user.email, Validators.required],
      isUserConfirmed: [!!user.confirmedAt],
      isPasswordExpired: [isPasswordExpired],
      isActive: [
        !user.groupIds.includes(cast.data.attributes.deactivatedUsersGroupId),
      ],
    });
  }

  async save() {
    let body = {
      user: {
        email: this.formGroup.value.email,
        passwordExpiresAt: this.formGroup.value.isPasswordExpired
          ? new Date().toISOString()
          : null,
      },
    };

    await firstValueFrom(
      this.passService.updateUser(this.formGroup.value.userUuid, body)
    );

    this.location.back();
  }

  async deactivateUser() {
    try {
      let areYouSure = await this.modalService.confirm(
        "Do you really want to deactivate the user?",
        {
          confirmText: "Deactivate",
          confirmClass: "btn text-white bg-red-700",
          cancelText: "No",
        }
      );

      if (!areYouSure) {
        return;
      }

      this.isLoading = true;

      let cast = await firstValueFrom(this.dashboardService.getCast());

      if (!cast.data.attributes.deactivatedUsersGroupId) {
        await this.modalService.alert(
          "Inactive group not found. Dashboard will automatically create one."
        );

        await firstValueFrom(this.dashboardService.createInactiveGroup());
      }

      //Remove user from course
      let areYouSureCourses = await this.modalService.confirm(
        "User might be enrolled in some courses. Do you also want to remove the existing enrollments?",
        {
          confirmText: "Remove",
          confirmClass: "btn text-white bg-red-700",
          cancelText: "No, keep the enrollments",
        }
      );

      if (areYouSureCourses) {
        let enrollments = await firstValueFrom(
          this.coursesService.courseUserEnrollmentList(
            this.formGroup.value.userUuid
          )
        );
        let unenroll = enrollments.map((enrollment) => {
          return {
            uuid: enrollment.uuid,
            isActive: false,
          };
        });

        await firstValueFrom(
          this.coursesService.courseUnenrollBatch({
            unenrolls: unenroll,
          })
        );

        // await Promise.all(
        //   enrollments.map((enrollment) =>
        //     firstValueFrom(
        //       this.coursesService.enrollmentDelete(enrollment.uuid)
        //     )
        //   )
        // );
      }

      //Add user to inactive grouph

      let groupIds = [
        cast.data.attributes.deactivatedUsersGroupId,
        cast.data.attributes.defaultGroupId,
      ];

      await firstValueFrom(
        this.passService.updateUser(this.formGroup.value.userUuid, {
          user: {
            groupIds,
          },
        })
      );

      this.formGroup.get("isActive").setValue(false);
    } catch (err) {
      throw err;
    } finally {
      this.isLoading = false;
    }

    this.activityLogService.didUserInParent(VERBS.deactivated, {
      id: this.formGroup.value.userUuid,
      email: this.formGroup.value.email,
    });
  }

  async activateUser() {
    try {
      let result = await this.modalService.confirm(
        "Do you really want to activate the user?"
      );

      if (!result) {
        return;
      }

      this.isLoading = true;

      let cast = await firstValueFrom(this.dashboardService.getCast());

      //Add user to inactive group

      await firstValueFrom(
        this.passService.updateUser(this.formGroup.value.userUuid, {
          user: {
            groupIds: [cast.data.attributes.defaultGroupId],
          },
        })
      );

      this.formGroup.get("isActive").setValue(true);
    } catch (err) {
      throw err;
    } finally {
      this.isLoading = false;
    }

    this.activityLogService.didUserInParent(VERBS.activated, {
      id: this.formGroup.value.userUuid,
      email: this.formGroup.value.email,
    });
  }

  async resendConfirmationEmail() {
    let result = await this.modalService.confirm(
      `Do you want to resend the confirmation email for ${this.formGroup.value.email}?`
    );

    if (result) {
      try {
        await firstValueFrom(
          this.passService.sendUserConfirmationEmail({
            user: {
              email: this.formGroup.value.email,
            },
          })
        );

        this.modalService.alert("Confirmation email sent!");
      } catch (err) {
        this.modalService.alert("Confirmation not sent due to error.");
      }
    }
  }
}
