<app-back-title-layout
  layoutTitle="Remove Members - Email List"
  [loading]="!group"
>
  <div class="my-5">
    <label>
      <span class="form-label">Emails</span>
    </label>

    <textarea
      rows="10"
      class="form-control"
      placeholder="Enter/paste emails separated by new line..."
      #list
    ></textarea>
  </div>

  <button class="btn btn-primary w-full" (click)="startRemoveUsers(list.value)">
    Start
  </button>

  <div class="my-5" *ngIf="taskItems">
    <div class="text-sm">
      <div class="p-2 text-gray-600 rounded" *ngIf="pendingCount">
        <app-icon icon="check_circle"></app-icon>

        {{ pendingCount }} of {{ taskItems?.length }} pending.
      </div>

      <div class="p-2 text-green-600 rounded" *ngIf="successCount">
        <app-icon icon="check_circle"></app-icon>

        {{ successCount }} of {{ taskItems?.length }} successfully processed!
      </div>

      <div class="p-2 text-error-dark rounded" *ngIf="errorCount">
        <app-icon icon="check_circle"></app-icon>

        {{ errorCount }} of {{ taskItems?.length }} errors.
      </div>
    </div>

    <app-item-list
      [items]="taskItems"
      [itemTemplate]="itemTemplate"
    ></app-item-list>

    <!-- <ul>
      <ng-container *ngFor="let task of tasks">
        <li class="border-b border-t py-1 flex items-center">
          <span class="mx-3 w-6">
            <app-icon
              *ngIf="task.success === true"
              class="text-green-600"
              icon="check"
            ></app-icon>
            <app-icon
              *ngIf="task.success === false"
              class="text-red-600"
              icon="close"
            ></app-icon>
          </span>
          <div class="leading-tight text-sm">
            <div>{{ task.data.email }}</div>
            <div
              [ngClass]="{
                'text-green-600': task.success === true,
                'text-red-600': task.success === false
              }"
              class="text-gray-600"
            >
              {{ task.message }}
            </div>
          </div>
        </li>
      </ng-container>
    </ul> -->
  </div>
</app-back-title-layout>

<ng-template #itemTemplate let-item="item">
  <div class="flex">
    <app-icon
      icon="person"
      class="text-gray-600 text-4xl mr-2 leading-none"
    ></app-icon>

    <div>
      <div class="leading-tight truncate font-semibold">
        {{ item.email }}
      </div>

      <!-- <div class="text-sm text-gray-700">{{ item.email }}</div> -->

      <div class="text-xs">
        <ng-container *ngFor="let step of item.task.steps">
          <div class="text-gray-500" *ngIf="step.isPending">
            <app-icon icon="remove"></app-icon>
            {{ step.message }}
          </div>
          <div class="text-green-500" *ngIf="step.isDone">
            <app-icon icon="check"></app-icon>
            {{ step.message }}
          </div>
          <div class="text-error-dark" *ngIf="step.isError">
            <app-icon icon="close"></app-icon>
            {{ step.message }}
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>
