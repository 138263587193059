<app-upload-wrapper (upload)="onUpload($event)" accept="video/*">
  <div
    @fadeInOut
    *ngIf="loading"
    class="absolute inset-0 text-primary bg-white bg-opacity-75 flex flex-col items-center justify-center border rounded w-full"
  >
    <app-loading wrapperClass="relative block" [loading]="true"> </app-loading>
    <!-- <app-icon class="text-6xl" [spin]="true" icon="autorenew"></app-icon> -->
  </div>
  <div
    *ngIf="!formGroup.value.content?.uri"
    class="border bg-purple-200 text-purple-500 rounded w-full flex flex-col items-center py-10"
  >
    <app-icon icon="upload" class="text-6xl"></app-icon>
    <span>DRAG AND DROP FILE HERE</span>
  </div>

  <ng-container
    *ngIf="
      formGroup.value.content?.hlsUri ||
      (formGroup.value.content?.dashUri && hasVideo)
    "
  >
    <div class="h-40 bg-gray-400 relative" #videoContainer>
      <video
        class="h-full w-full cue:{{
          formGroup.value.content?.subtitleClass
        }} cue-fullscreen:{{ formGroup.value.content?.subtitleClass }}"
        crossorigin="anonymous"
        #videoPlayer
      >
        <track
          *ngFor="let track of formGroup.value.content?.tracks"
          [label]="track.label"
          [kind]="track.kind"
          [srclang]="track.srclang"
          [src]="track.uri"
          [default]="track.default"
        />
      </video>
    </div>
  </ng-container>
  <ng-container
    *ngIf="
      formGroup.value.content?.uri &&
      (!formGroup.value.content?.hlsUri || !formGroup.value.content?.dashUri) &&
      hasVideo
    "
  >
    <div class="h-40 bg-gray-400 relative">
      <video
        controls
        class="h-full w-full cue:{{
          formGroup.value.content?.subtitleClass
        }} cue-fullscreen:{{ formGroup.value.content?.subtitleClass }}"
        crossorigin="anonymous"
      >
        <source [src]="formGroup.value.content?.uri" />
        <track
          *ngFor="let track of formGroup.value.content?.tracks"
          [label]="track.label"
          [kind]="track.kind"
          [srclang]="track.srclang"
          [src]="track.uri"
          [default]="track.default"
        />
      </video></div
  ></ng-container>

  <app-form-control-error-layout
    [control]="
      formGroup.get('content').get('hlsUri') ||
      formGroup.get('content').get('dashUri') ||
      formGroup.get('content').get('uri')
    "
  ></app-form-control-error-layout>

  <div
    *ngIf="
      formGroup.value.content?.hlsUri ||
      formGroup.value.content?.dashUri ||
      formGroup.value.content?.uri
    "
    class="truncate text-purple-500 text-sm my-1"
  >
    {{
      formGroup.value.content?.hlsUri ||
        formGroup.value.content?.dashUri ||
        formGroup.value.content?.uri
    }}
  </div>

  <button
    type="button"
    class="btn w-full my-1 py-0 text-sm bg-purple-500 text-white"
  >
    CHANGE
  </button>
</app-upload-wrapper>

<ng-container *ngIf="formGroup.value.content?.uri">
  <a
    [href]="formGroup.value.content.uri"
    target="_blank"
    class="block btn w-full my-1 py-0 text-sm bg-blue-500 text-white"
  >
    <!-- <app-icon icon="eye"></app-icon> -->
    PREVIEW
  </a>
</ng-container>

<div class="my-10">
  <label class="form-label leading-none cursor-default">
    Upload Subtitles
  </label>
  <div class="text-sm text-gray-600 mb-1">File should be in .vtt</div>

  <div
    *ngIf="formGroup.value.content?.tracks[0]?.uri"
    class="truncate text-purple-500 text-sm my-1"
  >
    {{ formGroup.value.content?.tracks[0]?.uri }}
  </div>

  <input
    #fileSubInput
    type="file"
    class="hidden"
    accept=".vtt"
    (change)="subFileChange($event)"
  />

  <button
    type="button"
    (click)="fileSubInput.click()"
    class="btn my-1 py-1 text-sm bg-purple-500 text-white"
    [disabled]="!hasVideo"
  >
    CHANGE
  </button>

  <div class="mt-4" [formGroup]="contentFormGroup">
    <label for="fontSize" class="form-label leading-none cursor-default"
      >Default Subtitle Font Size</label
    >
    <input
      (change)="changeSubtitleSize($event)"
      id="fontSize"
      [value]="rangeValue"
      type="range"
      max="12"
      step="2"
      min="0"
      class="w-full"
      [attr.list]="'fontSizeList'"
      [attr.disabled]="!formGroup.value.content?.tracks[0]?.uri || null"
    />
    <div class="form-label leading none">
      {{ fontSizePx }}
    </div>
    <datalist id="fontSizeList">
      <option value="0" label="XS"></option>
      <option value="2" label="S"></option>
      <option value="4" label="M"></option>
      <option value="6" label="L"></option>
      <option value="8" label="XL"></option>
      <option value="10" label="2XL"></option>
      <option value="12" label="3XL"></option>
    </datalist>
  </div>
</div>

<div class="my-10" [formGroup]="contentFormGroup">
  <label class="form-label leading-none cursor-default">
    Video Description
  </label>
  <div class="text-sm text-gray-600 mb-1">
    This description appears right under the video
  </div>

  <app-markdown-viewer-and-editor
    [autoResize]="false"
    editorClass="h-40 overflow-y-scroll"
    viewerClass="h-40 overflow-y-scroll border rounded p-2"
    formControlName="videoDescription"
  ></app-markdown-viewer-and-editor>
</div>
