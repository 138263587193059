import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { firstValueFrom, Observable } from "rxjs";
import { map } from "rxjs/operators";
import {
  LogItem,
  ActivityLogService,
  ACTIVITY_TYPES,
} from "src/app/services/activity-log.service";
import { DashboardService } from "src/app/services/dashboard.service";

interface LogListItem {
  date?: string;
  latest?: boolean;
  log?: LogItem[];
}

@Component({
  selector: "app-group-activity-logs",
  templateUrl: "./group-activity-logs.component.html",
  styles: [],
})
export class GroupActivityLogsComponent implements OnInit {
  lastEdit: LogItem;

  logListObservable: Observable<LogListItem[]>;

  constructor(
    private dashboardService: DashboardService,
    private activityLogService: ActivityLogService,
    private route: ActivatedRoute
  ) {}

  async ngOnInit() {
    this.loadData();
  }
  async loadData() {
    let { group_uuid: groupUuid } = this.route.snapshot.queryParams;

    let cast = await firstValueFrom(this.dashboardService.getCast());

    let match = {
      $or: [
        {
          $and: [
            {
              "statement.object.definition.type": {
                $in: [ACTIVITY_TYPES.group],
              },
            },
            {
              "statement.object.id":
                this.activityLogService.constructGroupObject({
                  id: groupUuid,
                }).id,
            },
          ],
        },
      ] as any,
    };

    if (cast.data.attributes.defaultGroupId === groupUuid) {
      match.$or.push({
        "statement.context.contextActivities.parent.id":
          this.activityLogService.constructGroupObject({
            id: groupUuid,
          }).id,
      });
    }

    this.logListObservable = this.activityLogService
      .aggregate([
        {
          $match: match,
        },
        {
          $project: {
            group: {
              $dateToString: {
                date: "$timestamp",
                format: "%Y-%m-01",
                timezone: "+08:00",
              },
            },
            statement: 1,
            timestamp: "$statement.timestamp",
          },
        },
        {
          $sort: {
            "statement.timestamp": -1,
          },
        },
        {
          $limit: 100,
        },
        {
          $group: {
            _id: "$group",
            statements: {
              $push: "$statement",
            },
          },
        },
        {
          $sort: {
            _id: -1,
          },
        },
      ])
      .pipe(
        map((result) => {
          let logListItems: LogListItem[] = result.map((item) => {
            let logListItem: LogListItem = {
              date: item._id,
              latest: false,
              log: item.statements.map((statement) =>
                this.activityLogService.constructLogItemFromStatement(statement)
              ),
            };

            return logListItem;
          });

          this.lastEdit = logListItems[0]?.log[0];

          return logListItems;
        })
      );
  }
}
