<!-- header -->
<div class="flex text-gray-400">
  <app-icon icon="filter_list"> </app-icon>
  <div class="ml-2 uppercase w-full">filter</div>
  <button class="flex justify-end text-lg" mat-dialog-close>
    <app-icon icon="close"></app-icon>
  </button>
</div>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <!-- filters -->
  <div>
    <div class="flex flex-col" [formGroup]="form.controls.first">
      <div class="flex w-full">
        <div class="pt-2">Where</div>
        <select
          class="form-control mx-2"
          (change)="firstField($event, 0)"
          formControlName="filter1"
        >
          <option [value]="null" disabled>Select</option>
          <option *ngFor="let option of mainDropDown" [value]="option.value">
            {{ option.name }}
          </option>
        </select>

        <select
          *ngIf="
            form.controls.first.get('filter1').value !== 'User' &&
            form.controls.first.get('filter1').value !== 'Group' &&
            form.controls.first.get('filter1').value !== 'Course'
          "
          class="form-control"
          (change)="secondField($event, 0)"
          formControlName="filter2"
        >
          <option [value]="null" disabled>Select</option>
          <option *ngFor="let option of filter1[0]" [value]="option.value">
            {{ option.name }}
          </option>
        </select>
        <div
          *ngIf="
            form.controls.first.get('filter1').value === 'User' ||
            form.controls.first.get('filter1').value === 'Group' ||
            form.controls.first.get('filter1').value === 'Course'
          "
          (click)="openModal(type[0], 0)"
          class="w-full"
        >
          <select
            class="form-control"
            formControlName="filter2"
            (mousedown)="preventDropDown($event)"
          >
            <option [value]="null" disabled>Select {{ type[0] }}</option>
            <option [value]="form.controls.first.get('filter2').value">
              <ng-container *ngIf="form.controls.first.get('filter2').value">{{
                form.controls.first.get("filter2").value.name
              }}</ng-container>
            </option>

            <!-- <app-icon [icon]="'expand_more'" class="text-gray-400"></app-icon> -->
          </select>
        </div>
        <ng-container
          *ngIf="form.controls.first.get('filter1').value === 'Email'"
        >
          <input
            placeholder="Enter Keyword"
            class="form-control mx-2"
            formControlName="filter3"
          />
        </ng-container>

        <ng-container
          *ngIf="form.controls.first.get('filter1').value === 'Last Sign In'"
        >
          <input
            type="date"
            class="mr-2 form-control"
            id="date"
            formControlName="filter3"
          />
        </ng-container>
      </div>
      <ng-container
        *ngIf="
          form.controls.first.get('filter1').value === 'Course' &&
          form.controls.first.get('filter2').value
        "
      >
        <div class="flex mt-2 w-full">
          <select
            formControlName="filter3"
            class="form-control mx-2"
            (change)="secondField($event, 0)"
          >
            <option [value]="null" disabled>Select</option>
            <option [value]="option.value" *ngFor="let option of filter2[0]">
              {{ option.name }}
            </option>
          </select>
          <div
            *ngIf="type2[0] === 'Batch'"
            (click)="openModal(type2[0], 0)"
            class="w-full"
          >
            <select
              formControlName="filter4"
              class="form-control"
              (mousedown)="preventDropDown($event)"
            >
              <option [value]="null" disabled>Select {{ type2[0] }}</option>
              <option [value]="form.controls.first.get('filter4').value">
                <ng-container
                  *ngIf="form.controls.first.get('filter4').value"
                  >{{
                    form.controls.first.get("filter4").value.name
                  }}</ng-container
                >
              </option>
            </select>
          </div>
          <select
            *ngIf="type2[0] === 'student status'"
            formControlName="filter4"
            class="form-control"
          >
            <option [value]="null" disabled>Select {{ type2[0] }}</option>
            <option [value]="option.value" *ngFor="let option of filter3[0]">
              {{ option.name }}
            </option>
          </select>
        </div>
      </ng-container>
    </div>
    <hr class="form-divider" />
    <div formArrayName="filters">
      <ng-container *ngIf="_filters.length > 0">
        <div *ngFor="let filter of _filters; let i = index" class="mt-2">
          <div class="flex flex-col" [formGroup]="filter">
            <div class="flex w-full">
              <div class="pt-2 ml-2">and</div>
              <select
                class="form-control mx-2"
                (change)="firstField($event, i + 1)"
                formControlName="filter1"
              >
                <option [value]="null" disabled>Select</option>
                <option
                  *ngFor="let option of mainDropDown"
                  [value]="option.value"
                >
                  {{ option.name }}
                </option>
              </select>
              <select
                *ngIf="
                  type[i + 1] !== 'User' &&
                  type[i + 1] !== 'Group' &&
                  type[i + 1] !== 'Course'
                "
                class="form-control mx-2"
                (change)="secondField($event, i + 1)"
                formControlName="filter2"
              >
                <option [value]="null" disabled>Select</option>
                <option
                  *ngFor="let option of filter1[i + 1]"
                  [value]="option.value"
                >
                  {{ option.name }}
                </option>
              </select>
              <div
                *ngIf="
                  type[i + 1] === 'User' ||
                  type[i + 1] === 'Group' ||
                  type[i + 1] === 'Course'
                "
                (click)="openModal(type[i + 1], i + 1)"
                class="w-full"
              >
                <select
                  class="form-control"
                  (change)="secondField($event, i + 1)"
                  formControlName="filter2"
                  (mousedown)="preventDropDown($event)"
                >
                  <option [value]="null" disabled>
                    Select {{ type[i + 1] }}
                  </option>
                  <option [value]="filter.controls.filter2.value">
                    <ng-container *ngIf="filter.controls.filter2.value">{{
                      filter.controls.filter2.value.name
                    }}</ng-container>
                  </option>
                </select>
              </div>
              <ng-container *ngIf="type[i + 1] === 'Email'">
                <input
                  placeholder="Enter Keyword"
                  class="form-control mx-2"
                  formControlName="filter3"
                />
              </ng-container>
              <ng-container *ngIf="type[i + 1] === 'Last Sign In'">
                <input
                  type="date"
                  class="mr-2 form-control"
                  id="date"
                  formControlName="filter3"
                />
              </ng-container>
              <button
                type="button"
                class="text-lg text-gray-400"
                (click)="removeFilter(i + 1)"
              >
                <app-icon icon="close"></app-icon>
              </button>
            </div>
            <ng-container
              *ngIf="
                filter.get('filter1').value === 'Course' &&
                filter.get('filter2').value
              "
            >
              <div class="flex mt-2">
                <select
                  formControlName="filter3"
                  class="form-control mx-2"
                  (change)="secondField($event, i + 1)"
                >
                  <option [value]="null" disabled>Select</option>
                  <option
                    [value]="option.value"
                    *ngFor="let option of filter2[i + 1]"
                  >
                    {{ option.name }}
                  </option>
                </select>
                <div
                  *ngIf="type2[i + 1] === 'Batch'"
                  (click)="openModal(type2[i + 1], i + 1)"
                  class="w-full"
                >
                  <select
                    formControlName="filter4"
                    (mousedown)="preventDropDown($event)"
                    class="form-control"
                  >
                    <option [value]="null" disabled>
                      Select {{ type2[i + 1] }}
                    </option>
                    <option [value]="filter.get('filter4').value">
                      <ng-container *ngIf="filter.get('filter4').value">{{
                        filter.get("filter4").value.name
                      }}</ng-container>
                    </option>
                  </select>
                </div>
                <select
                  *ngIf="type2[i + 1] === 'student status'"
                  formControlName="filter4"
                  class="form-control mx-2"
                >
                  <option [value]="null" disabled>
                    Select {{ type2[i + 1] }}
                  </option>
                  <option
                    [value]="option.value"
                    *ngFor="let option of filter3[i + 1]"
                  >
                    {{ option.name }}
                  </option>
                </select>
              </div>
            </ng-container>
          </div>
          <hr class="form-divider" />
        </div>
      </ng-container>
    </div>
  </div>
  <!-- add filter button -->
  <button type="button" (click)="addFilter()" class="flex text-gray-400 my-4">
    <app-icon icon="add"> </app-icon>
    <div class="uppercase ml-2 text-primary-600">add more filter</div>
  </button>
  <!-- filters tab -->
  <div *ngIf="form.controls.first" class="mb-4 flex flex-wrap">
    <div
      *ngIf="form.controls.first.get('filter1').value"
      class="w-max leading-none border text-gray-400 bg-gray-200 rounded-full p-1 mr-1 mb-1"
    >
      {{ form.controls.first.get("filter1").value }}
      <ng-container *ngIf="form.controls.first.get('filter2').value">
        <ng-container *ngIf="form.controls.first.get('filter2').value.name">{{
          form.controls.first.get("filter2").value.name
        }}</ng-container>
        <ng-container *ngIf="!form.controls.first.get('filter2').value.name">
          {{ form.controls.first.get("filter2").value }}
        </ng-container>
      </ng-container>
      <!-- {{ form.controls.first.get("filter2").value }} -->
      {{ form.controls.first.get("filter3").value }}
      <ng-container *ngIf="form.controls.first.get('filter4').value">
        <ng-container *ngIf="form.controls.first.get('filter4').value.name">{{
          form.controls.first.get("filter4").value.name
        }}</ng-container>
        <ng-container *ngIf="!form.controls.first.get('filter4').value.name">
          {{ form.controls.first.get("filter4").value }}
        </ng-container>
      </ng-container>
      <button type="button">
        <app-icon [icon]="'close'"></app-icon>
      </button>
    </div>
    <ng-container *ngFor="let filter of _filters; let i = index">
      <div
        *ngIf="filter.get('filter1').value"
        class="w-max leading-none border text-gray-400 bg-gray-200 rounded-full p-1 mr-1 mb-1"
      >
        {{ filter.get("filter1").value }}
        <ng-container *ngIf="filter.get('filter2').value">
          <ng-container *ngIf="!filter.get('filter2').value.name">
            {{ filter.get("filter2").value }}
          </ng-container>
          <ng-container *ngIf="filter.get('filter2').value.name">
            {{ filter.get("filter2").value.name }}
          </ng-container>
        </ng-container>

        {{ filter.get("filter3").value }}
        <ng-container *ngIf="filter.get('filter4').value">
          <ng-container *ngIf="!filter.get('filter4').value.name">
            {{ filter.get("filter4").value }}
          </ng-container>
          <ng-container *ngIf="filter.get('filter4').value.name">
            {{ filter.get("filter4").value.name }}
          </ng-container>
        </ng-container>
        <button type="button">
          <app-icon [icon]="'close'" (click)="removeFilter(i + 1)"></app-icon>
        </button>
      </div>
    </ng-container>
  </div>
  <!-- clear all and save buttons -->
  <div class="flex justify-between">
    <button
      type="button"
      (click)="clearAll()"
      class="btn border border-primary-600 rounded text-primary-600 bg-white"
    >
      clear all
    </button>
    <div>
      <button
        type="button"
        mat-dialog-close
        class="btn border border-primary-600 rounded text-primary-600 bg-white mr-2"
      >
        skip
      </button>
      <button type="submit" class="btn btn-primary" mat-dialog-close>
        filter
      </button>
    </div>
  </div>
</form>

<!-- Transition to modalService -->
<ng-template #userModal>
  <app-user-modal
    (user)="modalOutput($event)"
    [currentIndex]="currentIndex"
  ></app-user-modal>
</ng-template>

<ng-template #groupModal>
  <app-group-modal
    (group)="modalOutput($event)"
    [currentIndex]="currentIndex"
  ></app-group-modal>
</ng-template>

<ng-template #courseModal>
  <app-course-modal
    (course)="modalOutput($event)"
    [currentIndex]="currentIndex"
  ></app-course-modal>
</ng-template>

<ng-template #batchModal>
  <app-batch-modal
    [currentIndex]="currentIndex"
    (batch)="modalOutput($event)"
    [course]="batchInput"
  >
  </app-batch-modal>
</ng-template>

<ng-template #filterResultsModal>
  <app-filter-results></app-filter-results>
</ng-template>
