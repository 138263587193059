<div
  class="input-group my-10"
  *ngIf="resource"
  [formGroup]="formGroup.get('content')"
>
  <label>
    <span class="form-label leading-none"> Paste Embed Code </span>
    <!-- <div class="text-sm text-gray-600 mb-1">
      View FAQ to know how to get Google Docs embed code
    </div> -->
  </label>

  <div class="my-2">
    <div
      *ngIf="!formGroup.value.content?.uri"
      class="
        border
        bg-purple-200
        text-purple-500
        rounded
        w-full
        flex flex-col
        items-center
        py-10
      "
    >
      <app-icon icon="visibility" class="text-6xl"></app-icon>
      <span>PREVIEW WINDOW</span>
    </div>

    <div class="w-full h-80" *ngIf="formGroup.value.content?.uri">
      <object
        [data]="formGroup.value.content?.uri | safeResourceUrl"
        type="text/html"
        frameborder="0"
        allowfullscreen="true"
        mozallowfullscreen="true"
        webkitallowfullscreen="true"
        class="w-full h-full"
      ></object>
    </div>
  </div>

  <div class="border-2 rounded">
    <div class="border-b-2 p-1">
      <app-icon icon="code"></app-icon>
    </div>
    <textarea
      #code
      formControlName="embedCode"
      placeholder="&#60; Paste Embed Code here &#62;"
      class="p-1 w-full"
      rows="5"
      (change)="onEmbedChange()"
    ></textarea>
  </div>
  <app-form-control-error-layout
    [control]="formGroup.get('content').get('embedCode')"
  >
  </app-form-control-error-layout>
</div>
