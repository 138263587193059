import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { catchError, Subscription, switchMap, tap } from "rxjs";
import { fadeInOut } from "src/app/app-common-module/animations/fade";
import { MailerService } from "src/app/services/mailer.service";
import { ModalService } from "src/app/services/modal.service";

@Component({
  selector: "app-send-schedule-modal",
  templateUrl: "./send-schedule-modal.component.html",
  styles: [],
  animations: [fadeInOut],
})
export class SendScheduleModalComponent implements OnInit {
  loading: boolean = false;
  mutateSubscription: Subscription;
  @Input() filters: UntypedFormGroup;
  @Input() action: string;
  @Input() emailReq: UntypedFormGroup;
  @Input() mailUuid: string = "";

  constructor(
    private router: Router,
    private mailerService: MailerService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filters.currentValue) {
    }
  }
  ngOnDestroy(): void {
    this.mutateSubscription?.unsubscribe();
  }
  onSubmit() {
    this.mutateSubscription?.unsubscribe();
    this.loading = true;
    let subject = this.emailReq.get("subject").value;
    let email_body = this.emailReq.get("content").value;
    let date = this.emailReq.get("date").value;
    let time = this.emailReq.get("time").value;
    let sendOnDate = new Date(date + "T" + time).toISOString();
    let filters = this.mailerService.parseFilter(this.filters);
    this.mutateSubscription?.unsubscribe();
    if (this.action === "create") {
      this.mutateSubscription = this.mailerService
        .createEmailRequest({
          email_body: email_body,
          subject: subject,
          send_on_date: sendOnDate,
          filters: filters,
        })
        .pipe(
          tap(({ data, loading, errors }) => {
            if (errors) {
              throw new Error(errors[0]?.message);
            }
          }),
          switchMap(({ data, loading }) => {
            this.loading = loading;
            this.router.navigate(["_mailer"]);
            return this.modalService.alert("Schedule saved!");
          }),
          catchError((error) => {
            this.loading = false;
            return this.modalService.alert(error);
          })
        )
        .subscribe(() => {});
    }
    if (this.action === "edit") {
      this.mutateSubscription = this.mailerService
        .updateEmailRequest(this.mailUuid, {
          email_body: email_body,
          subject: subject,
          send_on_date: sendOnDate,
          filters: filters,
        })
        .pipe(
          tap(({ data, loading, errors }) => {
            if (errors) {
              throw new Error(errors[0]?.message);
            }
          }),
          switchMap(({ data, loading }) => {
            this.loading = loading;

            this.router.navigate(["_mailer"]);

            return this.modalService.alert("Schedule saved");
          }),
          catchError((error) => {
            this.loading = false;
            return this.modalService.alert(error);
          })
        )
        .subscribe(() => {});
    }
  }
}
