import { Component, OnInit, Input } from "@angular/core";
import { Resource } from "src/app/sinigangnababoywithgabi";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  AbstractControl,
  ValidatorFn,
  UntypedFormArray,
} from "@angular/forms";
import { Subscription } from "rxjs";

// Url validator
// From: https://stackoverflow.com/questions/3809401/what-is-a-good-regular-expression-to-match-a-url
export function url(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const allowed =
      /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g.test(
        control.value
      );
    return allowed ? null : { url: { value: control.value } };
  };
}

@Component({
  selector: "app-resource-type-upload-link",
  templateUrl: "./resource-type-upload-link.component.html",
})
export class ResourceTypeUploadLinkComponent implements OnInit {
  @Input()
  formGroup: UntypedFormGroup;

  @Input()
  resource: Resource;

  subscription: Subscription = new Subscription();

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    let resourceQueryParams = this.resource.content["queryParams"]?.map(
      (param) => {
        return this.formBuilder.group({
          name: [param.name, Validators.required],
          value: [param.value, Validators.required],
        });
      }
    );

    this.formGroup.addControl(
      "content",
      this.formBuilder.group({
        uri: [
          this.resource.content["uri"],
          this.formGroup.value.subtype !== "flarum"
            ? [url(), Validators.required]
            : [],
        ],
        email: [
          this.formGroup.value.subtype === "email"
            ? this.resource.content?.["uri"]?.replace("mailto:", "")
            : "",
          Validators.email,
        ],
        queryParams: this.formBuilder.array(resourceQueryParams || []),
      })
    );

    //Automatically append https on links without domain

    this.subscription.add(
      this.formGroup
        .get("content")
        .get("uri")
        .valueChanges.subscribe((result) => {
          if (!/^http.*/.test(result)) {
            this.formGroup
              .get("content")
              .get("uri")
              .setValue(`https://${result}`, {
                emitEvent: false,
              });
          }
        })
    );

    // Listen to email form changes and apply it to url
    if (this.formGroup.value.subtype === "email") {
      this.subscription.add(
        this.formGroup
          .get("content")
          .get("email")
          .valueChanges.subscribe((result) => {
            this.formGroup
              .get("content")
              .get("uri")
              .setValue(`mailto:${result}`, {
                emitEvent: false,
              });
          })
      );
    }
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  addQueryParam() {
    let formArray = this.formGroup
      .get("content")
      .get("queryParams") as UntypedFormArray;

    formArray.push(
      this.formBuilder.group({
        name: [null, Validators.required],
        value: [null, Validators.required],
      })
    );
  }

  removeQueryParam(index: number) {
    let formArray = this.formGroup
      .get("content")
      .get("queryParams") as UntypedFormArray;

    formArray.removeAt(index);
  }
}
