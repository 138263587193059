import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { switchMap, catchError, throwError, finalize } from "rxjs";
import { CsvService } from "src/app/services/csv.service";
import { DashboardService } from "src/app/services/dashboard.service";
import { HumanizeService } from "src/app/services/humanize.service";
import { CoursesService, PassService } from "src/app/sinigangnababoywithgabi";

import { UserCreateCsvComponent } from "src/app/user-management/users/user-create-csv/user-create-csv.component";

// OOP shenanigans haha
@Component({
  selector: "app-course-batch-enrollments-bulk",
  templateUrl: "./course-batch-enrollments-bulk.component.html",
})
export class CourseBatchEnrollmentsBulkComponent extends UserCreateCsvComponent {
  constructor(
    protected dashboardService: DashboardService,
    protected csvService: CsvService,
    protected passService: PassService,
    protected route: ActivatedRoute,
    protected humanizeService: HumanizeService,
    protected coursesService: CoursesService
  ) {
    super(
      dashboardService,
      csvService,
      passService,
      route,
      humanizeService,
      coursesService
    );
  }

  override startUpload() {
    const { schedule_uuid: scheduleUuid, course_uuid: courseUuid } =
      this.route.snapshot.queryParams;

    this.loading = true;
    this.uploadSubscription = this.dashboardService
      .getCast()
      .pipe(
        switchMap((cast) => {
          let defaultGroupId = cast.data.attributes.defaultGroupId;

          // Add default group id on all objects while removing duplicates
          this.items.forEach((item) => {
            item.groupIds = [
              ...new Set([defaultGroupId, ...item.groupIds]),
            ].filter((item) => item);
          });

          // Convert object to csv
          // So `firstName` to `first_name`
          return this.csvService.jsonToCsvFile(
            this.mapToCsvFields(this.items),
            this.fileName || "batch.csv",
            null,
            {
              snakeCaseKeys: true,
            }
          );
        }),
        switchMap((file) => {
          return this.passService.usersCsvImport(file);
        }),
        switchMap((result) => {
          this.response = result;
          let users = [...result.modified, ...result.succeeded];

          return this.coursesService.courseEnrollmentCreateBatch(courseUuid, {
            enrollments: users.map((user) => {
              return {
                schedule: scheduleUuid,
                user: user.id,
                isActive: true,
              };
            }),
          });
        }),
        catchError((err) => {
          return throwError(() => err);
        }),
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe();
  }
}
