import { Component, OnInit, SimpleChanges } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";

@Component({
  selector: "app-purchase-product-select-user",
  templateUrl: "./purchase-product-select-user.component.html",
  styles: [],
})
export class PurchaseProductSelectUserComponent implements OnInit {
  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {}

  private unsubscribe$: Subject<void> = new Subject<void>();
  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  onSelectUserUuid(userUuid: string) {
    this.router.navigate(["manual-payment"], {
      queryParamsHandling: "merge",

      //Apparently required to pag programmatic navigation. Hindi to required kapag nasa html si routerlink
      relativeTo: this.route,

      queryParams: {
        user_uuid: userUuid,
      },
    });
  }
}
