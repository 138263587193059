import { Component, Input } from "@angular/core";
import decamelize from "decamelize";

@Component({
  selector: "app-reporting-chart-table-generic",
  templateUrl: "./reporting-chart-table-generic.component.html",
  styles: [],
})
export class ReportingChartTableGenericComponent {
  @Input() data: any[] = [];

  @Input() icon: string = "";

  columns: string[];
  columnsForDisplay: string[];

  ngOnInit(): void {
    this.columns = Object.keys(this.data[0]);
    this.columnsForDisplay = this.columns.map((column) =>
      decamelize(column, {
        separator: "-",
      })
        .split("-")
        .join(" ")
    );
  }
}
