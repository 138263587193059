<!-- <app-dropzone-view (upload)="onUpload($event)"></app-dropzone-view> -->

<div class="text-center m-2">
  <div
    class="dropzone"
    [dropzone]="dropzoneConfig"
    (success)="onUpload($event)"
  ></div>
</div>

<div class="text-center text-gray-700 m-2">
  <span> Pages Uploaded: {{ getPageCount() }} </span>

  <br />

  <button
    class="bg-purple-500 text-white px-2"
    *ngIf="resource.uuid"
    [routerLink]="['/resource-management', 'misc', 'buribook-pages-view']"
    [queryParams]="{ resource_uuid: resource.uuid, activities: false }"
  >
    View Pages
  </button>
</div>

<div class="mb-4">
  <div *ngFor="let problem of problems" class="text-xs text-red-700">
    {{ problem }}
  </div>
</div>
