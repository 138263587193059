import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  delay,
  firstValueFrom,
  forkJoin,
  Observable,
  of,
  Subject,
  Subscription,
  throwError,
} from "rxjs";
import { catchError, filter, finalize, switchMap, tap } from "rxjs/operators";
import { CsvService } from "src/app/services/csv.service";
import { DashboardService } from "src/app/services/dashboard.service";
import { HumanizeService } from "src/app/services/humanize.service";
import {
  CoursesService,
  Group,
  PassService,
} from "src/app/sinigangnababoywithgabi";

export interface CsvItem {
  firstName: string;
  lastName: string;
  email: string;
  password?: string;
  groupIds?: string[];
  isPasswordExpired?: boolean;
  isRequiredToConfirm?: boolean;
  isDone?: boolean;
  username?: string;
  groupNames?: string[];
  traits?: string;
  deactivate?: boolean;
  uuid?: string;
}

@Component({
  selector: "app-user-create-csv",
  templateUrl: "./user-create-csv.component.html",
})
export class UserCreateCsvComponent implements OnInit {
  fileName: string;
  loading: boolean;

  uploadSubscription: Subscription;

  response: any;

  groups: Group[];

  listSourceObs: Observable<CsvItem[]>;

  items: CsvItem[];

  dataSubject = new Subject();

  pageSize: number = 20;

  constructor(
    protected dashboardService: DashboardService,
    protected csvService: CsvService,
    protected passService: PassService,
    protected route: ActivatedRoute,
    protected humanizeService: HumanizeService,
    protected coursesService: CoursesService
  ) {}

  async ngOnInit() {
    this.groups = await firstValueFrom(this.passService.listGroups()).then(
      (r) => r.groups
    );

    this.listSourceObs = this.dataSubject.pipe(
      switchMap(() => this.route.queryParams),
      switchMap((params) => {
        let page = parseInt(params.page) || 0;

        return of(
          this.items.slice(page * this.pageSize, (page + 1) * this.pageSize)
        );
      })
    );
  }

  ngOnDestroy() {
    this.cancelUpload();
  }

  cancelUpload() {
    if (this.uploadSubscription) {
      this.uploadSubscription.unsubscribe();
      this.loading = false;
    }
  }

  startUpload() {
    this.loading = true;
    this.uploadSubscription = this.dashboardService
      .getCast()
      .pipe(
        switchMap((cast) => {
          let { defaultGroupId, deactivatedUsersGroupId } =
            cast.data.attributes;
          //Map the list into only an array of uuids

          let uuids = [];

          this.items.forEach((item) => {
            if (item.uuid) uuids.push(item.uuid);
          });

          if (uuids.length > 0) {
            return forkJoin(
              uuids.map((uuid) => {
                return this.coursesService.courseUserEnrollmentList(uuid).pipe(
                  switchMap((enrollments) => {
                    if (enrollments.length > 0) {
                      let unenroll = enrollments.map((enrollment) => {
                        return {
                          uuid: enrollment.uuid,
                          isActive: false,
                        };
                      });
                      return this.coursesService.courseUnenrollBatch({
                        unenrolls: unenroll,
                      });
                    }
                    return of({});
                  }),

                  switchMap(() => {
                    return this.passService.updateUser(uuid, {
                      user: {
                        groupIds: [deactivatedUsersGroupId, defaultGroupId],
                      },
                    });
                  })
                );
              })
            );
          }

          //End pipe after deactivation by returning something other than a File

          // Add default group id on all objects while removing duplicates
          this.items.forEach((item) => {
            item.groupIds = [
              ...new Set([defaultGroupId, ...item.groupIds]),
            ].filter((item) => item);
          });

          // Convert object to csv
          // So `firstName` to `first_name`
          return this.csvService.jsonToCsvFile(
            this.mapToCsvFields(this.items),
            this.fileName || "batch.csv",
            null,
            {
              snakeCaseKeys: true,
            }
          );
        }),
        //stops propagation if result is not File
        tap((r) => {
          if (!(r instanceof File)) {
            this.response = {
              deactivated: r,
              modified: [],
              succeeded: [],
              failed: [],
            };
            this.loading = false;
          }
        }),
        filter((result) => result instanceof File),
        switchMap((file) => {
          if (file instanceof File)
            return this.passService.usersCsvImport(file);
        }),
        tap((r) => {
          this.response = r;
        }),
        catchError((err) => {
          return throwError(() => err);
        }),
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe();
  }

  mapToCsvFields(csvItems: CsvItem[]) {
    return csvItems.map((item) => {
      let obj: any = {
        ...item,
        firstName: item.firstName,
        lastName: item.lastName,
        email: item.email,
        password: item.password,
        // Set date today if the user is required to change the password
        passwordExpiresAt: item.isPasswordExpired ? new Date() : null,

        // Assign default group id on top of assigned group ids
        // Filter to remove null ids
        groupIdsCsv: item.groupIds.join(","),
        username: item.username,
      };

      // Set date today if the user is not required to confirm
      if (typeof item.isRequiredToConfirm === "boolean") {
        if (item.isRequiredToConfirm) {
          obj.confirmedAt = null;
        } else {
          obj.confirmedAt = new Date();
        }
      }

      return obj;
    });
  }

  downloadResult() {
    this.csvService.downloadAsCsv(
      this.items.map((item) => {
        return {
          "First Name": item.firstName,
          "Last Name": item.lastName,
          Email: item.email,
          Password: item.password,
          Traits: item.traits,
        };
      }),
      `csvresult${Date.now()}.csv`
    );
  }

  async onDataProcess(items: { [key: string]: string }[]) {
    this.cancelUpload();
    let result: any = this.humanizeService.humanizeBatchUpload({
      items,
      groups: this.groups,
    });
    this.items = result.map((r) => ({
      ...r,
      isDone: false,
    }));

    if (this.items[0].deactivate !== undefined) {
      let itemsWithUuidObservableList = this.items.map((item) => {
        return firstValueFrom(
          this.passService.listUsers(
            null,
            1,
            0,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            [item.email]
          )
        );
      });

      let itemsWithUuid = await Promise.all(itemsWithUuidObservableList);

      this.items = this.items.map((item, i) => {
        if (item.deactivate)
          return {
            ...item,
            uuid: itemsWithUuid[i]?.users[0]?.id,
          };
        return {
          ...item,
        };
      });
    }

    // Needs delay for it to work huhu
    await firstValueFrom(of({}).pipe(delay(10)));

    this.dataSubject.next(this.items);
  }
}
