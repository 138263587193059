import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-casteditor-block-navbar",
  templateUrl: "./casteditor-block-navbar.component.html",
  styleUrls: ["./casteditor-block-navbar.component.scss"],
})
export class CasteditorBlockNavbarComponent implements OnInit {
  constructor() {}

  @Input() block: any;

  ngOnInit(): void {}
}
