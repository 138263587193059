import { Component, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Subject } from "rxjs";
import { DashboardService } from "src/app/services/dashboard.service";
import { PermissionHelperService } from "src/app/services/permission-helper.service";
import {
  Group,
  Permission,
  PassService,
  Role,
} from "src/app/sinigangnababoywithgabi";

// DEPRECATED
@Component({
  selector: "app-group-common-role-permissions-toggle",
  templateUrl: "./group-common-role-permissions-toggle.component.html",
  styleUrls: ["./group-common-role-permissions-toggle.component.scss"],
})
export class GroupCommonRolePermissionsToggleComponent implements OnInit {
  rolePermissionFormGroups: UntypedFormGroup[];

  constructor(
    private passService: PassService,
    private route: ActivatedRoute,
    private permissionHelperService: PermissionHelperService,
    private dashboardService: DashboardService
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  private unsubscribe$: Subject<void> = new Subject<void>();
  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  async loadData() {
    let { group_uuid: groupUuid } = this.route.snapshot.queryParams;

    let cast = await this.dashboardService.getCast().toPromise();

    let group = await this.passService
      .getGroup(groupUuid, ["roles", "permissions"])
      .toPromise();

    let permissionMap: {
      [key: string]: Permission;
    } = {};
    let roleNameMap: {
      [key: string]: Role;
    } = {};

    group.permissions.map((permission) => {
      permissionMap[permission.id] = permission;
    });

    group.roles.forEach((role) => {
      role.permissions = role.permissionIds.map((id) => permissionMap[id]);

      roleNameMap[role.name] = role;
    });

    let rolePermissionFormGroups = [
      this.permissionHelperService.createRoleToggleFormGroup({
        groupId: groupUuid,
        roleId: roleNameMap["access:dashboard"]?.id,
        roleName: "access:dashboard",
        roleDescription: "Dashboard Access",
        permissionAttributes: [
          {
            name: "read:dashboard:all",
            description: "Have access on dashboard",
          },
        ],
        existingPermissionList: roleNameMap["access:dashboard"]?.permissions,
        unsubscribe: this.unsubscribe$,
      }),
    ];

    if (cast.data.attributes.defaultGroupId) {
      let defaultGroupId = cast.data.attributes.defaultGroupId;

      rolePermissionFormGroups.push(
        this.permissionHelperService.createRoleToggleFormGroup({
          groupId: groupUuid,
          roleId: roleNameMap[`manage:groups:${defaultGroupId}`]?.id,
          roleName: `manage:groups:${defaultGroupId}`,
          roleDescription: "Users and Groups",
          permissionAttributes: [
            {
              name: `read:groups:${defaultGroupId}`,
              description: "Read parent group",
            },
            {
              name: `update:groups:${defaultGroupId}`,
              description: "Update parent group",
            },
            {
              name: `read:users:group=${defaultGroupId}`,
              description: "Read users",
            },
            {
              name: `create:users`,
              description: "Create users",
            },
            {
              name: `update:users:group=${defaultGroupId}`,
              description: "Update users",
            },
            {
              name: `create:groups`,
              description: "Create groups",
            },
            {
              name: `read:groups:parent=${defaultGroupId}`,
              description: "Read groups",
            },
            {
              name: `update:groups:parent=${defaultGroupId}`,
              description: "Update groups",
            },
            {
              name: `delete:groups:parent=${defaultGroupId}`,
              description: "Delete groups",
            },
          ],
          existingPermissionList:
            roleNameMap[`manage:groups:${defaultGroupId}`]?.permissions,
          unsubscribe: this.unsubscribe$,
        })
      );

      rolePermissionFormGroups.push(
        this.permissionHelperService.createRoleToggleFormGroup({
          groupId: groupUuid,
          roleId: roleNameMap[`crud:permissions`]?.id,
          roleName: `crud:permissions`,
          roleDescription: "Roles and Permissions",
          permissionAttributes: [
            {
              name: `create:roles`,
              description: "Create roles",
            },
            {
              name: `read:roles`,
              description: "Read roles",
            },
            {
              name: `update:roles`,
              description: "Update roles",
            },
            {
              name: `delete:roles`,
              description: "Delete roles",
            },

            {
              name: `create:permissions`,
              description: "Create permissions",
            },
            {
              name: `read:permissions`,
              description: "Read permissions",
            },
            {
              name: `update:permissions`,
              description: "Update permissions",
            },
            {
              name: `delete:permissions`,
              description: "Delete permissions",
            },
          ],
          existingPermissionList: roleNameMap[`crud:permissions`]?.permissions,
          unsubscribe: this.unsubscribe$,
        })
      );
    }

    // rolePermissionFormGroups.push(
    //   this.permissionHelperService.createRoleToggleFormGroup({
    //     groupId: groupUuid,
    //     roleId: roleNameMap[`manage:groups:${groupUuid}`]?.id,
    //     roleName: `manage:groups:${groupUuid}`,
    //     roleDescription: "This Group",
    //     permissionAttributes: [
    //       {
    //         name: `update:groups:${groupUuid}`,
    //         description: "Update this group",
    //       },
    //     ],
    //     existingPermissionList:
    //       roleNameMap[`manage:groups:${groupUuid}`]?.permissions,
    //     unsubscribe: this.unsubscribe$,
    //   })
    // );

    rolePermissionFormGroups = [
      ...rolePermissionFormGroups,
      this.permissionHelperService.createRoleToggleFormGroup({
        groupId: groupUuid,
        roleId: roleNameMap["crud:collections"]?.id,
        roleName: "crud:collections",
        roleDescription: "Collections",
        permissionAttributes: [
          {
            name: "create:collections",
            description: "Create collections",
          },
          {
            name: "read:collections:all",
            description: "Read collections",
          },
          {
            name: "update:collections:all",
            description: "Update collections",
          },
          {
            name: "delete:collections:all",
            description: "Delete collections",
          },
        ],
        existingPermissionList: roleNameMap["crud:collections"]?.permissions,
        unsubscribe: this.unsubscribe$,
      }),
      this.permissionHelperService.createRoleToggleFormGroup({
        groupId: groupUuid,
        roleId: roleNameMap["crud:resources"]?.id,
        roleName: "crud:resources",
        roleDescription: "Resources",
        permissionAttributes: [
          {
            name: "create:resources",
            description: "Create resources",
          },
          {
            name: "read:resources:all",
            description: "Read resources",
          },
          {
            name: "update:resources:all",
            description: "Update resources",
          },
          {
            name: "delete:resources:all",
            description: "Delete resources",
          },
        ],
        existingPermissionList: roleNameMap["crud:resources"]?.permissions,
        unsubscribe: this.unsubscribe$,
      }),
      this.permissionHelperService.createRoleToggleFormGroup({
        groupId: groupUuid,
        roleId: roleNameMap["crud:exams"]?.id,
        roleName: "crud:exams",
        roleDescription: "Activities and Assessments",
        permissionAttributes: [
          {
            name: "create:exams",
            description: "Create activities and assessments",
          },
          {
            name: "read:exams:all",
            description: "Read activities and assessments",
          },
          {
            name: "update:exams:all",
            description: "Update activities and assessments",
          },
          // {
          //   name: "delete:exams:all",
          //   description: "Delete activities and assessments",
          // },
        ],
        existingPermissionList: roleNameMap["crud:exams"]?.permissions,
        unsubscribe: this.unsubscribe$,
      }),
      this.permissionHelperService.createRoleToggleFormGroup({
        groupId: groupUuid,
        roleId: roleNameMap["crud:courses"]?.id,
        roleName: "crud:courses",
        roleDescription: "Course",
        permissionAttributes: [
          {
            name: "create:courses:all",
            description: "Create courses",
          },
          {
            name: "read:courses:all",
            description: "Read courses",
          },
          {
            name: "update:courses:all",
            description: "Update courses",
          },
          {
            name: "delete:courses:all",
            description: "Delete courses",
          },
        ],
        existingPermissionList: roleNameMap["crud:courses"]?.permissions,
        unsubscribe: this.unsubscribe$,
      }),
      this.permissionHelperService.createRoleToggleFormGroup({
        groupId: groupUuid,
        roleId: roleNameMap["crud:schedules"]?.id,
        roleName: "crud:schedules",
        roleDescription: "Course Batches",
        permissionAttributes: [
          {
            name: "create:schedules:all",
            description: "Create batches",
          },
          {
            name: "read:schedules:all",
            description: "Read batches",
          },
          {
            name: "update:schedules:all",
            description: "Update batches",
          },
          {
            name: "delete:schedules:all",
            description: "Delete batches",
          },
        ],
        existingPermissionList: roleNameMap["crud:schedules"]?.permissions,
        unsubscribe: this.unsubscribe$,
      }),
    ];

    this.rolePermissionFormGroups = rolePermissionFormGroups;
  }
}
