import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { switchMap } from "rxjs";

@Component({
  selector: "app-filter-section",
  templateUrl: "./filter-section.component.html",
  styles: [],
})
export class FilterSectionComponent implements OnInit {
  show: boolean = false;
  filterTotal: number = 0;
  filters: string[] = [];
  params: any;
  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.filterTotal = 0;
      this.filters = [];
      this.params = { ...params };
      Object.keys(this.params).map((key) => {
        if (
          key === "first_name" ||
          key === "last_name" ||
          key === "date" ||
          key === "last_signin" ||
          key === "email"
        ) {
          this.filters.push(`${this.snakeToProper(key)}: ${this.params[key]}`);
        }
      });
      this.filterTotal = this.filters.length;
    });
  }

  deleteFilter(index: number) {
    let key = this.properToSnake(this.filters[index].split(":")[0]);
    this.params[key] = null;
    this.filters.splice(index, 1);
    this.router.navigate([], {
      relativeTo: this.route,
      replaceUrl: true,
      queryParamsHandling: "merge",
      queryParams: this.params,
    });
    this.filterTotal = this.filters.length;
  }
  properToSnake(input: string) {
    let _input = input.toLowerCase();
    return _input
      .split("")
      .map((character) => {
        if (character === " ") {
          return "_";
        } else {
          return character;
        }
      })
      .join("");
  }
  snakeToProper(input: string) {
    let _input = input.toLowerCase();
    return _input
      .split("")
      .map((character) => {
        if (character === "_") {
          return " ";
        } else {
          return character;
        }
      })
      .join("")
      .split(" ")
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.substring(1);
      })
      .join(" ");
  }
}
