<app-back-title-layout [layoutTitle]="subject">
  <div
    *ngIf="loading"
    [@fadeInOut]
    class="absolute inset-0 bg-white bg-opacity-50 h-full"
  >
    <app-loading [loading]="true" position="relative"></app-loading>
  </div>
  <div class="flex mb-2">
    <div
      class="
        w-max
        border
        rounded-full
        bg-success-200
        text-success-400
        px-2
        text-sm
        mr-2
      "
    >
      sent
    </div>
    <div class="">{{ sendDate | date }}</div>
  </div>

  <h2 class="form-heading mb-4">Email Report</h2>

  <div class="grid grid-cols-4 gap-4 mb-8">
    <div class="rounded bg-success-200 px-2 py-4 text-success-700">
      <div class="uppercase text-sm">open</div>
      <div class="font-bold">{{ open | number: "1.1-2" }}%</div>
    </div>
    <div class="rounded bg-success-200 px-2 py-4 text-success-700">
      <div class="uppercase text-sm">clicks</div>
      <div class="font-bold">{{ clicks | number: "1.1-2" }}%</div>
    </div>
    <div class="rounded bg-warning-200 px-2 py-4 text-warning-600">
      <div class="uppercase text-sm">not seen</div>
      <div class="font-bold">{{ notSeen | number: "1.1-2" }}%</div>
    </div>
    <div class="rounded bg-red-200 px-2 py-4 text-red-700">
      <div class="uppercase text-sm">bounce rate</div>
      <div class="font-bold">{{ bounceRate | number: "1.1-2" }}%</div>
    </div>
  </div>

  <app-item-select-with-header
    [enableSort]="true"
    [itemTemplate]="itemTemplate"
    [source]="emailLogObservable"
    [headers]="headers"
    [disableCheckbox]="true"
    [limit]="limit"
    [totalCount]="totalCount"
  ></app-item-select-with-header>
</app-back-title-layout>
<ng-template #itemTemplate let-log="item">
  <div class="sm:flex my-1">
    <a class="sm:flex cursor-pointer">
      <!-- Email -->
      <div class="sm:w-96 sm:flex">
        <app-icon
          icon="person"
          class="
            text-white text-xl
            leading-none
            px-1
            mr-1
            bg-gray-200
            rounded-full
            items-center
            justify-center
            shrink
          "
        ></app-icon>
        <div
          class="
            text-sm text-gray-700
            sm:items-center
            flex-wrap
            truncate
            sm:w-full
          "
        >
          {{ log.email }}
          <span *ngIf="!log.confirmedAt" class="text-sm text-red-500 italic"
            ><app-icon icon="error" class="align-bottom"></app-icon
          ></span>
        </div>
      </div>
      <!-- Name -->
      <div class="leading-tight truncate text-sm sm:items-center sm:w-96">
        {{ log.name }}
      </div>
      <!-- Status -->
      <div class="text-sm sm:items-center sm:w-56">
        <div
          class="shrink w-max rounded-full px-1 mt-2 sm:mt-0 {{ log.style }}"
        >
          {{ log.emailStatus }}
        </div>
      </div>
    </a>
  </div>
</ng-template>
