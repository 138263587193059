<div class="flex flex-col">
  <div class="flex w-full justify-between">
    <h1 class="font-bold text-xl">Reason for rejecting Edit</h1>
    <button type="button" mat-dialog-close="">
      <app-icon icon="close" class="text-2xl text-gray-400"></app-icon>
    </button>
  </div>

  <textarea
    placeholder="(Optional) Type reason here"
    class="h-36 my-6 form-control"
    [(ngModel)]="rejectedMessage"
  ></textarea>
  <button class="btn btn-primary" [mat-dialog-close]="rejectedMessage">
    send
  </button>
</div>
