import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { catchError, Subscription, switchMap, tap } from "rxjs";
import { MailerService } from "src/app/services/mailer.service";
import { ModalService } from "src/app/services/modal.service";

@Component({
  selector: "app-mailer-create-template",
  templateUrl: "./mailer-create-template.component.html",
  styles: [],
})
export class MailerCreateTemplateComponent implements OnInit {
  filters: UntypedFormGroup;
  firstFilter: string[] = [];
  _filters: Array<string[]> = [];
  inputHidden: string = "";
  inputFilters: any[] = [];
  deleteIndex: number = 0;
  filterTotal: number = 0;
  previewHidden: string = "hidden";
  close: boolean = false;
  mutateSubscription: Subscription;
  loading: boolean = false;
  form: UntypedFormGroup;
  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private mailerService: MailerService,
    private cd: ChangeDetectorRef,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      tempName: [null, Validators.required],
      tempDesc: [null, Validators.required],
      subject: [null, Validators.required],
      content: [null, Validators.required],
    });
  }
  ngOnDestroy(): void {
    this.mutateSubscription?.unsubscribe();
  }
  preview() {
    this.inputHidden = "hidden";
    this.previewHidden = "";
  }
  code() {
    this.inputHidden = "";
    this.previewHidden = "hidden";
  }
  closeModal(event) {
    this.close = !this.close;
  }
  onSubmit() {
    let tempName = this.form.get("tempName").value;
    let tempDesc = this.form.get("tempDesc").value;
    let subject = this.form.get("subject").value;
    let emailBody = this.form.get("content").value;
    let sendOnDate = new Date().toISOString();
    let filters = this.mailerService.parseFilter(this.filters);
    if (filters.length === 0) {
      filters = [{ key: "user", values: [""] }];
    }
    this.mutateSubscription?.unsubscribe();
    this.mutateSubscription = this.mailerService
      .createEmailTemplate({
        name: tempName,
        description: tempDesc,
        email_body: emailBody,
        subject: subject,
        send_on_date: sendOnDate,
        filters: filters,
      })
      .pipe(
        tap(({ data, loading }) => {
          if (!data) {
            throw new Error("No filters found");
          }
        }),
        switchMap(({ data, loading }) => {
          this.loading = loading;
          this.router.navigate(["_mailer/templates"]);
          return this.modalService.alert("Template saved");
        }),
        catchError((error) => {
          this.loading = false;
          return this.modalService.alert("No filter(s) found");
        })
      )
      .subscribe();
  }
  showFilters(filters: UntypedFormGroup) {
    this.filters = filters;
    this.inputFilters = [filters];
    this.firstFilter = [];
    this._filters = [];
    for (const [key, value] of Object.entries(
      (<UntypedFormGroup>filters.get("first")).controls
    )) {
      if (value.value && typeof value.value === "object" && value.value.name) {
        this.firstFilter.push(value.value.name);
      } else if (value.value) {
        this.firstFilter.push(value.value);
      }
    }
    (<UntypedFormArray>filters.get("filters")).controls.forEach(
      (filter, index) => {
        let innerArr = [];
        for (const [key, value] of Object.entries(filter.value)) {
          if (value && typeof value === "object" && value["name"]) {
            innerArr.push(value["name"]);
          } else if (value) {
            innerArr.push(value);
          }
        }
        this._filters.push(innerArr);
      }
    );
  }

  setFilterTotal(event) {
    this.filterTotal = event;
  }
  deleteFilter(index) {
    this.deleteIndex = 0;
    this.cd.detectChanges();
    this.deleteIndex = index;
  }
  async openModal(template, type) {
    let baseClass = ["flex", "justify-center", "items-center", "shadow-lg"];
    let smallContainerClass = ["sm:w-1/4", "w-3/5"];
    let largeContainerClass = ["sm:w-1/2", "w-3/5"];
    let filterResultsContainerClass = ["w-3/4", "h-1/2"];
    let modalClass;
    if (type === "cancelSchedule" || type === "testEmail") {
      modalClass = [...baseClass, ...smallContainerClass];
    } else if (type === "filterResults") {
      modalClass = [...baseClass, ...filterResultsContainerClass];
    } else {
      modalClass = [...baseClass, ...largeContainerClass];
    }
    let results = await this.modalService.openModal(template, {
      panelClass: modalClass,
    });
    return results;
  }
}
