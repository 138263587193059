import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppCommonModule } from "../app-common-module/app-common-module.module";
import { CreateEmailComponent } from "./create-email/create-email.component";
import { BatchModalComponent } from "./mailer-components/batch-modal/batch-modal.component";
import { CourseModalComponent } from "./mailer-components/course-modal/course-modal.component";
import { FilterResultsComponent } from "./mailer-components/filter-results/filter-results.component";
import { MailerFilterComponent } from "./mailer-components/mailer-filter/mailer-filter.component";
import { UserModalComponent } from "./mailer-components/user-modal/user-modal.component";
import { MailerEditTemplateComponent } from "./mailer-edit-template/mailer-edit-template.component";
import { MailerRoutingModule } from "./mailer-routing.module";
import { MailerTemplatesComponent } from "./mailer-templates/mailer-templates.component";
import { MailerComponent } from "./mailer.component";
import { MailerHistoryComponent } from "./main-screen/mailer-history/mailer-history.component";
import { MainScreenComponent } from "./main-screen/main-screen.component";
import { SystemEmailsComponent } from "./system-emails/system-emails.component";
import { EmailProfileComponent } from "./email-profile/email-profile.component";
import { EmailReportComponent } from "./email-report/email-report.component";
import { EmailEditComponent } from "./email-edit/email-edit.component";
import { EmailStatusComponent } from "./email-status/email-status.component";
import { MailerCreateTemplateComponent } from "./mailer-create-template/mailer-create-template.component";
import { SaveTemplateModalComponent } from "./mailer-components/save-template-modal/save-template-modal.component";
import { TestEmailModalComponent } from "./mailer-components/test-email-modal/test-email-modal.component";
import { SendScheduleModalComponent } from "./mailer-components/send-schedule-modal/send-schedule-modal.component";
import { CancelScheduleModalComponent } from "./mailer-components/cancel-schedule-modal/cancel-schedule-modal.component";
import { SystemEmailEditComponent } from "./system-email-edit/system-email-edit.component";
import { MatDialogModule } from "@angular/material/dialog";

@NgModule({
  declarations: [
    MailerComponent,
    MainScreenComponent,
    MailerHistoryComponent,
    SystemEmailsComponent,
    MailerTemplatesComponent,
    MailerEditTemplateComponent,
    CreateEmailComponent,
    MailerFilterComponent,
    UserModalComponent,
    CourseModalComponent,
    BatchModalComponent,
    FilterResultsComponent,
    EmailProfileComponent,
    EmailReportComponent,
    EmailEditComponent,
    EmailStatusComponent,
    MailerCreateTemplateComponent,
    SaveTemplateModalComponent,
    TestEmailModalComponent,
    SendScheduleModalComponent,
    CancelScheduleModalComponent,
    SystemEmailEditComponent,
  ],
  imports: [
    CommonModule,
    MailerRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    AppCommonModule,
  ],
})
export class MailerModule {}
