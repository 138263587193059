<ng-template #withLayout>
  <app-back-title-layout [loading]="!formGroup || loading">
    <div>
      <ng-container *ngIf="formGroup">
        <form [formGroup]="formGroup" class="relative" (ngSubmit)="onSave()">
          <app-exam-item-form
            [formGroup]="formGroup"
            [insideSlide]="insideSlide"
          >
          </app-exam-item-form>

          <div
            *ngIf="!canUpdate"
            class="absolute inset-0 bg-white bg-opacity-25 z-10"
          ></div>

          <div class="flex flex-row-reverse">
            <button
              class="btn btn-primary"
              [disabled]="formGroup.invalid"
              type="submit"
            >
              Save
            </button>

            <button
              type="button"
              *ngIf="formGroup.value.additional"
              [disabled]="formGroup.invalid"
              class="btn bg-blue-500 text-white"
              (click)="onSaveAndCreateAnother()"
            >
              Save and add another item
            </button>
          </div>
        </form>
      </ng-container>
    </div>
  </app-back-title-layout>
</ng-template>
<ng-container *ngIf="insideSlide; else withLayout">
  <div class="relative">
    <app-loading position="absolute" padding="py-32" [loading]="loading">
      <ng-container *ngIf="formGroup">
        <form [formGroup]="formGroup" class="relative" (ngSubmit)="onSave()">
          <app-exam-item-form
            [formGroup]="formGroup"
            [insideSlide]="insideSlide"
          >
          </app-exam-item-form>

          <div
            *ngIf="!canUpdate"
            class="absolute inset-0 bg-white bg-opacity-25 z-10"
          ></div>

          <div class="flex flex-row-reverse sticky bottom-0 right-0">
            <button
              class="btn btn-primary"
              [disabled]="formGroup.invalid"
              type="submit"
            >
              Save
            </button>

            <button
              type="button"
              *ngIf="formGroup.value.additional"
              [disabled]="formGroup.invalid"
              class="btn bg-blue-500 text-white"
              (click)="onSaveAndCreateAnother()"
            >
              Save and add another item
            </button>
          </div>
        </form>
      </ng-container>
    </app-loading>
  </div>
</ng-container>
