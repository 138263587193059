<app-back-title-layout [loading]="!formGroup">
  <ng-container *ngIf="formGroup">
    <form [formGroup]="formGroup" (ngSubmit)="onSave()">
      <label class="form-label text-lg">
        Maximum number of cards displayed
      </label>

      <label class="block my-2">
        <app-radio
          class="mr-2 text-xl text-primary"
          formControlName="limitRadio"
          value="all"
        ></app-radio>
        <span class="">ALL (DEFAULT)</span>
      </label>

      <label class="flex">
        <app-radio
          class="mr-2 text-xl text-primary"
          formControlName="limitRadio"
          value="custom"
        ></app-radio>

        <div>
          <span>Customized</span>
          <input
            type="number"
            [ngClass]="{
              invisible: formGroup.value.limitRadio === 'all'
            }"
            formControlName="limit"
            class="form-control w-12 my-2"
          />
        </div>
      </label>

      <hr class="my-10" />

      <label class="form-label text-lg"> Appearance </label>

      <div class="my-5">
        <label class="form-label">Card Layout</label>

        <label class="flex my-5">
          <app-radio
            class="mr-2 text-xl text-primary"
            formControlName="isInfoHidden"
            name="isInfoHidden"
            [value]="false"
          ></app-radio>

          <app-resource-card
            [isInfoHidden]="false"
            [isDownloaded]="true"
            [resource]="resources[0]"
            [cardHeaderBackgroundColor]="
              formGroup.value.cardHeaderBackgroundColor
            "
            [cardHeaderForegroundColor]="
              formGroup.value.cardHeaderForegroundColor
            "
          >
          </app-resource-card>
        </label>

        <label class="flex my-5">
          <app-radio
            class="mr-2 text-xl text-primary"
            formControlName="isInfoHidden"
            name="isInfoHidden"
            [value]="true"
          ></app-radio>

          <app-resource-card
            [isInfoHidden]="true"
            [resource]="resources[0]"
            [cardHeaderBackgroundColor]="
              formGroup.value.cardHeaderBackgroundColor
            "
            [cardHeaderForegroundColor]="
              formGroup.value.cardHeaderForegroundColor
            "
          >
          </app-resource-card>
        </label>
      </div>

      <hr class="my-10" />

      <label class="form-label">Colors</label>
      <div class="text-gray-400 text-xs">
        Your Cast already have its default overall color theme, changing the
        assigned colors below will override the your overall Cast color theme
      </div>

      <button
        type="button"
        (click)="resetToDefault()"
        class="my-2 text-primary text-sm hover:underline font-semibold"
      >
        Reset to default
      </button>

      <div class="my-5">
        <label class="form-label">Catalog</label>
        <div class="text-gray-400 text-xs leading-tight">Sample preview</div>

        <div
          class="border-2 my-1 p-5"
          [style.backgroundColor]="formGroup.value.baseBackgroundColor"
        >
          <div
            class="font-bold mb-2"
            [style.color]="formGroup.value.baseForegroundColor"
            [style.fontFamily]="formGroup.value.fontStyle"
          >
            {{ collection?.name }}
          </div>

          <div class="overflow-x-scroll pb-5">
            <div class="flex">
              <ng-container *ngFor="let resource of resources; let i = index">
                <div class="mr-2">
                  <app-resource-card
                    [isChecked]="i % 2 === 0"
                    [isErrored]="i % 2 === 1"
                    [isDownloaded]="true"
                    [isInfoHidden]="formGroup.value.isInfoHidden"
                    [resource]="resource"
                    [cardHeaderBackgroundColor]="
                      formGroup.value.cardHeaderBackgroundColor
                    "
                    [cardHeaderForegroundColor]="
                      formGroup.value.cardHeaderForegroundColor
                    "
                  >
                  </app-resource-card>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <div class="my-5">
        <label class="form-label">Font</label>

        <app-font-select formControlName="fontStyle"> </app-font-select>
      </div>

      <div class="my-5">
        <label class="form-label">Catalog Name Color</label>

        <div class="w-24">
          <app-color formControlName="baseForegroundColor"></app-color>
        </div>
      </div>

      <div class="my-5">
        <label class="form-label">Catalog Background Color</label>

        <div class="w-24">
          <app-color formControlName="baseBackgroundColor"></app-color>
        </div>
      </div>

      <div class="my-5">
        <label class="form-label">Card Header and Download Icon Color</label>

        <div class="w-24">
          <app-color formControlName="cardHeaderBackgroundColor"></app-color>
        </div>
      </div>

      <div class="my-5">
        <label class="form-label">Card Header Text Color</label>

        <div class="w-24">
          <app-color formControlName="cardHeaderForegroundColor"></app-color>
        </div>
      </div>

      <button class="btn btn-primary w-full" type="submit">SAVE</button>
    </form>
  </ng-container>
</app-back-title-layout>
