import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  debounceTime,
  distinctUntilChanged,
  flatMap,
  map,
  Observable,
  switchMap,
  tap,
} from "rxjs";
import { fadeInOut } from "src/app/app-common-module/animations/fade";
import { Header } from "src/app/app-common-module/components/list-table-header/list-table-header.component";
import { DashboardService } from "src/app/services/dashboard.service";
import { PassService } from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-user-modal",
  templateUrl: "./user-modal.component.html",
  styles: [],
  animations: [fadeInOut],
})
export class UserModalComponent implements OnInit {
  @Output() user = new EventEmitter<any>();
  @Input() currentIndex: number;
  canAddUsersObservable: Observable<boolean>;
  userListObservable: Observable<any[]>;
  loading: boolean = false;
  oneMonth = 2629800000;
  users: any;
  currentOrder: string;
  limit: number = 20;
  totalUserCount: number = 1;
  headers: Header[] = [
    { name: "Email", value: "email", width: "w-96" },
    { name: "Name", value: "name", width: "w-96" },
    { name: "Date Added", value: "date_added", width: "w-56" },
    { name: "Active", value: "active", width: "w-56" },
  ];
  defaultHeaders: string[] = [
    "Email",
    "Name",
    //"Birthdate",
    "Date Added",
    "Status",
    //"Sex",
  ];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private passService: PassService,
    private dashboardService: DashboardService
  ) {}

  ngOnInit(): void {
    this.constructCanAddUserObservable();
    this.constructUserListObservable();
  }
  constructCanAddUserObservable() {
    this.canAddUsersObservable = this.dashboardService
      .getPermissions()
      .pipe(map((permissions) => permissions.includes("create:users")));
  }
  constructUserListObservable() {
    this.userListObservable = this.route.queryParams.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((params) => {
        this.loading = true;
        let { order, user, page, date } = params;

        let date2 = date ? date + "T23:59:59.999Z" : null;
        let q2 = user ? user.split(" ") : null;
        this.currentOrder = order;
        this.users = null;

        return this.passService.listUsers(
          null,
          this.limit,
          page * this.limit,
          ["user_profile"],
          order,
          null,
          null,
          null,
          null,
          date,
          date2,
          q2
        );
      }),
      tap((result) => {
        this.users = result.users;
        this.totalUserCount = result.count;
      }),
      flatMap((result) => {
        return this.dashboardService.getCast().pipe(
          map((cast) => {
            return {
              cast,
              users: result.users,
            };
          })
        );
      }),
      map(({ cast, users }) => {
        return users.map((user) => {
          return {
            uuid: user.id,
            name:
              (user.userProfile[0]?.givenName || user.firstName) +
              " " +
              (user.userProfile[0]?.familyName || user.lastName),
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            confirmedAt: user.confirmedAt,
            createdAt: user.createdAt,
            selected: false,
            profile: user.userProfile, //array muna (2/11/22), magiging object
            //groups: user.groups,
            active:
              Date.now() - this.oneMonth <= Date.parse(user.lastSignInAt) &&
              Date.parse(user.lastSignInAt) <= Date.now() &&
              !user.groups
                ?.map((g) => g.id)
                .includes(cast.data.attributes.deactivatedUsersGroupId),
          };
        });
      }),
      tap((result) => {
        this.users = result;
        this.loading = false;
      })
    );
  }
  outputUser(user) {
    this.user.emit(user);
    this.router.navigate([], {
      relativeTo: this.route,
      replaceUrl: false,
      queryParamsHandling: "merge",
      queryParams: { user: null, page: null },
    });
  }
}
