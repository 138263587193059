import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable, Subject, firstValueFrom } from "rxjs";
import { switchMap, map, takeUntil } from "rxjs/operators";
import { FilterPipePipe } from "src/app/app-common-module/pipes/filter-pipe.pipe";
import { ModalService } from "src/app/services/modal.service";
import { PassService } from "src/app/sinigangnababoywithgabi";

interface RoleGroupInfo {
  roleUuid: string;
  groupUuid: string;
  groupName: string;
  permissionsAssignedCount: number;
}

@Component({
  selector: "app-course-permissions",
  templateUrl: "./course-permissions.component.html",
})
export class CoursePermissionsComponent implements OnInit {
  roleGroupInfos: RoleGroupInfo[];

  roleGroupInfoObservable: Observable<RoleGroupInfo[]>;

  constructor(
    private route: ActivatedRoute,
    private passService: PassService,
    private modalService: ModalService
  ) {}

  private unsubscribe$: Subject<void> = new Subject<void>();
  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  async ngOnInit() {
    let { course_uuid: courseUuid } = this.route.snapshot.queryParams;

    let filterAll = new FilterPipePipe();

    this.passService
      .listRoles(`crud:courses:${courseUuid}`, null, null, [
        "groups",
        // "permissions",
      ])
      .pipe(
        takeUntil(this.unsubscribe$),
        map((result: any) => result["roles"]),
        map((items: any) => {
          return items
            .filter((role) => role.groups?.length > 0)
            .map((role) => ({
              roleUuid: role.id,
              groupUuid: role.groups[0].id,
              groupName: role.groups[0].name,
              permissionsAssignedCount: role.permissionIds.length,
            }));
        }),
        switchMap((items) => {
          return this.route.queryParams.pipe(
            map((params) => {
              return filterAll.transform(items, params["q"]);
            })
          );
        }),
        map((items) => {
          this.roleGroupInfos = items;
        })
      )
      .subscribe();
  }

  async removeGroup(roleGroupInfo: RoleGroupInfo, index) {
    let result = await this.modalService.confirm(
      "Are you sure you want to remove this group?"
    );

    this.roleGroupInfos.splice(index, 1);

    if (result) {
      await firstValueFrom(this.passService.deleteRole(roleGroupInfo.roleUuid));
    }
  }
}
