import { Pipe, PipeTransform } from "@angular/core";
import { ColorHelperService } from "src/app/services/color-helper.service";
import { CastColor } from "src/app/sinigangnababoywithgabi";

@Pipe({
  name: "castColor",
})
export class CastColorPipe implements PipeTransform {
  constructor(private colorHelperService: ColorHelperService) {}

  transform(value: CastColor, ...args: unknown[]): unknown {
    return this.colorHelperService.constructRGBAFromCastColor(value);
  }
}
