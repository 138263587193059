import { Injectable } from "@angular/core";
import { Group } from "../sinigangnababoywithgabi";
import camelCase from "camelcase";

interface HumanizedBatchUpload {
  email: string;
  firstName: string;
  lastName: string;
  password?: string;
  isPasswordExpired?: boolean;
  passwordExpiresAt?: string;
  isRequiredToConfirm?: boolean;
  username: string;
  groupIds?: string[];
  groupNames: string[];
}

const HUMANIZE_PERIOD_MAP = {
  H: "Hour",
  D: "Day",
  M: "Month",
  Y: "Year",
};

@Injectable({
  providedIn: "root",
})
export class HumanizeService {
  constructor() {}

  humanizeBatchUpload({
    items,
    groups = [],
  }: {
    items: { [key: string]: any }[];
    groups?: Group[];
  }): HumanizedBatchUpload[] {
    let groupIdToNameMap = {};
    let groupNameToIdsMap = {};

    groups.forEach((group) => {
      groupIdToNameMap[group.id] = group.name;

      if (groupNameToIdsMap[group.name]) {
        groupNameToIdsMap[group.name].push(group.id);
      } else {
        groupNameToIdsMap[group.name] = [group.id];
      }
    });

    return (
      items
        .map((item) => {
          let transformed: any = {};

          // Transform each key to our data model
          Object.entries(item).forEach(([key, value]) => {
            // Regex comments
            // ((\s|_)*) - to match optional whitespace or underscore

            // For Email
            if (/email/gi.test(key)) {
              transformed.email = value;
            }

            // For Username
            else if (/username/gi.test(key)) {
              transformed.username = value;
            }
            // For first name
            else if (/first((\s|_)*)name/gi.test(key)) {
              transformed.firstName = value;
            }

            // For last name
            else if (/last((\s|_)*)name/gi.test(key)) {
              transformed.lastName = value;
            }

            // For password name
            else if (/password/gi.test(key) && !/(change|expire)/gi.test(key)) {
              transformed.password = value;
            }

            // For groups
            else if (/group(s?)/gi.test(key) && !/(id|csv)/gi.test(key)) {
              transformed.groups = value;
            }

            // For group ids
            else if (/group((\s|_)*)ids((\s|_)*)csv/gi.test(key)) {
              transformed.groupIdsCsv = value;
            }

            // Assumes only one of this is present
            else if (/is((\s|_)*)password((\s|_)*)expired/gi.test(key)) {
              transformed.isPasswordExpired = value;
            }

            // For password expiry date
            else if (/password((\s|_)*)expires((\s|_)*)at/gi.test(key)) {
              transformed.passwordExpiresAt = value;
            } else if (/^Change password upon login/gi.test(key)) {
              transformed.isPasswordExpired = /^(true)|(t)|(yes)|(y)$/i.test(
                value
              );
            } else if (/^Confirm account upon upload/gi.test(key)) {
              transformed.isRequiredToConfirm = !/^(true)|(t)|(yes)|(y)$/i.test(
                value
              );
            } else if (/deactivate/gi.test(key)) {
              transformed.deactivate = /^(true)|(t)|(yes)|(y)$/i.test(value);
            } else {
              transformed[camelCase(key)] = value;
            }
          });

          return transformed;
        })
        // Filter out null and items without email
        .filter((item) => item && item.email)
        // Process humanized CSV
        .map((item) => {
          // Assign groupIds based on `group_ids_csv` and `group` fields
          let userGroupIds = [];

          if (item.groups) {
            let groupNames = item.groups
              .split(",")
              .map((groupName) => groupName.trim());

            for (let i = 0; i < groupNames.length; ++i) {
              //Checks to see if the key is present

              if (groupNameToIdsMap[groupNames[i]])
                userGroupIds.push(...groupNameToIdsMap[groupNames[i]]);
            }
          }

          let groupIds = [
            ...(item.groupIdsCsv?.split(",") || []),
            ...(userGroupIds || []),
          ];

          return {
            ...item,
            firstName: item.firstName,
            lastName: item.lastName,
            email: item.email,
            username: item.username,
            password: item.password,

            // Password is expired if this field is earlier then the current time
            isPasswordExpired: item.hasOwnProperty("isPasswordExpired")
              ? item.isPasswordExpired
              : !!item.passwordExpiresAt &&
                new Date(item.passwordExpiresAt) < new Date(),

            // Set current date if email needs to be confirmed
            isRequiredToConfirm: item.isRequiredToConfirm,
            groupIds,
            groupNames: groupIds
              .map((id) => groupIdToNameMap[id])
              .filter((r) => r),
          };
        })
    );
  }

  humanizePeriodUnit(period: number | string, unit: string) {
    if (parseInt(period + "") > 1) {
      return `${period} ${HUMANIZE_PERIOD_MAP[unit]}s`;
    } else if (period) {
      return `${period} ${HUMANIZE_PERIOD_MAP[unit]}`;
    } else {
      return null;
    }
  }
}
