import { ClipboardModule } from "@angular/cdk/clipboard";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDialogModule } from "@angular/material/dialog";
import { AppCommonModule } from "../app-common-module/app-common-module.module";
import { GeneralSettingsCustomizeComponent } from "./general-settings-customize/general-settings-customize.component";
import { GeneralSettingsOptionsComponent } from "./general-settings-options/general-settings-options.component";
import { GeneralSettingsRoutingModule } from "./general-settings-routing.module";
import { GeneralSettingsComponent } from "./general-settings.component";
import { GeneralSettingsAddFieldComponent } from './general-settings-add-field/general-settings-add-field.component';
import { GeneralSettingsGuestLoginComponent } from './general-settings-guest-login/general-settings-guest-login.component';

@NgModule({
  declarations: [
    GeneralSettingsComponent,
    GeneralSettingsOptionsComponent,
    GeneralSettingsCustomizeComponent,
    GeneralSettingsAddFieldComponent,
    GeneralSettingsGuestLoginComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    DragDropModule,
    ClipboardModule,
    ReactiveFormsModule,
    GeneralSettingsRoutingModule,
    MatDialogModule,
    AppCommonModule,
  ],
})
export class GeneralSettingsModule {}
