<div class="flex">
  <button
    [routerLink]="['new']"
    class="px-2 py-1 text-sm text-white uppercase rounded bg-primary-600"
    [disabled]="!(canAddGroupsObservable | async)"
    [attr.data-tooltip]="
      !(canAddGroupsObservable | async)
        ? 'Contact your admin to gain access'
        : ''
    "
  >
    <app-icon icon="add_circle" class="text-white"></app-icon>
    Add group
  </button>
  <div class="flex sm:hidden">
    <app-dropdown-layout position="left">
      <div dropdownToggle class="flex items-center px-1 mt-1 ml-2 bg-gray-200">
        <app-icon icon="more_horiz" class="text-gray-400"> </app-icon>
      </div>
      <div dropdownContent>
        <ng-container *ngFor="let menu of mobileMenu">
          <button
            class="w-full px-2 py-1 text-left text-primary hover:bg-primary hover:text-white"
            (click)="exportFromMenu(menu.value)"
          >
            {{ menu.text }}
          </button>
        </ng-container>
      </div>
    </app-dropdown-layout>
  </div>
  <div class="hidden sm:flex">
    <button
      *ngIf="groups"
      (click)="exportGroups()"
      class="px-2 py-1 text-sm uppercase"
      [ngClass]="
        isExporting ? 'text-gray-600 animate-pulse' : 'text-primary-600'
      "
      [disabled]="isExporting"
    >
      <app-icon icon="insert_drive_file"></app-icon>
      {{ isExporting ? "Exporting ..." : "Export CSV" }}
    </button>
  </div>
</div>

<div class="block w-full mt-2 sm:flex">
  <app-search-bar-query
    placeholder="Search"
    class="w-3/4"
  ></app-search-bar-query>
  <div class="flex mt-2 sm:mt-0">
    <div class="flex items-center px-1 mx-2 text-gray-400 bg-gray-200">
      <app-dropdown-layout position="left">
        <div dropdownToggle class="flex items-center uppercase">
          <app-icon icon="import_export"> </app-icon>
          <div class="hidden text-sm sm:flex">Sort</div>
        </div>

        <div dropdownContent>
          <ng-container *ngFor="let orderItem of orderItems">
            <button
              class="w-full px-2 py-1 text-left text-primary hover:bg-primary hover:text-white"
              (click)="orderBy(orderItem.value)"
            >
              <app-icon
                icon="check"
                [ngClass]="{ invisible: orderItem.value !== currentOrder }"
              ></app-icon>
              {{ orderItem.text }}
            </button>
          </ng-container>
        </div>
      </app-dropdown-layout>
    </div>
  </div>
</div>
<div class="my-5">
  <app-item-select-with-header
    [enableSort]="true"
    [source]="groupListObservable"
    [itemTemplate]="itemTemplate"
    [headers]="headers"
    [category]="'group'"
    [key]="'groupUuid'"
    [batchActions]="groupItems"
    [limit]="limit"
    [totalCount]="totalGroupCount"
    [loading]="loading"
    (selectedItemList)="selectedItemList($event)"
  >
  </app-item-select-with-header>
</div>

<ng-template #itemTemplate let-groupItem="item">
  <div class="flex w-full">
    <div class="block mt-2 sm:flex sm:mt-0">
      <a
        class="items-center block w-full ml-2 sm:flex sm:ml-0"
        href="/users-and-groups/groups/profile?group_uuid={{
          groupItem.groupUuid
        }}"
      >
        <div class="grid w-full grid-cols-1 my-1 sm:flex">
          <div class="block sm:w-96 sm:flex">
            <div class="mb-2 sm:ml-2 sm:mb-0">
              <app-icon
                icon="group"
                [ngClass]="{
                  'text-primary': groupItem.isDefaultGroup,
                  'text-gray-500': groupItem.isDeactivatedUserGroup
                }"
                class="items-center justify-center h-10 px-1 mr-2 text-2xl leading-none text-white bg-gray-200 rounded-full"
              ></app-icon>
            </div>
            <div
              [ngClass]="{
                'text-primary': groupItem.isDefaultGroup,
                'text-gray-500': groupItem.isDeactivatedUserGroup
              }"
              class="flex items-center text-sm leading-none"
            >
              {{ groupItem.groupName }}
            </div>
          </div>
          <div class="sm:w-56">
            <div
              [ngClass]="{
                'text-primary': groupItem.isDefaultGroup,
                'text-gray-500': groupItem.isDeactivatedUserGroup
              }"
              class="text-sm text-gray-700"
            >
              <app-icon icon="person"></app-icon>
              {{ groupItem.userCount }}
            </div>
          </div>

          <div #roles class="text-sm truncate sm:w-96">
            <ng-container *ngFor="let role of groupItem.roles">
              <span
                class="inline-block rounded-full text-primary-500 bg-primary-50 px-2 py-0.5 m-0.5 max-w-xs text-sm"
              >
                {{ role.name }}
              </span>
            </ng-container>
          </div>

          <div
            *ngIf="checkOverflow(roles)"
            class="text-primary-500 text-sm px-2 py-0.5 m-0.5"
          >
            ({{ groupItem.roles.length }})
          </div>
        </div>
      </a>
    </div>
  </div>
</ng-template>
