import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  distinctUntilChanged,
  firstValueFrom,
  forkJoin,
  map,
  of,
  switchMap,
  tap,
  zip,
} from "rxjs";
import { fadeInOut } from "src/app/app-common-module/animations/fade";
import { ModalService } from "src/app/services/modal.service";
import {
  FieldEntriesService,
  FieldGroupsService,
  FormSubmissionsService,
  PassService,
  Tab,
  TabsService,
  UsersService,
} from "src/app/sinigangnababoywithgabi";
import { RejectFieldEntryModalComponent } from "../components/reject-field-entry-modal/reject-field-entry-modal.component";

@Component({
  selector: "app-idis-external-training-documents",
  templateUrl: "./idis-external-training-documents.component.html",
  styles: [],
  animations: [fadeInOut],
})
export class IdisExternalTrainingDocumentsComponent implements OnInit {
  limit: number = 6;
  formSubmissions;
  loading: boolean = false;
  tabUuid: string = "";
  constructor(
    private formSubmissionsService: FormSubmissionsService,
    private fieldsGroupsService: FieldGroupsService,
    private tabsService: TabsService,
    private passService: PassService,
    private fieldEntriesService: FieldEntriesService,
    private route: ActivatedRoute,
    private usersService: UsersService,
    private modalService: ModalService
  ) {}
  ngOnInit(): void {
    //Get tabs
    //Get tab with name External Training Document
    //Get fieldgroups
    //Get list of form submissions of first fieldGroup
    //Get list of fieldEntries per submission
    this.route.queryParams
      .pipe(
        distinctUntilChanged(),
        switchMap((params) => {
          let { page, q } = params;
          return this.getFormSubmissions(params);
        })
      )
      .subscribe();
  }

  getFormSubmissions(params) {
    let page = params.page ? params.page : 0;
    return this.tabsService
      .servicesBurecordsTabsTokenBasedGet(false, "sub_tabs")
      .pipe(
        switchMap((result) => {
          this.loading = true;
          let externalTrainingDocumentTab: Tab = {};
          result.tabs.forEach((tab) => {
            if (tab.displayName === "External Training Documents")
              externalTrainingDocumentTab = tab;
          });
          if (!externalTrainingDocumentTab.id) {
            return of({ count: 0, fieldGroups: [] });
          }
          this.tabUuid = externalTrainingDocumentTab.id;
          return this.fieldsGroupsService.servicesBurecordsTabsIdFieldGroupsGet(
            externalTrainingDocumentTab.id
          );
        }),
        switchMap(({ count, fieldGroups }) => {
          if (fieldGroups.length === 0) {
            return of({ count: 0, formSubmissions: [] });
          }
          return this.formSubmissionsService.servicesBurecordsFieldGroupsIdFormSubmissionsGet(
            fieldGroups[0].id,
            page * this.limit,
            this.limit
          );
        }),
        switchMap(({ count, formSubmissions }) => {
          if (formSubmissions.length === 0) {
            return of({});
          }
          let fieldEntriesObservable = formSubmissions.map((submission) => {
            return this.fieldEntriesService.servicesBurecordsFormSubmissionsIdFieldEntriesGet(
              submission.id,
              null,
              null,
              params.q
            );
          });
          let userListObservable = formSubmissions.map((submission) => {
            return this.passService.getUser(submission.userId);
          });
          let usersObservable = formSubmissions.map((submission) => {
            return this.usersService.servicesBurecordsUsersIdGet(
              submission.userId
            );
          });
          return zip([
            forkJoin(fieldEntriesObservable),
            forkJoin(userListObservable),
            forkJoin(usersObservable),
          ]).pipe(
            map(([fieldEntries, usersList, usersImgList]) => {
              return fieldEntries.map((fieldEntry, i) => {
                return {
                  fieldEntries: fieldEntry.fieldEntries,
                  user: { ...usersList[i], ...usersImgList[i] },
                };
              });
            })
          );
        }),
        tap((result) => {
          this.formSubmissions = result;
          this.loading = false;
        })
      );
  }
  async verifyFieldEntry(
    fieldEntry,
    verified: boolean,
    formSubmissionIndex: number,
    fieldEntryIndex: number
  ) {
    if (verified) {
      this.formSubmissions[formSubmissionIndex].fieldEntries.splice(
        fieldEntryIndex,
        1
      );
      await firstValueFrom(
        this.fieldEntriesService.servicesBurecordsFieldEntriesIdPut(
          fieldEntry.id,
          { fieldEntry: { approvalStatus: "approved" } }
        )
      );
    } else {
      let rejectedModal = await this.modalService.openModal(
        RejectFieldEntryModalComponent
      );
      if (rejectedModal) {
        this.formSubmissions[formSubmissionIndex].fieldEntries.splice(
          fieldEntryIndex,
          1
        );
        await firstValueFrom(
          this.fieldEntriesService.servicesBurecordsFieldEntriesIdPut(
            fieldEntry.id,
            {
              fieldEntry: {
                approvalStatus: "rejected",
                rejectMessage: rejectedModal,
              },
            }
          )
        );
      }
    }
  }
}
