import { Component, OnInit } from "@angular/core";
import {
  CoursesService,
  Resource,
  ResourcesService,
  V1Exam,
  V1ExamsService,
} from "src/app/sinigangnababoywithgabi";
import { ActivatedRoute } from "@angular/router";
import { firstValueFrom } from "rxjs";
import { LinkList } from "src/app/app-common-module/layouts/profile-link-list/profile-link-list.component";

@Component({
  selector: "app-resource-profile",
  templateUrl: "./resource-profile.component.html",
})
export class ResourceProfileComponent implements OnInit {
  resource: Resource;

  linkLists: LinkList[];

  canView: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private resourcesService: ResourcesService,
    private coursesService: CoursesService,
    private v1ExamsService: V1ExamsService
  ) {}

  async ngOnInit() {
    let { resource_uuid: resourceUuid } = this.route.snapshot.queryParams;

    this.resource = await firstValueFrom(
      this.resourcesService.getResourceByUuid(resourceUuid)
    );

    if (this.resource.allowedActions.includes("update")) {
      this.canView = true;
    } else {
      this.canView = false;
    }

    await this.setUpExternal();

    this.setuplinkList();
  }

  async setUpExternal() {
    if (
      this.resource.type === "buribook-with-activity" ||
      this.resource.type === "assessment"
    ) {
      let exam: V1Exam;
      try {
        exam = await firstValueFrom(
          this.v1ExamsService.examsRead(
            this.resource.content["examDetails"]?.["examUuid"],
            "allowed_actions"
          )
        );
      } catch (err) {}

      if (exam && !exam.allowedActions.includes("update")) {
        this.canView = false;
      } else {
        this.canView = true;
      }
    } else if (this.resource.type === "course") {
      let course;
      try {
        course = await firstValueFrom(
          this.coursesService.courseRead(
            this.resource.content["courseDetails"]?.["courseUuid"],
            "allowed_actions"
          )
        );
      } catch (err) {}

      if (course) {
        this.canView = true;
      } else {
        this.canView = false;
      }
    }
  }

  setuplinkList() {
    this.linkLists = [
      {
        icon: "account_box",
        name: "Resource Details",
        routerLinkOptions: {
          routerLink: ["../edit"],
          queryParamsHandling: "merge",
        },
      },
      {
        icon: "date_range",
        name: "Publish Details",
        description: "View and edit publish details",
        routerLinkOptions: {
          routerLink: ["../publish"],
          queryParamsHandling: "merge",
        },
      },
      {
        icon: "folder",
        name: "Collections",
        description: `This resource belongs to ${this.resource.packages.length} collection/s`,
        routerLinkOptions: {
          routerLink: ["../collection-edit"],
          queryParamsHandling: "merge",
        },
      },
      {
        icon: "insert_chart",
        name: "Analytics",
        description: "Not yet available",
        disabled: true,
        routerLinkOptions: {
          routerLink: ["../analytics-index"],
          queryParamsHandling: "merge",
        },
      },
    ];

    if (this.resource.type == "burislides") {
      this.linkLists.splice(3, 1, {
        icon: "insert_chart",
        name: "Slides Analytics",
        // description: "Not yet available",
        // disabled: true,

        routerLinkOptions: {
          routerLink: ["/resources", "profile", "burislide-analytics"],
          queryParamsHandling: "merge",
        },
      });
    }

    if (this.resource.type == "course") {
      this.linkLists.push({
        icon: "local_mall",
        name: "Payments",
        // description: "View and edit publish details",
        routerLinkOptions: {
          routerLink: ["/_courses", "payments"],
          queryParams: {
            course_uuid: this.resource.content.courseDetails?.courseUuid,
          },
        },
      });

      this.linkLists.push({
        icon: "group",
        name: "Batches",
        // description: "View and edit publish details",
        routerLinkOptions: {
          routerLink: ["/_courses", "batches"],
          queryParams: {
            course_uuid: this.resource.content.courseDetails?.courseUuid,
          },
        },
      });

      this.linkLists.splice(3, 1, {
        icon: "insert_chart",
        name: "Analytics",
        // description: "Not yet available",
        // disabled: true,

        routerLinkOptions: {
          routerLink: ["/_courses", "analytics", "index"],
          queryParams: {
            course_uuid: this.resource.content.courseDetails?.courseUuid,
          },
        },
      });

      this.linkLists.push({
        icon: "email",
        name: "Email Notifications",
        routerLinkOptions: {
          routerLink: ["/_courses", "email-notifications"],
          queryParamsHandling: "merge",
          queryParams: {
            course_uuid: this.resource.content.courseDetails?.courseUuid,
          },
        },
      });

      this.linkLists.push({
        icon: "beenhere",
        name: "Certification",
        routerLinkOptions: {
          routerLink: ["/_courses", "certification"],
          queryParamsHandling: "merge",
          queryParams: {
            course_uuid: this.resource.content.courseDetails?.courseUuid,
          },
        },
      });

      this.linkLists.push({
        icon: "policy",
        name: "Course Admins",
        routerLinkOptions: {
          routerLink: ["/_courses", "permissions"],
          queryParamsHandling: "merge",
          queryParams: {
            course_uuid: this.resource.content.courseDetails?.courseUuid,
          },
        },
      });

      this.linkLists.push({
        icon: "list_alt",
        name: "Course Editor Log",
        routerLinkOptions: {
          routerLink: ["/_courses", "activity-logs"],
          queryParamsHandling: "merge",
          queryParams: {
            course_uuid: this.resource.content.courseDetails?.courseUuid,
          },
        },
      });

      this.linkLists.push({
        icon: "palette",
        name: "Course Appearance",
        routerLinkOptions: {
          routerLink: ["/_courses", "appearance"],
          queryParamsHandling: "merge",
          queryParams: {
            course_uuid: this.resource.content.courseDetails?.courseUuid,
          },
        },
      });
    }

    if (
      this.resource.type == "buribook-with-activity" ||
      this.resource.type == "assessment"
    ) {
      this.linkLists.push({
        icon: "view_list",
        name: "Student answers",
        description: "View student answers",
        routerLinkOptions: {
          routerLink: ["/_exams", "user-submissions"],
          queryParams: {
            exam_uuid: this.resource.content["examDetails"]?.["examUuid"],
            timeslot_uuid:
              this.resource.content["examDetails"]?.["examTimeslotUuid"],
          },
        },
      });

      this.linkLists.push({
        icon: "insert_chart",
        name: "Exam results",
        description: "View exam results",
        routerLinkOptions: {
          routerLink: ["/_exams", "results"],
          queryParams: {
            exam_uuid: this.resource.content["examDetails"]?.["examUuid"],
            timeslot_uuid:
              this.resource.content["examDetails"]?.["examTimeslotUuid"],
          },
        },
      });
    }

    //Set disabled field of all active link list
    this.linkLists
      .filter((linkList) => !linkList.disabled)
      .forEach((linkList) => {
        linkList.disabled = !this.canView;
      });
  }
}
