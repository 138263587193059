<app-back-title-layout
  layoutTitleIcon="person"
  layoutTitle="{{ user?.firstName }} {{ user?.lastName }}"
  [titleIconButtons]="[
    {
      icon: 'mode',
      routerLink: 'edit',
      queryParamsHandling: 'merge'
    }
  ]"
  [loading]="!user"
>
  <div backTitleLayoutBeforeMain>
    <p class="my-2">{{ user?.email }}</p>

    <ng-container *ngFor="let group of user?.groups">
      <span
        class="inline-block rounded-full text-secondary-500 bg-secondary-100 px-2 py-0.5 m-0.5 max-w-xs truncate text-sm"
      >
        {{ group.name }}
      </span>
    </ng-container>

    <a
      class="block uppercase text-primary-600 text-sm"
      [routerLink]="['activity-logs']"
      queryParamsHandling="merge"
    >
      <app-icon icon="list_alt"></app-icon>

      View Activity Log
    </a>

    <h2 class="form-heading mt-10 mb-4">Completion and Progress</h2>

    <section>
      <div class="flex">
        <div class="rounded bg-primary-200 px-4 py-2 text-primary-500 mr-4">
          <div class="uppercase text-sm font-bold mb-2">
            Total Learning Time
          </div>

          <div class="text-2xl text-primary-700 font-bold">
            {{ totalReadingTime | timeDuration }}
          </div>
        </div>

        <div class="rounded bg-secondary-200 px-4 py-2 text-secondary-500 mr-4">
          <div class="uppercase text-sm font-bold mb-2">
            Attempted Resources Count
          </div>

          <div class="text-2xl text-secondary-700 font-bold">
            {{ attemptedResourcesCount }}
          </div>
        </div>

        <div class="rounded bg-success-200 px-4 py-2 text-success-500 mr-4">
          <div class="uppercase text-sm font-bold mb-2">
            Completed Resources Count
          </div>

          <div class="text-2xl text-success-700 font-bold">
            {{ completedResourcesCount }}
          </div>
        </div>
      </div>
    </section>

    <h2 class="form-heading mt-10 mb-4">Courses</h2>
  </div>
  <ng-container *ngIf="user">
    <app-item-list [itemTemplate]="itemTemplate" [source]="courseStatisticsObs">
    </app-item-list>
  </ng-container>
</app-back-title-layout>

<ng-template #itemTemplate let-item="item">
  <a
    [routerLink]="['stats', 'course-progress']"
    queryParamsHandling="merge"
    [queryParams]="{
      course_uuid: item.courseUuid
    }"
    class="flex items-center"
  >
    <img class="w-16 h-16 object-contain mr-5" [src]="item.coverUri" alt="" />

    <div>
      <div class="uppercase text-gray-500">Course</div>
      <div class="font-bold">{{ item.name }}</div>
      <div [ngSwitch]="item.status">
        <span
          *ngSwitchCase="'passed'"
          class="rounded-full text-success-700 bg-success-100 px-1"
        >
          Completed
        </span>
        <span
          *ngSwitchCase="'in_progress'"
          class="rounded-full text-secondary-700 bg-secondary-100 px-1"
        >
          In progress
        </span>
        <span
          *ngSwitchCase="'failed'"
          class="rounded-full text-error-700 bg-error-100 px-1"
        >
          Failed
        </span>

        <span
          *ngSwitchCase="'not_started'"
          class="rounded-full text-gray-700 bg-gray-100 px-1"
        >
          Not started
        </span>

        <span
          *ngSwitchCase="'needs_attention'"
          class="rounded-full text-warning-700 bg-warning-100 px-1"
        >
          Needs attention
        </span>
      </div>
    </div>
  </a>
</ng-template>
