export const environment = {
  production: true,
  CLIENT_ID: "66c706874ee344cacb7b14dd45561b247e4854f13d5b7ba0fae8e76aba10a46f",

  // Deprecated
  DOMAIN_CAST_MAP: new Map([
    ["https://production.dashboard.buri.io", 4],
    ["https://dashboard.demo.buri.dev", 7],
    ["https://dfa.dashboard.buri.io", 7],
    ["https://wikahon.dashboard.buri.io", 12],
    ["https://learninglibrary.dashboard.buri.io", 15],
    ["https://dev.dashboard.buri.dev", 4],
    ["https://staging.dashboard.buri.io", 4],
    ["https://2n4yzipo.dashboard.buri.io", 13],
    ["https://uatmetro.dashboard.buri.io", 16],
    ["https://uatdict.dashboard.buri.io", 17],
    ["https://uatgcash.dashboard.buri.io", 24],
    ["https://gcash.dashboard.buri.io", 24],
    ["https://uatgsis.dashboard.buri.io", 26],
    ["https://gsis.dashboard.buri.io", 26],
    ["https://buribooks.dashboard.buri.io", 23],
    ["https://dict.dashboard.buri.io", 28],
    ["https://ppp.dashboard.buri.io", 30],
    ["https://buricast.dashboard.buri.io", 31],

    ["https://production.castdashboard.com", 4],
    ["https://dfa.castdashboard.com", 7],
    ["https://wikahon.castdashboard.com", 12],
    ["https://learninglibrary.castdashboard.com", 15],
    ["https://gcash.castdashboard.com", 24],
    ["https://gsis.castdashboard.com", 26],
    ["https://buribooks.castdashboard.com", 23],
    ["https://dict.castdashboard.com", 28],
    ["https://ppp.castdashboard.com", 30],
    ["https://buricast.castdashboard.com", 31],
    ["https://dict.castdashboard.com", 32],
    ["https://btech-dict.castdashboard.com", 33],
    ["https://nlpsc-dict.castdashboard.com", 34],
    ["https://ibacmi-dict.castdashboard.com", 35],
    ["https://nbsc-dict.castdashboard.com", 36],
    ["https://training.castdashboard.com", 38],

    ["https://staging.dashboard.buri.dev", 4],
    ["https://dictstagingserver.dashboard.buri.dev", 28],
    ["https://dfa.staging.dashboard.buri.dev", 7],
    ["https://dict.staging.dashboard.buri.dev", 32],
  ]),

  DOMAIN_REALM_MAP: new Map([
    ["https://uatdict.dashboard.buri.io", "dict-6016.onburi.com"],
  ]),

  get OAUTH_URI(): string {
    //Special handling for preprod
    if (/.*preprod.dashboard\.buri\.dev/.test(window.location.hostname)) {
      return "https://pre-prod.buripass.buri.dev";
      //Special handling for staging
    } else if (
      /.*.dashboard\.buri\.dev/.test(window.location.hostname) ||
      /.*staging.dashboard\.buri\.dev/.test(window.location.hostname) ||
      window.location.hostname === "dictstagingserver.dashboard.buri.dev" ||
      window.location.hostname === "bokchoy.castdashboard.com" ||
      window.location.hostname === "bokchoy.dashboard.buri.dev"
    ) {
      return "https://staging.pass.buri.dev";
    } else {
      return "https://pass.buri.io";
    }
  },

  // Disable freedom wall because of https://buri.dev/T1232
  get FREEDOM_WALL_ENABLED() {
    if (
      window.location.hostname === "training.castdashboard.com" ||
      window.location.hostname === "senco.castdashboard.com" ||
      window.location.hostname === "bokchoy.castdashboard.com" ||
      window.location.hostname === "bokchoy.dashboard.buri.dev"
    ) {
      return true;
    } else {
      return false;
    }
  },
  get IDIS_ENABLED() {
    return (
      window.location.hostname === "staging.dashboard.buri.dev" ||
      window.location.hostname === "bokchoy.castdashboard.com" ||
      window.location.hostname === "bokchoy.dashboard.buri.dev" ||
      window.location.hostname === "deped.castdashboard.com"
    );
  },
  get UNLOCK_SCHEDULE_ENABLED() {
    if (
      /.*preprod.dashboard\.buri\.dev/.test(window.location.hostname) ||
      /.*staging.dashboard\.buri\.dev/.test(window.location.hostname) ||
      window.location.hostname === "training.castdashboard.com" ||
      window.location.hostname === "senco.castdashboard.com" ||
      window.location.hostname === "bokchoy.castdashboard.com" ||
      window.location.hostname === "bokchoy.dashboard.buri.dev" ||
      window.location.hostname === "dfa.castdashboard.com" ||
      window.location.hostname === "management-strategies.castdashboard.com"
    ) {
      return true;
    } else {
      return false;
    }
  },
  get PURCHASES_ENABLED() {
    if (
      /.*dashboard\.buri\.dev/.test(window.location.hostname) ||
      window.location.hostname === "bokchoy.castdashboard.com" ||
      window.location.hostname === "buribooksretailapp.castdashboard.com"
    ) {
      return true;
    } else {
      return false;
    }
  },
  get MAILER_ENABLED() {
    return true;

    // if (/.*staging\.dashboard\.buri\.dev/.test(window.location.hostname)) {
    //   return true;
    // } else {
    //   return false;
    // }
  },
  // For netlify use
  get BASE_URI(): string {
    // Direct 3rd party request
    if (/.*preprod\.dashboard\.buri\.dev/.test(window.location.hostname)) {
      // return "https://preproduction.gateway.buri.dev";
      return "/api-preprod";
    }
    if (
      /.*dashboard\.buri\.dev/.test(window.location.hostname) ||
      window.location.hostname === "dictstagingserver.dashboard.buri.dev" ||
      window.location.hostname === "bokchoy.castdashboard.com"
    ) {
      // return "https://staging.gateway.buri.dev";
      return "/api-staging";
    } else if (window.location.hostname === "testing.dashboard.buri.dev") {
      return "https://testing.gateway.buri.dev";
    } else {
      // return "https://gateway.buri.io/v1";
      return "/api";
    }

    // Uncomment for netlify redirecting
    // if (
    //   /.*dashboard\.buri\.dev/.test(window.location.hostname) ||
    //   window.location.hostname === "dictstagingserver.dashboard.buri.dev"
    // ) {
    //Special handling for staging
    //   return "/stagingapi";
    // } else if (window.location.hostname === "testing.dashboard.buri.dev") {
    //   return "/testapi";
    // } else {
    //   return "/api";
    // }
  },

  get LEARNERS_URI(): string {
    if (/.*preprod.dashboard\.buri\.dev/.test(window.location.hostname)) {
      return `https://${
        window.location.hostname.split(".")[0]
      }.preprod.cast-learner-react.buri.dev`;
      //Special handling for staging
    } else if (
      window.location.hostname === "staging.dashboard.buri.dev" ||
      window.location.hostname === "dictstagingserver.dashboard.buri.dev" ||
      window.location.hostname === "bokchoy.castdashboard.com" ||
      window.location.hostname === "bokchoy.dashboard.buri.dev"
    ) {
      return `https://${
        window.location.hostname.split(".")[0]
      }.cast-learner-react.buri.dev`;
    } else {
      return `https://${window.location.hostname.split(".")[0]}.castlms.com`;
    }
  },

  get GATEWAY_URI(): string {
    // return "https://gateway.buri.io/v1";
    return "/api";
  },

  get CANVA_API(): string {
    return "https://canva-extension.buri.dev/url";
  },

  get TIS_ENABLED() {
    return (
      window.location.hostname.includes("staging.dashboard.") ||
      window.location.hostname.includes("fsi.") ||
      window.location.hostname.includes("bokchoy.") ||
      window.location.hostname.includes("preprod.") ||
      window.location.hostname.includes("training.")
    );
    // ||     window.location.hostname.includes("gbf.") ||
  },
};
