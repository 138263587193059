<app-back-title-layout [loading]="!formGroup">
  <ng-container *ngIf="formGroup">
    <form [formGroup]="formGroup" (ngSubmit)="onSave()">
      <label class="form-label">Colors</label>
      <div class="text-gray-400 text-xs">
        Your Cast already have its default overall color theme, changing the
        assigned colors below will override the your overall Cast color theme
      </div>

      <button
        type="button"
        (click)="resetToDefault()"
        class="my-2 text-primary text-sm hover:underline font-semibold"
      >
        Reset to default
      </button>

      <div class="my-10">
        <label class="form-label text-lg"> Nav Bar Logo </label>
        <div class="text-gray-400 text-xs">Sample preview</div>

        <div
          class="my-1 flex items-center border-2 rounded-sm p-1"
          [style.backgroundColor]="formGroup.value.navBackgroundColor"
          [style.color]="formGroup.value.navForegroundColor"
        >
          <img class="h-8" [src]="formGroup.value.navbarImageUri" alt="" />

          <span
            class="mx-1 font-bold truncate"
            [style.fontFamily]="formGroup.value.navFont"
          >
            {{ formGroup.value.castTitle }}
          </span>
        </div>

        <a
          routerLink="/_casteditor/cast-edit"
          class="my-2 text-primary text-sm hover:underline font-semibold"
        >
          Change cast Logo
        </a>

        <div class="my-5">
          <label class="form-label">Font Style</label>

          <app-font-select formControlName="navFont"></app-font-select>
        </div>

        <div class="my-5">
          <label class="form-label">Background Color</label>

          <div class="w-24">
            <app-color formControlName="navBackgroundColor"> </app-color>
          </div>
        </div>

        <!-- <div class="my-5">
            <label class="form-label">Cast Name Color</label>

            <div class="w-24">
              <app-color formControlName="navForegroundColor"> </app-color>
            </div>
          </div> -->
      </div>

      <div class="my-10">
        <label class="form-label text-lg"> Nav Bar </label>
        <div>
          <div class="text-gray-400 text-xs leading-tight">
            Sample preview (Web)
          </div>

          <div
            class="my-1 flex justify-end items-center border-2 rounded-sm p-1"
            [style.backgroundColor]="formGroup.value.navBackgroundColor"
            [style.fontFamily]="formGroup.value.itemFont"
          >
            <button
              type="button"
              class="btn py-1 px-2 mx-1"
              [style.backgroundColor]="
                formGroup.value.itemActiveBackgroundColor
              "
              [style.color]="formGroup.value.itemActiveForegroundColor"
            >
              <app-icon class="mx-1" icon="home"></app-icon>

              <span>Home</span>
            </button>

            <button
              type="button"
              class="btn py-1 px-2 bg-transparent mx-1"
              [style.backgroundColor]="formGroup.value.itemBackgroundColor"
              [style.color]="formGroup.value.itemForegroundColor"
            >
              <app-icon class="mx-1" icon="home"></app-icon>

              <span>Learner</span>
            </button>

            <button
              type="button"
              class="btn bg-transparent btn-icon mx-1"
              [style.color]="formGroup.value.itemForegroundColor"
            >
              <app-icon icon="more_horiz"></app-icon>
            </button>
          </div>
        </div>

        <div>
          <div class="text-gray-400 text-xs leading-tight">
            Sample preview (iOS)
          </div>

          <div
            class="my-1 flex justify-between items-center border-2 rounded-sm p-3"
            [style.backgroundColor]="formGroup.value.navBackgroundColor"
            [style.fontFamily]="formGroup.value.itemFont"
          >
            <ng-container
              *ngTemplateOutlet="
                iOSButtonSample;
                context: { icon: 'home', text: 'Home', isSelected: true }
              "
            >
            </ng-container>

            <ng-container
              *ngTemplateOutlet="
                iOSButtonSample;
                context: {
                  icon: 'menu_book',
                  text: 'Learner'
                }
              "
            >
            </ng-container>

            <ng-container
              *ngTemplateOutlet="
                iOSButtonSample;
                context: {
                  icon: 'download',
                  text: 'Downloads'
                }
              "
            >
            </ng-container>

            <ng-container
              *ngTemplateOutlet="
                iOSButtonSample;
                context: {
                  icon: 'search',
                  text: 'Search'
                }
              "
            >
            </ng-container>

            <ng-container
              *ngTemplateOutlet="
                iOSButtonSample;
                context: {
                  icon: 'more_horiz',
                  text: 'See More'
                }
              "
            >
            </ng-container>
          </div>
        </div>
        <div class="my-5">
          <label class="form-label">Search Screen</label>
          <select class="form-control" formControlName="searchSlug">
            <option [ngValue]="null">-----</option>
            <option *ngFor="let screen of screens" [ngValue]="screen.slug">
              {{ screen.title }}
            </option>
          </select>
        </div>
        <div class="my-5">
          <label class="form-label">Font Style</label>

          <app-font-select formControlName="itemFont"></app-font-select>
        </div>

        <div class="my-5">
          <label class="form-label">
            <div>Web - Active Tab Color</div>
            <div>iOS - Active Icon and Font Color</div>
          </label>

          <div class="w-24">
            <app-color formControlName="itemActiveBackgroundColor"> </app-color>
          </div>
        </div>

        <div class="my-5">
          <label class="form-label"> Web - Active Icon and Font Color </label>

          <div class="w-24">
            <app-color formControlName="itemActiveForegroundColor"> </app-color>
          </div>
        </div>

        <div class="my-5">
          <label class="form-label"> Web - Tab Color </label>

          <div class="w-24">
            <app-color formControlName="itemBackgroundColor"> </app-color>
          </div>
        </div>

        <div class="my-5">
          <label class="form-label">
            <div>Web - Icon and Font Color</div>
            <div>iOS - Icon and Font Color</div>
          </label>

          <div class="w-24">
            <app-color formControlName="itemForegroundColor"> </app-color>
          </div>
        </div>
      </div>

      <button class="btn btn-primary w-full" type="submit">SAVE</button>
    </form>
  </ng-container>
</app-back-title-layout>

<ng-template
  #iOSButtonSample
  let-icon="icon"
  let-text="text"
  let-isSelected="isSelected"
>
  <button
    type="button"
    class="flex flex-col"
    [style.color]="
      isSelected
        ? formGroup.value.itemActiveBackgroundColor
        : formGroup.value.itemForegroundColor
    "
  >
    <app-icon class="text-2xl" icon="{{ icon }}"></app-icon>

    <span class="text-xs leading-none">{{ text }}</span>
  </button>
</ng-template>
