<div class="mb-4 relative" *ngIf="resource && resource.uuid">
  <app-loading
    position="absolute inset-0 bg-white bg-opacity-50"
    [loading]="loading"
  >
  </app-loading>

  <div class="my-5">
    <label class="form-label"> Course Content </label>

    <!-- <div class="flex justify-center my-4">
      <svg
        width="214"
        height="182"
        viewBox="0 0 214 182"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="214" height="182" fill="#F3F3F3" />
        <rect x="6" y="6" width="202" height="61" fill="#DEDEDE" />
        <rect x="6" y="73" width="43" height="61" fill="#DEDEDE" />
        <rect x="53" y="73" width="43" height="61" fill="#DEDEDE" />
        <rect x="100" y="73" width="43" height="61" fill="#DEDEDE" />
        <rect x="6" y="140" width="90" height="42" fill="#DEDEDE" />
      </svg>
    </div>
  
    <button type="button" class="btn btn-xs mb-3 w-full" disabled>
      PREVIEW (NOT YET AVAILABLE)
    </button> -->

    <a
      [routerLink]="['/_courses', 'content-edit']"
      [queryParams]="{
        course_uuid: resource.content?.courseDetails?.courseUuid,
        resource_collection_uuid: resource.content?.resourceCollectionUuid,
        resource_uuid: resource.uuid
      }"
      class="block btn btn-primary w-full"
    >
      <app-icon icon="edit"></app-icon>
      EDIT COURSE CONTENT
    </a>
  </div>
</div>
