import { Component, Inject, Input, OnInit } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ValuesOfCorrectTypeRule } from "graphql";
import { V1ExamsService } from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-exam-submission-response-rubric-grading",
  templateUrl: "./exam-submission-response-rubric-grading.component.html",
  styleUrls: ["./exam-submission-response-rubric-grading.component.scss"],
})
export class ExamSubmissionResponseRubricGradingComponent implements OnInit {
  isLoading: boolean;

  @Input()
  responseUuid: string;
  @Input()
  itemUuid: string;

  rubricFormArray: UntypedFormArray;
  formGroup: UntypedFormGroup;

  constructor(
    private v1ExamsService: V1ExamsService,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) data,
    public dialogRef: MatDialogRef<ExamSubmissionResponseRubricGradingComponent>
  ) {
    this.responseUuid = data?.responseUuid;
    this.itemUuid = data?.itemUuid;
  }

  async ngOnInit() {
    this.isLoading = true;

    if (!this.itemUuid) {
      return;
    }

    let rubrics = await this.v1ExamsService
      .examsSectionsItemsCriteriaList(this.itemUuid)
      .toPromise();

    let responseCriteria = await this.v1ExamsService
      .responseCriteriaList(this.responseUuid)
      .toPromise();

    let responseCriteriaUuidMap = responseCriteria.reduce((map, rc) => {
      map[rc.itemCriteria?.uuid] = rc;
      return map;
    }, {});

    this.formGroup = this.formBuilder.group({
      rubricFormArray: this.formBuilder.array(
        rubrics.map((rubric) => {
          return this.formBuilder.group({
            criteriaUuid: [rubric.uuid],
            responseCriteriaUuid: [responseCriteriaUuidMap[rubric.uuid]?.uuid],
            name: [rubric.name],
            weight: [rubric.weight],
            score: [
              responseCriteriaUuidMap[rubric.uuid]?.score,
              Validators.required,
            ],
            apparentScore: [0],
          });
        })
      ),
    });

    this.isLoading = false;
  }

  getRubricFormArray() {
    return this.formGroup.get("rubricFormArray") as UntypedFormArray;
  }

  async saveResponseCriteria() {
    this.isLoading = true;
    try {
      await Promise.all(
        this.formGroup.value.rubricFormArray.map((value) => {
          if (value.responseCriteriaUuid) {
            return this.v1ExamsService
              .responseCriteriaUpdate(value.responseCriteriaUuid, {
                score: value.score,
                itemCriteria: value.criteriaUuid,
              })
              .toPromise();
          } else {
            return this.v1ExamsService
              .responseCriteriaCreate(this.responseUuid, {
                score: value.score,
                itemCriteria: value.criteriaUuid,
              })
              .toPromise();
          }
        })
      );

      this.dialogRef.close(this.totalScore);
    } catch (err) {
      this.isLoading = false;
      throw err;
    }
  }

  get totalScore() {
    return this.getRubricFormArray().value.reduce((sum, item) => {
      sum += item.score * item.weight;
      return sum;
    }, 0);
  }
}
