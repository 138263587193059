<app-back-title-layout [loading]="!purchaseDetail || loading">
  <div class="form-width" *ngIf="purchaseDetail">
    <div
      class="w-full h-40 object-contain bg-gray-300 rounded flex items-center justify-center my-5"
    >
      <app-icon icon="local_mall" class="text-6xl text-primary-light">
      </app-icon>
      <!-- <img
        [src]="purchaseDetail.coverUri"
        alt=""
        class="w-full h-40 object-contain bg-gray-300 rounded"
      /> -->
    </div>

    <div class="flex justify-between items-start">
      <a [routerLink]="['/puchases', 'list', 'payment-option']">
        <div class="text-lg font-bold">
          {{ purchaseDetail.name }}
        </div>

        <div class="text-primary font-bold">
          {{ purchaseDetail.description }}
        </div>
      </a>

      <div>
        <button
          *ngIf="!purchaseDetail.subscriptionGroupUuid"
          [routerLink]="['/purchases', 'list', 'payment-option']"
          [queryParams]="{ product_id: purchaseDetail.productId }"
          class="mx-1 btn btn-icon btn-primary-light"
        >
          <app-icon icon="edit"></app-icon>
        </button>

        <button
          *ngIf="purchaseDetail.subscriptionGroupUuid"
          [routerLink]="['/purchases', 'list', 'subscription-edit']"
          [queryParams]="{
            product_id: purchaseDetail.productId,
            subscription_group_uuid: purchaseDetail.subscriptionGroupUuid,
            product_reference_type: purchaseDetail.productReferenceType,
            product_reference_id: purchaseDetail.productReferenceId
          }"
          class="mx-1 btn btn-icon btn-primary-light"
        >
          <app-icon icon="edit"></app-icon>
        </button>

        <button (click)="deleteProduct()" class="mx-1 btn btn-danger btn-icon">
          <app-icon icon="delete"></app-icon>
        </button>
      </div>
    </div>

    <hr class="my-5" />

    <div>
      <label class="font-bold">Subscription History</label>

      <hr class="my-5" />

      <div class="my-5">
        <app-search-bar></app-search-bar>
      </div>

      <a
        [routerLink]="['add-user']"
        queryParamsHandling="merge"
        [queryParams]="{
          product_id: purchaseDetail.productId,
          subscription_group_uuid: purchaseDetail.subscriptionGroupUuid,
          product_reference_type: purchaseDetail.productReferenceType,
          product_reference_id: purchaseDetail.productReferenceId
        }"
        class="btn btn-primary-light btn-thin form-width"
      >
        <app-icon icon="add"></app-icon>

        Add manual payment
      </a>

      <div class="my-5">
        <app-item-select-with-header
          key="userSubscriptionUuid"
          [headers]="headers"
          [batchActions]="batchActions"
          [totalCount]="purchaseHistoryTotalCount"
          category="user"
          [source]="purchaseHistoryObservable"
          [itemTemplate]="itemTemplate"
          [limit]="pageSize"
          [enableSort]="true"
          [loading]="loading"
          (selectedItemList)="onSelectedItemList($event)"
        >
        </app-item-select-with-header>
      </div>
    </div>
  </div>
</app-back-title-layout>

<ng-template #itemTemplate let-item="item">
  <div class="block w-full ml-2 mt-2 sm:mt-0 sm:flex">
    <div
      class="grid grid-cols-1 my-1 w-full sm:flex"
      *ngFor="let header of headers"
    >
      <!-- Email -->
      <div
        class="block {{ header.width }} sm:flex"
        *ngIf="header.name === 'Email'"
      >
        <div>
          <app-icon
            icon="person"
            class="text-white text-xl leading-none px-1 mr-1 bg-gray-200 rounded-full items-center justify-center shrink"
          ></app-icon>
        </div>

        <div
          class="text-sm text-gray-700 sm:items-center flex-wrap truncate sm:w-full"
        >
          {{ item.email }}

          <span *ngIf="!item.confirmedAt" class="text-sm text-red-500 italic">
            <app-icon icon="error" class="align-bottom"></app-icon>
            <!-- <span> email not yet confirmed </span> -->
          </span>
        </div>
      </div>
      <!-- Name -->
      <div
        class="truncate text-sm sm:items-center {{ header.width }}"
        *ngIf="header.name === 'Name'"
      >
        {{ item.name }}
      </div>

      <!-- Status -->
      <div
        class="truncate text-sm sm:items-center {{ header.width }}"
        *ngIf="header.name === 'Status'"
      >
        <app-pill-label
          [type]="item.isSubscriptionActive ? 'success' : 'default'"
          >{{
            item.isSubscriptionActive ? "Active" : "Inactive"
          }}</app-pill-label
        >
      </div>

      <!-- Created -->
      <div
        class="sm:items-center truncate {{ header.width }}"
        *ngIf="header.name === 'Created'"
      >
        {{ item.subscriptionDate | date }}
      </div>

      <!-- Expires at -->
      <div
        class="sm:items-center truncate {{ header.width }}"
        *ngIf="header.name === 'Expires at'"
      >
        {{ item.expiresDate | date }}
      </div>
    </div>
  </div>
</ng-template>
