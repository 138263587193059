<app-back-title-layout
  [titleIconButtons]="[
    {
      icon: 'mode',
      observable: hideTempObservable
    }
  ]"
  [layoutTitle]="hideTempDetails ? layoutTitle : 'Edit Template'"
  [layoutTitleIcon]="hideTempDetails ? 'mail' : ''"
  (result)="setResult($event)"
>
  <div
    backTitleLayoutBeforeMain
    class="mt-2"
    [ngClass]="hideTempDetails ? '' : 'hidden'"
  >
    {{ layoutDesc }}
  </div>
  <div
    *ngIf="loading"
    [@fadeInOut]
    class="absolute inset-0 bg-white bg-opacity-50 h-full"
  >
    <app-loading [loading]="true" position="relative"></app-loading>
  </div>

  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div [ngClass]="hideTempDetails ? 'hidden' : ''">
      <div class="flex justify-between mb-4">
        <div class="form-heading">Template Details</div>
      </div>
      <label for="tempName" class="form-label mb-2">Template Name</label>
      <input
        name="tempName"
        id="tempName"
        placeholder="Input Template Name"
        class="form-control"
        formControlName="tempName"
      />
      <div class="mb-4">
        <app-form-control-error-layout
          [control]="form.get('tempName')"
        ></app-form-control-error-layout>
      </div>
      <label for="tempDesc" class="form-label mb-2">Template Description</label>
      <input
        name="tempDesc"
        id="tempDesc"
        placeholder="Enter Description"
        class="form-control"
        formControlName="tempDesc"
      />
      <div class="mb-4">
        <app-form-control-error-layout
          [control]="form.get('tempDesc')"
        ></app-form-control-error-layout>
      </div>
      <hr class="form-divider" />
    </div>

    <div class="flex justify-between mb-4">
      <h2 class="form-heading">Email Details</h2>
      <button
        class="uppercase bg-secondary-600 rounded text-white px-2 py-1 text-sm"
        [ngClass]="hideTempDetails ? '' : 'hidden'"
        (click)="useTemplate()"
      >
        use template
      </button>
    </div>

    <div class="form-label" [ngClass]="hideTempDetails ? 'mt-4' : ''">
      Email Subject
    </div>
    <input
      placeholder="Email Subject"
      class="form-control"
      formControlName="subject"
    />
    <div class="mb-4">
      <app-form-control-error-layout
        [control]="form.get('subject')"
      ></app-form-control-error-layout>
    </div>
    <div class="form-heading mb-4">Email Content</div>
    <div class="flex justify-between border border-gray-300 py-1 rounded">
      <div class="flex">
        <button
          type="button"
          class="text-primary-600 mx-2 text-lg"
          (click)="code()"
        >
          <app-icon [icon]="'code'"></app-icon>
        </button>
        <button
          type="button"
          class="text-primary-600 text-lg mx-2"
          (click)="preview()"
        >
          <app-icon [icon]="'remove_red_eye'"></app-icon>
        </button>
      </div>
      <button
        class="btn btn-primary-borderless mr-2"
        type="button"
        (click)="openModal(testEmailModal, 'testEmail')"
        [disabled]="form.invalid"
      >
        <app-icon [icon]="'mail'"></app-icon>
        send test email
      </button>
    </div>
    <textarea
      #content
      class="p-2 rounded w-full border border-gray-300 {{ inputHidden }}"
      rows="15"
      [style.resize]="'none'"
      formControlName="content"
    ></textarea>
    <div class="mb-8">
      <app-form-control-error-layout
        [control]="form.get('content')"
      ></app-form-control-error-layout>
    </div>
    <div
      [innerHTML]="content.value"
      class="p-2 overflow-y-auto border border-gray-300 rounded mb-8 w-full h-96 {{
        previewHidden
      }}"
    ></div>
    <div
      class="flex justify-between"
      [ngClass]="hideTempDetails ? 'hidden' : ''"
    >
      <button
        type="button"
        class="btn rounded bg-white text-red-600 border border-red-600"
        (click)="deleteTemplate()"
      >
        delete template
      </button>
      <button type="submit" class="btn btn-primary" [disabled]="form.invalid">
        save changes
      </button>
    </div>
  </form>
</app-back-title-layout>

<ng-template #testEmailModal>
  <app-test-email-modal [emailReq]="form"></app-test-email-modal>
</ng-template>

<ng-template #saveTemplateModal>
  <app-save-template-modal
    [emailReq]="form"
    [filters]="filtersForm"
  ></app-save-template-modal>
</ng-template>
