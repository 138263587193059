import { Component, OnInit } from "@angular/core";
import {
  Component as ResourceComponent,
  Collection,
  ResourcesService,
} from "src/app/sinigangnababoywithgabi";
import { ActivatedRoute, Router } from "@angular/router";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { firstValueFrom, Subject, takeUntil, zip } from "rxjs";
import { ModalService } from "src/app/services/modal.service";
import { fadeInOut } from "src/app/app-common-module/animations/fade";

export enum LIST_STATE {
  NORMAL = "normal",
  SELECT = "select",
  SORT = "sort",
  SHARE = "share",
}

// DEPRECATED
@Component({
  selector: "app-component-list",
  templateUrl: "./component-list.component.html",
  styleUrls: ["./component-list.component.scss"],
  animations: [fadeInOut],
})
export class ComponentListComponent implements OnInit {
  searchString: string;
  components: ResourceComponent[];
  selectedComponentMap: Map<string, boolean>;
  collection: Collection;
  parentUuid: string;
  loading: boolean = false;
  fromShared: boolean = false;
  LIST_STATE = LIST_STATE;
  listState: LIST_STATE = LIST_STATE.NORMAL;

  constructor(
    private resourcesService: ResourcesService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.route.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((query) => {
        this.parentUuid = query["parent_uuid"];

        this.refreshList();
      });
  }

  private unsubscribe$: Subject<void> = new Subject<void>();
  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  refreshList() {
    if (!this.fromShared) {
      this.components = null;
      this.collection = null;
    }
    this.fromShared = false;
    this.selectedComponentMap = new Map();

    if (this.parentUuid) {
      this.getChildComponents(this.parentUuid);
    } else {
      this.getRootComponents();
    }
  }

  async getRootComponents() {
    this.components = await firstValueFrom(
      //DEPRECATED
      // this.resourcesService.listComponents(true)
      this.resourcesService.listCollections()
    );

    this.components = this.components.map((component) => {
      return {
        ...component,
        type: "package",
      };
    });
    this.loading = false;
  }

  async shareCollection(collectionUuid: string) {
    let result = await this.modalService.confirm(
      `Share collection will allow other Cast(s) to have access to this collection. Continue?`
    );
    if (result) {
      this.loading = true;
      await firstValueFrom(
        this.resourcesService.updateCollectionByUuid(collectionUuid, {
          isShareable: true,
        })
      );
      this.fromShared = true;
      this.resetList();
    } else {
      this.listState = LIST_STATE.NORMAL;
    }
  }

  async unshareCollection(collectionUuid: string) {
    let result = await this.modalService.confirm(
      `Unshare collection will not allow other Cast(s) to have access to this collection. Continue?`
    );
    if (result) {
      this.loading = true;
      await firstValueFrom(
        this.resourcesService.updateCollectionByUuid(collectionUuid, {
          isShareable: false,
        })
      );
      this.fromShared = true;
      this.resetList();
    } else {
      this.listState = LIST_STATE.NORMAL;
    }
  }

  async getChildComponents(collectionUuid: string) {
    let result = await firstValueFrom(
      this.resourcesService.getCollectionByUuid(collectionUuid, 1, true)
    );

    this.collection = result;
    this.components = result.components;
    this.loading = false;
  }

  componentClick(component: ResourceComponent) {
    if (
      this.listState == LIST_STATE.SORT ||
      this.listState == LIST_STATE.SHARE
    ) {
      return;
    } else if (this.listState == LIST_STATE.SELECT) {
      let previousValue = this.selectedComponentMap.get(component.uuid);
      this.toggleSelectComponent(component, !previousValue);
      return;
    } else {
      this.searchString = "";

      if (component.type === "package") {
        this.router.navigate([], {
          queryParams: {
            parent_uuid: component.uuid,
          },
          queryParamsHandling: "merge",
        });
      } else {
        this.router.navigate(["/resources", "profile"], {
          queryParams: {
            resource_uuid: component.uuid,
          },
        });
      }
    }
  }

  editCollection(component: ResourceComponent) {
    this.router.navigate(["/collections", "edit"], {
      queryParams: {
        collection_uuid: component.uuid,
      },
    });
  }

  initializeSortMode() {
    this.searchString = "";
    this.listState = LIST_STATE.SORT;
  }

  initializeSelectMode() {
    this.searchString = "";
    this.listState = LIST_STATE.SELECT;
  }

  initializeNormalMode() {
    this.searchString = "";
    this.listState = LIST_STATE.NORMAL;
  }
  initializeShareMode() {
    this.searchString = "";
    this.listState = LIST_STATE.SHARE;
  }

  toggleSelectComponent(component: ResourceComponent, value: boolean) {
    this.selectedComponentMap.set(component.uuid, value);
  }

  onSortDrop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.components, event.previousIndex, event.currentIndex);
  }

  async sortDone() {
    await firstValueFrom(
      this.resourcesService.setComponentOrderInCollection(this.parentUuid, {
        order: this.components.map((component) => component.uuid),
      } as any)
    );

    this.listState = LIST_STATE.NORMAL;
  }

  resetList() {
    this.listState = LIST_STATE.NORMAL;
    this.refreshList();
  }

  async unpublishSelectedComponents() {
    //Use public function of ComponentListViewComponent to encapsulate how selected components are extracted
    let selectedComponents = this.components.filter((c) =>
      this.selectedComponentMap.get(c.uuid)
    );

    await firstValueFrom(
      zip(
        ...selectedComponents.map((component) => {
          component.publishedAt = null;

          if (component.type === "package") {
            return this.resourcesService.updateCollectionByUuid(
              component.uuid,
              component
            );
          } else {
            return this.resourcesService.updateResourceByUuid(
              component.uuid,
              component
            );
          }
        })
      )
    );
  }

  async publishSelectedComponents() {
    //Use public function of ComponentListViewComponent to encapsulate how selected components are extracted
    let selectedComponents = this.components.filter((c) =>
      this.selectedComponentMap.get(c.uuid)
    );

    await firstValueFrom(
      zip(
        ...selectedComponents.map((component) => {
          component.publishedAt = new Date().toISOString();
          if (component.type === "package") {
            return this.resourcesService.updateCollectionByUuid(
              component.uuid,
              component
            );
          } else {
            return this.resourcesService.updateResourceByUuid(
              component.uuid,
              component
            );
          }
        })
      )
    );
  }

  async deleteSelectedComponents() {
    //Use public function of ComponentListViewComponent to encapsulate how selected components are extracted
    let selectedComponents = this.components.filter((c) =>
      this.selectedComponentMap.get(c.uuid)
    );

    let result = await this.modalService.confirm(
      `Are you sure you want to delete the following components?\n${selectedComponents
        .map((c) => c.name)
        .join("\n")}`
    );

    if (result) {
      await firstValueFrom(
        zip(
          ...selectedComponents.map((component) => {
            if (component.type === "package") {
              return this.resourcesService.deleteCollectionByUuid(
                component.uuid
              );
            } else {
              return this.resourcesService.deleteResourceByUuid(component.uuid);
            }
          })
        )
      );

      this.resetList();
    }
  }
}
