<div class="flex text-gray-400 text-sm">
  <app-icon icon="view_column"> </app-icon>
  <div class="text-sm uppercase w-full">column</div>
  <button class="flex justify-end" mat-dialog-close>
    <app-icon icon="close"></app-icon>
  </button>
</div>
<div class="text-gray-400 mt-2 mb-2">
  Customize displayed columns on your table
</div>
<!-- <div class="flex text-gray-500 text-sm">
  <app-icon icon="push_pin"> </app-icon>
  <div>Freezed Column</div>
</div>

<div
  cdkDropList
  #freezedList="cdkDropList"
  [cdkDropListData]="freezedColumns"
  [cdkDropListConnectedTo]="[columnList]"
  (cdkDropListDropped)="dropColumn($event)"
  class="list"
>
  <div *ngFor="let freezed of freezedColumns" cdkDrag>
    <div class="flex border mb-2 border-gray-400 rounded">
      <app-checkbox
        class="text-xl"
        uncheckClass="text-gray-500"
        checkClass="text-primary-dark"
      ></app-checkbox>
      <div class="w-full pl-1">{{ freezed }}</div>
      <div class="flex justify-end">
        <app-icon icon="unfold_more" cdkDragHandle></app-icon>
      </div>
    </div>
  </div>
</div>

<hr class="text-gray-400 mb-4 mt-2" /> -->
<!-- [cdkDropListConnectedTo]="[freezedList]" -->
<div
  cdkDropList
  #columnList="cdkDropList"
  [cdkDropListData]="columnHeaders"
  (cdkDropListDropped)="dropColumn($event)"
  class="list"
>
  <div *ngFor="let columnHeader of columnHeaders" cdkDrag>
    <div class="flex border mb-2 border-gray-400 rounded">
      <app-checkbox
        class="text-xl"
        uncheckClass="text-gray-500"
        checkClass="text-primary-dark"
        [(ngModel)]="columnHeader.checked"
      ></app-checkbox>
      <div class="w-full pl-1">{{ columnHeader.name }}</div>
      <div class="flex justify-end">
        <app-icon icon="unfold_more" cdkDragHandle></app-icon>
      </div>
    </div>
  </div>
</div>

<div class="flex justify-end mt-2">
  <button
    class="uppercase rounded items-center p-2 bg-primary-600 text-white"
    (click)="save()"
    mat-dialog-close
  >
    Save
  </button>
</div>
