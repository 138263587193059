<app-back-title-layout [loading]="!snapshot">
  <ng-container *ngIf="snapshot">
    <div backTitleLayoutBeforeMain>
      <div class="flex items-center justify-between bg-white my-5 rounded p-4">
        <div>
          <div class="text-gray-500 text-sm">
            <span class="text-gray-700 italic"> ({{ snapshot.name }}) </span>
            from
            <span class="font-bold text-gray-700">{{
              snapshot.fromDate | date : "longDate"
            }}</span>
            to
            <span class="font-bold text-gray-700">{{
              snapshot.toDate | date : "longDate"
            }}</span>
          </div>

          <div class="text-gray-500 text-xs">
            created at {{ snapshot.createdAt | date : "medium" }}
          </div>
        </div>
        <div>
          <app-pill-label type="success" *ngIf="snapshot.status === 'done'">
            READY
          </app-pill-label>

          <ng-container
            *ngIf="
              snapshot.status === 'idle' || snapshot.status === 'processing'
            "
          >
            <app-icon icon="cached" [spin]="true"></app-icon>

            <app-pill-label> PROCESSING </app-pill-label>
          </ng-container>
        </div>

        <button
          *ngIf="snapshot.status === 'done'"
          class="btn text-primary-600 bg-white text-sm normal-case"
          (click)="printPage()"
        >
          <app-icon icon="download"></app-icon>
          Download Report
        </button>
      </div>
    </div>

    <ng-container
      *ngIf="snapshot.status === 'idle' || snapshot.status === 'processing'"
    >
      <div
        class="w-full h-40 flex items-center justify-center bg-primary-200 text-primary-600 rounded"
      >
        <div>Report is still generating. Please come back later.</div>
      </div>
    </ng-container>

    <ng-container *ngIf="snapshot.status === 'done'">
      <mat-tab-group mat-align-tabs="start">
        <ng-container *ngFor="let frame of frameData">
          <mat-tab>
            <ng-template mat-tab-label>
              <span class="font-bold uppercase text-xs">
                {{ frame.name }}
              </span>
            </ng-template>

            <h3 class="font-bold py-5 text-lg capitalize">{{ frame.name }}</h3>

            <div class="my-5" [ngSwitch]="frame.chartType">
              <ng-container *ngSwitchCase="'value'">
                <app-reporting-chart-value [data]="frame.data">
                </app-reporting-chart-value>
              </ng-container>

              <ng-container *ngSwitchCase="'line_graph'">
                <app-reporting-chart-line [data]="frame.data">
                </app-reporting-chart-line>
              </ng-container>

              <ng-container *ngSwitchCase="'table'">
                <app-reporting-chart-table-simple
                  [data]="frame.data"
                  icon="person"
                >
                </app-reporting-chart-table-simple>
              </ng-container>

              <ng-container *ngSwitchCase="'table_resources'">
                <app-reporting-chart-table-resources [data]="frame.data">
                </app-reporting-chart-table-resources>
              </ng-container>

              <ng-container *ngSwitchCase="'table_generic'">
                <app-reporting-chart-table-generic [data]="frame.data">
                </app-reporting-chart-table-generic>
              </ng-container>

              <ng-container *ngSwitchCase="'any'">
                <ng-container
                  *ngTemplateOutlet="
                    chartTemplateAny;
                    context: { data: frame.data }
                  "
                ></ng-container>
              </ng-container>
            </div>
          </mat-tab>
        </ng-container>
      </mat-tab-group>
    </ng-container>
  </ng-container>
</app-back-title-layout>

<!-- Printing hackz -->
<div class="overflow-hidden h-0 invisible">
  <div class="for-printing-exclusive">
    <div class="my-10" *ngFor="let frame of frameData">
      <h3 class="font-bold py-5 text-lg capitalize">{{ frame.name }}</h3>
      <div class="my-5" [ngSwitch]="frame.chartType">
        <ng-container *ngSwitchCase="'value'">
          <app-reporting-chart-value [data]="frame.data">
          </app-reporting-chart-value>
        </ng-container>

        <ng-container *ngSwitchCase="'line_graph'">
          <app-reporting-chart-line [data]="frame.data">
          </app-reporting-chart-line>
        </ng-container>

        <ng-container *ngSwitchCase="'table'">
          <app-reporting-chart-table-simple [data]="frame.data" icon="person">
          </app-reporting-chart-table-simple>
        </ng-container>

        <ng-container *ngSwitchCase="'table_resources'">
          <app-reporting-chart-table-resources [data]="frame.data">
          </app-reporting-chart-table-resources>
        </ng-container>

        <ng-container *ngSwitchCase="'table_generic'">
          <app-reporting-chart-table-generic [data]="frame.data">
          </app-reporting-chart-table-generic>
        </ng-container>

        <ng-container *ngSwitchCase="'any'">
          <ng-container
            *ngTemplateOutlet="chartTemplateAny; context: { data: frame.data }"
          ></ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #chartTemplateAny let-data="data">
  {{ data | json }}
</ng-template>
