<ng-container *ngIf="!insideSlide">
  <app-back-title-layout
    layoutTitle="Tally Activity"
    [loading]="!formGroup || loading"
  >
    <ng-container *ngIf="formGroup">
      <form [formGroup]="formGroup">
        <label class="form-label"> Result List </label>

        <div
          formArrayName="tallyTypes"
          *ngFor="let tallyType of getUndeletedTallyTypesControls()"
        >
          <div class="flex items-center mb-2">
            <app-icon icon="fiber_manual_record" class="mr-2"></app-icon>

            <input
              type="text"
              [formControl]="tallyType.get('name')"
              class="form-control"
            />

            <button
              class="text-gray-600 text-2xl"
              role="button"
              type="button"
              (click)="onRemoveTallyType(tallyType)"
            >
              <app-icon icon="close"></app-icon>
            </button>
          </div>
        </div>

        <div class="flex items-center mb-2">
          <app-icon
            icon="fiber_manual_record"
            class="text-gray-500 mr-2"
          ></app-icon>

          <input
            type="text"
            placeholder="Add Results..."
            class="form-control"
            (keydown.enter)="onEnterNewTallyType($event)"
            (blur)="onEnterNewTallyType($event)"
          />
        </div>

        <hr class="my-10 border h-0" />

        <h2 class="text-bold">Questions</h2>

        <div
          *ngFor="
            let tallyItem of this.formGroup.get('tallyItems')['controls'];
            let i = index
          "
          class="form-width"
        >
          <div formArrayName="tallyItems">
            <div [formGroupName]="i">
              <label class="form-label">Question {{ i + 1 }}</label>

              <div class="relative">
                <app-upload-loading-wrapper
                  #uploader
                  accept="image/*"
                  text="Change Image"
                  formControlName="mediaUrl"
                >
                  <img
                    *ngIf="tallyItem.get('mediaUrl').value"
                    [src]="tallyItem.get('mediaUrl').value"
                    class="w-full h-40 object-contain bg-gray-300 rounded"
                  />

                  <div
                    *ngIf="!tallyItem.get('mediaUrl').value"
                    class="w-full h-40 flex flex-col items-center justify-center bg-gray-300 text-primary rounded"
                  >
                    <app-icon icon="image" class="text-5xl"> </app-icon>
                  </div>
                </app-upload-loading-wrapper>

                <button
                  type="button"
                  (click)="tallyItem.get('mediaUrl').setValue('')"
                  class="appearance-none pt-1 pb-2 pr-1 pl-2 z-auto rounded-bl-full hover:bg-red-500 text-white bg-gray-500 absolute top-0 right-0"
                >
                  <app-icon icon="close"></app-icon>
                </button>
              </div>

              <div class="mb-5">
                <div class="flex items-center">
                  <input
                    type="text"
                    class="form-control"
                    formControlName="text"
                  />

                  <button
                    type="button"
                    (click)="uploader.click()"
                    class="text-primary text-4xl ml-2"
                  >
                    <app-icon icon="photo"></app-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="my-5">
            <app-exam-item-form-choices-tally
              [tallyTypeFormArray]="this.formGroup.get('tallyTypes')"
              [formGroup]="tallyItem"
            ></app-exam-item-form-choices-tally>
          </div>
        </div>

        <div class="flex flex-row-reverse my-10">
          <button class="btn btn-primary" (click)="onSave()">Done</button>
        </div>
      </form>
    </ng-container>
  </app-back-title-layout>
</ng-container>

<ng-container *ngIf="insideSlide">
  <div class="relative">
    <app-loading position="absolute" padding="py-32" [loading]="loading">
      <ng-container *ngIf="formGroup">
        <form [formGroup]="formGroup">
          <label class="form-label"> Result List </label>

          <div
            formArrayName="tallyTypes"
            *ngFor="let tallyType of getUndeletedTallyTypesControls()"
          >
            <div class="flex items-center mb-2">
              <app-icon icon="fiber_manual_record" class="mr-2"></app-icon>

              <input
                type="text"
                [formControl]="tallyType.get('name')"
                class="form-control"
              />

              <button
                class="text-gray-600 text-2xl"
                role="button"
                type="button"
                (click)="onRemoveTallyType(tallyType)"
              >
                <app-icon icon="close"></app-icon>
              </button>
            </div>
          </div>

          <div class="flex items-center mb-2">
            <app-icon
              icon="fiber_manual_record"
              class="text-gray-500 mr-2"
            ></app-icon>

            <input
              type="text"
              placeholder="Add Results..."
              class="form-control"
              (keydown.enter)="onEnterNewTallyType($event)"
              (blur)="onEnterNewTallyType($event)"
            />
          </div>

          <hr class="my-10 border h-0" />

          <h2 class="text-bold">Questions</h2>

          <div
            *ngFor="
              let tallyItem of this.formGroup.get('tallyItems')['controls'];
              let i = index
            "
            class="form-width"
          >
          <ng-container *ngIf="tallyItem.get('itemUuid').value === tallyItemUuid">

            <div formArrayName="tallyItems">
                <div [formGroupName]="i">
                  <label class="form-label">Question {{ i + 1 }}</label>

                  <div class="relative">
                    <app-upload-loading-wrapper
                      #uploader
                      accept="image/*"
                      text="Change Image"
                      formControlName="mediaUrl"
                    >
                      <img
                        *ngIf="tallyItem.get('mediaUrl').value"
                        [src]="tallyItem.get('mediaUrl').value"
                        class="w-full h-40 object-contain bg-gray-300 rounded"
                      />

                      <div
                        *ngIf="!tallyItem.get('mediaUrl').value"
                        class="w-full h-40 flex flex-col items-center justify-center bg-gray-300 text-primary rounded"
                      >
                        <app-icon icon="image" class="text-5xl"> </app-icon>
                      </div>
                    </app-upload-loading-wrapper>

                    <button
                      type="button"
                      (click)="tallyItem.get('mediaUrl').setValue('')"
                      class="appearance-none pt-1 pb-2 pr-1 pl-2 z-auto rounded-bl-full hover:bg-red-500 text-white bg-gray-500 absolute top-0 right-0"
                    >
                      <app-icon icon="close"></app-icon>
                    </button>
                  </div>

                  <div class="mb-5">
                    <div class="flex items-center">
                      <input
                        type="text"
                        class="form-control"
                        formControlName="text"
                      />

                      <button
                        type="button"
                        (click)="uploader.click()"
                        class="text-primary text-4xl ml-2"
                      >
                        <app-icon icon="photo"></app-icon>
                      </button>
                    </div>
                  </div>
                </div>
            </div>

            <div class="my-5">
              <app-exam-item-form-choices-tally
                [tallyTypeFormArray]="this.formGroup.get('tallyTypes')"
                [formGroup]="tallyItem"
              ></app-exam-item-form-choices-tally>
            </div>
          </ng-container>

          </div>

          <div class="flex flex-row-reverse my-10">
            <button class="btn btn-primary" (click)="onSave()">Done</button>
          </div>
        </form>
      </ng-container>
    </app-loading>
  </div>
</ng-container>
