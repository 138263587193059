import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { firstValueFrom } from "rxjs";
import { DashboardService } from "src/app/services/dashboard.service";
import {
  Block,
  CastsService,
  Screen,
  V2Block,
  V2CastsService,
} from "src/app/sinigangnababoywithgabi";

export interface ScreenInfo {
  screenId: string | number;
  title: string;
  isPresentOnNavbar: boolean;
  icon: string;
  shareable: boolean;
  sharedScreen: boolean;
  castName: string;
  slug: string;
  isHidden?: boolean;
}

@Component({
  selector: "app-casteditor-screens-select",
  templateUrl: "./casteditor-screens-select.component.html",
})
export class CasteditorScreensSelectComponent implements OnInit {
  screenInfos: ScreenInfo[];

  screens: Screen[];
  navigationBlock: V2Block;
  selectedActionMap: any = {};
  screen: any;
  isSelectEnabled: boolean;
  castId: string;
  loading: boolean = false;
  constructor(
    private v2CastsService: V2CastsService,
    private castService: CastsService,
    private route: ActivatedRoute,
    private dashboardService: DashboardService
  ) {}

  async ngOnInit() {
    await this.constructScreenInfos();
    await this.constructSharedScreenInfos();
  }

  reload(reload: boolean) {
    if (reload) this.ngOnInit();
  }

  setLoading(value) {
    this.loading = value;
  }
  async constructScreenInfos() {
    let { is_select_enabled: isSelectEnabled } =
      this.route.snapshot.queryParams;

    let cast = await firstValueFrom(this.dashboardService.getCast());
    this.castId = cast.data.id;

    this.isSelectEnabled = !!isSelectEnabled;

    let defaultCastName = cast.data.attributes.title;

    this.navigationBlock = await firstValueFrom(
      this.v2CastsService.blockGet(cast.data.attributes.initialBlockId)
    ).catch((err) => null);

    // Get normal list of screens
    let screens = await firstValueFrom(
      this.castService.screenList(this.castId)
    );

    // get shared screen list here
    let sharedScreens = await firstValueFrom(
      this.v2CastsService.screenSharedScreensGet(this.castId)
    );

    let mappedSharedScreens: Screen[] =
      sharedScreens.data?.map((screen) => {
        return { ...screen.attributes, id: parseInt(screen.id) };
      }) || [];

    let mappedSharedScreenNames = await Promise.all(
      mappedSharedScreens.map(async (screen) => {
        return {
          id: screen.id,
          castId: screen.castId,
          name: await firstValueFrom(
            this.v2CastsService.castGet(screen.castId.toString())
          ).then((result) => result.data.attributes.title),
        };
      })
    );

    //merge the two lists according to nav bar ordering
    screens.push(...mappedSharedScreens);

    this.screenInfos = screens
      .filter((screen) => screen.title[0] !== ".")
      .map((screen) => {
        let navigationItem =
          this.navigationBlock?.data.attributes.properties.items.find(
            (item) => item.screenId == screen.id
          );

        this.screen = screen;
        let _screen =
          this.navigationBlock.data.attributes.properties.items.find(
            (item) => item.screenId === this.screen.id
          );

        return {
          title: screen.title,
          isPresentOnNavbar: !!navigationItem,
          screenId: screen.id,
          shareable: screen.shareable,
          icon: navigationItem?.icon?.material,
          sharedScreen: parseInt(this.castId) !== screen.castId,
          castName:
            parseInt(this.castId) !== screen.castId
              ? mappedSharedScreenNames.find(
                  (_screen) => _screen.castId === screen.castId
                )?.name
              : defaultCastName,
          slug: navigationItem?.slug || screen.slug,
          isHidden: _screen?.isHidden,
        };
      });
  }

  async constructSharedScreenInfos() {}

  async onScreenNavigationToggle() {
    let newNavigationBlock: Block = {
      id: parseInt(this.navigationBlock.data.id),
      order: null,
      type: "navigation",
      properties: {
        // ...this.navigationBlock.data.attributes,
        appearance:
          this.navigationBlock.data.attributes?.properties?.appearance,
        classes: this.navigationBlock.data.attributes?.properties?.classes,

        items: this.screenInfos
          .filter((screenInfo) => screenInfo.isPresentOnNavbar)
          .map((screenInfo) => {
            return {
              title: screenInfo.title,
              icon: {
                sfSymbols: screenInfo.icon,
                material: screenInfo.icon,
              },
              screenId: screenInfo.screenId,
              slug: screenInfo.slug,
            };
          }),
      },
    };

    await firstValueFrom(
      this.castService.blockUpdate("1", "6", newNavigationBlock.id + "", {
        block: newNavigationBlock,
      })
    );
  }
}
