<div
  #policy
  class="fixed z-50 bottom-0 left-0 bg-gray-100 px-6 py-4 w-full transition transform duration-300"
>
  <div class="text-sm mb-2">
    We use cookies in this website to give you the best experience on our site.
    To find out more, read our
    <a
      class="text-primary-600 underline"
      href="https://castlms.com/privacy"
      target="_blank"
      >cookie and privacy policy</a
    >
  </div>
  <button class="btn btn-primary" (click)="acceptPolicy()">okay</button>
</div>
