import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { merge, Observable, of } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { Resource, ResourcesService } from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-resource-list",
  templateUrl: "./resource-list.component.html",
})
export class ResourceListComponent implements OnInit {
  resources: Resource[];

  pageSize = 20;
  listLength = 0;

  resourceListObservable: Observable<any[]>;

  constructor(
    private resourcesService: ResourcesService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.resourcesService.listResourcesCount();

    this.resourceListObservable = this.route.queryParams.pipe(
      switchMap((params) => {
        return this.resourcesService
          .listResourcesCount(
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            true,
            params.q || null
          )
          .pipe(
            tap((result) => {
              this.listLength = result.count;
            }),
            map(() => params)
          );
      }),
      switchMap((params) => {
        return merge(
          of(null),

          this.resourcesService.listResources(
            null,
            this.pageSize,
            this.pageSize * params.page || null,
            null,
            null,
            null,
            null,
            null,
            null,
            true,
            params.q || null
          )
        );
      }),
      map((items) => {
        return items?.map((item) => ({
          uuid: item.uuid,
          name: item.name,
          type: item.type,
          subtype: item.subtype,
          coverUri: item.coverUri,
          flavorTextSpan: item.flavorTextSpan,
        }));
      })
    );
  }
}
