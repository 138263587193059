import {
  Component,
  OnInit,
  Input,
  TemplateRef,
  OnChanges,
} from "@angular/core";

@Component({
  selector: "app-item-select",
  templateUrl: "./item-select.component.html",
  styleUrls: ["./item-select.component.scss"],
})
export class ItemSelectComponent implements OnInit {
  @Input()
  items: any[];

  @Input()
  selectedItems: any[];

  @Input()
  key: string = "uuid";

  @Input()
  itemTemplate: TemplateRef<any>;

  itemMap: any = {};
  selectedItemMap: any = {};

  @Input()
  checkBoxAlignment: "start" | "center" | "end" = "center";

  constructor() {}

  ngOnInit(): void {
    this.items?.forEach((item) => {
      this.itemMap[item[this.key]] = item;
    });

    if (this.selectedItems) {
      this.selectedItems.forEach((item) => {
        this.selectedItemMap[item[this.key]] = true;
      });
    }
  }

  updateSelectedItems() {
    let newItems = Object.entries(this.selectedItemMap)
      .filter(([key, value]) => value)
      .map(([key, value]) => {
        return this.itemMap[key];
      })
      .filter((d) => d);

    this.selectedItems?.splice(0, this.selectedItems.length, ...newItems);
  }
}
