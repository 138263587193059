<app-back-title-layout [loading]="!course || !block">
  <ng-container *ngIf="course && block">
    <div>
      <ng-container *ngFor="let section of course.outline; let i = index">
        <section class="mb-5">
          <div
            class="
              rounded
              bg-purple-200
              p-2
              flex
              items-center
              justify-between
              my-1
            "
          >
            <app-icon class="text-primary text-lg" icon="folder"></app-icon>

            <span class="font-semibold flex-grow mx-2">{{
              section.title
            }}</span>

            <app-checkbox
              (change)="onSectionSelect($event.target.checked, section)"
              class="text-primary text-2xl"
            ></app-checkbox>
          </div>

          <div>
            <ng-container *ngFor="let sectionBlock of section.blocks">
              <label
                class="
                  flex
                  ml-3
                  items-center
                  cursor-pointer
                  rounded
                  bg-purple-100
                  my-1
                  p-2
                "
              >
                <img
                  class="h-10 w-10 object-contain"
                  [src]="sectionBlock.coverUri || 'assets/imgplaceholder.png'"
                />

                <div class="flex-grow mx-2 flex flex-col">
                  <span class="font-semibold">
                    {{ sectionBlock.title }}
                  </span>

                  <span class="text-gray-600 text-xs uppercase">
                    {{ sectionBlock.content.resourceDetails | resourceType }}
                  </span>
                </div>

                <app-checkbox
                  *ngIf="sectionBlock.uuid !== block.uuid"
                  [(ngModel)]="prerequisiteMap[sectionBlock.uuid]"
                  class="text-primary text-2xl"
                ></app-checkbox>
              </label>
            </ng-container>
          </div>
        </section>
      </ng-container>
    </div>
  </ng-container>

  <button
    class="btn btn-primary w-full sticky bottom-2 z-20"
    (click)="saveBlock()"
  >
    Confirm
  </button>
</app-back-title-layout>
