<app-back-title-layout layoutTitle="Resource Library" [loading]="!components">
  <ng-container *ngIf="components">
    <app-search-bar
      #search
      placeholder="Search"
      [(ngModel)]="searchString"
      [disabled]="listState == LIST_STATE.SORT"
    >
    </app-search-bar>

    <div class="my-3" *ngIf="!collection">
      <a
        [routerLink]="['/collections', 'new']"
        [queryParams]="{ parent_uuid: collection?.uuid }"
        class="text-center bg-primary text-white py-2 px-4 rounded w-full block uppercase"
      >
        <app-icon icon="add"></app-icon>
        Create New Collection
      </a>
    </div>

    <div class="my-3" *ngIf="collection">
      <a
        [routerLink]="['/resources', 'select-new']"
        [queryParams]="{
          parent_uuid: collection?.uuid,
          ignore: 'resource-select',
          order: 0
        }"
        class="text-center bg-blue-500 text-white py-2 px-4 rounded w-full block uppercase"
      >
        <app-icon icon="add"></app-icon>
        Create New Resource
      </a>
    </div>
    <div
      class="sticky top-14 left-0 right-0 z-50 w-full bg-white"
      *ngIf="listState == LIST_STATE.SORT"
    >
      <section class="w-full md:w-6/12 justify-center m-auto bg-white p-3">
        <button
          class="bg-gray-200 px-2 py-1 rounded uppercase w-full"
          (click)="resetList()"
        >
          Cancel
        </button>
      </section>
    </div>

    <div
      class="sticky top-14 left-0 right-0 z-50 w-full bg-white"
      *ngIf="listState == LIST_STATE.SELECT"
    >
      <section class="w-full md:w-6/12 justify-center m-auto bg-white p-3">
        <button
          class="bg-gray-200 px-2 py-1 rounded uppercase w-full"
          (click)="resetList()"
        >
          Cancel
        </button>
      </section>
    </div>
    <div class="mb-3 mt-10">
      <h2 class="font-bold h-5">
        <span *ngIf="collection?.name" class="cursor-pointer" appGoBack>
          &lsaquo;
          {{ collection.name }}
        </span>

        <span *ngIf="components && !collection?.name"> Collections </span>
      </h2>
    </div>

    <hr />

    <div
      class="flex flex-row-reverse my-3"
      [ngClass]="{
        invisible:
          components && !collection?.name && listState !== LIST_STATE.SELECT
      }"
    >
      <app-dropdown-layout position="right">
        <span dropdownToggle class="text-primary">Actions &#9662;</span>

        <div dropdownContent>
          <button
            class="text-left w-full text-sm uppercase block px-4 py-1 text-gray-800 hover:bg-primary hover:text-white"
            (click)="initializeSortMode()"
            *ngIf="parentUuid"
          >
            Re-arrange content
          </button>
          <ng-container *ngIf="listState === LIST_STATE.SORT">
            <hr />
            <button
              (click)="sortDone()"
              class="text-left w-full text-sm uppercase block px-4 py-1 text-gray-800 hover:bg-primary hover:text-white"
            >
              Save order
            </button>
            <hr />
            <button
              class="text-left w-full text-sm uppercase block px-4 py-1 text-gray-800 hover:bg-primary hover:text-white"
              (click)="initializeNormalMode()"
            >
              Toggle off sort
            </button>
          </ng-container>
          <ng-container *ngIf="listState == LIST_STATE.SELECT">
            <hr />
            <button
              class="text-left w-full text-sm uppercase block px-4 py-1 text-gray-800 hover:bg-primary hover:text-white"
              (click)="publishSelectedComponents()"
            >
              Publish selected items
            </button>
            <hr />
            <button
              class="text-left w-full text-sm uppercase block px-4 py-1 text-gray-800 hover:bg-primary hover:text-white"
              (click)="unpublishSelectedComponents()"
            >
              Un-publish selected items
            </button>
            <hr />
            <button
              class="text-left w-full text-sm uppercase block px-4 py-1 text-gray-800 hover:bg-primary hover:text-white"
              (click)="deleteSelectedComponents()"
            >
              Delete selected items
            </button>
            <hr />
            <button
              class="text-left w-full text-sm uppercase block px-4 py-1 text-gray-800 hover:bg-primary hover:text-white"
              (click)="initializeNormalMode()"
            >
              Untoggle select
            </button>
          </ng-container>
        </div>
      </app-dropdown-layout>
    </div>

    <ul *ngIf="!collection">
      <li class="border-t-2 py-2 hover:bg-gray-100">
        <a [routerLink]="['/resources', 'list']" class="flex items-center">
          <div class="pr-2 h-20 w-20">
            <div class="h-full w-full flex items-center justify-center">
              <app-icon class="text-6xl text-primary" icon="folder"></app-icon>
            </div>
          </div>

          <div class="flex flex-col">
            <span class="text-primary font-semibold"> All Resources </span>
          </div>
        </a>
      </li>
    </ul>

    <table class="w-full table-auto">
      <tbody
        cdkDropList
        (cdkDropListDropped)="onSortDrop($event)"
        [cdkDropListDisabled]="listState !== LIST_STATE.SORT"
      >
        <div
          *ngIf="loading"
          [@fadeInOut]
          class="absolute inset-0 bg-white bg-opacity-50"
        >
          <app-loading
            [loading]="true"
            position="relative"
            wrapperClass="flex items-center justify-center h-screen"
          ></app-loading>
        </div>
        <ng-container
          *ngFor="let component of components | filterAll : searchString"
        >
          <tr
            class="cursor-pointer"
            (click)="componentClick(component)"
            cdkDrag
            class="hover:bg-gray-100"
          >
            <td class="border-b-2 border-t-2">
              <div class="pr-2 py-2 h-20 w-20">
                <ng-container *ngIf="!component.coverUri; else imageIcon">
                  <div class="h-full w-full flex items-center justify-center">
                    <app-icon
                      class="text-6xl text-black opacity-50"
                      icon="folder"
                    ></app-icon>
                  </div>
                </ng-container>

                <ng-template #imageIcon>
                  <img
                    class="h-full w-full object-contain"
                    [src]="component.coverUri"
                  />
                </ng-template>
              </div>
            </td>
            <td class="border-b-2 border-t-2 align-top w-full">
              <div class="flex flex-col py-2">
                <span class="text-xs text-gray-500 uppercase">
                  <span>
                    {{ component | resourceType }}
                  </span>

                  <ng-container *ngIf="!component.publishedAt">
                    <span class="italic">(Unpublished)</span>
                  </ng-container>
                </span>
                <span class="text-sm font-semibold">
                  {{ component.name }}
                </span>
                <span class="text-gray-500 mt-2">
                  {{ component.flavorTextSpan }}
                </span>
                <div class="text-gray-400" *ngIf="component.isShareable">
                  <app-icon icon="link"></app-icon>
                  This collection is shareable
                </div>
              </div>
            </td>

            <ng-container>
              <td class="border-b-2 border-t-2">
                <ng-container *ngIf="listState == LIST_STATE.NORMAL">
                  <app-dropdown-layout toggleClass="p-3">
                    <span dropdownToggle>&#8942;</span>

                    <div dropdownContent>
                      <ul>
                        <li>
                          <a
                            (click)="
                              initializeSelectMode();
                              toggleSelectComponent(component, false)
                            "
                            class="w-full cursor-pointer text-sm uppercase block px-4 py-2 text-gray-800 hover:bg-primary hover:text-white"
                            >Select Item</a
                          >
                        </li>

                        <li *ngIf="component.type === 'package'">
                          <a
                            [routerLink]="['/collections', 'profile']"
                            [queryParams]="{ collection_uuid: component.uuid }"
                            class="w-full text-sm uppercase block px-4 py-2 text-gray-800 hover:bg-primary hover:text-white"
                            >Collection Profile</a
                          >
                        </li>
                        <li
                          *ngIf="
                            !component.isShareable &&
                            component.type === 'package'
                          "
                        >
                          <div
                            class="w-full cursor-pointer text-sm uppercase block px-4 py-2 text-gray-800 hover:bg-primary hover:text-white"
                            (click)="
                              initializeShareMode();
                              shareCollection(component.uuid)
                            "
                          >
                            Share Collection
                          </div>
                        </li>
                        <li
                          *ngIf="
                            component?.isShareable &&
                            component.type === 'package'
                          "
                        >
                          <div
                            class="w-full cursor-pointer text-sm uppercase block px-4 py-2 text-gray-800 hover:bg-primary hover:text-white"
                            (click)="
                              initializeShareMode();
                              unshareCollection(component.uuid)
                            "
                          >
                            Unshare Collection
                          </div>
                        </li>
                      </ul>
                    </div>
                  </app-dropdown-layout>
                </ng-container>

                <ng-container *ngIf="listState == LIST_STATE.SORT">
                  <span class="font-bold text-xl cursor-move" cdkDragHandle>
                    &#8801;
                  </span>
                </ng-container>

                <ng-container *ngIf="listState == LIST_STATE.SELECT">
                  <input
                    type="checkbox"
                    (change)="
                      toggleSelectComponent(component, $event.target.checked)
                    "
                    [checked]="selectedComponentMap.get(component.uuid)"
                  />
                </ng-container>
              </td>
            </ng-container>
          </tr>
        </ng-container>

        <ng-container *ngIf="components && components.length === 0">
          <tr>
            <td class="text-center border-t border-b py-4">
              No items in the list
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </ng-container>
</app-back-title-layout>

<!-- <app-loading loading="true"></app-loading> -->
