import { Component, OnInit } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder } from "@angular/forms";
import { Resource, ResourcesService } from "src/app/sinigangnababoywithgabi";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { firstValueFrom, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-resource-publish",
  templateUrl: "./resource-publish.component.html",
})
export class ResourcePublishComponent implements OnInit {
  resource: Resource;
  publishForm: UntypedFormGroup;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private location: Location,
    private resourcesService: ResourcesService
  ) {}

  ngOnInit(): void {
    this.constructForm();
  }

  private unsubscribe$: Subject<void> = new Subject<void>();
  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  async constructForm() {
    let resourceUuid = this.route.snapshot.queryParams["resource_uuid"];

    this.publishForm = this.formBuilder.group({
      method: ["hide"],
      date: [null],
    });

    this.resource = await firstValueFrom(
      this.resourcesService.getResourceByUuid(resourceUuid)
    );

    if (this.resource.publishedAt) {
      this.publishForm.get("method").setValue("schedule");

      this.publishForm
        .get("date")
        .setValue(
          new Date(this.resource.publishedAt).toLocaleString("sv").slice(0, 10)
        );
    }

    this.publishForm
      .get("method")
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result == "schedule") {
          this.publishForm
            .get("date")
            .setValue(
              new Date(this.resource.publishedAt || new Date())
                .toLocaleDateString("sv")
                .slice(0, 10)
            );
        }
      });
  }

  async updateResource() {
    let method = this.publishForm.get("method").value;

    if (method == "now") {
      let nowDate = new Date();
      nowDate.setHours(0, 0, 0, 0);

      this.resource.publishedAt = nowDate.toISOString();
    } else if (method == "schedule") {
      let datePart = this.publishForm.get("date").value;

      this.resource.publishedAt = new Date(datePart).toISOString();
    } else if (method == "hide") {
      this.resource.publishedAt = null;
    }

    let result = await firstValueFrom(
      this.resourcesService.updateResourceByUuid(
        this.resource.uuid,
        this.resource
      )
    );

    // Set redirect rules
    let {
      callback_uri: callback,
      redirect,
      redirect_field: redirectField = "resource-uuid",
    } = this.route.snapshot.queryParams;

    let doneHistoryLength = this.route.snapshot.queryParams[
      "done_history_length"
    ]
      ? parseInt(this.route.snapshot.queryParams["done_history_length"])
      : null;

    if (callback) {
      this.router.navigate([callback]);
    } else if (redirect) {
      let url = redirect.split("?")[0];
      let params = redirect
        .split("?")[1]
        ?.split("&")
        .reduce((acc, keyvalue) => {
          let key = keyvalue.split("=")[0];
          let value = keyvalue.split("=")[1];

          acc[key] = value;

          return acc;
        }, {});

      // this.router.navigate([url], {
      //   replaceUrl: true,
      //   queryParamsHandling: "merge",
      //   queryParams: {
      //     ...params,
      //     [redirectField]: result.uuid,
      //     redirect: null,
      //     redirect_field: null,
      //     type: null,
      //     subtype: null,
      //     parent_uuid: null,
      //   },
      // });

      // Force refresh

      let redirectUrl = new URL(url, window.location.origin);

      redirectUrl.searchParams.set(redirectField, result.uuid);

      if (params) {
        for (let key in params) {
          redirectUrl.searchParams.set(key, params[key]);
        }
      }

      // Add random value to clear cache
      redirectUrl.searchParams.set("rand", Math.random().toString());

      window.location.href = redirectUrl.toString();
    } else {
      if (doneHistoryLength) {
        window.history.go(-doneHistoryLength);
      } else {
        this.location.back();
      }
    }
  }
}
