<div class="bg-white rounded h-[600px]">
  <h2 class="text-center my-3 text-xl font-bold">Add Block</h2>

  <!-- Questions here -->
  <h2 class="text-left text-base text-gray-500 ml-4 font-bold">Questions</h2>
  <div class="grid grid-cols-3 grid-row gap-3 text-primary p-5">
    <ng-container *ngFor="let question of questions">
      <div class="h-48 w-48 aspect-square relative">
        <div class="absolute inset-0">
          <button
            [mat-dialog-close]="question"
            [disabled]="!!question.disabled"
            class="transition-colors duration-100 hover:bg-primary hover:text-primary-lightest rounded-lg bg-primary-lightest flex flex-col justify-center items-center h-full w-full p-3 cursor-pointer"
            [ngClass]="{
              'bg-gray-200 text-gray-400 hover:bg-gray-400 hover:text-gray-200':
                question.disabled,
              'hover:bg-primary hover:text-primary-lightest bg-primary-lightest':
                !question.disabled
            }"
          >
            <app-icon class="text-5xl" [icon]="question.icon"> </app-icon>

            <span class="font-semibold">
              {{ question.text }}
            </span>
          </button>
        </div>
      </div>
    </ng-container>
  </div>

  <!-- Resources here -->
  <h2 class="text-left text-base text-gray-500 ml-4 font-bold">Resources</h2>
  <div class="grid grid-cols-3 grid-row gap-3 text-primary p-5">
    <ng-container *ngFor="let resource of resources">
      <div class="h-48 w-48 aspect-square relative">
        <div class="absolute inset-0">
          <button
            [mat-dialog-close]="resource"
            [disabled]="!!resource.disabled"
            class="transition-colors duration-100 hover:bg-primary hover:text-primary-lightest rounded-lg bg-primary-lightest flex flex-col justify-center items-center h-full w-full p-3 cursor-pointer"
            [ngClass]="{
              'bg-gray-200 text-gray-400 hover:bg-gray-400 hover:text-gray-200':
                resource.disabled,
              'hover:bg-primary hover:text-primary-lightest bg-primary-lightest':
                !resource.disabled
            }"
          >
            <app-icon class="text-5xl" [icon]="resource.icon"> </app-icon>

            <span class="font-semibold">
              {{ resource.text }}
            </span>
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</div>
