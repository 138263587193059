<div
  class="rounded bg-gray-100 text-gray-400 mt-2 mb-2"
  *ngIf="filterTotal > 0"
>
  <div class="flex items-center ml-2">
    <app-icon icon="filter_list" class="p-1"></app-icon>
    <div class="uppercase">filter</div>
  </div>

  <!-- container for tablets -->
  <div class="flex p-2 w-full">
    <!-- tablet format -->
    <div class="flex flex-wrap">
      <!-- tablet content -->
      <div class="flex mr-1" *ngFor="let filter of filters; let i = index">
        <div class="flex w-max shrink rounded-full bg-gray-200 px-1 mr-1 mb-2">
          {{ filter }}
          <button
            type="button"
            class="text-gray-400"
            *ngIf="filter.length > 0"
            (click)="deleteFilter(i)"
          >
            <app-icon icon="close"></app-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
