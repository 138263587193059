<app-back-title-layout layoutTitle="Choose a Screen" [loading]="!screenInfos">
  <ng-container *ngIf="screenInfos">
    <a [routerLink]="['../create']" class="btn btn-primary my-5">
      <app-icon icon="add"></app-icon>

      ADD SCREEN
    </a>

    <app-item-list
      [items]="screenInfos"
      [itemTemplate]="itemTemplate"
      [externalLoading]="loading"
    >
    </app-item-list>

    <button class="btn btn-primary w-full my-5" appGoBack>DONE</button>
  </ng-container>
</app-back-title-layout>

<ng-template #itemTemplate let-screenInfo="item">
  <div class="flex items-center py-4">
    <div class="mx-2" *ngIf="isSelectEnabled">
      <app-checkbox
        class="text-primary mx-1"
        (change)="onScreenNavigationToggle()"
        [(ngModel)]="screenInfo.isPresentOnNavbar"
      ></app-checkbox>
    </div>

    <a
      class="px-5 flex-1 min-w-0"
      [routerLink]="['../edit']"
      [queryParams]="{
        screen_id: screenInfo.screenId
      }"
      queryParamsHandling="merge"
    >
      <div class="w-2/3">
        <div class="font-bold truncate text-lg">
          {{ screenInfo.title }}
        </div>
        <div class="text-gray-400" *ngIf="screenInfo.shareable">
          <app-icon icon="link"></app-icon>
          This screen is shareable
        </div>
        <div class="text-gray-400" *ngIf="screenInfo.sharedScreen">
          <app-icon icon="link"></app-icon>
          This screen is shared by <b>{{ screenInfo.castName }}</b>
        </div>
      </div>
    </a>
    <div class="flex items-center">
      <div class="flex">
        <span
          [ngClass]="{
            invisible: !screenInfo.isPresentOnNavbar || screenInfo.isHidden
          }"
          class="border rounded-full text-sm text-primary-600 bg-primary-200 px-2 mr-2"
        >
          Present on Navbar
        </span>
        <span
          *ngIf="screenInfo.isHidden"
          class="border rounded-full text-sm text-gray-600 bg-gray-200 px-2 mr-2"
        >
          Hidden on Navbar
        </span>
        <app-casteditor-screen-actions
          [screenId]="screenInfo.screenId"
          [navigationBlock]="navigationBlock"
          [screenInfos]="screenInfos"
          [castId]="castId"
          (reload)="reload($event)"
          (loading)="setLoading($event)"
        >
        </app-casteditor-screen-actions>
      </div>
    </div>
  </div>
</ng-template>
