import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";

class ResourceType {
  type: string;
  subtype?: string;
  text?: string;
  disabled? = false;
}

const AVAILABLE_RESOURCE_TYPES: ResourceType[] = [
  {
    type: "video",
  },
  {
    type: "pdf",
  },
  // {
  //   type: "buribook",
  // },
  // {
  //   type: "buribook-with-activity",
  // },
  {
    type: "burislides",
  },
  // {
  //   type: "html",
  // },
  {
    type: "xapi",
  },
  {
    type: "link",
  },

  // {
  //   type: 'audio',
  // },

  {
    type: "assessment",
  },

  // {
  //   type: "castcourse",
  // },

  // {
  //   type: "embed",
  //   subtype: "google-slide",
  //   // text: "Google Slide",
  // },

  // {
  //   type: "embed",
  //   subtype: "google-form",
  //   // text: "Google Form",
  // },

  // {
  //   type: "embed",
  //   subtype: "google-doc",
  //   // text: "Google Doc",
  // },

  // {
  //   type: "embed",
  //   subtype: "google-sheet",
  //   // text: "Google Sheet",
  // },

  {
    type: "assessment",
    subtype: "file-upload",
  },

  ...(environment.FREEDOM_WALL_ENABLED
    ? [
        {
          type: "assessment",
          subtype: "freedom-wall",
        },
      ]
    : []),

  // {
  //   type: "epub",
  // },

  // {
  //   type: "link",
  //   subtype: "flarum",
  //   text: "Flarum",
  // },

  {
    type: "link",
    subtype: "email",
  },
  {
    type: "embed",
  },
  {
    type: "text",
    subtype: "markdown",
  },
  {
    type: "casticulate",
  },
];

@Component({
  selector: "app-resource-select-new",
  templateUrl: "./resource-select-new.component.html",
  styles: [
    `
      .aspect-square {
        padding-top: 100%;
      }
      .aspect-rect {
        padding-top: 33%;
      }
    `,
  ],
})
export class ResourceSelectNewComponent implements OnInit {
  resourceTypes: ResourceType[];

  ignoreCourseCreate = false;
  ignoreResourceSelect = false;
  isInsideCourse = false;

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
    let { ignore, is_inside_course: isInsideCourse } =
      this.route.snapshot.queryParams;

    ignore = ignore?.split(",") || [];

    if (ignore?.includes("course")) {
      this.ignoreCourseCreate = true;
    }

    if (ignore?.includes("resource-select")) {
      this.ignoreResourceSelect = true;
    }

    if (isInsideCourse) {
      this.isInsideCourse = true;
    }

    //HARDCODE: Only show if DICT
    if (
      !/dict/g.test(window.location.hostname) &&
      !/training\.castdashboard/g.test(window.location.hostname)
    ) {
      ignore.push("flarum");
    }

    this.resourceTypes = AVAILABLE_RESOURCE_TYPES.filter(
      (resourceType) =>
        !ignore?.includes(resourceType.type) &&
        !ignore?.includes(resourceType.subtype)
    );
  }

  onTypeSelect(resourceType: ResourceType) {
    if (resourceType.type === "course") {
      this.router.navigate(["/_courses", "template-list"], {
        queryParamsHandling: "merge",
        replaceUrl: true,
      });
    } else {
      this.router.navigate(["/resources", "new"], {
        queryParams: { type: resourceType.type, subtype: resourceType.subtype },
        queryParamsHandling: "merge",
        replaceUrl: true,
      });
    }
  }
}
