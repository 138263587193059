<app-back-title-layout layoutTitle="Cast Details" [loading]="!formGroup">
  <ng-container *ngIf="formGroup">
    <form (ngSubmit)="saveCast()" class="my-10" [formGroup]="formGroup">
      <label class="block my-5">
        <div class="form-label">Cast Name</div>

        <input
          type="text"
          name="title"
          class="form-control"
          formControlName="title"
        />

        <app-form-control-error-layout
          [control]="formGroup.get('title')"
        ></app-form-control-error-layout>
      </label>

      <!-- <label class="my-5 flex">
          <app-checkbox class="text-primary"></app-checkbox>

          <div class="ml-3 flex-1">
            <div class="form-label">Reveal Cast name beside Cast logo</div>

            <div class="text-xs text-gray-500">
              Not recommended if your Cast Logo already mentions your Cast Name
            </div>
          </div>
        </label> -->

      <hr class="form-divider" />

      <h2 class="form-heading">Logos and Images</h2>

      <label class="block my-5">
        <span class="form-label"> Cast Logo </span>

        <div #thumbnailInput class="bg-gray-300 w-32 h-32">
          <app-upload-loading-wrapper
            accept="image/*"
            (upload)="onUpload($event, 'logoUri')"
          >
            <img
              [src]="formGroup.value.logoUri"
              alt=""
              class="w-full h-full object-contain"
            />
          </app-upload-loading-wrapper>
        </div>

        <div>
          <button
            type="button"
            (click)="thumbnailInput.click()"
            class="w-32 btn btn-thin btn-primary-light mt-2"
          >
            CHANGE
          </button>
          <!-- <span>Recomended size</span> -->
        </div>
      </label>

      <label class="block my-5">
        <div class="form-label m-0">Login Banner</div>

        <div class="text-xs text-gray-500 leading-none">
          Recommended size: Maximum of 1MB
        </div>

        <div #heroInput class="bg-gray-300 w-80 h-80 my-1">
          <app-upload-loading-wrapper
            accept="image/*"
            (upload)="onUpload($event, 'bannerUri')"
          >
            <img
              [src]="formGroup.value.bannerUri"
              alt=""
              class="w-full h-full object-contain"
            />
          </app-upload-loading-wrapper>
        </div>

        <div>
          <button
            type="button"
            (click)="heroInput.click()"
            class="w-32 mt-2 btn btn-thin btn-primary-light"
          >
            CHANGE
          </button>
        </div>
      </label>

      <label class="block my-5">
        <div class="form-label m-0">Web Favicon</div>

        <div class="text-xs text-gray-500 leading-none">
          Recommended size: 16X x 16PX
        </div>

        <div #faviconInput class="bg-gray-300 w-8 h-8 my-1">
          <app-upload-loading-wrapper
            accept="image/*"
            (upload)="onUpload($event, 'faviconUri')"
          >
            <img
              [src]="formGroup.value.faviconUri"
              alt=""
              class="w-full h-full object-contain"
            />
          </app-upload-loading-wrapper>
        </div>

        <div>
          <button
            type="button"
            (click)="faviconInput.click()"
            class="w-32 mt-2 btn btn-thin btn-primary-light"
          >
            CHANGE
          </button>
        </div>
      </label>

      <button
        type="submit"
        class="btn btn-primary w-full my-5"
        [disabled]="formGroup.invalid || formGroup.disabled"
      >
        SAVE
      </button>
    </form>
  </ng-container>
</app-back-title-layout>
