import { Location } from "@angular/common";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import {
  catchError,
  first,
  Observable,
  of,
  Subscription,
  switchMap,
  tap,
} from "rxjs";
import { fadeInOut } from "src/app/app-common-module/animations/fade";
import { MailerService } from "src/app/services/mailer.service";
import { ModalService } from "src/app/services/modal.service";

@Component({
  selector: "app-mailer-edit-template",
  templateUrl: "./mailer-edit-template.component.html",
  styles: [],
  animations: [fadeInOut],
})
export class MailerEditTemplateComponent implements OnInit {
  firstFilter: string[] = [];
  _filters: Array<string[]> = [];
  filters: any[];
  filtersForm: UntypedFormGroup;
  filterTotal: number = 0;
  inputResults: any[] = [];
  loading: boolean = false;
  layoutTitle: string = "";
  layoutDesc: string = "";
  hideTempDetails: boolean = true;
  hideTempObservable: Observable<any>;
  querySubscription: Subscription;
  mutateSubscription: Subscription;
  deleteSubscription: Subscription;
  deleteIndex: number = 0;
  inputHidden: string = "";
  previewHidden: string = "hidden";
  close: boolean = false;
  form: UntypedFormGroup;
  tempUuid: string = "";
  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private location: Location,
    private router: Router,
    private mailerService: MailerService,
    private modalService: ModalService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      tempName: [null, Validators.required],
      tempDesc: [null, Validators.required],
      subject: [null, Validators.required],
      date: [null, Validators.required],
      time: [null, Validators.required],
      content: [null, Validators.required],
    });
    this.querySubscription = this.route.queryParams
      .pipe(
        first(),
        switchMap((params) => {
          this.loading = true;
          let { template_uuid } = params;
          this.tempUuid = template_uuid;
          return this.mailerService.getEmailTemplate(this.tempUuid);
        })
      )
      .subscribe((result) => {
        this.loading = false;
        let data = result.data.emailTemplate;
        this.filters = data.filters;
        let subject = data.subject;
        let name = data.name;
        this.layoutTitle = name;
        let description = data.description;
        this.layoutDesc = description;
        let convertDate = new Date(data.sendOnDate);
        convertDate.setHours(convertDate.getHours() + 8);
        let dateString = convertDate.toISOString();
        let date = dateString.split("T")[0];
        let time = dateString.split("T")[1];
        let time2 = time.split(":")[0] + ":" + time.split(":")[1];
        let content = data.emailBody;
        this.setForm(name, description, subject, date, time2, content);
        this.constructObservable();
      });
  }
  setForm(name, description, subject, date, time2, content) {
    this.form.get("tempName").setValue(name);
    this.form.get("tempDesc").setValue(description);
    this.form.get("subject").setValue(subject);
    this.form.get("date").setValue(date);
    this.form.get("time").setValue(time2);
    this.form.get("content").setValue(content);
    if (this.hideTempDetails) {
      this.form.disable();
    }
  }
  setResult(event) {
    this.hideTempDetails = event.hideDetails;
    if (!event.hideDetails) {
      this.form.enable();
    }
  }
  constructObservable() {
    this.hideTempObservable = of({ hideButton: true, hideDetails: false });
  }
  ngOnDestroy(): void {
    this.querySubscription?.unsubscribe();
    this.mutateSubscription?.unsubscribe();
    this.deleteSubscription?.unsubscribe();
  }
  preview() {
    this.inputHidden = "hidden";
    this.previewHidden = "";
  }
  code() {
    this.inputHidden = "";
    this.previewHidden = "hidden";
  }
  closeModal(event) {
    this.close = !this.close;
    this.cd.detectChanges();
  }
  useTemplate() {
    this.router.navigate(["_mailer", "email", "edit"], {
      queryParamsHandling: "merge",
      queryParams: { status: "draft" },
    });
  }
  async deleteTemplate() {
    let areYouSure = await this.modalService.confirm(
      "Are you sure you want to delete this template?",
      {
        confirmText: "Delete",
        confirmClass: "btn text-white bg-red-700",
        cancelText: "No",
      }
    );
    if (!areYouSure) {
      return;
    }
    this.deleteSubscription = this.mailerService
      .deleteEmailTemplate(this.tempUuid)
      .subscribe((res) => {
        this.location.back();
      });
  }
  onSubmit() {
    this.loading = true;
    let tempName = this.form.get("tempName").value;
    let tempDesc = this.form.get("tempDesc").value;
    let subject = this.form.get("subject").value;
    let email_body = this.form.get("content").value;
    let sendOnDate = new Date().toISOString();
    let filters = this.mailerService.parseFilter(this.filtersForm);
    if (filters.length === 0) {
      filters = [{ key: "user", values: [""] }];
    }
    this.mutateSubscription?.unsubscribe();
    this.mutateSubscription = this.mailerService
      .updateEmailTemplate(this.tempUuid, {
        name: tempName,
        description: tempDesc,
        email_body: email_body,
        send_on_date: sendOnDate,
        subject: subject,
        filters: filters,
      })
      .pipe(
        tap(({ data, loading }) => {
          if (!data) {
            throw new Error("No filters found");
          }
        }),
        switchMap(({ data, loading }) => {
          this.loading = loading;
          this.router.navigate(["_mailer/templates"]);
          return this.modalService.alert("Template saved");
        }),
        catchError((error) => {
          this.loading = false;
          return this.modalService.alert("No filter(s) found");
        })
      )
      .subscribe();
  }
  setFilters(filters: UntypedFormGroup) {
    this.filtersForm = filters;
    this.inputResults = [filters];
    this.firstFilter = [];
    this._filters = [];

    for (const [key, value] of Object.entries(
      (<UntypedFormGroup>filters.get("first")).controls
    )) {
      if (value.value && typeof value.value === "object" && value.value.name) {
        this.firstFilter.push(value.value.name);
      } else if (value.value) {
        this.firstFilter.push(value.value);
      }
    }
    (<UntypedFormArray>filters.get("filters")).controls.forEach(
      (filter, index) => {
        let innerArr = [];
        for (const [key, value] of Object.entries(filter.value)) {
          if (value && typeof value === "object" && value["name"]) {
            innerArr.push(value["name"]);
          } else if (value) {
            innerArr.push(value);
          }
        }
        this._filters.push(innerArr);
      }
    );
    this.cd.detectChanges();
  }
  setFilterTotal(event) {
    this.filterTotal = event;
  }
  deleteFilter(index) {
    this.deleteIndex = 0;
    this.cd.detectChanges();
    this.deleteIndex = index;
  }
  async openModal(template, type) {
    let baseClass = ["flex", "justify-center", "items-center", "shadow-lg"];
    let smallContainerClass = ["sm:w-1/4", "w-3/5"];
    let largeContainerClass = ["sm:w-1/2", "w-3/5"];
    let filterResultsContainerClass = ["w-3/4", "h-1/2"];
    let modalClass;
    if (type === "cancelSchedule" || type === "testEmail") {
      modalClass = [...baseClass, ...smallContainerClass];
    } else if (type === "filterResults") {
      modalClass = [...baseClass, ...filterResultsContainerClass];
    } else {
      modalClass = [...baseClass, ...largeContainerClass];
    }
    let results = await this.modalService.openModal(template, {
      panelClass: modalClass,
    });
    return results;
  }
}
