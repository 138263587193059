import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { fadeInOut } from "../app-common-module/animations/fade";
import { DashBoardCard, DashboardService } from "../services/dashboard.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
  animations: [fadeInOut],
})
export class DashboardComponent implements OnInit {
  cards: DashBoardCard[];

  userinfo: any;
  permissions: string[];
  dashboardInfo: {
    dashboardTitle: string;
    dashboardLogoUrl: string;
  };

  constructor(
    private dashboardService: DashboardService,
    private titleService: Title
  ) {}
  public throwTestError(): void {
    throw new Error("Sentry Test Error");
  }
  async ngOnInit() {
    this.titleService.setTitle("Cast Dashboard");

    this.dashboardInfo = await this.dashboardService
      .getDashboardNameAndLogo()
      .toPromise();

    this.userinfo = await this.dashboardService.getUserInfo().toPromise();

    this.cards = await this.dashboardService.getDashboardCards().toPromise();
  }
}
