<app-back-title-layout layoutTitle="General Settings">
  <div class="flex justify-end">
    <button
      type="button"
      (click)="exportCsv()"
      class="flex text-primary-600 my-4"
    >
      <app-icon [icon]="'insert_drive_file'"></app-icon>
      <div class="uppercase ml-2">export csv</div>
    </button>
  </div>

  <form [formGroup]="form" (ngSubmit)="onSave()">
    <!-- Traits -->
    <div class="form-label">Traits</div>
    <div
      formArrayName="fields"
      *ngFor="let field of fields.controls; let i = index"
    >
      <div class="flex">
        <div class="uppercase font-bold my-2 w-full">field {{ i + 1 }}</div>
        <button
          type="button"
          class="text-lg text-gray-400"
          (click)="removeField(i, 'traits')"
        >
          <app-icon icon="close"></app-icon>
        </button>
      </div>
      <div [formGroupName]="i">
        <div class="flex">
          <select
            class="{{
              hideClass[i].default === 'hidden' ? 'hidden' : 'form-control'
            }}"
            formControlName="defaultField"
          >
            <option [ngValue]="null" disabled selected>Choose field</option>
            <option *ngFor="let field of defaultFields" [ngValue]="field.value">
              {{ field.name }}
            </option>
          </select>
          <input
            placeholder="Enter Custom Field"
            type="text"
            class="{{
              hideClass[i].custom === 'hidden' ? 'hidden' : 'form-control'
            }}"
            formControlName="customField"
          />
          <select class="form-control ml-2" formControlName="fieldType">
            <option *ngFor="let type of fieldTypes" [ngValue]="type.value">
              {{ type.name }}
            </option>
          </select>
        </div>

        <div class="flex justify-between">
          <label for="{{ 'required' + i }}" class="text-sm flex">
            <app-checkbox
              class="text-xl align-middle"
              uncheckClass="text-gray-500"
              checkClass="text-primary-dark"
              formControlName="required"
              id="{{ 'required' + i }}"
            >
            </app-checkbox
            ><span class="flex mt-1 ml-1">set as required</span>
          </label>
          <label for="{{ 'restrict' + i }}" class="text-sm flex">
            <app-checkbox
              class="text-xl align-middle"
              uncheckClass="text-gray-500"
              checkClass="text-primary-dark"
              formControlName="restrictValues"
              id="{{ 'restrict' + i }}"
            >
            </app-checkbox
            ><span class="flex mt-1 ml-1">restrict values</span>
          </label>
          <button
            type="button"
            class="uppercase text-primary-600 my-2 text-sm {{
              hideClass[i].default
            }}"
            (click)="setCustomClass(i, 'traits')"
          >
            Set Custom Field
          </button>
          <button
            type="button"
            class="uppercase text-primary-600 my-2 text-sm {{
              hideClass[i].custom
            }}"
            (click)="setDefaultClass(i, 'traits')"
          >
            Set Default Field
          </button>
        </div>

        <!-- Put options here -->
        <ng-container
          *ngIf="
            field.get('restrictValues') && field.get('restrictValues').value
          "
        >
          <div class="form-label">Options</div>
          <div
            formArrayName="dropdownFields"
            *ngFor="let option of getDropdownFields(field); let j = index"
          >
            <div [formGroupName]="j">
              <div class="flex justify-between items-center">
                <input
                  type="text"
                  formControlName="name"
                  class="form-control mb-1"
                />
                <button
                  type="button"
                  class="btn btn-icon bg-transparent text-gray-600 hover:text-red-600 text-2xl"
                  (click)="removeOption(field, j)"
                >
                  <app-icon icon="close"></app-icon>
                </button>
              </div>
            </div>
          </div>
          <input
            type="text"
            placeholder="Add option..."
            (keydown.enter)="onEnterNewOption($event, field)"
            (blur)="onEnterNewOption($event, field)"
            class="form-control mr-2"
          />
        </ng-container>
        <button
          type="button"
          (click)="addField(i, 'traits')"
          class="flex text-primary-600 my-4"
        >
          <app-icon icon="add"> </app-icon>
          <div class="uppercase ml-2">add more field</div>
        </button>
      </div>
    </div>
    <button
      *ngIf="fields.controls.length === 0"
      type="button"
      (click)="addField(0, 'traits')"
      class="flex text-primary-600 my-4"
    >
      <app-icon icon="add"> </app-icon>
      <div class="uppercase ml-2">add more field</div>
    </button>
    <hr class="form-divider" />

    <!-- Public Metadata -->
    <div class="form-label">Public Metadata</div>
    <div
      formArrayName="publicMetadata"
      *ngFor="let public of publicMetadata.controls; let i = index"
    >
      <div class="flex">
        <div class="uppercase font-bold my-2 w-full">field {{ i + 1 }}</div>
        <button
          type="button"
          class="text-lg text-gray-400"
          (click)="removeField(i, 'public')"
        >
          <app-icon icon="close"></app-icon>
        </button>
      </div>
      <div [formGroupName]="i">
        <div class="flex">
          <select
            class="{{
              hidePublicClass[i].default === 'hidden'
                ? 'hidden'
                : 'form-control'
            }}"
            formControlName="defaultField"
          >
            <option [ngValue]="null" disabled selected>Choose field</option>
            <option *ngFor="let field of defaultFields" [ngValue]="field.value">
              {{ field.name }}
            </option>
          </select>
          <input
            placeholder="Enter Custom Field"
            type="text"
            class="{{
              hidePublicClass[i].custom === 'hidden' ? 'hidden' : 'form-control'
            }}"
            formControlName="customField"
          />
          <select class="form-control ml-2" formControlName="fieldType">
            <option *ngFor="let type of fieldTypes" [ngValue]="type.value">
              {{ type.name }}
            </option>
          </select>
        </div>

        <div class="flex justify-between">
          <!-- <label for="{{ 'required' + i }}" class="text-sm flex">
            <app-checkbox
              class="text-xl align-middle"
              uncheckClass="text-gray-500"
              checkClass="text-primary-dark"
              formControlName="required"
              id="{{ 'required' + i }}"
            >
            </app-checkbox
            ><span class="flex mt-1 ml-1">set as required</span>
          </label> -->
          <label for="{{ 'restrict' + i }}" class="text-sm flex">
            <app-checkbox
              class="text-xl align-middle"
              uncheckClass="text-gray-500"
              checkClass="text-primary-dark"
              formControlName="restrictValues"
              id="{{ 'restrict' + i }}"
            >
            </app-checkbox
            ><span class="flex mt-1 ml-1">restrict values</span>
          </label>
          <button
            type="button"
            class="uppercase text-primary-600 my-2 text-sm {{
              hidePublicClass[i].default
            }}"
            (click)="setCustomClass(i, 'public')"
          >
            Set Custom Field
          </button>
          <button
            type="button"
            class="uppercase text-primary-600 my-2 text-sm {{
              hidePublicClass[i].custom
            }}"
            (click)="setDefaultClass(i, 'public')"
          >
            Set Default Field
          </button>
        </div>
        <!-- Put options here -->
        <ng-container
          *ngIf="
            public.get('restrictValues') && public.get('restrictValues').value
          "
        >
          <div class="form-label">Options</div>
          <div
            formArrayName="dropdownFields"
            *ngFor="let option of getDropdownFields(public); let j = index"
          >
            <div [formGroupName]="j">
              <div class="flex justify-between items-center">
                <input
                  type="text"
                  formControlName="name"
                  class="form-control mb-1"
                />
                <button
                  type="button"
                  class="btn btn-icon bg-transparent text-gray-600 hover:text-red-600 text-2xl"
                  (click)="removeOption(public, j)"
                >
                  <app-icon icon="close"></app-icon>
                </button>
              </div>
            </div>
          </div>
          <input
            type="text"
            placeholder="Add option..."
            (keydown.enter)="onEnterNewOption($event, public)"
            (blur)="onEnterNewOption($event, public)"
            class="form-control mr-2"
          />
        </ng-container>
        <button
          type="button"
          (click)="addField(i, 'public')"
          class="flex text-primary-600 my-4"
        >
          <app-icon icon="add"> </app-icon>
          <div class="uppercase ml-2">add more field</div>
        </button>
      </div>
    </div>
    <button
      *ngIf="publicMetadata.controls.length === 0"
      type="button"
      (click)="addField(0, 'public')"
      class="flex text-primary-600 my-4"
    >
      <app-icon icon="add"> </app-icon>
      <div class="uppercase ml-2">add more field</div>
    </button>
    <hr class="form-divider" />

    <!-- Admin Metadata -->
    <div class="form-label">Admin Metadata</div>
    <div
      formArrayName="adminMetadata"
      *ngFor="let admin of adminMetadata.controls; let i = index"
    >
      <div class="flex">
        <div class="uppercase font-bold my-2 w-full">field {{ i + 1 }}</div>
        <button
          type="button"
          class="text-lg text-gray-400"
          (click)="removeField(i, 'admin')"
        >
          <app-icon icon="close"></app-icon>
        </button>
      </div>
      <div [formGroupName]="i">
        <div class="flex">
          <select
            class="{{
              hideAdminClass[i].default === 'hidden' ? 'hidden' : 'form-control'
            }}"
            formControlName="defaultField"
          >
            <option [ngValue]="null" disabled selected>Choose field</option>
            <option *ngFor="let field of defaultFields" [ngValue]="field.value">
              {{ field.name }}
            </option>
          </select>
          <input
            placeholder="Enter Custom Field"
            type="text"
            class="{{
              hideAdminClass[i].custom === 'hidden' ? 'hidden' : 'form-control'
            }}"
            formControlName="customField"
          />
          <select class="form-control ml-2" formControlName="fieldType">
            <option *ngFor="let type of fieldTypes" [ngValue]="type.value">
              {{ type.name }}
            </option>
          </select>
        </div>

        <div class="flex justify-between">
          <!-- <label for="{{ 'required' + i }}" class="text-sm flex">
            <app-checkbox
              class="text-xl align-middle"
              uncheckClass="text-gray-500"
              checkClass="text-primary-dark"
              formControlName="required"
              id="{{ 'required' + i }}"
            >
            </app-checkbox
            ><span class="flex mt-1 ml-1">set as required</span>
          </label> -->
          <label for="{{ 'restrict' + i }}" class="text-sm flex">
            <app-checkbox
              class="text-xl align-middle"
              uncheckClass="text-gray-500"
              checkClass="text-primary-dark"
              formControlName="restrictValues"
              id="{{ 'restrict' + i }}"
            >
            </app-checkbox
            ><span class="flex mt-1 ml-1">restrict values</span>
          </label>
          <button
            type="button"
            class="uppercase text-primary-600 my-2 text-sm {{
              hideAdminClass[i].default
            }}"
            (click)="setCustomClass(i, 'admin')"
          >
            Set Custom Field
          </button>
          <button
            type="button"
            class="uppercase text-primary-600 my-2 text-sm {{
              hideAdminClass[i].custom
            }}"
            (click)="setDefaultClass(i, 'admin')"
          >
            Set Default Field
          </button>
        </div>
        <!-- Put options here -->
        <ng-container
          *ngIf="
            admin.get('restrictValues') && admin.get('restrictValues').value
          "
        >
          <div class="form-label">Options</div>
          <div
            formArrayName="dropdownFields"
            *ngFor="let option of getDropdownFields(admin); let j = index"
          >
            <div [formGroupName]="j">
              <div class="flex justify-between items-center">
                <input
                  type="text"
                  formControlName="name"
                  class="form-control mb-1"
                />
                <button
                  type="button"
                  class="btn btn-icon bg-transparent text-gray-600 hover:text-red-600 text-2xl"
                  (click)="removeOption(admin, j)"
                >
                  <app-icon icon="close"></app-icon>
                </button>
              </div>
            </div>
          </div>
          <input
            type="text"
            placeholder="Add option..."
            (keydown.enter)="onEnterNewOption($event, admin)"
            (blur)="onEnterNewOption($event, admin)"
            class="form-control mr-2"
          />
        </ng-container>
        <button
          type="button"
          (click)="addField(i, 'admin')"
          class="flex my-4 text-primary-600"
        >
          <app-icon icon="add"> </app-icon>
          <div class="uppercase ml-2">add more field</div>
        </button>
      </div>
    </div>
    <button
      *ngIf="adminMetadata.controls.length === 0"
      type="button"
      (click)="addField(0, 'admin')"
      class="flex text-primary-600 my-4"
    >
      <app-icon icon="add"> </app-icon>
      <div class="uppercase ml-2">add more field</div>
    </button>
    <div class="flex justify-end">
      <button class="btn btn-primary" type="submit">update</button>
    </div>
  </form>
</app-back-title-layout>
