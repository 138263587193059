import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";

interface Value {
  input:
    | "button"
    | "checkbox"
    | "date"
    | "email"
    | "search"
    | "submit"
    | "text"
    | "image"
    | "select"
    | "number";
  value: string;
  index: number;
  name?: string;
  options?: any[];
}
export interface Field {
  name: string;
  value: Value;
}
@Component({
  selector: "app-filter",
  templateUrl: "./filter.component.html",
  styles: [],
})
export class FilterComponent implements OnInit {
  form: UntypedFormGroup;
  selectIndex: number[] = [];
  params: any = {};
  @Input() fields: Field[] = [];
  @Output() state = new EventEmitter<any>();
  @Input() saved: any;
  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    if (this.saved) {
      this.form = this.saved.form;
      this.params = this.saved.params;
      this.selectIndex = this.saved.index;
    } else {
      this.form = new UntypedFormGroup({
        filter: new UntypedFormArray([], [Validators.minLength(1)]),
      });
    }
  }
  get _filters() {
    return <UntypedFormArray>this.form.get("filter");
  }
  onSubmit() {
    for (const prop in this.params) {
      this.params[prop] = null;
    }
    if ((<UntypedFormArray>this.form.get("filter")).controls.length > 0) {
      (<UntypedFormArray>this.form.get("filter")).controls.forEach(
        (element, index) => {
          this.params[element.get("filterChoice").value.value] =
            element.get("filterData").value;
        }
      );
    }
    this.state.emit({
      form: this.form,
      params: this.params,
      index: this.selectIndex,
    });
    this.router.navigate([], {
      relativeTo: this.route,
      replaceUrl: false,
      queryParamsHandling: "merge",
      queryParams: this.params,
    });
  }
  removeFilter(i) {
    (<UntypedFormArray>this.form.get("filter")).removeAt(i);
    this.selectIndex.splice(i, 1);
  }

  setInputType(index) {
    this.selectIndex[index] = (<UntypedFormArray>this.form.get("filter"))
      .at(index)
      .get("filterChoice").value.index
      ? (<UntypedFormArray>this.form.get("filter"))
          .at(index)
          .get("filterChoice").value.index
      : 0;
  }
  addFilter() {
    const filterGroup = new UntypedFormGroup({
      filterChoice: new UntypedFormControl(null, Validators.required),
      filterData: new UntypedFormControl(null, Validators.required),
    });
    (<UntypedFormArray>this.form.get("filter")).push(filterGroup);
  }
  clearAll() {
    (<UntypedFormArray>this.form.get("filter")).clear();
    this.selectIndex = [];
  }
}
