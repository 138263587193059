// DEPRECATED

import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { firstValueFrom } from "rxjs";
import { PassService, Role } from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-group-role-new",
  templateUrl: "./group-role-new.component.html",
  styles: [],
})
export class GroupRoleNewComponent implements OnInit {
  newRole: Role;
  formGroup: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private passService: PassService,
    private location: Location
  ) {}

  async ngOnInit() {
    this.formGroup = this.formBuilder.group({
      name: ["", Validators.required],
      description: [],
      permissions: this.formBuilder.group({}),
    });

    this.newRole = {
      tagList: [],
      permissions: [],
    };
  }

  async createRole() {
    let permissionMap = this.formGroup.get("permissions").value;

    let permissionsAttributes = Object.entries(permissionMap)
      .filter(([key, value]) => value)
      .map(([key, value]) => {
        return {
          name: key,
        };
      });

    Object.assign(this.newRole, {
      name: this.formGroup.value.name,
      description: this.formGroup.value.description,
      tagList: ["custom"],
      permissionsAttributes,
      // HARDCODE: until https://buri.dev/T549 is solved
      applicationId: localStorage.getItem("application_id"),
    });

    await firstValueFrom(
      this.passService.createRole({
        role: this.newRole,
      })
    );

    this.location.back();
  }
}
