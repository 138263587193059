import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Subject, takeUntil } from "rxjs";
import { ColorHelperService } from "src/app/services/color-helper.service";
import { ModalService } from "src/app/services/modal.service";
import { ResourcesService } from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-burislides-slide-appearance-modal",
  templateUrl: "./burislides-slide-appearance-modal.component.html",
  styles: [],
})
export class BurislidesSlideAppearanceModalComponent implements OnInit {
  formGroup: UntypedFormGroup;
  constructor(
    public dialogRef: MatDialogRef<BurislidesSlideAppearanceModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    private colorHelperService: ColorHelperService,
    private modalService: ModalService,
    private resourceService: ResourcesService
  ) {}
  async ngOnInit() {
    console.log(this.data);
    let appearance = this.data.currentSlide.spread.appearance;

    this.formGroup = this.fb.group({
      baseBackgroundColor: [
        this.colorHelperService.constructRGBAFromCastColor(
          appearance?.base?.backgroundColor
        ) || "rgba(0,0,0,1)",
      ],
      baseForegroundColor: [
        this.colorHelperService.constructRGBAFromCastColor(
          appearance?.base?.foregroundColor
        ) || "rgba(0,0,0,1)",
      ],
    });

    this.formGroup
      .get("baseBackgroundColor")
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe((control) => {
        this.setSlideColor();
      });
    this.formGroup
      .get("baseForegroundColor")
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe((control) => {
        this.setSlideColor();
      });
  }
  private unsubscribe$ = new Subject();
  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
  async onSave() {
    let appearance = {
      base: {
        foregroundColor: this.colorHelperService.constructCastColor(
          this.formGroup.value.baseForegroundColor
        ),
        backgroundColor: this.colorHelperService.constructCastColor(
          this.formGroup.value.baseBackgroundColor
        ),
      },
    };
    this.data.currentSlide.spread.appearance = appearance;
    this.dialogRef.close(this.data);
  }

  setSlideColor() {
    let appearance = {
      base: {
        foregroundColor: this.colorHelperService.constructCastColor(
          this.formGroup.value.baseForegroundColor
        ),
        backgroundColor: this.colorHelperService.constructCastColor(
          this.formGroup.value.baseBackgroundColor
        ),
      },
    };
    this.data.currentSlide.spread.appearance = appearance;
  }

  async resetToDefault() {
    let result = await this.modalService.confirm(
      "Are you sure you want to reset settings to default?"
    );
    if (result) {
      this.formGroup.reset({
        baseForegroundColor: "rgba(0,0,0,1)",
        baseBackgroundColor: "rgba(255,255,255,1)",
      });
    }
  }
}
