import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import {
  V1ExamChoice,
  V1ExamChoiceSetFeedback,
  V1ExamItem,
  V1ExamsService,
} from "src/app/sinigangnababoywithgabi";
import { firstValueFrom } from "rxjs";

@Component({
  selector: "app-burislides-block-activity",
  templateUrl: "./burislides-block-activity.component.html",
  styles: [],
})
export class BurislidesBlockActivityComponent implements OnInit {
  typeIcon: string = "add_circle";
  @Input() choices: V1ExamChoice[];
  @Input() sectionUuid: string;
  @Input() newItem: boolean;
  @Input() itemUuid: string;
  @Input() item: V1ExamItem;
  @Input() itemType: string = "CB";
  @Input() itemSubtype: string;
  @Input() editMode: boolean;
  @Input() previewMode: boolean;
  @Input() navigated: boolean;
  @Output() edit: EventEmitter<any> = new EventEmitter<any>();
  @Output() _itemUuid: EventEmitter<any> = new EventEmitter<any>();
  @Output() currentItem: EventEmitter<any> = new EventEmitter<any>();
  @Output() isLoading: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() updated: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() feedback: boolean;
  choiceSetFeedbacks: V1ExamChoiceSetFeedback[];
  constructor(
    private v1ExamsService: V1ExamsService,
    private cd: ChangeDetectorRef
  ) {}
  async ngOnInit() {
    console.log(this.itemUuid);
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.editMode !== (null || undefined) && changes.editMode) {
      if (changes.editMode.currentValue !== changes.editMode.previousValue) {
        this.cd.detectChanges();
      }
    }
    if (changes.itemUuid) {
      this.cd.detectChanges();
    }
  }
  onEdit() {
    this.edit.emit(this.item);
  }
  onCreate(itemUuid) {
    this._itemUuid.emit(itemUuid);
  }
  outputItem(item: V1ExamItem) {
    this.item = item;
    this.currentItem.emit(item);
  }
  onExamItemUpdate(updated) {
    this.updated.emit(updated);
  }
  outputChoices(choices: V1ExamChoice[]) {
    this.choices = choices;
  }
  outputIsLoading(loading) {
    this.isLoading.emit(loading);
  }
  outputChoiceSetFeedbacks(feedbacks) {
    this.choiceSetFeedbacks = feedbacks;
  }
  getFeedback() {
    this.feedback = true;
    this.cd.detectChanges();
  }
  setFeedback() {
    this.feedback = false;
    this.cd.detectChanges();
  }
}
