import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import { Observable, of, Subscription, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { fadeInOut } from "../../animations/fade";

@Component({
  selector: "app-item-list",
  templateUrl: "./item-list.component.html",
  styleUrls: ["./item-list.component.scss"],
  animations: [fadeInOut],
})
export class ItemListComponent implements OnInit {
  @Input()
  items: any[];

  @Input()
  source: Observable<any[]>;
  sourceSub: Subscription;

  @Input()
  key: string = "uuid";

  @Input()
  itemTemplate: TemplateRef<any>;

  @Input()
  emptyListMessage = "Nothing here.";

  @Input()
  liClass = "";
  @Input() externalLoading = false;
  loading = true;
  errorMessage: string;

  constructor() {}
  ngOnInit(): void {
    if (!this.items) {
      this.refreshList();
    }
  }

  ngOnChanges() {
    if (this.items) {
      this.loading = false;
    }

    if (!this.items && this.source) {
      this.refreshList();
    }
  }

  refreshList() {
    this.loading = true;
    this.errorMessage = null;

    if (this.source) {
      this.sourceSub?.unsubscribe();

      this.sourceSub = this.source
        .pipe(
          catchError((err) => {
            this.errorMessage = err.message;
            this.loading = false;

            return throwError(() => err);
          })
        )
        .subscribe((result) => {
          this.loading = false;
          this.items = result;
        });
    }
  }

  ngOnDestroy() {
    this.sourceSub?.unsubscribe();
  }
}
