import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { CsvService } from "src/app/services/csv.service";

@Component({
  selector: "app-csv-upload-or-paste",
  templateUrl: "./csv-upload-or-paste.component.html",
  styles: [],
})
export class CsvUploadOrPasteComponent implements OnInit {
  fileName: string;

  @Input()
  progress: number;

  @Input()
  camelCaseKeys = true;

  @Output()
  onChange = new EventEmitter<any[]>();

  ngOnInit(): void {}

  constructor(private csvService: CsvService) {}

  // If data came from file upload
  async fileChange(event) {
    let file = event.target.files[0];
    event.srcElement.value = null;

    this.processFile(file);
  }

  // If data came from copy paste
  async onPaste(event: ClipboardEvent) {
    let clipboardData = event.clipboardData;

    let data: string | File;

    data = clipboardData.getData("text") || clipboardData.files[0];

    this.processFile(data);
  }

  onClick(fileInput) {
    if (this.progress === null || this.progress === undefined) {
      fileInput.click();
    }
  }

  async processFile(data: string | File) {
    if (data instanceof File) {
      this.fileName = data.name;
    }
    // Convert file to camelCased json
    // Eg. `first_name` converted to `firstName`
    // This is also supported: `First Name` converted to `firstName`
    let result = await firstValueFrom(
      this.csvService.csvFileToJson(data, {
        camelCaseKeys: this.camelCaseKeys,
      })
    );

    this.onChange.emit(result);
  }
}
