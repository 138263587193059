<app-back-title-layout
  layoutTitle="Batch User Enrollments"
  [loading]="!schedule || !selectedUserUuids"
>
  <div class="relative">
    <app-loading
      [loading]="isLoading"
      wrapperClass="absolute inset-0 flex items-center justify-center bg-white bg-opacity-50"
    ></app-loading>
    <ng-container *ngIf="schedule && selectedUserUuids">
      <app-group-user-selector
        [(selectedUserUuids)]="selectedUserUuids"
      ></app-group-user-selector>

      <div class="mt-5 sticky bottom-0 pb-2">
        <button
          (click)="updateUserEnrollments()"
          [disabled]="isLoading"
          class="btn btn-primary w-full"
        >
          {{ isLoading ? "Updating..." : "Update User Enrollments" }}
        </button>
      </div>
    </ng-container>
  </div>
</app-back-title-layout>
