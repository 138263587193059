<div
  [style.backgroundColor]="
    block?.properties?.appearance?.base?.backgroundColor | castColor
  "
  class="w-full"
>
  <div class="flex justify-between w-full">
    <div cdkDragHandle>
      <button>
        <app-icon icon="unfold_more" class="text-gray-600"></app-icon>
      </button>
    </div>
    <app-casteditor-edit-toggle
      (deleteBlock)="onDeleteBlock()"
      settingsRouterLink="block-button-search-settings"
      [settingsQueryParams]="{ block_id: block.id }"
      [ngClass]="{
        invisible: disableEdit
      }"
    >
    </app-casteditor-edit-toggle>
  </div>
  <div class="flex flex-col w-full items-center px-20 py-16">
    <div class="flex items-center py-1 w-full">
      <div
        class="flex w-full font-bold justify-center items-center text-4xl"
        [style.color]="
          block.properties?.appearance?.base?.foregroundColor | castColor
        "
        [style.fontFamily]="block.properties?.appearance?.base?.fontFamily?.[0]"
      >
        {{ searchBlockTitle }}
      </div>
    </div>
    <div class="flex items-center justify-center py-1 w-full">
      <app-icon icon="search" class="mx-1"></app-icon>

      <input class="form-control" placeholder="Search" [disabled]="true" />
      <!-- <div class="h-5 border border-gray-700 rounded w-full mx-1"></div> -->
    </div>
  </div>
</div>
