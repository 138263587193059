import { Component, Input } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder } from "@angular/forms";
import { Resource, ResourcesService } from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-resource-type-upload-burislides",
  templateUrl: "./resource-type-upload-burislides.component.html",
  styles: [],
})
export class ResourceTypeUploadBurislidesComponent {
  @Input()
  resource: Resource;

  @Input()
  formGroup: UntypedFormGroup;

  resources: Resource[];

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.formGroup.addControl(
      "content",
      this.formBuilder.group({
        sourceResourceUuid: [this.resource.content["sourceResourceUuid"]],
        spreads: [this.resource.content["spreads"] || []],
        examDetails: [
          {
            examUuid: this.resource.content["examDetails"]?.["examUuid"],
            examTimeslotUuid:
              this.resource.content["examDetails"]?.["examTimeslotUuid"],
            examSectionUuid:
              this.resource.content["examDetails"]?.["examSectionUuid"],
          },
        ],
      })
    );
  }
}
