import { Component, OnInit } from "@angular/core";
import { Group, PassService, User } from "src/app/sinigangnababoywithgabi";
import { ActivatedRoute } from "@angular/router";
import { from, of, Subscription, throwError } from "rxjs";
import { switchMap, map, filter, toArray, tap, mergeMap } from "rxjs/operators";
import { ModalService } from "src/app/services/modal.service";
import { Task, TaskService } from "src/app/services/task.service";

@Component({
  selector: "app-group-edit-members-email-list",
  templateUrl: "./group-edit-members-email-list.component.html",
  styleUrls: ["./group-edit-members-email-list.component.scss"],
})
export class GroupEditMembersEmailListComponent implements OnInit {
  group: Group;
  users: User[];
  taskItems: {
    email: string;
    task: Task;
  }[];

  willReplaceMembers: boolean = false;

  subscription: Subscription;
  loading: boolean;

  constructor(
    private route: ActivatedRoute,
    private passService: PassService,
    private modalService: ModalService,
    private taskService: TaskService
  ) {}

  ngOnInit(): void {}

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  startAddUsers(value: string) {
    this.subscription?.unsubscribe();
    let { group_uuid: groupUuid } = this.route.snapshot.queryParams;

    this.loading = true;
    this.taskItems = null;

    let emails = this.extractEmails(value);

    this.subscription = this.passService
      .getGroup(groupUuid)
      .pipe(
        tap((group) => (this.group = group)),

        // Ask if you are sure to remove members of the group
        switchMap(() => {
          if (this.willReplaceMembers) {
            this.group.userIds = [];

            return this.modalService.confirm(
              "This action will replace all members of the group. Continue?"
            );
          } else {
            return of(true);
          }
        }),

        filter((result) => result),

        //Fetch users
        // switchMap(() => {
        //   return this.passService.listUsers();
        // }),
        // map((result) => result.users),

        // Create a tasks for checking and adding if user in the cast and in the group
        map(() => {
          this.taskItems = emails.map((email) => {
            return {
              email,
              task: this.taskService.createTask({
                context: {
                  email,
                },
                steps: [
                  {
                    successMessage: "User is registered in system",
                    errorMessage: "User is not registered in the system",
                    fn: this.checkIfUserIsRegisteredTask(email),
                  },
                  {
                    successMessage: "User is added in the group",
                    stepMessage: "Add user in group",
                    errorMessage: "User is not added in the group",
                    fn: this.updateGroupTask(groupUuid),
                  },
                ],
              }),
            };
          });

          return this.taskItems;
        }),

        // Start and set concurrency to 5
        switchMap((items) => {
          return from(items).pipe(
            mergeMap((item) => item.task.start(), 5),
            toArray()
          );
        })
      )
      .subscribe(() => {
        this.loading = false;
      });
  }

  get pendingCount() {
    return this.taskItems?.filter((i) => i.task.isPending).length;
  }
  get successCount() {
    return this.taskItems?.filter((i) => i.task.isDone).length;
  }
  get failedCount() {
    return this.taskItems?.filter((i) => i.task.isError).length;
  }

  extractEmails(rawString: string) {
    let emailRaw = rawString
      .trim()
      .split("\n")
      .map((val) => val.trim().toLowerCase())
      .filter((val) => val);

    return [...new Set(emailRaw)];
  }

  // Factory method for easier testing
  checkIfUserIsRegisteredTask(email: string) {
    return (context) => {
      return this.passService.listUsers(email, 5).pipe(
        map((result) => result.users),
        switchMap((users) => {
          let user = users.find((user) => user.email === email);

          if (!user) {
            return throwError(
              () => new Error("User is not registered in the system")
            );
          } else {
            context.user = user;
            return of(null);
          }
        })
      );
    };
  }

  updateGroupTask(groupUuid: string) {
    return (context) => {
      return this.passService.updateGroupUsers(groupUuid, {
        userIds: [context.user.id],
      });
    };
  }
}
