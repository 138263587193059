import { AbstractControl } from "@angular/forms";

/**
 * Requires a form array. Validates it such that the form array have minimum elements
 */
export const minLengthArray = (min: number) => {
  return (c: AbstractControl): { [key: string]: any } => {
    if (c.value.length >= min) return null;

    return { MinLengthArray: true };
  };
};
