import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { UserManagementComponent } from "./user-management.component";
import { GroupProfileComponent } from "./groups/group-profile/group-profile.component";
import { GroupEditComponent } from "./groups/group-edit/group-edit.component";
import { GroupEditMembersComponent } from "./groups/group-edit-members/group-edit-members.component";
import { GroupCreateComponent } from "./groups/group-create/group-create.component";
import { TablistComponent } from "./tablist/tablist.component";
import { UserListComponent } from "./tablist/user-list/user-list.component";
import { GroupListComponent } from "./tablist/group-list/group-list.component";
import { UserCreateComponent } from "./users/user-create/user-create.component";
import { UserProfileComponent } from "./users/user-profile/user-profile.component";
import { UserEditComponent } from "./users/user-edit/user-edit.component";
import { UserAccountDetailsComponent } from "./users/user-account-details/user-account-details.component";
import { UserGroupsComponent } from "./users/user-groups/user-groups.component";
import { UserStatsComponent } from "./users/user-stats/user-stats.component";
import { GroupEditMembersEmailListComponent } from "./groups/group-edit-members-email-list/group-edit-members-email-list.component";
import { GroupEditMembersIndexComponent } from "./groups/group-edit-members-index/group-edit-members-index.component";
import { AuthGuard } from "../app-common-module/guards/auth.guard";
import { GroupMemberListComponent } from "./groups/group-member-list/group-member-list.component";
import { GroupRolePermissionsEditComponent } from "./groups/group-role-permissions-edit/group-role-permissions-edit.component";
import { GroupCommonRolePermissionsToggleComponent } from "./groups/group-common-role-permissions-toggle/group-common-role-permissions-toggle.component";
import { GroupRemoveMembersEmailListComponent } from "./groups/group-remove-members-email-list/group-remove-members-email-list.component";
import { UserCreateIndexComponent } from "./users/user-create-index/user-create-index.component";
import { UserCreateCsvComponent } from "./users/user-create-csv/user-create-csv.component";
import { UserActivityLogsComponent } from "./users/user-activity-logs/user-activity-logs.component";
import { GroupActivityLogsComponent } from "./groups/group-activity-logs/group-activity-logs.component";
import { GroupRolePermissionsToggleComponent } from "./groups/group-role-permissions-toggle/group-role-permissions-toggle.component";
import { GroupRoleNewComponent } from "./groups/group-role-new/group-role-new.component";
import { RouteWithData } from "src/extensions/route-with-data";
import { UserCourseProgressComponent } from "./users/user-course-progress/user-course-progress.component";
import { UserGroupEditComponent } from "./users/user-group-edit/user-group-edit.component";

const routes: RouteWithData[] = [
  {
    path: "",
    canActivate: [AuthGuard],
    component: UserManagementComponent,
    data: {
      layoutTitle: "Users and Groups",
      hideBackTitleDiagonalDesign: false,
    },
    children: [
      {
        path: "groups",
        data: {
          layoutTitle: "Groups List",
          hideBackTitleDiagonalDesign: false,
        },
        children: [
          {
            path: "",
            component: TablistComponent,
            children: [
              {
                path: "",
                component: GroupListComponent,
              },
            ],
          },
          {
            path: "new",
            data: {
              layoutTitle: "Add Group",
              hideBackTitleDiagonalDesign: true,
            },
            children: [
              {
                path: "",
                component: GroupCreateComponent,
              },
              {
                path: "new-role",
                component: GroupRoleNewComponent,
                data: {
                  layoutTitle: "Create Role",
                  hideBackTitleDiagonalDesign: true,
                },
              },
              {
                path: "permissions-toggle",
                component: GroupRolePermissionsToggleComponent,
                data: {
                  layoutTitle: "Assign Permissions",
                  hideBackTitleDiagonalDesign: true,
                },
              },
            ],
          },
          {
            path: "profile",
            data: {
              hideBackTitleDiagonalDesign: true,
              preserveQueryParamFields: ["group_uuid"],
            },
            children: [
              {
                path: "",
                component: GroupProfileComponent,
              },
              {
                path: "edit",
                data: {
                  layoutTitle: "Edit Group Details",
                  hideBackTitleDiagonalDesign: true,
                  preserveQueryParamFields: ["group_uuid"],
                },
                children: [
                  {
                    path: "",
                    component: GroupEditComponent,
                  },
                  {
                    path: "new-role",
                    component: GroupRoleNewComponent,
                    data: {
                      layoutTitle: "Create Role",
                      hideBackTitleDiagonalDesign: true,
                    },
                  },
                  {
                    path: "permissions-toggle",
                    component: GroupRolePermissionsToggleComponent,
                    data: {
                      layoutTitle: "Assign Permissions",
                      hideBackTitleDiagonalDesign: true,
                      preserveQueryParamFields: ["group_uuid", "role_uuid"],
                    },
                  },
                ],
              },
              {
                path: "edit-members/index",
                component: GroupEditMembersIndexComponent,
                data: {
                  layoutTitle: "Edit Members",
                  hideBackTitleDiagonalDesign: true,
                  preserveQueryParamFields: ["group_uuid"],
                },
              },
              {
                path: "edit-members",
                component: GroupEditMembersComponent,
                data: {
                  layoutTitle: "Edit Members",
                  hideBackTitleDiagonalDesign: true,
                  preserveQueryParamFields: ["group_uuid"],
                },
              },
              {
                path: "member-list",
                component: GroupMemberListComponent,
                data: {
                  layoutTitle: "Group Members",
                  hideBackTitleDiagonalDesign: true,
                  preserveQueryParamFields: ["group_uuid"],
                },
              },
              {
                path: "edit-members/email-list",
                component: GroupEditMembersEmailListComponent,
                data: {
                  layoutTitle: "Email List",
                  hideBackTitleDiagonalDesign: true,
                  preserveQueryParamFields: ["group_uuid"],
                },
              },
              {
                path: "edit-members/email-list-remove",
                component: GroupRemoveMembersEmailListComponent,
                data: {
                  layoutTitle: "Remove Members",
                  hideBackTitleDiagonalDesign: true,
                  preserveQueryParamFields: ["group_uuid"],
                },
              },
              {
                path: "role-permissions-edit",
                data: {
                  layoutTitle: "Roles and Permissions",
                  hideBackTitleDiagonalDesign: true,
                  preserveQueryParamFields: ["group_uuid"],
                },
                children: [
                  {
                    path: "",
                    component: GroupRolePermissionsEditComponent,
                  },
                  {
                    path: "new-role",
                    component: GroupRoleNewComponent,
                    data: {
                      layoutTitle: "Create Role",
                      hideBackTitleDiagonalDesign: true,
                      preserveQueryParamFields: ["group_uuid"],
                    },
                  },
                  {
                    path: "permissions-toggle",
                    component: GroupRolePermissionsToggleComponent,
                    data: {
                      layoutTitle: "Assign Permissions",
                      hideBackTitleDiagonalDesign: true,
                      preserveQueryParamFields: ["group_uuid", "role_uuid"],
                    },
                  },
                ],
              },
              {
                path: "common-role-permissions-toggle",
                component: GroupCommonRolePermissionsToggleComponent,
                data: {
                  layoutTitle: "Toggle Permissions",
                  hideBackTitleDiagonalDesign: true,
                  preserveQueryParamFields: ["group_uuid"],
                },
              },
              {
                path: "activity-logs",
                component: GroupActivityLogsComponent,
                data: {
                  layoutTitle: "Activity Logs",
                  hideBackTitleDiagonalDesign: true,
                  preserveQueryParamFields: ["group_uuid"],
                },
              },
            ],
          },
        ],
      },
      {
        path: "users",
        data: {
          layoutTitle: "User List",
          hideBackTitleDiagonalDesign: false,
        },
        children: [
          {
            path: "",
            component: TablistComponent,
            children: [
              {
                path: "",
                component: UserListComponent,
              },
            ],
          },
          // { path: "groups", component: GroupListComponent },
          {
            path: "new",
            component: UserCreateComponent,
            data: {
              layoutTitle: "Create User",
              hideBackTitleDiagonalDesign: true,
            },
          },
          {
            path: "new/index",
            component: UserCreateIndexComponent,
            data: {
              layoutTitle: "User Creation",
              hideBackTitleDiagonalDesign: true,
            },
          },
          {
            path: "new/csv",
            component: UserCreateCsvComponent,
            data: {
              layoutTitle: "Import CSV",
              hideBackTitleDiagonalDesign: true,
            },
          },
          {
            path: "profile",
            data: {
              layoutTitle: "User Profile",
              hideBackTitleDiagonalDesign: true,
              preserveQueryParamFields: ["user_uuid"],
            },
            children: [
              {
                path: "",
                component: UserProfileComponent,
              },
              {
                path: "edit",
                component: UserEditComponent,
                data: {
                  layoutTitle: "User Details",
                  hideBackTitleDiagonalDesign: true,
                  preserveQueryParamFields: ["user_uuid"],
                },
              },
              {
                path: "account-details",
                component: UserAccountDetailsComponent,
                data: {
                  layoutTitle: "Account Details",
                  hideBackTitleDiagonalDesign: true,
                  preserveQueryParamFields: ["user_uuid"],
                },
              },
              {
                path: "groups",
                component: UserGroupsComponent,
                data: {
                  layoutTitle: "User Groups",
                  hideBackTitleDiagonalDesign: true,
                  preserveQueryParamFields: ["user_uuid"],
                },
              },
              {
                path: "group-edit",
                component: UserGroupEditComponent,
                data: {
                  layoutTitle: "Edit Groups",
                  hideBackTitleDiagonalDesign: true,
                  preserveQueryParamFields: ["user_uuid"],
                },
              },
              {
                path: "stats",
                data: {
                  layoutTitle: "Completion and Progress",
                  hideBackTitleDiagonalDesign: true,
                  preserveQueryParamFields: ["user_uuid"],
                },
                children: [
                  {
                    path: "",
                    component: UserStatsComponent,
                  },
                  {
                    path: "course-progress",
                    component: UserCourseProgressComponent,
                  },
                ],
              },
              {
                path: "activity-logs",
                component: UserActivityLogsComponent,
                data: {
                  layoutTitle: "User Activity Logs",
                  hideBackTitleDiagonalDesign: true,
                  preserveQueryParamFields: ["user_uuid"],
                },
              },
            ],
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserManagementRoutingModule {}
