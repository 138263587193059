<app-back-title-layout layoutTitle="Email Notification" [loading]="loading">
  <ng-container *ngIf="!loading">
    <label class="flex justify-between my-10">
      <div>
        <div class="form-label leading-none">Enable email notifications</div>

        <div class="text-sm">
          Notifies the user via email whenever the user is enrolled in this
          course
        </div>
      </div>

      <app-checkbox
        [formControl]="emailNotificationEnabledFormControl"
        class="text-3xl leading-none"
        uncheckIcon="toggle_off"
        checkIcon="toggle_on"
        uncheckClass="text-gray-500"
        checkClass="text-primary-dark"
      ></app-checkbox>
    </label>

    <div *ngIf="emailNotificationEnabledFormControl.value" [@fadeInOut]>
      <div [formGroup]="enrollEmailProfileFormGroup">
        <div class="my-10">
          <label class="form-label">
            <span>Subject</span>
            <input type="text" class="form-control" formControlName="subject" />
          </label>
        </div>
        <div class="my-10">
          <label class="form-label">
            <span>From email</span>
            <input
              type="text"
              class="form-control"
              formControlName="fromEmail"
            />
          </label>
        </div>
        <div class="my-10">
          <label class="form-label">
            <span>Display Course Title</span>
            <input
              type="text"
              class="form-control"
              formControlName="displayCourseTitle"
            />
          </label>
        </div>

        <div class="my-10">
          <label class="form-label"> HTML Template </label>

          <div class="border rounded">
            <app-markdown-viewer-and-editor
              [autoResize]="false"
              editorClass="h-40 overflow-y-scroll"
              viewerClass="h-40 overflow-y-scroll border rounded p-2"
              formControlName="htmlContent"
            >
            </app-markdown-viewer-and-editor>
          </div>
        </div>
      </div>
    </div>

    <button appGoBack class="btn btn-primary w-full">DONE</button>
  </ng-container>
</app-back-title-layout>
