import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { firstValueFrom } from "rxjs";
import { V1ExamsService } from "src/app/sinigangnababoywithgabi";

export interface ExamResultItem {
  item?: any;
  itemUuid: string;
  itemText?: string;
  itemType?: string;
  itemSubtype?: string;
  itemImageUri?: string;
  choicesV2?: any;
  responses: {
    responseText?: string;
    responseCreatedAt?: string;
    responseIsCorrect?: boolean;
    responseUserUuid?: string;
  }[];

  choices: {
    count?: number;
    countMax?: number;

    choiceText?: string;
    choiceImageUri?: string;
    choiceAudioUri?: string;
    isCorrect?: boolean;
  }[];
}

@Component({
  selector: "app-exam-results",
  templateUrl: "./exam-results.component.html",
  styles: [],
})
export class ExamResultsComponent implements OnInit {
  examResultItems: ExamResultItem[];

  constructor(
    private route: ActivatedRoute,
    private v1ExamsService: V1ExamsService
  ) {}

  async ngOnInit() {
    const { exam_uuid: examUuid, timeslot_uuid: examTimeslotUuid } =
      this.route.snapshot.queryParams;

    const result = await firstValueFrom(
      this.v1ExamsService.examsResultsGet(examUuid)
    );

    this.examResultItems = result.sections[0]?.items.map((item) => {
      let choices;

      choices = item.choices.map((choice) => {
        return {
          count: choice.results?.count,
          countMax: choice.results?.total,
          choiceText: choice.longInput || choice.shortInput,
          choiceImageUri: choice.imageUrl,
          choiceAudioUri: choice.audioUrl,
          isCorrect: choice.isCorrect,
        };
      });
      // }

      const responses = item.responses.map((response) => {
        return {
          responseText: response.longInput,
          responseCreatedAt: response.createdAt,
          responseIsCorrect:
            response.markedCorrect || response.isResponseCorrect,
        };
      });

      return {
        itemUuid: item.uuid,
        itemText: item.text,
        itemType: item.type,
        itemSubtype: item.subtype,
        itemImageUri: item.mediaUrl,
        responses,
        choices,
      };
    });
  }
}
