<app-back-title-layout [loading]="!rolePermissionFormGroup">
  <ng-container *ngIf="rolePermissionFormGroup">
    <div class="flex items-center">
      <app-icon icon="group" class="text-gray-600 text-6xl mr-2"></app-icon>

      <div>
        <div class="font-semibold leading-none">
          {{ group.name }}
        </div>

        <div class="text-sm text-gray-700">
          {{ group.userIds?.length }} member/s
        </div>

        <a
          [routerLink]="['/groups', 'edit-members', 'index']"
          [queryParams]="{
            group_uuid: group.id
          }"
          class="text-sm text-primary-light"
        >
          Edit group members
          <app-icon icon="chevron_right"></app-icon>
        </a>
      </div>
    </div>

    <hr class="my-5" />

    <div>
      <label class="form-label">Permissions Granted</label>

      <label>
        <app-radio
          [formControl]="grantTypeFormControl"
          class="text-primary"
          value="grantall"
          name="grantype"
        ></app-radio>

        <span class="mx-2">Grant All</span>
      </label>

      <br />

      <label>
        <app-radio
          [formControl]="grantTypeFormControl"
          class="text-primary"
          value="specific"
          name="grantype"
        ></app-radio>

        <span class="mx-2">Specific</span>
      </label>
    </div>

    <div
      class="mx-1"
      *ngIf="grantTypeFormControl.value === 'specific'"
      [formGroup]="rolePermissionFormGroup"
      [@fadeInOut]
    >
      <div
        class="ml-5 text-sm font-semibold"
        *ngIf="rolePermissionFormGroup.value.isActive"
      >
        <ng-container *ngFor="let pfg of getPermissionFormGroups().controls">
          <div [formGroup]="pfg">
            <label>
              <app-checkbox
                (change)="onCheckboxChange()"
                class="text-primary"
                formControlName="isActive"
              ></app-checkbox>
              <span>
                {{
                  pfg.value.permissionDescription || pfg.value.permissionName
                }}
              </span>
            </label>
          </div>
        </ng-container>
      </div>
    </div>

    <button class="btn btn-primary w-full my-5" appGoBack>Done</button>

    <button (click)="removeGroup()" class="btn btn-danger w-full my-5">
      Remove as admin
    </button>
  </ng-container>
</app-back-title-layout>
