<app-back-title-layout [layoutTitle]="title" [loading]="loading">
  <div
    *ngIf="errors"
    class="w-4/5 px-4 py-2 m-auto mb-4 text-pink-700 bg-pink-200 rounded"
  >
    <span
      *ngFor="let e of errors"
      class="block w-full text-xs italic font-light text-center"
      >{{ e }}</span
    >
  </div>

  <ng-container *ngIf="isEmailExists === null || isEmailExists === true">
    <form [formGroup]="formGroup" (ngSubmit)="emailCheck()" class="mt-5">
      <h2 class="form-heading">Account Details</h2>

      <div class="my-5">
        <label>
          <span class="form-label"> Email </span>

          <input formControlName="email" name="email" class="form-control" />

          <app-form-control-error-layout
            [control]="formGroup.get('email')"
          ></app-form-control-error-layout>
        </label>

        <ng-container *ngIf="isEmailAdded === true">
          <small class="text-sm text-red-700"
            >This email is already added in your Cast</small
          >
        </ng-container>
      </div>

      <ng-container *ngIf="isEmailAdded === null || isEmailAdded === true">
        <button
          type="submit"
          [disabled]="!formGroup.get('email')?.valid"
          class="btn btn-primary"
        >
          Check email
        </button>
      </ng-container>

      <ng-container *ngIf="isEmailExists === true && isEmailAdded === false">
        <div
          class="px-3 py-2 my-5 rounded bg-info-100 text-info-700 form-width"
        >
          <div class="flex align-top">
            <app-icon icon="info" class="mr-2 text-lg"></app-icon>

            <div class="flex-1">
              <div class="text-lg font-bold">Tip!</div>

              <div class="mb-3">
                This user already exists on a different Cast. Would you like to
                invite this user to your Cast?
              </div>

              <hr class="my-5 border-info-200" />
              `
              <div>
                <button
                  type="button"
                  (click)="reset()"
                  class="mr-2 bg-transparent border btn border-info-700 text-info-700"
                >
                  Not now
                </button>

                <button
                  type="button"
                  (click)="addUser()"
                  class="mr-2 text-white border btn bg-info-700"
                >
                  Invite
                </button>
              </div>
            </div>

            <div>
              <button
                class="ml-2 text-lg bg-transparent btn btn-icon"
                (click)="reset()"
              >
                <app-icon icon="close"></app-icon>
              </button>
            </div>
          </div>
        </div>
      </ng-container>
    </form>
  </ng-container>

  <ng-container *ngIf="isEmailExists === false">
    <form [formGroup]="formGroup" (ngSubmit)="createUser()" class="mt-5">
      <h2 class="form-heading">Account Details</h2>

      <div class="my-5">
        <label>
          <span class="form-label"> Email </span>

          <input formControlName="email" class="form-control" />

          <app-form-control-error-layout
            [control]="formGroup.get('email')"
          ></app-form-control-error-layout>
        </label>
      </div>

      <div class="my-5">
        <label class="form-label" for="password"> Password </label>

        <!-- <div class="my-2">
          <label class="mr-10">
            <app-radio
              formControlName="changePasswordOnLogin"
              class="mr-2 text-primary-700"
              [value]="true"
            ></app-radio>
            <span class="inline form-label">Change password on login</span>
          </label>

          <label>
            <app-radio
              formControlName="changePasswordOnLogin"
              class="mr-2 text-primary-700"
              [value]="false"
            ></app-radio>
            <span class="inline form-label">Provide password</span>
          </label>
        </div> -->

        <div class="flex items-center my-2">
          <input
            autofocus
            id="password"
            name="password"
            placeholder="minimum of 8 characters"
            [type]="hide ? 'password' : 'text'"
            formControlName="password"
            class="form-control"
            required
          />

          <button
            type="button"
            (click)="toggleVisibility()"
            class="bg-transparent btn btn-icon"
          >
            <app-icon [icon]="visibilityIcon"></app-icon>
          </button>
        </div>
        <app-form-control-error-layout
          [control]="formGroup.get('password')"
        ></app-form-control-error-layout>
      </div>

      <div class="my-2">
        <label class="mr-10">
          <app-checkbox
            formControlName="changePasswordOnLogin"
            class="mr-2 text-primary-700"
          ></app-checkbox>
          <span class="inline form-label">Change password on login</span>
        </label>
      </div>

      <hr class="form-divider" />

      <h2 class="form-heading">User Details</h2>

      <div class="my-5">
        <label>
          <span class="form-label"> First Name </span>
          <input formControlName="firstName" class="form-control" />
          <app-form-control-error-layout
            [control]="formGroup.get('firstName')"
          ></app-form-control-error-layout>
        </label>
      </div>

      <div class="my-5">
        <label>
          <span class="form-label"> Last Name </span>

          <input formControlName="lastName" class="form-control" />

          <app-form-control-error-layout
            [control]="formGroup.get('lastName')"
          ></app-form-control-error-layout>
        </label>
      </div>

      <hr class="form-divider" />

      <h2 class="form-heading">Group</h2>
      <div class="my-5 text-sm form-label">
        Users should be a member of a group. The roles assigned in a group
        allows its member to access features on your Cast.

        <!-- To learn more what the assigned role can do, please hover the role name. -->
      </div>

      <table class="w-full border-gray-200 border-b-1">
        <tr class="border-b-2 border-gray-200">
          <th class="my-1 font-normal text-left text-gray-500">Group Name</th>
          <th class="my-1 font-normal text-left text-gray-500">Members</th>
          <!-- <th class="my-1 font-normal text-left text-gray-500">Role</th> -->
          <th></th>
        </tr>

        <tr
          class="py-2 border-b"
          *ngFor="let group of groupListObs | async; let i = index"
        >
          <td class="py-2">
            <app-icon class="text-gray-400" icon="group"></app-icon>
            {{ group.name }}
          </td>
          <td>
            <app-icon class="text-gray-400" icon="person"></app-icon>
            {{ group.usersCount }}
          </td>

          <td>
            <button
              type="button"
              (click)="removeGroup(i)"
              class="text-gray-500 bg-transparent btn btn-icon hover:text-error-600"
            >
              <app-icon icon="close"></app-icon>
            </button>
          </td>
          <!-- <td class="my-1">asdsad</td> -->
        </tr>
      </table>

      <button
        type="button"
        class="bg-transparent btn text-primary-700"
        (click)="selectGroup()"
      >
        <app-icon icon="add"></app-icon>
        Add more group
      </button>

      <div class="flex flex-row-reverse mt-10">
        <button
          [disabled]="formGroup.invalid"
          type="submit"
          class="btn btn-primary"
        >
          Save
        </button>

        <button
          [disabled]="formGroup.invalid"
          (click)="createUser(true)"
          type="button"
          class="mx-4 btn btn-info"
        >
          Save and add another
        </button>
      </div>
    </form>
  </ng-container>
</app-back-title-layout>
