import { Component, OnInit } from "@angular/core";
import {
  ResourcesService,
  Resource,
  V1ExamItem,
} from "src/app/sinigangnababoywithgabi";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";

// DEPRECATED
@Component({
  selector: "app-buribook-pages-edit",
  templateUrl: "./buribook-pages-edit.component.html",
  styleUrls: ["./buribook-pages-edit.component.scss"],
})
export class BuribookPagesEditComponent implements OnInit {
  resource: Resource;

  spreads: any[];

  activities: V1ExamItem[];

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private resourcesService: ResourcesService
  ) {}

  ngOnInit() {
    let queryParams = this.route.snapshot.queryParams;

    if (queryParams["resource_uuid"]) {
      this.resourcesService
        .getResourceByUuid(queryParams["resource_uuid"])
        .subscribe((result) => {
          this.resource = result;

          this.parseContent();
        });
    }
  }

  parseContent() {
    this.spreads = [];

    this.resource.content["spreads"].forEach((spread, index) => {
      this.spreads.push({
        ...spread,
        leftPage: index * 2 + 1,
        rightPage: index * 2 + 2,
      });
    });
  }

  saveResource() {
    this.resourcesService
      .updateResourceByUuid(
        this.route.snapshot.queryParams["resource_uuid"],
        this.resource
      )
      .subscribe();
  }

  onDone() {
    this.location.back();
  }
}
