<div class="flex justify-between mb-2">
  <div
    *ngIf="loading"
    [@fadeInOut]
    class="absolute inset-0 bg-white bg-opacity-50 h-full"
  >
    <app-loading [loading]="true" position="relative"></app-loading>
  </div>
  <h2 class="form-heading">Send Test Email</h2>
  <button class="text-gray-400" mat-dialog-close>
    <app-icon [icon]="'close'"></app-icon>
  </button>
</div>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="text-gray-400">Email</div>
  <input
    type="email"
    placeholder="sample@buri.io"
    class="form-control"
    formControlName="email"
  />

  <div class="flex justify-end mt-2">
    <button class="btn btn-primary" [disabled]="form.invalid">send</button>
  </div>
</form>
