import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";

import { saveAs } from "file-saver";

@Injectable({
  providedIn: "root",
})
export class DownloaderService {
  constructor(private httpClient: HttpClient) {}

  downloadFile(url: string, fileName?: string): Observable<any> {
    if (fileName) {
      saveAs(url, fileName);
      return of(true);
    }
    saveAs(url);

    return of(true);
  }

  downloadPDF(url: string): Observable<any> {
    return this.httpClient.get(url, {
      headers: {
        "Content-Type": "application/pdf",
        Accept: "application/pdf",
      },
      responseType: "blob",
    });
  }
  downloadImage(url: string): Observable<any> {
    return this.httpClient.get(url, {
      headers: {
        "Content-Type": "image/*",
        Accept: "image/*",
      },
      responseType: "blob",
    });
  }
  getData(url: string): Observable<any> {
    return this.httpClient.get(url);
  }
}
