import {
  ChangeDetectorRef,
  Component,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  defer,
  filter,
  forkJoin,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  tap,
} from "rxjs";
import { debounceTime, distinctUntilChanged, takeUntil } from "rxjs/operators";
import { fadeInOut } from "src/app/app-common-module/animations/fade";
import {
  Action,
  Header,
} from "src/app/app-common-module/components/list-table-header/list-table-header.component";
import { CsvService } from "src/app/services/csv.service";
import { DashboardService } from "src/app/services/dashboard.service";
import { ModalService } from "src/app/services/modal.service";
import {
  CoursesService,
  PassService,
  V2Cast,
} from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-user-list",
  templateUrl: "./user-list.component.html",
  animations: [fadeInOut],
})
export class UserListComponent implements OnInit {
  @ViewChild("groups", { read: TemplateRef })
  groupModalTemplate: TemplateRef<any>;
  cast: V2Cast;
  users: any;
  page: number;
  loading = false;
  columnChange = 0;
  selectedUserIds: string[] = [];
  disableHeader: boolean = true;
  batchActions: Action[];
  userListObservable: Observable<any[]>;
  oneMonth = 2629800000;
  close: boolean = false;
  checkAll: boolean = false;
  orderItems = [
    {
      value: "created_at desc",
      text: "Newest",
    },
    {
      value: "created_at",
      text: "Oldest",
    },
    {
      value: "first_name",
      text: "Sort A-Z",
    },
    {
      value: "first_name desc",
      text: "Sort Z-A",
    },
    {
      value: "email",
      text: "Email",
    },
  ];
  width: number = 0;
  headers: Header[] = [
    { name: "Email", value: "email", width: "w-96" },
    { name: "Name", value: "last_name", width: "w-96" },
    { name: "Date Added", value: "first_name", width: "w-56" },
    { name: "Group", value: "group", width: "w-96" },
    { name: "Status", value: "status", width: "w-56" },
  ];
  currentOrder: string;
  canAddUsersObservable: Observable<boolean>;
  exportUsersObservable: Observable<any>;

  limit: number = 20;
  totalUserCount: number = 1;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private csvService: CsvService,
    private passService: PassService,
    private dashboardService: DashboardService,
    private modalService: ModalService,
    private coursesService: CoursesService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.constructCanAddUserObservable();
    this.constructUserListObservable();
    this.constructUserActions();
    this.constructExportUsersObs();
  }
  ngOnChanges(changes: SimpleChanges): void {}

  private unsubscribe$: Subject<void> = new Subject<void>();
  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  constructCanAddUserObservable() {
    this.canAddUsersObservable = this.dashboardService
      .getPermissions()
      .pipe(map((permissions) => permissions.includes("create:users")));
  }

  constructUserListObservable() {
    this.userListObservable = this.route.queryParams.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((params) => {
        this.loading = true;
        let {
          order,
          q,
          page,
          date,
          first_name,
          last_name,
          email,
          last_signin,
        } = params;

        let date2 = date ? date + "T23:59:59.999Z" : null;
        let last_signin2 = last_signin ? last_signin + "T23:59:59.999Z" : null;
        let q2 = q ? q.split(" ") : null;
        let firstNames = first_name?.split(" ");
        let lastNames = last_name?.split(" ");
        let emails = email?.split(" ");
        this.currentOrder = order;
        this.users = null;

        return this.passService.listUsers(
          null,
          this.limit,
          page * this.limit,
          ["user_profile", "groups"],
          order,
          null,
          last_signin,
          last_signin2,
          null,
          date,
          date2,
          q2,
          firstNames,
          lastNames,
          emails
        );
      }),
      tap((result) => {
        this.users = result.users;
        this.totalUserCount = result.count;
      }),
      switchMap((result) => {
        return this.dashboardService.getCast().pipe(
          map((cast) => {
            return {
              cast,
              users: result.users,
            };
          })
        );
      }),
      map(({ cast, users }) => {
        return users.map((user) => {
          return {
            uuid: user.id,
            name:
              (user.userProfile[0]?.givenName || user.firstName) +
              " " +
              (user.userProfile[0]?.familyName || user.lastName),
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            confirmedAt: user.confirmedAt,
            createdAt: user.createdAt,
            selected: false,
            profile: user.userProfile, //array muna (2/11/22), magiging object
            groups: user.groups,
            active:
              Date.now() - this.oneMonth <= Date.parse(user.lastSignInAt) &&
              Date.parse(user.lastSignInAt) <= Date.now() &&
              !user.groupIds?.includes(
                cast.data.attributes.deactivatedUsersGroupId
              ),
          };
        });
      }),
      tap((result) => {
        this.users = result;
        this.loading = false;
      })
    );
  }

  constructExportUsersObs() {
    this.exportUsersObservable = this.route.queryParams.pipe(
      takeUntil(this.unsubscribe$),

      switchMap((params) => {
        let { order, q, date, first_name, last_name, email, last_signin } =
          params;

        let date2 = date ? date + "T23:59:59.999Z" : null;
        let last_signin2 = last_signin ? last_signin + "T23:59:59.999Z" : null;
        let q2 = q ? q.split(" ") : null;
        let firstNames = first_name?.split(" ");
        let lastNames = last_name?.split(" ");
        let emails = email?.split(" ");
        this.currentOrder = order;

        return this.passService.listUsers(
          null,
          null,
          null,
          ["user_profile"],
          order,
          null,
          last_signin,
          last_signin2,
          null,
          date,
          date2,
          q2,
          firstNames,
          lastNames,
          emails
        );
      }),
      switchMap((result) => {
        return this.csvService.downloadAsCsv(
          result.users,
          "users" + Date.now() + ".csv",
          [
            "uuid",
            "email",
            "firstName",
            "lastName",
            "createdAt",
            "updatedAt",
            "confirmationSentAt",
            "confirmedAt",
          ]
        );
      }),
      map(() => true)
    );
  }

  constructUserActions() {
    this.batchActions = [
      {
        name: "Add to...",
        action: defer(() =>
          of(this.openModal(this.groupModalTemplate, "groups")).pipe(
            switchMap((group) => {
              return group;
            }),
            switchMap((group) => {
              let result = this.modalService.confirm(
                `This will add ${this.selectedUserIds.length} user(s) to ${group.name}. Continue?`
              );
              return forkJoin([result, of(group)]);
            }),

            filter(([result]) => result),
            switchMap(([result, group]) => {
              let response = this.passService.updateGroupUsers(group.uuid, {
                userIds: this.selectedUserIds,
              });
              return forkJoin([response, of(group)]);
            }),
            switchMap(([response, group]) => {
              return this.modalService.alert(
                `${response.count} user(s) successfully added to ${group.name}`
              );
            })
          )
        ),
      },
      {
        name: "Remove from...",
        action: defer(() =>
          of(this.openModal(this.groupModalTemplate, "groups")).pipe(
            switchMap((group) => {
              return group;
            }),
            switchMap((group) => {
              let result = this.modalService.confirm(
                `This will remove ${this.selectedUserIds.length} user(s) from ${group.name}. Continue?`
              );
              return forkJoin([result, of(group)]);
            }),

            filter(([result]) => result),
            switchMap(([result, group]) => {
              let response = this.passService.deleteGroupUsers(group.uuid, {
                userIds: this.selectedUserIds,
              });
              return forkJoin([response, of(group)]);
            }),
            switchMap(([response, group]) => {
              return this.modalService.alert(
                `${response.count} user(s) successfully removed from ${group.name}`
              );
            })
          )
        ),
      },
      {
        name: "Activate",
        action: defer(() =>
          of(this.selectedUserIds).pipe(
            switchMap((ids) =>
              this.modalService.confirm(
                `This action will activate ${ids.length} user(s). Continue?`
              )
            ),
            filter((r) => r),
            switchMap(() => this.dashboardService.getCast()),
            switchMap((r) => {
              this.cast = r;
              return forkJoin(
                this.selectedUserIds.map((uuid) => {
                  return this.passService.updateUser(uuid, {
                    user: {
                      groupIds: [this.cast.data.attributes.defaultGroupId],
                    },
                  });
                })
              );
            }),
            switchMap(() =>
              this.modalService.alert("User(s) successfully activated")
            )
          )
        ),
      },
      {
        name: "Deactivate",
        action: defer(() =>
          of(this.selectedUserIds).pipe(
            switchMap((ids) =>
              this.modalService.confirm(
                `This action will deactivate ${ids.length} user(s). Continue?`
              )
            ),
            filter((r) => r),
            switchMap(() => this.dashboardService.getCast()),
            switchMap((r) => {
              this.cast = r;

              if (!r.data.attributes.deactivatedUsersGroupId) {
                return of(
                  this.modalService.alert(
                    "Inactive group not found. Dashboard will automatically create one"
                  )
                ).pipe(
                  switchMap(() => this.dashboardService.createInactiveGroup())
                );
              }

              return of({});
            }),
            switchMap(() =>
              this.modalService.confirm(
                "User(s) might be enrolled in some courses. Do you also want to remove the existing enrollments?"
              )
            ),

            switchMap((r) => {
              return forkJoin(
                this.selectedUserIds.map((uuid) => {
                  if (r) {
                    return this.coursesService
                      .courseUserEnrollmentList(uuid)
                      .pipe(
                        switchMap((enrollments) => {
                          if (enrollments.length > 0) {
                            let unenroll = enrollments.map((enrollment) => {
                              return {
                                uuid: enrollment.uuid,
                                isActive: false,
                              };
                            });
                            return this.coursesService.courseUnenrollBatch({
                              unenrolls: unenroll,
                            });
                          }
                          return of({});
                        }),

                        switchMap(() => {
                          return this.passService.updateUser(uuid, {
                            user: {
                              groupIds: [
                                this.cast.data.attributes
                                  .deactivatedUsersGroupId,
                                this.cast.data.attributes.defaultGroupId,
                              ],
                            },
                          });
                        })
                      );
                  }

                  return this.passService.updateUser(uuid, {
                    user: {
                      groupIds: [
                        this.cast.data.attributes.deactivatedUsersGroupId,
                        this.cast.data.attributes.defaultGroupId,
                      ],
                    },
                  });
                })
              );
            }),
            switchMap((r) => {
              return this.modalService.alert(
                "Users(s) succesfully deactivated"
              );
            })
          )
        ),
      },
    ];
  }
  selectedItemList(selected: string[]) {
    this.selectedUserIds = selected;
  }
  orderBy(field) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParamsHandling: "merge",
      replaceUrl: true,
      queryParams: {
        order: field,
      },
    });
  }

  importExport(value) {
    if (value === "import") {
      this.router.navigate(["/users", "new", "csv"]);
    } else if (value === "export") {
    }
  }

  columnHeaders(event: any) {
    this.headers = event.headers;
    this.cd.detectChanges();
    if (!event.local) {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParamsHandling: "merge",
        replaceUrl: true,
        queryParams: {
          column: this.columnChange++,
        },
      });
    }
  }

  async openModal(template, type?: string) {
    let modalClass = [
      "flex",
      "justify-center",
      "items-center",
      "sm:w-2/3",
      "lg:w-1/2",
      "w-4/5",
      "h-3/4",
    ];
    if (type === "groups") {
      modalClass = ["flex", "justify-center", "items-center", "w-3/4", "h-1/2"];
    }
    let results = await this.modalService.openModal(template, {
      panelClass: modalClass,
    });
    return results;
  }
}
