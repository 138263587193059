<app-back-title-layout [loading]="loading">
  <!-- 
    !resource || examResultItems === null || examResultItems === undefined -->

  <div class="flex justify-end w-full">
    <button
      class="btn bg-transparent text-primary-700"
      [appWaitForObsAfterClick]="downloadCsvObs"
    >
      <app-icon icon="insert_drive_file"></app-icon>
      Export CSV
    </button>
  </div>

  <div class="max-w-2xl mx-auto">
    <div class="aspect-[4/3] bg-primary-100 rounded">
      <ng-container *ngIf="currentSlide">
        <div class="w-full">
          <app-burislides-block-preview
            [sectionUuid]="resource.content['examDetails']?.['examSectionUuid']"
            [editMode]="false"
            [previewMode]="true"
            [currentSlide]="currentSlide"
            [examItem]="spreadAnalytics?.examResultItem?.item"
            [examChoices]="spreadAnalytics?.examResultItem?.choicesV2"
            [navigated]="navigated"
          >
          </app-burislides-block-preview>
        </div>
      </ng-container>

      <!-- <ng-container [ngSwitch]="spreadAnalytics.spreadType"> -->
      <!-- the same view can be shown in more than one case -->
      <!-- 
        <ng-container *ngSwitchCase="'slide'">
          <img
            [src]="spreadAnalytics.uri"
            class="w-full h-full object-contain"
            alt=""
          />
        </ng-container>

        <ng-container *ngSwitchCase="'activity'">
          <div class="flex items-stretch h-full">
            <div class="flex-1">
              <img
                [src]="spreadAnalytics.examResultItem.itemImageUri"
                class="object-contain w-full h-full"
                alt=""
              />
            </div>

            <div class="flex-1 flex items-center justify-center">
              <div class="font-bold">
                {{ spreadAnalytics.examResultItem.itemText }}
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'link'">
          <div class="h-full w-full flex flex-col items-center justify-center">
            <app-icon icon="link" class="text-6xl text-primary-600"></app-icon>

            <a
              [href]="spreadAnalytics.uri"
              class="text-primary-400"
              target="_blank"
              >{{ spreadAnalytics.uri }}</a
            >
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'download-link'">
          <div class="h-full w-full flex flex-col items-center justify-center">
            <app-icon
              icon="download"
              class="text-6xl text-primary-600"
            ></app-icon>

            <a
              [href]="spreadAnalytics.uri"
              class="text-primary-400"
              target="_blank"
              >{{ spreadAnalytics.uri }}</a
            >
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'pdf'">
          <div class="h-full w-full flex flex-col items-center justify-center">
            <app-icon
              icon="picture_as_pdf"
              class="text-6xl text-primary-600"
            ></app-icon>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'video'">
          <div class="h-full w-full flex flex-col items-center justify-center">
            <app-icon icon="movie" class="text-6xl text-primary-600"></app-icon>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'html'">
          <div class="h-full w-full flex flex-col items-center justify-center">
            <app-icon icon="code" class="text-6xl text-primary-600"></app-icon>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'markdown'">
          <div class="h-full w-full flex flex-col items-center justify-center">
            <app-icon
              icon="text_snippet"
              class="text-6xl text-primary-600"
            ></app-icon>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'carousel'">
          <div class="h-full w-full flex flex-col items-center justify-center">
            <app-icon
              icon="web_stories"
              class="text-6xl text-primary-600"
            ></app-icon>
          </div>
        </ng-container>
      </ng-container> -->
    </div>
  </div>

  <div class="flex items-center justify-center my-5">
    <button
      class="btn btn-icon rounded-full text-white bg-gray-500"
      (click)="navigateSlide(-1)"
    >
      <app-icon icon="navigate_before"></app-icon>
    </button>

    <div class="mx-20 uppercase">Slide {{ spreadIndex + 1 }}</div>

    <button
      class="btn btn-icon rounded-full text-white bg-gray-500"
      (click)="navigateSlide(1)"
    >
      <app-icon icon="navigate_next"></app-icon>
    </button>
  </div>

  <div class="mt-10 mb-5 text-primary font-bold">
    <ng-container *ngIf="spreadAnalytics.spreadType === 'activity'">
      {{ spreadAnalytics.examResultItem.itemType | activityType }}
    </ng-container>
    <ng-container *ngIf="spreadAnalytics.spreadType === 'activity-group'">
      Group of Questions
    </ng-container>
    <ng-container
      *ngIf="
        spreadAnalytics.spreadType !== 'activity' &&
        spreadAnalytics.spreadType !== 'activity-group'
      "
    >
      {{
        spreadTypeMap[spreadAnalytics.spreadType] || spreadAnalytics.spreadType
      }}
    </ng-container>
  </div>

  <div class="flex gap-4">
    <button
      class="text-start leading-tight w-full p-2 bg-purple-200 text-purple-600 font-bold rounded hover:cursor-pointer"
      (click)="openUsersViewedModal()"
    >
      <div class="uppercase text-xs flex justify-between">
        <span> Number of users viewed </span>

        <app-icon icon="filter_list"></app-icon>
      </div>
      <div class="text-lg">
        {{ spreadAnalytics.usersViewed || "---" }}
      </div>
    </button>

    <ng-container *ngIf="spreadAnalytics.spreadType === 'download-link'">
      <div
        class="leading-tight w-full p-2 bg-purple-200 text-purple-600 font-bold rounded"
        (click)="openLinkClickedModal()"
      >
        <div class="uppercase text-xs flex justify-between">
          <span> Number of clicks</span>

          <app-icon icon="filter_list"></app-icon>
        </div>
        <div class="text-lg">
          {{ spreadAnalytics.linkClickCount || "---" }}
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="spreadAnalytics.spreadType === 'link'">
      <div
        class="leading-tight w-full p-2 bg-purple-200 text-purple-600 font-bold rounded"
        (click)="openLinkClickedModal()"
      >
        <div class="uppercase text-xs flex justify-between">
          <span> Number of clicks</span>

          <app-icon icon="filter_list"></app-icon>
        </div>
        <div class="text-lg">
          {{ spreadAnalytics.linkClickCount || "---" }}
        </div>
      </div>
    </ng-container>

    <div
      class="leading-tight w-full p-2 bg-blue-200 text-blue-600 font-bold rounded"
    >
      <div class="uppercase text-xs">Average time spent</div>
      <div class="text-lg">
        {{ (spreadAnalytics.averageTimeSpent | timeDuration) || "---" }}
      </div>
    </div>
  </div>

  <ng-container [ngSwitch]="spreadAnalytics.spreadType">
    <ng-container *ngSwitchCase="'activity'">
      <ng-container *ngIf="spreadAnalytics.examResultItem">
        <h3 class="font-bold text-xl mt-10 mb-5">Student Answers</h3>

        <div class="text-gray-500 mt-5 mb-2 text-lg">
          {{ spreadAnalytics.examResultItem.itemText }}
        </div>

        <ng-container
          *ngIf="
            spreadAnalytics.examResultItem.itemType === 'LA' ||
            spreadAnalytics.examResultItem.itemType === 'FW'
          "
        >
          <app-exam-results-text-answers
            [responses]="spreadAnalytics.examResultItem.responses"
            [showCorrectness]="false"
          >
          </app-exam-results-text-answers>
        </ng-container>

        <ng-container *ngIf="spreadAnalytics.examResultItem.itemType === 'SA'">
          <app-exam-results-text-answers
            [responses]="spreadAnalytics.examResultItem.responses"
          >
          </app-exam-results-text-answers>
        </ng-container>

        <ng-container
          *ngIf="
            spreadAnalytics.examResultItem.itemType == 'MC' ||
            spreadAnalytics.examResultItem.itemType == 'IC' ||
            spreadAnalytics.examResultItem.itemType == 'CB'
          "
        >
          <app-exam-results-choice-answers
            [choiceResults]="spreadAnalytics.examResultItem.choices"
          ></app-exam-results-choice-answers>
        </ng-container>

        <ng-container *ngIf="spreadAnalytics.examResultItem.itemType == 'PO'">
          <app-exam-results-choice-answers
            [choiceResults]="spreadAnalytics.examResultItem.choices"
            [showCorrectness]="false"
          ></app-exam-results-choice-answers>

          <ng-container
            *ngIf="spreadAnalytics.examResultItem.itemSubtype == 'SU'"
          >
            <app-exam-results-text-answers
              class="my-5"
              [responses]="spreadAnalytics.examResultItem.responses"
              [showCorrectness]="false"
            >
            </app-exam-results-text-answers>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'activity-group'">
      <h3 class="font-bold text-xl mt-10 mb-5">Student Answers</h3>
      <ng-container
        *ngFor="let examResultItem of spreadAnalytics.examResultItems"
      >
        <div class="text-gray-500 mt-5 mb-2 text-lg">
          {{ examResultItem.itemText }}
        </div>

        <ng-container
          *ngIf="
            examResultItem.itemType === 'LA' || examResultItem.itemType === 'FW'
          "
        >
          <app-exam-results-text-answers
            [responses]="examResultItem.responses"
            [showCorrectness]="false"
          >
          </app-exam-results-text-answers>
        </ng-container>

        <ng-container *ngIf="examResultItem.itemType === 'SA'">
          <app-exam-results-text-answers [responses]="examResultItem.responses">
          </app-exam-results-text-answers>
        </ng-container>

        <ng-container
          *ngIf="
            examResultItem.itemType == 'MC' ||
            examResultItem.itemType == 'IC' ||
            examResultItem.itemType == 'CB'
          "
        >
          <app-exam-results-choice-answers
            [choiceResults]="examResultItem.choices"
          ></app-exam-results-choice-answers>
        </ng-container>

        <ng-container *ngIf="examResultItem.itemType == 'PO'">
          <app-exam-results-choice-answers
            [choiceResults]="examResultItem.choices"
            [showCorrectness]="false"
          ></app-exam-results-choice-answers>

          <ng-container *ngIf="examResultItem.itemSubtype == 'SU'">
            <app-exam-results-text-answers
              class="my-5"
              [responses]="examResultItem.responses"
              [showCorrectness]="false"
            >
            </app-exam-results-text-answers>
          </ng-container>
        </ng-container>

        <hr class="my-5" />
      </ng-container>
    </ng-container>
  </ng-container>
</app-back-title-layout>
