import { Component, Input } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Resource } from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-resource-type-upload-casticulate",
  templateUrl: "./resource-type-upload-casticulate.component.html",
  styles: [],
})
export class ResourceTypeUploadCasticulateComponent {
  @Input()
  resource: Resource;

  @Input()
  formGroup: FormGroup;

  resources: Resource[];

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.formGroup.addControl(
      "content",
      this.formBuilder.group({
        casticulateUuid: [this.resource.content["casticulateUuid"]],
      })
    );
  }
}
