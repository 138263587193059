import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { DownloaderService } from "src/app/services/downloader.service";
import { Resource, UploadsService } from "src/app/sinigangnababoywithgabi";
@Component({
  selector: "app-resource-type-upload-pdf",
  templateUrl: "./resource-type-upload-pdf.component.html",
})
export class ResourceTypeUploadPdfComponent implements OnInit {
  @Input()
  formGroup: UntypedFormGroup;

  @Input()
  resource: Resource;

  sub;
  data;
  clicked: boolean = false;
  subscribing: boolean = false;
  observeClick = new Subject();
  constructor(
    private formBuilder: UntypedFormBuilder,
    private uploadsService: UploadsService,
    private downloadService: DownloaderService
  ) {}

  ngOnInit() {
    this.formGroup.addControl(
      "content",
      this.formBuilder.group({
        uri: [this.resource.content["uri"], Validators.required],
        contentType: ["application/pdf"],
      })
    );
  }
  ngOnDestroy(): void {
    this.observeClick.next(null);
    this.observeClick.complete();
  }
  onUpload(result) {
    this.formGroup.get("content").get("uri").setValue(result.uri);
  }
  //Uncomment setEvent if canva feature is ready
  /*
  setEvent(event: string) {
    if (event === "local") {
      this.clicked = !this.clicked;
    }
    if (event === "canva_pdf") {
      if (this.subscribing) {
        this.sub.unsubscribe();
        this.subscribing = false;
      }

      window.open(`https://canva.com`, `_blank`);
      const oneSecInterval = interval(1000);
      const getPDF = oneSecInterval.pipe(
        mergeMap(() => {
          return this.downloadService.getData(environment.CANVA_API);
        }),
        filter((data) => Object.keys(data).length !== 0),
        mergeMap((data) => {
          this.data = data;
          if (this.data.links[0].type === "PDF") {
            return this.downloadService.downloadPDF(this.data.links[0].url);
          }
          this.data = null;
        }),
        map((data) => {
          return new Blob([data], {
            type: "application/pdf",
          });
        }),
        mergeMap((blob) => {
          return this.uploadsService.uploadFile(
            blob,
            undefined,
            "response",
            true
          );
        }),
        takeUntil(this.observeClick)
      );
      this.sub = getPDF.subscribe((res) => {
        this.subscribing = true;
        const url = res.body.uri;
        this.formGroup.get("content").get("uri").setValue(url);
        this.sub.unsubscribe();
        this.subscribing = false;
        this.data = null;
      });
    }
  }*/
}
