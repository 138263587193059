import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class UserPreferenceService {
  constructor() {}

  getBooleanValueFromLocalStorage(key: string) {
    let value = localStorage.getItem(key);

    if (value === "false") {
      return false;
    } else if (value) {
      return true;
    } else {
      return false;
    }
  }

  setBooleanValueToLocalStorage(key: string, value: boolean) {
    if (value) {
      localStorage.setItem(key, "true");
    } else {
      localStorage.setItem(key, "false");
    }
  }

  get isWelcomeMessageDisabled() {
    return this.getBooleanValueFromLocalStorage("is_welcome_message_disabled");
  }

  set isWelcomeMessageDisabled(isVisible: boolean) {
    this.setBooleanValueToLocalStorage(
      "is_welcome_message_disabled",
      isVisible
    );
  }

  get isAddBlockTutorialDisabled() {
    return this.getBooleanValueFromLocalStorage(
      "is_add_block_tutorial_disabled"
    );
  }

  set isAddBlockTutorialDisabled(isVisible: boolean) {
    this.setBooleanValueToLocalStorage(
      "is_add_block_tutorial_disabled",
      isVisible
    );
  }
}
