<app-back-title-layout
  layoutTitle="Collection Group Permissions"
  [loading]="!rolePermissionFormGroups"
>
  <ng-container *ngIf="rolePermissionFormGroups">
    <button
      [routerLink]="['./add-group']"
      queryParamsHandling="merge"
      class="btn btn-primary w-full py-1"
    >
      <app-icon icon="add"></app-icon>

      ADD GROUP
    </button>

    <div class="my-5">
      <app-item-list
        [items]="rolePermissionFormGroups"
        [itemTemplate]="itemTemplate"
      >
      </app-item-list>
    </div>
  </ng-container>
</app-back-title-layout>

<ng-template #itemTemplate let-rpfg="item">
  <div class="mx-1" [formGroup]="rpfg">
    <label class="block">
      <app-checkbox
        class="text-primary-dark"
        formControlName="isActive"
      ></app-checkbox>

      <span class="font-bold">
        {{ rpfg.value.roleDescription || rpfg.value.roleName }}
      </span>
    </label>

    <div class="ml-5 text-sm font-semibold" *ngIf="rpfg.value.isActive">
      <ng-container
        *ngFor="let pfg of rpfg.controls.permissionFormGroups.controls"
      >
        <div [formGroup]="pfg">
          <label>
            <app-checkbox
              class="text-primary"
              formControlName="isActive"
            ></app-checkbox>
            <span>
              {{ pfg.value.permissionDescription || pfg.value.permissionName }}
            </span>
          </label>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
