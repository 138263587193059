import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  distinctUntilChanged,
  forkJoin,
  map,
  of,
  switchMap,
  tap,
  zip,
} from "rxjs";
import {
  FieldGroupsService,
  FormSubmissionsService,
  PassService,
  Tab,
  TabsService,
  UsersService,
} from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-idis-training-needs",
  templateUrl: "./idis-training-needs.component.html",
  styles: [],
})
export class IdisTrainingNeedsComponent implements OnInit {
  loading: boolean = false;
  limit: number = 10;
  page: number = 0;
  tabDisplayName: string = "";
  formSubmissions;
  tabUuid: string = "";
  constructor(
    private route: ActivatedRoute,
    private formSubmissionsService: FormSubmissionsService,
    private fieldGroupsService: FieldGroupsService,
    private tabsService: TabsService,
    private passService: PassService,
    private usersService: UsersService
  ) {}
  ngOnInit(): void {
    this.route.queryParams
      .pipe(
        distinctUntilChanged(),
        switchMap((params) => {
          let { q, page } = params;
          this.page = page ? page : 0;
          return this.tabsService.servicesBurecordsTabsTokenBasedGet(
            false,
            "sub_tabs"
          );
        }),
        switchMap(({ count, tabs }) => {
          this.loading = true;
          let externalTrainingDocumentTab: Tab = {};
          tabs.forEach((tab) => {
            if (tab.displayName === "Training Needs") {
              externalTrainingDocumentTab = tab;
              this.tabDisplayName = tab.displayName;
              this.tabUuid = tab.id;
            }
          });
          if (!externalTrainingDocumentTab.id) {
            return of({ count: 0, fieldGroups: [] });
          }
          return this.fieldGroupsService.servicesBurecordsTabsIdFieldGroupsGet(
            externalTrainingDocumentTab.id
          );
        }),
        switchMap(({ count, fieldGroups }) => {
          if (fieldGroups.length === 0) {
            return of({ count: 0, formSubmissions: [] });
          }
          return this.formSubmissionsService.servicesBurecordsFieldGroupsIdFormSubmissionsGet(
            fieldGroups[0].id,
            this.page * this.limit,
            this.limit
          );
        }),
        switchMap(({ count, formSubmissions }) => {
          if (formSubmissions.length === 0) {
            return of([]);
          }

          let userListObservable = formSubmissions.map((submission) => {
            return this.passService.getUser(submission.userId);
          });
          let usersObservable = formSubmissions.map((submission) => {
            return this.usersService.servicesBurecordsUsersIdGet(
              submission.userId
            );
          });
          return zip([
            of(formSubmissions),
            forkJoin(userListObservable),
            forkJoin(usersObservable),
          ]).pipe(
            map(([formSubmissions, usersList, usersImgList]) => {
              return formSubmissions.map((formSubmission, i) => {
                return {
                  ...formSubmission,
                  user: { ...usersList[i], ...usersImgList[i] },
                };
              });
            })
          );
        }),
        tap((result) => {
          this.formSubmissions = result;
          this.loading = false;
        })
      )
      .subscribe();
  }
}
