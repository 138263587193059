import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  UntypedFormBuilder,
  AbstractControl,
  Validators,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Subject, of, firstValueFrom } from "rxjs";
import { delay, catchError } from "rxjs/operators";
import {
  CourseCertificateEmailTemplate,
  CourseCertificateTemplate,
  CoursesService,
} from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-course-certificate-template-edit",
  templateUrl: "./course-certificate-template-edit.component.html",
  styleUrls: ["./course-certificate-template-edit.component.scss"],
})
export class CourseCertificateTemplateEditComponent implements OnInit {
  loading: boolean;

  formGroup: UntypedFormGroup;

  constructor(
    private route: ActivatedRoute,
    private coursesService: CoursesService,
    private formBuilder: UntypedFormBuilder,
    private location: Location
  ) {}

  async ngOnInit() {
    this.loading = true;
    await this.loadCertificateTemplate();

    this.loading = false;
  }

  private unsubscribe$: Subject<void> = new Subject<void>();
  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  async loadCertificateTemplate() {
    let { certificate_profile_uuid: certificateProfileUuid } =
      this.route.snapshot.queryParams;

    // Read or Create template
    let certificateTemplate: CourseCertificateTemplate;
    let certificateEmailTemplate: CourseCertificateEmailTemplate;

    certificateTemplate = await firstValueFrom(
      this.coursesService
        .certificateprofileTemplateRead(certificateProfileUuid)
        .pipe(
          catchError((err) =>
            this.coursesService.certificateprofileTemplateCreate(
              certificateProfileUuid
            )
          )
        )
    );

    certificateEmailTemplate = await firstValueFrom(
      this.coursesService
        .certificateprofileEmailtemplateRead(certificateProfileUuid)
        .pipe(
          catchError((err) =>
            this.coursesService.certificateprofileEmailtemplateCreate(
              certificateProfileUuid,
              {
                isActive: true,
              }
            )
          )
        )
    );

    this.formGroup = this.formBuilder.group({
      certificateProfileUuid: [certificateProfileUuid],

      logoUrl: [certificateTemplate.logoUrl],
      htmlTemplate: [certificateTemplate.htmlTemplate],
      signatoryName: [certificateTemplate.signatoryName],
      signatoryDesignation: [
        certificateTemplate.signatoryDesignation ||
          certificateTemplate.signatoryDelegation, // Include signatory delegation for backwards compatibility
      ],
      signatureUrl: [certificateTemplate.signatureUrl],

      emailTemplateFromEmail: [
        certificateEmailTemplate.fromEmail,
        [Validators.required, Validators.email],
      ],
      emailTemplateSubject: [
        certificateEmailTemplate.subject,
        Validators.required,
      ],
      emailTemplateHtmlContent: [certificateEmailTemplate.htmlContent],
    });
  }

  async htmlFileRead(event, formControl: UntypedFormControl | AbstractControl) {
    this.loading = true;
    let file: File = event.target.files[0];
    event.srcElement.value = null;

    let htmlText = await file.text();

    //Not sure bakit may ganito dito
    await firstValueFrom(of(null).pipe(delay(1000)));

    formControl.patchValue(htmlText);

    this.loading = false;
  }

  async onSave() {
    await firstValueFrom(
      this.coursesService.certificateprofileTemplateUpdate(
        this.formGroup.value.certificateProfileUuid,
        {
          logoUrl: this.formGroup.value.logoUrl,
          htmlTemplate: this.formGroup.value.htmlTemplate,
          signatoryName: this.formGroup.value.signatoryName,
          signatoryDesignation: this.formGroup.value.signatoryDesignation,
          signatureUrl: this.formGroup.value.signatureUrl,
        }
      )
    );

    await firstValueFrom(
      this.coursesService.certificateprofileEmailtemplateUpdate(
        this.formGroup.value.certificateProfileUuid,
        {
          htmlContent: this.formGroup.value.emailTemplateHtmlContent,
          fromEmail: this.formGroup.value.emailTemplateFromEmail,
          subject: this.formGroup.value.emailTemplateSubject,
        }
      )
    );

    this.location.back();
  }
}
