<app-back-title-layout layoutTitle="Certification" [loading]="loading">
  <ng-container *ngIf="!loading">
    <label
      *ngIf="!certificationEnabledFormControl.value"
      class="flex justify-between my-10"
    >
      <div>
        <div class="form-label leading-none">Offer Certification</div>

        <div class="text-sm">
          Grants users certificates after finishing this course
        </div>
      </div>

      <app-checkbox
        [formControl]="certificationEnabledFormControl"
        class="text-3xl leading-none"
        uncheckIcon="toggle_off"
        checkIcon="toggle_on"
        uncheckClass="text-gray-500"
        checkClass="text-primary-dark"
      ></app-checkbox>
    </label>

    <form
      *ngIf="certificationEnabledFormControl.value"
      [@fadeInOut]
      [formGroup]="formGroup"
      (ngSubmit)="save()"
    >
      <div class="my-10 form-width">
        <label class="form-label"> Certification End Message </label>

        <!-- <app-simplemde-editor formControlName="endMessage">
        </app-simplemde-editor> -->

        <app-markdown-viewer-and-editor
          [autoResize]="false"
          editorClass="h-40 overflow-y-scroll"
          viewerClass="h-40 overflow-y-scroll border rounded p-2"
          formControlName="endMessage"
        ></app-markdown-viewer-and-editor>
      </div>

      <div class="my-10 form-width">
        <label class="form-label"> Certificate Layout </label>

        <a
          class="block btn btn-primary w-full py-0 my-1"
          [routerLink]="['./certificate-template', 'edit']"
          [queryParams]="{
            certificate_profile_uuid: formGroup.value.certificateProfileUuid
          }"
          queryParamsHandling="merge"
        >
          EDIT
        </a>
      </div>

      <div class="flex flex-row-reverse">
        <button type="submit" class="btn btn-primary">DONE</button>
      </div>
    </form>
  </ng-container>
</app-back-title-layout>
