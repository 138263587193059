<app-back-title-layout layoutTitle="Group Members - Email List">
  <div class="my-5">
    <label>
      <span class="form-label">Emails</span>
    </label>

    <textarea
      rows="10"
      class="form-control"
      placeholder="Enter/paste emails separated by new line..."
      #list
    ></textarea>
  </div>
  <!-- 
  <div class="my-5">
    <label class="flex">
      <app-checkbox
        class="mx-1"
        [(ngModel)]="willReplaceMembers"
      ></app-checkbox>
      <span class="form-label">Replace Members</span>
    </label>
  </div> -->

  <button class="btn btn-primary w-full" (click)="startAddUsers(list.value)">
    Start
  </button>

  <div class="my-5" *ngIf="taskItems">
    <div class="mb-3 text-xs leading-tight">
      <div>
        Pending :
        <span class="font-bold text-gray-600">
          {{ pendingCount }}
        </span>
      </div>
      <div>
        Success
        <span class="font-bold text-green-600">
          {{ successCount }}
        </span>
      </div>
      <div>
        Failed:
        <span class="font-bold text-red-600">
          {{ failedCount }}
        </span>
      </div>
    </div>

    <app-item-list
      [items]="taskItems"
      [itemTemplate]="itemTemplate"
    ></app-item-list>
  </div>
</app-back-title-layout>

<ng-template #itemTemplate let-item="item">
  <div class="flex">
    <app-icon
      icon="person"
      class="text-gray-600 text-4xl mr-2 leading-none"
    ></app-icon>

    <div>
      <div class="leading-tight truncate font-semibold">
        {{ item.email }}
      </div>

      <!-- <div class="text-sm text-gray-700">{{ item.email }}</div> -->

      <div class="text-xs">
        <ng-container *ngFor="let step of item.task.steps">
          <div class="text-gray-500" *ngIf="step.isPending">
            <app-icon icon="remove"></app-icon>
            {{ step.stepMessage }}
          </div>
          <div class="text-green-500" *ngIf="step.isDone">
            <app-icon icon="check"></app-icon>
            {{ step.successMessage }}
          </div>
          <div class="text-error-dark" *ngIf="step.isError">
            <app-icon icon="close"></app-icon>
            {{ step.errorMessage }}
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>
