<app-back-title-layout>
  <button class="btn btn-primary" [appWaitForObsAfterClick]="createReportObs">
    Generate Report
  </button>

  <div class="my-5">
    <app-item-list [items]="blockStatusReports" [itemTemplate]="itemTemplate">
    </app-item-list>
  </div>
</app-back-title-layout>

<ng-template #itemTemplate let-itemObs="item">
  <div
    class="flex items-center justify-between"
    *ngIf="itemObs | async as item"
  >
    <div>Created at {{ item.created | date : "medium" }}</div>
    <div>
      <app-pill-label type="success" *ngIf="item.status === 'READY'">
        READY
      </app-pill-label>
      <app-pill-label type="error" *ngIf="item.status === 'FAILURE'">
        FAILURE
      </app-pill-label>

      <ng-container *ngIf="item.status === 'PENDING'">
        <app-pill-label> PENDING </app-pill-label>

        <app-icon icon="cached" [spin]="true"></app-icon>
      </ng-container>
    </div>

    <div>
      <a
        *ngIf="item.uri"
        [href]="item.uri"
        target="_blank"
        class="btn bg-transparent text-primary-700"
      >
        <app-icon icon="insert_drive_file"></app-icon>
        Download Report
      </a>
    </div>
  </div>

  <div></div>
</ng-template>
