<app-back-title-layout>
  <div class="my-5">
    <app-search-bar-query> </app-search-bar-query>
  </div>

  <button
    [routerLink]="['./add-group']"
    queryParamsHandling="merge"
    class="btn btn-primary w-full py-1"
  >
    <app-icon icon="add"></app-icon>

    ADD BATCH ADMIN
  </button>

  <div class="my-5">
    <app-item-list
      [source]="roleGroupInfoObservable"
      [itemTemplate]="itemTemplate"
    >
    </app-item-list>
  </div>
</app-back-title-layout>

<ng-template #itemTemplate let-roleGroupInfo="item">
  <a
    class="flex items-center"
    [routerLink]="['edit-group']"
    queryParamsHandling="merge"
    [queryParams]="{
      role_uuid: roleGroupInfo.roleUuid
    }"
  >
    <app-icon icon="group" class="text-gray-600 text-4xl mr-2"></app-icon>

    <div>
      <div class="font-semibold leading-none">
        {{ roleGroupInfo.groupName }}
      </div>

      <div class="text-sm text-primary">
        {{ roleGroupInfo.permissionsAssignedCount }} permission/s assigned
      </div>
    </div>
  </a>
</ng-template>
