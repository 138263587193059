import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { firstValueFrom } from "rxjs";
import { PassService } from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-purchase-select-new",
  templateUrl: "./purchase-select-new.component.html",
  styles: [],
})
export class PurchaseSelectNewComponent implements OnInit {
  constructor(private router: Router, private passService: PassService) {}

  async ngOnInit() {
    let group = await firstValueFrom(
      this.passService.listGroups("Learners")
    ).then((result) => {
      return result.groups[0];
    });

    // Redirect agad. Will use this component kapag may different custom subscription use cases na
    this.router.navigate(["/purchases", "list", "payment-option"], {
      replaceUrl: true,
      queryParams: {
        product_reference_type: "group",
        product_reference_id: group.id,
      },
    });
  }
}
