import { Component, Inject, OnInit } from "@angular/core";
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { first, firstValueFrom } from "rxjs";

import { ModalService } from "src/app/services/modal.service";
import { V1ExamsService } from "src/app/sinigangnababoywithgabi";
interface InputData {
  formGroup: UntypedFormGroup;
  insideSlide?: boolean;
}
@Component({
  selector: "app-burislides-feedback-modal",
  templateUrl: "./burislides-feedback-modal.component.html",
  styles: [],
})
export class BurislidesFeedbackModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<BurislidesFeedbackModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InputData,
    private fb: UntypedFormBuilder,
    private v1ExamsService: V1ExamsService,
    private modalService: ModalService
  ) {}
  loading: boolean = false;

  ngOnInit(): void {}
  async onSave() {
    this.loading = true;
    try {
      if (this.data.formGroup.value.itemUuid) {
        await firstValueFrom(
          this.v1ExamsService.examsSectionsItemsUpdate(
            this.data.formGroup.value.itemUuid,
            { ...this.data.formGroup.value }
          )
        );
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.loading = false;
      this.dialogRef.close({ feedback: this.data.formGroup });
    }
  }
  onUpload(event, formControl: AbstractControl) {
    formControl.setValue(event.uri);
  }

  onUploadClear(formControl: AbstractControl) {
    formControl.setValue(null);
  }
}
