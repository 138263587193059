import { Component, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { firstValueFrom, Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { FilterPipePipe } from "src/app/app-common-module/pipes/filter-pipe.pipe";
import {
  CourseSchedule,
  CoursesService,
  PassService,
} from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-course-batch-permissions",
  templateUrl: "./course-batch-permissions.component.html",
})
export class CourseBatchPermissionsComponent implements OnInit {
  schedule: CourseSchedule;

  rolePermissionFormGroups: UntypedFormGroup[];

  roleGroupInfoObservable: Observable<any>;

  constructor(
    private route: ActivatedRoute,
    private coursesService: CoursesService,
    private passService: PassService
  ) {}

  async ngOnInit() {
    let { schedule_uuid: scheduleUuid } = this.route.snapshot.queryParams;

    this.schedule = await firstValueFrom(
      this.coursesService.scheduleRead(scheduleUuid)
    );

    let filterAll = new FilterPipePipe();
    this.roleGroupInfoObservable = this.passService
      .listRoles(`crud:schedules:${scheduleUuid}`, null, null, ["groups"])
      .pipe(
        map((result) => result.roles),
        map((items) => {
          return items
            .filter((role) => role.groupIds.length)
            .map((role) => ({
              roleUuid: role.id,
              groupUuid: role.groups[0].id,
              groupName: role.groups[0].name,
              permissionsAssignedCount: role.permissionIds.length,
            }));
        }),
        switchMap((items) => {
          return this.route.queryParams.pipe(
            map((params) => {
              return filterAll.transform(items, params["q"]);
            })
          );
        })
      );
  }
}
