import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormArray,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { firstValueFrom } from "rxjs";
import { criteriaWeightSum } from "src/app/app-common-module/form-validators/criteria-weight-sum.validator";
import { minLengthArray } from "src/app/app-common-module/form-validators/min-length-array.validator";
import { NotificationService } from "src/app/services/notification.service";
import {
  V1ExamsService,
  V1ExamChoice,
  ResourcesService,
} from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-buribooks-question-new",
  templateUrl: "./buribooks-question-new.component.html",
  styles: [],
})
export class BuribooksQuestionNewComponent implements OnInit {
  formGroup: UntypedFormGroup;

  loading: boolean = false;
  canUpdate: boolean = false;

  constructor(
    private resourcesService: ResourcesService,
    private v1ExamsService: V1ExamsService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private formBuilder: UntypedFormBuilder,
    private notifService: NotificationService
  ) {}

  async ngOnInit() {
    let {
      item_type: itemType,

      // Flag for disabling/enabling show results checkbox
      disable_show_results: disableShowResults,
    } = this.route.snapshot.queryParams;

    this.formGroup = this.formBuilder.group({
      text: [null, Validators.required],
      title: [""],
      type: [itemType],
      mediaUrl: [null],
      hint: [null],
      instructions: [null],
      explanation: [null],
      required: [null],
      showResults: [
        {
          value: null,
          disabled: disableShowResults,
        },
      ],
      shuffleChoices: [null],
      weight: [null],
      correctFeedbackMessage: [null],
      correctFeedbackImageUrl: [null],
      incorrectFeedbackMessage: [null],
      incorrectFeedbackImageUrl: [null],
      additional: [null],
      isGraded: [null],
      choices: this.constructExamChoicesFormArray(itemType),
      rubrics: this.constructExamRubricsFormArray(itemType),
    });
  }

  constructExamChoicesFormArray(examItemType: string): UntypedFormArray {
    // If multiple choice
    if (
      examItemType == "MC" ||
      examItemType == "IC" ||
      examItemType == "CB" ||
      examItemType == "PO" ||
      examItemType == "TA"
    ) {
      return this.formBuilder.array([], minLengthArray(1));
    } else if (examItemType == "SA") {
      return this.formBuilder.array([
        this.formBuilder.group({
          text: [null, Validators.required],
          choiceUuid: [null],
          imageUri: [null],
          audioUri: [null],
          // Force correctness
          isCorrect: [true],
          isDeleted: [false],
        }),
      ]);
    }
  }

  constructExamRubricsFormArray(itemType: string): UntypedFormArray {
    if (itemType === "ME") {
      return this.formBuilder.array([], criteriaWeightSum());
    }
  }

  async createItem() {
    let {
      exam_section_uuid: sectionUuid,
      resource_uuid: resourceUuid,
      splice_index: spliceIndex,
    } = this.route.snapshot.queryParams;

    let examItems = await firstValueFrom(
      this.v1ExamsService.examsSectionsItemsList(sectionUuid)
    );

    examItems.splice(spliceIndex, 0, {});

    await Promise.all(
      examItems
        .map((item, i) => {
          item.orderId = i;

          return item;
        })
        .filter((item) => item.uuid)
        .map((item) =>
          firstValueFrom(
            this.v1ExamsService.examsSectionsItemsUpdate(item.uuid, item)
          )
        )
    );

    let examItem = await firstValueFrom(
      this.v1ExamsService.examsSectionsItemsCreate(sectionUuid, {
        ...this.formGroup.value,
        orderId: spliceIndex,
      })
    );

    let resource = await firstValueFrom(
      this.resourcesService.getResourceByUuid(resourceUuid)
    );

    let activitySpread = {
      examItemUuid: examItem.uuid,
      spread_type: "activity",
    };
    resource.content["spreads"].splice(spliceIndex, 0, activitySpread);

    await firstValueFrom(
      this.resourcesService.updateResourceByUuid(resource.uuid, resource)
    );

    return examItem;
  }

  async createChoice(itemUuid: string, choice: V1ExamChoice) {
    await firstValueFrom(
      this.v1ExamsService.examsSectionsItemsChoicesCreate(itemUuid, choice)
    );
  }

  // Main mathod for updating the exam item
  async createAll() {
    try {
      this.loading = true;

      let item = await this.createItem();

      await this.createChoices(item.uuid);
      await this.createRubrics(item.uuid);

      return { item };
    } catch (err) {
      throw err;
    } finally {
      this.loading = false;
    }
  }

  async createChoices(itemUuid: string) {
    // Get choices information from the activity-type-poll component
    let choicesArray = this.formGroup.get("choices") as UntypedFormArray;

    let createChoiceControls = choicesArray?.controls?.filter(
      (control) => !control.value.isDeleted
    );

    // Handle choices
    if (createChoiceControls) {
      // For each choice form control
      await Promise.all(
        createChoiceControls.map((control, i) =>
          this.createChoice(itemUuid, {
            longInput: control.value.text || "",
            shortInput: control.value.text || "",
            imageUrl: control.value.imageUri || "",
            audioUrl: control.value.audioUri || "",
            isCorrect: control.value.isCorrect,
            orderId: i,
          })
        )
      );
    }
  }

  async createRubrics(itemUuid: string) {
    // Get choices information from the activity-type-poll component
    let rubricFormArray = this.formGroup.get("rubrics") as UntypedFormArray;

    let createRubicFormControls = rubricFormArray?.controls?.filter(
      (control) => !control.value.isDeleted
    );

    if (createRubicFormControls) {
      await Promise.all(
        createRubicFormControls.map((control, i) =>
          firstValueFrom(
            this.v1ExamsService.examsSectionsItemsCriteriaCreate(itemUuid, {
              name: control.value.name,
              orderId: i,
              weight: control.value.weight / 100,
            })
          )
        )
      );
    }
  }

  async onSave() {
    let {
      // Flag to determine if we publish the resource on next.
      // Used for freedom-wall and file-upload resource types
      publish_resource_on_next: publishResourceOnNext,
      resource_uuid: resourceUuid,
    } = this.route.snapshot.queryParams;

    await this.createAll();

    if (publishResourceOnNext) {
      this.router.navigate(["/resources", "publish"], {
        replaceUrl: true,
        queryParams: {
          resource_uuid: resourceUuid,
        },
        queryParamsHandling: "merge",
      });
    } else {
      this.location.back();
    }

    this.notifService.addSuccess({
      title: "Question added!",
      message: "Question added successfully.",
    });
  }

  async onSaveAndCreateAnother() {
    let { item } = await this.createAll();

    this.formGroup.reset({
      title: "",
      type: this.formGroup.value.type,
    });

    this.formGroup.setControl(
      "choices",
      this.constructExamChoicesFormArray(this.formGroup.value.type)
    );

    this.formGroup.setControl(
      "rubrics",
      this.constructExamRubricsFormArray(this.formGroup.value.type)
    );

    let spliceIndex = parseInt(item.orderId + "") + 1;

    this.router.navigate(["../select-item-type"], {
      relativeTo: this.route,
      queryParams: {
        splice_index: spliceIndex,
      },
      replaceUrl: true,
      queryParamsHandling: "merge",
    });

    this.notifService.addSuccess({
      title: "Question added!",
      message: "Question added successfully.",
    });
  }
}
