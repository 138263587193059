<app-back-title-layout
  layoutTitle="Publish Details"
  [loading]="!resource"
  titleSupportLink="https://support.castlms.com/help/what-are-publish-details"
>
  <form *ngIf="resource" [formGroup]="publishForm">
    <div class="flex flex-col mb-10">
      <label class="flex mb-5">
        <input formControlName="method" class="my-1" type="radio" value="now" />
        <div class="ml-2 text-sm">
          Publish Now

          <div class="text-xs text-gray-600">
            Allow users to see your content immediately.
          </div>
        </div>
      </label>

      <label class="flex mb-5">
        <input
          formControlName="method"
          class="my-1"
          type="radio"
          value="schedule"
        />
        <div class="ml-2 text-sm">
          <div>Publish on Schedule</div>
          <div class="text-xs text-gray-600">
            Schedule when your users will be able to see your resource.
          </div>

          <div
            class="border-2 border-gray-300 w-full p-3 flex flex-col rounded"
            [ngClass]="{
              'pointer-events-none':
                publishForm.controls.method.value !== 'schedule',
              'opacity-25': publishForm.controls.method.value !== 'schedule'
            }"
          >
            <span class="flex flex-col mb-3">
              <span> Start Date </span>
              <input
                class="text-purple-700 mb-1"
                type="date"
                formControlName="date"
              />
              <!-- <input
              class="text-purple-700"
              type="time"
              formControlName="_time"
            /> -->
            </span>
            <!-- <span class="flex flex-col mb-3">
            <span>
              End Date and Time
            </span>
            <input
              class="text-purple-700  mb-1"
              type="date"
              formControlName="endDate"
            />
            <input
              class="text-purple-700"
              type="time"
              formControlName="endTime"
            />
          </span> -->
          </div>
        </div>
      </label>

      <label class="flex">
        <input
          formControlName="method"
          class="my-1"
          type="radio"
          value="hide"
        />
        <div class="ml-2 text-sm">
          Hide

          <div class="text-xs text-gray-600">
            This resource will be hidden from your users
          </div>
        </div>
      </label>
    </div>

    <div class="flex flex-row-reverse">
      <button
        [disabled]="!publishForm.valid"
        (click)="updateResource()"
        type="button"
        [ngClass]="{ 'hover:bg-blue-700': publishForm.valid }"
        class="btn btn-primary"
      >
        Done
      </button>
    </div>
  </form>
</app-back-title-layout>
