import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { fadeInOut } from "src/app/app-common-module/animations/fade";

import {
  Component as ResourceComponent,
  Collection,
  ResourcesService,
} from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-resource-library-selector",
  templateUrl: "./resource-library-selector.component.html",
  animations: [fadeInOut],
})
export class ResourceLibrarySelectorComponent implements OnInit {
  @Input()
  searchString: string;

  @Input()
  source: string;

  @Input()
  allowedResourceTypes: string[];

  @Output()
  select = new EventEmitter<any>();

  @Output()
  selectResource = new EventEmitter<any>();

  @Output()
  selectCollection = new EventEmitter<any>();

  components: ResourceComponent[];

  collection: Collection;

  constructor(private resourcesService: ResourcesService) {}

  ngOnInit(): void {
    this.refreshList();
  }

  async refreshList() {
    this.components = null;
    this.collection = null;

    this.source = this.source || "components";

    this.components = await firstValueFrom(
      this.resourcesService.listCollections()
    );
  }

  async getChildComponents(collectionUuid: string) {
    this.components = await firstValueFrom(
      this.resourcesService.listResources(
        this.allowedResourceTypes?.join(",") || null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        true,
        null,
        collectionUuid
      )
    );
  }

  async allResourcesClick() {
    this.collection = {
      name: "All Resources",
    };

    this.components = null;

    this.components = await firstValueFrom(
      this.resourcesService.listResources(
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        true
      )
    );
  }

  async componentClick(component: ResourceComponent) {
    if (this.source === "components" || this.source === "resources") {
      if (
        component.type === "package" ||
        component.type === "collection" ||
        !component.type
      ) {
        this.components = null;
        this.collection = component;

        this.getChildComponents(component.uuid);
      } else {
        this.selectResource.emit(component);
        this.select.emit(component.uuid);
      }
    } else if (this.source === "collections") {
      this.searchString = "";

      this.select.emit(component.uuid);
      this.selectCollection.emit(component);
    }
  }
}
