import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  debounceTime,
  distinctUntilChanged,
  map,
  Observable,
  Subscription,
  switchMap,
  tap,
} from "rxjs";
import { fadeInOut } from "src/app/app-common-module/animations/fade";
import { MailerService } from "src/app/services/mailer.service";

@Component({
  selector: "app-mailer-history",
  templateUrl: "./mailer-history.component.html",
  styleUrls: ["./mailer-history.component.scss"],
  animations: [fadeInOut],
})
export class MailerHistoryComponent implements OnInit {
  @ViewChild("top") topScroll: ElementRef;
  @ViewChild("bottom") bottomScroll: ElementRef;
  defaultHeaders: any[] = [
    { name: "Email Subject", value: "subject", width: "w-56" },
    { name: "Status", value: "", width: "w-56" },
    { name: "Date Sent", value: "send_on_date", width: "w-56" },
    { name: "Report Summary", value: "", width: "w-96" },
  ];
  loading: boolean = false;
  mailSubscription: Subscription;
  mailHistoryList: Observable<any[]>;
  selected: boolean = false;
  width: number = 0;
  query: any;
  limit: number = 7;
  totalCount: number = 100;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private mailerService: MailerService
  ) {}

  ngOnInit(): void {
    this.mailHistoryList = this.route.queryParams.pipe(
      distinctUntilChanged(),
      debounceTime(500),
      switchMap((params) => {
        this.loading = true;
        let { q, page, order } = params;
        if (!page) page = 0;
        if (!q) q = null;
        if (!order) order = null;
        let _order = order?.split(" ");
        let sortReqs = [];
        if (_order && _order.length === 1) {
          sortReqs.push(order + " asc");
        } else if (_order && _order.length > 1) {
          sortReqs.push(order);
        } else sortReqs = null;
        if (q) {
          return this.mailerService
            .getEmailsBySubject(q, this.limit, page * this.limit, sortReqs)
            .pipe(
              tap(
                (result) =>
                  (this.totalCount = result.data.emailsBySubject.count)
              ),
              map((result) => {
                return result.data.emailsBySubject.data;
              })
            );
        }
        return this.mailerService
          .getEmailRequests(page * this.limit, this.limit, sortReqs)
          .pipe(
            tap(
              (result) => (this.totalCount = result.data.emailRequests.count)
            ),
            map((result) => {
              return result.data.emailRequests.data;
            })
          );
      }),
      map((emails) => {
        return emails.map((email) => {
          let emailSent = Date.now() - Date.parse(email.sendOnDate) > 0;
          let logLength = email.emailLogs.length;

          let _open = 0,
            click = 0,
            _bounce = 0,
            _notSeen = 0,
            openRate = 0,
            clickRate = 0,
            notSeenRate = 0,
            bounceRate = 0;

          email.emailLogs.forEach((log) => {
            if (log.emailStatus === "open") _open++;
            if (log.emailStatus === "bounce") _bounce++;
            if (log.emailStatus === "delivered") _notSeen++;
            if (log.emailStatus === "clicked") click++;
          });
          openRate = (_open / logLength) * 100;
          clickRate = (click / logLength) * 100;
          notSeenRate = (_notSeen / logLength) * 100;
          bounceRate = (_bounce / logLength) * 100;

          return {
            subject: email.subject,
            sendOnDate: email.sendOnDate,
            uuid: email.uuid,
            emailLogs: email.emailLogs,
            status: emailSent ? "sent" : "scheduled",
            open: openRate,
            clicks: clickRate,
            bounce: bounceRate,
            notSeen: notSeenRate,
          };
        });
      }),
      tap((result) => {
        this.loading = false;
      })
    );
  }

  onSelect(mail) {
    if (mail.status === "sent") {
      this.router.navigate(["email/report"], {
        relativeTo: this.route,
        replaceUrl: false,
        queryParamsHandling: "merge",
        queryParams: {
          mail_uuid: mail.uuid,
          status: "sent",
          page: null,
          order: null,
        },
      });
      return;
    }
    this.router.navigate(["email/edit"], {
      relativeTo: this.route,
      replaceUrl: false,
      queryParamsHandling: "merge",
      queryParams: {
        mail_uuid: mail.uuid,
        status: "scheduled",
        page: null,
        order: null,
      },
    });
  }
}
