<p class="text-gray-700 mb-2">{{ message }}</p>

<form>
  <input
    type="text"
    [(ngModel)]="value"
    [ngModelOptions]="{ standalone: true }"
    cdkFocusInitial
    class="form-control"
  />

  <div class="flex justify-between items-center mt-10">
    <button
      class="btn bg-gray-300"
      type="button"
      [mat-dialog-close]="undefined"
    >
      Cancel
    </button>

    <button class="btn btn-primary" type="submit" [mat-dialog-close]="value">
      Ok
    </button>
  </div>
</form>
