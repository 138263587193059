import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-reporting-chart-table-resources",
  templateUrl: "./reporting-chart-table-resources.component.html",
  styles: [],
})
export class ReportingChartTableResourcesComponent implements OnInit {
  @Input() data: {
    name: string;
    value: string | number;
    valueText?: string;
    maxValue?: number;
  }[] = [];

  @Input() icon: string = "";

  colors = ["primary", "secondary", "success", "accent", "blue", "yellow"];

  ngOnInit(): void {}
}
