<div class="mb-5">
  <ng-container *ngIf="resource.content.casticulateUuid">
    <a
      class="block btn btn-primary w-full my-5"
      *ngIf="resource.uuid"
      href="/editors/casticulate-editor?resource_uuid={{
        resource.uuid
      }}&casticulate_uuid={{ resource.content.casticulateUuid }}"
    >
      <app-icon icon="edit"></app-icon>
      EDIT SLIDES
    </a>
  </ng-container>

  <ng-container *ngIf="!resource.content.casticulateUuid">
    <a
      class="block btn btn-primary w-full my-5"
      *ngIf="resource.uuid"
      href="/editors/casticulate-editor/new?resource_uuid={{ resource.uuid }}"
    >
      <app-icon icon="edit"></app-icon>
      EDIT SLIDES
    </a>
  </ng-container>
</div>
