<ul>
  <ng-container *ngFor="let linkList of linkLists">
    <li>
      <a
        [routerLink]="linkList.routerLinkOptions?.routerLink"
        [queryParams]="linkList.routerLinkOptions?.queryParams"
        [queryParamsHandling]="linkList.routerLinkOptions?.queryParamsHandling"
        [preserveFragment]="linkList.routerLinkOptions?.preserveFragment"
        [skipLocationChange]="linkList.routerLinkOptions?.skipLocationChange"
        [replaceUrl]="linkList.routerLinkOptions?.replaceUrl"
        [ngClass]="{
          'pointer-events-none cursor-default': linkList.disabled
        }"
      >
        <section class="pt-3 pb-5 border-b cursor-pointer">
          <header class="font-bold flex justify-between">
            <span
              [ngClass]="{
                'text-primary': !linkList.disabled,
                'text-gray-600': linkList.disabled
              }"
              class=""
            >
              {{ linkList.name }}
            </span>
            <span class="text-2xl leading-none text-purple-500">
              <app-icon icon="chevron_right"></app-icon>
            </span>
          </header>

          <span class="text-sm text-gray-700 leading-tight">
            {{ linkList.description }}
          </span>
        </section>
      </a>
    </li>
  </ng-container>
</ul>
