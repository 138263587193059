import { Location } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Subscription } from "rxjs";
import { fadeInOut } from "src/app/app-common-module/animations/fade";
import { MailerService } from "src/app/services/mailer.service";

@Component({
  selector: "app-cancel-schedule-modal",
  templateUrl: "./cancel-schedule-modal.component.html",
  styles: [],
  animations: [fadeInOut],
})
export class CancelScheduleModalComponent implements OnInit {
  @Input() emailUuid: string;
  loading: boolean = false;
  deleteSub: Subscription;
  constructor(
    private location: Location,
    private mailerService: MailerService
  ) {}
  ngOnInit(): void {}
  onCancel() {
    this.loading = true;
    this.deleteSub?.unsubscribe();
    this.deleteSub = this.mailerService
      .deleteEmailRequest(this.emailUuid)
      .subscribe((result) => {
        this.loading = false;
        this.location.back();
      });
  }
  ngOnDestroy(): void {
    this.deleteSub?.unsubscribe();
  }
}
