import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
export interface Column {
  name: string;
  checked: boolean;
  disabled: boolean;
  value: string;
  width: string;
}
@Component({
  selector: "app-columns",
  templateUrl: "./columns.component.html",
  styleUrls: ["./columns.component.scss"],
})
export class ColumnsComponent implements OnInit {
  @Input() columnHeaders: Column[] = [
    {
      name: "Email",
      checked: true,
      disabled: false,
      value: "email",
      width: "w-96",
    },
    {
      name: "Name",
      checked: true,
      disabled: false,
      value: "first_name",
      width: "w-96",
    },
    {
      name: "Date Added",
      checked: true,
      disabled: false,
      value: "created_at",
      width: "w-56",
    },
    {
      name: "Group",
      checked: true,
      disabled: false,
      value: "group",
      width: "w-96",
    },
    {
      name: "Status",
      checked: true,
      disabled: false,
      value: "",
      width: "w-56",
    },
    {
      name: "Sex",
      checked: false,
      disabled: false,
      value: "gender",
      width: "w-56",
    },
    {
      name: "Birthdate",
      checked: false,
      disabled: false,
      value: "birthdate",
      width: "w-56",
    },
    {
      name: "Place of Birth",
      checked: false,
      disabled: false,
      value: "birthplace",
      width: "w-56",
    },
    {
      name: "Civil Status",
      checked: false,
      disabled: false,
      value: "marital_status",
      width: "w-56",
    },
    {
      name: "Citizenship",
      checked: false,
      disabled: false,
      value: "citizenship",
      width: "w-56",
    },
    {
      name: "Address",
      checked: false,
      disabled: false,
      value: "permanent_address",
      width: "w-56",
    },
    {
      name: "Telephone no.",
      checked: false,
      disabled: false,
      value: "phone_number",
      width: "w-56",
    },
    {
      name: "Cellphone no.",
      checked: false,
      disabled: false,
      value: "mobile_number",
      width: "w-56",
    },
    {
      name: "Company/Agency",
      checked: false,
      disabled: false,
      value: "company_name",
      width: "w-56",
    },
    {
      name: "Company Category",
      checked: false,
      disabled: false,
      value: "company_category",
      width: "w-56",
    },
    {
      name: "Designation",
      checked: false,
      disabled: false,
      value: "company_designation",
      width: "w-56",
    },
  ];
  @Output() headers = new EventEmitter<any>();
  @Output() close = new EventEmitter<any>();
  freezedColumns: string[] = [];
  columns: string[] = [];
  _headers: any[] = [];
  defaultHeaders = ["Email", "Name", "Date Added", "Group", "Status"];

  constructor() {}

  ngOnInit(): void {
    this.freezedColumns.push("Profile Picture + Email");
    let placeholder = [...this.columnHeaders];
    let headerArrayLength = localStorage.getItem("headerArrayLength");
    if (headerArrayLength) {
      this.columns = new Array(parseInt(headerArrayLength));
      this._headers = new Array(parseInt(headerArrayLength));
    } else {
      this.columns = this.defaultHeaders;
      this._headers = [
        {
          name: "Email",
          checked: true,
          disabled: false,
          value: "email",
          width: "w-96",
        },
        {
          name: "Name",
          checked: true,
          disabled: false,
          value: "first_name",
          width: "w-96",
        },
        {
          name: "Date Added",
          checked: true,
          disabled: false,
          value: "created_at",
          width: "w-56",
        },
        {
          name: "Group",
          checked: true,
          disabled: false,
          value: "group",
          width: "w-96",
        },
        {
          name: "Status",
          checked: true,
          disabled: false,
          value: "",
          width: "w-56",
        },
      ];
    }
    placeholder.map((header) => {
      let headerSettings = localStorage.getItem(header.name);

      if (headerSettings) {
        header.checked = JSON.parse(headerSettings).checked;

        header.disabled = JSON.parse(headerSettings).disabled;
        this.columnHeaders.splice(JSON.parse(headerSettings).order, 1, header);
      }
      if (header.checked && JSON.parse(headerSettings)) {
        this.columns.splice(
          JSON.parse(headerSettings).checkedOrder,
          1,
          header.name
        );
        this._headers.splice(JSON.parse(headerSettings).checkedOrder, 1, {
          name: header.name,
          value: header.value,
          width: header.width,
        });
      }
    });

    this.headers.emit({
      columns: this.columns,
      local: true,
      headers: this._headers,
    });
  }

  dropColumn(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  toggleClose() {
    this.close.emit(true);
  }

  save() {
    this.columns = [];
    let headers = [];
    let order = 0;
    let checkedOrder = 0;
    this.columnHeaders.map((header) => {
      localStorage.setItem(
        header.name,
        `{"checked":${header.checked},"disabled":${header.disabled},"order":${order},"checkedOrder":${checkedOrder},"value":"${header.value}","width":"${header.width}"}`
      );
      if (header.checked) {
        this.columns.push(header.name);
        headers.push({
          name: header.name,
          value: header.value,
          width: header.width,
        });
        checkedOrder++;
      }
      order++;
    });
    localStorage.setItem("headerArrayLength", `${checkedOrder}`);
    this.headers.emit({
      columns: this.columns,
      local: false,
      headers: headers,
    });
    this.close.emit(true);
  }
}
