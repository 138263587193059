import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import {
  ActivityLogService,
  ACTIVITY_TYPES,
} from "src/app/services/activity-log.service";

interface LogItem {
  name?: string;
  email?: string;
  timestamp?: string;
  time?: string;
  action?: string;
  itemType?: string;
  item?: string;
  in: string;
}

interface LogListItem {
  date?: string;
  latest?: boolean;
  log?: LogItem[];
}

@Component({
  selector: "app-course-activity-logs",
  templateUrl: "./course-activity-logs.component.html",
})
export class CourseActivityLogsComponent implements OnInit {
  lastEdit: LogItem;

  logListObservable: Observable<LogListItem[]>;

  constructor(
    private activityLogService: ActivityLogService,
    private route: ActivatedRoute
  ) {}

  async ngOnInit() {
    this.loadData();
  }

  async loadData() {
    let { course_uuid: courseUuid } = this.route.snapshot.queryParams;

    this.logListObservable = this.activityLogService
      .aggregate([
        {
          $match: {
            $and: [
              {
                "statement.object.definition.type": {
                  $in: [
                    ACTIVITY_TYPES.courseBlock,
                    ACTIVITY_TYPES.courseSection,
                  ],
                },
              },
              {
                "statement.context.contextActivities.category.id":
                  this.activityLogService.constructCourseObject({
                    uuid: courseUuid,
                  }).id,
              },
            ],
          },
        },
        {
          $project: {
            group: {
              $dateToString: {
                date: "$timestamp",
                format: "%Y-%m-01",
                timezone: "+08:00",
              },
            },
            statement: 1,
            timestamp: "$statement.timestamp",
          },
        },
        {
          $sort: {
            "statement.timestamp": -1,
          },
        },
        {
          $limit: 100,
        },
        {
          $group: {
            _id: "$group",
            statements: {
              $push: "$statement",
            },
          },
        },
        {
          $sort: {
            _id: -1,
          },
        },
      ])
      .pipe(
        map((result) => {
          console.log(result);

          let logListItems: LogListItem[] = result.map((item) => {
            let logListItem: LogListItem = {
              date: item._id,
              latest: false,
              log: item.statements.map((statement) =>
                this.activityLogService.constructLogItemFromStatement(statement)
              ),
            };

            return logListItem;
          });

          this.lastEdit = logListItems[0]?.log[0];

          return logListItems;
        })
      );
  }
}
