<div
  [style.backgroundColor]="
    v2Block?.data.attributes.properties.appearance?.base?.backgroundColor
      | castColor
  "
>
  <div class="flex justify-between pb-4 pt-2">
    <div class="flex items-start font-bold">
      <div class="catalog-handle" cdkDragHandle>
        <button>
          <app-icon icon="unfold_more" class="text-gray-600"></app-icon>
        </button>
      </div>

      <div
        class="line-clamp-1"
        *ngIf="!isInEditMode; else editName"
        [style.color]="
          v2Block?.data.attributes.properties.appearance?.base?.foregroundColor
            | castColor
        "
        [style.fontFamily]="v2Block?.data.attributes.properties.appearance?.base?.fontFamily?.[0]"
      >
        {{ categoryNameLabel }}
      </div>
    </div>

    <div class="mx-1">
      <app-casteditor-edit-toggle
        [ngClass]="{
          invisible: !canEdit || disableEdit
        }"
        (editToggle)="onEditToggle($event)"
        (deleteBlock)="onDeleteBlock()"
      >
      </app-casteditor-edit-toggle>
    </div>
  </div>

  <div class="h-48 p-5" *ngIf="isLoading || errorMessage; else catalogLoaded">
    <div
      class="w-full h-full flex flex-col justify-center items-center bg-purple-200 hover:bg-purple-300 rounded-lg text-lg text-purple-400 hover:text-purple-500 font-bold"
    >
      <span class="text-center text-red-400" *ngIf="errorMessage">{{
        errorMessage
      }}</span>

      <app-loading
        *ngIf="isLoading"
        [loading]="true"
        position="relative"
      ></app-loading>
    </div>
  </div>
</div>

<ng-template #catalogLoaded>
  <div class="h-48 p-5" *ngIf="!collectionUuids.length">
    <button
      class="w-full h-full flex flex-col justify-center items-center bg-purple-200 hover:bg-purple-300 rounded-lg text-lg text-purple-400 hover:text-purple-500 font-bold"
      [disabled]="!isInEditMode"
      (click)="onAddCatalog()"
    >
      <div>
        <span> Nothing here yet. </span>
        <span *ngIf="isInEditMode">Add a catalog!</span>
      </div>

      <div class="text-3xl" *ngIf="isInEditMode">
        <app-icon icon="add_circle"></app-icon>
      </div>
    </button>
  </div>

  <div
    *ngIf="collectionUuids.length > 0"
    class="flex-grow flex rounded py-2 ml-1"
  >
    <button
      *ngIf="isInEditMode"
      [@zoomInOut]
      class="flex-none w-1/3 bg-secondary-100 rounded p-4"
      (click)="onAddCatalog()"
    >
      <app-icon
        class="rounded text-4xl text-secondary-500"
        icon="add_circle"
      ></app-icon>
      <div class="uppercase font-bold text-base text-secondary-500">
        add catalog
      </div>
    </button>

    <!-- list of collections  -->
    <div cdkDropListGroup class="w-full" *ngIf="isInEditMode; else preview">
      <div class="grid grid-cols-1 h-full w-full">
        <div
          cdkDropList
          [cdkDropListData]="collections"
          (cdkDropListDropped)="drop($event)"
        >
          <ng-container *ngFor="let collection of collections">
            <div cdkDrag class="my-2">
              <app-casteditor-catalog-card
                [collection]="collection"
                [isInEditMode]="isInEditMode"
                (deleteCatalog)="onDeleteCatalog(collection.uuid)"
              >
              </app-casteditor-catalog-card>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #editName>
  <input
    type="text"
    id="categoryName"
    name="categoryName"
    [(ngModel)]="categoryNameLabel"
    class="edit-name"
  />
</ng-template>
<ng-template #preview>
  <div class="grid grid-cols-2 gap-2 h-full w-full">
    <ng-container *ngFor="let collection of collections">
      <div cdkDrag class="my-2">
        <app-casteditor-catalog-card
          [collection]="collection"
          [isInEditMode]="isInEditMode"
          (deleteCatalog)="onDeleteCatalog(collection.uuid)"
        >
        </app-casteditor-catalog-card>
      </div>
    </ng-container>
  </div>
</ng-template>
