<div mat-dialog-content class="flex flex-wrap text-primary">
  <ng-container *ngFor="let icon of icons">
    <button
      [mat-dialog-close]="icon"
      class="p-1 m-1 w-20 h-20 text-4xl border rounded"
      [title]="icon.name"
    >
      <app-icon [icon]="icon.sets.material"></app-icon>
    </button>
  </ng-container>

  <button
    [mat-dialog-close]="{
      material: null,
      sfSymbols: null
    }"
    class="p-1 m-1 w-20 h-20 text-xs border rounded"
    title="Remove Icon"
  >
    No &nbsp; Icon
  </button>
</div>
