import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { LinkList } from "src/app/app-common-module/layouts/profile-link-list/profile-link-list.component";
import { Collection, ResourcesService } from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-collection-profile",
  templateUrl: "./collection-profile.component.html",
  styleUrls: ["./collection-profile.component.scss"],
})
export class CollectionProfileComponent implements OnInit {
  collection: Collection;

  linkLists: LinkList[];

  constructor(
    private route: ActivatedRoute,
    private resourcesService: ResourcesService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((result) => {
      this.resourcesService
        .getCollectionByUuid(result["collection_uuid"])
        .subscribe((collection) => {
          this.collection = collection;

          this.setUplinkList();
        });
    });
  }

  setUplinkList() {
    this.linkLists = [
      {
        icon: "folder",
        name: "Collection Details",
        routerLinkOptions: {
          routerLink: ["../edit"],
          queryParamsHandling: "merge",
        },
      },
      {
        icon: "folder",
        name: "Parent Collections",
        description: `Set parent collection/s`,
        routerLinkOptions: {
          routerLink: ["../parents-edit"],
          queryParamsHandling: "merge",
        },
      },
      {
        icon: "policy",
        name: "Permissions",
        routerLinkOptions: {
          routerLink: ["../permissions"],
          queryParamsHandling: "merge",
        },
      },
    ];
  }
}
