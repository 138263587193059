import { Component, OnInit } from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { CsvService } from "src/app/services/csv.service";
import { PassService } from "src/app/sinigangnababoywithgabi";
import { Location } from "@angular/common";
import camelCase from "camelcase";
@Component({
  selector: "app-general-settings-add-field",
  templateUrl: "./general-settings-add-field.component.html",
  styles: [],
})
export class GeneralSettingsAddFieldComponent implements OnInit {
  form: UntypedFormGroup;
  hideClass = [];
  hidePublicClass = [];
  hideAdminClass = [];
  identitySchema: any = {
    $id: `https://castlms.com/schemas/user-00.schema.json`,
    $schema: "https://json-schema.org/draft/2020-12/schema",
    title: "Cast User",
    type: "object",
    properties: {
      traits: {
        type: "object",
        properties: {
          email: {
            type: "string",
            format: "email",
            title: "Email",
            "ory.sh/kratos": {
              credentials: {
                password: {
                  identifier: true,
                },
              },
              recovery: {
                via: "email",
              },
              verification: {
                via: "email",
              },
            },
          },
          given_name: {
            type: "string",
            title: "Given Name",
          },
          family_name: {
            type: "string",
            title: "Family Name",
          },
        },
        required: ["email", "given_name", "family_name"],
        sort: ["email", "given_name", "family_name"],
        additional_properties: false,
      },
      metadata_public: {
        type: "object",
        required: [],
        sort: [],
        properties: {},
      },
      metadata_admin: {
        type: "object",
        required: [],
        sort: [],
        properties: {},
      },
    },
  };

  defaultFields = [
    { name: "Nickname", value: "nickname" },
    { name: "Gender", value: "gender" },
    { name: "Birthdate", value: "birthdate" },
    { name: "Phone Number", value: "phone_number" },
    { name: "Address", value: "address" },
  ];

  fieldTypes = [
    { name: "String", value: "string" },
    { name: "Number", value: "number" },
    { name: "Boolean", value: "boolean" },
  ];
  constructor(
    private passService: PassService,
    private fb: UntypedFormBuilder,
    private csvService: CsvService,
    private _location: Location
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      fields: this.fb.array([], Validators.required),
      publicMetadata: this.fb.array([]),
      adminMetadata: this.fb.array([]),
    });
    this.passService
      .getApplication(localStorage.getItem("application_id"))
      .subscribe((data) => {
        let arrOfFields = [];
        let requiredFields = [];
        let publicMetadataFields = [];
        let adminMetadataFields = [];
        if (data.identitySchema) {
          this.identitySchema = data.identitySchema;
        }
        arrOfFields = this.identitySchema.properties.traits.sort;
        requiredFields = this.identitySchema.properties.traits.required;
        publicMetadataFields =
          this.identitySchema.properties?.metadataPublic?.sort;
        adminMetadataFields =
          this.identitySchema.properties?.metadataAdmin?.sort;
        //Setting up traits field
        arrOfFields.forEach((field) => {
          let required = false;
          let _defaultField = null;
          let _customField =
            this.identitySchema.properties?.traits.properties[camelCase(field)]
              ?.title;

          requiredFields.forEach((requiredField) => {
            if (field === requiredField) required = true;
          });
          this.defaultFields.forEach((defaultField) => {
            if (defaultField.value === field) {
              _defaultField = field;
              _customField = null;
            }
          });
          let _field = _defaultField ? _defaultField : _customField;
          let fieldType =
            this.identitySchema.properties.traits.properties[
              camelCase(this.toSnakeCase(_field))
            ]?.type;
          let dropdownList =
            this.identitySchema.properties.traits.properties[
              camelCase(this.toSnakeCase(_field))
            ]?.enum;
          let dropdownArray = this.fb.array([]);
          if (dropdownList)
            dropdownList.forEach((option) => {
              dropdownArray.push(
                this.fb.group({
                  name: option,
                })
              );
            });

          this.fields.push(
            this.fb.group({
              defaultField: [_defaultField],
              customField: [_customField],
              fieldType: [fieldType],
              required: [required],
              restrictValues: [!!dropdownList],
              dropdownFields: dropdownArray,
            })
          );

          if (_customField) {
            this.hideClass.push({ custom: "", default: "hidden" });
          } else if (_defaultField) {
            this.hideClass.push({ custom: "hidden", default: "" });
          }
        });
        //Setting up public metadata fields
        if (publicMetadataFields) {
          publicMetadataFields.forEach((field) => {
            let required = false;
            let _defaultField = null;
            let _customField =
              this.identitySchema.properties?.metadataPublic?.properties[
                camelCase(field)
              ]?.title;

            requiredFields.forEach((requiredField) => {
              if (field === requiredField) required = true;
            });
            this.defaultFields.forEach((defaultField) => {
              if (defaultField.value === field) {
                _defaultField = field;
                _customField = null;
              }
            });
            let _field = _defaultField ? _defaultField : _customField;
            let fieldType =
              this.identitySchema.properties.metadataPublic.properties[
                camelCase(this.toSnakeCase(_field))
              ]?.type;
            let dropdownList =
              this.identitySchema.properties.metadataPublic.properties[
                camelCase(this.toSnakeCase(_field))
              ]?.enum;
            let dropdownArray = this.fb.array([]);

            if (dropdownList)
              dropdownList.forEach((option) => {
                dropdownArray.push(
                  this.fb.group({
                    name: option,
                  })
                );
              });
            this.publicMetadata.push(
              this.fb.group({
                defaultField: [_defaultField],
                customField: [_customField],
                fieldType: [fieldType],
                required: [required],
                restrictValues: [!!dropdownList],
                dropdownFields: dropdownArray,
              })
            );
            if (_customField) {
              this.hidePublicClass.push({ custom: "", default: "hidden" });
            } else if (_defaultField) {
              this.hidePublicClass.push({ custom: "hidden", default: "" });
            }
          });
        }
        //Setting up admin metadata fields
        if (adminMetadataFields) {
          adminMetadataFields.forEach((field) => {
            let required = false;
            let _defaultField = null;
            let _customField =
              this.identitySchema.properties?.metadataAdmin?.properties[
                camelCase(field)
              ]?.title;

            requiredFields.forEach((requiredField) => {
              if (field === requiredField) required = true;
            });
            this.defaultFields.forEach((defaultField) => {
              if (defaultField.value === field) {
                _defaultField = field;
                _customField = null;
              }
            });
            let _field = _defaultField ? _defaultField : _customField;
            let fieldType =
              this.identitySchema.properties.metadataAdmin.properties[
                camelCase(this.toSnakeCase(_field))
              ]?.type;
            let dropdownList =
              this.identitySchema.properties.metadataAdmin.properties[
                camelCase(this.toSnakeCase(_field))
              ]?.enum;

            let dropdownArray = this.fb.array([]);
            if (dropdownList)
              dropdownList.forEach((option) => {
                dropdownArray.push(
                  this.fb.group({
                    name: option,
                  })
                );
              });
            this.adminMetadata.push(
              this.fb.group({
                defaultField: [_defaultField],
                customField: [_customField],
                fieldType: [fieldType],
                required: [required],
                restrictValues: [!!dropdownList],
                dropdownFields: dropdownArray,
              })
            );
            if (_customField) {
              this.hideAdminClass.push({ custom: "", default: "hidden" });
            } else if (_defaultField) {
              this.hideAdminClass.push({ custom: "hidden", default: "" });
            }
          });
        }
      });
  }
  createField() {
    return this.fb.group({
      defaultField: [null],
      customField: [null],
      fieldType: ["string"],
      required: [null],
      restrictValues: [false],
      dropdownFields: this.fb.array([]),
    });
  }

  createOptionField(form, value) {
    let options = form.controls.dropdownFields.controls;
    options.push(this.fb.group({ name: value }));
  }
  onEnterNewOption(event: KeyboardEvent, form) {
    event.stopPropagation();
    event.preventDefault();
    let value = event.target["value"];
    event.target["value"] = "";

    if (value !== "") {
      //add option here
      this.createOptionField(form, value);
    }
  }
  removeOption(form, index) {
    form.controls.dropdownFields.removeAt(index);
  }
  getDropdownFields(form) {
    return form.controls.dropdownFields.controls;
  }
  get fields() {
    return <UntypedFormArray>this.form.get("fields");
  }
  get publicMetadata() {
    return <UntypedFormArray>this.form.get("publicMetadata");
  }
  get adminMetadata() {
    return <UntypedFormArray>this.form.get("adminMetadata");
  }

  addField(index, fieldGroup) {
    if (fieldGroup === "traits") {
      this.fields.insert(index + 1, this.createField());
      this.hideClass.splice(index + 1, 0, { custom: "", default: "hidden" });
    } else if (fieldGroup === "public") {
      this.publicMetadata.insert(index + 1, this.createField());
      this.hidePublicClass.splice(index + 1, 0, {
        custom: "",
        default: "hidden",
      });
    } else if (fieldGroup === "admin") {
      this.adminMetadata.insert(index + 1, this.createField());
      this.hideAdminClass.splice(index + 1, 0, {
        custom: "",
        default: "hidden",
      });
    }
  }

  removeField(index, fieldGroup) {
    if (fieldGroup === "traits") {
      this.fields.removeAt(index);
      this.hideClass.splice(index, 1);
    } else if (fieldGroup === "public") {
      this.publicMetadata.removeAt(index);
      this.hidePublicClass.splice(index, 1);
    } else if (fieldGroup === "admin") {
      this.adminMetadata.removeAt(index);
      this.hideAdminClass.splice(index, 1);
    }
  }

  onSave() {
    this.identitySchema.properties.traits.properties = {};
    this.identitySchema.properties.traits.sort = [];
    this.identitySchema.properties.metadata_public = {
      properties: {},
      sort: [],
      type: "object",
      additional_properties: false,
    };
    this.identitySchema.properties.metadata_admin = {
      properties: {},
      sort: [],
      type: "object",
      additional_properties: false,
    };

    let required = [];
    let publicMetadataRequired = [];
    let adminMetadataRequired = [];

    //Traits save
    for (let control of this.fields.controls) {
      let customField = control.get("customField").value;
      let defaultField = control.get("defaultField").value;
      let _required = control.get("required").value;
      let fieldType = control.get("fieldType").value;
      let restrictValues = control.get("restrictValues").value;
      let dropdownFields = control.get("dropdownFields").value;

      if (customField) {
        this.identitySchema.properties.traits.sort.push(
          this.toSnakeCase(customField)
        );
        if (_required) {
          required.push(this.toSnakeCase(customField));
        }
        if (this.toSnakeCase(customField) === "email") {
          this.identitySchema.properties.traits.properties[
            this.toSnakeCase(customField)
          ] = {
            type: fieldType,
            format: "email",
            title: customField,
            "ory.sh/kratos": {
              credentials: {
                password: {
                  identifier: true,
                },
              },
              recovery: {
                via: "email",
              },
              verification: {
                via: "email",
              },
            },
          };
        } else if (
          this.toSnakeCase(customField) === "username" ||
          this.toSnakeCase(customField) === "phone_number"
        ) {
          this.identitySchema.properties.traits.properties[
            this.toSnakeCase(customField)
          ] = {
            type: fieldType,
            title: customField,
            "ory.sh/kratos": {
              credentials: {
                password: {
                  identifier: true,
                },
              },
            },
          };
        } else if (restrictValues && dropdownFields) {
          this.identitySchema.properties.traits.properties[
            this.toSnakeCase(customField)
          ] = {
            type: fieldType,
            title: customField,
            enum: dropdownFields.map((field) => {
              return field.name;
            }),
          };
        } else {
          this.identitySchema.properties.traits.properties[
            this.toSnakeCase(customField)
          ] = {
            type: fieldType,
            title: customField,
          };
        }
      } else if (defaultField) {
        this.identitySchema.properties.traits.sort.push(defaultField);
        if (_required) {
          required.push(this.toSnakeCase(defaultField));
        }
        if (this.toSnakeCase(defaultField) === "email") {
          this.identitySchema.properties.traits.properties[
            this.toSnakeCase(defaultField)
          ] = {
            type: fieldType,
            format: "email",
            title: this.toProperCase(defaultField),
            "ory.sh/kratos": {
              credentials: {
                password: {
                  identifier: true,
                },
              },
              recovery: {
                via: "email",
              },
              verification: {
                via: "email",
              },
            },
          };
        } else if (
          this.toSnakeCase(defaultField) === "username" ||
          this.toSnakeCase(defaultField) === "phone_number"
        ) {
          this.identitySchema.properties.traits.properties[
            this.toSnakeCase(defaultField)
          ] = {
            type: fieldType,
            title: this.toProperCase(defaultField),
            "ory.sh/kratos": {
              credentials: {
                password: {
                  identifier: true,
                },
              },
            },
          };
        } else if (restrictValues && dropdownFields) {
          this.identitySchema.properties.traits.properties[
            this.toSnakeCase(defaultField)
          ] = {
            type: fieldType,
            title: this.toProperCase(defaultField),
            enum: dropdownFields.map((field) => {
              return field.name;
            }),
          };
        } else {
          this.identitySchema.properties.traits.properties[
            this.toSnakeCase(defaultField)
          ] = {
            type: fieldType,
            title: this.toProperCase(defaultField),
          };
        }
      }
    }

    //Public Metadata Save
    for (let control of this.publicMetadata.controls) {
      let customField = control.get("customField").value;
      let defaultField = control.get("defaultField").value;
      let _required = control.get("required").value;
      let fieldType = control.get("fieldType").value;
      let restrictValues = control.get("restrictValues").value;

      let dropdownFields = control.get("dropdownFields").value;

      if (customField) {
        this.identitySchema.properties.metadata_public.sort.push(
          this.toSnakeCase(customField)
        );
        if (_required) {
          publicMetadataRequired.push(this.toSnakeCase(customField));
        }
        if (this.toSnakeCase(customField) === "email") {
          this.identitySchema.properties.metadata_public.properties[
            this.toSnakeCase(customField)
          ] = {
            type: fieldType,
            format: "email",
            title: customField,
            "ory.sh/kratos": {
              credentials: {
                password: {
                  identifier: true,
                },
              },
              recovery: {
                via: "email",
              },
              verification: {
                via: "email",
              },
            },
          };
        } else if (
          this.toSnakeCase(customField) === "username" ||
          this.toSnakeCase(customField) === "phone_number"
        ) {
          this.identitySchema.properties.metadata_public.properties[
            this.toSnakeCase(customField)
          ] = {
            type: fieldType,
            title: customField,
            "ory.sh/kratos": {
              credentials: {
                password: {
                  identifier: true,
                },
              },
            },
          };
        } else if (restrictValues && dropdownFields) {
          this.identitySchema.properties.metadata_public.properties[
            this.toSnakeCase(customField)
          ] = {
            type: fieldType,
            title: customField,
            enum: dropdownFields.map((field) => {
              return field.name;
            }),
          };
        } else {
          this.identitySchema.properties.metadata_public.properties[
            this.toSnakeCase(customField)
          ] = {
            type: fieldType,
            title: customField,
          };
        }
      } else if (defaultField) {
        this.identitySchema.properties.metadata_public.sort.push(defaultField);
        if (_required) {
          required.push(this.toSnakeCase(defaultField));
        }
        if (this.toSnakeCase(defaultField) === "email") {
          this.identitySchema.properties.metadata_public.properties[
            this.toSnakeCase(defaultField)
          ] = {
            type: fieldType,
            format: "email",
            title: this.toProperCase(defaultField),
            "ory.sh/kratos": {
              credentials: {
                password: {
                  identifier: true,
                },
              },
              recovery: {
                via: "email",
              },
              verification: {
                via: "email",
              },
            },
          };
        } else if (
          this.toSnakeCase(defaultField) === "username" ||
          this.toSnakeCase(defaultField) === "phone_number"
        ) {
          this.identitySchema.properties.metadata_public.properties[
            this.toSnakeCase(defaultField)
          ] = {
            type: fieldType,
            title: this.toProperCase(defaultField),
            "ory.sh/kratos": {
              credentials: {
                password: {
                  identifier: true,
                },
              },
            },
          };
        } else if (restrictValues && dropdownFields) {
          this.identitySchema.properties.metadata_public.properties[
            this.toSnakeCase(defaultField)
          ] = {
            type: fieldType,
            title: this.toProperCase(defaultField),
            enum: dropdownFields.map((field) => {
              return field.name;
            }),
          };
        } else {
          this.identitySchema.properties.metadata_public.properties[
            this.toSnakeCase(defaultField)
          ] = {
            type: fieldType,
            title: this.toProperCase(defaultField),
          };
        }
      }
    }

    //Admin Metadata Save

    for (let control of this.adminMetadata.controls) {
      let customField = control.get("customField").value;
      let defaultField = control.get("defaultField").value;
      let _required = control.get("required").value;
      let fieldType = control.get("fieldType").value;
      let restrictValues = control.get("restrictValues").value;
      let dropdownFields = control.get("dropdownFields").value;

      if (customField) {
        this.identitySchema.properties.metadata_admin.sort.push(
          this.toSnakeCase(customField)
        );
        if (_required) {
          adminMetadataRequired.push(this.toSnakeCase(customField));
        }
        if (this.toSnakeCase(customField) === "email") {
          this.identitySchema.properties.metadata_admin.properties[
            this.toSnakeCase(customField)
          ] = {
            type: fieldType,
            format: "email",
            title: customField,
            "ory.sh/kratos": {
              credentials: {
                password: {
                  identifier: true,
                },
              },
              recovery: {
                via: "email",
              },
              verification: {
                via: "email",
              },
            },
          };
        } else if (
          this.toSnakeCase(customField) === "username" ||
          this.toSnakeCase(customField) === "phone_number"
        ) {
          this.identitySchema.properties.metadata_admin.properties[
            this.toSnakeCase(customField)
          ] = {
            type: fieldType,
            title: customField,
            "ory.sh/kratos": {
              credentials: {
                password: {
                  identifier: true,
                },
              },
            },
          };
        } else if (restrictValues && dropdownFields) {
          this.identitySchema.properties.metadata_admin.properties[
            this.toSnakeCase(customField)
          ] = {
            type: fieldType,
            title: customField,
            enum: dropdownFields.map((field) => {
              return field.name;
            }),
          };
        } else {
          this.identitySchema.properties.metadata_admin.properties[
            this.toSnakeCase(customField)
          ] = {
            type: fieldType,
            title: customField,
          };
        }
      } else if (defaultField) {
        this.identitySchema.properties.metadata_admin.sort.push(defaultField);
        if (_required) {
          required.push(this.toSnakeCase(defaultField));
        }
        if (this.toSnakeCase(defaultField) === "email") {
          this.identitySchema.properties.metadata_admin.properties[
            this.toSnakeCase(defaultField)
          ] = {
            type: fieldType,
            format: "email",
            title: this.toProperCase(defaultField),
            "ory.sh/kratos": {
              credentials: {
                password: {
                  identifier: true,
                },
              },
              recovery: {
                via: "email",
              },
              verification: {
                via: "email",
              },
            },
          };
        } else if (
          this.toSnakeCase(defaultField) === "username" ||
          this.toSnakeCase(defaultField) === "phone_number"
        ) {
          this.identitySchema.properties.metadata_admin.properties[
            this.toSnakeCase(defaultField)
          ] = {
            type: fieldType,
            title: this.toProperCase(defaultField),
            "ory.sh/kratos": {
              credentials: {
                password: {
                  identifier: true,
                },
              },
            },
          };
        } else if (restrictValues && dropdownFields) {
          this.identitySchema.properties.metadata_admin.properties[
            this.toSnakeCase(defaultField)
          ] = {
            type: fieldType,
            title: this.toProperCase(defaultField),
            enum: dropdownFields.map((field) => {
              return field.name;
            }),
          };
        } else {
          this.identitySchema.properties.metadata_admin.properties[
            this.toSnakeCase(defaultField)
          ] = {
            type: fieldType,
            title: this.toProperCase(defaultField),
          };
        }
      }
    }
    const clientName =
      window.location.hostname.split(".")[0] === "localhost"
        ? "bokchoy"
        : window.location.hostname.split(".")[0];
    const schemaVersion = parseInt(
      this.identitySchema.$id.split("-")[1].split(".")[0]
    );
    const schemaDescription =
      clientName.charAt(0).toUpperCase() + clientName.slice(1);

    this.identitySchema.$id = `https://${clientName}.castlms.com/schemas/user-${
      schemaVersion + 1 <= 9 ? "0" + (schemaVersion + 1) : schemaVersion + 1
    }.schema.json`;
    this.identitySchema.title = `${schemaDescription} User`;
    this.identitySchema.properties.traits.required = required;
    this.identitySchema.properties.metadata_public.required =
      publicMetadataRequired;
    this.identitySchema.properties.metadata_admin.required =
      adminMetadataRequired;
    this.passService
      .updateApplication(localStorage.getItem("application_id"), {
        identitySchema: this.identitySchema,
      })
      .subscribe(() => this._location.back());
  }

  async exportCsv() {
    let data = [{ email: "", first_name: "", last_name: "", traits: {} }];
    let csvData = ["email", "first_name", "last_name", "traits"];
    for (let control of this.fields.controls) {
      if (control.get("customField").value) {
        data[0].traits[this.toSnakeCase(control.get("customField").value)] = "";
      } else {
        data[0].traits[this.toSnakeCase(control.get("defaultField").value)] =
          "";
      }
    }
    data[0].traits = JSON.stringify(data[0].traits);
    await this.csvService.downloadAsCsv(
      data,
      "profile" + Date.now() + ".csv",
      csvData
    );
  }
  snakeToCamel(input: string) {
    return input
      .toLowerCase()
      .replace(/([-_][a-z0-9])/g, (group) =>
        group.toUpperCase().replace("_", "")
      );
  }

  toSnakeCase(input: string) {
    let _input = input.toLowerCase();
    return _input
      .replace(/[^A-Za-z0-9_ ]/g, "")
      .split("")
      .map((character) => {
        if (character === " ") {
          return "_";
        } else {
          return character;
        }
      })
      .join("");
  }

  toProperCase(input: string) {
    let _input = input.toLowerCase();
    return _input
      .split("")
      .map((character) => {
        if (character === "_") {
          return " ";
        } else {
          return character;
        }
      })
      .join("")
      .split(" ")
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.substring(1);
      })
      .join(" ");
  }
  setCustomClass(index, fieldGroup) {
    if (fieldGroup === "traits") {
      this.hideClass[index] = { custom: "", default: "hidden" };
    } else if (fieldGroup === "public") {
      this.hidePublicClass[index] = { custom: "", default: "hidden" };
    } else if (fieldGroup === "admin") {
      this.hideAdminClass[index] = { custom: "", default: "hidden" };
    }
  }

  setDefaultClass(index, fieldGroup) {
    if (fieldGroup === "traits") {
      this.hideClass[index] = { custom: "hidden", default: "" };
    } else if (fieldGroup === "public") {
      this.hidePublicClass[index] = { custom: "hidden", default: "" };
    } else if (fieldGroup === "admin") {
      this.hideAdminClass[index] = { custom: "hidden", default: "" };
    }
  }

  clearAll() {
    this.fields.clear();
    this.hideClass = [];
  }
}
