<app-back-title-layout layoutTitle="Courses">
  <div class="my-5">
    <app-search-bar-query placeholder="Search"> </app-search-bar-query>
  </div>

  <!-- <div class="my-3 w-full">
    <button
      [routerLink]="['new']"
      class="text-center bg-primary text-white font-bold py-2 px-4 rounded w-full"
    >
      Create Course
    </button>
  </div> -->

  <app-item-list
    [source]="listResourcesObservable"
    [itemTemplate]="itemTemplate"
  ></app-item-list>
</app-back-title-layout>

<ng-template #itemTemplate let-course="item">
  <a
    [routerLink]="['/_courses', 'profile']"
    [queryParams]="{
      resource_uuid: course.uuid,
      status: 'ongoing',
      page: null
    }"
    class="block"
  >
    <img
      [src]="course.coverUri"
      alt=""
      class="w-full h-40 object-contain bg-gray-300"
    />

    <div class="font-bold">
      {{ course.name }}
    </div>

    <div class="text-gray-500 text-xs">
      Created {{ course.createdAt | date }}
    </div>
  </a>
</ng-template>
