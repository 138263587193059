import { Component, OnInit, Input } from "@angular/core";
import {
  Resource,
  V1ExamItem,
  V1ExamsService,
} from "src/app/sinigangnababoywithgabi";
import { FormGroup, FormBuilder } from "@angular/forms";
import { firstValueFrom } from "rxjs";

@Component({
  selector: "app-resource-type-upload-assessment-freedom-wall",
  templateUrl: "./resource-type-upload-assessment-freedom-wall.component.html",
})
export class ResourceTypeUploadAssessmentFreedomWallComponent
  implements OnInit
{
  @Input()
  resource: Resource;

  @Input()
  formGroup: FormGroup;

  examUuid: string;
  item: V1ExamItem;

  constructor(
    private examsService: V1ExamsService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.examUuid = this.resource.content["examDetails"]?.["examUuid"];
    let examTimeslotUuid =
      this.resource.content["examDetails"]?.["examTimeslotUuid"];

    // Apprently, formgroup is enabled if we add new controls
    if (this.formGroup.enabled) {
      this.formGroup.addControl(
        "content",
        this.formBuilder.group({
          examDetails: this.formBuilder.group({
            examUuid: [this.examUuid],
            examTimeslotUuid: [examTimeslotUuid],
          }),
        })
      );

      if (this.resource?.uuid) {
        this.getItem();
      }
    }
  }

  async getItem() {
    let sections = await firstValueFrom(
      this.examsService.examsSectionsList(this.examUuid)
    );

    let section = sections[0];

    let items = await firstValueFrom(
      this.examsService.examsSectionsItemsList(section.uuid)
    );

    this.item = items[0];
  }
}
