import { Component, Input, OnInit, forwardRef } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "app-date-time",
  templateUrl: "./date-time.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimeComponent),
      multi: true,
    },
  ],
})
export class DateTimeComponent implements OnInit {
  @Input()
  value: string | Date;

  dateValue: string;
  timeValue: string;

  @Input()
  disabled: boolean;

  propagateChange = (_: any) => {};

  constructor() {}

  writeValue(value): void {
    this.value = value;

    if (this.value) {
      let date = new Date(this.value);

      this.dateValue =
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        date.getDate().toString().padStart(2, "0");
      this.timeValue =
        date.getHours().toString().padStart(2, "0") +
        ":" +
        date.getMinutes().toString().padStart(2, "0") +
        ":" +
        date.getSeconds().toString().padStart(2, "0");
    }

    this.propagateChange(this.value || null);
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {
    console.log(this.disabled);
  }

  updateDateTimeValue() {
    let updateDate: Date;

    if (this.dateValue) {
      updateDate = new Date(this.dateValue);

      let timeParts = this.timeValue?.split(":");
      updateDate.setHours(parseInt(timeParts?.[0]) || 0);
      updateDate.setMinutes(parseInt(timeParts?.[1]) || 0);
      updateDate.setSeconds(parseInt(timeParts?.[2]) || 0);
    }

    this.value = updateDate?.toISOString();

    this.propagateChange(updateDate?.toISOString() || null);
  }
}
