import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { fadeInOut } from "src/app/app-common-module/animations/fade";
import { PermissionHelperService } from "src/app/services/permission-helper.service";
import {
  Group,
  PassService,
  ResourcesService,
} from "src/app/sinigangnababoywithgabi";
import { ModalService } from "src/app/services/modal.service";

// DEPRECATED: In favor of unified permissions
@Component({
  selector: "app-course-permissions-edit-group",
  templateUrl: "./course-permissions-edit-group.component.html",
  animations: [fadeInOut],
})
export class CoursePermissionsEditGroupComponent implements OnInit {
  rolePermissionFormGroup: UntypedFormGroup;
  group: Group;
  grantTypeFormControl: UntypedFormControl;

  constructor(
    private route: ActivatedRoute,
    private passService: PassService,
    private permissionHelperService: PermissionHelperService,
    private resourcesService: ResourcesService,
    private location: Location,
    private modalService: ModalService
  ) {}

  async ngOnInit() {
    this.grantTypeFormControl = new UntypedFormControl();

    this.grantTypeFormControl.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        if (value === "grantall") {
          this.getPermissionFormGroups().controls.forEach(
            (pfg: UntypedFormControl) => {
              if (!pfg.get("isActive").value) {
                pfg.get("isActive").setValue(true, {
                  emitEvent: false,
                  onlySelf: true,
                });
              }
            }
          );
        }
      });

    await this.parseRolesAndPermissions();
  }

  private unsubscribe$: Subject<void> = new Subject<void>();

  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  getPermissionFormGroups(): UntypedFormArray {
    return this.rolePermissionFormGroup.get(
      "permissionFormGroups"
    ) as UntypedFormArray;
  }

  async parseRolesAndPermissions() {
    let {
      course_uuid: courseUuid,
      role_uuid: roleUuid,
      resource_uuid: resourceUuid,
    } = this.route.snapshot.queryParams;

    let permissionAttributes = [];

    let role: any = await this.passService
      .getRole(roleUuid, ["groups", "permissions"])
      .toPromise();

    this.group = role.groups[0];

    if (resourceUuid) {
      let resource = await this.resourcesService
        .getResourceByUuid(resourceUuid)
        .toPromise();

      if (resource.content.resourceCollectionUuid)
        permissionAttributes = [
          ...permissionAttributes,
          {
            name: `read:resources:${resourceUuid}`,
            description: `Can read resource`,
          },
          {
            name: `read:collection:${resource.content.resourceCollectionUuid}`,
            description: `Can read course content`,
          },
          {
            name: `update:collection:${resource.content.resourceCollectionUuid}`,
            description: `Can update course content`,
          },
        ];
    }

    this.rolePermissionFormGroup =
      this.permissionHelperService.createRoleToggleFormGroup({
        groupId: role.groupIds[0],
        roleId: role.id,
        roleName: `crud:courses:${courseUuid}`,
        roleDescription: role.groups[0].name,
        permissionAttributes: [
          {
            name: `read:courses:${courseUuid}`,
            description: "Can read the course",
          },
          {
            name: `update:courses:${courseUuid}`,
            description: "Can update the course",
          },
          {
            name: `delete:courses:${courseUuid}`,
            description: "Can delete the course",
          },
          ...permissionAttributes,
          {
            name: `create:schedules:course_uuid=${courseUuid}`,
            description: "Can create batches inside the course",
          },
          {
            name: `read:schedules:course_uuid=${courseUuid}`,
            description: "Can read batches inside the course",
          },
        ],
        existingPermissionList: role.permissions,
        unsubscribe: this.unsubscribe$,
      });

    if (
      this.rolePermissionFormGroup.value.permissionFormGroups.every(
        (pfg) => pfg.isActive
      )
    ) {
      this.grantTypeFormControl.setValue("grantall", {
        emitEvent: false,
      });
    } else {
      this.grantTypeFormControl.setValue("specific", {
        emitEvent: false,
      });
    }
  }

  onCheckboxChange() {
    this.grantTypeFormControl.setValue("specific", {
      emitEvent: false,
    });
  }

  async removeGroup() {
    let { course_uuid: courseUuid, role_uuid: roleUuid } =
      this.route.snapshot.queryParams;

    if (
      await this.modalService.confirm(
        "Are you sure you want to remove this group as an admin of this course?"
      )
    ) {
      this.passService.deleteRole(roleUuid).subscribe((result) => {
        this.location.back();
      });
    }
  }
}
