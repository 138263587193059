<app-back-title-layout layoutTitle="Resource Library">
  <app-search-bar-query placeholder="Search" class="my-5">
  </app-search-bar-query>

  <div class="my-5">
    <div class="flex flex-row-reverse">
      <app-pagination-query
        [pageSize]="pageSize"
        [listLength]="listLength"
      ></app-pagination-query>
    </div>

    <app-item-list
      [source]="resourceListObservable"
      [itemTemplate]="itemTemplate"
    ></app-item-list>
  </div>
</app-back-title-layout>

<ng-template #itemTemplate let-resource="item">
  <a
    [routerLink]="['/resources', 'profile']"
    [queryParams]="{ resource_uuid: resource.uuid }"
    class="flex"
  >
    <img
      loading="lazy"
      class="shrink-0 mr-2 h-20 w-20 object-contain bg-gray-300"
      [src]="resource.coverUri"
      alt=""
    />

    <div>
      <div class="text-xs text-gray-500 uppercase">
        <span>
          {{ resource | resourceType }}
        </span>

        <ng-container *ngIf="!resource.publishedAt">
          <span class="italic">(Unpublished)</span>
        </ng-container>
      </div>

      <div class="text-sm font-semibold">
        {{ resource.name }}
      </div>

      <div class="text-gray-500 mt-2">
        {{ resource.flavorTextSpan }}
      </div>
    </div>
  </a>
</ng-template>
