import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Collection, ResourcesService } from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-collection-parents-edit",
  templateUrl: "./collection-parents-edit.component.html",
  styleUrls: ["./collection-parents-edit.component.scss"],
})
export class CollectionParentsEditComponent implements OnInit {
  collections: Collection[];
  selectedCollections: Collection[];
  collectionUuid: string;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private resourcesService: ResourcesService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((result) => {
      this.collectionUuid = result["collection_uuid"];

      this.resourcesService
        .listParentCollectionOfCollection(result["collection_uuid"])
        .subscribe((collections) => {
          this.selectedCollections = collections;
        });

      this.resourcesService.listCollections().subscribe((collections) => {
        this.collections = collections;
      });
    });
  }

  updateCollections() {
    this.resourcesService
      .updateCollectionByUuid(this.collectionUuid, {
        parentPackageUuids: this.selectedCollections.map((c) => c.uuid),
      })
      .subscribe((result) => {
        this.location.back();
      });
  }
}
