<div
  class="text-red-700 text-sm"
  *ngIf="control && control.errors && (control.dirty || control.touched)"
>
  <div *ngIf="control.errors.required">
    <small>*Required</small>
  </div>
  <div *ngIf="control.errors.unique">
    <small>{{ control.errors.unique }}</small>
  </div>
  <div *ngIf="control.errors.invalidEmbed">
    <small>*Must be a valid embed code</small>
  </div>
  <div *ngIf="control.errors.lessThan">
    <small>{{ control.errors.lessThan }}</small>
  </div>
  <div *ngIf="control.errors.greaterThan">
    <small>{{ control.errors.greaterThan }}</small>
  </div>
  <div *ngIf="control.errors.email">
    <small>*Must be a valid email.</small>
  </div>
  <div *ngIf="control.errors.mobile">
    <small>{{ control.errors.mobile }}</small>
  </div>
  <div *ngIf="control.errors.confirmPassword">
    <small>{{ control.errors.confirmPassword }}</small>
  </div>
  <div *ngIf="control.errors.minlength">
    <small
      >*Must be at least
      {{ control.errors.minlength.requiredLength }} characters</small
    >
  </div>

  <div *ngIf="control.errors.min">
    <small>*Must be at least {{ control.errors.min.min }}</small>
  </div>

  <div *ngIf="control.errors.max">
    <small>*Must be at most {{ control.errors.max.max }}</small>
  </div>

  <div *ngIf="control.errors.url">
    <small>*Must be a valid url</small>
  </div>

  <div *ngIf="control.errors.custom">
    <small>*{{ control.errors.custom }}</small>
  </div>
</div>
