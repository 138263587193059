import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { firstValueFrom } from "rxjs";
import {
  ActivityLogService,
  VERBS,
} from "src/app/services/activity-log.service";
import { DashboardService } from "src/app/services/dashboard.service";
import { ModalService } from "src/app/services/modal.service";
import {
  CastsService,
  Screen,
  V2CastsService,
} from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-casteditor-screen-edit",
  templateUrl: "./casteditor-screen-edit.component.html",
})
export class CasteditorScreenEditComponent implements OnInit {
  formGroup = this.formBuilder.group({
    screenId: [null],
    title: ["", Validators.required],
    slug: [null],
  });

  constructor(
    private formBuilder: FormBuilder,
    private castService: CastsService,
    private v2CastService: V2CastsService,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private dashboardService: DashboardService,
    private activityLogService: ActivityLogService,
    private location: Location
  ) {}

  async ngOnInit() {
    let { screen_id: screenId } = this.route.snapshot.queryParams;

    // Get cast ID
    let {
      data: {
        id: castId,
        attributes: { initialBlockId },
      },
    } = await firstValueFrom(this.dashboardService.getCast());

    // Get Screen note na hardcoded yung castid due to v1 API code
    let screen: Screen = await firstValueFrom(
      this.castService.screenGet("0", screenId)
    );

    this.formGroup.patchValue({
      screenId: screen.id,
      title: screen.title,
    });

    let navigationBlock = await firstValueFrom(
      this.v2CastService.blockGet(initialBlockId)
    );

    // Set slug if found in navbar else disable slug formcontrol
    let foundItem = navigationBlock.data.attributes.items.find(
      (item) => item.screenId == screenId
    );

    if (foundItem) {
      this.formGroup.controls.slug.enable();
      this.formGroup.controls.slug.setValue(foundItem.slug);
    } else {
      this.formGroup.controls.slug.disable();
    }
  }

  async saveScreen() {
    this.formGroup.disable();

    try {
      // Get cast
      let cast = await firstValueFrom(this.dashboardService.getCast());

      let navigationBlock = await firstValueFrom(
        this.v2CastService.blockGet(cast.data.attributes.initialBlockId)
      );

      let updatedItems = navigationBlock.data.attributes.items.map((item) => {
        if (item.screenId == this.formGroup.value.screenId) {
          item.title = this.formGroup.value.title;
          item.slug = this.formGroup.value.slug;
        }

        return item;
      });

      let newNavigationBlock = {
        id: parseInt(navigationBlock.data.id),
        order: null,
        type: "navigation",
        properties: {
          ...navigationBlock.data.attributes.properties,
          items: updatedItems,
        },
      };

      await firstValueFrom(
        this.castService.blockUpdate("1", "6", newNavigationBlock.id + "", {
          block: newNavigationBlock,
        })
      );

      //
      // Update the screen

      await firstValueFrom(
        this.castService.screenUpdate(
          cast.data.id,
          this.formGroup.value.screenId,
          {
            screen: {
              title: this.formGroup.value.title,
              slug: this.formGroup.value.slug,
            },
          }
        )
      );

      // Activity Logging
      this.activityLogService.didCastScreen(VERBS.updated, {
        id: this.formGroup.value.screenId,
        castId: parseInt(cast.data.id),
      });

      this.location.back();
    } catch (err) {
      this.formGroup.enable();

      if (err.error?.["error_detail"]?.slug) {
        this.formGroup.get("slug").setErrors({
          custom: `This value ${err.error?.["error_detail"]?.slug}`,
        });
      }
    }
  }

  async deleteScreen() {
    let { screen_id: screenId } = this.route.snapshot.queryParams;

    let cast = await firstValueFrom(this.dashboardService.getCast());
    let castId = cast.data.id;

    let result = await this.modalService.confirm(
      "Are you sure you want to delete this screen?"
    );

    if (result) {
      let navigationBlock = await firstValueFrom(
        this.v2CastService.blockGet(cast.data.attributes.initialBlockId)
      );

      let updatedItems = navigationBlock.data.attributes.items.filter(
        (item) => item.screenId != screenId
      );

      let newNavigationBlock = {
        id: parseInt(navigationBlock.data.id),
        order: null,
        type: "navigation",
        properties: {
          ...navigationBlock.data.attributes.properties,
          items: updatedItems,
        },
      };

      await firstValueFrom(
        this.castService.blockUpdate("1", "6", newNavigationBlock.id + "", {
          block: newNavigationBlock,
        })
      );

      await firstValueFrom(this.castService.screenDelete(castId, screenId));

      this.location.back();
    }
  }
}
