import { Component, OnInit } from "@angular/core";
import {
  CoursesService,
  CourseSchedule,
  CourseUserEnrollment,
} from "src/app/sinigangnababoywithgabi";
import { ActivatedRoute } from "@angular/router";
import { DatePipe } from "@angular/common";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

// DEPRECATED
@Component({
  selector: "app-course-student-stats",
  templateUrl: "./course-student-stats.component.html",
})
export class CourseStudentStatsComponent implements OnInit {
  enrollments: CourseUserEnrollment[];
  schedules: CourseSchedule[];
  completionStats: {
    students: number | string;
    inProgress: number | string;
    notStarted: number | string;
    completed: number | string;
    needsAttention: number | string;
  } = {
    students: "---",
    inProgress: "---",
    notStarted: "---",
    completed: "---",
    needsAttention: "---",
  };

  scheduleTable: {
    schedule: CourseSchedule;
    name: string;
    statusText: string;
    statusClass: string;
    dateText: string;
    userCount: number;
  }[];

  overviewObservable: Observable<any>;
  scheduleTableObservable: Observable<any>;

  constructor(
    private route: ActivatedRoute,
    private coursesService: CoursesService
  ) {}

  ngOnInit(): void {
    let courseUuid = this.route.snapshot.queryParams["course_uuid"];

    // https://buri.dev/T1469
    this.overviewObservable = this.coursesService
      .courseStatisticsCourseCounts(courseUuid)
      .pipe(
        map((result) => {
          this.completionStats = {
            students: result.totalUsersCount,
            inProgress: result.totalInProgressCount,
            notStarted: result.totalNotStartedCount,
            completed: result.totalCompletedCount,
            needsAttention: result.totalNeedsAttentionCount,
          };
        })
      );

    // Schedules without count
    this.scheduleTableObservable = this.coursesService
      .courseScheduleList(courseUuid)
      .pipe(
        map((result) => {
          this.schedules = result;

          this.initializeScheduleTable();
        })
      );
  }

  initializeScheduleTable() {
    let datePipe = new DatePipe("en-ph");

    this.scheduleTable = this.schedules.map((schedule) => {
      let nowDate = new Date();
      let startDate = schedule.start ? new Date(schedule.start) : null;
      let endDate = schedule.end ? new Date(schedule.end) : null;
      let statusText: string;
      let statusClass: string;
      let dateText: string;

      if (startDate && startDate.valueOf() > nowDate.valueOf()) {
        statusText = "Queued";
        statusClass = "bg-purple-500";
        dateText = `Starts at ${datePipe.transform(startDate)}`;
        if (endDate) {
          dateText += ` until ${datePipe.transform(endDate)}`;
        }
      } else if (endDate && endDate.valueOf() < nowDate.valueOf()) {
        statusText = "Done";
        statusClass = "bg-gray-500";
        dateText = `Started ${datePipe.transform(startDate)}`;
        if (endDate) {
          dateText += ` until ${datePipe.transform(endDate)}`;
        }
      } else {
        statusText = "Ongoing";
        statusClass = "bg-purple-800";
        if (!startDate && !endDate) {
          dateText = "Forever";
        }
        if (startDate) {
          dateText = `Started ${datePipe.transform(startDate)}`;
        }
        if (endDate) {
          dateText += ` until ${datePipe.transform(endDate)}`;
        }
      }
      return {
        schedule: schedule,
        name: schedule.name,
        statusText,
        statusClass,
        dateText,
        userCount: schedule["enrollments"]?.length,
      };
    });
  }
}
