<div class="w-full h-full">
  <div class="flex w-full justify-end">
    <button mat-dialog-close><app-icon icon="close"></app-icon></button>
  </div>
  <h1 class="text-center font-bold text-2xl my-4">Edit Feedback</h1>

  <mat-tab-group>
    <div class="relative">
      <mat-tab label="Global Feedback">
        <div class="h-[500px] relative">
          <form
            [formGroup]="data.formGroup"
            (ngSubmit)="onSave()"
            class="my-10 relative h-full"
          >
            <app-loading
              position="absolute"
              padding="py-32"
              height="h-96"
              [loading]="loading"
            >
              <h2 class="font-bold text-xl">Global Feedback</h2>
              <p class="mb-4">
                This will be your default correct/incorrect feedback to this
                question block
              </p>
              <div *ngIf="data.formGroup.get('showResults').value" class="mx-8">
                <ng-container
                  *ngIf="
                    (data.formGroup.value.type == 'MC' ||
                      data.formGroup.value.type == 'IC' ||
                      data.formGroup.value.type == 'CB' ||
                      data.formGroup.value.type == 'SA') &&
                      data.formGroup.value.isGraded == true;
                    else text
                  "
                >
                  <div class="w-full my-10">
                    <label>Feedback for Correct Answer</label>
                    <div class="w-full flex justify-start items-start">
                      <div class="w-full flex-1">
                        <app-markdown-viewer-and-editor
                          [autoResize]="false"
                          editorClass="h-20 overflow-y-scroll"
                          viewerClass="h-20 overflow-y-scroll border rounded p-2"
                          formControlName="correctFeedbackMessage"
                        >
                        </app-markdown-viewer-and-editor>
                        <app-upload-loading-wrapper
                          #correctFeedbackImageUrlUploader
                          accept="image/*"
                          text="UPLOAD IMAGE"
                          [ngClass]="{
                            hidden:
                              !data.formGroup.value.correctFeedbackImageUrl
                          }"
                          (upload)="
                            onUpload(
                              $event,
                              data.formGroup.get('correctFeedbackImageUrl')
                            )
                          "
                        >
                          <div
                            class="relative"
                            *ngIf="data.formGroup.value.correctFeedbackImageUrl"
                          >
                            <img
                              [src]="
                                data.formGroup.value.correctFeedbackImageUrl
                              "
                              class="w-full h-40 object-contain bg-gray-300 rounded"
                            />

                            <button
                              type="button"
                              (click)="
                                onUploadClear(
                                  data.formGroup.get('correctFeedbackImageUrl')
                                )
                              "
                              class="appearance-none pt-1 pb-2 pr-1 pl-2 z-auto rounded-bl-full hover:bg-red-500 text-white bg-gray-500 absolute top-0 right-0"
                            >
                              <app-icon icon="close"></app-icon>
                            </button>
                          </div>
                        </app-upload-loading-wrapper>
                      </div>

                      <!-- <textarea
                      formControlName="correctFeedbackMessage"
                      placeholder="Feedback for Correct Answer"
                      class="form-control min-h-10"
                    ></textarea> -->
                      <button
                        type="button"
                        (click)="correctFeedbackImageUrlUploader.click()"
                        class="text-primary text-4xl"
                      >
                        <app-icon icon="photo"></app-icon>
                      </button>
                    </div>
                  </div>
                  <div class="my-10 w-full">
                    <label>Feedback for Incorrect Answer</label>
                    <div class="flex items-start justify-start w-full">
                      <div class="w-full flex-1">
                        <app-markdown-viewer-and-editor
                          placeholder="Feedback for Incorrect Answer"
                          [autoResize]="false"
                          editorClass="h-[150px] overflow-y-scroll"
                          viewerClass="h-[150px] overflow-y-scroll border rounded p-2"
                          formControlName="incorrectFeedbackMessage"
                        >
                        </app-markdown-viewer-and-editor>
                        <app-upload-loading-wrapper
                          #incorrectFeedbackImageUrlUploader
                          accept="image/*"
                          text="UPLOAD IMAGE"
                          [ngClass]="{
                            hidden:
                              !data.formGroup.value.incorrectFeedbackImageUrl
                          }"
                          (upload)="
                            onUpload(
                              $event,
                              data.formGroup.get('incorrectFeedbackImageUrl')
                            )
                          "
                        >
                          <div
                            class="relative"
                            *ngIf="
                              data.formGroup.value.incorrectFeedbackImageUrl
                            "
                          >
                            <img
                              [src]="
                                data.formGroup.value.incorrectFeedbackImageUrl
                              "
                              class="w-full h-40 object-contain bg-gray-300 rounded"
                            />

                            <button
                              type="button"
                              (click)="
                                onUploadClear(
                                  data.formGroup.get(
                                    'incorrectFeedbackImageUrl'
                                  )
                                )
                              "
                              class="appearance-none pt-1 pb-2 pr-1 pl-2 z-auto rounded-bl-full hover:bg-red-500 text-white bg-gray-500 absolute top-0 right-0"
                            >
                              <app-icon icon="close"></app-icon>
                            </button>
                          </div>
                        </app-upload-loading-wrapper>
                      </div>

                      <!-- <textarea
                      formControlName="incorrectFeedbackMessage"
                      placeholder="Feedback for Incorrect Answer"
                      class="form-control min-h-10"
                    ></textarea> -->
                      <button
                        (click)="incorrectFeedbackImageUrlUploader.click()"
                        type="button"
                        class="text-primary text-4xl"
                      >
                        <app-icon icon="photo"></app-icon>
                      </button>
                    </div>
                  </div>
                </ng-container>

                <ng-template #text>
                  <div class="w-full my-10">
                    <label>Answer</label>
                    <div class="flex items-start justify-start w-full">
                      <div class="w-full flex-1">
                        <app-markdown-viewer-and-editor
                          placeholder="Feedback for Answer"
                          [autoResize]="false"
                          editorClass="h-[150px] overflow-y-scroll"
                          viewerClass="h-[150px] overflow-y-scroll border rounded p-2"
                          formControlName="neutralFeedbackMessage"
                        >
                        </app-markdown-viewer-and-editor>
                        <app-upload-loading-wrapper
                          #neutralFeedbackImageUrlUploader
                          accept="image/*"
                          text="UPLOAD IMAGE"
                          [ngClass]="{
                            hidden:
                              !data.formGroup.value.neutralFeedbackImageUrl
                          }"
                          (upload)="
                            onUpload(
                              $event,
                              data.formGroup.get('neutralFeedbackImageUrl')
                            )
                          "
                        >
                          <div
                            class="relative"
                            *ngIf="data.formGroup.value.neutralFeedbackImageUrl"
                          >
                            <img
                              [src]="
                                data.formGroup.value.neutralFeedbackImageUrl
                              "
                              class="w-full h-40 object-contain bg-gray-300 rounded"
                            />

                            <button
                              type="button"
                              (click)="
                                onUploadClear(
                                  data.formGroup.get('neutralFeedbackImageUrl')
                                )
                              "
                              class="appearance-none pt-1 pb-2 pr-1 pl-2 z-auto rounded-bl-full hover:bg-red-500 text-white bg-gray-500 absolute top-0 right-0"
                            >
                              <app-icon icon="close"></app-icon>
                            </button>
                          </div>
                        </app-upload-loading-wrapper>
                      </div>

                      <!-- <textarea
                      formControlName="neutralFeedbackMessage"
                      placeholder="Feedback for Answer"
                      class="form-control min-h-10"
                    ></textarea> -->

                      <button
                        (click)="neutralFeedbackImageUrlUploader.click()"
                        type="button"
                        class="text-primary text-4xl"
                      >
                        <app-icon icon="photo"></app-icon>
                      </button>
                    </div>
                  </div>
                </ng-template>
              </div>
              <div class="flex w-full justify-end sticky bottom-0">
                <button class="btn btn-primary" type="submit">SAVE</button>
              </div>
            </app-loading>
          </form>
        </div>
      </mat-tab>
    </div>
    <ng-container *ngIf="data && data.insideSlide">
      <mat-tab label="Custom Feedback">
        <ng-container
          *ngIf="
            data.formGroup.value.type === 'SA' ||
            data.formGroup.value.type === 'FW' ||
            data.formGroup.value.type === 'LA' ||
            (data.formGroup.value.type === 'PO' &&
              data.formGroup.value.subtype === 'SU')
          "
        >
          <div
            class="text-center font-bold text-3xl h-[500px] items-center flex justify-center"
          >
            This item type does not support custom feedback.
          </div>
        </ng-container>
        <ng-container
          *ngIf="
            data.formGroup.value.type !== 'SA' &&
            data.formGroup.value.type !== 'FW' &&
            data.formGroup.value.type !== 'LA' &&
            !(
              data.formGroup.value.type === 'PO' &&
              data.formGroup.value.subtype === 'SU'
            )
          "
        >
          <app-exam-item-form-choice-feedbacks
            [formGroup]="data.formGroup"
            [insideSlides]="data.insideSlide"
          ></app-exam-item-form-choice-feedbacks>
        </ng-container>
      </mat-tab>
    </ng-container>
  </mat-tab-group>
</div>
