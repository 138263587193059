import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { firstValueFrom } from "rxjs";
import { ModalService } from "src/app/services/modal.service";
import {
  Course,
  CoursesService,
  ResourcesService,
} from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-course-template-list",
  templateUrl: "./course-template-list.component.html",
  styles: [],
})
export class CourseTemplateListComponent implements OnInit {
  courses: Course[];

  formGroup: UntypedFormGroup;

  loading: boolean;

  constructor(
    private coursesService: CoursesService,
    private resourcesService: ResourcesService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private formBuilder: UntypedFormBuilder
  ) {}

  async ngOnInit() {
    let courses = await firstValueFrom(
      this.coursesService.courseList(null, null, null, "content", true)
    );

    this.courses = [...courses];

    this.formGroup = this.formBuilder.group({
      name: ["", Validators.required],
      coverUri: [""],
    });
  }

  async cloneCourseToResource(course: Course) {
    try {
      this.loading = true;
      let { parent_uuid: parentUuid, order } = this.route.snapshot.queryParams;

      // Create clone task
      let courseCloneTask = await firstValueFrom(
        this.coursesService.courseCloneTaskCreate(course.uuid)
      );

      // Create new collection for resource container
      let collection = await firstValueFrom(
        this.resourcesService.createCollection({
          name: `${course.title} resources`,
        })
      );

      // Create new resource
      let resource = await firstValueFrom(
        this.resourcesService.createResource({
          name: course.title,
          description: course.description,
          coverUri: course.coverUri,
          content: {
            courseDetails: {
              courseUuid: null,
              courseCloneTaskUuid: courseCloneTask.uuid,
            },
            resourceCollectionUuid: collection.uuid,
          },
          packageUuids: [parentUuid],
          type: "course",
        })
      );

      if (order == "0") {
        let resources = await firstValueFrom(
          this.resourcesService.listResourcesInCollection(parentUuid, true)
        );
        let resourceUuids = resources.map((r) => r.uuid);

        let resourceIndex = resourceUuids.findIndex(
          (uuid) => uuid === resource.uuid
        );

        resourceUuids.splice(resourceIndex, 1);
        resourceUuids.splice(0, 0, resource.uuid);

        await firstValueFrom(
          this.resourcesService.setResourceOrderInCollection(parentUuid, {
            resourceUuids,
          })
        );
      }

      this.router.navigate(["/_courses", "content-edit"], {
        replaceUrl: true,
        queryParams: {
          course_uuid: resource.content?.courseDetails?.courseUuid,
          resource_collection_uuid: resource.content?.resourceCollectionUuid,
          resource_uuid: resource.uuid,
          publish_resource_on_next: true,
        },
      });
    } catch (err) {
      this.loading = false;
      throw err;
    }
  }

  async openNewCourseModal(template) {
    let result = await this.modalService.openModal(template);

    if (result) {
      console.log(result);
    }
  }

  onUpload(event) {
    this.formGroup.get("coverUri").setValue(event.uri);
  }
}
