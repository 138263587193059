import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-purchase-payment-option",
  templateUrl: "./purchase-payment-option.component.html",
  styles: ['.peso:before {content:"₱"}'],
})
export class PurchasePaymentOptionComponent implements OnInit {
  formGroup: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private location: Location
  ) {}

  private unsubscribe$: Subject<void> = new Subject<void>();
  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  async ngOnInit() {
    // Redirect agad. Will use this component kapag may different may different payment methods na (eg coupon and prepaid)
    this.proceedAsRecurring();
  }

  initFormGroup() {
    this.formGroup = this.formBuilder.group({
      paymentOption: [null, Validators.required],

      price: [null, Validators.required],
    });

    this.formGroup
      .get("paymentOption")
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        if (value === "onetime") {
          this.formGroup.get("price").enable();
        } else if (value === "recurring") {
          this.formGroup.get("price").disable();
        }
      });
  }

  async onFormSubmit() {
    if (this.formGroup.value.paymentOption == "onetime") {
      this.location.back();
    } else if (this.formGroup.value.paymentOption == "recurring") {
      this.proceedAsRecurring();
    }
  }

  async proceedAsRecurring() {
    this.router.navigate(["/purchases", "list", "subscription-new"], {
      queryParams: {},
      replaceUrl: true,
      queryParamsHandling: "merge",
    });
  }
}
