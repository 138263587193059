import { Pipe, PipeTransform } from "@angular/core";
import { Subscription } from "rxjs";
import { DashboardService } from "src/app/services/dashboard.service";

export const TYPES: any = [
  ["course", "Course"],
  ["video", "Video"],
  ["pdf", "PDF"],
  ["assessment", "Assessment"],
  ["burislides", "Slide"],
  ["xapi", "xAPI"],
  ["link", "Link"],
  ["embed", "Embed"],
  ["epub", "Epub"],
  ["casticulate", "Slides V2"],
];

export const SUBTYPES: any = [
  ["markdown", "Markdown"],
  ["file-upload", "File Submission"],
  ["freedom-wall", "Freedom Wall"],
  ["email", "Email"],
  // ["google-slide", "Google Slide"],
  // ["google-form", "Google Form"],
  // ["google-doc", "Google Doc"],
  // ["google-sheet", "Google Sheet"],
  // ["youtube", "YouTube"],
  // ["articulate", "Articulate"],

  // ["canva", "Canva"],
];

export function CAMELIZE(s) {
  if (s) {
    return s.replace(/-./g, (x) => x[1].toUpperCase());
  }
  return null;
}

@Pipe({
  name: "resourceType",
  pure: false,
})
export class ResourceTypePipe implements PipeTransform {
  cache: any;
  resourceTypeMap: Map<string, string> = new Map(TYPES);
  resourceSubtypeMap: Map<string, string> = new Map(SUBTYPES);

  subscription: Subscription;

  constructor(private dashboardService: DashboardService) {
    if (this.cache) {
      this.setValues(this.cache);
    } else {
      this.subscription = this.dashboardService.getCast().subscribe((data) => {
        this.cache = data;
        this.setValues(data);
      });
    }
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  private setValues(data: any) {
    let humanize = data.data.attributes.humanize?.buresource?.resource;

    let types: Map<string, string> = new Map();
    let subtypes: Map<string, string> = new Map();

    if (humanize?.type && Array.isArray(humanize.type)) {
      let humanizeTypeMap: Map<string, string> = new Map(humanize.type);
      let humanizeSubtypeMap: Map<string, string> = new Map(humanize.subtype);
      for (const [key, value] of humanizeTypeMap) {
        types.set(key, value);
      }
      for (const [key, value] of humanizeSubtypeMap) {
        subtypes.set(key, value);
      }
    }

    //fallback code for reading old humanize types
    //first condition for deletion
    for (const [key, value] of this.resourceTypeMap) {
      this.resourceTypeMap.set(
        key,
        humanize?.type?.[key] || types.get(key) || value
      );
    }

    for (const [key, value] of this.resourceSubtypeMap) {
      this.resourceSubtypeMap.set(
        key,
        humanize?.subtype?.[key] || subtypes.get(key) || value
      );
    }
  }

  transform(value: any, args?: any): any {
    let type: string;

    if (typeof value === "object" && value !== null) {
      type = value.subtype || value.type;
    } else {
      type = value;
    }

    return (
      this.resourceSubtypeMap.get(type) ||
      this.resourceTypeMap.get(type) ||
      type
    );
  }
}
