import { Component, OnInit } from "@angular/core";

export interface BlockItem {
  value: string;
  text: string;
  icon: string;
  disabled?: boolean;
  defaultProperty?: any;
}

export const AVAILABLE_BLOCK_TYPES: BlockItem[] = [
  {
    value: "catalog",
    text: "Catalog",
    icon: "view_agenda",
  },
  {
    value: "carousel",
    text: "Carousel",
    icon: "insert_photo",
  },
  {
    value: "markdown",
    text: "Text",
    icon: "text_fields",
  },
  {
    value: "course-status",
    text: "Personal Learning Data",
    icon: "format_list_bulleted",
    defaultProperty: {
      heading: "Personal Learning Data",
    },
  },
  {
    value: "buttonSearch",
    text: "Search Button",
    icon: "search",
  },
  // {properties
  //   value: "Video",
  //   icon: "ondemand_video",
  // },
  {
    value: "category",
    text: "Category",
    icon: "view_agenda",
  },
];

@Component({
  selector: "app-casteditor-modal-add-block",
  templateUrl: "./casteditor-modal-add-block.component.html",
  styleUrls: ["./casteditor-modal-add-block.component.scss"],
})
export class CasteditorModalAddBlockComponent implements OnInit {
  blockTypes: BlockItem[];

  constructor() {}

  ngOnInit(): void {
    this.blockTypes = AVAILABLE_BLOCK_TYPES;
  }
}
