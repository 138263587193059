import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import {
  FormBuilder,
  UntypedFormBuilder,
  UntypedFormGroup,
} from "@angular/forms";

@Component({
  selector: "app-burislides-block-markdown",
  templateUrl: "./burislides-block-markdown.component.html",
  styles: [],
})
export class BurislidesBlockMarkdownComponent implements OnInit {
  formGroup: UntypedFormGroup;
  @Input() initialValue: string;
  @Output() text: EventEmitter<string> = new EventEmitter<string>();
  constructor(private fb: UntypedFormBuilder) {}
  ngOnInit(): void {
    this.formGroup = this.fb.group({
      markdownText: [this.initialValue || ""],
    });
  }
  onSave() {
    this.text.emit(this.formGroup.value.markdownText);
  }
}
