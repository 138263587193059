import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { firstValueFrom, of, Subject } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  map,
  takeUntil,
} from "rxjs/operators";
import { fadeInOut } from "src/app/app-common-module/animations/fade";
import {
  CourseCertificateEmailTemplate,
  CoursesService,
} from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-course-certification",
  templateUrl: "./course-certification.component.html",
  styleUrls: ["./course-certification.component.scss"],
  animations: [fadeInOut],
})
export class CourseCertificationComponent implements OnInit {
  certificationEnabledFormControl: UntypedFormControl = new UntypedFormControl(
    false
  );
  formGroup: UntypedFormGroup;

  loading: boolean;

  certificateEmailTemplate: CourseCertificateEmailTemplate;

  constructor(
    private route: ActivatedRoute,
    private coursesService: CoursesService,
    private formBuilder: UntypedFormBuilder,
    private location: Location
  ) {}

  private destroy$ = new Subject();
  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  async ngOnInit() {
    this.loading = true;
    await this.setUpCertificationToggle();

    this.loading = false;
  }

  async setUpCertificationToggle() {
    let { course_uuid: courseUuid, schedule_uuid: scheduleUuid } =
      this.route.snapshot.queryParams;

    this.formGroup = this.formBuilder.group({
      certificateProfileUuid: [],
      courseUuid: [courseUuid],
      endMessage: [],
    });

    // Get course certificate profile
    let certificateProfile = await firstValueFrom(
      this.coursesService.courseCertificateprofilesList(courseUuid)
    ).then((result) => result.find((i) => i["schedule"] == scheduleUuid));

    if (certificateProfile) {
      this.certificationEnabledFormControl.setValue(true, {
        onlySelf: true,
        emitEvent: true,
      });

      this.formGroup.patchValue(
        {
          ...certificateProfile,
          endMessage: certificateProfile.endMessage || "",
          certificateProfileUuid: certificateProfile.uuid,
        },
        {
          onlySelf: true,
          emitEvent: true,
        }
      );
    }

    this.certificationEnabledFormControl.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        switchMap((value) => {
          this.certificationEnabledFormControl.disable({
            emitEvent: false,
          });
          // If true, create certificate profile
          // Else, delete certificate profile
          if (value) {
            return this.coursesService
              .courseCertificateprofileCreate(courseUuid)
              .pipe(
                map((result) => {
                  this.formGroup.patchValue(
                    {
                      certificateProfileUuid: result.uuid,
                      endMessage: "",
                      ...result,
                    },
                    {
                      onlySelf: true,
                      emitEvent: true,
                    }
                  );
                })
              );
          } else {
            // return this.coursesService.certificateprofileDelete(
            //   this.formGroup.value.certificateProfileUuid
            // );
          }
        }),
        map(() => {
          this.certificationEnabledFormControl.enable({
            emitEvent: false,
          });
        })
      )
      .subscribe();

    this.formGroup.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((value) => {
          return this.coursesService.certificateprofileUpdate(
            value.certificateProfileUuid,
            {
              endMessage: value.endMessage || "",
            }
          );
        })
      )
      .subscribe();
  }

  async save() {
    await firstValueFrom(
      this.coursesService.certificateprofileUpdate(
        this.formGroup.value.certificateProfileUuid,
        {
          endMessage: this.formGroup.value.endMessage || "",
        }
      )
    );

    this.location.back();
  }
}
