<div class="relative">
  <app-loading
    [loading]="!components"
    wrapperClass="absolute inset-0 pt-40"
  ></app-loading>

  <div *ngIf="components" [@fadeInOut]>
    <div class="mb-3 mt-10">
      <button
        *ngIf="collection?.name"
        class="font-bold flex items-center"
        (click)="refreshList()"
      >
        <app-icon class="text-2xl" icon="navigate_before"></app-icon>
        {{ collection.name }}
      </button>

      <span class="font-bold" *ngIf="components && !collection?.name">
        Collections
      </span>
    </div>

    <ul>
      <li class="border-b-2">
        <button
          *ngIf="!collection"
          class="flex items-center cursor-pointer text-left"
          (click)="allResourcesClick()"
        >
          <div class="px-2 h-20 w-20">
            <div class="h-full w-full flex items-center justify-center">
              <app-icon class="text-6xl text-primary" icon="folder"></app-icon>
            </div>
          </div>

          <div class="flex-grow">
            <div class="truncate font-semibold text-sm text-primary">
              See All Resources
            </div>
          </div>
        </button>
      </li>
    </ul>

    <app-item-list
      [items]="components | filterAll: searchString"
      [itemTemplate]="itemTemplate"
    >
    </app-item-list>
  </div>
</div>

<ng-template #itemTemplate let-item="item">
  <button
    class="flex cursor-pointer text-left w-full"
    (click)="componentClick(item)"
  >
    <div class="px-2 h-20 w-20 flex-none">
      <ng-container *ngIf="item.type || item.coverUri; else imageIcon">
        <img
          loading="lazy"
          class="h-full w-full object-contain"
          [src]="item.coverUri"
        />
      </ng-container>
    </div>

    <div class="flex flex-col justify-between flex-grow min-w-0 py-2">
      <div>
        <div class="uppercase text-xs text-gray-400 leading-none">
          {{ (item | resourceType) || "collection" }}
        </div>

        <div class="truncate text-sm font-medium">
          {{ item?.name }}
        </div>
      </div>

      <div class="uppercase text-xs text-gray-600">
        {{ item?.flavorTextSpan }}
      </div>
    </div>
  </button>
</ng-template>

<ng-template #imageIcon>
  <div class="h-full w-full flex items-center justify-center">
    <app-icon class="text-6xl text-black opacity-50" icon="folder"></app-icon>
  </div>
</ng-template>
