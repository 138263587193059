import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
} from "@angular/forms";
import { map, Subject, takeUntil } from "rxjs";
import { ModalService } from "src/app/services/modal.service";
import { BurislidesFeedbackModalComponent } from "../burislides-feedback-modal/burislides-feedback-modal.component";

@Component({
  selector: "app-exam-item-form",
  templateUrl: "./exam-item-form.component.html",
  styles: [],
})
export class ExamItemFormComponent implements OnInit {
  @Input() insideSlide: boolean = false;
  @Input() formGroup: UntypedFormGroup;
  @Output() feedback: EventEmitter<any> = new EventEmitter<any>();

  displayOptions = [
    { name: "Multiple Choice", value: "" },
    { name: "Dropdown", value: "DD" },
  ];

  inputTypes = [
    { name: "Text", value: "" },
    { name: "Date", value: "DT" },
  ];
  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalService: ModalService
  ) {}

  private unsubscribe$: Subject<void> = new Subject<void>();
  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.formGroup =
      this.formGroup ||
      this.formBuilder.group({
        itemUuid: [],
        text: [],
        title: [],
        type: [],
        subtype: [],
        mediaUrl: [],
        hint: [],
        instructions: [],
        explanation: [],
        required: [],
        showResults: [],
        shuffleChoices: [],
        weight: [],
        isGraded: [],
        neutralFeedbackMessage: [],
        neutralFeedbackImageUrl: [],
        correctFeedbackMessage: [],
        correctFeedbackImageUrl: [],
        incorrectFeedbackMessage: [],
        incorrectFeedbackImageUrl: [],
        choices: [[]],
      });

    if (
      this.formGroup.value.type == "LA" ||
      this.formGroup.value.type == "PO"
    ) {
      if (this.formGroup.value.subtype == "SU") {
        this.displayOptions = [{ name: "Survey", value: "SU" }];
      }

      this.formGroup
        .get("correctFeedbackMessage")
        .valueChanges.pipe(
          takeUntil(this.unsubscribe$),
          map((value) => {
            this.formGroup.get("incorrectFeedbackMessage").setValue(value);
          })
        )
        .subscribe();

      this.formGroup
        .get("correctFeedbackImageUrl")
        .valueChanges.pipe(
          takeUntil(this.unsubscribe$),
          map((value) => {
            this.formGroup.get("incorrectFeedbackImageUrl").setValue(value);
          })
        )
        .subscribe();
    }
  }

  onUpload(event, formControl: AbstractControl) {
    formControl.setValue(event.uri);
  }

  onUploadClear(formControl: AbstractControl) {
    formControl.setValue("");
  }

  async onEditFeedback() {
    let feedback = await this.modalService.openModal(
      BurislidesFeedbackModalComponent,
      {
        data: { formGroup: this.formGroup, insideSlide: this.insideSlide },
        panelClass: ["w-2/3"],
      }
    );

    if (feedback) this.feedback.emit(feedback);
  }
}
