<div class="flex justify-between items-start">
  <div cdkDragHandle *ngIf="isInEditMode">
    <app-icon [icon]="'unfold_more'"></app-icon>
  </div>
  <div class="rounded-2xl p-4 border-2 border-secondary-500 w-full h-20">
    <h1 class="text-primary-700 font-bold">{{ collection.name }}</h1>
    <p class="text-sm">{{ collection.description }}</p>
  </div>
  <button class="text-gray-500" *ngIf="isInEditMode">
    <app-icon [icon]="'delete'" (click)="onDeleteCatalog()"></app-icon>
  </button>
</div>
