import { Injectable } from "@angular/core";
import { UntypedFormArray, UntypedFormGroup } from "@angular/forms";
import { Apollo, gql } from "apollo-angular";
import { Observable } from "rxjs";
const EMAIL_REQS = gql`
  query EmailRequests($sort: [String!], $offset: Float, $limit: Float) {
    emailRequests(sort_requests: $sort, offset: $offset, limit: $limit) {
      count
      data {
        uuid
        subject
        sender
        send_on_date
        emailLogs {
          email_status
        }
      }
    }
  }
`;
const EMAIL_REQ = gql`
  query EmailRequest($uuid: String!) {
    emailRequest(uuid: $uuid) {
      uuid
      subject
      email_body
      send_on_date
      sender
      filters {
        key
        values
      }
      emailLogs {
        email_status
      }
    }
  }
`;
const GET_EMAIL_TEMP = gql`
  query EmailTemplates($uuid: String!) {
    emailTemplate(uuid: $uuid) {
      uuid
      name
      description
      subject
      email_body
      send_on_date
      filters {
        key
        values
      }
    }
  }
`;
const CREATE_TEMP = gql`
  mutation CreateEmailTemplate($params: TemplateParams!) {
    createEmailTemplate(params: $params) {
      name
      description
      subject
      sender
      name
      uuid
      email_body
      filters {
        key
        values
      }
    }
  }
`;
const UPDATE_TEMP = gql`
  mutation Mutation($params: TemplateParams!, $uuid: String!) {
    updateEmailTemplate(params: $params, uuid: $uuid) {
      uuid
      name
      description
      filters {
        key
        values
      }
      subject
    }
  }
`;
const DEL_TEMP = gql`
  mutation DeleteEmailTemplate($uuid: String!) {
    deleteEmailTemplate(uuid: $uuid)
  }
`;
const GET_TEMPS = gql`
  query EmailTemplates($offset: Float, $limit: Float) {
    emailTemplates(offset: $offset, limit: $limit) {
      count
      data {
        uuid
        name
        description
      }
    }
  }
`;
const UPDATE_REQ = gql`
  mutation Mutation($params: InputParams!, $uuid: String!) {
    updateEmailRequest(params: $params, uuid: $uuid) {
      uuid
      subject
      sender
      filters {
        key
        values
      }
      emailLogs {
        email_status
      }
    }
  }
`;
const DEL_REQ = gql`
  mutation DeleteEmailRequest($uuid: String!) {
    deleteEmailRequest(uuid: $uuid)
  }
`;
const CREATE_REQ = gql`
  mutation CreateEmailRequest($params: InputParams!) {
    createEmailRequest(params: $params) {
      subject
      sender
      uuid
      filters {
        key
        values
      }
      emailLogs {
        email_status
      }
    }
  }
`;
const GET_EMAILS_SUBJECT = gql`
  query Query(
    $subject: String
    $sort: [String!]
    $offset: Float
    $limit: Float
  ) {
    emailsBySubject(
      subject: $subject
      sort_requests: $sort
      offset: $offset
      limit: $limit
    ) {
      count
      data {
        uuid
        subject
        emailLogs {
          email_status
        }
        send_on_date
      }
    }
  }
`;
const GET_LOGS = gql`
  query Logs($offset: Float, $limit: Float) {
    logs(offset: $offset, limit: $limit) {
      count
      data {
        uuid
        email
        first_name
        last_name
        email_status
        course
        course_status
        schedules
        group_ids
        user_uuid
        email_req_uuid
        last_sign_in_at
        user_created_at
        created_at
        confirmation_sent_at
        confirmed_at
      }
    }
  }
`;
const GET_LOGS_ID = gql`
  query LogsByEmailRequestID(
    $uuid: String!
    $offset: Float
    $limit: Float
    $sort: [String!]
  ) {
    logsByEmailRequestID(
      email_req_uuid: $uuid
      offset: $offset
      limit: $limit
      sort_logs: $sort
    ) {
      count
      data {
        uuid
        email_req_uuid
        user_uuid
        email
        first_name
        last_name
        group_ids
        course
        schedules
        course_status
        email_status
        confirmed_at
        last_sign_in_at
        user_created_at
        created_at
        confirmation_sent_at
      }
    }
  }
`;
const FILTER_USERS = gql`
  query Query($params: InputParams!, $limit: Float, $offset: Float) {
    filterUsers(params: $params, limit: $limit, offset: $offset) {
      count
      data {
        ref_id
        user_info {
          email
          id
          confirmed_at
          last_sign_in_at
          created_at
          group_ids
          first_name
          last_name
        }
      }
    }
  }
`;
export interface Filter {
  key: string;
  values: string[];
}
export interface Params {
  filters: Filter[];
  subject?: string;
  email_body?: string;
  send_on_date?: string;
  name?: string;
  description?: string;
}
@Injectable({
  providedIn: "root",
})
export class MailerService {
  constructor(private apollo: Apollo) {}

  getFilteredUsers(offset: number, limit: number, params: Params) {
    return this.apollo.watchQuery<any>({
      query: FILTER_USERS,
      variables: { params: params, limit: limit, offset: offset },
    }).valueChanges;
  }
  getEmailLogs(offset: number, limit: number) {
    return this.apollo.watchQuery<any>({
      query: GET_LOGS,
      variables: { offset: offset, limit: limit },
    }).valueChanges;
  }
  getLogByEmailId(
    offset: number,
    limit: number,
    uuid: string,
    sortLogs?: string[]
  ) {
    return this.apollo.watchQuery<any>({
      query: GET_LOGS_ID,
      variables: {
        uuid: uuid,
        offset: offset,
        limit: limit,
        sort: sortLogs,
      },
    }).valueChanges;
  }
  getEmailRequests(
    offset: number,
    limit: number,
    sortReqs?: string[]
  ): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: EMAIL_REQS,
      variables: { offset: offset, limit: limit, sort: sortReqs },
    }).valueChanges;
  }

  getEmailRequest(mailUuid: string) {
    return this.apollo.watchQuery<any>({
      query: EMAIL_REQ,
      variables: { uuid: mailUuid },
    }).valueChanges;
  }
  getEmailsBySubject(
    subject: string,
    limit: number,
    offset: number,
    sortReqs?: string[]
  ) {
    return this.apollo.watchQuery<any>({
      query: GET_EMAILS_SUBJECT,
      variables: {
        subject: subject,
        limit: limit,
        offset: offset,
        sort: sortReqs,
      },
    }).valueChanges;
  }
  createEmailRequest(params: Params) {
    return this.apollo.mutate<any>({
      mutation: CREATE_REQ,
      variables: { params: params },
    });
  }
  updateEmailRequest(mailUuid: string, params: Params) {
    return this.apollo.mutate<any>({
      mutation: UPDATE_REQ,
      variables: { uuid: mailUuid, params: params },
    });
  }
  deleteEmailRequest(mailUuid: string) {
    return this.apollo.mutate<any>({
      mutation: DEL_REQ,
      variables: { uuid: mailUuid },
    });
  }
  getEmailTemplates(offset: number, limit: number) {
    return this.apollo.watchQuery<any>({
      query: GET_TEMPS,
      variables: { offset: offset, limit: limit },
    }).valueChanges;
  }
  getEmailTemplate(tempUuid: string) {
    return this.apollo.watchQuery<any>({
      query: GET_EMAIL_TEMP,
      variables: { uuid: tempUuid },
    }).valueChanges;
  }
  createEmailTemplate(params: Params) {
    return this.apollo.mutate<any>({
      mutation: CREATE_TEMP,
      variables: { params: params },
    });
  }
  updateEmailTemplate(tempUuid: string, params: Params) {
    return this.apollo.mutate<any>({
      mutation: UPDATE_TEMP,
      variables: { uuid: tempUuid, params: params },
    });
  }
  deleteEmailTemplate(tempUuid: string) {
    return this.apollo.mutate<any>({
      mutation: DEL_TEMP,
      variables: { uuid: tempUuid },
    });
  }
  parseFilter(_filters: UntypedFormGroup) {
    let filters = [];
    if (_filters) {
      let _key = "";
      let values = [];
      let controls = (<UntypedFormGroup>_filters.get("first")).controls;
      let _controls = (<UntypedFormArray>_filters.get("filters")).controls;

      for (const [key, value] of Object.entries(controls)) {
        _key =
          value.value === "Email"
            ? "email"
            : value.value === "User"
            ? "user"
            : value.value === "Group"
            ? "group"
            : value.value === "Course"
            ? "course"
            : value.value === "Batch"
            ? "schedule"
            : value.value === "after"
            ? "last_sign_in_at_from"
            : value.value === "before"
            ? "last_sign_in_at_to"
            : value.value === "student status"
            ? "course_status"
            : _key;

        if (
          key === "filter2" &&
          (_key === "user" || _key === "group" || _key === "course") &&
          value.value
        ) {
          values = [value.value.uuid];
          if (_key === "course") {
            filters.push({ key: _key, values: values });
          }
        }
        if (
          key === "filter3" &&
          (_key === "email" ||
            _key === "last_sign_in_at_from" ||
            _key === "last_sign_in_at_to") &&
          value.value
        ) {
          values = [value.value];
        }
        if (key === "filter4" && _key === "schedule" && value.value) {
          values = [value.value.uuid];
        }
        if (key === "filter4" && _key === "course_status" && value.value) {
          values = value.value;
        }
      }
      filters.push({ key: _key, values: values });

      if (_controls) {
        _controls.forEach((filter, index) => {
          for (const [key, value] of Object.entries(filter.value)) {
            _key =
              value === "Email"
                ? "email"
                : value === "User"
                ? "user"
                : value === "Group"
                ? "group"
                : value === "Course"
                ? "course"
                : value === "Batch"
                ? "schedule"
                : value === "after"
                ? "last_sign_in_at_from"
                : value === "before"
                ? "last_sign_in_at_to"
                : value === "student status"
                ? "course_status"
                : _key;
            if (
              key === "filter2" &&
              (_key === "user" || _key === "group" || _key === "course") &&
              value
            ) {
              values = [value["uuid"]];
              if (_key === "course") {
                filters.push({ key: _key, values: values });
              }
            }
            if (
              key === "filter3" &&
              (_key === "email" ||
                _key === "last_sign_in_at_from" ||
                _key === "last_sign_in_at_to") &&
              value
            ) {
              values = [value];
            }
            if (key === "filter4" && _key === "schedule" && value) {
              values = [value["uuid"]];
            }
            if (key === "filter4" && _key === "course_status" && value) {
              values = value as any;
            }
          }
          filters.push({ key: _key, values: values });
        });
      }
    }
    return filters;
  }
}
