import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { ModalService } from "src/app/services/modal.service";
import {
  Group,
  PassService,
  Permission,
  Role,
} from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-group-role-permissions-edit",
  templateUrl: "./group-role-permissions-edit.component.html",
  styleUrls: ["./group-role-permissions-edit.component.scss"],
})
export class GroupRolePermissionsEditComponent implements OnInit {
  group: Group;
  permissionMap: {
    [key: string]: Permission;
  };

  roles: Role[];

  formGroup: UntypedFormGroup;

  constructor(
    private passService: PassService,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private formBuilder: UntypedFormBuilder,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  async loadData() {
    let { group_uuid: groupUuid } = this.route.snapshot.queryParams;

    this.group = await this.passService
      .getGroup(groupUuid, ["roles"])
      // .getGroup(groupUuid, ["roles", "permissions"])
      .toPromise();

    // this.permissionMap = {};

    // this.group.permissions.map((permission) => {
    //   this.permissionMap[permission.id] = permission;
    // });

    // this.group.roles.forEach((role) => {
    //   role.permissions = role.permissionIds.map((id) => this.permissionMap[id]);
    // });

    // HARDCODE: There are more efficient way to do this
    this.roles = [
      ...(await this.passService
        .listRoles(null, null, null, null, ["readonly"], "any")
        .toPromise()
        .then((result) => result.roles)),
      ...(await this.passService
        .listRoles(null, null, null, null, ["custom"], "any")
        .toPromise()
        .then((result) => result.roles)),
    ];

    let selectedRoleId = this.group.roleIds.filter((roleId) =>
      this.roles.map((role) => role.id).includes(roleId)
    )[0];

    this.formGroup = this.formBuilder.group({
      selectedRoleId: [selectedRoleId || "none"],
    });
  }

  async addRole() {
    let result = await this.modalService.prompt("Enter role name");
    if (result) {
      this.passService
        .createRole({
          role: {
            name: result,
            groupIds: [this.group.id],
            applicationId: localStorage.getItem("application_id"),
          },
        })
        .subscribe((result) => {
          this.loadData();
        });
    }
  }

  async addPermission(role: Role) {
    let result = await this.modalService.prompt("Enter permission name");
    if (result) {
      this.passService
        .createPermission({
          permission: {
            name: result,
            roleIds: [role.id],
          },
        })
        .subscribe((result) => {
          this.loadData();
        });
    }
  }

  async deleteRole(role: Role) {
    let result = await this.modalService.confirm(
      `Are you sure you want to delete role ${role.name}?`
    );

    if (role.groupIds.length > 1) {
      result = await this.modalService.confirm(
        `This role is shared by ${role.groupIds.length} groups. \n ARE YOU SURE YOU WANNA PROCEED?`
      );
    }

    if (result) {
      this.passService.deleteRole(role.id).subscribe((result) => {
        this.loadData();
      });
    }
  }

  async deletePermission(permission: Permission) {
    let result = await this.modalService.confirm(
      `Are you sure you want to delete permission ${permission.name}?`
    );

    if (result) {
      this.passService.deletePermission(permission.id).subscribe((result) => {
        this.loadData();
      });
    }
  }

  async updateGroupRole() {
    let { group_uuid: groupUuid } = this.route.snapshot.queryParams;
    let selectedRoleId = this.formGroup.value.selectedRoleId;
    // let updatedRoleIds =
    //   selectedRoleId && selectedRoleId !== "none" ? [selectedRoleId] : [];

    let updatedRoleIds = this.group.roleIds.filter(
      (roleId) => !this.roles.map((role) => role.id).includes(roleId)
    );

    if (selectedRoleId) {
      updatedRoleIds.push(selectedRoleId);
    }

    if (!this.formGroup.pristine) {
      let result = await this.modalService.confirm(
        "Are you sure you want to change the role of this group?"
      );

      if (!result) {
        return;
      }
    }

    await this.passService
      .updateGroup(groupUuid, {
        group: {
          roleIds: updatedRoleIds,
        },
      })
      .toPromise();

    this.location.back();
  }
}
