<span class="text-primary-800 font-bold text-sm">
  {{ responses.length }} answers
</span>

<ul class="flex flex-wrap gap-2">
  <ng-container *ngFor="let response of responses.slice(0, 10)">
    <li
      class="p-2 rounded"
      [ngClass]="{
        'text-success-600 bg-success-100':
          showCorrectness && response.responseIsCorrect,
        'text-error-600 bg-error-100':
          showCorrectness && !response.responseIsCorrect,
        'text-gray-600 bg-gray-100': !showCorrectness
      }"
    >
      <div class="w-80">
        <div class="flex items-center">
          <app-icon icon="account_circle" class="text-4xl"></app-icon>

          <span class="ml-2 fint-bold text-gray-700">
            {{ response.responseUsernameObs | async }}
          </span>
        </div>

        <hr class="my-2" />

        <div class="line-clamp-4">
          {{ response.responseText }}
        </div>
      </div>
    </li>
  </ng-container>

  <input type="checkbox" class="peer hidden" id="labelId" />

  <label
    *ngIf="responses.length > 10"
    class="block peer-checked:hidden text-primary-600 w-100"
    for="labelId"
  >
    see more
  </label>

  <li class="hidden peer-checked:block">
    <ul class="flex flex-wrap gap-2">
      <ng-container *ngFor="let response of responses.slice(10, -1)">
        <li
          class="p-2 rounded"
          [ngClass]="{
            'text-success-600 bg-success-100':
              showCorrectness && response.responseIsCorrect,
            'text-error-600 bg-error-100':
              showCorrectness && !response.responseIsCorrect,
            'text-gray-600 bg-gray-100': !showCorrectness
          }"
        >
          <div class="w-80">
            <div class="flex items-center">
              <app-icon icon="account_circle" class="text-4xl"></app-icon>

              <span class="ml-2 fint-bold text-gray-700">
                {{ response.responseUsernameObs | async }}
              </span>
            </div>

            <hr class="my-2" />

            <div class="line-clamp-4">
              {{ response.responseText }}
            </div>
          </div>
        </li>
      </ng-container>
    </ul>
  </li>

  <label class="hidden peer-checked:block text-primary-600 w-100" for="labelId">
    see less
  </label>
</ul>
