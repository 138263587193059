import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { IdisRoutingModule } from "./idis-routing.module";
import { IdisOptionsComponent } from "./idis-options/idis-options.component";
import { IdisComponent } from "./idis.component";
import { AppCommonModule } from "../app-common-module/app-common-module.module";
import { IdisUsersComponent } from "./idis-users/idis-users.component";
import { IdisAnalyticsComponent } from "./idis-analytics/idis-analytics.component";
import { IdisUsersSettingsComponent } from "./idis-users-settings/idis-users-settings.component";
import { IdisExternalTrainingDocumentsComponent } from "./idis-external-training-documents/idis-external-training-documents.component";
import { IdisTrainingNeedsComponent } from "./idis-training-needs/idis-training-needs.component";
import { IdisUserProfileComponent } from "./idis-user-profile/idis-user-profile.component";
import { IdisFormEditorComponent } from "./idis-form-editor/idis-form-editor.component";
import { DropzoneModule } from "ngx-dropzone-wrapper";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDialogModule } from "@angular/material/dialog";
import { UserManagementModule } from "../user-management/user-management.module";
import { AddFieldModalComponent } from './components/add-field-modal/add-field-modal.component';
import { AddSubtabModalComponent } from './components/add-subtab-modal/add-subtab-modal.component';
import { RejectFieldEntryModalComponent } from './components/reject-field-entry-modal/reject-field-entry-modal.component';
import { IdisFormUserAnswersComponent } from './idis-form-user-answers/idis-form-user-answers.component';
import { IdisFormSubmissionsComponent } from './idis-form-submissions/idis-form-submissions.component';

@NgModule({
  declarations: [
    IdisOptionsComponent,
    IdisComponent,
    IdisUsersComponent,
    IdisAnalyticsComponent,
    IdisUsersSettingsComponent,
    IdisExternalTrainingDocumentsComponent,
    IdisTrainingNeedsComponent,
    IdisUserProfileComponent,
    IdisFormEditorComponent,
    AddFieldModalComponent,
    AddSubtabModalComponent,
    RejectFieldEntryModalComponent,
    IdisFormUserAnswersComponent,
    IdisFormSubmissionsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IdisRoutingModule,
    AppCommonModule,
    DropzoneModule,
    DragDropModule,
    MatDialogModule,
    UserManagementModule,
  ],
})
export class IdisModule {}
