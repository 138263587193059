import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, Subscription } from "rxjs";
export interface Header {
  name: string;
  value: string;
  width: string;
}
export interface Action {
  name: string;
  action: Observable<any>;
}
@Component({
  selector: "app-list-table-header",
  templateUrl: "./list-table-header.component.html",

  styles: [],
})
export class ListTableHeaderComponent implements OnInit {
  @Input() totalSelected: any[] = [];
  @Input() disableCheckbox: boolean = false;
  @Input() enableSort: boolean = false;
  @Input() selected: any[] = [];
  //category could be either "user", "group", or any kind of list *Must be in singular form
  @Input() category: string = "";
  // Header type requires name, value, and width fields
  // Sample: {name: "Date Added", value: "created_at", width: "w-56" }
  @Input() headers: Header[] = [];
  //Action type requires name and action fields
  @Input() batchActions: Action[] = [];

  @Output() reload = new EventEmitter<Boolean>();
  @Output() selectAll = new EventEmitter<Boolean>();

  hideCheckbox: string = "";
  checked: boolean = false;
  show: boolean = false;
  style: string[] = [];
  action: Subscription;
  navigationEvent: Subscription;
  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    if (this.disableCheckbox) {
      this.hideCheckbox = "hidden";
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selected) {
      if (
        changes.selected.currentValue.length > 0 &&
        changes.selected.previousValue !== changes.selected.currentValue
      ) {
        this.checked = true;
        this.show = false;
      } else {
        this.checked = false;
        this.show = true;
      }
    }
  }
  ngOnDestroy(): void {
    this.action?.unsubscribe();
    this.navigationEvent?.unsubscribe();
  }
  toggleHeader(event) {
    this.checked = event.target.checked;
    this.show = !this.show;
    this.selectAll.emit(this.checked);
  }
  sort(header, index) {
    let order = { order: "" };
    let desc = " desc";
    this.style[index] =
      this.style[index] === "text-primary-600" ? "" : "text-primary-600";
    for (let i = 0; i < this.style.length; i++) {
      this.style[i] = index !== i ? "" : this.style[i];
    }
    this.headers.forEach((item) => {
      if (header && header === item.value && this.style[index] === "") {
        order.order = item.value + desc;
      } else if (header === item.value) {
        order.order = item.value;
      }
    });
    this.router.navigate([], {
      relativeTo: this.route,
      replaceUrl: true,
      queryParamsHandling: "merge",
      queryParams: order,
    });
  }
  getClass(header) {
    return header;
  }
  batchAction(action: Observable<any>) {
    this.action?.unsubscribe();
    this.action = action.subscribe((result) => {
      if (!result) {
        this.reload.emit(false);
      } else this.reload.emit(true);
    });
    return;
  }
}
