<app-back-title-layout [loading]="loading">
  <div class="relative">
    <p class="text-sm text-center">Select a question block to add:</p>

    <app-select-activity-type-view
      [ignore]="['SU']"
      (select)="onTypeSelect($event)"
    >
    </app-select-activity-type-view>
  </div>
</app-back-title-layout>
