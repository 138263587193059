import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { firstValueFrom } from "rxjs";
import {
  ActivityLogService,
  VERBS,
} from "src/app/services/activity-log.service";
import { ModalService } from "src/app/services/modal.service";
import {
  CoursesService,
  Resource,
  ResourcesService,
  User,
  V1ExamsService,
} from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-resource-edit",
  templateUrl: "./resource-edit.component.html",
})
export class ResourceEditComponent implements OnInit {
  resource: Resource;
  creator: User;
  blockUuid: string;
  formGroup: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private resourcesService: ResourcesService,
    private v1ExamsService: V1ExamsService,
    private coursesService: CoursesService,
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private activityLogService: ActivityLogService
  ) {}

  ngOnInit() {
    this.load();
  }

  async load() {
    let { resource_uuid: resourceUuid, block_uuid: blockUuid } =
      this.route.snapshot.queryParams;
    this.blockUuid = blockUuid;
    this.resource = await firstValueFrom(
      this.resourcesService.getResourceByUuid(resourceUuid)
    );
    this.resource.content = this.resource.content || {};

    this.formGroup = this.formBuilder.group({
      uuid: [this.resource.uuid],
      name: [this.resource.name, Validators.required],
      description: [this.resource.description],
      coverUri: [this.resource.coverUri],
      publishedAt: [this.resource.publishedAt],
      type: [this.resource.type],
      subtype: [this.resource.subtype],
      flavorTextSpan: [this.resource.flavorTextSpan],
      tags: [this.resource.tags],
      packageUuids: this.formBuilder.array(
        this.resource["packages"].map((c) => c.uuid)
      ),
    });

    console.log(this.formGroup.value.description);
    if (!this.resource.allowedActions.includes("update")) {
      this.formGroup.disable();
    }
  }

  async updateResource() {
    try {
      this.formGroup.disable({
        emitEvent: true,
      });

      await this.updateHook();

      let result = await firstValueFrom(
        this.resourcesService.updateResourceByUuid(this.resource.uuid, {
          ...this.resource,
          ...this.formGroup.value,
        })
      );

      this.location.back();

      this.activityLogService.didResource(VERBS.updated, result);
    } catch (err) {
      this.formGroup.enable({
        emitEvent: true,
      });

      throw err;
    }
  }

  async deleteResource() {
    let result = await this.modalService.confirm(
      "Are you sure you want to delete this resource?"
    );

    if (result) {
      await this.deleteHook();

      await firstValueFrom(
        this.resourcesService.deleteResourceByUuid(this.resource.uuid)
      );
      if (this.blockUuid)
        await firstValueFrom(this.coursesService.blockDelete(this.blockUuid));
      this.activityLogService.didResource(VERBS.deleted, this.resource);

      history.go(-1);
    }
  }

  async updateHook() {
    let { block_uuid: blockUuid } = this.route.snapshot.queryParams;

    if (this.formGroup.value.type === "course") {
      await firstValueFrom(
        this.coursesService.courseUpdate(
          this.resource.content["courseDetails"]?.["courseUuid"],
          {
            title: this.formGroup.value.name,
            description: this.formGroup.value.description,
            coverUri: this.formGroup.value.coverUri,
            // isTemplateable: true,
          }
        )
      );

      await firstValueFrom(
        this.resourcesService.updateCollectionByUuid(
          this.resource.content?.["resourceCollectionUuid"],
          {
            name: `${this.formGroup.value.name} Resources`,
          }
        )
      );
    }

    //Hardcoded fix
    if (blockUuid) {
      await firstValueFrom(
        this.coursesService.blockUpdate(blockUuid, {
          title: this.formGroup.value.name,
          coverUri: this.formGroup.value.coverUri,
          content: {
            resourceDetails: {
              name: this.formGroup.value.name,
              tags: this.formGroup.value.tags,
              type: this.formGroup.value.type,
              uuid: this.formGroup.value.uuid,
              subtype: this.formGroup.value.subtype,
              cover_uri: this.formGroup.value.coverUri,
              description: this.formGroup.value.description,
              published_at: this.formGroup.value.publishedAt,
              flavor_text_span: this.formGroup.value.flavorTextSpan,
            },
          },
        })
      );
    }
  }

  async deleteHook() {
    try {
      if (
        this.formGroup.value.type === "assessment" ||
        this.formGroup.value.type === "buribook-with-activity" ||
        this.formGroup.value.type === "burislides"
      ) {
        //Delete exam first

        await firstValueFrom(
          this.v1ExamsService.examsDelete(
            this.resource.content["examDetails"]?.["examUuid"]
          )
        );
      }

      if (this.formGroup.value.type === "course") {
        //Delete course first

        await firstValueFrom(
          this.coursesService.courseDelete(
            this.resource.content["courseDetails"]?.["courseUuid"]
          )
        );
      }
    } catch (err) {}
  }
}
