import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CasteditorComponent } from "../casteditor/casteditor.component";
import { CasteditorActivityLogsComponent } from "./casteditor-activity-logs/casteditor-activity-logs.component";
import { CasteditorBlockCatalogSettingsComponent } from "./casteditor-block-catalog-settings/casteditor-block-catalog-settings.component";
import { CasteditorBlockNavigationSettingsComponent } from "./casteditor-block-navigation-settings/casteditor-block-navigation-settings.component";
import { CasteditorCastEditComponent } from "./casteditor-cast-edit/casteditor-cast-edit.component";
import { CasteditorMainComponent } from "./casteditor-main/casteditor-main.component";
import { CasteditorNavigationEditComponent } from "./casteditor-navigation-edit/casteditor-navigation-edit.component";
import { CasteditorScreenCreateComponent } from "./casteditor-screen-create/casteditor-screen-create.component";
import { CasteditorScreenEditComponent } from "./casteditor-screen-edit/casteditor-screen-edit.component";
import { CasteditorScreensSelectComponent } from "./casteditor-screens-select/casteditor-screens-select.component";
import { CasteditorMarginOutletComponent } from "./components/casteditor-margin-outlet/casteditor-margin-outlet.component";
import { CasteditorBlockButtonSearchComponent } from "./casteditor-block-button-search/casteditor-block-button-search.component";
import { CasteditorBlockButtonSearchSettingsComponent } from "./casteditor-block-button-search-settings/casteditor-block-button-search-settings.component";

const routes: Routes = [
  {
    path: "_casteditor",
    // path: "",
    component: CasteditorComponent,
    data: {
      layoutTitle: "Cast Editor",
    },
    children: [
      {
        path: "",
        component: CasteditorMainComponent,
      },
      {
        path: "activity-logs",
        component: CasteditorActivityLogsComponent,
        data: {
          layoutTitle: "Cast Editor Logs",
        },
      },
      {
        path: "navigation",
        children: [
          {
            path: "edit",
            data: {
              layoutTitle: "Navigation Bar",
            },
            children: [
              {
                path: "",
                component: CasteditorNavigationEditComponent,
              },
              {
                path: "settings",
                component: CasteditorBlockNavigationSettingsComponent,
                data: {
                  layoutTitle: "Navigation Settings",
                },
              },
            ],
          },
        ],
      },
      {
        path: "cast-edit",
        component: CasteditorCastEditComponent,
        data: {
          layoutTitle: "Cast Details",
        },
      },
      {
        path: "screens",
        children: [
          {
            path: "select",
            component: CasteditorScreensSelectComponent,
          },
          {
            path: "create",
            component: CasteditorScreenCreateComponent,
          },
          {
            path: "edit",
            component: CasteditorScreenEditComponent,
          },
        ],
      },

      {
        path: "block-catalog-settings",
        component: CasteditorBlockCatalogSettingsComponent,
        data: {
          layoutTitle: "Settings",
        },
      },
      {
        path: "block-button-search-settings",
        component: CasteditorBlockButtonSearchSettingsComponent,
        data: {
          layoutTitle: "Settings",
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CasteditorRoutingModule {}
