import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpResponse,
} from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { map, catchError, tap } from "rxjs/operators";
import { NotificationService } from "src/app/services/notification.service";

const EXCEPTIONS = [
  /\/modules\/admin\/users\/account_existence/,
  /\/services\/buricast\/v2\/domains\/.+/,
  /\/services\/buriexams\/v1\/exams\/.+/,
  /\/services\/buricourse\/courses\/[a-z0-9\-]+\/enrollemailprofile\/.*/,
  /\/services\/buricourse\/certificateprofiles\/.*/,
  /\/services\/buripass\/users\/[a-z0-9\-]+\/profile/,
];

/**
 * This interceptor handles HTTP error handling
 */
@Injectable()
export class ApiErrorInterceptor implements HttpInterceptor {
  constructor(
    private notificationService: NotificationService,
    private router: Router
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let willIntercept = true;
    EXCEPTIONS.forEach((except) => {
      if (except.test(req.url)) {
        willIntercept = false;
      }
    });

    if (!willIntercept) {
      return next.handle(req);
    }

    return next.handle(req).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 0) {
            this.notificationService.addError(new Error("Connection Error"));
          } else if (error.status !== 401) {
            let errorMessage;

            //Handle BuriPass Validations
            if (error.error?.["error"] == "validation_error") {
              let entry = Object.entries(error.error?.["error_details"])[0];
              errorMessage = `${entry[0]} ${entry[1]}`;
            } else {
              errorMessage = error.error?.["error_message"];
            }

            this.notificationService.addError(new Error(errorMessage));
          } else if (error.status === 401) {
            // Redirect to logout if 401

            this.router.navigateByUrl("/auth/logout");
          }
          return throwError(() => error);
        } else {
          return throwError(() => error);
        }
      })
    );
  }
}
