import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-reporting-chart-value",
  templateUrl: "./reporting-chart-value.component.html",
  styles: [],
})
export class ReportingChartValueComponent implements OnInit {
  @Input() data: {
    name: string;
    value: string;
    valueText?: string;
  }[] = [];

  // text-primary-600 bg-primary-200
  // text-secondary-600 bg-secondary-200
  // text-success-600 bg-success-200
  // text-accent-600 bg-accent-200
  // text-blue-600 bg-blue-200
  // text-green-600 bg-green-200
  // text-yellow-600 bg-yellow-200
  // text-orange-600 bg-orange-200
  // text-lime-600 bg-lime-200
  // text-red-600 bg-red-200

  colors = [
    "primary",
    "secondary",
    "success",
    "accent",
    "blue",
    "green",
    "yellow",
    "orange",
    "lime",
    "red",
  ];
  ngOnInit(): void {}
}
