import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styles: [
  ]
})
export class ReportingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
