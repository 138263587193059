<div class="flex gap-2">
  <input
    class="text-purple-700 form-control"
    [(ngModel)]="dateValue"
    (change)="updateDateTimeValue()"
    type="date"
    [disabled]="disabled"
  />
  <input
    class="text-purple-700 form-control"
    [(ngModel)]="timeValue"
    (change)="updateDateTimeValue()"
    type="time"
    [disabled]="disabled"
  />
</div>
