import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Group, PassService } from "src/app/sinigangnababoywithgabi";
import { DashboardService } from "src/app/services/dashboard.service";
import { filter, switchMap } from "rxjs/operators";
import { forkJoin, of } from "rxjs";

@Component({
  selector: "app-group-user-selector",
  templateUrl: "./group-user-selector.component.html",
  styleUrls: ["./group-user-selector.component.scss"],
})
export class GroupUserSelectorComponent implements OnInit {
  groups: Group[];
  filteredGroups: Group[];

  @Input()
  selectedUserUuids: string[];

  @Output()
  selectedUserUuidsChange = new EventEmitter<string[]>();

  @Output()
  singleUserChange = new EventEmitter<{
    userUuid: string;
    isSelected: boolean;
  }>();

  selectedUserUuidMap: any;

  searchString: string;

  constructor(
    private passService: PassService,
    private dashboardService: DashboardService
  ) {}

  ngOnInit() {
    this.selectedUserUuidMap = {};
    this.selectedUserUuids.forEach(
      (uuid) => (this.selectedUserUuidMap[uuid] = true)
    );

    this.dashboardService
      .getCast()
      .pipe(
        switchMap((cast) => {
          return forkJoin([
            of(cast),
            this.passService.listGroups(null, null, null, ["users_count"]),
          ]);
        }),
        switchMap(([cast, result]) => {
          return of(
            result.groups.filter(
              (group) =>
                group.parentId == cast.data.attributes.defaultGroupId ||
                group.id == cast.data.attributes.defaultGroupId
            )
          );
        })
      )
      .subscribe((result) => {
        this.groups = result;
        this.filter("");
      });
  }

  toggleGroupSelect(group: Group, value: boolean) {
    if (!group.users) {
      this.passService.getGroup(group.id, ["users"]).subscribe((result) => {
        group.users = result.users;
        group.users.forEach((user) => {
          this.selectedUserUuidMap[user.id] = value;
          this.onSingleValueChange(user.id);
        });
      });
    } else {
      group.users.forEach((user) => {
        this.selectedUserUuidMap[user.id] = value;
        this.onSingleValueChange(user.id);
      });
    }
  }

  onSingleValueChange(userId: string) {
    this.emitAllValues();
    this.emitSingleChangeValue(userId);
  }

  emitAllValues() {
    this.selectedUserUuids = Object.entries(this.selectedUserUuidMap)
      .filter(([key, value]) => value)
      .map(([key, value]) => key);

    this.selectedUserUuidsChange.emit(this.selectedUserUuids);
  }

  emitSingleChangeValue(userId: string) {
    this.singleUserChange.emit({
      userUuid: userId,
      isSelected: this.selectedUserUuidMap[userId],
    });
  }

  countSelected(group: Group) {
    return group.users?.filter((user) => this.selectedUserUuidMap[user.id])
      .length;
  }

  filter(value: string) {
    if (!value) {
      this.filteredGroups = this.groups;

      return;
    }

    this.filteredGroups = [];

    this.groups.forEach((group) => {
      if (new RegExp(value, "gi").test(group.name)) {
        this.filteredGroups.push(group);
      } else {
        let filteredUsers = [];

        group.users?.forEach((user) => {
          let name = user.firstName + " " + user.lastName + " " + user.email;
          if (new RegExp(value, "gi").test(name)) {
            filteredUsers.push(user);
          }
        });

        if (filteredUsers.length > 0) {
          this.filteredGroups.push({
            ...group,
            users: filteredUsers,
          });
        }
      }
    });
  }

  onOpen(group) {
    if (!group.users) {
      this.passService.getGroup(group.id, ["users"]).subscribe((result) => {
        group.users = result.users;
      });
    }
  }
}
