<div class="relative">
  <div class="absolute z-10 top-0 left-0 p-1">
    <app-icon icon="unfold_more" class="text-gray-600 bg-white"></app-icon>
  </div>

  <div
    class="flex font-bold overflow-hidden items-start justify-between w-full {{
      block.properties.classes?.navbar
    }}"
  >
    <img class="h-8" [src]="block.properties.logo" alt="" />
    <div class="flex">
      <span
        class="mx-1 {{ block.properties.classes?.links }}"
        *ngFor="let link of block.properties.links"
      >
        {{ link.text }}
      </span>
    </div>
  </div>
</div>
