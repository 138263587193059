import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Subject, first, firstValueFrom } from "rxjs";
import { zoomInOut } from "src/app/app-common-module/animations/zoom";
import {
  ActivityLogService,
  VERBS,
} from "src/app/services/activity-log.service";
import { DashboardService } from "src/app/services/dashboard.service";
import { ModalService } from "src/app/services/modal.service";
import { UserPreferenceService } from "src/app/services/user-preference.service";
import {
  Block,
  CastsService,
  ResourcesService,
  V2Block,
  V2CastsService,
} from "src/app/sinigangnababoywithgabi";
import { CasteditorModalAddCatalogComponent } from "../components/casteditor-modal-add-catalog/casteditor-modal-add-catalog.component";

@Component({
  selector: "app-casteditor-block-category",
  templateUrl: "./casteditor-block-category.component.html",
  styles: [],
  animations: [zoomInOut],
})
export class CasteditorBlockCategoryComponent implements OnInit {
  @Input() block: Block;
  @Input() disableEdit: boolean;
  @Output() deleteBlock: EventEmitter<any> = new EventEmitter();
  isInEditMode = false;
  v2Block: V2Block;
  isLoading = false;
  errorMessage: string;
  catalogLoaded: boolean = false;
  categoryNameLabel: string = "Categories";
  canEdit = true;
  collectionUuids = [];
  collections = [];
  private unsubscribe$: Subject<void> = new Subject<void>();
  constructor(
    private resourcesService: ResourcesService,
    private activityLogsService: ActivityLogService,
    private castsService: CastsService,
    private v2CastsService: V2CastsService,
    private userPreferenceService: UserPreferenceService,
    private modalService: ModalService,
    private dashboardService: DashboardService
  ) {}
  async ngOnInit() {
    try {
      this.isLoading = true;
      await this.fetchData();
    } catch (err) {
      this.errorMessage =
        err.error?.errorDescription ||
        err.error?.["error_description"] ||
        err.message; // Until hindi pa camelized si error object
    } finally {
      this.isLoading = false;
    }
  }
  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
  async fetchData() {
    // Get the V2Block
    this.collections = [];
    this.v2Block = await firstValueFrom(
      this.v2CastsService.blockGet(this.block.id + "")
    );
    this.collectionUuids =
      this.v2Block.data.attributes.properties.collectionUuids || [];

    this.collections = await Promise.all(
      this.collectionUuids.map(async (uuid) => {
        return firstValueFrom(this.resourcesService.getCollectionByUuid(uuid));
      })
    );
  }
  onDeleteBlock() {
    this.deleteBlock.emit();
  }
  async onDeleteCatalog(collectionUuid) {
    if (
      await this.modalService.confirm(
        "Are you sure you want to remove this catalog?"
      )
    ) {
      this.collections = this.collections.filter(
        (r) => r.uuid !== collectionUuid
      );
      this.collectionUuids = this.collectionUuids.filter(
        (r) => r !== collectionUuid
      );
      let removedCollection = this.collections.find(
        (r) => r.uuid === collectionUuid
      );
      let cast = await firstValueFrom(this.dashboardService.getCast());
      this.block.properties.collectionUuids = this.collectionUuids;
      await this.updateBlock();
    }
  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      event.container.data,
      event.previousIndex,
      event.currentIndex
    );
    moveItemInArray(
      this.collectionUuids,
      event.previousIndex,
      event.currentIndex
    );

    this.savePosition();
  }

  onEditToggle(value) {
    this.isInEditMode = value;
    // this.multiSelect.clearMultiselect();
    if (!this.isInEditMode) {
      // firstValueFrom(
      //   this.resourcesService.updateCollectionByUuid(
      //     this.block.properties.uuid,
      //     {
      //       name: this.collectionNameLabel,
      //     }
      //   )
      // );
    }
  }

  async onAddCatalog() {
    let addCatalog = await this.modalService.openModal(
      CasteditorModalAddCatalogComponent,
      {
        panelClass: ["sm:w-1/2", "w-3/5", "h-4/5"],
      }
    );
    if (addCatalog) {
      this.collectionUuids.push(addCatalog);
      this.block.properties.collectionUuids = this.collectionUuids;
      await this.updateBlock();
      this.collections.push(
        await firstValueFrom(
          this.resourcesService.getCollectionByUuid(addCatalog)
        )
      );
    }
  }

  async updateBlock() {
    let cast = await firstValueFrom(this.dashboardService.getCast());
    await firstValueFrom(
      this.castsService.blockUpdate(
        cast.data.id,
        this.block.screenId + "",
        this.block.id + "",
        { block: this.block }
      )
    );
  }
  savePosition() {
    // this.resourcesService
    //   .setComponentOrderInCollection(this.block.properties.uuid, {
    //     order: this.resources.map((resource) => resource.uuid),
    //   } as any)
    //   .subscribe((_) => {});
    this.block.properties.collectionUuids = this.collectionUuids;
    this.updateBlock();
    this.activityLogsService.didCastBlock(VERBS.updated, this.block);
  }
}
