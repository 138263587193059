import { Component } from '@angular/core';

@Component({
  selector: 'app-tis',
  templateUrl: './tis.component.html',
  styles: [
  ]
})
export class TisComponent {

}
