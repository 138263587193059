<app-item-list [items]="data" [itemTemplate]="itemTemplate"> </app-item-list>

<ng-template #itemTemplate let-item="item" let-index="index">
  <div class="my-2 flex gap-2">
    <img
      [src]="item.coverUri"
      class="object-contain w-20 h-20 bg-gray-300"
      alt=""
    />

    <div>
      <app-icon
        class="text-gray-400"
        [icon]="item | resourceTypeIcon"
      ></app-icon>

      <div class="leading-none font-bold">
        {{ item.name }}
      </div>
    </div>
  </div>

  <progress
    class="w-full
        bg-{{ colors[index % colors.length] }}-200
        rounded-full
        h-3
    "
    id="file"
    [max]="item.maxValue"
    [value]="item.value"
  >
    {{ (item.value / item.maxValue) * 100 }}%
  </progress>

  <div
    class="flex justify-between text-{{ colors[index % colors.length] }}-600"
  >
    <span> TIME SPENT: {{ item.valueText }} </span>

    <span class="font-bold">
      {{ (item.value / item.maxValue) * 100 | number : "1.2-2" }}%
    </span>
  </div>
</ng-template>
