<div class="w-full">
  <h2 class="text-center font-bold text-2xl mb-4">Link</h2>
  <label class="form-label">
    Paste Link*
    <input
      type="text"
      class="form-control"
      placeholder="Link"
      [(ngModel)]="inputLink"
    />
  </label>

  <div class="flex justify-end">
    <button
      class="btn text-white bg-primary-500"
      type="button"
      (click)="onSave()"
    >
      save
    </button>
  </div>
</div>
