import { Apollo, gql } from "apollo-angular";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { from, Observable, of } from "rxjs";
import { switchMap, map, mergeMap, toArray, catchError } from "rxjs/operators";
import { FilterPipePipe } from "src/app/app-common-module/pipes/filter-pipe.pipe";
import { CsvService } from "src/app/services/csv.service";
import {
  CoursesService,
  PassService,
  V1ExamsService,
} from "src/app/sinigangnababoywithgabi";

interface SubmissionItem {
  userName: string;
  submissionData: {
    submissionUuid: string;
    submissionDate: string;
    submissionScore: string | number;
  }[];
}

@Component({
  selector: "app-exam-user-submissions",
  templateUrl: "./exam-user-submissions.component.html",
  styleUrls: ["./exam-user-submissions.component.scss"],
})
export class ExamUserSubmissionsComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private passService: PassService,
    private v1ExamsService: V1ExamsService,
    private apollo: Apollo,
    private csvService: CsvService,
    private coursesService: CoursesService
  ) {}

  rawData: any;
  submissionList: SubmissionItem[];
  searchString: string;
  loading = true;

  listResultObservable: Observable<any>;
  downloadCsvObservable: Observable<any>;
  filterPipe = new FilterPipePipe();

  async ngOnInit() {
    this.listResultObservable = this.loadItems();
    this.initDownloadCsvObs();
  }

  fetchData() {
    let { timeslot_uuid: timeslotUuid } = this.route.snapshot.queryParams;

    return this.apollo
      .query({
        query: gql`
          query {
            admin{
              timeslot(uuid:"${timeslotUuid}"){
                uuid
                submissions_basic{
                  user_uuid
                  user{
                    first_name
                    last_name
                  }
                  submissions{
                    submission_uuid
                    tally_first
                    created_at
                  }
                }
              }
            }
          }
        `,
      })
      .pipe(
        map((result) => {
          let data = result.data as any;

          return data.admin.timeslot.submissionsBasic
            .filter((sub) => sub.user)
            .map((sub) => {
              return {
                userName: `${sub.user.firstName} ${sub.user.lastName}`,
                userId: `${sub.userUuid}`,
                submissionData: sub.submissions.map((s) => {
                  return {
                    submissionUuid: s.submissionUuid,
                    submissionDate: s.createdAt,
                    submissionScore: s.tallyFirst,
                  };
                }),
              };
            });
        })
      );
  }

  loadItems() {
    return of({}).pipe(
      map(() => {
        this.loading = true;
      }),
      switchMap(() => this.fetchData()),
      switchMap((result) => {
        return this.route.queryParams.pipe(
          map((params) => {
            let query = params.q;

            return this.filterPipe.transform(result, query);
          })
        );
      }),
      map((result) => {
        if (result.length > 0) {
          this.loading = false;
        }
        console.log(result);
        return result;
      })
    );
  }

  initDownloadCsvObs() {
    let { timeslot_uuid: timeslotUuid } = this.route.snapshot.queryParams;

    this.downloadCsvObservable = this.v1ExamsService
      .examTimeslotSubmissionsHistory(timeslotUuid)
      .pipe(
        switchMap((result) => from(result)),
        mergeMap((result) => {
          return this.passService.getUser(result.user).pipe(
            map((user) => {
              return {
                email: user.email,
                name: `${user.firstName} ${user.lastName}`,
                ...result,
              };
            }),
            catchError((err) => {
              return of({ email: null, name: null, ...result });
            })
          );
        }, 5),
        toArray(),

        switchMap((result) => {
          return this.csvService.downloadAsCsv(
            result,
            `exam-${timeslotUuid}-${Date.now()}.csv`
          );
        })
      );
  }

  // async downloadCsv() {
  //   this.csvLoading = true;

  //   try {
  //     let { timeslot_uuid: timeslotUuid, exam_uuid: examUuid } =
  //       this.activatedRoute.snapshot.queryParams;

  //     let submissions: any[];

  //     // this.examService.examTimeslotSubmissionsList(params["timeslotUuid"]).subscribe(res => {
  //     //   console.log(res)
  //     // })
  //     let res = await this.dumpService
  //       .dumpSubmissionWithUsers(timeslotUuid, "tally_first")
  //       .toPromise();

  //     this.rawData = res;
  //     submissions = [];

  //     for (let e in res) {
  //       let submission = res[e];

  //       let newSubmission = {
  //         name: `${submission.user?.firstName} ${submission.user?.lastName}`,
  //         email: submission.user.email,
  //         submissionUuid: submission.submission.uuid,
  //         score: submission.submission["tallyFirst"],
  //         submissionDate: submission.submission.createdAt,
  //       };
  //       submissions.push(newSubmission);
  //     }

  //     let examItems: V1ExamItem[] = await this.apollo
  //       .query({
  //         query: gql`
  //           query {
  //             admin{
  //               exam(uuid:"${examUuid}"){
  //                 sections{
  //                   items{
  //                     uuid
  //                     text
  //                     choices{
  //                       uuid
  //                       short_input
  //                       long_input
  //                       media_url
  //                     }
  //                   }
  //                 }
  //               }
  //             }
  //           }
  //         `,
  //       })
  //       .toPromise()
  //       .then((result: any) => {
  //         return result.data.admin.exam.sections[0].items;
  //       });

  //     let submissionCsv = [];

  //     this.rawData.forEach((entry) => {
  //       let { user, submission } = entry;
  //       let itemResponseMap = {};

  //       submission.responses.forEach((response: V1ExamResponse) => {
  //         itemResponseMap[response.item] = response;
  //       });

  //       let csvItem = {
  //         Name: `${user?.firstName} ${user?.lastName}`,
  //         Email: user.email,
  //         "Submission Entry": submission.createdAt,
  //         Score: submission["tallyFirst"],
  //       };

  //       examItems.forEach((item) => {
  //         let response: V1ExamResponse = itemResponseMap[item.uuid];

  //         if (response) {
  //           let choice = "";

  //           if (response.choices.length) {
  //             let itemChoice: V1ExamChoice = item["choices"].find(
  //               (c) => c.uuid === response.choices[0]
  //             );

  //             choice =
  //               itemChoice?.longInput ||
  //               itemChoice?.shortInput ||
  //               itemChoice?.mediaUrl;
  //           }

  //           csvItem[item.text] =
  //             response["shortInput"] || response["longInput"] || choice;
  //         } else {
  //           csvItem[item.text] = "";
  //         }
  //       });

  //       submissionCsv.push(csvItem);
  //     });

  //     this.csvService.downloadAsCsv(
  //       submissionCsv,
  //       `exam-${examUuid}-${Date.now()}.csv`
  //     );
  //   } catch (err) {}

  //   this.csvLoading = false;
  // }
}
