import { DatePipe } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-reporting-chart-line",
  templateUrl: "./reporting-chart-line.component.html",
  styles: [],
})
export class ReportingChartLineComponent implements OnInit {
  @Input() data: any[] = [];

  convertedChartData: {
    name: string;
    value: number;
  }[] = [];

  constructor(private datePipe: DatePipe) {}

  ngOnInit(): void {
    this.convertedChartData = this.data?.map((d) => {
      return {
        name: this.datePipe.transform(d.date, "LLL d"),
        value: d.count,
      };
    });
  }

  yAxisTickFormatting = (tick: any) => {
    return Math.round(tick);
  };
}
