import { ComponentType } from "@angular/cdk/portal";
import { Injectable, TemplateRef } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { firstValueFrom } from "rxjs";
import { AlertDialogComponent } from "../app-common-module/components/alert-dialog/alert-dialog.component";
import { ConfirmDialogComponent } from "../app-common-module/components/confirm-dialog/confirm-dialog.component";
import { IconSelectDialogComponent } from "../app-common-module/components/icon-select-dialog/icon-select-dialog.component";
import { PromptDialogComponent } from "../app-common-module/components/prompt-dialog/prompt-dialog.component";

@Injectable({
  providedIn: "root",
})
export class ModalService {
  constructor(private dialog: MatDialog) {}

  openModal(
    componentOrTemplate: ComponentType<any> | TemplateRef<any>,
    dialogOptions?: MatDialogConfig
  ) {
    let dialogRef = this.dialog.open(componentOrTemplate, {
      panelClass: "",
      ...dialogOptions,
    });
    return firstValueFrom(dialogRef.afterClosed());
  }

  confirm(
    message: string,
    options: {
      confirmText?: string;
      cancelText?: string;
      confirmClass?: string;
      cancelClass?: string;
    } = {}
  ) {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      closeOnNavigation: true,
    });

    dialogRef.componentInstance.message = message;

    Object.assign(dialogRef.componentInstance, options);

    return firstValueFrom(dialogRef.afterClosed());
  }

  alert(message: string) {
    let dialogRef = this.dialog.open(AlertDialogComponent, {
      closeOnNavigation: true,
    });

    dialogRef.componentInstance.message = message;

    return firstValueFrom(dialogRef.afterClosed());
  }

  prompt(
    message: string,
    options: { defaultValue?: string } = {}
  ): Promise<string> {
    let dialogRef = this.dialog.open(PromptDialogComponent, {
      closeOnNavigation: true,
    });

    dialogRef.componentInstance.message = message;
    dialogRef.componentInstance.defaultValue = options.defaultValue;

    return firstValueFrom(dialogRef.afterClosed());
  }

  selectIcon() {
    let dialogRef = this.dialog.open(IconSelectDialogComponent, {
      closeOnNavigation: true,
    });

    return firstValueFrom(dialogRef.afterClosed());
  }
}
