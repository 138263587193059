import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Subject, firstValueFrom, takeUntil } from "rxjs";
import {
  Frame,
  ReportsService,
  Snapshot,
} from "src/app/sinigangnababoywithgabi";

interface FrameData {
  id: string;
  name?: string;
  chartType?: string;
  data?: any;
}

@Component({
  selector: "app-reporting-snapshot-frames-display",
  templateUrl: "./reporting-snapshot-frames-display.component.html",
  styles: [],
})
export class ReportingSnapshotFramesDisplayComponent implements OnInit {
  snapshot: Snapshot;
  frameData: FrameData[];

  constructor(
    private reportsService: ReportsService,
    private route: ActivatedRoute
  ) {}

  private unsubscribe$: Subject<void> = new Subject<void>();
  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  async ngOnInit() {
    let { snapshot_id: snapshotId } = this.route.snapshot.queryParams;

    this.loadSnapshot(snapshotId);
  }

  async loadSnapshot(snapshotId: string) {
    this.snapshot = await firstValueFrom(
      this.reportsService.snapshotsGet(snapshotId)
    );

    this.frameData = this.snapshot.frames.map((frame) => {
      return {
        id: frame.id,
        name: frame.name,
        chartType: frame.chartType,
        data: frame.data,
      };
    });
  }

  printPage() {
    window.print();
  }
}
