<app-back-title-layout [loading]="!course" [hideLogoHeader]="true">
  <div backTitleLayoutBeforeMain>
    <section class="w-full flex items-center">
      <img
        [src]="resource?.coverUri || course?.coverUri"
        alt=""
        class="h-28 w-28 object-contain bg-gray-200 mr-2"
      />

      <div class="flex-1">
        <p>
          <app-icon icon="school"></app-icon>
        </p>

        <h3 class="text-2xl font-bold">{{ course?.title }}</h3>
      </div>

      <a
        class="btn btn-icon bg-transparent text-primary-700 self-start mx-2"
        [routerLink]="['activity-logs']"
        [queryParams]="{
          resource_uuid: resource?.uuid,
          course_uuid: course?.uuid
        }"
      >
        <app-icon icon="list_alt"></app-icon>
      </a>

      <a
        [routerLink]="['edit']"
        [queryParams]="{
          resource_uuid: resource?.uuid,
          course_uuid: course?.uuid
        }"
        class="btn btn-primary btn-icon self-start"
      >
        <app-icon icon="edit"></app-icon>
      </a>
    </section>

    <app-markdown-viewer
      class="my-2"
      styles="line-clamp-3"
      [value]="resource?.description"
    ></app-markdown-viewer>
  </div>

  <a
    class="btn btn-info inline-block"
    [routerLink]="['/_courses', 'content-edit']"
    [queryParams]="{
      course_uuid: course?.uuid,
      resource_collection_uuid: resource?.content?.resourceCollectionUuid,
      resource_uuid: resource?.uuid
    }"
  >
    <app-icon icon="edit"></app-icon>
    Edit course content
  </a>

  <ng-container *ngIf="course">
    <section class="my-10">
      <h4 class="text-xl font-bold">Analytics</h4>

      <div class="relative">
        <app-loading-with-retry
          [observable]="overviewObservable"
          [tryOnLoad]="true"
        ></app-loading-with-retry>

        <div class="flex justify-between gap-2 my-2">
          <div
            class="leading-tight w-full p-2 bg-purple-200 text-purple-600 font-bold rounded"
          >
            <div class="uppercase text-xs">Enrolled</div>
            <div class="text-lg">
              {{ completionStats?.students }}
            </div>
          </div>

          <div
            class="leading-tight w-full p-2 bg-green-200 text-green-600 font-bold rounded"
          >
            <div class="uppercase text-xs">Passed</div>
            <div class="text-lg">
              {{ completionStats?.completed }}
            </div>
          </div>

          <div
            class="leading-tight w-full p-2 bg-green-300 text-green-700 font-bold rounded"
          >
            <div class="uppercase text-xs">Certificates Issued</div>
            <div class="text-lg">
              {{ completionStats?.certificatesIssued }}
            </div>
          </div>
        </div>

        <div class="flex justify-between my-2">
          <div
            class="leading-tight w-full p-2 bg-gray-200 text-gray-600 font-bold rounded"
          >
            <div class="uppercase text-xs">Not started</div>
            <div class="text-lg">
              {{ completionStats?.notStarted }}
            </div>
          </div>

          <div
            class="mx-2 leading-tight w-full p-2 bg-blue-200 text-blue-600 font-bold rounded"
          >
            <div class="uppercase text-xs">In progress</div>
            <div class="text-lg">
              {{ completionStats?.inProgress }}
            </div>
          </div>
          <div
            class="leading-tight w-full p-2 bg-red-200 text-red-600 font-bold rounded"
          >
            <div class="uppercase text-xs">Needs attention</div>
            <div class="text-lg">
              {{ completionStats?.needsAttention }}
            </div>
          </div>
        </div>
      </div>
    </section>
    <nav class="flex w-full mb-4">
      <a
        class="border-b-4 inline-block py-2 border-gray-300 text-gray-300 px-4 font-bold uppercase whitespace-nowrap text-base"
        [routerLink]="['/_courses', 'profile']"
        [replaceUrl]="true"
        [queryParamsHandling]="'merge'"
        [queryParams]="{ status: 'ongoing', page: null }"
        routerLinkActive="text-secondary-600 border-secondary-600 "
      >
        <app-icon icon="date_range" class="mr-2"></app-icon>

        ongoing
      </a>

      <a
        class="border-b-4 inline-block py-2 border-gray-300 text-gray-300 px-4 font-bold uppercase text-base whitespace-nowrap"
        [routerLink]="['/_courses', 'profile']"
        [replaceUrl]="true"
        [queryParamsHandling]="'merge'"
        [queryParams]="{ status: 'queue', page: null }"
        routerLinkActive="text-secondary-600 border-secondary-600 "
      >
        <app-icon icon="date_range" class="mr-2"></app-icon>

        queued
      </a>
      <a
        class="border-b-4 inline-block py-2 border-gray-300 text-gray-300 px-4 font-bold uppercase text-base whitespace-nowrap"
        [routerLink]="['/_courses', 'profile']"
        [replaceUrl]="true"
        [queryParamsHandling]="'merge'"
        [queryParams]="{ status: 'done', page: null }"
        routerLinkActive="text-secondary-600 border-secondary-600 "
      >
        <app-icon icon="date_range" class="mr-2"></app-icon>

        done
      </a>
      <div class="border-b-4 border-gray-300 flex-1"></div>
    </nav>
    <section class="my-10">
      <h4 class="text-xl font-bold">Batches</h4>

      <button
        [routerLink]="['batches', 'create-batches']"
        [queryParams]="{ course_uuid: course.uuid }"
        queryParamsHandling="merge"
        class="btn btn-primary my-5"
      >
        + ADD MORE BATCH
      </button>

      <div class="relative my-5">
        <app-loading-with-retry
          class="h-24"
          [observable]="scheduleTableObservable"
          [tryOnLoad]="true"
        ></app-loading-with-retry>

        <ng-container *ngIf="scheduleTable">
          <div class="mb-5">
            <app-search-bar-query
              #search
              placeholder="Search"
            ></app-search-bar-query>
          </div>

          <app-item-select-with-header
            [source]="scheduleTableObservable"
            key="scheduleUuid"
            [itemTemplate]="itemTemplate"
            [batchActions]="batchActions"
            (selectedItemList)="onSelectedItemList($event)"
            checkBoxAlignment="start"
            [enableSort]="true"
            category="batch"
            [limit]="limit"
            [totalCount]="totalBatchCount"
            [headers]="[{ name: 'Name', value: 'name', width: 'w-96' }]"
            [loading]="loading"
          ></app-item-select-with-header>

          <!-- <app-item-select
            [items]="
              scheduleTableObservable | async | filterAll: search.value:['name']
            "
            [itemTemplate]="itemTemplate"
            checkBoxAlignment="start"
          ></app-item-select> -->
        </ng-container>
      </div>
    </section>
  </ng-container>
</app-back-title-layout>

<ng-template #itemTemplate let-item="item">
  <a
    [routerLink]="['batch-profile']"
    [queryParams]="{
      schedule_uuid: item.schedule.uuid,
      course_uuid: course.uuid
    }"
    class="block ml-4"
  >
    <div class="text-gray-600 flex">
      <span class="flex-1 text-lg"
        ><app-icon class="" icon="date_range"></app-icon> {{ item.name }}</span
      >

      <app-pill-label [type]="item.pillType" class="uppercase">
        {{ item.statusText }}
      </app-pill-label>

      <span class="font-bold mx-1"> {{ item.dateText }}</span>
    </div>

    <div
      *ngIf="item.completionStatsObservable | async as batchCompletionStats"
      class="flex justify-between my-5 text-sm gap-2"
    >
      <div
        class="leading-tight w-full p-2 bg-purple-200 text-purple-600 rounded"
      >
        <div class="uppercase text-xs">Enrolled</div>
        <div class="font-bold">
          {{ batchCompletionStats?.students }}
        </div>
      </div>

      <div class="leading-tight w-full p-2 bg-green-200 text-green-600 rounded">
        <div class="uppercase text-xs">Passed</div>
        <div class="font-bold">
          {{ batchCompletionStats?.completed }}
        </div>
      </div>

      <div class="leading-tight w-full p-2 bg-green-300 text-green-700 rounded">
        <div class="uppercase text-xs">Certificates Issued</div>
        <div class="font-bold">
          {{ batchCompletionStats?.certificatesIssued }}
        </div>
      </div>

      <div class="leading-tight w-full p-2 bg-gray-200 text-gray-600 rounded">
        <div class="uppercase text-xs">Not started</div>
        <div class="font-bold">
          {{ batchCompletionStats?.notStarted }}
        </div>
      </div>

      <div class="leading-tight w-full p-2 bg-blue-200 text-blue-600 rounded">
        <div class="uppercase text-xs">In progress</div>
        <div class="font-bold">
          {{ batchCompletionStats?.inProgress }}
        </div>
      </div>
      <div class="leading-tight w-full p-2 bg-red-200 text-red-600 rounded">
        <div class="uppercase text-xs">Needs attention</div>
        <div class="font-bold">
          {{ batchCompletionStats?.needsAttention }}
        </div>
      </div>
    </div>
  </a>
</ng-template>
