import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ColorHelperService } from "src/app/services/color-helper.service";
import { ModalService } from "src/app/services/modal.service";
import { Resource, ResourcesService } from "src/app/sinigangnababoywithgabi";
import { Subject, firstValueFrom, switchMap, takeUntil, tap } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";

@Component({
  selector: "app-burislides-appearance",
  templateUrl: "./burislides-appearance.component.html",
  styles: [],
})
export class BurislidesAppearanceComponent implements OnInit {
  formGroup: UntypedFormGroup;
  resource: Resource;
  constructor(
    private fb: UntypedFormBuilder,
    private colorHelperService: ColorHelperService,
    private modalService: ModalService,
    private resourceService: ResourcesService,
    private route: ActivatedRoute,
    private location: Location
  ) {}
  ngOnInit() {
    this.route.queryParams
      .pipe(
        switchMap((params) => {
          let { publish_resource_on_next, resource_uuid: resourceUuid } =
            params;
          return this.resourceService.getResourceByUuid(resourceUuid);
        }),
        tap((resource) => {
          this.resource = resource;
          let appearance = resource?.content?.appearance;
          this.formGroup = this.fb.group({
            baseBackgroundColor: [
              this.colorHelperService.constructRGBAFromCastColor(
                appearance?.base?.backgroundColor
              ) || "rgba(0,0,0,1)",
            ],
            baseForegroundColor: [
              this.colorHelperService.constructRGBAFromCastColor(
                appearance?.base?.foregroundColor
              ) || "rgba(0,0,0,1)",
            ],
          });
          this.formGroup
            .get("baseBackgroundColor")
            .valueChanges.pipe(takeUntil(this.unsubscribe$))
            .subscribe((control) => {
              this.setSlideColor();
            });
          this.formGroup
            .get("baseForegroundColor")
            .valueChanges.pipe(takeUntil(this.unsubscribe$))
            .subscribe((control) => {
              this.setSlideColor();
            });
        })
      )
      .subscribe();
  }
  private unsubscribe$ = new Subject();
  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
  async onSave() {
    let appearance = {
      base: {
        foregroundColor: this.colorHelperService.constructCastColor(
          this.formGroup.value.baseForegroundColor
        ),
        backgroundColor: this.colorHelperService.constructCastColor(
          this.formGroup.value.baseBackgroundColor
        ),
      },
    };
    this.resource.content.appearance = appearance;

    await firstValueFrom(
      this.resourceService.updateResourceByUuid(
        this.route.snapshot.queryParams["resource_uuid"],
        this.resource
      )
    );
    this.location.back();
  }

  setSlideColor() {
    let appearance = {
      base: {
        foregroundColor: this.colorHelperService.constructCastColor(
          this.formGroup.value.baseForegroundColor
        ),
        backgroundColor: this.colorHelperService.constructCastColor(
          this.formGroup.value.baseBackgroundColor
        ),
      },
    };
    this.resource.content.appearance = appearance;
  }

  async resetToDefault() {
    let result = await this.modalService.confirm(
      "Are you sure you want to reset settings to default?"
    );
    if (result) {
      this.formGroup.reset({
        baseForegroundColor: "rgba(0,0,0,1)",
        baseBackgroundColor: "rgba(255,255,255,1)",
      });
    }
  }
}
