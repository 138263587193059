import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  FormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { filter } from "jszip";
import { Key } from "protractor";
interface date {
  month: string;
  day: string;
  year: string;
}
@Component({
  selector: "app-filters",
  templateUrl: "./filters.component.html",
  styles: [],
})
export class FiltersComponent implements OnInit {
  @Output() filters = new EventEmitter<any>();
  @Output() close = new EventEmitter<any>();
  selectDefault = true;
  selected = [];
  selectedDate: date[] = [];
  months = [
    { name: "JAN", value: 1, days: 31 },
    { name: "FEB", value: 2, days: 28 },
    { name: "MAR", value: 3, days: 31 },
    { name: "APR", value: 4, days: 30 },
    { name: "MAY", value: 5, days: 31 },
    { name: "JUNE", value: 6, days: 30 },
    { name: "JULY", value: 7, days: 31 },
    { name: "AUG", value: 8, days: 31 },
    { name: "SEP", value: 9, days: 30 },
    { name: "OCT", value: 10, days: 31 },
    { name: "NOV", value: 11, days: 30 },
    { name: "DEC", value: 12, days: 31 },
  ];
  years: Array<number> = [];
  days: Array<number> = [];
  fields = [
    { name: "Email", value: "email" },
    { name: "First Name", value: "first_name" },
    { name: "Last Name", value: "last_name" },
    { name: "Date Added", value: "date" },
    { name: "Last Sign In", value: "last_signin" },
    // { field: "Middle Name", value: "middleName" },
    // { field: "Place of Birth", value: "birthplace" },
    // { field: "Citizenship", value: "citizenship" },
    // { field: "Address", value: "address" },
    // { field: "Telephone no.", value: "phoneNumber" },
    // { field: "Cellphone no.", value: "mobileNumber" },
    // { field: "Company/Agency", value: "company" },
    // { field: "Designation", value: "designation" },
    // { field: "Sex", value: "sex" },
    // { field: "Civil Status", value: "civilStatus" },
    // { field: "Company Category", value: "companyCategory" },
  ];
  form: UntypedFormGroup;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const date = new Date();
      for (let i = date.getFullYear(); i >= 1970; i--) {
        this.years.push(i);
      }
      this.form = new UntypedFormGroup({
        filter: new UntypedFormArray([], [Validators.minLength(1)]),
      });
      let filterParams = Object.keys(params)
        .filter((key) => this.checkFilter(key))
        .reduce((obj, key) => {
          obj[key] = params[key];
          return obj;
        }, {});
      Object.keys(filterParams).map((key, index) => {
        this.addFilter();
        this.setOption(key, index);
        (<UntypedFormArray>this.form.get("filter"))
          .at(index)
          .get("option")
          .setValue(key);
        if (key === "date" || key === "last_signin") {
          let year, month, day;
          [year, month, day] = params[key].split("-");
          this.setMonth(month, index);
          this.setDay(day, index);
          this.setYear(year, index);
          (<UntypedFormArray>this.form.get("filter"))
            .at(index)
            .get("year")
            .setValue(year);
          (<UntypedFormArray>this.form.get("filter"))
            .at(index)
            .get("month")
            .setValue(month);
          (<UntypedFormArray>this.form.get("filter"))
            .at(index)
            .get("day")
            .setValue(day);
        } else if (
          key === "first_name" ||
          key === "last_name" ||
          key === "email"
        ) {
          (<UntypedFormArray>this.form.get("filter"))
            .at(index)
            .get("filter")
            .setValue(params[key]);
        }
      });
    });
  }

  checkFilter(param) {
    let isEqual = false;
    for (let i = 0; i < this.fields.length; i++) {
      isEqual = this.fields[i].value === param;
      if (isEqual) break;
    }
    return isEqual;
  }
  get _filters() {
    return (<UntypedFormArray>this.form.get("filter")).controls;
  }
  toggleClose() {
    this.close.emit(true);
  }
  addFilter() {
    const filter = this.fb.group({
      option: this.fb.control(null, Validators.required),
      filter: this.fb.control(null, [Validators.required]),
      month: this.fb.control(null, [Validators.required]),
      day: this.fb.control(null, [Validators.required]),
      year: this.fb.control(null, [Validators.required]),
    });

    (<UntypedFormArray>this.form.get("filter")).push(filter);
    this.selectedDate.push({ month: "", day: "", year: "" });
  }
  onSubmit() {
    let firstName = "";
    let lastName = "";
    let email = "";
    let emailsLength = 0;
    let firstNamesLength = 0;
    let lastNamesLength = 0;
    let date = null;
    let lastSignIn = null;
    let params = {};
    let filterLength = (<UntypedFormArray>this.form.get("filter")).controls
      .length;

    if (filterLength > 0) {
      for (let i = 0; i < filterLength; i++) {
        if (this.selected[i] === "first_name") {
          firstNamesLength++;
        } else if (this.selected[i] === "last_name") {
          lastNamesLength++;
        } else if (this.selected[i] === "email") {
          emailsLength++;
        }
      }
      for (let i = 0; i < filterLength; i++) {
        let firstNameCounter = 0;
        let lastNameCounter = 0;
        let emailCounter = 0;
        let control = (<UntypedFormArray>this.form.get("filter")).controls[i];
        if (
          this.selectedDate[i].month &&
          this.selectedDate[i].day &&
          this.selectedDate[i].year
        ) {
          if (this.selected[i] === "date") {
            date = `${this.selectedDate[i].year}-${this.selectedDate[i].month}-${this.selectedDate[i].day}`;
          } else
            lastSignIn = `${this.selectedDate[i].year}-${this.selectedDate[i].month}-${this.selectedDate[i].day}`;
        }

        if (this.selected[i] === "first_name") {
          firstNameCounter++;
          if (
            control.get("filter").value &&
            firstNameCounter === firstNamesLength
          ) {
            firstName += control.get("filter").value;
          } else {
            firstName += control.get("filter").value + " ";
          }
        }
        if (this.selected[i] === "last_name") {
          lastNameCounter++;
          if (
            control.get("filter").value &&
            lastNameCounter === lastNamesLength
          ) {
            lastName += control.get("filter").value;
          } else {
            lastName += control.get("filter").value + " ";
          }
        }
        if (this.selected[i] === "email") {
          emailCounter++;
          if (control.get("filter").value && emailCounter === emailsLength) {
            email += control.get("filter").value;
          } else {
            email += control.get("filter").value + " ";
          }
        }
      }
    }

    firstName = firstName ? firstName : null;
    lastName = lastName ? lastName : null;
    email = email ? email : null;
    date = date ? date : null;
    lastSignIn = lastSignIn ? lastSignIn : null;
    params = {
      first_name: firstName,
      last_name: lastName,
      email,
      date,
      last_signin: lastSignIn,
    };

    this.router.navigate([], {
      relativeTo: this.route,
      replaceUrl: true,
      queryParamsHandling: "merge",
      queryParams: params,
    });
    this.close.emit(true);
  }
  removeFilter(index) {
    (<UntypedFormArray>this.form.get("filter")).removeAt(index);

    this.selected.splice(index, 1);
    this.selectedDate.splice(index, 1);
  }
  setOption(value, index) {
    this.selected.splice(index, 1, value);
  }
  clearAll() {
    (<UntypedFormArray>this.form.get("filter")).clear();

    this.selected = [];
    this.selectedDate = [];
  }
  setMonth(value, index) {
    this.selectedDate[index].month = value;
    let month = this.months.find((month) => month.value === parseInt(value));
    let days = month.days;
    for (let i = 1; i <= days; i++) {
      this.days.push(i);
    }
  }
  setDay(value, index) {
    this.selectedDate[index].day = value;
  }
  setYear(value, index) {
    this.selectedDate[index].year = value;
  }

  getHiddenClass(selected) {
    if (selected === "date" || selected === "last_signin") {
      return "hidden";
    }
  }
}
