import { Component, HostListener, OnInit } from "@angular/core";
import {
  CoursesService,
  Course,
  CourseCertificateProfile,
  ResourcesService,
  Resource,
  CategoryService,
  Category,
} from "src/app/sinigangnababoywithgabi";
import { ActivatedRoute, NavigationStart, Router } from "@angular/router";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";

import { slideIn } from "src/app/app-common-module/animations/slide";
import { ModalService } from "src/app/services/modal.service";
import {
  ActivityLogService,
  VERBS,
} from "src/app/services/activity-log.service";
import {
  firstValueFrom,
  interval,
  map,
  switchMap,
  takeWhile,
  tap,
  zip,
  filter,
  Subject,
  takeUntil,
  EMPTY,
  of,
} from "rxjs";
import { Location } from "@angular/common";
import { DashboardService } from "src/app/services/dashboard.service";

export interface SectionItem {
  sectionUuid: string;
  sectionName: string;
  sectionOrderId?: string;

  isInEditMode?: boolean;
  blockItems: BlockItem[];
}

export interface BlockItem {
  sectionUuid: string;
  blockUuid: string;
  blockName: string;
  coverUri: string;
  type: string;
  subtype: string;
  resourceUuid: string;
  prerequisites: string[];
  daysTilExpired?: number;
  passOnSubmit: boolean;
  customPassingMark: number | string;

  requiredForCertification?: boolean;
  openDate?: string;
}

@Component({
  selector: "app-course-content-edit",
  templateUrl: "./course-content-edit.component.html",
  animations: [slideIn],
})
export class CourseEditContentComponent implements OnInit {
  course: Course;
  canAddForum: boolean = false;
  enableForum: boolean = false;
  forumPresent: boolean = false;
  forum: Category;
  loading = false;
  courseUuid: string;
  resourceUuid: string;
  certificateProfile: CourseCertificateProfile;

  sectionItems: SectionItem[];
  isDragging: boolean;

  isCourseSetPresent: boolean;
  courseSetResources: Resource[];

  publishResourceOnNext: boolean;
  warnOnCourseEdit: boolean;

  resourceCount: number;
  isWithCertification: boolean;
  resourceCertificationCount: number;

  constructor(
    private resourcesService: ResourcesService,
    private coursesService: CoursesService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: ModalService,
    private activityLogService: ActivityLogService,
    private categoryService: CategoryService,
    private location: Location,
    private dashboardService: DashboardService
  ) {}

  async ngOnInit() {
    let { publish_resource_on_next: publishResourceOnNext } =
      this.route.snapshot.queryParams;

    if (publishResourceOnNext) {
      this.publishResourceOnNext = true;
    }
    //Get permissions if user has forum permissions, init forum
    let permissions = await firstValueFrom(
      this.dashboardService.getPermissions()
    );
    this.canAddForum = permissions.includes("create:categories:all");
    if (this.canAddForum) {
      this.initializeForum();
    }
    this.waitForTask();
    this.loadCourseDataObs();
  }
  async setForum() {
    try {
      this.loading = true;
      if (this.forum)
        await firstValueFrom(
          this.categoryService.apiCategoriesCreate2(this.forum.uuid, {
            isActive: this.enableForum,
          })
        );
    } catch (e) {
      console.log(e);
    } finally {
      this.loading = false;
    }
  }

  private unsubscribe$: Subject<void> = new Subject<void>();
  ngOnDestroy() {
    console.log("called destroy");
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  // get forum for this course if no forum, create one
  initializeForum() {
    let courseUuid = "";
    this.route.queryParams
      .pipe(
        filter((params) => params["course_uuid"]),
        switchMap((params) => {
          this.loading = true;
          let { course_uuid } = params;
          courseUuid = course_uuid;
          return this.categoryService.apiCategoriesRetrieve(
            null,
            null,
            null,
            null,
            null,
            courseUuid,
            "course"
          );
        }),
        tap((result) => {
          if (result.length > 0) {
            this.forumPresent = true;
            this.forum = result[0];
            this.enableForum = this.forum.isActive;
          }
        }),
        switchMap((result) => {
          if (result.length === 0) {
            return this.categoryService.apiCategoriesCreate({
              referenceId: courseUuid,
              referenceType: "course",
              isActive: false,
            });
          }
          return of(null);
        }),

        tap((result) => {
          if (result) {
            this.forumPresent = true;
            this.forum = result;
            this.enableForum = this.forum.isActive;
          }
          this.loading = false;
        }),

        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }
  waitForTask() {
    interval(2000)
      .pipe(
        switchMap(() => this.route.queryParams),
        switchMap((params) =>
          this.resourcesService.getResourceByUuid(params["resource_uuid"])
        ),
        takeWhile(
          (resource) => resource.content.courseDetails.courseCloneTaskUuid
        ),
        switchMap((resource) =>
          this.coursesService
            .courseCloneTaskRead(
              resource.content.courseDetails.courseCloneTaskUuid
            )
            .pipe(
              map((task) => ({
                task,
                resource,
              }))
            )
        ),
        tap(({ task, resource }) => {
          if (task.status === "READY") {
            this.router.navigate([], {
              relativeTo: this.route,
              replaceUrl: true,
              queryParams: {
                course_uuid: task.clone,
              },
              queryParamsHandling: "merge",
            });
          }
        }),
        switchMap(({ task, resource }) =>
          this.resourcesService
            .updateResourceByUuid(resource.uuid, {
              ...resource,
              content: {
                ...resource.content,
                courseDetails: {
                  ...resource.content.courseDetails,
                  courseUuid: task.clone,
                },
              },
            })
            .pipe(map(() => task))
        ),
        switchMap((task) => {
          return this.coursesService.courseRead(task.original, "outline").pipe(
            map((course) => ({
              originalCourse: course,
              task,
            }))
          );
        }),
        tap(({ originalCourse }) => {
          this.isCourseSetPresent = true;
          this.courseSetResources = [];

          originalCourse.outline?.forEach((section) => {
            section.blocks.forEach((block) => {
              this.courseSetResources.push(block.content.resourceDetails);
            });
          });
        }),
        takeWhile(({ task }) => task.status !== "READY"),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  async loadCourseDataObs() {
    this.route.queryParams
      .pipe(
        filter((params) => params["course_uuid"]),
        tap(() => {
          this.sectionItems = null;
        }),
        switchMap((params) => {
          let { course_uuid: courseUuid, resource_uuid: resourceUuid } = params;

          this.resourceUuid = resourceUuid;

          return zip([
            this.coursesService.courseRead(
              courseUuid,
              "outline,reference_info"
            ),
            this.coursesService.courseCertificateprofilesList(courseUuid),
            this.coursesService.courseScheduleList(courseUuid),
            this.coursesService.courseCertificateprofilesList(courseUuid),
          ]);
        }),
        map((result) => {
          let [course, [courseCertificate], schedules, [certificateProfile]] =
            result;

          this.certificateProfile = certificateProfile;
          let sections = course.outline;

          // Enable warn if there are existing schedules
          if (schedules?.length > 0) {
            this.warnOnCourseEdit = true;
          }

          // Save course object for activity logging
          this.course = course;
          this.courseUuid = course.uuid;

          // Initialize resource counts
          this.resourceCount = 0;
          this.resourceCertificationCount = 0;
          this.isWithCertification = !!courseCertificate;

          // Construct view model
          this.sectionItems = sections.map((section) => {
            return {
              sectionName: section.title,
              sectionUuid: section.uuid,
              sectionOrderId: section.orderId,

              isInEditMode: true,

              blockItems: section.blocks.map((block) => {
                this.resourceCount++;

                if (block.referenceInfo?.referenceId) {
                  this.resourceCertificationCount++;
                }

                return {
                  blockName: block.title,
                  blockUuid: block.uuid,
                  coverUri: block.coverUri,
                  type: block.content?.resourceDetails?.type,
                  subtype: block.content?.resourceDetails?.subtype,
                  resourceUuid: block.content?.resourceDetails?.uuid,
                  sectionUuid: section.uuid,
                  prerequisites: block.prerequisites,
                  daysTilExpired: block.daysTilExpired,
                  customPassingMark: block.referenceInfo?.referenceId
                    ? block.referenceInfo?.customPassingMark
                    : null,
                  passOnSubmit: block.referenceInfo?.referenceId
                    ? block.referenceInfo?.passOnSubmit
                    : false,
                  requiredForCertification:
                    !!block.referenceInfo?.customPassingMark,
                  openDate: block.openDate,
                } as BlockItem;
              }),
            } as SectionItem;
          });

          return this.sectionItems;
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  addBlock(sectionUuid: string, index?: number) {
    let resourceCollectionUuid =
      this.route.snapshot.queryParams["resource_collection_uuid"];
    let courseResourceUuid = this.route.snapshot.queryParams["resource_uuid"];
    this.router.navigate(["/resources", "select-new"], {
      queryParams: {
        ignore: "course",
        is_inside_course: true,
        section_uuid: sectionUuid,
        course_uuid: this.courseUuid,
        parent_uuid: resourceCollectionUuid,
        course_resource_uuid: courseResourceUuid,
        section_order: index,
      },
    });
  }

  async deleteBlock(blockItem: BlockItem, sectionItem: SectionItem) {
    await firstValueFrom(this.coursesService.blockDelete(blockItem.blockUuid));

    this.updateBlockOrder(sectionItem.blockItems, sectionItem.sectionUuid);

    this.activityLogService.didCourseBlock(
      VERBS.deleted,
      {
        uuid: blockItem.blockUuid,
        title: blockItem.blockName,
      },
      {
        context: {
          contextActivities: {
            parent: [
              this.activityLogService.constructCourseSectionObject({
                uuid: sectionItem.sectionUuid,
              }),
            ],
            category: [
              this.activityLogService.constructCourseObject(this.course),
            ],
          },
        },
      }
    );
  }

  async addSection(order) {
    let sectionTitle = await this.modalService.prompt(
      "Please insert section name"
    );

    if (sectionTitle) {
      let result = await firstValueFrom(
        this.coursesService.courseSectionCreate(this.courseUuid, {
          title: sectionTitle,
          orderId: (order + "").padStart(2, "0"),
          prerequisites: [],
        })
      );

      this.sectionItems.splice(order + 1, 0, {
        sectionName: result.title,
        sectionUuid: result.uuid,
        sectionOrderId: result.orderId,

        isInEditMode: true,

        blockItems: [],
      });

      await this.updateSectionOrder();

      this.activityLogService.didCourseSection(VERBS.created, result, {
        context: {
          contextActivities: {
            parent: [
              this.activityLogService.constructCourseObject(this.course),
            ],
            category: [
              this.activityLogService.constructCourseObject(this.course),
            ],
          },
        },
      });
    }
  }

  async deleteSection(sectionItem: SectionItem, index: number) {
    let result = await this.modalService.confirm(
      `Are your sure you want to delete ${sectionItem.sectionName}?`
    );

    if (result) {
      this.sectionItems.splice(index, 1);

      await firstValueFrom(
        this.coursesService.sectionDelete(sectionItem.sectionUuid)
      );
    }

    // this.loadCourseData();
    await this.updateSectionOrder();

    this.activityLogService.didCourseSection(
      VERBS.deleted,
      {
        uuid: sectionItem.sectionUuid,
        title: sectionItem.sectionName,
      },
      {
        context: {
          contextActivities: {
            parent: [
              this.activityLogService.constructCourseObject(this.course),
            ],
            category: [
              this.activityLogService.constructCourseObject(this.course),
            ],
          },
        },
      }
    );
  }

  sectionDrop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.sectionItems, event.previousIndex, event.currentIndex);
    this.updateSectionOrder();
  }

  onBlockDrop(event: CdkDragDrop<{ sectionItem: SectionItem }>) {
    this.updateBlockOrder(
      event.container.data.sectionItem.blockItems,
      event.container.data.sectionItem.sectionUuid
    );

    if (event.previousContainer != event.container) {
      this.updateBlockOrder(
        event.previousContainer.data.sectionItem.blockItems,
        event.previousContainer.data.sectionItem.sectionUuid
      );
    }
  }

  async updateSectionOrder() {
    let updates = this.sectionItems.map((sectionContainer, index) => {
      sectionContainer.sectionOrderId = (index + "").padStart(2, "0");

      return firstValueFrom(
        this.coursesService.sectionUpdate(sectionContainer.sectionUuid, {
          //HARDCODE habang wala pa yung integer na order
          orderId: sectionContainer.sectionOrderId,
        })
      );
    });

    await Promise.all(updates);
  }

  async updateBlockOrder(blockItems: BlockItem[], sectionUuid: string) {
    let updates = blockItems.map((blockItem, index) =>
      firstValueFrom(
        this.coursesService.blockUpdate(blockItem.blockUuid, {
          section: sectionUuid,
          //HARDCODE habang wala pa yung integer na order
          orderId: (index + "").padStart(2, "0"),
        })
      )
    );

    await Promise.all(updates);
  }

  updatedCertificateProfile() {
    return firstValueFrom(
      this.coursesService.certificateprofileUpdate(
        this.certificateProfile.uuid,
        {
          endMessage: this.certificateProfile.endMessage,
        }
      )
    );
  }

  async updateSection(sectionItem: SectionItem) {
    await firstValueFrom(
      this.coursesService.sectionUpdate(sectionItem.sectionUuid, {
        title: sectionItem.sectionName,
      })
    );

    this.activityLogService.didCourseSection(
      VERBS.updated,
      {
        uuid: sectionItem.sectionUuid,
        title: sectionItem.sectionName,
      },
      {
        context: {
          contextActivities: {
            parent: [
              this.activityLogService.constructCourseObject(this.course),
            ],
            category: [
              this.activityLogService.constructCourseObject(this.course),
            ],
          },
        },
      }
    );
  }

  onDragStart() {
    this.isDragging = true;
  }

  onDragEnd() {
    this.isDragging = false;
  }

  constructDropListConnectedTo() {
    return this.sectionItems?.map((item, i) => "block-list-" + i);
  }
}
