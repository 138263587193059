import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { firstValueFrom } from "rxjs";
import { ModalService } from "src/app/services/modal.service";
import { PassService, Role } from "src/app/sinigangnababoywithgabi";

interface PermissionGroupItem {
  name: string;
  permissionItems: PerimssionItem[];
}

interface PerimssionItem {
  name: string;
  permissionName: string;
}

@Component({
  selector: "app-group-role-permissions-toggle",
  templateUrl: "./group-role-permissions-toggle.component.html",
  styles: [],
})
export class GroupRolePermissionsToggleComponent implements OnInit {
  permissionList: PermissionGroupItem[];

  formGroup: UntypedFormGroup;
  role: Role;

  canDelete: boolean;

  constructor(
    private passService: PassService,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private location: Location
  ) {}

  async ngOnInit() {
    let { role_uuid: roleUuid } = this.route.snapshot.queryParams;

    this.role = await firstValueFrom(
      this.passService.getRole(roleUuid, ["permissions"])
    );

    this.formGroup = this.formBuilder.group({});

    this.canDelete = this.role.tagList.includes("custom");
  }

  async saveRole() {
    let permissionMap = this.formGroup.value;

    let permissionsAttributes = Object.entries(permissionMap)
      .filter(([key, value]) => value)
      .map(([key, value]) => {
        return {
          name: key,
        };
      });

    Object.assign(this.role, {
      name: this.formGroup.value.name,
      description: this.formGroup.value.description,
      tagList: ["custom"],
      permissionsAttributes,
      // HARDCODE: until https://buri.dev/T549 is solved
      applicationId: localStorage.getItem("application_id"),
    });

    await firstValueFrom(
      this.passService.updateRole(this.role.id, {
        role: this.role,
      })
    );

    this.location.back();
  }

  async deleteRole() {
    let result = await this.modalService.confirm(
      "Are you sure you want to remove this role? This action is not reversible."
    );

    if (result) {
      await firstValueFrom(this.passService.deleteRole(this.role.id));

      this.location.back();
    }
  }
}
