<app-back-title-layout>
  <div backTitleLayoutBeforeMainNoMargin class="bg-white h-100">
    <div class="flex items-center py-3 text-white bg-primary-dark">
      <!-- <button appGoBack class="flex items-center mx-3">
        <app-icon icon="chevron_left"></app-icon>
        <span class="text-xs"> Back </span>
      </button> -->

      <div class="flex-1 mx-3 font-bold">
        <!-- Cast Editor -->
      </div>

      <div class="mx-3">
        <a [routerLink]="['./cast-edit']">
          <app-icon icon="edit"></app-icon>
        </a>
      </div>

      <div class="mx-3">
        <a
          [routerLink]="['/_casteditor', 'screens', 'select']"
          [queryParams]="{ screenId: screenId }"
          queryParamsHandling="merge"
        >
          <app-icon icon="insert_drive_file"></app-icon>
        </a>
      </div>

      <div class="mx-3">
        <a
          [routerLink]="['/_casteditor', 'activity-logs']"
          [queryParams]="{ screenId: screenId }"
          queryParamsHandling="merge"
        >
          <app-icon icon="list_alt"></app-icon>
        </a>
      </div>
    </div>

    <div
      class="h-10 px-2 py-2 text-sm font-bold bg-primary-lightest text-primary-dark"
    >
      {{ screen?.title }}
    </div>

    <section *ngIf="initialBlock">
      <app-casteditor-block-navigation [block]="initialBlock">
      </app-casteditor-block-navigation>
    </section>

    <div class="relative px-1">
      <app-loading
        [loading]="!blocks"
        wrapperClass="absolute inset-0 bg-white opacity-50 flex justify-center items-center mt-20"
      ></app-loading>

      <div *ngIf="blocks" [@fadeInOut]>
        <div
          class="grid grid-cols-1 pb-10"
          cdkDropList
          (cdkDropListDropped)="dropBlock($event)"
          *ngIf="blocks?.length > 0; else noBlocks"
        >
          <ng-container *ngFor="let block of blocks; let blkIdx = index">
            <section
              cdkDrag
              [cdkDragDisabled]="disableEdit"
              queryParamsHandling="merge"
              class="block border-2 border-white hover:border-primary"
              [ngSwitch]="block.type"
            >
              <ng-container *ngSwitchCase="'navbar'">
                <app-casteditor-block-navbar [block]="block">
                </app-casteditor-block-navbar>
              </ng-container>

              <ng-container *ngSwitchCase="'category'">
                <app-casteditor-block-category
                  [block]="block"
                  (deleteBlock)="deleteBlock(blkIdx)"
                  [disableEdit]="disableEdit"
                ></app-casteditor-block-category>
              </ng-container>

              <ng-container *ngSwitchCase="'carousel'">
                <app-casteditor-block-carousel
                  [disableEdit]="disableEdit"
                  [block]="block"
                  (deleteBlock)="deleteBlock(blkIdx)"
                >
                </app-casteditor-block-carousel>
              </ng-container>

              <ng-container *ngSwitchCase="'markdown'">
                <div cdkDragHandle>
                  <app-icon icon="unfold_more" class="text-gray-600"></app-icon>
                </div>
                <app-casteditor-block-markdown
                  [disableEdit]="disableEdit"
                  [block]="block"
                  (deleteBlock)="deleteBlock(blkIdx)"
                >
                </app-casteditor-block-markdown>
              </ng-container>

              <ng-container *ngSwitchCase="'link'">
                <app-casteditor-block-link
                  [disableEdit]="disableEdit"
                  [block]="block"
                  (deleteBlock)="deleteBlock(blkIdx)"
                >
                </app-casteditor-block-link>
              </ng-container>

              <ng-container *ngSwitchCase="'catalog'">
                <app-casteditor-block-catalog
                  [disableEdit]="disableEdit"
                  [block]="block"
                  [screenId]="screenId"
                  (deleteBlock)="deleteBlock(blkIdx)"
                >
                </app-casteditor-block-catalog>
              </ng-container>

              <ng-container *ngSwitchCase="'course-status'">
                <app-casteditor-block-course-status
                  [disableEdit]="disableEdit"
                  [block]="block"
                  (deleteBlock)="deleteBlock(blkIdx)"
                >
                </app-casteditor-block-course-status>
              </ng-container>

              <ng-container *ngSwitchCase="'buttonSearch'">
                <app-casteditor-block-button-search
                  [disableEdit]="disableEdit"
                  [block]="block"
                  (deleteBlock)="deleteBlock(blkIdx)"
                >
                </app-casteditor-block-button-search>
              </ng-container>

              <div
                *ngSwitchDefault
                class="flex items-center justify-center w-full h-10 text-lg font-bold text-purple-400 bg-purple-200 rounded-lg"
              >
                <span> {{ block.type | titlecase }} Block </span>
              </div>
            </section>

            <button
              class="w-full p-1 my-5 text-base font-bold text-blue-600 uppercase bg-blue-100 rounded"
              (click)="openAddBlockModal(blkIdx)"
            >
              <app-icon class="rounded" icon="add_circle"></app-icon> ADD BLOCK
            </button>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</app-back-title-layout>

<ng-template #noBlocks>
  <button
    class="w-full p-1 my-5 text-base font-bold text-blue-600 uppercase bg-blue-100 rounded"
    (click)="openAddBlockModal(-1)"
  >
    <app-icon class="rounded" icon="add_circle"></app-icon> ADD BLOCK
  </button>
</ng-template>
