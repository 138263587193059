import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  Observable,
  Subject,
  debounceTime,
  distinctUntilChanged,
  firstValueFrom,
  switchMap,
  takeUntil,
  tap,
} from "rxjs";
import { fadeInOut } from "src/app/app-common-module/animations/fade";
import { Header } from "src/app/app-common-module/components/list-table-header/list-table-header.component";
import { ModalService } from "src/app/services/modal.service";
import {
  Collection,
  Component as ResourceComponent,
  ResourcesService,
} from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-casteditor-modal-add-catalog",
  templateUrl: "./casteditor-modal-add-catalog.component.html",
  styles: [],
  animations: [fadeInOut],
})
export class CasteditorModalAddCatalogComponent implements OnInit {
  collectionListObservable: Observable<Collection[]>;
  collections: any;
  loading: boolean = false;
  limit: number = 20;
  collectionCount: number;
  headers: Header[] = [
    { name: "Collection Name", value: "collection", width: "w-96" },
  ];
  defaultHeaders: string[] = ["Collection Name"];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private resourcesService: ResourcesService
  ) {}
  async ngOnInit() {
    this.loading = true;
    this.collectionCount = await this.totalCollectionCount();
    this.constructCollectionListObservable();
  }

  async totalCollectionCount() {
    return (await firstValueFrom(this.resourcesService.listCollections()))
      .length;
  }
  constructCollectionListObservable() {
    this.collectionListObservable = this.route.queryParams.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((params) => {
        let { page, q } = params;
        this.collections = null;
        let _page = page ? page : 0;
        return this.resourcesService.listCollections(
          this.limit,
          _page * this.limit,
          q
        );
      }),
      tap((result) => {
        console.log(result);
        this.loading = false;
      })
    );
  }
}
