<app-back-title-layout layoutTitle="Group Activity Logs">
  <div class="leading-tight truncate font-bold py-2">Last Edit</div>
  <div class="date text-xs text-gray-600">
    {{ lastEdit?.timestamp | date: "medium" }}
  </div>
  <div class="flex py-2">
    <app-icon
      icon="person"
      class="text-gray-600 text-4xl mr-2 leading-none"
    ></app-icon>
    <div>
      <div class="leading-tight text-sm truncate font-semibold">
        {{ lastEdit?.name }}
      </div>

      <div class="text-xs text-gray-700">{{ lastEdit?.email }}</div>
    </div>
  </div>

  <br />
  <div class="leading-tight truncate font-bold py-4">App Activity</div>

  <hr class="my-3" />

  <app-item-list
    [source]="logListObservable"
    [itemTemplate]="itemTemplate"
  ></app-item-list>

  <!-- <app-item-list [items]="logList" [itemTemplate]="itemTemplate">
  </app-item-list> -->
</app-back-title-layout>

<ng-template #itemTemplate let-activityLog="item">
  <div
    *ngIf="!activityLog.latest"
    class="uppercase leading-tight text-xs truncate font-bold py-2"
  >
    {{ activityLog.date | date: "MMMM YYYY" }}
  </div>

  <app-item-list [items]="activityLog.log" [itemTemplate]="itemTemplate2">
  </app-item-list>
</ng-template>

<ng-template #itemTemplate2 let-log="item">
  <div class="py-3">
    <div class="uppercase text-xs text-gray-600">
      {{ log.timestamp | date: "medium" }}
    </div>

    <div class="flex py-2">
      <app-icon
        icon="person"
        class="text-gray-600 text-4xl mr-2 leading-none"
      ></app-icon>
      <div>
        <div class="leading-tight text-sm truncate font-semibold">
          {{ log.name }}
        </div>

        <div class="text-xs text-gray-700">{{ log.email }}</div>
      </div>
    </div>

    <div class="text-xs">
      <span class="capitalize">
        {{ log.action }}
      </span>

      <span>
        {{ log.itemType }}
      </span>

      <span class="text-purple-700"> "{{ log.item }}" </span>

      <ng-container *ngIf="log.in || log.inItemType">
        <span> in </span>

        <span>
          {{ log.inItemType }}
        </span>

        <span *ngIf="log.in" class="text-purple-700"> "{{ log.in }}" </span>
      </ng-container>
    </div>
  </div>
</ng-template>
