<div *ngIf="!loading && formGroup">
  <ng-container
    *ngFor="let permissionGroupItem of permissionList; let i = index"
  >
    <ul
      [ngClass]="{
        'text-gray-500': formGroup.disabled
      }"
    >
      <li class="mb-5">
        <label>
          <app-checkbox
            [ngClass]="{
              'text-primary': formGroup.enabled
            }"
            [(ngModel)]="permissionGroupItem.isSelected"
            (change)="onGroupItemChange(permissionGroupItem)"
          ></app-checkbox>
          <span class="ml-1 form-label inline">
            {{ permissionGroupItem.name }}
          </span>
        </label>

        <ul class="ml-5" *ngIf="permissionGroupItem.isSelected">
          <li
            class="my-1"
            *ngFor="
              let permissionItem of permissionGroupItem.permissionItems;
              let j = index
            "
          >
            <label class="text-sm flex items-center">
              <app-checkbox
                [ngClass]="{
                  'text-primary': formGroup.enabled
                }"
                [(ngModel)]="permissionItem.isSelected"
                (change)="onItemChange(permissionItem)"
              ></app-checkbox>

              <span class="ml-1">
                {{ permissionItem.name }}
              </span>
            </label>

            <div
              class="ml-5"
              *ngIf="permissionItem.isSelected && !permissionItem.modeDisabled"
            >
              <label class="block text-xs">
                <app-radio
                  [ngClass]="{
                    'text-primary': formGroup.enabled
                  }"
                  name="{{ i }}{{ j }}"
                  [(ngModel)]="permissionItem.mode"
                  value="all"
                ></app-radio>
                ALL
              </label>

              <label class="block text-xs">
                <app-radio
                  [ngClass]="{
                    'text-primary': formGroup.enabled
                  }"
                  name="{{ i }}{{ j }}"
                  [(ngModel)]="permissionItem.mode"
                  value="specific"
                ></app-radio>
                Specific
              </label>

              <ul class="ml-5" *ngIf="permissionItem.mode == 'specific'">
                <li
                  *ngFor="
                    let specificItem of permissionItem.specificItems;
                    let i = index
                  "
                >
                  <div class="flex items-center py-2 text-xs">
                    <app-icon
                      class="text-primary text-4xl mr-2"
                      [icon]="specificItem.icon"
                    ></app-icon>

                    <div class="flex-1 min-w-0">
                      <div class="text-primary font-semibold leading-none">
                        {{ specificItem.title || specificItem.permissionName }}
                      </div>
                      <div class="text-gray-700">
                        {{ specificItem.subtitle }}
                      </div>
                    </div>

                    <button
                      (click)="deleteSpecific(permissionItem, i)"
                      type="button"
                      class="btn btn-icon text-2xl bg-transparent"
                    >
                      <app-icon icon="close"></app-icon>
                    </button>
                  </div>

                  <hr />

                  <!-- <div
                    *ngIf="
                    permissionItem.specificItemInfoObs(specificItem)
                    | async as obj
                    "
                    >
                    {{ obj.title }}
                  </div> -->
                </li>
                <button
                  (click)="addSpecific(permissionItem)"
                  type="button"
                  class="text-sm bg-transparent text-primary-700"
                >
                  <app-icon icon="add"></app-icon>
                  add more
                </button>
              </ul>
            </div>
          </li>
        </ul>
      </li>
    </ul>
  </ng-container>
</div>
