import { Component, Input, OnInit } from "@angular/core";
import {
  V1Exam,
  V1ExamSection,
  V1ExamItem,
  V1TallyExamProfile,
  V1TallyType,
  V1ExamsService,
  V1ExamChoice,
} from "src/app/sinigangnababoywithgabi";
import { ActivatedRoute } from "@angular/router";
import { firstValueFrom, Subject, zip } from "rxjs";
import { map } from "rxjs/operators";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Location } from "@angular/common";

@Component({
  selector: "app-tally-activity-edit",
  templateUrl: "./tally-activity-edit.component.html",
})
export class TallyActivityEditComponent implements OnInit {
  loading: boolean;
  @Input() insideSlide: boolean;
  @Input() examUuid: string;
  @Input() tallyItemUuid: string;
  exam: V1Exam;
  section: V1ExamSection;
  items: V1ExamItem[];
  tallyExamProfile: V1TallyExamProfile;
  tallyTypes: V1TallyType[];

  formGroup: UntypedFormGroup;

  constructor(
    private route: ActivatedRoute,
    private v1ExamsService: V1ExamsService,
    private formBuilder: UntypedFormBuilder,
    private location: Location
  ) {}

  async ngOnInit() {
    await this.fetchData();
  }

  private unsubscribe$ = new Subject();
  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  async fetchData() {
    this.loading = true;
    let { exam_uuid: examUuid } = this.route.snapshot.queryParams;

    let [section] = await firstValueFrom(
      this.v1ExamsService.examsSectionsList(examUuid || this.examUuid)
    );

    let items = await firstValueFrom(
      this.v1ExamsService.examsSectionsItemsList(section.uuid)
    );

    let tallyTypes = await firstValueFrom(
      this.v1ExamsService.tallyTypeList(examUuid || this.examUuid)
    );

    let allItemChoices: V1ExamChoice[] = await firstValueFrom(
      zip(
        items.map((item) =>
          this.v1ExamsService.examsSectionsItemsChoicesList(item.uuid)
        )
      ).pipe(
        map((result) => {
          let flattened: V1ExamChoice[] = [];

          result.forEach((choices) => flattened.push(...choices));

          return flattened;
        })
      )
    );

    // Construct form group
    this.formGroup = this.formBuilder.group({
      tallyTypes: this.constructTallyTypes(tallyTypes),

      tallyItems: this.constructTallyItems({
        items,
        tallyTypes,
        allItemChoices,
      }),
    });
    this.loading = false;
  }

  constructTallyTypes(tallyTypes: V1TallyType[]) {
    return this.formBuilder.array(
      tallyTypes.map((tt) => this.constructTallyTypeFormGroup(tt))
    );
  }

  constructTallyTypeFormGroup(tallyType: V1TallyType) {
    return this.formBuilder.group({
      tallyTypeUuid: [tallyType.uuid],
      name: [tallyType.name, Validators.required],
      isDeleted: [false],
    });
  }

  constructTallyItemFormGroup(item: V1ExamItem) {
    return this.formBuilder.group({
      uuid: [item.uuid],
      text: [item.text, Validators.required],
      mediaUrl: [item.mediaUrl || ""],
    });
  }

  constructTallyItems(options: {
    items: V1ExamItem[];
    tallyTypes: V1TallyType[];
    allItemChoices: V1ExamChoice[];
  }) {
    return this.formBuilder.array(
      options.items.map((item) => {
        let itemChoices = options.allItemChoices.filter(
          (choice) => choice.item === item.uuid
        );

        return this.formBuilder.group({
          itemUuid: [item.uuid],
          text: [item.text, Validators.required],
          mediaUrl: [item.mediaUrl],
          choices: this.formBuilder.array(
            itemChoices.map((choice) => {
              let tallyTypeIndex = options.tallyTypes.findIndex((tt) => {
                return tt.choices.includes(choice.uuid);
              });

              return this.formBuilder.group({
                choiceUuid: [choice.uuid],
                text: [
                  choice.shortInput || choice.longInput,
                  Validators.required,
                ],
                imageUrl: [choice.imageUrl],
                audioUrl: [choice.audioUrl],
                isDeleted: [false],
                isCorrect: [choice.isCorrect],
                tallyTypeIndex: [tallyTypeIndex, Validators.required],
              });
            })
          ),
        });
      })
    );
  }

  getUndeletedTallyTypesControls() {
    let tallyTypes = <UntypedFormArray>this.formGroup.get("tallyTypes");

    return tallyTypes.controls.filter((control) => !control.value.isDeleted);
  }

  onEnterNewTallyType(event: KeyboardEvent) {
    event.stopPropagation();
    event.preventDefault();

    let value = event.target["value"];
    event.target["value"] = "";

    if (value !== "") {
      let tallyTypes = <UntypedFormArray>this.formGroup.get("tallyTypes");

      tallyTypes.push(
        this.constructTallyTypeFormGroup({
          name: value,
        })
      );
    }
  }

  async updateItem(examItem: V1ExamItem) {
    return await firstValueFrom(
      this.v1ExamsService.examsSectionsItemsUpdate(examItem.uuid, {
        ...examItem,
      })
    );
  }

  createChoice(itemUuid: string, choice: V1ExamChoice) {
    return firstValueFrom(
      this.v1ExamsService.examsSectionsItemsChoicesCreate(itemUuid, choice)
    );
  }

  updateChoice(choice: V1ExamChoice) {
    return firstValueFrom(
      this.v1ExamsService.examsSectionsItemsChoicesUpdate(choice.uuid, choice)
    );
  }

  deleteChoice(choice: V1ExamChoice) {
    return firstValueFrom(
      this.v1ExamsService.examsSectionsItemsChoicesDelete(choice.uuid)
    );
  }

  createTallyType(examUuid: string, tallyType: V1TallyType) {
    return firstValueFrom(
      this.v1ExamsService.tallyTypeCreate(examUuid, tallyType)
    );
  }

  updateTallyType(tallyType: V1TallyType) {
    return firstValueFrom(
      this.v1ExamsService.tallyTypeDetailUpdate(tallyType.uuid, tallyType)
    );
  }

  deleteTallyType(tallyType: V1TallyType) {
    return firstValueFrom(
      this.v1ExamsService.tallyTypeDetailDelete(tallyType.uuid)
    );
  }

  // Main mathod for updating the exam item
  async updateAll() {
    let { exam_uuid: examUuid } = this.route.snapshot.queryParams;

    try {
      this.loading = true;

      let tallyItemsArray = this.formGroup.get(
        "tallyItems"
      ) as UntypedFormArray;
      let tallyTypesArray = this.formGroup.get(
        "tallyTypes"
      ) as UntypedFormArray;
      let allItemChoices = new UntypedFormArray([]);

      await Promise.all(
        tallyItemsArray.controls.map((control) => {
          return this.updateItem({
            uuid: control.value.itemUuid,
            text: control.value.text,
            mediaUrl: control.value.mediaUrl,
          }).then(() => {
            let choicesArray = control.get("choices") as UntypedFormArray;
            choicesArray.controls.forEach((control) => {
              allItemChoices.push(control);
            });

            return this.updateChoices(control.value.itemUuid, choicesArray);
          });
        })
      );

      await this.updateTallyTypes(examUuid, tallyTypesArray, allItemChoices);

      // let choices

      // await Promise.all(tallyTypesArray.controls.map(control=>{
      //   if()

      // }));
    } catch (err) {
      this.loading = false;
      throw err;
    }
  }

  async updateChoices(itemUuid: string, choicesArray: UntypedFormArray) {
    let updateAndCreateTallyTypeControls = choicesArray?.controls?.filter(
      (control) => !control.value.isDeleted
    );
    let deleteChoiceControls = choicesArray?.controls?.filter(
      (control) => control.value.isDeleted
    );

    // Handle choices
    if (updateAndCreateTallyTypeControls) {
      // For each choice form control
      for (let i = 0; i < updateAndCreateTallyTypeControls.length; i++) {
        let control = updateAndCreateTallyTypeControls[i];
        let index = i;
        let choiceValue = control.value;

        if (choiceValue.choiceUuid) {
          // If uuid exists, then the choice exists. Update the choice.
          let choice = await this.updateChoice({
            uuid: choiceValue.choiceUuid,
            longInput: choiceValue.text || "",
            shortInput: choiceValue.text || "",
            imageUrl: choiceValue.imageUri || "",
            audioUrl: choiceValue.audioUri || "",
            isCorrect: choiceValue.isCorrect,
            orderId: index,
          });

          control.get("choiceUuid").setValue(choice.uuid);
        } else {
          // If uuid dont exist, then the choice does not exist. Create the choice
          await this.createChoice(itemUuid, {
            longInput: choiceValue.text || "",
            shortInput: choiceValue.text || "",
            imageUrl: choiceValue.imageUri || "",
            audioUrl: choiceValue.audioUri || "",
            isCorrect: choiceValue.isCorrect,
            orderId: index,
          });
        }
      }
    }

    if (deleteChoiceControls) {
      // Delete choices in the deleted form array
      await Promise.all(
        deleteChoiceControls.map(async (control) => {
          let choiceUuid = control.value.choiceUuid;

          await this.deleteChoice({ uuid: choiceUuid });
        })
      );
    }
  }

  async updateTallyTypes(
    examUuid: string,
    tallyTypeArray: UntypedFormArray,
    choicesArray: UntypedFormArray
  ) {
    //Handle updates and create
    await Promise.all(
      tallyTypeArray?.controls
        ?.map((control, index) => {
          return { control, index };
        })
        .filter(({ control, index }) => !control.value.isDeleted)
        .map(({ control, index }) => {
          let tallyTypeValue = control.value;
          let choicesUuid = choicesArray.controls
            .filter((control) => control.value.tallyTypeIndex === index)
            .map((control) => control.value.choiceUuid);

          if (tallyTypeValue.tallyTypeUuid) {
            // If uuid exists, then the choice exists. Update the choice.
            return this.updateTallyType({
              uuid: tallyTypeValue.tallyTypeUuid,
              name: tallyTypeValue.name,
              choices: choicesUuid,
            });
          } else {
            // If uuid dont exist, then the choice does not exist. Create the choice
            return this.createTallyType(examUuid, {
              uuid: tallyTypeValue.tallyTypeUuid,
              name: tallyTypeValue.name,
              choices: choicesUuid,
            });
          }
        })
    );

    let deleteTallyTypeControls = tallyTypeArray?.controls?.filter(
      (control) => control.value.isDeleted
    );

    if (deleteTallyTypeControls) {
      // Delete choices in the deleted form array
      await Promise.all(
        deleteTallyTypeControls.map(async (control) => {
          let tallyTypeUuid = control.value.tallyTypeUuid;

          await this.deleteTallyType({ uuid: tallyTypeUuid });
        })
      );
    }
  }

  async onSave() {
    this.loading = true;
    await this.updateAll();

    if (this.insideSlide) {
    } else {
      this.location.back();
    }
    this.loading = false;
  }

  onRemoveTallyType(tallyTypeItem: UntypedFormGroup) {
    tallyTypeItem.get("isDeleted").setValue(true);
  }
}
