import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, UntypedFormControl } from "@angular/forms";
import { firstValueFrom } from "rxjs";
import { UploadsService } from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-exam-item-form-choices-tally",
  templateUrl: "./exam-item-form-choices-tally.component.html",
  styles: [],
})
export class ExamItemFormChoicesTallyComponent implements OnInit {
  @Input()
  formGroup: UntypedFormGroup;

  @Input()
  tallyTypeFormArray: UntypedFormArray;

  loading: boolean = false;

  constructor(
    public formBuilder: UntypedFormBuilder,
    public uploadService: UploadsService
  ) {}

  ngOnInit() {
    this.formGroup = this.formGroup || this.formBuilder.group({});
  }

  getChoiceControls() {
    let formArray = this.formGroup.get("choices") as UntypedFormArray;

    return formArray.controls;
  }

  getUndeletedChoiceControls() {
    return this.getChoiceControls().filter(
      (control) => !control.get("isDeleted").value
    );
  }

  onEnterNewChoice(event: KeyboardEvent) {
    event.stopPropagation();
    event.preventDefault();

    let value = event.target["value"];
    event.target["value"] = "";

    if (value !== "") {
      this.addItemChoice({
        text: value,
      });
    }
  }

  onImageUploadNewChoice(event) {
    this.addItemChoice({
      imageUri: event.uri,
    });
  }

  onAudioUploadNewChoice(event) {
    this.addItemChoice({
      audioUri: event.uri,
    });
  }

  async onUpload(event, formControl: UntypedFormControl) {
    this.loading = true;

    try {
      let file = event.target.files[0];
      event.srcElement.value = null;

      let result = await firstValueFrom(this.uploadService.uploadFile(file));

      formControl.setValue(result.uri);
    } catch (err) {
      throw err;
    } finally {
      this.loading = false;
    }
  }

  clearControl(formControl: UntypedFormControl) {
    formControl.setValue(null);
  }

  addItemChoice(values: {
    text?: string;
    imageUri?: string;
    audioUri?: string;
  }) {
    let formArray = this.formGroup.get("choices") as UntypedFormArray;

    let newFormGroup = this.formBuilder.group({
      isCorrect: [true],
      text: [values.text],
      choiceUuid: [null],
      imageUri: [values.imageUri],
      audioUri: [values.audioUri],
      isDeleted: [false],
      tallyTypeIndex: [null],
    });

    formArray.push(newFormGroup);
  }

  removeChoice(formGroup: UntypedFormGroup) {
    formGroup.get("isDeleted").patchValue(true);
    formGroup.disable();
  }

  getTallyTypeChoices() {
    let tallyChoices = this.tallyTypeFormArray.controls.map((control, i) => {
      return {
        name: control.value.name,
        value: i,
        isDeleted: control.value.isDeleted,
      };
    });

    return tallyChoices.filter((item) => !item.isDeleted);
  }
}
