<app-back-title-layout
  layoutTitle="Batches"
  titleSupportLink="https://support.castlms.com/help/what-are-batches"
>
  <div class="my-5">
    <app-search-bar-query placeholder="Search"> </app-search-bar-query>
  </div>

  <section class="mb-5">
    <button
      [routerLink]="['../create-batches']"
      queryParamsHandling="merge"
      class="btn btn-primary w-full"
    >
      + ADD NEW BATCH
    </button>
  </section>

  <section class="mt-3">
    <ul class="flex w-full my-3">
      <li class="flex-1 mr-2">
        <button
          class="w-full text-center block border-b-4 border-gray-300 text-gray-600 py-2 px-4"
          href="#"
          [ngClass]="{ active: tabFormControl.value == 1 }"
          (click)="selectTab(1)"
        >
          Ongoing
        </button>
      </li>
      <li class="flex-1 mr-2">
        <button
          class="w-full text-center block border-b-4 border-gray-300 text-gray-600 py-2 px-4"
          [ngClass]="{ active: tabFormControl.value == 2 }"
          (click)="selectTab(2)"
        >
          Queued
        </button>
      </li>

      <li class="flex-1 mr-2">
        <button
          class="w-full text-center block border-b-4 border-gray-300 text-gray-600 py-2 px-4"
          [ngClass]="{ active: tabFormControl.value == 3 }"
          (click)="selectTab(3)"
        >
          Done
        </button>
      </li>
    </ul>

    <div class="my-5">
      <app-item-list
        [source]="scheduleListObservable"
        [itemTemplate]="itemTemplate"
      ></app-item-list>
    </div>
  </section>
</app-back-title-layout>

<ng-template #itemTemplate let-schedule="item">
  <a
    class="flex"
    [routerLink]="['/_courses', 'batches', 'profile']"
    [queryParams]="{
      course_uuid: courseUuid,
      schedule_uuid: schedule.uuid
    }"
  >
    <app-icon
      class="text-gray-500 text-lg mr-2 leading-tight"
      icon="date_range"
    >
    </app-icon>

    <div>
      <div class="font-bold leading-tight">
        {{ schedule.name }}
      </div>

      <div class="text-gray-600 text-sm">
        <ng-container *ngIf="schedule.start && schedule.start; else forever">
          <span> {{ schedule.start | date }} - {{ schedule.end | date }} </span>
        </ng-container>
        <ng-template #forever>
          <span>Forever</span>
        </ng-template>
      </div>
    </div>
  </a>
</ng-template>
