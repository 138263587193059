import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { firstValueFrom } from "rxjs";
import {
  ActivityLogService,
  VERBS,
} from "src/app/services/activity-log.service";
import { ResourceHelperService } from "src/app/services/resource-helper.service";
import { Resource, V1ExamsService } from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-resource-new",
  templateUrl: "./resource-new.component.html",
})
export class ResourceNewComponent implements OnInit {
  formGroup: FormGroup;
  resource: Resource;

  loading: boolean = false;

  titleSupportLink: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private resourceHelperService: ResourceHelperService,
    private v1ExamsService: V1ExamsService,
    private formBuilder: FormBuilder,
    private activityLogService: ActivityLogService
  ) {}

  ngOnInit() {
    let {
      parent_uuid: parentUuid,
      type: resourceType,
      subtype,
    } = this.route.snapshot.queryParams;

    this.resource = {
      content: {},
    } as Resource;

    this.formGroup = this.formBuilder.group({
      name: ["", Validators.required],
      description: [""],
      type: [resourceType],
      subtype: [subtype],
      coverUri: [""],
      publishedAt: [null],
      flavorTextSpan: [""],
      tags: [[]],
      packageUuids: this.formBuilder.array([parentUuid]),
    });

    if (resourceType == "course") {
      this.titleSupportLink =
        "https://support.castlms.com/help/what-is-a-course";
    }
  }

  async createResource() {
    let {
      order,
      parent_uuid: parentUuid,
      is_inside_course: isInsideCourse,

      section_uuid: sectionUuid,
      course_uuid: courseUuid,
      section_order: sectionOrder,
    } = this.route.snapshot.queryParams;

    try {
      this.loading = true;

      // Not sure why may ganito please clarify
      this.formGroup.markAllAsTouched();

      if (this.formGroup.invalid) {
        this.loading = false;
        return;
      }

      let result = await this.resourceHelperService.createResource({
        resource: this.formGroup.value,
        collectionUuid: parentUuid,
        isInsideCourse,
        sectionUuid,
        sectionOrder,
        courseUuid,
        order,
      });

      this.redirectHook(result);

      // Activity Log
      this.activityLogService.didResource(VERBS.created, result);
    } catch (err) {
      this.loading = false;
      throw err;
    }
  }

  async redirectHook(resource: Resource) {
    let {
      is_inside_course: isInsideCourse,
      course_uuid: courseUuid,
      parent_uuid: parentUuid,
      course_resource_uuid: courseResourceUuid,
    } = this.route.snapshot.queryParams;

    if (resource.type === "course") {
      // Goto course editor
      // this.router.navigate(["/_courses", "content-edit"], {
      //   replaceUrl: true,
      //   queryParams: {
      //     course_uuid: resource.content?.courseDetails?.courseUuid,
      //     resource_collection_uuid: resource.content?.resourceCollectionUuid,
      //     resource_uuid: resource.uuid,
      //     publish_resource_on_next: true,
      //   },
      // });
      window.location.href = `/courses/content-edit?course_uuid=${resource.content?.courseDetails?.courseUuid}&resource_collection_uuid=${resource.content?.resourceCollectionUuid}&resource_uuid=${resource.uuid}&from_screen=add_resource&publish_resource_on_next=true`;
    } else if (
      resource.type === "buribook-with-activity" ||
      resource.type === "burislides"
    ) {
      // Goto buribook editor
      let queryParams = {
        exam_uuid: resource.content?.examDetails?.examUuid,
        resource_uuid: resource.uuid,
      };

      if (!isInsideCourse) {
        queryParams["publish_resource_on_next"] = true;
      }

      this.router.navigate(["/resource-management", "misc", "buribooks-edit"], {
        replaceUrl: true,
        queryParamsHandling: "merge",
        queryParams: queryParams,
      });
    } else if (
      resource.type === "assessment" &&
      (resource.subtype === "freedom-wall" ||
        resource.subtype === "file-upload")
    ) {
      // Goto question editor
      let sections = await firstValueFrom(
        this.v1ExamsService.examsSectionsList(
          resource.content?.examDetails?.examUuid
        )
      );
      let section = sections[0];

      let items = await firstValueFrom(
        this.v1ExamsService.examsSectionsItemsList(section.uuid)
      );

      let item = items[0];

      let queryParams = {
        exam_uuid: resource.content?.examDetails?.examUuid,
        resource_uuid: resource.uuid,
        item_uuid: item.uuid,
      };

      if (!isInsideCourse) {
        queryParams["publish_resource_on_next"] = true;
      }

      this.router.navigate(
        ["/resource-management", "misc", "activity-item", item.uuid],
        {
          replaceUrl: true,
          queryParamsHandling: "merge",
          queryParams,
        }
      );
    } else if (resource.type === "assessment") {
      // Goto exam editor

      let queryParams = {
        exam_uuid: resource.content?.examDetails?.examUuid,
        resource_uuid: resource.uuid,
        // disable_show_results: true,
      };

      if (!isInsideCourse) {
        queryParams["publish_resource_on_next"] = true;
      }

      this.router.navigate(
        ["/resource-management", "misc", "assessment-activity-edit"],
        {
          replaceUrl: true,
          queryParamsHandling: "merge",
          queryParams,
        }
      );
    } else if (resource.type === "casticulate") {
      if (!isInsideCourse) {
        window.location.href = `/editors/casticulate-editor/new?resource_uuid=${resource.uuid}&publish_resource_on_next=true`;
      } else {
        window.location.href = `/editors/casticulate-editor/new?resource_uuid=${resource.uuid}&course_uuid=${courseUuid}&course_resource_uuid=${courseResourceUuid}&resource_collection_uuid=${parentUuid}`;
      }
    } else {
      if (isInsideCourse) {
        // this.location.back();
        window.location.href = `/courses/content-edit?course_uuid=${courseUuid}&resource_collection_uuid=${parentUuid}&resource_uuid=${resource.uuid}&from_screen=add_resource`;
      } else {
        this.router.navigate(["publish"], {
          relativeTo: this.route.parent,
          replaceUrl: true,
          queryParamsHandling: "merge",
          queryParams: {
            resource_edit_on_back: true,
            resource_uuid: resource.uuid,
          },
        });
      }
    }
  }
}
