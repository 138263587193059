<ng-container *ngIf="loading">
  <div class="w-full h-40 relative">
    <app-loading [loading]="true" position="relative"></app-loading>
  </div>
</ng-container>

<ng-container *ngIf="!loading">
  <ng-container *ngIf="previewMode">
    <ng-container *ngIf="examItemFormGroups.length == 0">
      <div
        class="w-full bg-gray-100 flex flex-col items-center justify-center rounded-lg p-5"
      >
        <app-icon class="text-[6em] text-gray-300 my-5" icon="apps"></app-icon>

        <div class="text-xl font-bold text-gray-400 my-2">
          Nothing to see here
        </div>
        <div class="text-gray-300">add question through edit view</div>
      </div>
    </ng-container>

    <ng-container *ngIf="examItemFormGroups.length > 0">
      <div class="overflow-x-scroll whitespace-nowrap">
        <ng-container
          *ngFor="let examItemFormGroup of examItemFormGroups; let i = index"
        >
          <div class="inline-block h-full w-full align-top">
            <!-- class="inline-block w-[calc(100%)]" -->
            <app-burislides-block-activity-preview
              [choices]="examItemFormGroup.value.choices"
              [item]="examItemFormGroup.value"
              [itemType]="examItemFormGroup.value.type"
              [feedback]="{}"
              [choiceSetFeedbacks]="[]"
            ></app-burislides-block-activity-preview>
          </div>
        </ng-container>

        <div
          *ngIf="resultPageFormGroup.value.isResultPageShown"
          class="inline-flex flex-col h-full w-full align-top items-center justify-center"
        >
          <div class="text-2xl my-2 text-bold">
            You got 0 out of {{ examItemFormGroups.length }}!
          </div>

          <div class="my-2">
            <app-markdown-viewer
              [value]="resultPageFormGroup.value.resultPageText"
            ></app-markdown-viewer>
          </div>

          <ng-container *ngIf="resultPageFormGroup.value.resultPageImageUri">
            <img
              [src]="resultPageFormGroup.value.resultPageImageUri"
              alt=""
              class="w-32 h-32 object-contain"
            />
          </ng-container>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="editMode">
    <!-- <div class="flex justify-end w-full">
      <button class="btn btn-primary" (click)="onSave()">Save</button>
    </div> -->

    <label class="flex my-5">
      <app-checkbox
        class="mr-2 text-primary text-2xl leading-none"
        [formControl]="resultPageFormGroup.controls['isResultPageShown']"
      ></app-checkbox>

      <div>
        <div class="form-label">Show result page</div>
        <div class="text-sm text-gray-600 leading-none">
          Show score results at the end of Group of questions
        </div>
      </div>
    </label>

    <button
      class="text-center text-cyan-500 w-full p-2 text-4xl"
      (click)="onAddActivityClick(0)"
    >
      <app-icon icon="add_circle"></app-icon>
    </button>

    <ng-container
      *ngFor="let examItemFormGroup of examItemFormGroups; let i = index"
    >
      <div class="flex items-start my-5">
        <div class="border rounded-xl border-gray-400 p-4 flex-1 w-full">
          <div class="text-center">
            <div class="text-lg text-gray-500 font-bold">
              Question {{ i + 1 }}
            </div>
            <div class="text-2xl text-gray-700 font-bold">
              {{
                examItemFormGroup.value.subtype || examItemFormGroup.value.type
                  | activityType
              }}
            </div>
          </div>

          <ng-container
            *ngTemplateOutlet="
              itemFormTemplate;
              context: { formGroup: examItemFormGroup }
            "
          ></ng-container>
        </div>

        <span>
          <button
            class="btn btn-icon bg-white text-gray-500"
            (click)="onDeleteItem(examItemFormGroup.value.itemUuid)"
          >
            <app-icon icon="delete"></app-icon>
          </button>
        </span>
      </div>

      <button
        class="text-center text-cyan-500 w-full p-2 text-4xl"
        (click)="onAddActivityClick(i + 1)"
      >
        <app-icon icon="add_circle"></app-icon>
      </button>
    </ng-container>

    <div
      class="border rounded-xl border-gray-400 p-4 flex-1 w-full"
      *ngIf="resultPageFormGroup.value.isResultPageShown"
    >
      <div class="text-center">
        <div class="font-bold text-2xl text-gray-700">Results</div>
        <div class="text-lg text-gray-500">
          This will display the total score
        </div>
      </div>

      <div class="my-5">
        <label for="" class="form-label"> Supporting Text </label>

        <app-markdown-viewer-and-editor
          [autoResize]="true"
          editorClass="h-40 overflow-y-scroll"
          viewerClass="h-40 overflow-y-scroll border rounded p-2"
          [formControl]="resultPageFormGroup.get('resultPageText')"
        ></app-markdown-viewer-and-editor>
      </div>

      <div class="my-5">
        <label for="" class="form-label"> Supporting Image </label>

        <div class="mt-2 h-32 w-32 bg-purple-200">
          <app-upload-loading-wrapper
            #thumbnailInput
            (upload)="
              onUpload($event, resultPageFormGroup.get('resultPageImageUri'))
            "
            accept="image/jpeg,image/jpg,image/png,image/gif"
          >
            <div class="w-full h-full">
              <div
                *ngIf="!resultPageFormGroup.value.resultPageImageUri"
                class="h-full text-purple-500 w-full flex flex-col items-center justify-center"
              >
                <app-icon class="text-6xl" icon="image"></app-icon>
              </div>

              <img
                *ngIf="resultPageFormGroup.value.resultPageImageUri"
                [src]="resultPageFormGroup.value.resultPageImageUri"
                alt=""
                class="w-full object-contain h-full"
              />
            </div>
          </app-upload-loading-wrapper>
        </div>
        <div class="text-sm text-gray-500 mb-1">
          Recommended aspect ratio is 4:3 or 16:10.
        </div>
      </div>
    </div>

    <div class="flex justify-end w-full my-5">
      <button class="btn btn-primary" (click)="onSave()">Save</button>
    </div>
  </ng-container>
</ng-container>

<ng-template #itemFormTemplate let-formGroup="formGroup">
  <div [formGroup]="formGroup" class="relative form-width">
    <div
      *ngIf="formGroup.disabled"
      class="absolute inset-0 bg-white bg-opacity-25 z-10"
    ></div>

    <!-- <div class="my-10">
        <label>
          <span class="form-label">Title</span>

          <input type="text" formControlName="title" class="form-control" />
        </label>

      </div> -->

    <div class="my-10">
      <label>
        <span class="form-label">Question</span>

        <div class="flex items-start justify-start">
          <div class="flex-1">
            <input type="text" formControlName="text" class="form-control" />
            <app-upload-loading-wrapper
              #uploader
              accept="image/*"
              text="UPLOAD IMAGE"
              [ngClass]="{
                hidden: !formGroup.value.mediaUrl
              }"
              (upload)="onUpload($event, formGroup.get('mediaUrl'))"
            >
              <div class="relative" *ngIf="formGroup.value.mediaUrl">
                <img
                  [src]="formGroup.value.mediaUrl"
                  class="w-full h-40 object-contain bg-gray-300 rounded"
                />

                <button
                  type="button"
                  (click)="onUploadClear(formGroup.get('mediaUrl'))"
                  class="appearance-none pt-1 pb-2 pr-1 pl-2 z-auto rounded-bl-full hover:bg-red-500 text-white bg-gray-500 absolute top-0 right-0"
                >
                  <app-icon icon="close"></app-icon>
                </button>
              </div>
              <!-- 
                <div
                  *ngIf="!formGroup.value.mediaUrl"
                  class="w-full h-40 flex flex-col items-center justify-center bg-gray-300 text-primary rounded"
                >
                  <app-icon icon="image" class="text-5xl"> </app-icon>
    
                  <span>ADD IMAGE</span>
                </div> -->
            </app-upload-loading-wrapper>
          </div>

          <button
            type="button"
            (click)="uploader.click()"
            class="text-primary text-4xl mr-2"
          >
            <app-icon icon="photo"></app-icon>
          </button>
        </div>
        <div class="text-sm text-gray-500 mb-1">
          Recommended aspect ratio for image is 4:3 or 16:10.
        </div>

        <app-form-control-error-layout
          [control]="formGroup.get('text')"
        ></app-form-control-error-layout>
      </label>
    </div>
    <div
      class="my-5"
      *ngIf="
        formGroup.value.type == 'MC' ||
        (formGroup.value.type == 'PO' && formGroup.value.subtype !== 'SU')
      "
    >
      <label>
        <span class="form-label">Display options as</span>

        <div class="flex items-center">
          <select class="form-control" formControlName="subtype">
            <option
              *ngFor="let option of displayOptions"
              [value]="option.value"
            >
              {{ option.name }}
            </option>
          </select>
        </div>

        <app-form-control-error-layout
          [control]="formGroup.get('subtype')"
        ></app-form-control-error-layout>
      </label>
    </div>
    <div class="my-5" *ngIf="formGroup.value.type == 'SA'">
      <label>
        <span class="form-label">Choose answer input type</span>

        <div class="flex items-center">
          <select class="form-control" formControlName="subtype">
            <option *ngFor="let option of inputTypes" [value]="option.value">
              {{ option.name }}
            </option>
          </select>
        </div>

        <app-form-control-error-layout
          [control]="formGroup.get('subtype')"
        ></app-form-control-error-layout>
      </label>
    </div>

    <div class="my-10">
      <app-exam-item-form-choices-with-question-paths
        *ngIf="
          formGroup.value.type == 'MC' ||
          formGroup.value.type == 'IC' ||
          formGroup.value.type == 'CB' ||
          (formGroup.value.type == 'PO' && formGroup.value.subtype !== 'SU')
        "
        [formGroup]="formGroup"
        [examItemOptions]="examItemOptions"
        (addChoice)="onAddChoice($event, formGroup)"
      ></app-exam-item-form-choices-with-question-paths>

      <app-exam-item-form-shortanswer
        *ngIf="formGroup.value.type == 'SA'"
        [formGroup]="formGroup"
      >
      </app-exam-item-form-shortanswer>

      <app-exam-item-form-file-upload
        *ngIf="formGroup.value.type == 'ME'"
        [formGroup]="formGroup"
      >
      </app-exam-item-form-file-upload>
    </div>

    <ng-container *ngIf="formGroup.value.subtype === 'SU'">
      <div class="my-10">
        <label>
          <span class="form-label">Comment Box Text</span>
          <input
            formControlName="instructions"
            class="form-control"
            placeholder="Text for comment box"
          />
        </label>
      </div>
    </ng-container>

    <div class="my-10">
      <label class="flex mb-4" *ngIf="formGroup.get('required').enabled">
        <app-checkbox
          class="mr-2 text-primary text-2xl leading-none"
          [formControl]="formGroup.controls['required']"
        ></app-checkbox>

        <div>
          <div class="form-label">Required</div>
          <div class="text-sm text-gray-600 leading-none">
            Learner is required to answer this item
          </div>
        </div>
      </label>

      <label class="flex mb-4" *ngIf="formGroup.get('isGraded').enabled">
        <app-checkbox
          class="mr-2 text-primary text-2xl leading-none"
          [formControl]="formGroup.controls['isGraded']"
        ></app-checkbox>

        <div>
          <div class="form-label">Graded</div>
          <div class="text-sm text-gray-600 leading-none">
            This item is graded and will be added to the total grade.
          </div>
        </div>
      </label>

      <ng-container
        *ngIf="
          formGroup.value.type == 'MC' ||
          formGroup.value.type == 'IC' ||
          (formGroup.value.type == 'PO' && formGroup.value.subtype !== 'SU')
        "
      >
        <label class="flex mb-4">
          <app-checkbox
            class="mr-2 text-primary text-2xl leading-none"
            [formControl]="formGroup.controls['shuffleChoices']"
          ></app-checkbox>

          <div>
            <div class="form-label">Shuffle Choices</div>
            <div class="text-sm text-gray-600 leading-none">
              Choices will be shuffled
            </div>
          </div>
        </label>
      </ng-container>

      <div class="mb-4" *ngIf="formGroup.get('showResults').enabled">
        <label class="flex">
          <app-checkbox
            class="mr-2 text-primary text-2xl leading-none"
            [formControl]="formGroup.controls['showResults']"
          ></app-checkbox>
          <div class="mt-1">
            <div class="form-label leading-none">Show Feedback</div>
            <ng-container *ngIf="formGroup.get('showResults').value">
              <div>
                App will show a feedback if the user answered the question
              </div>

              <button
                type="button"
                (click)="onEditFeedback(formGroup, true)"
                *ngIf="formGroup.get('showResults').value"
                class="text-primary-600"
              >
                Edit Feedback
              </button>
            </ng-container>
          </div>
        </label>
      </div>
    </div>
  </div>
</ng-template>
