import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { SHA256 } from "crypto-js";
import { DashboardService } from "./dashboard.service";
import { switchMap, map, shareReplay } from "rxjs/operators";

interface TokenRequest {
  grantType?: string;
  code?: string;
  refreshToken?: string;
  codeVerifier?: string;
}

interface TokenResponse {
  accessToken?: string;
  refreshToken?: string;
  tokenType?: string;
  scope?: string;
  codeChallengeMethod?: string;
  codeChallenge?: string;
}

@Injectable({
  providedIn: "root",
})
export class OpenidService {
  baseUri: String = environment.OAUTH_URI;
  refreshTokenCache: Observable<TokenResponse>;

  constructor(
    private httpCLient: HttpClient,
    private dashboardService: DashboardService
  ) {}

  getLoginUrl(options: {
    codeChallenge?: string;
    state?: string;
    nonce?: string;
  }): Observable<URL> {
    return this.dashboardService.getCast().pipe(
      map((cast) => {
        let loginUrl = new URL(`${environment.OAUTH_URI}/oauth/authorize`);

        // Get value from environment. Para ito na lang yung papalitan kung kailangan ng changes.
        // Also auto change value depending on the domain.
        let realm = environment.DOMAIN_REALM_MAP.get(window.location.origin);

        loginUrl.searchParams.set(
          "client_id",
          cast.data.attributes.auth.clientId
        );

        //Auto change login depending on the domain
        loginUrl.searchParams.set(
          "redirect_uri",
          `${window.location.origin}/auth/callback`
        );

        //Set response type
        loginUrl.searchParams.set("response_type", "code");

        //Set default scope
        loginUrl.searchParams.set("scope", "openid profile email");

        //Set realm if provided
        if (realm) {
          loginUrl.searchParams.set("realm", realm);
        }

        // Set PCKE if provided
        if (options.codeChallenge) {
          loginUrl.searchParams.set("code_challenge", options?.codeChallenge);
          loginUrl.searchParams.set("code_challenge_method", "S256");
        }

        // Set state if provided
        if (options.state) {
          loginUrl.searchParams.set("state", options?.state);
        }

        // Set nonce if provided
        if (options.nonce) {
          loginUrl.searchParams.set("nonce", options?.nonce);
        }

        return loginUrl;
      })
    );
  }

  purgeRefreshTokenCache() {
    this.refreshTokenCache = null;
  }

  tokenFromRefreshToken(options: TokenRequest = {}): Observable<TokenResponse> {
    if (this.refreshTokenCache) {
      return this.refreshTokenCache;
    }

    this.refreshTokenCache = this.dashboardService.getCast().pipe(
      switchMap((cast) => {
        return this.httpCLient.post(`${this.baseUri}/oauth/token`, {
          ...options,
          clientId: cast.data.attributes.auth.clientId,
          redirectUri: `${window.location.origin}/auth/callback`,
          grantType: "refresh_token",
        });
      }),
      shareReplay(1)
    );

    return this.refreshTokenCache;
  }

  tokenFromAuthorizationCode(
    options: TokenRequest = {}
  ): Observable<TokenResponse> {
    return this.dashboardService.getCast().pipe(
      switchMap((cast) => {
        return this.httpCLient.post(`${this.baseUri}/oauth/token`, {
          ...options,
          clientId: cast.data.attributes.auth.clientId,
          redirectUri: `${window.location.origin}/auth/callback`,
          grantType: "authorization_code",
        });
      })
    );
  }

  tokenInfo(options: { accessToken: string }) {
    return this.httpCLient.get(`${this.baseUri}/oauth/token`, {
      headers: {
        Authorization: `Bearer ${options.accessToken}`,
      },
    });
  }

  stringGenerator(length: number) {
    var text = "";
    var possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  generateCodePair() {
    let verifier: string = this.stringGenerator(32);
    let challenge: string = SHA256(verifier).toString();

    return {
      verifier,
      challenge,
    };
  }
}
