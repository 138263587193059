<div
  *ngIf="loading"
  [@fadeInOut]
  class="absolute flex bg-opacity-50 w-3/4 h-1/2 items-center justify-center"
>
  <app-loading [loading]="true" position="relative"></app-loading>
</div>
<h2 class="form-heading mb-8 flex justify-between">
  Filter Results
  <button class="flex justify-end text-lg" mat-dialog-close>
    <app-icon icon="close"></app-icon>
  </button>
</h2>
<div class="text-gray-400 mb-8 text-sm">
  <i>{{ totalCount }} result(s) found</i>
</div>

<div class="mb-8 w-3/4">
  <app-search-bar-query placeholder="Search"></app-search-bar-query>
</div>

<div class="">
  <app-item-select-with-header
    [disableCheckbox]="true"
    [source]="filterUsersObservable"
    [itemTemplate]="itemTemplate"
    [headers]="headers"
    [category]="'user'"
    [key]="'uuid'"
    [limit]="limit"
    [totalCount]="totalCount"
  >
  </app-item-select-with-header>
</div>

<ng-template #itemTemplate let-user="item">
  <div class="flex">
    <div class="block sm:flex mt-2 sm:mt-0">
      <a class="block w-full sm:flex">
        <ng-container *ngIf="defaultHeaders">
          <div
            class="grid grid-cols-1 my-1 w-full sm:flex"
            *ngFor="let header of defaultHeaders"
          >
            <!-- Email -->
            <div class="block sm:w-96 sm:flex" *ngIf="header === 'Email'">
              <div>
                <app-icon
                  icon="person"
                  class="text-white text-xl leading-none px-1 mr-1 bg-gray-200 rounded-full items-center justify-center shrink"
                ></app-icon>
              </div>
              <div
                class="text-sm text-gray-700 sm:items-center flex-wrap truncate sm:w-full"
              >
                {{ user.email }}
                <span
                  *ngIf="!user.confirmedAt"
                  class="text-sm text-red-500 italic"
                >
                  <app-icon icon="error" class="align-bottom"></app-icon>
                  <!-- <span> email not yet confirmed </span> -->
                </span>
              </div>
            </div>
            <!-- Name -->
            <div
              class="leading-tight truncate text-sm sm:items-center sm:w-96"
              *ngIf="header === 'Name'"
            >
              <div class="">{{ user.name }}</div>
            </div>
            <!-- Date Added -->
            <div
              class="text-sm text-disabled-dark sm:items-center truncate sm:w-56"
              *ngIf="header === 'Date Added'"
            >
              <div class="">
                {{ user.createdAt | date }}
              </div>
            </div>

            <!-- Groups -->
            <!-- <div class="sm:w-64 ml-2 sm:flex">
              <div class="flex items-center bg-primary-100 rounded-full w-max">
                <app-icon
                  icon="group"
                  class="ml-1 text-primary-500 mr-1"
                ></app-icon>
                <span
                  class="
                    sm:truncate
                    text-sm text-primary-500
                    mt-2
                    sm:mt-0
                    px-1
                    items-center
                  "
                >
                  <ng-container *ngIf="user.groups.length > 0">
                    {{ user.groups[0].name }}
                  </ng-container>
                </span>
              </div>
              <div *ngIf="user.groups.length > 1" class="text-primary-500">
                +{{ user.groups.length - 1 }} more
              </div>
            </div> -->
            <!-- Active -->
            <div class="sm:w-56 items-center" *ngIf="header === 'Status'">
              <div
                class="shrink w-max bg-success-100 text-success-600 rounded-full px-1 mt-2 sm:mt-0 text-sm"
                *ngIf="user.active"
              >
                Active
              </div>
            </div>
          </div>
        </ng-container>
      </a>
    </div>
  </div>
</ng-template>
