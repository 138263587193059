import { Component, OnInit } from "@angular/core";
import {
  CourseSchedule,
  CoursesService,
} from "src/app/sinigangnababoywithgabi";
import { ActivatedRoute } from "@angular/router";
import { FormGroup, FormBuilder } from "@angular/forms";
import { Location } from "@angular/common";
import { firstValueFrom } from "rxjs";

@Component({
  selector: "app-course-batch-publish",
  templateUrl: "./course-batch-publish.component.html",
})
export class CourseBatchPublishComponent implements OnInit {
  schedule: CourseSchedule;
  batchAccessForm: FormGroup;
  loading: boolean;

  constructor(
    private route: ActivatedRoute,
    private coursesService: CoursesService,
    private formBuilder: FormBuilder,
    private location: Location
  ) {}

  async ngOnInit() {
    this.batchAccessForm = this.formBuilder.group({
      startDateTime: [new Date()],
      endDateTime: [null],
      batchAccess: [],
    });

    let scheduleUuid = this.route.snapshot.queryParams["schedule_uuid"];

    this.schedule = await firstValueFrom(
      this.coursesService.scheduleRead(scheduleUuid)
    );

    if (!this.schedule.start && !this.schedule.end) {
      this.batchAccessForm.get("batchAccess").setValue("forever");
    } else {
      this.batchAccessForm.get("batchAccess").setValue("startend");
      this.batchAccessForm.get("startDateTime").setValue(this.schedule.start);
      this.batchAccessForm.get("endDateTime").setValue(this.schedule.end);
    }
  }

  async updateBatchAccess() {
    try {
      this.loading = true;

      if (this.batchAccessForm.get("batchAccess").value == "forever") {
        this.schedule.start = null;
        this.schedule.end = null;
        //Set start end end date to null
      } else {
        this.schedule.start = this.batchAccessForm.value.startDateTime;
        this.schedule.end = this.batchAccessForm.value.endDateTime;
      }

      await firstValueFrom(
        this.coursesService.scheduleUpdate(this.schedule.uuid, this.schedule)
      );

      this.location.back();
    } catch (e) {
      this.loading = false;
      throw e;
    }
  }
}
