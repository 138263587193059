<div class="relative">
  <div class="absolute z-20 top-0 left-0 p-1">
    <app-icon icon="unfold_more" class="text-gray-600 bg-white"></app-icon>
  </div>

  <div class="absolute z-20 top-0 right-0 p-1">
    <app-casteditor-edit-toggle
      [ngClass]="{
        invisible: disableEdit
      }"
      id="toggle"
      class="z-10"
      [isInEditMode]="isInEditMode"
      (editToggle)="onEditToggle($event)"
      (saveBlock)="onSaveBlock()"
      (deleteBlock)="onDeleteBlock()"
    >
    </app-casteditor-edit-toggle>
  </div>

  <img
    *ngIf="block.properties.items?.[0]?.image"
    [src]="block.properties.items?.[0]?.image"
    [alt]="block.properties.items?.[0]?.name"
  />

  <div
    class="flex justify-center items-center bg-gray-600 h-20"
    *ngIf="!block.properties.items?.[0]?.image"
  ></div>

  <div
    *ngIf="isInEditMode"
    [@fadeInOut]
    class="absolute inset-0 bg-black bg-opacity-25"
  >
    <app-upload-loading-wrapper
      #uploader
      [clicked]="clicked"
      (upload)="onUpload($event)"
    >
      <!-- addblock.open() changed back to uploader.click() for rollback-->
      <button
        (click)="uploader.click()"
        class="flex items-center justify-center w-full h-full absolute inset-0"
      >
        <span class="text-white font-bold">CHANGE IMAGE</span>
      </button>
    </app-upload-loading-wrapper>
  </div>
</div>

<!-- Rollback for canva -->
<!--
<app-modal
  overlayClass="flex justify-center items-center"
  containerClass=""
  #addblock
>
  <app-casteditor-modal-add-image
    [type]="'Image'"
    (addPDF)="addblock.close(); setEvent($event)"
  ></app-casteditor-modal-add-image>
</app-modal>
-->
