import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  NgZone,
  Output,
  ViewChild,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

// import SimpleMDE from "simplemde";

declare var SimpleMDE: any;

@Component({
  selector: "app-simplemde-editor",
  templateUrl: "./simplemde-editor.component.html",
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SimplemdeEditorComponent),
      multi: true,
    },
  ],
})
export class SimplemdeEditorComponent
  implements AfterViewInit, ControlValueAccessor
{
  @Input()
  disabled: boolean;

  @Output() escape = new EventEmitter();

  @ViewChild("editor")
  editor: ElementRef;

  value: string;

  simpleMde: any;

  constructor(private zone: NgZone) {}

  ngAfterViewInit(): void {
    this.zone.runOutsideAngular(() => {
      this.simpleMde = new SimpleMDE({
        element: this.editor.nativeElement,
        forceSync: true,
        status: false,
        spellChecker: false,
        toolbar: [
          "bold",
          "italic",
          "heading",
          "|",
          "quote",
          "unordered-list",
          "ordered-list",
          "|",
          "link",
          "image",
          "table",
          "horizontal-rule",
        ],
      });
      // this.simpleMde.value(this.value || "");
      this.simpleMde.codemirror.on("change", () => {
        // this.value = this.simpleMde.value();
        this.zone.run(() => this.propagateChange(this.simpleMde.value()));
      });
    });
  }

  propagateChange = (_: any) => {};

  writeValue(value: any): void {
    this.value = value;

    this.simpleMde?.value(this.value || "");
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
