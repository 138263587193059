import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { firstValueFrom, Observable } from "rxjs";
import { DashboardService } from "src/app/services/dashboard.service";
import { CoursesService } from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-course-batch-enrollments-signuplink",
  templateUrl: "./course-batch-enrollments-signuplink.component.html",
})
export class CourseBatchEnrollmentsSignuplinkComponent implements OnInit {
  latestUrl: string;

  copiedLink: boolean;
  constructor(
    private coursesService: CoursesService,
    private dashboardService: DashboardService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {}

  copyLink() {
    this.copiedLink = true;
  }
  async generateLink() {
    let { schedule_uuid: scheduleUuid, course_uuid: courseUuid } =
      this.route.snapshot.queryParams;

    let cast = await firstValueFrom(this.dashboardService.getCast());

    let course = await firstValueFrom(
      this.coursesService.courseRead(courseUuid)
    );

    let result = await firstValueFrom(
      this.coursesService.scheduleEnrollsignuplinkCreate(scheduleUuid, {
        appName: cast.data.attributes.title,
        appLogo: cast.data.attributes.castIcon?.web?.nav,
        courseTitle: course.title,
        courseThumbnail: course.coverUri,
        groupIds: cast.data.attributes.defaultGroupId
          ? [cast.data.attributes.defaultGroupId]
          : [],
        isActive: true,
      })
    );

    this.latestUrl = result.linkUrl;

    this.copiedLink = false;
  }
}
