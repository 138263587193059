import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

@Component({
  selector: "app-search-bar-query",
  templateUrl: "./search-bar-query.component.html",
  styleUrls: ["./search-bar-query.component.scss"],
})
export class SearchBarQueryComponent implements OnInit {
  @Input()
  placeholder: any = "Search";
  @Input()
  disabled: any;

  @Input()
  queryField: string = "q";

  searchString: string;

  modelChanged = new Subject<string>();
  subscription: Subscription;

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.searchString = this.route.snapshot.queryParams[this.queryField];

    this.subscription = this.modelChanged
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((result) => {
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: {
            [this.queryField]: result,
            page: 0,
          },
          queryParamsHandling: "merge",
          replaceUrl: true,
        });
      });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  change(value: string) {
    this.modelChanged.next(value);
  }
}
