import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AppCommonModule } from "../app-common-module/app-common-module.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { ExamsRoutingModule } from "./exams-routing.module";
import { ExamsComponent } from "./exams.component";
import { ExamUserSubmissionsComponent } from "./exam-user-submissions/exam-user-submissions.component";
import { ExamSubmissionResponsesComponent } from "./exam-submission-responses/exam-submission-responses.component";
import { ExamSubmissionResponseRubricGradingComponent } from "./components/exam-submission-response-rubric-grading/exam-submission-response-rubric-grading.component";
import { ExamResultsComponent } from "./exam-results/exam-results.component";
import { ExamResultsTextAnswersComponent } from "./components/exam-results-text-answers/exam-results-text-answers.component";
import { ExamResultsChoiceAnswersComponent } from "./components/exam-results-choice-answers/exam-results-choice-answers.component";

@NgModule({
  declarations: [
    ExamsComponent,
    ExamUserSubmissionsComponent,
    ExamSubmissionResponsesComponent,
    ExamSubmissionResponseRubricGradingComponent,
    ExamResultsComponent,
    ExamResultsTextAnswersComponent,
    ExamResultsChoiceAnswersComponent,
  ],
  imports: [
    CommonModule,
    ExamsRoutingModule,
    AppCommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [ExamResultsTextAnswersComponent, ExamResultsChoiceAnswersComponent],
})
export class ExamsModule {}
