import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  defer,
  firstValueFrom,
  forkJoin,
  from,
  Observable,
  of,
  throwError,
} from "rxjs";
import {
  catchError,
  concatMap,
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  tap,
} from "rxjs/operators";
import { fadeInOut } from "src/app/app-common-module/animations/fade";
import {
  Action,
  Header,
} from "src/app/app-common-module/components/list-table-header/list-table-header.component";
import { CsvService } from "src/app/services/csv.service";
import { DashboardService } from "src/app/services/dashboard.service";
import { ModalService } from "src/app/services/modal.service";
import { PassService, Permission, Role } from "src/app/sinigangnababoywithgabi";

interface GroupItem {
  groupUuid?: string;
  groupName?: string;
  userCount?: number;
  isDefaultGroup?: boolean;
  isDeactivatedUserGroup?: boolean;
  createdAt?: string;
  permissions?: Permission[];
  roles?: Role[];
  selected?: boolean;
}

@Component({
  selector: "app-group-list",
  templateUrl: "./group-list.component.html",
  animations: [fadeInOut],
})
export class GroupListComponent implements OnInit {
  @ViewChild("top") topScroll: ElementRef;
  @ViewChild("bottom") bottomScroll: ElementRef;
  page: number;
  loading = false;
  width: number = 0;
  groups: any;
  checkAll: boolean = false;
  headers: Header[] = [
    { name: "Group Name", value: "name", width: "w-96" },
    { name: "Members", value: "users_count", width: "w-56" },
    { name: "Roles", value: "roles", width: "w-96" },
  ];
  groupItems: Action[] = [];
  defaultHeaders: string[] = ["Group Name", "Members"];
  mobileMenu = [{ text: "Export CSV", value: "export" }];
  selected: string[] = [];
  disableHeader: boolean = true;
  orderItems = [
    {
      value: "created_at desc",
      text: "Newest",
    },
    {
      value: "created_at",
      text: "Oldest",
    },
    {
      value: "name",
      text: "Sort A-Z",
    },
    {
      value: "name desc",
      text: "Sort Z-A",
    },
  ];
  currentOrder: string;

  totalGroupCount: number;
  limit = 20;

  groupListObservable: Observable<GroupItem[]>;

  canAddGroupsObservable: Observable<boolean>;

  isExporting: boolean;

  constructor(
    private passService: PassService,
    private route: ActivatedRoute,
    private csvService: CsvService,
    private dashboardService: DashboardService,
    private router: Router,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.constructCanAddGroupsObservable();
    this.constructGroupListObservable();
  }

  constructCanAddGroupsObservable() {
    this.canAddGroupsObservable = this.dashboardService
      .getPermissions()
      .pipe(map((permissions) => permissions.includes("create:groups")));
  }

  constructGroupListObservable() {
    this.groupListObservable = this.route.queryParams.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((params) => {
        this.loading = true;
        let { order, q, page } = params;
        this.currentOrder = order;
        this.groups = null;

        return this.passService.listGroups(
          q,
          this.limit,
          page * this.limit,
          ["users_count", "roles"],
          null,
          order
        );
      }),
      tap((result) => {
        this.groups = result.groups;
        this.totalGroupCount = result.count;
      }),
      switchMap((result) => {
        return this.dashboardService.getCast().pipe(
          map((cast) => {
            return {
              cast,
              groups: result.groups,
            };
          })
        );
      }),
      map(({ cast, groups }) => {
        return groups.map((group) => {
          let groupItem: GroupItem = {
            groupUuid: group.id,
            groupName: group.name,
            userCount: group.usersCount,
            isDefaultGroup: group.id === cast.data.attributes.defaultGroupId,
            isDeactivatedUserGroup:
              group.id === cast.data.attributes.deactivatedUsersGroupId,
            roles: group.roles.filter((role) => !role.name.includes(":")),
            // selected: false,
          };

          return groupItem;
        });
      }),
      tap((result) => {
        // this.groupActions();
        this.loading = false;
        this.groups = result;
      })
    );
  }

  orderBy(field) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParamsHandling: "merge",
      replaceUrl: true,
      queryParams: {
        order: field,
      },
    });
  }

  checkOverflow(element: HTMLElement) {
    if (
      element.offsetHeight < element.scrollHeight ||
      element.offsetWidth < element.scrollWidth
    ) {
      return true;
    }

    return false;
  }

  async exportGroups() {
    this.isExporting = true;

    let result = await firstValueFrom(this.passService.listGroups());

    this.isExporting = false;

    this.csvService.downloadAsCsv(
      result.groups.map((group) => {
        return {
          uuid: group.id,
          name: group.name,
          description: group.description,
        };
      }),
      "groups" + Date.now() + ".csv"
    );
  }
  test(value, selected) {
    if (selected.target.checked) {
      this.selected.push(value);
      // change reference of selected array for input change detection
      this.selected = [...this.selected];

      this.disableHeader = this.selected.length > 0 ? false : true;
    } else if (this.selected.includes(value) && !selected.target.checked) {
      let index = this.selected.indexOf(value);
      if (index !== -1) {
        this.selected.splice(index, 1);
      }
      // change reference of selected array for input change detection
      this.selected = [...this.selected];
      this.disableHeader = this.selected.length > 0 ? false : true;
    }
  }
  reload(event) {
    if (event) {
      window.location.reload();
    }
  }
  exportFromMenu(value) {
    if (value === "export") {
      this.exportGroups();
    }
  }
  selectList(event: Boolean) {
    if (event) {
      this.checkAll = true;
      this.selected = [];
      this.groups.map((group) => {
        this.selected.push(group.id);
        group.selected = true;
      });
    } else {
      this.checkAll = false;
      this.selected = [];
      this.groups.map((group) => {
        group.selected = false;
      });
    }
  }
  currentPage(page) {
    this.page = page;
  }
  selectedItemList(event) {
    this.selected = event;
    this.groupActions();
  }
  groupActions() {
    let observableList: Observable<any>[] = [];
    for (let i = 0; i < this.selected.length; i++) {
      observableList.push(this.passService.deleteGroup(this.selected[i]));
    }
    let observable: Observable<any> = defer(() => {
      return from(
        this.modalService.confirm(
          "Are you sure you want to delete selected group(s)?",
          {
            confirmText: "Delete",
            confirmClass: "btn text-white bg-red-700",
            cancelText: "No",
          }
        )
      ).pipe(
        concatMap((areYouSure) => {
          if (!areYouSure) {
            return throwError(() => new Error("Not Sure"));
          }
          return forkJoin(observableList);
        }),

        concatMap(() => {
          return from(this.modalService.alert("Group(s) successfully deleted"));
        }),
        catchError((error) => {
          return of(null);
        })
      );
    });
    this.groupItems = [];
    this.groupItems.push({
      name: "Remove",
      action: observable,
    });
  }
}
