import { Component, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { PermissionHelperService } from "src/app/services/permission-helper.service";
import {
  Collection,
  ResourcesService,
  PassService,
  Permission,
} from "src/app/sinigangnababoywithgabi";

interface GroupListItem {
  groupUuid: string;
  groupName: string;
  roleNames: string[];
  actions: string[];
}

@Component({
  selector: "app-collection-permissions",
  templateUrl: "./collection-permissions.component.html",
  styleUrls: ["./collection-permissions.component.scss"],
})
export class CollectionPermissionsComponent implements OnInit {
  collection: Collection;

  rolePermissionFormGroups: UntypedFormGroup[];

  constructor(
    private route: ActivatedRoute,
    private resourcesService: ResourcesService,
    private passService: PassService,
    private permissionHelperService: PermissionHelperService
  ) {}

  async ngOnInit() {
    let { collection_uuid: collectionUuid } = this.route.snapshot.queryParams;

    this.collection = await this.resourcesService
      .getCollectionByUuid(collectionUuid)
      .toPromise();

    await this.parseRolesAndPermissions();
  }

  private unsubscribe$: Subject<void> = new Subject<void>();
  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  async parseRolesAndPermissions() {
    let roles: any = await this.passService
      .listRoles(`crud:collections:${this.collection.uuid}`, null, null, [
        "groups",
        "permissions",
      ])
      .toPromise()
      // HARDCODE: Type cast to any until proper openAPI models are updated,
      .then((result: any) => result["roles"]);

    this.rolePermissionFormGroups = roles
      .filter((role) => role.groupIds.length > 0)
      .map((role) =>
        this.permissionHelperService.createRoleToggleFormGroup({
          groupId: role.groupIds[0],
          roleId: role.id,
          roleName: `crud:collections:${this.collection.uuid}`,
          roleDescription: role.groups[0].name,
          permissionAttributes: [
            {
              name: `read:collections:${this.collection.uuid}`,
              description: "Can read the collection",
            },
            {
              name: `update:collections:${this.collection.uuid}`,
              description: "Can update the collection",
            },
            {
              name: `delete:collections:${this.collection.uuid}`,
              description: "Can delete the collection",
            },
          ],
          existingPermissionList: role.permissions,
          unsubscribe: this.unsubscribe$,
        })
      );
  }
}
