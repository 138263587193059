import { Component, Input, OnInit } from "@angular/core";

const COLOR_TYPE_CLASS_MAP = {
  warning: "bg-warning-200 text-warning-600",
  success: "bg-green-200 text-green-600",
  successer: "bg-green-300 text-green-700",
  default: "bg-gray-200 text-gray-600",
  error: "bg-error-200 text-error-600",
  info: "bg-info-200 text-info-600",
};

@Component({
  selector: "app-pill-label",
  templateUrl: "./pill-label.component.html",
  styles: [],
})
export class PillLabelComponent implements OnInit {
  @Input()
  type: string = "default";

  pillClass: string;

  constructor() {}

  ngOnInit(): void {
    this.pillClass = `${
      COLOR_TYPE_CLASS_MAP[this.type] || COLOR_TYPE_CLASS_MAP["default"]
    } rounded-full text-xs px-2 whitespace-nowrap`;
  }
}
