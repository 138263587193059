import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { firstValueFrom } from "rxjs";
import { UserPreferenceService } from "src/app/services/user-preference.service";
import { OAuthService } from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-logout",
  templateUrl: "./logout.component.html",
  styleUrls: ["./logout.component.scss"],
})
export class LogoutComponent implements OnInit {
  constructor(
    private oauthService: OAuthService,
    private router: Router,
    private userPreferenceService: UserPreferenceService
  ) {
    this.router.onSameUrlNavigation = "reload";
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  async ngOnInit() {
    await firstValueFrom(this.oauthService.destroySession());

    // let isAddBlockTutorialDisabled =
    //   this.userPreferenceService.isAddBlockTutorialDisabled;
    // let isWelcomeMessageDisabled =
    //   this.userPreferenceService.isWelcomeMessageDisabled;

    localStorage.clear();

    // if (isAddBlockTutorialDisabled) {
    //   this.userPreferenceService.isAddBlockTutorialDisabled =
    //     isAddBlockTutorialDisabled;
    // }

    // if (isWelcomeMessageDisabled) {
    //   this.userPreferenceService.isWelcomeMessageDisabled =
    //     isWelcomeMessageDisabled;
    // }

    this.router
      .navigate(["/auth", "login"], {
        queryParamsHandling: "merge",
        replaceUrl: true,
      })
      .then(() => {
        window.location.reload();
      });
  }
}
