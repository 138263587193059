import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { firstValueFrom, map, Observable, switchMap, tap } from "rxjs";
import { ModalService } from "src/app/services/modal.service";
import {
  Block,
  Course,
  CourseBlock,
  CoursesService,
  PassService,
  User,
  V1ExamsService,
} from "src/app/sinigangnababoywithgabi";

interface CourseDetail {
  courseName: string;
  courseStatus?: string;
  coverUri: string;

  isCertificateActive?: boolean;
  userCertificateUuid?: string;
  certificateUrl?: string;

  sections?: {
    name: string;

    blocks: {
      name: string;
      coverUri?: string;
      score?: string;
      status?: string;
      type?: string;

      examTimeslotUuid?: string;
      submissionHistoryObs?: Observable<any>;
    }[];
  }[];
}

interface AssessmentListItem {
  blockName?: string;
  blockType?: string;
  status?: string;

  examTimeslotUuid?: string;
  submissionHistoryObs?: Observable<any>;
  // {
  //   submissionScore: number;
  //   submissionDate: Date;
  // }[]
}

@Component({
  selector: "app-user-course-progress",
  templateUrl: "./user-course-progress.component.html",
  styles: [],
})
export class UserCourseProgressComponent implements OnInit {
  user: User;
  courseDetail: CourseDetail;
  deleteSubmissionInProgress: boolean = false

  assessmentItems: AssessmentListItem[];

  constructor(
    private passService: PassService,
    private courseService: CoursesService,
    private v1ExamsService: V1ExamsService,
    private route: ActivatedRoute,
    private modalService: ModalService
  ) { }

  async ngOnInit() {
    this.initAssessmentList();
    this.initUser();
    let { course_uuid: courseUuid, user_uuid: userUuid } =
      this.route.snapshot.queryParams;

    let course = await firstValueFrom(
      this.courseService.courseRead(courseUuid, "outline,reference_info")
    );

    this.courseDetail = {
      courseName: course.title,
      coverUri: course.coverUri,
    };

    let sectionMap = {};

    course.outline.forEach((section) => {
      sectionMap[section.uuid] = section;
    });

    let courseUserCertificate = await firstValueFrom(
      this.courseService
        .courseUsercertificatesList(courseUuid, userUuid)
        .pipe(map((r) => r[0]))
    );

    this.courseDetail.isCertificateActive = courseUserCertificate?.isActive;
    this.courseDetail.certificateUrl = courseUserCertificate?.certificateUrl;
    this.courseDetail.userCertificateUuid = courseUserCertificate?.uuid;

    let courseStatus = await firstValueFrom(
      this.courseService
        .courseStatisticsCourseStatuses(
          courseUuid,
          userUuid,
          null,
          "section_statuses,block_statuses"
        )
        .pipe(map((r) => r[0]))
    );

    this.courseDetail.courseStatus = courseStatus.status;

    this.courseDetail.sections = courseStatus.sectionStatuses.map(
      (sectionStatus) => {
        let section = sectionMap[sectionStatus.section];

        return {
          name: section.title,
          blocks: section.blocks.map((block: CourseBlock) => ({
            name: block.title,
            coverUri: block.coverUri,
            blockStatusUuid: sectionStatus.blockStatuses.find(
              (bs) => bs.block == block.uuid
            )?.uuid,
            status: sectionStatus.blockStatuses.find(
              (bs) => bs.block == block.uuid
            )?.status,
            type: block.content.resourceDetails?.type,
            examTimeslotUuid: block.referenceInfo?.referenceId,
            submissionHistoryObs: block.referenceInfo?.referenceId
              ? this.v1ExamsService.examTimeslotSubmissionsHistory(
                block.referenceInfo?.referenceId,
                null,
                null,
                userUuid
              )
              : null,
          })),
        };
      }
    );


    this.deleteSubmissionInProgress = false
    console.log(this.courseDetail.sections);
  }

  async initAssessmentList() {

    let { course_uuid: courseUuid, user_uuid: userUuid } =
      this.route.snapshot.queryParams;

    let course = await firstValueFrom(
      this.courseService.courseRead(courseUuid, "outline,reference_info")
    );

    // REFACTOR if may course retaking feature na
    let blockStatuses = await firstValueFrom(
      this.courseService.courseBlockstatuses(courseUuid, userUuid, true)
    );

    let blockStatusMap = blockStatuses.reduce((map, item) => {
      map[item.block] = item;

      return map;
    }, {});

    this.assessmentItems = course.outline
      .reduce<CourseBlock[]>((arr, section) => {
        section.blocks.forEach((block) => {
          if (block.requiredForCertificate) arr = [...arr, block];
        });

        return arr;
      }, [])
      .map((block) => {
        return {
          blockName: block.title,
          blockType: block.content?.resourceDetails?.type,
          status: blockStatusMap[block.uuid]?.status,
          coverUri: block.coverUri,
          examUuid: block.content?.resourceDetails?.examUuid,
          examTimeslotUuid: block.referenceInfo?.referenceId,
          submissionHistoryObs:
            this.v1ExamsService.examTimeslotSubmissionsHistory(
              block.referenceInfo?.referenceId,
              null,
              null,
              userUuid
            ),
        };
      });
  }

  async initUser() {
    let { user_uuid: userUuid } = this.route.snapshot.queryParams;

    this.user = await firstValueFrom(
      this.passService.getUser(userUuid, ["groups"])
    );
  }

  async revokeCertificate() {
    let result = await this.modalService.confirm(
      "Are you sure you want to revoke this certificate?"
    );

    if (result) {
      let cert = await firstValueFrom(
        this.courseService.courseUsercertificatesUpdate(
          this.courseDetail.userCertificateUuid,
          {
            isActive: false,
          }
        )
      );

      this.courseDetail.isCertificateActive = cert.isActive;
    }
  }

  async setAsPassed() {
    let { course_uuid: courseUuid, user_uuid: userUuid } =
      this.route.snapshot.queryParams;

    let confirmed = await this.modalService.confirm(
      "Setting the following user as passed will automatically mark their course progress to complete/certificate issued. Continue?"
    );

    if (!confirmed) {
      return;
    }

    let result = await firstValueFrom(
      this.courseService.courseUpdatestatus(courseUuid, {
        batchUpdate: [
          {
            user: userUuid,
            certStatus: "passed",
            courseStatus: "passed",
            isComplete: true,
          },
        ],
      })
    );

    this.ngOnInit();
  }

  async deleteAttempt(blockStatusUuid: string, deleteAll?: boolean) {

    let result = await this.modalService.confirm(
      "Are you sure you want to delete the recent attempt for this assessment?"
    );

    if (!result) {
      return
    }


    this.deleteSubmissionInProgress = true
    try {
      let deleteSubmission = await firstValueFrom(
        this.courseService.blockStatusDeleteSubmissionTaskCreate(blockStatusUuid, deleteAll)
      );

      let pending = true

      while (pending) {
        try {
          let getStatus = await firstValueFrom(
            this.courseService.deleteSubmissionTaskGet(deleteSubmission.uuid)
          );

          if (getStatus.status == "COMPLETED") {
            pending = false
            this.ngOnInit();
          }
        } catch (err) {
          console.log(err)
          throw err
        }

      }
    } catch (err) {
      console.log(err)
      throw err;
    }
  }
}
