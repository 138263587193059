<form [formGroup]="formGroup" (ngSubmit)="onSave()">
  <h2 class="text-center font-bold text-2xl mb-4">Markdown</h2>
  <app-markdown-viewer-and-editor
    [autoResize]="false"
    editorClass="h-[627px] overflow-y-scroll"
    viewerClass="h-[627px] overflow-y-scroll border rounded p-2"
    formControlName="markdownText"
  >
  </app-markdown-viewer-and-editor>
  <div class="flex justify-end mt-4">
    <button class="btn btn-primary text-white bg-primary-500" type="submit">
      save
    </button>
  </div>
</form>
