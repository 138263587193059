<div class="flex flex-wrap justify-center text-primary">
  <ng-container *ngFor="let activityType of activityTypes">
    <button
      (click)="onTypeSelect(activityType)"
      class="
        flex flex-col
        justify-center
        items-center
        border
        rounded
        h-40
        w-40
        m-2
        transition-colors
        duration-100
        cursor-pointer
      "
      [ngClass]="{
        'text-gray-400 hover:bg-gray-200 hover:text-white':
          activityType.disabled,
        'border-primary hover:bg-primary hover:text-white':
          !activityType.disabled
      }"
      [disabled]="activityType.disabled"
    >
      <app-icon class="text-6xl my-2" [icon]="activityType.icon"> </app-icon>

      <span class="mb-5">
        {{ activityType.value | activityType }}
      </span>
    </button>
  </ng-container>
</div>
