import { trigger, transition, style, animate } from "@angular/animations";

export const slideIn = trigger("slideIn", [
  transition(":enter", [
    style({ transform: "translateX(30%)" }),
    animate("100ms ease-in", style({ transform: "translateX(0%)" })),
  ]),
  // transition(":leave", [
  //   animate("200ms ease-in", style({ transform: "translateX(-100%)" })),
  // ]),
]);
