<app-back-title-layout>
  <div
    *ngIf="loading"
    [@fadeInOut]
    class="absolute inset-0 bg-white bg-opacity-50 h-full"
  >
    <app-loading [loading]="true" position="relative"></app-loading>
  </div>
  <button
    [routerLink]="['create']"
    class="bg-primary-600 text-white text-sm rounded py-1 px-2 my-4 uppercase"
  >
    <app-icon [icon]="'add_circle'"></app-icon>
    Create New Template
  </button>

  <div class="grid grid-cols-2 gap-4">
    <ng-container *ngFor="let template of templates">
      <a
        class="border rounded border-gray-300 p-4 cursor-pointer"
        [routerLink]="['edit']"
        [queryParams]="{
          template_uuid: template.uuid
        }"
      >
        <div class="font-bold text-md">
          {{ template.name }}
        </div>
        <div class="text-sm text-gray-400">
          {{ template.description }}
        </div>
      </a>
    </ng-container>
  </div>
</app-back-title-layout>
