import { Component } from '@angular/core';

@Component({
  selector: 'app-idis',
  templateUrl: './idis.component.html',
  styles: [
  ]
})
export class IdisComponent {

}
