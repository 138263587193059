import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DashboardService } from "src/app/services/dashboard.service";
import { Block, CastsService } from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-casteditor-block-course-status",
  templateUrl: "./casteditor-block-course-status.component.html",
  styles: [],
})
export class CasteditorBlockCourseStatusComponent implements OnInit {
  constructor() {}

  @Input() block: any;
  @Input() disableEdit: boolean;
  @Output() deleteBlock: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {}

  onDeleteBlock() {
    this.deleteBlock.emit();
  }
}
