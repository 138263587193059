import { CommonModule } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { InMemoryCache } from "@apollo/client/core";
import { ApolloModule, APOLLO_OPTIONS } from "apollo-angular";
import { HttpLink } from "apollo-angular/http";
import { environment } from "src/environments/environment";
import { AppCommonModule } from "./app-common-module/app-common-module.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./auth/login/login.component";
import { LogoutComponent } from "./auth/logout/logout.component";
import { OauthCallbackComponent } from "./auth/oauth-callback/oauth-callback.component";
import { CasteditorModule } from "./casteditor/casteditor.module";
import { CoursesModule } from "./courses/courses.module";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { NotFoundComponent } from "./errors/not-found/not-found.component";
import { ExamsModule } from "./exams/exams.module";
import { GeneralSettingsModule } from "./general-settings/general-settings.module";
import { MailerModule } from "./mailer/mailer.module";
import { PurchasesModule } from "./purchases/purchases.module";
import { ResourceManagementModule } from "./resource-management/resource-management.module";
import { ApiAccessTokenInterceptor } from "./services/api-accesstoken-interceptor";
import { ApiCamelSnakeInterceptor } from "./services/api-camelsnake-interceptor";
import { ApiErrorInterceptor } from "./services/api-error-interceptor";
import { ApiRefreshtokenInterceptor } from "./services/api-refreshtoken-interceptor";
import { ApiModule, Configuration } from "./sinigangnababoywithgabi";
import { UserManagementModule } from "./user-management/user-management.module";
import { ReportingModule } from "./reporting/reporting.module";
import { ApiDedupeInterceptor } from "./services/api-dedupe-interceptor";
import { TisModule } from "./tis/tis.module";
import { IdisModule } from "./idis/idis.module";
import { Router } from "@angular/router";

export function configFunction() {
  return new Configuration({
    withCredentials: true,
    // basePath: "http://localhost:8112",
    // basePath: "https://staging.sinigangnababoywithgabi.buri.dev" //Uncomment for staging url
    basePath: environment.BASE_URI,
  });
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    OauthCallbackComponent,
    DashboardComponent,
    LogoutComponent,
    NotFoundComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    AppCommonModule,
    AppRoutingModule,
    // HttpModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ApiModule.forRoot(configFunction),
    ApolloModule,
    ResourceManagementModule,
    UserManagementModule,
    PurchasesModule,
    CoursesModule,
    ExamsModule,
    CasteditorModule,
    GeneralSettingsModule,
    MailerModule,
    ReportingModule,
    TisModule,
    IdisModule,
  ],
  providers: [
    // Turn off muna. Buggy pa.
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: ApiDedupeInterceptor,
    //   multi: true,
    // },

    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: ApiAccessTokenInterceptor,
    //   multi: true,
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiCamelSnakeInterceptor,
      multi: true,
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: ApiRefreshtokenInterceptor,
    //   multi: true,
    // },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: ApiInterceptor,
    //   multi: true,
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiErrorInterceptor,
      multi: true,
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink) => {
        return {
          cache: new InMemoryCache({
            addTypename: false,
          }),
          link: httpLink.create({
            uri: `${environment.BASE_URI}/modules/graphql`,
            withCredentials: true,
          }),
          defaultOptions: {
            watchQuery: {
              fetchPolicy: "no-cache",
              errorPolicy: "ignore",
            },
            query: {
              fetchPolicy: "no-cache",
              errorPolicy: "all",
            },
          },
        };
      },
      deps: [HttpLink],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
