import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-casteditor",
  templateUrl: "./casteditor.component.html",
  styleUrls: ["./casteditor.component.scss"],
})
export class CasteditorComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
