<div
  *ngIf="loading"
  [@fadeInOut]
  class="
    absolute
    flex bg-opacity-50
    w-3/4
    h-1/2
    items-center
    justify-center
  "
>
  <app-loading [loading]="true" position="relative"></app-loading>
</div>
<h2 class="form-heading mb-8">Courses</h2>
<div class="mb-8 w-3/4">
  <app-search-bar-query placeholder="Search" [queryField]="'course'"></app-search-bar-query>
</div>

<div class="">
  <app-item-select-with-header
    [disableCheckbox]="true"
    [source]="listResourcesObservable"
    [itemTemplate]="itemTemplate"
    [headers]="headers"
    [category]="'course'"
    [key]="'uuid'"
    [limit]="limit"
    [totalCount]="totalCourseCount"
  >
  </app-item-select-with-header>
</div>

<ng-template #itemTemplate let-courseItem="item">
  <div class="flex w-full">
    <div class="block sm:flex mt-2 sm:mt-0">
      <a
        class="flex items-center block w-full"
        (click)="
          outputCourse({
            uuid: courseItem.uuid,
            name: courseItem.name,
            cover: courseItem.coverUri,
            index: currentIndex,
            type: 'course'
          })
        "
        mat-dialog-close
      >
        <div class="grid grid-cols-1 my-1 w-full sm:flex">
          <div class="block sm:flex">
            <div>
              <img
                [src]="courseItem.coverUri"
                class="w-40 h-40 object-contain bg-gray-300"
              />
            </div>
            <div class="text-sm leading-none flex-none ml-4">
              <div class="uppercase text-gray-400">course</div>
              <div class="font-bold">{{ courseItem.name }}</div>
            </div>
          </div>
          <!-- <div class="text-sm sm:w-96">
              <div *ngFor="let role of groupItem.roles">{{ role.name }}</div>
            </div> -->
        </div>
      </a>
    </div>
  </div>
</ng-template>
