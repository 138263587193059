import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { ColorHelperService } from "src/app/services/color-helper.service";
import { ModalService } from "src/app/services/modal.service";
import {
  CastAppearance,
  Course,
  CoursesService,
} from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-course-appearance",
  templateUrl: "./course-appearance.component.html",
  styles: [],
})
export class CourseAppearanceComponent implements OnInit {
  formGroup: UntypedFormGroup;

  course: Course;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private courseService: CoursesService,
    private colorHelperService: ColorHelperService,
    private location: Location,
    private modalService: ModalService
  ) {}

  async ngOnInit() {
    let { course_uuid: courseUuid } = this.route.snapshot.queryParams;

    this.course = await this.courseService.courseRead(courseUuid).toPromise();

    let appearance: CastAppearance = this.course.styleInfo?.base;
    let cardHeaderAppearance: CastAppearance =
      this.course.styleInfo?.cardHeader;

    this.formGroup = this.formBuilder.group({
      courseFontStyle: [appearance?.fontFamily?.[0]],
      courseBackgroundColor: [
        this.colorHelperService.constructRGBAFromCastColor(
          appearance?.backgroundColor
        ) || "rgba(255,255,255,1)",
      ],
      courseForegroundColor: [
        this.colorHelperService.constructRGBAFromCastColor(
          appearance?.foregroundColor
        ) || "rgba(0,0,0,1)",
      ],
      cardHeaderBackgroundColor: [
        this.colorHelperService.constructRGBAFromCastColor(
          cardHeaderAppearance?.backgroundColor
        ) || "rgba(117,43,143,1)",
      ],
      cardHeaderForegroundColor: [
        this.colorHelperService.constructRGBAFromCastColor(
          cardHeaderAppearance?.foregroundColor
        ) || "rgba(255,255,255,1)",
      ],
    });
  }

  async onSave() {
    this.course.styleInfo = {
      base: {
        fontFamily: this.formGroup.value.courseFontStyle
          ? [this.formGroup.value.courseFontStyle]
          : [],
        backgroundColor: this.colorHelperService.constructCastColor(
          this.formGroup.value.courseBackgroundColor
        ),
        foregroundColor: this.colorHelperService.constructCastColor(
          this.formGroup.value.courseForegroundColor
        ),
      },
      cardHeader: {
        fontFamily: this.formGroup.value.courseFontStyle
          ? [this.formGroup.value.courseFontStyle]
          : [],
        backgroundColor: this.colorHelperService.constructCastColor(
          this.formGroup.value.cardHeaderBackgroundColor
        ),
        foregroundColor: this.colorHelperService.constructCastColor(
          this.formGroup.value.cardHeaderForegroundColor
        ),
      },
    };

    await this.courseService
      .courseUpdate(this.course.uuid, this.course)
      .toPromise();

    this.location.back();
  }

  async resetToDefault() {
    let result = await this.modalService.confirm(
      "Are you sure you want to reset settings to default?"
    );

    if (result) {
      this.formGroup.reset({
        courseFontStyle: null,
        courseBackgroundColor: "rgba(255,255,255,1)",
        courseForegroundColor: "rgba(0,0,0,1)",
        cardHeaderBackgroundColor: "rgba(117,43,143,1)",
        cardHeaderForegroundColor: "rgba(255,255,255,1)",
      });
    }
  }
}
