import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "timeDuration",
})
export class TimeDurationPipe implements PipeTransform {
  transform(value: number, ...args: unknown[]): unknown {
    if (value === null || value === undefined) {
      return null;
    }

    var seconds = Math.floor((value / 1000) % 60);
    var minutes = Math.floor((value / 1000 / 60) % 60);
    var hours = Math.floor(value / 1000 / 60 / 60);

    // const hourString = (hours + "").padStart(2, "0");
    // const minuteString = (minutes + "").padStart(2, "0");
    // const secondsString = (seconds + "").padStart(2, "0");

    return `${hours} hr ${minutes} min ${seconds} sec`;
  }
}
