import { Component, OnInit } from "@angular/core";
import {
  AdminReportsService,
  CoursesService,
} from "src/app/sinigangnababoywithgabi";
import { ActivatedRoute } from "@angular/router";
import {
  firstValueFrom,
  from,
  map,
  mergeMap,
  Observable,
  switchMap,
  toArray,
} from "rxjs";

interface CourseStatistic {
  name: string;
  coverUri: string;
  status: string;
}

@Component({
  selector: "app-user-stats",
  templateUrl: "./user-stats.component.html",
})
export class UserStatsComponent implements OnInit {
  totalReadingTime: number;
  attemptedResourcesCount: number;
  completedResourcesCount: number;
  recentActivity: any[];

  courseStatisticsObs: Observable<CourseStatistic[]>;

  constructor(
    private courseService: CoursesService,
    private adminReportsService: AdminReportsService,
    private route: ActivatedRoute
  ) {}

  async ngOnInit() {
    await this.loadUserWithStats();
    await this.loadCourseStats();
  }

  async loadCourseStats() {
    let { user_uuid: userUuid } = this.route.snapshot.queryParams;
    this.courseStatisticsObs = this.courseService
      .courseStatisticsCourseStatuses(null, userUuid)
      .pipe(
        switchMap((result) => from(result)),
        mergeMap(
          (item) =>
            this.courseService.courseRead(item.course).pipe(
              map((result) => {
                return {
                  name: result.title,
                  coverUri: result.coverUri,
                  status: item.status,
                  courseUuid: item.course,
                };
              })
            ),
          10
        ),
        toArray()
      );
  }

  async loadUserWithStats() {
    let { user_uuid: userUuid } = this.route.snapshot.queryParams;

    let result = await firstValueFrom(
      this.adminReportsService.reportUserWithStats(userUuid)
    );

    this.totalReadingTime = result["totalLearningDurationMs"];
    this.attemptedResourcesCount = result["resourceAttemptedCount"];
    this.completedResourcesCount = result["resourceCompletedCount"];
    this.recentActivity = result["recentActivity"];
  }
}
