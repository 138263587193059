import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { V1ExamItem, V1ExamsService } from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-activity-spread-view",
  templateUrl: "./activity-spread-view.component.html",
  styleUrls: ["./activity-spread-view.component.scss"],
})
export class ActivitySpreadViewComponent implements OnInit {
  @Input()
  index: number;

  @Input()
  item: V1ExamItem;

  @Input()
  itemUuid: string;

  @Output()
  trash: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  pencil: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  hideControls = false;

  constructor(private v1ExamsService: V1ExamsService) {}

  async ngOnInit() {
    if (!this.item && this.itemUuid) {
      this.item = await firstValueFrom(
        this.v1ExamsService.examsSectionsItemsRead(this.itemUuid)
      );

      // TODO also list the correct answer for UI

      // let choiceAnswerKeys = await this.examsService
      //   .examsSectionsItemsAnswerkeys(this.itemUuid)
      //   .toPromise();
    }
  }

  onTrash() {
    this.trash.emit(this.item);
  }

  onPencil() {
    this.pencil.emit(this.item);
  }
}
