<div class="input-group mb-5" [formGroup]="formGroup.get('content')">
  <a
    class="block btn btn-primary w-full my-5"
    *ngIf="resource.uuid"
    [routerLink]="['/resource-management', 'misc', 'buribooks-edit']"
    [queryParams]="{
      resource_uuid: resource.uuid,
      exam_uuid: resource.content.examDetails?.examUuid
    }"
  >
    <app-icon icon="edit"></app-icon>
    EDIT ACTIVITY
  </a>

  <a
    class="block btn btn-primary-light w-full my-5"
    *ngIf="resource.uuid"
    [routerLink]="['/resources', 'profile', 'burislide-analytics']"
    [queryParams]="{
      resource_uuid: resource.uuid
    }"
  >
    <app-icon icon="insert_chart"></app-icon>
    Slides Analytics
  </a>
</div>
