import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { firstValueFrom, Subject } from "rxjs";
import { V3PayProduct, V3PayService } from "src/app/sinigangnababoywithgabi";
import { SubscriptionFormGroupFields } from "../components/purchases-subscription-forms/purchases-subscription-forms.component";

@Component({
  selector: "app-purchase-subscription-edit",
  templateUrl: "./purchase-subscription-edit.component.html",
  styles: [],
})
export class PurchaseSubscriptionEditComponent implements OnInit {
  loading: boolean;

  subscriptionFormGroups: FormGroup<SubscriptionFormGroupFields>[];

  constructor(
    private v3PayService: V3PayService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private location: Location
  ) {}

  private unsubscribe$: Subject<void> = new Subject<void>();
  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  async ngOnInit() {
    let {
      product_id: productId,
      subscription_group_uuid: subscriptionGroupUuid,
    } = this.route.snapshot.queryParams;

    // Find single
    let products = await firstValueFrom(
      this.v3PayService.listProducts(
        null,
        null,
        null,
        null,
        null,
        "stock_records,subscription_plan,references",
        null,
        null,
        subscriptionGroupUuid
      )
    );

    this.subscriptionFormGroups = products.map((product) =>
      this.constructSubscriptionFormGroup(product)
    );
  }

  constructSubscriptionFormGroup(product?: V3PayProduct) {
    let subscriptionPlan = product?.subscriptionPlan;
    let stockRecord = product?.stockRecords[0];
    let helixPayReference = product?.references?.find(
      (reference) => reference.referenceType === "helixpay"
    );

    let formGroup = this.formBuilder.group<SubscriptionFormGroupFields>({
      productId: new FormControl(product?.id),
      productTitle: new FormControl(product?.title, Validators.required),

      stockRecordId: new FormControl(stockRecord?.id),
      price: new FormControl(stockRecord?.price, Validators.required),

      subscriptionPlanUuid: new FormControl(subscriptionPlan?.uuid),
      duration: new FormControl(
        subscriptionPlan?.planPeriod
          ? `${subscriptionPlan.planPeriod} ${subscriptionPlan.planUnit}`
          : null,
        Validators.required
      ),
      description: new FormControl(subscriptionPlan?.description),
      isFreeTrialEnabled: new FormControl(!!subscriptionPlan?.trialPeriod),
      freeTrialDuration: new FormControl(
        subscriptionPlan?.trialPeriod
          ? `${subscriptionPlan?.trialPeriod} ${subscriptionPlan?.trialUnit}`
          : null
      ),

      helixPayReferenceUuid: new FormControl(helixPayReference?.uuid || null),
      helixPayReferenceReferenceId: new FormControl(
        helixPayReference?.referenceId || null
      ),
    });

    return formGroup;
  }

  async saveSubscriptions() {
    this.loading = true;

    try {
      await Promise.all(
        // Create subscription if there is an associated product id. Else update the subscription
        this.subscriptionFormGroups.map((sfg) => {
          if (sfg.value.productId) {
            return this.saveSubscription(sfg);
          } else {
            return this.createSubscription(sfg);
          }
        })
      );
      this.location.back();
    } catch (err) {
      this.loading = false;

      throw err;
    }
  }

  async saveSubscription(formGroup: FormGroup<SubscriptionFormGroupFields>) {
    await firstValueFrom(
      this.v3PayService.updateProduct(formGroup.value.productId, {
        title: formGroup.value.productTitle,
        description: formGroup.value.description || "",
        stockRecords: [
          {
            id: formGroup.value.stockRecordId,
            price: formGroup.value.price,
          },
        ],
      })
    );

    let productId = formGroup.value.productId;
    let subscriptionPlanUuid = formGroup.value.subscriptionPlanUuid;

    const [planPeriod, planUnit] = formGroup.value.duration?.split(" ") || [];

    const [trialPeriod, trialUnit] = formGroup.value.isFreeTrialEnabled
      ? formGroup.value.freeTrialDuration?.split(" ") || []
      : [];

    let description = formGroup.value.description || "";

    // For helixpay
    let helixPayReferenceUuid = formGroup.value.helixPayReferenceUuid;
    let helixPayReferenceReferenceId =
      formGroup.value.helixPayReferenceReferenceId;
    await firstValueFrom(
      this.v3PayService.updateSubscriptionPlan(subscriptionPlanUuid, {
        planPeriod: parseInt(planPeriod),
        planUnit,
        trialPeriod:
          formGroup.value.isFreeTrialEnabled && trialPeriod
            ? parseInt(trialPeriod)
            : null,
        trialUnit,
        description,
      })
    );

    // Create/update helix pay reference
    if (helixPayReferenceUuid) {
      await firstValueFrom(
        this.v3PayService.updateProductReference(helixPayReferenceUuid, {
          referenceType: "helixpay",
          referenceId: helixPayReferenceReferenceId,
        })
      );
    } else {
      await firstValueFrom(
        this.v3PayService.createProductReference(productId, {
          referenceType: "helixpay",
          referenceId: helixPayReferenceReferenceId,
        })
      );
    }
  }

  async createSubscription(formGroup: FormGroup<SubscriptionFormGroupFields>) {
    let {
      subscription_group_uuid: subscriptionGroupUuid,
      product_reference_type: productReferenceType,
      product_reference_id: productReferenceId,
    } = this.route.snapshot.queryParams;

    const formGroupValue = formGroup.value;
    const [planPeriod, planUnit] = formGroupValue.duration?.split(" ") || [];
    const [trialPeriod, trialUnit] =
      formGroupValue.freeTrialDuration?.split(" ") || [];

    await firstValueFrom(
      this.v3PayService.createSubscriptionPlanWithProduct({
        product: {
          title: formGroupValue.productTitle,
          stockRecords: [
            {
              price: formGroupValue.price,
            },
          ],
          productReferences: [
            {
              referenceType: productReferenceType,
              referenceId: productReferenceId,
            },
          ],
        },
        plan: {
          name: formGroupValue.productTitle,
          group: subscriptionGroupUuid,
          planPeriod: parseInt(planPeriod),
          planUnit,
          trialPeriod: trialPeriod ? parseInt(trialPeriod) : undefined,
          trialUnit,
          isRecurring: true,
        },
      })
    );
  }
}
