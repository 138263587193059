<app-back-title-layout [loading]="!resource || loadingDone">
  <div class="w-full">
    <ng-container *ngIf="resource">
      <div class="sticky bottom-0 z-0 flex w-full justify-between">
        <div>
          <button
            (click)="done()"
            type="button"
            [disabled]="spreadViews.length === 0 || problems.length > 0"
            class="btn btn-primary my-5"
          >
            {{ publishResourceOnNext ? "NEXT" : "DONE" }}
          </button>

          <button
            (click)="fileInput.click()"
            type="button"
            class="btn btn-primary my-5 ml-4"
          >
            UPLOAD SLIDES
          </button>
        </div>

        <!-- Whole slide color scheme settings -->
        <button
          class="text-gray-500 text-2xl"
          [routerLink]="['appearance']"
          queryParamsHandling="merge"
        >
          <app-icon icon="color_lens"></app-icon>
        </button>
      </div>
      <div class="relative w-full">
        <app-loading
          [loading]="loading"
          wrapperClass="absolute inset-0 bg-white opacity-50 pt-40"
        ></app-loading>

        <div class="flex border rounded max-h-screen">
          <!-- Left column -->
          <div
            class="flex flex-col w-[450px] border-r rounded-r-none items-start py-6 px-10 overflow-y-auto"
          >
            <button
              type="button"
              [disabled]="!canUpdate"
              class="btn w-[160px] py-6 px-10 text-sm bg-secondary-200 text-secondary-400 border-2 border-secondary-400"
              [ngClass]="{ hidden: spreadViews.length > 0 }"
              (click)="openAddBlockModal()"
            >
              <app-icon
                icon="add_circle"
                class="text-secondary-500 text-3xl"
              ></app-icon>
            </button>
            <div
              appDnd
              (fileDropped)="processFiles($event)"
              cdkDropList
              (cdkDropListDropped)="spreadDrop($event)"
              class="relative w-[250px]"
              [cdkDropListDisabled]="!canUpdate"
            >
              <ng-container
                *ngFor="let spreadView of spreadViews; let i = index"
              >
                <div cdkDrag>
                  <div class="flex w-full justify-center">
                    <button
                      class="drag-hide text-secondary-500 rounded font-bold"
                      (click)="openAddBlockModal(i)"
                      [ngClass]="{
                        hidden: !canUpdate || spreadView !== this.currentSlide
                      }"
                    >
                      <app-icon icon="add_circle"></app-icon>
                    </button>
                  </div>

                  <div class="flex">
                    <div>
                      <button
                        class="p-3 text-gray-600"
                        cdkDragHandle
                        [ngClass]="{
                          invisible:
                            !canUpdate || spreadView !== this.currentSlide
                        }"
                      >
                        <app-icon icon="unfold_more"></app-icon>
                      </button>
                    </div>

                    <ng-container *ngIf="spreadView.spread.spreadType">
                      <div class="my-2">
                        <app-burislides-block-select
                          [spreadView]="spreadView"
                          (slidePreview)="setCurrentSlide($event, i)"
                          [selected]="spreadView.selected"
                          [activityItem]="currentActivityItem"
                          [icon]="spreadView.icon"
                        >
                        </app-burislides-block-select>
                      </div>
                    </ng-container>

                    <!-- <ng-container
                      *ngIf="spreadView.spread.spreadType === 'image-spread'"
                    >
                      <app-buribooks-image-spread-edit
                        class="drag-outline flex-grow"
                        (upload)="getProblems()"
                        [spread]="spreadView.spread"
                        [leftPageNumber]="spreadView.leftPageNumber"
                        [rightPageNumber]="spreadView.rightPageNumber"
                        [leftFile]="spreadView.leftFile"
                        [rightFile]="spreadView.rightFile"
                      ></app-buribooks-image-spread-edit>
                    </ng-container>

                    <ng-container
                      *ngIf="spreadView.spread.spreadType === 'activity'"
                    >
                      <div class="relative w-full">
                        <app-activity-spread-view
                          [item]="spreadView.item"
                          [itemUuid]="spreadView.spread.examItemUuid"
                          (pencil)="editActivitySpread($event)"
                        >
                        </app-activity-spread-view>

                        <div
                          @fadeInOut
                          *ngIf="isActivitySpreadInvisible(i)"
                          class="absolute inset-0 bg-gray-700 opacity-75 flex justify-center items-center"
                        >
                          <span class="text-white text-center font-bold p-2">
                            This activity will not be visible
                          </span>
                        </div>
                      </div>
                    </ng-container> -->

                    <div class="m-2">
                      <app-dropdown-layout position="left" width="w-fit">
                        <div
                          dropdownToggle
                          [ngClass]="{
                            hidden:
                              !canUpdate || spreadView !== this.currentSlide
                          }"
                        >
                          <app-icon icon="more_vert"></app-icon>
                        </div>
                        <div dropdownContent>
                          <button
                            class="py-1 px-2 text-left text-primary hover:bg-primary hover:text-white"
                            (click)="deleteSpread(spreadView, i)"
                            [ngClass]="{ invisible: !canUpdate }"
                          >
                            Delete
                          </button>
                        </div>
                      </app-dropdown-layout>
                    </div>
                  </div>

                  <!-- *ngIf="edit" -->
                  <div class="w-full flex justify-center">
                    <button
                      class="drag-hide text-secondary-500 rounded font-bold"
                      (click)="openAddBlockModal(i + 1)"
                      [ngClass]="{
                        hidden: !canUpdate || spreadView !== this.currentSlide
                      }"
                    >
                      <app-icon icon="add_circle"></app-icon>
                    </button>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <!-- Right column -->
          <div class="w-full justify-center items-center p-12 overflow-y-auto">
            <!-- Edit and Preview Buttons here  -->
            <div class="flex justify-between">
              <div>
                <button
                  type="button"
                  class="w-6 h-6 m-2"
                  (click)="toggleEditMode()"
                  [disabled]="editMode"
                  [ngClass]="{
                    'text-disabled-dark': previewMode,
                    'text-primary': editMode
                  }"
                >
                  <app-icon icon="edit"></app-icon>
                </button>
                <button
                  type="button"
                  class="w-6 h-6 m-2"
                  (click)="toggleEditMode()"
                  [disabled]="previewMode || disablePreview"
                  [ngClass]="{
                    'text-disabled-dark': editMode && !disablePreview,
                    'text-primary': previewMode || disablePreview
                  }"
                >
                  <app-icon icon="remove_red_eye"></app-icon>
                </button>
              </div>

              <button
                class="text-gray-500 text-xl"
                [disabled]="editMode"
                (click)="openSlideAppearanceModal(currentSlide?.index)"
              >
                <app-icon icon="color_lens"></app-icon>
              </button>
            </div>
            <button
              type="button"
              (click)="openAddBlockModal()"
              [disabled]="!canUpdate"
              class="btn w-full h-[627px] py-6 px-10 text-sm bg-gray-100 border border-gray-300"
              [ngClass]="{ hidden: currentSlide }"
            >
              <app-icon
                icon="add_circle"
                class="text-gray-300 text-9xl"
              ></app-icon>
            </button>

            <ng-container *ngIf="currentSlide">
              <div class="w-full">
                <app-burislides-block-preview
                  [sectionUuid]="resource.content['examDetails']?.['examSectionUuid']"
                  [editMode]="editMode"
                  [generalAppearance]="resource.content['appearance']"
                  [previewMode]="previewMode"
                  [currentSlide]="currentSlide"
                  (newSlide)="setNewSlide($event)"
                  (files)="processFiles($event)"
                  (upload)="onSave()"
                  (currentActivity)="setCurrentActivity($event)"
                  (isLoading)="checkLoading($event)"
                ></app-burislides-block-preview>
              </div>
            </ng-container>
          </div>
        </div>
        <input
          type="file"
          #fileInput
          multiple
          class="hidden"
          accept="image/*"
          (change)="processBulkUploadSlides($event.target.files)"
        />
        <!--Rollback Canva feature addblock.open() to fileInput.click()-->

        <!-- <div>
          <div
            *ngFor="let problem of problems"
            class="mx-auto text-xs text-red-700 bg-red-200 p-1 my-2 rounded"
          >
            {{ problem }}
          </div>
        </div> -->

        <!-- <hr class="border my-5" /> -->
      </div>
    </ng-container>
  </div>
</app-back-title-layout>

<!--Rollback for Canva feature-->
<!--
  <app-modal
    overlayClass="flex justify-center items-center"
    containerClass=""
    #addblock
  >
    <app-canva-modal
      [type]="'Images'"
      (addPDF)="addblock.close(); setEvent($event)"
    >
    </app-canva-modal>
  </app-modal>
  -->
