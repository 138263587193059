import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { ColorHelperService } from "src/app/services/color-helper.service";
import { ModalService } from "src/app/services/modal.service";
import {
  CastAppearance,
  CastsService,
  Collection,
  ResourcesService,
  Resource,
  V2Block,
  V2CastsService,
} from "src/app/sinigangnababoywithgabi";

interface CatalogBlockAppearance {
  base?: CastAppearance;
  cardHeader?: CastAppearance;
  cardTitle?: CastAppearance;
  cardInfo?: CastAppearance;
}

@Component({
  selector: "app-casteditor-block-catalog-settings",
  templateUrl: "./casteditor-block-catalog-settings.component.html",
  styles: [],
})
export class CasteditorBlockCatalogSettingsComponent implements OnInit {
  formGroup: UntypedFormGroup;

  catalogBlock: V2Block;

  collection: Collection;
  resources: Resource[];

  constructor(
    private v2CastService: V2CastsService,
    private castService: CastsService,
    private formBuilder: UntypedFormBuilder,
    private resourcesService: ResourcesService,
    private route: ActivatedRoute,
    private colorHelperService: ColorHelperService,
    private location: Location,
    private modalService: ModalService
  ) {}

  async ngOnInit() {
    let { block_id: blockId } = this.route.snapshot.queryParams;

    let catalogBlock = await this.v2CastService.blockGet(blockId).toPromise();
    this.catalogBlock = catalogBlock;

    this.collection = await this.resourcesService
      .getCollectionByUuid(
        catalogBlock.data.attributes.properties.uuid,
        null,
        null,
        null,
        ["resources"]
      )
      .toPromise();
    this.resources = this.collection.resources;

    let appearance: CatalogBlockAppearance =
      catalogBlock.data.attributes.properties.appearance;

    this.formGroup = this.formBuilder.group({
      blockId: [catalogBlock.data.id],

      fontStyle: [appearance?.cardHeader?.fontFamily[0]],

      isInfoHidden: [
        (catalogBlock.data.attributes.properties.layout?.info ?? "show") ===
          "hide",
      ],

      limitRadio: [
        catalogBlock.data.attributes.properties.limit ? "custom" : "all",
      ],
      limit: [catalogBlock.data.attributes.properties.limit],

      baseForegroundColor: [
        this.colorHelperService.constructRGBAFromCastColor(
          appearance?.base?.foregroundColor
        ) || "rgba(0,0,0,1)",
      ],
      baseBackgroundColor: [
        this.colorHelperService.constructRGBAFromCastColor(
          appearance?.base?.backgroundColor
        ) || "rgba(255,255,255,1)",
      ],
      cardHeaderForegroundColor: [
        this.colorHelperService.constructRGBAFromCastColor(
          appearance?.cardHeader?.foregroundColor
        ) || "rgba(255,255,255,1)",
      ],
      cardHeaderBackgroundColor: [
        this.colorHelperService.constructRGBAFromCastColor(
          appearance?.cardHeader?.backgroundColor
        ) || "rgba(117,43,143,1)",
      ],
    });
  }

  async onSave() {
    let { slug } = this.route.snapshot.queryParams;

    let appearance: CatalogBlockAppearance = {
      base: {
        fontFamily: this.formGroup.value.fontStyle
          ? [this.formGroup.value.fontStyle]
          : [],
        foregroundColor: this.colorHelperService.constructCastColor(
          this.formGroup.value.baseForegroundColor
        ),
        backgroundColor: this.colorHelperService.constructCastColor(
          this.formGroup.value.baseBackgroundColor
        ),
      },
      cardHeader: {
        fontFamily: this.formGroup.value.fontStyle
          ? [this.formGroup.value.fontStyle]
          : [],
        foregroundColor: this.colorHelperService.constructCastColor(
          this.formGroup.value.cardHeaderForegroundColor
        ),
        backgroundColor: this.colorHelperService.constructCastColor(
          this.formGroup.value.cardHeaderBackgroundColor
        ),
      },
    };

    await this.castService
      .blockUpdate("0", "0", this.formGroup.value.blockId, {
        block: {
          properties: {
            ...this.catalogBlock.data.attributes.properties,
            limit:
              this.formGroup.value.limitRadio === "all"
                ? null
                : this.formGroup.value.limit,
            appearance,
            layout: {
              title: this.formGroup.value.isInfoHidden ? "hide" : "show",
              info: this.formGroup.value.isInfoHidden ? "hide" : "show",
            },
          },
        },
      })
      .toPromise();

    // Redirect to old
    this.location.back();

    // Redirect to new
    // // add random to remove cache
    // let casteditorUrl = new URL(`https://${window.location.host}/casteditor`);

    // if (slug) {
    //   casteditorUrl.searchParams.set("slug", slug);
    // }

    // casteditorUrl.searchParams.set("random", Math.random().toString());

    // window.location.href = casteditorUrl.href;
  }

  async resetToDefault() {
    let result = await this.modalService.confirm(
      "Are you sure you want to reset settings to default?"
    );

    if (result) {
      this.formGroup.reset({
        blockId: this.formGroup.value.blockId,

        isInfoHidden: this.formGroup.value.isInfoHidden,

        fontStyle: null,
        baseForegroundColor: "rgba(0,0,0,1)",
        baseBackgroundColor: "rgba(255,255,255,1)",
        cardHeaderForegroundColor: "rgba(255,255,255,1)",
        cardHeaderBackgroundColor: "rgba(117,43,143,1)",
      });
    }
  }
}
