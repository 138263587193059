import { Component, OnInit } from "@angular/core";
import {
  CoursesService,
  Course,
  CourseBlock,
  CourseSection,
} from "src/app/sinigangnababoywithgabi";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { firstValueFrom } from "rxjs";

@Component({
  selector: "app-course-block-prerequisites-select",
  templateUrl: "./course-block-prerequisites-select.component.html",
})
export class CourseBlockPrerequisitesSelectComponent implements OnInit {
  course: Course;

  block: CourseBlock;

  prerequisiteMap: any;

  constructor(
    private coursesService: CoursesService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {}

  async ngOnInit() {
    let { course_uuid: courseUuid, block_uuid: blockUuid } =
      this.route.snapshot.queryParams;

    this.course = await firstValueFrom(
      this.coursesService.courseRead(courseUuid, "outline")
    );

    this.block = await firstValueFrom(this.coursesService.blockRead(blockUuid));

    this.prerequisiteMap = {};

    this.block["prerequisites"].forEach((prereq: string) => {
      this.prerequisiteMap[prereq] = true;
    });
  }

  async saveBlock() {
    let {
      course_uuid: courseUuid,
      resource_collection_uuid: resourceCollectionUuid,
      resource_uuid: resourceUuid,
    } = this.route.snapshot.queryParams;
    await firstValueFrom(
      this.coursesService.blockUpdate(this.block.uuid, {
        prerequisites: Object.keys(this.prerequisiteMap).filter(
          (prereq) => this.prerequisiteMap[prereq]
        ),
      } as any)
    );

    window.location.href = `/courses/content-edit?course_uuid=${courseUuid}&resource_collection_uuid=${resourceCollectionUuid}&resource_uuid=${resourceUuid}&from_screen=add_resource`;

  }

  onSectionSelect(isChecked: boolean, section: CourseSection) {
    if (isChecked) {
      section.blocks.forEach((block) => {
        // Set all block under section as prereq except the current block to avoid self prereq
        if (this.block.uuid !== block.uuid) {
          this.prerequisiteMap[block.uuid] = true;
        }
      });
    } else {
      section.blocks.forEach((block) => {
        this.prerequisiteMap[block.uuid] = false;
      });
    }
  }
}
