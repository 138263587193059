<div class="d-inline-block relative">
  <ngx-file-drop
    (click)="fileInput.click()"
    class="cursor-pointer"
    (onFileDrop)="dropped($event)"
    dropZoneClassName=""
    contentClassName=""
    [disabled]="progress !== null && progress !== undefined"
    [accept]="accept"
    [multiple]="multiple"
    [multiple]="false"
  >
    <ng-template
      let-openFileSelector="openFileSelector"
      ngx-file-drop-content-tmp
    >
      <ng-content></ng-content>
      <!-- <div
        class="border border-gray-500 rounded w-full flex flex-col items-center p-10"
      >
        <svg
          width="110"
          height="110"
          viewBox="0 0 110 110"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="File / upload_24px">
            <path
              id="icon/file/upload_24px"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M64.167 74.6911H45.8336C43.3128 74.6911 41.2503 72.6286 41.2503 70.1078V47.1911H33.9628C29.8836 47.1911 27.867 42.2411 30.7545 39.3536L51.792 18.3161C52.6483 17.4579 53.8109 16.9756 55.0232 16.9756C56.2356 16.9756 57.3982 17.4579 58.2545 18.3161L79.292 39.3536C82.1795 42.2411 80.117 47.1911 76.0378 47.1911H68.7503V70.1078C68.7503 72.6286 66.6878 74.6911 64.167 74.6911ZM82.5001 83.6287H27.5001C24.9792 83.6287 22.9167 85.6912 22.9167 88.212C22.9167 90.7329 24.9792 92.7954 27.5001 92.7954H82.5001C85.0209 92.7954 87.0834 90.7329 87.0834 88.212C87.0834 85.6912 85.0209 83.6287 82.5001 83.6287Z"
              fill="#AE86F8"
            />
          </g>
        </svg>

        <span class="text-purple-500">CHOOSE FILE TO UPLOAD</span>
      </div> -->
    </ng-template>
  </ngx-file-drop>

  <div
    @fadeInOut
    *ngIf="progress === 1 && !isUploadDone"
    class="
      absolute
      inset-0
      text-primary
      bg-white bg-opacity-75
      flex flex-col
      items-center
      justify-center
    "
  >
    <app-loading wrapperClass="relative block" [loading]="true"> </app-loading>
    <!-- <app-icon class="text-6xl" [spin]="true" icon="autorenew"></app-icon> -->
    <div class="font-bold">Upload complete, server processing.</div>
    <div class="font-bold">Please wait.</div>
  </div>

  <div
    @fadeInOut
    *ngIf="progress !== null && progress !== undefined && progress !== 1"
  >
    <div
      class="
        absolute
        inset-0
        bg-opacity-75 bg-white
        flex
        justify-center
        items-center
        z-10
        cursor-pointer
      "
    ></div>

    <div class="absolute inset-0 flex justify-center items-center z-20">
      <ngx-charts-pie-chart
        [scheme]="{ domain: ['#7c58c5', '#ffffff'] }"
        [results]="[
          {
            name: 'loading',
            value: progress
          },
          {
            name: 'remaining',
            value: 1 - progress
          }
        ]"
        [tooltipDisabled]="true"
        [doughnut]="true"
      >
      </ngx-charts-pie-chart>
    </div>

    <div class="absolute inset-0 flex justify-center items-center z-30">
      <button type="button" class="cursor-pointer" (click)="cancelUpload()">
        <app-icon icon="close" class="text-red-600 text-4xl"></app-icon>
      </button>
    </div>
  </div>
</div>

<input
  #fileInput
  name="uri"
  (change)="fileRead($event)"
  type="file"
  hidden
  [accept]="accept"
  [disabled]="disabled"
/>
