import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-mailer",
  templateUrl: "./mailer.component.html",
  styles: [],
})
export class MailerComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
