<app-back-title-layout [loading]="!formGroup">
  <ng-container *ngIf="formGroup">
    <div class="my-5">
      <label class="form-label">Course</label>
      <div class="text-gray-400 text-xs leading-tight">
        Sample preview of course items
      </div>

      <div
        class="border-2 my-1 p-5"
        [style.backgroundColor]="formGroup.value.courseBackgroundColor"
      >
        <div class="mx-auto">
          <button class="btn btn-icon bg-transparent">
            <app-icon icon="close"></app-icon>
          </button>

          <div
            class="font-bold mb-2 text-center"
            [style.color]="formGroup.value.courseForegroundColor"
            [style.fontFamily]="formGroup.value.fontStyle"
          >
            {{ course?.title }}
          </div>
        </div>

        <div class="pb-5">
          <div
            class="font-bold mb-2"
            [style.color]="formGroup.value.courseForegroundColor"
            [style.fontFamily]="formGroup.value.fontStyle"
          >
            Section 1
          </div>

          <div class="flex">
            <div class="mr-2">
              <app-resource-card
                [resource]="{
                  name: 'Sample Item 1',
                  type: 'video',
                  coverUri: '/assets/default-logo.png'
                }"
                [cardHeaderBackgroundColor]="
                  formGroup.value.cardHeaderBackgroundColor
                "
                [cardHeaderForegroundColor]="
                  formGroup.value.cardHeaderForegroundColor
                "
              >
              </app-resource-card>
            </div>

            <div class="mr-2">
              <app-resource-card
                [resource]="{
                  name: 'Sample Item 2',
                  type: 'pdf',
                  coverUri: '/assets/default-logo.png'
                }"
                [cardHeaderBackgroundColor]="
                  formGroup.value.cardHeaderBackgroundColor
                "
                [cardHeaderForegroundColor]="
                  formGroup.value.cardHeaderForegroundColor
                "
              >
              </app-resource-card>
            </div>
          </div>
        </div>

        <div class="pb-5">
          <div
            class="font-bold mb-2"
            [style.color]="formGroup.value.courseForegroundColor"
            [style.fontFamily]="formGroup.value.fontStyle"
          >
            Section 2
          </div>

          <div class="flex">
            <div class="mr-2">
              <app-resource-card
                [resource]="{
                  name: 'Sample Item 1',
                  type: 'video',
                  coverUri: '/assets/default-logo.png'
                }"
                [cardHeaderBackgroundColor]="
                  formGroup.value.cardHeaderBackgroundColor
                "
                [cardHeaderForegroundColor]="
                  formGroup.value.cardHeaderForegroundColor
                "
              >
              </app-resource-card>
            </div>

            <div class="mr-2">
              <app-resource-card
                [resource]="{
                  name: 'Sample Item 2',
                  type: 'pdf',
                  coverUri: '/assets/default-logo.png'
                }"
                [cardHeaderBackgroundColor]="
                  formGroup.value.cardHeaderBackgroundColor
                "
                [cardHeaderForegroundColor]="
                  formGroup.value.cardHeaderForegroundColor
                "
              >
              </app-resource-card>
            </div>
          </div>
        </div>
      </div>
    </div>

    <form [formGroup]="formGroup" (ngSubmit)="onSave()">
      <label class="form-label">Colors</label>
      <div class="text-gray-400 text-xs">
        Your Cast already have its default overall color theme, changing the
        assigned colors below will override the your overall Cast color theme
      </div>

      <button
        type="button"
        (click)="resetToDefault()"
        class="my-2 text-primary text-sm hover:underline font-semibold"
      >
        Reset to default
      </button>

      <div class="my-5">
        <label class="form-label">Font</label>

        <app-font-select formControlName="courseFontStyle"> </app-font-select>
      </div>

      <div class="my-5">
        <label class="form-label">Course Name Color</label>

        <div class="w-24">
          <app-color formControlName="courseForegroundColor"> </app-color>
        </div>
      </div>

      <div class="my-5">
        <label class="form-label">Background Color</label>

        <div class="w-24">
          <app-color formControlName="courseBackgroundColor"> </app-color>
        </div>
      </div>

      <div class="my-5">
        <label class="form-label">Card Header Color</label>

        <div class="w-24">
          <app-color formControlName="cardHeaderBackgroundColor"> </app-color>
        </div>
      </div>

      <div class="my-5">
        <label class="form-label">Card Header Text Color</label>

        <div class="w-24">
          <app-color formControlName="cardHeaderForegroundColor"> </app-color>
        </div>
      </div>

      <button class="btn btn-primary w-full" type="submit">SAVE</button>
    </form>
  </ng-container>
</app-back-title-layout>
