import { Component, Input } from "@angular/core";
import { Observable, map } from "rxjs";
import { PassService } from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-exam-results-text-answers",
  templateUrl: "./exam-results-text-answers.component.html",
  styles: [],
})
export class ExamResultsTextAnswersComponent {
  @Input() responses: {
    responseText?: string;
    responseUserUuid?: string;

    responseIsCorrect?: boolean;
    responseUsernameObs?: Observable<string>;
  }[] = [];

  @Input() showCorrectness: boolean = true;

  labelId: string;

  constructor(private passService: PassService) {}

  ngOnInit() {
    this.labelId = "labelId" + Math.random();

    this.responses.forEach((response) => {
      response.responseUsernameObs = this.constructNameObs(
        response.responseUserUuid
      );
    });
  }

  constructNameObs(userUuid: string) {
    return this.passService.getUser(userUuid).pipe(
      map((user) => {
        return `${user.firstName} ${user.lastName}`;
      })
    );
  }
}
