<ng-container *ngIf="editMode">
  <!-- new template here -->
  <div
    *ngIf="newItem; else edit"
    class="w-full h-[627px] py-6 px-10 text-sm bg-gray-100 border border-gray-300 overflow-y-auto"
  >
    <app-activity-question-new
      [insideSlide]="true"
      [itemSubtype]="itemSubtype"
      [itemType]="itemType"
      [sectionUuid]="sectionUuid"
      (itemUuid)="onCreate($event)"
      (isLoading)="outputIsLoading($event)"
    ></app-activity-question-new>
  </div>

  <!-- edit template here -->
  <ng-template #edit>
    <div
      class="w-full h-[627px] py-6 px-10 text-sm bg-gray-100 border border-gray-300 overflow-y-auto"
    >
      <ng-container *ngIf="itemType !== 'TA'">
        <app-activity-question-edit
          [insideSlide]="true"
          [examItemUuid]="itemUuid"
          (item)="outputItem($event)"
          (choices)="outputChoices($event)"
          (isLoading)="outputIsLoading($event)"
          (updated)="onExamItemUpdate($event)"
          (choiceSetFeedbacks)="outputChoiceSetFeedbacks($event)"
        ></app-activity-question-edit>
      </ng-container>
      <ng-container *ngIf="itemType === 'TA'">
        <app-tally-activity-edit [insideSlide]="true" [tallyItemUuid]="itemUuid">
        </app-tally-activity-edit>
      </ng-container>
    </div>
  </ng-template>
</ng-container>

<!-- preview template here -->

<ng-container *ngIf="newItem && previewMode">
  <div
    class="w-full h-[627px] text-sm bg-transparent border border-gray-300 overflow-y-auto rounded-2xl"
  >
    <div>
      <div class="my-10" *ngIf="itemType !== 'TA'">
        <i class="text-2xl font-bold text-gray-700">Question</i>
      </div>

      <ng-container [ngSwitch]="itemType">
        <ng-container *ngSwitchCase="'MC'">
          <input
            class="form-control"
            [disabled]="true"
            placeholder="Add option"
          />
        </ng-container>
        <ng-container *ngSwitchCase="'CB'">
          <input
            class="form-control"
            [disabled]="true"
            placeholder="Add option"
          />
        </ng-container>
        <ng-container *ngSwitchCase="'FB'">
          <input
            class="form-control"
            [disabled]="true"
            placeholder="Type in answer..."
          />
        </ng-container>
        <ng-container *ngSwitchCase="'FW'">
          <textarea
            class="form-control"
            [disabled]="true"
            placeholder="Type in answer..."
          ></textarea>
        </ng-container>
        <ng-container *ngSwitchCase="'PO'">
          <input
            class="form-control"
            [disabled]="true"
            placeholder="Add option"
          />
        </ng-container>
        <ng-container *ngSwitchCase="'TA'">
          <div class="text-center mt-40">
            <app-icon
              [icon]="'widgets'"
              class="text-2xl text-primary-300 mb-10"
            ></app-icon>
            <div class="font-bold text-2xl text-primary-400 mb-4">
              Nothing to see here
            </div>
            <div class="text-primary-400">
              Add a question block through edit view
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="previewMode && !newItem">
  <div
    class="w-full h-[627px] text-sm bg-transparent border border-gray-300 overflow-y-auto"
  >
    <app-burislides-block-activity-preview
      [choices]="choices"
      [item]="item"
      [itemType]="itemType"
      [feedback]="feedback"
      [navigated]="navigated"
      [choiceSetFeedbacks]="choiceSetFeedbacks"
    ></app-burislides-block-activity-preview>
  </div>
  <!-- <div class="w-full flex justify-between mt-4">
    <button
      (click)="setFeedback()"
      class="btn rounded-full bg-primary-600 text-white"
    >
      <app-icon icon="arrow_back"></app-icon>
    </button>
    <button
      (click)="getFeedback()"
      class="btn rounded-full bg-primary-600 text-white"
    >
      continue
    </button>
  </div> -->
</ng-container>
