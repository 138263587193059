<app-back-title-layout [loading]="loading">
  <div class="mb-4">
    <nav class="flex w-full mb-4">
      <!-- First parent tab here  -->
      <a
        class="text-xs border-b-4 inline-block py-2 border-gray-300 text-gray-300 px-4 font-bold whitespace-nowrap"
        [routerLink]="['/idis', 'users', 'settings']"
        [replaceUrl]="true"
        [queryParamsHandling]="'merge'"
        [queryParams]="{ parent_tab: 'user_information', page: null }"
        routerLinkActive="text-primary-900 border-primary-900 "
      >
        User Information
      </a>
      <!-- Second parent tab here  -->
      <!-- <a
        class="border-b-4 inline-block py-2 border-gray-300 text-gray-300 px-4 font-bold text-xs whitespace-nowrap"
        [routerLink]="['/idis', 'users', 'settings']"
        [replaceUrl]="true"
        [queryParamsHandling]="'merge'"
        [queryParams]="{ parent_tab: 'training_needs', page: null }"
        routerLinkActive="text-primary-900 border-primary-900 "
      >
        Training Needs
      </a> -->
      <div class="border-b-4 border-gray-300 flex-1"></div>
    </nav>
  </div>

  <div class="flex w-full justify-between">
    <ng-container *ngIf="enabledSubTabs">
      <div class="mr-4">
        <button
          (click)="addSubTab()"
          class="w-full uppercase text-primary-700 rounded border border-primary-700 py-1 px-4 flex justify-center items-center mb-4"
        >
          <app-icon [icon]="'add_circle_outline'" class="mr-1"></app-icon>add
          sub tab
        </button>

        <!-- list of sub tabs here  -->

        <div
          appDnd
          cdkDropList
          (cdkDropListDropped)="spreadDrop($event)"
          class="w-full"
        >
          <ng-container *ngFor="let subTab of subTabs; let i = index">
            <div cdkDrag>
              <div
                class="flex justify-between w-full self-stretch p-2 items-center rounded"
                [ngClass]="{ 'bg-primary-200': subTab.selected }"
              >
                <button class="p-3 text-gray-600" cdkDragHandle>
                  <app-icon icon="unfold_more"></app-icon>
                </button>
                <button
                  class="text-primary-900 text-xs flex flex-1"
                  (click)="selectSubTab(i)"
                >
                  {{ subTab.displayName }}
                </button>
                <button (click)="deleteSubTab(i)" class="text-gray-600">
                  <app-icon [icon]="'delete'"></app-icon>
                </button>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>

    <!-- form here  -->
    <ng-container *ngIf="currentForm">
      <div class="w-full">
        <div class="flex flex-1 justify-between mb-2">
          <div class="font-bold text-lg text-gray-900">
            {{ currentForm.displayName }}
          </div>
          <div class="flex">
            <!-- <div>
              <app-dropdown-layout position="left" width="w-fit">
                <div dropdownToggle>
                  <app-icon icon="arrow_drop_down"></app-icon>
                </div>
              </app-dropdown-layout>
            </div> -->
            <ng-container *ngIf="currentForm && currentForm.fieldGroups">
              <button
                *ngIf="currentForm.fieldGroups.length > 0"
                class="btn btn-primary py-1 mr-4"
                [routerLink]="['/idis', 'users', 'form-submissions']"
                [queryParams]="{
                  tab_uuid: currentForm.id,
                  fieldgroup_uuid: currentForm.fieldGroups[0].id
                }"
                [queryParamsHandling]="'merge'"
              >
                View Submissions
              </button>
            </ng-container>

            <a
              class="text-gray-600"
              [routerLink]="['/idis', 'users', 'settings', 'form-editor']"
              [queryParamsHandling]="'merge'"
              [queryParams]="{ tab_uuid: currentForm.id }"
            >
              <app-icon [icon]="'mode'"></app-icon>
            </a>
          </div>
        </div>
        <ng-container
          *ngIf="
            currentForm?.fieldGroups && currentForm?.fieldGroups.length > 0
          "
        >
          <div class="border rounded-lg bg-white shadow-sm p-6">
            <ng-container
              *ngFor="let fieldGroup of currentForm.fieldGroups; let i = index"
            >
              <div class="font-bold text-base text-gray-700 mb-8">
                {{ fieldGroup.name }}
              </div>

              <ng-container
                *ngFor="let field of fieldGroup.fields; let j = index"
              >
                <label class="text-base text-gray-500 flex flex-col">
                  {{ field.name }}

                  <!-- if fieldtype is dropdown, show select input  -->
                  <ng-container [ngSwitch]="field.fieldType">
                    <ng-container *ngSwitchCase="'dropdown'">
                      <select class="form-control mb-8">
                        <option *ngFor="let choice of field.fieldChoices">
                          {{ choice.name }}
                        </option>
                      </select>
                    </ng-container>
                    <ng-container *ngSwitchCase="'short_text'">
                      <input type="text" class="form-control mb-8" />
                    </ng-container>
                    <ng-container *ngSwitchCase="'paragraph'">
                      <textarea class="form-control mb-8"></textarea>
                    </ng-container>
                    <ng-container *ngSwitchCase="'file_upload'">
                      <div>
                        <button
                          class="uppercase rounded text-sm p-2 border m-2"
                        >
                          <app-icon icon="upload"></app-icon> upload
                        </button>
                      </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'email'">
                      <input
                        [type]="field.fieldType"
                        class="form-control mb-8"
                      />
                    </ng-container>
                    <ng-container *ngSwitchCase="'date'">
                      <input
                        [type]="field.fieldType"
                        class="form-control mb-8"
                      />
                    </ng-container>
                    <ng-container *ngSwitchCase="'time'">
                      <input
                        [type]="field.fieldType"
                        class="form-control mb-8"
                      />
                    </ng-container>
                    <ng-container *ngSwitchCase="'checkbox'">
                      <div class="mb-8 flex flex-col">
                        <ng-container *ngFor="let choice of field.fieldChoices">
                          <label class="text-base text-gray-500">
                            <input [type]="field.fieldType" />
                            {{ choice.name }}
                          </label>
                        </ng-container>
                      </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'radio'">
                      <div class="mb-8 flex flex-col">
                        <ng-container *ngFor="let choice of field.fieldChoices">
                          <label class="text-base text-gray-500">
                            <input
                              [type]="field.fieldType"
                              [name]="field.name"
                            />
                            {{ choice.name }}
                          </label>
                        </ng-container>
                      </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'number'">
                      <input
                        [type]="field.fieldType"
                        class="form-control mb-8"
                      />
                    </ng-container>
                  </ng-container>
                </label>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</app-back-title-layout>
