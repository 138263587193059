import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-pagination",
  templateUrl: "./pagination.component.html",
  styles: [],
})
export class PaginationComponent implements OnInit {
  @Input() page: number;
  @Output() pageChange = new EventEmitter<number>();

  @Input() listLength: number = 1;
  @Input() pageSize: number = 20;

  itemNumberFrom: number;
  itemNumberTo: number;

  constructor() {}

  ngOnInit(): void {}
  ngOnChanges(): void {
    this.itemNumberFrom = this.page * this.pageSize + 1;
    this.itemNumberTo = Math.min(
      this.listLength,
      this.page * this.pageSize + this.pageSize
    );
  }

  onInputChange(event) {
    let value = (parseInt(event.target.value) || 0) - 1;

    this.gotoPage(value);

    event.target.value = this.page + 1;
  }

  gotoPage(page) {
    this.page = Math.min(
      Math.ceil(this.listLength / this.pageSize) - 1,
      Math.max(0, page)
    );

    this.pageChange.emit(this.page);
  }

  incrementPage(value = 1) {
    this.gotoPage(this.page + value);
  }

  gotoFirstPage() {
    this.incrementPage(-Infinity);
  }

  gotoLastPage() {
    this.incrementPage(Infinity);
  }
}
