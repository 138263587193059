<app-back-title-layout [loading]="loading">
  <form [formGroup]="formGroup" class="relative" (ngSubmit)="onSave()">
    <app-exam-item-form [formGroup]="formGroup"> </app-exam-item-form>

    <div class="flex flex-row-reverse">
      <button
        class="btn btn-primary"
        [disabled]="formGroup.invalid"
        type="submit"
      >
        Save
      </button>

      <button
        type="button"
        [disabled]="formGroup.invalid"
        class="btn bg-blue-500 text-white mx-2"
        (click)="onSaveAndCreateAnother()"
      >
        Save and add another item
      </button>
    </div>
  </form>
</app-back-title-layout>
