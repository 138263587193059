import { Component, OnInit, Input } from "@angular/core";
import { trigger, transition, style, animate } from "@angular/animations";
import { AnimationOptions } from "ngx-lottie";

@Component({
  selector: "app-loading",
  templateUrl: "./loading.component.html",
  styleUrls: ["./loading.component.scss"],
  animations: [
    trigger("enterAnimation", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate("200ms ease-out", style({ opacity: 1 })),
      ]),
      transition(":leave", [
        style({ opacity: 1 }),
        animate("200ms ease-in", style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class LoadingComponent implements OnInit {
  @Input() loading;

  //Takes tailwind positon class for flexibility
  //Deprecated in favor or wrapperClass
  @Input() position: string = "fixed";

  //Deprecated in favor or wrapperClass
  @Input() height: string = "";

  //Deprecated in favor or wrapperClass
  @Input() padding: string = "";

  @Input() wrapperClass: string = "";

  constructor() {}

  ngOnInit(): void {}

  options: AnimationOptions = {
    path: "/assets/castloadanimation.json",
  };
}
