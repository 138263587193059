import { Component, Input } from "@angular/core";
import { BurslidesAnalyticsUsersModalComponent } from "src/app/resource-management/components/burslides-analytics-users-modal/burslides-analytics-users-modal.component";
import { ModalService } from "src/app/services/modal.service";

@Component({
  selector: "app-exam-results-choice-answers",
  templateUrl: "./exam-results-choice-answers.component.html",
  styles: [
    `
      progress.neutral::-webkit-progress-value {
        @apply bg-primary-400;
      }
      progress.neutral::-moz-progress-bar {
        @apply bg-primary-400;
      }
      progress.neutral {
        @apply bg-primary-200;
      }

      progress.success::-webkit-progress-value {
        @apply bg-success-400;
      }
      progress.success::-moz-progress-bar {
        @apply bg-success-400;
      }
      progress.success {
        @apply bg-success-200;
      }

      progress.error::-webkit-progress-value {
        @apply bg-error-400;
      }
      progress.error::-moz-progress-bar {
        @apply bg-error-400;
      }
      progress.error {
        @apply bg-error-200;
      }
    `,
  ],
})
export class ExamResultsChoiceAnswersComponent {
  @Input() choiceResults: {
    choiceText: string;
    isCorrect: boolean;
    count: number;
    countMax: number;
    userUuids?: string[];
  }[] = [];

  @Input() showCorrectness: boolean = true;

  constructor(private modalService: ModalService) {}

  async openUsersViewedModal(userUuids: string[]) {
    await this.modalService.openModal(BurslidesAnalyticsUsersModalComponent, {
      panelClass: "w-2/3",
      data: {
        title: "Users who answered this choice",
        userUuids,
      },
    });
  }
}
