<app-back-title-layout hideLogoHeader="true">
  <div backTitleLayoutBeforeMain>
    <ng-container *ngIf="schedule">
      <section class="flex w-full">
        <h3 class="flex-1 text-2xl font-bold">
          <app-icon icon="date_range"></app-icon> {{ schedule?.name }}
        </h3>

        <a
          [routerLink]="['/_courses', 'batches', 'profile']"
          queryParamsHandling="merge"
          class="self-start btn btn-primary btn-icon"
        >
          <app-icon icon="edit"></app-icon>
        </a>
      </section>

      <section class="flex justify-between w-full my-5">
        <div>
          <p class="text-sm font-bold text-gray-500">Enrollment Period:</p>
          <p>
            <app-pill-label [type]="enrollmentDatePillType">
              {{ enrollmentDateStatusText }}
            </app-pill-label>
          </p>
        </div>

        <div>
          <p class="text-sm font-bold text-gray-500">Access Period:</p>

          <p>
            <app-pill-label [type]="accessDatePillType">
              {{ accessDateStatusText }}
            </app-pill-label>

            <span class="mx-1 font-bold"> {{ accessDateText }}</span>
          </p>
        </div>
      </section>
    </ng-container>
  </div>

  <nav class="flex w-full mb-4">
    <button
      class="inline-block px-4 py-2 text-sm font-bold text-gray-300 uppercase border-b-4 border-gray-300 whitespace-nowrap hover:text-secondary-600 hover:border-secondary-600"
      [ngClass]="{
        'text-secondary-600  border-secondary-600': pageMode === 'analytics'
      }"
      (click)="setPageMode('analytics')"
    >
      <app-icon icon="insert_chart" class="mr-2"></app-icon>

      Analytics
    </button>

    <button
      class="inline-block px-4 py-2 text-sm font-bold text-gray-300 uppercase border-b-4 border-gray-300 whitespace-nowrap hover:text-secondary-600 hover:border-secondary-600"
      [ngClass]="{
        'text-secondary-600  border-secondary-600': pageMode === 'answers'
      }"
      (click)="setPageMode('answers')"
    >
      <app-icon icon="assignment" class="mr-2"></app-icon>

      Student Answers
    </button>

    <button
      class="inline-block px-4 py-2 text-sm font-bold text-gray-300 uppercase border-b-4 border-gray-300 whitespace-nowrap hover:text-secondary-600 hover:border-secondary-600"
      [ngClass]="{
        'text-secondary-600  border-secondary-600': pageMode === 'certificates'
      }"
      (click)="setPageMode('certificates')"
    >
      <app-icon icon="assignment" class="mr-2"></app-icon>

      Certificates
    </button>

    <div class="flex-1 border-b-4 border-gray-300"></div>
  </nav>

  <section class="mb-10" *ngIf="pageMode === 'analytics'">
    <div class="mb-1">
      <h2 class="font-bold">Batch Status</h2>
      <div class="text-xs text-gray-600">
        as of {{ currentDate | date : "medium" }}
      </div>
    </div>

    <div class="relative">
      <app-loading-with-retry
        [observable]="overviewObservable"
        [tryOnLoad]="true"
      ></app-loading-with-retry>

      <div class="flex justify-between gap-2 my-2">
        <div
          class="w-full p-2 font-bold leading-tight text-purple-600 bg-purple-200 rounded"
        >
          <div class="text-xs uppercase">Enrolled</div>
          <div class="text-lg">
            {{ overview?.students }}
          </div>
        </div>

        <div
          class="w-full p-2 font-bold leading-tight text-green-600 bg-green-200 rounded"
        >
          <div class="text-xs uppercase">Passed</div>
          <div class="text-lg">
            {{ overview?.completed }}
          </div>
        </div>

        <div
          class="w-full p-2 font-bold leading-tight text-green-700 bg-green-300 rounded"
        >
          <div class="text-xs uppercase">Certificates Issued</div>
          <div class="text-lg">
            {{ overview?.certificatesIssued }}
          </div>
        </div>
      </div>

      <div class="flex justify-between my-2">
        <div
          class="w-full p-2 font-bold leading-tight text-gray-600 bg-gray-200 rounded"
        >
          <div class="text-xs uppercase">Not started</div>
          <div class="text-lg">
            {{ overview?.notStarted }}
          </div>
        </div>

        <div
          class="w-full p-2 mx-2 font-bold leading-tight text-blue-600 bg-blue-200 rounded"
        >
          <div class="text-xs uppercase">In progress</div>
          <div class="text-lg">
            {{ overview?.inProgress }}
          </div>
        </div>
        <div
          class="w-full p-2 font-bold leading-tight text-red-600 bg-red-200 rounded"
        >
          <div class="text-xs uppercase">Needs attention</div>
          <div class="text-lg">
            {{ overview?.needsAttention }}
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="mb-10" *ngIf="pageMode === 'answers'">
    <section class="my-10">
      <button
        (click)="createReport()"
        class="w-full py-0 my-1 btn btn-primary"
        [ngClass]="{}"
      >
        Generate score report
      </button>

      <button
        *ngIf="latestGradeReport === undefined"
        class="w-full py-0 my-1 text-white bg-blue-300 btn"
        disabled
      >
        <app-icon icon="cached" [spin]="true"></app-icon>
        LOADING...
      </button>

      <button
        *ngIf="latestGradeReport === null"
        class="w-full py-0 my-1 text-white bg-blue-300 btn"
        disabled
      >
        No report available
      </button>

      <button
        *ngIf="latestGradeReport && !latestGradeReport.presignedUrl"
        class="w-full py-0 my-1 text-white bg-blue-300 btn"
        disabled
      >
        <app-icon icon="cached" [spin]="true"></app-icon>
        GENERATING REPORT
      </button>
      <ng-container *ngIf="latestGradeReport && latestGradeReport.presignedUrl">
        <a
          [href]="latestGradeReport.presignedUrl"
          class="block w-full py-0 text-white bg-blue-700 btn"
        >
          <div>
            <app-icon icon="download"></app-icon>
            DOWNLOAD LATEST REPORT
          </div>
        </a>

        <div class="text-sm text-center text-gray-600">
          report created on {{ latestGradeReport.modified | date : "medium" }}
        </div>
      </ng-container>
    </section>

    <app-item-list
      [itemTemplate]="studentAnswersItemTemplate"
      [source]="studentAnswersObservable"
    ></app-item-list>
  </section>

  <section class="mb-10" *ngIf="pageMode === 'certificates'">
    <section class="my-10">
      <button
        (click)="createCertificateZipFile()"
        class="w-full py-0 my-1 btn btn-primary"
        [ngClass]="{}"
      >
        Generate latest zip file
      </button>
      <button
        *ngIf="latestCertificateZipFiles === undefined"
        class="w-full py-0 my-1 text-white bg-blue-300 btn"
        disabled
      >
        <app-icon icon="cached" [spin]="true"></app-icon>
        LOADING...
      </button>
      <button
        *ngIf="latestCertificateZipFiles === null"
        class="w-full py-0 my-1 text-white bg-blue-300 btn"
        disabled
      >
        No file available
      </button>
      <ng-container *ngIf="latestCertificateZipFiles">
        <ng-container
          *ngFor="
            let latestCertificateZipFile of latestCertificateZipFiles;
            index as i
          "
        >
          <button
            *ngIf="!latestCertificateZipFile.objectUrl"
            class="w-full py-0 my-1 text-white bg-blue-300 btn"
            disabled
          >
            <app-icon icon="cached" [spin]="true"></app-icon>
            Generating zip file
          </button>
          <ng-container *ngIf="latestCertificateZipFile.objectUrl">
            <a
              [href]="latestCertificateZipFile.objectUrl"
              class="block w-full py-0 text-white bg-blue-700 btn"
            >
              <div>
                <app-icon icon="download"></app-icon>
                Download latest zip file #{{ i + 1 }}
              </div>
            </a>

            <div class="text-sm text-center text-gray-600">
              file created on
              {{ latestCertificateZipFile.modified | date : "medium" }}
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </section>
  </section>

  <section class="my-10">
    <div class="mb-1">
      <h2 class="font-bold">Enrolled Users</h2>

      <a
        [routerLink]="['enrollments', 'new', 'index']"
        queryParamsHandling="merge"
        class="my-5 btn btn-primary"
      >
        <app-icon icon="add_circle"></app-icon>
        Add enrollees
      </a>
    </div>

    <div class="relative">
      <div class="flex mb-5">
        <app-search-bar-query
          placeholder="Search"
          class="w-3/4"
        ></app-search-bar-query>
        <button
          class="flex items-center px-1 ml-4 text-gray-400 uppercase bg-gray-200"
          (click)="openNewCourseModal(filters)"
        >
          <app-icon icon="filter_list"> </app-icon>
          <div class="hidden text-sm sm:block">filter</div>
        </button>
      </div>

      <div class="my-5">
        <app-item-select-with-header
          key="userUuid"
          [batchActions]="batchActions"
          [headers]="headers"
          [totalCount]="totalCount"
          category="user"
          [source]="studentPerformanceTableObservable"
          [itemTemplate]="itemTemplate"
          [limit]="pageSize"
          [enableSort]="true"
          [loading]="loading"
          (selectedItemList)="onSelectedItemList($event)"
        >
        </app-item-select-with-header>
      </div>
    </div>
  </section>
</app-back-title-layout>

<ng-template #itemTemplate let-item="item">
  <div class="flex">
    <div class="block mt-2 sm:flex sm:mt-0">
      <a
        class="block w-full ml-2 sm:flex"
        [routerLink]="['/users', 'profile', 'stats', 'course-progress']"
        queryParamsHandling="merge"
        [queryParams]="{
          user_uuid: item.userUuid
        }"
      >
        <div
          class="grid w-full grid-cols-1 my-1 sm:flex"
          *ngFor="let header of headers"
        >
          <!-- Email -->
          <div
            class="block {{ header.width }} sm:flex"
            *ngIf="header.name === 'Email'"
          >
            <div>
              <app-icon
                icon="person"
                class="items-center justify-center px-1 mr-1 text-xl leading-none text-white bg-gray-200 rounded-full shrink"
              ></app-icon>
            </div>
            <div
              class="flex-wrap text-sm text-gray-700 truncate sm:items-center sm:w-full"
            >
              {{ item.email }}
              <span
                *ngIf="!item.confirmedAt"
                class="text-sm italic text-red-500"
              >
                <app-icon icon="error" class="align-bottom"></app-icon>
                <!-- <span> email not yet confirmed </span> -->
              </span>
            </div>
          </div>
          <!-- Name -->
          <div
            class="truncate text-sm sm:items-center {{ header.width }}"
            *ngIf="header.name === 'Name'"
          >
            {{ item.name }}
          </div>
          <!-- Date Added -->
          <div
            class="sm:items-center truncate {{ header.width }}"
            *ngIf="header.name === 'Course Progress'"
          >
            <ng-container *ngFor="let status of item.statuses">
              <ng-container [ngSwitch]="status">
                <app-pill-label *ngSwitchCase="'in_progress'" type="info">
                  In Progress
                </app-pill-label>

                <app-pill-label *ngSwitchCase="'passed'" type="success">
                  Passed
                </app-pill-label>

                <app-pill-label *ngSwitchCase="'failed'" type="error">
                  Failed
                </app-pill-label>

                <app-pill-label
                  *ngSwitchCase="'certificate_issued'"
                  type="successer"
                >
                  Certificate issued
                </app-pill-label>

                <app-pill-label *ngSwitchCase="'needs_attention'" type="error">
                  Needs attention
                </app-pill-label>

                <app-pill-label *ngSwitchDefault type="default">
                  Not Started
                </app-pill-label>
              </ng-container>
            </ng-container>
          </div>
          <div
            class="{{ header.width }} truncate text-sm"
            *ngIf="header.name === 'Status'"
          >
            <div
              class="px-1 mt-2 text-sm rounded-full shrink w-max bg-success-200 text-success-600 sm:mt-0"
              *ngIf="item.active"
            >
              Active
            </div>
          </div>
          <div
            class="{{ header.width }} truncate text-sm"
            *ngIf="header.name === 'Date Enrolled'"
          >
            {{ item.enrollmentDate | date }}
          </div>
        </div>
      </a>
    </div>
  </div>
</ng-template>

<!-- 
<ng-template #itemTemplate let-item="item">
  <a
    class="flex"
    [routerLink]="['../student-results']"
    [queryParams]="{ user_uuid: item.userUuid }"
    queryParamsHandling="merge"
  >
    <app-icon
      icon="person"
      class="mr-2 text-lg leading-tight text-gray-600"
    ></app-icon>

    <div>
      <div>
        <span>
          {{ item.name }}
        </span>
        <span
          class="rounded-full text-xs px-2 whitespace-nowrap  {{
            item.statusClass
          }}"
        >
          {{ item.statusText }}
        </span>
      </div>

      <div class="text-primary">
        {{ item.requirementsDone }}
      </div>
      <div class="flex items-center text-primary">
        <span> See scores </span>
        <app-icon class="leading-none" icon="chevron_right"></app-icon>
      </div>
    </div>
  </a>
</ng-template> -->

<ng-template #studentAnswersItemTemplate let-item="item">
  <div class="flex items-start">
    <img
      [src]="item.coverUri"
      alt=""
      class="object-contain mx-2 bg-gray-200 w-14 h-14"
    />

    <div class="flex-1 min-w-0">
      <a
        [routerLink]="['/_exams', 'user-submissions']"
        [queryParams]="{
          exam_uuid: item.examUuid,
          timeslot_uuid: item.examTimeslotUuid
        }"
        [queryParamsHandling]="'merge'"
        class="block font-semibold leading-tight text-gray-700"
      >
        <div>
          <app-icon [icon]="item.type | resourceTypeIcon"></app-icon>
        </div>
        <div>
          {{ item.name }}
        </div>
      </a>
    </div>

    <button
      [appWaitForObsAfterClick]="item.exportCsvObservable"
      class="bg-transparent btn text-primary-700"
    >
      <app-icon icon="insert_drive_file"></app-icon>
      Export CSV
    </button>
  </div>
</ng-template>

<ng-template #filters>
  <app-filter
    [fields]="fields"
    (state)="saveState($event)"
    [saved]="filterState"
  ></app-filter>
</ng-template>

<ng-template #forever>
  <span class="font-bold">Forever</span>
</ng-template>
