<app-back-title-layout layoutTitle="General Settings">
  <div class="font-bold text-lg mb-8">Log In as Guest</div>
  <form [formGroup]="form" (ngSubmit)="onSave()" *ngIf="form">
    <label class="flex mb-8"
      ><app-checkbox
        class="text-xl items-center justify-center align-middle"
        checkClass="text-primary-dark"
        uncheckClass="text-gray-500"
        formControlName="allowGuestLogin"
      ></app-checkbox>
      <div class="ml-2">
        <div class="font-bold text-base">Allow log in as guest</div>
        <div class="text-sm text-gray-400">
          Allow users to use your cast without signing up
        </div>
      </div>
    </label>
    <div *ngIf="allowGuestLogin.value">
      <div>
        <label class="flex mb-8"
          ><app-checkbox
            class="text-xl items-center justify-center align-middle"
            checkClass="text-primary-dark"
            uncheckClass="text-gray-500"
            formControlName="defaultLoginAsGuest"
          ></app-checkbox>
          <div class="ml-2">
            <div class="font-bold text-base">
              Enable immediate Cast Access for Guests
            </div>
            <div class="text-sm text-gray-400">
              Allow guests to skip the login page and immediately view your Cast
            </div>
          </div>
        </label>

        <div class="font-bold text-base mb-1">
          Additional Instructions
          <span class="italic font-normal">(optional)</span>
        </div>

        <div class="text-sm text-gray-400">
          This appears before the system's default instructions for claiming the
          guest user account.
        </div>
        <textarea
          placeholder="add additional instructions or reminder here for guest users"
          class="w-full border border-gray-200 p-2 mb-4"
          rows="6"
          formControlName="additionalInstructions"
        ></textarea>

        <div class="font-bold text-base mb-1">Group for Guest Accounts</div>
        <div class="text-sm text-gray-400">
          Assign or create a group in Cast for temporary guest accounts to
          easily set up resources that they can view
        </div>
        <!-- Table for guest accounts group -->
        <table class="w-full border-b-1 border-gray-200">
          <tr class="border-b-2 border-gray-200">
            <th class="my-1 font-normal text-left text-gray-500 py-1 w-96">
              Group Name
            </th>
            <th class="my-1 font-normal text-left text-gray-500 py-1 w-56">
              Members
            </th>
            <th class="my-1 font-normal text-left text-gray-500 py-1 w-96">
              Role
            </th>
          </tr>
          <tr
            class="py-2 border-b"
            *ngFor="let group of groupListGuestObs | async; let i = index"
          >
            <td class="py-2">
              <app-icon class="text-gray-400" icon="group"></app-icon>
              {{ group.name }}
            </td>
            <td class="py-2">
              <app-icon class="text-gray-400" icon="person"></app-icon
              >{{ group.usersCount }}
            </td>
            <td class="py-2">
              <ng-container *ngFor="let role of group.roles">
                <span
                  class="inline-block rounded-full bg-secondary-500 text-white px-2 py-0.5 m-0.5 max-w-xs truncate text-sm"
                  >{{ role.name }}</span
                ></ng-container
              >
            </td>
            <td class="text-right">
              <button
                type="button"
                (click)="removeGroupGuest(i)"
                class="btn btn-icon bg-transparent text-gray-500 hover:text-error-600"
              >
                <app-icon icon="close"></app-icon>
              </button>
            </td>
          </tr>
        </table>

        <button
          class="text-primary-600 uppercase mb-4 py-2"
          type="button"
          (click)="selectGroupForGuest()"
        >
          <app-icon icon="add"></app-icon> add more group
        </button>
        <div class="font-bold text-base mb-1">Group for Claimed Accounts</div>
        <div class="text-sm text-gray-400">
          Assign a group in Cast where guest users who have claimed their
          account will be automatically added.
        </div>
        <!-- Table for Claimed Accounts Group -->
        <table class="w-full border-b-1 border-gray-200">
          <tr class="border-b-2 border-gray-200">
            <th class="my-1 font-normal text-left text-gray-500 py-1 w-96">
              Group Name
            </th>
            <th class="my-1 font-normal text-left text-gray-500 py-1 w-56">
              Members
            </th>
            <th class="my-1 font-normal text-left text-gray-500 py-1 w-96">
              Role
            </th>
          </tr>
          <tr
            class="py-2 border-b"
            *ngFor="let group of groupListClaimedObs | async; let i = index"
          >
            <td class="py-2">
              <app-icon class="text-gray-400" icon="group"></app-icon>
              {{ group.name }}
            </td>
            <td class="py-2">
              <app-icon class="text-gray-400" icon="person"></app-icon
              >{{ group.usersCount }}
            </td>
            <td class="py-2">
              <ng-container *ngFor="let role of group.roles">
                <span
                  class="inline-block rounded-full bg-secondary-500 text-white px-2 py-0.5 m-0.5 max-w-xs truncate text-sm"
                  >{{ role.name }}</span
                ></ng-container
              >
            </td>
            <td class="text-right">
              <button
                type="button"
                (click)="removeGroupClaimed(i)"
                class="btn btn-icon bg-transparent text-gray-500 hover:text-error-600"
              >
                <app-icon icon="close"></app-icon>
              </button>
            </td>
          </tr>
        </table>

        <button
          class="text-primary-600 uppercase mb-4 py-2"
          type="button"
          (click)="selectGroupForClaimed()"
        >
          <app-icon icon="add"></app-icon> add more group
        </button>
      </div>
    </div>
    <div class="flex w-full justify-end">
      <button class="btn btn-primary" [disabled]="form.invalid">save</button>
    </div>
  </form>
</app-back-title-layout>
