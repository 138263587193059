import { Component, OnInit } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { ResourcesService } from "src/app/sinigangnababoywithgabi";
import { Location } from "@angular/common";
import { ModalService } from "src/app/services/modal.service";
import { firstValueFrom } from "rxjs";

@Component({
  selector: "app-collection-edit",
  templateUrl: "./collection-edit.component.html",
  styleUrls: ["./collection-edit.component.scss"],
})
export class CollectionEditComponent implements OnInit {
  formGroup: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private location: Location,
    private resourcesService: ResourcesService,
    private modalService: ModalService
  ) {}

  async ngOnInit() {
    let { collection_uuid: collectionUuid } = this.route.snapshot.queryParams;

    let result = await firstValueFrom(
      this.resourcesService.getCollectionByUuid(collectionUuid)
    );

    this.formGroup = this.formBuilder.group({
      name: [result.name, Validators.required],
      description: [result.description],
      coverUri: [result.coverUri],
    });
  }

  async updateCollection() {
    let { collection_uuid: collectionUuid } = this.route.snapshot.queryParams;

    await firstValueFrom(
      this.resourcesService.updateCollectionByUuid(collectionUuid, {
        name: this.formGroup.value.name,
        description: this.formGroup.value.description,
        coverUri: this.formGroup.value.coverUri,
      })
    );

    this.location.back();
  }

  async deleteCollection() {
    let { collection_uuid: collectionUuid } = this.route.snapshot.queryParams;

    let result = await this.modalService.confirm(
      "Are you sure you want to delete this collection?"
    );

    if (result) {
      await firstValueFrom(
        this.resourcesService.deleteCollectionByUuid(collectionUuid)
      );
    }

    history.go(-2);
  }

  onUpload(event) {
    this.formGroup.get("coverUri").setValue(event.uri);
  }
}
