import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthGuard {
  constructor(private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // let accessToken = localStorage.getItem("access_token");
    let permissions = JSON.parse(localStorage.getItem("permissions"));

    let activate: boolean = true;

    // if (!accessToken || !permissions) {
    //   activate = false;
    // }

    if (!permissions) {
      activate = false;
    }
    let accessPermission = permissions?.find((p) => p === "read:dashboard:all");

    if (!accessPermission) {
      activate = false;
    }

    let loginCallback = location.pathname + location.search;

    if (!activate) {
      return this.router.navigate(["/auth", "logout"], {
        queryParams: {
          login_callback: loginCallback,
        },
      });
    } else {
      return true;
    }
  }
}
