import { Component, Input, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Subject, first, firstValueFrom, takeUntil } from "rxjs";
import {
  V1ExamChoice,
  V1ExamChoiceSetFeedback,
  V1ExamsService,
} from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-exam-item-form-choice-feedbacks",
  templateUrl: "./exam-item-form-choice-feedbacks.component.html",
  styles: [],
})
export class ExamItemFormChoiceFeedbacksComponent implements OnInit {
  @Input() formGroup: UntypedFormGroup;
  @Input() insideSlides: boolean = true;
  choiceUuids: string[] = [];
  choiceSetFeedbacks: V1ExamChoiceSetFeedback[] = [];
  feedback: string;
  loading: boolean = false;
  constructor(
    private v1ExamsService: V1ExamsService,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute
  ) {}
  async ngOnInit() {
    this.loading = true;
    if (!this.insideSlides) {
      return;
    }
    if (!this.formGroup.value.itemUuid) {
      //Create Item if new item
      let item = await this.createItem();
      //Create Choices after creating item
      await this.createChoices(item.uuid);
      this.formGroup.addControl("itemUuid", new FormControl(item.uuid));
    } else {
      await this.createChoices(this.formGroup.value.itemUuid);
    }
    this.formGroup.addControl("choiceSetFeedbackImageUrl", new FormControl(""));
    this.formGroup.addControl("choiceSetFeedbackText", new FormControl(""));
    this.choiceSetFeedbacks = this.formGroup.get("choiceSetFeedbacks").value;

    this.formGroup
      .get("choiceSetFeedbackText")
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe((control) => {
        let update: boolean = false;
        this.choiceSetFeedbacks.forEach((choiceSetFeedback) => {
          if (this.compareArrays(choiceSetFeedback.choices, this.choiceUuids)) {
            choiceSetFeedback.text = control;
            update = true;
          }
        });
        if (!update) {
          this.choiceSetFeedbacks.push({
            choices: [...this.choiceUuids],
            text: control,
            imageUrl: "",
          });
        }
      });
    this.formGroup
      .get("choiceSetFeedbackImageUrl")
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe((control) => {
        let update: boolean = false;
        this.choiceSetFeedbacks.forEach((choiceSetFeedback) => {
          if (this.compareArrays(choiceSetFeedback.choices, this.choiceUuids)) {
            choiceSetFeedback.imageUrl = control;
            update = true;
          }
        });
        if (!update) {
          this.choiceSetFeedbacks.push({
            choices: [...this.choiceUuids],
            text: "",
            imageUrl: control,
          });
        }
      });

    this.loading = false;
  }
  private unsubscribe$ = new Subject();
  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
  async onSave() {
    this.loading = true;
    try {
      let updateChoiceSetFeedbacks = this.choiceSetFeedbacks.map(
        (choiceSetFeedback) => {
          let feedback = {
            choices: choiceSetFeedback.choices,
            text: choiceSetFeedback.text,
            imageUrl: choiceSetFeedback.imageUrl,
          };
          if (choiceSetFeedback.uuid) {
            return firstValueFrom(
              this.v1ExamsService.examsItemsChoicesetfeedbacksUpdate(
                choiceSetFeedback.uuid,
                feedback
              )
            );
          }
          return firstValueFrom(
            this.v1ExamsService.examsItemsChoicesetfeedbacksCreate(
              this.formGroup.value.itemUuid,
              feedback
            )
          );
        }
      );

      await Promise.all(updateChoiceSetFeedbacks);
    } catch (e) {
      console.log(e);
    } finally {
      this.loading = false;
    }
  }

  async createItem() {
    let { exam_section_uuid: sectionUuid, splice_index: spliceIndex } =
      this.route.snapshot.queryParams;
    let examItems = await firstValueFrom(
      this.v1ExamsService.examsSectionsItemsList(
        this.formGroup.value.sectionUuid
      )
    );
    examItems.splice(spliceIndex, 0, {});

    await Promise.all(
      examItems
        .map((item, i) => {
          item.orderId = i;

          return item;
        })
        .filter((item) => item.uuid)
        .map((item) =>
          firstValueFrom(
            this.v1ExamsService.examsSectionsItemsUpdate(item.uuid, item)
          )
        )
    );
    return await firstValueFrom(
      this.v1ExamsService.examsSectionsItemsCreate(
        this.formGroup.value.sectionUuid,
        { ...this.formGroup.value }
      )
    );
  }

  async createChoices(itemUuid: string) {
    let choicesArray = this.formGroup.get("choices") as UntypedFormArray;

    let createChoiceControls = choicesArray?.controls?.filter(
      (control) => !control.value.isDeleted
    );

    // Handle choices

    if (createChoiceControls) {
      // For each choice form control

      for (let i = 0; i < createChoiceControls.length; i++) {
        let control = createChoiceControls[i];
        if (!control.value.choiceUuid) {
          let choice = await this.createChoice(itemUuid, {
            longInput: control.value.text || "",
            shortInput: control.value.text || "",
            imageUrl: control.value.imageUri || "",
            audioUrl: control.value.audioUri || "",
            isCorrect: control.value.isCorrect,
            orderId: i,
          });

          choicesArray.setControl(
            i,
            this.fb.group({
              text: [choice.shortInput || choice.longInput],
              choiceUuid: [choice.uuid],
              imageUri: [choice.imageUrl],
              audioUri: [choice.audioUrl],
              isCorrect: [choice.isCorrect],
              isDeleted: [false],
              examItemUuidFlags: [control.value?.examItemUuidFlags],
            })
          );
        }
      }
    }
  }
  async createChoice(itemUuid: string, choice: V1ExamChoice) {
    return await firstValueFrom(
      this.v1ExamsService.examsSectionsItemsChoicesCreate(itemUuid, choice)
    );
  }
  onUpload(event, formControl: AbstractControl) {
    formControl.setValue(event.uri);
  }

  onUploadClear(formControl: AbstractControl) {
    formControl.setValue(null);
  }
  getChoiceControls() {
    let formArray = this.formGroup.get("choices") as UntypedFormArray;

    return formArray.controls || [];
  }
  onMultipleChoiceRadioChange(formGroup: UntypedFormGroup) {
    this.choiceUuids = [formGroup.value.choiceUuid];

    if (formGroup.value.isCorrect && this.formGroup.value.type !== "PO") {
      this.feedback = "correct";
    } else if (
      !formGroup.value.isCorrect &&
      this.formGroup.value.type !== "PO"
    ) {
      this.feedback = "incorrect";
    } else {
      this.feedback = "neutral";
    }

    if (this.choiceUuids.length > 0) {
      this.setFeedback();
    }
  }
  onCheckBoxChange(formGroup: UntypedFormGroup) {
    let choiceUuid = formGroup.value.choiceUuid;
    if (this.choiceUuids.includes(choiceUuid)) {
      this.choiceUuids = this.choiceUuids.filter(
        (selected) => selected !== choiceUuid
      );
    } else {
      this.choiceUuids = [...this.choiceUuids, choiceUuid];
    }
    let correctChoices = [];
    this.formGroup.value.choices.forEach((choice) => {
      if (choice.isCorrect) correctChoices.push(choice.choiceUuid);
    });

    this.feedback = this.compareArrays(this.choiceUuids, correctChoices)
      ? "correct"
      : "incorrect";
    if (this.choiceUuids.length > 0) {
      this.setFeedback();
    }
  }
  getUndeletedChoiceControls() {
    return this.getChoiceControls().filter(
      (control) => !control.get("isDeleted").value
    );
  }

  setFeedback() {
    let updated: boolean = false;
    this.choiceSetFeedbacks.forEach((choiceSetFeedback) => {
      if (this.compareArrays(choiceSetFeedback.choices, this.choiceUuids)) {
        updated = true;
        this.formGroup
          .get("choiceSetFeedbackImageUrl")
          .setValue(choiceSetFeedback.imageUrl);
        this.formGroup
          .get("choiceSetFeedbackText")
          .setValue(choiceSetFeedback.text);
      }
    });
    if (!updated) {
      this.formGroup.get("choiceSetFeedbackImageUrl").setValue("");

      this.formGroup.get("choiceSetFeedbackText").setValue("");
    }
  }

  compareArrays(firstArray: string[], secondArray: string[]) {
    if (firstArray.length !== secondArray.length) return false;
    let elements = new Set([...firstArray, ...secondArray]);
    for (const x of elements) {
      let count1 = firstArray.filter((e) => e === x).length;
      let count2 = secondArray.filter((e) => e === x).length;
      if (count1 !== count2) return false;
    }
    return true;
  }
}
