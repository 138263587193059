import { HttpEventType } from "@angular/common/http";
import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Subscription } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { UploadsService } from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-upload-loading-wrapper",
  templateUrl: "./upload-loading-wrapper.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UploadLoadingWrapperComponent),
      multi: true,
    },
  ],
})
export class UploadLoadingWrapperComponent
  implements OnInit, ControlValueAccessor
{
  @Input()
  clicked: boolean = false;
  @Input()
  text = "Change File";
  @Input()
  disabled: boolean;
  @Input()
  accept: string;

  @Input()
  downloadFilename: string;

  //Filename without extension extension will be added here
  @Input()
  fileName: any;

  @Output()
  upload = new EventEmitter<any>();

  @Input()
  overlayTemplate: TemplateRef<any>;

  uploadSubscription: Subscription;

  progress: number = null;

  @Input()
  file: File;

  @ViewChild("input")
  inputObject: ElementRef;

  constructor(private uploadService: UploadsService) {}

  click() {
    this.inputObject.nativeElement.click();
  }

  ngOnInit() {
    // if (this.file) {
    //   this.fileUpload();
    // }
  }

  //Rollback for canva
  /*ngOnChanges(changes: SimpleChanges) {
    let change = changes["clicked"];

    if (this.inputObject) {
      if (change.previousValue !== change.currentValue) {
        this.click();
      }
    }
  }*/

  ngOnDestroy() {
    this.uploadSubscription?.unsubscribe();
  }

  fileUpload() {
    let file = this.file;

    this.progress = 0;

    // Ignore content disposition for now

    // let fileExtension = file.name.split(".").pop();
    // let downloadname;

    // if (this.downloadFilename) {
    //   downloadname = `attachment;filename=${
    //     this.downloadFilename
    //   }.${fileExtension}`;
    // }

    this.uploadSubscription = this.uploadService
      .uploadFile(file, undefined, "events", true)
      // .uploadFile(file, downloadname, 'events', true)
      .pipe(
        catchError((err) => {
          this.file = null;
          this.progress = null;

          throw err;
        }),
        tap((event) => {
          if (event && event.type == HttpEventType.UploadProgress) {
            this.progress = event.loaded / event.total;
          }
        }),
        tap((event) => {
          if (event && event.type == HttpEventType.Response) {
            let result = event.body;

            this.upload.emit({
              uri: result["uri"],
              contentType: result["content_type"] || this.file.type,
            });

            this.propagateChange(result["uri"]);

            this.progress = null;
          }
        })
      )
      .subscribe();
  }

  fileRead(event) {
    this.file = event.target.files[0];
    event.srcElement.value = null;

    this.fileUpload();
  }

  cancelUpload() {
    if (this.uploadSubscription) {
      this.uploadSubscription.unsubscribe();
      this.progress = null;
    }
  }

  isUploading() {
    return this.progress !== null;
  }

  //For reactive forms===============================================
  _uri: string;

  get uri() {
    return this._uri;
  }

  @Input()
  set uri(val) {
    this._uri = val;
    this.propagateChange(this._uri);
  }

  propagateChange = (_: any) => {};

  writeValue(uri: string): void {
    this.uri = uri;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }
}
