<ul>
  <ng-container *ngFor="let item of items">
    <li class="border-b last:border-b-0 py-2 w-full">
      <label class="flex items-{{ checkBoxAlignment }}">
        <app-checkbox
          class="px-1 text-primary"
          (change)="updateSelectedItems()"
          [(ngModel)]="selectedItemMap[item[key]]"
        ></app-checkbox>

        <div class="w-full">
          <ng-container
            *ngTemplateOutlet="
              itemTemplate ? itemTemplate : default;
              context: { item: item }
            "
          >
          </ng-container>
        </div>
      </label>
    </li>
  </ng-container>
</ul>

<ng-template #default let-item="item">
  <span class="truncate font-semibold">
    {{ item[key] }}
  </span>
</ng-template>
