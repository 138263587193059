<app-back-title-layout layoutTitle="Edit Batch" [loading]="!formGroup">
  <ng-container *ngIf="formGroup">
    <form [formGroup]="formGroup" (submit)="updateBatch()">
      <label>
        <span class="form-label"> Batch Name </span>
        <input formControlName="name" class="form-control" />
      </label>

      <div class="my-3 flex flex-row-reverse">
        <button
          [disabled]="!formGroup.valid"
          type="submit"
          class="btn btn-primary"
        >
          Update
        </button>

        <button
          type="button"
          (click)="deleteBatch()"
          class="btn btn-danger mx-2"
        >
          Delete
        </button>
      </div>
    </form>
  </ng-container>
</app-back-title-layout>
