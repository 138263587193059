<ul>
  <ng-container *ngFor="let linkList of linkLists">
    <li>
      <div
        *ngIf="linkList.disabled"
        data-tooltip="Contact your admin to gain access"
      >
        <ng-container
          *ngTemplateOutlet="linkListTemplate; context: { linkList: linkList }"
        >
        </ng-container>
      </div>

      <a
        *ngIf="!linkList.disabled"
        [routerLink]="linkList.routerLinkOptions?.routerLink"
        [queryParams]="linkList.routerLinkOptions?.queryParams"
        [queryParamsHandling]="linkList.routerLinkOptions?.queryParamsHandling"
        [preserveFragment]="linkList.routerLinkOptions?.preserveFragment"
        [skipLocationChange]="linkList.routerLinkOptions?.skipLocationChange"
        [replaceUrl]="linkList.routerLinkOptions?.replaceUrl"
      >
        <ng-container
          *ngTemplateOutlet="linkListTemplate; context: { linkList: linkList }"
        >
        </ng-container>
      </a>

      <!-- <a
        [routerLink]="linkList.routerLinkOptions?.routerLink"
        [queryParams]="linkList.routerLinkOptions?.queryParams"
        [queryParamsHandling]="linkList.routerLinkOptions?.queryParamsHandling"
        [preserveFragment]="linkList.routerLinkOptions?.preserveFragment"
        [skipLocationChange]="linkList.routerLinkOptions?.skipLocationChange"
        [replaceUrl]="linkList.routerLinkOptions?.replaceUrl"
        [attr.data-tooltip]="
          disabled ? 'Contact your admin to gain access' : ''
        "
      >
        <div class="w-full flex py-3 border-b">
          <app-icon
            class="mr-3 text-2xl leading-none"
            [icon]="linkList.icon"
            [ngClass]="{
              'text-disabled-dark': linkList.disabled,
              'text-primary': !linkList.disabled
            }"
          ></app-icon>

          <div class="flex-1">
            <div
              class="flex justify-between font-bold"
              [ngClass]="{
                'text-disabled-dark': linkList.disabled,
                'text-primary': !linkList.disabled
              }"
            >
              <span class="flex-1"> {{ linkList.name }}</span>
            </div>

            <div *ngIf="linkList.description" class="mt-1 text-xs">
              {{ linkList.description }}
            </div>
          </div>

          <app-icon
            *ngIf="linkList.needsAttention"
            class="text-error-dark text-sm"
            icon="error"
          ></app-icon>

          <app-icon
            [ngClass]="{
              'text-disabled-dark': linkList.disabled,
              'text-primary': !linkList.disabled
            }"
            class="text-2xl leading-none"
            icon="chevron_right"
          ></app-icon>
        </div>
      </a> -->
    </li>
  </ng-container>
</ul>

<ng-template #linkListTemplate let-linkList="linkList">
  <div class="w-full flex py-3 border-b">
    <app-icon
      class="mr-3 text-2xl leading-none"
      [icon]="linkList.icon"
      [ngClass]="{
        'text-disabled-dark': linkList.disabled,
        'text-primary': !linkList.disabled
      }"
    ></app-icon>

    <div class="flex-1">
      <div
        class="flex justify-between font-bold"
        [ngClass]="{
          'text-disabled-dark': linkList.disabled,
          'text-primary': !linkList.disabled
        }"
      >
        <span class="flex-1"> {{ linkList.name }}</span>
      </div>

      <div *ngIf="linkList.description" class="mt-1 text-xs">
        {{ linkList.description }}
      </div>
    </div>

    <app-icon
      *ngIf="linkList.needsAttention"
      class="text-error-dark text-sm"
      icon="error"
    ></app-icon>

    <app-icon
      [ngClass]="{
        'text-disabled-dark': linkList.disabled,
        'text-primary': !linkList.disabled
      }"
      class="text-2xl leading-none"
      icon="chevron_right"
    ></app-icon>
  </div>
</ng-template>
