import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import {
  V1ExamChoice,
  V1ExamChoiceSetFeedback,
  V1ExamItem,
} from "src/app/sinigangnababoywithgabi";

interface PollResult {
  choice: { uuid: string; longInput: string };
  result: { percent: number; count: number };
}
interface TallyResult {
  score: number;
  type: { uuid: string; name: string };
}

@Component({
  selector: "app-burislides-block-activity-preview",
  templateUrl: "./burislides-block-activity-preview.component.html",
  styles: [],
})
export class BurislidesBlockActivityPreviewComponent implements OnInit {
  @Input() itemType: string;
  @Input() item: V1ExamItem;
  @Input() choices: V1ExamChoice[];
  @Input() feedback: boolean;
  @Input() navigated: boolean;
  @Input() choiceSetFeedbacks: V1ExamChoiceSetFeedback[];
  customFeedback: string = "";
  customImageFeedback: string = "";
  ddChoice: any;
  mcChoiceUuid: string = "";
  currentCbChoice: string = "";
  cbChoiceUuids: string[] = [];
  correctCbChoices: string[] = [];
  mcIsCorrect: boolean;
  cbIsCorrect: boolean;
  textIsCorrect: boolean;
  textInput: string;
  selectedIndex: number;
  pollResults: PollResult[] = [];
  tallyResults: TallyResult[] = [];
  bg: any;

  constructor(private cd: ChangeDetectorRef) {}
  ngOnInit(): void {
    if (this.itemType === "TA") {
      this.item = { type: "TA" };
      this.choices = [];
    }
    if (this.item.subtype === "DD") {
      this.ddChoice = this.choices[0];
      this.mcChoiceUuid = this.choices[0].uuid;
      this.mcIsCorrect = this.choices[0].isCorrect;
      if (this.item.type === "PO") {
        this.pollResults = this.choices.map((_choice) => {
          let count = 0;
          let percent = 0;
          if (_choice.uuid === this.mcChoiceUuid) {
            count = 1;
            percent = 100;
          }
          return {
            choice: { uuid: _choice.uuid, longInput: _choice.longInput },
            result: { percent: percent, count: count },
          };
        });
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.navigated) {
      if (changes.navigated.currentValue !== changes.navigated.previousValue) {
        this.ddChoice = null;
        this.mcChoiceUuid = "";
        this.currentCbChoice = "";
        this.cbChoiceUuids = [];
        this.correctCbChoices = [];
        this.mcIsCorrect = null;
        this.cbIsCorrect = null;
        this.textIsCorrect = null;
        this.textInput = null;
        this.selectedIndex = null;
        this.pollResults = [];
        this.tallyResults = [];
        this.customFeedback = "";
        this.customImageFeedback = "";
      }
    }
    if (changes.feedback)
      if (changes.feedback.currentValue !== changes.feedback.previousValue) {
        if (changes.feedback.currentValue) {
          if (
            !this.mcChoiceUuid &&
            !this.textInput &&
            this.cbChoiceUuids.length === 0
          ) {
            this.feedback = false;
          }
        }
      }
  }
  setCBChoices(choice: string, isCorrect: boolean) {
    this.customFeedback = "";
    this.customImageFeedback = "";
    if (choice) this.currentCbChoice = choice;
    if (this.cbChoiceUuids.includes(choice)) {
      let updatedSelection = this.cbChoiceUuids.filter(
        (selected) => selected !== choice
      );
      this.cbChoiceUuids = updatedSelection;
    } else {
      this.cbChoiceUuids = [...this.cbChoiceUuids, choice];
    }
    this.correctCbChoices = [];
    this.choices.forEach((choice) => {
      if (choice.isCorrect) {
        this.correctCbChoices.push(choice.uuid);
      }
    });

    if (this.correctCbChoices.length === this.cbChoiceUuids.length) {
      this.cbIsCorrect = true;
      this.correctCbChoices.forEach((correctUuid) => {
        if (!this.cbChoiceUuids.includes(correctUuid)) {
          this.cbIsCorrect = false;
        }
      });
    } else {
      this.cbIsCorrect = false;
    }
    this.choiceSetFeedbacks.forEach((choiceSetFeedback) => {
      if (this.compareArrays(this.cbChoiceUuids, choiceSetFeedback.choices)) {
        this.customFeedback = choiceSetFeedback.text;
        this.customImageFeedback = choiceSetFeedback.imageUrl;
      }
    });
  }
  setMCChoice(choice: string, isCorrect: boolean) {
    this.customFeedback = "";
    this.customImageFeedback = "";
    this.mcIsCorrect = isCorrect;
    this.mcChoiceUuid = choice;

    let mcChoiceUuids = [choice];
    this.choiceSetFeedbacks.forEach((choiceSetFeedback) => {
      if (this.compareArrays(mcChoiceUuids, choiceSetFeedback.choices)) {
        this.customFeedback = choiceSetFeedback.text;
        this.customImageFeedback = choiceSetFeedback.imageUrl;
      }
    });

    // this.ddChoice = choice;
    //If item type is poll, set pollresults
    if (this.item.type === "PO") {
      this.pollResults = this.choices.map((_choice) => {
        let count = 0;
        let percent = 0;
        if (_choice.uuid === choice) {
          this.ddChoice = _choice;
          count = 1;
          percent = 100;
        }
        return {
          choice: { uuid: _choice.uuid, longInput: _choice.longInput },
          result: { percent: percent, count: count },
        };
      });
    }
  }
  setSelectedIndex(selectedIndex) {
    this.selectedIndex = selectedIndex;
  }
  getFeedbackColor(isCorrect: boolean, choiceUuid: string) {
    if (this.textInput) {
      if (this.choices[0]) {
        this.textIsCorrect = this.textInput === this.choices[0].shortInput;
        return (!this.item.showResults || !this.feedback) && this.textInput
          ? "bg-gray-200 active:bg-gray-200 focus:bg-primary-700 hover:bg-primary-700 active:text-black focus:text-black hover:text-black"
          : this.textIsCorrect
          ? "bg-success-200 disabled:bg-success-200"
          : "bg-error-200 disabled:bg-error-200";
      }

      return "bg-gray-200 active:bg-gray-200 text-black";
    }
    if (this.mcChoiceUuid || this.ddChoice) {
      return this.mcChoiceUuid === choiceUuid ||
        this.ddChoice?.uuid === choiceUuid
        ? !this.item.showResults || !this.feedback || this.item.type === "PO"
          ? "bg-gray-200 active:bg-gray-200 text-black"
          : isCorrect
          ? "bg-success-200"
          : "bg-error-200"
        : "bg-white";
    } else if (this.cbChoiceUuids.length > 0) {
      return this.cbChoiceUuids.includes(choiceUuid)
        ? !this.item.showResults || !this.feedback
          ? "bg-gray-200 active:bg-gray-200 text-black"
          : isCorrect
          ? "bg-success-200"
          : "bg-error-200"
        : "bg-white";
    }

    return "bg-white";
  }
  getFeedbackColorLeft(isCorrect: boolean) {
    if (this.textInput) {
      if (this.choices[0]) {
        this.textIsCorrect = this.textInput === this.choices[0].shortInput;
        return (!this.item.showResults || !this.feedback) && this.textInput
          ? "bg-gray-200 active:bg-gray-200 focus:bg-primary-700 hover:bg-primary-700 active:text-black focus:text-black hover:text-black"
          : this.textIsCorrect
          ? "bg-success-200 disabled:bg-success-200"
          : "bg-error-200 disabled:bg-error-200";
      }

      return "bg-gray-200 active:bg-gray-200 text-black";
    }
    if (this.mcChoiceUuid || this.ddChoice) {
      return this.feedback
        ? !this.item.showResults || !this.feedback || this.item.type === "PO"
          ? "bg-gray-200 active:bg-gray-200 text-black"
          : isCorrect
          ? "bg-success-200"
          : "bg-error-200"
        : "bg-white";
    } else if (this.cbChoiceUuids.length > 0) {
      return this.feedback
        ? !this.item.showResults || !this.feedback
          ? "bg-gray-200 active:bg-gray-200 text-black"
          : isCorrect
          ? "bg-success-200"
          : "bg-error-200"
        : "bg-white";
    }

    return "bg-white";
  }
  getMediaUrl() {
    if (this.item.subtype === "SU") return `url(${this.item.mediaUrl})`;
    return "";
  }
  getCheckIcon(uuid: string, isCorrect: boolean) {
    if (this.cbChoiceUuids.includes(uuid)) {
      if (this.feedback) {
        if (isCorrect) {
          return "text-success-600";
        } else {
          return "text-error-600";
        }
      }
      return "text-primary-700";
    }
    return "invisible";
  }
  compareArrays(firstArray: string[], secondArray: string[]) {
    if (firstArray.length !== secondArray.length) return false;
    let elements = new Set([...firstArray, ...secondArray]);
    for (const x of elements) {
      let count1 = firstArray.filter((e) => e === x).length;
      let count2 = secondArray.filter((e) => e === x).length;
      if (count1 !== count2) return false;
    }
    return true;
  }
}
