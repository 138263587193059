import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-burislides-select-activity-modal-layout",
  templateUrl: "./burislides-select-activity-modal-layout.component.html",
  styles: [],
})
export class BurislidesSelectActivityModalLayoutComponent {
  constructor(
    public dialogRef: MatDialogRef<BurislidesSelectActivityModalLayoutComponent>
  ) {}

  onTypeSelect(value) {
    if (value === "SU") {
      this.dialogRef.close({
        type: "PO",
        subtype: "SU",
      });
    } else {
      this.dialogRef.close({
        type: value,
      });
    }
  }
}
