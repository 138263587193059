<app-back-title-layout [loading]="loading">
  <app-search-bar
    #search
    placeholder="Search"
    [(ngModel)]="searchString"
  ></app-search-bar>

  <app-resource-library-selector
    [searchString]="searchString"
    source="resources"
    (selectResource)="onSelectResource($event)"
  >
  </app-resource-library-selector>
</app-back-title-layout>
