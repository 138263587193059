<div
  class="inset-0 z-10 {{ position }} {{ height }} {{ padding }} {{
    wrapperClass
  }}"
  [@enterAnimation]
  *ngIf="loading"
>
  <div class="m-auto h-20 w-20">
    <ng-lottie [options]="options"></ng-lottie>
  </div>
</div>

<div [@enterAnimation] *ngIf="!loading">
  <ng-content> </ng-content>
</div>
