<div class="w-full">
  <ngx-charts-line-chart
    label="Activity"
    [xAxis]="true"
    [yAxis]="true"
    [showGridLines]="false"
    [showXAxisLabel]="true"
    [showYAxisLabel]="true"
    [yAxisTickFormatting]="yAxisTickFormatting"
    [results]="[
      {
        name: '',
        series: convertedChartData
      }
    ]"
  >
  </ngx-charts-line-chart>
</div>
