<app-back-title-layout
  layoutTitle="Course Block Certification Details"
  [loading]="!block"
>
  <ng-container *ngIf="block">
    <section class="flex my-5">
      <img [src]="block.coverUri" alt="" class="h-20 w-20 object-contain" />

      <div class="ml-3">
        <div class="mb-1 text-sm leading-none text-gray-600 uppercase">
          {{ block.content.resourceDetails?.type | resourceType }}
        </div>

        <div>{{ block.title }}</div>
      </div>
    </section>

    <hr class="my-5" />

    <section
      *ngIf="isValidForCertificate() && isRequiredForCertificate()"
      class="my-5"
    >
      <form (ngSubmit)="save()">
        <label class="mb-5">
          <span class="form-label"> Passing Rate </span>

          <input
            class="form-control"
            name="customPassingMark"
            [(ngModel)]="block.referenceInfo.customPassingMark"
            type="number"
            step="5"
            min="0"
            max="100"
          />
        </label>

        <button class="btn btn-primary w-full my-5" type="submit">Save</button>
      </form>

      <button class="btn btn-danger w-full my-5" (click)="remove()">
        Remove from certification
      </button>
    </section>

    <section
      *ngIf="isValidForCertificate() && !isRequiredForCertificate()"
      class="my-5"
    >
      <div>This block is not a certification requirement.</div>

      <button
        class="btn btn-primary w-full my-5"
        (click)="addCertificationRequirements()"
      >
        Add as requirement
      </button>
    </section>

    <section *ngIf="!isValidForCertificate()">
      This block is not yet available as a certification requirement.
    </section>
  </ng-container>
</app-back-title-layout>
