import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import { CastsService } from "src/app/sinigangnababoywithgabi";
import { MarkdownViewerAndEditorComponent } from "../../app-common-module/components/markdown-viewer-and-editor/markdown-viewer-and-editor.component";

@Component({
  selector: "app-casteditor-block-markdown",
  templateUrl: "./casteditor-block-markdown.component.html",
  styleUrls: ["./casteditor-block-markdown.component.scss"],
})
export class CasteditorBlockMarkdownComponent implements OnInit {
  constructor(private castsService: CastsService) {}

  placeholder: string = "Insert text here";
  textValue: string = "";

  @Input() block: any;
  @Input() blkIdx: number;
  @Input() disableEdit: boolean;
  castId: string;
  screenId: string;

  @ViewChild(MarkdownViewerAndEditorComponent)
  markdown: MarkdownViewerAndEditorComponent;

  @Output() deleteBlock: EventEmitter<any> = new EventEmitter();

  async ngOnInit() {
    this.textValue = this.block.properties.content;
  }

  onDeleteBlock() {
    this.deleteBlock.emit();
  }

  onEditToggle() {
    if (!this.markdown.isInEditMode) {
      this.markdown.toEditMode();
    } else {
      this.markdown.toViewMode();

      this.block.properties.content = this.textValue;

      this.castsService
        .blockUpdate(this.castId + "", this.screenId + "", this.block.id, {
          block: {
            ...this.block,
          },
        })
        .toPromise();
    }
  }
}
