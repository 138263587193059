<div
  class="h-full bg-transparent object-cover"
  [style.backgroundImage]="getMediaUrl()"
  [style.backgroundPosition]="'center'"
  [style.backgroundRepeat]="'no-repeat'"
  [style.backgroundSize]="'cover'"
  [ngClass]="{
    'grid grid-cols-2':
      (feedback && (mcChoiceUuid || textInput || cbChoiceUuids.length > 0)) ||
      (item.mediaUrl && !feedback && item.subtype !== 'SU')
  }"
>
  <!-- correct, incorrect, your answer has been submitted  -->
  <ng-container
    *ngIf="(mcChoiceUuid || textInput || cbChoiceUuids.length > 0) && feedback"
  >
    <div
      class="pt-10 pl-10 pr-8 pb-6 overflow-y-auto h-full"
      [ngClass]="getFeedbackColorLeft(mcIsCorrect || cbIsCorrect)"
    >
      <ng-container
        *ngIf="
          item.showResults &&
            item.type !== 'LA' &&
            item.type !== 'PO' &&
            item.type !== 'FW';
          else noResults
        "
      >
        <ng-container *ngIf="mcIsCorrect || textIsCorrect || cbIsCorrect">
          <div class="mb-10 flex text-lg">
            <!-- {{ item.correctFeedbackMessage }} -->
            <app-markdown-viewer
              [value]="customFeedback || item.correctFeedbackMessage"
            >
            </app-markdown-viewer>
          </div>
          <img
            *ngIf="customImageFeedback || item.correctFeedbackImageUrl"
            class="max-h-64 m-auto"
            [src]="customImageFeedback || item.correctFeedbackImageUrl"
            alt="correctImage"
          />
        </ng-container>
        <ng-container *ngIf="!mcIsCorrect && !textIsCorrect && !cbIsCorrect">
          <div class="mb-10 flex text-lg">
            <!-- {{ item.incorrectFeedbackMessage }} -->
            <app-markdown-viewer
              [value]="customFeedback || item.incorrectFeedbackMessage"
            >
            </app-markdown-viewer>
          </div>
          <img
            *ngIf="customImageFeedback || item.incorrectFeedbackImageUrl"
            class="max-h-64 m-auto"
            [src]="customImageFeedback || item.incorrectFeedbackImageUrl"
            alt="incorrectImage"
          />
        </ng-container>
      </ng-container>
      <ng-template #noResults>
        <div class="mb-10 flex text-lg">
          <!-- {{ item.neutralFeedbackMessage }} -->
          <app-markdown-viewer
            [value]="customFeedback || item.neutralFeedbackMessage"
          >
          </app-markdown-viewer>
        </div>
        <img
          *ngIf="customImageFeedback || item.neutralFeedbackImageUrl"
          class="max-h-64 m-auto"
          [src]="customImageFeedback || item.neutralFeedbackImageUrl"
          alt="neutralImage"
        />
      </ng-template>
    </div>
  </ng-container>

  <img
    *ngIf="item.mediaUrl && !feedback && item.subtype !== 'SU'"
    [src]="item.mediaUrl"
    [alt]="item.text"
    class="m-auto object-cover"
  />
  <!-- Question on the right column when item image is present  -->
  <div
    [ngClass]="{ 'text-center': item.subtype === 'SU' }"
    class="bg-transparent pt-10 pl-10 pr-8 pb-6 h-full overflow-y-auto"
  >
    <h1
      class="text-2xl font-bold mb-10"
      [ngClass]="{ 'rounded-full bg-white px-8 py-4': item.subtype === 'SU' }"
    >
      {{ item.text || "" }}
    </h1>
    <ng-container [ngSwitch]="item.type">
      <ng-container *ngSwitchCase="'MC'">
        <ng-container *ngIf="item.subtype !== 'DD'">
          <ng-container *ngFor="let choice of choices">
            <button
              type="button"
              [disabled]="feedback"
              (click)="setMCChoice(choice.uuid, choice.isCorrect)"
              class="block w-full text-left text-lg p-4 mb-4 rounded-3xl border-gray-700 border-2 transition-all"
              [ngClass]="getFeedbackColor(choice.isCorrect, choice.uuid)"
            >
              {{ choice.longInput }}
              <ng-container *ngIf="choice.imageUrl || choice.mediaUrl">
                <img
                  [src]="choice.imageUrl || choice.mediaUrl"
                  class="max-h-64 mx-auto"
                  [alt]="choice.longInput"
                />
              </ng-container>
              <ng-container *ngIf="choice.audioUrl">
                <audio controls class="w-full">
                  <source [src]="choice.audioUrl" type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
              </ng-container>
            </button>
          </ng-container>
        </ng-container>
        <!-- MC Dropdown  -->
        <ng-container *ngIf="item.subtype && item.subtype === 'DD'">
          <select
            #dropdown
            [disabled]="feedback"
            class="form-control transition-all"
            (ngModelChange)="
              setMCChoice($event.uuid, $event.isCorrect); dropdown.blur()
            "
            [ngModel]="ddChoice"
            [ngClass]="getFeedbackColor(mcIsCorrect, mcChoiceUuid)"
          >
            <option
              *ngFor="let choice of choices"
              [ngValue]="choice"
              class="bg-white text-black"
            >
              {{ choice.longInput }}
            </option>
          </select>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'CB'">
        <ng-container *ngFor="let choice of choices">
          <button
            type="button"
            [disabled]="feedback"
            (click)="setCBChoices(choice.uuid, choice.isCorrect)"
            class="block w-full text-left text-lg p-4 mb-4 rounded-3xl border-gray-700 border-2 transition-all items-center"
            [ngClass]="getFeedbackColor(choice.isCorrect, choice.uuid)"
          >
            <div class="flex w-full items-center">
              <app-icon
                icon="check_circle"
                class="text-3xl mr-2"
                [ngClass]="getCheckIcon(choice.uuid, choice.isCorrect)"
              ></app-icon>
              {{ choice.longInput }}
            </div>

            <ng-container *ngIf="choice.imageUrl || choice.mediaUrl">
              <img
                [src]="choice.imageUrl || choice.mediaUrl"
                class="max-h-64 mx-auto"
                [alt]="choice.longInput"
              />
            </ng-container>
            <ng-container *ngIf="choice.audioUrl">
              <audio controls class="w-full">
                <source [src]="choice.audioUrl" type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
            </ng-container>
          </button>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'PO'">
        <ng-container
          *ngIf="item.subtype !== 'SU' && item.subtype !== 'DD' && !feedback"
        >
          <ng-container *ngFor="let choice of choices; let i = index">
            <button
              type="button"
              [disabled]="feedback"
              (click)="setMCChoice(choice.uuid, choice.isCorrect)"
              class="block w-full text-left text-lg p-4 mb-4 rounded-3xl border-gray-700 border-2 transition-all"
              [ngClass]="getFeedbackColor(choice.isCorrect, choice.uuid)"
            >
              {{ choice.longInput }}
              <ng-container *ngIf="choice.imageUrl || choice.mediaUrl">
                <img
                  [src]="choice.imageUrl || choice.mediaUrl"
                  class="max-h-64 mx-auto"
                  [alt]="choice.longInput"
                />
              </ng-container>
              <ng-container *ngIf="choice.audioUrl">
                <audio controls class="w-full">
                  <source [src]="choice.audioUrl" type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
              </ng-container>
            </button>
          </ng-container>
        </ng-container>
        <!-- For poll DD  -->
        <ng-container *ngIf="item.subtype === 'DD' && !feedback">
          <select
            #dropdown
            [disabled]="feedback"
            class="form-control transition-all"
            (ngModelChange)="
              setMCChoice($event.uuid, $event.isCorrect); dropdown.blur()
            "
            [ngModel]="ddChoice"
            [ngClass]="getFeedbackColor(mcIsCorrect, mcChoiceUuid)"
          >
            <option
              *ngFor="let choice of choices"
              [ngValue]="choice"
              class="bg-white text-black"
            >
              {{ choice.longInput }}
            </option>
          </select>
        </ng-container>

        <!-- For poll feedback  -->
        <ng-container
          *ngIf="(item.subtype === 'DD' || !item.subtype) && feedback"
        >
          <ng-container *ngFor="let result of pollResults; let i = index">
            <div
              class="block w-full text-left text-lg font-bold p-4 mb-4 rounded-3xl border-gray-700 border-2 transition-all"
              [ngClass]="getFeedbackColor(false, result.choice.uuid)"
            >
              {{ result.choice.longInput }}
            </div>
            <progress
              [value]="result.result.percent"
              class="text-primary-500 w-full rounded-full h-2"
            ></progress>
            <div class="flex justify-between mb-8">
              <div class="text-primary-500">
                {{ result.result.count }} vote(s)
              </div>
              <div class="text-primary-500 font-bold">
                {{ result.result.percent }}%
              </div>
            </div>
          </ng-container>
        </ng-container>

        <!-- For poll survey  -->
        <ng-container *ngIf="item.subtype === 'SU'">
          <div class="space-x-4 text-center text-4xl transition-all w-full">
            <ng-container *ngFor="let choice of choices; index as i">
              <button
                (click)="setSelectedIndex(i + 1)"
                class="disabled:cursor-not-allowed text-primary-600"
              >
                <ng-container *ngIf="selectedIndex > i; else notSelected">
                  <app-icon class="animate-fadeIn" icon="star"></app-icon>
                </ng-container>
                <ng-template #notSelected>
                  <app-icon
                    class="w-fit margin-0"
                    icon="star_border"
                  ></app-icon>
                </ng-template>
              </button>
            </ng-container>
          </div>
          <div class="w-full text-center my-16">
            <h2 class="mb-10 text-2xl font-bold">
              {{ item.instructions || "Any Comments?" }}
            </h2>
            <textarea
              placeholder="Type in answer..."
              [disabled]="feedback"
              class="w-full border border-gray-700 form-control"
            ></textarea>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'LA'">
        <textarea
          [(ngModel)]="textInput"
          class="form-control"
          [disabled]="feedback"
          placeholder="Input answer here"
        ></textarea>
      </ng-container>
      <ng-container *ngSwitchCase="'SA'">
        <ng-container *ngIf="item.subtype !== 'DT'">
          <input
            type="text"
            [(ngModel)]="textInput"
            class="form-control text-black"
            [ngClass]="getFeedbackColor(false, '')"
          />
        </ng-container>
        <ng-container *ngIf="item.subtype === 'DT'">
          <input
            #date
            (ngModelChange)="date.blur()"
            type="date"
            [(ngModel)]="textInput"
            class="form-control"
            [ngClass]="getFeedbackColor(false, '')"
          />
        </ng-container>

        <div
          *ngIf="textInput && textIsCorrect === false && choices[0] && feedback"
        >
          Correct Answer: <b>{{ choices[0].shortInput }}</b>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'FW'">
        <ng-container *ngIf="feedback; else notFeedback">
          <div>
            <h1 class="font-bold mb-4">Responses</h1>
            <div>
              <div
                class="w-full h-fit p-4 rounded-lg border-2 bg-gray-200 mb-4 border-black"
              >
                <div class="flex items-center pb-2 border-b border-black mb-2">
                  <img
                    class="h-8 w-8 rounded-full mr-2 bg-primary-100"
                    src="./assets/profilePicAnonymous.png"
                  />
                  <p class="font-bold">Anonymous</p>
                </div>
                <p>
                  {{ textInput }}
                </p>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #notFeedback>
          <textarea
            [(ngModel)]="textInput"
            class="form-control"
            placeholder="Input answer here"
            [disabled]="feedback"
          ></textarea>
        </ng-template>
      </ng-container>
      <ng-container *ngSwitchCase="'TA'">
        <div class="text-center mt-40">
          <app-icon
            [icon]="'widgets'"
            class="text-2xl text-primary-300 mb-10"
          ></app-icon>
          <div class="font-bold text-2xl text-primary-400 mb-4">
            Preview not available
          </div>
          <div class="text-primary-400">Please wait for future updates</div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
