<app-back-title-layout>
  <section>
    <header
      class="rounded flex justify-between bg-blue-200 px-4 py-2 text-blue-700 mb-4"
    >
      <span> Total Learning Time </span>

      <span> {{ totalReadingTime | timeDuration }} </span>
    </header>

    <div
      class="rounded flex justify-between bg-pink-200 px-4 py-2 text-pink-700 mb-4"
    >
      <span> Attempted Resources Count </span>

      <span> {{ attemptedResourcesCount }} </span>
    </div>

    <div
      class="rounded flex justify-between bg-purple-200 px-4 py-2 text-purple-700 mb-4"
    >
      <span> Completed Resources Count </span>

      <span> {{ completedResourcesCount }} </span>
    </div>
  </section>

  <section class="mt-10">
    <header class="font-bold">Courses</header>

    <app-item-list [itemTemplate]="itemTemplate" [source]="courseStatisticsObs">
    </app-item-list>
  </section>
</app-back-title-layout>

<ng-template #itemTemplate let-item="item">
  <a
    [routerLink]="['course-progress']"
    queryParamsHandling="merge"
    [queryParams]="{
      course_uuid: item.courseUuid
    }"
    class="flex items-center"
  >
    <img class="w-16 h-16 object-contain mr-5" [src]="item.coverUri" alt="" />

    <div>
      <div class="uppercase text-gray-500">Course</div>
      <div class="font-bold">{{ item.name }}</div>
      <div [ngSwitch]="item.status">
        <span
          *ngSwitchCase="'passed'"
          class="rounded-full text-success-700 bg-success-100 px-1"
        >
          Completed
        </span>
        <span
          *ngSwitchCase="'in_progress'"
          class="rounded-full text-secondary-700 bg-secondary-100 px-1"
        >
          In progress
        </span>
        <span
          *ngSwitchCase="'failed'"
          class="rounded-full text-error-700 bg-error-100 px-1"
        >
          Failed
        </span>

        <span
          *ngSwitchCase="'not_started'"
          class="rounded-full text-gray-700 bg-gray-100 px-1"
        >
          Not started
        </span>

        <span
          *ngSwitchCase="'needs_attention'"
          class="rounded-full text-warning-700 bg-warning-100 px-1"
        >
          Needs attention
        </span>
      </div>
    </div>
  </a>
</ng-template>
