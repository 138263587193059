<!-- header -->
<div class="flex text-gray-400">
  <app-icon icon="filter_list"> </app-icon>
  <div class="ml-2 uppercase w-full">filter</div>
  <button class="flex justify-end text-lg" mat-dialog-close>
    <app-icon icon="close"></app-icon>
  </button>
</div>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <!-- filters -->
  <div formArrayName="filter">
    <div *ngFor="let _filter of _filters.controls; let i = index">
      <div class="flex">
        <div class="uppercase font-bold my-2 w-full">filter {{ i + 1 }}</div>
        <button
          type="button"
          class="text-lg text-gray-400"
          (click)="removeFilter(i)"
        >
          <app-icon icon="close"></app-icon>
        </button>
      </div>
      <div [formGroupName]="i" class="block sm:flex w-full">
        <div class="sm:w-1/2 mr-2 w-full">
          <label for="field">Field</label>
          <div class="w-full">
            <select
              #selectOption
              class="form-control"
              formControlName="filterChoice"
              (change)="setInputType(i)"
            >
              <option [ngValue]="null" disabled selected>Choose filter</option>
              <option *ngFor="let field of fields" [ngValue]="field.value">
                {{ field.name }}
              </option>
            </select>
          </div>
        </div>
        <ng-container
          *ngIf="
            _filters.at(i).get('filterChoice').value &&
            _filters.at(i).get('filterChoice').value.input === 'select'
          "
        >
          <div class="sm:w-1/2 w-full">
            <label for="keyword">Select</label>
            <select class="form-control" formControlName="filterData">
              <option [ngValue]="null" disabled selected>Choose option</option>
              <option
                *ngFor="let option of fields[selectIndex[i]].value.options"
                [ngValue]="option.value"
              >
                {{ option.name }}
              </option>
            </select>
          </div>
        </ng-container>
        <ng-container
          *ngIf="
            _filters.at(i).get('filterChoice').value &&
            _filters.at(i).get('filterChoice').value.input !== 'select'
          "
        >
          <div class="sm:w-1/2 w-full">
            <label for="keyword">{{
              _filters.at(i).get("filterChoice").value.name || "Keyword"
            }}</label>
            <div class="w-full">
              <input
                [type]="_filters.at(i).get('filterChoice').value.input"
                class="form-control"
                formControlName="filterData"
                min="1"
              />
            </div>
          </div>
        </ng-container>
      </div>
      <!-- <input class="form-control mb-2" [formControlName]="i" /> -->
    </div>
  </div>
  <!-- add filter button -->
  <button type="button" (click)="addFilter()" class="flex text-gray-400 my-4">
    <app-icon icon="add"> </app-icon>
    <div class="uppercase ml-2">add more filter</div>
  </button>
  <!-- field and keyword list form -->

  <!-- filters tab -->

  <!-- clear all and save buttons -->
  <div class="flex justify-between w-full">
    <button
      type="button"
      (click)="clearAll()"
      class="px-4 py-1 border border-primary-600 rounded uppercase text-primary-600"
    >
      clear all
    </button>
    <button
      type="submit"
      class="text-white px-4 py-1 uppercase bg-primary-600 rounded"
      mat-dialog-close
    >
      save
    </button>
  </div>
</form>
