<!-- <textarea
  [cdkTextareaAutosize]="autoResize"
  [disabled]="disabled"
  [(ngModel)]="value"
  (ngModelChange)="propagateChange($event)"
  class="hidden overflow-hidden min-h-20 border-0 w-full {{ editorClass }}"
  #editor
  (scroll)="onEditorScroll(editor, viewer)"
  (keyup.escape)="onEscape($event)"
></textarea> -->

<div class="min-h-20 w-full" *ngIf="isInEditMode" #editor>
  <app-simplemde-editor
    [disabled]="disabled"
    [(ngModel)]="value"
    (ngModelChange)="propagateChange($event)"
    class="{{ editorClass }}"
  ></app-simplemde-editor>
</div>

<div
  #viewer
  *ngIf="!isInEditMode"
  [ngClass]="{
    'bg-gray-200 text-gray-700': disabled
  }"
  class="w-full min-h-20 overflow-x-hidden {{ viewerClass }}"
  (click)="onViewerClick($event)"
>
  <markdown
    class="w-full prose"
    ngPreserveWhitespaces
    [data]="value"
    [disableSanitizer]="true"
  >
  </markdown>
</div>
