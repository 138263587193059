import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  UntypedFormArray,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import {
  EMPTY,
  first,
  firstValueFrom,
  forkJoin,
  of,
  switchMap,
  tap,
} from "rxjs";
import { ModalService } from "src/app/services/modal.service";
import {
  FieldChoicesService,
  FieldEntriesService,
  FieldGroupsService,
  FieldsService,
  FormSubmissionsService,
  TabsService,
} from "src/app/sinigangnababoywithgabi";
import { AddFieldModalComponent } from "../components/add-field-modal/add-field-modal.component";
import { fadeInOut } from "src/app/app-common-module/animations/fade";
import { Location } from "@angular/common";
import { toHaveDisplayValue } from "@testing-library/jest-dom/matchers";

@Component({
  selector: "app-idis-form-editor",
  templateUrl: "./idis-form-editor.component.html",
  styles: [],
  animations: [fadeInOut],
})
export class IdisFormEditorComponent implements OnInit {
  form: any;
  formGroup: UntypedFormGroup;
  title: string = "";
  tabUuid: string = "";
  currentForm: any = {};
  loading: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private modalService: ModalService,
    private fb: FormBuilder,
    private fieldChoicesService: FieldChoicesService,
    private fieldGroupsService: FieldGroupsService,
    private fieldsService: FieldsService,
    private formSubmissionsService: FormSubmissionsService,
    private fieldEntriesService: FieldEntriesService,
    private tabsService: TabsService,
    private location: Location
  ) {}
  ngOnInit(): void {
    this.route.queryParams
      .pipe(
        switchMap((params) => {
          this.loading = true;
          let { parent_tab, tab_uuid } = params;
          this.tabUuid = tab_uuid;
          //Create a formGroup
          this.formGroup = this.fb.group({
            fieldGroups: this.fb.array([]),
          });

          //Get tab by uuid
          //Get FieldGroups for the tab
          return this.tabsService.servicesBurecordsTabsIdGet(tab_uuid).pipe(
            switchMap((tab) => {
              this.currentForm["displayName"] = tab.displayName;
              this.currentForm["id"] = tab_uuid;
              return this.fieldGroupsService.servicesBurecordsTabsIdFieldGroupsGet(
                tab_uuid
              );
            }),
            //Get Fields from each FieldGroup
            switchMap((fieldGroups) => {
              if (fieldGroups.fieldGroups.length === 0) {
                this.loading = false;
                return EMPTY;
              }
              this.currentForm["fieldGroups"] = fieldGroups.fieldGroups;
              let fieldGroupsObservables = fieldGroups.fieldGroups.map(
                (fieldGroup) =>
                  this.fieldsService.servicesBurecordsFieldGroupsIdFieldsGet(
                    fieldGroup.id
                  )
              );

              return forkJoin(fieldGroupsObservables);
            }),
            //Get FieldChoice if radio or dropdown or checkbox Field
            switchMap((results) => {
              this.currentForm["fieldGroups"] = this.currentForm[
                "fieldGroups"
              ].map((fieldGroup, i) => {
                return {
                  ...fieldGroup,
                  fields: results[i].fields,
                };
              });

              let fieldsObservables = results.map((result) => {
                if (result.fields.length === 0) {
                  return of([]);
                }
                return forkJoin(
                  result.fields.map((field) => {
                    if (
                      field.fieldType === "checkbox" ||
                      field.fieldType === "dropdown" ||
                      field.fieldType === "radio"
                    ) {
                      return this.fieldChoicesService.servicesBurecordsFieldsIdFieldChoicesGet(
                        field.id
                      );
                    }
                    return of({ count: 0, fieldChoices: [] });
                  })
                );
              });
              return forkJoin(fieldsObservables);
            }),
            //Map everything into Tab object
            switchMap((results) => {
              results.forEach((result, i) => {
                result.forEach((field, j) => {
                  this.currentForm.fieldGroups[i].fields[j].fieldChoices =
                    field.fieldChoices;
                });
              });
              return of([parent_tab, tab_uuid]);
            })
          );
          // return of([parent_tab, tab_uuid]);
        }),

        //Assign form to Tab object
        //Assign title to displayName
        //
        tap(([parent_tab, tab_uuid]) => {
          this.form = this.currentForm;
          this.title = this.form.displayName;

          if (this.form) {
            // Iterate FieldGroups
            this.form.fieldGroups.forEach((fieldGroup) => {
              let formGroup = this.fb.group({
                fieldGroup: [fieldGroup.name, Validators.required],
                id: [fieldGroup.id],
                orderId: [fieldGroup.orderId],
                fields: this.fb.array([]),
              });
              //Iterate each field in a fieldGroup
              fieldGroup.fields.forEach((field) => {
                let fieldFormGroup = this.fb.group({
                  id: [field.id],
                  name: [field.name, Validators.required],
                  fieldType: [field.fieldType],
                  isRequired: [field.isRequired],
                  orderId: [field.orderId],
                  fieldChoices: this.fb.array([]),
                  fieldWidth: [field.fieldWidth],
                  fieldHint: [field.fieldHint],
                  canEmployeeUpdate: [field.canEmployeeUpdate],
                  needsApproval: [field.needsApproval],
                });
                (<UntypedFormArray>formGroup.get("fields")).push(
                  fieldFormGroup
                );
                //Iterate each fieldChoice in each field
                if (field.fieldChoices)
                  field.fieldChoices.forEach((fieldChoice) => {
                    let fieldChoiceFormGroup = this.fb.group({
                      name: [fieldChoice.name, Validators.required],
                      id: [fieldChoice.id],
                    });
                    (<UntypedFormArray>fieldFormGroup.get("fieldChoices")).push(
                      fieldChoiceFormGroup
                    );
                  });
              });

              this.fieldGroups.push(formGroup);
            });
          }
          this.loading = false;
        })
      )
      .subscribe();
  }
  get fieldGroups() {
    return <UntypedFormArray>this.formGroup.get("fieldGroups");
  }

  getFields(index: number) {
    return <UntypedFormArray>this.fieldGroups.at(index).get("fields");
  }

  getFieldChoices(fieldGroupIndex: number, fieldIndex: number) {
    return <UntypedFormArray>(
      this.getFields(fieldGroupIndex).at(fieldIndex).get("fieldChoices")
    );
  }

  async onEnterNewOption(event: KeyboardEvent, form) {
    event.stopPropagation();
    event.preventDefault();
    let value = event.target["value"];
    event.target["value"] = "";

    if (value !== "") {
      //add option here
      await this.createOptionField(form, value);
    }
  }
  fieldGroupDrop(event: CdkDragDrop<any[]>) {
    this.moveItemInFormArray(
      this.fieldGroups,
      event.previousIndex,
      event.currentIndex
    );
    this.fieldGroups.controls.forEach((control, i) => {
      control.get("orderId").setValue(i);
    });
  }

  fieldDrop(event: CdkDragDrop<any[]>, fieldGroupIndex) {
    this.moveItemInFormArray(
      <UntypedFormArray>this.fieldGroups.at(fieldGroupIndex).get("fields"),
      event.previousIndex,
      event.currentIndex
    );
    (<UntypedFormArray>(
      this.fieldGroups.at(fieldGroupIndex).get("fields")
    )).controls.forEach((control, i) => {
      control.get("orderId").setValue(i);
    });
  }

  moveItemInFormArray(
    formArray: UntypedFormArray,
    fromIndex: number,
    toIndex: number
  ) {
    const dir = toIndex > fromIndex ? 1 : -1;

    const from = fromIndex;
    const to = toIndex;

    const temp = formArray.at(from);
    for (let i = from; i * dir < to * dir; i = i + dir) {
      const current = formArray.at(i + dir);
      formArray.setControl(i, current);
    }
    formArray.setControl(to, temp);
  }
  async deleteForm() {
    let deleteForm = await this.modalService.confirm(
      "Deleting this item will permanently delete saved records. This action can not be undone."
    );
  }

  async addSection() {
    let fieldGroupForm = this.fb.group({
      fieldGroup: ["", Validators.required],
      fields: this.fb.array([]),
      id: [],
      orderId: [this.fieldGroups.controls.length - 1],
    });
    let newFieldGroup = await firstValueFrom(
      this.fieldGroupsService.servicesBurecordsTabsIdFieldGroupsPost(
        this.tabUuid,
        {
          fieldGroup: {
            name: "",
            orderId: this.fieldGroups.controls.length - 1,
          },
        }
      )
    );
    fieldGroupForm.get("id").setValue(newFieldGroup.id);
    this.fieldGroups.push(fieldGroupForm);
  }

  async addField(fieldGroupIndex: number) {
    let field = await this.modalService.openModal(AddFieldModalComponent, {
      panelClass: "overflow-y-auto",
      height: "600px",
    });

    if (field) {
      if (field.type === "default") {
        let defaultField = this.fb.group({
          id: [""],
          name: ["", Validators.required],
          fieldType: [field.value],
          isRequired: [false],
          orderId: [this.getFields(fieldGroupIndex).controls.length - 1],
          fieldWidth: ["full"],
          fieldHint: [""],
          fieldChoices: this.fb.array([]),
          canEmployeeUpdate: [false],
          needsApproval: [false],
        });

        let newField = await firstValueFrom(
          this.fieldsService.servicesBurecordsFieldGroupsIdFieldsPost(
            this.fieldGroups.at(fieldGroupIndex).get("id").value,
            {
              field: {
                name: "",
                fieldType: field.value,
                isRequired: false,
                orderId: this.getFields(fieldGroupIndex).controls.length - 1,
                canEmployeeUpdate: false,
                needsApproval: false,
              },
            }
          )
        );
        defaultField.get("id").setValue(newField.id);
        this.getFields(fieldGroupIndex).push(defaultField);
        // this.getFields(fieldGroupIndex)
        //   .at(this.getFields(fieldGroupIndex).length - 1)
        //   .get("id")
        //   .setValue(newField.id);
      }
      if (field.type === "premade") {
      }
    }
  }
  async createOptionField(form, value) {
    let fieldId = form.controls.id.value;
    let options = form.controls.fieldChoices.controls;
    let choice = this.fb.group({ name: value, id: [""] });
    let newChoice = await firstValueFrom(
      this.fieldChoicesService.servicesBurecordsFieldsIdFieldChoicesPost(
        fieldId,
        { fieldChoice: { name: value } }
      )
    );
    choice.get("id").setValue(newChoice.id);
    options.push(choice);
  }
  async deleteSection(fieldGroup, i) {
    let fieldGroupId = this.fieldGroups.at(i).get("id").value;
    this.fieldGroups.removeAt(i);
    await firstValueFrom(
      this.fieldGroupsService.servicesBurecordsFieldGroupsIdDelete(fieldGroupId)
    );
  }
  async deleteField(fieldGroup, field, fieldIndex, fieldGroupIndex) {
    let fieldId = this.getFields(fieldGroupIndex)
      .at(fieldIndex)
      .get("id").value;
    this.getFields(fieldGroupIndex).removeAt(fieldIndex);
    await firstValueFrom(
      this.fieldsService.servicesBurecordsFieldsIdDelete(fieldId)
    );
  }
  async removeChoice(form, index) {
    let choiceUuid = form.controls.fieldChoices.at(index).get("id").value;
    form.controls.fieldChoices.removeAt(index);
    await firstValueFrom(
      this.fieldChoicesService.servicesBurecordsFieldChoicesIdDelete(choiceUuid)
    );
  }
  async changeFieldType(fieldGroup, field, fieldIndex) {
    let changeField = await this.modalService.openModal(
      AddFieldModalComponent,
      {
        panelClass: "overflow-y-auto",
        height: "600px",
      }
    );

    if (changeField) {
      if (changeField.type === "default") {
        field.get("fieldType").setValue(changeField.value);
        field.get("fieldChoices").setValue([]);
        await firstValueFrom(
          this.fieldsService.servicesBurecordsFieldsIdPut(
            field.get("id").value,
            {
              field: {
                name: field.get("name").value,
                fieldType: changeField.value,
                isRequired: false,
                canEmployeeUpdate: false,
              },
            }
          )
        );
      }
      if (field.type === "premade") {
      }
    }
  }
  requiresValidation(fieldGroup, field, fieldIndex) {}
  setEmployeePermissions(field, permission) {
    if (permission === "view") {
      field.get("canEmployeeUpdate").setValue(false);
    }
    if (permission === "edit") {
      field.get("canEmployeeUpdate").setValue(true);
    }
  }
  getDropdownIcon(field) {
    return field.get("canEmployeeUpdate").value ? "mode" : "visibility";
  }

  async updateForm() {
    if (this.fieldGroups.controls.length > 0)
      this.fieldGroups.controls.forEach(async (fieldGroup, i) => {
        let fieldGroupUuid = fieldGroup.get("id").value;

        let updatedFieldGroup = {
          name: fieldGroup.get("fieldGroup").value,
          orderId: fieldGroup.get("orderId").value,
        };
        await firstValueFrom(
          this.fieldGroupsService.servicesBurecordsFieldGroupsIdPut(
            fieldGroupUuid,
            { fieldGroup: updatedFieldGroup }
          )
        );

        if ((<UntypedFormArray>fieldGroup.get("fields")).controls.length > 0)
          (<UntypedFormArray>fieldGroup.get("fields")).controls.forEach(
            async (field, j) => {
              let fieldUuid = field.get("id").value;
              let updatedField = {
                name: field.get("name").value,
                fieldType: field.get("fieldType").value,
                isRequired: field.get("isRequired").value,
                orderId: field.get("orderId").value,
                canEmployeeUpdate: field.get("canEmployeeUpdate").value,
                fieldWidth: field.get("fieldWidth").value,
                fieldHint: field.get("fieldHint").value,
                needsApproval: field.get("needsApproval").value,
              };
              await firstValueFrom(
                this.fieldsService.servicesBurecordsFieldsIdPut(fieldUuid, {
                  field: updatedField,
                })
              );
              if (
                (<UntypedFormArray>field.get("fieldChoices")).controls.length >
                0
              )
                (<UntypedFormArray>field.get("fieldChoices")).controls.forEach(
                  async (choice, k) => {
                    let choiceUuid = choice.get("id").value;
                    let updatedChoice = {
                      name: choice.get("name").value,
                    };
                    await firstValueFrom(
                      this.fieldChoicesService.servicesBurecordsFieldChoicesIdPut(
                        choiceUuid,
                        { fieldChoice: updatedChoice }
                      )
                    );
                  }
                );
            }
          );
        if (i === this.fieldGroups.controls.length - 1) {
          this.loading = false;
          this.location.back();
        }
      });
  }
  async onSave() {
    this.loading = true;
    await this.updateForm();
  }
}
