import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Group, PassService, Role } from "src/app/sinigangnababoywithgabi";
import {
  ActivityLogService,
  VERBS,
} from "src/app/services/activity-log.service";
import { DashboardService } from "src/app/services/dashboard.service";
import { firstValueFrom } from "rxjs";

@Component({
  selector: "app-group-create",
  templateUrl: "./group-create.component.html",
  styleUrls: ["./group-create.component.scss"],
})
export class GroupCreateComponent implements OnInit {
  formGroup: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private passService: PassService,
    private dashboardService: DashboardService,
    private activityLogService: ActivityLogService
  ) {}

  async ngOnInit() {
    this.formGroup = this.formBuilder.group({
      name: ["", Validators.required],
      description: ["", null],
      selectedRoleId: [null],
    });
  }

  async save() {
    let cast = await firstValueFrom(this.dashboardService.getCast());

    let group: Group = {
      name: this.formGroup.value.name,
      description: this.formGroup.value.description,
      parentId: cast.data.attributes.defaultGroupId,
      roleIds: [this.formGroup.value.selectedRoleId],
    };

    let result = await firstValueFrom(this.passService.createGroup({ group }));

    this.router.navigate(["groups", "profile", "edit-members"], {
      queryParams: {
        group_uuid: result["id"],
      },
      replaceUrl: true,
    });

    this.activityLogService.didGroup(VERBS.created, result);
  }
}
