import { Component, OnInit } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { PassService } from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-tablist",
  templateUrl: "./tablist.component.html",
  styleUrls: ["./tablist.component.scss"],
})
export class TablistComponent implements OnInit {
  castUserCount: number;
  activeUserCount: number;

  constructor(private passService: PassService) {}

  async ngOnInit() {
    let today = new Date();
    let from = new Date(today.getFullYear(), today.getMonth(), 0);
    let to = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    let [castUserCount, activeUserCount] = await Promise.all([
      firstValueFrom(this.passService.listUsers(null, 0)).then((r) => r.count),
      firstValueFrom(
        this.passService.listUsers(
          null,
          0,
          null,
          null,
          null,
          null,
          from.toISOString(),
          to.toISOString()
        )
      ).then((r) => r.count),
    ]);

    this.castUserCount = castUserCount;
    this.activeUserCount = activeUserCount;
  }
}
