import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { CdkDragDrop, transferArrayItem } from "@angular/cdk/drag-drop";
import {
  BlockItem,
  SectionItem,
} from "../../course-content-edit/course-content-edit.component";
import { slideIn } from "src/app/app-common-module/animations/slide";
import { zoomInOut } from "src/app/app-common-module/animations/zoom";
import { ModalService } from "src/app/services/modal.service";
import { ActivatedRoute } from "@angular/router";
import {
  CourseBlock,
  CoursesService,
  Resource,
  ResourcesService,
} from "src/app/sinigangnababoywithgabi";
import { firstValueFrom, from } from "rxjs";
import {
  ActivityLogService,
  VERBS,
} from "src/app/services/activity-log.service";

@Component({
  selector: "app-course-content-edit-block-list",
  templateUrl: "./course-content-edit-block-list.component.html",
  styleUrls: ["./course-content-edit-block-list.component.scss"],

  animations: [slideIn, zoomInOut],
})
export class CourseEditContentBlockListComponent implements OnInit {
  @Input()
  sectionItem: SectionItem;

  @Input()
  blockListIndex: number;

  @Input()
  inEditMode: boolean;

  @Output()
  addBlock: EventEmitter<any> = new EventEmitter();
  @Output()
  deleteBlock: EventEmitter<any> = new EventEmitter();

  @Output()
  blockDrop: EventEmitter<any> = new EventEmitter();

  blockHoverIndex: number;

  constructor(
    private modalService: ModalService,
    private route: ActivatedRoute,
    private resourcesService: ResourcesService,
    private coursesService: CoursesService,
    private activityLogService: ActivityLogService
  ) {}

  ngOnInit(): void {}

  async onBlockDrop(event: CdkDragDrop<{ sectionItem: SectionItem }>) {
    // If drop came from another block list
    if (event.previousContainer.data.sectionItem) {
      transferArrayItem(
        event.previousContainer.data.sectionItem.blockItems,
        event.container.data.sectionItem.blockItems,
        event.previousIndex,
        event.currentIndex
      );

      this.blockDrop.emit(event);

      //If drop came from course set
    } else {
      // Create block
      // Add data to block

      let sectionUuid = event.container.data.sectionItem.sectionUuid;

      let { course_uuid: courseUuid } = this.route.snapshot.queryParams;

      let resource = event.item.data as Resource;

      let index = event.currentIndex;

      let block: CourseBlock = await this.addExistingBlockFromCollection({
        sectionUuid,
        courseUuid,
        resource,
        order: 0,
      });

      let blockItem: BlockItem = {
        blockUuid: block.uuid,
        blockName: block.title,
        sectionUuid: block.section,
        coverUri: block.coverUri,
        type: block.content.resourceDetails?.type,
        subtype: block.content.resourceDetails?.subtype,
        resourceUuid: block.content.resourceDetails.uuid,
        daysTilExpired: block.daysTilExpired,
        prerequisites: [],
        passOnSubmit: block.referenceInfo?.passOnSubmit,
        customPassingMark: block.referenceInfo?.customPassingMark as any,
        openDate: block.openDate,
      };
      console.log(blockItem);
      event.container.data.sectionItem.blockItems.splice(index, 0, blockItem);

      this.blockDrop.emit(event);
    }
  }

  onAddBlock(index: number) {
    this.addBlock.emit(index);
  }

  async onDeleteBlock(blockItem: BlockItem, index: number) {
    let result = await this.modalService.confirm(
      `Are your sure you want to delete ${blockItem.blockName}?`
    );

    if (result) {
      this.sectionItem.blockItems.splice(index, 1);
      this.deleteBlock.emit(blockItem);
    }
  }

  toggleEditMode() {
    this.inEditMode = !this.inEditMode;
  }

  async addExistingBlockFromCollection(options: {
    sectionUuid: string;
    courseUuid: string;
    resource: Resource;
    order: number;
  }) {
    let resource = await firstValueFrom(
      this.resourcesService.getResourceByUuid(options.resource.uuid)
    );

    let referenceInfo;

    if (resource.type === "assessment") {
      referenceInfo = {
        referenceId: resource.content?.examDetails?.examTimeslotUuid,
        customPassingMark: 50,
      };
    }

    let result = await firstValueFrom(
      this.coursesService.sectionBlockCreate(options.sectionUuid, {
        title: resource.name,
        coverUri: resource.coverUri,
        orderId: (options.order + "").padStart(2, "0"),
        content: {
          resourceDetails: {
            uuid: resource.uuid,
            name: resource.name,
            description: resource.description,
            coverUri: resource.coverUri,
            type: resource.type,
            subtype: resource.subtype,
            flavorTextSpan: resource.flavorTextSpan,
            tags: resource.tags,
            publishedAt: resource.publishedAt,
          },
        },
        referenceInfo,
      })
    );

    this.activityLogService.didCourseBlock(VERBS.created, result, {
      context: {
        contextActivities: {
          parent: [
            this.activityLogService.constructCourseSectionObject({
              uuid: options.sectionUuid,
            }),
          ],
          category: [
            this.activityLogService.constructCourseObject({
              uuid: options.courseUuid,
            }),
          ],
        },
      },
    });

    return result;
  }
}
