import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { ActivityLogService } from "src/app/services/activity-log.service";
import { DashboardService } from "src/app/services/dashboard.service";
import { ModalService } from "src/app/services/modal.service";
import { CastsService, V2CastsService } from "src/app/sinigangnababoywithgabi";
@Component({
  selector: "app-casteditor-block-button-search",
  templateUrl: "./casteditor-block-button-search.component.html",
  styles: [],
})
export class CasteditorBlockButtonSearchComponent implements OnInit {
  constructor(
    private router: Router,
    private castsService: CastsService,
    private dashboardService: DashboardService,
    private activityLogsService: ActivityLogService,
    private v2CastsService: V2CastsService,
    private modalService: ModalService
  ) {}

  @Input() screenId: any;
  @Input() block: any;
  @Input() disableEdit: boolean;
  @Output() deleteBlock: EventEmitter<any> = new EventEmitter();

  searchBlockTitle: string = "test";

  async ngOnInit() {
    this.searchBlockTitle = this.block.properties.title;
  }

  onDeleteBlock() {
    this.deleteBlock.emit();
  }
}
