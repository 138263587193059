<app-back-title-layout [loading]="!formGroup">
  <form [formGroup]="formGroup" (ngSubmit)="createRole()" *ngIf="formGroup">
    <label class="block my-10">
      <div class="form-label">Role Name</div>

      <input formControlName="name" class="form-control" />

      <app-form-control-error-layout
        [control]="formGroup.get('name')"
      ></app-form-control-error-layout>
    </label>

    <label class="block my-10">
      <div class="form-label">Role Description</div>

      <textarea
        formControlName="description"
        class="form-control"
        rows="3"
      ></textarea>
    </label>

    <app-group-role-permissions-toggle-form
      [formGroup]="formGroup.get('permissions')"
      [role]="newRole"
    >
    </app-group-role-permissions-toggle-form>

    <div class="flex flex-row-reverse">
      <button [disabled]="formGroup.invalid" class="btn btn-primary">
        SAVE
      </button>
    </div>
  </form>
</app-back-title-layout>
