import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import {
  CastsService,
  CloudflareStreamService,
  Resource,
  UploadsService,
} from "src/app/sinigangnababoywithgabi";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormArray,
} from "@angular/forms";
import { Platform } from "@angular/cdk/platform";
import { delay, firstValueFrom, of, retry, switchMap } from "rxjs";
import { fadeInOut } from "src/app/app-common-module/animations/fade";
import { DashboardService } from "src/app/services/dashboard.service";
declare let shaka: any;
@Component({
  selector: "app-resource-type-upload-video",
  templateUrl: "./resource-type-upload-video.component.html",
  styleUrls: ["./resource-type-upload-video.component.scss"],
  animations: [fadeInOut],
})
export class ResourceTypeUploadVideoComponent implements OnInit {
  @ViewChild("videoPlayer") videoElementRef: ElementRef | undefined;
  @ViewChild("videoContainer") videoContainerRef: ElementRef | undefined;
  videoElement: HTMLVideoElement | undefined;
  videoContainerElement: HTMLDivElement | undefined;
  player: any;
  cue: any;
  loading: boolean = false;
  hasVideo: boolean = false;
  fontSize: string = "text-base";
  fontSizePx: string = "16px";
  rangeValue: string = "6";
  castDomains: Array<string> = [];
  @Input()
  formGroup: UntypedFormGroup;

  @Input()
  resource: Resource;

  contentFormGroup: UntypedFormGroup;

  defaultHosts = [/.*castlms.com/, /.*castdashboard.com/, /.*buri.dev/];
  constructor(
    private formBuilder: UntypedFormBuilder,
    private uploadsService: UploadsService,
    private cloudFlareStreamService: CloudflareStreamService,
    private platform: Platform,
    private castsService: CastsService,
    private dashboardService: DashboardService
  ) {}

  async ngOnInit() {
    let tracks = this.resource.content["tracks"];
    this.contentFormGroup = this.formBuilder.group({
      uri: [this.resource.content["uri"], Validators.required],
      hlsUri: [this.resource.content["hlsUri"]],
      dashUri: [this.resource.content["dashUri"]],
      cfStreamUid: [this.resource.content["cfStreamUid"]],
      subtitleClass: [this.resource.content["subtitleClass"] || "text-base"],
      contentType: [this.resource.content["contentType"], Validators.required],
      videoDescription: [this.resource.content["videoDescription"]],
      tracks: [
        tracks
          ? tracks.map((track) => ({
              default: track["default"],
              kind: track["kind"],
              label: track["label"],
              srclang: track["srclang"],
              uri: track["uri"],
            }))
          : [],
      ],
    });

    this.formGroup.addControl("content", this.contentFormGroup);
    this.getRangeValue(this.contentFormGroup.get("subtitleClass").value);

    let cast = await firstValueFrom(this.dashboardService.getCast());
    let domains = await firstValueFrom(
      this.castsService.castDomainsList(cast.data.id)
    );
    let mappedDomains = domains.map((domain) => {
      return domain.name;
    });

    //Test the domains if is already under the default values
    this.castDomains = mappedDomains.filter((domain) => {
      let result = true;
      this.defaultHosts.forEach((host) => {
        if (host.test(domain)) {
          result = false;
        }
      });
      return result;
    });

  }

  getRangeValue(subtitleClass) {
    switch (subtitleClass) {
      case "text-xs":
        this.fontSizePx = "12px";
        this.rangeValue = "0";
        break;
      case "text-sm":
        this.fontSizePx = "14px";
        this.rangeValue = "2";
        break;
      case "text-base":
        this.fontSizePx = "16px";
        this.rangeValue = "4";
        break;
      case "text-lg":
        this.fontSizePx = "18px";
        this.rangeValue = "6";
        break;
      case "text-xl":
        this.fontSizePx = "20px";
        this.rangeValue = "8";
        break;
      case "text-2xl":
        this.fontSizePx = "24px";
        this.rangeValue = "10";
        break;
      case "text-3xl":
        this.fontSizePx = "30px";
        this.rangeValue = "12";
        break;
      default:
        this.fontSizePx = "16px";
        this.rangeValue = "4";
    }
  }
  ngAfterViewInit(): void {
    this.hasVideo =
      this.formGroup.get("content").get("dashUri").value ||
      this.formGroup.get("content").get("hlsUri").value ||
      this.formGroup.get("content").get("uri").value;
    shaka.polyfill.installAll();
    if (shaka.Player.isBrowserSupported()) {
      this.videoElement = this.videoElementRef?.nativeElement;
      this.videoContainerElement = this.videoContainerRef?.nativeElement;
      this.initPlayer(
        this.formGroup.get("content").get("dashUri").value ||
          this.formGroup.get("content").get("hlsUri").value ||
          this.formGroup.get("content").get("uri").value
      );
    } else {
      console.error("Browser not supported");
    }
  }
  async initPlayer(url) {
    this.player = new shaka.Player(this.videoElement);

    if (this.videoContainerElement) {
      const ui = new shaka.ui.Overlay(
        this.player,
        this.videoContainerElement,
        this.videoElement
      );
      this.cue = new shaka.text.Cue(0, 0, "");
      this.cue.fontSize = "12px";
      let subs = this.formGroup.value.content?.tracks[0]?.uri;
      let lang = this.formGroup.value.content?.tracks[0]?.srclang;
      let videoUrl = this.platform.SAFARI
        ? this.formGroup.get("content").get("hlsUri").value ||
          this.formGroup.get("content").get("uri").value
        : url;
      await this.player.load(videoUrl);

      // await this.player.addTextTrackAsync(
      //   subs,
      //   lang || "en",
      //   "subtitles",
      //   "text/vtt"
      // );
      // const textTracks = this.player.getTextTracks();
      // console.log(textTracks);
      // console.log(this.cue);
      // if (textTracks.length > 0) {
      //   this.player.setTextTrackVisibility(true);
      //   this.player.selectTextTrack(textTracks[0]);
      // }
      this.videoElement?.play();
    }
  }
  onUpload(result) {
    this.cloudFlareStreamService
      .uploadCloudflareStream({
        videoUrl: result.uri,
        allowedHosts: this.castDomains.join(","),
      })
      .pipe(
        switchMap((upload) => {
          this.loading = true;
          this.contentFormGroup
            .get("hlsUri")
            .setValue(upload.result.playback.hls);
          this.contentFormGroup
            .get("dashUri")
            .setValue(upload.result.playback.dash);
          this.contentFormGroup.get("uri").setValue(result.uri);
          this.contentFormGroup.get("cfStreamUid").setValue(upload.result.uid);

          return this.cloudFlareStreamService
            .detailCloudflareStream(upload.result.uid)
            .pipe(
              switchMap((status) => {
                if (status.result.status.state !== "ready") {
                  throw new Error("Error");
                }
                return of(status);
              }),
              delay(1000),
              retry()
            );
        })
      )
      .subscribe((status) => {
        if (status.result.status.state === "ready") {
          this.loading = false;
          this.hasVideo = true;
          this.ngAfterViewInit();
        }
      });

    this.formGroup
      .get("content")
      .get("contentType")
      .setValue(result.contentType);
  }

  removeVideo() {
    this.contentFormGroup.get("uri").setValue(null);
    this.contentFormGroup.get("hlsUri").setValue(null);
    this.contentFormGroup.get("dashUri").setValue(null);
  }

  subFileChange(event) {
    let file: File = event.target.files[0];
    event.srcElement.value = null;
    let tracksFormArray = this.contentFormGroup.get(
      "tracks"
    ) as UntypedFormArray;

    tracksFormArray.patchValue([]);

    this.uploadsService.uploadFile(file).subscribe((result) => {
      tracksFormArray.patchValue([
        {
          default: true,
          kind: "captions",
          label: "Subtitle",
          // srclang: track["srclang"],
          uri: result.uri,
        },
      ]);
      this.ngAfterViewInit();
    });
  }

  changeSubtitleSize(event) {
    let shaka: HTMLElement = document.querySelector(".shaka-text-container");

    // shaka.style["fontSize"] = "36px";
    // shaka.style["display"] = "none";
    // console.log(shaka);

    switch (event.target.value) {
      case "0":
        this.fontSize = "text-xs";
        this.fontSizePx = "12px";
        break;
      case "2":
        this.fontSize = "text-sm";
        this.fontSizePx = "14px";
        break;
      case "4":
        this.fontSize = "text-base";
        this.fontSizePx = "16px";
        break;
      case "6":
        this.fontSize = "text-lg";
        this.fontSizePx = "18px";
        break;
      case "8":
        this.fontSize = "text-xl";
        this.fontSizePx = "20px";
        break;
      case "10":
        this.fontSize = "text-2xl";
        this.fontSizePx = "24px";
        break;
      case "12":
        this.fontSize = "text-3xl";
        this.fontSizePx = "30px";
        break;
      default:
        this.fontSize = "text-base";
        this.fontSizePx = "16px";
    }
    this.formGroup.get("content").get("subtitleClass").setValue(this.fontSize);
  }
}
