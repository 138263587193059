import { Component, OnInit } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  UntypedFormBuilder,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Subject, firstValueFrom } from "rxjs";
import {
  takeUntil,
  flatMap,
  map,
  debounceTime,
  distinctUntilChanged,
} from "rxjs/operators";
import { fadeInOut } from "src/app/app-common-module/animations/fade";
import {
  CourseCertificateTemplate,
  CourseCertificateEmailTemplate,
  CoursesService,
} from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-course-email-notifications",
  templateUrl: "./course-email-notifications.component.html",
  styleUrls: ["./course-email-notifications.component.scss"],
  animations: [fadeInOut],
})
export class CourseEmailNotificationsComponent implements OnInit {
  emailNotificationEnabledFormControl: UntypedFormControl =
    new UntypedFormControl(false);
  enrollEmailProfileFormGroup: UntypedFormGroup;

  loading: boolean;

  constructor(
    private route: ActivatedRoute,
    private coursesService: CoursesService,
    private formBuilder: UntypedFormBuilder
  ) {}

  private destroy$ = new Subject();
  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  async ngOnInit() {
    this.loading = true;
    await this.setUpNotificationToggle();

    this.loading = false;
  }

  async setUpNotificationToggle() {
    let { course_uuid: courseUuid } = this.route.snapshot.queryParams;

    this.enrollEmailProfileFormGroup = this.formBuilder.group({
      enrollEmailProfileUuid: [],
      courseUuid: [courseUuid],
      subject: [],
      fromEmail: [],
      htmlContent: [],
      displayCourseTitle: [],
      isActive: [true],
    });

    let enrollEmailProfile = await firstValueFrom(
      this.coursesService.courseEnrollemailprofileRead(courseUuid)
    ).catch((err) => null);

    if (enrollEmailProfile) {
      this.emailNotificationEnabledFormControl.setValue(true, {
        onlySelf: true,
        emitEvent: true,
      });

      this.enrollEmailProfileFormGroup.patchValue(
        {
          ...enrollEmailProfile,
          enrollEmailProfileUuid: enrollEmailProfile.uuid,
        },
        {
          onlySelf: true,
          emitEvent: true,
        }
      );
    }

    this.emailNotificationEnabledFormControl.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        flatMap((value) => {
          this.emailNotificationEnabledFormControl.disable({
            emitEvent: false,
          });
          // If true, create certificate profile
          // Else, delete certificate profile
          if (value) {
            return this.coursesService
              .courseEnrollemailprofileCreate(courseUuid, {
                ...this.enrollEmailProfileFormGroup.value,
                textContent: this.enrollEmailProfileFormGroup.value.htmlContent,
                isActive: true,
              })
              .pipe(
                map((result) => {
                  this.enrollEmailProfileFormGroup.patchValue(
                    {
                      enrollEmailProfileUuid: result.uuid,
                      ...result,
                    },
                    {
                      onlySelf: true,
                      emitEvent: true,
                    }
                  );
                })
              );
          } else {
            return this.coursesService.courseEnrollemailprofileDelete(
              courseUuid
            );
          }
        }),
        map(() => {
          this.emailNotificationEnabledFormControl.enable({
            emitEvent: false,
          });
        })
      )
      .subscribe();

    this.enrollEmailProfileFormGroup.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(300),
        distinctUntilChanged(),
        flatMap((value) => {
          return this.coursesService.courseEnrollemailprofileUpdate(
            courseUuid,
            {
              ...this.enrollEmailProfileFormGroup.value,
              textContent: this.enrollEmailProfileFormGroup.value.htmlContent,
              isActive: true,
            }
          );
        })
      )
      .subscribe();
  }
}
