import { Component, OnInit } from "@angular/core";

// DEPRECATED
@Component({
  selector: "app-group-edit-members-index",
  templateUrl: "./group-edit-members-index.component.html",
  styleUrls: ["./group-edit-members-index.component.scss"],
})
export class GroupEditMembersIndexComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
