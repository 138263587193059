<app-back-title-layout [loading]="!formGroup">
  <ng-container *ngIf="formGroup">
    <form [formGroup]="formGroup" (ngSubmit)="save()">
      <h2 class="text-xl font-bold">Group Details</h2>

      <div class="my-5">
        <label>
          <span class="form-label"> Group Name </span>

          <input
            formControlName="name"
            class="form-control"
            placeholder="Group Name"
          />

          <app-form-control-error-layout
            [control]="formGroup.get('name')"
          ></app-form-control-error-layout>
        </label>
      </div>

      <div class="my-5">
        <label>
          <span class="form-label"> Description </span>
          <textarea
            formControlName="description"
            placeholder="Add short description about this group"
            type="text"
            class="form-control"
          ></textarea>
        </label>
      </div>

      <hr class="my-10" />

      <h2 class="text-xl font-bold">Role</h2>

      <app-role-selector
        [setLearnersAsDefaultRole]="true"
        formControlName="selectedRoleId"
      ></app-role-selector>

      <div class="mt-10 flex flex-row-reverse">
        <button
          type="submit"
          [disabled]="this.formGroup.invalid"
          class="btn btn-primary"
        >
          Next
        </button>
      </div>
    </form>
  </ng-container>
</app-back-title-layout>
