import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { Subject, firstValueFrom } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {
  DashBoardCard,
  DashboardService,
} from "src/app/services/dashboard.service";
import { CastsService, PassService } from "src/app/sinigangnababoywithgabi";
import { fadeInOut } from "../../animations/fade";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-nav-drawer-layout",
  templateUrl: "./nav-drawer-layout.component.html",
  styleUrls: ["./nav-drawer-layout.component.scss"],
  animations: [fadeInOut],
})
export class NavDrawerLayoutComponent implements OnInit {
  @Input()
  layoutTitle: string;

  @Input()
  title: string;

  @ViewChild("sidenav") sideNav: ElementRef;
  @ViewChild("content") content: ElementRef;
  sidebar;
  mobileMaxWidth;
  current;
  toggleNav;
  role;
  dashboardInfo: {
    dashboardTitle: string;
    dashboardLogoUrl: string;
  };
  cards: DashBoardCard[];
  userInfo;
  permission;
  initials: string = "";
  learnersUri: string = environment.LEARNERS_URI;
  private unsubscribe$: Subject<void> = new Subject<void>();
  constructor(
    private dashboardService: DashboardService, //private passService: PassService
    private titleService: Title,
    private route: ActivatedRoute,
    private passService: PassService
  ) {}

  async ngOnInit() {
    //Experimental
    let cast = await firstValueFrom(this.dashboardService.getCast());
    if (this.layoutTitle) {
      this.layoutTitle = this.layoutTitle || this.title; //for backwards compat
      this.titleService.setTitle(this.layoutTitle || "Cast Dashboard");
    } else {
      this.route.data
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((data: any) => {
          this.layoutTitle = data.layoutTitle || data.title;
          this.titleService.setTitle(this.layoutTitle || "Cast Dashboard");
        });
    }
    this.mobileMaxWidth = window.matchMedia("(max-width:640px)");

    this.toggleNav = localStorage.getItem("nav");

    this.dashboardInfo = await firstValueFrom(
      this.dashboardService.getDashboardNameAndLogo()
    );

    this.cards = await firstValueFrom(
      this.dashboardService.getDashboardCards()
    );
    this.learnersUri = cast.data.attributes.defaultDomain?.learner
      ? `https://${cast.data.attributes.defaultDomain?.learner}`
      : environment.LEARNERS_URI;
    this.userInfo = await firstValueFrom(this.dashboardService.getUserInfo());
    this.initials =
      this.userInfo.givenName && this.userInfo.familyName
        ? this.userInfo?.givenName?.[0] + this.userInfo?.familyName?.[0]
        : "AU";
    this.role = "Role/Permission";
    //this.permission = await this.dashboardService.getPermissions().toPromise();
  }
  ngOnChanges(): void {
    if (this.layoutTitle) {
      this.layoutTitle = this.layoutTitle || this.title; // For backwards compatback
      this.titleService.setTitle(this.layoutTitle || "Cast Dashboard");
    }
  }
  ngAfterViewInit(): void {
    if (!this.toggleNav) {
      localStorage.setItem("nav", "on");

      this.toggleNav = localStorage.getItem("nav");
    } else if (this.toggleNav === "off" && !this.mobileMaxWidth.matches) {
      this.toggleNav = "on";
      this.toggleSideNav();
    }
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
  toggleSideNav() {
    if (this.mobileMaxWidth.matches) {
      this.sideNav.nativeElement.classList.toggle("-translate-x-full");
      this.setSideNavSettings();
    } else {
      this.sideNav.nativeElement.classList.toggle("sm:translate-x-0");
      this.sideNav.nativeElement.classList.toggle("sm:relative");
      this.content.nativeElement.classList.toggle("sm:ml-[300px]");
      this.setSideNavSettings();
    }
  }

  setSideNavSettings() {
    if (this.toggleNav === "on") {
      localStorage.setItem("nav", "off");
      this.toggleNav = localStorage.getItem("nav");
    } else {
      localStorage.setItem("nav", "on");
      this.toggleNav = localStorage.getItem("nav");
    }
  }
}
