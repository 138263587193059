<header class="fixed z-20 w-full bg-white shadow-md">
  <!-- navbar -->
  <nav class="flex items-center justify-between px-2 h-14">
    <button
      class="p-3 mx-5 bg-transparent btn btn-icon"
      type="button"
      (click)="toggleSideNav()"
    >
      <app-icon class="text-2xl" icon="more_horiz"></app-icon>
    </button>

    <a
      [routerLink]="['/']"
      class="flex items-center flex-1 min-w-0 text-buri-blue"
    >
      <ng-container>
        <img src="assets/default-logo.png" alt="cast logo" class="h-10 mr-3" />
      </ng-container>
    </a>

    <app-dropdown-layout position="right">
      <span
        dropdownToggle
        class="flex items-center justify-center w-8 h-8 overflow-hidden font-bold text-white rounded-full select-none bg-primary-700"
      >
        {{ initials }}
      </span>
      <div dropdownContent>
        <a
          [href]="learnersUri"
          target="_blank"
          class="block w-full px-4 py-2 text-base text-gray-800 hover:bg-primary hover:text-white"
        >
          Go to Learners Site
        </a>
        <a
          [routerLink]="['/auth/logout']"
          class="block w-full px-4 py-2 text-base text-gray-800 hover:bg-primary hover:text-white"
          >Log out</a
        >
      </div>
    </app-dropdown-layout>
    <!-- <a [routerLink]="['/auth/logout']" class="ml-4"
      ><app-icon class="text-2xl text-primary" [icon]="'logout'"></app-icon
    ></a> -->
  </nav>
</header>
<!-- <div class="relative flex min-h-screen"> -->
<!-- sidebar -->
<div
  sidebar
  #sidenav
  class="overflow-y-auto h-full pt-14 sidebar flex-none bg-primary-600 w-[300px] text-white top-0 left-0 z-10 transform -translate-x-full sm:relative sm:translate-x-0 transition"
>
  <!-- logo -->
  <div class="mx-4 my-6">
    <ng-container *ngIf="dashboardInfo?.dashboardLogoUrl">
      <img
        [src]="dashboardInfo?.dashboardLogoUrl"
        alt="dashboard logo"
        class="object-contain w-20 h-20 mb-2 bg-gray-200 border-2 rounded"
      />
    </ng-container>
    <ng-container *ngIf="!dashboardInfo?.dashboardLogoUrl">
      <img
        [src]="'/assets/castprofile.png'"
        alt="dashboard logo"
        class="w-20 h-20 mb-2 rounded"
      />
    </ng-container>

    <!-- sidenav -->
    <nav>
      <div class="min-w-0">
        <div class="text-xl font-bold truncate">
          {{ dashboardInfo?.dashboardTitle }}
        </div>
      </div>
      <ng-container *ngIf="userInfo"
        ><div class="mb-8 text-sm">{{ userInfo.email }}</div></ng-container
      >

      <!-- <div
          class="inline-block px-2 mb-8 text-sm rounded-full bg-buri-light-blue"
        >
          {{ role }}
        </div> -->

      <hr class="" />

      <div>
        <ul>
          <a
            [routerLink]="['/']"
            [ngClass]="
              layoutTitle === 'Home'
                ? 'w-full text-lg block py-2 text-white font-bold'
                : 'w-full text-lg opacity-50 block py-2 text-white font-bold  hover:opacity-100'
            "
          >
            <app-icon class="pr-2" [icon]="'home'"></app-icon>
            Home</a
          >
          <li *ngFor="let card of cards; let i = index" [@fadeInOut]>
            <a
              *ngIf="card.href"
              [href]="card.href"
              [target]="card.target"
              class="block w-full py-2 text-lg font-bold text-white opacity-50 hover:opacity-100"
            >
              <app-icon class="pr-2" [icon]="card.icon"></app-icon>
              {{ card.title }}</a
            >
            <a
              *ngIf="!card.href"
              [routerLink]="card.routerLink"
              class="block w-full py-2 text-lg font-bold text-white opacity-50 hover:opacity-100"
              routerLinkActive="opacity-100"
            >
              <app-icon class="pr-2" [icon]="card.icon"></app-icon>
              {{ card.title }}</a
            >
          </li>
        </ul>
      </div>
    </nav>
    <svg
      width="266"
      height="342"
      viewBox="0 0 266 342"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="polygons"
    >
      <path
        d="M215.681 187.227L179.774 207.958L143.866 187.227L143.866 145.764L179.774 125.033L215.681 145.764L215.681 187.227Z"
        fill="#8A5DFA"
      />
      <path
        d="M215.681 104.305L179.774 125.036L143.866 104.305L143.866 62.8421L179.774 42.1108L215.681 62.8421L215.681 104.305Z"
        fill="#8A5DFA"
      />
      <path
        d="M143.868 228.687L107.96 249.419L72.0527 228.687L72.0527 187.225L107.96 166.494L143.868 187.225L143.868 228.687Z"
        fill="#8A5DFA"
      />
      <path
        d="M287.5 228.687L251.592 249.419L215.685 228.687L215.685 187.225L251.592 166.494L287.5 187.225L287.5 228.687Z"
        fill="#8A5DFA"
      />
      <path
        d="M287.5 62.8398L251.592 83.5711L215.685 62.8398L215.685 21.3773L251.592 0.645995L287.5 21.3773L287.5 62.8398Z"
        fill="#8A5DFA"
      />
      <path
        d="M287.5 311.617L251.592 332.348L215.685 311.617L215.685 270.155L251.592 249.423L287.5 270.155L287.5 311.617Z"
        fill="#8A5DFA"
      />
      <path
        d="M72.0526 270.152L36.1449 290.884L0.237317 270.152L0.237311 228.69L36.145 207.958L72.0526 228.69L72.0526 270.152Z"
        fill="#8A5DFA"
      />
      <path
        d="M215.681 353.078L179.774 373.809L143.866 353.078L143.866 311.616L179.774 290.884L215.681 311.616L215.681 353.078Z"
        fill="#8A5DFA"
      />
    </svg>
  </div>
</div>
<!-- content -->
<div
  #content
  class="relative print:ml-0 sm:ml-[300px] bg-gray-50 pt-14 min-h-screen transition pb-12"
>
  <ng-content></ng-content>

  <footer
    class="absolute bottom-0 flex items-center justify-center w-full h-12 bg-primary-100"
  >
    <span class="block w-full text-xs text-center text-primary-400">
      Copyright©2020 Buri Technologies, Inc. All rights reserved</span
    >
  </footer>
</div>
<!-- </div> -->
