<div class="m-auto bg-white rounded modal-box">
  <div *ngIf="initialModal">
    <img class="w-full bg-gray-400 rounded-t object-cover"
      src="https://images.unsplash.com/photo-1459262838948-3e2de6c1ec80?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=800&amp;q=80"
      alt="" />

    <div class="py-4 text-center px-6">
      <section>
        <h3 class="font-bold text-2xl">Welcome to Cast Editor</h3>
        <p class="font-normal text-base">
          In a few short steps, your very own LMS will be cast into reality!
        </p>
        <br />
        <p class="font-normal text-base">First, what is your Cast called?</p>
      </section>

      <section class="mt-10">
        <form class="flex flex-col" method="POST" action="#">
          <div class="mb-6 pt-3 rounded bg-gray-200">
            <input type="text" id="castName" class="placeholder-gray-500" placeholder="Enter your Cast name here" class="
                bg-gray-200
                rounded
                w-full
                focus:outline-none
                border-b-4 border-gray-300
                focus:border-purple-600
                transition
                duration-500
                px-3
                pb-3
              " />
          </div>
          <button class="
              bg-purple-600
              hover:bg-purple-700
              text-white text-base
              font-bold
              py-2
              rounded
              shadow-lg
              hover:shadow-xl
              transition
              duration-200
            " (click)="clickNext()">
            NEXT
          </button>
        </form>
      </section>
    </div>
  </div>

  <div *ngIf="!initialModal">
    <div class="carousel relative shadow-2xl">
      <div class="carousel-inner relative overflow-hidden w-full">
        <div class="list-group-item mb-10">
          <ng-container *ngFor="let item of carouselItems">
            <input class="carousel-open" [(ngModel)]="radioSelected" type="radio" name="carousel" id="{{ item.name }}"
              aria-hidden="true" hidden="" value="{{ item.name }}" (change)="onItemChanged()"
              [checked]="item.checked" />

            <div class="carousel-item absolute opacity-0 h-auto">
              <img class="w-full bg-gray-400 rounded-t object-cover" src="{{ item.value }}" alt="" />
              <div class="py-4 text-center px-6 w-full">
              
                <div *ngIf="item.name === 'carousel-item1'" class="carousel-text font-normal text-base">
                  Your Cast is made up of blocks. You can add blocks with the

                  <span class="
                      inline-block
                      rounded
                      bg-blue-200
                      font-bold
                      text-blue-700 text-center
                      py-1
                      px-2
                      mx-1
                      whitespace-nowrap
                    ">
                    <app-icon icon="add_circle"></app-icon> ADD BLOCK
                  </span>
                  button.
                </div>
                <div *ngIf="item.name === 'carousel-item2'" class="carousel-text font-normal text-base">
                  Edit blocks and their contents with the

                  <app-icon class="rounded bg-gray-200 text-purple-400 p-1" icon="create"></app-icon>
                  button.
                </div>
                <div *ngIf="item.name === 'carousel-item3'" class="carousel-text font-normal text-base">
                  Finally, you can add things to your blocks with the

                  <app-icon class="
                      rounded
                      bg-purple-200
                      text-opacity-75 text-purple-700
                      p-1
                    " icon="add_circle"></app-icon>
                  button.
                </div>

                <section class="mt-10">
                  <button class="
                      w-full
                      bg-purple-600
                      hover:bg-purple-700
                      text-white text-base
                      font-bold
                      py-2
                      rounded
                      shadow-lg
                      hover:shadow-xl
                      transition
                      duration-200
                    " (click)="switchCarousel()">
                    {{ buttonlabel }}
                  </button>
                </section>
                
              </div>
            </div>
          </ng-container>
        </div>

        <ol class="carousel-indicators">
          <ng-container *ngFor="let item of carouselItems">
            <li class="inline-block mr-3">
              <label for="{{ item.name }}" class="
                  carousel-bullet
                  cursor-pointer
                  block
                  text-4xl text-purple-200
                  hover:text-purple-700
                ">•</label>
            </li>
          </ng-container>
        </ol>
      </div>
    </div>
  </div>
</div>