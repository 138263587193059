<span class="text-sm text-gray-500">
  {{ itemNumberFrom }} - {{ itemNumberTo || "---" }} of
  {{ listLength || "---" }}
</span>

<button
  class="btn btn-icon bg-transparent text-primary-dark"
  (click)="gotoFirstPage()"
>
  <app-icon icon="first_page"></app-icon>
</button>

<button
  class="btn btn-icon bg-transparent text-primary-dark"
  (click)="incrementPage(-1)"
>
  <app-icon icon="chevron_left"></app-icon>
</button>

<input
  type="text"
  class="w-10 rounded px-1 border"
  value="{{ page + 1 }}"
  (change)="onInputChange($event)"
/>

<button
  class="btn btn-icon bg-transparent text-primary-dark"
  (click)="incrementPage()"
>
  <app-icon icon="chevron_right"></app-icon>
</button>

<button
  class="btn btn-icon bg-transparent text-primary-dark"
  (click)="gotoLastPage()"
>
  <app-icon icon="last_page"></app-icon>
</button>
