<form [formGroup]="formGroup" (ngSubmit)="onSave()">
  <h2 class="text-center font-bold text-2xl mb-4">HTML</h2>
  <textarea
    class="form-control h-[627px] overflow-y-scroll"
    formControlName="markdownText"
  ></textarea>
  <div class="flex justify-end mt-4">
    <button class="btn btn-primary text-white bg-primary-500" type="submit">
      save
    </button>
  </div>
</form>
