<div class="flex">
  <mat-drawer-container autosize="true">
    <mat-drawer #drawer mode="side" class="w-[300px]">
      <section class="bg-white overflow-y-scroll h-screen">
        <app-course-set-list
          [resources]="courseSetResources"
          [cdkDropListConnectedTo]="constructDropListConnectedTo()"
        ></app-course-set-list>
      </section>
    </mat-drawer>
  </mat-drawer-container>

  <section class="flex-1 overflow-y-scroll h-screen">
    <app-back-title-layout
      layoutTitle="Course Editor"
      titleSupportLink="https://support.castlms.com/help/what-is-the-course-content-editor"
      [loading]="!sectionItems || loading"
    >
      <div backTitleLayoutBeforeMainNoMargin>
        <div
          class="mx-4 flex flex-row-reverse justify-between items-center my-2"
        >
          <div class="flex items-center">
            <a
              [routerLink]="['/resources', 'edit']"
              [queryParams]="{ resource_uuid: resourceUuid }"
              class="btn btn-icon bg-transparent text-gray-600 mx-2"
            >
              <app-icon icon="settings"></app-icon>
            </a>

            <a
              [routerLink]="['/resources', 'publish']"
              [queryParams]="{ resource_uuid: resourceUuid }"
              class="btn btn-primary"
            >
              Publish
            </a>
          </div>

          <button
            type="button"
            *ngIf="isCourseSetPresent"
            (click)="drawer.toggle()"
            class="btn bg-secondary-500 text-white"
          >
            <app-icon icon="lightbulb_outline"></app-icon>
            Course Set
          </button>
        </div>

        <div class="my-5 flex justify-between">
          <div class="flex items-center bg-primary-600 text-white p-5 w-full">
            <img
              [src]="course?.coverUri"
              class="object-contain w-28 h-28 bg-gray-300 mx-5"
              alt=""
            />

            <div>
              <app-icon class="text-2xl" icon="school"></app-icon>

              <div class="leading-none font-bold text-2xl">
                {{ course?.title }}
              </div>

              <!-- <div class="mt-4 text-sm">
                <span class="font-bold">
                  {{ resourceCount }}
                </span>
                resources
              </div> -->

              <div *ngIf="isWithCertification" class="text-sm mt-4">
                <span class="font-bold">
                  <app-icon icon="check_box"></app-icon>
                  with certification
                </span>
                resources
              </div>

              <div class="text-sm">
                <span class="font-bold">
                  <app-icon icon="edit"></app-icon>

                  {{ resourceCertificationCount }}
                </span>
                resource(s) required for certification
              </div>
            </div>
          </div>
        </div>
        <div class="font-bold px-4" *ngIf="canAddForum && forumPresent">
          <app-checkbox
            class="text-primary-600"
            [(ngModel)]="enableForum"
            (ngModelChange)="setForum()"
          />
          Add Forum
        </div>
      </div>

      <div cdkDropList (cdkDropListDropped)="sectionDrop($event)">
        <div cdkDropListGroup>
          <button
            *ngIf="sectionItems?.length === 0"
            (click)="addSection(0)"
            class="text-center w-full text-gray-300 hover:text-gray-400 py-20"
          >
            <app-icon icon="add_circle_outline" class="text-9xl"></app-icon>
            <div class="text-xl">Your Course is currently empty.</div>
            <div class="text-xl">Click to add a section.</div>
          </button>

          <ng-container *ngFor="let sectionItem of sectionItems; let i = index">
            <div class="group">
              <button
                (click)="addSection(i - 1)"
                class="block opacity-0 group-focus-within:opacity-100 group-hover:opacity-100 transition-opacity w-full bg-blue-50 text-blue-500 hover:bg-blue-200 hover:text-blue-600 text-center"
              >
                <app-icon icon="add_circle" class="mx-2"></app-icon>
                <span class="font-semibold text-sm">ADD SECTION</span>
              </button>

              <div
                class="border-2 border-transparent group-hover:border-secondary-500"
                cdkDrag
                (cdkDragStarted)="onDragStart()"
                (cdkDragEnded)="onDragEnd()"
              >
                <div class="flex items-center my-2">
                  <button cdkDragHandle>
                    <app-icon
                      icon="unfold_more"
                      class="text-gray-600 text-2xl"
                    ></app-icon>
                  </button>

                  <input
                    type="text"
                    class="font-semibold mx-2 text-2xl border-b-2 border-dashed border-primary flex-grow"
                    [(ngModel)]="sectionItem.sectionName"
                    (blur)="updateSection(sectionItem)"
                    (keydown.enter)="updateSection(sectionItem)"
                  />

                  <app-dropdown-layout
                    position="right"
                    toggleClass="btn btn-icon bg-transparent text-2xl text-gray-500"
                  >
                    <div dropdownToggle>
                      <app-icon icon="more_vert"> </app-icon>
                    </div>
                    <div dropdownContent>
                      <!-- <button
                        class="w-full py-1 px-2 text-left text-primary hover:bg-primary hover:text-white"
                      >
                        Section order
                      </button> -->
                      <button
                        (click)="deleteSection(sectionItem, i)"
                        class="w-full py-1 px-2 text-left text-primary hover:bg-primary hover:text-white"
                      >
                        Delete section
                      </button>
                    </div>
                  </app-dropdown-layout>
                </div>

                <app-course-content-edit-block-list
                  [blockListIndex]="i"
                  [sectionItem]="sectionItem"
                  (addBlock)="addBlock(sectionItem.sectionUuid, $event)"
                  (deleteBlock)="deleteBlock($event, sectionItem)"
                  (blockDrop)="onBlockDrop($event)"
                >
                </app-course-content-edit-block-list>
              </div>

              <button
                (click)="addSection(i)"
                class="block opacity-0 group-focus-within:opacity-100 group-hover:opacity-100 transition-opacity w-full bg-blue-50 text-blue-500 hover:bg-blue-200 hover:text-blue-600 text-center"
              >
                <app-icon icon="add_circle" class="mx-2"></app-icon>
                <span class="font-semibold text-sm">ADD SECTION </span>
              </button>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="my-20" *ngIf="publishResourceOnNext">
        <a
          class="block btn btn-primary w-full"
          [routerLink]="['/resources', 'publish']"
          [queryParams]="{
            resource_uuid: resourceUuid,
            done_history_length: 2
          }"
          >NEXT</a
        >
      </div>
    </app-back-title-layout>
  </section>
</div>
