<app-back-title-layout [layoutTitle]="title">
  <a
    [routerLink]="['../edit-members', 'index']"
    queryParamsHandling="merge"
    class="
      my-5
      block
      p-1
      text-center
      bg-primary-light
      text-white
      rounded
      uppercase
      text-xs
    "
    >Edit members list</a
  >

  <div class="my-2">
    <app-search-bar-query placeholder="Search"></app-search-bar-query>
  </div>

  <div class="my-2 flex justify-end">
    <app-pagination-query
      [pageSize]="pageSize"
      [listLength]="userCount"
    ></app-pagination-query>
  </div>

  <app-item-list
    [itemTemplate]="itemTemplate"
    [source]="userObservable"
  ></app-item-list>

  <button
    (click)="exportUsers()"
    class="btn bg-white w-full my-5 font-bold text-gray-600"
  >
    Export
  </button>
</app-back-title-layout>

<ng-template #itemTemplate let-item="item">
  <div class="flex items-center">
    <app-icon icon="person" class="text-gray-600 text-4xl mr-3"></app-icon>

    <div class>
      <div class="truncate font-semibold leading-tight">
        {{ item.firstName }} {{ item.lastName }}
      </div>

      <div class="text-sm text-gray-600">{{ item.email }}</div>
    </div>
  </div>
</ng-template>
