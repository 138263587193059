import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { ColorHelperService } from "src/app/services/color-helper.service";
import { CastColor, Resource, V2Block } from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-casteditor-card",
  templateUrl: "./casteditor-card.component.html",
  styleUrls: ["./casteditor-card.component.scss"],
})
export class CasteditorCardComponent implements OnInit {
  constructor(private colorHelperService: ColorHelperService) {}

  @Input() resource: Resource;
  @Input() isInEditMode;

  @Input() cardHeaderBackgroundColor;
  @Input() cardHeaderForegroundColor;

  @Output()
  deleteResource: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {}

  onDeleteResource() {
    this.deleteResource.emit();
  }

  transformCastColor(castColor: CastColor) {
    return this.colorHelperService.constructRGBAFromCastColor(castColor);
  }
}
