<div class="d-flex flex-column align-items-center" *ngIf="resource">
  <small
    class="text-truncate text-primary w-50"
    [class.invisible]="!formGroup.value.content.uri"
  >
    {{ formGroup.value.content.uri }}
  </small>
  <a
    [href]="formGroup.value.content.uri"
    target="_blank"
    class="btn btn-primary btn-sm"
    [class.disabled]="!formGroup.value.content.uri"
  >
    Preview
  </a>
</div>
