<div class="mb-5 flex flex-col items-center">
  <app-icon icon="assignment" class="text-primary text-6xl"></app-icon>

  <ng-container *ngIf="resource.uuid">
    <button
      class="btn btn-primary w-full py-0"
      *ngIf="resource.subtype !== 'tally'"
      [routerLink]="[
        '/resource-management',
        'misc',
        'assessment-activity-edit'
      ]"
      [attr.data-tooltip]="
        !canRead
          ? 'You are not allowed to access this assessment. Contact your admin to gain access'
          : ''
      "
      [queryParams]="{
        resource_uuid: resource.uuid,
        exam_uuid: resource.content.examDetails?.examUuid
      }"
      [disabled]="!canRead || formGroup?.disabled"
    >
      EDIT ASSESSMENT
    </button>

    <button
      class="bg-purple-500 text-white w-full rounded"
      *ngIf="resource.subtype === 'tally'"
      [routerLink]="[
        '/resource-management',
        'misc',
        'assessment-tally-activity-edit'
      ]"
      [queryParams]="{
        exam_uuid: resource.content.examDetails?.examUuid
      }"
      [disabled]="!canRead || formGroup?.disabled"
    >
      EDIT QUESTIONS
    </button>
  </ng-container>
</div>
