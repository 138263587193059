<app-back-title-layout layoutTitle="View Attempt" [loading]="loading">
  <app-item-list
    [items]="attempts"
    [itemTemplate]="itemTemplate"
    emptyListMessage="Data not yet available"
  >
  </app-item-list>
</app-back-title-layout>

<ng-template #itemTemplate let-attempt="item" let-index="index"
  ><div class="bg-gray-200 rounded p-5 mb-3">
    <div class="text-sm text-gray-700">
      Question {{ index + 1 }} ({{ attempt.itemType | activityType }})
    </div>
    <div class="mt-2">{{ attempt.itemQuestion }}</div>

    <ng-container *ngIf="attempt.itemType == 'MC' || attempt.itemType == 'IC'">
      <ul>
        <li
          *ngFor="let itemChoice of attempt.itemChoices; let j = index"
          class="flex items-center bg-gray-400 rounded-sm my-1 p-2"
        >
          <span class="mr-2 text-2xl text-primary">
            <app-radio
              disabled="disabled"
              name="{{ attempt.itemUuid }}{{ j }}"
              [checked]="itemChoice.isSelected"
            >
            </app-radio>
          </span>

          <img
            *ngIf="itemChoice.image"
            [src]="itemChoice.image"
            class="mx-2 h-40 w-40 object-contain"
          />

          <span class="ml-2">
            {{ itemChoice.text }}
          </span>
          <!-- <label *ngIf="itemType == 'MC'; else image" for="{{choice.uuid}}" class="text-sm"> {{choice.shortInput}}</label><br>

            <ng-template #image>
                <img src="{{choice.mediaUrl}}">
            </ng-template> -->
        </li>
      </ul>
    </ng-container>

    <ng-container *ngIf="attempt.itemType == 'PO'">
      <ul>
        <li
          *ngFor="let itemChoice of attempt.itemChoices; let j = index"
          class="flex items-center bg-gray-400 rounded-sm my-1 p-2"
        >
          <span class="mr-2 text-2xl text-primary">
            <app-radio
              disabled="disabled"
              name="{{ attempt.itemUuid }}{{ j }}"
              [checked]="itemChoice.isSelected"
            >
            </app-radio>
          </span>

          <img
            *ngIf="itemChoice.image"
            [src]="itemChoice.image"
            class="mx-2 h-40 w-40 object-contain"
          />

          <span class="ml-2">
            {{ itemChoice.text }}
          </span>
          <!-- <label *ngIf="itemType == 'MC'; else image" for="{{choice.uuid}}" class="text-sm"> {{choice.shortInput}}</label><br>

            <ng-template #image>
                <img src="{{choice.mediaUrl}}">
            </ng-template> -->
        </li>
      </ul>
    </ng-container>

    <ng-container *ngIf="attempt.itemType == 'CB'">
      <ul>
        <li
          *ngFor="let itemChoice of attempt.itemChoices; let j = index"
          class="flex items-center bg-gray-400 rounded-sm my-1 p-2"
        >
          <span class="mr-2 text-2xl text-primary">
            <app-checkbox
              disabled="disabled"
              name="{{ attempt.itemUuid }}{{ j }}"
              [checked]="itemChoice.isSelected"
            >
            </app-checkbox>
          </span>

          <img
            *ngIf="itemChoice.image"
            [src]="itemChoice.image"
            class="mx-2 h-40 w-40 object-contain"
          />

          <span class="ml-2">
            {{ itemChoice.text }}
          </span>
          <!-- <label *ngIf="itemType == 'MC'; else image" for="{{choice.uuid}}" class="text-sm"> {{choice.shortInput}}</label><br>

            <ng-template #image>
                <img src="{{choice.mediaUrl}}">
            </ng-template> -->
        </li>
      </ul>
    </ng-container>

    <ng-container
      *ngIf="
        attempt.itemType == 'LA' ||
        attempt.itemType == 'SA' ||
        attempt.itemType == 'FW'
      "
    >
      <div class="text-sm pt-5">
        {{ attempt.responseTextAnswer }}
      </div>
    </ng-container>

    <ng-container *ngIf="attempt.itemType == 'ME'">
      <div class="text-sm text-blue-500 truncate pt-5">
        <a [href]="attempt.responseMediaUrl" target="_blank">
          {{ attempt.responseMediaUrl }}
        </a>
      </div>

      <div class="text-sm pt-5">
        {{ attempt.responseTextAnswer }}
      </div>
    </ng-container>
  </div>

  <div *ngIf="attempt.responseUuid; else noAnswer">
    <div
      *ngIf="
        attempt.itemType == 'MC' ||
        attempt.itemType == 'IC' ||
        attempt.itemType == 'CB' ||
        attempt.itemType == 'SA'
      "
    >
      <div
        class="bg-green-200 text-green-700 rounded h-8 text-center leading-8 mb-3"
        *ngIf="attempt.responseIsCorrect"
      >
        Answer is correct
      </div>
      <div
        class="bg-red-200 text-red-700 rounded h-8 text-center leading-8 mb-3"
        *ngIf="attempt.responseIsCorrect === false"
      >
        Answer is incorrect.
      </div>
      <div
        class="bg-gray-200 text-gray-700 rounded h-8 text-center leading-8 mb-3"
        *ngIf="attempt.responseIsCorrect === null"
      >
        Pending final answer
      </div>
    </div>

    <div
      *ngIf="
        attempt.itemType == 'LA' ||
        attempt.itemType == 'FW' ||
        attempt.itemType == 'ME'
      "
    >
      <div
        *ngIf="!attempt.responseScore"
        class="bg-purple-200 text-purple-700 rounded h-8 text-center leading-8 mb-3"
      >
        Please check the answer
      </div>

      <div
        *ngIf="attempt.responseScore"
        class="bg-purple-200 text-purple-700 rounded h-8 text-center leading-8 mb-3"
      >
        Answer is checked
      </div>
    </div>

    <div class="mb-3 text-sm">
      Points
      <button
        *ngIf="attempt.itemType == 'ME'"
        (click)="updateResponseScoreViaRubric(attempt)"
        class="border-b-2 border-primary-dark"
      >
        {{ attempt.responseScore || "No score yet" }}
      </button>

      <button
        *ngIf="attempt.itemType !== 'ME'"
        (click)="updateResponseScore(attempt)"
        class="border-b-2 border-primary-dark"
      >
        {{ attempt.responseScore || "No score yet" }}
      </button>
    </div>
  </div>
</ng-template>

<ng-template #noAnswer>
  <div class="bg-red-200 text-red-700 rounded h-8 text-center leading-8 mb-3">
    Examinee did not answer this question.
  </div>

  <div class="mb-3 text-sm">
    Points
    <button class="border-b-2 border-primary-dark">0.00</button>
  </div>
</ng-template>

<ng-template #incorrect>
  <div class="bg-red-200 text-red-700 rounded h-8 text-center leading-8 mb-3">
    Answer is incorrect.
  </div>
</ng-template>

<ng-template #textAnswerResult>
  <div
    class="bg-purple-200 text-purple-700 rounded h-8 text-center leading-8 mb-3"
  >
    Please check the answer
  </div>
</ng-template>
