import { Component, OnInit } from "@angular/core";

export interface SlidesBlockItem {
  type: string;
  value: string;
  text: string;
  icon: string;
  disabled?: boolean;
  defaultProperty?: any;
  index?: number;
}

export const QUESTIONS = [
  {
    type: "question",
    value: "MC",
    text: "Multiple Choice",
    icon: "radio_button_checked",
  },
  { type: "question", value: "CB", text: "Checkbox", icon: "check_box" },
  { type: "question", value: "LA", text: "Text", icon: "notes" },
  {
    type: "question",
    value: "SA",
    text: "Fill in the blanks",
    icon: "text_format",
  },
  { type: "question", value: "FW", text: "Freedom Wall", icon: "chat_bubble" },
  { type: "question", value: "PO", text: "Poll", icon: "format_list_numbered" },
  // { type: "question", value: "TA", text: "Tally", icon: "filter_1" },
  { type: "question", value: "SU", text: "Survey", icon: "star_half" },
  {
    type: "resource",
    value: "activity-group",
    text: "Group of questions",
    icon: "apps",
  },
];
export const RESOURCES = [
  { type: "resource", value: "slide", text: "Image", icon: "image" },
  { type: "resource", value: "pdf", text: "PDF", icon: "picture_as_pdf" },
  { type: "resource", value: "video", text: "Video", icon: "play_arrow" },
  { type: "resource", value: "link", text: "Link", icon: "link" },
  { type: "resource", value: "html", text: "HTML", icon: "html" },
  {
    type: "resource",
    value: "markdown",
    text: "Markdown",
    icon: "text_fields",
  },
  {
    type: "resource",
    value: "carousel",
    text: "Carousel",
    icon: "view_carousel",
  },
  {
    type: "resource",
    value: "download-link",
    text: "Download Link",
    icon: "download",
  },
  // {
  //   type: "resource",
  //   value: "DECISION_POINT",
  //   text: "Decision Point",
  //   icon: "call_split",
  // },
];

@Component({
  selector: "app-burislides-modal",
  templateUrl: "./burislides-modal.component.html",
  styles: [],
})
export class BurislidesModalComponent implements OnInit {
  questions;
  resources;
  constructor() {}
  ngOnInit(): void {
    this.questions = QUESTIONS;
    this.resources = RESOURCES;
  }
}
