import { AbstractControl } from "@angular/forms";

/**
 * Requires a form array of form groups have the following form control fields:
 * - weight
 * - isDeleted
 */
export const criteriaWeightSum = () => {
  return (c: AbstractControl): { [key: string]: any } => {
    if (!c.value?.length) {
      return null;
    }

    let total = c.value
      .filter((item) => !item.isDeleted)
      .reduce((sum, item) => {
        return sum + item.weight || 0;
      }, 0);

    if (total !== 100) {
      return { custom: "Total should total 100" };
    } else {
      return null;
    }
  };
};
