import { Component, OnInit } from "@angular/core";
import { map, Subscription } from "rxjs";
import { fadeInOut } from "src/app/app-common-module/animations/fade";
import { MailerService } from "src/app/services/mailer.service";

@Component({
  selector: "app-mailer-templates",
  templateUrl: "./mailer-templates.component.html",
  styles: [],
  animations: [fadeInOut],
})
export class MailerTemplatesComponent implements OnInit {
  querySub: Subscription;
  templates: any[];
  loading: boolean = false;
  constructor(private mailerService: MailerService) {}

  ngOnInit(): void {
    this.loading = true;
    this.querySub = this.mailerService
      .getEmailTemplates(0, 0)
      .pipe(
        map((res) => {
          return res.data.emailTemplates.data;
        })
      )
      .subscribe((result) => {
        this.loading = false;
        this.templates = result;
      });
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.querySub?.unsubscribe();
  }
}
