<div class="w-full overflow-auto">
  <h1 class="font-bold text-xl mb-8">{{ title }}</h1>
  <div class="block w-full sm:flex mb-6">
    <app-search-bar-query
      placeholder="Search"
      class="w-3/4"
    ></app-search-bar-query>
  </div>
  <div class="mb-5">
    <app-item-select-with-header
      [headers]="headers"
      [totalCount]="totalUsers"
      [key]="'uuid'"
      [category]="'user'"
      [source]="usersObservable"
      [itemTemplate]="itemTemplate"
      [limit]="limit"
      [enableSort]="false"
      [disableCheckbox]="true"
      [loading]="loading"
    >
    </app-item-select-with-header>
  </div>
</div>

<ng-template #itemTemplate let-user="item">
  <div class="flex">
    <div class="block sm:flex mt-2 sm:mt-0">
      <a
        class="block w-full sm:flex ml-2 sm:ml-0"
        [routerLink]="['profile']"
        [queryParams]="{
          user_uuid: user.uuid
        }"
      >
        <ng-container *ngIf="headers">
          <div
            class="grid grid-cols-1 my-1 w-full sm:flex"
            *ngFor="let header of headers"
          >
            <!-- Email -->
            <div class="block sm:w-96 sm:flex" *ngIf="header.name === 'Email'">
              <div class="sm:ml-2 mb-2 sm:mb-0">
                <app-icon
                  icon="person"
                  class="text-white text-2xl leading-none px-1 mr-1 bg-gray-200 rounded-full items-center justify-center"
                ></app-icon>
              </div>
              <div
                class="text-sm text-gray-700 sm:items-center flex-wrap truncate sm:w-full"
              >
                {{ user.email }}
                <span
                  *ngIf="!user.confirmedAt"
                  class="text-sm text-red-500 italic"
                >
                  <app-icon icon="error" class="align-bottom"></app-icon>
                  <!-- <span> email not yet confirmed </span> -->
                </span>
              </div>
            </div>
            <!-- Name -->
            <div
              class="truncate text-sm sm:items-center sm:w-96"
              *ngIf="header.name === 'Name'"
            >
              {{ user.name }}
            </div>
            <!-- Date Added -->
            <div
              class="text-sm text-disabled-dark sm:items-center truncate sm:w-56"
              *ngIf="header.name === 'Date Added'"
            >
              <ng-container>
                {{ user.createdAt | date }}
              </ng-container>
            </div>
          </div>
        </ng-container>
      </a>
    </div>
  </div>
</ng-template>
