import { Component, EventEmitter, OnInit, Output, Input } from "@angular/core";

@Component({
  selector: "app-burislides-block-link",
  templateUrl: "./burislides-block-link.component.html",
  styles: [],
})
export class BurislidesBlockLinkComponent implements OnInit {
  @Output() link: EventEmitter<string> = new EventEmitter<string>();
  @Input() inputLink: string = "";
  constructor() {}
  ngOnInit(): void {}
  onSave() {
    this.link.emit(this.inputLink);
  }
}
