import { Component, OnInit, Input, Renderer2, ElementRef } from "@angular/core";
import { fromEvent, merge, Subject, Subscription } from "rxjs";
import { filter, map, takeUntil } from "rxjs/operators";
import { fadeInOut } from "../../animations/fade";

@Component({
  selector: "app-dropdown-layout",
  templateUrl: "./dropdown-layout.component.html",
  styleUrls: ["./dropdown-layout.component.scss"],
  animations: [fadeInOut],
})
export class DropdownLayoutComponent implements OnInit {
  @Input()
  text: string;

  @Input()
  icon: string;

  @Input()
  toggleClass = "";

  @Input()
  toggleBodyClass = "";

  @Input()
  position = "right";

  @Input()
  width: string = "w-56";
  isOpen = false;

  private subscription: Subscription;

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {}

  ngOnDestoy() {
    this.unlistenFromEvents();
  }

  open(event?) {
    event?.stopPropagation();
    event?.preventDefault();

    this.isOpen = true;

    this.listenToEvents();
  }

  close(event?) {
    event?.stopPropagation();
    event?.preventDefault();

    this.isOpen = false;

    this.unlistenFromEvents();
  }

  toggle(event) {
    if (this.isOpen) {
      this.close(event);
    } else {
      this.open(event);
    }
  }

  listenToEvents() {
    this.subscription = merge(
      fromEvent(window, "click").pipe(
        filter((e: Event) => !this.elementRef.nativeElement.contains(e.target))
      ),
      fromEvent(document, "keydown").pipe(
        filter((e: KeyboardEvent) => e.code == "Escape")
      )
    ).subscribe((result) => {
      this.close();
    });
  }

  unlistenFromEvents() {
    this.subscription?.unsubscribe();
  }
}
