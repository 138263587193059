import { Injectable } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { merge, Subject } from "rxjs";
import { filter, switchMap, map, takeUntil, tap } from "rxjs/operators";
import { PassService, Permission } from "src/app/sinigangnababoywithgabi";
import { DashboardService } from "./dashboard.service";

// DEPRECATED
@Injectable({
  providedIn: "root",
})
export class PermissionHelperService {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private passService: PassService,
    private dashboardService: DashboardService
  ) {}

  createPermissionToggleFormGroup(options: {
    roleId: string;
    permissionName: string;
    permissionDescription?: string;

    permissionId?: string;
    unsubscribe: Subject<any>;
  }) {
    let formGroup = this.formBuilder.group({
      roleId: [options.roleId],
      permissionName: [options.permissionName],
      permissionDescription: [options.permissionDescription],
      permissionId: [options.permissionId],
      isActive: [!!options.permissionId],
    });

    // Listen on isActive change, disabling the form
    let isActiveObservable = formGroup.get("isActive").valueChanges.pipe(
      tap(() => {
        formGroup.disable({
          emitEvent: false,
        });
      })
    );

    //Set up if value is true
    let isActiveTrueObservable = isActiveObservable.pipe(
      filter((value) => value),
      switchMap(() =>
        this.passService.createPermission({
          permission: {
            roleIds: [formGroup.value.roleId],
            name: formGroup.value.permissionName,
          },
        })
      ),
      tap((result) => {
        formGroup.patchValue(
          {
            permissionId: result.id,
          },
          {
            emitEvent: false,
          }
        );
      })
    );

    //Set up if value is false
    let isActiveFalseObservable = isActiveObservable.pipe(
      filter((value) => !value),
      switchMap(() =>
        this.passService.deletePermission(formGroup.value.permissionId)
      ),
      tap(() => {
        formGroup.patchValue(
          {
            permissionId: null,
          },
          {
            emitEvent: false,
          }
        );
      })
    );

    // Subscribe to both events, enabling form after ech event is done
    merge(isActiveTrueObservable, isActiveFalseObservable)
      .pipe(
        takeUntil(options.unsubscribe),
        tap(() => {
          formGroup.enable({
            emitEvent: false,
          });
        })
      )
      .subscribe();

    return formGroup;
  }

  createRoleToggleFormGroup(options: {
    groupId?: string;
    roleId?: string;
    roleName: string;
    roleDescription?: string;
    permissionAttributes: {
      name: string;
      description?: string;
    }[];

    existingPermissionList?: Permission[];

    unsubscribe: Subject<any>;
  }) {
    let permissionNameIdMap: {
      [key: string]: string;
    } = {};

    options.existingPermissionList?.forEach((permission) => {
      permissionNameIdMap[permission.name] = permission.id;
    });

    let formGroup = this.formBuilder.group({
      roleId: [options.roleId],
      roleName: [options.roleName],
      roleDescription: [options.roleDescription],
      isActive: [options.roleId ? true : false],
      permissionFormGroups: this.formBuilder.array(
        options.permissionAttributes.map((pa) => {
          return this.createPermissionToggleFormGroup({
            roleId: options.roleId,
            permissionName: pa.name,
            permissionId: permissionNameIdMap[pa.name],
            permissionDescription: pa.description,
            unsubscribe: options.unsubscribe,
          });
        })
      ),
    });

    // Listen on isActive change, disabling the form
    let isActiveObservable = formGroup.get("isActive").valueChanges.pipe(
      tap(() => {
        formGroup.disable({
          emitEvent: false,
        });
      })
    );

    //Set up if value is true
    let isActiveTrueObservable = isActiveObservable.pipe(
      filter((value) => value),
      switchMap(() => this.dashboardService.getCast()),
      map((cast) => {
        // HARDCODE: until https://buri.dev/T549 is solved
        cast.data.attributes.auth;
        return localStorage.getItem("application_id");
      }),
      switchMap((applicationId) =>
        this.passService.createRole({
          role: {
            name: options.roleName,
            applicationId: applicationId,
            permissionsAttributes: options.permissionAttributes,
            groupIds: options.groupId ? [options.groupId] : null,
          },
        })
      ),
      tap((result) => {
        formGroup.setControl(
          "permissionFormGroups",
          this.formBuilder.array(
            options.permissionAttributes.map((pa, i) => {
              return this.createPermissionToggleFormGroup({
                roleId: options.roleId,
                permissionName: pa.name,
                permissionId: result["permissionIds"][i],
                permissionDescription: pa.description,
                unsubscribe: options.unsubscribe,
              });
            })
          )
        );
      }),
      tap((result) => {
        formGroup.patchValue(
          {
            roleId: result.id,
          },
          {
            emitEvent: false,
          }
        );
      })
    );

    //Set up if value is false
    let isActiveFalseObservable = isActiveObservable.pipe(
      filter((value) => !value),
      switchMap((applicationId) =>
        this.passService.deleteRole(formGroup.value.roleId)
      ),
      tap(() => {
        formGroup.patchValue(
          {
            roleId: null,
          },
          {
            emitEvent: false,
          }
        );
      })
    );

    //Listen to

    formGroup
      .get("isActive")
      .valueChanges.pipe(
        takeUntil(options.unsubscribe),
        switchMap((isActive) => {
          return this.dashboardService.getCast().pipe(
            map((cast) => {
              // HARDCODE: until https://buri.dev/T549 is solved
              cast.data.attributes.auth;
              return [localStorage.getItem("application_id"), isActive];
            })
          );
        }),
        switchMap(([applicationId, isActive]) => {
          formGroup.disable({
            emitEvent: false,
          });

          if (isActive) {
            return this.passService
              .createRole({
                role: {
                  name: options.roleName,
                  applicationId: applicationId,
                  permissionsAttributes: options.permissionAttributes,
                  groupIds: options.groupId ? [options.groupId] : null,
                },
              })
              .pipe(
                map((result) => {
                  formGroup.setControl(
                    "permissionFormGroups",
                    this.formBuilder.array(
                      options.permissionAttributes.map((pa, i) => {
                        return this.createPermissionToggleFormGroup({
                          roleId: options.roleId,
                          permissionName: pa.name,
                          permissionId: result["permissionIds"][i],
                          permissionDescription: pa.description,
                          unsubscribe: options.unsubscribe,
                        });
                      })
                    )
                  );

                  //There is a bug in emitEvent. The change event still fires
                  // let formArray = formGroup.get(
                  //   "permissionFormGroups"
                  // ) as FormArray;

                  // formArray.controls.forEach(
                  //   (control: FormGroup, i: number) => {
                  //     control.patchValue(
                  //       {
                  //         roleId: result["id"],
                  //         permissionId: result["permissionIds"][i],
                  //         // isActive: true,
                  //       },s
                  //       {
                  //         emitEvent: false,
                  //         onlySelf: true,
                  //       }
                  //     );
                  //   }
                  // );

                  return result["id"];
                })
              );
          } else {
            return this.passService
              .deleteRole(formGroup.value.roleId)
              .pipe(map((result) => null));
          }
        }),
        map((roleId) => {
          formGroup.patchValue(
            {
              roleId,
            },
            {
              emitEvent: false,
            }
          );

          formGroup.enable({
            emitEvent: false,
          });

          return null;
        })
      )
      .subscribe();

    return formGroup;
  }
}
