import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  catchError,
  Observable,
  Subscription,
  switchMap,
  throwError,
} from "rxjs";
import { fadeInOut } from "../../animations/fade";
import {
  Action,
  Header,
} from "../list-table-header/list-table-header.component";

@Component({
  selector: "app-item-select-with-header",
  templateUrl: "./item-select-with-header.component.html",
  styleUrls: ["./item-select-with-header.component.scss"],
  animations: [fadeInOut],
})
export class ItemSelectWithHeaderComponent implements OnInit {
  @ViewChild("top") topScroll: ElementRef;
  @ViewChild("bottom") bottomScroll: ElementRef;
  @Input() disablePagination: boolean = false;
  @Input() disableCheckbox: boolean = false;
  @Input() enableSort: boolean = false;
  @Input()
  items: any[];

  @Input()
  key: string = "uuid";

  @Input()
  itemTemplate: TemplateRef<any>;

  @Input()
  emptyListMessage = "Nothing here.";

  @Input()
  source: Observable<any[]>;

  @Input()
  headers: Header[] = [];

  @Input()
  category: string = "";

  @Input()
  batchActions: Action[] = [];

  @Input()
  limit: number = 20;

  @Input()
  totalCount: number = 0;

  @Input()
  checkBoxAlignment: "start" | "center" | "end" = "center";

  @Input()
  loading: boolean = false;

  @Output()
  selectedItemList = new EventEmitter<any>();
  selected: any[] = [];
  page: number;
  width: number = 0;
  sourceSub: Subscription;
  errorMessage: string;
  hideCheckbox: string = "";
  currentPageNumber: number;
  selectedCurrentPage: Array<string[]> = [[]];
  navigationEvent: Subscription;
  prevQ: string = "";
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (this.disableCheckbox) {
      this.hideCheckbox = "hidden";
    }
    if (!this.items) {
      this.refreshList(false);
    }
  }

  ngAfterViewChecked(): void {
    this.width = this.bottomScroll.nativeElement.scrollWidth;
    this.cd.detectChanges();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.source) {
      this.refreshList(false);
    }
  }
  toggleAll(event) {
    this.selectedCurrentPage[this.currentPageNumber] = [];
    if (event) {
      this.items.map((item) => {
        if (
          this.selected.includes(item[this.key]) ||
          this.selectedCurrentPage[this.currentPageNumber].includes(
            item[this.key]
          )
        )
          return;
        this.selectedCurrentPage[this.currentPageNumber].push(item[this.key]);
        this.selected.push(item[this.key]);
        item.selected = true;
      });
    } else {
      this.items.map((item) => {
        let index = this.selected.indexOf(item[this.key]);
        if (index > -1) {
          this.selected.splice(index, 1);
        }
        item.selected = false;
      });
    }
    this.selected = [...this.selected];
    this.selectedCurrentPage[this.currentPageNumber] = [
      ...this.selectedCurrentPage[this.currentPageNumber],
    ];
    // this.selectedCurrentPage[this.currentPageNumber] = [...this.selected]
    this.selectedItemList.emit(this.selected);
  }

  updateSelectedItem(value, selected) {
    if (
      selected.target.checked &&
      this.currentPageNumber > -1 &&
      !this.selected.includes(value[this.key])
    ) {
      this.selected.push(value[this.key]);

      if (this.selectedCurrentPage[this.currentPageNumber]) {
        this.selectedCurrentPage[this.currentPageNumber].push(value[this.key]);
      } else {
        this.selectedCurrentPage[this.currentPageNumber] = [];
        this.selectedCurrentPage[this.currentPageNumber].push(value[this.key]);
      }
    } else if (
      this.selected.includes(value[this.key]) &&
      !selected.target.checked &&
      this.currentPageNumber > -1
    ) {
      let index = this.selected.indexOf(value[this.key]);
      let indexCurrentPage = this.selectedCurrentPage.indexOf(value[this.key]);
      if (index > -1 || indexCurrentPage > -1) {
        this.selected.splice(index, 1);
        this.selectedCurrentPage[this.currentPageNumber].splice(
          indexCurrentPage,
          1
        );
      }
    }
    // change reference of selected array for input change detection
    this.selected = [...this.selected];
    this.selectedCurrentPage[this.currentPageNumber] = [
      ...this.selectedCurrentPage[this.currentPageNumber],
    ];
    this.selectedItemList.emit(this.selected);
  }
  onTopScroll(event) {
    this.bottomScroll.nativeElement.scrollLeft =
      this.topScroll.nativeElement.scrollLeft;
  }
  onBottomScroll(event) {
    this.topScroll.nativeElement.scrollLeft =
      this.bottomScroll.nativeElement.scrollLeft;
  }
  currentPage(page) {
    this.page = page;
  }
  onResize(event) {
    this.width = this.bottomScroll.nativeElement.scrollWidth;
  }
  refreshList(action: boolean) {
    this.errorMessage = null;

    if (this.source) {
      this.sourceSub?.unsubscribe();

      this.sourceSub = this.source
        .pipe(
          switchMap((result) => {
            this.items = result;
            return this.route.queryParams;
          }),
          catchError((err) => {
            this.errorMessage = err.message;

            return throwError(() => err);
          })
        )
        .subscribe((params) => {
          let { page, q } = params;

          if (!page) {
            this.currentPageNumber = 0;
          } else this.currentPageNumber = page;

          // if (this.prevQ != q) {
          //   this.selectedCurrentPage[this.currentPageNumber + 9999] = [];
          //   this.prevQ = q;
          // }
          if (action) {
            this.selectedCurrentPage[this.currentPageNumber] = [];
            this.selected = [];
            return;
          }
          if (this.selected) {
            this.items.map((item) => {
              this.selected.forEach((uuid) => {
                if (item[this.key] === uuid) {
                  item.selected = true;
                }
              });
            });
          }
        });
    }
  }
  ngOnDestroy(): void {
    this.sourceSub?.unsubscribe();
    this.navigationEvent?.unsubscribe();
  }
}
