import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReportingComponent } from "./reporting.component";
import { ReportingRoutingModule } from "./reporting-routing.module";
import { AppCommonModule } from "../app-common-module/app-common-module.module";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ReportingSnapshotFramesDisplayComponent } from "./reporting-snapshot-frames-display/reporting-snapshot-frames-display.component";
import { ReportingChartLineComponent } from "./components/reporting-chart-line/reporting-chart-line.component";
import { ReportingChartValueComponent } from "./components/reporting-chart-value/reporting-chart-value.component";
import { ReportingChartTableSimpleComponent } from "./components/reporting-chart-table-simple/reporting-chart-table-simple.component";
import { ReportingChartTableResourcesComponent } from "./components/reporting-chart-table-resources/reporting-chart-table-resources.component";
import { ReportingSnapshotsListComponent } from './reporting-snapshots-list/reporting-snapshots-list.component';
import { ReportingChartTableGenericComponent } from './components/reporting-chart-table-generic/reporting-chart-table-generic.component';

@NgModule({
  declarations: [
    ReportingComponent,
    ReportingSnapshotFramesDisplayComponent,
    ReportingChartLineComponent,
    ReportingChartValueComponent,
    ReportingChartTableSimpleComponent,
    ReportingChartTableResourcesComponent,
    ReportingSnapshotsListComponent,
    ReportingChartTableGenericComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ReportingRoutingModule,
    NgxChartsModule,
    AppCommonModule,
  ],
})
export class ReportingModule {}
