import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  Observable,
  Subject,
  catchError,
  debounce,
  debounceTime,
  distinctUntilChanged,
  forkJoin,
  map,
  of,
  switchMap,
  tap,
} from "rxjs";
import { Header } from "src/app/app-common-module/components/list-table-header/list-table-header.component";
import { DashboardService } from "src/app/services/dashboard.service";
import { ModalService } from "src/app/services/modal.service";
import { PassService, UsersService } from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-idis-users",
  templateUrl: "./idis-users.component.html",
  styles: [],
})
export class IdisUsersComponent implements OnInit {
  headers: Header[] = [{ name: "Name", value: "name", width: "w-96" }];
  idsListOfStats = [
    { name: "total users", count: "999", link: "" },
    {
      name: "external training documents",
      count: "999",
      link: "/idis/users/external-training-document",
    },
    {
      name: "training needs",
      count: "999",
      link: "/idis/users/training-needs",
    },
    { name: "edit requests", count: "999", link: "" },
  ];
  canAddUsersObservable: Observable<boolean>;
  loading: boolean;
  userListObservable: Observable<any>;
  limit: number = 20;
  totalCount: number = 0;
  constructor(
    private dashboardService: DashboardService,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private usersService: UsersService,
    private passService: PassService
  ) {}
  ngOnInit(): void {
    this.constructCanAddUserObservable();
    this.constructUserListObservable();
  }
  private unsubscribe$: Subject<void> = new Subject<void>();
  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
  constructCanAddUserObservable() {
    this.canAddUsersObservable = this.dashboardService
      .getPermissions()
      .pipe(map((permissions) => permissions.includes("create:users")));
  }
  constructUserListObservable() {
    this.userListObservable = this.route.queryParams.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((params) => {
        this.loading = true;
        let { q, page } = params;
        return this.usersService.servicesBurecordsUsersGet(
          true,
          null,
          null,
          "Staging 1 - Group 1",
          null,
          null,
          page * this.limit || 0,
          this.limit
        );
      }),
      tap((result) => console.log(result)),
      switchMap(({ count, users }) => {
        let usersList = users.map((user) => this.passService.getUser(user.id));
        return forkJoin(usersList).pipe(
          map((_users) => {
            return _users.map((user, i) => {
              return {
                ...users[i],
                name: user.firstName + " " + user.lastName,
              };
            });
          }),
          catchError((err) => {
            if (err.status === 404) {
              return of(users);
            }
          })
        );
      }),
      tap((result) => {
        console.log(result);
        this.loading = false;
      })
    );
  }
  openModal(type) {}
}
