<div class="h-[500px] relative">
  <form
    [formGroup]="formGroup"
    (ngSubmit)="onSave()"
    class="my-10 relative h-full"
  >
    <app-loading position="absolute" padding="py-32" [loading]="loading">
      <h2 class="font-bold text-xl">Choose option to create custom feedback</h2>
      <p class="mb-4" *ngIf="formGroup.value.type === 'MC'">
        Select/unselect radio buttons to switch feedbacks. This will override
        your global feedback.
      </p>
      <p class="mb-4" *ngIf="formGroup.value.type === 'CB'">
        Check/Uncheck boxes to switch feedbacks. This will override your global
        feedback.
      </p>
      <div formArrayName="choices">
        <ng-container
          *ngFor="
            let choiceControl of getUndeletedChoiceControls();
            let i = index
          "
        >
          <div class="flex justify-between items-start my-2">
            <app-radio
              *ngIf="
                formGroup.value.type == 'MC' ||
                formGroup.value.type == 'IC' ||
                formGroup.value.type == 'PO'
              "
              class="text-primary text-2xl mr-2"
              [value]="i"
              (change)="onMultipleChoiceRadioChange(choiceControl)"
            ></app-radio>

            <app-checkbox
              *ngIf="formGroup.value.type == 'CB'"
              class="text-primary text-2xl mr-2"
              (change)="onCheckBoxChange(choiceControl)"
            ></app-checkbox>

            <div class="flex-1">
              <div class="py-2 px-8 border rounded-3xl">
                {{ choiceControl.value.text }}

                <img
                  *ngIf="choiceControl.value.imageUri"
                  class="w-full h-40 mb-1 object-contain border-0 bg-gray-300 rounded"
                  [src]="choiceControl.value.imageUri"
                />
                <audio
                  *ngIf="choiceControl.value.audioUri"
                  class="flex-1"
                  controls
                  src="{{ choiceControl.get('audioUri').value }}"
                >
                  <!-- <source [src]="choiceControl.get('audioUri').value" /> -->
                </audio>
              </div>

              <!-- <div
                class="relative"
                [ngClass]="{
                  hidden:
                    !choiceControl.value.imageUri ||
                    formGroup.value.subtype === 'DD'
                }"
              >
                <img
                  class="w-full h-40 mb-1 object-contain border-0 bg-gray-300 rounded"
                  [src]="choiceControl.value.imageUri"
                />
              </div>

              <div
                class="relative flex items-center"
                *ngIf="
                  choiceControl.get('audioUri').value &&
                  formGroup.value.subtype !== 'DD'
                "
              >
                <audio
                  class="flex-1"
                  controls
                  src="{{ choiceControl.get('audioUri').value }}"
                ></audio>
              </div> -->
            </div>
          </div>
        </ng-container>
      </div>
      <div *ngIf="formGroup.get('showResults').value" class="mx-8">
        <ng-container
          *ngIf="
            (formGroup.value.type == 'MC' ||
              formGroup.value.type == 'IC' ||
              formGroup.value.type == 'CB' ||
              formGroup.value.type == 'SA') &&
              formGroup.value.isGraded == true;
            else text
          "
        >
          <div class="w-full my-10" *ngIf="feedback === 'correct'">
            <label>Feedback for Correct Answer</label>
            <div class="w-full flex justify-start items-start">
              <div class="w-full flex-1">
                <app-markdown-viewer-and-editor
                  [autoResize]="false"
                  editorClass="h-20 overflow-y-scroll"
                  viewerClass="h-20 overflow-y-scroll border rounded p-2"
                  formControlName="choiceSetFeedbackText"
                >
                </app-markdown-viewer-and-editor>
                <app-upload-loading-wrapper
                  #correctFeedbackImageUrlUploader
                  accept="image/*"
                  text="UPLOAD IMAGE"
                  [ngClass]="{
                    hidden: !formGroup.value.choiceSetFeedbackImageUrl
                  }"
                  (upload)="
                    onUpload($event, formGroup.get('choiceSetFeedbackImageUrl'))
                  "
                >
                  <div
                    class="relative"
                    *ngIf="formGroup.value.choiceSetFeedbackImageUrl"
                  >
                    <img
                      [src]="formGroup.value.choiceSetFeedbackImageUrl"
                      class="w-full h-40 object-contain bg-gray-300 rounded"
                    />

                    <button
                      type="button"
                      (click)="
                        onUploadClear(
                          formGroup.get('choiceSetFeedbackImageUrl')
                        )
                      "
                      class="appearance-none pt-1 pb-2 pr-1 pl-2 z-auto rounded-bl-full hover:bg-red-500 text-white bg-gray-500 absolute top-0 right-0"
                    >
                      <app-icon icon="close"></app-icon>
                    </button>
                  </div>
                </app-upload-loading-wrapper>
              </div>

              <button
                type="button"
                (click)="correctFeedbackImageUrlUploader.click()"
                class="text-primary text-4xl"
              >
                <app-icon icon="photo"></app-icon>
              </button>
            </div>
          </div>
          <div class="my-10 w-full" *ngIf="feedback === 'incorrect'">
            <label>Feedback for Incorrect Answer</label>
            <div class="flex items-start justify-start w-full">
              <div class="w-full flex-1">
                <app-markdown-viewer-and-editor
                  placeholder="Feedback for Incorrect Answer"
                  [autoResize]="false"
                  editorClass="h-[150px] overflow-y-scroll"
                  viewerClass="h-[150px] overflow-y-scroll border rounded p-2"
                  formControlName="choiceSetFeedbackText"
                >
                </app-markdown-viewer-and-editor>
                <app-upload-loading-wrapper
                  #incorrectFeedbackImageUrlUploader
                  accept="image/*"
                  text="UPLOAD IMAGE"
                  [ngClass]="{
                    hidden: !formGroup.value.choiceSetFeedbackImageUrl
                  }"
                  (upload)="
                    onUpload($event, formGroup.get('choiceSetFeedbackImageUrl'))
                  "
                >
                  <div
                    class="relative"
                    *ngIf="formGroup.value.choiceSetFeedbackImageUrl"
                  >
                    <img
                      [src]="formGroup.value.choiceSetFeedbackImageUrl"
                      class="w-full h-40 object-contain bg-gray-300 rounded"
                    />

                    <button
                      type="button"
                      (click)="
                        onUploadClear(
                          formGroup.get('choiceSetFeedbackImageUrl')
                        )
                      "
                      class="appearance-none pt-1 pb-2 pr-1 pl-2 z-auto rounded-bl-full hover:bg-red-500 text-white bg-gray-500 absolute top-0 right-0"
                    >
                      <app-icon icon="close"></app-icon>
                    </button>
                  </div>
                </app-upload-loading-wrapper>
              </div>

              <button
                (click)="incorrectFeedbackImageUrlUploader.click()"
                type="button"
                class="text-primary text-4xl"
              >
                <app-icon icon="photo"></app-icon>
              </button>
            </div>
          </div>
        </ng-container>

        <ng-template #text>
          <div
            class="w-full my-10"
            *ngIf="formGroup.get('choiceSetFeedbackText')"
          >
            <label>Answer</label>
            <div class="flex items-start justify-start w-full">
              <div class="w-full flex-1">
                <app-markdown-viewer-and-editor
                  placeholder="Feedback for Answer"
                  [autoResize]="false"
                  editorClass="h-[150px] overflow-y-scroll"
                  viewerClass="h-[150px] overflow-y-scroll border rounded p-2"
                  [formControl]="formGroup.get('choiceSetFeedbackText')"
                >
                </app-markdown-viewer-and-editor>
                <app-upload-loading-wrapper
                  #neutralFeedbackImageUrlUploader
                  accept="image/*"
                  text="UPLOAD IMAGE"
                  [ngClass]="{
                    hidden: !formGroup.value.choiceSetFeedbackImageUrl
                  }"
                  (upload)="
                    onUpload($event, formGroup.get('choiceSetFeedbackImageUrl'))
                  "
                >
                  <div
                    class="relative"
                    *ngIf="formGroup.value.choiceSetFeedbackImageUrl"
                  >
                    <img
                      [src]="formGroup.value.choiceSetFeedbackImageUrl"
                      class="w-full h-40 object-contain bg-gray-300 rounded"
                    />

                    <button
                      type="button"
                      (click)="
                        onUploadClear(
                          formGroup.get('choiceSetFeedbackImageUrl')
                        )
                      "
                      class="appearance-none pt-1 pb-2 pr-1 pl-2 z-auto rounded-bl-full hover:bg-red-500 text-white bg-gray-500 absolute top-0 right-0"
                    >
                      <app-icon icon="close"></app-icon>
                    </button>
                  </div>
                </app-upload-loading-wrapper>
              </div>

              <button
                (click)="neutralFeedbackImageUrlUploader.click()"
                type="button"
                class="text-primary text-4xl"
              >
                <app-icon icon="photo"></app-icon>
              </button>
            </div>
          </div>
        </ng-template>
      </div>
      <div class="flex w-full justify-end sticky bottom-0">
        <button class="btn btn-primary" type="submit">SAVE</button>
      </div>
    </app-loading>
  </form>
</div>
