<h2 class="text-lg font-semibold mb-2">Select Group:</h2>

<mat-dialog-content
  ><div mat-dialog-content class="min-h-10 min-w-20">
    <app-search-bar [formControl]="searchFormControl"></app-search-bar>

    <div class="my-5">
      <app-item-list [source]="groupObs" [itemTemplate]="itemTemplate">
      </app-item-list>
    </div>
  </div>
</mat-dialog-content>
<ng-template #itemTemplate let-item="item">
  <button class="flex items-center text-left" [mat-dialog-close]="item.id">
    <app-icon icon="group" class="text-primary text-4xl mr-2"></app-icon>

    <div class="flex-1 min-w-0">
      <div class="font-semibold leading-none truncate">
        {{ item.name }}
      </div>

      <div class="text-sm text-primary">{{ item.userIds?.length }} user/s</div>
    </div>
  </button>
</ng-template>
