<app-back-title-layout [loading]="!group">
  <ng-container *ngIf="group">
    <form [formGroup]="formGroup" (ngSubmit)="saveGroup()" class="pb-10">
      <div
        class="text-5xl rounded-full bg-gray-300 text-white h-20 w-20 flex items-center justify-center mb-5"
      >
        <app-icon icon="group"></app-icon>
      </div>

      <h2 class="form-heading">Group Details</h2>

      <div class="my-5">
        <label>
          <span class="form-label"> Group Name </span>
          <input
            formControlName="name"
            class="form-control"
            placeholder="Group Name"
          />
        </label>
      </div>

      <div class="my-5">
        <label>
          <span class="form-label"> Description </span>
          <textarea
            formControlName="description"
            type="text"
            class="form-control"
            placeholder="Add short description about this group"
          ></textarea>
        </label>
      </div>

      <hr class="my-10" />

      <h2 class="text-xl font-bold">Role</h2>

      <ng-container *ngIf="group.roles.length > 1">
        <div class="flex p-3 bg-warning-100 text-warning-700 rounded my-2">
          <app-icon icon="warning" class="text-lg"></app-icon>

          <div class="flex-1 mx-2">
            <div class="font-bold line-clamp-2 text-lg">Special Group</div>

            <div>
              This group has special roles. Editing the role of this group is
              not possible at the moment.
            </div>
          </div>
        </div>
      </ng-container>

      <app-role-selector
        [setLearnersAsDefaultRole]="true"
        formControlName="selectedRoleId"
      ></app-role-selector>

      <div class="my-5 flex flex-row-reverse">
        <button type="submit" class="btn btn-primary">SAVE</button>
      </div>
    </form>

    <!-- <div class="my-5 pb-10">
      <button
        type="button"
        class="w-full btn btn-danger"
        (click)="deleteGroup()"
        [disabled]="!canDelete"
      >
        <app-icon icon="delete"></app-icon>
        DELETE GROUP
      </button>
    </div> -->
  </ng-container>
</app-back-title-layout>
