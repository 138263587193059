import { Component, OnInit } from "@angular/core";
import {
  AdminReportsService,
  CoursesService,
  PassService,
  User,
} from "src/app/sinigangnababoywithgabi";
import { ActivatedRoute } from "@angular/router";
import { LinkList } from "src/app/app-common-module/layouts/profile-link-list/profile-link-list.component";
import { firstValueFrom, map, Observable, switchMap, tap } from "rxjs";

interface CourseStatistic {
  name: string;
  coverUri: string;
  status: string;
}

@Component({
  selector: "app-user-profile",
  templateUrl: "./user-profile.component.html",
})
export class UserProfileComponent implements OnInit {
  user: User;
  linkLists: LinkList[];

  totalReadingTime: number;
  attemptedResourcesCount: number;
  completedResourcesCount: number;

  courseStatisticsObs: Observable<CourseStatistic[]>;

  limit: number = 20;

  constructor(
    private activatedRoute: ActivatedRoute,
    private passService: PassService,
    private adminReportService: AdminReportsService,
    private coursesService: CoursesService,
    private route: ActivatedRoute
  ) {}

  async ngOnInit() {
    let { user_uuid: userUuid } = this.activatedRoute.snapshot.queryParams;

    this.user = await firstValueFrom(
      this.passService.getUser(userUuid, ["groups"])
    );

    let stats = await firstValueFrom(
      this.adminReportService.reportUserWithStats(userUuid)
    );

    this.totalReadingTime = stats["totalLearningDurationMs"];
    this.attemptedResourcesCount = stats["resourceAttemptedCount"];
    this.completedResourcesCount = stats["resourceCompletedCount"];

    this.loadCourseStats();
  }

  async loadCourseStats() {
    this.courseStatisticsObs = this.route.queryParams.pipe(
      switchMap((params) => {
        let { user_uuid: userUuid, page: pageRaw } = params;

        let page = parseInt(pageRaw) || 0;

        return this.coursesService.courseStatisticsCourseStatuses(
          null,
          userUuid,
          null,
          "course_details",
          page * this.limit,
          this.limit
        );
      }),
      map((result) => {
        return result.map((item) => {
          return {
            name: item["courseDetails"].title,
            coverUri: item["courseDetails"].coverUri,
            status: item.status,
            courseUuid: item.course,
          };
        });
      })
    );
  }
}
