<app-back-title-layout layoutTitle="User Enrollment" [loading]="!schedule">
  <ng-container>
    <div class="flex">
      <div>
        <app-icon class="text-lg" icon="date_range"></app-icon>
      </div>

      <div class="mx-2">
        <div class="text-lg font-bold">
          {{ schedule?.name }}
        </div>

        <div>
          <div class="text-gray-600 text-sm">
            <ng-container
              *ngIf="schedule?.start && schedule?.start; else forever"
            >
              <span>
                {{ schedule?.start | date }} - {{ schedule?.end | date }}
              </span>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <div class="my-5">
      <a
        [routerLink]="['../new', 'index']"
        class="block btn btn-primary w-full"
        queryParamsHandling="merge"
      >
        <app-icon icon="add"></app-icon>

        ADD NEW USER
      </a>
    </div>

    <div class="my-5">
      <div class="flex justify-end">
        <app-pagination-query
          [pageSize]="pageSize"
          [listLength]="enrollmentCount"
        ></app-pagination-query>
      </div>

      <app-item-list
        [source]="userListObservable"
        [itemTemplate]="itemTemplate"
      >
      </app-item-list>
    </div>
  </ng-container>
</app-back-title-layout>

<ng-template #itemTemplate let-item="item">
  <div class="flex">
    <app-icon
      icon="person"
      class="text-gray-600 text-4xl mr-2 leading-none"
    ></app-icon>

    <a
      [routerLink]="['/users', 'profile']"
      [queryParams]="{
        user_uuid: item.id
      }"
      class="flex-1"
    >
      <div class="leading-tight truncate font-semibold">
        {{ item.firstName }} {{ item.lastName }}
      </div>

      <div class="text-sm text-gray-700">{{ item.email }}</div>

      <!-- <div *ngIf="!item.confirmedAt" class="text-sm text-red-500 italic">
        <app-icon icon="error" class="align-bottom"></app-icon>
        <span> email not yet confirmed </span>
      </div> -->

      <div class="text-disabled-dark">
        {{ item.createdAt | date }}
      </div>
    </a>

    <div>
      <app-dropdown-layout toggleClass="p-3" toggleBodyClass="z-50">
        <span dropdownToggle>&#8230;</span>

        <div dropdownContent>
          <ul class="my-1">
            <li class="px-2 hover:bg-error-light text-error-dark">
              <button type="button" (click)="removeEnrollment(item)">
                Delete enrollment
              </button>
            </li>
          </ul>
        </div>
      </app-dropdown-layout>
    </div>
  </div>
</ng-template>

<ng-template #forever>
  <span>Forever</span>
</ng-template>
