<div class="m-auto bg-white rounded modal-box">
  <img
    class="w-full bg-gray-400 rounded-t object-cover"
    src="https://images.unsplash.com/photo-1459262838948-3e2de6c1ec80?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=800&amp;q=80"
    alt=""
  />

  <section class="py-4 text-center px-6">
    <p>
      Cast accepts a variety of different file types for your learning material
      needs.
      <!-- Click the <app-icon class="rounded text-purple-700 text-2xl" icon="info"></app-icon> icon to learn more. -->
    </p>

    <button
      class="mt-10 btn btn-primary w-full font-bold"
      type="button"
      [mat-dialog-close]="true"
    >
      GOT IT!
    </button>
  </section>
</div>
