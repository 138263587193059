<app-back-title-layout [loading]="loading">
  <button
    class="btn btn-primary my-4"
    [routerLink]="['/resources', 'new']"
    queryParamsHandling="merge"
    [queryParams]="{ type: 'course' }"
  >
    <app-icon icon="add_circle"></app-icon>

    Create own course
  </button>

  <h2 class="form-heading my-4">Recommended for you</h2>

  <div class="grid gap-6 grid-cols-2">
    <ng-container *ngFor="let course of courses">
      <button
        (click)="cloneCourseToResource(course)"
        class="flex text-left items-center border-b pb-5 border-gray-200"
      >
        <img
          [src]="course.coverUri || 'assets/default-logo.png'"
          class="shrink-0 w-28 h-28 object-contain bg-gray-200"
        />

        <div class="mx-2">
          <div class="font-bold">
            {{ course.title }}
          </div>

          <markdown
            class="text-xs mt-3 line-clamp-3"
            [data]="
              course.content?.resourceDetails?.description || course.description
            "
          >
          </markdown>
        </div>
      </button>
    </ng-container>
  </div>
</app-back-title-layout>

<ng-template #newCourseTemplate>
  <form [formGroup]="formGroup" class="flex flex-col items-center">
    <h3 class="text-xl font-bold">Create Course</h3>

    <div class="my-10 h-32 w-32 bg-purple-200">
      <app-upload-loading-wrapper
        #thumbnailInput
        (upload)="onUpload($event)"
        accept="image/jpeg,image/jpg,image/png,image/gif"
      >
        <div class="w-full h-full">
          <div
            *ngIf="!formGroup.value.coverUri"
            class="h-full text-purple-500 w-full flex flex-col items-center justify-center"
          >
            <app-icon class="text-6xl" icon="image"></app-icon>
          </div>

          <img
            *ngIf="formGroup.value.coverUri"
            [src]="formGroup.value.coverUri"
            alt=""
            class="w-full object-contain h-full"
          />
        </div>
      </app-upload-loading-wrapper>
    </div>

    <div>
      <label for="coursename" class="form-label">Course Name</label>
      <input
        formControlName="name"
        type="text"
        id="coursename"
        name="coursename"
        class="form-control"
      />
    </div>

    <button class="btn btn-primary my-5">Done</button>
  </form>
</ng-template>
