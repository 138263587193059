import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpEventType,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import decamelizeKeys from "decamelize-keys";
import camelcaseKeys from "camelcase-keys";

const DECAMELIZE_EXCEPTION = [
  /\/services\/lrs\/.+/,
  /\/services\/learninglocker\/.+/,
];

/**
 * This interceptor does the following:
 * 1. Converts all the HTTP response bodies to camelCase
 * 2. Converts all the HTTP request bodies to snake_case
 *
 * Why? This is just preference. The code maintainer just prefers dealing with camelCase attributes in the code.
 */
@Injectable()
export class ApiCamelSnakeInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Convert request body to snake_case
    if (
      !DECAMELIZE_EXCEPTION.some((exception) => exception.test(req.url)) &&
      req.body &&
      !(req.body instanceof FormData)
    ) {
      req = req.clone({
        body: decamelizeKeys(req.body, {
          deep: true,
        }),
      });
    }

    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event.type == HttpEventType.Response) {
          // Convert response body to camelCase
          return event.clone({
            body: camelcaseKeys(event.body, {
              deep: true,
            }),
          });
        } else {
          return event;
        }
      })
    );
  }
}
