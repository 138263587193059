import { Apollo, gql } from "apollo-angular";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { ModalService } from "src/app/services/modal.service";
import {
  CoursesService,
  V1ExamSection,
  V1ExamsService,
} from "src/app/sinigangnababoywithgabi";
import { ExamSubmissionResponseRubricGradingComponent } from "../components/exam-submission-response-rubric-grading/exam-submission-response-rubric-grading.component";
import { firstValueFrom } from "rxjs";

interface Attempt {
  itemUuid: string;
  itemQuestion: string;
  itemType: string;
  itemChoices: {
    uuid: string;
    text: string;
    image: string;
    isCorrect: boolean;
    isSelected: boolean;
  };

  responseUuid: string;
  responseChoiceAnswers: string;
  responseTextAnswer: string;
  responseMediaUrl: string;
  responseScore: string;
  responseIsCorrect: boolean;
}

@Component({
  selector: "app-exam-submission-responses",
  templateUrl: "./exam-submission-responses.component.html",
  styleUrls: ["./exam-submission-responses.component.scss"],
})
export class ExamSubmissionResponsesComponent implements OnInit {
  sections: V1ExamSection[];
  submissionUuid: string;
  loading = true;

  attempts: Attempt[];

  constructor(
    private activatedRoute: ActivatedRoute,
    private v1ExamsService: V1ExamsService,
    private apollo: Apollo,
    private modalService: ModalService,
    private coursesService: CoursesService
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  async loadData() {
    this.loading = true;
    let {
      submission_uuid: submissionUuid,
      exam_uuid: examUuid,
      timeslot_uuid: timeslotUuid,
      block_status_uuid: blockStatusUuid,
    } = this.activatedRoute.snapshot.queryParams;

    if (blockStatusUuid) {
      let blockStatus = await firstValueFrom(
        this.coursesService.blockstatusRead(blockStatusUuid)
      );
    }

    if (!examUuid && timeslotUuid) {
      let timeslot = await firstValueFrom(
        this.v1ExamsService.examsTimeslotsRead(timeslotUuid)
      );

      examUuid = timeslot.exam;
    }

    if (!submissionUuid) {
      this.attempts = [];
      this.loading = false;

      return;
    }

    let result: any = await firstValueFrom(
      this.apollo.query({
        query: gql`
          query Data($exam_uuid: ID!, $submission_uuid: ID!) {
            admin {
              exam(uuid: $exam_uuid) {
                sections {
                  items {
                    uuid
                    text
                    type
                    choices {
                      uuid
                      short_input
                      long_input
                      media_url
                      choiceAnswer {
                        is_correct
                      }
                    }
                  }
                }
              }
              submission(uuid: $submission_uuid) {
                responses {
                  uuid
                  item
                  short_input
                  long_input
                  media_url
                  choices
                  score
                  poll_choice
                  is_response_correct
                }
              }
            }
          }
        `,
        variables: {
          examUuid,
          submissionUuid,
        },
      })
    ).then((r) => r.data["admin"]);

    this.attempts = [];

    let responseItemMap = {};
    result.submission.responses = result.submission.responses.reverse();
    result.submission.responses.forEach((response) => {
      responseItemMap[response.item] = response;
    });

    result.exam.sections[0]?.items.forEach((item) => {
      let response = responseItemMap[item.uuid];

      this.attempts.push({
        itemUuid: item.uuid,
        itemQuestion: item.text,
        itemType: item.type,
        itemChoices: item.choices.map((choice) => {
          return {
            uuid: choice.uuid,
            text: choice.shortInput || choice.longInput,
            image: choice.mediaUrl,
            isCorrect: choice.choiceAnswer?.isCorrect,
            isSelected:
              response?.choices.includes(choice.uuid) ||
              response?.pollChoice === choice.uuid,
          };
        }),

        responseUuid: response?.uuid,
        responseChoiceAnswers: response?.choices,
        responseTextAnswer: response?.shortInput || response?.longInput,
        responseMediaUrl: response?.mediaUrl,
        responseIsCorrect: response?.isResponseCorrect,
        responseScore: response?.score,
      });
    });
    this.loading = false;
  }

  async updateResponseScore(attempt: Attempt) {
    let {
      block_status_uuid: blockStatusUuid,
      submission_uuid: submissionUuid,
    } = this.activatedRoute.snapshot.queryParams;

    let score = await this.modalService.prompt("New score", {
      defaultValue: attempt.responseScore,
    });

    if (score !== null && score !== undefined) {
      attempt.responseScore = score;

      await firstValueFrom(
        this.v1ExamsService.examsTimeslotsSubmissionsResponsesUpdate(
          attempt.responseUuid,
          {
            score: attempt.responseScore as any,
          }
        )
      );

      if (blockStatusUuid) {
        let totalScore = 0;

        this.attempts.forEach((attempt) => {
          return (totalScore += parseInt(attempt.responseScore));
        });

        await firstValueFrom(
          this.coursesService.blockstatusUpdate(blockStatusUuid, {
            submission: {
              totalScore: totalScore,
              status: "final",
            },
          })
        );
      }

      await this.loadData();
    }
  }

  async updateResponseScoreViaRubric(attempt: Attempt) {
    let { block_status_uuid: blockStatusUuid } =
      this.activatedRoute.snapshot.queryParams;

    let result = await this.modalService.openModal(
      ExamSubmissionResponseRubricGradingComponent,
      {
        data: {
          itemUuid: attempt.itemUuid,
          responseUuid: attempt.responseUuid,
        },
      }
    );

    if (result !== undefined) {
      attempt.responseScore = result;
    }

    if (blockStatusUuid) {
      let totalScore = 0;

      this.attempts.forEach(
        (attempt) => (totalScore += parseInt(attempt.responseScore))
      );

      await firstValueFrom(
        this.coursesService.blockstatusUpdate(blockStatusUuid, {
          submission: {
            totalScore: totalScore,
            status: "final",
          },
        })
      );
    }
  }
}
