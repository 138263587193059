import { Apollo, gql } from "apollo-angular";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Course, CoursesService, User } from "src/app/sinigangnababoywithgabi";
import { firstValueFrom } from "rxjs";

@Component({
  selector: "app-course-student-result",
  templateUrl: "./course-student-result.component.html",
})
export class CourseStudentResultComponent implements OnInit {
  course: Course;
  user: User;

  userCourseStat: {
    completedBlockCount: number;
    totalBlockCount: number;
    hasCompletedCourse: boolean;
  };

  tableValues: {
    name: string;
    coverUri: string;
    score: number;
    scoreText: string;
    perfectScore: number;
    statusText: string;
    statusClass: string;
    blockStatusUuid: string;
    submissionUuid: string;
    examUuid: string;
  }[];

  constructor(
    private route: ActivatedRoute,
    private apollo: Apollo,
    private coursesService: CoursesService
  ) {}

  ngOnInit(): void {
    this.load();
  }

  async load() {
    let { user_uuid: userUuid, course_uuid: courseUuid } =
      this.route.snapshot.queryParams;

    let { user, course } = await this.fetchData({
      courseUuid,
      userUuid,
    });

    let courseStatuses = await firstValueFrom(
      this.coursesService.courseStatisticsCourseStatuses(
        courseUuid,
        userUuid,
        null,
        "block_statuses,block_details,grade_percent,submission_id"
      )
    );

    this.user = user;
    this.course = course;

    await this.constructTableValues({
      course,
      courseStatus: courseStatuses[0],
    });
  }

  async fetchData({ courseUuid, userUuid }) {
    let { course, user } = await firstValueFrom(
      this.apollo.query({
        query: gql`
          query AdminStuff($course_uuid: ID!, $user_uuid: ID!) {
            admin {
              user(uuid: $user_uuid) {
                uuid
                email
                first_name
                last_name
              }
              course(uuid: $course_uuid) {
                title
                sections {
                  title
                  blocks {
                    uuid
                    title
                    cover_uri
                    required_for_certificate
                    resource {
                      exam {
                        uuid
                      }
                    }
                  }
                }
              }
            }
          }
        `,
        variables: {
          courseUuid,
          userUuid,
        },
      })
    ).then((result: any) => result.data.admin);

    return {
      course,
      user,
    };
  }

  async constructTableValues({ course, courseStatus }) {
    let blockUuidCourseStatusMap = {};

    //Extract exam blocks
    let examBlocks = [];

    let blockExamMap = {};

    course.sections?.forEach((section) => {
      section.blocks?.forEach((block) => {
        if (block.resource?.exam?.uuid) {
          blockExamMap[block.uuid] = block.resource?.exam?.uuid;
        }
      });
    });

    courseStatus?.sectionStatuses.forEach((ss) => {
      ss.blockStatuses.forEach((bs) => {
        blockUuidCourseStatusMap[bs.block] = bs;

        if (bs.blockDetails.requiredForCertificate) {
          examBlocks.push(bs.blockDetails);
        }
      });
    });

    this.tableValues = [];

    examBlocks.forEach((examBlock) => {
      let statusText;
      let statusClass;
      let bcs = blockUuidCourseStatusMap[examBlock.uuid];

      let score = 0;
      let perfectScore = 0;
      let scoreText = bcs?.gradePercent ? `${bcs?.gradePercent}%` : "";

      if (!bcs) {
        statusText = "NOT STARTED";
        statusClass = "bg-gray-200 text-gray-600";
      } else if (bcs.status === "in_progress") {
        statusText = "IN PROGRESS";
        statusClass = "bg-blue-200 text-blue-600";
      } else if (bcs.status === "needs_attention") {
        statusText = "NEEDS ATTENTION";
        statusClass = "bg-warning-200 text-warning-600";
      } else if (bcs.status === "failed") {
        statusText = "FAILED";
        statusClass = "bg-red-200 text-red-600";
      } else if (bcs.status === "passed") {
        statusText = "PASSED";
        statusClass = "bg-green-200 text-green-600";
      }

      this.tableValues.push({
        name: examBlock.title,
        coverUri: examBlock.coverUri,
        score,
        perfectScore,
        scoreText,
        statusText,
        statusClass,
        blockStatusUuid: bcs.uuid,
        submissionUuid: bcs.submissionId,
        examUuid: blockExamMap[examBlock.uuid],
      });
    });
  }
}

// /?exam_uuid=47a75b28-8758-43b2-bf4d-7af5830da138&timeslot_uuid=a562d282-9a6e-4d07-aa76-84240dd5d2ed&submission_uuid=e31ca81a-3e89-4c24-aebd-3d403eea672d
