import { Component, OnInit, Input, TemplateRef } from "@angular/core";
import { Resource, UploadsService } from "src/app/sinigangnababoywithgabi";
import { UntypedFormGroup, Validators, UntypedFormControl } from "@angular/forms";

// DEPRECATED
@Component({
  selector: "app-resource-type-upload-buribook",
  templateUrl: "./resource-type-upload-buribook.component.html",
})
export class ResourceTypeUploadBuribookComponent implements OnInit {
  @Input()
  resource: Resource;

  @Input()
  formGroup: UntypedFormGroup;

  problems: string[];

  dropzoneConfig;

  constructor(private uploadsService: UploadsService) {
    this.uploadsService.uploadFile;
    this.dropzoneConfig = {
      url: `${uploadsService.configuration.basePath}/services/buriuploads/uploads`,
      withCredentials: true,
      acceptedFiles: "image/*",
    };
  }

  ngOnInit() {
    this.initializeForm();

    this.initializeSpreads();
    this.initializePages();

    this.getProblems();
  }

  initializeForm() {
    this.formGroup.addControl(
      "_valid",
      new UntypedFormControl(false, Validators.requiredTrue)
    );
  }

  initializeSpreads() {
    this.resource.content["spreads"] = this.resource.content["spreads"] || [];
  }

  // To be deleted
  initializePages() {
    this.resource.content["pages"] = this.resource.content["pages"] || [];
  }

  onUpload(dropzoneEvent) {
    let file: File = dropzoneEvent[0];
    let result = dropzoneEvent[1];

    let order = parseInt(file.name);

    if (Number.isInteger(order)) {
      // <-- To be deleted
      this.resource.content["pages"][order - 1] = {
        type: "image",
        uri: result.uri,
      };
      // To be deleted -->

      let spreadIndex = Math.floor((order - 1) / 2);

      let spread = this.resource.content["spreads"][spreadIndex] || {
        spreadType: "image-spread",
        leftUri: "",
        rightUri: "",
      };

      if (order % 2 == 1) {
        spread["leftUri"] = result.uri;
      } else {
        spread["rightUri"] = result.uri;
      }

      this.resource.content["spreads"][spreadIndex] = spread;
    }

    this.getProblems();

    let pageCount = this.getPageCount();

    if (pageCount) {
      this.formGroup.get("flavorTextSpan").setValue(`${pageCount} pages`);
    }
  }

  getPageCount() {
    let count = 0;

    this.resource.content["spreads"].forEach((spread) => {
      if (spread.leftUri) {
        count++;
      }

      if (spread.rightUri) {
        count++;
      }
    });

    return count;
  }

  getProblems() {
    this.problems = [];

    for (let i = 0; i < this.resource.content["spreads"].length; i++) {
      let spread = this.resource.content["spreads"][i];
      if (!spread) {
        this.problems.push(
          ...[
            `Page ${i * 2 + 1} has a problem. Please reupload the page.`,
            `Page ${i * 2 + 2} has a problem. Please reupload the page.`,
          ]
        );
      } else if (!spread.leftUri) {
        this.problems.push(
          `Page ${i * 2 + 1} has a problem. Please reupload the page.`
        );
      } else if (!spread.rightUri) {
        this.problems.push(
          `Page ${i * 2 + 2} has a problem. Please reupload the page.`
        );
      }
    }

    if (this.problems.length > 0) {
      this.formGroup.get("_valid").setValue(false);
    } else {
      this.formGroup.get("_valid").setValue(true);
    }
  }
}
