<app-back-title-layout>
  <a [routerLink]="['../', 'batches']" queryParamsHandling="merge">
    <section class="pt-3 pb-5 border-b cursor-pointer">
      <header class="mb-2 font-bold flex justify-between">
        <span> Student Performance </span>
        <span class="text-lg text-purple-500">
          <app-icon icon="chevron_right"></app-icon>
        </span>
      </header>

      <span class="text-sm text-gray-700">
        See student scores and status by batch or individually per student
      </span>
    </section>
  </a>

  <a [routerLink]="null" class="text-gray-500" queryParamsHandling="merge">
    <section class="pt-3 pb-5 border-b cursor-pointer">
      <header class="mb-2 font-bold flex justify-between">
        <span> Course Statistics </span>
        <span class="text-lg text-gray-500">
          <app-icon icon="chevron_right"></app-icon>
        </span>
      </header>

      <span class="text-sm text-gray-700">
        See overall course scores and student activity
      </span>
    </section>
  </a>
</app-back-title-layout>
