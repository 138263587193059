import { Component, OnInit, Input } from "@angular/core";
import { Resource, V1ExamsService } from "src/app/sinigangnababoywithgabi";
import { FormGroup, FormBuilder } from "@angular/forms";
import { firstValueFrom } from "rxjs";

@Component({
  selector: "app-resource-type-upload-assessment",
  templateUrl: "./resource-type-upload-assessment.component.html",
})
export class ResourceTypeUploadAssessmentComponent implements OnInit {
  @Input()
  resource: Resource;

  @Input()
  formGroup: FormGroup;

  canRead: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private v1ExamsService: V1ExamsService
  ) {}

  async ngOnInit() {
    let examUuid = this.resource.content["examDetails"]?.["examUuid"];
    let examTimeslotUuid =
      this.resource.content["examDetails"]?.["examTimeslotUuid"];

    if (examUuid) {
      let exam = await firstValueFrom(this.v1ExamsService.examsRead(examUuid));

      this.canRead = !!exam;
    } else {
      this.canRead = true;
    }

    // Apprently, formgroup is enabled if we add new controls
    if (this.formGroup.enabled) {
      this.formGroup.addControl(
        "content",
        this.formBuilder.group({
          examDetails: this.formBuilder.group({
            examUuid: [examUuid],
            examTimeslotUuid: [examTimeslotUuid],
          }),
        })
      );
    }
  }
}
