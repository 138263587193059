<app-back-title-layout
  layoutTitle="Edit Parent Collections"
  [loading]="!(collections && selectedCollections)"
>
  <ng-container *ngIf="collections && selectedCollections">
    <div class="my-5">
      <app-search-bar
        placeholder="Search"
        #search
        [(ngModel)]="search.value"
      ></app-search-bar>
    </div>

    <app-item-select
      [items]="collections | filterAll: search.value"
      [selectedItems]="selectedCollections"
      [itemTemplate]="itemTemplate"
    ></app-item-select>

    <button class="btn btn-primary w-full" (click)="updateCollections()">
      Save
    </button>
  </ng-container>
</app-back-title-layout>

<ng-template #itemTemplate let-item="item">
  <app-icon icon="folder" class="text-primary"></app-icon>

  {{ item.name }}
</ng-template>
