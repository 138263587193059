<div class="my-5 relative" [formGroup]="formGroup">
  <label class="form-label">Options</label>

  <div formArrayName="choices">
    <ng-container
      *ngFor="let choiceControl of getUndeletedChoiceControls(); let i = index"
    >
      <div>
        <div class="flex justify-between items-start my-2">
          <div class="flex-1">
            <input
              type="text"
              placeholder="Insert text here..."
              class="form-control mr-2"
              [formControl]="choiceControl.get('text')"
            />

            <div
              class="relative"
              [ngClass]="{ hidden: !choiceControl.value.imageUri }"
            >
              <img
                class="w-full h-40 mb-1 object-contain border-0 bg-gray-300 rounded"
                [src]="choiceControl.value.imageUri"
              />

              <button
                type="button"
                class="appearance-none pt-1 pb-2 pr-1 pl-2 z-auto rounded-bl-full hover:bg-red-500 text-white bg-gray-500 absolute top-0 right-0"
                (click)="clearControl(choiceControl.get('imageUri'))"
              >
                <app-icon icon="close"></app-icon>
              </button>
            </div>

            <div
              class="relative flex items-center"
              *ngIf="choiceControl.value.audioUri"
            >
              <audio
                class="flex-1"
                controls
                src="{{ choiceControl.value.audioUri }}"
              ></audio>

              <button
                type="button"
                class="btn btn-icon mx-2 text-gray-600 bg-transparent"
                (click)="clearControl(choiceControl.get('audioUri'))"
              >
                <app-icon icon="close"></app-icon>
              </button>
            </div>

            <div class="w-full relative">
              <select
                [formControl]="choiceControl.get('tallyTypeIndex')"
                class="block appearance-none text-sm w-full bg-white border-b-2 border-primary hover:border-primary-dark px-2 py-1 pr-8 leading-tight focus:outline-none focus:ring"
              >
                <option [ngValue]="null">-----</option>
                <option
                  *ngFor="let tallyTypeChoice of getTallyTypeChoices()"
                  [ngValue]="tallyTypeChoice.value"
                >
                  {{ tallyTypeChoice.name }}
                </option>
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
              >
                <app-icon icon="expand_more"></app-icon>
              </div>
            </div>
          </div>

          <input
            #uploader
            type="file"
            accept="image/*"
            class="hidden"
            (change)="onUpload($event, choiceControl.get('imageUri'))"
          />
          <button
            type="button"
            (click)="uploader.click()"
            class="btn btn-icon text-primary bg-transparent text-4xl mr-2"
          >
            <app-icon icon="photo"></app-icon>
          </button>

          <input
            #audioUploader
            type="file"
            accept="audio/*"
            class="hidden"
            (change)="onUpload($event, choiceControl.get('audioUri'))"
          />
          <button
            type="button"
            (click)="audioUploader.click()"
            class="btn btn-icon text-primary bg-transparent text-4xl mr-2"
          >
            <app-icon icon="audiotrack"></app-icon>
          </button>

          <button
            type="button"
            class="btn btn-icon bg-transparent text-gray-600 hover:text-red-600 text-2xl"
            (click)="removeChoice(choiceControl)"
          >
            <app-icon icon="close"></app-icon>
          </button>
        </div>
      </div>
    </ng-container>

    <div class="flex justify-between items-center">
      <input
        type="text"
        placeholder="Add option..."
        (keydown.enter)="onEnterNewChoice($event)"
        (blur)="onEnterNewChoice($event)"
        class="form-control mr-2"
      />

      <button
        type="button"
        (click)="newChoiceImageUploader.click()"
        class="btn btn-icon bg-transparent text-gray-500 text-4xl mr-2"
      >
        <app-icon icon="photo"></app-icon>
      </button>

      <button
        type="button"
        (click)="newChoiceAudioUploader.click()"
        class="btn btn-icon bg-transparent text-gray-500 text-4xl mr-2"
      >
        <app-icon icon="audiotrack"></app-icon>
      </button>

      <button
        type="button"
        class="btn btn-icon invisible text-gray-600 hover:text-red-600 text-2xl"
      >
        <app-icon icon="close"></app-icon>
      </button>
    </div>

    <app-upload-loading-wrapper
      #newChoiceImageUploader
      class="invisible"
      accept="image/*"
      (upload)="onImageUploadNewChoice($event)"
      text="Change Image"
    >
    </app-upload-loading-wrapper>

    <app-upload-loading-wrapper
      #newChoiceAudioUploader
      class="invisible"
      accept="audio/*"
      (upload)="onAudioUploadNewChoice($event)"
      text="Change Image"
    >
    </app-upload-loading-wrapper>
  </div>

  <app-loading
    [loading]="loading"
    wrapperClass="absolute inset-0 bg-white opacity-50 flex justify-center items-center"
  >
  </app-loading>
</div>
