<app-back-title-layout
  layoutTitle="Collection Group Permissions - Add Group"
  [loading]="!groups"
>
  <ng-container *ngIf="groups">
    <div class="my-5">
      <app-search-bar
        #search
        [(ngModel)]="search.value"
        name="search"
        placeholder="Search"
      ></app-search-bar>
    </div>

    <app-item-list
      [items]="groups | filterAll: search.value"
      [itemTemplate]="itemTemplate"
    >
    </app-item-list>
  </ng-container>
</app-back-title-layout>

<ng-template #itemTemplate let-group="item">
  <button class="w-full flex items-center" (click)="selectGroup(group)">
    <app-icon icon="person" class="mr-2 text-gray-700 text-4xl"></app-icon>

    <div class="text-left">
      <div class="truncate font-semibold leading-tight">
        {{ group.name }}
      </div>

      <div class="text-sm text-gray-700">
        {{ group.userIds?.length || "0" }} users
      </div>
    </div>
  </button>
</ng-template>
