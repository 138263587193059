import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-buribooks-image-spread-edit",
  templateUrl: "./buribooks-image-spread-edit.component.html",
  styleUrls: ["./buribooks-image-spread-edit.component.scss"],
})
export class BuribooksImageSpreadEditComponent implements OnInit {
  @Input()
  spread: any;

  @Input()
  leftPageNumber: number;
  @Input()
  rightPageNumber: number;

  @Input()
  leftFile: File;
  @Input()
  rightFile: File;

  @Output()
  upload = new EventEmitter<any>();

  @Input()
  hideControls = false;

  constructor() {}

  ngOnInit(): void {}

  onUpload(event, position: string) {
    if (position == "right") {
      this.spread.rightUri = event.uri;
    } else if (position == "left") {
      this.spread.leftUri = event.uri;
    }

    this.upload.emit();
  }
}
