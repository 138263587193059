<div class="w-32 bg-white rounded shadow-md">
  <div
    class="py-1 px-2 text-xs uppercase rounded-t flex justify-between"
    [style.backgroundColor]="cardHeaderBackgroundColor"
  >
    <div [style.color]="cardHeaderForegroundColor" class="truncate">
      {{ resource | resourceType }}
    </div>

    <app-icon *ngIf="isChecked" class="text-green-500" icon="check_circle">
    </app-icon>
    <app-icon *ngIf="isErrored" class="text-error-dark" icon="error">
    </app-icon>
  </div>

  <div class="relative h-32">
    <img
      *ngIf="resource?.coverUri"
      class="object-contain w-full h-full bg-gray-200"
      [src]="resource.coverUri"
      loading="lazy"
    />

    <div
      *ngIf="!isInfoHidden"
      class="absolute inset-0 flex flex-col justify-end"
      style="
        background: linear-gradient(transparent 0%, transparent 50%, white 80%);
      "
    >
      <div class="p-1 flex justify-between items-end">
        <div class="min-w-0">
          <div class="font-semibold text-xs leading-tight line-clamp-2">
            {{ resource?.name }}
          </div>

          <div class="text-xs text-gray-500">
            {{ resource?.flavorTextSpan }}
          </div>
        </div>

        <app-icon
          *ngIf="isDownloaded"
          class="leading-none"
          [style.color]="cardHeaderBackgroundColor"
          icon="cloud_download"
        >
        </app-icon>
      </div>

      <div></div>
    </div>
  </div>
</div>
