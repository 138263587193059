import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Group, PassService } from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-collection-permissions-add-group",
  templateUrl: "./collection-permissions-add-group.component.html",
  styleUrls: ["./collection-permissions-add-group.component.scss"],
})
export class CollectionPermissionsAddGroupComponent implements OnInit {
  groups: Group[];

  constructor(
    private passService: PassService,
    private route: ActivatedRoute,
    private location: Location
  ) {}

  ngOnInit(): void {
    let { collection_uuid: collectionUuid } = this.route.snapshot.queryParams;

    this.passService
      .listGroups(null, null, null, ["roles"])
      .subscribe((result) => {
        this.groups = result.groups.filter((group) =>
          group.roles.some(
            (role) => role.name !== `crud:collections:${collectionUuid}`
          )
        );
      });
  }

  async selectGroup(group: Group) {
    let { collection_uuid: collectionUuid } = this.route.snapshot.queryParams;

    await this.passService
      .createRole({
        role: {
          name: `crud:collections:${collectionUuid}`,
          groupIds: [group.id],
          permissionsAttributes: [
            {
              name: `read:collections:${collectionUuid}`,
            },
          ],
          applicationId: localStorage.getItem("application_id"),
        },
      })
      .toPromise();

    this.location.back();
  }
}
