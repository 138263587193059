<app-back-title-layout
  [loading]="!courseDetail || deleteSubmissionInProgress"
  layoutTitleIcon="person"
  layoutTitle="{{ user?.firstName }} {{ user?.lastName }}"
  [titleIconButtons]="[
    {
      icon: 'mode',
      routerLink: '../../edit',
      queryParamsHandling: 'merge'
    }
  ]"
>
  <div backTitleLayoutBeforeMain>
    <p class="my-2">{{ user?.email }}</p>

    <ng-container *ngFor="let group of user?.groups">
      <span
        class="inline-block rounded-full text-secondary-500 bg-secondary-100 px-2 py-0.5 m-0.5 max-w-xs truncate text-sm"
      >
        {{ group.name }}
      </span>
    </ng-container>

    <a
      class="block uppercase text-primary-600 text-sm"
      [routerLink]="['/users', 'profile', 'activity-logs']"
      queryParamsHandling="merge"
    >
      <app-icon icon="list_alt"></app-icon>

      View Activity Log
    </a>
  </div>

  <ng-container *ngIf="courseDetail">
    <section>
      <h2 class="form-heading">Course Progress</h2>

      <div class="my-4" [ngSwitch]="courseDetail.courseStatus">
        <span
          *ngSwitchCase="'passed'"
          class="rounded-full text-success-700 bg-success-100 px-2 py-1"
        >
          Completed
        </span>
        <span
          *ngSwitchCase="'in_progress'"
          class="rounded-full text-secondary-700 bg-secondary-100 px-2 py-1"
        >
          In progress
        </span>
        <span
          *ngSwitchCase="'failed'"
          class="rounded-full text-error-700 bg-error-100 px-2 py-1"
        >
          Failed
        </span>

        <span
          *ngSwitchCase="'not_started'"
          class="rounded-full text-gray-700 bg-gray-100 px-2 py-1"
        >
          Not started
        </span>

        <span
          *ngSwitchCase="'needs_attention'"
          class="rounded-full text-warning-700 bg-warning-100 px-2 py-1"
        >
          Needs attention
        </span>
      </div>

      <div class="flex items-center">
        <img
          [src]="courseDetail.coverUri"
          alt=""
          class="w-20 h-20 object-contain mr-5"
        />
        <div>
          <app-icon icon="school"></app-icon>
          <div class="font-bold">{{ courseDetail.courseName }}</div>
        </div>
      </div>
    </section>

    <section class="my-5">
      <button
        (click)="setAsPassed()"
        *ngIf="!courseDetail.isCertificateActive"
        class="btn btn-primary"
      >
        Set as passed
      </button>
    </section>

    <section class="my-5" *ngIf="courseDetail.certificateUrl">
      <a
        class="btn btn-primary"
        *ngIf="courseDetail.isCertificateActive"
        [href]="courseDetail.certificateUrl"
        target="_blank"
      >
        <app-icon icon="insert_drive_file"></app-icon>

        View Certificate
      </a>

      <button
        (click)="revokeCertificate()"
        *ngIf="courseDetail.isCertificateActive"
        class="block my-2 text-primary-700"
      >
        <app-icon icon="cancel"></app-icon>
        Revoke Certificate
      </button>

      <p *ngIf="!courseDetail.isCertificateActive" class="my-2 text-error-700">
        <app-icon icon="cancel"></app-icon>
        Certificate Revoked
      </p>
    </section>

    <!-- <section class="my-10">
      <h2 class="form-heading">Assessments</h2>

      <hr class="my-5" />

      <app-item-list
        [items]="assessmentItems"
        [itemTemplate]="assessmentItemsTemplate"
      >
      </app-item-list>
    </section> -->

    <hr class="my-5" />

    <section class="my-10">
      <app-item-list
        [items]="courseDetail.sections"
        [itemTemplate]="sectionTemplate"
      >
      </app-item-list>
    </section>
  </ng-container>
</app-back-title-layout>

<ng-template #assessmentItemsTemplate let-item="item">
  <div class="flex justify-between">
    <img [src]="item.coverUri" alt="" class="w-16 h-16 object-contain mr-2" />

    <div class="flex-1 min-w-0">
      <app-icon icon="{{ item.blockType | resourceTypeIcon }}"></app-icon>

      <div class="font-bold truncate">
        {{ item.blockName }}
      </div>
    </div>

    <div class="flex-1 text-sm" [ngSwitch]="item.status">
      <span
        *ngSwitchCase="'passed'"
        class="rounded-full text-success-700 bg-success-100 px-2 py-1"
      >
        Passed
      </span>
      <span
        *ngSwitchCase="'in_progress'"
        class="rounded-full text-secondary-700 bg-secondary-100 px-2 py-1"
      >
        In progress
      </span>
      <span
        *ngSwitchCase="'failed'"
        class="rounded-full text-error-700 bg-error-100 px-2 py-1"
      >
        Failed
      </span>

      <span
        *ngSwitchCase="'not_started'"
        class="rounded-full text-gray-700 bg-gray-100 px-2 py-1"
      >
        Not started
      </span>

      <span
        *ngSwitchCase="'needs_attention'"
        class="rounded-full text-warning-700 bg-warning-100 px-2 py-1"
      >
        Needs attention
      </span>
    </div>

    <ng-container
      *ngIf="item.submissionHistoryObs | async as submissionHistory"
    >
      <div>
        <a
          class="text-sm"
          *ngIf="submissionHistory[0]"
          [routerLink]="['/_exams', 'submission-responses']"
          [queryParams]="{
            timeslot_uuid: item.examTimeslotUuid,
            submission_uuid: submissionHistory[0].uuid,
            block_status_uuid: item.blockStatusUuid
          }"
        >
          <b
            class="font-bold"
            [ngClass]="{
              'text-success-700': item.status === 'passed',
              'text-error-700': item.status === 'failed'
            }"
          >
            {{ submissionHistory[0].totalScore }} points
          </b>
          latest attempt on
          {{ submissionHistory[0].createdAt | date : "medium" }}
        </a>

        <details *ngIf="submissionHistory.length > 1">
          <summary class="text-gray-500 text-xs my-2">
            view previous attempts ({{ submissionHistory.length - 1 }})
          </summary>

          <ul class="ml-10">
            <ng-container *ngFor="let history of submissionHistory.slice(1)">
              <li class="text-sm list-disc">
                <a
                  [routerLink]="['/_exams', 'submission-responses']"
                  [queryParams]="{
                    timeslot_uuid: item.examTimeslotUuid,
                    submission_uuid: history.uuid,
                    block_status_uuid: item.blockStatusUuid
                  }"
                  class="block py-1"
                >
                  <b class="font-bold"> {{ history.totalScore }} points </b>
                  submitted on
                  {{ history.createdAt | date }}
                </a>
              </li>
            </ng-container>
          </ul>
        </details>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #sectionTemplate let-item="item">
  <h3 class="text-lg font-bold mt-10">
    {{ item.name }}
  </h3>

  <app-item-list [items]="item.blocks" [itemTemplate]="blockTemplate">
  </app-item-list>
</ng-template>

<ng-template #blockTemplate let-item="item">
  <div class="flex items-center">
    <img [src]="item.coverUri" alt="" class="w-20 h-20 object-contain mr-5" />

    <div class="flex-1 min-w-0">
      <div class="uppercase text-gray-500">
        <app-icon icon="{{ item.type | resourceTypeIcon }}"></app-icon>
      </div>
      <div class="font-bold truncate">
        {{ item.name }}
      </div>
    </div>

    <div class="flex-1 text-sm" [ngSwitch]="item.status">
      <span
        *ngSwitchCase="'passed'"
        class="rounded-full text-success-700 bg-success-100 px-2 py-1"
      >
        Passed
      </span>
      <span
        *ngSwitchCase="'in_progress'"
        class="rounded-full text-secondary-700 bg-secondary-100 px-2 py-1"
      >
        In progress
      </span>
      <span
        *ngSwitchCase="'failed'"
        class="rounded-full text-error-700 bg-error-100 px-2 py-1"
      >
        Failed
      </span>

      <span
        *ngSwitchCase="'not_started'"
        class="rounded-full text-gray-700 bg-gray-100 px-2 py-1"
      >
        Not started
      </span>

      <span
        *ngSwitchCase="'needs_attention'"
        class="rounded-full text-warning-700 bg-warning-100 px-2 py-1"
      >
        Needs attention
      </span>
    </div>

    <ng-container *ngIf="item.examTimeslotUuid">
      <ng-container
        *ngIf="item.submissionHistoryObs | async as submissionHistory"
      >
        <div>
          <div>
            <a
              class="text-sm"
              *ngIf="submissionHistory[0]"
              [routerLink]="['/_exams', 'submission-responses']"
              [queryParams]="{
                timeslot_uuid: item.examTimeslotUuid,
                submission_uuid: submissionHistory[0].uuid,
                block_status_uuid: item.blockStatusUuid
              }"
            >
              <b
                class="font-bold"
                [ngClass]="{
                  'text-success-700': item.status === 'passed',
                  'text-error-700': item.status === 'failed',
                  hidden: submissionHistory[0].totalScore === null
                }"
              >
                {{ submissionHistory[0].totalScore }} points
              </b>
              latest attempt on
              {{ submissionHistory[0].modifiedAt | date : "medium" }}
            </a>

            <ng-container *ngIf="submissionHistory[0]">
              <app-dropdown-layout toggleClass="px-3" class="float-right">
                <span dropdownToggle>&#8942;</span>

                <div dropdownContent>
                  <ul>
                    <li>
                      <a
                        (click)="deleteAttempt(item.blockStatusUuid)"
                        class="w-full cursor-pointer text-sm block px-4 py-2 text-gray-800 hover:bg-primary hover:text-white"
                        >Delete recent attempt</a
                      >
                    </li>
                    <li>
                      <a
                        (click)="deleteAttempt(item.blockStatusUuid, true)"
                        class="w-full cursor-pointer text-sm block px-4 py-2 text-gray-800 hover:bg-primary hover:text-white"
                        >Delete ALL attempts</a
                      >
                    </li>
                  </ul>
                </div>
              </app-dropdown-layout>
            </ng-container>
          </div>

          <details *ngIf="submissionHistory.length > 1">
            <summary class="text-gray-500 text-xs my-2">
              view previous attempts ({{ submissionHistory.length - 1 }})
            </summary>

            <ul class="ml-10">
              <ng-container *ngFor="let history of submissionHistory.slice(1)">
                <li class="text-sm list-disc">
                  <a
                    [routerLink]="['/_exams', 'submission-responses']"
                    [queryParams]="{
                      timeslot_uuid: item.examTimeslotUuid,
                      submission_uuid: history.uuid,
                      block_status_uuid: item.blockStatusUuid
                    }"
                    class="block py-1"
                  >
                    <b class="font-bold"> {{ history.totalScore }} points </b>
                    submitted on
                    {{ history.createdAt | date }}
                  </a>
                </li>
              </ng-container>
            </ul>
          </details>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-template>
