<app-back-title-layout [loading]="loading">
  <div class="flex w-full justify-end my-6">
    <button
      class="text-gray-400 text-2xl"
      [routerLink]="['/idis', 'users', 'settings', 'form-editor']"
      [queryParamsHandling]="'merge'"
      [queryParams]="{ tab_uuid: tabUuid }"
    >
      <app-icon icon="settings"></app-icon>
    </button>
  </div>
  <div
    class="w-full flex flex-col py-10 px-6 border rounded-lg shadow-sm bg-white"
  >
    <div class="w-2/3 mb-10">
      <app-search-bar-query></app-search-bar-query>
    </div>

    <!-- List of form submissions of external training documents here  -->

    <ng-container *ngFor="let formSubmission of formSubmissions; let i = index">
      <div
        class="p-4 flex justify-center border rounded-lg bg-white mb-4"
        *ngIf="formSubmission.fieldEntries.length > 0"
      >
        <!-- name here  -->
        <div class="flex items-center">
          <img
            [src]="formSubmission.user.imageUrl"
            *ngIf="formSubmission.user.imageUrl"
            class="text-white text-2xl leading-none mx-1 bg-gray-200 rounded-full items-center justify-center h-8 w-8"
          />
          <div class="font-bold text-lg">
            {{
              formSubmission.user.firstName + " " + formSubmission.user.lastName
            }}
          </div>
        </div>

        <!-- user field entries here  -->
        <div class="w-full flex flex-col">
          <ng-container
            *ngFor="
              let fieldEntry of formSubmission.fieldEntries;
              let j = index
            "
          >
            <ng-container
              *ngIf="
                fieldEntry.fieldNeedsApproval &&
                fieldEntry.approvalStatus === 'pending'
              "
            >
              <div class="flex w-full justify-between">
                <div>
                  Added
                  {{
                    fieldEntry.valueStr ||
                      fieldEntry.valueBool ||
                      fieldEntry.valueFileUrl ||
                      fieldEntry.valueInt
                  }}
                  in
                  <span class="text-primary-700">{{
                    fieldEntry.fieldDisplayName
                  }}</span>
                </div>
                <div class="flex">
                  <button
                    type="button"
                    (click)="verifyFieldEntry(fieldEntry, true, i, j)"
                    class="py-1 px-2 border border-success-500 rounded mr-4 text-success-500"
                  >
                    Verify
                  </button>
                  <button
                    type="button"
                    (click)="verifyFieldEntry(fieldEntry, false, i, j)"
                    class="py-1 px-2 border rounded border-error-500 text-error-500"
                  >
                    Reject
                  </button>
                </div>
              </div>
              <hr class="border my-4" />
            </ng-container>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</app-back-title-layout>
