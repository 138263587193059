<app-back-title-layout [layoutTitle]="title" [loading]="loading">
  <form class="w-full" (ngSubmit)="onSave()" [formGroup]="formGroup">
    <div class="flex justify-end mb-6">
      <!-- <button
        type="button"
        class="text-gray-600 mr-6 text-2xl"
        (click)="deleteForm()"
      >
        <app-icon icon="delete"></app-icon>
      </button> -->
      <button class="btn btn-primary" type="submit">save</button>
    </div>
    <button
      type="button"
      class="btn btn-primary-outline w-full mb-6"
      (click)="addSection()"
    >
      <app-icon icon="add_circle_outline"></app-icon> add section
    </button>

    <!-- form editor here  -->
    <div class="py-10 px-6 flex flex-col rounded-lg bg-white border shadow-sm">
      <!-- drag and drop fieldGroups  -->
      <div
        appDnd
        cdkDropList
        (cdkDropListDropped)="fieldGroupDrop($event)"
        class="w-full"
      >
        <div
          formArrayName="fieldGroups"
          class="w-full"
          cdkDrag
          *ngFor="let fieldGroup of fieldGroups.controls; let i = index"
        >
          <div class="flex w-full" [formGroupName]="i">
            <button
              type="button"
              class="p-3 text-gray-600 flex items-start"
              cdkDragHandle
            >
              <app-icon icon="unfold_more"></app-icon>
            </button>
            <div class="w-full">
              <div class="flex items-center w-full mb-8">
                <input
                  placeholder="Enter Form Title Here"
                  formControlName="fieldGroup"
                  class="font-bold border-b border-gray-400 w-full"
                />
                <app-dropdown-layout position="right">
                  <div dropdownToggle>
                    <app-icon icon="more_vert"></app-icon>
                  </div>
                  <div dropdownContent>
                    <button
                      type="button"
                      class="w-full py-1 px-2 text-left text-primary hover:bg-primary hover:text-white"
                      (click)="deleteSection(fieldGroup, i)"
                    >
                      Delete
                    </button>
                  </div>
                </app-dropdown-layout>
              </div>
              <button
                type="button"
                (click)="addField(i)"
                class="btn text-primary-700 bg-primary-100 w-full p-1 mb-8"
              >
                <app-icon icon="add_circle_outline"></app-icon> add field
              </button>
              <div
                appDnd
                cdkDropList
                [cdkDropListData]="fieldGroup.fields"
                (cdkDropListDropped)="fieldDrop($event, i)"
                class="w-full"
              >
                <div
                  formArrayName="fields"
                  class="w-full"
                  cdkDrag
                  *ngFor="let field of getFields(i).controls; let j = index"
                >
                  <div [formGroupName]="j" class="w-full mb-8">
                    <div class="flex w-full items-center justify-between">
                      <div
                        class="text-gray-400 text-2xl flex items-center w-full"
                      >
                        <ng-container [ngSwitch]="field.value.fieldType">
                          <ng-container *ngSwitchCase="'short_text'">
                            <app-icon icon="text_fields"></app-icon>
                          </ng-container>
                          <ng-container *ngSwitchCase="'dropdown'">
                            <app-icon icon="arrow_drop_down_circle"></app-icon>
                          </ng-container>
                          <ng-container *ngSwitchCase="'paragraph'">
                            <app-icon icon="notes"></app-icon>
                          </ng-container>
                          <ng-container *ngSwitchCase="'file_upload'">
                            <app-icon icon="upload"></app-icon>
                          </ng-container>
                          <ng-container *ngSwitchCase="'email'">
                            <app-icon icon="mail"></app-icon>
                          </ng-container>
                          <ng-container *ngSwitchCase="'date'">
                            <app-icon icon="event"></app-icon>
                          </ng-container>
                          <ng-container *ngSwitchCase="'time'">
                            <app-icon icon="event"></app-icon>
                          </ng-container>
                          <ng-container *ngSwitchCase="'checkbox'">
                            <app-icon icon="check_box"></app-icon>
                          </ng-container>
                          <ng-container *ngSwitchCase="'radio'">
                            <app-icon icon="radio_button_checked"></app-icon>
                          </ng-container>
                          <ng-container *ngSwitchCase="'number'">
                            <app-icon icon="looks_one"></app-icon>
                          </ng-container>
                        </ng-container>
                        <input
                          class="text-gray-500 text-base ml-2 border-dashed border-b w-full"
                          formControlName="name"
                          placeholder="Enter field name here"
                        />
                      </div>
                      <app-dropdown-layout position="right">
                        <div dropdownToggle>
                          <app-icon icon="more_vert"></app-icon>
                        </div>
                        <div dropdownContent class="w-full">
                          <button
                            type="button"
                            class="w-full py-1 px-2 text-left text-primary hover:bg-primary hover:text-white"
                            (click)="changeFieldType(fieldGroup, field, j)"
                          >
                            Change Field Type
                          </button>
                          <!-- <button
                            type="button"
                            class="w-full py-1 px-2 text-left text-primary hover:bg-primary hover:text-white"
                            (click)="requiresValidation(fieldGroup, field, j)"
                          >
                            Requires Validation
                          </button> -->
                          <button
                            type="button"
                            class="w-full py-1 px-2 text-left text-primary hover:bg-primary hover:text-white"
                            (click)="deleteField(fieldGroup, field, j, i)"
                          >
                            Delete
                          </button>
                        </div>
                      </app-dropdown-layout>
                    </div>

                    <div class="flex w-full items-center justify-between">
                      <div class="flex w-full items-center">
                        <button
                          type="button"
                          class="p-3 text-gray-600"
                          cdkDragHandle
                        >
                          <app-icon icon="unfold_more"></app-icon>
                        </button>
                        <label class="text-sm">
                          <input type="checkbox" formControlName="isRequired" />
                          required</label
                        >
                      </div>
                      <div class="flex w-full items-center">
                        <label class="text-sm">
                          <input
                            type="checkbox"
                            formControlName="needsApproval"
                          />
                          needs verification</label
                        >
                      </div>
                      <div class="flex items-center">
                        <div class="text-sm text-gray-500 mr-2">
                          employee can:
                        </div>
                        <app-dropdown-layout position="right">
                          <div dropdownToggle class="flex">
                            <app-icon
                              [icon]="getDropdownIcon(field)"
                              class="text-primary-600"
                            ></app-icon>
                            <app-icon icon="keyboard_arrow_down"> </app-icon>
                          </div>
                          <div dropdownContent class="w-full">
                            <button
                              type="button"
                              class="w-full py-1 px-2 text-left"
                              (click)="setEmployeePermissions(field, 'view')"
                            >
                              <app-icon
                                icon="visibility"
                                class="text-primary-600 mr-1"
                              ></app-icon>
                              <span class="text-sm">View</span>
                              <div class="text-xs">
                                Employee can view this field
                              </div>
                            </button>
                            <button
                              type="button"
                              class="w-full py-1 px-2 text-left"
                              (click)="setEmployeePermissions(field, 'edit')"
                            >
                              <app-icon
                                icon="mode"
                                class="text-primary-600 mr-1"
                              ></app-icon>
                              <span class="text-sm">View and Edit</span>
                              <div class="text-xs">
                                Employee can view and edit this field
                              </div>
                            </button>
                          </div>
                        </app-dropdown-layout>
                      </div>
                    </div>

                    <!-- Field Choices here  -->
                    <ng-container
                      *ngIf="
                        field.value.fieldType === 'dropdown' ||
                        field.value.fieldType === 'checkbox' ||
                        field.value.fieldType === 'radio'
                      "
                    >
                      <div class="flex w-full">
                        <div class="text-sm">
                          Options ({{ getFieldChoices(i, j).controls.length }})
                        </div>
                        <!-- <div>see more</div> -->
                      </div>
                      <div
                        formArrayName="fieldChoices"
                        *ngFor="
                          let choice of getFieldChoices(i, j).controls;
                          let k = index
                        "
                      >
                        <div [formGroupName]="k">
                          <div class="flex justify-between items-center">
                            <input
                              type="text"
                              formControlName="name"
                              class="form-control mb-1"
                            />
                            <button
                              type="button"
                              class="btn btn-icon bg-transparent text-gray-600 hover:text-red-600 text-2xl"
                              (click)="removeChoice(field, k)"
                            >
                              <app-icon icon="close"></app-icon>
                            </button>
                          </div>
                        </div>
                      </div>
                      <input
                        type="text"
                        placeholder="Add option..."
                        (keydown.enter)="onEnterNewOption($event, field)"
                        (blur)="onEnterNewOption($event, field)"
                        class="form-control mr-2"
                      />
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</app-back-title-layout>
