<div
  class="w-full h-full flex flex-col justify-center items-center select-none"
  style="background: white; background: rgba(255, 255, 255, 0.527)"
  (click)="retry()"
  [ngClass]="{
    'cursor-pointer': isError
  }"
>
  <ng-container *ngIf="!isError">
    <app-loading [loading]="true" position="relative"></app-loading>
  </ng-container>

  <ng-container *ngIf="isError">
    <app-icon
      icon="error"
      class="text-6xl leading-none text-red-300"
    ></app-icon>

    <span class="text-primary underline"> Tap to retry </span>
  </ng-container>
</div>
