import { Component, OnInit } from "@angular/core";
import {
  User,
  CoursesService,
  CourseUserEnrollment,
  PassService,
} from "src/app/sinigangnababoywithgabi";
import { Subscription, from, of, throwError } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import {
  catchError,
  switchMap,
  mergeMap,
  map,
  tap,
  toArray,
} from "rxjs/operators";
import { TaskService, Task } from "src/app/services/task.service";

// THIS COMPONENT PROBABLY NEEDS REWORK
@Component({
  selector: "app-course-batch-enrollments-email-list",
  templateUrl: "./course-batch-enrollments-email-list.component.html",
})
export class CourseBatchEnrollmentsEmailListComponent implements OnInit {
  users: User[];

  userEnrollments: CourseUserEnrollment[];

  emailTasks: {
    email: string;
    task: Task;
  }[];

  loading: boolean;

  subscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private passService: PassService,
    private coursesService: CoursesService,
    private taskService: TaskService
  ) {}

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
  ngOnInit(): void {}

  extractEmails(rawString: string) {
    let emailRaw = rawString
      .trim()
      .split("\n")
      .map((val) => val.trim().toLowerCase())
      .filter((val) => val);

    return [...new Set(emailRaw)];
  }

  enroll(value: string) {
    let { schedule_uuid: scheduleUuid } = this.route.snapshot.queryParams;

    // Remove current subscription if any
    this.subscription?.unsubscribe();

    this.loading = true;
    this.emailTasks = null;

    // Clean and extract emails
    let rawEmails: string[] = this.extractEmails(value);

    // Remove email duplicates
    let emails: string[] = [...new Set(rawEmails)];

    this.subscription = of(null)
      .pipe(
        // Get current enrollments
        switchMap(() =>
          this.coursesService.scheduleEnrollmentsList(scheduleUuid)
        ),

        tap((enrollments) => {
          this.userEnrollments = enrollments;
        }),

        // Create task for each email
        map(() =>
          emails.map((email) => {
            return {
              email,
              task: this.constructEmailTask(email),
            };
          })
        ),

        tap((result) => {
          this.emailTasks = result;
        }),

        // Execute each task concurrently
        switchMap((emailTasks) => {
          return from(emailTasks).pipe(
            mergeMap((emailTask) => emailTask.task.start(), 5),
            toArray()
          );
        }),
        switchMap(() =>
          this.coursesService.scheduleSchedulestatsUpdate(scheduleUuid)
        )
      )
      .subscribe((result) => {
        this.loading = false;
      });
  }

  constructEmailTask(email: string) {
    let { schedule_uuid: scheduleUuid, course_uuid: courseUuid } =
      this.route.snapshot.queryParams;

    return this.taskService.createTask({
      context: {
        email,
        scheduleUuid,
        courseUuid,
      },
      steps: [
        {
          successMessage: "User is registered in system.",
          stepMessage: "Checking if user is in the system.",
          fn: this.checkUserExistsTask(),
        },
        {
          successMessage: "User is enrolled.",
          stepMessage: "Checking enrollment",
          fn: this.addUserEnrollmentTask(),
        },
      ],
    });
  }

  checkUserExistsTask() {
    return (context) => {
      // Query user list via email
      return this.passService.listUsers(context.email).pipe(
        map((result) =>
          result.users.find((user) => user.email === context.email)
        ),
        switchMap((user) => {
          if (!user) {
            return throwError(
              () => new Error("User is not registered in the system.")
            );
          } else {
            context.user = user;
            return of(null);
          }
        })
      );
    };
  }

  addUserEnrollmentTask() {
    return (context) => {
      let enrollment = this.userEnrollments.find(
        (e) => e.user === context.user.id
      );

      if (enrollment) {
        return throwError(() => new Error("User is already enrolled."));
      } else {
        return this.coursesService
          .courseEnrollmentCreate(context.courseUuid, {
            schedule: context.scheduleUuid,
            user: context.user.id,
            isActive: true,
          })
          .pipe(
            catchError((err) => {
              return throwError(() => new Error("Failed enrollment."));
            })
          );
      }
    };
  }

  get pendingCount() {
    return this.emailTasks?.filter((item) => item.task.isPending).length;
  }

  get successCount() {
    return this.emailTasks?.filter((item) => item.task.isDone).length;
  }

  get errorCount() {
    return this.emailTasks?.filter((item) => item.task.isError).length;
  }
}
