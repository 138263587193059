import { Component, OnInit } from "@angular/core";
import {
  CourseSchedule,
  CoursesService,
  CourseUserEnrollment,
} from "src/app/sinigangnababoywithgabi";
import { ActivatedRoute } from "@angular/router";
import { LinkList } from "src/app/app-common-module/layouts/profile-link-list/profile-link-list.component";
import { firstValueFrom } from "rxjs";

@Component({
  selector: "app-course-batch-profile",
  templateUrl: "./course-batch-profile.component.html",
})
export class CourseBatchProfileComponent implements OnInit {
  schedule: CourseSchedule;
  enrollments: CourseUserEnrollment[];

  linkLists: LinkList[];

  constructor(
    private route: ActivatedRoute,
    private coursesService: CoursesService
  ) {}

  async ngOnInit() {
    let { schedule_uuid: scheduleUuid, course_uuid: courseUuid } =
      this.route.snapshot.queryParams;

    this.schedule = await firstValueFrom(
      this.coursesService.scheduleRead(scheduleUuid)
    );

    this.enrollments = await firstValueFrom(
      this.coursesService.scheduleEnrollmentsList(scheduleUuid, null, true)
    );

    this.linkLists = [
      {
        icon: "assignment",
        name: "Batch Details",
        routerLinkOptions: {
          routerLink: ["../edit"],
          queryParamsHandling: "merge",
        },
      },
      {
        icon: "group",
        name: "User Enrollment",
        description:
          "This batch has " + (this.enrollments?.length || "0") + " user/s",
        routerLinkOptions: {
          routerLink: ["/_courses", "batches", "enrollments", "list"],
          queryParamsHandling: "merge",
        },
      },
      {
        icon: "date_range",
        name: "Access Period",
        description: "Change batch access period",
        routerLinkOptions: {
          routerLink: ["./publish"],
          queryParamsHandling: "merge",
        },
      },
      {
        icon: "date_range",
        name: "Enrollment Period",
        description: "Change enrollment period",
        routerLinkOptions: {
          routerLink: ["./enrollment-period"],
          queryParamsHandling: "merge",
        },
      },
      {
        icon: "policy",
        name: "Permissions",
        routerLinkOptions: {
          routerLink: ["../permissions"],
          queryParamsHandling: "merge",
        },
      },
    ];
  }
}
