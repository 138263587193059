<div class="ml-3">
  <div
    [id]="'block-list-' + blockListIndex"
    cdkDropList
    [cdkDropListData]="{ sectionItem: sectionItem }"
    (cdkDropListDropped)="onBlockDrop($event)"
    [cdkDropListDisabled]="!sectionItem.isInEditMode"
    [cdkDropListSortingDisabled]="!sectionItem.isInEditMode"
  >
    <button
      *ngIf="sectionItem.blockItems.length > 0"
      (click)="onAddBlock(-1)"
      class="block w-full text-center text-blue-500 transition-opacity opacity-0 bg-blue-50 hover:bg-blue-200 hover:text-blue-600"
      [ngClass]="{
        'opacity-100': blockHoverIndex == 0
      }"
      (mouseenter)="blockHoverIndex = 0"
      (mouseleave)="blockHoverIndex = null"
    >
      <app-icon icon="add_circle" class="mx-2"></app-icon>
      <span class="text-sm font-semibold uppercase">Add resource</span>
    </button>

    <button
      *ngIf="sectionItem.blockItems.length === 0"
      (click)="onAddBlock(0)"
      class="w-full py-5 text-center text-blue-300 bg-blue-50 hover:text-blue-400"
    >
      <app-icon icon="add_circle_outline" class="text-7xl"></app-icon>
      <div class="text-xl">The section is empty.</div>
      <div class="text-xl">Click to add a resource.</div>
    </button>

    <ng-container
      *ngFor="let blockItem of sectionItem.blockItems; let i = index"
    >
      <div
        cdkDrag
        class="flex items-start gap-1 my-1 group"
        (mouseenter)="blockHoverIndex = i"
        (mouseleave)="blockHoverIndex = null"
      >
        <button cdkDragHandle class="mt-1" *ngIf="sectionItem.isInEditMode">
          <app-icon icon="unfold_more" class="text-gray-600"></app-icon>
        </button>

        <div class="w-full py-2 border-b border-gray-200">
          <div
            class="flex flex-grow min-w-0 gap-2 transition-all duration-100 rounded"
          >
            <img
              class="flex-none object-contain w-20 h-20"
              [src]="blockItem.coverUri || 'assets/imgplaceholder.png'"
            />

            <div class="flex-grow min-w-0">
              <div [title]="blockItem | resourceType" class="text-gray-600">
                <app-icon [icon]="blockItem | resourceTypeIcon"></app-icon>
              </div>

              <div class="font-semibold truncate">
                <a
                  *ngIf="sectionItem.isInEditMode"
                  [routerLink]="['/resources', 'edit']"
                  [queryParams]="{
                    resource_uuid: blockItem.resourceUuid,
                    block_uuid: blockItem.blockUuid
                  }"
                >
                  {{ blockItem.blockName }}
                </a>

                <span *ngIf="!sectionItem.isInEditMode">
                  {{ blockItem.blockName }}
                </span>
                <!-- <input
                    type="text"
                    class="w-full font-semibold bg-transparent border-b-2 border-dashed border-primary"
                    [(ngModel)]="blockItem.blockName"
                  /> -->
              </div>

              <!-- {{ block | json }} -->
            </div>

            <app-dropdown-layout
              position="right"
              toggleClass="btn btn-icon bg-transparent text-2xl text-gray-500"
            >
              <div dropdownToggle>
                <app-icon icon="more_vert"> </app-icon>
              </div>
              <div dropdownContent>
                <!-- <button
                  class="w-full px-2 py-1 text-left text-primary hover:bg-primary hover:text-white"
                >
                  Block order
                </button> -->
                <button
                  (click)="onDeleteBlock(blockItem, i)"
                  class="w-full px-2 py-1 text-left text-primary hover:bg-primary hover:text-white"
                >
                  Delete resource
                </button>
              </div>
            </app-dropdown-layout>
          </div>

          <div
            *ngIf="blockItem.requiredForCertification"
            class="my-1 text-sm italic text-blue-500"
          >
            *required for certification
          </div>

          <div class="flex gap-1 my-1 text-sm">
            <div>
              <a
                [routerLink]="['/_courses', 'block-edit']"
                [queryParams]="{ block_uuid: blockItem.blockUuid }"
                queryParamsHandling="merge"
                class="px-1 py-0.5 rounded text-white bg-primary-700"
              >
                <app-icon icon="edit"></app-icon>
              </a>
            </div>

            <div>
              <ng-container
                *ngIf="
                  (blockItem.prerequisites &&
                    blockItem.prerequisites.length > 0) ||
                    blockItem.openDate ||
                    blockItem.customPassingMark ||
                    blockItem.daysTilExpired > 0;
                  else noPrereq
                "
              >
                <div
                  *ngIf="blockItem.openDate"
                  class="text-sm text-primary-600"
                >
                  - <app-icon icon="schedule"></app-icon> Unlocks at
                  <span class="font-bold">{{
                    blockItem.openDate | date : "medium"
                  }}</span>
                </div>
                <div
                  *ngIf="blockItem.daysTilExpired > 0"
                  class="text-sm text-primary-600"
                >
                  - <app-icon icon="timer"></app-icon> This resource expires in
                  <span class="font-bold"
                    >{{ blockItem.daysTilExpired }}
                    {{
                      blockItem.daysTilExpired === 1 ? " day" : " days"
                    }}</span
                  >
                </div>
                <div
                  *ngIf="!blockItem.passOnSubmit && blockItem.customPassingMark"
                  class="text-sm text-primary-600"
                >
                  - passed when score is at least
                  <span class="font-bold">{{
                    blockItem.customPassingMark
                  }}</span>
                </div>

                <div
                  *ngIf="
                    blockItem.prerequisites &&
                    blockItem.prerequisites.length > 0
                  "
                  class="text-sm text-primary-600"
                >
                  - {{ blockItem.prerequisites.length }}
                  {{
                    blockItem.prerequisites.length > 1
                      ? "prerequisites"
                      : "prerequisite"
                  }}
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <button
        (click)="onAddBlock(i)"
        class="block w-full text-center text-blue-500 transition-opacity opacity-0 bg-blue-50 hover:bg-blue-200 hover:text-blue-600"
        [ngClass]="{
          'opacity-100': blockHoverIndex == i || blockHoverIndex == i + 1
        }"
        (mouseenter)="blockHoverIndex = i"
        (mouseleave)="blockHoverIndex = null"
      >
        <app-icon icon="add_circle" class="mx-2"></app-icon>
        <span class="text-sm font-semibold uppercase">Add resource</span>
      </button>
    </ng-container>
  </div>
</div>

<ng-template #noPrereq>
  <span class="text-gray-400"> &nbsp; without prerequisites </span>
</ng-template>
