<app-back-title-layout [loading]="loading">
  <div class="flex w-full justify-end my-6">
    <button
      class="text-gray-400 text-2xl"
      [routerLink]="['/idis', 'users', 'settings', 'form-editor']"
      [queryParamsHandling]="'merge'"
      [queryParams]="{ tab_uuid: tabUuid }"
    >
      <app-icon icon="settings"></app-icon>
    </button>
  </div>
  <div
    class="w-full flex flex-col py-10 px-6 border rounded-lg shadow-sm bg-white"
  >
    <div class="w-2/3 mb-10">
      <app-search-bar-query></app-search-bar-query>
    </div>

    <!-- List of form submissions of training needs here  -->

    <ng-container *ngFor="let formSubmission of formSubmissions; let i = index">
      <div class="p-4 flex justify-center border rounded-lg bg-white mb-4">
        <!-- name here  -->
        <div class="flex items-center w-1/3">
          <img
            [src]="formSubmission.user.imageUrl"
            *ngIf="formSubmission.user.imageUrl"
            class="text-white text-2xl leading-none mx-1 bg-gray-200 rounded-full items-center justify-center h-8 w-8"
          />
          <div class="font-bold text-lg">
            {{
              formSubmission.user.firstName + " " + formSubmission.user.lastName
            }}
          </div>
        </div>
        <!-- user field entries here  -->
        <div class="w-full flex flex-col">
          <ng-container>
            <div class="flex w-full justify-between">
              <div>
                <span class="text-primary-700">{{ tabDisplayName }}</span>
                <div class="text-sm">{{ formSubmission.updatedAt | date }}</div>
              </div>
              <div class="flex">
                <button
                  class="py-1 px-2 border border-primary-700 rounded text-primary-700"
                >
                  Assign Training
                </button>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</app-back-title-layout>
