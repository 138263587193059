import { Pipe, PipeTransform } from "@angular/core";
import { Resource } from "src/app/sinigangnababoywithgabi";

const ResourceTypeIconMap = new Map([
  // Types
  ["video", "play_arrow"],
  ["pdf", "picture_as_pdf"],
  ["buribook-with-activity", "import_contacts"],
  ["burislides", "view_carousel"],
  ["html", "html"],
  ["xapi", "attachment"],
  ["link", "link"],
  ["audio", "music"],
  ["assessment", "assignment"],
  ["epub", "menu_book"],
  ["embed", "code"],
  ["course", "school"],
  ["casticulate", "view_carousel"],

  // Subtypes
  ["email", "email"],
  ["markdown", "text_fields"],
  ["file-upload", "how_to_vote"],
  ["freedom-wall", "chat_bubble"],
  ["google-slide", "add_to_drive"],
  ["google-sheet", "add_to_drive"],
  ["google-form", "add_to_drive"],
  ["google-doc", "add_to_drive"],
  ["flarum", "message"],
  ["youtube", "play_arrow"],
  ["canva", "photo_library"],
  ["articulate", "school"],
]);

@Pipe({
  name: "resourceTypeIcon",
})
export class ResourceTypeIconPipe implements PipeTransform {
  transform(value: any, ...args: unknown[]): string {
    let icon: string;

    if (typeof value === "object" && value !== null) {
      icon =
        ResourceTypeIconMap.get(value.subtype) ||
        ResourceTypeIconMap.get(value.type);
    } else {
      icon = ResourceTypeIconMap.get(value);
    }

    return icon || null;
  }
}
