import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../app-common-module/guards/auth.guard";
import { RouteWithData } from "src/extensions/route-with-data";
import { ReportingComponent } from "./reporting.component";
import { ReportingSnapshotFramesDisplayComponent } from "./reporting-snapshot-frames-display/reporting-snapshot-frames-display.component";
import { ReportingSnapshotsListComponent } from "./reporting-snapshots-list/reporting-snapshots-list.component";

const routes: RouteWithData[] = [
  {
    path: "reporting",
    component: ReportingComponent,
    canActivate: [AuthGuard],
    data: { layoutTitle: "Reports" },
    children: [
      {
        path: "list",
        data: {
          layoutTitle: "Reports",
        },
        component: ReportingSnapshotsListComponent,
      },
      {
        path: "snapshot-display",
        data: {
          layoutTitle: "Reports",
        },
        component: ReportingSnapshotFramesDisplayComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ReportingRoutingModule {}
