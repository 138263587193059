<div class="flex justify-between mb-2">
  <div
    *ngIf="loading"
    [@fadeInOut]
    class="absolute inset-0 bg-white bg-opacity-50 h-full"
  >
    <app-loading [loading]="true" position="relative"></app-loading>
  </div>
  <h2 class="form-heading">Save as Template</h2>
  <button mat-dialog-close>
    <app-icon [icon]="'close'" class="text-gray-400"></app-icon>
  </button>
</div>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="text-gray-400">Template Name</div>
  <input
    placeholder="Template Name"
    formControlName="tempName"
    class="form-control mb-2"
  />
  <div class="text-gray-400">Template Description</div>
  <input
    placeholder="Add a short description to define this template"
    formControlName="tempDesc"
    class="form-control mb-2"
  />
  <div class="justify-end flex">
    <button class="btn btn-primary" [disabled]="form.invalid">
      save template
    </button>
  </div>
</form>
