import {
  Component,
  ElementRef,
  forwardRef,
  HostListener,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "app-markdown-viewer-and-editor",
  templateUrl: "./markdown-viewer-and-editor.component.html",
  styleUrls: ["./markdown-viewer-and-editor.component.scss"],

  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MarkdownViewerAndEditorComponent),
      multi: true,
    },
  ],
})
export class MarkdownViewerAndEditorComponent
  implements OnInit, ControlValueAccessor
{
  @Input()
  value: string;

  @Input()
  disabled: boolean;

  @Input()
  placeholder: string = "";

  @Input()
  viewerClass = "";
  @Input()
  editorClass = "";

  @Input()
  autoResize = true;

  isInEditMode: boolean = false;
  @Input() isInCastEditor = false;

  propagateChange = (_: any) => {};

  constructor(private elementRef: ElementRef) {}

  writeValue(value: any): void {
    if (value !== undefined && value !== null) {
      this.value = value;
    }
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {}

  @HostListener("document:click", ["$event"])
  clickout(event) {
    if (!this.isInCastEditor) {
      if (this.elementRef.nativeElement.contains(event.target)) {
        this.toEditMode();
      } else {
        this.toViewMode();
      }
    }
  }

  @ViewChild("editor") editorElement: ElementRef;

  onViewerClick(event) {
    if (!this.isInCastEditor) {
      event.stopPropagation();
      event.preventDefault();
      this.toEditMode();
    }
  }

  toViewMode() {
    this.isInEditMode = false;
  }

  toEditMode() {
    this.isInEditMode = true;
  }
}
