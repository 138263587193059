import { Overlay } from "@angular/cdk/overlay";
import { TemplatePortal } from "@angular/cdk/portal";
import {
  Component,
  ElementRef,
  forwardRef,
  Input,
  TemplateRef,
  ViewContainerRef,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { merge, Observable, Subject, Subscription } from "rxjs";
import { filter } from "rxjs/operators";

interface OptionItem {
  text: string;
  value: any;
}

@Component({
  selector: "app-multi-select",
  templateUrl: "./multi-select.component.html",
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectComponent),
      multi: true,
    },
  ],
})
export class MultiSelectComponent implements ControlValueAccessor {
  @Input()
  options: OptionItem[];

  @Input()
  disabled: boolean;

  optionValueMap: Map<any, OptionItem>;
  selectedValueMap: Map<any, boolean>;

  selectedTextSubject = new Subject<string>();

  dropdownClosingActionsSub: Subscription;
  overlayRef: any;

  constructor(
    private overlay: Overlay,
    private elementRef: ElementRef,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.constructMaps();
  }

  ngOnDestroy() {
    this.dropdownClosingActionsSub?.unsubscribe();
  }

  constructMaps() {
    this.optionValueMap = new Map();
    this.selectedValueMap = new Map();

    this.options
      ?.filter((option) => option.value)
      .forEach((option) => {
        this.optionValueMap.set(option.value, option);

        this.selectedValueMap.set(option.value, false);
      });
  }

  propagateChange = (_: any) => {};

  writeValue(values: any[]): void {
    this.selectedValueMap.forEach((value, key) => {
      this.selectedValueMap.set(key, false);
    });

    if (values !== undefined && values !== null) {
      values.forEach((value) => {
        this.selectedValueMap.set(value, true);
      });
    }

    this.selectedTextSubject.next(this.getSelectedText());
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  isSelected(key: any) {
    return this.selectedValueMap.get(key);
  }

  toggleSelection(key) {
    this.selectedValueMap.set(key, !this.selectedValueMap.get(key));

    this.propagateChange(
      [...this.selectedValueMap]
        .filter(([key, value]) => key && value)
        .map(([key, value]) => key)
    );

    this.selectedTextSubject.next(this.getSelectedText());
  }

  getSelectedText() {
    // let selectedCount = [...this.selectedValueMap].filter(
    //   ([key, value]) => key && value
    // ).length;

    // return `${selectedCount || 0} item/s selected`;

    let result = [...this.selectedValueMap]
      .filter(([key, value]) => key && value)
      .map(([key, value]) => this.optionValueMap.get(key))
      .filter((item) => item)
      .map((item) => item.text)
      .join(", ");

    return result;
  }

  openDropdown(body: TemplateRef<any>) {
    this.overlayRef = this.overlay.create({
      hasBackdrop: true,
      backdropClass: "cdk-overlay-transparent-backdrop",
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      maxHeight: "50%",
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.elementRef)
        // .withFlexibleDimensions(true)
        .withPositions([
          {
            originX: "start",
            originY: "bottom",
            overlayX: "start",
            overlayY: "top",
          },
        ]),
    });

    const templatePortal = new TemplatePortal(body, this.viewContainerRef);
    this.overlayRef.attach(templatePortal);

    this.dropdownClosingActionsSub = this.dropdownClosingActions().subscribe(
      () => this.destroyDropdown()
    );
  }

  dropdownClosingActions(): Observable<any> {
    const backdropClick$ = this.overlayRef.backdropClick();
    const escKeyDown$ = this.overlayRef
      .keydownEvents()
      .pipe(filter((event: KeyboardEvent) => event.code == "Escape"));
    const detachment$ = this.overlayRef.detachments();

    return merge(backdropClick$, detachment$, escKeyDown$);
  }

  destroyDropdown(): void {
    if (!this.overlayRef) {
      return;
    }

    this.dropdownClosingActionsSub.unsubscribe();
    this.overlayRef.detach();
  }
}
