import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  Observable,
  firstValueFrom,
  map,
  of,
  switchMap,
  takeWhile,
  tap,
  timer,
} from "rxjs";
import { CoursesService } from "src/app/sinigangnababoywithgabi";

interface BlockStatusReport {
  created: string;
  status: string;
  uri: string;
}
@Component({
  selector: "app-course-block-completion-reports",
  templateUrl: "./course-block-completion-reports.component.html",
  styles: [],
})
export class CourseBlockCompletionReportsComponent implements OnInit {
  blockStatusReports: Observable<BlockStatusReport>[];
  createReportObs: Observable<any>;

  loading: boolean;

  constructor(
    private route: ActivatedRoute,
    private courseService: CoursesService
  ) {}

  async ngOnInit() {
    this.listReports();
    this.initCreateReportObs();
  }

  async listReports() {
    let { course_uuid: courseUuid } = this.route.snapshot.queryParams;

    this.blockStatusReports = await firstValueFrom(
      this.courseService.courseBlockstatusReportsList(courseUuid)
    ).then((res) =>
      res.map((item) => {
        return timer(0, 5000).pipe(
          switchMap(() => {
            if (item.status === "READY") {
              return of({
                created: item.created,
                status: item.status,
                uri: item.link,
              });
            }

            return this.courseService
              .courseBlockstatusReportsGet(item.uuid)
              .pipe(
                map((result) => ({
                  created: result.created,
                  status: result.status,
                  uri: result.link,
                }))
              );
          }),
          takeWhile((result) => result.status !== "READY", true)
        );
      })
    );
  }

  async initCreateReportObs() {
    this.createReportObs = this.courseService
      .courseBlockstatusReportsCreate(
        this.route.snapshot.queryParams.course_uuid,
        {}
      )
      .pipe(
        tap((result) => {
          this.blockStatusReports = [
            timer(0, 5000).pipe(
              switchMap(() =>
                this.courseService.courseBlockstatusReportsGet(result.uuid)
              ),
              map((result) => ({
                created: result.created,
                status: result.status,
                uri: result.link,
              })),
              takeWhile((result) => result.status !== "READY", true)
            ),
            ...this.blockStatusReports,
          ];
        })
      );
  }
}
