import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { firstValueFrom } from "rxjs";
import {
  Group,
  PassService,
  ResourcesService,
} from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-course-permissions-add-group",
  templateUrl: "./course-permissions-add-group.component.html",
})
export class CoursePermissionsAddGroupComponent implements OnInit {
  groups: Group[];

  loading: boolean;

  constructor(
    private passService: PassService,
    private route: ActivatedRoute,
    private location: Location,
    private resourcesService: ResourcesService
  ) {}

  async ngOnInit() {
    let { course_uuid: courseUuid } = this.route.snapshot.queryParams;

    let result = await firstValueFrom(
      this.passService.listGroups(null, null, null, ["roles"])
    );

    this.groups = result.groups.filter(
      (group) =>
        !group.roles.some((role) => role.name === `crud:courses:${courseUuid}`)
    );
  }

  async selectGroup(group: Group) {
    this.loading = true;
    try {
      let { course_uuid: courseUuid, resource_uuid: resourceUuid } =
        this.route.snapshot.queryParams;

      let permissionsAttributes = [
        {
          name: `read:courses:${courseUuid}`,
          description: "Can read the course",
        },
        {
          name: `update:courses:${courseUuid}`,
          description: "can update the course",
        },
        {
          name: `delete:courses:${courseUuid}`,
          description: "Can delete the course",
        },
        {
          name: `create:schedules:course_uuid=${courseUuid}`,
          description: "Can create batches inside the course",
        },
        {
          name: `read:schedules:course_uuid=${courseUuid}`,
          description: "Can read batches inside the course",
        },
      ];

      if (resourceUuid) {
        let resource = await firstValueFrom(
          this.resourcesService.getResourceByUuid(resourceUuid)
        );

        if (resource.content.resourceCollectionUuid)
          permissionsAttributes = [
            ...permissionsAttributes,
            {
              name: `read:collection:${resource.content.resourceCollectionUuid}`,
              description: `Can read course content`,
            },
            {
              name: `update:collection:${resource.content.resourceCollectionUuid}`,
              description: `Can update course content`,
            },
          ];
      }

      await firstValueFrom(
        this.passService.createRole({
          role: {
            name: `crud:courses:${courseUuid}`,
            groupIds: [group.id],
            permissionsAttributes,
            applicationId: localStorage.getItem("application_id"),
          },
        })
      );

      this.location.back();
    } catch (err) {
      throw err;
    } finally {
      this.loading = false;
    }
  }
}
