<h2 class="form-heading mb-4">Mailer History</h2>
<div
  *ngIf="loading"
  [@fadeInOut]
  class="absolute inset-0 bg-white bg-opacity-50 h-full"
>
  <app-loading [loading]="true" position="relative"></app-loading>
</div>
<div class="px-4">
  <div class="flex mb-4">
    <button
      [routerLink]="['create']"
      class="py-1 px-2 rounded bg-primary-600 uppercase text-sm text-white mr-2"
    >
      <app-icon icon="add_circle" class="text-white"></app-icon>
      Create Email
    </button>
    <button [routerLink]="['templates']" class="uppercase text-primary-600">
      Templates
    </button>
  </div>
  <app-search-bar-query></app-search-bar-query>
  <app-item-select-with-header
    [enableSort]="true"
    [disableCheckbox]="true"
    [itemTemplate]="itemTemplate"
    [source]="mailHistoryList"
    [totalCount]="totalCount"
    [limit]="limit"
    [headers]="defaultHeaders"
    [category]="'email'"
  >
  </app-item-select-with-header>
</div>
<ng-template #itemTemplate let-mail="item">
  <div class="sm:flex">
    <a class="sm:flex cursor-pointer" (click)="onSelect(mail)">
      <div class="sm:w-56 truncate">
        <app-icon [icon]="'mail'" class="text-gray-400 text-lg"></app-icon
        >{{ mail.subject }}
      </div>
      <div *ngIf="mail.status === 'sent'" class="sm:w-56 text-sm">
        <div
          class="
            border
            rounded-full
            text-success-600
            bg-success-100
            w-max
            shrink
            px-1
          "
        >
          {{ mail.status }}
        </div>
      </div>
      <div *ngIf="mail.status === 'scheduled'" class="sm:w-56 text-sm">
        <div
          class="
            border
            rounded-full
            text-yellow-400
            bg-yellow-100
            w-max
            shrink
            px-1
          "
        >
          {{ mail.status }}
        </div>
      </div>
      <div class="sm:w-56 text-sm">{{ mail.sendOnDate | date }}</div>
      <div class="sm:w-96 flex flex-wrap text-sm">
        <div
          class="
            mr-1
            border
            px-1
            text-blue-400
            bg-blue-100
            pt-1
            text-sm
            rounded
            w-28
            text-center
          "
        >
          open:
          <span class="font-bold">{{ mail.open | number: "1.1-2" }}%</span>
        </div>
        <div
          class="
            mr-1
            border
            px-1
            text-blue-400
            bg-blue-100
            pt-1
            text-sm
            rounded
            w-28
            text-center
          "
        >
          clicks:
          <span class="font-bold">{{ mail.clicks | number: "1.1-2" }}%</span>
        </div>
        <div
          class="
            mr-1
            border
            px-1
            text-red-400
            bg-red-100
            pt-1
            text-sm
            rounded
            w-28
            text-center
          "
        >
          bounce:
          <span class="font-bold">{{ mail.bounce | number: "1.1-2" }}%</span>
        </div>
      </div>
    </a>
  </div>
</ng-template>
