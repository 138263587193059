import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ResourcesService, Resource } from "src/app/sinigangnababoywithgabi";

import querystring from "query-string";
import { firstValueFrom } from "rxjs";
import { ModalService } from "src/app/services/modal.service";
import { ResourceHelperService } from "src/app/services/resource-helper.service";

@Component({
  selector: "app-resource-library-add-to-collection",
  templateUrl: "./resource-library-add-to-collection.component.html",
})
export class ResourceLibraryAddToCollectionComponent implements OnInit {
  loading: boolean;
  searchString: string;

  redirect: string;
  source: string;
  collectionUuid: string;
  order: number;
  resources: Resource[];

  constructor(
    private resourcesService: ResourcesService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private modalService: ModalService,
    private resourceHelperService: ResourceHelperService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((query) => {
      //For backwards compat
      this.collectionUuid = query["collection_uuid"] || query["parent_uuid"];

      this.resourcesService
        .listResourcesInCollection(this.collectionUuid, true)
        .subscribe((result) => {
          this.resources = result;
        });
    });
  }

  goBackOrRedirect(uuid) {
    let {
      redirect,
      redirect_field: redirectField,
      is_inside_course: isInsideCourse,
      course_uuid: courseUuid,
      parent_uuid: parentUuid,
      course_resource_uuid: courseResourceUuid,
    } = this.route.snapshot.queryParams;

    if (redirect) {
      let redirectUrl = new URL("http://placeholder" + redirect);

      //Parse params
      let redirectParams = querystring.parse(redirectUrl.search);

      //Add to params
      redirectParams[redirectField || "selected_resource_uuid"] = uuid;

      window.location.href = `${redirectUrl.pathname}?${querystring.stringify(
        redirectParams
      )}`;

      // this.router.navigateByUrl(
      //   redirectUrl.pathname + "?" + querystring.stringify(redirectParams),
      //   { replaceUrl: true }
      // );
    } else {
      // this.location.back();
      if (isInsideCourse) {
        window.location.href = `/courses/content-edit?course_uuid=${courseUuid}&resource_collection_uuid=${parentUuid}&resource_uuid=${courseResourceUuid}&from_screen=add_resource`;
      } else {
        window.location.href = `/resource-library/list/resources?collection_uuid=${parentUuid}`;
      }
    }
  }

  async onSelectResource(resource: Resource) {
    let { order } = this.route.snapshot.queryParams;

    try {
      if (
        resource.type === "buribook-with-activity" ||
        resource.type === "burislides"
      ) {
        let confirm = await this.modalService.confirm(
          "Cloning this resource will allow you to create your own activities inside it. Do you want to clone this resource?",
          {
            confirmText: "Yes, clone it.",
            cancelText: "No",
          }
        );

        this.loading = true;

        if (confirm) {
          let resourceWithContent = await firstValueFrom(
            this.resourcesService.getResourceByUuid(resource.uuid)
          );

          let newResource = await this.resourceHelperService.createResource({
            collectionUuid: this.collectionUuid,
            resource: {
              name: resourceWithContent.name,
              description: resourceWithContent.description,
              coverUri: resourceWithContent.coverUri,
              publishedAt: resourceWithContent.publishedAt,
              flavorTextSpan: resourceWithContent.flavorTextSpan,
              tags: resourceWithContent.tags,
              type: resourceWithContent.type,
              subtype: resourceWithContent.subtype,
              content: {
                ...resourceWithContent.content,
                spreads: resourceWithContent.content.spreads?.filter(
                  (s) =>
                    s.spreadType === "image-spread" || s.spreadType === "slide"
                ),

                // For backwards compatibility
                pages: resourceWithContent.content.pages?.filter(
                  (p) => p.type === "image"
                ),
                examDetails: {},
                sourceResourceUuid: resource.uuid,
              },
            },
            order,
          });

          this.goBackOrRedirect(newResource.uuid);

          return;
        }
      }

      if (order === "0") {
        await firstValueFrom(
          this.resourcesService.updateResourcesInCollection(
            this.collectionUuid,
            {
              resourceUuids: [
                resource.uuid,
                ...this.resources?.map((r) => r.uuid),
              ],
            }
          )
        );
        this.goBackOrRedirect(resource.uuid);
      } else {
        firstValueFrom(
          this.resourcesService.addResourcesInCollection(this.collectionUuid, {
            resourceUuids: [resource.uuid],
          })
        );

        this.goBackOrRedirect(resource.uuid);
      }
    } catch (err) {
      throw err;
    } finally {
      this.loading = false;
    }
  }
}
