<app-back-title-layout layoutTitle="General Settings">
  <div class="font-bold text-lg">Customize Resource Name</div>
  <a (click)="reset()" class="text-primary text-sm cursor-pointer"
    >Reset to Default</a
  >

  <form *ngIf="resourceForm" [formGroup]="resourceForm" (ngSubmit)="save()">
    <ng-container *ngFor="let resource of resources">
      <div class="my-5">
        <label class="form-label">
          <app-icon
            [icon]="resource.name | resourceTypeIcon"
            class="mr-1"
          ></app-icon>
          <span>{{ resource.label }} </span>
          <!-- <app-icon class="text-primary" [icon]="'help_outline'"></app-icon> -->
        </label>
        <input [formControlName]="resource.name" class="form-control" />
      </div>
    </ng-container>
    <div class="flex w-full justify-end">
      <button type="submit" class="btn btn-primary uppercase my-5">Save</button>
    </div>
  </form>
</app-back-title-layout>
