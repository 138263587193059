import { Pipe, PipeTransform } from "@angular/core";

const ACTIVITY_TYPE_MAP = {
  MC: "Multiple Choice",
  IC: "Image Multiple Choice",
  TF: "True or False",
  CB: "Checkbox",
  SA: "Fill in the Blank",
  LA: "Text",
  FW: "Freedom Wall",
  PO: "Poll",
  TA: "Tally",
  ME: "File Upload",
  SU: "Survey",
  DD: "Dropdown",
};

@Pipe({
  name: "activityType",
})
export class ActivityTypePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return ACTIVITY_TYPE_MAP[value] || value;
  }
}
