<div class="my-5 relative" [formGroup]="formGroup">
  <label class="form-label">Options</label>

  <div formArrayName="choices">
    <ng-container
      *ngFor="let choiceControl of getUndeletedChoiceControls(); let i = index"
    >
      <div class="flex justify-between items-start mt-2 mb-4">
        <app-radio
          *ngIf="formGroup.value.type == 'MC' || formGroup.value.type == 'IC'"
          [checked]="choiceControl.get('isCorrect').value"
          class="text-primary text-2xl mr-2"
          [name]="formGroup.value.itemUuid"
          [value]="i"
          (change)="onMultipleChoiceRadioChange(choiceControl)"
        ></app-radio>

        <app-checkbox
          *ngIf="formGroup.value.type == 'CB'"
          class="text-primary text-2xl mr-2"
          [formControl]="choiceControl.get('isCorrect')"
        ></app-checkbox>

        <div class="flex-1 min-w-0">
          <input
            type="text"
            placeholder="Insert text here..."
            class="form-control mr-2"
            [formControl]="choiceControl.get('text')"
          />

          <div
            class="relative"
            [ngClass]="{
              hidden:
                !choiceControl.value.imageUri ||
                formGroup.value.subtype === 'DD'
            }"
          >
            <!-- <app-upload-loading-wrapper
              #uploader
              accept="image/*"
              text="Change Image"
              (upload)="onUpload($event, choiceControl)"
            >
              <img
                class="w-full h-40 mb-5 object-contain border-0 bg-gray-300 rounded"
                [src]="choiceControl.value.imageUri"
              />
            </app-upload-loading-wrapper> -->

            <img
              class="w-full h-40 mb-1 object-contain border-0 bg-gray-300 rounded"
              [src]="choiceControl.value.imageUri"
            />

            <button
              type="button"
              class="appearance-none pt-1 pb-2 pr-1 pl-2 z-auto rounded-bl-full hover:bg-red-500 text-white bg-gray-500 absolute top-0 right-0"
              (click)="clearControl(choiceControl.get('imageUri'))"
            >
              <app-icon icon="close"></app-icon>
            </button>
          </div>

          <div
            class="relative flex items-center"
            *ngIf="
              choiceControl.get('audioUri').value &&
              formGroup.value.subtype !== 'DD'
            "
          >
            <!-- <app-upload-loading-wrapper
          #audioUploader
          accept="audio/*"
          class="hidden"
          (upload)="onAudioUpload($event, choiceControl)"
        >
        </app-upload-loading-wrapper> -->

            <audio
              class="flex-1"
              controls
              src="{{ choiceControl.get('audioUri').value }}"
            >
              <!-- <source [src]="choiceControl.get('audioUri').value" /> -->
            </audio>

            <button
              type="button"
              class="btn btn-icon mx-2 text-gray-600 bg-transparent"
              (click)="clearControl(choiceControl.get('audioUri'))"
            >
              <app-icon icon="close"></app-icon>
            </button>
          </div>

          <div
            class="my-2"
            *ngIf="formGroup.value.type == 'MC' || formGroup.value.type == 'PO'"
          >
            <label class="form-label text-xs"
              >When selected, unlocks the following questions (optional)</label
            >

            <app-multi-select
              [formControl]="choiceControl.get('examItemUuidFlags')"
              [options]="realExamItemOptions"
            ></app-multi-select>
          </div>
        </div>

        <input
          #uploader
          type="file"
          accept="image/*"
          class="hidden"
          (change)="onUpload($event, choiceControl.get('imageUri'))"
        />
        <button
          type="button"
          (click)="uploader.click()"
          class="btn btn-icon text-primary bg-transparent text-4xl mr-2"
          [ngClass]="{
            hidden: formGroup.value.subtype === 'DD'
          }"
        >
          <app-icon icon="photo"></app-icon>
        </button>

        <input
          #audioUploader
          type="file"
          accept="audio/*"
          class="hidden"
          (change)="onUpload($event, choiceControl.get('audioUri'))"
        />
        <button
          type="button"
          (click)="audioUploader.click()"
          class="btn btn-icon text-primary bg-transparent text-4xl mr-2"
          [ngClass]="{
            hidden: formGroup.value.subtype === 'DD'
          }"
        >
          <app-icon icon="audiotrack"></app-icon>
        </button>

        <button
          type="button"
          class="btn btn-icon bg-transparent text-gray-600 hover:text-red-600 text-2xl"
          (click)="removeChoice(choiceControl)"
        >
          <app-icon icon="close"></app-icon>
        </button>
      </div>
    </ng-container>

    <div class="flex justify-between items-center">
      <input
        type="text"
        placeholder="Add option..."
        (keydown.enter)="onEnterNewChoice($event)"
        (blur)="onEnterNewChoice($event)"
        class="form-control mr-2"
      />

      <button
        type="button"
        (click)="newChoiceImageUploader.click()"
        class="btn btn-icon bg-transparent text-gray-500 text-4xl mr-2"
        [ngClass]="{
          hidden: formGroup.value.subtype === 'DD'
        }"
      >
        <app-icon icon="photo"></app-icon>
      </button>

      <button
        type="button"
        (click)="newChoiceAudioUploader.click()"
        class="btn btn-icon bg-transparent text-gray-500 text-4xl mr-2"
        [ngClass]="{
          hidden: formGroup.value.subtype === 'DD'
        }"
      >
        <app-icon icon="audiotrack"></app-icon>
      </button>

      <button
        type="button"
        class="btn btn-icon invisible text-gray-600 hover:text-red-600 text-2xl"
      >
        <app-icon icon="close"></app-icon>
      </button>
    </div>

    <app-upload-loading-wrapper
      #newChoiceImageUploader
      class="invisible"
      accept="image/*"
      (upload)="onImageUploadNewChoice($event)"
      text="Change Image"
    >
    </app-upload-loading-wrapper>

    <app-upload-loading-wrapper
      #newChoiceAudioUploader
      class="invisible"
      accept="audio/*"
      (upload)="onAudioUploadNewChoice($event)"
      text="Change Image"
    >
    </app-upload-loading-wrapper>
  </div>

  <app-loading
    [loading]="loading"
    wrapperClass="absolute inset-0 bg-white opacity-50 flex justify-center items-center"
  >
  </app-loading>

  <!-- <div [class.invisible]="areChoicesValid()">
    <span class="text-red-600">
      You must add at least one choice.
    </span>
  </div> -->
</div>
