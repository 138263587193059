<app-back-title-layout layoutTitle="Edit Certificate" [loading]="loading">
  <form (ngSubmit)="onSave()" [formGroup]="formGroup" *ngIf="!loading">
    <div class="my-10">
      <label>
        <div class="form-label">Certificate Template</div>
        <app-markdown-viewer-and-editor
          [autoResize]="false"
          editorClass="h-40 overflow-y-scroll"
          viewerClass="h-40 overflow-y-scroll border rounded p-2"
          formControlName="htmlTemplate"
        ></app-markdown-viewer-and-editor>
        <!-- <textarea
          formControlName="htmlTemplate"
          class="form-control h-40"
        ></textarea> -->

        <!-- <div class="h-40 bg-gray-200 overflow-scroll">
          <svg viewBox="0 0 1050 740" preserveAspectRatio="xMinYMax meet">
            <foreignObject
              class="paper-size"
              [innerHtml]="formGroup?.value.htmlTemplate | safeHtml"
            >
            </foreignObject>
          </svg>
        </div> -->

        <input
          type="file"
          class="hidden"
          accept="text/html"
          #upload
          (change)="htmlFileRead($event, formGroup.get('htmlTemplate'))"
        />
        <button
          (click)="upload.click()"
          type="button"
          class="btn btn-primary form-width my-1 py-1 uppercase"
        >
          Change HTML Template
        </button>
      </label>
    </div>

    <div class="my-10 form-width">
      <label class="form-label leading-none"> Logo </label>

      <div class="mt-2 h-40 w-full bg-purple-200">
        <app-upload-loading-wrapper
          #thumbnailInput
          formControlName="logoUrl"
          accept="image/jpeg,image/jpg,image/png,image/gif"
        >
          <div class="w-full h-full">
            <div
              *ngIf="!formGroup.value.logoUrl"
              class="h-full text-purple-500 w-full flex flex-col items-center justify-center"
            >
              <app-icon class="text-6xl" icon="image"></app-icon>
            </div>

            <img
              *ngIf="formGroup.value.logoUrl"
              [src]="formGroup.value.logoUrl"
              alt=""
              class="w-full object-contain h-full"
            />
          </div>
        </app-upload-loading-wrapper>
      </div>

      <div>
        <button
          type="button"
          [disabled]="formGroup.get('logoUrl').disabled"
          (click)="thumbnailInput.click()"
          class="w-full bg-purple-500 text-white mt-2 rounded"
        >
          {{ formGroup.value.logoUrl ? "CHANGE" : "ADD IMAGE" }}
        </button>
      </div>
    </div>

    <h2 class="form-heading">Signatory</h2>

    <div class="my-10 form-width">
      <div class="my-5">
        <label class="form-label"> Name </label>
        <input
          type="text"
          class="form-control"
          formControlName="signatoryName"
        />
      </div>

      <div class="my-5">
        <label class="form-label"> Title of Signatory </label>
        <input
          type="text"
          class="form-control"
          formControlName="signatoryDesignation"
        />
      </div>

      <div class="my-10">
        <label class="form-label leading-none"> Signature </label>

        <div class="mt-2 h-40 w-full bg-purple-200">
          <app-upload-loading-wrapper
            #signatureInput
            formControlName="signatureUrl"
            accept="image/png"
          >
            <div class="w-full h-full">
              <div
                *ngIf="!formGroup.value.signatureUrl"
                class="h-full text-purple-500 w-full flex flex-col items-center justify-center"
              >
                <app-icon class="text-6xl" icon="image"></app-icon>
              </div>

              <img
                *ngIf="formGroup.value.signatureUrl"
                [src]="formGroup.value.signatureUrl"
                alt=""
                class="w-full object-contain h-full"
              />
            </div>
          </app-upload-loading-wrapper>
        </div>

        <div>
          <button
            type="button"
            [disabled]="formGroup.get('signatureUrl').disabled"
            (click)="signatureInput.click()"
            class="w-full bg-purple-500 text-white mt-2 rounded"
          >
            {{ formGroup.value.logoUrl ? "CHANGE" : "ADD IMAGE" }}
          </button>
        </div>
      </div>
    </div>

    <h2 class="form-heading">Email Template</h2>

    <div class="my-10">
      <div class="my-5">
        <label class="form-label"> From Email </label>
        <input
          type="text"
          formControlName="emailTemplateFromEmail"
          class="form-control"
        />

        <app-form-control-error-layout
          [control]="formGroup.get('emailTemplateFromEmail')"
        ></app-form-control-error-layout>
      </div>

      <div class="my-5">
        <label class="form-label"> Subject </label>
        <input
          type="text"
          formControlName="emailTemplateSubject"
          class="form-control"
        />
        <app-form-control-error-layout
          [control]="formGroup.get('emailTemplateSubject')"
        ></app-form-control-error-layout>
      </div>

      <div class="my-5">
        <label class="form-label"> HTML Content </label>
        <app-markdown-viewer-and-editor
          [autoResize]="false"
          editorClass="h-40 overflow-y-scroll"
          viewerClass="h-40 overflow-y-scroll border rounded p-2"
          formControlName="emailTemplateHtmlContent"
        ></app-markdown-viewer-and-editor>
        <!-- <textarea
          formControlName="emailTemplateHtmlContent"
          class="form-control h-40"
        ></textarea> -->

        <input
          type="file"
          class="hidden"
          accept="text/html"
          #uploadEmailTemplate
          (change)="
            htmlFileRead($event, formGroup.get('emailTemplateHtmlContent'))
          "
        />
        <button
          (click)="uploadEmailTemplate.click()"
          type="button"
          class="btn btn-primary form-width my-1 py-1 uppercase"
        >
          Change Email Template
        </button>
      </div>

      <!-- <input
        #htmlContentFile
        (change)="
          htmlFileRead($event, emailTemplateFormGroup.get('htmlContent'))
        "
        type="file"
        accept="text/html"
        class="hidden"
      />
      <button
        (click)="htmlContentFile.click()"
        type="button"
        class="w-full bg-purple-500 text-white mt-2 rounded"
      >
        Upload HTML
      </button> -->
    </div>

    <div class="flex flex-row-reverse">
      <button [disabled]="formGroup.invalid" class="btn btn-primary">
        DONE
      </button>
    </div>
  </form>
</app-back-title-layout>
