import { Component, OnInit } from "@angular/core";
import {
  Group,
  CoursesService,
  CourseSchedule,
} from "src/app/sinigangnababoywithgabi";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { ModalService } from "src/app/services/modal.service";
import { firstValueFrom } from "rxjs";

@Component({
  selector: "app-course-batch-edit",
  templateUrl: "./course-batch-edit.component.html",
})
export class CourseBatchEditComponent implements OnInit {
  schedule: CourseSchedule;
  groups: Group[];
  formGroup: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private location: Location,
    private coursesService: CoursesService,
    private modalService: ModalService
  ) {}

  async ngOnInit() {
    let scheduleUuid = this.route.snapshot.queryParams["schedule_uuid"];

    let schedule = await firstValueFrom(
      this.coursesService.scheduleRead(scheduleUuid)
    );

    this.formGroup = this.formBuilder.group({
      name: [schedule.name, Validators.required],
    });
  }

  async updateBatch() {
    let scheduleUuid = this.route.snapshot.queryParams["schedule_uuid"];

    await firstValueFrom(
      this.coursesService.scheduleUpdate(scheduleUuid, {
        ...this.formGroup.value,
      })
    );

    this.location.back();
  }

  async deleteBatch() {
    let scheduleUuid = this.route.snapshot.queryParams["schedule_uuid"];

    let result = await this.modalService.confirm(
      "Are you sure you want to delete this batch?"
    );

    if (result) {
      await firstValueFrom(this.coursesService.scheduleDelete(scheduleUuid));

      history.go(-2);
      // this.location.back();
      // this.location.back();
    }
  }
}
