<!-- <div class="relative">
  <button class="{{ textClass }}" type="button" (click)="open($event)">
    <span *ngIf="text">
      {{ text }}
    </span>

    <app-icon *ngIf="icon" [icon]="icon"></app-icon>
  </button>

  <button
    *ngIf="isOpen"
    (click)="close($event)"
    tabindex="-1"
    class="fixed inset-0 h-full w-full"
  ></button>

  <div
    *ngIf="isOpen"
    (click)="close($event)"
    class="border border-gray-200 absolute {{
      position
    }}-0 mt-2 py-2 w-48 z-10 bg-white shadow-md "
  >
    <ng-content></ng-content>
  </div>
</div> -->

<div class="relative text-left">
  <div>
    <button
      (click)="toggle($event)"
      type="button"
      [class]="toggleClass"
      id="menu-button"
      aria-expanded="true"
      aria-haspopup="true"
      aria-expanded="true"
      aria-labelledby="listbox-label"
    >
      <ng-content select="[dropdownToggle]"></ng-content>
    </button>
  </div>

  <div
    *ngIf="isOpen"
    class="
      origin-top-{{ position }}
      absolute
      {{ position }}-0
      mt-2
      py-2
      {{ width }}
      rounded-md
      shadow-lg
      bg-white
      z-10
      border border-gray-200
      {{ toggleBodyClass }}
    "
    role="menu"
    aria-orientation="vertical"
    aria-labelledby="menu-button"
    tabindex="-1"
  >
    <ng-content dropdownContent></ng-content>
  </div>
</div>
