import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray } from "@angular/forms";
import { V1ExamChoice, V1ExamsService } from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-exam-item-form-shortanswer",
  templateUrl: "./exam-item-form-shortanswer.component.html",
  styles: [],
})
export class ExamItemFormShortanswerComponent implements OnInit {
  @Input()
  formGroup: UntypedFormGroup;

  itemChoices: V1ExamChoice[];

  constructor(
    public v1ExamsService: V1ExamsService,
    public formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.formGroup = this.formGroup || this.formBuilder.group({});
  }

  getChoiceControls() {
    let formArray = this.formGroup.get("choices") as UntypedFormArray;

    return formArray.controls;
  }
}
