import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { Resource } from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-resource-type-upload-embed",
  templateUrl: "./resource-type-upload-embed.component.html",
  styles: [],
})
export class ResourceTypeUploadEmbedComponent implements OnInit {
  @Input()
  formGroup: UntypedFormGroup;

  @Input()
  resource: Resource;
  @ViewChild("code") textArea: ElementRef;
  constructor(private formBuilder: UntypedFormBuilder, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.formGroup.addControl(
      "content",
      this.formBuilder.group({
        uri: [this.resource.content["uri"], Validators.required],
        contentType: [this.resource.content["contentType"]],
        embedCode: [
          this.resource.content["embedCode"] || "",
          [
            this.allowedCodeValidator(/<iframe[^]*?src="(.*?)"[^]*?<\/iframe>/),
            Validators.required,
          ],
        ],
      })
    );
  }

  allowedCodeValidator(allowedRe: RegExp): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const allowed = allowedRe.test(control.value);
      return allowed ? null : { invalidEmbed: { value: control.value } };
    };
  }

  decoder(input) {
    let textArea = this.renderer.createElement("textarea");
    textArea.innerHTML = input;
    return textArea.value;
  }
  onEmbedChange() {
    let data = this.decoder(this.formGroup.value.content.embedCode);

    let subtype: string = "";

    let regexes: RegExp[] = [
      /https:\/\/docs.google.com\/([a-z]+)\/d\/e\/[^/]+\/.*/,
      /https:\/\/docs.google.com\/([a-z]+)\/d\/[^/]+\/.*/,
      /https:\/\/www.(youtube).com\/embed\/[^"]+/,
      /https:\/\/www.(canva).com\/design\/.*?\/.*/,
    ];

    for (let i = 0; i < regexes.length; i++) {
      if (regexes[i].test(data)) {
        let result = regexes[i].exec(data);

        subtype =
          result[1] === "document"
            ? "google-doc"
            : result[1] === "forms"
            ? "google-form"
            : result[1] === "spreadsheets"
            ? "google-sheet"
            : result[1] === "presentation"
            ? "google-slide"
            : result[1] === "youtube"
            ? "youtube"
            : result[1] === "canva"
            ? "canva"
            : null;

        break;
      }
    }

    let uri = data ? /<iframe.[^]*?src="(.*?)"/.exec(data)[1] : null;

    this.formGroup.patchValue({
      subtype,
      content: { uri, contentType: "text/html", embedCode: data },
    });
  }
}
