import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  debounceTime,
  defer,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  takeUntil,
  tap,
  zip,
} from "rxjs";
import { Action } from "src/app/app-common-module/components/list-table-header/list-table-header.component";
import { FilterPipePipe } from "src/app/app-common-module/pipes/filter-pipe.pipe";
import { ModalService } from "src/app/services/modal.service";
import {
  ResourcesService,
  CoursesService,
  Course,
  Resource,
  CourseSchedule,
  CourseScheduleWithCount,
} from "src/app/sinigangnababoywithgabi";
import { CompletionStats } from "../course-batch-stats/course-batch-stats.component";

const datePipe = new DatePipe("en-ph");

// Should this be inside a service?
export const constructScheduleDatePillAndDateText = (
  startDate: Date,
  endDate: Date
) => {
  let nowDate = new Date();

  if (startDate && startDate.valueOf() > nowDate.valueOf()) {
    return {
      statusText: "Queued",
      pillType: "warning",
      dateText: `${datePipe.transform(startDate)} -  ${datePipe.transform(
        endDate
      )}`,
    };
  } else if (endDate && endDate.valueOf() < nowDate.valueOf()) {
    return {
      statusText: "Done",
      pillType: "default",
      dateText: `${datePipe.transform(startDate)} -  ${datePipe.transform(
        endDate
      )}`,
    };
  } else {
    let dateText;

    if (!startDate && !endDate) {
      dateText = "Forever";
    }
    if (startDate && !endDate) {
      dateText = `Started ${datePipe.transform(startDate)}`;
    }

    if (startDate && endDate) {
      dateText = `${datePipe.transform(startDate)} -  ${datePipe.transform(
        endDate
      )}`;
    }

    return {
      dateText,
      statusText: "Ongoing",
      pillType: "info",
    };
  }
};

@Component({
  selector: "app-course-profile",
  templateUrl: "./course-profile.component.html",
  styles: [],
})
export class CourseProfileComponent implements OnInit {
  loading: boolean = false;
  resource: Resource;
  course: Course;
  limit = 20;
  totalBatchCount: number = 0;
  constructor(
    private route: ActivatedRoute,
    private resourcesService: ResourcesService,
    private coursesService: CoursesService,
    private modalService: ModalService
  ) {}

  async ngOnInit() {
    let { resource_uuid: resourceUuid } = this.route.snapshot.queryParams;

    this.resource = await firstValueFrom(
      this.resourcesService.getResourceByUuid(resourceUuid)
    );

    this.course = await firstValueFrom(
      this.coursesService.courseRead(
        this.resource.content.courseDetails?.courseUuid
      )
    );

    this.initAnalyitics();
    this.initBatchList();
    this.initBatchActions();
  }

  private unsubscribe$: Subject<void> = new Subject<void>();
  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  completionStats: CompletionStats = {
    students: "---",
    inProgress: "---",
    notStarted: "---",
    completed: "---",
    certificatesIssued: "---",
    needsAttention: "---",
  };
  overviewObservable: Observable<any>;
  async initAnalyitics() {
    // https://buri.dev/T1469
    this.overviewObservable = this.coursesService
      .courseStatisticsCourseCounts(
        this.course.uuid,
        "total_user_certificates_count"
      )
      .pipe(
        map((result) => {
          this.completionStats = {
            students: result.totalUsersCount,
            inProgress: result.totalInProgressCount,
            notStarted: result.totalNotStartedCount,
            certificatesIssued: result.totalUserCertificatesCount,
            completed: result.totalCompletedCount,
            needsAttention: result.totalNeedsAttentionCount,
          };
        })
      );
  }

  scheduleTableObservable: Observable<any>;
  schedules: CourseSchedule[];
  scheduleTable: {
    schedule: CourseSchedule;
    name: string;
    statusText: string;
    pillType: string;
    dateText: string;
    userCount: number;
    completionStatsObservable?: Observable<CompletionStats>;
  }[];

  batchActions: Action[];
  selectedScheduleUuids: string[] = [];

  async initBatchActions() {
    this.batchActions = [
      {
        name: "Remove",
        action: defer(() =>
          of(this.selectedScheduleUuids).pipe(
            switchMap((ids) =>
              this.modalService.confirm(
                `This action will remove ${ids.length} batch(es). Continue?`
              )
            ),
            filter((r) => !!r),
            switchMap(() => {
              return zip(
                this.selectedScheduleUuids.map((uuid) =>
                  this.coursesService.scheduleDelete(uuid)
                )
              );
            })
          )
        ),
      },
    ];
  }

  async initBatchList() {
    // let filterAll = new FilterPipePipe();

    this.scheduleTableObservable = this.route.queryParams.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((params) => {
        this.loading = true;
        let { page, order, q, status } = params;
        if (!page) page = 0;
        let offset = page * this.limit;
        return this.coursesService.courseScheduleListWithCount(
          this.course.uuid,
          this.limit,
          offset,
          q,
          status
        );
      }),
      tap((result) => {
        this.totalBatchCount = result.count;
      }),
      map((result) => {
        return this.initializeScheduleTable(result.schedules);
      }),
      tap((result) => {
        this.loading = false;
      })
    );
    // this.scheduleTableObservable = this.coursesService
    //   .courseScheduleList(this.course.uuid, limit,)
    //   .pipe(
    //     map((result) => {
    //       return this.initializeScheduleTable(result);
    //     }),
    //     switchMap((result) =>
    //       this.route.queryParams.pipe(
    //         map((params) => {
    //           let modifiedResult = result;

    //           const { q, order } = params;

    //           if (q) {
    //             modifiedResult = filterAll.transform(result, params.q, [
    //               "name",
    //             ]);
    //           }

    //           if (order) {
    //             const [field, direction] = order.split(" ");

    //             modifiedResult = modifiedResult.sort((a, b) =>
    //               direction === "desc"
    //                 ? -a[field].localeCompare(b[field])
    //                 : a[field].localeCompare(b[field])
    //             );
    //           }

    //           return modifiedResult;
    //         })
    //       )
    //     )
    //   );
  }

  initializeScheduleTable(schedules: CourseSchedule[]) {
    this.scheduleTable = schedules.map((schedule) => {
      let startDate = schedule.start ? new Date(schedule.start) : null;
      let endDate = schedule.end ? new Date(schedule.end) : null;
      let { statusText, dateText, pillType } =
        constructScheduleDatePillAndDateText(startDate, endDate);

      return {
        schedule: schedule,
        scheduleUuid: schedule.uuid,
        name: schedule.name,
        statusText,
        pillType,
        dateText,
        userCount: schedule["enrollments"]?.length,
        completionStatsObservable: this.coursesService
          .courseStatisticsScheduleCounts(
            schedule.uuid,
            "total_user_certificates_count"
          )
          .pipe(
            map((result) => ({
              students: result.totalUsersCount,
              inProgress: result.totalInProgressCount,
              notStarted: result.totalNotStartedCount,
              certificatesIssued: result.totalUserCertificatesCount,
              completed: result.totalCompletedCount,
              needsAttention: result.totalNeedsAttentionCount,
            }))
          ),
      };
    });
    // this.scheduleTable = this.scheduleTable.filter(
    //   (schedule) => schedule.statusText === "Ongoing"
    // );
    return this.scheduleTable;
  }

  onSelectedItemList(selected: string[]) {
    this.selectedScheduleUuids = selected;
  }
}
