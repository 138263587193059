import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DashboardService } from "src/app/services/dashboard.service";
import { V2Block, V2Cast } from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-casteditor-block-navigation",
  templateUrl: "./casteditor-block-navigation.component.html",
  styleUrls: ["./casteditor-block-navigation.component.scss"],
})
export class CasteditorBlockNavigationComponent implements OnInit {
  constructor(private dashboardService: DashboardService) {}

  @Input() block: V2Block;

  cast: V2Cast;

  async ngOnInit() {
    this.cast = await this.dashboardService.getCast().toPromise();
  }
}
