<div class="flex justify-center xl:h-screen xl:overflow-hidden overflow-hidden">
  <div
    class="flex flex-col basis-1/4 p-4 xl:p-8 space-y-12 xl:overflow-y-auto w-96"
    *ngIf="cast"
    [@fadeInOut]
  >
    <img
      class="w-xs xl:w-md h-24 xl:h-40 object-contain"
      [src]="
        cast?.data.attributes.castIcon?.web?.nav || '/assets/default-logo.png'
      "
    />

    <button
      (click)="gotoLoginPage()"
      class="rounded py-2 px-4 w-full xl:w-auto bg-purple-700 text-white"
    >
      LOG IN
    </button>

    <div class="text-center xl:text-left">
      <h2 class="font-bold">Need Help?</h2>
      <ul>
        <li>
          <a
            class="text-blue-400"
            href="https://pass.buri.io/users/password/new"
            >I forgot my password</a
          >
        </li>
        <li>
          <a
            class="text-blue-400"
            href="https://pass.buri.io/users/confirmation/new"
            >I didn't receive confirmation instructions</a
          >
        </li>
      </ul>
    </div>

    <div class="flex flex-col items-center xl:items-start">
      <img class="w-12" src="/assets/default-logo.png" />
      <p class="text-xs text-gray-500">
        Powered by <em class="font-bold text-purple-700">Buri.io</em>
      </p>
    </div>

    <div></div>
    <div class="flex flex-col"></div>
  </div>

  <div class="hidden xl:flex flex-1 items-center justify-center">
    <ng-container
      *ngIf="cast?.data.attributes.castIcon?.web?.banner; else defaultBanner"
    >
      <img
        class="w-full h-full object-cover"
        [src]="cast?.data.attributes.castIcon?.web?.banner"
        alt="Login Banner"
      />
    </ng-container>
  </div>
</div>

<ng-template #defaultBanner>
  <img class="mx-10" src="/assets/castlms-login.png" alt="Login Banner" />
</ng-template>
