import {
  Component,
  Input,
  Output,
  SimpleChanges,
  EventEmitter,
  ChangeDetectorRef,
} from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject, firstValueFrom, takeUntil } from "rxjs";
import { ModalService } from "src/app/services/modal.service";
import {
  Resource,
  ResourcesService,
  V1ExamChoice,
  V1ExamChoiceSetFeedback,
  V1ExamItem,
  V1ExamsService,
} from "src/app/sinigangnababoywithgabi";
import { BurislidesSelectActivityModalLayoutComponent } from "../burislides-select-activity-modal-layout/burislides-select-activity-modal-layout.component";
import { BurislidesFeedbackModalComponent } from "../burislides-feedback-modal/burislides-feedback-modal.component";

@Component({
  selector: "app-burislides-block-activity-group",
  templateUrl: "./burislides-block-activity-group.component.html",
  styles: [],
})
export class BurislidesBlockActivityGroupComponent {
  @Input() examSectionUuid: string;
  @Input() examItemRequiredFlags: any;
  @Input() examChoiceTriggerFlags: any;
  @Input() isResultPageShown: boolean;
  @Input() resultPageImageUri: string;
  @Input() resultPageText: string;

  @Input() editMode: boolean;
  @Input() previewMode: boolean;
  @Output() isLoading: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onSectionCreate: EventEmitter<string> = new EventEmitter<string>();

  @Output() updated: EventEmitter<any> = new EventEmitter<any>();

  examItemFormGroups: FormGroup[];

  resultPageFormGroup: FormGroup;

  displayOptions = [
    { name: "Multiple Choice", value: "" },
    { name: "Dropdown", value: "DD" },
  ];

  inputTypes = [
    { name: "Text", value: "" },
    { name: "Date", value: "DT" },
  ];

  examItemOptions: {
    text: string;
    value: string;
  }[];

  loading: boolean;

  constructor(
    private resourcesService: ResourcesService,
    private route: ActivatedRoute,
    private router: Router,
    private v1ExamsService: V1ExamsService,
    private cd: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private modalService: ModalService
  ) {}

  async ngOnInit() {
    await this.loadResourceFormGroup();
    await this.loadExamItems();
  }
  async ngOnChanges(changes: SimpleChanges) {
    if (
      changes.examSectionUuid &&
      changes.examSectionUuid.previousValue &&
      changes.examSectionUuid.currentValue !==
        changes.examSectionUuid.previousValue
    ) {
      await this.loadExamItems();
    }

    if (
      changes.isResultPageShown &&
      changes.isResultPageShown.previousValue !==
        changes.isResultPageShown.currentValue
    ) {
      await this.loadResourceFormGroup();
    }

    if (
      changes.resultPageImageUri &&
      changes.resultPageImageUri.previousValue !==
        changes.resultPageImageUri.currentValue
    ) {
      await this.loadResourceFormGroup();
    }
    if (
      changes.resultPageText &&
      changes.resultPageText.previousValue !==
        changes.resultPageText.currentValue
    ) {
      await this.loadResourceFormGroup();
    }
  }

  private unsubscribe$: Subject<void> = new Subject<void>();
  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  outputIsLoading(loading) {
    this.isLoading.emit(loading);
  }

  async loadResourceFormGroup() {
    this.resultPageFormGroup = this.formBuilder.group({
      isResultPageShown: [this.isResultPageShown],
      resultPageImageUri: [this.resultPageImageUri],
      resultPageText: [this.resultPageText],
    });
  }

  async loadExamItems() {
    this.loading = true;

    this.isLoading.emit(true);
    this.examItemFormGroups = null;

    if (!this.examSectionUuid) {
      await this.createSectionAndUpdateResource();
    }

    const examItems = await firstValueFrom(
      this.v1ExamsService.examsSectionsItemsList(
        this.examSectionUuid,
        "choices"
      )
    );

    // Fetch choice feedbacks

    const choiceItemFlagMap = {};

    this.examItemRequiredFlags?.forEach((item) => {
      item?.flags?.forEach((flag) => {
        const flagId = flag.flagId.replace("_SELECTED", "");

        if (choiceItemFlagMap[flagId]) {
          choiceItemFlagMap[flagId].push(item.examItemUuid);
        } else {
          choiceItemFlagMap[flagId] = [item.examItemUuid];
        }
      });
    });

    // Add skip to map
    this.examChoiceTriggerFlags?.forEach((choiceTrigger) => {
      if (
        choiceTrigger.flags.some(
          (flag) => flag.flagId == "skip" && flag.value == true
        )
      ) {
        choiceItemFlagMap[choiceTrigger.examChoiceUuid] = ["skip"];
      }
    });

    this.examItemFormGroups = [];

    for (let i = 0; i < examItems.length; i++) {
      let examItem = examItems[i];

      let choiceSetFeedbacks = await firstValueFrom(
        this.v1ExamsService.examsItemsChoicesetfeedbacksList(examItem.uuid)
      );

      this.examItemFormGroups.push(
        this.constructItemFormGroup({
          itemUuid: examItem.uuid,
          text: examItem.text,
          title: examItem.title,
          type: examItem.type,
          subtype: examItem.subtype,
          mediaUrl: examItem.mediaUrl,
          required: examItem.required,
          showResults: examItem.showResults,
          shuffleChoices: examItem.shuffleChoices,
          isGraded: examItem.isGraded,
          instructions: examItem.instructions,
          orderId: examItem.orderId,
          correctFeedbackMessage: examItem.correctFeedbackMessage,
          correctFeedbackImageUrl: examItem.correctFeedbackImageUrl,
          incorrectFeedbackMessage: examItem.incorrectFeedbackMessage,
          incorrectFeedbackImageUrl: examItem.incorrectFeedbackImageUrl,
          neutralFeedbackMessage: examItem.neutralFeedbackMessage,
          neutralFeedbackImageUrl: examItem.neutralFeedbackImageUrl,
          choices: examItem.choices,
          choiceSetFeedbacks,
          choiceItemFlagMap,
        })
      );
    }

    this.constructExamItemOptions();

    this.isLoading.emit(false);
    this.loading = false;
  }

  constructItemFormGroup(options: {
    itemUuid?: string;
    text?: string;
    title?: string;
    type?: string;
    subtype?: string;
    mediaUrl?: string;
    required?: boolean;
    showResults?: boolean;
    shuffleChoices?: boolean;
    isGraded?: boolean;
    instructions?: string;
    orderId?: number;

    correctFeedbackMessage?: string;
    correctFeedbackImageUrl?: string;

    incorrectFeedbackMessage?: string;
    incorrectFeedbackImageUrl?: string;

    neutralFeedbackMessage?: string;
    neutralFeedbackImageUrl?: string;

    choiceSetFeedbacks?: V1ExamChoiceSetFeedback[];

    choices?: V1ExamChoice[];
    choiceItemFlagMap?: any;
  }) {
    const formGroup = this.formBuilder.group({
      itemUuid: [options.itemUuid],
      text: [options.text],
      title: [options.title],
      type: [options.type],
      subtype: [options.subtype],
      mediaUrl: [options.mediaUrl],
      required: [options.required],
      showResults: [options.showResults],
      shuffleChoices: [options.shuffleChoices],
      isGraded: [options.isGraded],
      instructions: [options.instructions],
      orderId: [options.orderId],

      correctFeedbackMessage: [options.correctFeedbackMessage],
      correctFeedbackImageUrl: [options.correctFeedbackImageUrl],

      incorrectFeedbackMessage: [options.incorrectFeedbackMessage],
      incorrectFeedbackImageUrl: [options.incorrectFeedbackImageUrl],

      neutralFeedbackMessage: [options.neutralFeedbackMessage],
      neutralFeedbackImageUrl: [options.neutralFeedbackImageUrl],

      choiceSetFeedbacks: [options.choiceSetFeedbacks],

      choices: this.formBuilder.array([]),
    });

    if (!options.itemUuid) {
    } else if (
      options.type == "MC" ||
      options.type == "IC" ||
      options.type == "CB" ||
      options.type == "PO"
    ) {
      let choices = options.choices || [];

      const choiceFormGroups = formGroup.get("choices") as FormArray;

      for (const choice of choices) {
        let choiceFG = this.constructChoiceFormGroup({
          choiceUuid: choice.uuid,
          text: choice.longInput || choice.shortInput,
          imageUrl: choice.imageUrl,
          audioUrl: choice.audioUrl,
          isCorrect: options.type == "PO" ? true : choice.isCorrect,
          examItemUuidFlags: options.choiceItemFlagMap[choice.uuid],
        });

        choiceFG
          .get("examItemUuidFlags")
          .valueChanges.pipe(takeUntil(this.unsubscribe$))
          .subscribe((value) => {
            const cfg = formGroup.get("choices") as FormArray;

            if (value?.includes("skip")) {
              choiceFG.get("examItemUuidFlags").setValue(["skip"], {
                emitEvent: false,
              });

              cfg.controls
                .filter((control) => control != choiceFG)
                .forEach((control) => {
                  control.get("examItemUuidFlags").setValue(
                    this.examItemOptions
                      .filter((option) => option.value != "skip")
                      .filter(
                        (option) =>
                          option.value != formGroup.get("itemUuid").value
                      )
                      .map((option) => option.value),
                    {
                      emitEvent: false,
                    }
                  );
                });
            }
          });

        choiceFormGroups.push(choiceFG);
      }
    } else if (options.type == "SA") {
      let choices = options.choices || [];
      const choiceFormGroups = formGroup.get("choices") as FormArray;

      if (choices.length > 0) {
        for (const choice of choices) {
          choiceFormGroups.push(
            this.constructChoiceFormGroup({
              choiceUuid: choice.uuid,
              text: choice.longInput || choice.shortInput,
              imageUrl: null,
              audioUrl: null,
              isCorrect: true,
            })
          );
        }
      } else {
        choiceFormGroups.push(
          this.constructChoiceFormGroup({
            choiceUuid: null,
            text: null,
            imageUrl: null,
            audioUrl: null,
            isCorrect: true,
          })
        );
      }
    }

    return formGroup;
  }

  constructChoiceFormGroup(options: {
    choiceUuid?: string;
    text?: string;
    imageUrl?: string;
    audioUrl?: string;
    isCorrect?: boolean;
    examItemUuidFlags?: string[];
  }) {
    return this.formBuilder.group({
      choiceUuid: [options.choiceUuid],
      text: [options.text],
      longInput: [options.text],
      shortInput: [options.text],
      imageUri: [options.imageUrl],
      audioUri: [options.audioUrl],
      isCorrect: [options.isCorrect || false],
      isDeleted: [false],
      examItemUuidFlags: [options.examItemUuidFlags],
    });
  }

  constructExamItemOptions() {
    this.examItemOptions = [
      {
        text: "Skip all questions",
        value: "skip",
      },
      ...this.examItemFormGroups.map((formGroup, i) => {
        return {
          text: `${i + 1}. ${formGroup.get("text").value}`,
          value: formGroup.get("itemUuid").value,
        };
      }),
    ];
  }

  onUpload(event, formControl: AbstractControl) {
    formControl.setValue(event.uri);
    formControl.markAsDirty();
  }

  onUploadClear(formControl: AbstractControl) {
    formControl.setValue("");
    formControl.markAsDirty();
  }

  getUpdatedSpread() {
    const requiredFlagMap = {};

    let finalRequiredFlags = [];
    let finalTriggerFlags = [];

    this.examItemFormGroups?.forEach((formGroup) => {
      const itemUuid = formGroup.get("itemUuid").value;

      const choiceFormGroups = formGroup.get("choices") as FormArray;

      choiceFormGroups.controls.forEach((control) => {
        const examChoiceUuid = control.get("choiceUuid").value;

        const flags = control.get("examItemUuidFlags").value;

        if (flags) {
          if (flags.includes("skip")) {
            finalTriggerFlags.push({
              examChoiceUuid,
              flags: [
                {
                  flagId: `skip`,
                  value: true,
                },
              ],
            });
          } else {
            flags.forEach((flag) => {
              if (requiredFlagMap[flag]) {
                requiredFlagMap[flag].push(examChoiceUuid);
              } else {
                requiredFlagMap[flag] = [examChoiceUuid];
              }
            });

            finalTriggerFlags.push({
              examChoiceUuid,
              flags: [
                {
                  flagId: `${examChoiceUuid}_SELECTED`,
                  value: true,
                },
              ],
            });
          }
        }
      });
    });

    Object.entries(requiredFlagMap).forEach(
      ([examItemUuid, choiceUuids]: [string, string[]]) => {
        const uniqueChoiceUuids = [...new Set(choiceUuids)];

        uniqueChoiceUuids.forEach((choiceUuid) => {
          finalRequiredFlags.push({
            examItemUuid,
            flags: [
              {
                flagId: `${choiceUuid}_SELECTED`,
                value: true,
              },
            ],
          });
        });
      }
    );

    return {
      examSectionUuid: this.examSectionUuid,
      examItemRequiredFlags: finalRequiredFlags,
      examChoiceTriggerFlags: finalTriggerFlags,
      resultPageText: this.resultPageFormGroup.get("resultPageText").value,
      resultPageImageUri:
        this.resultPageFormGroup.get("resultPageImageUri").value,
      isResultPageShown:
        this.resultPageFormGroup.get("isResultPageShown").value,
    };
  }

  async createSectionAndUpdateResource() {
    let resource = await firstValueFrom(
      this.resourcesService.getResourceByUuid(
        this.route.snapshot.queryParams["resource_uuid"]
      )
    );

    if (!resource) {
      return;
    }

    let examUuid = resource.content.examDetails.examUuid;

    const section = await firstValueFrom(
      this.v1ExamsService.examsSectionsCreate(examUuid, {
        title: "New Section",
      })
    );

    this.examSectionUuid = section.uuid;

    this.spreadChangeOut();
  }

  async createItem(examItem: V1ExamItem) {
    return await firstValueFrom(
      this.v1ExamsService.examsSectionsItemsCreate(this.examSectionUuid, {
        ...examItem,
      })
    );
  }

  async updateItem(examItem: V1ExamItem) {
    return await firstValueFrom(
      this.v1ExamsService.examsSectionsItemsUpdate(examItem.uuid, {
        ...examItem,
      })
    );
  }

  async deleteItem(examItem: V1ExamItem) {
    await firstValueFrom(
      this.v1ExamsService.examsSectionsItemsDelete(examItem.uuid)
    );
  }

  async createChoice(itemUuid: string, choice: V1ExamChoice) {
    return firstValueFrom(
      this.v1ExamsService.examsSectionsItemsChoicesCreate(itemUuid, choice)
    );
  }

  async updateChoice(choice: V1ExamChoice) {
    await firstValueFrom(
      this.v1ExamsService.examsSectionsItemsChoicesUpdate(choice.uuid, choice)
    );
  }

  async deleteChoice(choice: V1ExamChoice) {
    await firstValueFrom(
      this.v1ExamsService.examsSectionsItemsChoicesDelete(choice.uuid)
    );
  }

  // // Main mathod for updating the exam item
  async updateAll() {
    try {
      this.loading = true;

      // Sort orderids
      this.examItemFormGroups.forEach((fg, i) => {
        if (fg.get("orderId").value != i) {
          fg.get("orderId").setValue(i);
          fg.markAsDirty();
        }

        fg.get("orderId").setValue(this.examItemFormGroups.indexOf(fg));
      });

      // Update dirty items
      let dirtyExamItemFormGroups = this.examItemFormGroups.filter(
        (formGroup) => formGroup.dirty
      );

      for (let i = 0; i < dirtyExamItemFormGroups.length; i++) {
        let dirtyExamItemFormGroup = dirtyExamItemFormGroups[i];

        await this.updateChoices(
          dirtyExamItemFormGroup.get("itemUuid").value,
          dirtyExamItemFormGroup.get("choices") as FormArray
        );

        await this.updateItem({
          uuid: dirtyExamItemFormGroup.get("itemUuid").value,
          text: dirtyExamItemFormGroup.get("text").value,
          title: dirtyExamItemFormGroup.get("title").value,
          type: dirtyExamItemFormGroup.get("type").value,
          subtype: dirtyExamItemFormGroup.get("subtype").value,
          mediaUrl: dirtyExamItemFormGroup.get("mediaUrl").value,
          required: dirtyExamItemFormGroup.get("required").value,
          showResults: dirtyExamItemFormGroup.get("showResults").value,
          shuffleChoices: dirtyExamItemFormGroup.get("shuffleChoices").value,
          isGraded: dirtyExamItemFormGroup.get("isGraded").value,
          instructions: dirtyExamItemFormGroup.get("instructions").value,
        });
      }

      this.spreadChangeOut();
      // await this.updateResource();

      // this.isLoading.emit(this.loading);
      // await this.updateChoices();
      // await this.updateRubrics();
      // await this.updateItem();
    } catch (err) {
      throw err;
    } finally {
      this.loading = false;
    }
  }

  async updateChoices(itemUuid: string, choicesArray: FormArray) {
    let updateAndCreateChoiceControls = choicesArray?.controls?.filter(
      (control) => !control.value.isDeleted
    );
    let deleteChoiceControls = choicesArray?.controls?.filter(
      (control) => control.value.choiceUuid && control.value.isDeleted
    );

    // Handle choices
    if (updateAndCreateChoiceControls) {
      // For each choice form control
      for (let i = 0; i < updateAndCreateChoiceControls.length; i++) {
        let control = updateAndCreateChoiceControls[i];
        let index = i;
        let choiceValue = control.value;

        if (choiceValue.choiceUuid) {
          // If uuid exists, then the choice exists. Update the choice.
          await this.updateChoice({
            uuid: choiceValue.choiceUuid,
            longInput: choiceValue.text || "",
            shortInput: choiceValue.text || "",
            imageUrl: choiceValue.imageUri || "",
            audioUrl: choiceValue.audioUri || "",
            isCorrect: choiceValue.isCorrect,
            orderId: index,
          });
        } else {
          // If uuid dont exist, then the choice does not exist. Create the choice
          let createdChoice = await this.createChoice(itemUuid, {
            longInput: choiceValue.text || "",
            shortInput: choiceValue.text || "",
            imageUrl: choiceValue.imageUri || "",
            audioUrl: choiceValue.audioUri || "",
            isCorrect: choiceValue.isCorrect,
            orderId: index,
          });

          control.get("choiceUuid").setValue(createdChoice.uuid);
        }
      }
    }

    if (deleteChoiceControls) {
      // Delete choices in the deleted form array
      await Promise.all(
        deleteChoiceControls.map(async (control) => {
          let choiceUuid = control.value.choiceUuid;

          await this.deleteChoice({ uuid: choiceUuid });
        })
      );
    }
  }

  async onSave() {
    this.constructExamItemOptions();
    await this.updateAll();
  }

  async onDeleteItem(itemUuid: string) {
    let result = await this.modalService.confirm(
      "Are you sure you want to delete this item?"
    );

    if (!result) {
      return;
    }

    this.loading = true;

    await this.deleteItem({
      uuid: itemUuid,
    });
    await this.loadExamItems();
  }

  async onAddActivityClick(spliceIndex: number) {
    let result = await this.modalService.openModal(
      BurislidesSelectActivityModalLayoutComponent,
      {
        data: {
          ignore: ["FW", "TA"],
        },
      }
    );

    if (result) {
      this.loading = true;
      // Create new activity
      const examItem = await this.createItem({
        text: "",
        title: "",
        type: result.type,
        subtype: result.subtype,
        mediaUrl: "",
        required: false,
        showResults: false,
        shuffleChoices: false,
        isGraded: true,
        instructions: "",
        orderId: spliceIndex,
      });

      this.examItemFormGroups.splice(
        spliceIndex,
        0,
        this.constructItemFormGroup({
          itemUuid: examItem.uuid,
          text: examItem.text,
          title: examItem.title,
          type: examItem.type,
          subtype: examItem.subtype,
          mediaUrl: examItem.mediaUrl,
          required: examItem.required,
          showResults: examItem.showResults,
          shuffleChoices: examItem.shuffleChoices,
          isGraded: examItem.isGraded,
          instructions: examItem.instructions,
          orderId: examItem.orderId,
          correctFeedbackMessage: examItem.correctFeedbackMessage,
          correctFeedbackImageUrl: examItem.correctFeedbackImageUrl,
          incorrectFeedbackMessage: examItem.incorrectFeedbackMessage,
          incorrectFeedbackImageUrl: examItem.incorrectFeedbackImageUrl,
          neutralFeedbackMessage: examItem.neutralFeedbackMessage,
          neutralFeedbackImageUrl: examItem.neutralFeedbackImageUrl,

          choiceSetFeedbacks: [],

          choices: examItem.choices,
        })
      );

      this.constructExamItemOptions();

      await this.updateAll();
    }
  }

  spreadChangeOut() {
    this.updated.emit(this.getUpdatedSpread());
  }

  async onEditFeedback(formGroup: FormGroup, insideSlide: boolean) {
    let feedback = await this.modalService.openModal(
      BurislidesFeedbackModalComponent,
      {
        data: { formGroup: formGroup, insideSlide: insideSlide },
        panelClass: ["w-2/3"],
      }
    );
    // console.log(feedback);
    // if (feedback) this.feedback.emit(feedback);
  }

  onAddChoice(values, formGroup) {
    let formArray = formGroup.get("choices") as FormArray;

    let newFormGroup = this.constructChoiceFormGroup({
      choiceUuid: null,
      text: values.text,
      imageUrl: values.imageUri,
      audioUrl: values.audioUri,
      isCorrect: formGroup.get("type").value == "PO" ? true : false,
    });

    newFormGroup
      .get("examItemUuidFlags")
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        const cfg = formGroup.get("choices") as FormArray;

        if (value?.includes("skip")) {
          newFormGroup.get("examItemUuidFlags").setValue(["skip"], {
            emitEvent: false,
          });

          cfg.controls
            .filter((control) => control != newFormGroup)
            .forEach((control) => {
              control.get("examItemUuidFlags").setValue(
                this.examItemOptions
                  .filter((option) => option.value != "skip")
                  .filter(
                    (option) => option.value != formGroup.get("itemUuid").value
                  )
                  .map((option) => option.value),
                {
                  emitEvent: false,
                }
              );
            });
        }
      });

    formArray.push(newFormGroup);
    formArray.markAsDirty();
  }
}
