import { Component, OnInit } from "@angular/core";

const ICONS = [
  {
    name: "User",
    sets: {
      material: "person",
      sfSymbols: "person.fill",
    },
  },
  {
    name: "Course",
    sets: {
      material: "school",
      sfSymbols: "graduationcap.fill",
    },
  },
  {
    name: "Account Details",
    sets: {
      material: "account_box",
      sfSymbols: "person.crop.square.fill",
    },
  },
  {
    name: "Lock",
    sets: {
      material: "lock",
      sfSymbols: "lock.fill",
    },
  },
  {
    name: "Print",
    sets: {
      material: "print",
      sfSymbols: "printer.fill",
    },
  },
  {
    name: "Check - Filled",
    sets: {
      material: "check_circle",
      sfSymbols: "checkmark.circle.fill",
    },
  },
  {
    name: "Failed - Filled",
    sets: {
      material: "error",
      sfSymbols: "questionmark.circle.fill",
    },
  },
];

@Component({
  selector: "app-icon-select-dialog",
  templateUrl: "./icon-select-dialog.component.html",
  styleUrls: ["./icon-select-dialog.component.scss"],
})
export class IconSelectDialogComponent implements OnInit {
  icons = ICONS;

  constructor() {}

  ngOnInit(): void {}
}
