<div class="my-5 relative" [formGroup]="formGroup">
  <label class="form-label">Options</label>

  <div formArrayName="rubrics" *ngIf="this.formGroup.get('rubrics')">
    <ng-container
      *ngFor="
        let rubricControl of getUndeletedRubricFormControls();
        let i = index
      "
    >
      <div class="flex justify-between items-center">
        <input
          type="text"
          placeholder="Insert text here..."
          class="form-control mr-2"
          [formControl]="rubricControl.get('name')"
        />

        <div class="w-24">
          <input
            type="number"
            step="1"
            min="0"
            max="100"
            class="form-control"
            [formControl]="rubricControl.get('weight')"
          />
        </div>

        <button
          type="button"
          class="text-gray-600 hover:text-red-600 text-2xl"
          (click)="removeRubric(rubricControl)"
        >
          <app-icon icon="close"></app-icon>
        </button>
      </div>
    </ng-container>

    <!-- <div class="flex justify-between items-center">
      <input
        type="text"
        placeholder="Insert text here..."
        class="form-control mr-2"
        [(ngModel)]="newCriteriaName"
        [ngModelOptions]="{ standalone: true }"
      />

      <div class="w-20">
        <input
          type="number"
          step="1"
          min="0"
          max="100"
          class="form-control"
          [(ngModel)]="newCriteriaWeight"
          [ngModelOptions]="{ standalone: true }"
        />
      </div>

      <button
        type="button"
        class="invisible text-gray-600 hover:text-red-600 text-2xl"
      >
        <app-icon icon="close"></app-icon>
      </button>
    </div> -->

    <app-form-control-error-layout
      [control]="formGroup.get('rubrics')"
    ></app-form-control-error-layout>

    <button
      (click)="onAddRubric()"
      type="button"
      class="btn my-1 p-0 w-full bg-primary-light text-white"
    >
      <app-icon icon="add"></app-icon>
      <span>ADD NEW CRITERIA</span>
    </button>
  </div>

  <!-- <app-loading
    [loading]="loading"
    wrapperClass="absolute inset-0 bg-white opacity-50 flex justify-center items-center"
  >
  </app-loading> -->

  <!-- <div [class.invisible]="areChoicesValid()">
    <span class="text-red-600">
      You must add at least one choice.
    </span>
  </div> -->
</div>
