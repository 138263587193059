import { Component, OnInit } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { LinkList } from "src/app/app-common-module/layouts/profile-link-list/profile-link-list.component";
import { DashboardService } from "src/app/services/dashboard.service";

@Component({
  selector: "app-course-batch-enrollments-index",
  templateUrl: "./course-batch-enrollments-index.component.html",
})
export class CourseBatchEnrollmentsIndexComponent implements OnInit {
  linkLists: LinkList[];

  constructor(private dashboardService: DashboardService) {}

  async ngOnInit() {
    let permissions = await firstValueFrom(
      this.dashboardService.getPermissions()
    );

    let canAddUsers = permissions.includes("create:users");

    this.linkLists = [
      {
        name: "Self Enroll users",
        description: "Users can initiate their enrollment",
        routerLinkOptions: {
          routerLink: ["../self-enroll"],
          queryParamsHandling: "merge",
        },
      },
      {
        name: "Select from Existing List",
        description: "Select users from your current list",
        routerLinkOptions: {
          routerLink: ["../groups"],
          queryParamsHandling: "merge",
        },
      },
      {
        name: "Upload a CSV file",
        description:
          "Add users not yet existing to your current list (suggested when adding bulk of new users)",
        routerLinkOptions: {
          routerLink: ["../", "bulk"],
          queryParamsHandling: "merge",
        },
        disabled: !canAddUsers,
      },
      // {
      //   name: "Manually Create New User",
      //   description: "Input user information to add new users",
      //   routerLinkOptions: {
      //     routerLink: ["../"],
      //     queryParamsHandling: "merge",
      //   },
      // },
      {
        name: "Generate Enrollment Link",
        description:
          "Copy a unique link that will allow new and registered users to enroll automatically to this Course",
        routerLinkOptions: {
          routerLink: ["../", "signuplink"],
          queryParamsHandling: "merge",
        },
      },
      {
        name: "Enroll via email",
        description: "Add students via an email list.",
        routerLinkOptions: {
          routerLink: ["../", "email-list"],
          queryParamsHandling: "merge",
        },
      },
    ];
  }
}
