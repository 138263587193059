import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { firstValueFrom } from "rxjs";
import { DashboardService } from "src/app/services/dashboard.service";

@Component({
  selector: "app-casteditor-cast-edit",
  templateUrl: "./casteditor-cast-edit.component.html",
  styles: [],
})
export class CasteditorCastEditComponent implements OnInit {
  formGroup: UntypedFormGroup;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private dashboardService: DashboardService,
    private location: Location
  ) {}

  async ngOnInit() {
    let cast = await firstValueFrom(this.dashboardService.getCast());

    this.formGroup = this.formBuilder.group({
      castId: [cast?.data?.id],
      title: [cast?.data?.attributes?.title, Validators.required],
      logoUri: [cast?.data?.attributes?.castIcon?.web?.nav],
      faviconUri: [cast?.data?.attributes?.castIcon?.web?.favicon],
      heroUri: [cast?.data?.attributes?.castIcon?.web?.hero],
      bannerUri: [cast?.data?.attributes?.castIcon?.web?.banner],
    });
  }

  onUpload(event, formControlName: string) {
    this.formGroup.get(formControlName).setValue(event.uri);
  }

  async saveCast() {
    await firstValueFrom(
      this.dashboardService.saveCast({
        data: {
          attributes: {
            title: this.formGroup.value.title,

            castIcon: {
              web: {
                favicon: this.formGroup.value.faviconUri,

                nav: this.formGroup.value.logoUri,

                hero: this.formGroup.value.logoUri,

                banner: this.formGroup.value.bannerUri,
              },
            },
          },
        },
      })
    );

    this.location.back();
  }
}
