<!-- From https://tailwindui.com/components/application-ui/forms/select-menus -->
<!-- <div class="my-2" (click)="openDropdown(dropdownBody)">
  asdasd
</div> -->

<button
  (click)="openDropdown(dropdownBody)"
  class="block w-full border border-gray-300 transition-colors hover:bg-gray-50 rounded py-1 px-2 leading-tight text-sm text-left cursor-default focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
>
  <div class="flex justify-between items-center">
    <div class="truncate flex-1 min-w-0">
      {{ getSelectedText() }}
      <!-- {{ selectedTextSubject | async }} -->
    </div>

    <app-icon icon="unfold_more"></app-icon>
  </div>
</button>

<ng-template #dropdownBody>
  <ul
    class="mt-2 py-2 rounded-md shadow-lg bg-white border border-gray-200 overflow-y-scroll"
    tabindex="-1"
    role="listbox"
    aria-labelledby="listbox-label"
    aria-activedescendant="listbox-option-3"
  >
    <ng-container *ngFor="let option of options">
      <li
        class="text-primary text-sm cursor-default select-none relative py-1 d px-2 hover:bg-primary hover:text-white"
        id="listbox-option-0"
        role="option"
      >
        <button
          (click)="toggleSelection(option.value)"
          class="w-full text-left"
        >
          <div class="flex gap-1">
            <span *ngIf="isSelected(option.value)">
              <app-icon icon="check_box"></app-icon>
            </span>

            <span *ngIf="!isSelected(option.value)">
              <app-icon icon="check_box_outline_blank"></app-icon>
            </span>

            <span class="font-normal ml-3 block truncate">
              {{ option.text }}
            </span>
          </div>
        </button>
      </li>
    </ng-container>
  </ul>
</ng-template>

<!-- <app-dropdown-layout
  #dropdown
  position="left"
  toggleClass="
      form-control
      text-left
      cursor-default
      focus:outline-none
      focus:ring-1
      focus:ring-primary-500
      focus:border-primary-500
      sm:text-sm"
>
  <div dropdownToggle class="flex justify-between items-center">
    <div class="truncate flex-1">
      {{ selectedTextSubject | async }}
    </div>

    <app-icon icon="unfold_more"></app-icon>
  </div>

  <ul
    *ngIf="dropdown.isOpen"
    dropdownContent
    class=""
    tabindex="-1"
    role="listbox"
    aria-labelledby="listbox-label"
    aria-activedescendant="listbox-option-3"
  >
    <ng-container *ngFor="let option of options">
      <li
        class="
          text-primary
          cursor-default
          select-none
          relative
          py-1
          px-2
          hover:bg-primary hover:text-white
        "
        id="listbox-option-0"
        role="option"
      >
        <button
          (click)="toggleSelection(option.value)"
          class="w-full text-left"
        >
          <div class="flex justify-between">
            <span class="font-normal ml-3 block truncate">
              {{ option.text }}
            </span>

            <span *ngIf="isSelected(option.value)">
              <app-icon icon="check"></app-icon>
            </span>
          </div>
        </button>
      </li>
    </ng-container>
  </ul>
</app-dropdown-layout> -->
