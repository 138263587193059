import { Injectable } from "@angular/core";
import { Papa } from "ngx-papaparse";
import { Observable } from "rxjs";

import camelcaseKeys from "camelcase-keys";
import decamelizeKeys from "decamelize-keys";

@Injectable({
  providedIn: "root",
})
export class CsvService {
  constructor(private papa: Papa) {}

  jsonToBlob(data: any, fieldorder?: string[]): Blob {
    // const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
    // const header = fieldorder || Object.keys(data[0]);
    // const csv = data.map((row) =>
    //   header
    //     .map((fieldName) => JSON.stringify(row[fieldName], replacer))
    //     .join(",")
    // );
    // csv.unshift(header.join(","));

    const csvArray = this.papa.unparse(data, {
      columns: fieldorder,
    });

    const blob = new Blob([csvArray], { type: "text/csv" });

    return blob;
  }

  jsonToFile(data: any, filename: string, fieldorder?: string[]): File {
    let blob: Blob = this.jsonToBlob(data, fieldorder);

    return new File([blob], filename, {
      type: "text/csv",
      lastModified: Date.now(),
    });
  }

  downloadAsCsv(data: any, filename: string, fieldorder?: string[]) {
    let blob = this.jsonToBlob(data, fieldorder);

    const a = document.createElement("a");
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();

    return Promise.resolve();
  }

  csvFileToJson(
    file: File | string,
    options: {
      delimiter?: string;
      camelCaseKeys?: boolean;
    } = {}
  ): Observable<any[]> {
    return new Observable((observer) => {
      this.papa.parse(file, {
        header: true,
        delimiter: options?.delimiter,
        complete: (result) => {
          if (options.camelCaseKeys) {
            observer.next(
              camelcaseKeys(result.data, {
                deep: true,
              })
            );
          } else {
            observer.next(result.data);
          }
          observer.complete();
        },
        error: (err) => {
          observer.error(err);
        },
      });
    });
  }

  jsonToCsvFile(
    data: any,
    filename,
    fieldorder?: string[],
    options: { snakeCaseKeys?: boolean } = {}
  ) {
    return new Observable<File>((observer) => {
      try {
        let result;

        if (options.snakeCaseKeys) {
          result = this.jsonToFile(
            decamelizeKeys(data, {
              deep: true,
            }),
            filename,
            fieldorder
          );
        } else {
          result = this.jsonToFile(data, filename, fieldorder);
        }

        observer.next(result);
        observer.complete();
      } catch (err) {
        observer.error(err);
      }
    });
  }
}
