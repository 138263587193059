import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { from, Observable } from "rxjs";
import { flatMap, map, mergeMap, tap } from "rxjs/operators";
import { CsvService } from "src/app/services/csv.service";
import { Group, User, PassService } from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-group-member-list",
  templateUrl: "./group-member-list.component.html",
  styleUrls: ["./group-member-list.component.scss"],
})
export class GroupMemberListComponent implements OnInit {
  title = "Group Members";
  group: Group;
  users: User[];

  userObservable: Observable<User[]>;

  pageSize = 20;
  userCount: number;

  constructor(
    private passService: PassService,
    private route: ActivatedRoute,
    private csvService: CsvService
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  async loadData() {
    let { group_uuid: groupUuid } = this.route.snapshot.queryParams;

    this.userObservable = this.route.queryParams.pipe(
      flatMap((params) => {
        let { page, q } = params;

        let limit = this.pageSize;
        let offset = this.pageSize * page;

        return this.passService.listUsers(q, limit, offset, null, null, [
          groupUuid,
        ]);
      }),
      tap((result) => {
        this.userCount = result.count;
      }),
      map((result) => result.users)
    );
  }

  exportUsers() {
    let { group_uuid: groupUuid } = this.route.snapshot.queryParams;

    return this.passService
      .listUsers(null, null, null, null, null, [groupUuid])
      .pipe(
        map((result) => result.users),

        tap((users) => {
          return this.csvService.downloadAsCsv(
            users,
            `group-${groupUuid}-users-${Date.now()}.csv`,
            [
              "uuid",
              "email",
              "firstName",
              "lastName",
              "createdAt",
              "updatedAt",
              "confirmationSentAt",
              "confirmedAt",
            ]
          );
        })
      )
      .toPromise();
  }
}
