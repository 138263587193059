import { HttpEventType } from "@angular/common/http";
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Subscription, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { UploadsService } from "src/app/sinigangnababoywithgabi";
import { fadeInOut } from "../../animations/fade";

@Component({
  selector: "app-upload-wrapper",
  templateUrl: "./upload-wrapper.component.html",
  styleUrls: ["./upload-wrapper.component.scss"],
  animations: [fadeInOut],
})
export class UploadWrapperComponent implements OnInit {
  @ViewChild("fileInput") fileInput: ElementRef;
  @Input()
  clicked: boolean = false;
  @Input()
  disabled: boolean;
  @Input()
  accept: string;

  @Input()
  downloadFilename: string;

  //Filename without extension extension will be added here
  @Input()
  fileName: any;

  @Input()
  multiple: boolean = false;

  @Output()
  upload = new EventEmitter<any>();

  uploadSubscription: Subscription;

  progress: number;
  file: File;
  isUploadDone: boolean = false;

  constructor(private uploadService: UploadsService) {}

  //Rollback for canva
  /*ngOnChanges(changes: SimpleChanges): void {
    if (this.fileInput) {
      let change = changes["clicked"];
      if (change.currentValue !== change.previousValue) {
        this.fileInput.nativeElement.click();
      }
    }
  }*/

  ngOnInit() {}

  clamp(num, min, max) {
    return Math.min(Math.max(num, min), max);
  }

  dropped(event) {
    let dropfile = event[0];

    dropfile.fileEntry.file((file: File) => {
      this.file = file;
      this.fileSelect();
    });
  }

  fileSelect() {
    let file = this.file;

    let fileExtension = file.name.split(".").pop();

    this.progress = 0;

    let downloadname;

    // if (this.downloadFilename) {
    //   downloadname = `attachment;filename=${
    //     this.downloadFilename
    //   }.${fileExtension}`;
    // }

    // Ignore content disposition for now
    this.isUploadDone = false;
    this.uploadSubscription = this.uploadService
      .uploadFile(file, undefined, "events", true)
      // .uploadFile(file, downloadname, 'events', true)
      .pipe(
        catchError((err) => {
          this.file = null;
          this.progress = null;

          throw err;
        })
      )
      .subscribe(
        (event) => {
          if (event && event.type == HttpEventType.UploadProgress) {
            // this.progress = this.clamp(event.loaded / event.total, 0, 0.99);
            this.progress = event.loaded / event.total;
          } else if (event && event.type == HttpEventType.Response) {
            this.isUploadDone = true;
            let result = event.body;

            this.upload.emit({
              uri: result["uri"],
              contentType: result["content_type"] || this.file.type,
            });

            this.progress = null;
          }
        },
        catchError((error) => {
          this.isUploadDone = true;
          return throwError(error);
        })
      );
  }

  fileRead(event) {
    this.file = event.target.files[0];
    event.srcElement.value = null;

    this.fileSelect();
  }

  cancelUpload() {
    if (this.uploadSubscription) {
      this.uploadSubscription.unsubscribe();
      this.progress = null;
    }
  }
}
