<app-back-title-layout layoutTitle="Student Profile" [loading]="!tableValues">
  <ng-container *ngIf="tableValues">
    <div class="flex items-center mb-5">
      <div>
        <app-icon icon="person" class="text-gray-600 text-6xl"></app-icon>
      </div>
      <div class="text-sm">
        <div class="text-gray-900 font-bold">
          {{ user.firstName }} {{ user.lastName }}
        </div>

        <div class="text-gray-600 leading-loose">{{ user.email }}</div>
      </div>
    </div>

    <a
      class="my-5 text-primary font-bold flex justify-between"
      [routerLink]="['/users/profile']"
      [queryParams]="{ user_uuid: user.uuid }"
    >
      <span>View User Profile</span>
      <app-icon icon="chevron_right" class="text-lg"></app-icon>
    </a>

    <hr class="my-5" />

    <a
      href="mailto:{{ user.email }}"
      class="inline-block btn btn-primary w-full"
    >
      <app-icon icon="chat"></app-icon>
      EMAIL USER
    </a>

    <div class="my-5 font-bold">Course Completion</div>

    <app-item-list
      [itemTemplate]="itemTemplate"
      [items]="tableValues"
    ></app-item-list>
  </ng-container>
</app-back-title-layout>

<ng-template #itemTemplate let-tableValue="item">
  <div class="flex items-center">
    <img [src]="tableValue.coverUri" alt="" class="w-10 h-10 mx-2" />

    <div>
      <a
        [routerLink]="['/_exams', 'submission-responses']"
        [queryParams]="{
          block_status_uuid: tableValue.blockStatusUuid,
          submission_uuid: tableValue.submissionUuid,
          exam_uuid: tableValue.examUuid
        }"
        class="block"
      >
        <div class="leading-tight font-semibold">
          {{ tableValue.name }}
        </div>

        <div class="leading-tight text-primary-light">
          Score {{ tableValue.scoreText }}
        </div>

        <div>
          <span class="rounded-full text-xs px-2 {{ tableValue.statusClass }}">
            {{ tableValue.statusText }}
          </span>
        </div>
      </a>
    </div>
  </div>
</ng-template>
