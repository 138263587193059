<div class="m-5">
  <h2 class="text-2xl font-bold">
    <app-icon icon="lightbulb_outline"></app-icon>
    Course Set
  </h2>

  <div class="text-sm text-gray-400 mb-5">
    Drag and drop resources to add to your course outline
  </div>

  <app-search-bar-query class="mb-10"></app-search-bar-query>

  <ng-container *ngIf="resources">
    <ul
      cdkDropList
      [cdkDropListConnectedTo]="cdkDropListConnectedTo"
      [cdkDropListSortingDisabled]="true"
      (cdkDropListDropped)="onDrop($event)"
      [cdkDropListData]="resources"
    >
      <ng-container *ngFor="let item of resources">
        <li cdkDrag [cdkDragData]="item" class="flex flex-grow rounded py-2">
          <img
            class="h-10 w-10 mx-2 object-contain flex-none"
            [src]="item.coverUri || 'assets/imgplaceholder.png'"
          />

          <div class="min-w-0">
            <div class="text-primary-light text-xs uppercase">
              {{ item | resourceType }}
            </div>

            <div class="min-w-0 font-semibold truncate">
              {{ item.name }}
            </div>
          </div>
        </li>
      </ng-container>
    </ul>
  </ng-container>
</div>
