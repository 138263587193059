import { ChangeDetectorRef, Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  Observable,
  catchError,
  distinctUntilChanged,
  firstValueFrom,
  from,
  map,
  mergeMap,
  of,
  shareReplay,
  switchMap,
  tap,
  toArray,
} from "rxjs";
import { ExamResultItem } from "src/app/exams/exam-results/exam-results.component";
import { ActivityLogService } from "src/app/services/activity-log.service";
import {
  PassService,
  Resource,
  ResourcesService,
  V1ExamChoice,
  V1ExamItem,
  V1ExamsService,
} from "src/app/sinigangnababoywithgabi";
import {
  SPREAD_TYPE,
  SpreadView,
} from "../../misc/burislides-edit/burislides-edit.component";
import { QUESTIONS } from "../../components/burislides-modal/burislides-modal.component";
import { ModalService } from "src/app/services/modal.service";
import { BurslidesAnalyticsUsersModalComponent } from "../../components/burslides-analytics-users-modal/burslides-analytics-users-modal.component";
import { CsvService } from "src/app/services/csv.service";
import { TimeDurationPipe } from "src/app/app-common-module/pipes/time-duration.pipe";

interface SpreadAnalytics {
  spreadType?: string;
  loading?: true;

  // Generic
  uri?: string;
  usersViewed?: number;
  usersViewdUuids?: string[];
  averageTimeSpent?: number;
  linkClickCount?: number;
  linkClickUserUuids?: string[];

  // If Activity
  examResultItem?: ExamResultItem;

  // If Activity group

  examResultItems?: ExamResultItem[];
}

@Component({
  selector: "app-resource-burislide-analytics",
  templateUrl: "./resource-burislide-analytics.component.html",
  styles: [],
})
export class ResourceBurislideAnalyticsComponent {
  navigated: boolean = false;
  spreadIndex: number;
  spreadAnalytics: SpreadAnalytics = {};
  spreadViews: SpreadView[] = [];
  examItem: V1ExamItem;
  examChoices: V1ExamChoice[];
  resource: Resource;
  examResultItems: ExamResultItem[];
  currentSlide: SpreadView;
  loading: boolean = false;
  // Long term convert to pipe?
  spreadTypeMap = {
    slide: "Slide",
    activity: "Activity",
    link: "Link",
    "download-link": "Download Link",
    video: "Video",
    pdf: "PDF",
    carousel: "Image Carousel",
    html: "HTML",
    markdown: "Text",
  };

  downloadCsvObs: Observable<any>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private resourcesService: ResourcesService,
    private v1ExamsService: V1ExamsService,
    private activityLogService: ActivityLogService,
    private modalService: ModalService,
    private cd: ChangeDetectorRef,
    private csvService: CsvService,
    private passService: PassService,
    private timeDurationPi0pe: TimeDurationPipe
  ) {}

  async ngOnInit() {
    this.loading = true;
    await this.fetchResource();
    await this.fetchExamResults();
    this.loading = false;
    this.initSlidePage();
  }

  clamp(num, min, max) {
    return Math.min(Math.max(num, min), max);
  }
  updateSpreadViews() {
    // Update image spread numbering
    this.spreadViews
      .filter((view) => {
        return view.spread.spreadType === SPREAD_TYPE.IMAGE_SPREAD;
      })
      .forEach((spread, index) => {
        spread.leftPageNumber = index * 2 + 1;
        spread.rightPageNumber = index * 2 + 2;
      });

    // Update slide numbering
    this.spreadViews
      .filter((view) => {
        return (
          view.spread.spreadType !== SPREAD_TYPE.ACTIVITY &&
          view.spread.spreadType !== SPREAD_TYPE.IMAGE_SPREAD
        );
      })
      .forEach((spread, index) => {
        spread.slidePageNumber = index + 1;
      });
    //Update Thumbnails
    this.spreadViews
      .filter((view) => {
        return view.spread.spreadType === SPREAD_TYPE.SLIDE;
      })
      .forEach((spread, index) => {
        spread.slideThumbnail = spread.spread.uri;
      });
    this.spreadViews
      .filter((view) => {
        return view.spread.spreadType === SPREAD_TYPE.ACTIVITY;
      })
      .forEach(async (spread, index) => {
        let item = await firstValueFrom(
          this.v1ExamsService.examsSectionsItemsRead(spread.spread.examItemUuid)
        );
        spread.itemType = item.type;
        spread.itemSubtype = item.subtype;
        spread.slideTitle = item.text;
        spread.slideRequired = item.required;
        //Add icon depending on the item type/subtype
        QUESTIONS.map((question) => {
          if (question.value === item.subtype) {
            spread.icon = question.icon;
          } else if (question.value === item.type) {
            spread.icon = question.icon;
          }
        });
      });
  }
  async fetchResource() {
    let { resource_uuid: resourceUuid } = this.route.snapshot.queryParams;

    this.resource = await firstValueFrom(
      this.resourcesService.getResourceByUuid(resourceUuid)
    );
    this.resource.content["spreads"].forEach((spread, index) => {
      this.spreadViews.push({
        spread,
      });
    });
    this.updateSpreadViews();
  }

  async fetchExamResults() {
    const examUuid = this.resource.content["examDetails"]?.["examUuid"];

    if (examUuid) {
      const result = await firstValueFrom(
        this.v1ExamsService.examsResultsGet(examUuid)
      );

      // HARDCODE: Map submitted_by to user uuid
      result.sections.forEach((section) => {
        section.items.forEach((item) => {
          item.responses.forEach((response) => {
            response.user =
              response["submittedBy"]?.["userUuid"] || response.user;
          });
        });
      });

      this.examResultItems = [];

      result.sections?.forEach((section) => {
        section.items?.map((item) => {
          let choices;

          if (item.type === "PO") {
            let choicesTotal = item.responses[0]?.["pollResults"]?.reduce(
              (a, b) => {
                return a + b.result.count;
              },
              0
            );

            choices = item.responses[0]?.["pollResults"]?.map(
              ({ choice, result }) => {
                let userUuidSet = new Set();

                item.responses
                  .filter((response) => {
                    return response["pollChoice"] === choice.uuid;
                  })
                  .map((response) => {
                    userUuidSet.add(response.user);
                  });

                return {
                  count: result.count,
                  countMax: choicesTotal,
                  choiceText: choice.longInput || choice.shortInput,
                  choiceImageUri: choice.imageUrl,
                  choiceAudioUri: choice.audioUrl,
                  isCorrect: choice.isCorrect,
                  userUuids: [...userUuidSet],
                };
              }
            );
          } else {
            choices = item.choices.map((choice) => {
              const userUuidSet = new Set();

              item.responses
                .filter((response) => {
                  return response?.["choices"]?.includes(choice.uuid);
                })
                .map((response) => {
                  userUuidSet.add(response.user);
                });

              return {
                count: choice.results?.count,
                countMax: choice.results?.total,
                choiceText: choice.longInput || choice.shortInput,
                choiceImageUri: choice.imageUrl,
                choiceAudioUri: choice.audioUrl,
                isCorrect: choice.isCorrect,
                userUuids: [...userUuidSet],
              };
            });
          }

          // }

          const responses = item.responses.map((response) => {
            return {
              responseText: response.longInput,
              responseCreatedAt: response.createdAt,
              responseIsCorrect:
                response.markedCorrect || response.isResponseCorrect,
              responseUserUuid: response.user,
            };
          });

          this.examResultItems.push({
            item: item,
            choicesV2: item.choices,
            itemUuid: item.uuid,
            itemText: item.text,
            itemType: item.type,
            itemSubtype: item.subtype,
            itemImageUri: item.mediaUrl,
            responses,
            choices,
          });
        });
      });
    }
  }

  async initSlidePage() {
    this.route.queryParams
      .pipe(
        map((params) => {
          return parseInt(params["spread_index"]) || 0;
        }),
        distinctUntilChanged(),
        tap(async (spreadIndex) => {
          this.spreadIndex = spreadIndex;

          this.spreadAnalytics.usersViewed = null;
          this.spreadAnalytics.usersViewdUuids = null;
          this.spreadAnalytics.averageTimeSpent = null;
          this.spreadAnalytics.examResultItem = null;
          this.spreadAnalytics.examResultItems = null;
          this.spreadAnalytics.linkClickCount = null;
          this.spreadAnalytics.linkClickUserUuids = null;
          this.downloadCsvObs = null;

          this.initDownloadCsvObs();

          this.spreadAnalytics.examResultItem = this.getSlideAnalytics(
            this.spreadIndex
          );

          const aggregateData = await this.getUserViewAndTimeAggregateData(
            this.spreadIndex
          );

          if (
            this.spreadAnalytics.spreadType === "link" ||
            this.spreadAnalytics.spreadType === "download-link"
          ) {
            const { linkClickCount, linkClickUserUuids } =
              await this.getUserLinkClickAggregateData(
                this.spreadAnalytics.uri
              );

            this.spreadAnalytics.linkClickCount = linkClickCount;
            this.spreadAnalytics.linkClickUserUuids = linkClickUserUuids;
          }

          this.spreadAnalytics.usersViewed = aggregateData.userCount;
          this.spreadAnalytics.usersViewdUuids = aggregateData.userUuids;
          this.spreadAnalytics.averageTimeSpent =
            aggregateData.averateTimePerUser * 1000;
        })
      )
      .subscribe();
  }

  getSlideAnalytics(spreadIndex: number) {
    const resourceSpread = this.resource.content.spreads[spreadIndex];
    this.currentSlide = this.spreadViews[spreadIndex];
    this.spreadAnalytics.spreadType = resourceSpread.spreadType;

    if (resourceSpread.spreadType === "slide") {
      this.spreadAnalytics.uri = resourceSpread.uri;
    } else if (resourceSpread.spreadType === "activity") {
      let examItemUuid = resourceSpread.examItemUuid;
      return this.examResultItems.find(
        (item) => item.itemUuid === examItemUuid
      );
    } else if (resourceSpread.spreadType === "activity-group") {
      this.spreadAnalytics.examResultItems = this.examResultItems.filter(
        (resultItem) => {
          return (
            resultItem.item.section === this.currentSlide.spread.examSectionUuid
          );
        }
      );
    } else if (
      resourceSpread.spreadType === "link" ||
      resourceSpread.spreadType === "download-link" ||
      resourceSpread.spreadType === "video" ||
      resourceSpread.spreadType === "pdf"
    ) {
      this.spreadAnalytics.uri = resourceSpread.uri;
    }
  }

  async openUsersViewedModal() {
    await this.modalService.openModal(BurslidesAnalyticsUsersModalComponent, {
      panelClass: ["w-2/3", "overflow-y-scroll"],
      data: {
        title: "Users who viewed",
        userUuids: this.spreadAnalytics.usersViewdUuids,
      },
    });
  }

  fetchSectionExamResults(sectionUuid: string) {
    return this.examResultItems.filter((item) => {
      return item.item.sectionUuid === sectionUuid;
    });
  }

  async openLinkClickedModal() {
    await this.modalService.openModal(BurslidesAnalyticsUsersModalComponent, {
      panelClass: "w-2/3",
      data: {
        title: "Users who clicked",
        userUuids: this.spreadAnalytics.linkClickUserUuids,
      },
    });
  }

  async getUserViewAndTimeAggregateData(spreadIndex: number) {
    let { resource_uuid: resourceUuid } = this.route.snapshot.queryParams;

    let result = await firstValueFrom(
      this.activityLogService.aggregate([
        {
          $match: {
            "statement.object.id":
              this.activityLogService.constructResourceObject({
                uuid: resourceUuid,
              }).id,
            "statement.object.definition.extensions.https://id&46;xapi&46;buri&46;io/extensions/burislide_page_index":
              spreadIndex,
          },
        },
        {
          $group: {
            _id: "$statement.actor.openid",
            userTotalDuration: {
              $sum: "$metadata.https://learninglocker&46;net/result-duration.seconds",
            },
          },
        },
        {
          $group: {
            _id: 1,
            userCount: {
              $sum: 1,
            },
            openids: {
              $addToSet: "$_id",
            },
            averageTimePerUser: {
              $avg: "$userTotalDuration",
            },
          },
        },
      ])
    );

    return {
      userCount: result[0]?.userCount || 0,
      userUuids:
        result[0]?.openids?.map((openid) => {
          // Extract uuid from openid via regex
          let match = openid.match(
            /[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/
          );
          return match[0];
        }) || [],
      averateTimePerUser: result[0]?.averageTimePerUser || 0,
    };
  }
  async getUserLinkClickAggregateData(uri: string) {
    let result = await firstValueFrom(
      this.activityLogService.aggregate([
        {
          $match: {
            "statement.object.id": uri,
            "statement.context.contextActivities.parent.id":
              this.activityLogService.constructResourceObject({
                uuid: this.resource.uuid,
              }).id,
          },
        },
        {
          $group: {
            _id: "$statement.actor.openid",
          },
        },
        {
          $group: {
            _id: 1,
            userCount: {
              $sum: 1,
            },
            openids: {
              $addToSet: "$_id",
            },
          },
        },
      ])
    );

    return {
      linkClickCount: result[0]?.userCount || 0,
      linkClickUserUuids:
        result[0]?.openids?.map((openid) => {
          // Extract uuid from openid via regex
          let match = openid.match(
            /[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/
          );
          return match[0];
        }) || [],
    };
  }

  navigateSlide(increment: number) {
    let newSpreadIndex = this.clamp(
      this.spreadIndex + increment,
      0,
      this.resource.content.spreads.length - 1
    );

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        spread_index: newSpreadIndex,
      },
      queryParamsHandling: "merge",
    });
    this.navigated = !this.navigated;
    this.cd.detectChanges();
  }

  async initDownloadCsvObs() {
    let { timeslot_uuid: timeslotUuid } = this.route.snapshot.queryParams;

    const examUuid = this.resource.content["examDetails"]?.["examUuid"];

    // CacheableGetusersObsMap

    const obsMap = {};

    if (examUuid) {
      this.downloadCsvObs = this.v1ExamsService.examsResultsGet(examUuid).pipe(
        map((result) => {
          // Extract responses

          // HARDCODE: Map submitted_by to user uuid
          result.sections.forEach((section) => {
            section.items.forEach((item) => {
              item.responses.forEach((response) => {
                response.user =
                  response["submittedBy"]?.["userUuid"] || response.user;
              });
            });
          });

          let itemStatMap = {};
          let itemChoicesMap = {};

          result.sections.forEach((section) =>
            section.items.forEach((item) => {
              itemStatMap[item.uuid] = item;

              item.choices.forEach((choice) => {
                itemChoicesMap[choice.uuid] = choice;
              });
            })
          );

          let responseItems = [];

          this.spreadViews.forEach((spread, spreadIndex) => {
            if (spread.spread.spreadType === SPREAD_TYPE.ACTIVITY) {
              let item = itemStatMap[spread.spread.examItemUuid];

              if (item) {
                item.responses.forEach((response) => {
                  responseItems.push({
                    Timestamp: response.createdAt,
                    "User uuid": response.user,
                    Name: "",
                    "Slide Number": spreadIndex + 1,
                    Question: item.text,
                    Answer: [
                      itemChoicesMap[response.pollChoice]?.shortInput ||
                        itemChoicesMap[response.pollChoice]?.longInput,
                      response.shortInput || response.longInput || "",
                      ...response.choices.map((uuid) => {
                        return (
                          itemChoicesMap[uuid]?.shortInput ||
                          itemChoicesMap[uuid]?.longInput
                        );
                      }),
                    ]
                      .filter((r) => r)
                      .join(", "),
                    "Is correct":
                      response.markedCorrect || response.isResponseCorrect,
                    itemType: item.type,
                    itemSubtype: item.subtype,
                  });
                });
              }
            } else if (
              spread.spread.spreadType === SPREAD_TYPE.ACTIVITY_GROUP
            ) {
              const sectionUuid = spread.spread.examSectionUuid;

              let examItems = result.sections.find(
                (section) => section.uuid === sectionUuid
              ).items as any[];

              for (let i = 0; i < examItems.length; i++) {
                let item = examItems[i];
                if (item) {
                  item.responses.forEach((response) => {
                    responseItems.push({
                      Timestamp: response.createdAt,
                      "User uuid": response.user,
                      Name: "",
                      "Slide Number": spreadIndex + 1 + "." + (i + 1),
                      Question: item.text,
                      Answer: [
                        itemChoicesMap[response.pollChoice]?.shortInput ||
                          itemChoicesMap[response.pollChoice]?.longInput,
                        response.shortInput || response.longInput || "",
                        ...response.choices.map((uuid) => {
                          return (
                            itemChoicesMap[uuid]?.shortInput ||
                            itemChoicesMap[uuid]?.longInput
                          );
                        }),
                      ]
                        .filter((r) => r)
                        .join(", "),
                      "Is correct":
                        response.markedCorrect || response.isResponseCorrect,
                      itemType: item.type,
                      itemSubtype: item.subtype,
                    });
                  });
                }
              }
            }
          });

          return responseItems;
        }),
        switchMap((result) => {
          // Fetch users. Cache results so that we don't have to fetch again

          return from(result).pipe(
            mergeMap((item) => {
              let userUuid = item["User uuid"];

              if (!userUuid) {
                return of({
                  firstName: "",
                  lastName: "",
                });
              }

              if (!obsMap[userUuid]) {
                obsMap[userUuid] = this.passService.getUser(userUuid).pipe(
                  catchError((err) => {
                    return of({
                      firstName: "",
                      lastName: "",
                    });
                  }),
                  shareReplay(1)
                );
              }

              return obsMap[userUuid];
            }, 5),
            toArray(),
            map((users: any[]) => {
              return result.map((item, index) => {
                let user = users[index];

                item.Name = `${user.firstName} ${user.lastName}`;

                return item;
              });
            })
          );
        }),
        switchMap((result) =>
          this.csvService.downloadAsCsv(
            result,
            `Slides_activity_answers_${new Date().toISOString()}.csv`
          )
        ),
        switchMap((result) => {
          // Get time aggregate data for all slides

          let { resource_uuid: resourceUuid } = this.route.snapshot.queryParams;

          return this.activityLogService.aggregate([
            {
              $match: {
                "statement.object.id":
                  this.activityLogService.constructResourceObject({
                    uuid: resourceUuid,
                  }).id,
              },
            },
            {
              $group: {
                _id: "$statement.actor.openid",
                name: {
                  $first: "$statement.actor.name",
                },
                userTotalDuration: {
                  $sum: "$metadata.https://learninglocker&46;net/result-duration.seconds",
                },
              },
            },
          ]);
        }),
        switchMap((result) => {
          // Download as csv

          return this.csvService.downloadAsCsv(
            result,
            `Slides_time_${new Date().toISOString()}.csv`,
            ["name", "userTotalDuration"]
          );
        }),
        switchMap((result) => {
          // Get link click stats

          let { resource_uuid: resourceUuid } = this.route.snapshot.queryParams;

          let slidesWithLinks = this.resource.content.spreads.filter(
            (spread) => {
              return (
                spread.spreadType === "link" ||
                spread.spreadType === "download-link"
              );
            }
          );

          return this.activityLogService.aggregate([
            {
              $match: {
                "statement.object.id": {
                  $in: slidesWithLinks.map((spread) => {
                    return spread.uri;
                  }),
                },
                "statement.context.contextActivities.parent.id":
                  this.activityLogService.constructResourceObject({
                    uuid: resourceUuid,
                  }).id,
              },
            },
            {
              $project: {
                timestamp: "$statement.timestamp",
                name: "$statement.actor.name",
                linkUri: "$statement.object.id",
              },
            },
          ]);
        }),
        switchMap((result) => {
          return this.csvService.downloadAsCsv(
            result,
            `Slides_link_clicks_${new Date().toISOString()}.csv`
          );
        })
      );
    }
  }
}
