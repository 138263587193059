<div class="mb-3 relative">
  <app-loading
    wrapperClass="absolute inset-0 bg-white opacity-50 flex items-center justify-center"
    [loading]="loading"
  >
  </app-loading>

  <label class="form-label"> HTML Upload </label>

  <div class="my-4">
    <label class="block truncate">
      <span class="form-label text-xs inline">ZIP file: </span>

      <a
        *ngIf="formGroup.value.content.zipUri"
        [href]="formGroup.value.content.zipUri"
        class="text-xs text-primary-light"
      >
        {{ formGroup.value.content.zipUri }}
      </a>

      <span class="text-xs" *ngIf="!formGroup.value.content.zipUri">
        No zip uploaded
      </span>
    </label>

    <input
      type="file"
      class="hidden"
      #zipUpload
      accept="application/zip"
      (change)="uploadZip($event)"
    />

    <button
      type="button"
      (click)="zipUpload.click()"
      class="btn text-xs uppercase text-white bg-primary-light py-1 px-1 w-full"
    >
      Upload zip file
    </button>

    <app-form-control-error-layout
      [control]="formGroup.get('content').get('zipUri')"
    ></app-form-control-error-layout>
  </div>

  <!-- <ng-template let-openFileSelector="openFileSelector"> -->
  <ul *ngIf="baseDirectory.length > 0">
    <ng-container *ngFor="let item of baseDirectory">
      <ng-container *ngIf="item.isFile">
        <li
          (click)="selectIndex(item)"
          class="px-2 cursor-pointer"
          [ngClass]="{
            'bg-purple-200': item.isIndex
          }"
        >
          <app-icon class="text-gray-500" [icon]="'text_snippet'"></app-icon>
          {{ item.name }}
        </li>
      </ng-container>
      <ng-container *ngIf="!item.isFile">
        <li class="px-2">
          <app-icon class="text-primary-light" [icon]="'folder'"></app-icon>
          {{ item.name }}
        </li>
      </ng-container>
    </ng-container>
  </ul>

  <!-- <div *ngIf="baseDirectory.length === 0" class="p-10 text-center">
    Drop files here
  </div> -->

  <!-- <button type="button" (click)="openFileSelector()">Browse Files</button> -->
  <!-- </ng-template> -->
  <div class="my-1">
    <span class="form-label text-xs inline"> Selected index file:</span>

    <span class="text-xs">
      {{ formGroup.value.content.uri?.split("/").pop() || "No index selected" }}
    </span>
  </div>
  <div class="my-3">
    <a
      *ngIf="formGroup.value.content.uri"
      target="_blank"
      class="block btn btn-primary w-full"
      [href]="formGroup.value.content.uri"
    >
      Preview</a
    >

    <a
      *ngIf="!formGroup.value.content.uri"
      target="_blank"
      class="block btn btn-primary w-full cursor-not-allowed"
    >
      Preview</a
    >
  </div>
</div>

<div class="d-flex flex-column align-items-center" *ngIf="resource">
  <!-- <app-file-upload-input-view
    (upload)="onUpload($event)"
    accept="application/zip"
  >
    <div
      class="border p-1 d-flex flex-column justify-content-center text-center text-primary border-primary box-uploader"
    >
      HTML Zip File
      <app-icon icon="html-five" class="h1"></app-icon>
    </div>
  </app-file-upload-input-view> -->
</div>
