import { Component, OnInit } from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from "@angular/forms";
import { ResourcesService } from "src/app/sinigangnababoywithgabi";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { firstValueFrom } from "rxjs";

@Component({
  selector: "app-collection-new",
  templateUrl: "./collection-new.component.html",
})
export class CollectionNewComponent implements OnInit {
  formGroup: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private resourcesService: ResourcesService
  ) {}

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      name: ["", Validators.required],
    });
  }

  async createCollection() {
    let parentUuid = this.route.snapshot.queryParams["parent_uuid"];

    let collection = await firstValueFrom(
      this.resourcesService.createCollection({
        ...this.formGroup.value,
        publishedAt: new Date(),
        parentPackageUuids: parentUuid ? [parentUuid] : null,
      })
    );

    this.router.navigate(["/components/list"], {
      queryParams: { parent_uuid: collection.uuid },
    });
    // this.location.back();
  }
}
