<app-back-title-layout [loading]="!formGroup">
  <ng-container *ngIf="formGroup">
    <form [formGroup]="formGroup" (ngSubmit)="onSave()">
      <label class="form-label">Colors</label>
      <div class="text-gray-400 text-xs">
        Your Cast already have its default overall color theme, changing the
        assigned colors below will override the your overall Cast color theme
      </div>

      <button
        type="button"
        (click)="resetToDefault()"
        class="my-2 text-primary text-sm hover:underline font-semibold"
      >
        Reset to default
      </button>
      <div class="my-5">
        <label class="form-label">Header</label>
        <div class="text-gray-400 text-xs leading-tight">Preview</div>
        <div
          class="border-2 my-1 p-5"
          [style.backgroundColor]="formGroup.value.baseBackgroundColor"
        >
          <div class="flex flex-col w-full items-center px-20 py-16">
            <div class="flex items-center py-1 w-full">
              <div
                class="font-bold mb-2 text-4xl justify-center flex w-full"
                [style.color]="formGroup.value.baseForegroundColor"
                [style.fontFamily]="formGroup.value.fontStyle"
              >
                {{ formGroup.value.title }}
              </div>
            </div>
            <div class="flex items-center justify-center py-1 w-full">
              <app-icon icon="search" class="mx-1"></app-icon>

              <input
                class="form-control"
                placeholder="Search"
                [disabled]="true"
              />
              <!-- <div class="h-5 border border-gray-700 rounded w-full mx-1"></div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="my-5">
        <label class="form-label">Header BG</label>
        <div class="w-24">
          <app-color formControlName="baseBackgroundColor"></app-color>
        </div>
      </div>
      <div class="my-5">
        <label class="form-label">Header Title</label>
        <input
          formControlName="title"
          placeholder="Header Title"
          class="form-control"
        />
      </div>
      <div class="my-5">
        <label class="form-label">Font Style</label>

        <app-font-select formControlName="fontStyle"> </app-font-select>
      </div>
      <label class="form-label">Header Title Text</label>

      <div class="w-24">
        <app-color formControlName="baseForegroundColor"></app-color>
      </div>

      <div class="flex w-full justify-end">
        <button class="btn btn-primary" type="submit">SAVE</button>
      </div>
    </form>
  </ng-container>
</app-back-title-layout>
