<div class="relative">
  <app-loading
    [loading]="isLoading"
    wrapperClass="absolute inset-0 bg-white flex justify-center items-center"
  ></app-loading>

  <div class="min-h-40">
    <div *ngIf="formGroup" [formGroup]="formGroup">
      <div formArrayName="rubricFormArray">
        <ng-container
          *ngFor="
            let rubricControl of getRubricFormArray()?.controls;
            let i = index
          "
        >
          <div [formGroupName]="i">
            <label>
              <div class="form-label">
                {{ rubricControl.value.name }}
              </div>

              <div class="flex">
                <div class="w-3/4">
                  <input
                    type="number"
                    class="form-control"
                    formControlName="score"
                  />
                </div>

                <div class="w-1/4 flex justify-center items-center">
                  <span>
                    {{
                      rubricControl.value.score * rubricControl.value.weight
                        | number: "1.0-2"
                    }}
                  </span>
                </div>
              </div>
            </label>
          </div>
        </ng-container>

        <label class="block mt-5">
          <div class="form-label">Total Points</div>

          <div
            class="
              bg-blue-200
              text-blue-700
              rounded
              h-8
              text-center
              leading-8
              mb-3
            "
          >
            {{ totalScore }}
          </div>
        </label>

        <button
          class="btn btn-primary w-full my-1"
          (click)="saveResponseCriteria()"
        >
          DONE
        </button>
      </div>
    </div>
  </div>
</div>
