import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-course-analytics-index",
  templateUrl: "./course-analytics-index.component.html",
})
export class CourseAnalyticsIndexComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
