import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  distinctUntilChanged,
  first,
  map,
  Observable,
  of,
  switchMap,
  tap,
} from "rxjs";
import { fadeInOut } from "src/app/app-common-module/animations/fade";
import { Header } from "src/app/app-common-module/components/list-table-header/list-table-header.component";
import {
  CoursesService,
  ResourcesService,
} from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-batch-modal",
  templateUrl: "./batch-modal.component.html",
  styles: [],
  animations: [fadeInOut],
})
export class BatchModalComponent implements OnInit {
  @Output() batch = new EventEmitter<any>();
  @Input() course: any;
  @Input() currentIndex: number;
  loading: boolean = false;
  listBatchObservable: Observable<any>;
  limit: number = 4;
  totalBatchCount: number;
  headers: Header[] = [
    { name: "Batch Name", value: "name", width: "w-96" },
    { name: "Batch Status", value: "batch_status", width: "w-56" },
    { name: "Access Period", value: "access_period", width: "w-96" },
  ];

  constructor(
    private courseService: CoursesService,
    private resourcesService: ResourcesService,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.listBatchObservable = this.route.queryParams.pipe(
      distinctUntilChanged(),
      switchMap((params) => {
        let { batch, course_uuid, page } = params;
        if (!batch) batch = null;
        if (!page) page = 0;
        if (this.course) {
          return this.courseService.courseScheduleListWithCount(
            this.course.uuid,
            this.limit,
            page * this.limit,
            batch
          );
        }
        // return this.courseService.courseScheduleList(course_uuid);
        return of({ count: 0, schedules: [] });
      }),
      tap((result) => {
        this.cd.detectChanges();
        this.totalBatchCount = result.count;
      }),
      map(({ count, schedules }) => {
        return schedules.map((batch) => {
          let status =
            batch.end > Date.now() || batch.end === null
              ? "ongoing"
              : batch.end < Date.now()
              ? "done"
              : batch.start > Date.now()
              ? "queued"
              : null;
          return {
            uuid: batch.uuid,
            name: batch.name,
            role: batch.role,
            start: batch.start,
            end: batch.end === null ? "Forever" : batch.end,
            status: status,
            statusClass:
              status === "ongoing"
                ? "text-success-500 bg-success-50"
                : status === "done"
                ? "text-gray-500 bg-gray-50"
                : status === "queue"
                ? "text-secondary-500 bg-secondary-500"
                : "",
          };
        });
      })
    );
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.course) {
      if (changes.course.currentValue !== changes.course.previousValue) {
        this.ngOnInit();
      }
    }
  }
  outputBatch(data) {
    this.batch.emit(data);
    this.router.navigate([], {
      relativeTo: this.route,
      replaceUrl: false,
      queryParamsHandling: "merge",
      queryParams: { batch: null, page: null },
    });
  }
}
