<app-back-title-layout layoutTitle="Exams Answers">
  <!-- <app-search-bar class="my-5" placeholder="Search" [(ngModel)]="searchString">
  </app-search-bar> -->

  <app-search-bar-query class="my-5" placeholder="Search">
  </app-search-bar-query>

  <button
    class="btn btn-primary w-full my-5"
    [appWaitForObsAfterClick]="downloadCsvObservable"
  >
    Download CSV
  </button>

  <!-- <app-item-list
    class="my-5"
    [items]="submissionList | filterAll: searchString"
    [itemTemplate]="itemTemplate"
  >
  </app-item-list> -->
  <app-item-list
    class="my-5"
    [source]="listResultObservable"
    [itemTemplate]="itemTemplate"
  >
  </app-item-list>
</app-back-title-layout>

<ng-template #itemTemplate let-submissionItem="item">
  <div class="flex">
    <div class="mx-1">
      <app-icon icon="person" class="text-gray-600 text-xl"></app-icon>
    </div>

    <div class="flex-1">
      <div>
        {{ submissionItem.userName }}
      </div>

      <a
        [routerLink]="['/_exams', 'submission-responses']"
        queryParamsHandling="merge"
        [queryParams]="{ submission_uuid: submission.submissionUuid }"
        class="flex items-center justify-between my-1"
        *ngFor="let submission of submissionItem.submissionData.slice(0, 1)"
      >
        <div class="text-sm text-gray-600">
          Latest submission at
          {{ submission.submissionDate | date : "medium" }}
        </div>

        <div
          class="bg-blue-200 text-blue-700 rounded h-8 w-10 text-center leading-8"
        >
          {{ submission.submissionScore }}
        </div>
      </a>

      <!-- <ul>
        <li
          *ngFor="
            let submission of submissionItem.submissionData.slice(0, 1)
          "
        ></li>
      </ul> -->

      <details *ngIf="submissionItem.submissionData.length > 1">
        <summary
          class="text-center underline text-primary-light cursor-pointer"
        >
          See previous submissions ({{
            submissionItem.submissionData.length - 1
          }}
          submissions)
        </summary>

        <ul>
          <li
            *ngFor="let submission of submissionItem.submissionData.slice(1)"
            class="hover:bg-gray-100 rounded"
          >
            <a
              [routerLink]="['/_exams', 'submission-responses']"
              queryParamsHandling="merge"
              [queryParams]="{
                submission_uuid: submission.submissionUuid
              }"
              class="flex items-center justify-between my-1 pr-1"
            >
              <div class="text-sm text-gray-600">
                Submission at
                {{ submission.submissionDate | date : "medium" }}
              </div>

              <div
                class="bg-blue-200 text-blue-700 rounded h-8 w-10 text-center leading-8"
              >
                {{ submission.submissionScore }}
              </div>
            </a>
          </li>
        </ul>
      </details>

      <!-- <div
        *ngIf="submissionItem.submissionData.length > 1"
        class="text-center underl;ine text-primary-light"
      >
        See all submissions ({{ submissionItem.submissionData.length }}
        submissions)
      </div> -->
    </div>
  </div>
</ng-template>
