<button
  type="button"
  (click)="clickSlide()"
  class="w-[160px] h-[90px] flex transition-colors duration-100 rounded bg-primary-lightest justify-center items-center py-6 px-10 cursor-pointer"
  [ngClass]="{ 'border-2 border-secondary-400': selected }"
>
  <ng-container
    *ngIf="
      spreadView.slideThumbnail ||
        spreadView.spread.imageUri ||
        spreadView.spread.leftUri;
      else noThumbnail
    "
  >
    <img
      [src]="
        spreadView.slideThumbnail ||
        spreadView.spread.imageUri ||
        spreadView.spread.leftUri
      "
      class="object-contain h-[50px]"
    />
  </ng-container>
  <ng-template #noThumbnail>
    <app-icon [icon]="icon" class="text-primary-700 text-3xl"></app-icon
  ></ng-template>
</button>
<div
  class="font-bold uppercase text-xs flex items-center justify-center mt-1"
  *ngIf="
    spreadView.spread.spreadType !== 'new' &&
    spreadView.spread.spreadType !== 'activity' &&
    spreadView.spread.spreadType !== 'download-link' &&
    spreadView.spread.spreadType !== 'activity-group' &&
    spreadView.spread.spreadType !== 'image-spread'
  "
>
  {{ spreadView.spread.spreadType }}
</div>
<div
  class="text-xs flex items-start justify-center mt-1 font-bold"
  *ngIf="spreadView.spread.spreadType === 'image-spread'"
>
  BuriBooks Pages {{ spreadView.leftPageNumber }} &
  {{ spreadView.rightPageNumber }}
</div>
<div
  class="text-xs flex items-start justify-center mt-1 font-bold"
  *ngIf="spreadView.spread.spreadType === 'download-link'"
>
  {{ spreadView.spread.title }}
</div>
<div
  class="text-xs flex items-start justify-center mt-1 font-bold"
  *ngIf="
    spreadView.spread.spreadType === 'activity' ||
    spreadView.spread.spreadType === 'activity-group'
  "
>
  <!-- <div *ngIf="spreadView.slideRequired" class="text-primary-600">*</div> -->

  <div class="line-clamp-2">{{ spreadView.slideTitle }}</div>
</div>
