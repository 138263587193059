import { Component, HostBinding, Input, OnInit } from "@angular/core";
import { Subscription, Observable, Subject } from "rxjs";
import { catchError, takeUntil } from "rxjs/operators";

@Component({
  selector: "app-loading-with-retry",
  templateUrl: "./loading-with-retry.component.html",
  styleUrls: ["./loading-with-retry.component.scss"],
})
export class LoadingWithRetryComponent implements OnInit {
  @HostBinding("style.display")
  display;

  @Input()
  opacity: number = 0.5;

  @Input()
  observable: Observable<any>;

  subscription: Subscription;

  isError: boolean = false;
  isLoading: boolean = false;

  @Input()
  tryOnLoad = false;

  constructor() {}

  ngOnInit(): void {
    if (this.tryOnLoad) {
      this.try();
    } else {
      this.hide();
    }
  }

  private unsubscribe$: Subject<void> = new Subject<void>();
  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  retry() {
    if (this.isError) {
      this.try();
    }
  }

  try() {
    this.isError = false;

    this.show();

    if (this.observable) {
      this.isLoading = true;

      this.subscription?.unsubscribe();

      this.subscription = this.observable
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          () => {
            this.hide();
          },
          (err) => {
            this.isError = true;
            throw err;
          },
          () => {
            this.isLoading = false;
          }
        );
    } else {
      this.isLoading = false;
    }
  }

  show() {
    this.display = "block";
  }

  hide() {
    this.display = "none";
  }
}
