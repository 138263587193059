<div class="relative">
  <div class="absolute z-10 top-0 left-0 p-1">
    <app-icon icon="unfold_more" class="text-gray-600 bg-white"></app-icon>
  </div>
  <div class="absolute z-10 top-0 right-0 p-1">
    <app-casteditor-edit-toggle
      [ngClass]="{
        invisible: disableEdit
      }"
      (deleteBlock)="onDeleteBlock()"
    >
    </app-casteditor-edit-toggle>
  </div>

  <div>
    <span class="{{ block.properties.class }}">
      {{ block.properties.text }}
    </span>
  </div>
</div>
