import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Course, CoursesService } from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-course-payments",
  templateUrl: "./course-payments.component.html",
  styleUrls: ["./course-payments.component.scss"],
})
export class CoursePaymentsComponent implements OnInit {
  course: Course;
  constructor(
    private route: ActivatedRoute,
    private coursesService: CoursesService
  ) {}

  ngOnInit(): void {
    let { course_uuid: courseUuid } = this.route.snapshot.queryParams;

    this.coursesService.courseRead(courseUuid).subscribe((result) => {
      this.course = result;
    });
  }
}
