import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../app-common-module/guards/auth.guard";
import { TisComponent } from "./tis.component";
import { TisOptionsComponent } from "./tis-options/tis-options.component";

const routes: Routes = [
  {
    path: "tis",
    component: TisComponent,
    canActivate: [AuthGuard],
    data: {
      layoutTitle: "Training Information System (TIS)",
      hideBackTitleDiagonalDesign: false,
    },
    children: [
      {
        path: "",
        component: TisOptionsComponent,
        data: {
          layoutTitle: "Training Information System (TIS)",
          hideBackTitleDiagonalDesign: false,
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TisRoutingModule {}
