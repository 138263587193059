<div class="bg-white rounded">
  <h2 class="text-center my-3 text-xl font-bold">Add Block</h2>

  <div class="grid grid-cols-2 grid-row gap-3 text-primary p-5">
    <ng-container *ngFor="let blockType of blockTypes">
      <div class="h-48 w-48 aspect-square relative">
        <div class="absolute inset-0">
          <button
            [mat-dialog-close]="blockType"
            [disabled]="!!blockType.disabled"
            class="transition-colors duration-100 hover:bg-primary hover:text-primary-lightest rounded-lg bg-primary-lightest flex flex-col justify-center items-center h-full w-full p-3 cursor-pointer"
            [ngClass]="{
              'bg-gray-200 text-gray-400 hover:bg-gray-400 hover:text-gray-200':
                blockType.disabled,
              'hover:bg-primary hover:text-primary-lightest bg-primary-lightest':
                !blockType.disabled
            }"
          >
            <app-icon class="text-5xl" [icon]="blockType.icon"> </app-icon>

            <span class="font-semibold">
              {{ blockType.text }}
            </span>
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</div>
