import { animate, style, transition, trigger } from "@angular/animations";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { firstValueFrom, Observable, Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {
  DashBoardCard,
  DashboardService,
} from "src/app/services/dashboard.service";
import { fadeInOut } from "../../animations/fade";

export interface TitleIconButton {
  icon: string;
  observable?: Observable<any>;
  routerLink?: string | string[];
  queryParamsHandling?: string;
  queryParams?: any;
}

@Component({
  selector: "app-back-title-layout",
  templateUrl: "./back-title-layout.component.html",
  styleUrls: ["./back-title-layout.component.scss"],
  animations: [
    fadeInOut,
    trigger("enterAnimation", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate("200ms ease-out", style({ opacity: 1 })),
      ]),
      transition(":leave", [
        style({ opacity: 1 }),
        animate("200ms ease-in", style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class BackTitleLayoutComponent implements OnInit {
  @Input()
  title: string; //Deprecated in favor of layoutTitle

  @Input()
  layoutTitle: string;

  @Input()
  layoutTitleIcon: string;

  @Input()
  loading;

  @Input()
  backRouterLink;

  @Input()
  backQueryParamsHandling;

  @Input()
  titleSupportLink: string;

  @Input()
  titleIconButtons: TitleIconButton[];

  @Input()
  hideLogoHeader: boolean = false;

  @Output()
  result = new EventEmitter<any>();

  hideDiagonalStyle: boolean;
  actionSub: Subscription;
  hideButton: boolean = false;
  dashboardInfo: {
    dashboardTitle: string;
    dashboardLogoUrl: string;
  };
  cards: DashBoardCard[];

  breadcrumbs: {
    name: string;
    routerLink: string[];
    queryParams: any;
    disabled?: boolean;
  }[];

  constructor(
    private route: ActivatedRoute,
    private titleService: Title,
    private dashboardService: DashboardService
  ) {}

  async ngOnInit() {
    this.breadcrumbs = this.generateBreadCrumbs();

    this.route.data.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.hideDiagonalStyle = data.hideBackTitleDiagonalDesign;

      // For backwards compat. Prioritize Input title
      this.layoutTitle =
        this.layoutTitle || this.title || data.layoutTitle || data.title; // data.title is deprecated

      this.layoutTitleIcon = this.layoutTitleIcon || data.layoutTitleIcon;
    });

    this.cards = await firstValueFrom(
      this.dashboardService.getDashboardCards()
    );
  }

  private unsubscribe$: Subject<void> = new Subject<void>();
  ngOnDestroy() {
    this.actionSub?.unsubscribe();
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.layoutTitle) {
      this.layoutTitle = this.layoutTitle || this.title; // For backwards compatback
      this.titleService.setTitle(this.layoutTitle || "Cast Dashboard");
    }
  }
  doAction(observable: Observable<any>) {
    if (!observable) return;
    this.actionSub?.unsubscribe();
    this.actionSub = observable.subscribe((result) => {
      this.result.emit(result);
      this.hideButton = result.hideButton;
    });
  }
  generateBreadCrumbs() {
    return this.route.pathFromRoot
      .filter((route) => route.routeConfig?.data?.layoutTitle)
      .map((route) => {
        let { layoutTitle, preserveQueryParamFields, disableOnBreadcrumb } =
          route.routeConfig?.data;

        let queryParams = {};

        if (preserveQueryParamFields) {
          preserveQueryParamFields.forEach((key) => {
            queryParams[key] = route.snapshot.queryParams[key];
          });
        }

        return {
          name: layoutTitle,
          routerLink: [
            "/",
            ...route.pathFromRoot
              .map((r) => r.routeConfig?.path)
              .filter((path) => path),
          ],
          queryParams,
          disabled: true,
        };
      });
  }
}
