import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { catchError, Subscription, switchMap, tap } from "rxjs";
import { fadeInOut } from "src/app/app-common-module/animations/fade";
import { MailerService } from "src/app/services/mailer.service";
import { ModalService } from "src/app/services/modal.service";

@Component({
  selector: "app-save-template-modal",
  templateUrl: "./save-template-modal.component.html",
  styles: [],
  animations: [fadeInOut],
})
export class SaveTemplateModalComponent implements OnInit {
  @Input() filters: UntypedFormGroup;
  @Input() emailReq: UntypedFormGroup;
  mutateSubscription: Subscription;
  loading: boolean = false;
  form: UntypedFormGroup;
  constructor(
    private fb: UntypedFormBuilder,
    private mailerService: MailerService,
    private router: Router,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      tempName: [null, Validators.required],
      tempDesc: [null, Validators.required],
    });
  }
  ngOnDestroy(): void {
    this.mutateSubscription?.unsubscribe();
  }
  onSubmit() {
    this.loading = true;
    let tempName = this.form.get("tempName").value;
    let tempDesc = this.form.get("tempDesc").value;
    let subject = this.emailReq.get("subject").value;
    let email_body = this.emailReq.get("content").value;
    let date = this.emailReq.get("date").value;
    let time = this.emailReq.get("time").value;
    let sendOnDate = new Date(date + "T" + time).toISOString();
    let filters = this.mailerService.parseFilter(this.filters);
    this.mutateSubscription?.unsubscribe();
    this.mutateSubscription = this.mailerService
      .createEmailTemplate({
        name: tempName,
        description: tempDesc,
        email_body: email_body,
        send_on_date: sendOnDate,
        subject: subject,
        filters: filters,
      })
      .pipe(
        tap(({ data, loading }) => {
          if (!data) {
            throw new Error("No filters found");
          }
        }),
        switchMap(({ data, loading }) => {
          this.loading = loading;
          this.router.navigate(["_mailer/templates"]);
          return this.modalService.alert("Template saved");
        }),
        catchError((error) => {
          this.loading = false;
          return this.modalService.alert("No filter(s) found");
        })
      )
      .subscribe(() => {});
  }
}
