<!-- header -->
<div class="flex text-gray-400">
  <app-icon icon="filter_list"> </app-icon>
  <div class="ml-2 uppercase w-full">filter</div>
  <button class="flex justify-end text-lg" mat-dialog-close>
    <app-icon icon="close"></app-icon>
  </button>
</div>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <!-- filters -->

  <div formArrayName="filter">
    <div *ngFor="let _filter of _filters; let i = index" [formGroupName]="i">
      <div class="flex">
        <div class="uppercase font-bold my-2 w-full">filter {{ i + 1 }}</div>
        <button
          type="button"
          class="text-lg text-gray-400"
          (click)="removeFilter(i)"
        >
          <app-icon icon="close"></app-icon>
        </button>
      </div>
      <div class="block sm:flex w-full">
        <div class="sm:w-1/2 mr-2 w-full">
          <label for="field">Field</label>
          <div class="w-full">
            <select
              #selectOption
              formControlName="option"
              (change)="setOption(selectOption.value, i)"
              class="form-control"
            >
              <option [value]="null" disabled selected>Choose filter</option>
              <option *ngFor="let field of fields" [value]="field.value">
                {{ field.name }}
              </option>
            </select>
          </div>
        </div>
        <div
          class="sm:w-1/2 w-full flex"
          *ngIf="selected[i] === 'date' || selected[i] === 'last_signin'"
        >
          <div class="w-1/3">
            <label for="month">Month</label>
            <select
              #selectMonth
              (change)="setMonth(selectMonth.value, i)"
              formControlName="month"
              class="form-control"
              name="month"
            >
              <option [value]="null" disabled selected>---</option>
              <option *ngFor="let month of months" [value]="month.value">
                {{ month.name }}
              </option>
            </select>
          </div>
          <div class="w-1/3">
            <label for="day">Day</label>
            <select
              #selectDay
              (change)="setDay(selectDay.value, i)"
              formControlName="day"
              class="form-control"
              name="month"
            >
              <option [value]="null" disabled selected>---</option>
              <option *ngFor="let day of days" [value]="day">
                {{ day }}
              </option>
            </select>
          </div>
          <div class="w-1/3">
            <label for="year">Year</label>
            <select
              #selectYear
              (change)="setYear(selectYear.value, i)"
              formControlName="year"
              class="form-control"
              name="month"
            >
              <option [value]="null" disabled selected>---</option>
              <option *ngFor="let year of years" [value]="year">
                {{ year }}
              </option>
            </select>
          </div>
        </div>
        <div class="sm:w-1/2 w-full" [ngClass]="getHiddenClass(selected[i])">
          <label for="keyword">Keyword</label>
          <div class="w-full">
            <input
              placeholder="Type keyword(s)"
              class="form-control"
              id="keyword"
              formControlName="filter"
            />
          </div>
        </div>
      </div>
      <!-- <input class="form-control mb-2" [formControlName]="i" /> -->
    </div>
  </div>
  <!-- add filter button -->
  <button type="button" (click)="addFilter()" class="flex text-gray-400 my-4">
    <app-icon icon="add"> </app-icon>
    <div class="uppercase ml-2">add more filter</div>
  </button>
  <!-- field and keyword list form -->

  <!-- filters tab -->

  <!-- clear all and save buttons -->
  <div class="flex justify-between">
    <button
      type="button"
      (click)="clearAll()"
      class="px-4 py-1 border border-primary-600 rounded uppercase text-primary-600"
    >
      clear all
    </button>
    <button
      type="submit"
      class="text-white px-4 py-1 uppercase bg-primary-600 rounded"
      mat-dialog-close
    >
      save
    </button>
  </div>
</form>

<div class="flex"></div>
