<div class="w-full mx-auto border-2 rounded relative overflow-hidden">
  <div
    class="flex justify-between items-center p-2 bg-primary-lightest overflow-hidden"
  >
    <div class="text-xs text-primary font-bold">
      {{ item?.type | activityType }}
    </div>

    <button
      *ngIf="!hideControls"
      type="button"
      class="px-1 text-primary bg-gray-200"
      (click)="onPencil()"
    >
      <app-icon icon="edit"></app-icon>
    </button>
  </div>

  <div class="m-2">
    <div *ngIf="item?.mediaUrl">
      <img
        [src]="item?.mediaUrl"
        alt=""
        class="w-full h-24 object-contain bg-gray-300 rounded"
      />
    </div>

    <div class="text-sm font-bold">Question {{ index }}</div>

    <div class="mb-5 text-sm mt-1">
      <span *ngIf="item?.text">{{ item?.text }}</span>

      <span class="text-gray-600 italic" *ngIf="item && !item.text"
        >No question</span
      >
    </div>

    <div
      class="flex items-center justify-between border-t-2 text-sm text-primary py-1"
    >
      <div class="italic">
        <span *ngIf="item?.required"> *Required </span>
      </div>

      <div>
        Points
        <span class="border-b-2 border-primary-dark">{{ item?.weight }}</span>
      </div>
    </div>
  </div>

  <app-loading
    [loading]="!item"
    wrapperClass="absolute inset-0 bg-white opacity-50 flex items-center justify-center"
  >
  </app-loading>
</div>
