import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-casteditor-block-link",
  templateUrl: "./casteditor-block-link.component.html",
  styleUrls: ["./casteditor-block-link.component.scss"],
})
export class CasteditorBlockLinkComponent implements OnInit {
  constructor() {}

  @Input() block: any;
  @Input() disableEdit: boolean;
  @Output() deleteBlock: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {}

  onDeleteBlock() {
    this.deleteBlock.emit();
  }
}
