import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { firstValueFrom, from, of, Subject } from "rxjs";
import { map, switchMap, takeUntil, tap, catchError } from "rxjs/operators";
import { fadeInOut } from "src/app/app-common-module/animations/fade";
import {
  ActivityLogService,
  VERBS,
} from "src/app/services/activity-log.service";
import { DashboardService } from "src/app/services/dashboard.service";
import { ModalService } from "src/app/services/modal.service";
import { UserPreferenceService } from "src/app/services/user-preference.service";
import {
  Block,
  CastsService,
  Collection,
  Screen,
  V2Block,
  V2Cast,
  V2CastsService,
} from "src/app/sinigangnababoywithgabi";
import {
  BlockItem,
  CasteditorModalAddBlockComponent,
} from "../components/casteditor-modal-add-block/casteditor-modal-add-block.component";
import { CasteditorModalWelcomeComponent } from "../components/casteditor-modal-welcome/casteditor-modal-welcome.component";

@Component({
  selector: "app-casteditor-main",
  templateUrl: "./casteditor-main.component.html",
  styleUrls: ["./casteditor-main.component.scss"],
  animations: [fadeInOut],
})
export class CasteditorMainComponent implements OnInit {
  screen: Screen;
  initialBlock: V2Block;
  blocks: Block[];
  disableEdit: boolean = true;
  collection: Collection;
  components?: Collection["components"];
  screenId: string;

  welcomeDialog: MatDialogRef<CasteditorModalWelcomeComponent>;

  constructor(
    private route: ActivatedRoute,
    private castsService: CastsService,
    private v2CastsService: V2CastsService,
    private castService: CastsService,
    private userPreferenceService: UserPreferenceService,
    private modalService: ModalService,
    private dashboardService: DashboardService,
    private activityLogsService: ActivityLogService
  ) {}

  private unsubscribe$: Subject<void> = new Subject<void>();
  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  async ngOnInit() {
    this.initialize();
  }

  async initialize() {
    let cast = await firstValueFrom(this.dashboardService.getCast());
    let castId = cast.data.id;
    this.initialBlock = await this.getInitialBlock();
    let screens = await firstValueFrom(this.castService.screenList(castId));

    this.route.queryParams
      .pipe(
        takeUntil(this.unsubscribe$),
        tap(() => {
          this.screen = null;
          this.blocks = null;
        }),
        switchMap((params) => {
          let { screen_id: screenId } = params;
          screens.forEach((screen) => {
            if (screen.id === parseInt(screenId) || !screenId)
              this.disableEdit = false;
          });
          return this.castsService.screenGet(castId, screenId);
        }),
        catchError((err) => {
          return from(this.getDefaultScreenId(cast)).pipe(
            switchMap((screenId: any) =>
              this.castsService.screenGet(castId, screenId)
            )
          );
        }),
        switchMap((screen: Screen) => {
          this.screen = screen;
          this.screenId = screen.id + "";

          return this.castsService.blockList(castId, this.screenId);
        }),
        map((blocks) => {
          // HARDCODE: Remove legacy navbar if initialblock is present
          if (this.initialBlock) {
            this.blocks = blocks.filter((block) => block.type !== "navbar");
          } else {
            this.blocks = blocks;
          }
        })
      )
      .subscribe(() => {
        this.openWelcomeModal();
      });
  }

  async getDefaultScreenId(cast: V2Cast): Promise<string> {
    let screens = await firstValueFrom(
      this.castService.screenList(cast.data.id)
    );

    let screen = screens.find(
      (screen) => screen.slug == cast.data.attributes.homeScreen
    );

    return (screen?.id || screens[0]?.id) + "";
  }

  dropBlock(event: CdkDragDrop<string[]>) {
    let draggedBlock = this.blocks[event.previousIndex];

    moveItemInArray(this.blocks, event.previousIndex, event.currentIndex);

    this.saveBlockOrder();

    this.activityLogsService.didCastBlock(VERBS.sorted, draggedBlock, {
      context: {
        contextActivities: {
          parent: [
            this.activityLogsService.constructCastScreenObject(this.screen),
          ],
        },
      },
    });
  }

  async saveBlockOrder() {
    let cast = await firstValueFrom(this.dashboardService.getCast());
    let castId = cast.data.id;

    //Save block order.
    //Baka need ng mas maayos na backend solution to kasi andaming request.
    //For now nagwowork naman.
    Promise.all(
      this.blocks.map((block, i) => {
        return firstValueFrom(
          this.castsService.blockUpdate(castId, this.screenId, block.id + "", {
            block: {
              ...block,
              order: i * 10,
            },
          })
        );
      })
    );
  }

  async openWelcomeModal() {
    if (!this.userPreferenceService.isWelcomeMessageDisabled) {
      await this.modalService.openModal(CasteditorModalWelcomeComponent);
      this.userPreferenceService.isWelcomeMessageDisabled = true;
    }
  }

  async deleteBlock(blkIdx: number) {
    if (
      await this.modalService.confirm(
        "Are you sure you want to delete this block?"
      )
    ) {
      let cast = await firstValueFrom(this.dashboardService.getCast());
      let castId = cast.data.id;

      let [removedBlock] = this.blocks.splice(blkIdx, 1);

      await firstValueFrom(
        this.castsService.blockDelete(
          castId,
          this.screenId,
          removedBlock.id + ""
        )
      );

      this.activityLogsService.didCastBlock(VERBS.deleted, removedBlock, {
        context: {
          contextActivities: {
            parent: [
              this.activityLogsService.constructCastScreenObject(this.screen),
            ],
          },
        },
      });
    }
  }

  async getInitialBlock() {
    try {
      let v2Cast = await this.dashboardService.getCast().toPromise();

      let result = await this.v2CastsService
        .blockGet(v2Cast.data.attributes.initialBlockId)
        .toPromise();

      return result;
    } catch (err) {
      return null;
    }
  }

  async openAddBlockModal(blockIndex: number) {
    let blockItem: BlockItem = await this.modalService.openModal(
      CasteditorModalAddBlockComponent,
      { panelClass: "overflow-y-auto" }
    );

    if (blockItem) {
      let cast = await this.dashboardService.getCast().toPromise();

      let castId = cast.data.id;

      let block = await this.castService
        .blockCreate(castId, this.screenId, {
          block: {
            type: blockItem.value,
            properties: blockItem.defaultProperty || {},
            order: (blockIndex + 1) * 10,
          },
        })
        .toPromise();

      // Add to last block list. Unless magkaron ng add block in the middle
      this.blocks.splice(blockIndex + 1, 0, block);

      this.saveBlockOrder();
    }
  }
}
