<app-back-title-layout
  layoutTitle="Edit Screen"
  [loading]="!formGroup.value.screenId"
>
  <ng-container *ngIf="formGroup">
    <a
      class="block w-full btn btn-primary bg-primary-dark"
      [routerLink]="['/_casteditor']"
      queryParamsHandling="merge"
    >
      <app-icon icon="edit"></app-icon>

      EDIT SCREEN
    </a>

    <form (ngSubmit)="saveScreen()" class="my-10" [formGroup]="formGroup">
      <label class="block my-5">
        <div class="form-label">Screen Title</div>

        <input
          type="text"
          name="title"
          class="form-control"
          formControlName="title"
        />
      </label>

      <label class="block my-5">
        <div class="form-label">Slug</div>

        <input
          type="text"
          name="title"
          class="form-control"
          formControlName="slug"
        />

        <app-form-control-error-layout
          [control]="formGroup.get('slug')"
        ></app-form-control-error-layout>
      </label>

      <button
        type="submit"
        class="w-full my-5 btn btn-primary"
        [disabled]="formGroup.invalid || formGroup.disabled"
      >
        SAVE
      </button>
    </form>

    <button
      type="button"
      (click)="deleteScreen()"
      class="w-full btn btn-danger"
      [disabled]="formGroup.disabled"
    >
      <app-icon icon="delete"></app-icon>
      DELETE SCREEN
    </button>
  </ng-container>
</app-back-title-layout>
