<app-upload-wrapper
  id="upload"
  [clicked]="clicked"
  (upload)="onUpload($event)"
  accept="application/pdf"
>
  <div
    *ngIf="!formGroup.value.content?.uri"
    class="
      border
      bg-purple-200
      text-purple-500
      rounded
      w-full
      flex flex-col
      items-center
      py-10
    "
  >
    <app-icon icon="upload" class="text-6xl"></app-icon>
    <span>DRAG AND DROP FILE HERE</span>
  </div>

  <div *ngIf="formGroup.value.content?.uri">
    <iframe
      [src]="formGroup.value.content?.uri | safeResourceUrl"
      width="100%"
      height="300"
      type="application/pdf"
    ></iframe>
  </div>

  <app-form-control-error-layout
    [control]="formGroup.get('content').get('uri')"
  ></app-form-control-error-layout>

  <div
    *ngIf="formGroup.value.content?.uri"
    class="truncate text-purple-500 text-sm my-1"
  >
    {{ formGroup.value.content.uri }}
  </div>
  <!-- Removed (click)="addblock.open and moved it inside the component"-->
  <button
    type="button"
    class="btn w-full my-1 py-0 text-sm bg-purple-500 text-white"
  >
    CHANGE
  </button>
</app-upload-wrapper>

<ng-container *ngIf="formGroup.value.content?.uri">
  <a
    [href]="formGroup.value.content.uri"
    target="_blank"
    class="block btn w-full my-1 py-0 text-sm bg-blue-500 text-white"
  >
    <!-- <app-icon icon="eye"></app-icon> -->
    PREVIEW
  </a>
</ng-container>
<!--Uncomment if canva feature is ready-->
<!--
<app-modal
  overlayClass="flex justify-center items-center"
  containerClass=""
  #addblock
>
  <app-canva-modal [type]="'PDF'" (addPDF)="addblock.close(); setEvent($event)">
  </app-canva-modal>
</app-modal>
-->
