import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { OAuthService, V2Cast } from "src/app/sinigangnababoywithgabi";
import { DashboardService } from "src/app/services/dashboard.service";
import { fadeInOut } from "src/app/app-common-module/animations/fade";
import { firstValueFrom, interval, Subscription, throwError } from "rxjs";
import {
  catchError,
  filter,
  mergeMap,
  switchMap,
  map,
  take,
  takeWhile,
  tap,
} from "rxjs/operators";
import { OpenidService } from "src/app/services/openid.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  animations: [fadeInOut],
})
export class LoginComponent implements OnInit {
  cast: V2Cast;

  windowObjectReference: Window;

  constructor(
    private route: ActivatedRoute,
    private dashboardService: DashboardService,
    private openidService: OpenidService,
    private oAuthService: OAuthService,
    private router: Router
  ) {}

  async ngOnInit() {
    this.cast = await firstValueFrom(this.dashboardService.getCast());
  }

  private subscription: Subscription;
  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  async getLoginUrl(options?: { codeChallenge?: string; state?: string }) {
    return firstValueFrom(
      this.openidService.getLoginUrl({
        codeChallenge: options?.codeChallenge,
        state: options?.state,
      })
    );
  }

  async gotoLoginPage() {
    window.location.href = (await this.getLoginUrl()).href;
  }

  openLoginPage() {
    let { login_callback: loginCallback } = this.route.snapshot.queryParams;

    let { verifier, challenge } = this.openidService.generateCodePair();
    let state = this.openidService.stringGenerator(16);

    let name = "Login using BuriPass";

    this.subscription?.unsubscribe();

    this.subscription = this.openidService
      .getLoginUrl({
        codeChallenge: challenge,
        state,
      })
      .pipe(
        // Open login window
        tap((loginUrl) => {
          this.openWindow({
            name,
            url: loginUrl.href,
          });
        }),

        // Listen every 1 second
        mergeMap(() => interval(1000)),

        // While window not closed
        takeWhile(() => {
          return (
            this.windowObjectReference && !this.windowObjectReference.closed
          );
        }),

        // Proceed if url changed
        filter(() => {
          try {
            return (
              this.windowObjectReference.window.location.origin ===
              window.origin
            );
          } catch (err) {
            return false;
          }
        }),

        //Parse oauth values
        map(() => {
          let url = new URL(this.windowObjectReference.window.location.href);

          return {
            code: url.searchParams.get("code"),
            state: url.searchParams.get("state"),
          };
        }),

        // Only proceed if the state is equal to the defined state
        filter((result) => result.state === state),

        // Take only the first result
        take(1),

        //Process oauth PCKE
        switchMap((result) => {
          return this.openidService.tokenFromAuthorizationCode({
            // codeVerifier: verifier,
            code: result.code,
          });
        }),

        // Close login window then redirect
        tap((result) => {
          localStorage.setItem("access_token", result.accessToken);
          localStorage.setItem("refresh_token", result.refreshToken);

          this.windowObjectReference?.close();
        }),

        // Get and store permissions and applicaiton id
        switchMap(() => this.oAuthService.getUserPermissions()),
        tap((result) => {
          let { permissions, applicationUuid } = result;

          localStorage.setItem("permissions", JSON.stringify(permissions));
          localStorage.setItem("application_id", applicationUuid);
        }),

        tap(() => {
          if (
            loginCallback &&
            !loginCallback.includes("logout") &&
            !loginCallback.includes("/auth/callback")
          ) {
            this.router.navigateByUrl(loginCallback, {
              replaceUrl: true,
            });
          } else {
            this.router.navigate(["/"], { replaceUrl: true });
          }
        }),

        // Close login window if error encountered
        catchError((err) => {
          this.windowObjectReference?.close();

          return throwError(() => err);
        })
      )
      .subscribe();
  }

  openWindow({ name, url }) {
    const strWindowFeatures =
      "toolbar=no, menubar=no, status=no, location=no, width=300, height=600, top=100, left=100";

    let currentWindowUrl: string;
    try {
      currentWindowUrl = this.windowObjectReference?.window.location.origin;
    } catch (err) {}

    if (
      this.windowObjectReference === null ||
      this.windowObjectReference?.closed
    ) {
      /* if the pointer to the window object in memory does not exist
          or if such pointer exists but the window was closed */
      this.windowObjectReference = window.open(url, name, strWindowFeatures);
    } else if (currentWindowUrl !== url) {
      /* if the resource to load is different,
          then we load it in the already opened secondary window and then
          we bring such window back on top/in front of its parent window. */
      this.windowObjectReference = window.open(url, name, strWindowFeatures);
      this.windowObjectReference.focus();
    } else {
      /* else the window reference must exist and the window
          is not closed; therefore, we can bring it back on top of any other
          window with the focus() method. There would be no need to re-create
          the window or to reload the referenced resource. */
      this.windowObjectReference.focus();
    }
  }
}
