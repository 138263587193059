import {
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import {
  FormArray,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Subscription } from "rxjs";
import { ModalService } from "src/app/services/modal.service";
import { MailerService } from "src/app/services/mailer.service";

@Component({
  selector: "app-create-email",
  templateUrl: "./create-email.component.html",
  styles: [],
})
export class CreateEmailComponent implements OnInit {
  @ViewChild("filterResultsModal") filterResultsModal: TemplateRef<any>;
  firstFilter: string[] = [];
  _filters: Array<string[]> = [];
  filters: any[] = [];
  filtersForm: UntypedFormGroup;
  inputFilters: any[] = [];
  inputHidden: string = "";
  previewHidden: string = "hidden";
  close: boolean = false;
  form: UntypedFormGroup;
  mutateSubscription: Subscription;
  loading: boolean = false;
  filterTotal: number = 0;
  deleteIndex: number = 0;
  mailUuid: string = "";
  tempUuid: string = "";
  action: string = "create";
  showButton: string = "";
  constructor(
    private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private modalService: ModalService,
    private viewContainerRef: ViewContainerRef,
    private mailerService: MailerService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      subject: [null, Validators.required],
      date: [null, Validators.required],
      time: [null, Validators.required],
      content: [null, Validators.required],
    });
  }

  preview() {
    this.inputHidden = "hidden";
    this.previewHidden = "";
  }
  code() {
    this.inputHidden = "";
    this.previewHidden = "hidden";
  }
  closeModal(event) {
    this.close = !this.close;
  }
  ngOnDestroy(): void {
    this.mutateSubscription?.unsubscribe();
  }
  // showFilters(filters: UntypedFormGroup) {

  //   this.inputFilters = [filters];
  //   this.firstFilter = [];
  //   this._filters = [];
  //   for (const [key, value] of Object.entries(
  //     (<UntypedFormGroup>filters.get("first")).controls
  //   )) {
  //     if (value.value && typeof value.value === "object" && value.value.name) {
  //       this.firstFilter.push(value.value.name);
  //     } else if (value.value) {
  //       this.firstFilter.push(value.value);
  //     }
  //   }
  //   (<UntypedFormArray>filters.get("filters")).controls.forEach(
  //     (filter, index) => {
  //       let innerArr = [];
  //       for (const [key, value] of Object.entries(filter.value)) {
  //         if (value && typeof value === "object" && value["name"]) {
  //           innerArr.push(value["name"]);
  //         } else if (value) {
  //           innerArr.push(value);
  //         }
  //       }
  //       this._filters.push(innerArr);
  //     }
  //   );
  // }
  setFilterTotal(event) {
    this.filterTotal = event;
  }
  deleteFilter(index) {
    // console.log(this.filters);
    this.deleteIndex = index;

    if (
      this.filters[index].key === "schedule" ||
      this.filters[index].key === "course_status"
    ) {
      this.filters.splice(index + 1, 1);
    } else {
      this.filters.splice(index, 1);
    }

    // console.log(this.filters);
  }
  setFilters(filters: UntypedFormGroup) {
    this.filters = this.mailerService.parseFilter(filters);
    this.filtersForm = filters;
    this.inputFilters = [filters];
    this.firstFilter = [];
    this._filters = [];
    for (const [key, value] of Object.entries(
      (<UntypedFormGroup>filters.get("first")).controls
    )) {
      if (value.value && typeof value.value === "object" && value.value.name) {
        this.firstFilter.push(value.value.name);
      } else if (value.value) {
        this.firstFilter.push(value.value);
      }
    }
    (<UntypedFormArray>filters.get("filters")).controls.forEach(
      (filter, index) => {
        let innerArr = [];
        for (const [key, value] of Object.entries(filter.value)) {
          if (value && typeof value === "object" && value["name"]) {
            innerArr.push(value["name"]);
          } else if (value) {
            innerArr.push(value);
          }
        }
        this._filters.push(innerArr);
      }
    );

    this.cd.detectChanges();
  }
  async openModal(template, type) {
    let baseClass = ["flex", "justify-center", "items-center", "shadow-lg"];
    let smallContainerClass = ["sm:w-1/4", "w-3/5"];
    let largeContainerClass = ["sm:w-1/2", "w-3/5"];
    let filterResultsContainerClass = ["w-3/4", "h-1/2"];
    let modalClass;
    if (type === "cancelSchedule" || type === "testEmail") {
      modalClass = [...baseClass, ...smallContainerClass];
    } else if (type === "filterResults") {
      modalClass = [...baseClass, ...filterResultsContainerClass];
    } else {
      modalClass = [...baseClass, ...largeContainerClass];
    }
    let results = await this.modalService.openModal(template, {
      panelClass: modalClass,
    });
    return results;
  }
}
