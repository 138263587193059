<app-back-title-layout
  layoutTitle="Account Details"
  [loading]="!formGroup || isLoading"
>
  <form
    [formGroup]="formGroup"
    (ngSubmit)="save()"
    class="mt-5"
    *ngIf="formGroup"
  >
    <label class="my-10">
      <span class="form-label"> Email </span>

      <div
        *ngIf="!formGroup.value.isUserConfirmed"
        class="text-sm text-red-500 italic mb-1"
      >
        <app-icon icon="error" class="align-bottom"></app-icon>
        <span> email not yet confirmed </span>
      </div>

      <input formControlName="email" class="form-control" />

      <app-form-control-error-layout
        [control]="formGroup.get('email')"
      ></app-form-control-error-layout>

      <ng-container *ngIf="!formGroup.value.isUserConfirmed">
        <button
          type="button"
          class="text-primary-light text-sm"
          (click)="resendConfirmationEmail()"
        >
          Resend confirmation email
        </button>
      </ng-container>
    </label>

    <label class="my-10 flex justify-between items-center">
      <span class="form-label"> Change password on login </span>

      <app-checkbox
        class="text-3xl"
        uncheckIcon="toggle_off"
        checkIcon="toggle_on"
        uncheckClass="text-gray-500"
        checkClass="text-primary-dark"
        formControlName="isPasswordExpired"
      ></app-checkbox>
    </label>

    <button
      [disabled]="formGroup.invalid"
      type="submit"
      class="btn btn-primary w-full my-10"
    >
      Save
    </button>

    <button
      *ngIf="formGroup.value.isActive"
      type="button"
      class="w-full block py-2 text-center border border-red-500 text-red-500 rounded"
      (click)="deactivateUser()"
    >
      <app-icon icon="delete"></app-icon>
      DEACTIVATE USER
    </button>

    <button
      *ngIf="!formGroup.value.isActive"
      type="button"
      class="w-full block py-2 text-center border border-primary text-primary rounded"
      (click)="activateUser()"
    >
      ACTIVATE USER
    </button>
  </form>
</app-back-title-layout>
