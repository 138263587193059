import { Component, Inject, Input, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import {
  Subject,
  debounceTime,
  distinctUntilChanged,
  forkJoin,
  map,
  of,
  switchMap,
  takeUntil,
  tap,
} from "rxjs";
import { Header } from "src/app/app-common-module/components/list-table-header/list-table-header.component";
import { FilterPipePipe } from "src/app/app-common-module/pipes/filter-pipe.pipe";
import { ActivityLogService } from "src/app/services/activity-log.service";
import { PassService } from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-burslides-analytics-users-modal",
  templateUrl: "./burslides-analytics-users-modal.component.html",
  styles: [],
})
export class BurslidesAnalyticsUsersModalComponent implements OnInit {
  usersObservable: any;
  loading: boolean;
  totalUsers: number;
  false;
  limit: number = 20;
  headers: Header[] = [
    { name: "Email", value: "email", width: "w-96" },
    { name: "Name", value: "last_name", width: "w-96" },
    { name: "Date Added", value: "date_added", width: "w-56" },
  ];

  @Input() userUuids: string[];
  @Input() title: string;

  constructor(
    private activityLogService: ActivityLogService,
    private route: ActivatedRoute,
    private passService: PassService,
    private filterPipe: FilterPipePipe,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.userUuids = data.userUuids;
    this.title = data.title;
  }
  ngOnInit(): void {
    this.contructUsersObservable();
  }
  private unsubscribe$: Subject<void> = new Subject<void>();
  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
  contructUsersObservable() {
    this.totalUsers = this.userUuids.length;
    this.usersObservable = of(this.userUuids).pipe(
      switchMap((uuids) => {
        this.loading = true;
        return forkJoin(uuids.map((uuid) => this.passService.getUser(uuid)));
      }),
      map((users: any) => {
        return users.map((user) => {
          return {
            uuid: user.id,
            name: user.firstName + " " + user.lastName,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            createdAt: user.createdAt,
            confirmedAt: user.confirmedAt,
          };
        });
      }),
      switchMap((users) => {
        return this.route.queryParams.pipe(
          takeUntil(this.unsubscribe$),
          debounceTime(500),
          distinctUntilChanged(),
          switchMap((params) => {
            let { q, page } = params;

            page = page || 0;

            return of(
              this.filterPipe
                .transform(users, q, ["email", "firstName", "lastName"])
                .slice(page * this.limit, (page + 1) * this.limit)
            );
          })
        );
      }),

      tap((r) => {
        this.loading = false;
      })
    );
  }

  async getUsersViewListAggregateData() {}
}
