<header>
  <div
    class="w-full flex justify-center transition"
    [ngClass]="hideDiagonalStyle ? '' : 'bg-primary-600'"
  >
    <div class="w-full">
      <nav class="w-4/5 mx-4 my-4 overflow-x-hidden whitespace-nowrap">
        <ol class="flex uppercase text-xs text-gray-400 whitespace-nowrap">
          <li class="inline-flex items-center" *ngIf="breadcrumbs?.length > 3">
            <app-icon class="leading-none" icon="more_horiz"></app-icon>

            <app-icon class="mx-2 leading-none" icon="chevron_right"></app-icon>
          </li>

          <li
            class="inline-flex items-center"
            *ngFor="let breadcrumb of breadcrumbs?.slice(-3); let i = index"
          >
            <ng-container *ngIf="i !== 0">
              <app-icon
                class="mx-2 leading-none"
                icon="chevron_right"
              ></app-icon>
            </ng-container>

            <a
              *ngIf="!breadcrumb.disabled"
              class="hover:underline"
              [ngClass]="hideDiagonalStyle ? '' : 'text-white'"
              [routerLink]="breadcrumb.routerLink"
              [queryParams]="breadcrumb.queryParams"
            >
              {{ breadcrumb.name }}
            </a>

            <span *ngIf="breadcrumb.disabled"> {{ breadcrumb.name }}</span>
          </li>
        </ol>
      </nav>

      <div class="mt-2 mx-4 flex items-center" *ngIf="!hideLogoHeader">
        <div *ngFor="let card of cards">
          <div *ngIf="layoutTitle === card.title">
            <app-icon class="text-white mr-2" [icon]="card.icon"></app-icon>
          </div>
        </div>

        <div class="flex flex-1">
          <h1
            class="font-bold font text-2xl"
            [ngClass]="hideDiagonalStyle ? 'text-gray-700' : 'text-white'"
          >
            <app-icon
              *ngIf="layoutTitleIcon"
              [icon]="layoutTitleIcon"
            ></app-icon>

            {{ layoutTitle }}

            <a
              *ngIf="titleSupportLink"
              target="_blank"
              [href]="titleSupportLink"
              class="mx-1 text-primary-light"
            >
              <app-icon class="leading-tight" icon="help_outline"></app-icon>
            </a>
          </h1>
        </div>

        <ng-container *ngIf="titleIconButtons">
          <div [ngClass]="hideButton ? 'hidden' : ''">
            <ng-container *ngFor="let titleIconButton of titleIconButtons">
              <a
                (click)="doAction(titleIconButton.observable)"
                [routerLink]="titleIconButton.routerLink"
                [queryParams]="titleIconButton.queryParams"
                [queryParamsHandling]="titleIconButton.queryParamsHandling"
                class="btn btn-icon btn-primary"
              >
                <app-icon [icon]="titleIconButton.icon"></app-icon>
              </a>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <svg
    *ngIf="!hideDiagonalStyle"
    width="100%"
    height="40"
    viewBox="0 0 1140 40"
    preserveAspectRatio="none"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1140 0L1140 36.2214L0 23.2997L0 0L1140 0" fill="#DDD6FF" />
    <path d="M0 40L1140 9.08196L1140 0L0 0L0 40Z" fill="#7A3CF1" />
  </svg>
</header>

<div class="relative">
  <app-loading position="absolute" padding="py-32" [loading]="loading">
    <section>
      <ng-content select="[backTitleLayoutBeforeMainNoMargin]"></ng-content>
    </section>

    <section class="mx-4">
      <ng-content select="[backTitleLayoutBeforeMain]"></ng-content>
    </section>

    <section class="mx-4 p-4 mt-4 bg-white rounded-lg">
      <ng-content></ng-content>
    </section>
  </app-loading>
</div>
