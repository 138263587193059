<app-back-title-layout>
  <div>
    <h1 class="font-bold text-xl">IDIS</h1>
    <div class="text-gray-400 text-sm">
      Insert supporting introduction for this infosys
    </div>
  </div>
  <div class="grid grid-cols-2 gap-4 mt-4">
    <ng-container *ngFor="let action of idisActions">
      <a
        [routerLink]="action.routerLink"
        class="flex w-full border border-gray-300 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
      >
        <div
          class="border-r border-gray-300 py-4 px-4 text-4xl text-primary-600 bg-primary-100"
        >
          <app-icon [icon]="action.icon"></app-icon>
        </div>
        <div class="ml-4 truncate py-4 px-2">
          <div class="truncate text-left font-bold mb-1">{{ action.name }}</div>
          <div class="truncate text-xs text-gray-400">
            {{ action.description }}
          </div>
        </div>
      </a>
    </ng-container>
  </div>
</app-back-title-layout>
