import { Injectable } from "@angular/core";
import { from, Observable, of, throwError } from "rxjs";
import { concatMap, map, catchError, last, tap } from "rxjs/operators";

const enum STEP_STATUS {
  PENDING = "pending",
  DONE = "done",
  ERROR = "error",
}

interface StepInput {
  fn: (context?: any) => Observable<any>;
  successMessage?: string;
  errorMessage?: string;
  stepMessage?: string;
}

class Step {
  status: STEP_STATUS;

  //Should be private
  stepMessage: string;

  //Should be private
  successMessage: string;

  //Should be private
  errorMessage: string;

  // Current message of the step
  message: string;

  fn: (context?: any) => Observable<any>;

  constructor(options: StepInput) {
    this.successMessage = options.successMessage;
    this.errorMessage = options.errorMessage;
    this.stepMessage = options.stepMessage;

    this.message = options.stepMessage;
    this.status = STEP_STATUS.PENDING;

    this.fn = options.fn;
  }

  get isDone() {
    return this.status === STEP_STATUS.DONE;
  }

  get isError() {
    return this.status === STEP_STATUS.ERROR;
  }

  get isPending() {
    return this.status === STEP_STATUS.PENDING;
  }
}

export class Task {
  status: STEP_STATUS;
  context: any;
  steps: Step[];

  constructor(options: { context?: any; steps: Step[] }) {
    this.context = options.context || {};
    this.steps = options.steps;
    this.status = STEP_STATUS.PENDING;
  }

  start() {
    return from(this.steps).pipe(
      concatMap((step) => {
        step.status = STEP_STATUS.PENDING;
        this.status = STEP_STATUS.PENDING;

        step.message = step.stepMessage;
        return step.fn(this.context).pipe(
          tap((result) => {
            step.status = STEP_STATUS.DONE;
            step.message = step.successMessage;
          }),
          catchError((err) => {
            step.status = STEP_STATUS.ERROR;
            this.status = STEP_STATUS.ERROR;
            step.message = step.errorMessage || err.message;

            return throwError(() => err);
          })
        );
      }),
      last(),
      map((result) => {
        this.status = STEP_STATUS.DONE;

        return result;
      }),
      catchError((err) => {
        this.status = STEP_STATUS.ERROR;
        return of(err);
      })
    );
  }

  stop() {}

  get isDone() {
    return this.status === STEP_STATUS.DONE;
  }

  get isError() {
    return this.status === STEP_STATUS.ERROR;
  }

  get isPending() {
    return this.status === STEP_STATUS.PENDING;
  }
}

@Injectable({
  providedIn: "root",
})
export class TaskService {
  constructor() {}

  createTask(options: { context?: any; steps: StepInput[] }) {
    return new Task({
      context: options.context,
      steps: options.steps.map((step) => new Step(step)),
    });
  }
}
