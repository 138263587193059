<app-back-title-layout
  layoutTitle="Course Enrollments - Self Enroll"
  [loading]="!selfEnrollableFormControl"
>
  <!-- <ng-container *ngIf="rolePermissionFormGroup">
    <div class="my-5" [formGroup]="rolePermissionFormGroup">
      <label class="flex justify-between">
        <span class="form-label">
          {{
            rolePermissionFormGroup.value.roleDescription ||
              rolePermissionFormGroup.value.roleName
          }}
        </span>

        <app-checkbox
          class="text-primary-dark"
          formControlName="isSelfEnrollActive"
        ></app-checkbox>
      </label>

      <div class="text-gray-700 text-sm">
        Users can initiate their enrollment
      </div>
    </div>
  </ng-container> -->

  <ng-container *ngIf="selfEnrollableFormControl">
    <div class="my-5">
      <label class="flex justify-between">
        <span class="form-label"> Self Enroll </span>

        <app-checkbox
          class="text-primary-dark"
          [formControl]="selfEnrollableFormControl"
        ></app-checkbox>
      </label>

      <div class="text-gray-700 text-sm">
        Users can initiate their enrollment
      </div>
    </div>

    <a
      routerLink="./add-group"
      queryParamsHandling="merge"
      class="my-5 py-1 btn btn-primary w-full"
      >+ ADD GROUP</a
    >

    <div class="my-5">
      <app-item-list [items]="groupInfos" [itemTemplate]="itemTemplate">
      </app-item-list>
    </div>
  </ng-container>

  <button appGoBack class="my-10 btn btn-primary w-full">DONE</button>
</app-back-title-layout>

<ng-template #itemTemplate let-roleGroupInfo="item" let-index="index">
  <div class="flex items-center">
    <app-icon icon="group" class="text-gray-600 text-4xl mr-2"></app-icon>

    <a
      [routerLink]="['/groups', 'profile']"
      [queryParams]="{ group_uuid: roleGroupInfo.groupUuid }"
      class="flex-1"
    >
      <div class="font-semibold leading-none">
        {{ roleGroupInfo.groupName }}
      </div>

      <div class="text-sm text-primary">
        {{ roleGroupInfo.userCount }} user/s
      </div>
    </a>

    <button
      (click)="removeGroup(roleGroupInfo, index)"
      class="btn btn-icon bg-transparent text-error-600"
    >
      <app-icon icon="delete"></app-icon>
    </button>
  </div>
</ng-template>
