<app-back-title-layout layoutTitle="Users and Groups">
  <section backTitleLayoutBeforeMain>
    <div class="grid grid-cols-1">
      <div class="my-5 flex flex-col sm:flex-row">
        <div
          class="bg-secondary-200 h-20 mr-0 mb-2 font-semibold rounded px-4 py-4 items-left justify-left w-full sm:w-96 sm:mr-1 sm:mb-0"
        >
          <div class="text-sm text-secondary-500 truncate">
            Total Cast Users
          </div>
          <div class="text-2xl text-secondary-700">
            {{ castUserCount || "---" }}
          </div>
        </div>
        <div
          class="bg-secondary-200 h-20 ml-0 font-semibold rounded px-4 py-4 items-left justify-left w-full sm:w-64 sm:ml-1"
        >
          <div class="text-sm text-secondary-500 truncate">
            Active Users for this Month
          </div>
          <div class="text-2xl text-secondary-700">
            {{ activeUserCount || "---" }}
          </div>
        </div>
      </div>
    </div>
  </section>

  <nav class="flex w-full mb-4">
    <a
      class="border-b-4 inline-block py-2 border-gray-300 text-gray-300 px-4 font-bold uppercase text-sm whitespace-nowrap"
      routerLink="/users"
      [replaceUrl]="true"
      routerLinkActive="text-secondary-600 border-secondary-600 "
    >
      <app-icon icon="person" class="mr-2"></app-icon>

      Users
    </a>

    <a
      class="border-b-4 inline-block py-2 border-gray-300 text-gray-300 px-4 font-bold uppercase text-sm whitespace-nowrap"
      routerLink="/groups"
      [replaceUrl]="true"
      routerLinkActive="text-secondary-600 border-secondary-600 "
    >
      <app-icon icon="group" class="mr-2"></app-icon>

      Groups and permissions
    </a>

    <div class="border-b-4 border-gray-300 flex-1"></div>
  </nav>

  <div class="relative">
    <router-outlet></router-outlet>
  </div>
</app-back-title-layout>
