import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { IdisOptionsComponent } from "./idis-options/idis-options.component";
import { AuthGuard } from "../app-common-module/guards/auth.guard";
import { IdisComponent } from "./idis.component";
import { IdisUsersComponent } from "./idis-users/idis-users.component";
import { IdisAnalyticsComponent } from "./idis-analytics/idis-analytics.component";
import { IdisUsersSettingsComponent } from "./idis-users-settings/idis-users-settings.component";
import { IdisExternalTrainingDocumentsComponent } from "./idis-external-training-documents/idis-external-training-documents.component";
import { IdisTrainingNeedsComponent } from "./idis-training-needs/idis-training-needs.component";
import { IdisUserProfileComponent } from "./idis-user-profile/idis-user-profile.component";
import { IdisFormEditorComponent } from "./idis-form-editor/idis-form-editor.component";
import { IdisFormSubmissionsComponent } from "./idis-form-submissions/idis-form-submissions.component";

const routes: Routes = [
  {
    path: "idis",
    component: IdisComponent,
    canActivate: [AuthGuard],
    data: {
      layoutTitle: "Individual Development Information System (IDIS)",
      hideBackTitleDiagonalDesign: false,
    },
    children: [
      {
        path: "",
        component: IdisOptionsComponent,
        data: {
          layoutTitle: "Individual Development Information System (IDIS)",
          hideBackTitleDiagonalDesign: false,
        },
      },
      {
        path: "users",
        component: IdisUsersComponent,
        data: {
          layoutTitle: "IDIS List of Users",
          hideBackTitleDiagonalDesign: false,
        },
        children: [
          // {
          //   path: "settings",
          //   data: {
          //     layoutTitle: "Settings",
          //     hideBackTitleDiagonalDesign: false,
          //   },
          //   children: [{ path: "", component: IdisUsersSettingsComponent }],
          // },
        ],
      },
      {
        path: "users/settings",
        component: IdisUsersSettingsComponent,
        data: {
          layoutTitle: "Settings",
          hideBackTitleDiagonalDesign: false,
        },
      },

      {
        path: "users/user-profile",
        component: IdisUserProfileComponent,
        data: {
          layoutTitle: "User Profile",
          hideBackTitleDiagonalDesign: true,
        },
      },
      {
        path: "users/external-training-document",
        component: IdisExternalTrainingDocumentsComponent,
        data: {
          layoutTitle: "External Training Documents",
          hideBackTitleDiagonalDesign: true,
        },
      },
      {
        path: "users/form-submissions",
        component: IdisFormSubmissionsComponent,
        data: {
          hideBackTitleDiagonalDesign: true,
        },
      },
      {
        path: "users/settings/form-editor",
        component: IdisFormEditorComponent,
        data: {
          hideBackTitleDiagonalDesign: true,
        },
      },
      {
        path: "users/training-needs",
        component: IdisTrainingNeedsComponent,
        data: {
          layoutTitle: "Training Needs",
          hideBackTitleDiagonalDesign: true,
        },
      },
      {
        path: "analytics",
        component: IdisAnalyticsComponent,
        data: {
          layoutTitle: "IDIS Analytics",
          hideBackTitleDiagonalDesign: false,
        },
      },
      {
        path: "settings",
        component: IdisAnalyticsComponent,
        data: {
          layoutTitle: "IDIS Settings",
          hideBackTitleDiagonalDesign: false,
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class IdisRoutingModule {}
