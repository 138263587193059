import { Component, OnInit } from "@angular/core";
import { User, PassService } from "src/app/sinigangnababoywithgabi";
import { ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { firstValueFrom, Observable } from "rxjs";
import { switchMap, tap, map } from "rxjs/operators";

@Component({
  selector: "app-group-edit-members",
  templateUrl: "./group-edit-members.component.html",
  styleUrls: ["./group-edit-members.component.scss"],
})
export class GroupEditMembersComponent implements OnInit {
  userObservable: Observable<User[]>;

  selectedUserIds: string[] = [];
  selectedUserIdMap: any = {};
  loading = false;

  pageSize = 20;
  userCount: number;

  constructor(
    private route: ActivatedRoute,
    private passService: PassService,
    private location: Location
  ) {}

  async ngOnInit() {
    let { group_uuid: groupUuid } = this.route.snapshot.queryParams;

    let group = await firstValueFrom(
      this.passService.getGroup(groupUuid, ["users"])
    );
    this.selectedUserIds = group.users.map((u) => u.id);

    this.selectedUserIds.forEach((id) => {
      this.selectedUserIdMap[id] = true;
    });

    this.userObservable = this.route.queryParams.pipe(
      switchMap((params) => {
        let { page, q } = params;

        let limit = this.pageSize;
        let offset = this.pageSize * page;

        return this.passService.listUsers(q, limit, offset, null, null);
      }),
      tap((result) => {
        this.userCount = result.count;
      }),
      map((result) => result.users)
    );
  }

  async save() {
    let { group_uuid: groupUuid } = this.route.snapshot.queryParams;

    let selectedUserUuids = Object.entries(this.selectedUserIdMap)
      .filter(([key, value]) => value)
      .map(([key, value]) => key);

    await firstValueFrom(
      this.passService.updateGroup(groupUuid, {
        group: {
          userIds: selectedUserUuids,
        },
      })
    );

    this.location.back();
  }
}
