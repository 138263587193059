import { Component, forwardRef, Input } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "app-font-select",
  templateUrl: "./font-select.component.html",
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FontSelectComponent),
      multi: true,
    },
  ],
})
export class FontSelectComponent implements ControlValueAccessor {
  fonts = [
    { name: "Futura", value: "Futura Lt BT, san serif" },
    { name: "VAG Rounded", value: "VAG Rounded Regular, sans-serif" },
    { name: "Poppins", value: "Poppins, sans-serif" },
  ];

  @Input()
  value: string;

  @Input()
  name: string;

  @Input()
  disabled: boolean;

  propagateChange = (_: any) => {};

  constructor() {}

  writeValue(value: any): void {
    this.value = value;

    this.propagateChange(value);
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
