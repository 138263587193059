import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable, firstValueFrom, switchMap, tap, map } from "rxjs";
import { DashboardService } from "src/app/services/dashboard.service";
import { User, PassService } from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-user-group-edit",
  templateUrl: "./user-group-edit.component.html",
  styles: [],
})
export class UserGroupEditComponent implements OnInit {
  groupObservable: Observable<User[]>;

  selectedGroupIds: string[] = [];
  selectedGroupIdMap: any = {};
  loading = false;

  pageSize = 20;
  userCount: number;

  constructor(
    private route: ActivatedRoute,
    private passService: PassService,
    private dashboardService: DashboardService,
    private location: Location
  ) {}

  async ngOnInit() {
    let { user_uuid: userUuid } = this.route.snapshot.queryParams;

    let cast = await firstValueFrom(this.dashboardService.getCast());
    let user = await firstValueFrom(
      this.passService.getUser(userUuid, ["groups"])
    );

    this.selectedGroupIds = user.groups.map((g) => g.id);

    this.selectedGroupIds.forEach((id) => {
      this.selectedGroupIdMap[id] = true;
    });

    this.groupObservable = this.route.queryParams.pipe(
      switchMap((params) => {
        let { page, q } = params;

        let limit = this.pageSize;
        let offset = this.pageSize * page;

        return this.passService.listGroups(q, limit, offset, ["users_count"]);
      }),
      tap((result) => {
        this.userCount = result.count;
      }),
      map((result) =>
        result.groups.filter(
          (group) => group.id !== cast.data.attributes.defaultGroupId
        )
      )
    );
  }

  async save() {
    let { user_uuid: userUuid } = this.route.snapshot.queryParams;

    let selectedGroupUuids = Object.entries(this.selectedGroupIdMap)
      .filter(([key, value]) => value)
      .map(([key, value]) => key);

    let cast = await firstValueFrom(this.dashboardService.getCast());

    let newGroupIds = [
      ...new Set([cast.data.attributes.defaultGroupId, ...selectedGroupUuids]),
    ];

    await firstValueFrom(
      this.passService.updateUserGroups(userUuid, {
        groupIds: newGroupIds,
      })
    );

    this.location.back();
  }
}
