import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { TitleIconButton } from "src/app/app-common-module/layouts/back-title-layout/back-title-layout.component";
import { DashboardService } from "src/app/services/dashboard.service";
import { ModalService } from "src/app/services/modal.service";
import {
  Block,
  CastsService,
  V2Block,
  V2CastsService,
} from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-casteditor-navigation-edit",
  templateUrl: "./casteditor-navigation-edit.component.html",
  styleUrls: ["./casteditor-navigation-edit.component.scss"],
})
export class CasteditorNavigationEditComponent implements OnInit {
  navigationBlock: V2Block;

  formGroup: UntypedFormGroup;

  titleIconButtons: TitleIconButton[];

  constructor(
    private v2CastService: V2CastsService,
    private castService: CastsService,
    private formBuilder: UntypedFormBuilder,
    private modalService: ModalService,
    private dashboardService: DashboardService
  ) {}

  ngOnInit(): void {
    this.getNavigationBlock();
  }

  private unsubscribe$ = new Subject();
  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  onDrop(event: CdkDragDrop<any[]>) {
    moveItemInArray(
      this.navigationBlock.data.attributes.items,
      event.previousIndex,
      event.currentIndex
    );

    this.formGroup.patchValue({
      items: this.navigationBlock.data.attributes.items,
    });
  }

  async getNavigationBlock() {
    let cast = await this.dashboardService.getCast().toPromise();
    this.navigationBlock = await this.v2CastService
      .blockGet(cast.data.attributes.initialBlockId)
      .toPromise();

    this.formGroup = this.formBuilder.group({
      id: [this.navigationBlock.data.id],
      logo: [cast.data.attributes.castIcon?.web?.nav],
      items: [this.navigationBlock.data.attributes.items],
      classes: [this.navigationBlock.data.attributes?.properties?.classes],
    });

    this.formGroup.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        this.saveNavigation();
      });

    this.titleIconButtons = [
      {
        icon: "settings",
        routerLink: "settings",
        queryParams: {
          block_id: this.navigationBlock.data.id,
        },
      },
    ];
  }

  async selectIcon(item: any) {
    let result = await this.modalService.selectIcon();

    item.icon = result.sets;

    await this.saveNavigation();
  }

  async saveNavigation() {
    let newNavigationBlock: Block = {
      id: parseInt(this.navigationBlock.data.id),
      order: null,
      type: "navigation",
      properties: {
        ...this.navigationBlock.data.attributes.properties,
        classes: this.formGroup.value.classes,
        items: this.formGroup.value.items,
      },
    };

    await this.castService
      .blockUpdate("1", "6", newNavigationBlock.id + "", {
        block: newNavigationBlock,
      })
      .toPromise();
  }
}
