import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import {
  debounceTime,
  distinctUntilChanged,
  flatMap,
  map,
  Observable,
  switchMap,
  tap,
} from "rxjs";
import { fadeInOut } from "src/app/app-common-module/animations/fade";
import {
  Action,
  Header,
} from "src/app/app-common-module/components/list-table-header/list-table-header.component";
import { DashboardService } from "src/app/services/dashboard.service";
import { PassService, Permission, Role } from "src/app/sinigangnababoywithgabi";
interface GroupItem {
  groupUuid?: string;
  groupName?: string;
  userCount?: number;
  isDefaultGroup?: boolean;
  isDeactivatedUserGroup?: boolean;
  createdAt?: string;
  permissions?: Permission[];
  roles?: Role[];
  selected?: boolean;
}
@Component({
  selector: "app-group-modal",
  templateUrl: "./group-modal.component.html",
  styles: [],
  animations: [fadeInOut],
})
export class GroupModalComponent implements OnInit {
  @Input() currentIndex: number;
  @Output() group = new EventEmitter<any>();
  page: number;
  loading = false;
  width: number = 0;
  groups: any;
  checkAll: boolean = false;
  headers: Header[] = [
    { name: "Group Name", value: "name", width: "w-96" },
    { name: "Members", value: "", width: "w-56" },
    { name: "Roles", value: "roles", width: "w-96" },
  ];
  groupItems: Action[] = [];
  defaultHeaders: string[] = ["Group Name", "Members", "Roles"];
  mobileMenu = [{ text: "Export CSV", value: "export" }];
  selected: string[] = [];
  disableHeader: boolean = true;
  orderItems = [
    {
      value: "created_at desc",
      text: "Newest",
    },
    {
      value: "created_at",
      text: "Oldest",
    },
    {
      value: "name",
      text: "Sort A-Z",
    },
    {
      value: "name desc",
      text: "Sort Z-A",
    },
  ];
  currentOrder: string;

  totalGroupCount: number;
  limit = 20;

  groupListObservable: Observable<GroupItem[]>;

  canAddGroupsObservable: Observable<boolean>;
  constructor(
    private passService: PassService,
    private route: ActivatedRoute,
    private dashboardService: DashboardService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.constructCanAddGroupsObservable();
    this.constructGroupListObservable();
  }

  constructCanAddGroupsObservable() {
    this.canAddGroupsObservable = this.dashboardService
      .getPermissions()
      .pipe(map((permissions) => permissions.includes("create:groups")));
  }
  constructGroupListObservable() {
    this.groupListObservable = this.route.queryParams.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((params) => {
        this.loading = true;
        let { order, group, page } = params;
        this.currentOrder = order;
        this.groups = null;

        return this.passService.listGroups(
          group,
          this.limit,
          page * this.limit,
          ["users_count", "roles"],
          null,
          order
        );
      }),
      tap((result) => {
        this.groups = result.groups;
        this.totalGroupCount = result.count;
      }),
      flatMap((result) => {
        return this.dashboardService.getCast().pipe(
          map((cast) => {
            return {
              cast,
              groups: result.groups,
            };
          })
        );
      }),
      map(({ cast, groups }) => {
        return groups.map((group) => {
          let groupItem: GroupItem = {
            groupUuid: group.id,
            groupName: group.name,
            userCount: group.usersCount,
            isDefaultGroup: group.id === cast.data.attributes.defaultGroupId,
            isDeactivatedUserGroup:
              group.id === cast.data.attributes.deactivatedUsersGroupId,
            roles: group.roles.filter((role) => !role.name.includes(":")),
            // selected: false,
          };

          return groupItem;
        });
      }),
      tap((result) => {
        // this.groupActions();
        this.loading = false;
        this.groups = result;
      })
    );
  }
  checkOverflow(element: HTMLElement) {
    if (
      element.offsetHeight < element.scrollHeight ||
      element.offsetWidth < element.scrollWidth
    ) {
      return true;
    }

    return false;
  }
  outputGroup(data) {
    this.group.emit(data);
    this.router.navigate([], {
      relativeTo: this.route,
      replaceUrl: false,
      queryParamsHandling: "merge",
      queryParams: { group: null, page: null },
    });
  }

  setGroupList(groups) {
    this.groups = groups;
  }
}
