<ng-container *ngIf="resource?.uuid">
  <div class="min-h-20 relative">
    <app-loading
      [loading]="!item"
      position="absolute"
      padding="py-5"
      height="h-20"
    >
    </app-loading>

    <ng-container *ngIf="item">
      <div class="my-5">
        <app-activity-spread-view [item]="item" [hideControls]="true">
        </app-activity-spread-view>
      </div>

      <button
        class="bg-purple-500 text-white w-full rounded"
        [routerLink]="[
          '/resource-management',
          'misc',
          'activity-item',
          item.uuid
        ]"
        [queryParams]="{
          exam_uuid: resource.content.examDetails?.examUuid
        }"
        [disabled]="formGroup?.disabled"
      >
        EDIT QUESTION
      </button>
    </ng-container>
  </div>
</ng-container>
