<div class="relative bg-white">
  <div class="absolute top-0 z-10 right-0 p-1">
    <app-casteditor-edit-toggle
      [ngClass]="{
        invisible: disableEdit
      }"
      (editToggle)="onEditToggle()"
      (deleteBlock)="onDeleteBlock()"
    >
    </app-casteditor-edit-toggle>
  </div>

  <div class="markdown-box">
    <app-markdown-viewer-and-editor
      class="w-full h-full"
      [(ngModel)]="textValue"
      [isInCastEditor]="true"
      [placeholder]="placeholder"
    >
    </app-markdown-viewer-and-editor>
  </div>
</div>
