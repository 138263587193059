<label
  role="checkbox"
  #label
  (keydown.enter)="label.click()"
  (keydown.space)="label.click()"
  tabindex="0"
  class="cursor-pointer"
>
  <input
    type="checkbox"
    class="hidden"
    [ngModel]="checked"
    [name]="name"
    [id]="id"
    [value]="value"
    [disabled]="disabled"
    (ngModelChange)="valueChange($event)"
  />

  <app-icon
    class="select-none {{ checked ? checkClass : uncheckClass }}"
    [icon]="checked ? checkIcon : uncheckIcon"
  ></app-icon>
</label>
