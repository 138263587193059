import { Component, OnInit, Input } from "@angular/core";
import { Resource, ResourcesService } from "src/app/sinigangnababoywithgabi";
import { UntypedFormGroup, UntypedFormBuilder } from "@angular/forms";

@Component({
  selector: "app-resource-type-upload-buribook-with-activity",
  templateUrl: "./resource-type-upload-buribook-with-activity.component.html",
})
export class ResourceTypeUploadBuribookWithActivityComponent implements OnInit {
  @Input()
  resource: Resource;

  @Input()
  formGroup: UntypedFormGroup;

  resources: Resource[];

  constructor(
    private resourcesService: ResourcesService,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.formGroup.addControl(
      "content",
      this.formBuilder.group({
        sourceResourceUuid: [this.resource.content["sourceResourceUuid"]],
        spreads: [this.resource.content["spreads"] || []],
        examDetails: [
          {
            examUuid: this.resource.content["examDetails"]?.["examUuid"],
            examTimeslotUuid:
              this.resource.content["examDetails"]?.["examTimeslotUuid"],
            examSectionUuid:
              this.resource.content["examDetails"]?.["examSectionUuid"],
          },
        ],
      })
    );
  }
}
