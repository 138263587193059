import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { catchError, Subscription, switchMap, tap } from "rxjs";
import { fadeInOut } from "src/app/app-common-module/animations/fade";
import { MailerService } from "src/app/services/mailer.service";
import { ModalService } from "src/app/services/modal.service";

@Component({
  selector: "app-test-email-modal",
  templateUrl: "./test-email-modal.component.html",
  styles: [],
  animations: [fadeInOut],
})
export class TestEmailModalComponent implements OnInit {
  form: UntypedFormGroup;
  loading: boolean = false;
  mutateSubscription: Subscription;
  @Input() emailReq: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private mailerService: MailerService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      email: [null, Validators.required],
    });
  }
  onSubmit() {
    this.loading = true;
    let subject = this.emailReq.get("subject").value;
    let email_body = this.emailReq.get("content").value;
    let key = "email";
    let values = [this.form.get("email").value];
    let params = {
      email_body: email_body,
      subject: subject,
      filters: [{ key: key, values: values }],
    };
    this.mutateSubscription?.unsubscribe();
    this.mutateSubscription = this.mailerService
      .createEmailRequest(params)
      .pipe(
        tap(({ data, loading }) => {
          if (!data) {
            throw new Error("Nothing found.");
          }
        }),
        switchMap(({ data, loading }) => {
          this.loading = loading;
          return this.modalService.alert("Test email sent!");
        }),
        catchError((error) => {
          this.loading = false;
          return this.modalService.alert("No email(s) found.");
        })
      )
      .subscribe(() => {});
  }
  ngOnDestroy(): void {
    this.mutateSubscription?.unsubscribe();
  }
}
