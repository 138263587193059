import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { map, Observable, Subscription, switchMap, tap } from "rxjs";
import { fadeInOut } from "src/app/app-common-module/animations/fade";
import { Header } from "src/app/app-common-module/components/list-table-header/list-table-header.component";
import { MailerService } from "src/app/services/mailer.service";

@Component({
  selector: "app-email-report",
  templateUrl: "./email-report.component.html",
  styles: [],
  animations: [fadeInOut],
})
export class EmailReportComponent implements OnInit {
  loading: boolean = false;
  subject: string = "";
  open: number = 0;
  clicks: number = 0;
  notSeen: number = 0;
  bounceRate: number = 0;
  emailLogObservable: Observable<any[]>;
  limit: number = 7;
  mailUuid: string = "";
  sendDate: string = "";
  totalCount: number = 0;
  mailReqSub: Subscription;
  headers: Header[] = [
    { name: "Email", value: "email", width: "w-96" },
    { name: "Name", value: "first_name", width: "w-96" },
    { name: "Status", value: "", width: "w-56" },
  ];
  constructor(
    private mailerService: MailerService,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.mailReqSub = this.route.queryParams
      .pipe(
        switchMap((params) => {
          this.loading = true;
          this.cd.detectChanges();
          let { mail_uuid } = params;
          return this.mailerService.getEmailRequest(mail_uuid);
        })
      )
      .subscribe(({ data, loading }) => {
        this.loading = loading;
        this.subject = data.emailRequest.subject;
        this.sendDate = data.emailRequest.sendOnDate;
        let length = data.emailRequest.emailLogs.length;
        let notSeenCounter = 0;
        let openCounter = 0;
        let bounceRateCounter = 0;
        let clickCounter = 0;
        data.emailRequest.emailLogs.forEach((log) => {
          if (log.emailStatus === "delivered") {
            notSeenCounter++;
          }
          if (log.emailStatus === "open") {
            openCounter++;
          }
          if (log.emailStatus === "bounce") {
            bounceRateCounter++;
          }
          if (log.emailStatus === "click") {
            clickCounter++;
          }
          this.open = (openCounter / length) * 100;
          this.clicks = (clickCounter / length) * 100;
          this.bounceRate = (bounceRateCounter / length) * 100;
          this.notSeen = (notSeenCounter / length) * 100;
        });
      });
    this.emailLogObservable = this.route.queryParams.pipe(
      switchMap((params) => {
        this.loading = true;
        this.cd.detectChanges();
        let { mail_uuid, page, order } = params;
        this.mailUuid = mail_uuid;
        if (!page) page = 0;
        if (!order) order = null;
        let _order = order?.split(" ");
        let sortLogs = [];
        if (_order && _order.length === 1) {
          sortLogs.push(order + " asc");
        } else if (_order && _order.length > 1) {
          sortLogs.push(order);
        } else sortLogs = null;
        return this.mailerService.getLogByEmailId(
          page * this.limit,
          this.limit,
          mail_uuid,
          sortLogs
        );
      }),
      tap(({ data, loading }) => {
        this.totalCount = data.logsByEmailRequestId.count;
      }),
      map(({ data, loading }) => {
        this.loading = loading;
        return data.logsByEmailRequestId.data.map((log) => {
          let status = "";
          let style = "";
          if (log.emailStatus === "open") {
            status = "opened";
            style = "text-success-500 bg-success-50";
          }
          if (log.emailStatus === "bounce") {
            status = "bounced";
            style = "text-red-500 bg-red-50";
          }
          if (log.emailStatus === "delivered") {
            status = "not seen";
            style = "text-warning-500 bg-warning-50";
          }
          if (log.emailStatus === "click") {
            status = "clicked";
            style = "text-success-500 bg-success-50";
          }
          return {
            email: log.email,
            name: log.firstName + " " + log.lastName,
            confirmedAt: log.confirmedAt,
            uuid: log.uuid,
            emailStatus: status,
            style: style,
          };
        });
      })
    );
  }
  ngOnDestroy(): void {
    this.mailReqSub?.unsubscribe();
  }
}
