import { DatePipe, Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { firstValueFrom } from "rxjs";
import {
  ActivityLogService,
  VERBS,
} from "src/app/services/activity-log.service";
import { ModalService } from "src/app/services/modal.service";
import {
  Resource,
  Course,
  ResourcesService,
  CoursesService,
  CourseCertificateProfile,
} from "src/app/sinigangnababoywithgabi";

const datePipe = new DatePipe("en-ph");

@Component({
  selector: "app-course-profile-edit",
  templateUrl: "./course-profile-edit.component.html",
  styles: [],
})
export class CourseProfileEditComponent implements OnInit {
  formGroup: UntypedFormGroup;
  constructor(
    private route: ActivatedRoute,
    private resourcesService: ResourcesService,
    private coursesService: CoursesService,
    private formBuilder: UntypedFormBuilder,
    private location: Location,
    private activityLogService: ActivityLogService,
    private modalService: ModalService
  ) {}

  async ngOnInit() {
    let { resource_uuid: resourceUuid, course_uuid: courseUuid } =
      this.route.snapshot.queryParams;

    let resource = await firstValueFrom(
      this.resourcesService.getResourceByUuid(resourceUuid)
    );

    let course = await firstValueFrom(
      this.coursesService.courseRead(
        courseUuid || resource.content.courseDetails?.courseUuid
      )
    );

    const [courseCertificate] = await firstValueFrom(
      this.coursesService.courseCertificateprofilesList(courseUuid)
    );

    this.initFormGroup(resource, course, courseCertificate);
  }

  async initFormGroup(
    resource: Resource,
    course: Course,
    courseCertificate: CourseCertificateProfile
  ) {
    this.formGroup = this.formBuilder.group({
      courseUuid: [course.uuid],
      resourceUuid: [resource.uuid],
      resourceCollectionUuid: [resource.content?.["resourceCollectionUuid"]],

      name: [resource.name],
      coverUri: [resource.coverUri],
      description: [resource.description],

      isWithCertification: [!!courseCertificate],
    });
  }

  onUpload(event) {
    this.formGroup.get("coverUri").setValue(event.uri);
  }

  async submit() {
    await firstValueFrom(
      this.coursesService.courseUpdate(this.formGroup.value.courseUuid, {
        title: this.formGroup.value.name,
        description: this.formGroup.value.description,
        coverUri: this.formGroup.value.coverUri,
      })
    );

    await firstValueFrom(
      this.resourcesService.updateCollectionByUuid(
        this.formGroup.value.resourceCollectionUuid,
        {
          name: `${this.formGroup.value.name} Resources`,
        }
      )
    );

    await firstValueFrom(
      this.resourcesService.updateResourceByUuid(
        this.formGroup.value.resourceUuid,
        {
          name: this.formGroup.value.name,
          description: this.formGroup.value.description,
          coverUri: this.formGroup.value.coverUri,
        }
      )
    );

    this.location.back();
  }

  async deleteResource() {
    let result = await this.modalService.confirm(
      "Are you sure you want to delete this resource?"
    );

    if (result) {
      await firstValueFrom(
        this.coursesService.courseDelete(this.formGroup.value.courseUuid)
      );

      await firstValueFrom(
        this.resourcesService.deleteResourceByUuid(
          this.formGroup.value.resourceUuid
        )
      );

      this.activityLogService.didResource(VERBS.deleted, {
        uuid: this.formGroup.value.resourceUuid,
        name: this.formGroup.value.name,
      });

      history.go(-2);
    }
  }
}
