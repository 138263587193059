<app-back-title-layout
  layoutTitle="Update Course Block"
  [loading]="!block || loading"
>
  <ng-container *ngIf="block">
    <div class="select-resource flex my-10">
      <div class="flex">
        <div>
          <img
            [src]="block.coverUri || block.content.resourceDetails?.coverUri"
            class="h-12 w-12 object-contain"
            alt=""
          />
        </div>

        <div class="ml-3">
          <div class="text-sm uppercase leading-tight text-gray-500">
            {{ block.content.resourceDetails | resourceType }}
          </div>

          <span class="font-bold">
            {{ block.title }}
          </span>
        </div>
      </div>
    </div>

    <div class="form-width my-10">
      <label for="" class="form-label">Prerequisites</label>

      <div class="h-64 relative" *ngIf="!prerequisiteBlocks">
        <app-loading [loading]="true" position="absolute inset-0"></app-loading>
      </div>

      <ng-container *ngIf="prerequisiteBlocks">
        <ul>
          <ng-container *ngFor="let prerequisiteBlock of prerequisiteBlocks">
            <li class="flex py-2 border-b-2 last:border-b-0">
              <div>
                <img
                  [src]="
                    prerequisiteBlock.coverUri ||
                    prerequisiteBlock.content.resourceDetails?.type
                  "
                  class="h-12 w-12 object-contain"
                  alt=""
                />
              </div>

              <div class="ml-3">
                <div class="text-sm uppercase leading-tight text-gray-500">
                  {{ prerequisiteBlock.content.resourceDetails | resourceType }}
                </div>

                <span class="font-bold">
                  {{ prerequisiteBlock.title }}
                </span>
              </div>
            </li>
          </ng-container>
        </ul>

        <a
          class="block my-2 btn btn-primary w-full py-0 form-width"
          [routerLink]="['../block-prerequisites-select']"
          queryParamsHandling="merge"
        >
          Edit Prerequisites
        </a>
      </ng-container>
    </div>

    <form *ngIf="formGroup" [formGroup]="formGroup" (ngSubmit)="formSave()">
      <div
        class="my-10 form-width"
        *ngIf="formGroup.get('unlockOnSchedule').enabled && showUnlockSchedule"
      >
        <label class="flex justify-between items-center">
          <span class="form-label">Unlock on Schedule</span>

          <app-checkbox
            formControlName="unlockOnSchedule"
            class="text-3xl"
            uncheckIcon="toggle_off"
            checkIcon="toggle_on"
            uncheckClass="text-gray-500"
            checkClass="text-primary-700"
          ></app-checkbox>
        </label>

        <div class="text-xs">
          Set a date and time when this resource should be unlocked on the
          learner's portal.
        </div>

        <ng-container *ngIf="formGroup.get('unlockOnSchedule').value">
          <label class="block text-sm text-gray-700 mt-1">Schedule</label>
          <app-date-time formControlName="unlockOnScheduleDate"></app-date-time>
        </ng-container>
      </div>
      <div
        class="my-10 form-width"
        *ngIf="formGroup.get('resourceExpiry').enabled"
      >
        <label class="flex justify-between items-center">
          <span class="form-label">Resource Expiry</span>

          <app-checkbox
            formControlName="resourceExpiry"
            class="text-3xl"
            uncheckIcon="toggle_off"
            checkIcon="toggle_on"
            uncheckClass="text-gray-500"
            checkClass="text-primary-700"
          ></app-checkbox>
        </label>

        <div class="text-xs">
          Make this resource inaccessible to user after a set number of days
        </div>

        <ng-container *ngIf="formGroup.get('resourceExpiry').value">
          <label class="block text-sm text-gray-700 my-4"
            >Set number of days
          </label>

          <div class="text-sm">After</div>
          <input
            type="number"
            formControlName="resourceExpiryInDays"
            class="form-control mb-4"
            min="0"
          />

          <!-- <app-date-time formControlName="resourceExpiryDate"></app-date-time> -->
        </ng-container>
      </div>
      <div
        class="my-10 form-width"
        *ngIf="formGroup.get('completeExternally').enabled"
      >
        <label class="flex justify-between items-center">
          <span class="form-label">Complete externally</span>

          <app-checkbox
            formControlName="completeExternally"
            class="text-3xl"
            uncheckIcon="toggle_off"
            checkIcon="toggle_on"
            uncheckClass="text-gray-500"
            checkClass="text-primary-700"
          ></app-checkbox>
        </label>

        <div class="text-xs">
          The completion of this resource can be trigerred externally
        </div>
      </div>

      <ng-container *ngIf="showSeparatePassingBehaviorControls">
        <div
          class="my-10 form-width"
          *ngIf="formGroup.get('completeOnPassing').enabled"
        >
          <label class="flex justify-between items-center">
            <span class="form-label">Get passing grade to complete</span>

            <app-checkbox
              formControlName="completeOnPassing"
              class="text-3xl"
              uncheckIcon="toggle_off"
              checkIcon="toggle_on"
              uncheckClass="text-gray-500"
              checkClass="text-primary-700"
            ></app-checkbox>
          </label>

          <div class="text-xs">
            Learners must pass the grade requirement on this resource for it to
            be considered as complete
          </div>
        </div>

        <div
          class="my-10 form-width"
          *ngIf="formGroup.get('passOnSubmit').enabled"
        >
          <label class="flex justify-between items-center">
            <span class="form-label">Submit output to complete</span>

            <app-checkbox
              formControlName="passOnSubmit"
              class="text-3xl"
              uncheckIcon="toggle_off"
              checkIcon="toggle_on"
              uncheckClass="text-gray-500"
              checkClass="text-primary-700"
            ></app-checkbox>
          </label>

          <div class="text-xs">
            Learners will be marked as complete for this resource as long as the
            learner submits this requirement
          </div>
        </div>
      </ng-container>

      <div
        class="my-10 form-width"
        *ngIf="formGroup.get('isCertificateRequired').enabled"
      >
        <label class="flex justify-between items-center">
          <span class="form-label">Set as certification requirement</span>

          <app-checkbox
            formControlName="isCertificateRequired"
            class="text-3xl"
            uncheckIcon="toggle_off"
            checkIcon="toggle_on"
            uncheckClass="text-gray-500"
            checkClass="text-primary-700"
          ></app-checkbox>
        </label>

        <ng-container *ngIf="formGroup.value.isCertificateRequired">
          <div class="text-xs">
            This resource will become a requirement for learners to get their
            course certificate
          </div>

          <ng-container
            *ngIf="
              formGroup.get('passBehavior').enabled &&
              !showSeparatePassingBehaviorControls
            "
          >
            <label for="mt-5" class="form-label"> Passing Behavior </label>

            <label class="form-width flex my-2">
              <app-radio
                class="mx-5 text-primary-700"
                formControlName="passBehavior"
                value="pass_on_submit"
              ></app-radio>

              <div>
                <span class="">Submit output to complete</span>

                <div class="text-xs">
                  Learners will be marked as complete for this resource as long
                  as the learner submits this requirement
                </div>
              </div>
            </label>

            <label class="form-width flex my-2">
              <app-radio
                class="mx-5 text-primary-700"
                formControlName="passBehavior"
                value="pass_to_complete"
              ></app-radio>

              <div>
                <span class="">Get passing grade to complete</span>

                <div class="text-xs">
                  Learners must pass the grade requirement on this resource for
                  it to be considered as complete
                </div>
              </div>
            </label>
          </ng-container>

          <div
            class="p-3 rounded my-1 bg-primary-lightest"
            *ngIf="
              formGroup.value.completeOnPassing ||
              formGroup.value.passBehavior === 'pass_on_submit'
            "
          >
            <label *ngIf="formGroup.value.completeOnPassing">
              <div class="form-label text-sm text-primary">
                Minimum passing grade (%)
              </div>

              <input
                type="number"
                step="0.01"
                class="form-control"
                formControlName="customPassingMark"
              />

              <app-form-control-error-layout
                [control]="formGroup.get('customPassingMark')"
              ></app-form-control-error-layout>
            </label>

            <ng-container
              *ngIf="
                block.content.resourceDetails?.type === 'assessment' &&
                block.content.resourceDetails?.subtype !== 'file-upload'
              "
            >
              <label>
                <div class="form-label text-sm text-primary">Attempt Limit</div>

                <input
                  type="number"
                  class="form-control"
                  formControlName="maxAllowedSubmissions"
                />

                <app-form-control-error-layout
                  [control]="formGroup.get('maxAllowedSubmissions')"
                ></app-form-control-error-layout>
              </label>
            </ng-container>
          </div>
        </ng-container>
      </div>

      <div class="flex flex-row-reverse my-5">
        <button
          type="submit"
          class="btn btn-primary"
          [disabled]="formGroup.invalid"
        >
          SAVE
        </button>

        <button
          (click)="deleteBlock()"
          type="button"
          class="btn btn-danger mx-2"
        >
          Delete
        </button>
      </div>
    </form>

    <ng-container *ngIf="!formGroup">
      <div class="flex flex-row-reverse my-5">
        <button type="button" appGoBack class="btn btn-primary">SAVE</button>
      </div>
    </ng-container>
  </ng-container>
</app-back-title-layout>
