import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  defer,
  filter,
  firstValueFrom,
  from,
  map,
  mergeMap,
  Observable,
  of,
  switchMap,
  tap,
  toArray,
  zip,
} from "rxjs";
import {
  Action,
  Header,
} from "src/app/app-common-module/components/list-table-header/list-table-header.component";
import { ModalService } from "src/app/services/modal.service";
import { PassService, V3PayService } from "src/app/sinigangnababoywithgabi";

interface PurchaseDetail {
  productId: string;
  name: string;
  price: string;
  description: string;
  coverUri?: string;
  subscriptionGroupUuid: string;

  productReferenceId: string;
  productReferenceType: string;
}

@Component({
  selector: "app-purchase-product-detail",
  templateUrl: "./purchase-product-detail.component.html",
  styles: [],
})
export class PurchaseProductDetailComponent implements OnInit {
  loading: boolean;
  purchaseDetail: PurchaseDetail;

  pageSize = 20;
  purchaseHistoryTotalCount = 10;
  purchaseHistoryObservable: Observable<any[]>;

  selectedUserSubscriptionUuids: string[];

  headers: Header[] = [
    { name: "Email", value: "email", width: "w-56" },
    { name: "Name", value: "name", width: "w-56" },
    { name: "Status", value: "isSubscriptionActive", width: "w-32" },
    { name: "Created", value: "subscriptionDate", width: "w-40" },
    { name: "Expires at", value: "expiresDate", width: "w-40" },
  ];

  batchActions: Action[] = [
    {
      name: "Unsubscribe",
      action: defer(() =>
        of(this.selectedUserSubscriptionUuids).pipe(
          switchMap((ids) =>
            this.modalService.confirm(
              `This action will unsubscribe ${ids.length} user(s). Continue?`
            )
          ),
          filter((r) => !!r),
          switchMap(() =>
            this.v3PayService.unsubscribeUsers({
              userSubscriptions: this.selectedUserSubscriptionUuids,
            })
          )
        )
      ),
    },
  ];

  constructor(
    private route: ActivatedRoute,
    private v3PayService: V3PayService,
    private location: Location,
    private modalService: ModalService,
    private passService: PassService
  ) {}

  async ngOnInit() {
    this.initPurchaseDetail();

    this.initPurchaseHistoryObs();
  }

  async initPurchaseDetail() {
    let { product_id: productId } = this.route.snapshot.queryParams;

    let product = await firstValueFrom(
      this.v3PayService.getProduct(
        productId,
        "references,stock_records,subscription_plan,product_references"
      )
    );

    //Assume that there is only a single price
    let price = product.stockRecords[0]?.price
      ? `₱ ${product.stockRecords[0].price}`
      : null;

    let subscriptionGroupUuid = product.subscriptionPlan?.group;

    let productGroupReference = product.references?.find(
      (r) => r.referenceType === "group"
    );

    const productReferenceId = productGroupReference?.referenceId;
    const productReferenceType = productGroupReference?.referenceType;

    //Calculate if there is a price range
    // let [minPrice, maxPrice] = product.stockRecords.reduce(
    //   (items, stockRecord) => {
    //     items[0] = Math.min(stockRecord.price, items[0]);
    //     items[1] = Math.max(stockRecord.price, items[1]);

    //     return items;
    //   },
    //   [0, 0]
    // );

    // let price;
    // if (minPrice === maxPrice) {
    //   price = ` ₱ ${minPrice}`;
    // }

    let description;
    if (subscriptionGroupUuid) {
      description = `Subscription`;
    } else if (!subscriptionGroupUuid) {
      description = `One-Time Payment`;
    }

    this.purchaseDetail = {
      productId: product.id,
      name: product.title,
      price,
      description,
      subscriptionGroupUuid,
      productReferenceType,
      productReferenceId,
    };
  }

  async initPurchaseHistoryObs() {
    let { product_id: productId } = this.route.snapshot.queryParams;

    this.purchaseHistoryObservable = this.v3PayService
      .listUsersubscriptions(productId)
      .pipe(
        tap((result) => {
          this.purchaseHistoryTotalCount = result.length;
        }),
        switchMap((result) => from(result)),
        mergeMap((item) => {
          return this.passService.getUser(item.user).pipe(
            map((user) => {
              return { user, userSubscription: item };
            })
          );
        }, 5),

        toArray(),
        map((result) =>
          result.map(({ user, userSubscription }) => {
            return {
              userSubscriptionUuid: userSubscription.uuid,
              name: `${user.firstName} ${user.lastName}`,
              email: user.email,
              confirmedAt: user.confirmedAt,
              isSubscriptionActive: userSubscription.isActive,
              subscriptionDate: userSubscription.created,
              expiresDate: userSubscription.expires,
            };
          })
        )
      );
  }

  onSelectedItemList(selected: string[]) {
    this.selectedUserSubscriptionUuids = selected;
  }

  async deleteProduct() {
    let { product_id: productId } = this.route.snapshot.queryParams;

    let result = await this.modalService.confirm(
      "Are you sure you want to delete this purchase?"
    );

    this.loading = true;

    if (result) {
      await firstValueFrom(this.v3PayService.deleteProduct(productId));

      this.location.back();
    }
  }
}
