import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-reject-field-entry-modal",
  templateUrl: "./reject-field-entry-modal.component.html",
  styles: [],
})
export class RejectFieldEntryModalComponent implements OnInit {
  rejectedMessage: string = "N/A";
  constructor() {}
  ngOnInit(): void {}
}
