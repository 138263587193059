import { Component, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import {
  map,
  merge,
  Observable,
  of,
  Subject,
  switchMap,
  takeUntil,
} from "rxjs";
import { Group, PassService } from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-group-select-dialog",
  templateUrl: "./group-select-dialog.component.html",
  styles: [],
})
export class GroupSelectDialogComponent implements OnInit {
  groupObs: Observable<Group[]>;

  searchFormControl: UntypedFormControl = new UntypedFormControl();

  constructor(private passService: PassService) {}

  private unsubscribe$: Subject<void> = new Subject<void>();
  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.groupObs = merge(of(null), this.searchFormControl.valueChanges).pipe(
      takeUntil(this.unsubscribe$),

      switchMap((value) => {
        return merge(this.passService.listGroups(value));
      }),
      map((result) => result?.groups)
    );
  }
}
