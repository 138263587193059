import { Component, OnInit, Input } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { firstValueFrom } from "rxjs";
import {
  Resource,
  CoursesService,
  CourseSection,
  CourseBlock,
  ResourcesService,
} from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-resource-type-upload-course",
  templateUrl: "./resource-type-upload-course.component.html",
})
export class ResourceTypeUploadCourseComponent implements OnInit {
  @Input()
  resource: Resource;

  @Input()
  formGroup: UntypedFormGroup;

  loading: boolean = false;

  courseUuid: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private coursesService: CoursesService,
    private resourcesService: ResourcesService
  ) {}

  async ngOnInit() {
    this.courseUuid = this.resource.content["courseDetails"]?.["courseUuid"];
    let resourceCollectionUuid =
      this.resource.content["resourceCollectionUuid"];

    //Create collection if no resource collection uuid
    if (this.resource.uuid && !resourceCollectionUuid) {
      try {
        this.loading = true;

        let collection = await firstValueFrom(
          this.resourcesService.createCollection({
            name: `${this.resource.name}`,
          })
        );

        this.resource.content["resourceCollectionUuid"] = collection.uuid;
        resourceCollectionUuid = collection.uuid;

        await firstValueFrom(
          this.resourcesService.updateResourceByUuid(
            this.resource.uuid,
            this.resource
          )
        );
      } catch (err) {
        throw err;
      } finally {
        this.loading = false;
      }
    }

    this.formGroup.addControl(
      "content",
      this.formBuilder.group({
        courseDetails: this.formBuilder.group({
          courseUuid: [this.courseUuid],
        }),
        resourceCollectionUuid: [resourceCollectionUuid],
      })
    );
    // this.cloneCourse();
    // this.mistakeCleaner();
  }

  async mistakeCleaner() {
    let sections: CourseSection[] = await firstValueFrom(
      this.coursesService.courseSectionList(this.courseUuid)
    );

    let tbd = sections.filter((section) =>
      section.created.includes("2020-06-18")
    );
    // //   .slice(0, 300);

    await Promise.all(
      tbd.map((t) => this.coursesService.sectionDelete(t.uuid).toPromise())
    );
  }

  async cloneCourse() {
    let course = await firstValueFrom(
      this.coursesService.courseRead(this.courseUuid)
    );

    let newcourse = await firstValueFrom(
      this.coursesService.courseCreate({
        ...course,
        description: "",
        coverUri: "",
      })
    );

    let sections: CourseSection[] = await firstValueFrom(
      this.coursesService.courseSectionList(this.courseUuid)
    );

    for (let i = 0; i < sections.length; i++) {
      let section = sections[i];

      let newsection = await firstValueFrom(
        this.coursesService.courseSectionCreate(newcourse.uuid, {
          ...section,
          uuid: undefined,
          course: newcourse.uuid,
          parent: undefined,
          coverUri: section.coverUri || "",
          prerequisites: [],
        })
      );

      let blocks = await firstValueFrom(
        this.coursesService.sectionBlockList(section.uuid)
      );

      let newBlocks: CourseBlock[] = await Promise.all(
        blocks.map((block) =>
          firstValueFrom(
            this.coursesService.sectionBlockCreate(newsection.uuid, {
              ...block,
              uuid: undefined,
              section: newsection.uuid,
              prerequisites: [],
            })
          )
        )
      );
    }

    await firstValueFrom(
      this.resourcesService.createResource({
        // ...this.resource,
        name: this.resource.name + "(copy)",
        type: this.resource.type,
        coverUri: this.resource.coverUri,
        description: this.resource.description,
        tags: this.resource.tags,
        packageUuids: this.resource.packages.map((p) => p.uuid),
        content: {
          courseDetails: {
            courseUuid: newcourse.uuid,
          },
        },
      })
    );
  }
}
