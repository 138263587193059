import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import {
  CAMELIZE,
  SUBTYPES,
  TYPES,
} from "src/app/app-common-module/pipes/resource-type.pipe";
import { DashboardService } from "src/app/services/dashboard.service";
import { ModalService } from "src/app/services/modal.service";
import { V2Cast } from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-general-settings-customize",
  templateUrl: "./general-settings-customize.component.html",
  styles: [],
})
export class GeneralSettingsCustomizeComponent implements OnInit {
  constructor(
    private dashboardService: DashboardService,
    private formBuilder: UntypedFormBuilder,
    private _location: Location,
    private modalService: ModalService
  ) {}
  resourceForm: UntypedFormGroup;
  resources = [];
  cast: V2Cast;
  typesDef: Map<string, string> = new Map(TYPES);
  subtypesDef: Map<string, string> = new Map(SUBTYPES);
  async ngOnInit() {
    let response = await this.dashboardService.getCast().toPromise();
    this.cast = response;
    let humanize: any = response.data.attributes.humanize?.buresource?.resource;
    let types = new Map();
    let subtypes = new Map();
    let form = {};

    if (humanize?.type && Array.isArray(humanize.type)) {
      let humanizeTypeMap: Map<string, string> = new Map(humanize.type);
      let humanizeSubtypeMap: Map<string, string> = new Map(humanize.subtype);
      for (const [key, value] of humanizeTypeMap) {
        types.set(key, value);
      }
      for (const [key, value] of humanizeSubtypeMap) {
        subtypes.set(key, value);
      }
    }
    //fallback code for reading old humanize object
    //first condition for deletion
    this.typesDef.forEach((value, key) => {
      form[key] = [humanize?.type?.[key] || types.get(key) || value];
      this.push(this.resources, { name: key, label: value });
    });
    this.subtypesDef.forEach((value, key) => {
      form[key] = [humanize?.subtype?.[key] || subtypes.get(key) || value];
      this.push(this.resources, { name: key, label: value });
    });
    this.resourceForm = this.formBuilder.group(form);
  }

  //Pushes to array if object is not duplicate
  push(arr, item) {
    if (!arr.find(({ name }) => name === item.name)) {
      arr.push(item);
    }
  }

  save() {
    this.set();
    this.dashboardService.saveCast(this.cast).subscribe(() => {
      this._location.back();
    });
  }

  async reset() {
    if (await this.modalService.confirm("Are you sure you want to reset?")) {
      for (const [key, value] of this.typesDef) {
        this.resourceForm.get(key).setValue(value);
      }
      for (const [key, value] of this.subtypesDef) {
        this.resourceForm.get(key).setValue(value);
      }
    }
  }
  set() {
    let type = [];
    let subtype = [];
    TYPES.forEach((_type) => {
      type.push([_type[0], this.resourceForm.get(_type[0]).value]);
    });

    SUBTYPES.forEach((_subtype) => {
      subtype.push([_subtype[0], this.resourceForm.get(_subtype[0]).value]);
    });

    subtype.push(["flarum", "Forum"]);

    this.cast.data.attributes.humanize = {
      buresource: { resource: { type, subtype } },
    };
  }
}
