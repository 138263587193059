import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Apollo, gql } from "apollo-angular";
import {
  CourseSchedule,
  CourseScheduleGradeReport,
  CoursesService,
  CourseUnenrollBatch,
  CourseUserCertificatesDownload,
  CourseUserEnrollment,
  PassService,
  User,
  V1ExamsService,
} from "src/app/sinigangnababoywithgabi";

import {
  defer,
  firstValueFrom,
  from,
  interval,
  Observable,
  of,
  Subject,
  zip,
} from "rxjs";
import {
  catchError,
  concatMap,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  take,
  takeUntil,
  tap,
  toArray,
} from "rxjs/operators";
import { Field } from "src/app/app-common-module/components/filter/filter.component";
import { ModalService } from "src/app/services/modal.service";
import {
  Action,
  Header,
} from "src/app/app-common-module/components/list-table-header/list-table-header.component";
import { DownloaderService } from "src/app/services/downloader.service";
import { constructScheduleDatePillAndDateText } from "../course-profile/course-profile.component";

export interface CompletionStats {
  students: number | string;
  inProgress: number | string;
  notStarted: number | string;
  completed: number | string;
  certificatesIssued: number | string;
  needsAttention: number | string;
}

interface StudentPerformanceItem {
  userUuid: string;
  name: string;
  statuses: string[];
  requirementsDone?: string;
}

interface StudentAnswersItem {
  name: string;
  type: string;
  coverUri: string;
  examUuid: string;
  exportCsvObservable?: Observable<any>;
}

@Component({
  selector: "app-course-batch-stats",
  templateUrl: "./course-batch-stats.component.html",
})
export class CourseBatchStatsComponent implements OnInit {
  schedule: CourseSchedule;
  loading: boolean;
  latestGradeReport: CourseScheduleGradeReport;
  latestCertificateZipFiles: CourseUserCertificatesDownload[];
  filterState: any;
  overview: CompletionStats = {
    students: "---",
    inProgress: "---",
    notStarted: "---",
    completed: "---",
    certificatesIssued: "---",
    needsAttention: "---",
  };

  pageMode: "analytics" | "answers" | "certificates" = "analytics";

  overviewObservable: Observable<any>;
  studentPerformanceTableObservable: Observable<StudentPerformanceItem[]>;
  totalCount: number = 0;
  pageSize: number = 20;
  fields: Field[] = [
    {
      name: "Status",
      value: {
        input: "select",
        value: "status",
        index: 0,
        options: [
          { name: "Not Started", value: "not_started" },
          { name: "In Progress", value: "in_progress" },
          { name: "Needs Attention", value: "needs_attention" },
          { name: "Passed", value: "passed" },
          { name: "Failed", value: "failed" },
        ],
      },
    },
    {
      name: "Passed course within",
      value: {
        input: "number",
        value: "passed_in",
        index: 1,
        name: "No. of days",
      },
    },
  ];

  enrollmentDateStatusText: string;
  enrollmentDatePillType: string;
  enrollmentDateText: string;

  accessDateStatusText: string;
  accessDatePillType: string;
  accessDateText: string;

  constructor(
    private route: ActivatedRoute,
    private coursesService: CoursesService,
    private passService: PassService,
    private v1ExamsService: V1ExamsService,
    private apollo: Apollo,
    private modalService: ModalService,
    private downloaderService: DownloaderService
  ) {}

  ngOnInit() {
    this.fetchData();

    this.initOverview();

    this.initStudentTable();

    this.initStudentAnswers();

    this.getLatestReport();
    this.getLatestCertificateZipFile();
  }

  private unsubscribe$: Subject<void> = new Subject<void>();
  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  async initOverview() {
    let { schedule_uuid: scheduleUuid } = this.route.snapshot.queryParams;

    // https://buri.dev/T1469
    this.overviewObservable = this.coursesService
      .courseStatisticsScheduleCounts(
        scheduleUuid,
        "total_user_certificates_count"
      )
      .pipe(
        map((result) => {
          this.overview = {
            students: result.totalUsersCount,
            inProgress: result.totalInProgressCount,
            notStarted: result.totalNotStartedCount,
            certificatesIssued: result.totalUserCertificatesCount,
            completed: result.totalCompletedCount,
            needsAttention: result.totalNeedsAttentionCount,
          };
        })
      );
  }

  headers: Header[] = [
    { name: "Email", value: "email", width: "w-96" },
    { name: "Name", value: "name", width: "w-56" },
    { name: "Course Progress", value: "statuses", width: "w-56" },
    { name: "Date Enrolled", value: "enrollmentDate", width: "w-56" },
  ];
  selectedUserUuids: string[] = [];

  batchActions: Action[];
  onSelectedItemList(selected: string[]) {
    this.selectedUserUuids = selected;
  }

  async initStudentTable() {
    this.batchActions = [
      {
        name: "Remove",
        action: defer(() =>
          of(this.selectedUserUuids).pipe(
            switchMap((ids) =>
              this.modalService.confirm(
                `This action will remove ${ids.length} user(s) from this batch. Continue?`
              )
            ),
            filter((r) => !!r),
            switchMap(() =>
              this.coursesService.scheduleEnrollmentsList(this.schedule.uuid)
            ),
            map((enrollments) =>
              enrollments.filter((s) => this.selectedUserUuids.includes(s.user))
            ),
            switchMap((enrollments) => {
              let unenroll = enrollments.map((enrollment) => {
                return {
                  uuid: enrollment.uuid,
                  isActive: false,
                };
              });
              return this.coursesService.courseUnenrollBatch({
                unenrolls: unenroll,
              });
            })
          )
        ),
      },
      {
        name: "Set as Passed",
        action: defer(() =>
          of(this.selectedUserUuids).pipe(
            switchMap((ids) =>
              this.modalService.confirm(
                `Setting the following user(s) as passed will automatically mark their course progress to complete/certificate issued.`
              )
            ),
            filter((r) => !!r),
            switchMap(() => this.route.queryParams),
            switchMap((queryParams) => {
              return this.coursesService.courseUpdatestatus(
                queryParams["course_uuid"],
                {
                  batchUpdate: this.selectedUserUuids.map((uuid) => ({
                    user: uuid,
                    certStatus: "passed",
                    courseStatus: "passed",
                    isComplete: true,
                  })),
                }
              );
            })
          )
        ),
      },
    ];

    this.studentPerformanceTableObservable = this.route.queryParams.pipe(
      debounceTime(500),
      // Only listen on specific key changes. May be there is a more elegant way of doing this.
      distinctUntilChanged(
        (old, current) =>
          !["course_uuid", "schedule_uuid", "status", "page", "q", "passed_in"]
            .map((key) => old[key] === current[key]) // converts to array of boolean
            .includes(false)
      ),
      switchMap((params) => {
        this.loading = true;
        const {
          course_uuid: courseUuid,
          schedule_uuid: scheduleUuid,
          status,
          page,
          q,
          passed_in: passedIn,
        } = params;
        let limit = this.pageSize;
        let offset = page * limit || 0;
        return this.coursesService
          .courseStatisticsCourseStatusesDetailCount(
            courseUuid,
            null,
            scheduleUuid,
            "block_statuses,block_details,enrollments,is_certificate_issued",
            offset,
            limit,
            status,
            q,
            passedIn
          )
          .pipe(
            tap((result) => (this.totalCount = result.count)),
            map((result) => {
              return {
                params,
                courseStatuses: result.courseStatuses,
              };
            })
          );
      }),

      // Must return a user x courseStatus pair
      switchMap(({ params, courseStatuses }) => {
        const { schedule_uuid: scheduleUuid } = params;

        // if (q) {
        //   return this.passService.listUsers(q).pipe(
        //     map(({ users }) => {
        //       let courseStatusMap = courseStatuses.reduce((map, item) => {
        //         return (map[item.user] = item), map;
        //       }, {});
        //       return users.map((user) => {
        //         return {
        //           user,
        //           courseStatus: courseStatusMap[user.id],
        //           scheduleUuid,
        //         };
        //         // return { user: null, courseStatus: null };
        //       });
        //     })
        //   );
        // } else {
        return from(courseStatuses).pipe(
          concatMap((courseStatus) => {
            return this.passService.getUser(courseStatus.user).pipe(
              map((user) => {
                return {
                  user,
                  courseStatus,
                  scheduleUuid,
                };
              }),
              catchError((err) => {
                let user: User = null;

                return of({
                  user,
                  courseStatus,
                  scheduleUuid,
                });
              })
            );
          }),
          filter((r) => !!r.user),
          toArray()
        );
        // }
      }),

      // Map user x courseStatus pair to the correct display
      map((result) => {
        return (
          result
            // Make sure courseStatusExists
            .filter((result) => result.courseStatus)
            .map((item) => {
              let {
                user,
                courseStatus,
                scheduleUuid,
              }: { user: User; courseStatus: any; scheduleUuid: any } = item;

              let statuses: string[] = [];
              // let requirementsCount: number = 0;
              // let totalRequirements: number = 0;
              let enrollmentDate = "";

              // Count finished requirements
              // courseStatus?.sectionStatuses.forEach((ss) => {
              //   ss.blockStatuses.forEach((bs) => {
              //     if (bs.blockDetails.requiredForCertificate) {
              //       totalRequirements++;

              //       if (bs.status == "passed") {
              //         requirementsCount++;
              //       }
              //     }
              //   });
              // });

              // Get enrollment date
              enrollmentDate = courseStatus?.enrollments.filter(
                (e) => e.schedule == scheduleUuid
              )[0]?.created;

              statuses.push(courseStatus?.status || "not_started");

              if (courseStatus?.isCertificateIssued) {
                statuses.push("certificate_issued");
              }

              return {
                userUuid: user.id,
                name: `${user.firstName} ${user.lastName}`,
                confirmedAt: user.confirmedAt,
                email: user.email,
                lastModified: courseStatus?.updatedAt,
                statuses,
                // requirementsDone: requirementsCount
                //   ? `${requirementsCount} out of ${totalRequirements} requirements passed`
                //   : "No data available",
                enrollmentDate,
              };
            })
        );
      }),

      // For ordering
      switchMap((result) => {
        this.loading = false;
        return this.route.queryParams.pipe(
          // Listen only on order change
          distinctUntilChanged(
            (old, current) => old["order"] === current["order"]
          ),
          map((params) => {
            let { order } = params;
            let modifiedResult = result;

            if (order) {
              const [field, direction] = order.split(" ");

              modifiedResult = modifiedResult.sort((a, b) =>
                direction === "desc"
                  ? -a[field].localeCompare(b[field])
                  : a[field].localeCompare(b[field])
              );
            }

            return modifiedResult;
          })
        );
      })
    );
  }

  studentAnswersObservable: Observable<StudentAnswersItem[]>;
  async initStudentAnswers() {
    let { course_uuid: courseUuid } = this.route.snapshot.queryParams;

    this.studentAnswersObservable = this.apollo
      .query({
        query: gql`
          query AdminStuff($course_uuid: ID!) {
            admin {
              course(uuid: $course_uuid) {
                title
                sections {
                  title
                  blocks {
                    uuid
                    title
                    cover_uri
                    required_for_certificate
                    resource {
                      type
                      content
                    }
                  }
                }
              }
            }
          }
        `,
        variables: {
          courseUuid,
        },
      })
      .pipe(
        map((result: any) => result.data.admin),
        map(({ course }) => {
          let examBlocks = [];

          course.sections?.forEach((section) => {
            section.blocks?.forEach((block) => {
              if (block.requiredForCertificate) {
                examBlocks.push(block);
              }
            });
          });

          return examBlocks.map((block) => {
            return {
              name: block.title,
              type: block.resource.type,
              coverUri: block.coverUri,
              examUuid: block.resource.content?.examDetails?.examUuid,
              examTimeslotUuid:
                block.resource.content?.examDetails?.examTimeslotUuid,

              exportCsvObservable: this.v1ExamsService
                .examTimeslotResponsesreportsCreate(
                  block.resource.content?.examDetails?.examTimeslotUuid
                )
                .pipe(
                  switchMap((result) =>
                    interval(3000).pipe(
                      switchMap(() => {
                        return this.v1ExamsService.examTimeslotResponsesreportsList(
                          block.resource.content?.examDetails?.examTimeslotUuid
                        );
                      }),
                      map((list) => list.find((i) => i.uuid === result.uuid)),
                      filter((result) => result.status === "READY"),
                      take(1)
                    )
                  ),
                  map((result) => {
                    return result;
                  }),
                  switchMap((result) =>
                    this.downloaderService.downloadFile(
                      result.objectUrl,
                      result.objectUrl.split("/").pop()
                    )
                  ),
                  // To enable button
                  map(() => true)
                ),
            };
          });
        })
      );
  }

  async fetchData() {
    let { schedule_uuid: scheduleUuid } = this.route.snapshot.queryParams;

    this.schedule = await firstValueFrom(
      this.coursesService.scheduleRead(scheduleUuid)
    );

    let enrollStartDate = this.schedule.enrollStart
      ? new Date(this.schedule.enrollStart)
      : null;
    let enrollEndDate = this.schedule.enrollEnd
      ? new Date(this.schedule.enrollEnd)
      : null;
    let accessStartDate = this.schedule.start
      ? new Date(this.schedule.start)
      : null;
    let accessEndDate = this.schedule.end ? new Date(this.schedule.end) : null;

    let {
      statusText: enrollmentDateStatusText,
      pillType: enrollmentDatePillType,
      dateText: enrollmentDateText,
    } = constructScheduleDatePillAndDateText(enrollStartDate, enrollEndDate);
    this.enrollmentDateStatusText = enrollmentDateStatusText;
    this.enrollmentDatePillType = enrollmentDatePillType;
    this.enrollmentDateText = enrollmentDateText;

    let {
      statusText: accessDateStatusText,
      pillType: accessDatePillType,
      dateText: accessDateText,
    } = constructScheduleDatePillAndDateText(accessStartDate, accessEndDate);
    this.accessDateStatusText = accessDateStatusText;
    this.accessDatePillType = accessDatePillType;
    this.accessDateText = accessDateText;
  }

  getLatestReport() {
    let { schedule_uuid: scheduleUuid } = this.route.snapshot.queryParams;

    interval(3000)
      .pipe(
        switchMap(() =>
          //OPTIMIZATION: Fectch single object instead of featching the whole list
          this.coursesService.scheduleGradereportList(scheduleUuid)
        ),
        map((result) => {
          return result[0];
        }),
        tap((result) => {
          // Undefined means data is still being fetched
          // Null means no data talaga
          if (result && !result.expiredUrl) {
            this.latestGradeReport = result;
          } else {
            this.latestGradeReport = null;
          }
        }),
        filter((result) => result?.status !== "PENDING"),
        takeUntil(this.unsubscribe$),
        take(1)
      )
      .subscribe();
  }

  createReport() {
    let { schedule_uuid: scheduleUuid } = this.route.snapshot.queryParams;

    this.latestGradeReport = {};
    this.coursesService
      .scheduleGradereportCreate(scheduleUuid)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        this.getLatestReport();
      });
  }

  getLatestCertificateZipFile() {
    let { schedule_uuid: scheduleUuid } = this.route.snapshot.queryParams;
    interval(3000)
      .pipe(
        switchMap(() =>
          //OPTIMIZATION: Fectch single object instead of featching the whole list
          this.coursesService.scheduleUsercertificatesDownloadList(scheduleUuid)
        ),
        map((result) => {
          return result.downloadRequests?.[0]?.bulkCertificateDownloads || null;
        }),
        tap((result) => {
          // Undefined means data is still being fetched
          // Null means no data talaga
          this.latestCertificateZipFiles = result;
        }),
        filter((result) => {
          if (result) {
            return result.reduce(
              (acc, currentValue) => acc && currentValue?.status !== "PENDING",
              true
            );
          }
          return false;
        }),
        takeUntil(this.unsubscribe$),
        take(1)
      )
      .subscribe();
  }

  createCertificateZipFile() {
    let { course_uuid: courseUuid, schedule_uuid: scheduleUuid } =
      this.route.snapshot.queryParams;

    this.latestGradeReport = {};
    this.coursesService
      .scheduleUsercertificatesDownloadCreate(scheduleUuid, {
        scheduleIds: [scheduleUuid],
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        this.getLatestCertificateZipFile();
      });
  }

  async openNewCourseModal(template) {
    let result = await this.modalService.openModal(template, {
      panelClass: ["w-4/5", "sm:w-2/3", "lg:w-1/2"],
    });
  }
  saveState(state) {
    this.filterState = state;
  }

  setPageMode(value: "analytics" | "answers" | "certificates") {
    this.pageMode = value;
  }

  currentDate = new Date();
}
