<!-- <div>
  {{ resource | json }}
</div> -->

<app-back-title-layout [loading]="!resource">
  <div class="row" *ngIf="resource">
    <ng-container *ngFor="let spread of spreads; let i = index">
      <div class="flex m-5">
        <ng-container *ngIf="spread.spreadType === 'image-spread'">
          <div class="card m-3 mx-auto rounded-t">
            <div class="p-0 h-full border-2 border-b-0">
              <div class="flex justify-center h-full">
                <img class="w-1/2 object-contain" src="{{ spread.leftUri }}" />
                <img class="w-1/2 object-contain" src="{{ spread.rightUri }}" />
              </div>
            </div>

            <div class="border-2 rounded-b flex">
              <div class="w-1/2 p-0 text-center">
                {{ spread.leftPage }}
              </div>
              <div class="w-1/2 p-0 text-center">
                {{ spread.rightPage }}
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</app-back-title-layout>
