<app-item-list
  [items]="subscriptionFormGroups"
  [itemTemplate]="itemTemplate"
></app-item-list>

<button
  class="btn btn-primary-light btn-thin form-width"
  (click)="addSubscription()"
>
  <app-icon icon="add"></app-icon>

  Add new subscription
</button>

<ng-template #itemTemplate let-formGroup="item">
  <div [formGroup]="formGroup">
    <div class="my-5">
      <label>
        <div class="form-label leading-tight">Product Title</div>
        <input
          type="text"
          class="form-control"
          formControlName="productTitle"
        />
      </label>
    </div>

    <div class="flex items-end my-5">
      <label>
        <div class="form-label leading-tight">Price</div>
        <input type="text" class="form-control" formControlName="price" />
      </label>

      <div class="p-2 font-bold">/</div>

      <label class="w-1/3">
        <div class="form-label leading-tight">Duration</div>

        <select class="form-control w-full" formControlName="duration">
          <option [ngValue]="null"></option>
          <option *ngFor="let duration of durations" [ngValue]="duration">
            {{ humanizer(duration) }}
          </option>
        </select>
        <!-- <input type="text" class="form-control" /> -->
      </label>
    </div>

    <label class="block my-5">
      <div class="form-label leading-tight">
        Supporting Subscription Details
      </div>

      <textarea
        class="form-control"
        rows="4"
        formControlName="description"
      ></textarea>
    </label>

    <label class="block my-5">
      <div class="form-label leading-tight">
        (Optional) Helix Pay Reference Id
      </div>

      <input
        type="text"
        formControlName="helixPayReferenceReferenceId"
        class="form-control"
      />
    </label>

    <label class="flex my-5">
      <app-checkbox formControlName="isFreeTrialEnabled"></app-checkbox>

      <div class="ml-2">
        <div class="form-label leading-tight">Include Trial Period</div>

        <div class="text-sm">
          Offer free access to this resource bundle before starting billing
        </div>
      </div>
    </label>

    <label class="my-5" *ngIf="formGroup.value.isFreeTrialEnabled">
      <div class="form-label">Duration</div>
      <div class="w-1/3">
        <select class="form-control" formControlName="freeTrialDuration">
          <option [ngValue]="null"></option>
          <option *ngFor="let duration of durations" [ngValue]="duration">
            {{ humanizer(duration) }}
          </option>
        </select>
      </div>
    </label>
  </div>
</ng-template>
