import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  EMPTY,
  Observable,
  Subscription,
  catchError,
  defer,
  filter,
  finalize,
  forkJoin,
  from,
  map,
  mergeMap,
  of,
  switchMap,
  tap,
} from "rxjs";
import { Action } from "src/app/app-common-module/components/list-table-header/list-table-header.component";
import { ModalService } from "src/app/services/modal.service";
import {
  Block,
  CastsService,
  ResourcesService,
  V2Block,
  V2CastsService,
} from "src/app/sinigangnababoywithgabi";
import { ScreenInfo } from "../../casteditor-screens-select/casteditor-screens-select.component";
import { DashboardService } from "src/app/services/dashboard.service";

@Component({
  selector: "app-casteditor-screen-actions",
  templateUrl: "./casteditor-screen-actions.component.html",
  styles: [],
})
export class CasteditorScreenActionsComponent implements OnInit {
  screenActions: Action[];
  action: Subscription;
  success: boolean;
  @Input() castId: string;
  @Input() screenId: string;
  @Input() navigationBlock: V2Block;
  @Input() screenInfos: ScreenInfo[];
  @Output() reload = new EventEmitter<Boolean>();
  @Output() loading = new EventEmitter<Boolean>();
  constructor(
    private modalService: ModalService,
    private castService: CastsService,
    private resourceService: ResourcesService,
    private dashboardService: DashboardService,
    private v2CastService: V2CastsService
  ) {}

  ngOnInit(): void {
    this.constructScreenActions();
  }
  ngOnDestroy(): void {
    this.action?.unsubscribe();
  }
  constructScreenActions() {
    this.screenActions = [
      {
        name: "Hide Screen",
        action: defer(() =>
          from(
            this.modalService.confirm(
              "This will hide the screen from the navbar. Continue?"
            )
          ).pipe(
            filter((result) => result),
            switchMap((result) => {
              this.loading.emit(true);
              return this.dashboardService.getCast();
            }),
            switchMap((result) => {
              return this.v2CastService.blockGet(
                result.data.attributes.initialBlockId
              );
            }),
            switchMap((result) => {
              let newNavigationBlock: Block = {
                id: parseInt(result.data.id),
                order: null,
                type: "navigation",
                properties: {
                  ...result.data.attributes.properties,
                  items: this.screenInfos
                    .filter(
                      (screenInfo) =>
                        this.screenId === screenInfo.screenId ||
                        screenInfo.isPresentOnNavbar
                    )
                    .map((screenInfo) => {
                      return {
                        isHidden:
                          screenInfo.isHidden ||
                          this.screenId === screenInfo.screenId,
                        title: screenInfo.title,
                        icon: {
                          sfSymbols: screenInfo.icon,
                          material: screenInfo.icon,
                        },
                        screenId: screenInfo.screenId,
                        slug: screenInfo.slug,
                      };
                    }),
                },
              };
              return this.castService.blockUpdate(
                "1",
                "6",
                newNavigationBlock.id + "",
                {
                  block: newNavigationBlock,
                }
              );
            }),
            switchMap((result) =>
              this.modalService.alert("Successfully hid screen from navbar")
            ),
            tap((result) => {
              this.loading.emit(false);
            })
          )
        ),
      },
      {
        name: "Show Screen",
        action: defer(() =>
          from(
            this.modalService.confirm(
              "This will show the screen in the navbar. Continue?"
            )
          ).pipe(
            filter((result) => result),
            switchMap((result) => {
              this.loading.emit(true);
              return this.dashboardService.getCast();
            }),
            switchMap((result) => {
              return this.v2CastService.blockGet(
                result.data.attributes.initialBlockId
              );
            }),
            switchMap((result) => {
              let newNavigationBlock: Block = {
                id: parseInt(result.data.id),
                order: null,
                type: "navigation",
                properties: {
                  ...result.data.attributes.properties,
                  items: this.screenInfos
                    .filter(
                      (screenInfo) =>
                        this.screenId === screenInfo.screenId ||
                        screenInfo.isPresentOnNavbar
                    )
                    .map((screenInfo) => {
                      return {
                        isHidden:
                          screenInfo.isHidden &&
                          this.screenId !== screenInfo.screenId,
                        title: screenInfo.title,
                        icon: {
                          sfSymbols: screenInfo.icon,
                          material: screenInfo.icon,
                        },
                        screenId: screenInfo.screenId,
                        slug: screenInfo.slug,
                      };
                    }),
                },
              };
              return this.castService.blockUpdate(
                "1",
                "6",
                newNavigationBlock.id + "",
                {
                  block: newNavigationBlock,
                }
              );
            }),
            switchMap((result) =>
              this.modalService.alert("Successfully showed screen in navbar")
            ),
            tap((result) => {
              this.loading.emit(false);
            })
          )
        ),
      },
      {
        name: "Remove from Navbar",
        action: defer(() =>
          from(
            this.modalService.confirm(
              "This will remove the screen from the navbar. Continue?"
            )
          ).pipe(
            filter((result) => result),
            switchMap((result) => {
              this.loading.emit(true);
              let newNavigationBlock: Block = {
                id: parseInt(this.navigationBlock.data.id),
                order: null,
                type: "navigation",
                properties: {
                  // ...this.navigationBlock.data.attributes,
                  appearance:
                    this.navigationBlock.data.attributes?.properties
                      ?.appearance,
                  classes:
                    this.navigationBlock.data.attributes?.properties?.classes,

                  items: this.screenInfos
                    .filter(
                      (screenInfo) =>
                        this.screenId !== screenInfo.screenId &&
                        screenInfo.isPresentOnNavbar
                    )
                    .map((screenInfo) => {
                      return {
                        title: screenInfo.title,
                        icon: {
                          sfSymbols: screenInfo.icon,
                          material: screenInfo.icon,
                        },
                        screenId: screenInfo.screenId,
                        slug: screenInfo.slug,
                      };
                    }),
                },
              };

              return this.castService.blockUpdate(
                "1",
                "6",
                newNavigationBlock.id + "",
                {
                  block: newNavigationBlock,
                }
              );
            }),
            switchMap((result) =>
              this.modalService.alert("Successfully removed screen from navbar")
            ),
            tap(() => {
              this.reload.emit(true);
              this.loading.emit(false);
            })
          )
        ),
      },
      {
        name: "Add to Navbar",
        action: defer(() =>
          from(
            this.modalService.confirm(
              "This will add the screen to the navbar. Continue?"
            )
          ).pipe(
            filter((result) => result),
            switchMap((result) => {
              this.loading.emit(true);
              let newNavigationBlock: Block = {
                id: parseInt(this.navigationBlock.data.id),
                order: null,
                type: "navigation",
                properties: {
                  // ...this.navigationBlock.data.attributes,
                  appearance:
                    this.navigationBlock.data.attributes?.properties
                      ?.appearance,
                  classes:
                    this.navigationBlock.data.attributes?.properties?.classes,

                  items: this.screenInfos
                    .filter(
                      (screenInfo) =>
                        this.screenId === screenInfo.screenId ||
                        screenInfo.isPresentOnNavbar
                    )
                    .map((screenInfo) => {
                      return {
                        title: screenInfo.title,
                        icon: {
                          sfSymbols: screenInfo.icon,
                          material: screenInfo.icon,
                        },
                        screenId: screenInfo.screenId,
                        slug: screenInfo.slug,
                      };
                    }),
                },
              };

              return this.castService.blockUpdate(
                "1",
                "6",
                newNavigationBlock.id + "",
                {
                  block: newNavigationBlock,
                }
              );
            }),
            switchMap((result) =>
              this.modalService.alert("Successfully added screen to navbar")
            ),
            tap(() => {
              this.reload.emit(true);
              this.loading.emit(false);
            })
          )
        ),
      },
      {
        name: "Share Screen",
        action: defer(() =>
          from(
            this.modalService.confirm(
              "Share screen will allow your other Cast(s) to reuse this screen. Continue?"
            )
          ).pipe(
            filter((result) => result),
            switchMap((result) => {
              this.loading.emit(true);
              return this.castService.screenUpdate(this.castId, this.screenId, {
                screen: { shareable: true },
              });
            }),
            tap((result) => {
              if (result) this.success = true;
            }),
            //get screen blocks
            switchMap((result) =>
              this.castService.blockList(this.castId, this.screenId)
            ),
            //make catalog type blocks shareable

            map((result) =>
              result.filter((result) => result.type === "catalog")
            ),
            switchMap((result) => {
              let collectionIds = result.map((_result) =>
                this.resourceService.updateCollectionByUuid(
                  _result.properties?.uuid,
                  { isShareable: true }
                )
              );
              return forkJoin(collectionIds);
            }),
            tap((result) => {
              this.success = result && result.length > 0;
            }),

            finalize(() => {
              if (this.success)
                this.modalService.alert("Successfully updated screen");
              this.reload.emit(true);
              this.loading.emit(false);
            })
          )
        ),
      },
      {
        name: "Unshare Screen",
        action: defer(() =>
          from(
            this.modalService.confirm(
              "Unshare screen will not allow your other Cast(s) to reuse this screen. Continue?"
            )
          ).pipe(
            filter((result) => result),
            switchMap((result) => {
              this.loading.emit(true);
              return this.castService.screenUpdate(this.castId, this.screenId, {
                screen: { shareable: false },
              });
            }),
            tap((result) => {
              if (result) this.success = true;
            }),
            //get screen blocks
            switchMap((result) =>
              this.castService.blockList(this.castId, this.screenId)
            ),
            //make catalog type blocks unshareable
            map((result) =>
              result.filter((result) => result.type === "catalog")
            ),
            switchMap((result) => {
              let collectionIds = result.map((_result) =>
                this.resourceService.updateCollectionByUuid(
                  _result.properties?.uuid,
                  { isShareable: false }
                )
              );
              return forkJoin(collectionIds);
            }),
            tap((result) => {
              this.success = result && result.length > 0;
            }),
            finalize(() => {
              if (this.success)
                this.modalService.alert("Successfully updated screen");
              this.reload.emit(true);
              this.loading.emit(false);
            })
          )
        ),
      },
      {
        name: "Delete",
        action: defer(() =>
          from(
            this.modalService.confirm("This will delete the screen. Continue?")
          ).pipe(
            filter((result) => result),
            switchMap((result) => {
              this.loading.emit(true);
              let newNavigationBlock: Block = {
                id: parseInt(this.navigationBlock.data.id),
                order: null,
                type: "navigation",
                properties: {
                  ...this.navigationBlock.data.attributes.properties,
                  items: this.navigationBlock.data.attributes.items.filter(
                    (item) => item.screenId != this.screenId
                  ),
                },
              };

              return this.castService.blockUpdate(
                "1",
                "6",
                newNavigationBlock.id + "",
                {
                  block: newNavigationBlock,
                }
              );
            }),
            switchMap((result) =>
              this.castService.screenDelete(this.castId, this.screenId)
            ),

            switchMap((result) =>
              this.modalService.alert("Successfully deleted screen")
            ),
            tap(() => {
              this.reload.emit(true);
              this.loading.emit(false);
            })
          )
        ),
      },
    ];
  }
  screenAction(action: Observable<any>) {
    this.action?.unsubscribe();
    this.action = action.subscribe((result) => {
      if (!result) {
        this.reload.emit(false);
      } else this.reload.emit(true);
    });
    return;
  }
}
