<app-item-list [items]="data" [itemTemplate]="itemTemplate"> </app-item-list>

<ng-template #itemTemplate let-item="item">
  <div class="flex justify-between items-center">
    <div>
      <ng-container *ngIf="icon">
        <app-icon [icon]="icon"></app-icon>
      </ng-container>

      <span class="px-2">
        {{ item.name }}
      </span>
    </div>

    <div class="font-bold text-blue-600">
      {{ item.valueText }}
    </div>
  </div>
</ng-template>
