import { Component, Input, OnInit } from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import { firstValueFrom } from "rxjs";
import { UploadsService } from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-exam-item-form-choices",
  templateUrl: "./exam-item-form-choices.component.html",
  styles: [],
})
export class ExamItemFormChoicesComponent implements OnInit {
  @Input()
  formGroup: UntypedFormGroup;

  loading: boolean = false;

  constructor(
    public formBuilder: UntypedFormBuilder,
    public uploadService: UploadsService
  ) {}

  ngOnInit() {
    this.formGroup = this.formGroup || this.formBuilder.group({});

    this.autoValidateChoices();
  }

  getChoiceControls() {
    let formArray = this.formGroup.get("choices") as UntypedFormArray;
    return formArray.controls;
  }

  getUndeletedChoiceControls() {

    let choiceControls = this.getChoiceControls();
    if (choiceControls && choiceControls.length > 0)
      return choiceControls.filter(
        (control) => !control.get("isDeleted").value
      );
    return [];
  }

  autoValidateChoices() {
    let choiceControls = this.getUndeletedChoiceControls();

    if (
      (this.formGroup.value.type == "MC" ||
        this.formGroup.value.type == "IC") &&
      choiceControls.length > 0 &&
      choiceControls.every((control) => !control.get("isCorrect").value)
    ) {
      choiceControls[0].get("isCorrect").patchValue(true, {
        emitEvent: false,
      });
    }
  }

  onEnterNewChoice(event: KeyboardEvent) {
    event.stopPropagation();
    event.preventDefault();

    let value = event.target["value"];
    event.target["value"] = "";

    if (value !== "") {
      this.addItemChoice({
        text: value,
      });
    }

    this.autoValidateChoices();
  }

  onImageUploadNewChoice(event) {
    this.addItemChoice({
      imageUri: event.uri,
    });
  }

  onAudioUploadNewChoice(event) {
    this.addItemChoice({
      audioUri: event.uri,
    });
  }

  onMultipleChoiceRadioChange(formGroup: UntypedFormGroup) {
    let controls = this.getChoiceControls();

    controls.forEach((control, i) => {
      control.get("isCorrect").setValue(false);
    });

    formGroup.get("isCorrect").setValue(true);
  }

  async onUpload(event, formControl: UntypedFormControl) {
    this.loading = true;

    try {
      let file = event.target.files[0];
      event.srcElement.value = null;

      let result = await firstValueFrom(this.uploadService.uploadFile(file));

      formControl.setValue(result.uri);
    } catch (err) {
      throw err;
    } finally {
      this.loading = false;
    }
  }

  clearControl(formControl: UntypedFormControl) {
    formControl.setValue(null);
  }

  addItemChoice(values: {
    text?: string;
    imageUri?: string;
    audioUri?: string;
  }) {
    let formArray = this.formGroup.get("choices") as UntypedFormArray;

    let newFormGroup = this.formBuilder.group({
      isCorrect: [false],
      text: [values.text],
      choiceUuid: [null],
      imageUri: [values.imageUri],
      audioUri: [values.audioUri],
      isDeleted: [false],
    });

    formArray.push(newFormGroup);
  }

  removeChoice(formGroup: UntypedFormGroup) {
    formGroup.get("isDeleted").patchValue(true);
    formGroup.disable();
  }
}
