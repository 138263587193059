import { Pipe, PipeTransform } from "@angular/core";

function escapeRegex(string) {
  return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
}

@Pipe({
  name: "filterAll",
})
export class FilterPipePipe implements PipeTransform {
  transform(value: any, searchText: string, fields?: string[]): any {
    if (!searchText || !value) {
      return value;
    }

    let escapedText = escapeRegex(searchText);
    return value.filter((data) => this.matchValue(data, escapedText, fields));
  }

  matchValue(data, value, fields?: string[]) {
    return Object.keys(data)
      .filter((key) => !fields || fields.includes(key))
      .map((key) => {
        return new RegExp(value, "gi").test(data[key]);
      })
      .some((result) => result);
  }
}
