import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { Resource } from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-resource-type-upload-epub",
  templateUrl: "./resource-type-upload-epub.component.html",
})
export class ResourceTypeUploadEpubComponent implements OnInit {
  @Input()
  formGroup: UntypedFormGroup;

  @Input()
  resource: Resource;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.formGroup.addControl(
      "content",
      this.formBuilder.group({
        uri: [this.resource.content["uri"], Validators.required],
        // contentType: ["application/pdf"],
      })
    );
  }

  onUpload(result) {
    this.formGroup.get("content").get("uri").setValue(result.uri);
  }
}
