<app-back-title-layout>
  <div
    *ngIf="loading"
    [@fadeInOut]
    class="absolute inset-0 bg-white bg-opacity-50 h-full"
  >
    <app-loading [loading]="true" position="relative"></app-loading>
  </div>
  <div
    class="flex justify-between {{ hideStatus }} mb-4"
    *ngIf="status === 'scheduled'"
  >
    <div
      class="w-max border rounded-full bg-warning-200 text-warning-600 px-2 text-sm pt-1"
    >
      scheduled
    </div>
    <div>
      <button
        class="border rounded bg-secondary-600 text-white uppercase py-1 px-2 text-sm mr-2"
        (click)="enableEdit()"
      >
        edit
      </button>
      <button
        class="border rounded bg-secondary-600 text-white uppercase py-1 px-2 text-sm"
        (click)="openModal(cancelScheduleModal, 'cancelSchedule')"
      >
        cancel schedule
      </button>
    </div>
  </div>
  <div class="flex justify-between mb-4">
    <h2 class="form-heading">Email Details</h2>
  </div>
  <div class="text-sm text-gray-400">Send to</div>
  <div class="rounded bg-gray-100 text-gray-400">
    <div class="uppercase p-2">filter</div>
    <!-- container for tablets -->
    <div class="flex p-2 flex-wrap w-full">
      <!-- tablet format -->
      <div class="flex w-max shrink rounded-full bg-gray-200 px-1 mr-1">
        <!-- tablet content -->
        <div class="flex mr-1" *ngFor="let filter of firstFilter">
          {{ filter }}
        </div>
        <button
          type="button"
          class="text-gray-400"
          *ngIf="firstFilter.length > 0"
        >
          <app-icon icon="close"></app-icon>
        </button>
      </div>
      <!-- tablet format -->
      <div class="flex">
        <!-- tablet content -->
        <div class="flex mr-1" *ngFor="let filter of _filters; let i = index">
          <div class="flex w-max shrink rounded-full bg-gray-200 px-1 mr-1">
            <div *ngFor="let _filter of filter" class="flex mr-1">
              {{ _filter }}
            </div>

            <button
              type="button"
              class="text-gray-400"
              *ngIf="filter.length > 0"
              (click)="deleteFilter(i + 1)"
            >
              <app-icon icon="close"></app-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- filter results here -->
    <button
      class="text-gray-400 p-2"
      type="button"
      (click)="openModal(filterResultsModal, 'filterResults')"
    >
      {{ filterTotal }} result(s) found
    </button>
  </div>
  <button
    class="uppercase text-primary-600 mb-4 {{ showButton }}"
    (click)="openModal(mailerFilterModal, 'mailerFilter')"
  >
    <app-icon [icon]="'mode'"></app-icon>
    edit filter
  </button>
  <form [formGroup]="form" (ngSubmit)="openModal(sendScheduleModal, 'send')">
    <label class="form-label">Email Subject</label>
    <input
      placeholder="Email Subject"
      class="form-control"
      formControlName="subject"
    />
    <div class="mb-4">
      <app-form-control-error-layout
        [control]="form.get('subject')"
      ></app-form-control-error-layout>
    </div>
    <label class="form-label">Schedule</label>
    <div class="flex mb-8">
      <div class="w-1/2 mr-2">
        <input
          type="date"
          class="form-control"
          id="date"
          formControlName="date"
        />

        <app-form-control-error-layout
          [control]="form.get('date')"
        ></app-form-control-error-layout>
      </div>
      <div class="w-1/2">
        <input
          type="time"
          class="mr-2 form-control"
          id="time"
          formControlName="time"
        />

        <app-form-control-error-layout
          [control]="form.get('time')"
        ></app-form-control-error-layout>
      </div>
    </div>
    <div class="form-label mb-4">Email Content</div>
    <div class="flex justify-between border border-gray-300 py-1 rounded">
      <div class="flex">
        <button
          type="button"
          class="text-primary-600 mx-2 text-lg"
          (click)="code()"
        >
          <app-icon [icon]="'code'"></app-icon>
        </button>
        <button
          type="button"
          class="text-primary-600 text-lg mx-2"
          (click)="preview()"
        >
          <app-icon [icon]="'remove_red_eye'"></app-icon>
        </button>
      </div>
      <button
        class="btn btn-primary-borderless mr-2"
        type="button"
        (click)="openModal(testEmailModal, 'testEmail')"
        [disabled]="form.invalid"
      >
        <app-icon [icon]="'mail'"></app-icon>
        send test email
      </button>
    </div>
    <textarea
      #content
      class="p-2 rounded w-full border border-gray-300 {{ inputHidden }}"
      rows="15"
      [style.resize]="'none'"
      formControlName="content"
    ></textarea>
    <div class="mb-8">
      <app-form-control-error-layout
        [control]="form.get('content')"
      ></app-form-control-error-layout>
    </div>
    <div
      [innerHTML]="content.value"
      class="p-2 overflow-y-auto border border-gray-300 rounded mb-8 w-full h-96 {{
        previewHidden
      }}"
    ></div>
    <div
      class="flex justify-between"
      *ngIf="status === 'scheduled' || status === 'draft'"
    >
      <button
        type="button"
        class="btn btn-primary-borderless {{ showButton }}"
        (click)="openModal(saveTemplateModal, 'saveTemplate')"
        [disabled]="form.invalid"
      >
        <app-icon [icon]="'save'" class="mr-1"></app-icon>save as template
      </button>
      <button
        type="submit"
        class="btn btn-primary {{ showButton }}"
        [disabled]="form.invalid"
      >
        save
      </button>
    </div>
  </form>
</app-back-title-layout>
<!-- Modal Templates -->
<ng-template #cancelScheduleModal>
  <app-cancel-schedule-modal [emailUuid]="mailUuid"></app-cancel-schedule-modal>
</ng-template>
<ng-template #testEmailModal>
  <app-test-email-modal [emailReq]="form"></app-test-email-modal>
</ng-template>
<ng-template #sendScheduleModal>
  <app-send-schedule-modal
    [emailReq]="form"
    [action]="action"
    [mailUuid]="mailUuid"
    [filters]="filtersForm"
  ></app-send-schedule-modal>
</ng-template>
<ng-template #saveTemplateModal>
  <app-save-template-modal
    [emailReq]="form"
    [filters]="filtersForm"
  ></app-save-template-modal>
</ng-template>
<ng-template #mailerFilterModal>
  <app-mailer-filter
    [deleteIndex]="deleteIndex"
    [filtersArr]="filters"
    (filters)="setFilters($event)"
  ></app-mailer-filter>
</ng-template>
<ng-template #filterResultsModal>
  <app-filter-results
    (totalUsers)="setFilterTotal($event)"
    [filters]="inputFilters"
  ></app-filter-results>
</ng-template>
<div class="hidden">
  <ng-container *ngTemplateOutlet="filterResultsModal"></ng-container>
</div>
<div class="hidden">
  <ng-container *ngTemplateOutlet="mailerFilterModal"></ng-container>
</div>
