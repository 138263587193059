import { Component, OnInit, Input } from "@angular/core";
import { RouterLink, QueryParamsHandling } from "@angular/router";

export interface LinkList {
  icon?: string;

  name: string;
  description?: string;
  disabled?: boolean;
  routerLinkOptions?: {
    queryParams?: {
      [k: string]: any;
    };
    routerLink: string | string[];
    fragment?: string;
    queryParamsHandling?: QueryParamsHandling;
    preserveFragment?: boolean;
    skipLocationChange?: boolean;
    replaceUrl?: boolean;
  };
  needsAttention?: boolean;
}

@Component({
  selector: "app-profile-link-list",
  templateUrl: "./profile-link-list.component.html",
  styleUrls: ["./profile-link-list.component.scss"],
})
export class ProfileLinkListComponent implements OnInit {
  @Input()
  linkLists: LinkList[];

  constructor() {}

  ngOnInit(): void {}
}
