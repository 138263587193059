<div class="relative pl-1">
  <ng-container *ngIf="resource.type === 'course'">
    <a
      href="/courses/profile?resource_uuid={{ resource.uuid }}"
      class="flex cursor-pointer"
    >
      <div class="flex justify-center">
        <app-resource-card
          [isInfoHidden]="false"
          [resource]="resource"
          [cardHeaderBackgroundColor]="cardHeaderBackgroundColor"
          [cardHeaderForegroundColor]="cardHeaderForegroundColor"
          x
        >
        </app-resource-card>
      </div>
    </a>
  </ng-container>

  <ng-container *ngIf="resource.type !== 'course'">
    <a
      [routerLink]="['/resources', 'profile']"
      [queryParams]="{
        resource_uuid: resource.uuid
      }"
      class="flex cursor-pointer"
    >
      <div class="flex justify-center">
        <app-resource-card
          [isInfoHidden]="false"
          [resource]="resource"
          [cardHeaderBackgroundColor]="cardHeaderBackgroundColor"
          [cardHeaderForegroundColor]="cardHeaderForegroundColor"
          x
        >
        </app-resource-card>
      </div>
    </a>
  </ng-container>

  <div class="absolute bottom-0 right-0 z-10 p-1">
    <button
      class="px-1 ml-2 text-white bg-red-600 rounded-sm"
      (click)="onDeleteResource()"
      *ngIf="isInEditMode"
    >
      <app-icon icon="delete"></app-icon>
    </button>
  </div>
</div>
