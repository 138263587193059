import { Component, EventEmitter, Input, Output } from "@angular/core";
import {
  FormGroup,
  UntypedFormBuilder,
  FormArray,
  UntypedFormControl,
} from "@angular/forms";
import { firstValueFrom } from "rxjs";
import { UploadsService } from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-exam-item-form-choices-with-question-paths",
  templateUrl: "./exam-item-form-choices-with-question-paths.component.html",
  styles: [],
})
export class ExamItemFormChoicesWithQuestionPathsComponent {
  @Input()
  formGroup: FormGroup;

  @Input()
  examItemOptions: {
    value: string;
    text: string;
  }[];

  realExamItemOptions: {
    value: string;
    text: string;
  }[];

  @Output()
  addChoice: EventEmitter<any> = new EventEmitter<any>();

  loading: boolean = false;

  constructor(
    public formBuilder: UntypedFormBuilder,
    public uploadService: UploadsService
  ) {}

  ngOnInit() {
    this.formGroup = this.formGroup || this.formBuilder.group({});

    this.realExamItemOptions = this.examItemOptions.filter(
      (option) => option.value !== this.formGroup.value.itemUuid
    );

    this.autoValidateChoices();
  }

  getChoiceControls() {
    let formArray = this.formGroup.get("choices") as FormArray;

    return formArray.controls;
  }

  getUndeletedChoiceControls() {
    return this.getChoiceControls().filter(
      (control) => !control.get("isDeleted").value
    );
  }

  autoValidateChoices() {
    let choiceControls = this.getUndeletedChoiceControls();

    if (
      (this.formGroup.value.type == "MC" ||
        this.formGroup.value.type == "IC") &&
      choiceControls.length > 0 &&
      choiceControls.every((control) => !control.get("isCorrect").value)
    ) {
      choiceControls[0].get("isCorrect").patchValue(true, {
        emitEvent: false,
      });
    }
  }

  onEnterNewChoice(event: KeyboardEvent) {
    event.stopPropagation();
    event.preventDefault();

    let value = event.target["value"];
    event.target["value"] = "";

    if (value !== "") {
      this.addItemChoice({
        text: value,
      });
    }

    this.autoValidateChoices();
  }

  onImageUploadNewChoice(event) {
    this.addItemChoice({
      imageUri: event.uri,
    });
  }

  onAudioUploadNewChoice(event) {
    this.addItemChoice({
      audioUri: event.uri,
    });
  }

  onMultipleChoiceRadioChange(formGroup: FormGroup) {
    let controls = this.getChoiceControls();

    controls.forEach((control, i) => {
      control.get("isCorrect").setValue(false);
    });

    formGroup.get("isCorrect").setValue(true);

    formGroup.markAsDirty();
  }

  async onUpload(event, formControl: UntypedFormControl) {
    this.loading = true;

    try {
      let file = event.target.files[0];
      event.srcElement.value = null;

      let result = await firstValueFrom(this.uploadService.uploadFile(file));

      formControl.setValue(result.uri);
      formControl.markAsDirty();
    } catch (err) {
      throw err;
    } finally {
      this.loading = false;
    }
  }

  clearControl(formControl: UntypedFormControl) {
    formControl.setValue(null);
    formControl.markAsDirty();
  }

  addItemChoice(values: {
    text?: string;
    imageUri?: string;
    audioUri?: string;
  }) {
    this.addChoice.emit(values);

    // let formArray = this.formGroup.get("choices") as FormArray;

    // let newFormGroup = this.formBuilder.group({
    //   isCorrect: [false],
    //   text: [values.text],
    //   choiceUuid: [null],
    //   imageUri: [values.imageUri],
    //   audioUri: [values.audioUri],
    //   isDeleted: [false],
    //   examItemUuidFlags: [],
    // });

    // formArray.push(newFormGroup);
    // formArray.markAsDirty();
  }

  removeChoice(formGroup: FormGroup) {
    formGroup.get("isDeleted").patchValue(true);
    formGroup.disable();

    let formArray = this.formGroup.get("choices") as FormArray;
    formArray.markAsDirty();
  }
}
