import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil, tap } from "rxjs/operators";
import {
  ActivityLogService,
  VERBS,
} from "src/app/services/activity-log.service";
import { DashboardService } from "src/app/services/dashboard.service";
import { CastsService } from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-casteditor-screen-create",
  templateUrl: "./casteditor-screen-create.component.html",
  styles: [],
})
export class CasteditorScreenCreateComponent implements OnInit {
  formGroup: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dashboardService: DashboardService,
    private activityLogsService: ActivityLogService,
    private castService: CastsService,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      title: [null, Validators.required],
      slug: [null, Validators.required],
    });

    // Set default slug if slug input is untouched
    this.formGroup
      .get("title")
      .valueChanges.pipe(
        takeUntil(this.unsubscribe$),
        tap((result) => {
          let slugFormControl = this.formGroup.get("slug");

          if (slugFormControl.untouched) {
            slugFormControl.setValue(result.toLowerCase().split(" ").join("-"));
          }
        })
      )
      .subscribe();
  }

  private unsubscribe$: Subject<void> = new Subject<void>();
  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  async createScreen() {
    try {
      let cast = await this.dashboardService.getCast().toPromise();
      let castId = cast.data.id;
      let screen = {
        slug: this.formGroup.value.slug,
        title: this.formGroup.value.title,
        description: this.formGroup.value.title,
        castId: parseInt(castId),
      };

      this.formGroup.disable();

      let screenResult = await this.castService
        .screenCreate(castId, {
          screen,
        })
        .toPromise();

      this.location.back();

      this.activityLogsService.didCastScreen(VERBS.created, screenResult);
    } catch (err) {
      this.formGroup.enable();
    }
  }
}
