<app-back-title-layout>
  <!-- stats here  -->
  <div class="flex justify-end mb-4">
    <a
      class="text-2xl text-gray-500"
      [routerLink]="'/idis/users/settings'"
      [replaceUrl]="false"
      [queryParamsHandling]="'merge'"
      [queryParams]="{ parent_tab: 'user_information', page: null }"
      ><app-icon [icon]="'settings'"></app-icon
    ></a>
  </div>
  <div class="grid grid-cols-4 gap-6">
    <ng-container *ngFor="let stats of idsListOfStats">
      <div class="p-4 shadow-sm rounded-lg bg-info-200">
        <div class="flex justify-between">
          <div class="text-info-500 uppercase text-sm mb-4 font-bold">
            {{ stats.name }}
          </div>
          <ng-container *ngIf="stats.link">
            <a [routerLink]="stats.link" class="text-info-500">
              <app-icon [icon]="'keyboard_arrow_right'"></app-icon>
            </a>
          </ng-container>
        </div>

        <div class="text-info-700 text-2xl font-bold">{{ stats.count }}</div>
      </div>
    </ng-container>
  </div>

  <!-- list of users component here  -->
  <div class="mt-8 p-6 border rounded-lg shadow-sm">
    <h2 class="font-bold text-xl mb-6">Users</h2>
    <div class="flex items-center mb-6">
      <button
        [routerLink]="['new', 'index']"
        class="py-1 px-2 rounded bg-primary-600 uppercase text-sm text-white"
        [disabled]="!(canAddUsersObservable | async)"
        [attr.data-tooltip]="
          !(canAddUsersObservable | async)
            ? 'Contact your admin to gain access'
            : ''
        "
      >
        <app-icon icon="add_circle" class="text-white"></app-icon>
        Add user
      </button>
      <div class="hidden sm:flex">
        <button
          [routerLink]="['new', 'csv']"
          class="text-primary-600 uppercase text-sm px-2 py-1"
        >
          <app-icon icon="insert_drive_file"></app-icon>
          Import CSV
        </button>
      </div>
    </div>
    <div class="block w-full sm:flex">
      <app-search-bar-query
        placeholder="Search"
        class="w-3/4"
      ></app-search-bar-query>
      <div class="flex mt-2 sm:mt-0">
        <button
          class="uppercase flex items-center p-2 text-gray-400 mx-2 rounded border"
          (click)="openModal('filters')"
        >
          <app-icon icon="filter_list"> </app-icon>
          <div class="hidden sm:block text-sm">filter</div>
        </button>

        <button
          class="uppercase flex items-center p-2 text-gray-400 rounded border"
          (click)="openModal('columns')"
        >
          <app-icon icon="view_column"> </app-icon>
          <div class="hidden sm:block text-sm">columns</div>
        </button>
      </div>
    </div>
    <div class="my-5">
      <app-item-select-with-header
        [headers]="headers"
        [totalCount]="totalCount"
        [key]="'uuid'"
        [category]="'user'"
        [source]="userListObservable"
        [itemTemplate]="userTemplate"
        [limit]="limit"
        [enableSort]="false"
        [loading]="loading"
      >
      </app-item-select-with-header>
    </div>
  </div>
</app-back-title-layout>

<ng-template #userTemplate let-user="item">
  <div class="flex">
    <div class="block sm:flex mt-2 sm:mt-0">
      <a
        class="block w-full sm:flex ml-2 sm:ml-0"
        [routerLink]="['user-profile']"
        [queryParams]="{ user_uuid: user.id, parent_tab: 'user_information' }"
      >
        <!-- Name  -->
        <ng-container *ngIf="headers">
          <div
            class="grid grid-cols-1 my-1 w-full sm:flex"
            *ngFor="let header of headers"
          >
            <div class="block sm:w-96 sm:flex" *ngIf="header.name === 'Name'">
              <img
                [src]="user.imageUrl"
                *ngIf="user.imageUrl"
                class="text-white text-2xl leading-none mx-1 bg-gray-200 rounded-full items-center justify-center h-8 w-8"
              />
              {{ user.name || user.id }}
            </div>
          </div>
        </ng-container>
      </a>
    </div>
  </div>
</ng-template>
