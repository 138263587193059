import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { forkJoin, Observable, of, Subscription } from "rxjs";
import { ModalComponent } from "src/app/app-common-module/layouts/modal/modal.component";
import { ModalService } from "src/app/services/modal.service";
import {
  CoursesService,
  PassService,
  ResourcesService,
} from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-mailer-filter",
  templateUrl: "./mailer-filter.component.html",
  styles: [],
})
export class MailerFilterComponent implements OnInit {
  @Input() filtersArr: any[] = [];
  @Input() deleteIndex: number = 0;
  @Output() filters = new EventEmitter<any>();
  @ViewChild("user") user: ModalComponent;
  @ViewChild("group") group: ModalComponent;
  @ViewChild("course") course: ModalComponent;
  @ViewChild("batch") batch: ModalComponent;
  @ViewChild("filterResults") filterRes: ModalComponent;
  @ViewChild("userModal", { read: TemplateRef })
  userModalTemplate: TemplateRef<any>;
  @ViewChild("groupModal", { read: TemplateRef })
  groupModalTemplate: TemplateRef<any>;
  @ViewChild("courseModal", { read: TemplateRef })
  courseModalTemplate: TemplateRef<any>;
  @ViewChild("batchModal", { read: TemplateRef })
  batchModalTemplate: TemplateRef<any>;
  @ViewChild("filterResultsModal", { read: TemplateRef })
  filterResultsModalTemplate: TemplateRef<any>;
  _close: boolean = false;
  filter1: any[] = [];
  filter2: any[] = [];
  filter3: any[] = [];
  getNameSub: Subscription;
  currentIndex: number = 0;
  form: UntypedFormGroup;
  type: any[] = [];
  type2: any[] = [];
  batchInput: any;
  delete: boolean = false;
  mainDropDown: any[] = [
    // {
    //   name: "All",
    //   value: "All",
    //   pair: [
    //     { name: "all emails", value: "all emails" },
    //     { name: "emails confirmed", value: "emails confirmed" },
    //     { name: "emails not confirmed", value: "emails not confirmed" },
    //   ],
    // },

    {
      name: "Email",
      value: "Email",
      pair: [
        { name: "include", value: "include" },
        // { name: "exclude", value: "exclude" },
      ],
    },
    { name: "User", value: "User" },
    { name: "Group", value: "Group" },
    {
      name: "Course",
      value: "Course",
      pair: [
        { name: "batch", value: "Batch" },
        {
          name: "student status",
          value: "student status",
          pair: [
            // { name: "all", value: "all" },
            { name: "not started", value: "not_started" },
            { name: "in progress", value: "in_progress" },
            {
              name: "passed with certification",
              value: "passed",
            },
            { name: "with failed activity", value: "failed" },
            { name: "needs attention", value: "needs_attention" },
          ],
        },
      ],
    },
    {
      name: "Last Sign In",
      value: "Last Sign In",
      pair: [
        // { name: "is", value: "is" },
        { name: "after", value: "after" },
        { name: "before", value: "before" },
      ],
    },
  ];
  resourceSub: Subscription;
  constructor(
    private fb: UntypedFormBuilder,
    private resourcesService: ResourcesService,
    private router: Router,
    private route: ActivatedRoute,
    private passService: PassService,
    private coursesService: CoursesService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      first: this.fb.group({
        filter1: this.fb.control(null, Validators.required),
        filter2: this.fb.control(null, Validators.required),
        filter3: this.fb.control(null),
        filter4: this.fb.control(null),
      }),
      filters: this.fb.array([], [Validators.minLength(1)]),
    });
    if (this.filtersArr) {
      this.setFilters(this.filtersArr);
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.deleteIndex &&
      changes.deleteIndex.currentValue &&
      changes.deleteIndex.currentValue !== 0
    ) {
      if (changes.deleteIndex.currentValue === 0) {
        return;
      }
      let delInx = changes.deleteIndex.currentValue;
      this.delete = true;
      // this.setFilters(this.filtersArr);
    }
  }
  setFilters(filters) {
    let listObservable: Observable<any>[] = [];
    this.form = this.fb.group({
      first: this.fb.group({
        filter1: this.fb.control(null, Validators.required),
        filter2: this.fb.control(null, Validators.required),
        filter3: this.fb.control(null),
        filter4: this.fb.control(null),
      }),
      filters: this.fb.array([], [Validators.minLength(1)]),
    });

    let filter2 = null;
    let filter3 = null;
    let filter4 = null;

    for (let i = 0; i < filters.length; i++) {
      let filter1 =
        filters[i].key === "email"
          ? "Email"
          : filters[i].key === "user"
          ? "User"
          : filters[i].key === "group"
          ? "Group"
          : filters[i].key === "course"
          ? "Course"
          : filters[i].key === "last_sign_in_at_from" ||
            filters[i].key === "last_sign_in_at_to"
          ? "Last Sign In"
          : null;
      if (filter1 === "Email") {
        filter2 = "include";
        filter3 = filters[i].values[0];
        filter4 = null;

        listObservable.push(
          of({
            filter1: filter1,
            filter2: filter2,
            filter3: filter3,
            filter4: filter4,
          })
        );
      } else if (filter1 === "User") {
        listObservable.push(
          forkJoin({
            filter1: of("User"),
            service: this.passService.getUser(filters[i].values[0]),
          })
        );
      } else if (filter1 === "Group") {
        listObservable.push(
          forkJoin({
            filter1: of("Group"),
            service: this.passService.getGroup(filters[i].values[0]),
          })
        );
      } else if (filter1 === "Course") {
        if (filters[i + 1].key === "schedule") {
          listObservable.push(
            forkJoin({
              filter1: of("Course"),
              service: this.coursesService.courseRead(filters[i].values[0]),
              filter3: of("Batch"),
              batch: this.coursesService.scheduleRead(filters[i + 1].values[0]),
            })
          );
        }
        if (filters[i + 1].key === "course_status") {
          listObservable.push(
            forkJoin({
              filter1: of("Course"),
              service: this.coursesService.courseRead(filters[i].values[0]),
              filter3: of("student status"),
              filter4: of(filters[i + 1].values),
            })
          );
        }
      } else if (filter1 === "Last Sign In") {
        if (filters[i].key === "last_sign_in_at_from") {
          filter2 = "after";
          filter3 = filters[i].values[0];
          filter4 = null;
        } else if (filters[i].key === "last_sign_in_at_to") {
          filter2 = "before";
          filter3 = filters[i].values[0];
          filter4 = null;
        }
        listObservable.push(
          of({
            filter1: filter1,
            filter2: filter2,
            filter3: filter3,
            filter4: filter4,
          })
        );
      }
    }

    forkJoin(listObservable).subscribe({
      next: (values) => {
        values.forEach((value, index) => {
          if (value.filter1 === "Email" || value.filter1 === "Last Sign In") {
            if (index === 0) {
              this.setValues(value.filter1, index);
              this.form.get("first").get("filter1").setValue(value.filter1);
              this.form.get("first").get("filter2").setValue(value.filter2);
              this.form.get("first").get("filter3").setValue(value.filter3);
              this.form.get("first").get("filter4").setValue(value.filter4);
            } else if (index > 0) {
              this.addFilter();
              this.setValues(value.filter1, index);
              (<UntypedFormArray>this.form.get("filters")).controls[index - 1]
                .get("filter1")
                .setValue(value.filter1);
              (<UntypedFormArray>this.form.get("filters")).controls[index - 1]
                .get("filter2")
                .setValue(value.filter2);
              (<UntypedFormArray>this.form.get("filters")).controls[index - 1]
                .get("filter3")
                .setValue(value.filter3);
              (<UntypedFormArray>this.form.get("filters")).controls[index - 1]
                .get("filter4")
                .setValue(value.filter4);
            }
          } else {
            let _filter1 = null;
            let _filter2 = {};
            let _filter3 = null;
            let _filter4 = null;

            if (value.filter1 === "User") {
              _filter1 = value.filter1;
              _filter2 = {
                name: value.service.firstName + " " + value.service.lastName,
                uuid: value.service.id,
              };
            } else if (value.filter1 === "Course") {
              this.batchInput = {
                name: value.service.title,
                uuid: value.service.uuid,
              };
              _filter1 = value.filter1;
              _filter2 = {
                name: value.service.title,
                uuid: value.service.uuid,
              };
            } else if (value.filter1 === "Group") {
              _filter1 = value.filter1;
              _filter2 = {
                name: value.service.name,
                uuid: value.service.id,
              };
            }
            if (value.filter3 === "Batch") {
              _filter3 = value.filter3;
              _filter4 = {
                name: value.batch.name,
                uuid: value.batch.uuid,
              };
            }
            if (value.filter3 === "student status") {
              _filter3 = value.filter3;
              _filter4 = value.filter4;
            }
            if (index === 0) {
              this.setValues(_filter1, index);
              this.form.get("first").get("filter1").setValue(_filter1);
              this.form.get("first").get("filter2").setValue(_filter2);
              this.form.get("first").get("filter3").setValue(_filter3);
              this.form.get("first").get("filter4").setValue(_filter4);

              if (value.filter3 === "Batch") this.setBatch("Batch", index);

              if (value.filter3 === "student status")
                this.setBatch("student status", index);
            } else if (index > 0) {
              this.addFilter();
              this.setValues(_filter1, index);
              if (
                value.filter3 === "Batch" ||
                value.filter3 === "student status"
              ) {
                let batchIndex = index;
                if (value.filter3 === "Batch")
                  this.setBatch("Batch", batchIndex);

                if (value.filter3 === "student status")
                  this.setBatch("student status", batchIndex);

                (<UntypedFormArray>this.form.get("filters")).controls[
                  batchIndex - 1
                ]
                  .get("filter1")
                  .setValue(_filter1);
                (<UntypedFormArray>this.form.get("filters")).controls[
                  batchIndex - 1
                ]
                  .get("filter2")
                  .setValue(_filter2);
                (<UntypedFormArray>this.form.get("filters")).controls[
                  batchIndex - 1
                ]
                  .get("filter3")
                  .setValue(_filter3);
                (<UntypedFormArray>this.form.get("filters")).controls[
                  batchIndex - 1
                ]
                  .get("filter4")
                  .setValue(_filter4);
              } else {
                this.setValues(_filter1, index);
                let filterLength = (<UntypedFormArray>this.form.get("filters"))
                  .length;
                this.setValues(_filter1, filterLength);
                (<UntypedFormArray>this.form.get("filters")).controls[
                  filterLength - 1
                ]
                  .get("filter1")
                  .setValue(_filter1);
                (<UntypedFormArray>this.form.get("filters")).controls[
                  filterLength - 1
                ]
                  .get("filter2")
                  .setValue(_filter2);
                (<UntypedFormArray>this.form.get("filters")).controls[
                  filterLength - 1
                ]
                  .get("filter3")
                  .setValue(_filter3);
                (<UntypedFormArray>this.form.get("filters")).controls[
                  filterLength - 1
                ]
                  .get("filter4")
                  .setValue(_filter4);
              }
            }
          }
        });
      },
      complete: () => {
        this.onSubmit();
      },
    });
  }

  setValues(filter1, index) {
    let option = { target: { value: filter1 } };
    this.firstField(option, index);
  }
  setBatch(filter, index) {
    let option = { target: { value: filter } };
    this.secondField(option, index);
  }
  get _filters() {
    return (<UntypedFormArray>this.form.get("filters")).controls;
  }
  onSubmit() {
    this.filters.emit(this.form);
  }
  addFilter() {
    let _filter = this.fb.group({
      filter1: this.fb.control(null, Validators.required),
      filter2: this.fb.control(null, Validators.required),
      filter3: this.fb.control(null),
      filter4: this.fb.control(null),
    }) as UntypedFormGroup;

    (<UntypedFormArray>this.form.get("filters")).push(_filter);
  }
  clearAll() {
    (<UntypedFormArray>this.form.get("filters")).clear();
  }
  removeFilter(index) {
    (<UntypedFormArray>this.form.get("filters")).removeAt(index - 1);
    this.filter1.splice(index, 1);
    this.filter2.splice(index, 1);

    this.type.splice(index, 1);
    if (this.type2[index]) this.type2.splice(index, 1);
    if (this.filter3[index]) this.filter3.splice(index, 1);
  }
  firstField(event, index) {
    this.type[index] = event.target.value;

    this.mainDropDown.forEach((element) => {
      if (element.value === event.target.value) {
        this.filter1[index] = element.pair;
        this.filter2[index] = element.pair;
        // this.filter1.splice(index, 1, element.pair);
        // this.filter2.splice(index, 1, element.pair);
      }
    });
    // if (index === 0) {
    //   this.form.controls.first.get("filter2").setValue(null);
    //   this.form.controls.first.get("filter3").setValue(null);
    //   this.form.controls.first.get("filter4").setValue(null);
    // } else {
    //   (<UntypedFormArray>this.form.get("filters")).controls[index - 1]
    //     .get("filter2")
    //     .setValue(null);
    //   (<UntypedFormArray>this.form.get("filters")).controls[index - 1]
    //     .get("filter3")
    //     .setValue(null);
    //   (<UntypedFormArray>this.form.get("filters")).controls[index - 1]
    //     .get("filter4")
    //     .setValue(null);
    // }
  }
  secondField(event, index) {
    this.type2[index] = event.target.value;
    this.mainDropDown[3].pair.forEach((element) => {
      if (element.value === event.target.value) {
        this.filter3[index] = element.pair;
        // this.filter3.splice(index, 1, element.pair);
      }
    });
    // if (index === 0) this.form.controls.first.get("filter4").setValue(null);
    // else if (index > 1)
    //   (<UntypedFormArray>this.form.get("filters")).controls[index - 1]
    //     .get("filter4")
    //     .setValue(null);
  }

  modalOutput(data) {
    if (data.type === "course") {
      //set course variable for batch modal input
      this.resourceSub = this.resourcesService
        .getResourceByUuid(data.uuid)
        .subscribe((result) => {
          data.uuid = result.content.courseDetails?.courseUuid;
          this.batchInput = { ...data };
        });
    }
    if (data.type !== "batch") {
      if (data.index === 0)
        (<UntypedFormArray>this.form.get("first"))
          .get("filter2")
          .setValue(data);
      else
        (<UntypedFormArray>this.form.get("filters")).controls[data.index - 1]
          .get("filter2")
          .setValue(data);
    } else {
      if (data.index === 0)
        (<UntypedFormArray>this.form.get("first"))
          .get("filter4")
          .setValue(data);
      else
        (<UntypedFormArray>this.form.get("filters")).controls[data.index - 1]
          .get("filter4")
          .setValue(data);
    }
    if (data.type !== "course")
      this.router.navigate([], {
        relativeTo: this.route,
        replaceUrl: false,
        queryParamsHandling: "merge",
      });
  }
  ngOnDestroy(): void {
    this.resourceSub?.unsubscribe();
    this.getNameSub?.unsubscribe();
  }
  preventDropDown(e) {
    e.preventDefault();
  }

  async openModal(value, index) {
    this.currentIndex = index;

    let modalClass = [
      "flex",
      "justify-center",
      "items-center",
      "w-3/4",
      "h-1/2",
      ,
    ];
    let template;
    switch (value) {
      case "User":
        template = this.userModalTemplate;
        break;
      case "Group":
        template = this.groupModalTemplate;
        break;
      case "Course":
        template = this.courseModalTemplate;
        break;
      case "Filter":
        template = this.filterResultsModalTemplate;
        break;
      case "Batch":
        template = this.batchModalTemplate;
        break;
      default:
        console.log("No match found");
        break;
    }
    let results = await this.modalService.openModal(template, {
      panelClass: modalClass,
    });
    return results;
  }
}
