import { Component, OnInit } from "@angular/core";
import { map, merge, Subscription } from "rxjs";
import {
  NotificationService,
  SuccessMessage,
} from "src/app/services/notification.service";
import { fadeInOut } from "../../animations/fade";

@Component({
  selector: "app-notification-overlay",
  templateUrl: "./notification-overlay.component.html",
  animations: [fadeInOut],
})
export class NotificationOverlayComponent implements OnInit {
  successMessages: SuccessMessage[] = [];
  errors: Error[] = [];

  subscription: Subscription;
  timeout: number = 3000;

  constructor(private notificationService: NotificationService) {}

  ngOnInit(): void {
    this.subscription = merge(
      this.notificationService.onError.pipe(
        map((result) => {
          this.errors.push(result);

          setTimeout(() => {
            this.errors.splice(-1, 1);
          }, this.timeout);
        })
      ),

      this.notificationService.onSuccess.pipe(
        map((result) => {
          this.successMessages.push(result);

          setTimeout(() => {
            this.successMessages.splice(-1, 1);
          }, this.timeout);
        })
      )
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  removeItem(list: any[], index: number) {
    list.splice(index, 1);
  }

  testAddError() {
    this.notificationService.onError.emit(new Error("sample"));
  }
}
