<div class="grid gap-2 grid-cols-3">
  <ng-container *ngFor="let item of data; let i = index">
    <div
      class="leading-tight w-full p-2 bg-{{
        colors[i % colors.length]
      }}-200 text-{{ colors[i % colors.length] }}-600 font-bold rounded"
    >
      <div class="uppercase text-xs">{{ item.name }}</div>
      <div class="text-lg">{{ item.valueText }}</div>
    </div>
  </ng-container>
</div>
