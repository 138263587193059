import {
  Component,
  OnInit,
  Input,
  ViewContainerRef,
  Output,
  EventEmitter,
} from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-casteditor-modal-welcome",
  templateUrl: "./casteditor-modal-welcome.component.html",
  styleUrls: ["./casteditor-modal-welcome.component.scss"],
})
export class CasteditorModalWelcomeComponent implements OnInit {
  constructor(
    public viewContainerRef: ViewContainerRef,
    public dialogRef: MatDialogRef<CasteditorModalWelcomeComponent>
  ) {}

  initialModal: boolean;

  carouselItems: any;
  radioSelected: string;
  // htmlString:string;
  buttonlabel: string;

  ngOnInit(): void {
    this.radioSelected = "carousel-item1";
    this.carouselItems = [
      {
        name: "carousel-item1",
        checked: true,
        next: "carousel-item2",
        buttonlabel: "NEXT",
        value:
          "https://buriblob.sgp1.cdn.digitaloceanspaces.com/dashboard-assets/Frame%20190.png",
        // htmlString:`<p class="font-normal text-base">Your Cast is made up of blocks. You can add blocks with the _____ button.</p>`
      },
      {
        name: "carousel-item2",
        checked: false,
        next: "carousel-item3",
        buttonlabel: "NEXT",
        value:
          "https://buriblob.sgp1.cdn.digitaloceanspaces.com/dashboard-assets/Frame%20192.png",
        // htmlString:`<p class="font-normal text-base">Edit blocks and their contents with the <app-icon icon="create"></app-icon> button.</p>`
      },
      {
        name: "carousel-item3",
        checked: false,
        next: "none",
        buttonlabel: "LET'S GET STARTED!",
        value:
          "https://buriblob.sgp1.cdn.digitaloceanspaces.com/dashboard-assets/Frame%20193.png",
        // htmlString:`<p class="font-normal text-base">Finally, you can add things to your blocks with the ____ button.</p>`
      },
    ];
    // this.htmlString = this.carouselItems[0].htmlString
    this.buttonlabel = this.carouselItems[0].buttonlabel;
  }

  switchCarousel() {
    let item = this.carouselItems.find(
      (Item) => Item.name === this.radioSelected
    );
    if (item.next === "none") {
      this.dialogRef?.close();
    } else {
      (document.getElementById(item.next) as HTMLElement).click();
    }
  }

  onItemChanged() {
    console.log("changing " + this.radioSelected);
    let item = this.carouselItems.find(
      (Item) => Item.name === this.radioSelected
    );
    // this.htmlString =  item.htmlString
    this.buttonlabel = item.buttonlabel;
  }

  clickNext() {
    this.initialModal = false;
  }
}
