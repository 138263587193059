import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { MatSidenavModule } from "@angular/material/sidenav";

import { CoursesRoutingModule } from "./courses-routing.module";
import { CoursesComponent } from "./courses.component";
import { CourseListComponent } from "./course-list/course-list.component";
import { CourseEditContentComponent } from "./course-content-edit/course-content-edit.component";
import { RouterModule } from "@angular/router";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { AppCommonModule } from "../app-common-module/app-common-module.module";
import { CourseBatchesComponent } from "./course-batches/course-batches.component";
import { CourseBatchesCreateComponent } from "./course-batches-create/course-batches-create.component";
import { CourseStudentStatsComponent } from "./course-student-stats/course-student-stats.component";
import { CourseAnalyticsIndexComponent } from "./course-analytics-index/course-analytics-index.component";
import { CourseBatchStatsComponent } from "./course-batch-stats/course-batch-stats.component";
import { CourseBatchProfileComponent } from "./course-batch-profile/course-batch-profile.component";
import { CourseBatchEditComponent } from "./course-batch-edit/course-batch-edit.component";
import { CourseBatchPublishComponent } from "./course-batch-publish/course-batch-publish.component";
import { CourseBatchEnrollmentsComponent } from "./course-batch-enrollments/course-batch-enrollments.component";
import { GroupUserSelectorComponent } from "./group-user-selector/group-user-selector.component";
import { CourseEditContentBlockListComponent } from "./components/course-content-edit-block-list/course-content-edit-block-list.component";
import { CourseBlockPrerequisitesSelectComponent } from "./course-block-prerequisites-select/course-block-prerequisites-select.component";
import { CourseStudentResultComponent } from "./course-student-result/course-student-result.component";
import { CourseBlockCertificationDetailsComponent } from "./course-block-certification-details/course-block-certification-details.component";
import { CourseBatchEnrollmentsIndexComponent } from "./course-batch-enrollments-index/course-batch-enrollments-index.component";
import { CourseBatchEnrollmentsEmailListComponent } from "./course-batch-enrollments-email-list/course-batch-enrollments-email-list.component";
import { CourseSectionEditComponent } from "./course-section-edit/course-section-edit.component";
import { CourseBlockEditComponent } from "./course-block-edit/course-block-edit.component";
import { CourseBatchPermissionsComponent } from "./course-batch-permissions/course-batch-permissions.component";
import { CourseBatchPermissionsAddGroupComponent } from "./course-batch-permissions-add-group/course-batch-permissions-add-group.component";
import { CoursePermissionsComponent } from "./course-permissions/course-permissions.component";
import { CoursePermissionsAddGroupComponent } from "./course-permissions-add-group/course-permissions-add-group.component";
import { CourseBatchEnrollmentsBulkComponent } from "./course-batch-enrollments-bulk/course-batch-enrollments-bulk.component";
import { CourseCertificationComponent } from "./course-certification/course-certification.component";
import { CoursePermissionsEditGroupComponent } from "./course-permissions-edit-group/course-permissions-edit-group.component";
import { CourseBatchPermissionsEditGroupComponent } from "./course-batch-permissions-edit-group/course-batch-permissions-edit-group.component";
import { CourseBatchEnrollmentsSignuplinkComponent } from "./course-batch-enrollments-signuplink/course-batch-enrollments-signuplink.component";
import { CoursePaymentsComponent } from "./course-payments/course-payments.component";
import { CourseCertificateTemplateEditComponent } from "./course-certificate-template-edit/course-certificate-template-edit.component";
import { CourseBatchEnrollmentsListComponent } from "./course-batch-enrollments-list/course-batch-enrollments-list.component";
import { CourseActivityLogsComponent } from "./course-activity-logs/course-activity-logs.component";
import { CourseBatchEnrollmentsSelfEnrollComponent } from "./course-batch-enrollments-self-enroll/course-batch-enrollments-self-enroll.component";
import { CourseEmailNotificationsComponent } from "./course-email-notifications/course-email-notifications.component";
import { CourseUpdatePrecautionModalComponent } from "./components/course-update-precaution-modal/course-update-precaution-modal.component";
import { MatDialogModule } from "@angular/material/dialog";
import { CourseAppearanceComponent } from "./course-appearance/course-appearance.component";
import { CourseBatchEnrollmentsSelfEnrollAddGroupComponent } from "./course-batch-enrollments-self-enroll-add-group/course-batch-enrollments-self-enroll-add-group.component";
import { CourseBatchEnrollmentPeriodComponent } from "./course-batch-enrollment-period/course-batch-enrollment-period.component";
import { CourseSetListComponent } from "./components/course-set-list/course-set-list.component";
import { CourseTemplateListComponent } from "./course-template-list/course-template-list.component";
import { MarkdownModule } from "ngx-markdown";
import { CourseProfileComponent } from './course-profile/course-profile.component';
import { CourseProfileEditComponent } from './course-profile-edit/course-profile-edit.component';
import { CourseBlockCompletionReportsComponent } from './course-block-completion-reports/course-block-completion-reports.component';

@NgModule({
  declarations: [
    CoursesComponent,
    CourseListComponent,
    CourseEditContentComponent,
    CourseBatchesComponent,
    CourseBatchesCreateComponent,
    CourseStudentStatsComponent,
    CourseAnalyticsIndexComponent,
    CourseBatchStatsComponent,
    CourseBatchProfileComponent,
    CourseBatchEditComponent,
    CourseBatchPublishComponent,
    CourseBatchEnrollmentsComponent,
    GroupUserSelectorComponent,
    CourseEditContentBlockListComponent,
    CourseBlockPrerequisitesSelectComponent,
    CourseStudentResultComponent,
    CourseBlockCertificationDetailsComponent,
    CourseBatchEnrollmentsIndexComponent,
    CourseBatchEnrollmentsEmailListComponent,
    CourseSectionEditComponent,
    CourseBlockEditComponent,
    CourseBatchPermissionsComponent,
    CourseBatchPermissionsAddGroupComponent,
    CoursePermissionsComponent,
    CoursePermissionsAddGroupComponent,
    CourseBatchEnrollmentsBulkComponent,
    CourseCertificationComponent,
    CoursePermissionsEditGroupComponent,
    CourseBatchPermissionsEditGroupComponent,
    CourseBatchEnrollmentsSignuplinkComponent,
    CoursePaymentsComponent,
    CourseCertificateTemplateEditComponent,
    CourseBatchEnrollmentsListComponent,
    CourseActivityLogsComponent,
    CourseBatchEnrollmentsSelfEnrollComponent,
    CourseEmailNotificationsComponent,
    CourseUpdatePrecautionModalComponent,
    CourseAppearanceComponent,
    CourseBatchEnrollmentsSelfEnrollAddGroupComponent,
    CourseBatchEnrollmentPeriodComponent,
    CourseSetListComponent,
    CourseTemplateListComponent,
    CourseProfileComponent,
    CourseProfileEditComponent,
    CourseBlockCompletionReportsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    DragDropModule,
    ClipboardModule,
    ReactiveFormsModule,
    CoursesRoutingModule,
    MatDialogModule,
    MatSidenavModule,
    AppCommonModule,
    MarkdownModule,
  ],
  exports: [RouterModule],
})
export class CoursesModule {}
