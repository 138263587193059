import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { ModalService } from "src/app/services/modal.service";
import {
  BurislidesModalComponent,
  SlidesBlockItem,
} from "../burislides-modal/burislides-modal.component";
import {
  UploadsService,
  V1ExamChoice,
  V1ExamItem,
} from "src/app/sinigangnababoywithgabi";
import { SpreadView } from "../../misc/burislides-edit/burislides-edit.component";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { tap } from "rxjs";

@Component({
  selector: "app-burislides-block-preview",
  templateUrl: "./burislides-block-preview.component.html",
  styleUrls: ["./burislides-block-preview.component.scss"],
})
export class BurislidesBlockPreviewComponent implements OnInit {
  @Input() sectionUuid: string;
  @Input() currentSlide: SpreadView;
  @Input() slideFile: File;
  @Input() editMode: boolean;
  @Input() previewMode: boolean;
  @Input() loadingInput: boolean;
  @Input() examItem: V1ExamItem;
  @Input() examChoices: V1ExamChoice[];
  @Input() navigated: boolean;
  @Input() generalAppearance: any;
  @Output() newSlide = new EventEmitter<SlidesBlockItem>();
  @Output() files = new EventEmitter<any>();
  @Output() upload = new EventEmitter<any>();
  @Output() currentActivity: EventEmitter<V1ExamItem> =
    new EventEmitter<V1ExamItem>();
  @Output() isLoading = new EventEmitter<boolean>();
  slidesIndex: number = 0;
  markdown: string = "";
  newResource;
  loading: boolean;
  feedback: boolean;
  @ViewChild("fileInput", { static: false }) input: ElementRef;
  constructor(
    private modalService: ModalService,
    private uploadsService: UploadsService,
    private cd: ChangeDetectorRef
  ) {}
  ngOnChanges(changes: SimpleChanges) {
    if (this.currentSlide && changes.currentSlide) {
      if (
        changes.currentSlide.currentValue !== changes.currentSlide.previousValue
      ) {
        this.slidesIndex = 0;
      }
    }
    if (changes.previewMode)
      if (
        changes.previewMode.currentValue !== changes.previewMode.previousValue
      ) {
        this.setFeedback();
      }
    if (changes.navigated)
      if (changes.navigated.currentValue !== changes.navigated.previousValue) {
        this.setFeedback();
      }
  }
  ngOnInit(): void {}
  async openAddBlockModal() {
    let blockItem: SlidesBlockItem = await this.modalService.openModal(
      BurislidesModalComponent,
      { panelClass: "overflow-y-auto" }
    );
    if (blockItem) {
      if (blockItem.type === "resource") {
        //Set input value for preview slide
      }
      if (blockItem.type === "question") {
        //Set input value for preview slide
      }
      this.newResource = blockItem;
      this.newSlide.emit(blockItem);
    }
  }
  async processFiles(files) {
    this.files.emit(files);
  }
  outputIsLoading(loading) {
    this.isLoading.emit(loading);
  }
  onUpload(event) {
    this.currentSlide.spread.uri = event.uri;
    this.cd.detectChanges();
  }
  onUploadDL(event) {
    this.currentSlide.spread.imageUri = event.uri;
  }
  markdownOut(text) {
    this.currentSlide.spread.text = text;
    this.upload.emit();
  }
  linkOut(link) {
    this.currentSlide.spread.uri = link;
    this.upload.emit();
  }

  itemCreateOut(itemUuid) {
    this.currentSlide.spread.examItemUuid = itemUuid;
    this.upload.emit();
  }

  activityItem(item) {
    this.currentActivity.emit(item);
  }
  downloadLinkOut(link) {
    this.currentSlide.spread.uri = link;
  }
  downloadTextOut(description) {
    this.currentSlide.spread.text = description;
  }
  downloadTitleOut(title) {
    this.currentSlide.spread.title = title;
  }

  activityGroupOut(activityGroupSpread) {
    Object.assign(this.currentSlide.spread, activityGroupSpread);
  }
  onSave() {
    this.upload.emit();
  }

  setSlideIndex(action) {
    if (action === "back") {
      if (this.slidesIndex > 0) {
        this.slidesIndex = this.slidesIndex - 1;
      } else {
        this.slidesIndex = this.slidesIndex;
      }
    } else if (action === "next") {
      if (this.slidesIndex + 1 < (this.currentSlide.spread.uris.length || 1)) {
        this.slidesIndex = this.slidesIndex + 1;
      } else {
        this.slidesIndex = this.slidesIndex;
      }
    }
  }
  processSlideImages(files) {
    this.loading = true;
    this.uploadsService
      .uploadFiles(files)
      .pipe(
        tap((result) => {
          if (
            this.currentSlide.spread.uris &&
            this.currentSlide.spread.uris.length > 0
          ) {
            this.currentSlide.spread.uris = [
              ...this.currentSlide.spread.uris,
              ...result.map((item) => item.uri),
            ];
          } else {
            this.currentSlide.spread.uris = result.map((item) => item.uri);
          }
          this.currentSlide.spread.viewMode = "carousel";
        })
      )
      .subscribe((result) => {
        this.loading = false;
      });
  }
  changeViewMode(event) {
    this.currentSlide.spread.viewMode = event.checked ? "scroll" : "carousel";
  }
  async deleteSlideImage(index) {
    let result = await this.modalService.confirm(
      "Are you sure you want to delete this spread?"
    );

    if (result) {
      this.currentSlide.spread.uris.splice(index, 1);
    }
  }
  imageDrop(event: CdkDragDrop<any[]>) {
    moveItemInArray(
      this.currentSlide.spread.uris,
      event.previousIndex,
      event.currentIndex
    );
  }
  getMediaUrl() {
    if (this.currentSlide.spread.spreadType === "download-link")
      return `url(${this.currentSlide.spread.imageUri})`;
    return "";
  }
  getFeedback() {
    this.feedback = true;
    this.cd.detectChanges();
  }
  setFeedback() {
    this.feedback = false;
    this.cd.detectChanges();
  }
}
