import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { firstValueFrom } from "rxjs";
import { ColorHelperService } from "src/app/services/color-helper.service";
import { DashboardService } from "src/app/services/dashboard.service";
import { ModalService } from "src/app/services/modal.service";
import {
  CastAppearance,
  CastsService,
  V2Block,
  V2CastsService,
} from "src/app/sinigangnababoywithgabi";

interface NavigationItemAppearance {
  base: CastAppearance;
  item: CastAppearance;
  itemActive: CastAppearance;
}

@Component({
  selector: "app-casteditor-block-navigation-settings",
  templateUrl: "./casteditor-block-navigation-settings.component.html",
  styles: [],
})
export class CasteditorBlockNavigationSettingsComponent implements OnInit {
  formGroup: UntypedFormGroup;
  cast: any;
  navigationBlock: V2Block;
  screens = [];
  constructor(
    private v2CastService: V2CastsService,
    private castService: CastsService,
    private formBuilder: UntypedFormBuilder,
    private location: Location,
    private dashboardService: DashboardService,
    private colorHelperService: ColorHelperService,
    private modalService: ModalService
  ) {}

  async ngOnInit() {
    let cast = await this.dashboardService.getCast().toPromise();
    this.cast = cast;
    let navigationBlock = await this.v2CastService
      .blockGet(cast.data.attributes.initialBlockId)
      .toPromise();

    this.navigationBlock = navigationBlock;
    this.screens = navigationBlock.data?.attributes?.items;
    let appearance: NavigationItemAppearance =
      navigationBlock.data.attributes.properties.appearance;

    this.formGroup = this.formBuilder.group({
      blockId: [navigationBlock.data.id],
      searchSlug: [this.cast.data.attributes.searchSlug || null],
      navbarImageUri: [cast.data.attributes.castIcon?.web?.nav],
      castTitle: [cast.data.attributes.title],

      navFont: [appearance?.base?.fontFamily?.[0]],
      navBackgroundColor: [
        this.colorHelperService.constructRGBAFromCastColor(
          appearance?.base?.backgroundColor
        ) || "rgba(255,255,255,1)",
      ],
      navForegroundColor: [
        this.colorHelperService.constructRGBAFromCastColor(
          appearance?.base?.foregroundColor
        ) || "rgba(0,0,0,1)",
      ],

      itemFont: [appearance?.item?.fontFamily[0]],
      itemBackgroundColor: [
        this.colorHelperService.constructRGBAFromCastColor(
          appearance?.item?.backgroundColor
        ) || "rgba(255,255,255,0)",
      ],
      itemForegroundColor: [
        this.colorHelperService.constructRGBAFromCastColor(
          appearance?.item?.foregroundColor
        ) || "rgba(117,43,143,1)",
      ],

      itemActiveBackgroundColor: [
        this.colorHelperService.constructRGBAFromCastColor(
          appearance?.itemActive?.backgroundColor
        ) || "rgba(117,43,143,1)",
      ],
      itemActiveForegroundColor: [
        this.colorHelperService.constructRGBAFromCastColor(
          appearance?.itemActive?.foregroundColor
        ) || "rgba(255,255,255,1)",
      ],
    });
  }

  async onSave() {
    let appearance: NavigationItemAppearance = {
      base: {
        fontFamily: this.formGroup.value.navFont
          ? [this.formGroup.value.navFont]
          : [],
        foregroundColor: this.colorHelperService.constructCastColor(
          this.formGroup.value.navForegroundColor
        ),
        backgroundColor: this.colorHelperService.constructCastColor(
          this.formGroup.value.navBackgroundColor
        ),
        backgroundImage: null,
      },
      item: {
        // Same with item_active for consistency
        fontFamily: this.formGroup.value.itemFont
          ? [this.formGroup.value.itemFont]
          : [],
        foregroundColor: this.colorHelperService.constructCastColor(
          this.formGroup.value.itemForegroundColor
        ),
        backgroundColor: this.colorHelperService.constructCastColor(
          this.formGroup.value.itemBackgroundColor
        ),
        backgroundImage: null,
      },
      itemActive: {
        // Same with item for consistency
        fontFamily: this.formGroup.value.itemFont
          ? [this.formGroup.value.itemFont]
          : [],
        foregroundColor: this.colorHelperService.constructCastColor(
          this.formGroup.value.itemActiveForegroundColor
        ),
        backgroundColor: this.colorHelperService.constructCastColor(
          this.formGroup.value.itemActiveBackgroundColor
        ),
        backgroundImage: null,
      },
    };

    await firstValueFrom(
      this.castService.blockUpdate("0", "0", this.formGroup.value.blockId, {
        block: {
          properties: {
            ...this.navigationBlock.data.attributes.properties,
            appearance,
          },
        },
      })
    );

    await firstValueFrom(
      this.castService.castUpdate(this.cast.data.id + "", {
        cast: {
          title: this.cast.data.attributes.title,
          properties: {
            ...this.cast.data.attributes,
            searchSlug: this.formGroup.value.searchSlug,
          },
        },
      })
    );
    this.location.back();
  }

  async resetToDefault() {
    let result = await this.modalService.confirm(
      "Are you sure you want to reset settings to default?"
    );

    if (result) {
      this.formGroup.reset({
        blockId: this.formGroup.value.blockId,

        navbarImageUri: this.formGroup.value.navbarImageUri,
        castTitle: this.formGroup.value.castTitle,

        navFont: null,
        navBackgroundColor: "rgba(255,255,255,1)",

        navForegroundColor: "rgba(0,0,0,1)",

        itemFont: null,
        itemBackgroundColor: "rgba(255,255,255,0)",
        itemForegroundColor: "rgba(117,43,143,1)",
        itemActiveBackgroundColor: "rgba(117,43,143,1)",
        itemActiveForegroundColor: "rgba(255,255,255,1)",
      });
    }
  }
}
