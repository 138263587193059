<app-back-title-layout
  layoutTitle="Toggle Permissions"
  [loading]="!rolePermissionFormGroups"
>
  <ng-container *ngIf="rolePermissionFormGroups">
    <ng-container *ngFor="let rpfg of rolePermissionFormGroups">
      <div class="my-5" [formGroup]="rpfg">
        <label class="block">
          <app-checkbox
            class="text-primary-dark"
            formControlName="isActive"
          ></app-checkbox>

          <span class="font-bold">
            {{ rpfg.value.roleDescription || rpfg.value.roleName }}
          </span>
        </label>

        <div class="ml-5 text-sm font-semibold" *ngIf="rpfg.value.isActive">
          <ng-container
            *ngFor="let pfg of rpfg.controls.permissionFormGroups.controls"
          >
            <div [formGroup]="pfg">
              <label>
                <app-checkbox
                  class="text-primary"
                  formControlName="isActive"
                ></app-checkbox>
                <span>
                  {{
                    pfg.value.permissionDescription || pfg.value.permissionName
                  }}
                </span>
              </label>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </ng-container>
</app-back-title-layout>
