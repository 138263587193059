import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CoursesComponent } from "./courses.component";
import { CourseEditContentComponent } from "./course-content-edit/course-content-edit.component";
import { CourseBatchesComponent } from "./course-batches/course-batches.component";
import { CourseBatchesCreateComponent } from "./course-batches-create/course-batches-create.component";
import { CourseStudentStatsComponent } from "./course-student-stats/course-student-stats.component";
import { CourseAnalyticsIndexComponent } from "./course-analytics-index/course-analytics-index.component";
import { CourseListComponent } from "./course-list/course-list.component";
import { CourseBatchStatsComponent } from "./course-batch-stats/course-batch-stats.component";
import { CourseBatchProfileComponent } from "./course-batch-profile/course-batch-profile.component";
import { CourseBatchEditComponent } from "./course-batch-edit/course-batch-edit.component";
import { CourseBatchPublishComponent } from "./course-batch-publish/course-batch-publish.component";
import { CourseBatchEnrollmentsComponent } from "./course-batch-enrollments/course-batch-enrollments.component";
import { CourseBlockPrerequisitesSelectComponent } from "./course-block-prerequisites-select/course-block-prerequisites-select.component";
import { CourseStudentResultComponent } from "./course-student-result/course-student-result.component";
import { CourseBlockCertificationDetailsComponent } from "./course-block-certification-details/course-block-certification-details.component";
import { CourseBatchEnrollmentsIndexComponent } from "./course-batch-enrollments-index/course-batch-enrollments-index.component";
import { CourseBatchEnrollmentsEmailListComponent } from "./course-batch-enrollments-email-list/course-batch-enrollments-email-list.component";
import { AuthGuard } from "../app-common-module/guards/auth.guard";
import { CourseSectionEditComponent } from "./course-section-edit/course-section-edit.component";
import { CourseBlockEditComponent } from "./course-block-edit/course-block-edit.component";
import { CourseBatchPermissionsComponent } from "./course-batch-permissions/course-batch-permissions.component";
import { CourseBatchPermissionsAddGroupComponent } from "./course-batch-permissions-add-group/course-batch-permissions-add-group.component";
import { CoursePermissionsComponent } from "./course-permissions/course-permissions.component";
import { CoursePermissionsAddGroupComponent } from "./course-permissions-add-group/course-permissions-add-group.component";
import { CourseBatchEnrollmentsBulkComponent } from "./course-batch-enrollments-bulk/course-batch-enrollments-bulk.component";
import { CourseCertificationComponent } from "./course-certification/course-certification.component";
import { CoursePermissionsEditGroupComponent } from "./course-permissions-edit-group/course-permissions-edit-group.component";
import { CourseBatchPermissionsEditGroupComponent } from "./course-batch-permissions-edit-group/course-batch-permissions-edit-group.component";
import { CourseBatchEnrollmentsSignuplinkComponent } from "./course-batch-enrollments-signuplink/course-batch-enrollments-signuplink.component";
import { CoursePaymentsComponent } from "./course-payments/course-payments.component";
import { CourseCertificateTemplateEditComponent } from "./course-certificate-template-edit/course-certificate-template-edit.component";
import { CourseBatchEnrollmentsListComponent } from "./course-batch-enrollments-list/course-batch-enrollments-list.component";
import { CourseActivityLogsComponent } from "./course-activity-logs/course-activity-logs.component";
import { CourseBatchEnrollmentsSelfEnrollComponent } from "./course-batch-enrollments-self-enroll/course-batch-enrollments-self-enroll.component";
import { CourseEmailNotificationsComponent } from "./course-email-notifications/course-email-notifications.component";
import { CourseAppearanceComponent } from "./course-appearance/course-appearance.component";
import { CourseBatchEnrollmentsSelfEnrollAddGroupComponent } from "./course-batch-enrollments-self-enroll-add-group/course-batch-enrollments-self-enroll-add-group.component";
import { CourseBatchEnrollmentPeriodComponent } from "./course-batch-enrollment-period/course-batch-enrollment-period.component";
import { RouteWithData } from "src/extensions/route-with-data";
import { CourseTemplateListComponent } from "./course-template-list/course-template-list.component";
import { CourseProfileComponent } from "./course-profile/course-profile.component";
import { CourseProfileEditComponent } from "./course-profile-edit/course-profile-edit.component";
import { CourseBlockCompletionReportsComponent } from "./course-block-completion-reports/course-block-completion-reports.component";

const routes: RouteWithData[] = [
  {
    path: "_courses",
    // path: "",
    component: CoursesComponent,
    canActivate: [AuthGuard],
    data: {
      hideBackTitleDiagonalDesign: false,
      layoutTitle: "Courses",
    },
    children: [
      {
        path: "",
        component: CourseListComponent,
      },
      {
        path: "profile",
        data: {
          hideBackTitleDiagonalDesign: true,
          layoutTitle: "Course Profile",
          preserveQueryParamFields: ["resource_uuid", "course_uuid"],
        },
        children: [
          {
            path: "",
            component: CourseProfileComponent,
          },

          {
            path: "edit",
            component: CourseProfileEditComponent,
            data: {
              hideBackTitleDiagonalDesign: true,
              layoutTitle: "Edit Course Details",
              preserveQueryParamFields: ["resource_uuid", "course_uuid"],
            },
          },

          {
            path: "activity-logs",
            component: CourseActivityLogsComponent,
            data: {
              hideBackTitleDiagonalDesign: true,
              layoutTitle: "Course Activity Logs",
              preserveQueryParamFields: ["resource_uuid", "course_uuid"],
            },
          },

          {
            path: "batch-profile",
            data: {
              hideBackTitleDiagonalDesign: true,
              layoutTitle: "Batch Profile",
              preserveQueryParamFields: ["course_uuid", "schedule_uuid"],
            },
            children: [
              {
                path: "",
                component: CourseBatchStatsComponent,
              },
              {
                path: "enrollments",
                children: [
                  {
                    path: "new",
                    data: {
                      hideBackTitleDiagonalDesign: true,
                      layoutTitle: "Select Method",
                      preserveQueryParamFields: [
                        "course_uuid",
                        "schedule_uuid",
                      ],
                    },
                    children: [
                      {
                        path: "",
                        redirectTo: "index",
                        pathMatch: "full",
                      },
                      {
                        path: "index",
                        component: CourseBatchEnrollmentsIndexComponent,
                      },
                      {
                        path: "self-enroll",
                        data: {
                          hideBackTitleDiagonalDesign: true,
                          layoutTitle: "Self Enroll",
                          preserveQueryParamFields: [
                            "course_uuid",
                            "schedule_uuid",
                          ],
                        },
                        children: [
                          {
                            path: "",
                            component:
                              CourseBatchEnrollmentsSelfEnrollComponent,
                          },
                          {
                            path: "add-group",
                            component:
                              CourseBatchEnrollmentsSelfEnrollAddGroupComponent,
                            data: {
                              hideBackTitleDiagonalDesign: true,
                              layoutTitle: "Add group to self enroll",
                              preserveQueryParamFields: [
                                "course_uuid",
                                "schedule_uuid",
                              ],
                            },
                          },
                        ],
                      },
                      {
                        path: "groups",
                        component: CourseBatchEnrollmentsComponent,
                        data: {
                          hideBackTitleDiagonalDesign: true,
                          layoutTitle: "Select Users",
                          preserveQueryParamFields: [
                            "course_uuid",
                            "schedule_uuid",
                          ],
                        },
                      },
                      {
                        path: "bulk",
                        component: CourseBatchEnrollmentsBulkComponent,
                        data: {
                          hideBackTitleDiagonalDesign: true,
                          layoutTitle: "Upload CSV",
                          preserveQueryParamFields: [
                            "course_uuid",
                            "schedule_uuid",
                          ],
                        },
                      },
                      {
                        path: "signuplink",
                        component: CourseBatchEnrollmentsSignuplinkComponent,
                        data: {
                          hideBackTitleDiagonalDesign: true,
                          layoutTitle: "Enrollment Link",
                          preserveQueryParamFields: [
                            "course_uuid",
                            "schedule_uuid",
                          ],
                        },
                      },
                      {
                        path: "email-list",
                        component: CourseBatchEnrollmentsEmailListComponent,
                        data: {
                          hideBackTitleDiagonalDesign: true,
                          layoutTitle: "Email List",
                          preserveQueryParamFields: [
                            "course_uuid",
                            "schedule_uuid",
                          ],
                        },
                      },
                    ],
                  },
                ],
              },

              {
                path: "student-results",
                component: CourseStudentResultComponent,
                data: {
                  hideBackTitleDiagonalDesign: true,
                  layoutTitle: "Student Profile",
                  preserveQueryParamFields: [
                    "course_uuid",
                    "schedule_uuid",
                    "user_uuid",
                  ],
                },
              },
            ],
          },

          {
            path: "batches",
            data: {
              hideBackTitleDiagonalDesign: true,
              layoutTitle: "Course Batches",
              preserveQueryParamFields: ["course_uuid"],
            },
            children: [
              {
                path: "create-batches",
                component: CourseBatchesCreateComponent,
              },
              {
                path: "profile",
                data: {
                  hideBackTitleDiagonalDesign: true,
                  layoutTitle: "Batch Profile",
                  preserveQueryParamFields: ["course_uuid", "schedule_uuid"],
                },
                children: [
                  {
                    path: "",
                    component: CourseBatchProfileComponent,
                  },
                  {
                    path: "edit",
                    component: CourseBatchEditComponent,
                    data: {
                      hideBackTitleDiagonalDesign: true,
                      layoutTitle: "Edit Batch",
                      preserveQueryParamFields: [
                        "course_uuid",
                        "schedule_uuid",
                      ],
                    },
                  },
                  {
                    path: "publish",
                    component: CourseBatchPublishComponent,
                    data: {
                      hideBackTitleDiagonalDesign: true,
                      layoutTitle: "Batch Access Period",
                      preserveQueryParamFields: [
                        "course_uuid",
                        "schedule_uuid",
                      ],
                    },
                  },
                  {
                    path: "enrollment-period",
                    component: CourseBatchEnrollmentPeriodComponent,
                    data: {
                      hideBackTitleDiagonalDesign: true,
                      layoutTitle: "Batch Enrollment Period",
                      preserveQueryParamFields: [
                        "course_uuid",
                        "schedule_uuid",
                      ],
                    },
                  },
                  {
                    path: "permissions",
                    data: {
                      hideBackTitleDiagonalDesign: true,
                      layoutTitle: "Batch Group Permissions",
                      preserveQueryParamFields: [
                        "course_uuid",
                        "schedule_uuid",
                      ],
                    },
                    children: [
                      {
                        path: "",
                        component: CourseBatchPermissionsComponent,
                      },
                      {
                        path: "add-group",
                        component: CourseBatchPermissionsAddGroupComponent,
                        data: {
                          hideBackTitleDiagonalDesign: true,
                          layoutTitle: "Batch Permissions - Add Group",
                          preserveQueryParamFields: [
                            "course_uuid",
                            "schedule_uuid",
                          ],
                        },
                      },
                      {
                        path: "edit-group",
                        component: CourseBatchPermissionsEditGroupComponent,
                        data: {
                          hideBackTitleDiagonalDesign: true,
                          layoutTitle: "Assign Batch Permissions",
                          preserveQueryParamFields: [
                            "course_uuid",
                            "schedule_uuid",
                          ],
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "content-edit",
        data: {
          hideBackTitleDiagonalDesign: true,
          layoutTitle: "Course Content Editor",
          preserveQueryParamFields: ["resource_uuid", "course_uuid"],
        },
        children: [
          {
            path: "",
            component: CourseEditContentComponent,
          },
          {
            path: "block-edit",
            data: {
              hideBackTitleDiagonalDesign: true,
              layoutTitle: "Update Course Block",
              preserveQueryParamFields: [
                "resource_uuid",
                "course_uuid",
                "block_uuid",
              ],
            },
            children: [
              {
                path: "",
                component: CourseBlockEditComponent,
              },
              {
                path: "block-prerequisites-select",
                component: CourseBlockPrerequisitesSelectComponent,
                data: {
                  hideBackTitleDiagonalDesign: true,
                  layoutTitle: "Update Prerequisites",
                  preserveQueryParamFields: [
                    "resource_uuid",
                    "course_uuid",
                    "block_uuid",
                  ],
                },
              },
            ],
          },

          {
            path: "block-prerequisites-select",
            redirectTo: "block-edit/block-prerequisites-select",
            pathMatch: "full",
          },
        ],
      },

      // For backwards compatibility
      {
        path: "block-edit",
        redirectTo: "content-edit/block-edit",
        pathMatch: "full",
      },

      {
        path: "section-edit",
        component: CourseSectionEditComponent,
      },
      {
        path: "block-certification-details",
        component: CourseBlockCertificationDetailsComponent,
      },
      {
        path: "appearance",
        component: CourseAppearanceComponent,
        data: {
          hideBackTitleDiagonalDesign: true,
          layoutTitle: "Course Appearance",
          preserveQueryParamFields: ["resource_uuid", "course_uuid"],
        },
      },
      {
        path: "block-completion-reports",
        component: CourseBlockCompletionReportsComponent,
        data: {
          hideBackTitleDiagonalDesign: true,
          layoutTitle: "Course Reports",
          preserveQueryParamFields: ["resource_uuid", "course_uuid"],
        },
      },
      {
        path: "permissions",
        data: {
          hideBackTitleDiagonalDesign: true,
          layoutTitle: "Course Permissions",
          preserveQueryParamFields: ["resource_uuid", "course_uuid"],
        },
        children: [
          {
            path: "",
            component: CoursePermissionsComponent,
          },
          {
            path: "add-group",
            component: CoursePermissionsAddGroupComponent,
            data: {
              hideBackTitleDiagonalDesign: true,
              layoutTitle: "Add Group",
              preserveQueryParamFields: ["resource_uuid", "course_uuid"],
            },
          },
          {
            path: "edit-group",
            component: CoursePermissionsEditGroupComponent,
            data: {
              hideBackTitleDiagonalDesign: true,
              layoutTitle: "Assign Course Permissions",
              preserveQueryParamFields: ["resource_uuid", "course_uuid"],
            },
          },
        ],
      },
      {
        path: "email-notifications",
        component: CourseEmailNotificationsComponent,
        data: {
          hideBackTitleDiagonalDesign: true,
          layoutTitle: "Email notification",
          preserveQueryParamFields: ["resource_uuid", "course_uuid"],
        },
      },
      {
        path: "certification",
        data: {
          hideBackTitleDiagonalDesign: true,
          layoutTitle: "Certification",
          preserveQueryParamFields: ["resource_uuid", "course_uuid"],
        },
        children: [
          {
            path: "",
            component: CourseCertificationComponent,
          },
          {
            path: "certificate-template",
            children: [
              {
                path: "edit",
                component: CourseCertificateTemplateEditComponent,
                data: {
                  hideBackTitleDiagonalDesign: true,
                  layoutTitle: "Edit Certificate",
                  preserveQueryParamFields: ["resource_uuid", "course_uuid"],
                },
              },
            ],
          },
        ],
      },
      {
        path: "payments",
        component: CoursePaymentsComponent,
        data: {
          hideBackTitleDiagonalDesign: true,
          layoutTitle: "Payments (Disabled)",
        },
      },

      // DEPRECATED
      {
        path: "batches",
        data: {
          hideBackTitleDiagonalDesign: true,
          layoutTitle: "Course Batches",
          preserveQueryParamFields: ["course_uuid"],
        },
        children: [
          {
            path: "",
            component: CourseBatchesComponent,
          },
          {
            path: "create-batches",
            component: CourseBatchesCreateComponent,
          },
          {
            path: "profile",
            data: {
              hideBackTitleDiagonalDesign: true,
              layoutTitle: "Batch Profile",
              preserveQueryParamFields: ["course_uuid", "schedule_uuid"],
            },
            children: [
              {
                path: "",
                component: CourseBatchProfileComponent,
              },
              {
                path: "edit",
                component: CourseBatchEditComponent,
                data: {
                  hideBackTitleDiagonalDesign: true,
                  layoutTitle: "Edit Batch",
                  preserveQueryParamFields: ["course_uuid", "schedule_uuid"],
                },
              },
              {
                path: "publish",
                component: CourseBatchPublishComponent,
                data: {
                  hideBackTitleDiagonalDesign: true,
                  layoutTitle: "Batch Access Period",
                  preserveQueryParamFields: ["course_uuid", "schedule_uuid"],
                },
              },
              {
                path: "enrollment-period",
                component: CourseBatchEnrollmentPeriodComponent,
                data: {
                  hideBackTitleDiagonalDesign: true,
                  layoutTitle: "Batch Enrollment Period",
                  preserveQueryParamFields: ["course_uuid", "schedule_uuid"],
                },
              },
              {
                path: "permissions",
                data: {
                  hideBackTitleDiagonalDesign: true,
                  layoutTitle: "Batch Group Permissions",
                  preserveQueryParamFields: ["course_uuid", "schedule_uuid"],
                },
                children: [
                  {
                    path: "",
                    component: CourseBatchPermissionsComponent,
                  },
                  {
                    path: "add-group",
                    component: CourseBatchPermissionsAddGroupComponent,
                    data: {
                      hideBackTitleDiagonalDesign: true,
                      layoutTitle: "Batch Permissions - Add Group",
                      preserveQueryParamFields: [
                        "course_uuid",
                        "schedule_uuid",
                      ],
                    },
                  },
                  {
                    path: "edit-group",
                    component: CourseBatchPermissionsEditGroupComponent,
                    data: {
                      hideBackTitleDiagonalDesign: true,
                      layoutTitle: "Assign Batch Permissions",
                      preserveQueryParamFields: [
                        "course_uuid",
                        "schedule_uuid",
                      ],
                    },
                  },
                ],
              },
            ],
          },

          // DEPRECATED
          {
            path: "enrollments",
            data: {
              hideBackTitleDiagonalDesign: true,
              layoutTitle: "Enrollments",
              preserveQueryParamFields: ["course_uuid", "schedule_uuid"],
            },
            children: [
              {
                path: "",
                redirectTo: "list",
                pathMatch: "full",
              },
              {
                path: "list",
                component: CourseBatchEnrollmentsListComponent,
              },
              {
                path: "new",
                data: {
                  hideBackTitleDiagonalDesign: true,
                  layoutTitle: "Select Method",
                  preserveQueryParamFields: ["course_uuid", "schedule_uuid"],
                },
                children: [
                  {
                    path: "",
                    redirectTo: "index",
                    pathMatch: "full",
                  },
                  {
                    path: "index",
                    component: CourseBatchEnrollmentsIndexComponent,
                  },
                  {
                    path: "self-enroll",
                    data: {
                      hideBackTitleDiagonalDesign: true,
                      layoutTitle: "Self Enroll",
                      preserveQueryParamFields: [
                        "course_uuid",
                        "schedule_uuid",
                      ],
                    },
                    children: [
                      {
                        path: "",
                        component: CourseBatchEnrollmentsSelfEnrollComponent,
                      },
                      {
                        path: "add-group",
                        component:
                          CourseBatchEnrollmentsSelfEnrollAddGroupComponent,
                        data: {
                          hideBackTitleDiagonalDesign: true,
                          layoutTitle: "Add group to self enroll",
                          preserveQueryParamFields: [
                            "course_uuid",
                            "schedule_uuid",
                          ],
                        },
                      },
                    ],
                  },
                  {
                    path: "groups",
                    component: CourseBatchEnrollmentsComponent,
                    data: {
                      hideBackTitleDiagonalDesign: true,
                      layoutTitle: "Select Users",
                      preserveQueryParamFields: [
                        "course_uuid",
                        "schedule_uuid",
                      ],
                    },
                  },
                  {
                    path: "bulk",
                    component: CourseBatchEnrollmentsBulkComponent,
                    data: {
                      hideBackTitleDiagonalDesign: true,
                      layoutTitle: "Upload CSV",
                      preserveQueryParamFields: [
                        "course_uuid",
                        "schedule_uuid",
                      ],
                    },
                  },
                  {
                    path: "signuplink",
                    component: CourseBatchEnrollmentsSignuplinkComponent,
                    data: {
                      hideBackTitleDiagonalDesign: true,
                      layoutTitle: "Enrollment Link",
                      preserveQueryParamFields: [
                        "course_uuid",
                        "schedule_uuid",
                      ],
                    },
                  },
                  {
                    path: "email-list",
                    component: CourseBatchEnrollmentsEmailListComponent,
                    data: {
                      hideBackTitleDiagonalDesign: true,
                      layoutTitle: "Email List",
                      preserveQueryParamFields: [
                        "course_uuid",
                        "schedule_uuid",
                      ],
                    },
                  },
                ],
              },
            ],
          },
          // For BACKWARDS COMPATIBILITY
          {
            path: "publish",
            redirectTo: "profile/publish",
          },
          {
            path: "edit",
            redirectTo: "profile/edit",
          },
        ],
      },
      {
        path: "analytics",
        children: [
          {
            path: "index",
            component: CourseAnalyticsIndexComponent,
            data: {
              hideBackTitleDiagonalDesign: true,
              layoutTitle: "Course Analytics",
              preserveQueryParamFields: ["course_uuid"],
            },
          },

          {
            path: "batches",
            data: {
              hideBackTitleDiagonalDesign: true,
              layoutTitle: "Student Performance",
              preserveQueryParamFields: ["course_uuid"],
            },
            children: [
              {
                path: "",
                component: CourseStudentStatsComponent,
              },
              {
                path: "batch-stats",
                component: CourseBatchStatsComponent,
                data: {
                  hideBackTitleDiagonalDesign: true,
                  layoutTitle: "Batch Performance",
                  preserveQueryParamFields: ["course_uuid", "schedule_uuid"],
                },
              },
            ],
          },

          // For backwards compatibility
          {
            path: "batch-stats",
            redirectTo: "batches/batch-stats",
            pathMatch: "full",
          },
        ],
      },
      {
        path: "template-list",
        children: [
          {
            path: "",
            component: CourseTemplateListComponent,
            data: {
              hideBackTitleDiagonalDesign: true,
              layoutTitle: "Cast Courses",
              // preserveQueryParamFields: ["course_uuid"],
            },
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CoursesRoutingModule {}
