import { Component, Input, OnInit } from "@angular/core";
import { CdkDragDrop } from "@angular/cdk/drag-drop";
import { ActivatedRoute } from "@angular/router";
import { Observable, switchMap } from "rxjs";
import { Resource, ResourcesService } from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-course-set-list",
  templateUrl: "./course-set-list.component.html",
  styles: [],
})
export class CourseSetListComponent implements OnInit {
  @Input()
  cdkDropListConnectedTo: string[];

  @Input()
  resources: Resource[];

  resourceListObs: Observable<Resource[]>;

  constructor(
    private route: ActivatedRoute,
    private resourcesService: ResourcesService
  ) {}

  async ngOnInit() {
    let { resource_collection_uuid: resourceCollectionUuid } =
      this.route.snapshot.queryParams;

    this.resourceListObs = this.route.queryParams.pipe(
      switchMap((params) => {
        return this.resourcesService.listResources(
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          true,
          params.q || null,
          resourceCollectionUuid
        );
      })
    );
  }

  onDrop(event: CdkDragDrop<any>) {}
}
