import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { FilterPipePipe } from "src/app/app-common-module/pipes/filter-pipe.pipe";
import {
  ResourcesService,
  Course,
  Resource,
} from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-course-list",
  templateUrl: "./course-list.component.html",
  styleUrls: ["./course-list.component.scss"],
})
export class CourseListComponent implements OnInit {
  courses: Course[];
  listResourcesObservable: Observable<Resource[]>;

  constructor(
    private resourcesService: ResourcesService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // this.resourcesService.listResources("course").subscribe((result) => {
    //   this.courses = result;
    // });

    let filterAll = new FilterPipePipe();

    this.listResourcesObservable = this.resourcesService
      .listResources(
        "course",
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        true
      )
      .pipe(
        map((items) => {
          return items.map((item) => ({
            uuid: item.uuid,
            name: item.name,
            type: item.type,
            coverUri: item.coverUri,
            flavorTextSpan: item.flavorTextSpan,
            createdAt: item["createdAt"],
          }));
        }),
        switchMap((items) => {
          return this.route.queryParams.pipe(
            map((params) => {
              return filterAll.transform(items, params["q"]);
            })
          );
        })
      );
  }
}
