import { Injectable } from "@angular/core";
import { CastColor } from "../sinigangnababoywithgabi";

@Injectable({
  providedIn: "root",
})
export class ColorHelperService {
  constructor() {}

  parseRGBA(rgba: string): {
    r: number;
    g: number;
    b: number;
    a: number;
  } {
    let result = /rgba?\(([0-9\.]+),([0-9\.]+),([0-9\.]+),?([0-9\.]+)?\)/.exec(
      rgba
    );

    if (!result) {
      throw new Error("Invalid RGB");
    }

    let [text, rRaw, gRaw, bRaw, aRaw] = result;

    let r = parseFloat(rRaw) ?? null;
    let g = parseFloat(gRaw) ?? null;
    let b = parseFloat(bRaw) ?? null;
    let a = parseFloat(aRaw ?? "1");

    return {
      r,
      g,
      b,
      a,
    };
  }

  constructRGBA(r: number, g: number, b: number, a = 1) {
    return `rgba(${r || 0},${g || 0},${b || 0},${a})`;
  }

  constructCastColor(rgbString: string): CastColor {
    let parsed = this.parseRGBA(rgbString);

    return {
      red: parsed.r,
      green: parsed.g,
      blue: parsed.b,
      alpha: parsed.a,
    };
  }

  constructRGBAFromCastColor(color: CastColor): string {
    if (!color) return null;

    return this.constructRGBA(color.red, color.green, color.blue, color.alpha);
  }
}
