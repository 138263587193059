import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Location } from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { firstValueFrom, of, Subject } from "rxjs";
import { delay, map, tap } from "rxjs/operators";
import { fadeInOut } from "src/app/app-common-module/animations/fade";
import { ModalService } from "src/app/services/modal.service";
import {
  V1ExamItem,
  Resource,
  ResourcesService,
  UploadsService,
  V1ExamsService,
} from "src/app/sinigangnababoywithgabi";
import {
  BurislidesModalComponent,
  QUESTIONS,
  RESOURCES,
  SlidesBlockItem,
} from "../../components/burislides-modal/burislides-modal.component";
import { BurislidesSlideAppearanceModalComponent } from "../../components/burislides-slide-appearance-modal/burislides-slide-appearance-modal.component";
export interface SpreadView {
  spread: {
    spreadType?: string;

    // For image spread
    leftUri?: string;
    rightUri?: string;

    // For slide, link, video, pdf,
    uri?: string;
    // For html, markdown, download link
    text?: string;
    // For carousel
    uris?: string[];
    viewMode?: string;
    // For activity
    examItemUuid?: string;

    // For download link
    imageUri?: string;
    title?: string;

    // For activity group
    examSectionUuid?: string;
    examChoiceTriggerFlags?: any[];
    examItemRequiredFlags?: any[];
    isResultPageShown?: boolean;
    resultPageImageUri?: string;
    resultPageText?: string;
  };
  item?: any;
  leftPageNumber?: number;
  rightPageNumber?: number;
  slidePageNumber?: number;
  leftFile?: File;
  rightFile?: File;
  slideFile?: File;
  index?: number;
  itemType?: string;
  itemSubtype?: string;
  selected?: boolean;
  icon?: string;
  slideThumbnail?: string;
  slideTitle?: string;
  slideRequired?: boolean;
}
// Defined here for reference and for less error prone coding
export enum SPREAD_TYPE {
  SLIDE = "slide",
  IMAGE_SPREAD = "image-spread",
  ACTIVITY = "activity",
  LINK = "link",
  VIDEO = "video",
  PDF = "pdf",
  HTML = "html",
  MARKDOWN = "markdown",
  CAROUSEL = "carousel",
  DOWNLOAD_LINK = "download-link",
  ACTIVITY_GROUP = "activity-group",
}
@Component({
  selector: "app-burislides-edit",
  templateUrl: "./burislides-edit.component.html",
  styles: [],
  animations: [fadeInOut],
})
export class BurislidesEditComponent implements OnInit {
  resource: Resource;

  spreadViews: SpreadView[] = [];
  currentSlide: SpreadView;
  activities: V1ExamItem[];
  currentActivityItem: V1ExamItem;
  problems: any[] = [];
  disablePreview: boolean;
  loading: boolean;
  loadingDone: boolean;
  sub;
  data;
  publishResourceOnNext: boolean;
  canUpdate: boolean;
  subscribing: boolean;
  observeClick = new Subject();
  editMode: boolean = true;
  previewMode: boolean = false;
  @ViewChild("fileInput", { static: false }) input: ElementRef;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private resourcesService: ResourcesService,
    private v1ExamsService: V1ExamsService,
    private uploadsService: UploadsService,
    private modalService: ModalService,
    private cd: ChangeDetectorRef
  ) {}
  async ngOnInit() {
    let {
      publish_resource_on_next: publishResourceOnNext,
      resource_uuid: resourceUuid,
    } = this.route.snapshot.queryParams;

    if (publishResourceOnNext) {
      this.publishResourceOnNext = true;
    }

    if (resourceUuid) {
      let result = await firstValueFrom(
        this.resourcesService.getResourceByUuid(resourceUuid)
      );

      this.resource = result;

      this.parseContent();
    }
  }
  async openSlideAppearanceModal(index?: number) {
    if (typeof index === "number") {
      let appearanceModal = await this.modalService.openModal(
        BurislidesSlideAppearanceModalComponent,
        {
          panelClass: ["overflow-y-auto", "w-1/2"],
          height: "600px",
          data: { currentSlide: this.currentSlide },
        }
      );
      // this.resource.content['spreads'][index]=this.currentSlide

      this.onSave();
    }
  }
  async openAddBlockModal(index?: number) {
    let blockItem: SlidesBlockItem = await this.modalService.openModal(
      BurislidesModalComponent,
      { panelClass: "overflow-y-auto", height: "600px" }
    );

    if (blockItem) {
      if (this.spreadViews.length === 0) {
        this.onAddSlide(0);
      }
      if (index >= 0) {
        this.onAddSlide(index);
      }

      this.setNewSlide(blockItem);
    }
  }
  async onAddSlide(spliceIndex) {
    let newSlide = { spread: { spreadType: "new" } };
    if (this.spreadViews) {
      this.spreadViews.splice(spliceIndex, 0, newSlide);
      this.setCurrentSlide(this.spreadViews[spliceIndex], spliceIndex);
    }
  }
  async setNewSlide(newSlide: SlidesBlockItem) {
    //set SpreadView to a new slide and insert to spreadViews
    if (!newSlide) {
      return;
    }
    let slide = {
      itemType: newSlide.value === "SU" ? "PO" : newSlide.value,
      itemSubtype: newSlide.value === "SU" ? newSlide.value : "",
      spread: {
        spreadType: `${
          newSlide.type === "question" ? "activity" : newSlide.value
        }`,
      },
      selected: true,
    };
    if (this.spreadViews.length > 0 && this.currentSlide) {
      this.spreadViews[this.currentSlide.index] = slide;
    }
    this.setCurrentSlide(
      this.spreadViews[this.currentSlide.index],
      this.currentSlide.index
    );

    this.cd.detectChanges();
  }
  async setCurrentActivity(examItem: V1ExamItem) {
    this.currentActivityItem = examItem;
  }
  async parseContent() {
    // Extract exam details
    let examUuid = this.resource.content["examDetails"]?.["examUuid"];

    // Allow update if exam is not yet created or exam is created but has 'update' allowed action
    if (examUuid) {
      let exam = await firstValueFrom(
        this.v1ExamsService.examsRead(examUuid, "allowed_actions")
      );

      if (exam.allowedActions.includes("update")) {
        this.canUpdate = true;
      }
    } else {
      this.canUpdate = true;
    }

    this.spreadViews = [];

    this.resource.content["spreads"].forEach((spread, index) => {
      this.spreadViews.push({
        spread,
      });
    });

    this.updateSpreadViews();

    //Select the first slide by default
    if (this.spreadViews.length > 0) {
      this.setCurrentSlide(this.spreadViews[0], 0);
    }

    this.cd.detectChanges();
    this.getProblems();
  }

  updateSpreadViews() {
    // Update image spread numbering
    this.spreadViews
      .filter((view) => {
        return view.spread.spreadType === SPREAD_TYPE.IMAGE_SPREAD;
      })
      .forEach((spread, index) => {
        spread.leftPageNumber = index * 2 + 1;
        spread.rightPageNumber = index * 2 + 2;
      });

    // Update slide numbering
    this.spreadViews
      .filter((view) => {
        return (
          view.spread.spreadType !== SPREAD_TYPE.ACTIVITY &&
          view.spread.spreadType !== SPREAD_TYPE.IMAGE_SPREAD
        );
      })
      .forEach((spread, index) => {
        spread.slidePageNumber = index + 1;
      });
    //Update Thumbnails
    this.spreadViews
      .filter((view) => {
        return view.spread.spreadType === SPREAD_TYPE.SLIDE;
      })
      .forEach((spread, index) => {
        spread.slideThumbnail = spread.spread.uri;
      });
    this.spreadViews
      .filter((view) => {
        return view.spread.spreadType === SPREAD_TYPE.ACTIVITY;
      })
      .forEach(async (spread, index) => {
        let item = await firstValueFrom(
          this.v1ExamsService.examsSectionsItemsRead(spread.spread.examItemUuid)
        );
        spread.itemType = item.type;
        spread.itemSubtype = item.subtype;
        spread.slideTitle = item.text;
        spread.slideRequired = item.required;
        //Add icon depending on the item type/subtype
        QUESTIONS.map((question) => {
          if (question.value === item.subtype) {
            spread.icon = question.icon;
          } else if (question.value === item.type) {
            spread.icon = question.icon;
          } else {
            spread.icon = "radio_button_checked";
          }
        });
      });
    this.spreadViews
      .filter((view) => {
        return view.spread.spreadType === SPREAD_TYPE.ACTIVITY_GROUP;
      })
      .forEach(async (spread, index) => {
        spread.slideTitle = "Group of questions";
        spread.icon = "apps";
      });
  }
  checkLoading(loading) {
    this.disablePreview = loading;
    this.cd.detectChanges();
  }
  setCurrentSlide(slide: SpreadView, index: number, insideToggle?: boolean) {
    //Set to edit when selecting a slide
    if (!insideToggle) {
      this.editMode = true;
      this.previewMode = false;
    }

    for (let i = 0; i < this.spreadViews.length; i++) {
      this.spreadViews[i].selected = i === index;
      this.spreadViews[i].index = i;
    }
    this.currentSlide = slide;
    this.cd.detectChanges();
  }

  async saveResource() {
    await firstValueFrom(
      this.resourcesService.updateResourceByUuid(
        this.route.snapshot.queryParams["resource_uuid"],
        this.resource
      )
    );

    this.saveItemOrder();
  }

  async onSave() {
    this.loading = true;
    try {
      this.spreadViews.map((view) => view.spread);

      this.resource.content["spreads"] = this.spreadViews.map(
        (view) => view.spread
      );

      await this.saveResource();
      this.updateSpreadViews();
    } catch (e) {
      console.log(e);
    } finally {
      this.loading = false;
    }
  }

  mapPageViewToContentSpread() {
    this.resource.content["spreads"] = this.spreadViews.map((s) => s.spread);
  }

  getProblems() {
    if (this.resource.type == "burislides") {
      this.getBuriSlideProblems();
    }
  }
  getBuriSlideProblems() {
    this.problems = [];

    if (this.spreadViews.length === 0) {
      this.problems.push(`*Pages required`);
    }

    this.spreadViews
      .filter((view) => {
        return view.spread.spreadType === SPREAD_TYPE.SLIDE;
      })
      .forEach((view, index) => {
        if (!view.spread.uri) {
          this.problems.push(
            `Page ${index + 1} has a problem. Please reupload the page.`
          );
        }
      });
  }
  processFiles(fileList: File[]) {
    if (this.resource.type == "burislides") {
      this.processBuriSlideFiles(fileList);
    }
  }

  processBulkUploadSlides(fileList) {
    this.loading = true;

    let items: {
      pageNumber: number;
      file: File;
      result?: any;
    }[] = [];

    for (let i = 0; i < fileList.length; i++) {
      let file = fileList[i];

      //   // Get page number from file
      let pageNumber = parseInt(file.name);

      items.push({
        pageNumber,
        file: fileList[i],
      });
    }

    this.uploadsService
      .uploadFiles(items.map((i) => i.file))
      .pipe(
        map((result) => {
          return result.map((r, i) => {
            return {
              ...items[i],
              result: r,
            };
          });
        }),

        // Process files with integer pageNumber
        tap((result) => {
          result.sort((a, b) => {
            return a.pageNumber - b.pageNumber;
          });

          result
            .filter((item) => item.pageNumber)
            .forEach((item) => {
              // Get page number from file
              let pageNumber = item.pageNumber;

              // Search if existing spreadview exists if not, create and insert new spread views
              let spreadView = this.spreadViews.find((spreadView) => {
                return spreadView.slidePageNumber === pageNumber;
              });

              if (spreadView) {
                spreadView.spread.uri = item.result.uri;
              } else {
                let newSpreadView: SpreadView = {
                  slidePageNumber: pageNumber,
                  spread: {
                    spreadType: SPREAD_TYPE.SLIDE,
                    uri: item.result.uri,
                  },
                };

                // Insert new spread
                let activityCount = this.spreadViews.filter(
                  (view) => view.spread.spreadType === SPREAD_TYPE.ACTIVITY
                ).length;

                // let spreadPosition = Math.floor((pageNumber - 1) / 2);
                // let newSlideIndex = pageNumber - 1 + activityCount;

                this.spreadViews.push(newSpreadView);

                // else {
                //   this.spreadViews.push(newSpreadView);
                // }

                // Fill in empty spreads
                // for (let j = 0; j < this.spreadViews.length; j++) {
                //   if (!this.spreadViews[j]) {
                //     this.spreadViews[j] = {
                //       spread: {
                //         spreadType: SPREAD_TYPE.SLIDE,
                //         uri: null,
                //       },
                //     };
                //   }
                // }

                this.spreadViews
                  .filter((view) => {
                    return view.spread.spreadType === SPREAD_TYPE.SLIDE;
                  })
                  .forEach((spread, index) => {
                    spread.slidePageNumber = index + 1;
                  });
              }
            });
        }),

        // Process files with non-integer pageNumber and appends them at the end
        tap((result) => {
          result
            .filter(
              (item) =>
                item.pageNumber === null ||
                item.pageNumber === undefined ||
                isNaN(item.pageNumber)
            )
            .forEach((item) => {
              // Get last spread view
              let lastSpreadView = this.spreadViews
                .filter(
                  (spreadView) =>
                    spreadView.spread.spreadType === SPREAD_TYPE.SLIDE
                )
                .pop();

              let newSpreadView: SpreadView = {
                slidePageNumber: lastSpreadView?.slidePageNumber + 1 || 1,
                spread: {
                  spreadType: SPREAD_TYPE.SLIDE,
                  uri: item.result.uri,
                },
              };

              this.spreadViews.push(newSpreadView);
              this.setCurrentSlide(this.spreadViews[0], 0);
            });
        }),
        tap(() => {
          this.mapPageViewToContentSpread();
          this.getProblems();

          this.loading = false;

          this.onSave();
        })
      )
      .subscribe();
  }
  processBuriSlideFiles(fileList) {
    this.loading = true;

    let items: {
      pageNumber: number;
      file: File;
      result?: any;
    }[] = [];

    for (let i = 0; i < fileList.length; i++) {
      let file = fileList[i];

      //   // Get page number from file
      let pageNumber = parseInt(file.name);
      // let pageNumber = i + 1;
      items.push({
        pageNumber,
        file: fileList[i],
      });
    }

    this.uploadsService
      .uploadFiles(items.map((i) => i.file))
      .pipe(
        map((result) => {
          return result.map((r, i) => {
            return {
              ...items[i],
              result: r,
            };
          });
        }),

        // Process files with integer pageNumber
        tap((result) => {
          result.sort((a, b) => {
            return a.pageNumber - b.pageNumber;
          });
          result
            .filter((item) => item.pageNumber)
            .forEach((item) => {
              // Get page number from file
              let pageNumber = item.pageNumber;

              // Search if existing spreadview exists if not, create and insert new spread views
              let spreadView = this.spreadViews.find((spreadView) => {
                return spreadView.slidePageNumber === pageNumber;
              });

              if (spreadView) {
                spreadView.spread.uri = item.result.uri;
                if (spreadView.spread.spreadType === "carousel") {
                  spreadView.spread.uris = [
                    ...spreadView.spread.uris,
                    item.result.uri,
                  ];
                }
              } else {
                let newSpreadView: SpreadView = {
                  slidePageNumber: pageNumber,
                  spread: {
                    spreadType: this.currentSlide.spread.spreadType,
                    uri: item.result.uri,
                  },
                };
                this.spreadViews.push(newSpreadView);
                // // Insert new spread
                // let activityCount = this.spreadViews.filter(
                //   (view) => view.spread.spreadType === SPREAD_TYPE.ACTIVITY
                // ).length;

                // // let spreadPosition = Math.floor((pageNumber - 1) / 2);

                // this.spreadViews[pageNumber - 1 + activityCount] = {
                //   ...newSpreadView,
                // };
                // // Fill in empty spreads
                // for (let j = 0; j < this.spreadViews.length; j++) {
                //   if (!this.spreadViews[j]) {
                //     this.spreadViews[j] = {
                //       spread: {
                //         spreadType: this.currentSlide.spread.spreadType,
                //         uri: null,
                //       },
                //     };
                //   }
                // }

                this.spreadViews
                  .filter((view) => {
                    return (
                      view.spread.spreadType !== SPREAD_TYPE.ACTIVITY &&
                      view.spread.spreadType !== SPREAD_TYPE.IMAGE_SPREAD
                    );
                  })
                  .forEach((spread, index) => {
                    spread.slidePageNumber = index + 1;
                  });
              }
            });
        }),

        // Process files with non-integer pageNumber
        tap((result) => {
          result
            .filter(
              (item) =>
                item.pageNumber === null ||
                item.pageNumber === undefined ||
                isNaN(item.pageNumber)
            )
            .forEach((item) => {
              // Get last spread view
              // let lastSpreadView = this.spreadViews
              //   .filter(
              //     (spreadView) =>
              //       spreadView.spread.spreadType === SPREAD_TYPE.SLIDE
              //   )
              //   .pop();

              let newSpreadView: SpreadView = {
                slidePageNumber: this.currentSlide.index || 1,
                spread: {
                  spreadType: this.currentSlide.spread.spreadType,
                  uri: item.result.uri,
                },
              };

              this.spreadViews[this.currentSlide.index] = newSpreadView;
              this.setCurrentSlide(newSpreadView, this.currentSlide.index);
            });
        }),
        tap(() => {
          this.mapPageViewToContentSpread();
          this.getProblems();

          this.loading = false;

          this.onSave();
        })
      )
      .subscribe();
  }
  spreadDrop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.spreadViews, event.previousIndex, event.currentIndex);

    this.onSave();
  }
  async deleteSpread(spreadView, index) {
    let result = await this.modalService.confirm(
      "Are you sure you want to delete this spread?"
    );

    if (result && this.spreadViews.length > 0) {
      this.spreadViews.splice(index, 1);
      if (this.spreadViews[index - 1]) {
        this.spreadViews[index - 1] = {
          ...this.spreadViews[index - 1],
          selected: true,
        };
        this.currentSlide = this.spreadViews[index - 1];
      } else if (this.spreadViews[index]) {
        this.spreadViews[index] = {
          ...this.spreadViews[index],
          selected: true,
        };
        this.currentSlide = this.spreadViews[index];
      } else {
        this.currentSlide = null;
      }

      // Also delete activity object
      if (spreadView.spread.spreadType === SPREAD_TYPE.ACTIVITY) {
        try {
          await firstValueFrom(
            this.v1ExamsService.examsSectionsItemsDelete(
              spreadView.spread?.examItemUuid
            )
          );
        } catch (e) {
          console.log(e);
        }
      }

      this.onSave();
      this.getProblems();
    }
  }

  async onAddActivityClick(spliceIndex) {
    let examSectionId =
      this.resource.content["examDetails"]?.["examSectionUuid"];

    if (!examSectionId) {
      this.loading = true;
      // Create an exam
      let exam = await firstValueFrom(
        this.v1ExamsService.examsCreate({
          title: `${this.resource.name}`,
          description: `uuid: ${this.resource.uuid}`,
        })
      );

      // Create an exam section
      let section = await firstValueFrom(
        this.v1ExamsService.examsSectionsCreate(exam.uuid, {
          title: "Default Section",
        })
      );

      // Create an exam timeslot
      let timeslot = await firstValueFrom(
        this.v1ExamsService.examsTimeslotsCreate(exam.uuid, {
          accessType: "PU",
        })
      );

      // Update users

      this.resource.content = {
        ...this.resource.content,
        examDetails: {
          examUuid: exam.uuid,
          examTimeslotUuid: timeslot.uuid,
          examSectionUuid: section.uuid,
        },
      };

      await firstValueFrom(
        this.resourcesService.updateResourceByUuid(
          this.resource.uuid,
          this.resource
        )
      );

      // Change query params to not mess up browser history
      this.router.navigate([], {
        queryParamsHandling: "merge",
        relativeTo: this.route,
        replaceUrl: true,
        queryParams: {
          exam_uuid: exam.uuid,
        },
      });

      // Add miniscule delay para gumana consecutive navigate
      await firstValueFrom(of(null).pipe(delay(100)));

      examSectionId = section.uuid;
    }

    this.router.navigate(["select-item-type"], {
      queryParamsHandling: "merge",
      relativeTo: this.route,
      queryParams: {
        exam_section_uuid: examSectionId,
        splice_index: spliceIndex,
      },
    });
  }

  async done() {
    this.loadingDone = true;
    await this.onSave();
    try {
      if (this.publishResourceOnNext) {
        this.router.navigate(["/resources", "publish"], {
          queryParamsHandling: "merge",
          replaceUrl: true,
          queryParams: {
            resource_uuid: this.resource.uuid,
            done_history_length: 2,
          },
        });
      } else {
        this.location.back();
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.loadingDone = false;
    }
  }

  saveItemOrder() {
    return Promise.all(
      this.spreadViews
        .filter((view) => view.spread.spreadType === SPREAD_TYPE.ACTIVITY)
        .map((item, index) =>
          firstValueFrom(
            this.v1ExamsService.examsSectionsItemsUpdate(
              item.spread.examItemUuid,
              {
                orderId: index,
              }
            )
          )
        )
    );
  }

  toggleEditMode() {
    this.editMode = !this.editMode;
    this.previewMode = !this.previewMode;

    this.setCurrentSlide(this.currentSlide, this.currentSlide.index, true);

    this.cd.detectChanges();
  }
}
