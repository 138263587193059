<div
  *ngIf="loading"
  [@fadeInOut]
  class="absolute inset-0 bg-white bg-opacity-50 h-full"
>
  <app-loading [loading]="true" position="relative"></app-loading>
</div>
<div class="text-center w-full font-bold mb-4 text-xl">Are you sure?</div>

<div class="mb-2 mx-auto w-full text-center">
  Saving this email will <strong>start the sending schedule</strong>
</div>

<div class="flex justify-between w-full">
  <button class="btn btn-primary" (click)="onSubmit()" mat-dialog-close>
    confirm
  </button>
  <button
    class="btn text-primary-600 border border-primary-600 rounded p-1 bg-white"
    mat-dialog-close
  >
    cancel
  </button>
</div>
