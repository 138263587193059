import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Location } from "@angular/common";
import {
  CoursesService,
  PassService,
  User,
  UserIds,
} from "src/app/sinigangnababoywithgabi";
import {
  ActivityLogService,
  VERBS,
} from "src/app/services/activity-log.service";
import { first, firstValueFrom } from "rxjs";
import { DashboardService } from "src/app/services/dashboard.service";
import { ModalService } from "src/app/services/modal.service";
import camelCase from "camelcase";
@Component({
  selector: "app-user-edit",
  templateUrl: "./user-edit.component.html",
})
export class UserEditComponent implements OnInit {
  accountDetailsFormGroup;
  formGroup: UntypedFormGroup;

  user: User;
  castClientId: string = "";
  isLoading: boolean;
  booleanChoices = [
    { name: "True", value: true },
    { name: "False", value: false },
  ];
  constructor(
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private location: Location,
    private passService: PassService,
    private activityLogService: ActivityLogService,
    private dashboardService: DashboardService,
    private modalService: ModalService,
    private coursesService: CoursesService
  ) {}

  async ngOnInit() {
    let { user_uuid: userUuid } = this.route.snapshot.queryParams;

    let user = await firstValueFrom(
      this.passService.getUser(userUuid, ["groups"])
    );

    let { groups } = await firstValueFrom(
      this.passService.listGroups(null, null, null, ["users_count"])
    );
    let application = await firstValueFrom(
      this.passService.getApplication(localStorage.getItem("application_id"))
    );

    let castV2 = await firstValueFrom(this.dashboardService.getCast());
    this.castClientId = castV2.data.attributes.auth.clientId;
    let _metadataAdmin = application.identitySchema?.properties?.metadataAdmin;
    let _metadataPublic =
      application.identitySchema?.properties?.metadataPublic;
    let _traits = application.identitySchema?.properties?.traits;

    let _traitsSorted = _traits?.sort;
    let _metadataPublicSorted = _metadataPublic?.sort;
    let _metadataAdminSorted = _metadataAdmin?.sort;

    let _traitsRequired = _traits?.required?.map((require) =>
      this.snakeToCamel(require)
    );
    let _metadataPublicRequired = _metadataPublic?.required?.map((require) =>
      this.snakeToCamel(require)
    );
    let _metadataAdminRequired = _metadataAdmin?.required?.map((require) =>
      this.snakeToCamel(require)
    );

    let traitsSorted = _traitsSorted?.map((key) => {
      return this.snakeToCamel(key);
    });
    let metadataPublicSorted = _metadataPublicSorted?.map((key) => {
      return this.snakeToCamel(key);
    });
    let metadataAdminSorted = _metadataAdminSorted?.map((key) => {
      return this.snakeToCamel(key);
    });

    let groupMap = groups.reduce<any>((obj, group) => {
      obj[group.id] = group;

      return obj;
    }, {});

    user.groups = user.groups.map((group) => {
      return groupMap[group.id];
    });

    this.user = user;

    let cast = await firstValueFrom(this.dashboardService.getCast());

    let isPasswordExpired;

    if (
      user.passwordExpiresAt &&
      new Date(user.passwordExpiresAt) < new Date()
    ) {
      isPasswordExpired = true;
    }

    this.formGroup = this.formBuilder.group({
      userUuid: [user.id],
      firstName: [user.firstName, Validators.required],
      lastName: [user.lastName, Validators.required],
      email: [user.email],
      isUserConfirmed: [!!user.confirmedAt],
      isPasswordExpired: [isPasswordExpired],
      isActive: [
        !user.groups
          .map((g) => g.id)
          .includes(cast.data.attributes.deactivatedUsersGroupId),
      ],
      traits: this.formBuilder.array([]),
      publicMetadata: this.formBuilder.array([]),
      adminMetadata: this.formBuilder.array([]),
    });

    let userProfile = await firstValueFrom(
      this.passService.getUserProfile(userUuid)
    );
    let traits = userProfile.data.attributes.traits;
    let publicMetadata = userProfile.data.attributes.metadataPublic;
    let adminMetadata = userProfile.data.attributes.metadataAdmin;

    if (_traits) {
      traitsSorted.forEach((key) => {
        let _key = camelCase(key);
        let _displayKey = _traits?.properties[_key]?.title;
        let _value = traits ? traits[_key] : null;
        let _boolean =
          traits && _traits?.properties[_key]?.type === "boolean"
            ? traits[_key]
            : null;

        let _enumValue =
          traits && !!_traits?.properties[_key]?.enum ? traits[_key] : null;

        let _type =
          _traits?.properties[_key]?.type === "string"
            ? "text"
            : _traits?.properties[_key]?.type;
        let _dropdown =
          !!_traits?.properties[_key]?.enum ||
          _traits?.properties[_key]?.type === "boolean";

        let _enum = this.formBuilder.array([]);

        if (_traits?.properties[_key]?.enum) {
          _traits?.properties[_key]?.enum.forEach((option) => {
            _enum.push(this.formBuilder.group({ name: option, value: option }));
          });
        }
        this._traits.push(
          this.formBuilder.group({
            key: _key,
            name: _displayKey,
            value: [
              _value,
              // _traitsRequired.includes(key) &&
              // _traits?.properties[_key]?.type !== "boolean" &&
              // !!!_traits?.properties[_key]?.enum
              //   ? Validators.required
              //   : undefined,
            ],
            type: _type,
            dd: _dropdown,
            enum: _enum,
            boolean: [
              _boolean,
              // _traitsRequired.includes(key) &&
              // _traits?.properties[_key]?.type === "boolean"
              //   ? Validators.required
              //   : undefined,
            ],
            enumValue: [
              _enumValue,
              // _traitsRequired.includes(key) && !!_traits?.properties[_key]?.enum
              //   ? Validators.required
              //   : undefined,
            ],
          })
        );
      });
    }

    if (_metadataPublic) {
      metadataPublicSorted.forEach((key) => {
        let _key = camelCase(key);
        let _displayKey = _metadataPublic?.properties[_key]?.title;
        let _value = publicMetadata ? publicMetadata[_key] : null;
        let _boolean =
          publicMetadata &&
          _metadataPublic?.properties[_key]?.type === "boolean"
            ? publicMetadata[_key]
            : null;
        let _enumValue =
          publicMetadata && !!_metadataPublic?.properties[_key]?.enum
            ? publicMetadata[_key]
            : null;
        let _type =
          _traits?.properties[_key]?.type === "string"
            ? "text"
            : _traits?.properties[_key]?.type;
        let _dropdown =
          !!_metadataPublic?.properties[_key]?.enum ||
          _metadataPublic?.properties[_key]?.type === "boolean";
        let _enum = this.formBuilder.array([]);
        if (_metadataPublic?.properties[_key]?.enum) {
          _metadataPublic?.properties[_key]?.enum.forEach((option) => {
            _enum.push(this.formBuilder.group({ name: option, value: option }));
          });
        }
        this._publicMetadata.push(
          this.formBuilder.group({
            key: _key,
            name: _displayKey,
            value: [
              _value,
              // _metadataPublicRequired.includes(key) &&
              // _metadataPublic?.properties[_key]?.type !== "boolean" &&
              // !!!_metadataPublic?.properties[_key]?.enum
              //   ? Validators.required
              //   : undefined,
            ],
            type: _type,
            dd: _dropdown,
            enum: _enum,
            boolean: [
              _boolean,
              // _metadataPublicRequired.includes(key) &&
              // _metadataPublic?.properties[_key]?.type === "boolean"
              //   ? Validators.required
              //   : undefined,
            ],
            enumValue: [
              _enumValue,
              // _metadataPublicRequired.includes(key) &&
              // !!_metadataPublic?.properties[_key]?.enum
              //   ? Validators.required
              //   : undefined,
            ],
          })
        );
      });
    }

    if (_metadataAdmin) {
      metadataAdminSorted.forEach((key) => {
        let _key = camelCase(key);
        let _displayKey = _metadataAdmin?.properties[_key]?.title;
        let _value = adminMetadata ? adminMetadata[_key] : null;
        let _dropdown =
          !!_metadataAdmin?.properties[_key]?.enum ||
          _metadataAdmin?.properties[_key]?.type === "boolean";
        let _boolean =
          adminMetadata && _metadataAdmin?.properties[_key]?.type === "boolean"
            ? adminMetadata[_key]
            : null;
        let _enumValue =
          adminMetadata && !!_metadataAdmin?.properties[_key]?.enum
            ? adminMetadata[_key]
            : null;
        let _type =
          _traits?.properties[_key]?.type === "string"
            ? "text"
            : _traits?.properties[_key]?.type;

        let _enum = this.formBuilder.array([]);
        if (_metadataAdmin?.properties[_key]?.enum) {
          _metadataAdmin?.properties[_key]?.enum.forEach((option) => {
            _enum.push(this.formBuilder.group({ name: option, value: option }));
          });
        }

        this._adminMetadata.push(
          this.formBuilder.group({
            key: _key,
            name: _displayKey,
            value: [
              _value,
              // _metadataAdminRequired.includes(key) &&
              // _metadataAdmin?.properties[_key]?.type !== "boolean" &&
              // !!!_metadataAdmin?.properties[_key]?.enum
              //   ? Validators.required
              //   : undefined,
            ],
            type: _type,
            dd: _dropdown,
            enum: _enum,
            boolean: [
              _boolean,
              // _metadataAdminRequired.includes(key) &&
              // _metadataAdmin?.properties[_key]?.type === "boolean"
              //   ? Validators.required
              //   : undefined,
            ],
            enumValue: [
              _enumValue,
              // _metadataAdminRequired.includes(_key) &&
              // !!_metadataAdmin?.properties[_key]?.enum
              //   ? Validators.required
              //   : undefined,
            ],
          })
        );
      });
    }
    // if (traits) {
    //   console.log(traits);
    //   this.traits = Object.keys(traits).map((key) => {
    //     let _displayKey = _traits?.properties[key]?.title;
    //     let _value = traits[key];

    //     console.log(_traits?.properties[key]);

    //     return {
    //       key: key,
    //       displayKey: _displayKey,
    //       value: _value,
    //     };
    //   });
    //   console.log(this.traits);
    // } else if (!traits) {
    //   // this.additionalFields = Object.keys(userProfile).map((key) => ({
    //   //   key: key,
    //   //   displayKey:
    //   //     key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, " $1"),
    //   //   value: userProfile[key],
    //   // }));
    //   // this.additionalFields = this.additionalFields.filter((profile) => {
    //   //   if (profile.key === "traits") return false;
    //   //   return true;
    //   // });
    //   this.traits = null;
    // }
  }

  getDropdownFields(form) {
    return form.controls.enum.controls;
  }
  get _traits() {
    return <UntypedFormArray>this.formGroup.get("traits");
  }
  get _publicMetadata() {
    return <UntypedFormArray>this.formGroup.get("publicMetadata");
  }
  get _adminMetadata() {
    return <UntypedFormArray>this.formGroup.get("adminMetadata");
  }

  isRequiredField(field) {
    const value = field.value;
    const enumValue = field.enumValue;
    const booleanValue = field.boolean;
    if (!(value.validator || enumValue.validator || booleanValue.validator))
      return false;
    const validator = value.validator
      ? value.validator({} as AbstractControl)
      : enumValue.validator
      ? enumValue.validator({} as AbstractControl)
      : booleanValue.validator({} as AbstractControl);
    return validator && validator.required;
  }
  async save() {
    if (this.formGroup.valid) {
      let email = this.formGroup.get("email").dirty
        ? this.formGroup.get("email").value
        : undefined;

      let body = {
        firstName: this.formGroup.value.firstName,
        lastName: this.formGroup.value.lastName,
        email,
        passwordExpiresAt: this.formGroup.value.isPasswordExpired
          ? new Date().toISOString()
          : null,
      };

      await firstValueFrom(
        this.passService.updateUser(this.formGroup.value.userUuid, {
          user: body,
        })
      );

      let traits = {};
      let metadataPublic = {};
      let metadataAdmin = {};

      for (let control of this._traits.controls) {
        let value = control.get("value").value;
        let enumValue = control.get("enumValue").value;
        let booleanValue = control.get("boolean").value;
        let key = control.get("key").value;

        traits[key] = enumValue
          ? enumValue
          : typeof booleanValue === "boolean"
          ? booleanValue
          : value;
      }

      for (let control of this._publicMetadata.controls) {
        let value = control.get("value").value;
        let enumValue = control.get("enumValue").value;
        let booleanValue = control.get("boolean").value;
        let key = control.get("key").value;

        metadataPublic[key] = enumValue
          ? enumValue
          : typeof booleanValue === "boolean"
          ? booleanValue
          : value;
      }

      for (let control of this._adminMetadata.controls) {
        let value = control.get("value").value;
        let enumValue = control.get("enumValue").value;
        let booleanValue = control.get("boolean").value;
        let key = control.get("key").value;

        metadataAdmin[key] = enumValue
          ? enumValue
          : typeof booleanValue === "boolean"
          ? booleanValue
          : value;
      }
      await firstValueFrom(
        this.passService.updateUserProfile(this.formGroup.value.userUuid, {
          traits,
          metadataPublic,
          metadataAdmin,
        })
      );
      this.location.back();

      this.activityLogService.didUserInParent(VERBS.passwordChanged, {
        id: this.formGroup.value.userUuid,
        firstName: this.formGroup.value.firstName,
        lastName: this.formGroup.value.lastName,
      });
    }
  }
  async refreshUserList() {
    let user = await firstValueFrom(
      this.passService.getUser(this.formGroup.value.userUuid, ["groups"])
    );

    let { groups } = await firstValueFrom(
      this.passService.listGroups(null, null, null, ["users_count"])
    );

    let groupMap = groups.reduce<any>((obj, group) => {
      obj[group.id] = group;

      return obj;
    }, {});

    user.groups = user.groups.map((group) => {
      return groupMap[group.id];
    });

    this.user = user;
  }
  async deactivateUser() {
    try {
      let areYouSure = await this.modalService.confirm(
        "Do you really want to deactivate the user?",
        {
          confirmText: "Deactivate",
          confirmClass: "btn text-white bg-red-700",
          cancelText: "No",
        }
      );

      if (!areYouSure) {
        return;
      }

      this.isLoading = true;

      let cast = await firstValueFrom(this.dashboardService.getCast());

      if (!cast.data.attributes.deactivatedUsersGroupId) {
        await this.modalService.alert(
          "Inactive group not found. Dashboard will automatically create one."
        );

        await firstValueFrom(this.dashboardService.createInactiveGroup());
      }

      //Remove user from course
      let areYouSureCourses = await this.modalService.confirm(
        "User might be enrolled in some courses. Do you also want to remove the existing enrollments?",
        {
          confirmText: "Remove",
          confirmClass: "btn text-white bg-red-700",
          cancelText: "No, keep the enrollments",
        }
      );

      if (areYouSureCourses) {
        let enrollments = await firstValueFrom(
          this.coursesService.courseUserEnrollmentList(
            this.formGroup.value.userUuid
          )
        );
        let unenroll = enrollments.map((enrollment) => {
          return {
            uuid: enrollment.uuid,
            isActive: false,
          };
        });

        await firstValueFrom(
          this.coursesService.courseUnenrollBatch({
            unenrolls: unenroll,
          })
        );
        // await Promise.all(
        //   enrollments.map((enrollment) =>
        //     firstValueFrom(
        //       this.coursesService.enrollmentDelete(enrollment.uuid)
        //     )
        //   )
        // );
      }

      //Add user to inactive grouph

      let groupIds = [
        cast.data.attributes.deactivatedUsersGroupId,
        cast.data.attributes.defaultGroupId,
      ];

      await firstValueFrom(
        this.passService.updateUser(this.formGroup.value.userUuid, {
          user: {
            groupIds,
          },
        })
      );
      // let deletedGroupIds = this.user.groupIds.filter(
      //   (groupId) => !groupIds.includes(groupId)
      // );

      // //Delete groupids
      // await firstValueFrom(
      //   this.passService.deleteUserGroups(this.formGroup.value.userUuid, {
      //     groupIds: deletedGroupIds,
      //   })
      // );

      // //Add new groupids
      // await firstValueFrom(
      //   this.passService.updateUserGroups(this.formGroup.value.userUuid, {
      //     groupIds,
      //   })
      // );

      // Refresh current user
      this.refreshUserList();
      // Update groupids directly
      // await firstValueFrom(
      //   this.passService.updateUser(this.formGroup.value.userUuid, {
      //     user: {
      //       groupIds,
      //     },
      //   })
      // );

      this.formGroup.get("isActive").setValue(false);
    } catch (err) {
      throw err;
    } finally {
      this.isLoading = false;
    }

    this.activityLogService.didUserInParent(VERBS.deactivated, {
      id: this.formGroup.value.userUuid,
      email: this.formGroup.value.email,
    });
  }
  async removeGroup(userGroupIndex) {
    let userIds = [this.user.uuid];
    try {
      this.isLoading = true;
      await firstValueFrom(
        this.passService.deleteGroupUsers(this.user.groups[userGroupIndex].id, {
          userIds: userIds,
        })
      );
      await this.ngOnInit();
    } catch (e) {
      throw e;
    } finally {
      this.isLoading = false;
    }
  }
  async activateUser() {
    try {
      let result = await this.modalService.confirm(
        "Do you really want to activate the user?"
      );

      if (!result) {
        return;
      }

      this.isLoading = true;

      let cast = await firstValueFrom(this.dashboardService.getCast());

      // await firstValueFrom(
      //   this.passService.updateUser(this.formGroup.value.userUuid, {
      //     user: {
      //       groupIds: [cast.data.attributes.defaultGroupId],
      //     },
      //   })
      // );
      let groupIds = this.user.groups.map((group) => {
        return group.id;
      });
      // Delete non default group
      await firstValueFrom(
        this.passService.deleteUserGroups(this.formGroup.value.userUuid, {
          groupIds: groupIds.filter(
            (id) => id !== cast.data.attributes.defaultGroupId
          ),
        })
      );

      // Update user to parent group
      //  await firstValueFrom(
      //         this.passService.updateUserGroups(this.formGroup.value.userUuid, {
      //           groupIds: [cast.data.attributes.defaultGroupId],
      //         })
      //       );

      // Refresh current user
      this.refreshUserList();

      this.formGroup.get("isActive").setValue(true);
    } catch (err) {
      throw err;
    } finally {
      this.isLoading = false;
    }

    this.activityLogService.didUserInParent(VERBS.activated, {
      id: this.formGroup.value.userUuid,
      email: this.formGroup.value.email,
    });
  }

  async resendConfirmationEmail() {
    let result = await this.modalService.confirm(
      `Do you want to resend the confirmation email for ${this.formGroup.value.email}?`
    );

    if (result) {
      try {
        await firstValueFrom(
          this.passService.sendUserConfirmationEmail(
            {
              user: {
                email: this.formGroup.value.email,
              },
            },
            this.castClientId
          )
        );

        this.modalService.alert("Confirmation email sent!");
      } catch (err) {
        this.modalService.alert("Confirmation not sent due to error.");
      }
    }
  }

  async sendPasswordChangeEmail() {
    let result = await this.modalService.confirm(
      `Do you want to send a password reset email for ${this.formGroup.value.email}?`
    );

    if (result) {
      try {
        await firstValueFrom(
          this.passService.sendUserPasswordChangeEmail(
            {
              user: {
                email: this.formGroup.value.email,
              },
            },
            this.castClientId
          )
        );

        this.modalService.alert("Password reset email sent!");
      } catch (err) {
        this.modalService.alert("Password reset email not sent due to error.");
      }
    }
  }

  snakeToCamel(input: string) {
    return input
      .toLowerCase()
      .replace(/([-_][a-z0-9])/g, (group) =>
        group.toUpperCase().replace("_", "")
      );
  }
  toSnakeCase(input: string) {
    let _input = input.toLowerCase();
    return _input
      .replace(/[^A-Za-z0-9 ]/g, "")
      .split("")
      .map((character) => {
        if (character === " ") {
          return "_";
        } else {
          return character;
        }
      })
      .join("");
  }
}
