import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import {
  Group,
  CoursesService,
  PassService,
} from "src/app/sinigangnababoywithgabi";
import { ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";

@Component({
  selector: "app-course-batches-create",
  templateUrl: "./course-batches-create.component.html",
})
export class CourseBatchesCreateComponent implements OnInit {
  courseUuid: string;
  groups: Group[];
  newBatchForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private location: Location,
    private coursesService: CoursesService
  ) {}

  async ngOnInit() {
    this.courseUuid = this.route.snapshot.queryParams["course_uuid"];

    this.newBatchForm = this.formBuilder.group({
      batchName: ["", Validators.required],
      startDateTime: [new Date().toISOString()],
      endDateTime: [null],
      batchAccess: ["forever"],
    });
  }

  async createNewBatch() {
    this.newBatchForm.disable();

    try {
      let body = {
        course: this.courseUuid,
        name: this.newBatchForm.value.batchName,
        isActive: true,
        start: null,
        end: null,
      };

      if (this.newBatchForm.get("batchAccess").value == "forever") {
        //Set start end end date to null
        body.start = new Date().toISOString();
      } else {
        body.start = this.newBatchForm.value.startDateTime;
        body.end = this.newBatchForm.value.endDateTime;
      }

      await this.coursesService
        .courseScheduleCreate(this.courseUuid, body)
        .toPromise();

      this.location.back();
    } catch (err) {
      this.newBatchForm.enable();

      throw err;
    }
  }
}
