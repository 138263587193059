import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-casteditor-modal-layout",
  templateUrl: "./casteditor-modal-layout.component.html",
  styleUrls: ["./casteditor-modal-layout.component.scss"],
})
export class CasteditorModalLayoutComponent implements OnInit {
  constructor() {}

  @Output()
  done = new EventEmitter<any>();

  ngOnInit(): void {}

  async gotoResourceCreate() {
    this.done.emit();
  }
}
