import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { RouteWithData } from "src/extensions/route-with-data";
import { AuthGuard } from "../app-common-module/guards/auth.guard";
import { CollectionEditComponent } from "./collections/collection-edit/collection-edit.component";
import { CollectionNewComponent } from "./collections/collection-new/collection-new.component";
import { CollectionParentsEditComponent } from "./collections/collection-parents-edit/collection-parents-edit.component";
import { CollectionPermissionsAddGroupComponent } from "./collections/collection-permissions-add-group/collection-permissions-add-group.component";
import { CollectionPermissionsComponent } from "./collections/collection-permissions/collection-permissions.component";
import { CollectionProfileComponent } from "./collections/collection-profile/collection-profile.component";
import { ComponentListComponent } from "./components/component-list/component-list.component";
import { ComponentSelectComponent } from "./components/component-select/component-select.component";
import { ActivityQuestionEditComponent } from "./misc/activity-question-edit/activity-question-edit.component";
import { ActivityQuestionNewComponent } from "./misc/activity-question-new/activity-question-new.component";
import { AssessmentActivityEditComponent } from "./misc/assessment-activity-edit/assessment-activity-edit.component";
import { AssessmentNewQuestionSelectTypeComponent } from "./misc/assessment-new-question-select-type/assessment-new-question-select-type.component";
import { BuribookPagesEditComponent } from "./misc/buribook-pages-edit/buribook-pages-edit.component";
import { BuribooksNewQuestionSelectTypeComponent } from "./misc/buribooks-new-question-select-type/buribooks-new-question-select-type.component";
import { BuribooksEditComponent } from "./misc/buribooks-edit/buribooks-edit.component";
import { TallyActivityEditComponent } from "./misc/tally-activity-edit/tally-activity-edit.component";
import { ResourceLibraryAddToCollectionComponent } from "./resource-library/resource-library-add-to-collection/resource-library-add-to-collection.component";
import { ResourceLibraryAddToCourseComponent } from "./resource-library/resource-library-add-to-course/resource-library-add-to-course.component";
import { ResourceManagementComponent } from "./resource-management.component";
import { ResourceEditComponent } from "./resources/resource-edit/resource-edit.component";
import { ResourceListComponent } from "./resources/resource-list/resource-list.component";
import { ResourceNewComponent } from "./resources/resource-new/resource-new.component";
import { ResourceProfileComponent } from "./resources/resource-profile/resource-profile.component";
import { ResourcePublishComponent } from "./resources/resource-publish/resource-publish.component";
import { ResourceSelectCollectionsComponent } from "./resources/resource-select-collections/resource-select-collections.component";
import { ResourceSelectNewComponent } from "./resources/resource-select-new/resource-select-new.component";
import { BuribooksQuestionNewComponent } from "./misc/buribooks-question-new/buribooks-question-new.component";
import { BurislidesEditComponent } from "./misc/burislides-edit/burislides-edit.component";
import { ResourceBurislideAnalyticsComponent } from "./resources/resource-burislide-analytics/resource-burislide-analytics.component";
import { BurislidesAppearanceComponent } from "./misc/burislides-appearance/burislides-appearance.component";

const childRoutes: RouteWithData[] = [
  {
    path: "resources",
    data: {
      hideBackTitleDiagonalDesign: false,
      layoutTitle: "Resource Library",
    },
    children: [
      {
        path: "",
        redirectTo: "/components/list",
        pathMatch: "full",
      },
      {
        path: "list",
        component: ResourceListComponent,
      },
      {
        path: "new",
        component: ResourceNewComponent,
        data: {
          hideBackTitleDiagonalDesign: true,
          layoutTitle: "Create Resource",
          disableOnBreadcrumb: true,
        },
      },
      {
        path: "select-new",
        component: ResourceSelectNewComponent,
        data: {
          hideBackTitleDiagonalDesign: true,
          layoutTitle: "Create Resource",
        },
      },
      {
        path: "profile",
        data: {
          hideBackTitleDiagonalDesign: true,
          layoutTitle: "Resource Profile",
          preserveQueryParamFields: ["resource_uuid"],
        },
        children: [
          {
            path: "",
            component: ResourceProfileComponent,
          },
          {
            path: "edit",
            component: ResourceEditComponent,
            data: {
              hideBackTitleDiagonalDesign: true,
              layoutTitle: "Resource Details",
              preserveQueryParamFields: ["resource_uuid"],
            },
          },
          {
            path: "publish",
            component: ResourcePublishComponent,
            data: {
              hideBackTitleDiagonalDesign: true,
              layoutTitle: "Publish Details",
              preserveQueryParamFields: ["resource_uuid"],
            },
          },
          {
            path: "collection-edit",
            component: ResourceSelectCollectionsComponent,
            data: {
              hideBackTitleDiagonalDesign: true,
              layoutTitle: "Edit Collections",
              preserveQueryParamFields: ["resource_uuid"],
            },
          },
          {
            path: "burislide-analytics",
            component: ResourceBurislideAnalyticsComponent,
            data: {
              hideBackTitleDiagonalDesign: true,
              layoutTitle: "Slides Analytics",
              preserveQueryParamFields: ["resource_uuid"],
            },
          },
        ],
      },

      // For backwards compatibility
      {
        path: "edit",
        redirectTo: "profile/edit",
        pathMatch: "full",
      },
      {
        path: "publish",
        redirectTo: "profile/publish",
        pathMatch: "full",
      },
      {
        path: "collection-edit",
        redirectTo: "profile/collection-edit",
        pathMatch: "full",
      },
      // {
      //   path: 'select',
      //   component: CollectionSelectWithCallbackComponent
      // },
      // {
      //   path: ':uuid',
      //   component: CollectionUpdateComponent
      // }
    ],
  },
  {
    path: "library",
    children: [
      {
        path: "add-to-collection",
        component: ResourceLibraryAddToCollectionComponent,
        data: {
          layoutTitle: "Select Resource",
          hideBackTitleDiagonalDesign: true,
        },
      },
      {
        path: "add-to-course",
        component: ResourceLibraryAddToCourseComponent,
        data: {
          hideBackTitleDiagonalDesign: true,
        },
      },
    ],
  },
  {
    path: "components",
    children: [
      {
        path: "list",
        component: ComponentListComponent,
        data: {
          hideBackTitleDiagonalDesign: true,
        },
      },
      {
        path: "select",
        component: ComponentSelectComponent,
        data: {
          hideBackTitleDiagonalDesign: true,
        },
      },
    ],
  },
  {
    path: "collections",
    children: [
      {
        path: "new",
        component: CollectionNewComponent,
        data: {
          hideBackTitleDiagonalDesign: true,
        },
      },
      {
        path: "edit",
        component: CollectionEditComponent,
        data: {
          hideBackTitleDiagonalDesign: true,
        },
      },
      {
        path: "profile",
        component: CollectionProfileComponent,
        data: {
          hideBackTitleDiagonalDesign: true,
        },
      },
      {
        path: "permissions",
        component: CollectionPermissionsComponent,
        data: {
          hideBackTitleDiagonalDesign: true,
        },
      },
      {
        path: "permissions/add-group",
        component: CollectionPermissionsAddGroupComponent,
        data: {
          hideBackTitleDiagonalDesign: true,
        },
      },
      {
        path: "parents-edit",
        component: CollectionParentsEditComponent,
        data: {
          hideBackTitleDiagonalDesign: true,
        },
      },
    ],
  },
  {
    path: "misc",
    data: {
      hideBackTitleDiagonalDesign: true,
      layoutTitle: "Resource Library",
    },
    children: [
      {
        path: "",
        redirectTo: "/components/list",
        pathMatch: "full",
      },
      {
        path: "buribook-pages-view",
        component: BuribookPagesEditComponent,
        data: {
          hideBackTitleDiagonalDesign: true,
        },
      },
      {
        path: "assessment-activity-edit",
        children: [
          {
            path: "",
            component: AssessmentActivityEditComponent,
            data: {
              layoutTitle: "Edit Assessment",
              hideBackTitleDiagonalDesign: true,
            },
          },
          {
            path: "select-item-type",
            component: AssessmentNewQuestionSelectTypeComponent,
            data: {
              layoutTitle: "Add Question item",
              hideBackTitleDiagonalDesign: true,
            },
          },
          {
            path: "new",
            component: ActivityQuestionNewComponent,
            data: {
              layoutTitle: "New Question",
              hideBackTitleDiagonalDesign: true,
            },
          },
          {
            path: "edit",
            component: ActivityQuestionEditComponent,
            data: {
              layoutTitle: "Edit Question",
              hideBackTitleDiagonalDesign: true,
            },
          },
        ],
      },
      //DEPRECATED
      {
        path: "tally-activity-edit",
        component: TallyActivityEditComponent,
        data: {
          hideBackTitleDiagonalDesign: true,
        },
      },
      {
        path: "activity-item/:uuid",
        component: ActivityQuestionEditComponent,
        data: {
          hideBackTitleDiagonalDesign: true,
        },
      },
      {
        path: "buribooks-edit",
        data: {
          layoutTitle: "Slides Editor",
          hideBackTitleDiagonalDesign: true,
          preserveQueryParamFields: [
            "parent_uuid",
            "redirect",
            "exam_uuid",
            "resource_uuid",
            "publish_resource_on_next",
          ],
        },
        children: [
          {
            path: "",
            component: BurislidesEditComponent,
          },
          {
            path: "appearance",
            component: BurislidesAppearanceComponent,
            data: {
              layoutTitle: "Edit Overall Slide Appearance",
              preserveQueryParamFields: [
                "parent_uuid",
                "redirect",
                "exam_uuid",
                "resource_uuid",
                "publish_resource_on_next",
              ],
            },
          },
          {
            path: "select-item-type",
            component: BuribooksNewQuestionSelectTypeComponent,
            data: {
              layoutTitle: "Add Question Item",
              hideBackTitleDiagonalDesign: true,
              preserveQueryParamFields: [
                "parent_uuid",
                "redirect",
                "exam_uuid",
                "resource_uuid",
                "publish_resource_on_next",
              ],
            },
          },
          {
            path: "edit",
            component: ActivityQuestionEditComponent,
            data: {
              layoutTitle: "Edit Activity",
              hideBackTitleDiagonalDesign: true,
            },
          },
          {
            path: "new",
            component: BuribooksQuestionNewComponent,
            data: {
              layoutTitle: "Add Activity",
              hideBackTitleDiagonalDesign: true,
            },
          },
          {
            path: "tally-activity-edit",
            component: TallyActivityEditComponent,
            data: {
              hideBackTitleDiagonalDesign: true,
            },
          },
        ],
      },
      // {
      //   path: 'video-activity-edit',
      //   children: [
      //     {
      //       path: '',
      //       component: VideoActivityEditComponent
      //     },
      //     {
      //       path: 'new-question',
      //       component: VideoNewQuestionSelectTypeComponent
      //     }
      //   ]
      // }
    ],
  },
];

const routes: Routes = [
  //Deprecated
  {
    path: "resource-management",
    component: ResourceManagementComponent,
    canActivate: [AuthGuard],
    children: childRoutes,
  },
  {
    path: "",
    component: ResourceManagementComponent,
    canActivate: [AuthGuard],
    children: childRoutes,
  },
  // { path: "**", redirectTo: "/not-found" },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ResourceManagementRoutingModule {}
