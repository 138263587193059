import {
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Renderer2,
} from "@angular/core";
import { Subscription, Observable } from "rxjs";

@Directive({
  selector: "[appWaitForObsAfterClick]",
})
export class WaitForObsAfterClickDirective {
  @Input("disabled")
  @HostBinding("disabled")
  isDisabled: boolean;

  @Input("appWaitForObsAfterClick")
  obs: Observable<any>;

  subscription: Subscription;

  constructor(private renderer: Renderer2, private el: ElementRef) {}

  @HostListener("click")
  onClick() {
    this.isDisabled = true;

    if (this.obs) {
      this.subscription?.unsubscribe();

      this.subscription = this.obs.subscribe(() => {
        this.isDisabled = false;
        this.subscription?.unsubscribe();
      });
    }
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.subscription && this.subscription.unsubscribe();
  }
}
