import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl, UntypedFormArray } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { firstValueFrom, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { fadeInOut } from "src/app/app-common-module/animations/fade";
import { ModalService } from "src/app/services/modal.service";
import { PermissionHelperService } from "src/app/services/permission-helper.service";
import { Group, PassService } from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-course-batch-permissions-edit-group",
  templateUrl: "./course-batch-permissions-edit-group.component.html",
  animations: [fadeInOut],
})
export class CourseBatchPermissionsEditGroupComponent implements OnInit {
  // course: Course;

  rolePermissionFormGroup: UntypedFormGroup;
  group: Group;
  grantTypeFormControl: UntypedFormControl;

  constructor(
    private route: ActivatedRoute,
    private passService: PassService,
    private permissionHelperService: PermissionHelperService,
    private location: Location,
    private modalService: ModalService
  ) {}

  async ngOnInit() {
    this.grantTypeFormControl = new UntypedFormControl();

    this.grantTypeFormControl.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        if (value === "grantall") {
          this.getPermissionFormGroups().controls.forEach(
            (pfg: UntypedFormControl) => {
              if (!pfg.get("isActive").value) {
                pfg.get("isActive").setValue(true, {
                  emitEvent: false,
                  onlySelf: true,
                });
              }
            }
          );
        }
      });

    await this.parseRolesAndPermissions();
  }

  private unsubscribe$: Subject<void> = new Subject<void>();

  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  getPermissionFormGroups(): UntypedFormArray {
    return this.rolePermissionFormGroup.get(
      "permissionFormGroups"
    ) as UntypedFormArray;
  }

  async parseRolesAndPermissions() {
    let {
      course_uuid: courseUuid,
      schedule_uuid: scheduleUuid,
      role_uuid: roleUuid,
    } = this.route.snapshot.queryParams;

    let role: any = await firstValueFrom(
      this.passService.getRole(roleUuid, ["groups", "permissions"])
    );

    this.group = role.groups[0];

    this.rolePermissionFormGroup =
      this.permissionHelperService.createRoleToggleFormGroup({
        groupId: role.groupIds[0],
        roleId: role.id,
        roleName: `crud:schedules:${scheduleUuid}`,
        roleDescription: role.groups[0].name,
        permissionAttributes: [
          {
            name: `read:schedules:${scheduleUuid}`,
            description: "Can read the batch",
          },
          {
            name: `update:schedules:${scheduleUuid}`,
            description: "Can update the batch",
          },
          {
            name: `delete:schedules:${scheduleUuid}`,
            description: "Can delete the batch",
          },
        ],
        existingPermissionList: role.permissions,
        unsubscribe: this.unsubscribe$,
      });

    if (
      this.rolePermissionFormGroup.value.permissionFormGroups.every(
        (pfg) => pfg.isActive
      )
    ) {
      this.grantTypeFormControl.setValue("grantall", {
        emitEvent: false,
      });
    } else {
      this.grantTypeFormControl.setValue("specific", {
        emitEvent: false,
      });
    }
  }

  onCheckboxChange() {
    this.grantTypeFormControl.setValue("specific", {
      emitEvent: false,
    });
  }

  async removeGroup() {
    let { course_uuid: courseUuid, role_uuid: roleUuid } =
      this.route.snapshot.queryParams;

    if (
      await this.modalService.confirm(
        "Are you sure you want to remove this group as an admin of this batch?"
      )
    ) {
      await firstValueFrom(this.passService.deleteRole(roleUuid));

      this.location.back();
    }
  }
}
