import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { first, firstValueFrom } from "rxjs";
import { ColorHelperService } from "src/app/services/color-helper.service";
import { ModalService } from "src/app/services/modal.service";
import {
  CastsService,
  V2Block,
  V2CastsService,
} from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-casteditor-block-button-search-settings",
  templateUrl: "./casteditor-block-button-search-settings.component.html",
  styles: [],
})
export class CasteditorBlockButtonSearchSettingsComponent implements OnInit {
  constructor(
    private v2CastService: V2CastsService,
    private castService: CastsService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private colorHelperService: ColorHelperService,
    private location: Location,
    private modalService: ModalService
  ) {}
  buttonSearchBlock: V2Block;
  formGroup: UntypedFormGroup;
  async ngOnInit() {
    let { block_id: blockId } = this.route.snapshot.queryParams;
    let buttonSearchBlock = await firstValueFrom(
      this.v2CastService.blockGet(blockId)
    );
    this.buttonSearchBlock = buttonSearchBlock;
    let appearance = buttonSearchBlock.data.attributes.properties.appearance;
    this.formGroup = this.formBuilder.group({
      blockId: [buttonSearchBlock.data.id],
      fontStyle: [appearance?.base?.fontFamily[0]],
      title: [buttonSearchBlock.data.attributes.properties.title],
      baseForegroundColor: [
        this.colorHelperService.constructRGBAFromCastColor(
          appearance?.base?.foregroundColor
        ) || "rgba(0,0,0,1)",
      ],
      baseBackgroundColor: [
        this.colorHelperService.constructRGBAFromCastColor(
          appearance?.base?.backgroundColor
        ) || "rgba(255,255,255,1)",
      ],
    });
  }

  async onSave() {
    let { slug } = this.route.snapshot.queryParams;
    let appearance = {
      base: {
        fontFamily: this.formGroup.value.fontStyle
          ? [this.formGroup.value.fontStyle]
          : [],
        foregroundColor: this.colorHelperService.constructCastColor(
          this.formGroup.value.baseForegroundColor
        ),
        backgroundColor: this.colorHelperService.constructCastColor(
          this.formGroup.value.baseBackgroundColor
        ),
      },
    };
    await firstValueFrom(
      this.castService.blockUpdate("0", "0", this.formGroup.value.blockId, {
        block: {
          properties: {
            ...this.buttonSearchBlock.data.attributes.properties,
            title: this.formGroup.value.title,
            appearance,
          },
        },
      })
    );

    // Redirect to old
    this.location.back();

    // REdirect to new
    // if (slug) {
    //   window.location.href = `https://${window.location.host}/casteditor?slug=${slug}`;
    // } else {
    //   window.location.href = `https://${window.location.host}/casteditor`;
    // }
  }

  async resetToDefault() {
    let result = await this.modalService.confirm(
      "Are you sure you want to reset settings to default?"
    );

    if (result) {
      this.formGroup.reset({
        blockId: this.formGroup.value.blockId,
        fontStyle: null,
        baseForegroundColor: "rgba(0,0,0,1)",
        baseBackgroundColor: "rgba(255,255,255,1)",
      });
    }
  }
}
