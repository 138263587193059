import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, Validators } from "@angular/forms";
@Component({
  selector: "app-exam-item-form-file-upload",
  templateUrl: "./exam-item-form-file-upload.component.html",
  styles: [],
})
export class ExamItemFormFileUploadComponent implements OnInit {
  @Input()
  formGroup: UntypedFormGroup;

  constructor(public formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.formGroup = this.formGroup || this.formBuilder.group({});
  }

  getUndeletedRubricFormControls() {
    let formArray = this.formGroup.get("rubrics") as UntypedFormArray;

    return formArray?.controls.filter((control) => !control.value.isDeleted);
  }

  onAddRubric() {
    let formArray = this.formGroup.get("rubrics") as UntypedFormArray;

    let newFormGroup = this.formBuilder.group({
      criteriaUuid: [null],
      name: [null, Validators.required],
      weight: [0, [Validators.min(0), Validators.max(100)]],
      isDeleted: [false],
    });

    formArray.push(newFormGroup);
  }

  removeRubric(formGroup: UntypedFormGroup) {
    formGroup.get("isDeleted").setValue(true);
    formGroup.disable();
  }
}
