<app-back-title-layout [loading]="!subscriptionFormGroups || loading">
  <h3 class="my-3 text-lg font-bold">Subscription Option</h3>

  <form>
    <app-purchases-subscription-forms
      [subscriptionFormGroups]="subscriptionFormGroups"
    >
    </app-purchases-subscription-forms>

    <div class="flex flex-row-reverse my-10">
      <button class="btn btn-primary" (click)="saveSubscriptions()">
        SAVE
      </button>
    </div>
  </form>
</app-back-title-layout>
