import { Directive, Input, HostListener } from "@angular/core";
import { Location } from "@angular/common";
import { Router } from "@angular/router";

@Directive({
  selector: "[appGoBack]",
})
export class GoBackDirective {
  @Input()
  disabled = false;

  @Input() backRouterLink: any[];
  @Input() backQueryParamsHandling;

  constructor(private location: Location, private router: Router) {}

  getHistoryLength() {
    return history.length;
  }

  @HostListener("click", ["$event"]) onClick($event) {
    if (this.getHistoryLength() <= 2) {
      this.router.navigateByUrl("/", {
        replaceUrl: true,
      });
    } else {
      if (!this.disabled) {
        if (this.backRouterLink) {
          this.router.navigate(this.backRouterLink, {
            replaceUrl: true,
            queryParamsHandling: this.backQueryParamsHandling,
          });
        } else {
          this.location.back();
        }
      }
    }
  }
}
