<app-back-title-layout
  layoutTitle="Course Permissions"
  [loading]="!roleGroupInfos"
>
  <div class="my-5">
    <app-search-bar-query> </app-search-bar-query>
  </div>

  <button
    [routerLink]="['./add-group']"
    queryParamsHandling="merge"
    class="btn btn-primary w-full py-1"
  >
    <app-icon icon="add"></app-icon>

    ADD COURSE ADMIN
  </button>

  <div class="my-5">
    <app-item-list [items]="roleGroupInfos" [itemTemplate]="itemTemplate">
    </app-item-list>
  </div>
</app-back-title-layout>

<ng-template #itemTemplate let-roleGroupInfo="item" let-index="index">
  <div class="flex items-center">
    <app-icon icon="group" class="text-gray-600 text-4xl mr-2"></app-icon>

    <a
      class="flex-1"
      [routerLink]="['/groups', 'profile']"
      [queryParams]="{
        group_uuid: roleGroupInfo.groupUuid
      }"
    >
      <div class="font-semibold leading-none">
        {{ roleGroupInfo.groupName }}
      </div>

      <!-- <div class="text-sm text-primary">
        {{ roleGroupInfo.permissionsAssignedCount }} permission/s assigned
      </div> -->
    </a>

    <button
      (click)="removeGroup(roleGroupInfo, index)"
      class="btn btn-icon bg-transparent text-error-600"
      type="button"
    >
      <app-icon icon="delete"></app-icon>
    </button>
  </div>
</ng-template>
