import { trigger, transition, style, animate } from "@angular/animations";

export const zoomInOut = trigger("zoomInOut", [
  transition(":enter", [
    style({ transform: "scale(0)" }),
    animate("100ms ease-in", style({ transform: "scale(1)" })),
  ]),
  transition(":leave", [
    animate("100ms ease-in", style({ transform: "scale(0)" })),
  ]),
]);
