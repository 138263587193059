import { DragDropModule } from "@angular/cdk/drag-drop";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DropzoneModule } from "ngx-dropzone-wrapper";
import { NgxFileDropModule } from "ngx-file-drop";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { AppCommonModule } from "../app-common-module/app-common-module.module";
import { CollectionEditComponent } from "./collections/collection-edit/collection-edit.component";
import { CollectionNewComponent } from "./collections/collection-new/collection-new.component";
import { CollectionParentsEditComponent } from "./collections/collection-parents-edit/collection-parents-edit.component";
import { CollectionPermissionsAddGroupComponent } from "./collections/collection-permissions-add-group/collection-permissions-add-group.component";
import { CollectionPermissionsComponent } from "./collections/collection-permissions/collection-permissions.component";
import { CollectionProfileComponent } from "./collections/collection-profile/collection-profile.component";
import { ComponentListComponent } from "./components/component-list/component-list.component";
import { ComponentSelectComponent } from "./components/component-select/component-select.component";
import { ActivityQuestionEditComponent } from "./misc/activity-question-edit/activity-question-edit.component";
import { ActivitySpreadViewComponent } from "./misc/activity-spread-view/activity-spread-view.component";
import { AssessmentActivityEditComponent } from "./misc/assessment-activity-edit/assessment-activity-edit.component";
import { AssessmentNewQuestionSelectTypeComponent } from "./misc/assessment-new-question-select-type/assessment-new-question-select-type.component";
import { BuribookPagesEditComponent } from "./misc/buribook-pages-edit/buribook-pages-edit.component";
import { BuribooksNewQuestionSelectTypeComponent } from "./misc/buribooks-new-question-select-type/buribooks-new-question-select-type.component";
import { BuribooksEditComponent } from "./misc/buribooks-edit/buribooks-edit.component";
import { BuribooksImageSpreadEditComponent } from "./components/buribooks-image-spread-edit/buribooks-image-spread-edit.component";
import { BuribooksSlideSpreadComponent } from "./components/buribooks-slide-spread/buribooks-slide-spread.component";
import { SelectActivityTypeViewComponent } from "./misc/select-activity-type-view/select-activity-type-view.component";
import { TallyActivityEditComponent } from "./misc/tally-activity-edit/tally-activity-edit.component";
import { ResourceLibraryAddToCollectionComponent } from "./resource-library/resource-library-add-to-collection/resource-library-add-to-collection.component";
import { ResourceLibraryAddToCourseComponent } from "./resource-library/resource-library-add-to-course/resource-library-add-to-course.component";
import { ResourceManagementRoutingModule } from "./resource-management-routing.module";
import { ResourceManagementComponent } from "./resource-management.component";
import { CanvaModalComponent } from "./components/canva/canva-modal";
import { ResourceDetailsComponent } from "./components/resource-details/resource-details.component";
import { ResourceTypeUploadAssessmentFileUploadComponent } from "./components/resource-details/resource-type-uploads/resource-type-upload-assessment-file-upload/resource-type-upload-assessment-file-upload.component";
import { ResourceTypeUploadAssessmentFreedomWallComponent } from "./components/resource-details/resource-type-uploads/resource-type-upload-assessment-freedom-wall/resource-type-upload-assessment-freedom-wall.component";
import { ResourceTypeUploadAssessmentComponent } from "./components/resource-details/resource-type-uploads/resource-type-upload-assessment/resource-type-upload-assessment.component";
import { ResourceTypeUploadAudioComponent } from "./components/resource-details/resource-type-uploads/resource-type-upload-audio/resource-type-upload-audio.component";
import { ResourceTypeUploadBuribookWithActivityComponent } from "./components/resource-details/resource-type-uploads/resource-type-upload-buribook-with-activity/resource-type-upload-buribook-with-activity.component";
import { ResourceTypeUploadBuribookComponent } from "./components/resource-details/resource-type-uploads/resource-type-upload-buribook/resource-type-upload-buribook.component";
import { ResourceTypeUploadCourseComponent } from "./components/resource-details/resource-type-uploads/resource-type-upload-course/resource-type-upload-course.component";
import { ResourceTypeUploadEmbedComponent } from "./components/resource-details/resource-type-uploads/resource-type-upload-embed/resource-type-upload-embed.component";
import { ResourceTypeUploadHtmlComponent } from "./components/resource-details/resource-type-uploads/resource-type-upload-html/resource-type-upload-html.component";
import { ResourceTypeUploadLinkComponent } from "./components/resource-details/resource-type-uploads/resource-type-upload-link/resource-type-upload-link.component";
import { ResourceTypeUploadPdfComponent } from "./components/resource-details/resource-type-uploads/resource-type-upload-pdf/resource-type-upload-pdf.component";
import { ResourceTypeUploadVideoWithActivityComponent } from "./components/resource-details/resource-type-uploads/resource-type-upload-video-with-activity/resource-type-upload-video-with-activity.component";
import { ResourceTypeUploadVideoComponent } from "./components/resource-details/resource-type-uploads/resource-type-upload-video/resource-type-upload-video.component";
import { ResourceTypeUploadEpubComponent } from "./components/resource-details/resource-type-uploads/resourcee-type-upload-epub/resource-type-upload-epub.component";
import { ResourceEditComponent } from "./resources/resource-edit/resource-edit.component";
import { ResourceListComponent } from "./resources/resource-list/resource-list.component";
import { ResourceNewComponent } from "./resources/resource-new/resource-new.component";
import { ResourceProfileComponent } from "./resources/resource-profile/resource-profile.component";
import { ResourcePublishComponent } from "./resources/resource-publish/resource-publish.component";
import { ResourceSelectCollectionsComponent } from "./resources/resource-select-collections/resource-select-collections.component";
import { ResourceSelectNewComponent } from "./resources/resource-select-new/resource-select-new.component";
import { ResourceTypeUploadTextComponent } from "./components/resource-details/resource-type-uploads/resource-type-upload-text/resource-type-upload-text.component";
import { ExamItemFormComponent } from "./components/exam-item-form/exam-item-form.component";
import { ExamItemFormChoicesComponent } from "./components/exam-item-form-choices/exam-item-form-choices.component";
import { ExamItemFormShortanswerComponent } from "./components/exam-item-form-shortanswer/exam-item-form-shortanswer.component";
import { ExamItemFormFileUploadComponent } from "./components/exam-item-form-file-upload/exam-item-form-file-upload.component";
import { ActivityQuestionNewComponent } from "./misc/activity-question-new/activity-question-new.component";
import { BuribooksQuestionNewComponent } from "./misc/buribooks-question-new/buribooks-question-new.component";
import { ExamItemFormChoicesTallyComponent } from "./components/exam-item-form-choices-tally/exam-item-form-choices-tally.component";
import { ResourceTypeUploadXapiComponent } from "./components/resource-details/resource-type-uploads/resource-type-upload-xapi/resource-type-upload-xapi.component";
import { BurislidesEditComponent } from "./misc/burislides-edit/burislides-edit.component";
import { BurislidesModalComponent } from "./components/burislides-modal/burislides-modal.component";
import { MatDialogModule } from "@angular/material/dialog";
import { BurislidesBlockSelectComponent } from "./components/burislides-block-select/burislides-block-select.component";
import { BurislidesBlockPreviewComponent } from "./components/burislides-block-preview/burislides-block-preview.component";
import { BurislidesBlockActivityComponent } from "./components/burislides-block-activity/burislides-block-activity.component";
import { BurislidesBlockLinkComponent } from "./components/burislides-block-link/burislides-block-link.component";
import { BurislidesBlockMarkdownComponent } from "./components/burislides-block-markdown/burislides-block-markdown.component";
import { BurislidesBlockActivityPreviewComponent } from "./components/burislides-block-activity-preview/burislides-block-activity-preview.component";
import { ResourceBurislideAnalyticsComponent } from "./resources/resource-burislide-analytics/resource-burislide-analytics.component";
import { ExamsModule } from "../exams/exams.module";
import { BurslidesAnalyticsUsersModalComponent } from "./components/burslides-analytics-users-modal/burslides-analytics-users-modal.component";
import { ResourceTypeUploadBurislidesComponent } from "./components/resource-details/resource-type-uploads/resource-type-upload-burislides/resource-type-upload-burislides.component";
import { BurislidesBlockHtmlComponent } from "./components/burislides-block-html/burislides-block-html.component";
import { BurislidesSlideAppearanceModalComponent } from "./components/burislides-slide-appearance-modal/burislides-slide-appearance-modal.component";
import { BurislidesAppearanceComponent } from "./misc/burislides-appearance/burislides-appearance.component";
import { BurislidesFeedbackModalComponent } from "./components/burislides-feedback-modal/burislides-feedback-modal.component";
import { ExamItemFormChoiceFeedbacksComponent } from "./components/exam-item-form-choice-feedbacks/exam-item-form-choice-feedbacks.component";
import { BurislidesBlockActivityGroupComponent } from "./components/burislides-block-activity-group/burislides-block-activity-group.component";
import { ExamItemFormChoicesWithQuestionPathsComponent } from "./components/exam-item-form-choices-with-question-paths/exam-item-form-choices-with-question-paths.component";
import { BurislidesSelectActivityModalLayoutComponent } from "./components/burislides-select-activity-modal-layout/burislides-select-activity-modal-layout.component";
import { ResourceTypeUploadCasticulateComponent } from './components/resource-details/resource-type-uploads/resource-type-upload-casticulate/resource-type-upload-casticulate.component';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    ResourceEditComponent,
    ResourceDetailsComponent,
    ResourceNewComponent,
    ResourceProfileComponent,
    ResourceManagementComponent,
    ResourceTypeUploadAudioComponent,
    ResourceTypeUploadBuribookComponent,
    ResourceTypeUploadBuribookWithActivityComponent,
    ResourceTypeUploadHtmlComponent,
    ResourceTypeUploadLinkComponent,
    ResourceTypeUploadPdfComponent,
    ResourceTypeUploadVideoComponent,
    ResourceTypeUploadVideoWithActivityComponent,
    ComponentListComponent,
    CollectionNewComponent,
    ResourcePublishComponent,
    CollectionEditComponent,
    BuribookPagesEditComponent,
    ActivityQuestionEditComponent,
    BuribooksNewQuestionSelectTypeComponent,
    SelectActivityTypeViewComponent,
    ResourceTypeUploadAssessmentComponent,
    AssessmentActivityEditComponent,
    ActivitySpreadViewComponent,
    BuribooksEditComponent,
    BuribooksImageSpreadEditComponent,
    ComponentSelectComponent,
    ResourceTypeUploadCourseComponent,
    AssessmentNewQuestionSelectTypeComponent,
    ResourceTypeUploadAssessmentFreedomWallComponent,
    TallyActivityEditComponent,
    ResourceSelectCollectionsComponent,
    CollectionProfileComponent,
    CollectionPermissionsComponent,
    CollectionParentsEditComponent,
    ResourceListComponent,
    ResourceSelectNewComponent,
    ResourceLibraryAddToCollectionComponent,
    CollectionPermissionsAddGroupComponent,
    ResourceTypeUploadAssessmentFileUploadComponent,
    ResourceTypeUploadEpubComponent,
    ResourceLibraryAddToCourseComponent,
    BuribooksSlideSpreadComponent,
    CanvaModalComponent,
    ResourceTypeUploadEmbedComponent,
    ResourceTypeUploadTextComponent,
    ExamItemFormComponent,
    ExamItemFormChoicesComponent,
    ExamItemFormShortanswerComponent,
    ExamItemFormFileUploadComponent,
    ActivityQuestionNewComponent,
    BuribooksQuestionNewComponent,
    ExamItemFormChoicesTallyComponent,
    ResourceTypeUploadXapiComponent,
    BurislidesEditComponent,
    BurislidesModalComponent,
    BurislidesBlockSelectComponent,
    BurislidesBlockPreviewComponent,
    BurislidesBlockActivityComponent,
    BurislidesBlockLinkComponent,
    BurislidesBlockMarkdownComponent,
    BurislidesBlockActivityPreviewComponent,
    ResourceBurislideAnalyticsComponent,
    BurslidesAnalyticsUsersModalComponent,
    ResourceTypeUploadBurislidesComponent,
    BurislidesBlockHtmlComponent,
    BurislidesSlideAppearanceModalComponent,
    BurislidesAppearanceComponent,
    BurislidesFeedbackModalComponent,
    ExamItemFormChoiceFeedbacksComponent,
    BurislidesBlockActivityGroupComponent,
    ExamItemFormChoicesWithQuestionPathsComponent,
    BurislidesSelectActivityModalLayoutComponent,
    ResourceTypeUploadCasticulateComponent,
  ],
  imports: [
    // CommonModule,

    ResourceManagementRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AppCommonModule,
    DropzoneModule,
    DragDropModule,
    NgxFileDropModule,
    MatDialogModule,
    ExamsModule,
    MatSlideToggleModule,
  ],
})
export class ResourceManagementModule {}
