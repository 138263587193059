import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../app-common-module/guards/auth.guard";
import { GeneralSettingsAddFieldComponent } from "./general-settings-add-field/general-settings-add-field.component";
import { GeneralSettingsCustomizeComponent } from "./general-settings-customize/general-settings-customize.component";
import { GeneralSettingsOptionsComponent } from "./general-settings-options/general-settings-options.component";
import { GeneralSettingsComponent } from "./general-settings.component";
import { GeneralSettingsGuestLoginComponent } from "./general-settings-guest-login/general-settings-guest-login.component";

const routes: Routes = [
  {
    path: "general-settings",
    // path: "",
    component: GeneralSettingsComponent,
    canActivate: [AuthGuard],
    data: { layoutTitle: "General Settings" },
    children: [
      {
        path: "",
        component: GeneralSettingsOptionsComponent,
      },
      { path: "customize", component: GeneralSettingsCustomizeComponent },
      { path: "add-fields", component: GeneralSettingsAddFieldComponent },
      { path: "guest-login", component: GeneralSettingsGuestLoginComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class GeneralSettingsRoutingModule {}
