<div
  *ngIf="loading"
  [@fadeInOut]
  class="
    absolute
    flex bg-opacity-50
    w-3/4
    h-1/2
    items-center
    justify-center
  "
>
  <app-loading [loading]="true" position="relative"></app-loading>
</div>
<h2 class="form-heading mb-4">Batches</h2>
<div class="flex mb-4">
  <ng-container *ngIf="course">
    <img class="h-20 w-20 mr-4" [src]="course.cover" [alt]="course.cover" />

    <div>
      <div class="uppercase text-gray-400">course</div>
      <div class="font-bold">
        {{ course.name }}
      </div>
    </div>
  </ng-container>
</div>
<div class="w-3/4 mb-8">
  <app-search-bar-query placeholder="Search" [queryField]="'batch'"></app-search-bar-query>
</div>
<app-item-select-with-header
  [headers]="headers"
  [source]="listBatchObservable"
  [disableCheckbox]="true"
  [category]="'batch'"
  [key]="'uuid'"
  [limit]="limit"
  [totalCount]="totalBatchCount"
  [itemTemplate]="itemTemplate"
>
</app-item-select-with-header>
<ng-template #itemTemplate let-batchItem="item">
  <!-- <ng-container *ngIf="listBatchObservable"> -->
  <div class="flex w-full">
    <div class="block sm:flex mt-2 sm:mt-0">
      <a
        class="flex items-center block w-full"
        (click)="
          outputBatch({
            uuid: batchItem.uuid,
            name: batchItem.name,
            index: currentIndex,
            type: 'batch'
          })
        "
        mat-dialog-close
      >
        <div class="grid grid-cols-1 my-1 w-full sm:flex">
          <div class="block sm:flex">
            <div class="text-sm leading-none flex w-96 text-gray-400">
              <div class="w-full">
                <app-icon [icon]="'date_range'"></app-icon>{{ batchItem.name }}
              </div>
            </div>
            <div class="text-sm leading-none flex w-56 text-gray-400">
              <div
                class="w-max rounded-full border p-1 {{
                  batchItem.statusClass
                }}"
              >
                {{ batchItem.status }}
              </div>
            </div>
            <div class="text-sm flex w-96 text-gray-400">
              <div class="w-full">
                {{ batchItem.start | date }} -
                <ng-container *ngIf="batchItem.end === 'Forever'">{{
                  batchItem.end
                }}</ng-container>
                <ng-container *ngIf="batchItem.end !== 'Forever'">{{
                  batchItem.end | date
                }}</ng-container>
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
  <!-- </ng-container> -->
</ng-template>
