import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  Resource,
  ResourcesService,
  CoursesService,
} from "src/app/sinigangnababoywithgabi";

import { Location } from "@angular/common";
import {
  ActivityLogService,
  VERBS,
} from "src/app/services/activity-log.service";
import querystring from "query-string";
import { firstValueFrom } from "rxjs";
import { ModalService } from "src/app/services/modal.service";
import { ResourceHelperService } from "src/app/services/resource-helper.service";

@Component({
  selector: "app-resource-library-add-to-course",
  templateUrl: "./resource-library-add-to-course.component.html",
})
export class ResourceLibraryAddToCourseComponent implements OnInit {
  searchString: string;

  redirect: string;
  source: string;
  order: number;
  resources: Resource[];

  loading: boolean;

  constructor(
    private resourcesService: ResourcesService,
    private coursesService: CoursesService,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    private activityLogService: ActivityLogService,
    private modalService: ModalService,
    private resourceHelperService: ResourceHelperService
  ) {}

  ngOnInit(): void {}

  async onSelect(resourceUuid: string) {
    try {
      let {
        section_uuid: sectionUuid,
        course_uuid: courseUuid,
        parent_uuid: parentUuid,
        section_order: sectionOrder,
      } = this.route.snapshot.queryParams;

      let resource = await firstValueFrom(
        this.resourcesService.getResourceByUuid(resourceUuid)
      );
      if (
        resource.type === "buribook-with-activity" ||
        resource.type === "burislides"
      ) {
        let confirm = await this.modalService.confirm(
          "Cloning this resource will allow you to create your own activities inside it. Do you want to clone this resource?",
          {
            confirmText: "Yes, clone it.",
            cancelText: "No",
          }
        );
        this.loading = true;
        if (confirm) {
          let newResource = await this.resourceHelperService.createResource({
            isInsideCourse: true,
            courseUuid: courseUuid,
            sectionUuid: sectionUuid,
            sectionOrder: sectionOrder,
            resource: {
              name: resource.name,
              description: resource.description,
              coverUri: resource.coverUri,
              publishedAt: resource.publishedAt,
              flavorTextSpan: resource.flavorTextSpan,
              tags: resource.tags,
              type: resource.type,
              subtype: resource.subtype,
              content: {
                ...resource.content,
                spreads: resource.content.spreads?.filter(
                  (s) =>
                    s.spreadType === "image-spread" || s.spreadType === "slide"
                ),

                // For backwards compatibility
                pages: resource.content.pages?.filter(
                  (p) => p.type === "image"
                ),
                examDetails: {},
                sourceResourceUuid: resource.uuid,
              },
            },
          });
          this.goBackOrRedirect(newResource.uuid);
          return;
        }
      }
      await firstValueFrom(
        this.resourcesService.addResourcesInCollection(parentUuid, {
          resourceUuids: [resource.uuid],
        })
      );

      let referenceInfo;
      let orderId;

      if (resource.type === "assessment") {
        referenceInfo = {
          referenceId: resource.content?.examDetails?.examTimeslotUuid,
          customPassingMark: 50,
        };
      }

      // https://buri.dev/T1838
      if (parseInt(sectionOrder) === -1) {
        orderId = 0;
        // Get all section blocks
        let blocks = await firstValueFrom(
          this.coursesService.sectionBlockList(sectionUuid)
        );

        // Adjust all blocks
        for (let i = 0; i < blocks.length; i++) {
          let block = blocks[i];

          await firstValueFrom(
            this.coursesService.blockUpdate(block.uuid, {
              ...block,
              orderId: (i + 1 + "").padStart(2, "0"),
            })
          );
        }
      } else {
        // orderId = sectionOrder;
        orderId = parseInt(sectionOrder) + 1;
        let blocks = await firstValueFrom(
          this.coursesService.sectionBlockList(sectionUuid)
        );
        // Adjust all blocks
        for (let i = orderId; i < blocks.length; i++) {
          let block = blocks[i];
          await firstValueFrom(
            this.coursesService.blockUpdate(block.uuid, {
              ...block,
              orderId: (i + 1 + "").padStart(2, "0"),
            })
          );
        }
      }

      let result = await firstValueFrom(
        this.coursesService.sectionBlockCreate(sectionUuid, {
          title: resource.name,
          coverUri: resource.coverUri,
          content: {
            resourceDetails: {
              uuid: resource.uuid,
              name: resource.name,
              description: resource.description,
              coverUri: resource.coverUri,
              type: resource.type,
              subtype: resource.subtype,
              flavorTextSpan: resource.flavorTextSpan,
              tags: resource.tags,
              publishedAt: resource.publishedAt,
            },
          },
          orderId: (orderId + "")?.padStart(2, "0"),
          referenceInfo,
        })
      );

      // this.location.back();
      this.redirectToNewCourse();

      this.activityLogService.didCourseBlock(VERBS.created, result, {
        context: {
          contextActivities: {
            parent: [
              this.activityLogService.constructCourseSectionObject({
                uuid: sectionUuid,
              }),
            ],
            category: [
              this.activityLogService.constructCourseObject({
                uuid: courseUuid,
              }),
            ],
          },
        },
      });
    } catch (err) {
      this.loading = false;
    }
  }
  goBackOrRedirect(uuid) {
    let {
      redirect,
      redirect_field: redirectField,
      is_inside_course: isInsideCourse,
      course_uuid: courseUuid,
      parent_uuid: parentUuid,
      course_resource_uuid: courseResourceUuid,
    } = this.route.snapshot.queryParams;

    if (redirect) {
      let redirectUrl = new URL("http://placeholder" + redirect);

      //Parse params
      let redirectParams = querystring.parse(redirectUrl.search);

      //Add to params
      redirectParams[redirectField || "selected_resource_uuid"] = uuid;

      this.router.navigateByUrl(
        redirectUrl.pathname + "?" + querystring.stringify(redirectParams),
        { replaceUrl: true }
      );
    } else {
      // this.location.back();
      window.location.href = `/courses/content-edit?course_uuid=${courseUuid}&resource_collection_uuid=${parentUuid}&resource_uuid=${courseResourceUuid}&from_screen=add_resource`;
    }
  }

  redirectToNewCourse() {
    let {
      is_inside_course: isInsideCourse,
      course_uuid: courseUuid,
      parent_uuid: parentUuid,
      course_resource_uuid: courseResourceUuid,
    } = this.route.snapshot.queryParams;
    window.location.href = `/courses/content-edit?course_uuid=${courseUuid}&resource_collection_uuid=${parentUuid}&resource_uuid=${courseResourceUuid}&from_screen=add_resource`;
  }
}
