<app-back-title-layout>
  <section>
    <h2 class="font-bold mb-5">Overview</h2>

    <div class="relative">
      <app-loading-with-retry
        [observable]="overviewObservable"
        [tryOnLoad]="true"
      ></app-loading-with-retry>

      <div class="flex justify-between my-2">
        <div
          class="leading-tight w-full py-1 bg-purple-200 text-purple-600 rounded flex flex-col justify-center align-center text-center"
        >
          <div class="text-lg font-bold">
            {{ completionStats?.students }}
          </div>
          <div>students</div>
        </div>
        <div
          class="mx-2 leading-tight w-full py-1 bg-blue-200 text-blue-600 rounded flex flex-col justify-center align-center text-center"
        >
          <div class="text-lg font-bold">
            {{ completionStats?.inProgress }}
          </div>
          <div>in progress</div>
        </div>
        <div
          class="leading-tight w-full py-1 bg-gray-200 text-gray-600 rounded flex flex-col justify-center align-center text-center"
        >
          <div class="text-lg font-bold">
            {{ completionStats?.notStarted }}
          </div>
          <div>not started</div>
        </div>
      </div>

      <div class="flex justify-between my-2">
        <div
          class="mr-2 leading-tight w-full py-3 bg-green-200 text-green-600 rounded flex flex-col justify-center align-center text-center"
        >
          <div class="text-2xl font-bold">
            {{ completionStats?.completed }}
          </div>
          <div>passed</div>
        </div>
        <div
          class="leading-tight w-full py-1 bg-red-200 text-red-600 rounded flex flex-col justify-center align-center text-center"
        >
          <div class="text-2xl font-bold">
            {{ completionStats?.needsAttention }}
          </div>
          <div>needs attention</div>
        </div>
      </div>
    </div>
  </section>

  <section class="my-10">
    <h2 class="font-bold my-2">Student Batches</h2>

    <div class="relative">
      <app-loading-with-retry
        class="h-24"
        [observable]="scheduleTableObservable"
        [tryOnLoad]="true"
      ></app-loading-with-retry>

      <ng-container *ngIf="scheduleTable">
        <div class="mb-5">
          <app-search-bar
            #search
            placeholder="Search"
            [(ngModel)]="search.value"
          ></app-search-bar>
        </div>

        <table class="table-auto w-full">
          <tbody>
            <ng-container
              *ngFor="let item of scheduleTable | filterAll: search.value"
            >
              <tr class="border-b border-t cursor-pointer">
                <td class="py-2 align-top w-1 pr-3">
                  <app-icon
                    class="leading-none text-gray-600 text-2xl"
                    icon="date_range"
                  ></app-icon>
                </td>
                <td class="py-2 align-top">
                  <a
                    routerLink="../batch-stats"
                    [queryParams]="{ schedule_uuid: item.schedule.uuid }"
                    queryParamsHandling="merge"
                  >
                    <div>
                      <span
                        class="text-xs rounded-full text-white uppercase py-1 px-2 {{
                          item.statusClass
                        }}"
                      >
                        {{ item.statusText }}
                      </span>
                    </div>

                    <div class="font-bold">
                      {{ item.schedule.name }}
                    </div>

                    <div
                      class="text-gray-600 text-sm"
                      *ngIf="
                        item.userCount !== undefined && item.userCount !== null
                      "
                    >
                      {{ item.userCount || 0 }}
                      {{ item.userCount > 0 ? "students" : "student" }}
                    </div>

                    <div class="text-gray-600 text-sm">
                      {{ item.dateText }}
                    </div>
                  </a>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </ng-container>
    </div>
  </section>
</app-back-title-layout>
