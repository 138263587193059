import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  debounceTime,
  defer,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  map,
  Observable,
  of,
  switchMap,
  tap,
} from "rxjs";
import {
  Action,
  Header,
} from "src/app/app-common-module/components/list-table-header/list-table-header.component";
import { DashboardService } from "src/app/services/dashboard.service";
import { ModalService } from "src/app/services/modal.service";
import {
  Group,
  PassService,
  Role,
  User,
} from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-group-profile",
  templateUrl: "./group-profile.component.html",
})
export class GroupProfileComponent implements OnInit {
  group: Group;
  loading: boolean = false;
  oneMonth = 2629800000;
  columnChange = 0;
  currentOrder: string;
  close: boolean = false;
  orderItems = [
    {
      value: "created_at desc",
      text: "Newest",
    },
    {
      value: "created_at",
      text: "Oldest",
    },
    {
      value: "first_name",
      text: "Sort A-Z",
    },
    {
      value: "first_name desc",
      text: "Sort Z-A",
    },
    {
      value: "email",
      text: "Email",
    },
  ];

  headers: Header[] = [
    { name: "Email", value: "email", width: "w-96" },
    { name: "Name", value: "last_name", width: "w-96" },
    { name: "Date Added", value: "first_name", width: "w-56" },
    { name: "Status", value: "first_name", width: "w-56" },
  ];

  userObs: Observable<User[]>;
  totalUserCount: number;
  limit = 20;

  selectedUserIds: string[];
  batchActions: Action[];

  roles: Role[];

  constructor(
    private route: ActivatedRoute,
    private passService: PassService,
    private dashboardService: DashboardService,
    private modalService: ModalService,
    private router: Router
  ) {}

  async ngOnInit() {
    let { group_uuid: groupUuid } = this.route.snapshot.queryParams;

    this.group = await firstValueFrom(
      this.passService.getGroup(groupUuid, ["roles"])
    );

    this.roles = this.group.roles.filter((role) => !role.name.includes(":"));
    this.constructUserObs();
    this.constructGroupActions();
  }

  constructUserObs() {
    let { group_uuid: groupUuid } = this.route.snapshot.queryParams;

    this.userObs = this.route.queryParams.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((params) => {
        this.loading = true;
        let {
          order,
          q,
          page,
          date,
          first_name,
          last_name,
          email,
          last_signin,
        } = params;
        let date2 = date ? date + "T23:59:59.999Z" : null;
        let last_signin2 = last_signin ? last_signin + "T23:59:59.999Z" : null;
        let q2 = q ? q.split(" ") : null;
        let firstNames = first_name?.split(" ");
        let lastNames = last_name?.split(" ");
        let emails = email?.split(" ");
        this.currentOrder = order;

        return this.passService.listUsers(
          null,
          this.limit,
          this.limit * (page || 0),
          ["groups", "user_profile"],
          order,
          [groupUuid],
          last_signin,
          last_signin2,
          null,
          date,
          date2,
          q2,
          firstNames,
          lastNames,
          emails
        );
      }),
      tap((r) => {
        this.totalUserCount = r.count;
      }),
      switchMap((r) => {
        return this.dashboardService.getCast().pipe(
          map((cast) => {
            return {
              cast,
              users: r.users,
            };
          })
        );
      }),
      map(({ cast, users }) => {
        this.loading = false;
        return users.map((user) => {
          return {
            id: user.id,
            name: user.firstName + " " + user.lastName,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            confirmedAt: user.confirmedAt,
            createdAt: user.createdAt,
            profile: user.userProfile, //array muna (2/11/22), magiging object
            //groups: user.groups,
            active:
              Date.now() - this.oneMonth <= Date.parse(user.lastSignInAt) &&
              Date.parse(user.lastSignInAt) <= Date.now() &&
              !user.groups
                .map((g) => g.id)
                .includes(cast.data.attributes.deactivatedUsersGroupId),
          };
        });
      })
    );
  }

  constructGroupActions() {
    this.batchActions = [
      {
        name: "Remove",
        action: defer(() =>
          of(this.selectedUserIds).pipe(
            switchMap((ids) =>
              this.modalService.confirm(
                `This action will remove ${ids.length} users from the group. Continue?`
              )
            ),
            filter((r) => r),
            switchMap(() => {
              return this.passService.updateGroup(this.group.id, {
                group: {
                  userIds: this.group.userIds?.filter(
                    (id) => !this.selectedUserIds.includes(id)
                  ),
                },
              });
            })
          )
        ),
      },
    ];
  }

  selectedItemList(selected: string[]) {
    this.selectedUserIds = selected;
  }

  openModifyPrompt(template) {
    this.modalService.openModal(template);
  }
  columnHeaders(event: any) {
    this.headers = event.headers;

    if (!event.local) {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParamsHandling: "merge",
        replaceUrl: true,
        queryParams: {
          column: this.columnChange++,
        },
      });
    }
  }
  closeModal(event) {
    this.close = !this.close;
  }
  orderBy(field) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParamsHandling: "merge",
      replaceUrl: true,
      queryParams: {
        order: field,
      },
    });
  }
  async openModal(template) {
    let modalClass = [
      "flex",
      "justify-center",
      "items-center",
      "sm:w-2/3",
      "lg:w-1/2",
      "w-4/5",
    ];
    await this.modalService.openModal(template, {
      panelClass: modalClass,
    });
  }
}
