import { Input } from "@angular/core";
import { Component, OnInit } from "@angular/core";
import { LinkList } from "../profile-link-list/profile-link-list.component";

@Component({
  selector: "app-index-link-list",
  templateUrl: "./index-link-list.component.html",
  styleUrls: ["./index-link-list.component.scss"],
})
export class IndexLinkListComponent implements OnInit {
  @Input()
  linkLists: LinkList[];

  constructor() {}

  ngOnInit(): void {}
}
