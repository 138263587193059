<div class="flex items-center">
  <button
    [routerLink]="['new', 'index']"
    class="py-1 px-2 rounded bg-primary-600 uppercase text-sm text-white"
    [disabled]="!(canAddUsersObservable | async)"
    [attr.data-tooltip]="
      !(canAddUsersObservable | async)
        ? 'Contact your admin to gain access'
        : ''
    "
  >
    <app-icon icon="add_circle" class="text-white"></app-icon>
    Add user
  </button>

  <div class="sm:hidden">
    <app-dropdown-layout position="left">
      <div dropdownToggle class="bg-gray-200 px-1 mx-2">
        <app-icon icon="more_horiz" class="text-gray-400"> </app-icon>
      </div>
      <div dropdownContent>
        <button
          [routerLink]="['new', 'csv']"
          class="w-full py-1 px-2 text-left text-primary hover:bg-primary hover:text-white"
        >
          <app-icon icon="insert_drive_file"></app-icon>
          Import CSV
        </button>

        <button
          class="w-full py-1 px-2 text-left text-primary hover:bg-primary disabled:text-gray-600 hover:text-white"
          [appWaitForObsAfterClick]="exportUsersObservable"
        >
          <app-icon icon="insert_drive_file"></app-icon>
          Export CSV
        </button>
      </div>
    </app-dropdown-layout>
  </div>

  <div class="hidden sm:flex">
    <button
      [routerLink]="['new', 'csv']"
      class="text-primary-600 uppercase text-sm px-2 py-1"
    >
      <app-icon icon="insert_drive_file"></app-icon>
      Import CSV
    </button>

    <button
      class="btn text-primary-600 bg-transparent disabled:bg-transparent text-sm"
      [appWaitForObsAfterClick]="exportUsersObservable"
    >
      <app-icon icon="insert_drive_file"></app-icon>
      Export CSV
    </button>
  </div>
</div>

<div class="mt-2 block w-full sm:flex">
  <app-search-bar-query
    placeholder="Search"
    class="w-3/4"
  ></app-search-bar-query>
  <div class="flex mt-2 sm:mt-0">
    <button
      class="uppercase flex bg-gray-200 items-center px-1 text-gray-400 ml-2"
      (click)="openModal(filters)"
    >
      <app-icon icon="filter_list"> </app-icon>
      <div class="hidden sm:block text-sm">filter</div>
    </button>
    <div class="flex bg-gray-200 px-1 text-gray-400 mx-2 items-center">
      <app-dropdown-layout position="left">
        <div dropdownToggle class="uppercase flex items-center">
          <app-icon icon="import_export"> </app-icon>
          <div class="hidden sm:flex text-sm">Sort</div>
        </div>

        <div dropdownContent>
          <ng-container *ngFor="let orderItem of orderItems">
            <button
              class="w-full py-1 px-2 text-left text-primary hover:bg-primary hover:text-white"
              (click)="orderBy(orderItem.value)"
            >
              <app-icon
                icon="check"
                [ngClass]="{ invisible: orderItem.value !== currentOrder }"
              ></app-icon>
              {{ orderItem.text }}
            </button>
          </ng-container>
        </div>
      </app-dropdown-layout>
    </div>
    <button
      class="uppercase flex bg-gray-200 items-center px-1 text-gray-400"
      (click)="openModal(columns)"
    >
      <app-icon icon="view_column"> </app-icon>
      <div class="hidden sm:block text-sm">columns</div>
    </button>
  </div>
</div>
<div class="w-full">
  <app-filter-section></app-filter-section>
</div>
<div class="my-5">
  <app-item-select-with-header
    [headers]="headers"
    [totalCount]="totalUserCount"
    [key]="'uuid'"
    [category]="'user'"
    [source]="userListObservable"
    [itemTemplate]="itemTemplate"
    [limit]="limit"
    [enableSort]="true"
    (selectedItemList)="selectedItemList($event)"
    [batchActions]="batchActions"
    [loading]="loading"
  >
  </app-item-select-with-header>
</div>

<ng-template #itemTemplate let-user="item">
  <div class="flex">
    <div class="block sm:flex mt-2 sm:mt-0">
      <a
        class="block w-full sm:flex ml-2 sm:ml-0"
        href="/users-and-groups/users/profile/courses-and-certification?user_uuid={{user.uuid}}"
      >
        <ng-container *ngIf="headers">
          <div
            class="grid grid-cols-1 my-1 w-full sm:flex"
            *ngFor="let header of headers"
          >
            <!-- Email -->
            <div class="block sm:w-96 sm:flex" *ngIf="header.name === 'Email'">
              <div class="sm:ml-2 mb-2 sm:mb-0">
                <app-icon
                  icon="person"
                  class="text-white text-2xl leading-none px-1 mr-1 bg-gray-200 rounded-full items-center justify-center"
                ></app-icon>
              </div>
              <div
                class="text-sm text-gray-700 sm:items-center flex-wrap truncate sm:w-full"
              >
                {{ user.email }}
                <span
                  *ngIf="!user.confirmedAt"
                  class="text-sm text-red-500 italic"
                >
                  <app-icon icon="error" class="align-bottom"></app-icon>
                  <!-- <span> email not yet confirmed </span> -->
                </span>
              </div>
            </div>
            <!-- Name -->
            <div
              class="truncate text-sm sm:items-center sm:w-96"
              *ngIf="header.name === 'Name'"
            >
              {{ user.name }}
            </div>
            <!-- Date Added -->
            <div
              class="text-sm text-disabled-dark sm:items-center truncate sm:w-56"
              *ngIf="header.name === 'Date Added'"
            >
              <ng-container>
                {{ user.createdAt | date }}
              </ng-container>
            </div>
            <!-- Groups -->
            <div
              class="sm:w-96 sm:flex truncate"
              *ngIf="header.name === 'Group'"
            >
              <div class="flex items-center bg-primary-50 rounded-full">
                <ng-container *ngIf="user.groups.length > 0">
                  <app-icon
                    icon="group"
                    class="ml-1 text-primary-500 mr-1"
                  ></app-icon>
                  <span
                    class="sm:truncate text-sm text-primary-500 mt-2 sm:mt-0 px-1 items-center"
                  >
                    {{ user.groups[0].name }}
                  </span>
                </ng-container>
              </div>
              <div *ngIf="user.groups.length > 1" class="text-primary-500">
                +{{ user.groups.length - 1 }} more
              </div>
            </div>
            <!-- Birthdate -->
            <div
              class="truncate text-sm sm:items-center sm:w-56"
              *ngIf="header.name === 'Birthdate' && user.profile"
            >
              <ng-container *ngIf="user.profile.length === 1">
                {{ user.profile[0].birthdate }}
              </ng-container>
            </div>

            <!-- Active -->
            <div
              class="sm:w-56 truncate text-sm"
              *ngIf="header.name === 'Status'"
            >
              <div
                class="shrink w-max bg-success-100 text-success-600 rounded-full px-1 mt-2 sm:mt-0 text-sm"
                *ngIf="user.active"
              >
                Active
              </div>
            </div>
            <!-- Gender -->
            <div
              class="truncate text-sm sm:items-center sm:w-56"
              *ngIf="header.name === 'Sex' && user.profile"
            >
              <ng-container *ngIf="user.profile.length === 1">
                {{ user.profile[0].gender }}
              </ng-container>
            </div>
            <!-- Place of birth -->
            <div
              class="truncate text-sm sm:items-center sm:w-56"
              *ngIf="header.name === 'Place of Birth' && user.profile"
            >
              <ng-container *ngIf="user.profile.length === 1">
                {{ user.profile[0].birthplace }}
              </ng-container>
            </div>
            <!-- Civil Status -->
            <div
              class="truncate text-sm sm:items-center sm:w-56"
              *ngIf="header.name === 'Civil Status' && user.profile"
            >
              <ng-container *ngIf="user.profile.length === 1">
                {{ user.profile[0].maritalStatus }}
              </ng-container>
            </div>
            <!-- Citizenship -->
            <div
              class="truncate text-sm sm:items-center sm:w-56"
              *ngIf="header.name === 'Citizenship' && user.profile"
            >
              <ng-container *ngIf="user.profile.length === 1">
                {{ user.profile[0].citizenship }}
              </ng-container>
            </div>
            <!-- Address -->
            <div
              class="truncate text-sm sm:items-center sm:w-56"
              *ngIf="header.name === 'Address' && user.profile"
            >
              <ng-container *ngIf="user.profile.length === 1">
                {{ user.profile[0].permanentAddress }}
              </ng-container>
            </div>
            <!-- Telephone no. -->
            <div
              class="truncate text-sm sm:items-center sm:w-56"
              *ngIf="header.name === 'Telephone no.' && user.profile"
            >
              <ng-container *ngIf="user.profile.length === 1">
                {{ user.profile[0].phoneNumber }}
              </ng-container>
            </div>
            <!-- Cellphone no. -->
            <div
              class="truncate text-sm sm:items-center sm:w-56"
              *ngIf="header.name === 'Cellphone no.' && user.profile"
            >
              <ng-container *ngIf="user.profile.length === 1">
                {{ user.profile[0].mobileNumber }}
              </ng-container>
            </div>
            <!-- Company/Agency -->
            <div
              class="truncate text-sm sm:items-center sm:w-56"
              *ngIf="header.name === 'Company/Agency' && user.profile"
            >
              <ng-container *ngIf="user.profile.length === 1">
                {{ user.profile[0].companyName }}
              </ng-container>
            </div>
            <!-- Company Category -->
            <div
              class="truncate text-sm sm:items-center sm:w-56"
              *ngIf="header.name === 'Company Category' && user.profile"
            >
              <ng-container *ngIf="user.profile.length === 1">
                {{ user.profile[0].companyCategory }}
              </ng-container>
            </div>
            <!-- Designation -->
            <div
              class="truncate text-sm sm:items-center sm:w-56"
              *ngIf="header.name === 'Designation' && user.profile"
            >
              <ng-container *ngIf="user.profile.length === 1">
                {{ user.profile[0].companyDesignation }}
              </ng-container>
            </div>
          </div>
        </ng-container>
      </a>
    </div>
  </div>
</ng-template>
<ng-template #filters> <app-filters></app-filters></ng-template>
<ng-template #columns>
  <app-columns (headers)="columnHeaders($event)"></app-columns
></ng-template>
<ng-template #groups><app-group-modal></app-group-modal></ng-template>
