<app-back-title-layout layoutTitle="Resource Details" [loading]="!formGroup">
  <ng-container *ngIf="formGroup">
    <form [formGroup]="formGroup" (ngSubmit)="updateResource()">
      <app-resource-details [resource]="resource" [formGroup]="formGroup">
      </app-resource-details>

      <!-- <div class="my-5">
      <details>
        <summary class="form-label">Advanced</summary>

        <div class="my-5">
          <label>
            <span class="form-label leading-none">
              Created by
            </span>

            {{ creator.firstName }}
            {{ creator.lastName }}
          </label>
        </div>
      </details>
    </div> -->

      <div class="my-3 flex flex-row-reverse">
        <button
          [disabled]="!formGroup?.valid || formGroup?.disabled"
          type="submit"
          class="btn btn-primary mx-5"
        >
          Save
        </button>

        <button
          [disabled]="
            !resource.allowedActions.includes('delete') || formGroup?.disabled
          "
          (click)="deleteResource()"
          type="button"
          class="btn btn-danger mx-5"
        >
          Delete
        </button>
      </div>
    </form>
  </ng-container>
</app-back-title-layout>
