import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { SpreadView } from "../../misc/burislides-edit/burislides-edit.component";
import {
  QUESTIONS,
  RESOURCES,
  SlidesBlockItem,
} from "../burislides-modal/burislides-modal.component";
import { V1ExamItem } from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-burislides-block-select",
  templateUrl: "./burislides-block-select.component.html",
  styles: [],
})
export class BurislidesBlockSelectComponent implements OnInit {
  @Output() slidePreview = new EventEmitter<SpreadView>();
  @Input() spreadView: SpreadView;
  @Input() selected: boolean;
  @Input() activityItem: V1ExamItem;

  @Input() icon = "add_circle";

  constructor() {}
  ngOnInit(): void {
    if (this.spreadView) {
      if (this.spreadView.spread.spreadType !== "activity") {
        RESOURCES.map((resource) => {
          if (resource.value === this.spreadView.spread.spreadType) {
            this.icon = resource.icon;
          }
        });
        if (this.spreadView.spread.spreadType === "new") {
          this.icon = "add_circle";
        }
      } else {
        QUESTIONS.map((question) => {
          if (question.value === this.spreadView.itemType) {
            this.icon = question.icon;
          }
        });
      }
    }
  }

  ngOnChange(changes: SimpleChanges) {
    if (changes.spreadView.currentValue !== changes.spreadView.previousValue)
      this.ngOnInit();
  }
  clickSlide() {
    this.slidePreview.emit(this.spreadView);
  }
}
