<app-back-title-layout [loading]="!examResultItems">
  <app-item-list [items]="examResultItems" [itemTemplate]="itemTemplate">
  </app-item-list>
</app-back-title-layout>

<ng-template #itemTemplate let-item="item" let-index="index">
  <div class="my-10">
    <div class="font-bold text-lg">{{ index + 1 }}. {{ item.itemText }}</div>

    <ng-container
      *ngIf="
        item.itemType === 'SA' ||
        item.itemType === 'LA' ||
        item.itemType === 'FW'
      "
    >
      <div class="ml-5">
        <app-exam-results-text-answers [responses]="item.responses">
        </app-exam-results-text-answers>

        <span class="text-primary-800 font-bold text-sm">
          {{ item.responses.length }} answers
        </span>

        <ul class="flex flex-wrap gap-2">
          <ng-container *ngFor="let response of item.responses.slice(0, 10)">
            <li class="bg-gray-100 p-2 rounded">
              <span class="text-gray-600"> {{ response.responseText }} </span>
            </li>
          </ng-container>

          <input
            type="checkbox"
            class="peer hidden"
            id="item:{{ item.itemUuid }}"
          />

          <label
            *ngIf="item.responses.length > 10"
            class="block peer-checked:hidden text-primary-600"
            for="item:{{ item.itemUuid }}"
          >
            <app-icon icon="add"></app-icon>

            see more
          </label>

          <li class="hidden peer-checked:block">
            <ul class="flex flex-wrap gap-2">
              <ng-container
                *ngFor="let response of item.responses.slice(10, -1)"
              >
                <li class="bg-gray-50 p-2 rounded">
                  <span class="text-gray-600">
                    {{ response.responseText }}
                  </span>
                </li>
              </ng-container>
            </ul>
          </li>

          <label
            class="hidden peer-checked:block text-primary-600"
            for="item:{{ item.itemUuid }}"
          >
            <app-icon icon="remove"></app-icon>

            see less
          </label>
        </ul>
      </div>
    </ng-container>

    <ng-container
      *ngIf="
        item.itemType == 'MC' || item.itemType == 'IC' || item.itemType == 'CB'
      "
    >
      <div class="ml-5">
        <app-exam-results-choice-answers
          [choiceResults]="item.choices"
        ></app-exam-results-choice-answers>
      </div>
    </ng-container>

    <ng-container *ngIf="item.itemType == 'PO'">
      <div class="ml-5">
        <app-exam-results-choice-answers
          [choiceResults]="item.choices"
          [showCorrectness]="false"
        ></app-exam-results-choice-answers>
      </div>
    </ng-container>
  </div>
</ng-template>
