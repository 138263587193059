import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-prompt-dialog",
  templateUrl: "./prompt-dialog.component.html",
})
export class PromptDialogComponent implements OnInit {
  value: string;

  @Input()
  message: string;
  @Input()
  defaultValue: string;

  constructor() {}

  ngOnInit(): void {
    this.value = this.defaultValue;
  }
}
