<p class="text-gray-700">{{ message }}</p>

<div class="flex justify-between items-center w-full mt-10">
  <button [class]="cancelClass" [mat-dialog-close]="false">
    {{ cancelText }}
  </button>
  <button [class]="confirmClass" [mat-dialog-close]="true" cdkFocusInitial>
    {{ confirmText }}
  </button>
</div>
