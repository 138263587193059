<app-back-title-layout layoutTitle="Create New Collection">
  <form [formGroup]="formGroup" (submit)="createCollection()">
    <div class="my-5">
      <label>
        <span class="form-label">
          Collection Name
        </span>
        <input formControlName="name" class="form-control" />

        <app-form-control-error-layout
          [control]="formGroup.get('name')"
        ></app-form-control-error-layout>
      </label>
    </div>

    <button
      [disabled]="!formGroup.valid"
      type="submit"
      class="btn btn-primary w-full"
    >
      Create
    </button>
  </form>
</app-back-title-layout>
