<div class="relative h-full">
  <label [ngClass]="{ hidden: isUploading() }" class="absolute inset-0">
    <input
      #input
      type="file"
      class="hidden"
      [accept]="accept"
      [disabled]="disabled"
      (change)="fileRead($event)"
    />

    <ng-container *ngIf="!overlayTemplate; else overlayTemplate">
      <div
        [ngClass]="{
          'hover:opacity-75 cursor-pointer': !disabled
        }"
        class="h-full w-full opacity-0 hover:opacity-75 bg-purple-300 flex justify-center items-center z-10 border-2 border-dotted border-purple-900"
      >
        <span class="uppercase test-purple-900">
          {{ text }}
        </span>
      </div>
    </ng-container>
  </label>

  <ng-container *ngIf="isUploading()">
    <div
      class="h-full w-full opacity-75 absolute inset-0 bg-purple-300 flex justify-center items-center z-10 cursor-pointer border-2 border-dotted border-purple-900"
    ></div>

    <div class="absolute h-full w-full flex justify-center items-center z-20">
      <ngx-charts-pie-chart
        [scheme]="{ domain: ['#7c58c5', '#ffffff'] }"
        [results]="[
          {
            name: 'loading',
            value: progress
          },
          {
            name: 'remaining',
            value: 1 - progress
          }
        ]"
        [tooltipDisabled]="true"
        [doughnut]="true"
      >
      </ngx-charts-pie-chart>
    </div>

    <div class="absolute h-full w-full flex justify-center items-center z-30">
      <button class="cursor-pointer" (click)="cancelUpload()">
        <svg
          width="24"
          height="24"
          viewBox="0 0 8 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.8228 0.183264C7.76667 0.127012 7.69999 0.0823841 7.62658 0.0519343C7.55318 0.0214845 7.47448 0.00581086 7.39501 0.00581086C7.31554 0.00581086 7.23685 0.0214845 7.16345 0.0519343C7.09004 0.0823841 7.02336 0.127012 6.96722 0.183264L4 3.14442L1.03278 0.177196C0.976598 0.121018 0.909905 0.0764551 0.836505 0.0460517C0.763104 0.0156483 0.684434 5.91933e-10 0.604986 0C0.525539 -5.91934e-10 0.446868 0.0156483 0.373468 0.0460517C0.300068 0.0764551 0.233374 0.121018 0.177196 0.177196C0.121018 0.233374 0.0764551 0.300068 0.0460517 0.373468C0.0156483 0.446868 -5.91933e-10 0.525539 0 0.604986C5.91934e-10 0.684434 0.0156483 0.763104 0.0460517 0.836505C0.0764551 0.909905 0.121018 0.976598 0.177196 1.03278L3.14442 4L0.177196 6.96722C0.121018 7.0234 0.0764551 7.0901 0.0460517 7.1635C0.0156483 7.2369 0 7.31557 0 7.39501C0 7.47446 0.0156483 7.55313 0.0460517 7.62653C0.0764551 7.69993 0.121018 7.76663 0.177196 7.8228C0.233374 7.87898 0.300068 7.92355 0.373468 7.95395C0.446868 7.98435 0.525539 8 0.604986 8C0.684434 8 0.763104 7.98435 0.836505 7.95395C0.909905 7.92355 0.976598 7.87898 1.03278 7.8228L4 4.85558L6.96722 7.8228C7.0234 7.87898 7.0901 7.92355 7.1635 7.95395C7.2369 7.98435 7.31557 8 7.39501 8C7.47446 8 7.55313 7.98435 7.62653 7.95395C7.69993 7.92355 7.76663 7.87898 7.8228 7.8228C7.87898 7.76663 7.92355 7.69993 7.95395 7.62653C7.98435 7.55313 8 7.47446 8 7.39501C8 7.31557 7.98435 7.2369 7.95395 7.1635C7.92355 7.0901 7.87898 7.0234 7.8228 6.96722L4.85558 4L7.8228 1.03278C8.05339 0.802195 8.05339 0.413846 7.8228 0.183264Z"
            fill="#c53030"
          />
        </svg>
      </button>
    </div>
  </ng-container>

  <ng-content> </ng-content>
</div>
