<app-back-title-layout [loading]="!schedule">
  <ng-container *ngIf="schedule">
    <div>
      <!-- <img
        [src]="null"
        alt=""
        class="w-full h-40 object-contain bg-gray-300 rounded"
      /> -->

      <div class="text-lg font-bold">
        {{ schedule.name }}
      </div>
    </div>

    <div class="mt-5">
      <app-profile-link-list [linkLists]="linkLists"> </app-profile-link-list>
    </div>
  </ng-container>
</app-back-title-layout>
