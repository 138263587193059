<app-back-title-layout [layoutTitle]="title">
  <div class="leading-tight truncate font-bold py-2">Last Seen</div>
  <div class="date text-xs text-gray-600">{{ lastSeen | date: "medium" }}</div>

  <br />
  <div class="leading-tight truncate font-bold py-4">App Activity</div>
  <hr />

  <!-- <app-item-list [items]="logList" [itemTemplate]="itemTemplate">
</app-item-list> -->
  <app-item-list [source]="logListObservable" [itemTemplate]="itemTemplate">
  </app-item-list>
</app-back-title-layout>

<ng-template #itemTemplate let-activityLog="item">
  <div class="py-2">
    <div
      *ngIf="!activityLog.latest"
      class="date leading-tight text-xs truncate font-bold py-2"
    >
      {{ activityLog.date | date: "MMMM YYYY" }}
    </div>

    <app-item-list [items]="activityLog.log" [itemTemplate]="itemTemplate2">
    </app-item-list>

    <ng-template #itemTemplate2 let-log="item">
      <div class="py-3">
        <div class="upeercase text-xs text-gray-600">
          {{ log.timestamp | date: "medium" }}
        </div>

        <div class="text-xs">
          <span class="capitalize">
            {{ log.action }}
          </span>

          <span>
            {{ log.itemType }}
          </span>

          <span
            class="
              inline-block
              rounded
              text-purple-700 text-center
              whitespace-nowrap
            "
          >
            "{{ log.item }}"
          </span>

          <ng-container *ngIf="log.in || log.inItemType">
            <span> in </span>

            <span>
              {{ log.inItemType }}
            </span>

            <span *ngIf="log.in" class="text-purple-700"> "{{ log.in }}" </span>
          </ng-container>
        </div>
      </div>
    </ng-template>
  </div>
</ng-template>
