<div
  *ngIf="loading"
  [@fadeInOut]
  class="flex absolute bg-opacity-50 w-3/4 h-1/2 items-center justify-center"
>
  <app-loading [loading]="true" position="relative"></app-loading>
</div>
<h2 class="form-heading mb-8">Group</h2>
<button
  mat-dialog-close
  [routerLink]="['/groups/new']"
  class="py-1 px-2 rounded bg-primary-600 uppercase text-sm text-white mb-4"
  [disabled]="!(canAddGroupsObservable | async)"
  [attr.data-tooltip]="
    !(canAddGroupsObservable | async) ? 'Contact your admin to gain access' : ''
  "
>
  <app-icon icon="add_circle" class="text-white"></app-icon>
  Add group
</button>
<div class="mb-8 w-3/4">
  <app-search-bar-query
    placeholder="Search"
    [queryField]="'group'"
  ></app-search-bar-query>
</div>
<div class="">
  <app-item-select-with-header
    [disableCheckbox]="false"
    [source]="groupListObservable"
    [itemTemplate]="itemTemplate"
    [headers]="headers"
    [category]="'group'"
    [key]="'groupUuid'"
    [batchActions]="groupItems"
    [limit]="limit"
    [totalCount]="totalGroupCount"
    (selectedItemList)="setGroupList($event)"
  >
  </app-item-select-with-header>
  <div class="flex w-full justify-end">
    <button [mat-dialog-close]="groups" class="btn btn-primary">done</button>
  </div>
</div>

<ng-template #itemTemplate let-groupItem="item">
  <div class="flex w-full">
    <div class="block sm:flex mt-2 sm:mt-0">
      <a
        class="flex items-center w-full cursor-pointer"
        (click)="
          outputGroup({
            name: groupItem.groupName,
            uuid: groupItem.groupUuid,
            type: 'group',
            index: currentIndex
          })
        "
        [mat-dialog-close]="
          data?.idOnly
            ? [groupItem.groupUuid]
            : {
                name: groupItem.groupName,
                uuid: groupItem.groupUuid,
                type: 'group',
                index: currentIndex
              }
        "
      >
        <div class="grid grid-cols-1 my-1 w-full sm:flex">
          <div class="block sm:w-96 sm:flex">
            <div>
              <app-icon
                icon="group"
                [ngClass]="{
                  'text-primary': groupItem.isDefaultGroup,
                  'text-gray-500': groupItem.isDeactivatedUserGroup
                }"
                class="text-white text-xl h-10 leading-none px-1 mr-1 bg-gray-200 rounded-full items-center justify-center"
              ></app-icon>
            </div>
            <div
              [ngClass]="{
                'text-primary': groupItem.isDefaultGroup,
                'text-gray-500': groupItem.isDeactivatedUserGroup
              }"
              class="text-sm leading-none flex-none"
            >
              {{ groupItem.groupName }}
            </div>
          </div>
          <div class="sm:w-56">
            <div
              [ngClass]="{
                'text-primary': groupItem.isDefaultGroup,
                'text-gray-500': groupItem.isDeactivatedUserGroup
              }"
              class="text-sm text-gray-700"
            >
              <app-icon icon="person"></app-icon>
              {{ groupItem.userCount }}
            </div>
          </div>
          <div #roles class="text-sm sm:w-96 truncate">
            <ng-container *ngFor="let role of groupItem.roles">
              <span
                class="inline-block rounded-full text-primary-500 bg-primary-50 px-2 py-0.5 m-0.5 max-w-xs text-sm"
              >
                {{ role.name }}
              </span>
            </ng-container>
          </div>

          <div
            *ngIf="checkOverflow(roles)"
            class="text-primary-500 text-sm px-2 py-0.5 m-0.5"
          >
            ({{ groupItem.roles.length }})
          </div>
        </div>
      </a>
    </div>
  </div>
</ng-template>
