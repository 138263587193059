<app-back-title-layout
  layoutTitle="{{ resource | resourceType }} Profile"
  [loading]="!linkLists"
>
  <ng-container *ngIf="linkLists">
    <img
      [src]="resource.coverUri"
      alt=""
      class="w-full h-40 object-contain bg-gray-300 rounded"
    />

    <div class="text-lg font-bold truncate">
      {{ resource.name }}
    </div>

    <ng-container
      *ngIf="
        resource.type == 'buribook-with-activity' ||
        resource.type == 'burislides'
      "
    >
      <a
        class="block btn btn-primary w-full my-5"
        *ngIf="resource.uuid"
        [routerLink]="
          canView ? ['/resource-management', 'misc', 'buribooks-edit'] : []
        "
        [attr.data-tooltip]="
          !canView ? 'Contact your admin to gain access' : ''
        "
        [ngClass]="{
          disabled: !canView
        }"
        [queryParams]="{
          resource_uuid: resource.uuid,
          exam_uuid: resource.content.examDetails?.examUuid
        }"
      >
        <app-icon icon="edit"></app-icon>
        EDIT ACTIVITY
      </a>

      <!-- <div
        *ngIf="!exam"
        class="absolute inset-0 bg-white bg-opacity-75 flex flex-col items-center justify-center text-primary font-bold"
      >
        <div>You are not allowed to access this activity.</div>
        <div>The creator of this activity can access it.</div>
      </div> -->
    </ng-container>

    <ng-container
      *ngIf="
        resource.type == 'assessment' && resource.subtype !== 'file-upload'
      "
    >
      <a
        class="block btn btn-primary w-full my-5"
        *ngIf="resource.uuid"
        [routerLink]="
          canView
            ? ['/resource-management', 'misc', 'assessment-activity-edit']
            : []
        "
        [attr.data-tooltip]="
          !canView ? 'Contact your admin to gain access' : ''
        "
        [ngClass]="{
          disabled: !canView
        }"
        [queryParams]="{
          resource_uuid: resource.uuid,
          exam_uuid: resource.content.examDetails?.examUuid
        }"
      >
        <app-icon icon="edit"></app-icon>
        EDIT ASSESSMENT
      </a>

      <!-- <div
        *ngIf="!exam"
        class="
          absolute
          inset-0
          bg-white bg-opacity-75
          flex flex-col
          items-center
          justify-center
          text-primary
          font-bold
        "
      >
        <div>You are not allowed to access this assessment.</div>
        <div>The creator of this assessment can access it.</div>
      </div> -->
    </ng-container>

    <ng-container *ngIf="resource.type == 'course'">
      <a
        [routerLink]="['/_courses', 'content-edit']"
        [queryParams]="{
          course_uuid: resource.content?.courseDetails?.courseUuid,
          resource_collection_uuid: resource.content?.resourceCollectionUuid,
          resource_uuid: resource.uuid
        }"
        class="block btn btn-primary w-full my-5"
      >
        <app-icon icon="edit"></app-icon>
        EDIT COURSE
      </a>
    </ng-container>

    <div class="mt-5">
      <app-profile-link-list [linkLists]="linkLists"> </app-profile-link-list>
    </div>
  </ng-container>
</app-back-title-layout>
