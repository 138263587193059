<div class="my-2 flex justify-end">
  <app-pagination-query
    [pageSize]="limit"
    [listLength]="totalCount ? totalCount : items?.length"
    [page]="page"
    (currentPage)="currentPage($event)"
    [hidden]="disablePagination"
  ></app-pagination-query>
</div>
<div
  #top
  (window:resize)="onResize($event)"
  (scroll)="onTopScroll($event)"
  id="top"
  class="scroll mb-4"
>
  <div class="div1" [style.width.px]="width"></div>
</div>
<div
  #bottom
  (scroll)="onBottomScroll($event)"
  class="overflow-x-scroll grid grid-cols-1 relative min-h-40"
>
  <app-list-table-header
    [headers]="headers"
    [batchActions]="batchActions"
    [category]="category"
    [selected]="
      selectedCurrentPage[currentPageNumber]
        ? selectedCurrentPage[currentPageNumber]
        : []
    "
    [enableSort]="enableSort"
    [disableCheckbox]="disableCheckbox"
    [totalSelected]="selected"
    (selectAll)="toggleAll($event)"
    (reload)="refreshList($event)"
  >
    <ul>
      <ng-container *ngFor="let item of items">
        <li class="border-b last:border-b-0 py-2 w-full">
          <div class="flex items-{{ checkBoxAlignment }}">
            <div class="{{ hideCheckbox }}">
              <app-checkbox
                class="text-xl"
                uncheckClass="text-gray-500"
                checkClass="text-primary-dark"
                (change)="updateSelectedItem(item, $event)"
                [(ngModel)]="item.selected"
              ></app-checkbox>
            </div>
            <div class="w-full">
              <ng-container
                *ngTemplateOutlet="
                  itemTemplate ? itemTemplate : default;
                  context: { item: item }
                "
              >
              </ng-container>
            </div>
          </div>
        </li>
      </ng-container>
    </ul>
    <div
      *ngIf="loading"
      [@fadeInOut]
      class="absolute inset-0 bg-white bg-opacity-50"
    >
      <app-loading [loading]="true" position="relative"></app-loading>
    </div>

    <ng-container *ngIf="items && items.length == 0">
      <div
        class="w-full py-5 rounded flex flex-col items-center justify-center bg-purple-200 text-primary-light"
      >
        <span class="font-bold">{{ emptyListMessage }}</span>
      </div>
    </ng-container>

    <div
      *ngIf="errorMessage"
      [@fadeInOut]
      class="w-full p-3 text-sm my-5 rounded bg-error-light text-error-dark"
    >
      <span class="font-bold text-red-600 line-clamp-2">{{
        errorMessage
      }}</span>

      <div>
        <span>Oops, something went wrong please </span>

        <button (click)="refreshList(false)" class="underline inline">
          refresh this page
        </button>
      </div>

      <div class="border-t border-error-dark mt-5">
        <span>Still not working? You may contact us via</span>

        <span class="font-bold">support@buri.io</span>
      </div>
    </div>
  </app-list-table-header>
</div>

<div class="my-2 flex justify-end">
  <app-pagination-query
    [pageSize]="limit"
    [listLength]="totalCount"
    [page]="page"
    (currentPage)="currentPage($event)"
    [hidden]="disablePagination"
  ></app-pagination-query>
</div>
<ng-template #default let-item="item">
  <span class="truncate font-semibold">
    {{ item[key] }}
  </span>
</ng-template>
