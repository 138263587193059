import { Component, OnInit } from "@angular/core";
import {
  Resource,
  ResourcesService,
  Collection,
} from "src/app/sinigangnababoywithgabi";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";

@Component({
  selector: "app-resource-select-collections",
  templateUrl: "./resource-select-collections.component.html",
  styleUrls: ["./resource-select-collections.component.scss"],
})
export class ResourceSelectCollectionsComponent implements OnInit {
  resource: Resource;
  collections: Collection[];

  constructor(
    private route: ActivatedRoute,
    private resourcesService: ResourcesService,
    private location: Location
  ) {}

  ngOnInit() {
    this.load();
  }

  async load() {
    let { resource_uuid: resourceUuid } = this.route.snapshot.queryParams;

    this.collections = await this.resourcesService
      .listCollections()
      .toPromise();
    this.resource = await this.resourcesService
      .getResourceByUuid(resourceUuid)
      .toPromise();
  }

  updateCollections() {
    this.resourcesService
      .updateResourceByUuid(this.resource.uuid, {
        packageUuids: this.resource.packages.map((p) => p.uuid),
      })
      .subscribe(() => {
        this.location.back();
      });
  }
}
