import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder } from "@angular/forms";
import { firstValueFrom, Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { GroupSelectDialogComponent } from "src/app/app-common-module/components/group-select-dialog/group-select-dialog.component";
import { DashboardService } from "src/app/services/dashboard.service";
import { ModalService } from "src/app/services/modal.service";
import { PassService, Role } from "src/app/sinigangnababoywithgabi";

export enum PermissionObjectType {
  GROUP = "group",
  COURSE = "course",
}

export enum PermissionMode {
  ALL = "all",
  SPECIFIC = "specific",
}

export interface PermissionGroupItem {
  name: string;
  permissionItems: PerimssionItem[];

  isSelected?: boolean;
}

export interface PerimssionItem {
  name: string;
  mode?: PermissionMode;
  modeDisabled?: boolean;

  isSelected?: boolean;
  allPermissionName: string;
  specificItemPrefix?: string;
  specificItemObjectType?: PermissionObjectType;
  specificItemInfoObs?: Observable<{ title: string; subtitle: string }>;
  specificItems?: SpecificPermissionItem[];
}

export interface SpecificPermissionItem {
  permissionName: string;
  title?: string;
  subtitle?: string;
  icon?: string;
}

@Component({
  selector: "app-group-role-permissions-toggle-form",
  templateUrl: "./group-role-permissions-toggle-form.component.html",
  styles: [],
})
export class GroupRolePermissionsToggleFormComponent implements OnInit {
  permissionList: PermissionGroupItem[];

  @Input()
  role: Role;

  @Input()
  formGroup: UntypedFormGroup;

  loading: boolean;

  constructor(
    private dashboardService: DashboardService,
    private formBuilder: UntypedFormBuilder,
    private passService: PassService,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.initializePermissionList();
  }

  // HARDCODE: Disable editing of permissions
  ngAfterViewChecked() {
    if (this.role?.id) {
      this.formGroup.disable();
    }
  }

  async initializePermissionList() {
    this.loading = true;
    let cast = await this.dashboardService.getCast().toPromise();

    this.formGroup = this.formGroup || this.formBuilder.group({});
    this.role = this.role || {};

    let rolePermissions = this.role.permissions || [];

    let parentGroupId = cast.data.attributes.defaultGroupId;

    this.permissionList = [
      {
        name: "Dashboard",
        permissionItems: [
          {
            name: "can access",
            allPermissionName: "read:dashboard:all",
            modeDisabled: true,
          },
        ],
      },
      {
        name: "Users",
        permissionItems: [
          {
            name: "can view",
            allPermissionName: `read:users:group=${parentGroupId}`,
            modeDisabled: true,
          },
          {
            name: "can add",
            allPermissionName: "create:users",
            modeDisabled: true,
          },
          {
            name: "can update",
            allPermissionName: `update:users:group=${parentGroupId}`,
            modeDisabled: true,
          },
        ],
      },
      {
        name: "Groups",
        permissionItems: [
          {
            name: "can view",
            allPermissionName: `read:groups:parent=${parentGroupId}`,
            specificItemPrefix: `read:groups:`,
            specificItemObjectType: PermissionObjectType.GROUP,
          },
          {
            name: "can add",
            allPermissionName: "create:groups",
          },
          {
            name: "can update",
            allPermissionName: `update:groups:parent=${parentGroupId}`,
            specificItemPrefix: `update:groups:`,
            specificItemObjectType: PermissionObjectType.GROUP,
          },
          {
            name: "can delete",
            allPermissionName: `delete:groups:parent=${parentGroupId}`,
            specificItemPrefix: `delete:groups:`,
            specificItemObjectType: PermissionObjectType.GROUP,
          },
        ],
      },
      {
        name: "Roles",
        permissionItems: [
          {
            name: "can view",
            allPermissionName: "read:roles",
            modeDisabled: true,
          },
          {
            name: "can add",
            allPermissionName: "create:roles",
            modeDisabled: true,
          },
          {
            name: "can update",
            allPermissionName: "update:roles",
            modeDisabled: true,
          },
          {
            name: "can delete",
            allPermissionName: "delete:roles",
            modeDisabled: true,
          },
        ],
      },
      {
        name: "Permissions",
        permissionItems: [
          {
            name: "can view",
            allPermissionName: "read:permissions",
            modeDisabled: true,
          },
          {
            name: "can add",
            allPermissionName: "create:permissions",
            modeDisabled: true,
          },
          {
            name: "can update",
            allPermissionName: "update:permissions",
            modeDisabled: true,
          },
          {
            name: "can delete",
            allPermissionName: "delete:permissions",
            modeDisabled: true,
          },
        ],
      },
      {
        name: "Collections",
        permissionItems: [
          {
            name: "can view",
            allPermissionName: "read:collections:all",
            modeDisabled: true,
          },
          {
            name: "can add",
            allPermissionName: "create:collections",
            modeDisabled: true,
          },
          {
            name: "can update",
            allPermissionName: "update:collections:all",
            modeDisabled: true,
          },
          {
            name: "can delete",
            allPermissionName: "delete:collections:all",
            modeDisabled: true,
          },
        ],
      },
      {
        name: "Resources",
        permissionItems: [
          {
            name: "can view",
            allPermissionName: "read:resources:all",
            modeDisabled: true,
          },
          {
            name: "can add",
            allPermissionName: "create:resources",
            modeDisabled: true,
          },
          {
            name: "can update",
            allPermissionName: "update:resources:all",
            modeDisabled: true,
          },
          {
            name: "can delete",
            allPermissionName: "delete:resources:all",
            modeDisabled: true,
          },
        ],
      },
      {
        name: "Exams",
        permissionItems: [
          {
            name: "can view",
            allPermissionName: "read:exams:all",
            modeDisabled: true,
          },
          {
            name: "can add",
            allPermissionName: "create:exams",
            modeDisabled: true,
          },
          {
            name: "can update",
            allPermissionName: "update:exams:all",
            modeDisabled: true,
          },
        ],
      },
      {
        name: "Courses",
        permissionItems: [
          {
            name: "can view",
            allPermissionName: "read:courses:all",
            modeDisabled: true,
          },
          {
            name: "can add",
            allPermissionName: "create:courses:all",
            modeDisabled: true,
          },
          {
            name: "can update",
            allPermissionName: "update:courses:all",
            modeDisabled: true,
          },
          {
            name: "can delete",
            allPermissionName: "delete:courses:all",
            modeDisabled: true,
          },
        ],
      },
      {
        name: "Batches",
        permissionItems: [
          {
            name: "can view",
            allPermissionName: "read:schedules:all",
            modeDisabled: true,
          },
          {
            name: "can add",
            allPermissionName: "create:schedules:all",
            modeDisabled: true,
          },
          {
            name: "can update",
            allPermissionName: "update:schedules:all",
            modeDisabled: true,
          },
          {
            name: "can delete",
            allPermissionName: "delete:schedules:all",
            modeDisabled: true,
          },
        ],
      },
      {
        name: "Uploads",
        permissionItems: [
          {
            name: "admin uploads",
            allPermissionName: "upload:admin",
            modeDisabled: true,
          },
          {
            name: "student uploads",
            allPermissionName: "upload:student",
            modeDisabled: true,
          },
        ],
      },
      {
        name: "Forums",
        permissionItems: [
          {
            name: "can mark comments as spam/not safe",
            allPermissionName: "moderate:comments:all",
            modeDisabled: true,
          },
          {
            name: "can add and delete Categories",
            allPermissionName: "create:categories:all",
            modeDisabled: true,
          },
          {
            name: "can add and delete Topics",
            allPermissionName: "create:topics:student",
            modeDisabled: true,
          },
        ],
      },
    ];

    let rolePermissionNames = rolePermissions.map((p) => p.name);

    // Extract specific permissions
    this.permissionList.forEach((groupItem) => {
      groupItem.permissionItems.forEach((item) => {
        // Initialize specific items
        item.specificItems = item.specificItems || [];

        // If all permission exists in role
        if (
          rolePermissionNames.some((name) => name == item.allPermissionName)
        ) {
          groupItem.isSelected = true;
          item.isSelected = true;
          item.mode = PermissionMode.ALL;
        } else {
          let filteredSpecificPermissionNames = rolePermissionNames.filter(
            (key) =>
              item.specificItemPrefix &&
              new RegExp(item.specificItemPrefix).test(key) &&
              key != item.allPermissionName
          );

          if (filteredSpecificPermissionNames.length > 0) {
            groupItem.isSelected = true;
            item.isSelected = true;
            item.mode = PermissionMode.SPECIFIC;

            filteredSpecificPermissionNames.forEach((key) => {
              let newItem: SpecificPermissionItem = {
                permissionName: key,
                title: "",
                subtitle: "",
              };

              firstValueFrom(
                this.getPermissionInfo({
                  type: item.specificItemObjectType,
                  id: key.replace(item.specificItemPrefix, ""),
                })
              ).then((result) => {
                newItem.title = result.title;
                newItem.subtitle = result.subtitle;
                newItem.icon = result.icon;
              });

              item.specificItems.push(newItem);
            });
          }
        }
      });
    });

    this.updateFormGroup();
    this.loading = false;
  }

  private unsubscribe$: Subject<void> = new Subject<void>();
  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  onGroupItemChange(groupItem: PermissionGroupItem) {
    this.updateFormGroup();
  }

  onItemChange(item: PerimssionItem) {
    if (item.isSelected && !item.mode) {
      item.mode = PermissionMode.ALL;
    }

    this.updateFormGroup();
  }

  onModeChange(item: PerimssionItem) {
    this.updateFormGroup();
  }

  getPermissionInfo(options: {
    type: PermissionObjectType;
    id: string;
  }): Observable<{ title: string; subtitle: string; icon: string }> {
    if (options.type == "group") {
      return this.passService.getGroup(options.id).pipe(
        map((group) => ({
          title: group.name,
          subtitle: `${group.userIds?.length} user/s`,
          icon: "group",
        }))
      );
    }
  }

  updateFormGroup() {
    let formGroupKeys = Object.keys(this.formGroup.value);

    // Set all form value to false first
    formGroupKeys.forEach((key) => {
      this.formGroup.get(key).setValue(false);
    });

    this.permissionList.forEach((groupItem) => {
      groupItem.permissionItems.forEach((item) => {
        let formControl = this.formGroup.get(item.allPermissionName);

        //Add form control if nonexisting
        if (!formControl) {
          formControl = this.formBuilder.control(false);

          this.formGroup.addControl(item.allPermissionName, formControl);
        }

        // Add specific items form controls
        item.specificItems?.forEach((specific) => {
          let specificFormControl = this.formGroup.get(specific.permissionName);

          if (!specificFormControl) {
            specificFormControl = this.formBuilder.control(false);

            this.formGroup.addControl(
              specific.permissionName,
              specificFormControl
            );
          }
        });

        // Set form values depending on permission list data
        if (groupItem.isSelected) {
          if (item.isSelected) {
            if (item.mode == PermissionMode.ALL) {
              // Set true to all permission but false to specific
              formControl.setValue(true);

              item.specificItems?.forEach((specific) => {
                this.formGroup.get(specific.permissionName).setValue(false);
              });
            } else if (item.mode == PermissionMode.SPECIFIC) {
              // Set false to all permission but true to specific
              formControl.setValue(false);

              item.specificItems?.forEach((specific) => {
                this.formGroup.get(specific.permissionName).setValue(true);
              });
            }
          }
        }
      });
    });
  }

  async addSpecific(permissionItem: PerimssionItem) {
    let result = await this.modalService.openModal(GroupSelectDialogComponent);

    if (result) {
      let newPermissionName = permissionItem.specificItemPrefix + result;

      //Check if it already exists
      let existing = permissionItem.specificItems.find(
        (i) => i.permissionName == newPermissionName
      );

      if (!existing) {
        let newItem: SpecificPermissionItem = {
          permissionName: newPermissionName,
          title: "",
          subtitle: "",
        };

        permissionItem.specificItems.push(newItem);

        firstValueFrom(
          this.getPermissionInfo({
            type: permissionItem.specificItemObjectType,
            id: result,
          })
        ).then((result) => {
          newItem.title = result.title;
          newItem.subtitle = result.subtitle;
          newItem.icon = result.icon;
        });
      }

      this.updateFormGroup();
    }
  }

  deleteSpecific(permissionItem: PerimssionItem, index: number) {
    permissionItem.specificItems.splice(index, 1);
  }
}
