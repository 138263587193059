import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./app-common-module/guards/auth.guard";
import { LoginComponent } from "./auth/login/login.component";
import { LogoutComponent } from "./auth/logout/logout.component";
import { OauthCallbackComponent } from "./auth/oauth-callback/oauth-callback.component";
import { DashboardComponent } from "./dashboard/dashboard.component";

const routes: Routes = [
  {
    path: "auth",
    children: [
      {
        path: "login",
        component: LoginComponent,
      },
      {
        path: "callback",
        component: OauthCallbackComponent,
      },
      {
        path: "logout",
        component: LogoutComponent,
      },
    ],
  },
  {
    path: "",
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        data: { layoutTitle: "Home" },
        component: DashboardComponent,
      },
      // {
      //   path: "courses",
      //   loadChildren: () =>
      //     import("./courses/courses.module").then((m) => m.CoursesModule),
      // },
      // {
      //   path: "exams",
      //   loadChildren: () =>
      //     import("./exams/exams.module").then((m) => m.ExamsModule),
      // },
      // {
      //   path: "casteditor",
      //   loadChildren: () =>
      //     import("./casteditor/casteditor.module").then(
      //       (m) => m.CasteditorModule
      //     ),
      // },
      // {
      //   path: "general-settings",
      //   loadChildren: () =>
      //     import("./general-settings/general-settings-routing.module").then(
      //       (m) => m.GeneralSettingsRoutingModule
      //     ),
      // },
    ],
  },
  // { path: "not-found", component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "disabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
  // Quick Fix for scrollPositionRestorationProblem
  // https://github.com/angular/angular/issues/24547#issuecomment-630736014
  // constructor(router: Router, viewportScroller: ViewportScroller) {
  //   router.events
  //     .pipe(filter((e): e is Scroll => e instanceof Scroll))
  //     .subscribe((e) => {
  //       if (e.position) {
  //         // backward navigation
  //         setTimeout(() => {
  //           viewportScroller.scrollToPosition(e.position);
  //         }, 0);
  //       } else if (e.anchor) {
  //         // anchor navigation
  //         setTimeout(() => {
  //           viewportScroller.scrollToAnchor(e.anchor);
  //         }, 0);
  //       } else {
  //         // forward navigation
  //         setTimeout(() => {
  //           viewportScroller.scrollToPosition([0, 0]);
  //         }, 0);
  //       }
  //     });
  // }
}
