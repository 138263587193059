<app-back-title-layout
  layoutTitle="Navigation Bar"
  [loading]="!formGroup"
  [titleIconButtons]="titleIconButtons"
>
  <div *ngIf="formGroup" [formGroup]="formGroup">
    <div class="my-10">
      <button
        class="w-full py-1 btn btn-primary"
        [routerLink]="['/_casteditor', 'screens', 'select']"
        [queryParams]="{
          is_select_enabled: true
        }"
        queryParamsHandling="merge"
      >
        <app-icon icon="add"></app-icon>

        ADD LINKED SCREEN
      </button>
    </div>

    <div class="my-10">
      <ul cdkDropList (cdkDropListDropped)="onDrop($event)">
        <ng-container *ngFor="let item of formGroup.value.items">
          <li cdkDrag class="flex items-center py-2 border-t border-b">
            <button cdkDragHandle class="mx-5">
              <app-icon icon="unfold_more"> </app-icon>
            </button>

            <div class="flex items-center flex-1 px-5 font-bold">
              <button
                class="w-10 h-10 p-1 text-2xl bg-transparent border btn"
                (click)="selectIcon(item)"
              >
                <app-icon icon="{{ item.icon?.material }}"></app-icon>
              </button>

              <a
                routerLink="/_casteditor"
                [queryParams]="{ screen_id: item.screenId }"
                class="block w-full mx-2"
              >
                {{ item.title }}
              </a>
            </div>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
</app-back-title-layout>
