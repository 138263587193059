import { Component } from '@angular/core';

@Component({
  selector: 'app-idis-analytics',
  templateUrl: './idis-analytics.component.html',
  styles: [
  ]
})
export class IdisAnalyticsComponent {

}
