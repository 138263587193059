<input
  type="file"
  #fileInput
  (change)="fileChange($event)"
  class="hidden"
  accept=".csv"
/>

<button
  class="form-width text-gray-500 bg-gray-100 py-16 rounded-xl cursor-pointer"
  (click)="onClick(fileInput)"
>
  <ng-container *ngIf="progress">
    <div class="relative h-24">
      <div class="absolute inset-0 flex items-center justify-center h-full">
        <ngx-charts-pie-chart
          [scheme]="{ domain: ['#7c58c5', '#ffffff'] }"
          [results]="[
            {
              name: 'loading',
              value: progress || 0
            },
            {
              name: 'remaining',
              value: 1 - (progress || 0)
            }
          ]"
          [tooltipDisabled]="true"
          [doughnut]="true"
        >
        </ngx-charts-pie-chart>

        <span
          class="absolute inset-0 flex items-center justify-center text-3xl"
        >
          <app-icon icon="close"></app-icon>
        </span>

        <span
          class="absolute inset-0 flex items-center justify-center text-3xl"
        >
          <app-icon icon="cancel"></app-icon>
        </span>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!progress">
    <span class="text-9xl leading-none">
      <app-icon icon="upload"></app-icon>
    </span>
  </ng-container>

  <div>
    {{ fileName || "UPLOAD CSV HERE" }}
  </div>
</button>

<label class="form-label mt-5"> or paste data here </label>

<input
  type="text"
  #pasteInput
  placeholder="copy and paste data from CSV here"
  class="form-control mb-5"
  maxlength="0"
  (paste)="onPaste($event)"
/>
