import { Injectable } from "@angular/core";
import { firstValueFrom, Subject } from "rxjs";
import { switchMap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import {
  Block,
  Course,
  CourseBlock,
  CourseSection,
  Group,
  LearninglockerService,
  LrsContext,
  LRSService,
  LrsStatement,
  LrsVerb,
  Resource,
  Screen,
  User,
} from "../sinigangnababoywithgabi";
import { DashboardService } from "./dashboard.service";

export interface LogItem {
  name?: string;
  email?: string;
  timestamp?: string;
  time?: string;
  action?: string;
  itemType?: string;
  item?: string;
  in: string;
}

export const VERBS = {
  launched: {
    id: "http://adlnet.gov/expapi/verbs/launched",
    display: {
      en: "launched",
    },
  },

  activated: {
    id: "https://w3id.org/xapi/adl/verbs/activated",
    display: {
      en: "activated",
    },
  },

  deactivated: {
    id: "https://w3id.org/xapi/adl/verbs/deactivated",
    display: {
      en: "deactivated",
    },
  },

  loggedIn: {
    id: "https://w3id.org/xapi/adl/verbs/logged-in",
    display: {
      en: "logged-in",
    },
  },

  created: {
    id: "http://activitystrea.ms/schema/1.0/create",
    display: {
      en: "created",
    },
  },

  updated: {
    id: "http://activitystrea.ms/schema/1.0/update",
    display: {
      en: "updated",
    },
  },

  deleted: {
    id: "http://activitystrea.ms/schema/1.0/delete",
    display: {
      en: "deleted",
    },
  },

  removed: {
    id: "http://activitystrea.ms/schema/1.0/remove",
    display: {
      en: "removed",
    },
  },

  sorted: {
    id: "http://id.xapi.buri.io/verbs/sorted",
    display: {
      en: "sorted",
    },
  },

  passwordChanged: {
    id: "http://id.xapi.buri.io/verbs/password-changed",
    display: {
      en: "password changed",
    },
  },
};

export const ACTIVITY_TYPES = {
  group: "http://id.xapi.buri.io/activitytypes/group",
  user: "http://id.xapi.buri.io/activitytypes/user",

  cast: "http://id.xapi.buri.io/activitytypes/cast",
  castScreen: "http://id.xapi.buri.io/activitytypes/cast-screen",
  castBlock: "http://id.xapi.buri.io/activitytypes/cast-block",

  resource: "http://id.tincanapi.com/activitytype/resource",

  course: "http://adlnet.gov/expapi/activities/course",
  courseSection: "http://id.xapi.buri.io/activitytypes/course-section",
  courseBlock: "http://id.xapi.buri.io/activitytypes/course-block",
};

export const EXTENSIONS = {
  castId: "http://id.xapi.buri.io/extensions/cast_id",
  resourceType: "http://id.xapi.buri.io/extensions/resource-type",
  resourceSubtype: "http://id.xapi.buri.io/extensions/resource-subtype",
};

@Injectable({
  providedIn: "root",
})
export class ActivityLogService {
  subject = new Subject<any>();

  constructor(
    private dashboardService: DashboardService,
    private lrsService: LRSService,
    private learningLockerService: LearninglockerService
  ) {
    this.subject
      .pipe(
        switchMap((statement) => this.lrsService.storeStatements([statement]))
      )
      .subscribe();
  }

  getOpenidFromUserId(userId) {
    return `${environment.OAUTH_URI}/users/${userId}`;
  }

  /**
   * Creates a statement that integrates current context
   *
   * @param statement
   * @returns
   */
  async createStatement(statement: LrsStatement) {
    let cast = await firstValueFrom(this.dashboardService.getCast());
    let userInfo = await firstValueFrom(this.dashboardService.getUserInfo());

    let finalStatement: LrsStatement = {
      timestamp: new Date().toISOString(),
      actor: {
        openid: this.getOpenidFromUserId(userInfo.userId),
        name: `${userInfo.givenName || userInfo.firstName} ${
          userInfo.familyName || userInfo.lastName
        }`,
        // account: {
        //   homePage: "https://pass.buri.io",
        //   name: userInfo.email,
        // },
      },
      verb: statement.verb,
      object: statement.object,
      context: {
        ...statement.context,
        contextActivities: {
          ...(statement.context?.contextActivities || {}),
          category: [
            {
              id: `https://cast.buri.dev/casts/${cast.data.id}`,
            },
            {
              id: window.location.origin,
            },
            ...(statement.context?.contextActivities?.category || []),
          ],
          grouping: [
            {
              id: `https://id.xapi.buri.io/objects/buricast/casts/${cast.data.id}`,
            },
            {
              id: window.location.origin,
            },
          ],
        },
        platform: window.location.origin,
        extensions: {
          ...(statement.context?.extensions || {}),
          [EXTENSIONS.castId]: cast.data.id,
        },
      },
    };

    this.subject.next(finalStatement);
  }

  aggregate(pipeline: any[]) {
    return this.dashboardService.getCast().pipe(
      switchMap((cast) =>
        this.learningLockerService.statementAggregate(
          JSON.stringify([
            {
              $match: {
                relatedActivities: {
                  $in: [
                    `https://cast.buri.dev/casts/${cast.data.id}`,
                    `https://id.xapi.buri.io/objects/buricast/casts/${cast.data.id}`,
                  ],
                },
              },
            },
            ...pipeline,
          ]),
          false
        )
      )
    );
  }

  constructLogItemFromStatement(statement: LrsStatement) {
    return {
      name: statement.actor.name,
      email: "",
      timestamp: statement.timestamp,
      action: this.extractFromLocale(statement.verb.display),
      itemType: this.parseObjectTypeName(statement.object.id),
      item:
        this.extractFromLocale(statement.object.definition?.name) ||
        statement.object?.id,
      in: this.extractFromLocale(
        statement.context.contextActivities?.parent?.[0]?.definition?.name
      ),
      inItemType: this.parseObjectTypeName(
        statement.context.contextActivities?.parent?.[0]?.id
      ),
    };
  }

  parseObjectTypeName(id: string) {
    if (/buricast\/screens\/.*/.test(id)) {
      return "screen";
    } else if (/buricast\/blocks\/.*/.test(id)) {
      return "block";
    } else if (/buresource\/resources\/.*/.test(id)) {
      return "resource";
    } else if (/buricourse\/courses\/.*/.test(id)) {
      return "course";
    } else if (/buricourse\/sections\/.*/.test(id)) {
      return "course section";
    } else if (/buricourse\/blocks\/.*/.test(id)) {
      return "course block";
    } else {
      return "";
    }
  }

  constructResourceObject(resource: Resource) {
    return {
      id: `https://id.xapi.buri.io/objects/buresource/resources/${resource.uuid}`,
      definition: {
        name: {
          "en-ph": resource.name,
        },
        description: {
          "en-ph": resource.description,
        },
        type: ACTIVITY_TYPES.resource,
        extensions: {
          [EXTENSIONS.resourceType]: resource.type,
          [EXTENSIONS.resourceSubtype]: resource.subtype,
        },
      },
    };
  }

  didResource(
    verb: any,
    resource: Resource,
    options: { context?: LrsContext } = {}
  ) {
    return this.createStatement({
      object: this.constructResourceObject(resource),
      verb: verb,
      ...options,
    });
  }

  constructCastScreenObject(screen: Screen) {
    return {
      id: `https://id.xapi.buri.io/objects/buricast/screens/${screen.id}`,
      definition: {
        name: {
          "en-ph": screen.title,
        },
        type: ACTIVITY_TYPES.castScreen,
      },
    };
  }

  didCastScreen(
    verb: LrsVerb,
    screen: Screen,
    options: { context?: LrsContext } = {}
  ) {
    return this.createStatement({
      object: this.constructCastScreenObject(screen),
      verb: verb,
      context: options.context,
    });
  }

  constructCastBlockObject(block: Block) {
    let blockMap = {
      markdown: "Text block",
      catalog: "Catalog block",
      carousel: "Carousel block",
    };

    return {
      id: `https://id.xapi.buri.io/objects/buricast/blocks/${block.id}`,
      definition: {
        name: {
          "en-ph": blockMap[block.type] || block.type,
        },
        type: ACTIVITY_TYPES.castBlock,
      },
    };
  }

  didCastBlock(
    verb: LrsVerb,
    block: Block,
    options: { context?: LrsContext } = {}
  ) {
    return this.createStatement({
      object: this.constructCastBlockObject(block),
      verb: verb,
      context: options.context,
    });
  }

  constructCourseObject(course: Course) {
    return {
      id: `https://id.xapi.buri.io/objects/buricourse/courses/${course.uuid}`,
      definition: {
        name: course.title
          ? {
              "en-ph": course.title,
            }
          : undefined,
        type: ACTIVITY_TYPES.course,
      },
    };
  }

  didCourse(
    verb: LrsVerb,
    course: Course,
    options: { context?: LrsContext } = {}
  ) {
    return this.createStatement({
      object: this.constructCourseObject(course),
      verb: verb,
      context: options.context,
    });
  }

  constructCourseBlockObject(block: CourseBlock) {
    return {
      id: `https://id.xapi.buri.io/objects/buricourse/blocks/${block.uuid}`,
      definition: {
        name: {
          "en-ph": block.title,
        },
        type: ACTIVITY_TYPES.courseBlock,
      },
    };
  }

  didCourseBlock(
    verb: LrsVerb,
    block: CourseBlock,
    options: { context?: LrsContext } = {}
  ) {
    return this.createStatement({
      object: this.constructCourseBlockObject(block),
      verb: verb,
      context: options.context,
    });
  }

  constructCourseSectionObject(section: CourseSection) {
    return {
      id: `https://id.xapi.buri.io/objects/buricourse/sections/${section.uuid}`,
      definition: {
        name: section.title
          ? {
              "en-ph": section.title,
            }
          : undefined,
        type: ACTIVITY_TYPES.courseSection,
      },
    };
  }

  didCourseSection(
    verb: LrsVerb,
    section: CourseSection,
    options: { context?: LrsContext } = {}
  ) {
    return this.createStatement({
      object: this.constructCourseSectionObject(section),
      verb: verb,
      context: options.context,
    });
  }

  constructGroupObject(group: Group) {
    return {
      id: `https://id.xapi.buri.io/objects/buripass/groups/${group.id}`,
      definition: {
        name: group.name
          ? {
              "en-ph": group.name,
            }
          : undefined,
        type: ACTIVITY_TYPES.group,
      },
    };
  }

  didGroup(
    verb: LrsVerb,
    group: Group,
    options: { context?: LrsContext } = {}
  ) {
    return this.createStatement({
      object: this.constructGroupObject(group),
      verb: verb,
      context: options.context,
    });
  }

  constructUserObject(user: User) {
    return {
      // objectType: "Agent",
      // openid: `https://pass.buri.io/users/${user.id}`,
      // name: `${user.firstName} ${user.lastName}`,
      id: this.getOpenidFromUserId(user.id),
      definition: {
        name: {
          "en-ph": user.email || `${user.firstName} ${user.lastName}`,
        },
      },
    };
  }

  async didUserInParent(
    verb: LrsVerb,
    user: User,

    options: { context?: LrsContext } = {}
  ) {
    let cast = await firstValueFrom(this.dashboardService.getCast());

    await this.createStatement({
      object: this.constructUserObject(user),
      verb: verb,
      context: {
        ...options.context,
        contextActivities: {
          parent: [
            this.constructGroupObject({
              id: cast.data.attributes.defaultGroupId,
            }),
          ],
          category: [
            this.constructGroupObject({
              id: cast.data.attributes.defaultGroupId,
            }),
          ],
        },
      },
    });
  }

  extractFromLocale(obj: any) {
    return (
      obj?.["en-ph"] ||
      obj?.["enPh"] ||
      obj?.["en"] ||
      obj?.["en-us"] ||
      obj?.["en-US"] ||
      obj?.["enUs"]
    );
  }
}
