<app-back-title-layout>
  <div backTitleLayoutBeforeMain>
    <div class="my-5 rounded bg-white p-2">
      <h3 class="font-bold mb-1">Time Range</h3>

      <div class="flex items-center justify-between">
        <div class="flex justify-between gap-2 items-center">
          <select
            name=""
            id=""
            class="form-control"
            [formControl]="timerangeSelectFormControl"
          >
            <option
              [value]="item.value"
              *ngFor="let item of timerangeSelectOptions"
            >
              ({{ item.name }})
            </option>
          </select>

          <ng-container *ngIf="timerangeSelectFormControl.value === 'custom'">
            <input
              class="form-control"
              type="date"
              [formControl]="timerangeFromFormControl"
            />
            <span>to</span>
            <input
              class="form-control"
              type="date"
              [formControl]="timerangeToFormControl"
            />
            <div class="flex"></div>
          </ng-container>
        </div>
        <button
          [disabled]="
            timerangeFromFormControl.invalid || timerangeToFormControl.invalid
          "
          class="btn btn-primary"
          [appWaitForObsAfterClick]="createSnapshotObs"
        >
          Generate Report
        </button>
      </div>
    </div>

    <h2 class="font-bold text-xl my-5">Generated Reports</h2>
  </div>

  <div class="my-5">
    <app-item-list [items]="snapshotItems" [itemTemplate]="itemTemplate">
    </app-item-list>
  </div>
</app-back-title-layout>

<ng-template #itemTemplate let-itemObs="item">
  <div
    class="flex items-center justify-between"
    *ngIf="itemObs | async as item"
  >
    <a
      [routerLink]="['../', 'snapshot-display']"
      [queryParams]="{ snapshot_id: item.snapshotId }"
    >
      <div class="text-gray-500 text-sm">
        <span class="text-gray-700 italic"> ({{ item.name }}) </span> from
        <span class="font-bold text-gray-700">{{
          item.fromDate | date : "longDate"
        }}</span>
        to
        <span class="font-bold text-gray-700">{{
          item.toDate | date : "longDate"
        }}</span>
      </div>

      <div class="text-gray-500 text-xs">
        created at {{ item.createdAt | date : "medium" }}
      </div>
    </a>
    <div>
      <app-pill-label type="success" *ngIf="item.status === 'done'">
        READY
      </app-pill-label>

      <ng-container
        *ngIf="item.status === 'idle' || item.status === 'processing'"
      >
        <app-icon icon="cached" [spin]="true"></app-icon>

        <app-pill-label> PROCESSING </app-pill-label>
      </ng-container>
    </div>
  </div>
</ng-template>
