<h2 class="form-heading mb-8">Collections</h2>
<!-- <div
  *ngIf="loading"
  [@fadeInOut]
  class="flex absolute bg-opacity-50 items-center justify-center"
>
  <app-loading [loading]="true" position="relative"></app-loading>
</div> -->
<div class="mb-8 w-3/4">
  <app-search-bar-query
    placeholder="Search"
    [queryField]="'q'"
  ></app-search-bar-query>
</div>

<app-item-select-with-header
  [itemTemplate]="itemTemplate"
  [source]="collectionListObservable"
  [headers]="headers"
  [disableCheckbox]="true"
  [limit]="limit"
  [totalCount]="collectionCount"
  [loading]="loading"
>
</app-item-select-with-header>

<ng-template #itemTemplate let-collection="item">
  <div class="flex">
    <div class="block sm:flex mt-2 sm:mt-0">
      <a class="block w-full sm:flex" [mat-dialog-close]="collection.uuid">
        <ng-container *ngIf="defaultHeaders">
          <div
            class="grid grid-cols-1 my-1 w-full sm:flex"
            *ngFor="let header of defaultHeaders"
          >
            <!-- Name -->
            <div
              class="leading-tight truncate text-sm sm:items-center sm:w-96"
              *ngIf="header === 'Collection Name'"
            >
              <div class="">{{ collection.name }}</div>
            </div>
          </div>
        </ng-container>
      </a>
    </div>
  </div>
</ng-template>
