<app-dropdown-layout position="right">
  <app-icon dropdownToggle icon="more_vert"> </app-icon>

  <div dropdownContent>
    <ng-container *ngFor="let _screenAction of screenActions">
      <button
        class="w-full py-1 px-2 text-left hover:bg-gray-200"
        (click)="screenAction(_screenAction.action)"
      >
        {{ _screenAction.name }}
      </button>
    </ng-container>
  </div>
</app-dropdown-layout>
