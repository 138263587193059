import { Component, forwardRef, Input, OnInit } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "app-markdown-viewer",
  templateUrl: "./markdown-viewer.component.html",
  styleUrls: ["./markdown-viewer.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MarkdownViewerComponent),
      multi: true,
    },
  ],
})
export class MarkdownViewerComponent implements ControlValueAccessor {
  @Input()
  value: string;

  @Input()
  backgroundColor: string;

  @Input()
  foregroundColor: string;
  @Input()
  disabled: boolean;

  @Input()
  placeholder: string = "Search";

  @Input()
  styles: string = "";
  propagateChange = (_: any) => {};

  constructor() {}

  writeValue(value: any): void {
    if (value !== undefined && value !== null) {
      this.value = value;
    }
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {}
}
