import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-assessment-new-question-select-type",
  templateUrl: "./assessment-new-question-select-type.component.html",
})
export class AssessmentNewQuestionSelectTypeComponent implements OnInit {
  loading: boolean;

  constructor(private router: Router, private route: ActivatedRoute) {}

  async ngOnInit() {}

  async onTypeSelect(activityType: string) {
    this.router.navigate(["./", "new"], {
      relativeTo: this.route.parent,
      queryParams: {
        // Uncomment if we want to disable show results in assessment
        // disable_show_results: true,
        item_type: activityType,
      },
      queryParamsHandling: "merge",
      replaceUrl: true,
    });
  }
}
