import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import {
  Validators,
  FormGroup,
  FormBuilder,
  FormControl,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import {
  catchError,
  Subject,
  switchMap,
  takeUntil,
  tap,
  throwError,
  zip,
} from "rxjs";
import { V3PayService } from "src/app/sinigangnababoywithgabi";
import { SubscriptionFormGroupFields } from "../components/purchases-subscription-forms/purchases-subscription-forms.component";

@Component({
  selector: "app-purchase-subscription-new",
  templateUrl: "./purchase-subscription-new.component.html",
  styles: [],
})
export class PurchaseSubscriptionNewComponent implements OnInit {
  subscriptionFormGroups: FormGroup<SubscriptionFormGroupFields>[];

  loading: boolean;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private location: Location,
    private v3PayService: V3PayService
  ) {}

  private unsubscribe$: Subject<void> = new Subject<void>();
  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  async ngOnInit() {
    this.subscriptionFormGroups = [this.constructSubscriptionFormGroup()];
  }

  constructSubscriptionFormGroup() {
    return this.formBuilder.group<SubscriptionFormGroupFields>({
      productTitle: new FormControl(),

      price: new FormControl(null),

      duration: new FormControl(null, Validators.required),
      description: new FormControl(null),
      isFreeTrialEnabled: new FormControl(false),
      freeTrialDuration: new FormControl(null),

      helixPayReferenceUuid: new FormControl(null),
      helixPayReferenceReferenceId: new FormControl(null),
    });
  }

  onSave() {
    const {
      product_reference_type: productReferenceType,
      product_reference_id: productReferenceId,
    } = this.route.snapshot.queryParams;

    this.loading = true;

    // Create subscription group
    // Create multiple products and plans referencing the subsgroup

    this.v3PayService
      .createSubscriptionGroup({
        name: "Learner Subscription",
      })
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap((subsgroup) => {
          return zip(
            this.subscriptionFormGroups.map((fg) => {
              const formGroupValue = fg.value;
              const [planPeriod, planUnit] =
                formGroupValue.duration?.split(" ") || [];
              const [trialPeriod, trialUnit] =
                formGroupValue.freeTrialDuration?.split(" ") || [];

              let productReferences = [
                {
                  referenceType: productReferenceType,
                  referenceId: productReferenceId,
                },
              ];

              if (fg.value.helixPayReferenceReferenceId) {
                productReferences.push({
                  referenceType: "helixpay",
                  referenceId: fg.value.helixPayReferenceReferenceId,
                });
              }

              return this.v3PayService.createSubscriptionPlanWithProduct({
                product: {
                  title: formGroupValue.productTitle,
                  stockRecords: [
                    {
                      price: formGroupValue.price,
                    },
                  ],
                  productReferences,
                },
                plan: {
                  name: formGroupValue.productTitle,
                  group: subsgroup.uuid,
                  planPeriod: parseInt(planPeriod),
                  planUnit,
                  trialPeriod: trialPeriod ? parseInt(trialPeriod) : undefined,
                  trialUnit,
                  isRecurring: true,
                },
              });
            })
          );
        }),
        tap(() => {
          this.location.back();
        }),
        catchError((err) => {
          this.loading = false;

          return throwError(() => err);
        })
      )
      .subscribe();
  }

  addSubscriptionFormGroup() {
    this.subscriptionFormGroups.push(this.constructSubscriptionFormGroup());
  }
}
