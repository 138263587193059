<div class="fixed top-28 inset-x-1/4 z-50 flex flex-col">
  <div
    *ngFor="let error of errors; let i = index"
    [@fadeInOut]
    class="flex p-3 bg-error-100 text-error-700 rounded my-2"
  >
    <app-icon icon="error" class="text-lg"></app-icon>

    <div class="flex-1 mx-2">
      <div class="font-bold line-clamp-2 text-lg">{{ error.message }}</div>

      <div>Oops, something went wrong.</div>
    </div>

    <span>
      <button class="btn btn-icon" (click)="removeItem(errors, i)">
        <app-icon icon="close"></app-icon>
      </button>
    </span>
  </div>

  <div
    *ngFor="let successMessage of successMessages; let i = index"
    [@fadeInOut]
    class="flex p-3 bg-success-100 text-success-700 rounded my-2"
  >
    <app-icon icon="check_circle" class="text-lg"></app-icon>

    <div class="flex-1 mx-2">
      <div class="font-bold line-clamp-2 text-lg">
        {{ successMessage.title }}
      </div>

      <div>{{ successMessage.message }}</div>
    </div>

    <span>
      <button class="btn btn-icon" (click)="removeItem(successMessages, i)">
        <app-icon icon="close"></app-icon>
      </button>
    </span>
  </div>

  <!-- <button (click)="testAddError()">add Error</button> -->
</div>
