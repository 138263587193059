<div class="flex-0 flex flex-col justify-between">
  <div>
    <div [@slideIn] *ngIf="!isInEditMode">
      <button
        class="bg-gray-200 text-primary btn btn-icon"
        (click)="onToggleEditMode()"
      >
        <app-icon icon="edit"></app-icon>
      </button>
    </div>

    <div [@slideIn] class="flex" *ngIf="isInEditMode">
      <a
        *ngIf="settingsRouterLink"
        class="ml-2 text-primary bg-gray-200 btn btn-icon"
        [routerLink]="settingsRouterLink"
        [queryParams]="settingsQueryParams"
      >
        <app-icon icon="settings"></app-icon>
      </a>

      <button
        class="ml-2 text-white bg-red-600 btn btn-icon"
        (click)="onDeleteBlock()"
      >
        <app-icon icon="delete"></app-icon>
      </button>

      <button
        class="ml-2 text-white bg-green-600 btn btn-icon"
        (click)="onSaveBlock()"
      >
        <app-icon icon="check"></app-icon>
      </button>
    </div>
  </div>
</div>
