import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  debounceTime,
  distinctUntilChanged,
  map,
  Observable,
  of,
  share,
  switchMap,
  tap,
} from "rxjs";
import { fadeInOut } from "src/app/app-common-module/animations/fade";
import { Header } from "src/app/app-common-module/components/list-table-header/list-table-header.component";
import { DashboardService } from "src/app/services/dashboard.service";
import { MailerService } from "src/app/services/mailer.service";

@Component({
  selector: "app-filter-results",
  templateUrl: "./filter-results.component.html",
  styles: [],
  animations: [fadeInOut],
})
export class FilterResultsComponent implements OnInit {
  @Input() filters: any[] = [];
  @Output() totalUsers = new EventEmitter<any>();
  limit: number = 20;
  loading: boolean = false;
  totalCount: number = 0;
  oneMonth = 2629800000;
  filterUsersObservable: Observable<any[]>;
  headers: Header[] = [
    { name: "Email", value: "email", width: "w-96" },
    { name: "Name", value: "name", width: "w-96" },
    { name: "Date Added", value: "date", width: "w-56" },
    { name: "Status", value: "status", width: "w-56" },
  ];
  defaultHeaders: string[] = ["Email", "Name", "Date Added", "Status"];
  constructor(
    private mailerService: MailerService,
    private route: ActivatedRoute,
    private dashboardService: DashboardService
  ) {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.filters.currentValue &&
      changes.filters.currentValue.length > 0
    ) {
      this.constructObservable();
    }
  }

  constructObservable() {
    this.filterUsersObservable = this.route.queryParams.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((params) => {
        let filters = [];
        if (this.filters.length > 0) {
          filters = this.mailerService.parseFilter(this.filters[0]);
        }
        let { page } = params;
        // console.log(this.filters);
        return this.mailerService.getFilteredUsers(
          page * this.limit,
          this.limit,
          {
            filters: filters,
          }
        );
      }),
      tap(({ data, loading }) => {
        if (data) {
          this.totalCount = data.filterUsers.count;
          this.totalUsers.emit(this.totalCount);
        } else {
          this.totalCount = 0;
          this.totalUsers.emit(this.totalCount);
        }
      }),
      map(({ data, loading }) => {
        if (!data) {
          return [];
        }
        return data.filterUsers.data.map((user) => {
          let userInfo = user.userInfo;
          return {
            email: userInfo.email,
            name: userInfo.firstName + " " + userInfo.lastName,
            uuid: userInfo.id,
            confirmedAt: userInfo.confirmedAt,
            createdAt: userInfo.createdAt,
            active:
              Date.now() - this.oneMonth <= Date.parse(userInfo.lastSignInAt) &&
              Date.parse(userInfo.lastSignInAt) <= Date.now(),
          };
        });
      })
    );
  }
}
