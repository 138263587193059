<app-back-title-layout layoutTitle="Select Item" [loading]="!components">
  <ng-container *ngIf="components">
    <app-search-bar
      #search
      placeholder="Search"
      [(ngModel)]="searchString"
    ></app-search-bar>

    <app-resource-library-selector
      [searchString]="searchString"
      [source]="source"
      (select)="onSelect($event)"
    >
    </app-resource-library-selector>

    <!-- <div class="mb-3 mt-10">
      <h2 class="font-bold h-5">
        <button
          *ngIf="collection?.name"
          class="font-bold"
          (click)="refreshList()"
        >
          <app-icon icon="navigate_before"></app-icon>
          {{ collection.name }}
        </button>

        <span *ngIf="components && !collection?.name"> Collections </span>
      </h2>
    </div>

    <ul>
      <a
        *ngIf="!collection"
        class="flex items-center cursor-pointer border-b-2"
        (click)="allResourcesClick()"
      >
        <div class="px-2 h-20 w-20">
          <div class="h-full w-full flex items-center justify-center">
            <app-icon class="text-6xl text-primary" icon="folder"></app-icon>
          </div>
        </div>

        <div class="flex-grow">
          <div class="truncate font-semibold leading-tight text-primary">
            See All Resources
          </div>
        </div>
      </a>

      <app-item-list
        [items]="components | filterAll: searchString"
        [itemTemplate]="itemTemplate"
      >
      </app-item-list>

      <ng-container *ngIf="components && components.length === 0">
        <div
          class="flex flex-col items-center border-t border-b py-4 text-primary-light text-2xl"
        >
          <app-icon class="text-4xl" icon="error"></app-icon>

          <span class="text-2xl uppercase"> Empty list </span>
        </div>
      </ng-container>
    </ul> -->
  </ng-container>
</app-back-title-layout>

<ng-template #itemTemplate let-item="item">
  <a class="flex cursor-pointer" (click)="componentClick(item)">
    <div class="px-2 h-20 w-20 flex-none">
      <ng-container *ngIf="!item.type && !item.coverUri; else imageIcon">
        <div class="h-full w-full flex items-center justify-center">
          <app-icon
            class="text-6xl text-black opacity-50"
            icon="folder"
          ></app-icon>
        </div>
      </ng-container>

      <ng-template #imageIcon>
        <img
          loading="lazy"
          class="h-full w-full object-contain"
          [src]="item.coverUri"
        />
      </ng-template>
    </div>

    <div class="flex flex-col justify-between flex-grow min-w-0">
      <div>
        <div class="uppercase text-xs text-gray-600">
          {{ (item?.type | resourceType) || "collection" }}
        </div>

        <div class="truncate text-sm font-semibold leading-tight">
          {{ item?.name }}
        </div>
      </div>

      <div class="uppercase text-xs text-gray-600">
        {{ item?.flavorTextSpan }}
      </div>
    </div>
  </a>
</ng-template>
