import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { TisRoutingModule } from "./tis-routing.module";
import { TisOptionsComponent } from "./tis-options/tis-options.component";
import { TisComponent } from "./tis.component";
import { AppCommonModule } from "../app-common-module/app-common-module.module";

@NgModule({
  declarations: [TisOptionsComponent, TisComponent],
  imports: [CommonModule, TisRoutingModule, AppCommonModule],
})
export class TisModule {}
