import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { firstValueFrom } from "rxjs";
import { DashboardService } from "src/app/services/dashboard.service";
import { OAuthService, CastsService } from "src/app/sinigangnababoywithgabi";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-oauth-callback",
  templateUrl: "./oauth-callback.component.html",
  styleUrls: ["./oauth-callback.component.scss"],
})
export class OauthCallbackComponent implements OnInit {
  constructor(
    private oauthService: OAuthService,
    private route: ActivatedRoute,
    private router: Router,
    private dashboardService: DashboardService
  ) {}

  ngOnInit() {
    this.login();
  }

  async login() {
    let cast = await firstValueFrom(this.dashboardService.getCast());
    let clientId = cast.data.attributes.auth.clientId;

    let queryParams = this.route.snapshot.queryParams;

    await firstValueFrom(
      this.oauthService.loginCookie({
        code: queryParams["code"],
        redirectUri: `${window.location.origin}/auth/callback`,
        platform: window.location.origin,
        clientId: clientId,
      })
    );

    let userinfo = await firstValueFrom(this.oauthService.getUserInfo());
    let { permissions, applicationUuid } = await firstValueFrom(
      this.oauthService.getUserPermissions()
    );

    localStorage.setItem("userinfo", JSON.stringify(userinfo));
    localStorage.setItem("permissions", JSON.stringify(permissions));
    localStorage.setItem("application_id", applicationUuid);

    let loginCallback = localStorage.getItem("login_callback");
    localStorage.removeItem("login_callback");

    if (
      loginCallback &&
      !loginCallback.includes("logout") &&
      !loginCallback.includes("/auth/callback")
    ) {
      this.router.navigateByUrl(loginCallback, {
        replaceUrl: true,
      });
    } else {
      this.router.navigate(["/"], { replaceUrl: true });
    }
  }
}
