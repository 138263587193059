import { Location } from "@angular/common";
import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { firstValueFrom, Subject } from "rxjs";
import { filter, switchMap, takeUntil, tap } from "rxjs/operators";
import { ModalService } from "src/app/services/modal.service";
import {
  CourseBlock,
  CoursesService,
  ResourcesService,
  V1ExamsService,
} from "src/app/sinigangnababoywithgabi";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-course-block-edit",
  templateUrl: "./course-block-edit.component.html",
})
export class CourseBlockEditComponent implements OnInit, AfterContentChecked {
  formGroup: FormGroup;
  loading: boolean;
  showUnlockSchedule: boolean;
  block: CourseBlock;
  selectedResourceUuid: string;

  prerequisiteBlocks: CourseBlock[];

  showSeparatePassingBehaviorControls: boolean;

  constructor(
    private route: ActivatedRoute,
    private courseService: CoursesService,
    private resourcesService: ResourcesService,
    private v1ExamsService: V1ExamsService,
    private formBuilder: FormBuilder,
    private location: Location,
    private modalService: ModalService,
    private cd: ChangeDetectorRef
  ) {}

  private unsubscribe$: Subject<void> = new Subject<void>();
  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
  ngAfterContentChecked(): void {
    this.cd.detectChanges();
  }

  async ngOnInit() {
    this.showUnlockSchedule = environment.UNLOCK_SCHEDULE_ENABLED;
    let { block_uuid: blockUuid } = this.route.snapshot.queryParams;

    this.block = await firstValueFrom(
      this.courseService.blockRead(
        blockUuid,
        "reference_info,prerequisites_detail"
      )
    );

    this.prerequisiteBlocks = this.block.prerequisites as CourseBlock[];

    let disableAssessmentFormControls: boolean =
      this.block.content.resourceDetails?.type !== "assessment" &&
      this.block.content.resourceDetails?.type !== "burislides";

    this.showSeparatePassingBehaviorControls =
      this.block.content.resourceDetails?.subtype === "file-upload";

    this.formGroup = this.formBuilder.group({
      completeExternally: [
        { value: this.block.completeExternally, disabled: false },
      ],

      isCertificateRequired: [
        {
          value: !!this.block.referenceInfo?.customPassingMark,
          disabled: disableAssessmentFormControls,
        },
      ],
      referenceId: [
        {
          value: this.block.referenceInfo?.referenceId,
          disabled: disableAssessmentFormControls,
        },
      ],
      customPassingMark: [
        {
          value: this.block.referenceInfo?.customPassingMark,
          disabled: disableAssessmentFormControls,
        },
        [Validators.min(1), Validators.max(100)],
      ],
      maxAllowedSubmissions: [
        {
          value: this.block.referenceInfo?.maxAllowedSubmissions,
          disabled: disableAssessmentFormControls,
        },
        [Validators.min(1), Validators.max(100)],
      ],

      passOnSubmit: [
        {
          value:
            this.block.referenceInfo?.passOnSubmit ||
            !this.block.completeOnPassing,
          disabled:
            disableAssessmentFormControls ||
            this.showSeparatePassingBehaviorControls,
        },
      ],
      completeOnPassing: [
        {
          value: this.block.completeOnPassing,
          disabled: disableAssessmentFormControls,
        },
      ],

      // Custom behavior
      passBehavior: [
        {
          value: this.block.referenceInfo?.passOnSubmit
            ? "pass_on_submit"
            : this.block.completeOnPassing
            ? "pass_to_complete"
            : null,
          disabled:
            disableAssessmentFormControls ||
            this.showSeparatePassingBehaviorControls,
        },
        // Validators.required,
      ],

      unlockOnSchedule: [!!this.block.openDate],
      unlockOnScheduleDate: [this.block.openDate],
      resourceExpiry: [!!this.block.daysTilExpired],
      resourceExpiryInDays: [this.block.daysTilExpired],
      // resourceExpiryDate: [this.block.daysTilExpired],
    });

    //Auto formatting
    this.formGroup
      .get("customPassingMark")
      .valueChanges.pipe(
        takeUntil(this.unsubscribe$),
        tap((value: string) => {
          this.formGroup.get("customPassingMark").patchValue(value, {
            emitEvent: false,
          });
        })
      )
      .subscribe();

    this.formGroup
      .get("maxAllowedSubmissions")
      .valueChanges.pipe(
        takeUntil(this.unsubscribe$),
        tap((value) => {
          this.formGroup.get("maxAllowedSubmissions").patchValue(value, {
            emitEvent: false,
          });
        })
      )
      .subscribe();

    // Pass behaviour events
    this.formGroup
      .get("passBehavior")
      .valueChanges.pipe(
        takeUntil(this.unsubscribe$),
        tap((value) => {
          if (value === "pass_on_submit") {
            this.formGroup.patchValue({
              passOnSubmit: true,
              completeOnPassing: false,
            });
          } else {
            this.formGroup.patchValue({
              passOnSubmit: false,
              completeOnPassing: true,
            });
          }
        })
      )
      .subscribe();

    // We need to change this to save only after formsave
    this.formGroup
      .get("isCertificateRequired")
      .valueChanges.pipe(
        takeUntil(this.unsubscribe$),
        filter((value) => value),
        switchMap(() => {
          this.formGroup.get("passBehavior").addValidators(Validators.required);

          return this.resourcesService.getResourceByUuid(
            this.block.content?.resourceDetails?.uuid
          );
        }),

        tap((result) => {
          let timeslotUuid = result.content.examDetails?.examTimeslotUuid;

          this.formGroup.patchValue({
            referenceId: timeslotUuid,
            customPassingMark: 50,
            maxAllowedSubmissions: null,
          });
        })
      )
      .subscribe();

    this.formGroup
      .get("isCertificateRequired")
      .valueChanges.pipe(
        takeUntil(this.unsubscribe$),
        filter((value) => !value),
        switchMap(() => {
          this.formGroup.get("passBehavior").clearValidators();
          return this.resourcesService.getResourceByUuid(
            this.block.content?.resourceDetails?.uuid
          );
        }),
        tap((result) => {
          let timeslotUuid = result.content.examDetails?.examTimeslotUuid;
          this.formGroup.patchValue(
            {
              referenceId: timeslotUuid,
              customPassingMark: null,
              maxAllowedSubmissions: null,
              completeOnPassing: false,
            },
            {
              emitEvent: false,
            }
          );
        })
      )
      .subscribe();
  }

  async deleteBlock() {
    let result = await this.modalService.confirm(
      "Are you sure you want to delete this block?"
    );

    if (result) {
      await firstValueFrom(this.courseService.blockDelete(this.block.uuid));

      this.location.back();
    }
  }

  async formSave() {
    try {
      this.loading = true;

      await firstValueFrom(
        this.courseService.blockUpdate(this.block.uuid, {
          completeOnPassing: this.formGroup.value.completeOnPassing,
          completeExternally: this.formGroup.value.completeExternally,
          openDate: this.formGroup.value.unlockOnSchedule
            ? this.formGroup.value.unlockOnScheduleDate
            : null,
          daysTilExpired: this.formGroup.value.resourceExpiry
            ? this.formGroup.value.resourceExpiryInDays
            : 0,
          referenceInfo: {
            referenceId: this.formGroup.value.referenceId,
            customPassingMark: this.formGroup.value.customPassingMark,
            maxAllowedSubmissions: this.formGroup.value.maxAllowedSubmissions,
            passOnSubmit:
              this.formGroup.value.passOnSubmit ||
              !this.formGroup.value.completeOnPassing,
          },
        })
      );

      if (
        this.formGroup.get("referenceId").enabled &&
        this.formGroup.get("referenceId").value
      ) {
        await firstValueFrom(
          this.v1ExamsService.examsTimeslotsUpdate(
            this.formGroup.value.referenceId,
            {
              maxAllowedSubmissions: this.formGroup.value.maxAllowedSubmissions,
            }
          )
        );
      }

      this.location.back();
    } catch (err) {
      this.loading = false;
    }
  }
}
