<app-back-title-layout layoutTitle="Generate Enrollment Link">
  <div class="text-sm text-gray-600 my-2">
    Copy this link to automatically allow users to enroll to your course
  </div>

  <div class="flex items-center justify-between my-2">
    <input class="form-control" [(ngModel)]="latestUrl" />

    <button
      (click)="copyLink()"
      class="p-2"
      [attr.data-tooltip]="copiedLink ? 'Copied' : 'Copy'"
      [cdkCopyToClipboard]="latestUrl"
    >
      <app-icon icon="copy"></app-icon>
    </button>
  </div>

  <button class="btn btn-primary w-full my-2" (click)="generateLink()">
    GENERATE LINK
  </button>
</app-back-title-layout>

<ng-template #itemTemplate let-item="item">
  <div
    class="flex items-center justify-between bg-gray-200 text-gray-500 rounded p-2"
  >
    <!-- <div class="min-w-0 flex-1 bg-red-100">
      <span class="whitespace-nowrap text-ellipsis">
        {{ item.linkUrl }}
      </span>
    </div> -->
    <div class="flex-1 truncate text-xs">
      {{ item.linkUrl }}
    </div>

    <button class="p-2" [cdkCopyToClipboard]="item.linkUrl">
      <app-icon icon="copy"></app-icon>
    </button>
  </div>
</ng-template>
