import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-pagination-query",
  templateUrl: "./pagination-query.component.html",
  styles: [],
})
export class PaginationQueryComponent implements OnInit {
  @Input() page: number;
  @Output() currentPage = new EventEmitter<number>();
  @Input() listLength: number = 1;
  @Input() pageSize: number = 20;

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.page = parseInt(this.route.snapshot.queryParams["page"]) || 0;
  }

  onPageChange(value) {
    this.currentPage.emit(value);
    this.router.navigate([], {
      relativeTo: this.route,
      queryParamsHandling: "merge",
      replaceUrl: true,
      queryParams: {
        page: value,
      },
    });
  }
}
