<ul class="flex flex-col gap-2">
  <ng-container *ngFor="let choice of choiceResults">
    <li class="bg-gray-100 p-2 rounded">
      <span class="text-gray-700"> {{ choice.choiceText }} </span>

      <div>
        <img
          *ngIf="choice.choiceImageUri"
          [src]="choice.choiceImageUri"
          class="w-full h-40 mb-1 object-contain border-0 bg-gray-300 rounded"
          alt=""
        />

        <audio
          *ngIf="choice.choiceImageUri"
          [src]="choice.choiceAudioUri"
          class="w-full h-8"
          controls=""
        ></audio>
      </div>

      <progress
        class="w-full bg-primary-200 rounded-full h-3"
        [max]="choice.countMax"
        [value]="choice.count"
        [ngClass]="{
          success: showCorrectness && choice.isCorrect,
          error: showCorrectness && !choice.isCorrect,
          neutral: !showCorrectness
        }"
      ></progress>

      <button
        *ngIf="choice.countMax"
        class="flex text-primary-600 font-semibold text-sm justify-between underline cursor-pointer"
        (click)="
          choice.userUuids &&
            choice.userUuids.length > 0 &&
            openUsersViewedModal(choice.userUuids)
        "
      >
        <div>
          <span>{{ choice.count }}</span> /
          <span> {{ choice.countMax }} </span> answered
        </div>
      </button>
    </li>
  </ng-container>
</ul>
