<div
  class="input-group my-10"
  *ngIf="resource"
  [formGroup]="formGroup.get('content')"
>
  <ng-container [ngSwitch]="formGroup.value.subtype">
    <ng-container *ngSwitchCase="'flarum'"> </ng-container>

    <ng-container *ngSwitchCase="'email'">
      <label>
        <span class="form-label"> Email </span>
        <input
          formControlName="email"
          placeholder="email@example.com"
          class="form-control"
        />
      </label>

      <app-form-control-error-layout
        [control]="formGroup.get('content').get('email')"
      ></app-form-control-error-layout>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <label>
        <span class="form-label"> Paste Link </span>
        <input
          formControlName="uri"
          placeholder="www.cast.com"
          class="form-control"
        />
      </label>

      <app-form-control-error-layout
        [control]="formGroup.get('content').get('uri')"
      ></app-form-control-error-layout>
    </ng-container>
  </ng-container>

  <ng-container
    *ngIf="
      formGroup.value.content?.uri && formGroup.get('content').get('uri').valid
    "
  >
    <a
      [href]="formGroup.value.content.uri"
      target="_blank"
      class="block btn w-full my-1 py-0 text-sm bg-blue-500 text-white"
    >
      <!-- <app-icon icon="eye"></app-icon> -->
      PREVIEW
    </a>
  </ng-container>

  <div class="my-10">
    <span class="form-label"> Optional Query Parameters </span>

    <ng-container
      *ngFor="
        let queryParamGroup of formGroup.get('content').get('queryParams')[
          'controls'
        ];
        let i = index
      "
    >
      <div class="flex my-2" [formGroup]="queryParamGroup">
        <input
          type="text"
          class="form-control"
          formControlName="name"
          placeholder="Parameter name..."
        />

        <input
          type="text"
          class="form-control mx-2"
          formControlName="value"
          placeholder="Parameter value..."
        />

        <button
          type="button"
          (click)="removeQueryParam(i)"
          class="btn btn-icon bg-transparent text-gray-500"
        >
          <app-icon icon="close"></app-icon>
        </button>
      </div>
    </ng-container>

    <button
      (click)="addQueryParam()"
      type="button"
      class="btn bg-transparent text-secondary-500"
    >
      <app-icon icon="add_circle"></app-icon>

      Add parameter
    </button>
  </div>
</div>
