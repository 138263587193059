import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { map, switchMap, takeUntil } from "rxjs/operators";
import { FilterPipePipe } from "src/app/app-common-module/pipes/filter-pipe.pipe";
import { DashboardService } from "src/app/services/dashboard.service";
import { V3PayService } from "src/app/sinigangnababoywithgabi";

interface PurchasesItem {
  productId: string;
  name: string;
  description: string;
  price: string;
}

@Component({
  selector: "app-purchase-list",
  templateUrl: "./purchase-list.component.html",
  styles: [],
})
export class PurchaseListComponent implements OnInit {
  purchaseListObservable: Observable<PurchasesItem[]>;

  constructor(
    private route: ActivatedRoute,
    private v3PayService: V3PayService
  ) {}

  private unsubscribe$: Subject<void> = new Subject<void>();
  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    let filterAll = new FilterPipePipe();

    this.purchaseListObservable = this.v3PayService
      .listProducts(
        null,
        null,
        null,
        null,
        null,
        "references,stock_records,plan"
      )
      .pipe(
        takeUntil(this.unsubscribe$),
        map((products) => {
          return products.map((product) => {
            let stockRecord = product.stockRecords[0];

            return {
              productId: product.id,
              name: product.title,
              description: "Subscription",
              price: stockRecord?.price,
            };
          });
        }),

        switchMap((items) => {
          return this.route.queryParams.pipe(
            map((params) => {
              return filterAll.transform(items, params["q"]);
            })
          );
        })
      );
  }
}
