import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-reporting-chart-table-simple",
  templateUrl: "./reporting-chart-table-simple.component.html",
  styles: [],
})
export class ReportingChartTableSimpleComponent implements OnInit {
  @Input() data: {
    name: string;
    value: string | number;
    valueText?: string;
  }[] = [];

  @Input() icon: string = "";

  ngOnInit(): void {}
}
