<div
  [style.backgroundColor]="backgroundColor"
  [style.color]="foregroundColor"
  class="w-full min-h-20 overflow-x-hidden {{ styles }}"
>
  <markdown
    class="w-full prose"
    ngPreserveWhitespaces
    [data]="value"
    [disableSanitizer]="true"
  >
  </markdown>
</div>
