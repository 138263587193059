import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-add-subtab-modal",
  templateUrl: "./add-subtab-modal.component.html",
  styles: [],
})
export class AddSubtabModalComponent implements OnInit {
  name: string = "";
  constructor() {}
  ngOnInit(): void {}
}
