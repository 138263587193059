<app-back-title-layout [loading]="!formGroup">
  <ng-container *ngIf="formGroup">
    <h2 class="my-3 text-xl font-bold">Choose Payment Option</h2>

    <form [formGroup]="formGroup" (ngSubmit)="onFormSubmit()">
      <div class="p-2 my-3 rounded bg-error-light text-error-dark flex">
        <app-icon icon="error"></app-icon>

        <div class="ml-2">
          <div class="font-bold text-sm">Payment Providers Required</div>

          <div class="leading-tight text-xs">
            Please
            <span class="underline">set up your payment providers</span> to use
            this feature properly.
          </div>
        </div>
      </div>

      <label class="flex mb-5">
        <div>
          <app-radio
            formControlName="paymentOption"
            name="paymentOption"
            value="onetime"
            class="text-primary"
          ></app-radio>
        </div>

        <div class="w-full ml-2">
          <div class="font-bold">One-Time Payment</div>

          <div class="text-sm text-gray-600">
            Add paywall once, without time frame
          </div>

          <label
            class="my-1"
            *ngIf="formGroup.value.paymentOption === 'onetime'"
          >
            <div class="form-label text-sm">Price</div>

            <div class="w-1/2">
              <input
                type="number"
                formControlName="price"
                class="form-control"
              />
            </div>
          </label>
        </div>
      </label>

      <label class="flex">
        <div>
          <app-radio
            formControlName="paymentOption"
            name="paymentOption"
            value="recurring"
            class="text-primary"
          ></app-radio>
        </div>

        <div class="w-full ml-2">
          <div class="font-bold">Subscription</div>

          <div class="text-sm text-gray-600">
            Add recurring payment after a time frame
          </div>

          <!-- <div class="my-1 p-2 bg-primary-lightest rounded text-xs">
          <span class="font-bold">100.00 PHP</span> /
          <span>Monthly</span>
        </div>

        <a class="text-xs text-primary underline">Edit Subscription</a> -->
        </div>
      </label>

      <input
        type="submit"
        value="NEXT"
        class="my-10 btn btn-primary w-full"
        [disabled]="formGroup.invalid"
      />
    </form>
  </ng-container>
</app-back-title-layout>
