import { Component } from '@angular/core';

@Component({
  selector: 'app-tis-options',
  templateUrl: './tis-options.component.html',
  styles: [
  ]
})
export class TisOptionsComponent {

}
