import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-user-management",
  templateUrl: "./user-management.component.html",
})
export class UserManagementComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
