<div
  class="py-2 px-3 border rounded flex items-center text-gray-700 w-full print:hidden"
>
  <app-icon class="mr-2 text-gray-500" icon="search"></app-icon>

  <input
    class="w-full text-sm leading-tight appearance-none outline-none border-none"
    type="text"
    name="search"
    [placeholder]="placeholder"
    [disabled]="disabled"
    [ngModel]="value"
    (ngModelChange)="propagateChange($event)"
  />
</div>
