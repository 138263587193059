import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { ActivityLogService } from "src/app/services/activity-log.service";
import { map } from "rxjs/operators";

interface LogItem {
  name?: string;
  email?: string;
  timestamp?: string;
  time?: string;
  action?: string;
  itemType?: string;
  item?: string;
}

interface LogListItem {
  date?: string;
  latest?: boolean;
  log?: LogItem[];
}

@Component({
  selector: "app-user-activity-logs",
  templateUrl: "./user-activity-logs.component.html",
})
export class UserActivityLogsComponent implements OnInit {
  title = "User Activity Logs";
  lastSeen: string;

  // logList: LogListItem[];
  logListObservable: Observable<LogListItem[]>;

  constructor(
    private route: ActivatedRoute,
    private activityLogService: ActivityLogService
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  async loadData() {
    let { user_uuid: userUuid } = this.route.snapshot.queryParams;

    this.logListObservable = this.activityLogService
      .aggregate([
        {
          $match: {
            agents: this.activityLogService.getOpenidFromUserId(userUuid),
          },
        },
        {
          $project: {
            group: {
              $dateToString: {
                date: "$timestamp",
                format: "%Y-%m-01",
                timezone: "+08:00",
              },
            },
            statement: 1,
            timestamp: "$statement.timestamp",
          },
        },
        {
          $sort: {
            "statement.timestamp": -1,
          },
        },
        {
          $limit: 100,
        },
        {
          $group: {
            _id: "$group",
            statements: {
              $push: "$statement",
            },
          },
        },
        {
          $sort: {
            _id: -1,
          },
        },
      ])
      .pipe(
        map((result) => {
          let logListItems: LogListItem[] = result.map((item) => {
            let logListItem: LogListItem = {
              date: item._id,
              latest: false,
              log: item.statements.map((statement) =>
                this.activityLogService.constructLogItemFromStatement(statement)
              ),
            };

            return logListItem;
          });

          this.lastSeen = logListItems[0]?.log[0].timestamp;

          return logListItems;
        })
      );
  }
}
