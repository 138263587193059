import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import JSZip from "jszip";
import { Resource, UploadsService } from "src/app/sinigangnababoywithgabi";

interface DirectoryItem {
  uri: string;
  isFile: boolean;
  name: string;
  isIndex: boolean;
}

@Component({
  selector: "app-resource-type-upload-html",
  templateUrl: "./resource-type-upload-html.component.html",
})
export class ResourceTypeUploadHtmlComponent implements OnInit {
  @Input()
  resource: Resource;

  @Input()
  formGroup: UntypedFormGroup;

  baseDirectory: DirectoryItem[];

  loading: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private uploadsService: UploadsService
  ) {}

  ngOnInit() {
    this.formGroup.addControl(
      "content",
      this.formBuilder.group({
        uri: [this.resource.content["uri"], Validators.required],
        baseUri: [this.resource.content["baseUri"], Validators.required],
        fileRelativeUris: [
          this.resource.content["fileRelativeUris"] || [],
          Validators.required,
        ],
        zipUri: [this.resource.content["zipUri"] || "", Validators.required],
      })
    );

    this.formGroup.get("content").get("zipUri").markAsDirty();

    this.constructDirectory();
  }

  async uploadZip(event) {
    this.loading = true;

    this.formGroup.get("content").get("uri").setValue("");
    this.formGroup.get("subtype").setValue("");

    try {
      let file = event.target.files[0];
      event.srcElement.value = null;
      //upload file
      let result = await this.uploadsService.uploadFile(file).toPromise();

      this.formGroup.get("content").get("zipUri").setValue(result.uri);

      //extract zip file
      const zip = new JSZip();
      let fileNames = [];
      let fileRelatives = [];
      let uploadFiles = [];
      let folderName;
      let content;

      let read = await zip.loadAsync(file);

      read.forEach((relativePath, zipEntry) => {
        fileRelatives.push(relativePath);
        fileNames.push(zipEntry.name);
      });

      for (let i = 0; i < fileNames.length; i++) {
        let endPoint = fileNames[i].split("/").pop();
        //checks to see if endpoint is not null
        if (endPoint) {
          content = await read.file(fileNames[i]).async("blob");
          uploadFiles.push(
            new File([content], fileNames[i], {
              type: content.type,
            })
          );
        }
      }

      this.formGroup
        .get("content")
        .get("fileRelativeUris")
        .setValue(fileRelatives);

      //upload extracted zip file
      const file_ = await this.uploadsService
        .uploadDirectory(uploadFiles, folderName, true)
        .toPromise();

      let baseUriCandidate = new RegExp("(https://.+?/.+?/.+?/)").exec(
        file_[0].uri
      )[0];

      //auto set index.html as default after extraction
      for (let i = 0; i < file_.length; ++i) {
        if (file_[i].uri.split("/").pop() === "index.html") {
          this.formGroup.get("content").get("uri").setValue(file_[i].uri);
        }
        if (file_[i].uri.split("/").pop() === "tincan.xml") {
          this.formGroup.get("subtype").setValue("articulate");
        }
      }

      this.formGroup.get("content").get("baseUri").setValue(baseUriCandidate);
    } catch (err) {
      throw err;
    }
    this.constructDirectory();
    this.loading = false;
  }

  constructDirectory() {
    let relativeUris: string[] =
      this.formGroup.value.content["fileRelativeUris"];
    let baseUri: string = this.formGroup.value.content["baseUri"];

    let indexFile: string = this.formGroup.value.content["uri"]
      ?.split("/")
      .pop();

    // Reduce to produce unique files and folders
    let rawDirectory = relativeUris.reduce((map: any, relativeUri) => {
      let uriComponents = relativeUri.split("/");

      if (uriComponents.length === 1) {
        map[relativeUri] = {
          uri: baseUri + relativeUri,
          name: relativeUri,
          isFile: true,
          isIndex: indexFile === relativeUri,
        };
      } else {
        // Get only the top level folder name
        map[uriComponents[0]] = {
          uri: baseUri + relativeUri,
          name: uriComponents[0],
          isFile: false,
          isIndex: false,
        };
      }

      return map;
    }, {});

    this.baseDirectory = Object.values(rawDirectory);
  }

  selectIndex(baseDirectoryItem: DirectoryItem) {
    this.baseDirectory.forEach((item) => {
      item.isIndex = false;
    });

    if (baseDirectoryItem.isFile) {
      baseDirectoryItem.isIndex = true;
    }

    this.formGroup.get("content").get("uri").setValue(baseDirectoryItem.uri);
  }
}
// async fileDrop(event) {
//   this.loading = true;

//   await of(event)
//     .pipe(
//       map((droppedFiles) => {
//         return droppedFiles.filter((droppedFile) => {
//           return droppedFile.fileEntry.isFile;
//         });
//       }),
//       flatMap((droppedFiles) => {
//         return Promise.all(
//           droppedFiles.map((droppedFile) => {
//             return new Promise<File>((resolve, reject) => {
//               const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;

//               fileEntry.file((file: File) => {
//                 let uploadFile = new File([file], droppedFile.relativePath, {
//                   type: file.type,
//                 });

//                 resolve(uploadFile);
//               });
//             });
//           })
//         );
//       }),
//       flatMap((uploadFiles: any[]) => {
//         let folderName;
//         let baseUri = this.formGroup.get("content").value["baseUri"];

//         if (baseUri) {
//           let urlComponents = baseUri.split("/");
//           urlComponents.pop();
//           folderName = urlComponents.pop();
//         }

//         return this.uploadsService.uploadDirectory(
//           uploadFiles,
//           folderName,
//           true
//         );
//       }),
//       map((result) => {
//         // Set base uri
//         let baseUri = this.formGroup.get("content").value["baseUri"];
//         if (!baseUri) {
//           let baseUriCandidate = new RegExp("(https://.+/.+/)").exec(
//             result[0].uri
//           )[0];

//           this.formGroup
//             .get("content")
//             .get("baseUri")
//             .setValue(baseUriCandidate);

//           baseUri = baseUriCandidate;
//         }

//         // Set relative uris
//         let uniqueUrls = [
//           ...new Set([
//             ...this.formGroup.value.content["fileRelativeUris"],
//             ...result.map(
//               (item) => new RegExp(`${baseUri}(.*)`).exec(item.uri)?.[1]
//             ),
//           ]),
//         ];

//         this.formGroup
//           .get("content")
//           .get("fileRelativeUris")
//           .setValue(uniqueUrls);

//         // Set uri
//         let uri = this.formGroup.get("content").value["uri"];
//         if (!uri) {
//           let uriCandidate = result.find(
//             (item) => new RegExp(`(index.*)`).exec(item.uri)?.[0]
//           )?.uri;

//           this.formGroup.get("content").get("uri").setValue(uriCandidate);
//         }
//       })
//     )
//     .toPromise();

//   this.constructDirectory();

//   this.loading = false;
// }
