<ng-template #withLayout>
  <app-back-title-layout [loading]="loading">
    <form [formGroup]="formGroup" class="relative" (ngSubmit)="onSave()">
      <app-exam-item-form [formGroup]="formGroup" [insideSlide]="insideSlide">
      </app-exam-item-form>

      <div class="flex flex-row-reverse">
        <button
          class="btn btn-primary"
          [disabled]="formGroup.invalid"
          type="submit"
        >
          Save
        </button>

        <button
          type="button"
          [disabled]="formGroup.invalid"
          class="btn bg-blue-500 text-white mx-2"
          (click)="onSaveAndCreateAnother()"
        >
          Save and add another item
        </button>
      </div>
    </form>
  </app-back-title-layout>
</ng-template>

<ng-container *ngIf="insideSlide; else withLayout">
  <div class="relative">
    <app-loading position="absolute" padding="py-32" [loading]="loading">
      <form [formGroup]="formGroup" class="relative" (ngSubmit)="onSave()">
        <app-exam-item-form
          [formGroup]="formGroup"
          [insideSlide]="insideSlide"
          (feedback)="onSave()"
        >
        </app-exam-item-form>

        <div class="flex flex-row-reverse sticky bottom-0 right-0">
          <button
            class="btn btn-primary"
            [disabled]="formGroup.invalid"
            type="submit"
          >
            Save
          </button>
        </div>
      </form>
    </app-loading>
  </div>
</ng-container>
