import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-add-field-modal",
  templateUrl: "./add-field-modal.component.html",
  styles: [],
})
export class AddFieldModalComponent implements OnInit {
  defaultFields = [
    {
      type: "default",
      value: "short_text",
      text: "Text",
      icon: "text_fields",
    },
    { type: "default", value: "number", text: "Number", icon: "looks_one" },
    { type: "default", value: "date", text: "Date", icon: "event" },
    {
      type: "default",
      value: "dropdown",
      text: "Dropdown",
      icon: "arrow_drop_down",
    },
    {
      type: "default",
      value: "radio",
      text: "Radio Button",
      icon: "radio_button_checked",
    },
    { type: "default", value: "checkbox", text: "Checkbox", icon: "check_box" },
    { type: "default", value: "paragraph", text: "Text Area", icon: "notes" },
    { type: "default", value: "file_upload", text: "Upload", icon: "upload" },
  ];
  premadeFields = [
    {
      type: "premade",
      value: "department",
      text: "Department",
    },
    { type: "premade", value: "position", text: "Position" },
    { type: "premade", value: "status", text: "Status" },
    {
      type: "premade",
      value: "name",
      text: "Name",
    },
    {
      type: "premade",
      value: "gender",
      text: "Gender",
    },
    {
      type: "premade",
      value: "birthdate",
      text: "Birthdate",
    },
    {
      type: "premade",
      value: "telephone_number",
      text: "Telephone Number",
    },
    { type: "premade", value: "address", text: "Address" },
    {
      type: "premade",
      value: "cellphone_number",
      text: "Cellphone Number",
    },
    {
      type: "premade",
      value: "educational_attainment",
      text: "Educational Attainment",
    },
  ];
  constructor() {}
  ngOnInit(): void {}
}
