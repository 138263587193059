import { Component, OnInit } from "@angular/core";
import {
  Resource,
  V1ExamItem,
  ResourcesService,
  V1ExamSection,
  V1Exam,
  V1ExamsService,
} from "src/app/sinigangnababoywithgabi";
import { ActivatedRoute, Router } from "@angular/router";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  switchMap,
  takeUntil,
} from "rxjs/operators";
import { Location } from "@angular/common";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { ModalService } from "src/app/services/modal.service";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { firstValueFrom, Subject } from "rxjs";
import { CsvService } from "src/app/services/csv.service";

@Component({
  selector: "app-assessment-activity-edit",
  templateUrl: "./assessment-activity-edit.component.html",
})
export class AssessmentActivityEditComponent implements OnInit {
  resource: Resource;

  exam: V1Exam;
  items: V1ExamItem[];
  //HARDCODE assume muna na isang section lang
  section: V1ExamSection;

  publishResourceOnNext: boolean;
  isInsideCourse: boolean;

  canUpdate: boolean;

  formGroup: UntypedFormGroup;
  loading: boolean;
  constructor(
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private location: Location,
    private resourcesService: ResourcesService,
    private v1ExamsService: V1ExamsService,
    private modalService: ModalService,
    private csvService: CsvService
  ) {}

  ngOnInit() {
    this.load();
  }

  private unsubscribe$ = new Subject();
  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  async load() {
    let {
      resource_uuid: resourceUuid,
      publish_resource_on_next: publishResourceOnNext,
      is_inside_course: isInsideCourse,
    } = this.route.snapshot.queryParams;

    this.publishResourceOnNext = !!publishResourceOnNext;
    this.isInsideCourse = !!isInsideCourse;

    if (resourceUuid) {
      this.resource = await firstValueFrom(
        this.resourcesService.getResourceByUuid(resourceUuid)
      );

      if (this.resource.content["examDetails"]) {
        this.parseContent(this.resource.content);
      }
    }
  }

  async parseContent(content) {
    this.exam = await firstValueFrom(
      this.v1ExamsService.examsRead(
        content["examDetails"]["examUuid"],
        "allowed_actions"
      )
    );

    let timeslot = await firstValueFrom(
      this.v1ExamsService.examsTimeslotsRead(
        content["examDetails"]["examTimeslotUuid"]
      )
    );

    let [section] = await firstValueFrom(
      this.v1ExamsService.examsSectionsList(content["examDetails"]["examUuid"])
    );

    if (!section) {
      // HARDCODED
      // Fallback if section is not create
      // Create an exam section
      this.section = await firstValueFrom(
        this.v1ExamsService.examsSectionsCreate(this.exam.uuid, {
          title: "Default Section",
        })
      );
    } else {
      this.section = section;
    }

    this.items = await firstValueFrom(
      this.v1ExamsService.examsSectionsItemsList(this.section.uuid)
    );

    this.formGroup = this.formBuilder.group({
      examUuid: [this.exam.uuid],
      timeslotUuid: [timeslot.uuid],
      sectionUuid: [this.section.uuid],
      shuffleItems: [this.section.shuffleItems],
      itemLimit: [
        this.section.itemLimit,
        [Validators.min(0), Validators.max(this.items.length)],
      ],
      timeLimit: [this.exam.timeLimit],
      maxAllowedSubmissions: [timeslot.maxAllowedSubmissions],
    });

    this.canUpdate = this.exam.allowedActions.includes("update");
    if (!this.canUpdate) {
      setTimeout(() => {
        this.formGroup.disable();
      }, 100);
    }

    //Initiate subscriptions
    this.formGroup
      .get("shuffleItems")
      .valueChanges.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        takeUntil(this.unsubscribe$),
        switchMap((value) =>
          this.v1ExamsService.examsSectionsUpdate(this.section.uuid, {
            shuffleItems: value,
          })
        )
      )
      .subscribe();

    this.formGroup
      .get("itemLimit")
      .valueChanges.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        filter((value) => this.formGroup.get("itemLimit").valid),
        takeUntil(this.unsubscribe$),
        switchMap((value) => {
          if (value) {
            this.formGroup.get("shuffleItems").setValue(true);
          }

          return this.v1ExamsService.examsSectionsUpdate(this.section.uuid, {
            itemLimit: value,
          });
        })
      )
      .subscribe();

    this.formGroup
      .get("timeLimit")
      .valueChanges.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        takeUntil(this.unsubscribe$),
        switchMap((value) =>
          this.v1ExamsService.examsUpdate(this.exam.uuid, {
            timeLimit: value,
          })
        )
      )
      .subscribe();

    this.formGroup
      .get("maxAllowedSubmissions")
      .valueChanges.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        takeUntil(this.unsubscribe$),
        switchMap((value) =>
          this.v1ExamsService.examsTimeslotsUpdate(
            this.formGroup.value.timeslotUuid,
            {
              maxAllowedSubmissions: value,
            }
          )
        )
      )
      .subscribe();
  }

  onAddActivityClick(spliceIndex) {
    this.router.navigate(["select-item-type"], {
      queryParamsHandling: "merge",
      relativeTo: this.route,
      queryParams: {
        exam_section_uuid: this.section.uuid,
        splice_index: spliceIndex,
      },
    });
  }

  goBack() {
    this.location.back();
  }

  async fileChange(event) {
    this.loading = true;
    let file = event.target.files[0];
    event.srcElement.value = null;
    let result = await firstValueFrom(
      this.v1ExamsService.examsSectionsItemsFileupload(this.section.uuid, file)
    );
    this.formGroup = null;
    this.ngOnDestroy();
    this.load();
    this.loading = false;
  }
  async deleteActivitySpread(spliceIndex: number, activity: V1ExamItem) {
    let result = await this.modalService.confirm(
      `Are you sure you want to delete the question ${
        activity.text ? '"' + activity.text + '"' : ""
      }?`
    );

    if (result) {
      this.items.splice(spliceIndex, 1);

      await firstValueFrom(
        this.v1ExamsService.examsSectionsItemsDelete(activity.uuid)
      );

      this.saveItemOrder();
    }
  }

  editActivitySpread(activity: V1ExamItem) {
    this.router.navigate(["edit"], {
      relativeTo: this.route.parent,
      queryParams: {
        item_uuid: activity.uuid,
        // disable_show_results: true,
      },
      queryParamsHandling: "merge",
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.items, event.previousIndex, event.currentIndex);

    this.saveItemOrder();
  }

  saveItemOrder() {
    Promise.all(
      this.items.map((item, index) =>
        firstValueFrom(
          this.v1ExamsService.examsSectionsItemsUpdate(item.uuid, {
            orderId: index,
          })
        )
      )
    );
  }
}
