<app-back-title-layout
  layoutTitle="Create {{ formGroup.value | resourceType }}"
  [loading]="!formGroup"
  [backRouterLink]="['resources', 'select-new']"
  [titleSupportLink]="titleSupportLink"
  backQueryParamsHandling="merge"
>
  <form [formGroup]="formGroup" class="relative" (ngSubmit)="createResource()">
    <app-loading
      position="absolute inset-0 bg-white bg-opacity-75"
      [loading]="loading"
    ></app-loading>

    <app-resource-details [resource]="resource" [formGroup]="formGroup">
    </app-resource-details>

    <div class="flex flex-row-reverse">
      <button type="submit" class="btn btn-primary">Next</button>
    </div>
  </form>
</app-back-title-layout>
