<app-back-title-layout [loading]="!subsChoices || loading">
  <div class="flex py-2">
    <app-icon
      icon="person"
      class="text-gray-600 text-4xl mr-2 leading-none"
    ></app-icon>
    <div>
      <div class="leading-tight text-sm truncate font-semibold">
        {{ user?.firstName }}{{ user?.lastName }}
      </div>

      <div class="text-xs text-gray-700">{{ user?.email }}</div>
    </div>
  </div>

  <label class="block font-bold mt-5">Subscription</label>

  <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <ng-container *ngFor="let choice of subsChoices">
      <label class="flex gap-5 my-2">
        <app-radio
          class="text-primary"
          [value]="choice.subscripitionPlanUuid"
          formControlName="subscripitionPlanUuid"
        ></app-radio>
        <div class="text-gray-500">
          <b class="font-bold text-gray-900"> ₱{{ choice.price }} </b>
          / {{ choice.planUnitDuration }}

          <div class="leading-none" *ngIf="choice.freeTrialUnitDuration">
            {{ choice.freeTrialUnitDuration }} Free
          </div>
        </div>
      </label>
    </ng-container>

    <div class="flex gap-2 bg-warning-100 text-warning-600 p-2 rounded-md my-5">
      <app-icon class="text-xl" icon="warning"></app-icon>

      <div class="flex flex-col gap-4">
        <p>
          Renewal of subcription will not recurr at the end of its duration.
        </p>
        <p>
          When the subscription ends, resource(s) will be locked and user will
          be notified to pay to renew subcription.
        </p>
        <p>
          To renew, user may use another payment method or pay again manually
          when the subscription expires.
        </p>
      </div>
    </div>

    <div class="flex flex-row-reverse">
      <button class="btn btn-primary" [disabled]="formGroup.invalid">
        Save
      </button>
    </div>
  </form>
</app-back-title-layout>
