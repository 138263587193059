import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-alert-dialog",
  templateUrl: "./alert-dialog.component.html",
})
export class AlertDialogComponent implements OnInit {
  @Input()
  message: string;

  constructor() {}

  ngOnInit(): void {}
}
