<app-back-title-layout [loading]="!formGroup">
  <form [formGroup]="formGroup" (ngSubmit)="saveRole()" *ngIf="formGroup">
    <app-group-role-permissions-toggle-form
      [formGroup]="formGroup"
      [role]="role"
    >
    </app-group-role-permissions-toggle-form>

    <button [disabled]="true" class="btn btn-primary w-full">SAVE</button>

    <button
      [disabled]="!canDelete"
      type="button"
      (click)="deleteRole()"
      class="btn btn-danger w-full my-5"
    >
      DELETE
    </button>
  </form>
</app-back-title-layout>
