import { Component, Input, OnInit } from "@angular/core";
import { Resource } from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-resource-card",
  templateUrl: "./resource-card.component.html",
  styles: [],
})
export class ResourceCardComponent implements OnInit {
  @Input() resource: Resource;

  @Input() cardHeaderBackgroundColor: string;
  @Input() cardHeaderForegroundColor: string;

  @Input() isInfoHidden: boolean;

  @Input() isChecked: boolean;
  @Input() isErrored: boolean;
  @Input() isDownloaded: boolean;

  constructor() {}

  ngOnInit(): void {
    // Set default values if input is null
    this.cardHeaderBackgroundColor =
      this.cardHeaderBackgroundColor || "rgba(117,43,143,1)";
    this.cardHeaderForegroundColor = this.cardHeaderForegroundColor || "white";
  }
}
