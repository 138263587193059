import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormGroup,
  UntypedFormArray,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { first, Subscription, switchMap } from "rxjs";
import { fadeInOut } from "src/app/app-common-module/animations/fade";
import { ModalComponent } from "src/app/app-common-module/layouts/modal/modal.component";
import { MailerService } from "src/app/services/mailer.service";
import { ModalService } from "src/app/services/modal.service";

@Component({
  selector: "app-email-edit",
  templateUrl: "./email-edit.component.html",
  animations: [fadeInOut],
})
export class EmailEditComponent implements OnInit {
  @ViewChild("sendSchedule") sendSchedule: ModalComponent;
  @Input() hideBackTitleLayout: boolean;
  loading: boolean = false;
  firstFilter: string[] = [];
  _filters: Array<string[]> = [];
  filters: any[];
  filtersForm: FormGroup;
  inputFilters: any[] = [];
  filterTotal: number = 0;
  status: string = "scheduled";
  hideStatus: string = "";
  showButton: string = "hidden";
  inputHidden: string = "";
  previewHidden: string = "hidden";
  action: string = "edit";
  form: FormGroup;
  querySub: Subscription;
  mutationSub: Subscription;
  close: boolean = false;
  mailUuid: string = "";
  tempUuid: string = "";
  deleteIndex: number = 0;
  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private mailerService: MailerService,
    private cd: ChangeDetectorRef,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      subject: [null, Validators.required],
      date: [null, Validators.required],
      time: [null, Validators.required],
      content: [null, Validators.required],
    });
    this.querySub = this.route.queryParams
      .pipe(
        first(),
        switchMap((params) => {
          this.loading = true;
          let { mail_uuid, status, template_uuid } = params;
          this.status = status;
          this.mailUuid = mail_uuid;
          this.tempUuid = template_uuid;
          if (this.tempUuid && this.status === "draft") {
            this.action = "create";
            return this.mailerService.getEmailTemplate(this.tempUuid);
          }
          return this.mailerService.getEmailRequest(this.mailUuid);
        })
      )
      .subscribe((result) => {
        this.loading = false;
        let res = result.data.emailRequest;
        if (this.tempUuid && this.status === "draft") {
          res = result.data.emailTemplate;
        }
        this.filters = res.filters;
        let convertDate = new Date(res.sendOnDate);
        convertDate.setHours(convertDate.getHours() + 8);
        let dateString = convertDate.toISOString();
        let date = dateString.split("T")[0];
        let time = dateString.split("T")[1];
        let time2 = time.split(":")[0] + ":" + time.split(":")[1];
        let content = res.emailBody;
        this.form.get("subject").setValue(res.subject);
        this.form.get("date").setValue(date);
        this.form.get("time").setValue(time2);
        this.form.get("content").setValue(content);
        this.form.disable();
        if (this.status === "draft" && this.tempUuid) {
          this.form.enable();
          this.showButton = "";
        }
      });
  }
  ngOnDestroy(): void {
    this.querySub?.unsubscribe();
    this.mutationSub?.unsubscribe();
  }

  enableEdit() {
    this.hideStatus = "hidden";
    this.showButton = "";
    this.form.enable();
  }
  onSubmit() {
    this.sendSchedule.open();
  }
  preview() {
    this.inputHidden = "hidden";
    this.previewHidden = "";
  }
  code() {
    this.inputHidden = "";
    this.previewHidden = "hidden";
  }
  setFilters(filters: UntypedFormGroup) {
    this.filters = this.mailerService.parseFilter(filters);
    this.filtersForm = filters;
    this.inputFilters = [filters];
    this.firstFilter = [];
    this._filters = [];
    for (const [key, value] of Object.entries(
      (<UntypedFormGroup>filters.get("first")).controls
    )) {
      if (value.value && typeof value.value === "object" && value.value.name) {
        this.firstFilter.push(value.value.name);
      } else if (value.value) {
        this.firstFilter.push(value.value);
      }
    }
    (<UntypedFormArray>filters.get("filters")).controls.forEach(
      (filter, index) => {
        let innerArr = [];
        for (const [key, value] of Object.entries(filter.value)) {
          if (value && typeof value === "object" && value["name"]) {
            innerArr.push(value["name"]);
          } else if (value) {
            innerArr.push(value);
          }
        }
        this._filters.push(innerArr);
      }
    );
    this.cd.detectChanges();
  }
  setFilterTotal(event) {
    this.filterTotal = event;
  }
  deleteFilter(index) {
    this.deleteIndex = 0;
    this.cd.detectChanges();
    this.deleteIndex = index;
  }

  async openModal(template, type) {
    let baseClass = ["flex", "justify-center", "items-center", "shadow-lg"];
    let smallContainerClass = ["sm:w-1/4", "w-3/5"];
    let largeContainerClass = ["sm:w-1/2", "w-3/5"];
    let filterResultsContainerClass = ["w-3/4", "h-1/2"];
    let modalClass;
    if (type === "cancelSchedule" || type === "testEmail") {
      modalClass = [...baseClass, ...smallContainerClass];
    } else if (type === "filterResults") {
      modalClass = [...baseClass, ...filterResultsContainerClass];
    } else {
      modalClass = [...baseClass, ...largeContainerClass];
    }
    let results = await this.modalService.openModal(template, {
      panelClass: modalClass,
    });
    return results;
  }
}
