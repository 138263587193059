<div
  class="input-group my-10"
  *ngIf="resource"
  [formGroup]="formGroup.get('content')"
>
  <label for="Markdown Text" class="form-label"></label>

  <app-markdown-viewer-and-editor
    [autoResize]="false"
    editorClass="h-40 overflow-y-scroll"
    viewerClass="h-40 overflow-y-scroll border rounded p-2"
    formControlName="text"
  ></app-markdown-viewer-and-editor>
</div>
