<div class="grid grid-cols-1">
  <div class="flex">
    <div class="{{ hideCheckbox }}">
      <app-checkbox
        class="text-xl"
        uncheckClass="text-gray-500"
        checkClass="text-primary-dark"
        checkIcon="indeterminate_check_box"
        [name]="'header'"
        [(ngModel)]="checked"
        (change)="toggleHeader($event)"
      ></app-checkbox>
    </div>
    <ng-container *ngIf="show && totalSelected.length === 0; else batch">
      <div class="hidden sm:flex pt-1">
        <div
          *ngFor="let header of headers; let i = index"
          class="flex sm:visible invisible"
          [ngClass]="getClass(header.width)"
        >
          <button
            class="flex w-full {{ style[i] }}"
            (click)="sort(header.value, i)"
            [disabled]="!enableSort"
          >
            <div class="text-sm">
              <app-icon icon="arrow_upward"></app-icon>
              {{ header.name }}
            </div>
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<div class="sm:flex">
  <div class="sm:flex-1 border-t-2 border-b-2">
    <ng-content></ng-content>
  </div>
</div>

<ng-template #batch>
  <!-- ngIf for userbatchaction -->
  <div class="flex w-full">
    <ng-container>
      <div class="px-2">
        <app-dropdown-layout position="left">
          <app-icon dropdownToggle icon="more_vert"> </app-icon>

          <div dropdownContent>
            <ng-container *ngFor="let _batchAction of batchActions">
              <button
                class="w-full py-1 px-2 text-left hover:bg-gray-200"
                (click)="batchAction(_batchAction.action)"
              >
                {{ _batchAction.name }}
              </button>
            </ng-container>
          </div>
        </app-dropdown-layout>
      </div>
      <div *ngIf="totalSelected" class="text-sm text-primary-600 pt-1">
        {{ totalSelected.length }} {{ category }}(s) selected
      </div>
    </ng-container>
  </div>
</ng-template>
