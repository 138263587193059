import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import {
  firstValueFrom,
  from,
  tap,
  takeUntil,
  toArray,
  filter,
  switchMap,
  Observable,
  Subject,
  zip,
  map,
  concatMap,
  merge,
  of,
  defer,
} from "rxjs";
import { debounceTime } from "rxjs/operators";
import { GroupSelectDialogComponent } from "src/app/app-common-module/components/group-select-dialog/group-select-dialog.component";
import {
  ActivityLogService,
  VERBS,
} from "src/app/services/activity-log.service";
import { DashboardService } from "src/app/services/dashboard.service";
import { ModalService } from "src/app/services/modal.service";
import { NotificationService } from "src/app/services/notification.service";
import {
  Group,
  PassService,
  UserManagementService,
} from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-user-create",
  templateUrl: "./user-create.component.html",
})
export class UserCreateComponent implements OnInit {
  title = "Create new user";
  formGroup: UntypedFormGroup;
  errors: string[];
  visibilityIcon: string = "visibility_off";
  hide: boolean = true;
  isEmailExists: boolean = null;
  isEmailAdded: boolean = null;

  loading: boolean;

  groupListObs: Observable<Group[]>;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userManagementService: UserManagementService,
    private dashboardService: DashboardService,
    private passService: PassService,
    private activityLogService: ActivityLogService,
    private location: Location,
    private notificationService: NotificationService,
    private modalService: ModalService
  ) {}

  private unsubscribe$: Subject<void> = new Subject<void>();
  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required, Validators.minLength(6)]],
      changePasswordOnLogin: [false],
      changeUserDetailsOnLogin: [true],
      groupIds: [[]],
    });

    // Should migrate to email async validation
    this.formGroup
      .get("email")
      .valueChanges.pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(200),
        tap(() => {
          this.isEmailExists = null;
          this.isEmailAdded = null;
        })
      )
      .subscribe();

    // Uncomment if alllowed na mag create without password
    // this.formGroup
    //   .get("changePasswordOnLogin")
    //   .valueChanges.pipe(
    //     takeUntil(this.unsubscribe$),
    //     tap((value) => {
    //       if (value) {
    //         this.formGroup.get("password").disable();
    //       } else {
    //         this.formGroup.get("password").enable();
    //       }
    //     })
    //   )
    //   .subscribe();

    this.generateGroupListObs();

    zip([
      this.dashboardService.getCast(),
      this.passService.listGroups("Learners"),
    ])
      .pipe(
        takeUntil(this.unsubscribe$),
        map(([cast, groupList]) => {
          let groupIds = [];

          if (cast.data.attributes.defaultGroupId) {
            groupIds.push(cast.data.attributes.defaultGroupId);
          }

          if (groupList.groups.length > 0) {
            groupIds.push(groupList.groups[0].id);
          }

          return groupIds;
        }),
        tap((groupIds: string[]) => {
          this.formGroup.get("groupIds").setValue(groupIds);
        })
      )
      .subscribe();
  }

  toggleVisibility() {
    this.hide = !this.hide;
    this.visibilityIcon = this.hide ? "visibility_off" : "visibility";
  }

  async emailCheck() {
    this.isEmailExists = null;
    this.isEmailAdded = null;
    let email = this.formGroup.get("email").value;

    let isEmailExists = await this.emailExists(email);

    //If email exists check is member of default group
    let isEmailAdded = false;
    if (isEmailExists) {
      let result = await firstValueFrom(this.passService.listUsers(email, 5));

      let user = result.users.find((u) => u.email === email);

      if (user) {
        isEmailAdded = true;
      }
    }

    this.isEmailExists = isEmailExists;
    this.isEmailAdded = isEmailAdded;
  }

  async emailExists(email) {
    return firstValueFrom(
      this.userManagementService.adminCheckAccountExistence({ email })
    )
      .then((r) => true)
      .catch((err) => false);
  }

  async createUser(addAnother: boolean = false) {
    let formGroupValue = this.formGroup.value;

    this.loading = true;

    try {
      let cast = await firstValueFrom(this.dashboardService.getCast());

      if (cast.data.attributes.defaultGroupId) {
        let user = await firstValueFrom(
          this.passService.createUser({
            user: {
              ...formGroupValue,
              passwordExpiresAt: formGroupValue.changePasswordOnLogin
                ? new Date()
                : null,
              groupIds: [
                cast.data.attributes.defaultGroupId,
                ...formGroupValue.groupIds,
              ],
              password: formGroupValue.password,

              // Uncomment if blank passwor dis now allowed
              // Set password only if "Provide Password" is selected in UI
              // password: formGroupValue.changePasswordOnLogin
              //   ? null
              //   : formGroupValue.password,
            },
          })
        );

        this.notificationService.addSuccess({
          title: "User Added!",
          message: "User added successfully.",
        });

        this.activityLogService.didUserInParent(VERBS.created, user);

        if (addAnother) {
          this.reset();
          this.loading = false;
        } else {
          this.location.back();
        }
      }
    } catch (err) {
      this.loading = false;
      throw err;
    }
  }

  async addUser() {
    //DEPRECATED. This can be a security issue
    await firstValueFrom(
      this.userManagementService.adminAddUserEmail({
        email: this.formGroup.get("email").value,
      })
    );

    this.notificationService.addSuccess({
      title: "User Added!",
      message: "User added successfully.",
    });

    this.location.back();
  }

  async selectGroup() {
    let result = await this.modalService.openModal(
      GroupSelectDialogComponent,
      {}
    );

    if (result) {
      let distinctGroupIds: string[] = [
        ...new Set([...this.formGroup.get("groupIds").value, result]),
      ];

      this.formGroup.get("groupIds").setValue(distinctGroupIds);
    }
  }

  removeGroup(index: number) {
    let newGroupIds = [...this.formGroup.get("groupIds").value];

    newGroupIds.splice(index, 1);

    this.formGroup.get("groupIds").setValue(newGroupIds);
  }

  reset() {
    this.formGroup.reset({
      groupIds: [...this.formGroup.get("groupIds").value],
    });

    this.isEmailAdded = null;
    this.isEmailExists = null;
  }

  generateGroupListObs() {
    this.groupListObs = defer(() =>
      merge(
        this.formGroup.get("groupIds").valueChanges,
        of(this.formGroup.get("groupIds").value)
      ).pipe(
        takeUntil(this.unsubscribe$),
        filter((r) => r),
        switchMap((groupIds: string[]) => {
          return from(groupIds).pipe(
            concatMap((id) => this.passService.getGroup(id, ["users_count"])),
            toArray()
          );
        })
      )
    );
  }
}
