<app-back-title-layout
  layoutTitle="Batch Access Period"
  [loading]="!schedule || loading"
>
  <ng-container *ngIf="schedule">
    <form [formGroup]="batchAccessForm" (submit)="updateBatchAccess()">
      <div class="flex flex-col mb-10">
        <label class="flex mb-5">
          <!-- <input
            formControlName="batchAccess"
            type="radio"
            value="forever"
          /> -->

          <app-radio
            name="batchAccess"
            formControlName="batchAccess"
            value="forever"
            class="text-primary"
          >
          </app-radio>

          <div class="ml-2 leading-tight">
            Forever

            <div class="text-xs text-gray-600">
              Users will immediately have unlimited access to this course
            </div>
          </div>
        </label>

        <label class="flex">
          <app-radio
            name="batchAccess"
            formControlName="batchAccess"
            value="startend"
            class="text-primary"
          >
          </app-radio>
          <!-- <input formControlName="batchAccess" type="radio" value="startend" /> -->
          <div class="ml-2">
            <div>Set start and end date</div>
            <div class="text-xs text-gray-600">
              Set a specific time period batches can access your course
            </div>

            <div
              class="border-gray-300 w-full p-3"
              [ngClass]="{
                'pointer-events-none':
                  batchAccessForm.controls.batchAccess.value !== 'startend',
                'opacity-25':
                  batchAccessForm.controls.batchAccess.value !== 'startend'
              }"
            >
              <div class="mb-3">
                <div>Start Date and Time</div>

                <app-date-time formControlName="startDateTime"></app-date-time>
              </div>

              <div class="mb-3">
                <div>End Date and Time</div>

                <app-date-time formControlName="endDateTime"></app-date-time>
              </div>
            </div>
          </div>
        </label>
      </div>

      <button
        type="submit"
        [disabled]="!batchAccessForm.valid"
        class="btn btn-primary w-full"
      >
        Done
      </button>
    </form>
  </ng-container>
</app-back-title-layout>
