<div class="relative min-h-40">
  <ul *ngIf="items && items.length">
    <ng-container *ngFor="let item of items; let index = index">
      <li class=" border-b last:border-b-0 py-2 {{ liClass }}">
        <ng-container
          *ngTemplateOutlet="
            itemTemplate ? itemTemplate : default;
            context: { item: item, index: index }
          "
        ></ng-container>
      </li>
    </ng-container>
  </ul>

  <div
    *ngIf="loading || externalLoading"
    [@fadeInOut]
    class="absolute inset-0 bg-white bg-opacity-50"
  >
    <app-loading [loading]="true" position="relative"></app-loading>
  </div>

  <ng-container *ngIf="items && items.length == 0">
    <div
      class="w-full py-5 rounded flex flex-col items-center justify-center bg-purple-200 text-primary-light"
    >
      <span class="font-bold">{{ emptyListMessage }}</span>
    </div>
  </ng-container>

  <div
    *ngIf="errorMessage"
    [@fadeInOut]
    class="w-full p-3 text-sm my-5 rounded bg-error-light text-error-dark"
  >
    <span class="font-bold text-red-600 line-clamp-2">{{ errorMessage }}</span>

    <div>
      <span>Oops, something went wrong please </span>

      <button (click)="refreshList()" class="underline inline">
        refresh this page
      </button>
    </div>

    <div class="border-t border-error-dark mt-5">
      <span>Still not working? You may contact us via</span>

      <span class="font-bold">support@buri.io</span>
    </div>
  </div>
</div>

<ng-template #default let-item="item">
  <span class="truncate font-semibold">
    {{ item[key] }}
  </span>
</ng-template>
