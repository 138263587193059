import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Group, PassService } from "src/app/sinigangnababoywithgabi";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Component({
  selector: "app-user-groups",
  templateUrl: "./user-groups.component.html",
})
export class UserGroupsComponent implements OnInit {
  groupListObservable: Observable<Group[]>;

  constructor(
    private route: ActivatedRoute,
    private passService: PassService
  ) {}

  async ngOnInit() {
    let { user_uuid: userUuid } = this.route.snapshot.queryParams;

    this.groupListObservable = this.passService
      .getUser(userUuid, ["groups"])
      .pipe(map((result) => result.groups));
  }
}
