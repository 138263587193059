<div class="my-5">
  <app-search-bar
    [(ngModel)]="searchString"
    (ngModelChange)="filter($event)"
  ></app-search-bar>
</div>

<div class="my-5">
  <!-- <app-loading [loading]="!filteredGroups" position="relative"></app-loading> -->

  <app-item-list [itemTemplate]="itemTemplate" [items]="filteredGroups">
  </app-item-list>
</div>

<!-- <ng-container *ngFor="let group of filteredGroups">
  <details class="bg-gray-200" open>
    <summary class="cursor-pointer p-3 my-1 text-gray-700">
      <app-checkbox
        (change)="toggleGroupSelect(group, $event.target.checked)"
        class="text-primary mr-2"
      >
      </app-checkbox>

      <app-icon icon="group"></app-icon>
      <span>
        {{ group.name }}
      </span>

      <span>
        ({{ countSelected(group) }} out of {{ group.users.length }} selected)
      </span>
    </summary>

    <ng-container *ngFor="let user of group.users">
      <ul class="pl-5">
        <li class="border-b border-t">
          <label class="px-2 py-1 flex items-center">
            <app-checkbox
              [(ngModel)]="selectedUserUuidMap[user.uuid]"
              (change)="onSingleValueChange(user)"
              class="text-primary mr-2"
            >
            </app-checkbox>

            <div class="px-2">
              <div class="truncate font-semibold">
                {{ user.firstName }} {{ user.lastName }}
              </div>

              <div class="text-sm text-gray-700">{{ user.email }}</div>
            </div>
          </label>
        </li>
      </ul>
    </ng-container>
  </details>
</ng-container> -->

<ng-template let-group="item" #itemTemplate>
  <details (toggle)="onOpen(group)">
    <summary class="cursor-pointer px-1 flex items-center">
      <span>
        <app-checkbox
          (change)="toggleGroupSelect(group, $event.target.checked)"
          class="text-primary text-lg"
        >
        </app-checkbox>
      </span>

      <span class="text-4xl text-primary mx-3">
        <app-icon icon="group"></app-icon>
      </span>

      <div>
        <div class="text-primary-dark font-bold leading-none">
          {{ group.name }}
        </div>

        <div>
          (
          <span class="text-primary font-semibold">
            {{ countSelected(group) || 0 }}
          </span>
          of
          <span> {{ group.usersCount }} user/s selected </span>
          )
        </div>
      </div>
    </summary>

    <ng-container *ngFor="let user of group.users">
      <ul class="pl-5">
        <li class="border-b border-t">
          <label class="py-1 flex items-center">
            <app-checkbox
              [(ngModel)]="selectedUserUuidMap[user.uuid]"
              (change)="onSingleValueChange(user)"
              class="text-primary"
            >
            </app-checkbox>

            <span class="text-4xl text-primary mx-3">
              <app-icon icon="person"></app-icon>
            </span>

            <div>
              <div class="truncate font-semibold leading-tight">
                {{ user.firstName }} {{ user.lastName }}
              </div>

              <div class="text-sm text-gray-700">{{ user.email }}</div>
            </div>
          </label>
        </li>
      </ul>
    </ng-container>
  </details>
</ng-template>
