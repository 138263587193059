<app-nav-drawer-layout>
  <header class="bg-white">
    <!-- <nav class="flex items-center justify-between p-3">
      <a [routerLink]="['/']" class="flex items-center min-w-0 text-buri-blue">
        <ng-container *ngIf="dashboardInfo?.dashboardLogoUrl">
          <img
            [src]="dashboardInfo?.dashboardLogoUrl"
            alt=""
            class="h-10 mr-3"
          />
        </ng-container>

        <ng-container *ngIf="!dashboardInfo?.dashboardLogoUrl">
          <img src="assets/default-logo.png" alt="" class="h-10 mr-3" />
        </ng-container>

        <div class="min-w-0">
          <div class="font-bold truncate">
            {{ dashboardInfo?.dashboardTitle }}
          </div>
          <div class="text-sm">Cast LMS Admin Dashboard</div>
        </div>
      </a>

      <a
        [routerLink]="['auth/logout']"
        class="px-2 py-1 text-xs uppercase border rounded border-buri-blue whitespace-nowrap text-buri-blue hover:bg-buri-blue hover:text-white"
        >Log out</a
      >
    </nav> -->

    <section class="relative w-full bg-white">
      <img src="assets/castbanner.jpg" class="object-cover w-full h-40" />

      <h1
        *ngIf="userinfo"
        [@fadeInOut]
        class="absolute inset-0 flex flex-col items-center justify-center text-white"
      >
        <span class="text-2xl font-bold"> Welcome back, </span>

        <span class="text-2xl">
          {{ userinfo?.firstName || userinfo?.givenName }}!
        </span>
      </h1>
    </section>
  </header>

  <app-margin-layout>
    <div class="relative flex flex-wrap justify-center p-3 pb-20">
      <app-loading [loading]="!cards" wrapperClass="absolute pt-10">
      </app-loading>

      <div *ngFor="let card of cards" [@fadeInOut]>
        <ng-container *ngIf="!card.hide">
          <a
            *ngIf="card.href"
            [href]="card.href"
            [target]="card.target"
            class="block w-48 h-40 m-2 font-semibold bg-transparent rounded shadow-md"
          >
            <div
              class="flex items-center justify-center w-full h-24 p-2 rounded-t"
              [style.backgroundColor]="card.backgroundColor"
            >
              <app-icon
                [style.color]="card.iconColor"
                class="text-6xl text-white"
                [icon]="card.icon"
              ></app-icon>
            </div>

            <div
              class="h-16 pt-1 pl-2 text-left text-black"
              [style.backgroundColor]="card.textBackgroundColor"
            >
              {{ card.title }} <br />
              <span class="block text-xs font-light leading-tight">{{
                card.description
              }}</span>
            </div>
          </a>

          <a
            *ngIf="!card.href"
            [routerLink]="card.routerLink"
            class="block w-48 h-40 m-2 font-semibold bg-transparent rounded shadow-md"
          >
            <div
              class="flex items-center justify-center w-full h-24 p-2 rounded-t"
              [style.backgroundColor]="card.backgroundColor"
            >
              <app-icon
                [style.color]="card.iconColor"
                class="text-6xl text-white"
                [icon]="card.icon"
              ></app-icon>
            </div>

            <div
              class="h-16 pt-1 pl-2 text-left text-black"
              [style.backgroundColor]="card.textBackgroundColor"
            >
              {{ card.title }} <br />
              <span class="block text-xs font-light leading-tight">{{
                card.description
              }}</span>
            </div>
          </a>
        </ng-container>
      </div>
    </div>
  </app-margin-layout>
  <!-- <button (click)="throwTestError()">Test Sentry Error</button> -->
</app-nav-drawer-layout>
