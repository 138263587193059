<app-back-title-layout>
  <div class="p-6 flex flex-col border rounded-lg bg-white">
    <div class="flex w-full justify-between items-start">
      <!-- user details here  -->
      <div class="flex flex-col">
        <div class="font-bold text-xl">
          {{ userDetails.firstName + " " + userDetails.lastName }}
        </div>
        <div class="text-sm text-gray-500">{{ userDetails.email }}</div>
        <div class="flex">
          <!-- loop of field entries for certain user here  -->
          <ng-container *ngIf="false">
            <div class="text-sm text-gray-500">Region 1 (Ilocos Region)</div>
          </ng-container>
        </div>
      </div>
      <!-- View Activity Log button here  -->
      <button class="text-primary-600 uppercase text-sm">
        <app-icon icon="list_alt"></app-icon> view activity log
      </button>
    </div>
    <hr class="my-2" />
    <div>
      <div class="flex mb-2">
        <div class="uppercase font-bold text-gray-500 text-xs">Groups</div>
      </div>
      <div class="flex flex-wrap">
        <!-- list of groups here  -->
        <ng-container *ngIf="userDetails.groups.length > 0">
          <ng-container *ngFor="let group of userDetails.groups; let i = index">
            <div
              class="bg-gray-100 rounded-full py-1 px-2 flex text-gray-500 items-center justify-center mr-2"
            >
              <app-icon icon="people"></app-icon>
              <div class="ml-1" [ngClass]="{ 'font-bold': i === 0 }">
                {{ group.name }}
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="my-6">
    <nav class="flex w-full mb-4">
      <!-- First parent tab here  -->
      <a
        class="text-xs border-b-4 inline-block py-2 border-gray-300 text-gray-300 px-4 font-bold whitespace-nowrap"
        [routerLink]="['/idis', 'users', 'user-profile']"
        [replaceUrl]="true"
        [queryParamsHandling]="'merge'"
        [queryParams]="{ parent_tab: 'user_information' }"
        routerLinkActive="text-primary-900 border-primary-900 "
      >
        User Information
      </a>
      <!-- Second parent tab here  -->
      <a
        class="border-b-4 inline-block py-2 border-gray-300 text-gray-300 px-4 font-bold text-xs whitespace-nowrap"
        [routerLink]="['/idis', 'users', 'user-profile']"
        [replaceUrl]="true"
        [queryParamsHandling]="'merge'"
        [queryParams]="{ parent_tab: 'assigned_trainings' }"
        routerLinkActive="text-primary-900 border-primary-900 "
      >
        Assigned Trainings
      </a>
      <!-- Third parent tab here  -->
      <a
        class="border-b-4 inline-block py-2 border-gray-300 text-gray-300 px-4 font-bold text-xs whitespace-nowrap"
        [routerLink]="['/idis', 'users', 'user-profile']"
        [replaceUrl]="true"
        [queryParamsHandling]="'merge'"
        [queryParams]="{ parent_tab: 'training_needs' }"
        routerLinkActive="text-primary-900 border-primary-900 "
      >
        Training Needs
      </a>
      <div class="border-b-4 border-gray-300 flex-1"></div>
    </nav>
  </div>

  <div class="flex w-full justify-between">
    <ng-container *ngIf="enabledSubTabs">
      <div class="mr-4">
        <!-- list of sub tabs here  -->

        <ng-container *ngFor="let subTab of sampleSubTabs; let i = index">
          <div
            class="flex justify-between w-full self-stretch p-2 items-start rounded mb-2"
            [ngClass]="{ 'bg-primary-200': subTab.selected }"
          >
            <button
              class="text-primary-900 text-xs flex items-start"
              (click)="selectSubTab(i)"
            >
              {{ subTab.displayName }}
            </button>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <!-- form here  -->
    <ng-container *ngIf="currentForm">
      <div class="w-full">
        <div class="flex flex-1 justify-between mb-2">
          <div class="font-bold text-lg text-gray-900">
            {{ currentForm.displayName }}
          </div>
        </div>
        <ng-container
          *ngIf="currentForm.displayName === 'Account Details'; else formView"
        >
          <!-- Show Account Details Component Here  -->
          <div class="border rounded-lg bg-white shadow-sm p-6">
            <app-user-edit></app-user-edit>
          </div>
        </ng-container>
        <ng-template #formView>
          <ng-container
            *ngIf="
              currentForm?.fieldGroups && currentForm?.fieldGroups.length > 0
            "
          >
            <div class="border rounded-lg bg-white shadow-sm p-6">
              <ng-container
                *ngFor="
                  let fieldGroup of currentForm.fieldGroups;
                  let i = index
                "
              >
                <div class="font-bold text-base text-gray-700 mb-8">
                  {{ fieldGroup.name }}
                </div>

                <ng-container
                  *ngFor="let field of fieldGroup.fields; let j = index"
                >
                  <label class="text-base text-gray-500 flex flex-col">
                    {{ field.name }}

                    <!-- if fieldtype is dropdown, show select input  -->
                    <ng-container [ngSwitch]="field.fieldType">
                      <ng-container *ngSwitchCase="'dropdown'">
                        <select class="form-control mb-8">
                          <option *ngFor="let choice of field.fieldChoices">
                            {{ choice.name }}
                          </option>
                        </select>
                      </ng-container>
                      <ng-container *ngSwitchCase="'short_text'">
                        <input type="text" class="form-control mb-8" />
                      </ng-container>
                      <ng-container *ngSwitchCase="'paragraph'">
                        <textarea class="form-control mb-8"></textarea>
                      </ng-container>
                      <ng-container *ngSwitchCase="'file_upload'">
                        <div>
                          <button
                            class="uppercase rounded text-sm p-2 border m-2"
                          >
                            <app-icon icon="upload"></app-icon> upload
                          </button>
                        </div>
                      </ng-container>
                      <ng-container *ngSwitchCase="'email'">
                        <input
                          [type]="field.fieldType"
                          class="form-control mb-8"
                        />
                      </ng-container>
                      <ng-container *ngSwitchCase="'date'">
                        <input
                          [type]="field.fieldType"
                          class="form-control mb-8"
                        />
                      </ng-container>
                      <ng-container *ngSwitchCase="'time'">
                        <input
                          [type]="field.fieldType"
                          class="form-control mb-8"
                        />
                      </ng-container>
                      <ng-container *ngSwitchCase="'checkbox'">
                        <div class="mb-8 flex flex-col">
                          <ng-container
                            *ngFor="let choice of field.fieldChoices"
                          >
                            <label class="text-base text-gray-500">
                              <input [type]="field.fieldType" />
                              {{ choice.name }}
                            </label>
                          </ng-container>
                        </div>
                      </ng-container>
                      <ng-container *ngSwitchCase="'radio'">
                        <div class="mb-8 flex flex-col">
                          <ng-container
                            *ngFor="let choice of field.fieldChoices"
                          >
                            <label class="text-base text-gray-500">
                              <input
                                [type]="field.fieldType"
                                [name]="field.name"
                              />
                              {{ choice.name }}
                            </label>
                          </ng-container>
                        </div>
                      </ng-container>
                      <ng-container *ngSwitchCase="'number'">
                        <input
                          [type]="field.fieldType"
                          class="form-control mb-8"
                        />
                      </ng-container>
                    </ng-container>
                  </label>
                </ng-container>
              </ng-container>
            </div>
          </ng-container>
        </ng-template>
      </div>
    </ng-container>
  </div>
</app-back-title-layout>
