<div class="card w-full border-2 rounded box-sizing">
  <div class="p-0 w-full h-48 bg-gray-200">
    <div class="flex justify-center w-full h-full">
      <div
        class="leaf w-1/2 h-full relative focus:border hover:border-2 border-primary border-dashed"
      >
        <img
          *ngIf="spread.leftUri"
          class="w-full h-full object-contain object-right"
          [src]="spread.leftUri"
        />

        <div
          *ngIf="!spread.leftUri"
          class="h-full w-full flex flex-col items-center justify-center bg-red-200 text-red-600"
        >
          <app-icon class="text-4xl" icon="error"></app-icon>

          <span class="italic">Missing Page</span>
        </div>

        <button
          (click)="leftUploader.click()"
          type="button"
          class="hidden absolute top-0 right-0 rounded bg-gray-300 text-primary w-6 h-6 m-2"
        >
          <app-icon icon="edit"></app-icon>
        </button>

        <app-upload-loading-wrapper
          #leftUploader
          [file]="leftFile"
          accept="image/*"
          class="hidden"
          text="Change image"
          (upload)="onUpload($event, 'left')"
        >
        </app-upload-loading-wrapper>
      </div>

      <div
        class="leaf w-1/2 h-full relative focus:border hover:border-2 border-primary border-dashed"
      >
        <img
          *ngIf="spread.rightUri"
          class="w-full h-full object-contain object-left"
          [src]="spread.rightUri"
        />

        <div
          *ngIf="!spread.rightUri"
          class="h-full w-full flex flex-col items-center justify-center bg-red-200 text-red-600"
        >
          <app-icon class="text-4xl" icon="error"></app-icon>

          <span class="italic">Missing Page</span>
        </div>

        <button
          (click)="rightUploder.click()"
          type="button"
          class="hidden absolute top-0 right-0 rounded bg-gray-300 text-primary w-6 h-6 m-2"
        >
          <app-icon icon="edit"></app-icon>
        </button>
        <app-upload-loading-wrapper
          #rightUploder
          [file]="rightFile"
          accept="image/*"
          text="Change image"
          class="hidden"
          (upload)="onUpload($event, 'right')"
        >
        </app-upload-loading-wrapper>
      </div>
    </div>
  </div>

  <div class="flex text-sm border-t-2">
    <div class="w-1/2 p-0 text-center border-r">
      {{ leftPageNumber }}
    </div>
    <div class="w-1/2 p-0 text-center border-l">
      {{ rightPageNumber }}
    </div>
  </div>
</div>
