import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { firstValueFrom, Observable, Subject } from "rxjs";
import { switchMap, takeUntil, tap } from "rxjs/operators";
import { ModalService } from "src/app/services/modal.service";
import { CoursesService, PassService } from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-course-batch-enrollments-self-enroll",
  templateUrl: "./course-batch-enrollments-self-enroll.component.html",
})
export class CourseBatchEnrollmentsSelfEnrollComponent implements OnInit {
  selfEnrollableFormControl: UntypedFormControl;

  rolePermissionFormGroup: UntypedFormGroup;

  groupInfos: any[];

  constructor(
    private route: ActivatedRoute,
    private coursesService: CoursesService,
    private passService: PassService,
    private modalService: ModalService
  ) {}

  private unsubscribe$: Subject<void> = new Subject<void>();
  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  async ngOnInit() {
    this.setUpSelfEnrollableCheckBox();
    this.setUpGroupList();
  }

  async setUpSelfEnrollableCheckBox() {
    let { schedule_uuid: scheduleUuid } = this.route.snapshot.queryParams;

    let schedule = await firstValueFrom(
      this.coursesService.scheduleRead(scheduleUuid)
    );

    if (schedule) {
      this.selfEnrollableFormControl = new UntypedFormControl(schedule.selfEnrollable);

      this.selfEnrollableFormControl.valueChanges
        .pipe(
          takeUntil(this.unsubscribe$),

          // tap((result) => {
          //   if (result || this.rolePermissionFormGroup.value.isActive) {
          //     this.rolePermissionFormGroup.get("isActive").setValue(result);
          //   }
          // }),
          switchMap((result) => {
            return this.coursesService.scheduleUpdate(scheduleUuid, {
              selfEnrollable: result,
            });
          })
        )
        .subscribe();
    }
  }

  async setUpGroupList() {
    let { schedule_uuid: scheduleUuid, course_uuid: courseUuid } =
      this.route.snapshot.queryParams;

    let result = await firstValueFrom(
      this.passService.listRoles(
        `self_enroll:enrollments:schedule_uuid=${scheduleUuid}`,
        null,
        null,
        ["groups"]
      )
    );

    this.groupInfos = result.roles
      .filter((role) => role.groups.length)
      .map((role) => ({
        roleUuid: role.id,
        groupUuid: role.groups[0].id,
        groupName: role.groups[0].name,
        userCount: role.groups[0].userIds?.length,
      }));
  }

  async removeGroup(details, index) {
    let result = await this.modalService.confirm(
      "Are you sure you want to remove this group?"
    );

    if (result) {
      this.groupInfos.splice(index, 1);

      await this.passService.deleteRole(details.roleUuid).toPromise();
    }
  }
}
