import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { firstValueFrom } from "rxjs";
import { Group, PassService } from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-course-batch-enrollments-self-enroll-add-group",
  templateUrl:
    "./course-batch-enrollments-self-enroll-add-group.component.html",
  styles: [],
})
export class CourseBatchEnrollmentsSelfEnrollAddGroupComponent
  implements OnInit
{
  groups: Group[];

  loading: boolean;

  constructor(
    private passService: PassService,
    private route: ActivatedRoute,
    private location: Location
  ) {}

  async ngOnInit() {
    let { schedule_uuid: scheduleUuid } = this.route.snapshot.queryParams;

    let result = await firstValueFrom(
      this.passService.listGroups(null, null, null, ["roles"])
    );

    this.groups = result.groups.filter(
      (group) =>
        !group.roles.some(
          (role) =>
            role.name ===
            `self_enroll:enrollments:schedule_uuid=${scheduleUuid}`
        )
    );
  }

  async selectGroup(group: Group) {
    let { schedule_uuid: scheduleUuid, course_uuid: courseUuid } =
      this.route.snapshot.queryParams;

    this.loading = true;

    let permissionsAttributes = [
      {
        name: `read:courses:${courseUuid}`,
        description: "Users can read the course",
      },
      {
        name: `self_enroll:enrollments:schedule_uuid=${scheduleUuid}`,
        description: "Users can self enroll",
      },
    ];

    await firstValueFrom(
      this.passService.createRole({
        role: {
          name: `self_enroll:enrollments:schedule_uuid=${scheduleUuid}`,
          groupIds: [group.id],
          permissionsAttributes,
          applicationId: localStorage.getItem("application_id"),
        },
      })
    ).catch((err) => {
      this.loading = false;

      throw err;
    });

    this.location.back();
  }
}
