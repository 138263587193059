<app-back-title-layout [loading]="!group">
  <ng-container *ngIf="formGroup">
    <form [formGroup]="formGroup" (ngSubmit)="updateGroupRole()">
      <ng-container *ngFor="let role of roles">
        <label class="flex my-5">
          <app-radio
            [value]="role.id"
            class="text-primary"
            formControlName="selectedRoleId"
          ></app-radio>

          <div class="mx-2">
            <div class="form-label text-primary">{{ role.name }}</div>

            <div class="text-sm text-gray-700">{{ role.description }}</div>

            <a
              [routerLink]="['permissions-toggle']"
              [queryParams]="{ role_uuid: role.id }"
              queryParamsHandling="merge"
              class="text-primary"
              >view permissions</a
            >
          </div>
        </label>
      </ng-container>

      <a
        class="w-full py-1 text-sm uppercase btn btn-primary-light"
        href="/users-and-groups/groups/profile/edit/new-role"
        queryParamsHandling="merge"
      >
        <app-icon icon="add"></app-icon>

        <span>add new role</span>
      </a>

      <button class="w-full my-5 btn btn-primary" type="submit">SAVE</button>
    </form>
  </ng-container>

  <!-- <details>
    <summary>Legacy</summary>
    <ng-container *ngIf="group">
      <button class="btn btn-primary" (click)="addRole()">Add Role</button>

      <ng-container *ngFor="let role of group.roles">
        <div class="my-3">
          <div class="flex justify-between">
            <div>
              <div class="font-bold">
                {{ role.name }}
              </div>

              <div class="text-sm text-gray-600">
                Shared with {{ role.groupIds.length }} group/s
              </div>
            </div>

            <button class="text-red-600" (click)="deleteRole(role)">
              <app-icon icon="delete"> </app-icon>
            </button>
          </div>

          <div class="ml-3 text-sm">
            <ng-container *ngFor="let permission of role.permissions">
              <div class="flex justify-between border-b-2">
                <span>
                  {{ permission.name }}
                </span>

                <button
                  class="text-red-600"
                  (click)="deletePermission(permission)"
                >
                  <app-icon icon="delete"> </app-icon>
                </button>
              </div>
            </ng-container>

            <button class="py-1 btn btn-primary" (click)="addPermission(role)">
              Add permission
            </button>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </details> -->
</app-back-title-layout>
