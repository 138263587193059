import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  SimpleChanges,
  Output,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  Observable,
  Subject,
  map,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  tap,
} from "rxjs";
import { CsvService } from "src/app/services/csv.service";
import { DashboardService } from "src/app/services/dashboard.service";
import { ModalService } from "src/app/services/modal.service";
import {
  V2Cast,
  PassService,
  CoursesService,
} from "src/app/sinigangnababoywithgabi";
import {
  Action,
  Header,
} from "../list-table-header/list-table-header.component";

@Component({
  selector: "app-user-select",
  templateUrl: "./user-select.component.html",
  styleUrls: ["./user-select.component.scss"],
})
export class UserSelectComponent implements OnInit {
  cast: V2Cast;
  users: any;
  page: number;
  loading = false;
  columnChange = 0;
  selectedUserIds: string[] = [];
  batchActions: Action[];
  userListObservable: Observable<any[]>;
  oneMonth = 2629800000;
  orderItems = [
    {
      value: "created_at desc",
      text: "Newest",
    },
    {
      value: "created_at",
      text: "Oldest",
    },
    {
      value: "first_name",
      text: "Sort A-Z",
    },
    {
      value: "first_name desc",
      text: "Sort Z-A",
    },
    {
      value: "email",
      text: "Email",
    },
  ];
  headers: Header[] = [
    { name: "Email", value: "email", width: "w-96" },
    { name: "Name", value: "last_name", width: "w-96" },
    { name: "Date Added", value: "first_name", width: "w-56" },
    { name: "Status", value: "first_name", width: "w-56" },
  ];

  currentOrder: string;

  limit: number = 20;
  totalUserCount: number = 1;

  @Input()
  multiple = false;

  @Output()
  selectUserUuid: Subject<string[]> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private passService: PassService,
    private dashboardService: DashboardService,
    private modalService: ModalService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.constructUserListObservable();
  }
  ngOnChanges(changes: SimpleChanges): void {}

  private unsubscribe$: Subject<void> = new Subject<void>();
  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  constructUserListObservable() {
    this.userListObservable = this.route.queryParams.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((params) => {
        this.loading = true;
        let {
          order,
          q,
          page,
          date,
          first_name,
          last_name,
          email,
          last_signin,
        } = params;

        let date2 = date ? date + "T23:59:59.999Z" : null;
        let last_signin2 = last_signin ? last_signin + "T23:59:59.999Z" : null;
        let q2 = q ? q.split(" ") : null;
        let firstNames = first_name?.split(" ");
        let lastNames = last_name?.split(" ");
        let emails = email?.split(" ");
        this.currentOrder = order;
        this.users = null;

        return this.passService.listUsers(
          null,
          this.limit,
          page * this.limit,
          ["user_profile"],
          order,
          null,
          last_signin,
          last_signin2,
          null,
          date,
          date2,
          q2,
          firstNames,
          lastNames,
          emails
        );
      }),
      tap((result) => {
        this.users = result.users;
        this.totalUserCount = result.count;
      }),
      switchMap((result) => {
        return this.dashboardService.getCast().pipe(
          map((cast) => {
            return {
              cast,
              users: result.users,
            };
          })
        );
      }),
      map(({ cast, users }) => {
        return users.map((user) => {
          return {
            uuid: user.id,
            name: user.firstName + " " + user.lastName,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            confirmedAt: user.confirmedAt,
            createdAt: user.createdAt,
            selected: false,
            profile: user.userProfile, //array muna (2/11/22), magiging object
            //groups: user.groups,
            active:
              Date.now() - this.oneMonth <= Date.parse(user.lastSignInAt) &&
              Date.parse(user.lastSignInAt) <= Date.now() &&
              !user.groupIds?.includes(
                cast.data.attributes.deactivatedUsersGroupId
              ),
          };
        });
      }),
      tap((result) => {
        this.users = result;
        this.loading = false;
      })
    );
  }

  // selectedItemList(selected: string[]) {
  //   this.selectedUserIds = selected;
  // }
  orderBy(field) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParamsHandling: "merge",
      replaceUrl: true,
      queryParams: {
        order: field,
      },
    });
  }

  async openModal(template, type?: string) {
    let modalClass = [
      "flex",
      "justify-center",
      "items-center",
      "sm:w-2/3",
      "lg:w-1/2",
      "w-4/5",
    ];
    if (type === "groups") {
      modalClass = [
        "flex",
        "justify-center",
        "items-center",
        "w-3/4",
        "h-1/2",
        ,
      ];
    }
    let results = await this.modalService.openModal(template, {
      panelClass: modalClass,
    });
    return results;
  }

  columnHeaders(event: any) {
    this.headers = event.headers;
    this.cd.detectChanges();
    if (!event.local) {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParamsHandling: "merge",
        replaceUrl: true,
        queryParams: {
          column: this.columnChange++,
        },
      });
    }
  }

  onSelectedItemList(item) {
    this.selectUserUuid.next(item.uuid);
  }
}
