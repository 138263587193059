import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { PurchasesRoutingModule } from "./purchases-routing.module";
import { PurchaseListComponent } from "./purchase-list/purchase-list.component";
import { PurchasesComponent } from "./purchases.component";
import { AppCommonModule } from "../app-common-module/app-common-module.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PurchaseSelectNewComponent } from "./purchase-select-new/purchase-select-new.component";
import { PurchasePaymentOptionComponent } from "./purchase-payment-option/purchase-payment-option.component";
import { PurchaseSubscriptionEditComponent } from "./purchase-subscription-edit/purchase-subscription-edit.component";
import { PurchaseProfileComponent } from "./purchase-profile/purchase-profile.component";
import { PurchaseProductDetailComponent } from "./purchase-product-detail/purchase-product-detail.component";
import { PurchaseSubscriptionNewComponent } from "./purchase-subscription-new/purchase-subscription-new.component";
import { PurchasesSubscriptionFormsComponent } from "./components/purchases-subscription-forms/purchases-subscription-forms.component";
import { PurchaseProductSelectUserComponent } from "./purchase-product-select-user/purchase-product-select-user.component";
import { PurchaseProductUserManualPaymentComponent } from './purchase-product-user-manual-payment/purchase-product-user-manual-payment.component';

@NgModule({
  declarations: [
    PurchaseListComponent,
    PurchasesComponent,
    PurchaseSelectNewComponent,
    PurchasePaymentOptionComponent,
    PurchaseSubscriptionEditComponent,
    PurchaseProfileComponent,
    PurchaseProductDetailComponent,
    PurchaseSubscriptionNewComponent,
    PurchasesSubscriptionFormsComponent,
    PurchaseProductSelectUserComponent,
    PurchaseProductUserManualPaymentComponent,
  ],
  imports: [
    CommonModule,
    PurchasesRoutingModule,
    AppCommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class PurchasesModule {}
