<app-back-title-layout layoutTitle="Collection Profile" [loading]="!collection">
  <ng-container *ngIf="collection">
    <div>
      <img
        [src]="collection.coverUri"
        alt=""
        class="w-full h-40 object-contain bg-gray-300 rounded"
      />

      <div class="text-lg font-bold">
        {{ collection.name }}
      </div>
    </div>

    <div class="mt-5">
      <app-profile-link-list [linkLists]="linkLists"> </app-profile-link-list>
    </div>
  </ng-container>
</app-back-title-layout>
