<app-back-title-layout [loading]="!course">
  <div class="my-5">
    <img
      [src]="course?.coverUri"
      alt=""
      class="w-full h-40 object-contain bg-gray-300 rounded"
    />

    <div class="text-lg font-bold">
      {{ course?.title }}
    </div>
  </div>

  <div class="relative">
    <div class="my-5 flex">
      <app-checkbox class="text-primary-dark" [checked]="true"></app-checkbox>

      <div class="ml-2">
        <label class="form-label">Set as FREE</label>
        <div class="text-sm">No price will be set to this course</div>
      </div>
    </div>

    <div class="my-5">
      <label class="form-label">Price</label>
      <input type="text" disabled placeholder="0.00" class="form-control" />
    </div>

    <div class="my-5">
      <label for="" class="form-label">Payment History</label>

      <hr class="my-3 brder-2" />

      <app-search-bar></app-search-bar>

      <div class="my-5">
        <app-item-list [items]="[]"></app-item-list>
      </div>
    </div>

    <div class="absolute inset-0 bg-opacity-50 bg-white"></div>
  </div>
</app-back-title-layout>
