<app-back-title-layout layoutTitle="Edit members">
  <a [routerLink]="['../']" queryParamsHandling="merge" [replaceUrl]="true">
    <section class="pt-3 pb-5 border-b cursor-pointer">
      <header class="mb-2 font-bold flex justify-between">
        <span> Edit members user list </span>
        <span class="text-lg text-purple-500">
          <app-icon icon="chevron_right"></app-icon>
        </span>
      </header>

      <span class="text-sm text-gray-700">
        Set members via user checkbox.
      </span>
    </section>
  </a>

  <a
    [routerLink]="['../', 'email-list']"
    queryParamsHandling="merge"
    [replaceUrl]="true"
  >
    <section class="pt-3 pb-5 border-b cursor-pointer">
      <header class="mb-2 font-bold flex justify-between">
        <span> Add members via email </span>
        <span class="text-lg text-purple-500">
          <app-icon icon="chevron_right"></app-icon>
        </span>
      </header>

      <span class="text-sm text-gray-700">
        Add members via an email list.
      </span>
    </section>
  </a>

  <a
    [routerLink]="['../', 'email-list-remove']"
    queryParamsHandling="merge"
    [replaceUrl]="true"
  >
    <section class="pt-3 pb-5 border-b cursor-pointer">
      <header class="mb-2 font-bold flex justify-between">
        <span> Remove members via email </span>
        <span class="text-lg text-purple-500">
          <app-icon icon="chevron_right"></app-icon>
        </span>
      </header>

      <span class="text-sm text-gray-700">
        Remove members via an email list.
      </span>
    </section>
  </a>
</app-back-title-layout>
