import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import {
  CoursesService,
  FlarumService,
  Resource,
  ResourcesService,
  V1ExamsService,
} from "../sinigangnababoywithgabi";
import { ActivityLogService, VERBS } from "./activity-log.service";
import { DashboardService } from "./dashboard.service";

// Long term this should be inside the resource service or gateway
@Injectable({
  providedIn: "root",
})
export class ResourceHelperService {
  constructor(
    private resourcesService: ResourcesService,
    private coursesService: CoursesService,
    private v1ExamsService: V1ExamsService,
    private flarumService: FlarumService,
    private activityLogService: ActivityLogService
  ) {}

  async createResource({
    resource,
    order,
    collectionUuid,
    isInsideCourse,
    sectionOrder,
    sectionUuid,
    courseUuid,
  }: {
    resource: Resource;
    order?: string;
    collectionUuid?: string;
    isInsideCourse?: boolean;
    sectionUuid?: string;
    courseUuid?: string;
    sectionOrder?: string;
  }) {
    // Modify resource form depending on the resource type and subtype
    await this.resourceContentHook({ resource });

    // Create resource
    let result = await firstValueFrom(
      this.resourcesService.createResource({
        ...resource,
        packageUuids: collectionUuid ? [collectionUuid] : [],
      })
    );

    // Add in front of collection is order is zero
    if (order === "0" && collectionUuid) {
      let resources = await firstValueFrom(
        this.resourcesService.listResourcesInCollection(collectionUuid, true)
      );
      let resourceUuids = resources.map((r) => r.uuid);

      let resourceIndex = resourceUuids.findIndex(
        (uuid) => uuid === result.uuid
      );

      resourceUuids.splice(resourceIndex, 1);
      resourceUuids.splice(0, 0, result.uuid);

      await firstValueFrom(
        this.resourcesService.setResourceOrderInCollection(collectionUuid, {
          resourceUuids,
        })
      );
    }

    // If inside course, create course block
    if (isInsideCourse) {
      await this.hookIfCreatedInsideCourse({
        resource: result,
        courseUuid,
        sectionOrder,
        sectionUuid,
      });
    }

    this.activityLogService.didResource(VERBS.created, result);

    return result;
  }

  //Hardcoded hooks
  async resourceContentHook(options: { resource: Resource }) {
    if (options.resource.type === "course") {
      await this.addCourseDetail({ resource: options.resource });
    }

    if (options.resource.type === "assessment") {
      await this.addExamDetail({ resource: options.resource });
    }

    if (options.resource.type === "buribook-with-activity") {
      await this.addBuriBooksExamDetail({ resource: options.resource });
    }

    if (options.resource.type === "burislides") {
      await this.addBuriBooksExamDetail({ resource: options.resource });
    }

    if (
      options.resource.type === "link" &&
      options.resource.subtype === "flarum"
    ) {
      await this.addFlarumDetail({ resource: options.resource });
    }
  }

  async addCourseDetail(options: { resource: Resource }) {
    let course = await firstValueFrom(
      this.coursesService.courseCreate({
        title: options.resource.name,
        coverUri: options.resource.coverUri,
        description: options.resource.description,
        prerequisites: [],
      })
    );

    //Hardcode
    // await this.coursesService
    //   .courseCertificateprofileCreate(course.uuid, {
    //     isActive: true,
    //   } as any)
    //   .toPromise();

    let collection = await firstValueFrom(
      this.resourcesService.createCollection({
        name: `${options.resource.name} resources`,
      })
    );

    options.resource.content = {
      courseDetails: {
        courseUuid: course.uuid,
      },
      resourceCollectionUuid: collection.uuid,
    };
  }

  async addBuriBooksExamDetail(options: { resource: Resource }) {
    // Create an exam
    let exam = await firstValueFrom(
      this.v1ExamsService.examsCreate({
        title: `${options.resource.name}`,
        description: `uuid: ${options.resource.uuid}`,
      })
    );

    // Create an exam section
    let section = await firstValueFrom(
      this.v1ExamsService.examsSectionsCreate(exam.uuid, {
        title: "Default Section",
      })
    );

    // Create an exam timeslot
    let timeslot = await firstValueFrom(
      this.v1ExamsService.examsTimeslotsCreate(exam.uuid, {
        accessType: "PU",
      })
    );

    if (options.resource.subtype === "tally") {
      await firstValueFrom(
        this.v1ExamsService.tallyExamProfileDetailCreate(exam.uuid, {
          exam: exam.uuid,
        })
      );
    }

    options.resource.content = {
      ...options.resource.content,
      examDetails: {
        examUuid: exam.uuid,
        examTimeslotUuid: timeslot.uuid,
        examSectionUuid: section.uuid,
      },
    };
  }

  async addExamDetail(options: { resource: Resource }) {
    let exam = await firstValueFrom(
      this.v1ExamsService.examsCreate({
        title: `${options.resource.name}`,
        description: `uuid: ${options.resource.uuid}`,
      })
    );

    let timeslot = await firstValueFrom(
      this.v1ExamsService.examsTimeslotsCreate(exam.uuid, {
        accessType: "PU",
      })
    );

    // Create an exam section
    let section = await firstValueFrom(
      this.v1ExamsService.examsSectionsCreate(exam.uuid, {
        title: "Default Section",
      })
    );

    if (options.resource.subtype === "freedom-wall") {
      let item = await firstValueFrom(
        this.v1ExamsService.examsSectionsItemsCreate(section.uuid, {
          title: "Freedom Wall",
          text: "Edit this question",
          type: "FW",
        })
      );
    }

    if (options.resource.subtype === "file-upload") {
      // Create an exam section
      // let section = await firstValueFrom(
      //   this.v1ExamsService.examsSectionsCreate(exam.uuid, {
      //     title: "Default Section",
      //   })
      // );

      let item = await firstValueFrom(
        this.v1ExamsService.examsSectionsItemsCreate(section.uuid, {
          title: "File Upload",
          text: "Edit this question",
          type: "ME",
        })
      );
    }

    // if (options.resourceForm.value.subtype === "tally") {
    //   await this.examsService
    //     .tallyExamProfileDetailCreate(exam.uuid, {
    //       exam: exam.uuid,
    //     })
    //     .toPromise();
    // }

    options.resource.content = {
      ...options.resource.content,
      examDetails: {
        examUuid: exam.uuid,
        examTimeslotUuid: timeslot.uuid,
      },
    };
  }

  async addFlarumDetail(options: { resource: Resource }) {
    let result = await firstValueFrom(
      this.flarumService.createFlarumTopic({
        data: {
          type: "topics",
          attributes: {
            name: options.resource.name,
            description: options.resource.description,
          },
        },
      })
    );
    //Hardcode DICT
    options.resource.content = {
      uri: `https://dict.flarum.buri.dev/t/${result.data.attributes.slug}`,
    };
  }

  async hookIfCreatedInsideCourse(options: {
    resource: Resource;
    sectionUuid: string;
    courseUuid: string;
    sectionOrder: string;
  }) {
    let { sectionUuid, courseUuid, sectionOrder, resource } = options;

    let referenceInfo;
    let orderId;

    // Add default referenc info if resource is an assessment
    if (resource.type === "assessment") {
      referenceInfo = {
        referenceId: resource.content?.examDetails?.examTimeslotUuid,
        customPassingMark: 50,
      };
    }

    if (resource.type === "burislides") {
      referenceInfo = {
        referenceId: resource.content?.examDetails?.examTimeslotUuid,
        customPassingMark: null,
        maxAllowedSubmissions: null,
        passOnSubmit: true,
      };
    }

    // https://buri.dev/T1838
    if (parseInt(sectionOrder) === -1) {
      orderId = 0;
      // Get all section blocks
      let blocks = await firstValueFrom(
        this.coursesService.sectionBlockList(sectionUuid)
      );

      // Adjust all blocks
      for (let i = 0; i < blocks.length; i++) {
        let block = blocks[i];

        await firstValueFrom(
          this.coursesService.blockUpdate(block.uuid, {
            ...block,
            orderId: (i + 1 + "").padStart(2, "0"),
          })
        );
      }
    } else {
      orderId = parseInt(sectionOrder) + 1;
      let blocks = await firstValueFrom(
        this.coursesService.sectionBlockList(sectionUuid)
      );
      // Adjust all blocks
      for (let i = orderId; i < blocks.length; i++) {
        let block = blocks[i];
        await firstValueFrom(
          this.coursesService.blockUpdate(block.uuid, {
            ...block,
            orderId: (i + 1 + "").padStart(2, "0"),
          })
        );
      }
    }

    let result = await firstValueFrom(
      this.coursesService.sectionBlockCreate(sectionUuid, {
        title: resource.name,
        coverUri: resource.coverUri,
        content: {
          resourceDetails: {
            uuid: resource.uuid,
            name: resource.name,
            description: resource.description,
            coverUri: resource.coverUri,
            type: resource.type,
            subtype: resource.subtype,
            flavorTextSpan: resource.flavorTextSpan,
            tags: resource.tags,
            publishedAt: resource.publishedAt,
          },
        },
        orderId: (orderId + "")?.padStart(2, "0"),
        referenceInfo,
        requiredForCertificate: true,
      })
    );

    // Activity log
    this.activityLogService.didCourseBlock(VERBS.created, result, {
      context: {
        contextActivities: {
          parent: [
            this.activityLogService.constructCourseSectionObject({
              uuid: sectionUuid,
            }),
          ],
          category: [
            this.activityLogService.constructCourseObject({
              uuid: courseUuid,
            }),
          ],
        },
      },
    });
  }
}
