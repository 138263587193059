import { Component, OnInit, Input } from "@angular/core";
import { Resource, ResourcesService } from "src/app/sinigangnababoywithgabi";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { firstValueFrom } from "rxjs";

// Probably DEPRECATED
@Component({
  selector: "app-resource-type-upload-video-with-activity",
  templateUrl: "./resource-type-upload-video-with-activity.component.html",
})
export class ResourceTypeUploadVideoWithActivityComponent implements OnInit {
  @Input()
  resource: Resource;

  @Input()
  formGroup: UntypedFormGroup;

  resources: Resource[];
  selectedResource: Resource;
  selectedResourceUuid: string;

  constructor(
    private resourcesService: ResourcesService,
    private formBuilder: UntypedFormBuilder
  ) {}

  async ngOnInit() {
    this.formGroup.addControl(
      "content",
      this.formBuilder.group({
        sourceResourceUuid: [
          this.resource.content["sourceResourceUuid"],
          Validators.required,
        ],
      })
    );

    this.resources = await firstValueFrom(
      this.resourcesService.listResources("video")
    );
  }

  onSelect() {
    // if (this.resource.content["sourceResourceUuid"]) {
    //   this.resourcesService
    //     .getResourceByUuid(this.resource.content["sourceResourceUuid"])
    //     .subscribe((result) => {});
    // }
  }
}
