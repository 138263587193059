import { Component, OnInit } from "@angular/core";
import {
  Component as ResourceComponent,
  Collection,
  ResourcesService,
} from "src/app/sinigangnababoywithgabi";
import { ActivatedRoute, Router } from "@angular/router";

import querystring from "query-string";

@Component({
  selector: "app-component-select",
  templateUrl: "./component-select.component.html",
  styleUrls: ["./component-select.component.scss"],
})
//COPY PASTE MUNA NG CODE -_- Pagsamahin eith ComponentListComponent pag may time na
// DEPRECATED
export class ComponentSelectComponent implements OnInit {
  searchString: string;
  components: ResourceComponent[];

  collection: Collection;
  redirect: string;
  source: string;

  constructor(
    private resourcesService: ResourcesService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((query) => {
      this.redirect = query["redirect"];
      this.source = query["source"];

      this.refreshList();
    });
  }

  refreshList() {
    this.components = null;
    this.collection = null;

    this.source = this.source || "components";

    this.getCollections();
  }

  getCollections() {
    this.resourcesService.listCollections().subscribe((result) => {
      this.components = result;
    });
  }

  getChildComponents(collectionUuid: string) {
    this.resourcesService
      .listResourcesInCollection(collectionUuid, true)
      .subscribe((result) => {
        this.components = result;
      });
  }

  async allResourcesClick() {
    this.collection = {
      name: "All Resources",
    };

    this.components = null;

    this.resourcesService
      .listResources(null, null, null, null, null, null, null, null, null, true)
      .subscribe((result) => {
        this.components = result;
      });
  }

  async onSelect(uuid: string) {
    this.searchString = "";

    if (this.redirect) {
      if (this.source === "components" || this.source === "resources") {
        let redirectUrl = new URL("http://placeholder" + this.redirect);

        //Parse params
        let redirectParams = querystring.parse(redirectUrl.search);

        //Add to params
        redirectParams["selected_resource_uuid"] = uuid;

        this.router.navigateByUrl(
          redirectUrl.pathname + "?" + querystring.stringify(redirectParams),
          { replaceUrl: true }
        );
      } else if (this.source === "collections") {
        let redirectUrl = new URL("http://placeholder" + this.redirect);

        //Parse params
        let redirectParams = querystring.parse(redirectUrl.search);

        //Add to params
        redirectParams["selected_collection_uuid"] = uuid;

        this.router.navigateByUrl(
          redirectUrl.pathname + "?" + querystring.stringify(redirectParams),
          { replaceUrl: true }
        );
      }
    }
  }

  async componentClick(component: ResourceComponent) {
    this.searchString = "";

    if (this.source === "components" || this.source === "resources") {
      if (
        component.type === "package" ||
        component.type === "collection" ||
        !component.type
      ) {
        this.components = null;
        this.collection = component;

        this.getChildComponents(component.uuid);
      } else if (this.redirect) {
        let redirectUrl = new URL("http://placeholder" + this.redirect);

        //Parse params
        let redirectParams = querystring.parse(redirectUrl.search);

        //Add to params
        redirectParams["selected_resource_uuid"] = component.uuid;

        this.router.navigateByUrl(
          redirectUrl.pathname + "?" + querystring.stringify(redirectParams),
          { replaceUrl: true }
        );
      }
    } else if (this.source === "collections" && this.redirect) {
      let redirectUrl = new URL("http://placeholder" + this.redirect);

      //Parse params
      let redirectParams = querystring.parse(redirectUrl.search);

      //Add to params
      redirectParams["selected_collection_uuid"] = component.uuid;

      this.router.navigateByUrl(
        redirectUrl.pathname + "?" + querystring.stringify(redirectParams),
        { replaceUrl: true }
      );
    }
  }
}
