import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { slideIn } from "src/app/app-common-module/animations/slide";

@Component({
  selector: "app-casteditor-edit-toggle",
  templateUrl: "./casteditor-edit-toggle.component.html",
  styleUrls: ["./casteditor-edit-toggle.component.scss"],
  animations: [slideIn],
})
export class CasteditorEditToggleComponent implements OnInit {
  constructor() {}

  @Input()
  isInEditMode: boolean;

  @Input() settingsRouterLink: string | string[];
  @Input() settingsQueryParams: any;

  @Output() deleteBlock: EventEmitter<any> = new EventEmitter();
  @Output() editToggle: EventEmitter<any> = new EventEmitter();
  @Output() saveBlock: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {}

  onToggleEditMode() {
    this.isInEditMode = !this.isInEditMode;
    this.editToggle.emit(this.isInEditMode);
  }

  onSaveBlock() {
    this.isInEditMode = !this.isInEditMode;
    this.saveBlock.emit();
    this.editToggle.emit(this.isInEditMode);
  }
  onDeleteBlock() {
    this.deleteBlock.emit();
  }
}
