import { DragDropModule } from "@angular/cdk/drag-drop";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDialogModule } from "@angular/material/dialog";
import { ColorPickerModule } from "ngx-color-picker";
import { AppCommonModule } from "../app-common-module/app-common-module.module";
import { CasteditorActivityLogsComponent } from "./casteditor-activity-logs/casteditor-activity-logs.component";
import { CasteditorBlockButtonSearchComponent } from "./casteditor-block-button-search/casteditor-block-button-search.component";
import { CasteditorBlockCarouselComponent } from "./casteditor-block-carousel/casteditor-block-carousel.component";
import { CasteditorBlockCatalogSettingsComponent } from "./casteditor-block-catalog-settings/casteditor-block-catalog-settings.component";
import { CasteditorBlockCatalogComponent } from "./casteditor-block-catalog/casteditor-block-catalog.component";
import { CasteditorBlockCourseStatusComponent } from "./casteditor-block-course-status/casteditor-block-course-status.component";
import { CasteditorBlockLinkComponent } from "./casteditor-block-link/casteditor-block-link.component";
import { CasteditorBlockMarkdownComponent } from "./casteditor-block-markdown/casteditor-block-markdown.component";
import { CasteditorBlockNavbarComponent } from "./casteditor-block-navbar/casteditor-block-navbar.component";
import { CasteditorBlockNavigationSettingsComponent } from "./casteditor-block-navigation-settings/casteditor-block-navigation-settings.component";
import { CasteditorBlockNavigationComponent } from "./casteditor-block-navigation/casteditor-block-navigation.component";
import { CasteditorCastEditComponent } from "./casteditor-cast-edit/casteditor-cast-edit.component";
import { CasteditorMainComponent } from "./casteditor-main/casteditor-main.component";
import { CasteditorNavigationEditComponent } from "./casteditor-navigation-edit/casteditor-navigation-edit.component";
import { CasteditorRoutingModule } from "./casteditor-routing.module";
import { CasteditorScreenCreateComponent } from "./casteditor-screen-create/casteditor-screen-create.component";
import { CasteditorScreenEditComponent } from "./casteditor-screen-edit/casteditor-screen-edit.component";
import { CasteditorScreensSelectComponent } from "./casteditor-screens-select/casteditor-screens-select.component";
import { CasteditorComponent } from "./casteditor.component";
import { CasteditorCardComponent } from "./components/casteditor-card/casteditor-card.component";
import { CasteditorEditToggleComponent } from "./components/casteditor-edit-toggle/casteditor-edit-toggle.component";
import { CasteditorMarginOutletComponent } from "./components/casteditor-margin-outlet/casteditor-margin-outlet.component";
import { CasteditorModalAddBlockComponent } from "./components/casteditor-modal-add-block/casteditor-modal-add-block.component";
import { CasteditorModalAddImageComponent } from "./components/casteditor-modal-add-image/casteditor-modal-add-image.component";
import { CasteditorModalLayoutComponent } from "./components/casteditor-modal-layout/casteditor-modal-layout.component";
import { CasteditorModalWelcomeComponent } from "./components/casteditor-modal-welcome/casteditor-modal-welcome.component";
import { CasteditorScreenActionsComponent } from './components/casteditor-screen-actions/casteditor-screen-actions.component';
import { CasteditorBlockCategoryComponent } from './casteditor-block-category/casteditor-block-category.component';
import { CasteditorBlockButtonSearchSettingsComponent } from './casteditor-block-button-search-settings/casteditor-block-button-search-settings.component';
import { CasteditorModalAddCatalogComponent } from './components/casteditor-modal-add-catalog/casteditor-modal-add-catalog.component';
import { CasteditorCatalogCardComponent } from './components/casteditor-catalog-card/casteditor-catalog-card.component';

@NgModule({
  declarations: [
    CasteditorComponent,
    CasteditorMainComponent,
    CasteditorBlockCatalogComponent,
    CasteditorCardComponent,
    CasteditorEditToggleComponent,
    CasteditorModalLayoutComponent,
    CasteditorBlockNavbarComponent,
    CasteditorBlockLinkComponent,
    CasteditorModalWelcomeComponent,
    CasteditorBlockMarkdownComponent,
    CasteditorModalAddBlockComponent,
    CasteditorNavigationEditComponent,
    CasteditorScreensSelectComponent,
    CasteditorScreenEditComponent,
    CasteditorBlockNavigationComponent,
    CasteditorActivityLogsComponent,
    CasteditorMarginOutletComponent,
    CasteditorBlockCourseStatusComponent,
    CasteditorBlockButtonSearchComponent,
    CasteditorScreenCreateComponent,
    CasteditorCastEditComponent,
    CasteditorBlockNavigationSettingsComponent,
    CasteditorBlockCatalogSettingsComponent,
    CasteditorModalAddImageComponent,
    CasteditorBlockCarouselComponent,
    CasteditorScreenActionsComponent,
    CasteditorBlockCategoryComponent,
    CasteditorBlockButtonSearchSettingsComponent,
    CasteditorModalAddCatalogComponent,
    CasteditorCatalogCardComponent,
  ],
  imports: [
    AppCommonModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    CasteditorRoutingModule,
    DragDropModule,
    ColorPickerModule,
    MatDialogModule,
  ],
})
export class CasteditorModule {}
