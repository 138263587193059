<div
  [style.backgroundColor]="
    v2Block?.data.attributes.properties.appearance?.base?.backgroundColor
      | castColor
  "
>
  <div class="flex justify-between pt-2 pb-4">
    <div class="flex items-start font-bold">
      <div class="catalog-handle" cdkDragHandle>
        <button>
          <app-icon icon="unfold_more" class="text-gray-600"></app-icon>
        </button>
      </div>

      <div
        class="line-clamp-1"
        *ngIf="!isInEditMode; else editName"
        [style.color]="
          v2Block?.data.attributes.properties.appearance?.base?.foregroundColor
            | castColor
        "
        [style.fontFamily]="v2Block?.data.attributes.properties.appearance?.base?.fontFamily?.[0]"
      >
        {{ collectionNameLabel }}
      </div>
    </div>

    <div class="mx-1">
      <app-casteditor-edit-toggle
        [ngClass]="{
          invisible: !canEdit || disableEdit
        }"
        settingsRouterLink="block-catalog-settings"
        [settingsQueryParams]="{ block_id: block.id }"
        (editToggle)="onEditToggle($event)"
        (deleteBlock)="onDeleteBlock()"
      >
      </app-casteditor-edit-toggle>
    </div>
  </div>

  <div class="h-48 p-5" *ngIf="isLoading || errorMessage; else resourcesLoaded">
    <div
      class="flex flex-col items-center justify-center w-full h-full text-lg font-bold text-purple-400 bg-purple-200 rounded-lg hover:bg-purple-300 hover:text-purple-500"
    >
      <span class="text-center text-red-400" *ngIf="errorMessage">{{
        errorMessage
      }}</span>

      <app-loading
        *ngIf="isLoading"
        [loading]="true"
        position="relative"
      ></app-loading>
    </div>
  </div>
</div>

<ng-template #resourcesLoaded>
  <div class="h-48 p-5" *ngIf="!resources?.length">
    <button
      class="flex flex-col items-center justify-center w-full h-full text-lg font-bold text-purple-400 bg-purple-200 rounded-lg hover:bg-purple-300 hover:text-purple-500"
      [disabled]="!isInEditMode"
      (click)="onAddResource()"
    >
      <div>
        <span> Nothing here yet. </span>
        <span *ngIf="isInEditMode">Add something!</span>
      </div>

      <div class="text-3xl" *ngIf="isInEditMode">
        <app-icon icon="add_circle"></app-icon>
      </div>
    </button>
  </div>

  <!-- [ngClass]="{
    'bg-purple-200 p-3': isInEditMode
  }" -->
  <div *ngIf="resources?.length" class="flex flex-grow py-2 ml-1 rounded">
    <button
      *ngIf="isInEditMode"
      [@zoomInOut]
      class="flex-none w-24 bg-purple-200 rounded"
      (click)="onAddResource()"
    >
      <app-icon
        class="text-4xl text-purple-400 rounded"
        icon="add_circle"
      ></app-icon>
    </button>
    <div
      cdkDropList
      [cdkDropListData]="resources"
      [cdkDropListDisabled]="!isInEditMode"
      cdkDropListOrientation="horizontal"
      class="flex h-48 overflow-x-scroll"
      (cdkDropListDropped)="drop($event)"
    >
      <ng-container *ngFor="let resource of resources">
        <div cdkDrag>
          <app-casteditor-card
            [resource]="resource"
            [isInEditMode]="isInEditMode"
            (deleteResource)="onDeleteResource(resource.uuid)"
            [cardHeaderBackgroundColor]="
              v2Block.data.attributes.properties.appearance?.cardHeader
                ?.backgroundColor | castColor
            "
            [cardHeaderForegroundColor]="
              v2Block.data.attributes.properties.appearance?.cardHeader
                ?.foregroundColor | castColor
            "
          ></app-casteditor-card>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #editName>
  <input
    type="text"
    id="collectionName"
    name="collectionName"
    [(ngModel)]="collectionNameLabel"
    class="edit-name"
  />
</ng-template>
