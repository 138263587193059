import { Component, OnInit } from "@angular/core";
import { LinkList } from "src/app/app-common-module/layouts/profile-link-list/profile-link-list.component";

// DEPRECATED to MODAL
@Component({
  selector: "app-user-create-index",
  templateUrl: "./user-create-index.component.html",
})
export class UserCreateIndexComponent implements OnInit {
  linkLists: LinkList[] = [
    {
      name: "Add user manually",
      description: "You may add users manually or add existing users",
      routerLinkOptions: {
        routerLink: ["../"],
        queryParamsHandling: "merge",
      },
    },
    {
      name: "Upload a CSV file",
      description:
        "Add users not yet existing to your current list (suggested when adding bulk of new users)",
      routerLinkOptions: {
        routerLink: ["../", "csv"],
        queryParamsHandling: "merge",
      },
    },
    // {
    //   name: "Manually Create New User",
    //   description: "Input user information to add new users",
    //   routerLinkOptions: {
    //     routerLink: ["../"],
    //     queryParamsHandling: "merge",
    //   },
    // },
  ];

  constructor() {}

  ngOnInit(): void {}
}
