import { Component, Input, OnInit } from "@angular/core";
import { FormArray, FormControl, UntypedFormGroup } from "@angular/forms";
import { Resource } from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-resource-details",
  templateUrl: "./resource-details.component.html",
})
export class ResourceDetailsComponent implements OnInit {
  @Input()
  formGroup: UntypedFormGroup;
  @Input()
  resource: Resource;

  constructor() {}

  ngOnInit(): void {}

  onUpload(event) {
    this.formGroup.get("coverUri").setValue(event.uri);
  }

  addTag(event) {
    event.preventDefault();
    event.stopPropagation();

    let newTag = event.target.value;
    let tagFormControl = this.formGroup.get("tags");

    if (newTag && tagFormControl.value.indexOf(newTag) < 0) {
      tagFormControl.setValue([...tagFormControl.value, newTag]);
    }

    event.target.value = "";
  }

  removeTag(index: number, event) {
    if (!event.target.value) {
      let tagFormControl = this.formGroup.get("tags");

      let newValue = [...tagFormControl.value];

      newValue.splice(index, 1);

      tagFormControl.setValue(newValue);
    }
  }
}
