import { Component, OnInit } from "@angular/core";
import {
  CourseSchedule,
  CoursesService,
  CourseUserEnrollment,
} from "src/app/sinigangnababoywithgabi";
import { ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { firstValueFrom } from "rxjs";

@Component({
  selector: "app-course-batch-enrollments",
  templateUrl: "./course-batch-enrollments.component.html",
})
export class CourseBatchEnrollmentsComponent implements OnInit {
  schedule: CourseSchedule;
  enrollments: CourseUserEnrollment[];

  selectedUserUuids: string[];

  isLoading: boolean;

  constructor(
    private route: ActivatedRoute,
    private coursesService: CoursesService,
    private location: Location
  ) {}

  async ngOnInit() {
    let scheduleUuid = this.route.snapshot.queryParams["schedule_uuid"];

    this.schedule = await firstValueFrom(
      this.coursesService.scheduleRead(scheduleUuid)
    );

    this.enrollments = await firstValueFrom(
      this.coursesService.scheduleEnrollmentsList(scheduleUuid)
    );

    this.updateSelectedUserUuids();
  }

  updateSelectedUserUuids() {
    this.selectedUserUuids = this.enrollments.map((e) => e.user);
  }

  async updateUserEnrollments() {
    try {
      let { schedule_uuid: scheduleUuid } = this.route.snapshot.queryParams;
      this.isLoading = true;

      await firstValueFrom(
        this.coursesService.scheduleEnrollmentsUpdate(scheduleUuid, {
          enrollments: this.selectedUserUuids.map((userUuid) => ({
            user: userUuid,
            isActive: true,
          })),
        })
      );

      // Need pa ba RUN to?
      await firstValueFrom(
        this.coursesService.scheduleSchedulestatsUpdate(scheduleUuid)
      );

      this.location.back();
    } catch (err) {
      this.isLoading = false;
    }
  }
}
