<app-back-title-layout
  layoutTitleIcon="group"
  layoutTitle="{{ group?.name }}"
  [titleIconButtons]="[
    {
      icon: 'mode',
      routerLink: 'edit',
      queryParamsHandling: 'merge'
    }
  ]"
  [loading]="!group"
>
  <div backTitleLayoutBeforeMain>
    <p class="my-2">{{ group?.description }}</p>

    <ng-container *ngFor="let role of roles">
      <span
        class="inline-block rounded-full bg-secondary-500 text-white px-2 py-0.5 m-0.5 max-w-xs truncate text-sm"
      >
        {{ role.name }}
      </span>
    </ng-container>

    <div class="text-gray-700 my-2">
      <app-icon icon="person"></app-icon>

      {{ totalUserCount }}
    </div>

    <a
      class="uppercase text-primary-600 text-sm"
      [routerLink]="['activity-logs']"
      queryParamsHandling="merge"
    >
      <app-icon icon="list_alt"></app-icon>

      View Activity Log
    </a>

    <h2 class="form-heading mt-10">Members</h2>
  </div>

  <ng-container *ngIf="group">
    <!-- <button
      class="btn btn-primary my-2"
      [routerLink]="['edit-members', 'index']"
      queryParamsHandling="merge"
    >
      <app-icon icon="add_circle"></app-icon>

      Add user
    </button> -->

    <button class="btn btn-primary my-2" (click)="openModifyPrompt(modifyUser)">
      <app-icon icon="add_circle"></app-icon>

      Modify members
    </button>

    <!-- [headers]="headers"
      [batchActions]="groupItems" -->

    <div class="mt-2 block w-full sm:flex">
      <app-search-bar-query
        placeholder="Search"
        class="w-3/4"
      ></app-search-bar-query>
      <div class="flex mt-2 sm:mt-0">
        <button
          class="uppercase flex bg-gray-200 items-center px-1 text-gray-400 ml-2"
          (click)="openModal(filters)"
        >
          <app-icon icon="filter_list"> </app-icon>
          <div class="hidden sm:block text-sm">filter</div>
        </button>
        <div class="flex bg-gray-200 px-1 text-gray-400 mx-2 items-center">
          <app-dropdown-layout position="left">
            <div dropdownToggle class="uppercase flex items-center">
              <app-icon icon="import_export"> </app-icon>
              <div class="hidden sm:flex text-sm">Sort</div>
            </div>

            <div dropdownContent>
              <ng-container *ngFor="let orderItem of orderItems">
                <button
                  class="w-full py-1 px-2 text-left text-primary hover:bg-primary hover:text-white"
                  (click)="orderBy(orderItem.value)"
                >
                  <app-icon
                    icon="check"
                    [ngClass]="{ invisible: orderItem.value !== currentOrder }"
                  ></app-icon>
                  {{ orderItem.text }}
                </button>
              </ng-container>
            </div>
          </app-dropdown-layout>
        </div>
        <button
          class="uppercase flex bg-gray-200 items-center px-1 text-gray-400"
          (click)="openModal(columns)"
        >
          <app-icon icon="view_column"> </app-icon>
          <div class="hidden sm:block text-sm">columns</div>
        </button>
      </div>
    </div>
    <div class="w-full">
      <app-filter-section></app-filter-section>
    </div>
    <app-item-select-with-header
      [batchActions]="batchActions"
      [enableSort]="true"
      [source]="userObs"
      [itemTemplate]="itemTemplate"
      [category]="'user'"
      [key]="'id'"
      [limit]="limit"
      [headers]="headers"
      [totalCount]="totalUserCount"
      [loading]="loading"
      (selectedItemList)="selectedItemList($event)"
    >
    </app-item-select-with-header>
  </ng-container>
</app-back-title-layout>

<ng-template #itemTemplate let-item="item">
  <div class="flex w-full">
    <div class="block sm:flex mt-2 sm:mt-0">
      <a
        class="block sm:flex items-center ml-2 w-full"
        href="/users-and-groups/users/profile/courses-and-certification?user_uuid={{item.id}}"
      >
        <ng-container *ngIf="headers">
          <div class="flex" *ngFor="let header of headers">
            <div
              class="sm:{{ header.width }} flex leading-tight truncate text-sm"
              *ngIf="header.name === 'Email'"
            >
              <div
                class="w-6 h-6 mr-1 text-white text-xl bg-gray-200 rounded-full flex items-center justify-center"
              >
                <app-icon icon="person"></app-icon>
              </div>

              <div class="truncate">
                {{ item.email }}
              </div>
            </div>

            <div
              *ngIf="header.name === 'Name'"
              class="sm:w-96 truncate text-sm"
            >
              {{ item.firstName + " " + item.lastName }}
            </div>

            <div
              *ngIf="header.name === 'Date Added'"
              class="sm:w-56 truncate text-sm"
            >
              {{ item.createdAt | date }}
            </div>
            <div
              *ngIf="header.name === 'Birthdate' && item.profile"
              class="sm:w-56 truncate text-sm"
            >
              <ng-container *ngIf="item.profile.length === 1">
                {{ item.profile[0].birthdate }}
              </ng-container>
            </div>
            <div
              *ngIf="header.name === 'Status'"
              class="sm:w-56 truncate text-sm"
            >
              <div
                class="shrink w-max bg-success-100 text-success-600 rounded-full px-1 mt-2 sm:mt-0 text-sm"
                *ngIf="item.active"
              >
                Active
              </div>
            </div>
            <div
              *ngIf="header.name === 'Sex' && item.profile"
              class="sm:w-56 truncate text-sm"
            >
              <ng-container *ngIf="item.profile.length === 1">
                {{ item.profile[0].gender }}
              </ng-container>
            </div>
            <div
              *ngIf="header.name === 'Place of Birth' && item.profile"
              class="sm:w-56 truncate text-sm"
            >
              <ng-container *ngIf="item.profile.length === 1">
                {{ item.profile[0].birthplace }}
              </ng-container>
            </div>
            <div
              *ngIf="header.name === 'Civil Status' && item.profile"
              class="sm:w-56 truncate text-sm"
            >
              <ng-container *ngIf="item.profile.length === 1">
                {{ item.profile[0].maritalStatus }}
              </ng-container>
            </div>
            <div
              *ngIf="header.name === 'Citizenship' && item.profile"
              class="sm:w-56 truncate text-sm"
            >
              <ng-container *ngIf="item.profile.length === 1">
                {{ item.profile[0].citizenship }}
              </ng-container>
            </div>
            <div
              *ngIf="header.name === 'Address' && item.profile"
              class="sm:w-56 truncate text-sm"
            >
              <ng-container *ngIf="item.profile.length === 1">
                {{ item.profile[0].permanentAddress }}
              </ng-container>
            </div>
            <div
              *ngIf="header.name === 'Telephone no.' && item.profile"
              class="sm:w-56 truncate text-sm"
            >
              <ng-container *ngIf="item.profile.length === 1">
                {{ item.profile[0].phoneNumber }}
              </ng-container>
            </div>
            <div
              *ngIf="header.name === 'Cellphone no.' && item.profile"
              class="sm:w-56 truncate text-sm"
            >
              <ng-container *ngIf="item.profile.length === 1">
                {{ item.profile[0].mobileNumber }}
              </ng-container>
            </div>
            <div
              *ngIf="header.name === 'Company/Agency' && item.profile"
              class="sm:w-56 truncate text-sm"
            >
              <ng-container *ngIf="item.profile.length === 1">
                {{ item.profile[0].companyName }}
              </ng-container>
            </div>
            <div
              *ngIf="header.name === 'Company Category' && item.profile"
              class="sm:w-56 truncate text-sm"
            >
              <ng-container *ngIf="item.profile.length === 1">
                {{ item.profile[0].companyCategory }}
              </ng-container>
            </div>
            <div
              *ngIf="header.name === 'Designation' && item.profile"
              class="sm:w-56 truncate text-sm"
            >
              <ng-container *ngIf="item.profile.length === 1">
                {{ item.profile[0].companyDesignation }}
              </ng-container>
            </div>
          </div>
        </ng-container>
      </a>
    </div>
  </div>
</ng-template>

<ng-template #modifyUser>
  <div class="flex justify-between mb-5">
    <span class="form-heading">Modify members</span>

    <button class="btn btn-icon bg-transparent" mat-dialog-close>
      <app-icon icon="close"></app-icon>
    </button>
  </div>

  <a
    [routerLink]="['edit-members']"
    queryParamsHandling="merge"
    mat-dialog-close
  >
    <div class="font-bold text-primary-700">Edit members user list</div>

    <div class="text-sm text-gray-700">Set members via user checkbox.</div>
  </a>

  <hr class="form-divider" />

  <a
    [routerLink]="['edit-members', 'email-list']"
    queryParamsHandling="merge"
    mat-dialog-close
  >
    <div class="font-bold text-primary-700">Add members via email</div>

    <div class="text-sm text-gray-700">Add members via an email list.</div>
  </a>

  <hr class="form-divider" />

  <a
    [routerLink]="['edit-members', 'email-list-remove']"
    queryParamsHandling="merge"
    mat-dialog-close
  >
    <div class="font-bold text-primary-700">Remove members via email</div>

    <div class="text-sm text-gray-700">Remove members via an email list.</div>
  </a>

  <hr class="form-divider" />
</ng-template>
<ng-template #filters> <app-filters></app-filters></ng-template>
<ng-template #columns>
  <app-columns (headers)="columnHeaders($event)"></app-columns
></ng-template>
