import { Component, forwardRef, Input, OnInit } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { firstValueFrom } from "rxjs";
import { PassService, Role } from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-role-selector",
  templateUrl: "./role-selector.component.html",
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RoleSelectorComponent),
      multi: true,
    },
  ],
})
export class RoleSelectorComponent implements OnInit, ControlValueAccessor {
  @Input()
  disabled: boolean;

  @Input()
  setLearnersAsDefaultRole: boolean;

  roles: Role[];

  selectedRoleId: string;

  constructor(private passService: PassService) {}
  async ngOnInit() {
    this.roles = [
      ...(await firstValueFrom(
        this.passService.listRoles(null, null, null, null, ["readonly"], "any")
      ).then((result) => result.roles)),
      ...(await firstValueFrom(
        this.passService.listRoles(null, null, null, null, ["custom"], "any")
      ).then((result) => result.roles)),
    ];

    if (this.setLearnersAsDefaultRole && !this.selectedRoleId) {
      // HARDCODE: Set Learners as default role
      let selectedRoleId = this.roles.find((role) =>
        /Learners/.test(role.name)
      )?.id;

      this.writeValue(selectedRoleId);
    }
  }

  isCustomRole(role: Role) {
    return role.tagList.includes("custom");
  }

  propagateChange = (_: any) => {};

  writeValue(value: any): void {
    this.selectedRoleId = value;

    this.propagateChange(value);
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {}
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
