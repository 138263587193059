import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { userInfo } from "os";
import {
  EMPTY,
  Observable,
  debounceTime,
  distinctUntilChanged,
  firstValueFrom,
  forkJoin,
  of,
  switchMap,
  tap,
  zip,
} from "rxjs";
import { ModalService } from "src/app/services/modal.service";
import {
  FieldChoicesService,
  FieldEntriesService,
  FieldGroupsService,
  FieldsService,
  FormSubmissionsService,
  ServicesBurecordsFieldGroupsIdFieldsGet200Response,
  ServicesBurecordsFieldsIdFieldChoicesGet200Response,
  TabsService,
} from "src/app/sinigangnababoywithgabi";
import { AddSubtabModalComponent } from "../components/add-subtab-modal/add-subtab-modal.component";
import { fadeInOut } from "src/app/app-common-module/animations/fade";

@Component({
  selector: "app-idis-users-settings",
  templateUrl: "./idis-users-settings.component.html",
  styles: [],
  animations: [fadeInOut],
})
export class IdisUsersSettingsComponent implements OnInit {
  mainTabs = [];
  parentTab: string = "";
  parentTabId: string = "";
  enabledSubTabs: boolean = true;
  currentForm: any = {};
  subTabs = [];
  trainingNeedsForm2: any = {};
  loading: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private modalService: ModalService,
    private fieldChoicesService: FieldChoicesService,
    private fieldGroupsService: FieldGroupsService,
    private fieldsService: FieldsService,
    private formSubmissionsService: FormSubmissionsService,
    private fieldEntriesService: FieldEntriesService,
    private tabsService: TabsService
  ) {}
  ngOnInit(): void {
    this.route.queryParams
      .pipe(
        distinctUntilChanged(),
        switchMap((params) => {
          let { parent_tab } = params;
          this.parentTab = parent_tab;
          //Get tabs
          if (parent_tab === "training_needs") {
            this.enabledSubTabs = false;
            this.currentForm = this.trainingNeedsForm2;
          }
          if (parent_tab === "user_information") {
            this.enabledSubTabs = true;
            if (this.subTabs.length > 0) this.currentForm = this.subTabs[0];
          }

          return of({});
        })

        //Map everything into one array
        //Assign currentForm to first subtab
      )
      .subscribe();
    this.getTabsInfo();
  }

  getTabsInfo() {
    this.tabsService
      .servicesBurecordsTabsTokenBasedGet(true, "sub_tabs")
      .pipe(
        tap((result) => {
          this.loading = true;
        }),
        //Look for training_needs and user_information parent tabs
        // If no parent tabs, create training_needs and user_information parent tabs
        switchMap((tabs) => {
          let observables: Observable<any>[] = [];
          if (tabs.count > 0) {
            let userInformationTab: boolean = false;
            let trainingNeedsTab: boolean = false;
            tabs.tabs.forEach((tab) => {
              if (tab.name === "user_information") {
                userInformationTab = true;
              }
              if (tab.name === "training_needs") {
                trainingNeedsTab = true;
              }
            });
            if (!userInformationTab) {
              observables.push(
                this.tabsService.servicesBurecordsTabsTokenBasedPost({
                  tab: {
                    parentTabId: null,
                    name: "user_information",
                    displayName: "User Information",
                    orderId: 0,
                  },
                })
              );
            }
            if (!trainingNeedsTab) {
              observables.push(
                this.tabsService.servicesBurecordsTabsTokenBasedPost({
                  tab: {
                    parentTabId: null,
                    name: "training_needs",
                    displayName: "Training Needs",
                    orderId: 1,
                  },
                })
              );
            }
          } else {
            observables = [
              this.tabsService.servicesBurecordsTabsTokenBasedPost({
                tab: {
                  parentTabId: null,
                  name: "user_information",
                  displayName: "User Information",
                  orderId: 0,
                },
              }),
              this.tabsService.servicesBurecordsTabsTokenBasedPost({
                tab: {
                  parentTabId: null,
                  name: "training_needs",
                  displayName: "Training Needs",
                  orderId: 1,
                },
              }),
            ];
          }

          if (observables.length > 0) return forkJoin(observables);
          return of(tabs);
        }),
        //If they are available, get subtabs
        //Get each subtab if available

        switchMap((results) => {
          return this.tabsService.servicesBurecordsTabsTokenBasedGet(
            true,
            "sub_tabs"
          );
        }),
        tap(({ count, tabs }) => {
          this.subTabs = tabs[0].subTabs;
          this.trainingNeedsForm2 = { ...tabs[1] };

          //Get FieldGroups for each subtab
        }),
        switchMap(({ count, tabs }) => {
          this.mainTabs = tabs;

          if (tabs[0] && tabs[0].subTabs.length === 0) {
            this.finishObservable();
          } else if (tabs[0]) {
            this.parentTabId = tabs[0].id;
          }
          let subTabsFieldGroups = tabs[0].subTabs.map((subTab) => {
            return this.fieldGroupsService.servicesBurecordsTabsIdFieldGroupsGet(
              subTab.id
            );
          });

          return forkJoin([
            forkJoin(subTabsFieldGroups),
            this.fieldGroupsService.servicesBurecordsTabsIdFieldGroupsGet(
              tabs[1].id
            ),
          ]);
        }),
        tap(([subTabsFieldGroups, trainingNeedsFieldGroups]) => {
          subTabsFieldGroups.forEach((subTabFieldGroups, i) => {
            this.subTabs[i].fieldGroups = subTabFieldGroups.fieldGroups;
          });
          this.trainingNeedsForm2.fieldGroups =
            trainingNeedsFieldGroups.fieldGroups;
        }),
        //Get Fields from each FieldGroup
        switchMap(([subTabsFieldGroups, trainingNeedsFieldGroups]) => {
          let fields: Observable<ServicesBurecordsFieldGroupsIdFieldsGet200Response>[] =
            [];
          subTabsFieldGroups.forEach((subTabsFieldGroup) => {
            subTabsFieldGroup.fieldGroups.forEach((fieldGroup) => {
              subTabsFieldGroup.fieldGroups;
              fields.push(
                this.fieldsService.servicesBurecordsFieldGroupsIdFieldsGet(
                  fieldGroup.id
                )
              );
            });
          });
          let fieldGroupsObservables: any =
            trainingNeedsFieldGroups.fieldGroups.map((fieldGroup) =>
              this.fieldsService.servicesBurecordsFieldGroupsIdFieldsGet(
                fieldGroup.id
              )
            );
          if (trainingNeedsFieldGroups.fieldGroups.length === 0) {
            fieldGroupsObservables = of({ count: 0, fields: [] });
          }
          if (fields.length === 0 || fieldGroupsObservables.length === 0) {
            this.finishObservable();
          }
          return zip([zip(fields), zip(fieldGroupsObservables)]);
        }),
        tap(
          ([userInformation, trainingNeeds]: [
            ServicesBurecordsFieldGroupsIdFieldsGet200Response[],
            any
          ]) => {
            this.subTabs.forEach((subTab) => {
              subTab.fieldGroups.forEach((subTabFieldGroup) => {
                userInformation.forEach((fieldGroup) => {
                  if (fieldGroup.fields.length > 0) {
                    if (
                      fieldGroup.fields[0].fieldGroupId === subTabFieldGroup.id
                    ) {
                      subTabFieldGroup.fields = fieldGroup.fields;
                    }
                  } else {
                    subTabFieldGroup.fields = [];
                  }
                });
              });
            });

            this.trainingNeedsForm2.fieldGroups.forEach(
              (trainingNeedsFieldGroup) => {
                trainingNeeds.forEach((fieldGroup) => {
                  if (fieldGroup.fields.length > 0) {
                    if (
                      fieldGroup.fields[0].fieldGroupId ===
                      trainingNeedsFieldGroup.id
                    ) {
                      trainingNeedsFieldGroup.fields = fieldGroup.fields;
                    }
                  }
                });
              }
            );
            // trainingNeeds.fields.forEach((field))
          }
        ),
        //Get FieldChoice if radio or dropdown or radio Field
        switchMap(
          ([userInformation, trainingNeeds]: [
            ServicesBurecordsFieldGroupsIdFieldsGet200Response[],
            any
          ]) => {
            let fieldsChoicesObservables: Observable<ServicesBurecordsFieldsIdFieldChoicesGet200Response>[] =
              [];
            userInformation.forEach((fieldGroup) => {
              fieldGroup.fields.forEach((field) => {
                if (
                  field.fieldType === "checkbox" ||
                  field.fieldType === "dropdown" ||
                  field.fieldType === "radio"
                ) {
                  fieldsChoicesObservables.push(
                    this.fieldChoicesService.servicesBurecordsFieldsIdFieldChoicesGet(
                      field.id
                    )
                  );
                } else {
                  fieldsChoicesObservables.push(
                    of({ count: 0, fieldChoices: [] })
                  );
                }
              });
            });
            let trainingNeedsFieldChoices: Observable<ServicesBurecordsFieldsIdFieldChoicesGet200Response>[] =
              [];
            if (trainingNeeds.fields && trainingNeeds.fields.length > 0) {
              trainingNeedsFieldChoices = trainingNeeds.fields.map((field) =>
                this.fieldChoicesService.servicesBurecordsFieldsIdFieldChoicesGet(
                  field.id
                )
              );
            } else {
              trainingNeedsFieldChoices.push(
                of({ count: 0, fieldChoices: [] })
              );
            }
            if (
              fieldsChoicesObservables.length === 0 ||
              trainingNeedsFieldChoices.length === 0
            ) {
              this.finishObservable();
            }
            return zip([
              zip(fieldsChoicesObservables),
              zip(trainingNeedsFieldChoices),
            ]);
          }
        ),
        tap(([userInformationChoices, trainingNeedsChoices]) => {
          this.subTabs.forEach((subTab) => {
            subTab.fieldGroups.forEach((fieldGroup) => {
              fieldGroup.fields.forEach((field) => {
                userInformationChoices.forEach((choices) => {
                  if (choices.fieldChoices.length > 0) {
                    if (choices.fieldChoices[0].fieldId === field.id) {
                      field.fieldChoices = choices.fieldChoices;
                    }
                  }
                });
              });
            });
          });

          this.trainingNeedsForm2.fieldGroups.forEach((fieldGroup) => {
            fieldGroup.fields.forEach((field) => {
              trainingNeedsChoices.forEach((choices) => {
                if (choices.fieldChoices.length > 0) {
                  if (choices.fieldChoices[0].fieldId === field.id) {
                    field.fieldChoices = choices.fieldChoices;
                  }
                }
              });
            });
          });

          if (this.parentTab === "training_needs") {
            this.enabledSubTabs = false;
            this.currentForm = this.trainingNeedsForm2;
          }
          if (this.parentTab === "user_information" || !this.parentTab) {
            this.enabledSubTabs = true;
            this.subTabs[0].selected = true;
            this.currentForm = this.subTabs[0];
          }
          this.loading = false;
        })
      )
      .subscribe();
  }

  finishObservable() {
    if (this.parentTab === "training_needs") {
      this.enabledSubTabs = false;
      this.parentTabId = this.mainTabs[1].id;
      this.currentForm = this.trainingNeedsForm2;
    }
    if (this.parentTab === "user_information" || !this.parentTab) {
      this.enabledSubTabs = true;
      this.parentTabId = this.mainTabs[0].id;
      this.currentForm = this.subTabs[0];
      this.selectSubTab(this.subTabs.length - 1);
    }

    this.loading = false;
    return EMPTY;
  }
  async addSubTab() {
    let subTab = await this.modalService.openModal(AddSubtabModalComponent);
    if (subTab) {
      let tab = await firstValueFrom(
        this.tabsService.servicesBurecordsTabsTokenBasedPost({
          tab: {
            displayName: subTab,
            orderId: this.subTabs.length,
            name: subTab,
            parentTabId: this.parentTabId,
          },
        })
      );
      this.subTabs.push({
        id: tab.id,
        selected: false,
        displayName: subTab,
        name: subTab,
      });
      this.selectSubTab(this.subTabs.length - 1);
    }
  }
  async deleteSubTab(index) {
    let deleteTab = await this.modalService.confirm(
      "Are you sure you want to delete this tab?"
    );
    if (deleteTab) {
      await firstValueFrom(
        this.tabsService.servicesBurecordsTabsIdDelete(this.subTabs[index].id)
      );
      this.subTabs.splice(index, 1);
    }
  }
  async spreadDrop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.subTabs, event.previousIndex, event.currentIndex);
    this.subTabs.forEach(async (subTab, i) => {
      subTab.orderId = i;
      await firstValueFrom(
        this.tabsService.servicesBurecordsTabsIdPut(subTab.id, {
          tab: {
            orderId: i,
          },
        })
      );
    });

    this.onSave();
  }
  selectSubTab(index) {
    this.subTabs = this.subTabs.map((subtab, i) => {
      let selected = index === i;
      return {
        ...subtab,
        selected,
      };
    });
    this.currentForm = this.subTabs[index];
  }
  onSave() {}
}
