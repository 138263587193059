import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-casteditor-catalog-card",
  templateUrl: "./casteditor-catalog-card.component.html",
  styles: [],
})
export class CasteditorCatalogCardComponent implements OnInit {
  constructor() {}
  @Input() collection;
  @Input() isInEditMode: boolean;
  @Output() deleteCatalog: EventEmitter<any> = new EventEmitter();
  ngOnInit(): void {}
  onDeleteCatalog() {
    this.deleteCatalog.emit();
  }
}
