import { Component, OnInit } from "@angular/core";
import { Group, User, PassService } from "src/app/sinigangnababoywithgabi";
import { ActivatedRoute } from "@angular/router";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Location } from "@angular/common";
import { firstValueFrom, from, Observable, Subject } from "rxjs";
import { ModalService } from "src/app/services/modal.service";
import { DashboardService } from "src/app/services/dashboard.service";
import {
  ActivityLogService,
  VERBS,
} from "src/app/services/activity-log.service";

interface AdministeredGroup {
  groupName: string;
  groupId: string;
  roleId: string;
  canViewGroup: UntypedFormGroup;
  canUpdateGroup: UntypedFormGroup;
  canViewUsers: UntypedFormGroup;
  canUpdateUsers: UntypedFormGroup;
  canReadChildGroups: UntypedFormGroup;
  canUpdateChildGroups: UntypedFormGroup;
}

@Component({
  selector: "app-group-edit",
  templateUrl: "./group-edit.component.html",
  styleUrls: ["./group-edit.component.scss"],
})
export class GroupEditComponent implements OnInit {
  group: Group;
  administeredGroups: AdministeredGroup[];
  formGroup: UntypedFormGroup;

  administeredGroupsObservable: Observable<AdministeredGroup[]>;

  canDelete: boolean;

  constructor(
    private passService: PassService,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private location: Location,
    private modalService: ModalService,
    private dahsboardService: DashboardService,
    private activityLogService: ActivityLogService
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  private unsubscribe$: Subject<void> = new Subject<void>();
  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  async loadData() {
    let { group_uuid: groupUuid } = this.route.snapshot.queryParams;

    let cast = await firstValueFrom(this.dahsboardService.getCast());

    this.group = await firstValueFrom(
      this.passService.getGroup(groupUuid, ["roles"])
    );

    this.formGroup = this.formBuilder.group({
      name: [this.group.name, Validators.required],
      description: [this.group.description],
      selectedRoleId: [
        {
          value: this.group.roles[0]?.id || null,
          // Disable if special group
          disabled: this.group.roles.length > 1,
        },
      ],
    });

    this.canDelete = this.group.id !== cast.data.attributes.defaultGroupId;
  }

  async saveGroup() {
    let roleIds = [];
    if (this.group.roles.length > 1) {
      roleIds = this.group.roles.map((r) => r.id);
    } else if (this.formGroup.value.selectedRoleId) {
      roleIds = [this.formGroup.value.selectedRoleId];
    }

    await firstValueFrom(
      this.passService.updateGroup(this.group.id, {
        group: {
          name: this.formGroup.get("name").value,
          description: this.formGroup.get("description").value,
          roleIds: roleIds,
        },
      })
    );

    this.location.back();

    this.activityLogService.didGroup(VERBS.updated, {
      id: this.group.id,
      name: this.formGroup.get("name").value,
    });
  }

  async deleteGroup() {
    let result = await this.modalService.confirm(
      "Are you sure you want to delete this group?"
    );

    if (result) {
      await firstValueFrom(this.passService.deleteGroup(this.group.id));

      window.history.go(-2);

      this.activityLogService.didGroup(VERBS.deleted, {
        id: this.group.id,
        name: this.formGroup.get("name").value,
      });
    }
  }
}
