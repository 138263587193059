import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { firstValueFrom } from "rxjs";
import {
  CourseSchedule,
  CoursesService,
} from "src/app/sinigangnababoywithgabi";

@Component({
  selector: "app-course-batch-enrollment-period",
  templateUrl: "./course-batch-enrollment-period.component.html",
  styles: [],
})
export class CourseBatchEnrollmentPeriodComponent implements OnInit {
  schedule: CourseSchedule;
  batchAccessForm: UntypedFormGroup;
  loading: boolean;

  constructor(
    private route: ActivatedRoute,
    private coursesService: CoursesService,
    private formBuilder: UntypedFormBuilder,
    private location: Location
  ) {}

  async ngOnInit() {
    this.batchAccessForm = this.formBuilder.group({
      startDateTime: [new Date()],
      endDateTime: [null],
      batchAccess: [],
    });

    let { schedule_uuid: scheduleUuid } = this.route.snapshot.queryParams;

    this.schedule = await firstValueFrom(
      this.coursesService.scheduleRead(scheduleUuid)
    );

    if (!this.schedule.enrollStart && !this.schedule.enrollEnd) {
      this.batchAccessForm.get("batchAccess").setValue("forever");
    } else {
      this.batchAccessForm.get("batchAccess").setValue("startend");

      this.batchAccessForm
        .get("startDateTime")
        .setValue(this.schedule.enrollStart);

      this.batchAccessForm.get("endDateTime").setValue(this.schedule.enrollEnd);
    }
  }

  async updateBatchAccess() {
    try {
      this.loading = true;

      if (this.batchAccessForm.get("batchAccess").value == "forever") {
        this.schedule.enrollStart = null;
        this.schedule.enrollEnd = null;
        //Set start end end date to null
      } else {
        this.schedule.enrollStart = this.batchAccessForm.value.startDateTime;
        this.schedule.enrollEnd = this.batchAccessForm.value.endDateTime;
      }

      await firstValueFrom(
        this.coursesService.scheduleUpdate(this.schedule.uuid, this.schedule)
      );

      this.location.back();
    } catch (error) {
      this.loading = false;
      throw error;
    }
  }
}
