import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LinkList } from "src/app/app-common-module/layouts/profile-link-list/profile-link-list.component";

@Component({
  selector: "app-general-settings-options",
  templateUrl: "./general-settings-options.component.html",
  styles: [],
})
export class GeneralSettingsOptionsComponent implements OnInit {
  constructor(private router: Router, private route: ActivatedRoute) {}

  linkList: LinkList[];

  ngOnInit(): void {
    this.linkList = [
      {
        icon: "",
        disabled: true,
        name: "Basic Cast Info",
        description: "Prep your Cast's info for Web, IOS, and Android",
        routerLinkOptions: {
          routerLink: [""],
          queryParamsHandling: "merge",
        },
      },
      {
        icon: "",
        name: "Customize Resource Name",
        description: "Allows you to change name of resource type",
        routerLinkOptions: {
          routerLink: ["customize"],
          queryParamsHandling: "merge",
        },
      },
      {
        icon: "",
        name: "Profile Additional Fields",
        description: "Allows you to add custom profile fields",
        routerLinkOptions: {
          routerLink: ["add-fields"],
          queryParamsHandling: "merge",
        },
      },
      {
        icon: "",
        name: "Log In as Guest",
        description: "Allow users to use your cast without signing up",
        routerLinkOptions: {
          routerLink: ["guest-login"],
          queryParamsHandling: "merge",
        },
      },
    ];
  }
}
