import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-confirm-dialog",
  templateUrl: "./confirm-dialog.component.html",
})
export class ConfirmDialogComponent implements OnInit {
  @Input()
  message: string;

  @Input()
  cancelText: string = "Cancel";

  @Input()
  confirmText: string = "Ok";

  @Input()
  confirmClass: string = "btn btn-primary";

  @Input()
  cancelClass: string = "btn bg-gray-300";

  constructor() {}

  ngOnInit(): void {}
}
