import { Component, OnInit, Input, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "app-radio",
  templateUrl: "./radio.component.html",
  styleUrls: ["./radio.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioComponent),
      multi: true,
    },
  ],
})
export class RadioComponent implements ControlValueAccessor {
  @Input()
  _selected: string;

  get selected() {
    return this._selected;
  }

  set selected(val) {
    this._selected = val;
    this.propagateChange(this._selected);
  }

  @Input()
  name: string;
  @Input()
  value: string;
  @Input()
  disabled: boolean;
  @Input()
  checked: boolean;

  @Input()
  checkIcon: string = "radio_button_checked";
  @Input()
  uncheckIcon: string = "radio_button_unchecked";

  propagateChange = (_: any) => {};

  constructor() {}

  writeValue(value: any): void {
    if (value !== undefined && value !== null) {
      this.selected = value;

      if (this.selected === this.value) {
        this.checked = true;
      } else {
        this.checked = false;
      }
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {}

  valueChange(value: string) {
    this.selected = value;
  }
}
