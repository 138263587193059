import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import {
  CastsService,
  Collection,
  ResourcesService,
  Resource,
  V2Block,
  V2CastsService,
} from "src/app/sinigangnababoywithgabi";
import { Router } from "@angular/router";
import { CasteditorModalLayoutComponent } from "../components/casteditor-modal-layout/casteditor-modal-layout.component";
import { UserPreferenceService } from "src/app/services/user-preference.service";
import { ModalService } from "src/app/services/modal.service";
import { DashboardService } from "src/app/services/dashboard.service";
import {
  ActivityLogService,
  VERBS,
} from "src/app/services/activity-log.service";
import { zoomInOut } from "src/app/app-common-module/animations/zoom";
import { firstValueFrom, Subject } from "rxjs";

@Component({
  selector: "app-casteditor-block-catalog",
  templateUrl: "./casteditor-block-catalog.component.html",
  styleUrls: ["./casteditor-block-catalog.component.scss"],
  animations: [zoomInOut],
})
export class CasteditorBlockCatalogComponent implements OnInit {
  constructor(
    private resourcesService: ResourcesService,
    private router: Router,
    private castsService: CastsService,
    private v2CastsService: V2CastsService,
    private userPreferenceService: UserPreferenceService,
    private modalService: ModalService,
    private dashboardService: DashboardService,
    private activityLogsService: ActivityLogService
  ) {}

  @Input() block: any;

  v2Block: V2Block;

  @Input() screenId: any;
  @Input() disableEdit: boolean;
  @Output() deleteBlock: EventEmitter<any> = new EventEmitter();
  @Output() deleteResource: EventEmitter<any> = new EventEmitter();
  @Output() dropChildComponent: EventEmitter<any> = new EventEmitter();

  resources: Resource[];
  collection: Collection;

  isInEditMode = false;

  collectionNameLabel: string;

  canEdit = false;
  isLoading = true;

  errorMessage: string;

  async ngOnInit() {
    try {
      this.isLoading = true;
      await this.fetchData();
      this.isLoading = false;
    } catch (err) {
      this.isLoading = false;
      this.errorMessage =
        err.error?.errorDescription ||
        err.error?.["error_description"] ||
        err.message; // Until hindi pa camelized si error object
    }
  }

  private unsubscribe$: Subject<void> = new Subject<void>();
  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  async fetchData() {
    // Get the V2Block

    this.v2Block = await firstValueFrom(
      this.v2CastsService.blockGet(this.block.id)
    );

    if (!this.block.properties.uuid) {
      // Create a new collection if no data is present
      let cast = await firstValueFrom(this.dashboardService.getCast());
      let castId = cast.data.id;

      this.collection = await firstValueFrom(
        this.resourcesService.createCollection({
          name: "New Block",
        })
      );

      this.block.properties.uuid = this.collection.uuid;
      this.resources = [];

      this.collectionNameLabel = this.collection.name;

      await firstValueFrom(
        this.castsService.blockUpdate(castId, this.screenId, this.block.id, {
          block: {
            ...this.block,
            properties: {
              uuid: this.collection.uuid,
            },
          },
        })
      );

      this.canEdit = true;
    } else {
      // Parse data
      let collectionUuid = this.v2Block.data.attributes.properties.uuid;

      try {
        let collection = await firstValueFrom(
          this.resourcesService.getCollectionByUuid(
            collectionUuid,
            null,
            true,
            null,
            ["resources"]
          )
        );

        this.collection = collection;
        this.resources = collection.resources;

        this.collectionNameLabel = this.collection.name;
        this.isLoading = false;
        this.canEdit = this.collection.allowedActions.includes("update");
      } catch (err) {
        //HARDCODE: allow deletion if there is error
        this.isLoading = false;
        this.canEdit = true;

        throw err;
      }
    }
  }

  async onAddResource() {
    if (!this.userPreferenceService.isAddBlockTutorialDisabled) {
      let result = await this.modalService.openModal(
        CasteditorModalLayoutComponent
      );

      if (result) {
        this.userPreferenceService.isAddBlockTutorialDisabled = true;

        this.addResource();
      }
    } else {
      this.addResource();
    }
  }

  addResource() {
    this.router.navigate(["/resources", "select-new"], {
      queryParams: {
        parent_uuid: this.block.properties.uuid || this.collection.uuid,
        redirect: window.location.pathname + window.location.search,
        order: "0",
      },
    });
  }

  onDeleteBlock() {
    this.deleteBlock.emit();
  }

  async onDeleteResource(resourceUuid: string) {
    //HARDCODE
    if (
      await this.modalService.confirm(
        "Are you sure you want to delete this resource?"
      )
    ) {
      this.resources = this.resources.filter((r) => r.uuid !== resourceUuid);

      let removedResource = this.resources.find((r) => r.uuid === resourceUuid);

      await firstValueFrom(
        this.resourcesService.updateResourcesInCollection(
          this.block.properties.uuid,
          {
            resourceUuids: this.resources.map((resource) => resource.uuid),
          }
        )
      );

      this.activityLogsService.didResource(VERBS.removed, removedResource, {
        context: {
          contextActivities: {
            parent: [
              this.activityLogsService.constructCastBlockObject(this.block),
            ],
          },
        },
      });
    }
  }

  savePosition() {
    this.resourcesService
      .setComponentOrderInCollection(this.block.properties.uuid, {
        order: this.resources.map((resource) => resource.uuid),
      } as any)
      .subscribe((_) => {});

    this.activityLogsService.didCastBlock(VERBS.updated, this.block);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      event.container.data,
      event.previousIndex,
      event.currentIndex
    );

    this.savePosition();
  }

  onEditToggle(value) {
    this.isInEditMode = value;
    // this.multiSelect.clearMultiselect();

    if (!this.isInEditMode) {
      firstValueFrom(
        this.resourcesService.updateCollectionByUuid(
          this.block.properties.uuid,
          {
            name: this.collectionNameLabel,
          }
        )
      );
    }
  }
}
