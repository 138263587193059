import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";

@Component({
  selector: "app-burislides-block-html",
  templateUrl: "./burislides-block-html.component.html",
  styles: [],
})
export class BurislidesBlockHtmlComponent implements OnInit {
  formGroup: UntypedFormGroup;
  @Input() initialValue: string;
  @Output() html: EventEmitter<string> = new EventEmitter<string>();
  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      markdownText: [this.initialValue || ""],
    });
  }
  onSave() {
    this.html.emit(this.formGroup.value.markdownText);
  }
}
