import { Component, OnInit, Input, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "app-checkbox",
  templateUrl: "./checkbox.component.html",
  styleUrls: ["./checkbox.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input()
  _checked: boolean;

  get checked() {
    return this._checked;
  }

  @Input()
  set checked(val) {
    this._checked = val;
    this.propagateChange(this._checked);
  }

  @Input()
  id: string;
  @Input()
  name: string;
  @Input()
  value: string;
  @Input()
  disabled: boolean;

  @Input()
  checkIcon: string = "check_box";
  @Input()
  uncheckIcon: string = "check_box_outline_blank";

  @Input()
  checkClass: string;

  @Input()
  uncheckClass: string;

  currentIcon: string;

  propagateChange = (_: any) => {};

  constructor() {}

  writeValue(value: boolean = false): void {
    this.checked = value;
    this.setIcon();
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {}

  valueChange(value: boolean) {
    this.checked = value;
    this.setIcon();
  }

  setIcon() {
    if (this.checked) {
      this.currentIcon = this.checkIcon;
    } else {
      this.currentIcon = this.uncheckIcon;
    }
  }
}
